import { FC, useCallback } from 'react';

import { SavedFilterForm } from '@components/layout/Jobs/SavedFilters/SavedFilterForm';
import { SavedFilterModal } from '@components/layout/Jobs/SavedFilters/SavedFilterModal';
import { EditFormDeleteButton } from '@components/layout/Jobs/SavedFilters/styled';
import {
  SavedFilterFormValues,
  useSavedFilterFormik,
} from '@components/layout/Jobs/SavedFilters/useSavedFilterFormik';
import useAnalytics from '@hooks/useAnalytics';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import {
  ActiveEditEnabledFilter,
  SavedFilterModalType,
} from '@internals/business-shared/src/components/SavedFilters/types';
import { notificationsEnabled } from '@internals/business-shared/src/components/SavedFilters/utils';
import { useUpdateJobsSavedFilterMutation } from '@internals/business-shared/src/hooks/mutation/useUpdateJobsSavedFilterMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { EditedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';
import { Div, Button, ButtonProps } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface EditSavedFilterModalProps {
  onFilterEdited: (newFilter: EditedJobsSavedFilter) => void;
}

export const EditSavedFilterModal: FC<EditSavedFilterModalProps> = ({
  onFilterEdited,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { openModal, closeModal, filterState, setFilterFormChanged } =
    useSavedFiltersContext();
  const { activeFilter } = filterState as ActiveEditEnabledFilter;
  const [editSavedFilterMutation, { loading }] =
    useUpdateJobsSavedFilterMutation();

  const onSave = (formValues: SavedFilterFormValues) => {
    track(ANEventSpace(ANEvent.Saved, ANObject.FilterPrompt, ANPage.JobList), {
      email: notificationsEnabled(formValues.emailFrequency),
      push: notificationsEnabled(formValues.pushFrequency),
    });
    editSavedFilterMutation(
      {
        ...activeFilter.filters,
        id: activeFilter.id,
        title: formValues.title,
        notificationSettings: {
          email: formValues.emailFrequency,
          push: formValues.pushFrequency,
        },
      },
      {
        onCompleted: (response) => {
          closeModal();
          onFilterEdited(response.updateJobWatchlist);
        },
      }
    );
  };

  const onDelete = () => {
    openModal(SavedFilterModalType.ConfirmDelete);
  };

  const onFormChange = useCallback(() => {
    setFilterFormChanged(true);
  }, []);

  const formik = useSavedFilterFormik(onSave, onFormChange, {
    title: activeFilter.label,
    emailFrequency: activeFilter.notificationSettings.email,
    pushFrequency: activeFilter.notificationSettings.push,
  });

  const commonButtonProps: Partial<ButtonProps> = {
    size: 'small',
    py: 4,
    px: 6,
    mr: 4,
  };

  return (
    <SavedFilterModal
      isOpen
      onClose={closeModal}
      title={t('job.list.savedFilters.action.edit.title')}
    >
      <Div>
        <SavedFilterForm formik={formik} />
        <Div mt={8} display="flex" justifyContent="space-between">
          <EditFormDeleteButton
            {...commonButtonProps}
            variant="tertiary"
            disabled={loading}
            onClick={onDelete}
          >
            {t('job.list.savedFilters.action.delete.button')}
          </EditFormDeleteButton>
          <Div display="flex" justifyContent="flex-end">
            <Button
              {...commonButtonProps}
              variant="secondary"
              disabled={loading}
              onClick={closeModal}
            >
              {t('general.label.cancel')}
            </Button>
            <Button
              {...commonButtonProps}
              mr={0}
              variant={loading ? 'inProgress' : 'primary'}
              onClick={formik.submitForm}
              disabled={!formik.isValid}
            >
              {t('general.label.save')}
            </Button>
          </Div>
        </Div>
      </Div>
    </SavedFilterModal>
  );
};
