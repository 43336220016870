import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES,
  BoligmappaDocumentAndProfessionTypesQueryPayload,
  BoligmappaDocumentAndProfessionTypesQueryType,
} from '../../utils/query/BoligmappaDocumentAndProfessionTypes/BoligmappaDocumentAndProfessionTypes';

export const BOLIGMAPPA_DOCUMENT_TYPE_UNDEFINED = '0';
export const BOLIGMAPPA_PROFESSION_TYPE_UNDEFINED = '-1';

export interface BoligmappaDocumentAndProfessionTypesQueryResult
  extends QueryResult {
  data: BoligmappaDocumentAndProfessionTypesQueryType | null;
}

export const useBoligmappaDocumentAndProfessionTypesQuery = (
  options?: QueryHookOptions<BoligmappaDocumentAndProfessionTypesQueryPayload>
): BoligmappaDocumentAndProfessionTypesQueryResult => {
  const { data, ...rest } =
    useQuery<BoligmappaDocumentAndProfessionTypesQueryPayload>(
      BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES,
      {
        ...options,
      }
    );

  return useMemo(() => {
    return {
      data: data ? data.boligmappaDocumentAndProfessionTypes : null,
      ...rest,
    };
  }, [data, rest]);
};
