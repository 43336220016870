import gql from 'graphql-tag';

import {
  RESTORE_JOBVariables,
  RESTORE_JOB as RESTORE_JOB_Result,
} from '../../generated/generated';

export type RestoreJobMutationPayload = RESTORE_JOB_Result;
export type RestoreJobMutationVariables = RESTORE_JOBVariables;

export const RESTORE_JOB_MUTATION = gql`
  mutation RESTORE_JOB($jobId: ID!) {
    restoreJob(input: { jobId: $jobId }) {
      success
    }
  }
`;
