import { FC } from 'react';

import { ProductInfoRow } from '@components/layout/Products/ProductInfoRow';
import { Div } from '@schibsted-smb/fireball';

interface ProductSummaryProps {
  data: { name: string; value: string }[];
}

export const ProductSummary: FC<ProductSummaryProps> = ({ data }) => (
  <Div backgroundColor="beige.light" p={5} borderRadius={3}>
    {data.map((item) => (
      <ProductInfoRow key={item.name} name={item.name} value={item.value} />
    ))}
  </Div>
);
