import { Input } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StandardMessagesInput = styled(Input)`
  &,
  &:focus {
    padding: 0 0 0 ${(props) => props.theme.space[8]}px;
  }
`;

export default StandardMessagesInput;
