import { FC } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Column, Heading, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const Header: FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Column
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      px={0}
    >
      <Heading.h1 fontSize={isMobile ? 5 : 7} color="black.black0" mb={3}>
        {t('job.modal.boost.selectPackage.title')}
      </Heading.h1>
      <Text.p color="black.black0" textAlign="center" maxWidth={600}>
        {isMobile
          ? t('job.modal.boost.selectPackage.mobile.description')
          : t('job.modal.boost.selectPackage.description')}
      </Text.p>
    </Column>
  );
};
