import gql from 'graphql-tag';

import {
  CHAT_FIRST_MESSAGE_QUERYVariables,
  CHAT_FIRST_MESSAGE_QUERY as CHAT_FIRST_MESSAGE_QUERY_Result,
  CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges_node,
} from '../../generated/generated';

export type ChatFirstMessageQueryPayload = CHAT_FIRST_MESSAGE_QUERY_Result;
export type ChatFirstMessageQueryVariables = CHAT_FIRST_MESSAGE_QUERYVariables;
export type ChatFirstMessage = CHAT_FIRST_MESSAGE_QUERY_chatMessages_edges_node;

export const CHAT_FIRST_MESSAGE_QUERY = gql`
  query CHAT_FIRST_MESSAGE_QUERY(
    $chatId: ID!
    $first: Int
    $from: Int
    $orderByTime: SortDirection
  ) {
    chatMessages(
      input: {
        chatId: $chatId
        first: $first
        from: $from
        orderByTime: $orderByTime
      }
    ) {
      edges {
        node {
          id
          text
        }
      }
    }
  }
`;
