import * as React from 'react';

import BadgeWrapper from '@components/base/Badge/styled/BadgeWrapper';

export interface BadgeProps {
  text: string;
  isResponsive?: boolean;
}

export const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = ({
  text,
  ...props
}) => <BadgeWrapper {...props}>{text}</BadgeWrapper>;

export default Badge;
