import {
  FC,
  PropsWithChildren,
  Reducer,
  Suspense,
  useCallback,
  useReducer,
} from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { BoligMappaAuthModal } from '@components/elements/BoligMappa/modal/BoligMappaAuthModal';
import { getBoligModalContent } from '@components/elements/BoligMappa/ModalContentFactory';
import { useBoligMappaAuthContext } from '@contexts/BoligMappaAuthProvider';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import {
  hasBoligmappaJobAssociations,
  useBoligmappaJobAssociationsQuery,
} from '@internals/business-shared/src/hooks/query/useBoligmappaJobAssociationsQuery';
import { useDisconnectBoligmappa } from '@internals/business-shared/src/hooks/useDisconnectBoligmappa';
import {
  BoligMappaContentAction,
  BoligMappaContentDefaultValue,
  BoligMappaContentState,
  BoligMappaModalReducer,
} from '@internals/business-shared/src/reducers/BoligMappaModalReducer';
import { hasAnyBoligmappaFiles } from '@internals/business-shared/src/utils/adapters/boligmappaFile';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { ChatJob } from '@internals/business-shared/src/utils/query/ChatJob/ChatJobQuery';
import { ActionButton, Heading, Modal, Spinner } from '@schibsted-smb/fireball';
import { ModalProps } from '@schibsted-smb/fireball/lib/components/elements/Modal';
import { bugsnagClient } from '@utils/initBugsnag';

type BoligMappaContentModalProps = Omit<
  PropsWithChildren<ModalProps>,
  'isClosable'
> & {
  isClosable?: boolean;
  job: ChatJob;
};
export const BoligMappaContentModal: FC<BoligMappaContentModalProps> = ({
  children,
  ...modalProps
}) => {
  const { isAuthorised } = useBoligMappaAuthContext();
  const isTabletOrMobile = useIsTabletOrMobile();
  const [boligMappaContentModalState, dispatchBoligMappaContentModalState] =
    useReducer<Reducer<BoligMappaContentState, BoligMappaContentAction>>(
      BoligMappaModalReducer,
      BoligMappaContentDefaultValue
    );
  const { loading: disconnecting, removeAssociation } = useDisconnectBoligmappa(
    modalProps.job,
    boligMappaContentModalState,
    dispatchBoligMappaContentModalState
  );
  const { loading: jobAssociationsLoading } = useBoligmappaJobAssociationsQuery(
    {
      variables: {
        jobId: modalProps.job.id,
      },
      onCompleted: (data) => {
        if (hasBoligmappaJobAssociations(data)) {
          dispatchBoligMappaContentModalState({
            type: 'SHOW-ASSOCIATED-ADDRESS',
            payload: {
              jobAssociations: data.boligmappaJobAssociations,
            },
          });
        }
      },
      onError: (err) => {
        bugsnagClient.notify(
          new Error(
            `Boligmappa job associations error. Job: ${modalProps.job.id} Error: ${err.message}`
          )
        );
      },
    }
  );

  const onModalCloseRequest = useCallback(() => {
    modalProps.onClose?.();
    dispatchBoligMappaContentModalState({
      type: 'RESET',
    });
  }, [modalProps]);

  if (!isAuthorised) {
    return <BoligMappaAuthModal isClosable {...modalProps} />;
  }

  const canDisconnect = !hasAnyBoligmappaFiles(
    boligMappaContentModalState.jobAssociations
  );

  const isAssociatedAddressStep =
    boligMappaContentModalState.kind === 'SHOW-ASSOCIATED-ADDRESS';

  const canDisplayBackButton =
    (isAssociatedAddressStep && canDisconnect) ||
    boligMappaContentModalState.prevStep;

  const onBackButton = () => {
    if (isAssociatedAddressStep && canDisconnect) {
      removeAssociation();
      return;
    }
    if (boligMappaContentModalState.prevStep) {
      dispatchBoligMappaContentModalState({
        type: boligMappaContentModalState.prevStep,
        payload: { ...boligMappaContentModalState },
      });
    }
  };

  const ModalContent = getBoligModalContent(boligMappaContentModalState.kind);

  if (!ModalContent) {
    return null;
  }

  return (
    <Modal
      {...modalProps}
      isClosable
      shouldCloseOnOverlayClick={false}
      contentProps={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        m: 0,
        p: 20,
      }}
      {...(isTabletOrMobile && { verticalSize: 'fullHeight', size: 'wide' })}
      header={
        <>
          {canDisplayBackButton && (
            <ActionButton
              mr={4}
              borderColor="transparent"
              fill="blue.darkest"
              onClick={onBackButton}
              disabled={disconnecting}
            >
              <FireIcon
                iconName={IconPickerName.ArrowLeft}
                width={24}
                height={24}
              />
            </ActionButton>
          )}
          <Heading.h3 m={0} textAlign="left">
            {boligMappaContentModalState.title ?? ''}
          </Heading.h3>
        </>
      }
      headerProps={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        px: 6,
        m: 0,
        mb: 2,
      }}
      onClose={onModalCloseRequest}
    >
      {jobAssociationsLoading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <ModalContent
            boligMappaState={boligMappaContentModalState}
            boligMappaDispatch={dispatchBoligMappaContentModalState}
            job={modalProps.job}
            onClose={onModalCloseRequest}
          />
        </Suspense>
      )}
    </Modal>
  );
};
