import gql from 'graphql-tag';

import { JOBS_SAVED_FILTER_FRAGMENT } from '../../fragments/JobsSavedFilterFragment';
import {
  JOBS_SAVED_FILTERS_QUERY as JOBS_SAVED_FILTERS_QUERY_Result,
  JOBS_SAVED_FILTERS_QUERY_jobWatchlistList,
} from '../../generated/generated';

export type JobsSavedFiltersQueryPayload = JOBS_SAVED_FILTERS_QUERY_Result;
export type JobsSavedFilters = JOBS_SAVED_FILTERS_QUERY_jobWatchlistList[];
export type JobsSavedFilter = JOBS_SAVED_FILTERS_QUERY_jobWatchlistList;

export const JOBS_SAVED_FILTERS_QUERY = gql`
  ${JOBS_SAVED_FILTER_FRAGMENT}
  query JOBS_SAVED_FILTERS_QUERY {
    jobWatchlistList {
      ...JobsSavedFilterFragment
    }
  }
`;
