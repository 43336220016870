import { FC } from 'react';

import {
  useIsMobile,
  useIsTablet,
  useIsTabletOrMobile,
} from '@contexts/DeviceSizeContext';
import { Column, Container, Row, hexToRgba } from '@schibsted-smb/fireball';

import { Features } from './Features';
import { Footer } from './Footer';
import { Header } from './Header';
import { Packages } from './Packages';

const CUSTOM_BLUE_COLOR = '#11203F';

export const SelectPackageState: FC = () => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Container maxWidth={!isTabletOrMobile && 920}>
      {!isTablet && (
        <Column px={0}>
          <Header />
          <Column
            bg={!isMobile && hexToRgba(CUSTOM_BLUE_COLOR, 0.4)}
            borderRadius={!isMobile && 8}
            display="flex"
            flexDirection={isMobile ? 'column-reverse' : 'column'}
            mb={!isMobile && 5}
            px={0}
          >
            <Packages />
            <Features />
          </Column>
          <Footer />
        </Column>
      )}
      {isTablet && (
        <Row>
          <Column pl={0} pr={5} width={1 / 2}>
            <Header />
            <Features />
            <Footer />
          </Column>
          <Column pl={5} pr={0} width={1 / 2}>
            <Packages />
          </Column>
        </Row>
      )}
    </Container>
  );
};
