import gql from 'graphql-tag';

import EMPLOYEE_FRAGMENT from '../../fragments/EmployeeFragment';
import {
  EMPLOYEE_LIST_QUERY as EMPLOYEE_LIST_QUERY_Result,
  EMPLOYEE_LIST_QUERY_businessEmployees,
  EMPLOYEE_LIST_QUERYVariables,
} from '../../generated/generated';

export type EmployeeListQueryPayload = EMPLOYEE_LIST_QUERY_Result;
export type EmployeeListQueryVariables = EMPLOYEE_LIST_QUERYVariables;
export type Employee = EMPLOYEE_LIST_QUERY_businessEmployees;

export const EMPLOYEE_LIST_QUERY = gql`
  ${EMPLOYEE_FRAGMENT}

  query EMPLOYEE_LIST_QUERY($businessId: ID!) {
    businessEmployees(businessId: $businessId) {
      ...EmployeeFragment
    }
  }
`;
