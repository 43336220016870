import gql from 'graphql-tag';

import {
  UPDATE_BUSINESS_DISTRICTSVariables,
  UPDATE_BUSINESS_DISTRICTS as UPDATE_BUSINESS_DISTRICTS_Result,
} from '../../generated/generated';

export type UpdateBusinessDistrictsPayload = UPDATE_BUSINESS_DISTRICTS_Result;
export type UpdateBusinessDistrictsVariables =
  UPDATE_BUSINESS_DISTRICTSVariables;

export const UPDATE_BUSINESS_DISTRICTS = gql`
  mutation UPDATE_BUSINESS_DISTRICTS(
    $toggleDistricts: [ToggleDistrictsInput!]!
  ) {
    updateBusinessDistricts(input: { toggleDistricts: $toggleDistricts }) {
      districts {
        id
        name
      }
    }
  }
`;
