import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  ProductDisplayType,
  ProductsStatus,
  ProductType,
} from '@internals/business-shared/src/utils/ProductUtils';
import { Div, Heading, Text, Button, Alert } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const ProductAlert: React.FC<
  React.PropsWithChildren<{
    status: ProductsStatus['success'];
    isOwned: ProductsStatus['owned'];
  }>
> = ({ status, isOwned }) => {
  const { t } = useTranslation();
  if (isOwned) {
    return (
      <Alert variant="success" data-testid="alert-is-owned">
        {t('settings.products.ownedAlert')}
      </Alert>
    );
  }
  return status ? (
    <Alert variant="success" data-testid="alert-success-contact">
      {t('settings.products.successContactAlert')}
    </Alert>
  ) : (
    <Alert variant="danger" data-testid="alert-fail-contact">
      {t('settings.products.failContactAlert')}
    </Alert>
  );
};

interface ProductsSubsectionProps {
  icon: IconPickerName;
  title: string;
  description?: string;
  productType: ProductType;
  productDisplay: ProductDisplayType;
  onActionButtonClick: () => void;
  actionButtonText: string;
  testId: string;
}

const ProductsSubsection: React.FC<
  React.PropsWithChildren<ProductsSubsectionProps>
> = ({
  icon,
  title,
  description,
  productType,
  productDisplay,
  onActionButtonClick,
  actionButtonText,
  testId = 'default',
}) => {
  const themeContext = useTheme();
  const productStatus = productDisplay[productType];

  return (
    <Div
      border={`1px solid ${themeContext.colors.black.black4}`}
      p={6}
      mt={7}
      data-testid={`products-subsection-${testId}`}
    >
      <Div display="flex" alignItems="center" mb={1}>
        <Div width={32} height={32}>
          <FireIcon iconName={icon} variant="color" />
        </Div>
        <Heading.h4 ml={5} fontWeight={themeContext.fontWeights.medium}>
          {title}
        </Heading.h4>
      </Div>
      <Text.p
        ml={[47, null, null, null, null, 0, 47]}
        color="black.black8"
        lineHeight={1.5}
        fontSize={2}
      >
        {description}
      </Text.p>
      <Div mt={5} ml={[47, null, null, null, null, 0, 47]}>
        {productStatus.display ? (
          <Button
            variant="secondary"
            isLoading={productStatus.loading}
            fontSize={1}
            onClick={onActionButtonClick}
            data-testid={`action-btn-${testId}`}
          >
            {actionButtonText}
          </Button>
        ) : (
          <ProductAlert
            status={productStatus.success}
            isOwned={productStatus.owned}
          />
        )}
      </Div>
    </Div>
  );
};

export default ProductsSubsection;
