import type { GraphQLErrorHandler } from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { bugsnagClient } from '../../utils/initBugsnag';

export const defaultErrorHandler =
  (
    handler: GraphQLErrorHandler,
    reportUnhandled = false
  ): GraphQLErrorHandler =>
  (error): void => {
    handler(error);

    if (!reportUnhandled) return;

    bugsnagClient.notify('Unhandled error in defaultErrorHandler', (event) => {
      event.addMetadata('error', error);
    });
  };
