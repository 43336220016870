import { createElement, FC, useMemo } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { CompanyStateAction } from '@components/elements/CompanyStateAlert/CompanyStateAction';
import { CompanyStateAlertIcon } from '@components/elements/CompanyStateAlert/CompanyStateAlertIcon';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Heading, Text } from '@schibsted-smb/fireball';
import {
  CompanyStateAlertText,
  CompanyStateAlertVariant,
} from '@utils/companyState/getCompanyStateBannerData';
import { ActionType } from '@utils/companyState/useCompanyStateAction';
import { useTheme } from 'styled-components';

interface VariantColors {
  background: string;
  text: string;
}

export interface CompanyStateBannerProps {
  text: CompanyStateAlertText;
  variant: CompanyStateAlertVariant;
  action?: ActionType;
  icon?: IconPickerName;
  onClose?: VoidFunction;
}

export interface CompanyStateAlertProps
  extends Omit<CompanyStateBannerProps, 'variant'> {
  colors: VariantColors;
}

const CompanyStateMobileAlert: FC<CompanyStateAlertProps> = ({
  text,
  action,
  colors,
  onClose,
}) => {
  const theme = useTheme();
  return (
    <Div backgroundColor={colors.background} color={colors.text} width="100%">
      <Div
        p={5}
        pr={0}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        display="flex"
      >
        <Heading.h3 mb={0} pr={5}>
          {text.title}
        </Heading.h3>
        {onClose && (
          <Button variant="linkSecondary" onClick={onClose} px={5} py={3}>
            <FireIcon
              iconName={IconPickerName.Cross}
              width={14}
              height={14}
              variant="flat"
              fill={theme.colors.black.black7}
            />
          </Button>
        )}
      </Div>
      <Div
        p={5}
        pt={0}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <Text.p mb={5}>{text.subtitle}</Text.p>
        {action && <CompanyStateAction {...action} />}
      </Div>
    </Div>
  );
};

const CompanyStateWideAlert: FC<CompanyStateAlertProps> = ({
  text,
  action,
  colors,
  icon,
  onClose,
}) => {
  const theme = useTheme();

  return (
    <Div
      width="100%"
      backgroundColor={colors.background}
      color={colors.text}
      position="relative"
    >
      <Div width="100%" display="flex" justifyContent="center" py={7} px={8}>
        <Div
          maxWidth="500px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          {icon && (
            <Div mb={6}>
              <CompanyStateAlertIcon icon={icon} />
            </Div>
          )}
          <Heading.h2 textAlign="center" mb={5}>
            {text.title}
          </Heading.h2>
          <Text.p textAlign="center" mb={5}>
            {text.subtitle}
          </Text.p>
          {action && <CompanyStateAction {...action} />}
        </Div>
      </Div>
      {onClose && (
        <Div position="absolute" top={0} right={0}>
          <Button variant="linkSecondary" onClick={onClose} p={5}>
            <FireIcon
              iconName={IconPickerName.Cross}
              width={14}
              height={14}
              variant="flat"
              fill={theme.colors.black.black7}
            />
          </Button>
        </Div>
      )}
    </Div>
  );
};

export const CompanyStateBanner: FC<CompanyStateBannerProps> = ({
  variant,
  ...props
}) => {
  const isMobile = useIsMobile();
  const themeContext = useTheme();
  const colors = useMemo(() => {
    const themeColors = themeContext.colors;
    const colorMap: Record<CompanyStateAlertVariant, VariantColors> = {
      danger: {
        background: themeColors.red.base,
        text: themeColors.black.black0,
      },
      warning: {
        background: themeColors.yellow.lightest,
        text: themeColors.black.black9,
      },
      info: {
        background: themeColors.blue.lightest,
        text: themeColors.black.black9,
      },
    };
    return colorMap[variant];
  }, [themeContext, variant]);

  return createElement(
    isMobile ? CompanyStateMobileAlert : CompanyStateWideAlert,
    { ...props, colors }
  );
};
