import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  isQualificationListQuerySuccessResponse,
  QUALIFICATION_LIST_QUERY,
  QualificationListData,
  QualificationListQueryPayload,
  QualificationListQueryVariables,
} from '../../utils/query/QualificationList/QualificationListQuery';

export interface QualificationListQueryResult extends QueryResult {
  data: QualificationListData | null;
}

export const useQualificationListQuery = (
  options?: QueryHookOptions<
    QualificationListQueryPayload,
    QualificationListQueryVariables
  >
): QualificationListQueryResult => {
  const { data, ...rest } = useQuery<
    QualificationListQueryPayload,
    QualificationListQueryVariables
  >(QUALIFICATION_LIST_QUERY, {
    ...options,
  });

  const qualificationList: QualificationListData | null =
    useMemo<QualificationListData | null>(() => {
      if (data && !isQualificationListQuerySuccessResponse(data.business)) {
        return null;
      }

      if (data && isQualificationListQuerySuccessResponse(data.business)) {
        return data.business.certificateConnection.edges.map(
          (edge) => edge.node
        );
      }

      return null;
    }, [data]);

  return {
    ...rest,
    data: qualificationList,
  };
};
