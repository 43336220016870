import gql from 'graphql-tag';

import {
  REQUEST_PASSWORD_CHANGEVariables,
  REQUEST_PASSWORD_CHANGE as REQUEST_PASSWORD_CHANGE_Result,
} from '../../generated/generated';

export type RequestPasswordChangeVariables = REQUEST_PASSWORD_CHANGEVariables;
export type RequestPasswordChangePayload = REQUEST_PASSWORD_CHANGE_Result;

export const REQUEST_PASSWORD_CHANGE = gql`
  mutation REQUEST_PASSWORD_CHANGE($input: RequestPasswordResetInput!) {
    requestPasswordChange(input: $input)
  }
`;
