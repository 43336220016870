import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledReportLink = styled(Link)`
  display: block;
  color: ${(props): string => props.theme.colors.black.black9};

  &:hover {
    color: ${(props): string => props.theme.colors.black.black9};
    text-decoration: none;
  }
`;

export default StyledReportLink;
