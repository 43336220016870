import gql from 'graphql-tag';

import {
  REJECT_ONE_TO_ONE_JOBVariables,
  REJECT_ONE_TO_ONE_JOB as REJECT_ONE_TO_ONE_JOB_Result,
} from '../../generated/generated';

export type RejectOneToOneJobMutationPayload = REJECT_ONE_TO_ONE_JOB_Result;
export type RejectOneToOneJobMutationVariables = REJECT_ONE_TO_ONE_JOBVariables;

export const REJECT_ONE_TO_ONE_JOB = gql`
  mutation REJECT_ONE_TO_ONE_JOB($input: RejectOneToOneJobInput!) {
    rejectOneToOneJob(input: $input) {
      success
    }
  }
`;
