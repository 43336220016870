import gql from 'graphql-tag';

import EMPLOYEE_FRAGMENT from '../../fragments/EmployeeFragment';
import {
  ADD_BUSINESS_EMPLOYEEVariables,
  ADD_BUSINESS_EMPLOYEE as ADD_BUSINESS_EMPLOYEE_Result,
  ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload,
} from '../../generated/generated';

export type AddBusinessEmployeeMutationVariables =
  ADD_BUSINESS_EMPLOYEEVariables;
export type AddBusinessEmployeeMutationPayload = ADD_BUSINESS_EMPLOYEE_Result;

export const isAddBusinessEmployeeSuccessResponse = (
  addBusinessEmployee?: ADD_BUSINESS_EMPLOYEE_Result['addBusinessEmployee']
): addBusinessEmployee is ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload =>
  !!addBusinessEmployee &&
  addBusinessEmployee.__typename === 'AddBusinessEmployeePayload';

export const ADD_BUSINESS_EMPLOYEE = gql`
  ${EMPLOYEE_FRAGMENT}

  mutation ADD_BUSINESS_EMPLOYEE($input: AddBusinessEmployeeInput!) {
    addBusinessEmployee(input: $input) {
      ... on AddBusinessEmployeePayload {
        employee {
          ...EmployeeFragment
        }
      }
      ... on AddBusinessEmployeeInputError {
        message
        businessId {
          message
        }
        position {
          message
        }
        firstName {
          message
        }
        lastName {
          message
        }
        phoneNr {
          message
        }
        email {
          message
        }
        image {
          message
        }
      }
      ... on AddBusinessEmployeeNoAccessError {
        message
      }
    }
  }
`;
