export const Paths = {
  Homepage: '/',
  JobsList: '/tyot',
  JobsListItem: '/tyot/:listId',
  JobsItem: '/tyo/:id',
  JobsItemPrint: '/tyo/:id/print',
  JobsItemTitle: '/tyo/:id/:title',
  Messages: '/viestit',
  MessagesItem: '/viestit/:chatId',
  MessagesItemName: '/viestit/:chatId/:name',
  Icons: '/icons',
  Customer: '/asiakkaat',
  CustomerContract: '/asiakkaat/sopimus',
  CustomerInvoice: '/asiakkaat/lasku',
  CustomerEvaluation: '/asiakkaat/arvioinnit',
  Profile: '/profiili',
  ProfileEvaluations: '/profiili/arvioinnit',
  ProfileEmployees: '/profiili/tyontekijat',
  ProfileCompanyInfo: '/profiili/yritystiedot',
  ProfileGraphic: '/profiili/graafinen',
  ProfilePictures: '/profiili/kuvat',
  ProfileReferences: '/profiili/suositukset',
  ProfileQualifications: '/profiili/patevyydet',
  SettingsNotifications: '/asetukset/ilmoitukset',
  SettingsJobs: '/asetukset/tyohaku',
  SettingsProfile: '/asetukset/yritystiedot',
  SettingsProducts: '/asetukset/tuotteet',
  // todo: add translations
  SettingsProductAddons: '/asetukset/tuotteet/addons',
  SettingsProductAddon: '/asetukset/tuotteet/addons/:slug',
  SettingsProductAddonPurchase: '/asetukset/tuotteet/addons/:slug/purchase',
  SettingsProductRewards: '/asetukset/tuotteet/rewards',
  SettingsPayment: '/asetukset/kuitit',
  SettingsCustomerService: '/asetukset/asiakaspalvelu',
  SettingsReceipts: '/asetukset/asiakaspalvelu2',
  SettingsPrivacyAndTerms: '/asetukset/yksityisyys',
  SettingsIntegrations: '/asetukset/integraatiot',
  Settings: '/asetukset',
  Logout: '/kirjaudu-ulos',
  AccountSwitch: '/vaihda-kayttajaa',
} as const;

export const Params = {
  Page: 'sivu',
  Contract: 'contract',
  Search: 'haku',
  Size: 'koko',
  Municipality: 'kunta',
  District: 'kaupunginosa',
  County: 'county',
  Folder: 'kansio',
  WorkType: 'tyotyyppi',
  WorkSubset: 'alakategoria',
  Industry: 'industry',
  WorkSubsetGroup: 'subset-group',
  Job: 'tyo',
  Evaluation: 'arviointi',
  Report: 'ilmianna',
  BoostCreditsModal: 'lisaa-luottoja-ikkuna',
  BuySubscriptionModal: 'osta-tilaus-ikkuna',
  SelectedPackage: 'valittu-paketti',
} as const;
