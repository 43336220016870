import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const IconContainer = styled(Div)`
  svg {
    vertical-align: top;
  }
`;

export default IconContainer;
