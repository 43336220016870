import { useLayoutEffect, useMemo, useRef } from 'react';

const useKeepScrollPosition = (
  scrollContainerID: string,
  deps: any[] = []
): void => {
  const previousScrollPosition = useRef(0);
  const scrollContainer = document.getElementById(scrollContainerID);

  useMemo(() => {
    if (scrollContainer) {
      previousScrollPosition.current =
        scrollContainer.scrollHeight - scrollContainer.scrollTop;
    }
  }, [...deps]);

  useLayoutEffect(() => {
    if (scrollContainer) {
      scrollContainer.scrollTop =
        scrollContainer.scrollHeight - previousScrollPosition.current;
    }
  }, [...deps]);
};

export default useKeepScrollPosition;
