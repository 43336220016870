import gql from 'graphql-tag';

import {
  POSTAL_ADDRESS_QUERYVariables,
  POSTAL_ADDRESS_QUERY as POSTAL_ADDRESS_QUERY_Result,
  POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress,
} from '../../generated/generated';

export type PostalAddressQueryPayload = POSTAL_ADDRESS_QUERY_Result;
export type PostalAddressQueryVariables = POSTAL_ADDRESS_QUERYVariables;

export const isPostalAddressQuerySuccessResponse = (
  postalAddress?: POSTAL_ADDRESS_QUERY_Result['postalAddress']
): postalAddress is POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress =>
  !!postalAddress && postalAddress.__typename === 'PostalAddress';

export const POSTAL_ADDRESS_QUERY = gql`
  query POSTAL_ADDRESS_QUERY($postalCode: String!) {
    postalAddress(input: { postalCode: $postalCode }) {
      ... on PostalAddress {
        id
        postalCode
        city
        district
        region
        lat
        lon
        municipality {
          id
          name
          county {
            id
            name
          }
        }
      }
      ... on PostalAddressNotFoundError {
        code
        message
      }
    }
  }
`;
