import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  ANSWER_TEMPLATES_QUERY,
  AnswerTemplate,
  AnswerTemplatesQueryPayload,
} from '../../utils/query/AnswerTemplates/AnswerTemplatesQuery';
import { removeNullable } from '../../utils/types/utilsTypes';

export interface AnswerTemplatesQueryResult extends QueryResult {
  data: AnswerTemplate[] | null;
}

export const useAnswerTemplatesQuery = (
  options?: QueryHookOptions<AnswerTemplatesQueryPayload>
): AnswerTemplatesQueryResult => {
  const { data, ...rest } = useQuery<AnswerTemplatesQueryPayload>(
    ANSWER_TEMPLATES_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

  const result = useMemo(() => {
    return {
      data: data ? removeNullable<AnswerTemplate>(data.answerTemplates) : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
