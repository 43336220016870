import dayjs from 'dayjs';

import {
  InvoiceLatestAttempt,
  PaymentType,
  ProductTemplateSlug,
  UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid,
} from '../generated/generated';
import { CompanyState as GQLCompanyState } from '../query/CompanyState/CompanyStateQuery';
import rawDateFromTimestamp from '../rawDateFromTimestamp';

export type GQLUnpaidInvoice =
  UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid;

export enum StateType {
  // blacklisted and deactivated companies are logged out:
  AccessDeactivated = 'access-deactivated',
  AccessBlacklist = 'access-blacklist',
  AccessDeactivatedHasSubscription = 'access-deactivated-has-subscription',
  AccessBlacklistHasSubscription = 'access-blacklist-has-subscription',

  AccessUnverified = 'access-unverified',
  OrderUnpaid = 'order-unpaid',
  AccessVerifiedLacksProducts = 'lacks-products',
  // has other products than subscription ('subscription' product gives access to paid jobs)
  LacksSubscriptions = 'lacks-subscriptions',
  ProductsExpired = 'products-expired',
}

export enum UnpaidOrderType {
  OrderUnpaidInvoiceNotStarted = 'order-unpaid-invoice-not-started',
  OrderUnpaidCardNotStarted = 'order-unpaid-card-not-started',
  OrderInvoiceDue = 'order-invoice-due',
  OrderOverdueInvoice = 'order-overdue-invoice',
  OrderCardPaymentFailedRejected = 'order-card-payment-failed-rejected',
  OrderCardPaymentFailedInvalidAmount = 'order-card-payment-failed-invalid-amount',
  OrderCardPaymentFailedFunds = 'order-card-payment-failed-funds',
  OrderCardPaymentFailedCardDetails = 'order-card-payment-failed-card-details',
  OrderCardPaymentFailedCardType = 'order-card-payment-failed-card-type',
  OrderCardPaymentFailedCardExpired = 'order-card-payment-failed-expired',
  OrderCardPaymentFailedCardExpiredOrWrongDate = 'order-card-payment-failed-expired-or-wrong-date',
  OrderCardPaymentFailedCardDeclined = 'order-card-payment-failed-declined',
  // general state to contain the rest of card failed statuses
  OrderCardPaymentFailed = 'order-card-payment-failed',
}

export const getCompanyStateType = (
  state?: GQLCompanyState
): StateType | null => {
  if (!state) return null;
  const {
    isBlacklisted,
    isDeactivated,
    isOrderUnpaid,
    isVerified,
    hasProducts,
    products,
    hasProductExpired,
    isNew,
  } = state;
  const hasSubscription = products?.includes(ProductTemplateSlug.SUBSCRIPTION);

  if (isBlacklisted) {
    return hasSubscription
      ? StateType.AccessBlacklistHasSubscription
      : StateType.AccessBlacklist;
  }
  if (isDeactivated) {
    return hasSubscription
      ? StateType.AccessDeactivatedHasSubscription
      : StateType.AccessDeactivated;
  }
  if (!isVerified) {
    return StateType.AccessUnverified;
  }
  if (isOrderUnpaid) {
    return StateType.OrderUnpaid;
  }
  if (!hasProducts && isNew) {
    return StateType.AccessVerifiedLacksProducts;
  }
  if (!hasProducts && hasProductExpired) {
    return StateType.ProductsExpired;
  }
  if (!hasSubscription) {
    return StateType.LacksSubscriptions;
  }

  return null;
};

const getCardPaymentFailedState = (
  payment: InvoiceLatestAttempt | null
): UnpaidOrderType => {
  if (!payment) return UnpaidOrderType.OrderUnpaidCardNotStarted;

  switch (payment) {
    case InvoiceLatestAttempt.REJECTED_BY_CARD_ISSUER: {
      return UnpaidOrderType.OrderCardPaymentFailedRejected;
    }
    case InvoiceLatestAttempt.INVALID_AMOUNT: {
      return UnpaidOrderType.OrderCardPaymentFailedInvalidAmount;
    }
    case InvoiceLatestAttempt.INSUFFICIENT_FUNDS: {
      return UnpaidOrderType.OrderCardPaymentFailedFunds;
    }
    case InvoiceLatestAttempt.INCORRECT_CARD_DETAILS: {
      return UnpaidOrderType.OrderCardPaymentFailedCardDetails;
    }
    case InvoiceLatestAttempt.CARD_NOT_SUPPORTED: {
      return UnpaidOrderType.OrderCardPaymentFailedCardType;
    }
    case InvoiceLatestAttempt.CARD_EXPIRED: {
      return UnpaidOrderType.OrderCardPaymentFailedCardExpired;
    }
    case InvoiceLatestAttempt.CARD_EXPIRED_OR_WRONG_EXPIRE_DATE: {
      return UnpaidOrderType.OrderCardPaymentFailedCardExpiredOrWrongDate;
    }
    case InvoiceLatestAttempt.CARD_DECLINED: {
      return UnpaidOrderType.OrderCardPaymentFailedCardDeclined;
    }
    default: {
      return UnpaidOrderType.OrderCardPaymentFailed;
    }
  }
};

// it can take up to 3 days to verify payment
const PAYMENT_OVERDUE_GRACE_TIME_IN_DAYS = 3;

export const getCompanyUnpaidOrderState = (
  unpaidInvoices: GQLUnpaidInvoice[],
  isNew: boolean
): UnpaidOrderType | null => {
  if (!unpaidInvoices.length) return null;

  const [mostDelayedInvoice] = unpaidInvoices;
  const { payType, dueDate, latestAttempt } = mostDelayedInvoice;

  if (isNew && payType === PaymentType.CARD) {
    return UnpaidOrderType.OrderUnpaidCardNotStarted;
  }
  if (isNew && payType === PaymentType.INVOICE) {
    return UnpaidOrderType.OrderUnpaidInvoiceNotStarted;
  }

  const today = dayjs();
  const paymentDay = dayjs(rawDateFromTimestamp(dueDate));
  const paymentDelayInDays = today.diff(paymentDay, 'day');
  const isPaymentGraceTime =
    paymentDelayInDays >= 0 &&
    paymentDelayInDays <= PAYMENT_OVERDUE_GRACE_TIME_IN_DAYS;
  if (payType === PaymentType.CARD) {
    return getCardPaymentFailedState(latestAttempt);
  }
  if (isPaymentGraceTime) {
    return UnpaidOrderType.OrderInvoiceDue;
  }
  if (paymentDelayInDays > PAYMENT_OVERDUE_GRACE_TIME_IN_DAYS) {
    return UnpaidOrderType.OrderOverdueInvoice;
  }

  return null;
};

export type CompanyStateAlertType = StateType | UnpaidOrderType;
