import * as React from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import NotFound from '@components/elements/NotFound';
import ProfileEditListItem from '@components/elements/ProfileEditListItem';
import Evaluated from '@components/layout/CustomerService/Evaluation/Evaluated';
import EvaluationList from '@components/layout/CustomerService/Evaluation/EvaluationList';
import EvaluationRequest from '@components/layout/CustomerService/Evaluation/EvaluationRequest';
import EvaluationValue from '@components/layout/CustomerService/Evaluation/EvaluationValue';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import { usePageAnalytics } from '@hooks/useAnalytics';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Spinner, Text } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';

const ProfileEvaluations: React.FC = () => {
  const { t } = useTranslation();
  const [openedEvaluation, setOpenedEvaluation] = React.useState<string | null>(
    null
  );
  const [
    isEvaluationRequestOpen,
    openEvaluationRequest,
    closeEvaluationRequest,
  ] = useBoolean();

  usePageAnalytics(ANSpace(ANObject.Reviews, ANPage.Profile));

  return (
    <>
      <EvaluationRequest
        isOpen={isEvaluationRequestOpen}
        onClose={closeEvaluationRequest}
      />
      {!!openedEvaluation && (
        <Evaluated
          onClose={() => setOpenedEvaluation(null)}
          evaluationId={openedEvaluation}
        />
      )}
      <SectionHeader
        title={t('profile.evaluations.list.title')}
        px={6}
        onButtonClick={openEvaluationRequest}
        buttonText={t('general.label.new')}
      />
      <EvaluationList
        renderEvaluation={(evaluation) => (
          <ProfileEditListItem
            key={evaluation.node.id}
            text={{
              name: getUserName(evaluation.node.job.owner),
              address: getAddressString(evaluation.node.job.address),
            }}
            renderText={(text) => (
              <>
                <Text.p fontSize={3} mb={4} color="black.black9">
                  {text.name}
                </Text.p>
                <Text.p fontSize={2} mb={0} color="black.black8">
                  {text.address}
                </Text.p>
              </>
            )}
            renderComponent={() => {
              const MIN_FILLED_RATING_VALUE = 4;
              return (
                <EvaluationValue
                  value={evaluation.node.score}
                  unfilled={evaluation.node.score < MIN_FILLED_RATING_VALUE}
                />
              );
            }}
            onClick={() => setOpenedEvaluation(evaluation.node.id)}
            wideBottomBorder
            testId={`evaluation-${evaluation.node.id}`}
          />
        )}
        notFoundComponent={
          <NotFound
            title={t('profile.evaluations.list.empty.title')}
            text={t('profile.evaluations.list.empty.text')}
            button={{
              text: t('general.action.requestEvaluation'),
              onClick: openEvaluationRequest,
            }}
          />
        }
        loaderComponent={
          <CenteredSpinnerContainer>
            <Spinner size={6} />
          </CenteredSpinnerContainer>
        }
      />
    </>
  );
};

export default ProfileEvaluations;
