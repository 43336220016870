import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ContentGridContainer = styled(Div)`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${(props): number => props.theme.space[8]}px;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[3]}) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints[4]}) {
    grid-template-columns: 1fr;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints[5]}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export default ContentGridContainer;
