import { FC, useCallback } from 'react';

import { SavedFilterForm } from '@components/layout/Jobs/SavedFilters/SavedFilterForm';
import { SavedFilterModal } from '@components/layout/Jobs/SavedFilters/SavedFilterModal';
import {
  SavedFilterFormValues,
  useSavedFilterFormik,
} from '@components/layout/Jobs/SavedFilters/useSavedFilterFormik';
import useAnalytics from '@hooks/useAnalytics';
import { createJobsSavedFilterUpdate } from '@internals/business-shared/src/cache/updates/createJobsSavedFilter';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import { SavedFiltersFeatureTourStep } from '@internals/business-shared/src/components/SavedFilters/SavedFiltersFeatureTourContext';
import { NewSaveEnabledFilter } from '@internals/business-shared/src/components/SavedFilters/types';
import { notificationsEnabled } from '@internals/business-shared/src/components/SavedFilters/utils';
import { useCreateJobsSavedFilterMutation } from '@internals/business-shared/src/hooks/mutation/useCreateJobsSavedFilterMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { CreatedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/CreateJobsSavedFilter/CreateJobsSavedFilterMutation';
import { Div, Button, DivProps } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface CreateSavedFilterModalProps {
  onFilterCreated: (newFilter: CreatedJobsSavedFilter) => void;
}

export const CreateSavedFilterModal: FC<CreateSavedFilterModalProps> = ({
  onFilterCreated,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { closeModal, filterState, setFilterFormChanged } =
    useSavedFiltersContext();
  const { editedFilter } = filterState as NewSaveEnabledFilter;
  const [createSavedFilter, { loading: createSavedFilterLoading }] =
    useCreateJobsSavedFilterMutation();

  const saveFilter = (formValues: SavedFilterFormValues) => {
    track(
      ANEventSpace(ANEvent.Created, ANObject.FilterPrompt, ANPage.JobList),
      {
        email: notificationsEnabled(formValues.emailFrequency),
        push: notificationsEnabled(formValues.pushFrequency),
      }
    );
    createSavedFilter(
      {
        title: formValues.title,
        ...editedFilter.filters,
        notificationSettings: {
          email: formValues.emailFrequency,
          push: formValues.pushFrequency,
        },
      },
      {
        onCompleted: (newFilterData) => {
          closeModal();
          onFilterCreated(newFilterData.createJobWatchlist);
        },
        update: createJobsSavedFilterUpdate,
      }
    );
  };

  const onFormChange = useCallback(() => {
    setFilterFormChanged(true);
  }, []);

  const formik = useSavedFilterFormik(saveFilter, onFormChange);

  return (
    <SavedFilterModal
      isOpen
      onClose={closeModal}
      title={t('job.list.savedFilters.action.save.title')}
      contentContainerProps={
        {
          'data-tooltip-id': SavedFiltersFeatureTourStep.Settings,
        } as DivProps
      }
    >
      <Div>
        <SavedFilterForm formik={formik} />
        <Div mt={8} display="flex" justifyContent="flex-end">
          <Button
            variant="secondary"
            size="small"
            py={4}
            px={6}
            mr={4}
            onClick={closeModal}
            disabled={createSavedFilterLoading}
          >
            {t('general.label.cancel')}
          </Button>
          <Button
            variant={createSavedFilterLoading ? 'inProgress' : 'primary'}
            size="small"
            py={4}
            px={6}
            onClick={formik.submitForm}
            disabled={!formik.isValid}
          >
            {t('general.label.save')}
          </Button>
        </Div>
      </Div>
    </SavedFilterModal>
  );
};
