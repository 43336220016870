import { createContext, FC, ReactElement, useContext, useMemo } from 'react';

import { useMapJobListSearchParamsToQueryVariables } from '@hooks/useJobListSearchParams';
import {
  JobListQueryResult,
  useJobListQuery,
} from '@internals/business-shared/src/hooks/query/useJobList';
import {
  FilteredJobCount,
  mapFilteredJobAggregations,
} from '@internals/business-shared/src/utils/jobFilters';

interface JobListContextValue {
  queryResult: JobListQueryResult;
}

export const JobListContext = createContext<JobListContextValue | undefined>(
  undefined
);

interface JobListContextProps {
  children: ReactElement;
}

export const JobListProvider: FC<JobListContextProps> = ({ children }) => {
  const jobsListQueryVariables = useMapJobListSearchParamsToQueryVariables();
  const queryResult = useJobListQuery({
    fetchPolicy: 'cache-and-network',
    variables: jobsListQueryVariables,
    notifyOnNetworkStatusChange: true,
  });

  return (
    <JobListContext.Provider value={{ queryResult }}>
      {children}
    </JobListContext.Provider>
  );
};

export const useJobListContext = () => {
  const context = useContext(JobListContext);
  if (context === undefined) {
    throw new Error('useJobListContext must be used within a JobListProvider');
  }
  return context;
};

export const useJobListQueryResult = () => {
  const context = useJobListContext();
  return context.queryResult;
};

export const useJobListAggregations = (): FilteredJobCount => {
  const { data, previousData, loading } = useJobListQueryResult();
  const prevData = useMemo(
    () =>
      previousData
        ? mapFilteredJobAggregations(
            previousData.jobConnection.pageInfo.aggregations
          )
        : null,
    [previousData]
  );
  const currData = useMemo(
    () =>
      data
        ? mapFilteredJobAggregations(data.jobConnection.pageInfo.aggregations)
        : null,
    [data]
  );
  // we want to display previous data while loading
  return !currData && loading ? prevData : currData;
};
