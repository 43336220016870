import * as React from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import PhoneInput from '@components/base/PhoneInput';
import { EmployeeData } from '@components/layout/Profile/ProfileEmployees/index';
import {
  FILE_UPLOAD_TOTAL_SIZE_LIMIT,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
  FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
  IMAGE_FILE_EXTENSIONS,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { isValidEmail } from '@internals/business-shared/src/utils/isValidEmail';
import { isEmptyObject } from '@internals/business-shared/src/utils/objectUtils';
import { Omit } from '@internals/business-shared/src/utils/Omit';
import { Employee } from '@internals/business-shared/src/utils/query/EmployeeList/EmployeeListQuery';
import { isEmptyString } from '@internals/business-shared/src/utils/types/StringUtils';
import { FireIcon } from '@root/src/components/base/FireIcon/FireIcon';
import {
  Button,
  Div,
  Card,
  Dropzone,
  Heading,
  Avatar,
  Input,
  Modal,
  Text,
  FilesDetails,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import InputContainer from '../styled/InputContainer';
import { IconRing } from './styled/IconRing';

type FilePreviewType =
  | Partial<FilesDetails>
  | (Pick<FilesDetails, 'previewUrl'> & { file: EmployeeData['image'] });

interface ProfileEmployeesEmployeeModalProps {
  employeeToEdit?: Employee;
  isAddNewEmployeeOpen: boolean;
  addEmployee: (newEmployee: EmployeeData) => void;
  deleteEmployee: (employeeId: string) => void;
  deleteEmployeeImage: (employeeId: string) => void;
  updateEmployee: (updatedEmployee: EmployeeData) => void;
  closeModal: () => void;
  saveButtonLoading?: boolean;
  deleteButtonLoading?: boolean;
  errors: GQLErrorState;
  setErrors: React.Dispatch<React.SetStateAction<GQLErrorState>>;
}

const ProfileEmployeesEmployeeModal: React.FC<
  React.PropsWithChildren<ProfileEmployeesEmployeeModalProps>
> = ({
  employeeToEdit,
  isAddNewEmployeeOpen,
  addEmployee,
  deleteEmployee,
  deleteEmployeeImage,
  updateEmployee,
  closeModal,
  saveButtonLoading,
  deleteButtonLoading,
  errors,
  setErrors,
}) => {
  const { t } = useTranslation();
  const [name, setName] = React.useState(employeeToEdit?.firstName || '');
  const [surname, setSurname] = React.useState(employeeToEdit?.lastName || '');
  const [profession, setProfession] = React.useState(
    employeeToEdit?.position || ''
  );
  const [phone, setPhone] = React.useState(employeeToEdit?.phoneNr || '');
  const [email, setEmail] = React.useState(employeeToEdit?.email || '');
  const themeContext = useTheme();
  const [filePreview, setFilePreview] = React.useState<FilePreviewType>({});
  const isErrors = React.useMemo(() => !isEmptyObject(errors), [errors]);
  const isModalOpen = React.useMemo(
    () => !!employeeToEdit || isAddNewEmployeeOpen,
    [employeeToEdit, isAddNewEmployeeOpen]
  );

  const cleanupForm = () => {
    setName('');
    setSurname('');
    setProfession('');
    setPhone('');
    setEmail('');
    setFilePreview({});
    setErrors({});
  };

  const isEmptyField = (value: string) => isEmptyString(value.trim());

  const validateEmptyField = (value: string, errorField: string) => {
    if (isEmptyField(value)) {
      setErrors((prev: GQLErrorState) => ({
        ...prev,
        [errorField]: {
          msg: t('general.form.validation.required'),
          variant: 'danger',
        },
      }));
      return true;
    }
    setErrors((prev: GQLErrorState) => Omit(errorField, prev));
    return false;
  };

  const handleRequiredFields = () => {
    const requiredFields: Record<string, string> = {
      firstName: name,
      lastName: surname,
    };

    let isFieldEmpty = false;

    Object.entries(requiredFields).forEach(([key, value]) => {
      if (validateEmptyField(value, key)) {
        isFieldEmpty = true;
      }
    });

    return isFieldEmpty;
  };

  const handleEmailValidation = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name: fieldName, value: fieldValue } = event.target;

    if (isEmptyField(fieldValue) || isValidEmail(fieldValue)) {
      setErrors((prev: GQLErrorState) => Omit(fieldName, prev));
    } else {
      setErrors((prev: GQLErrorState) => ({
        ...prev,
        [fieldName]: {
          msg: t('general.form.validation.email.invalidFormat'),
          variant: 'danger',
        },
      }));
    }
  };

  const handleAddEmployee = () => {
    if (!isAddNewEmployeeOpen) {
      return;
    }

    addEmployee({
      firstName: name,
      lastName: surname,
      position: profession,
      phoneNr: phone,
      email,
      ...(filePreview?.file instanceof File && { image: filePreview.file }),
    });
  };

  const handleUpdateEmployee = () => {
    if (!employeeToEdit) {
      return;
    }

    if (!filePreview?.previewUrl) {
      deleteEmployeeImage(employeeToEdit.id);
    }

    updateEmployee({
      firstName: name,
      lastName: surname,
      position: profession,
      phoneNr: phone,
      email,
      ...(filePreview?.file instanceof File && { image: filePreview.file }),
    });
  };

  const onSaveClick = () => {
    if (isErrors || handleRequiredFields()) {
      return;
    }

    handleAddEmployee();
    handleUpdateEmployee();
  };

  const handleSetImage = React.useCallback(() => {
    const previewUrl = employeeToEdit?.imageUrl ?? '';
    const path = employeeToEdit?.image?.name ?? '';

    if (!previewUrl || !path) {
      return;
    }

    setFilePreview({
      file: { path },
      previewUrl,
    });
  }, [employeeToEdit, setFilePreview]);

  React.useEffect(() => {
    if (!isModalOpen) {
      cleanupForm();
    }
  }, [isModalOpen]);

  React.useEffect(() => {
    setName(employeeToEdit?.firstName || '');
    setSurname(employeeToEdit?.lastName || '');
    setProfession(employeeToEdit?.position || '');
    setPhone(employeeToEdit?.phoneNr || '');
    setEmail(employeeToEdit?.email || '');
    handleSetImage();
  }, [employeeToEdit]);

  return (
    <Modal
      isOpen={isModalOpen}
      isClosable
      onClose={closeModal}
      footer={
        <Div display="flex" flexDirection="column" width="100%">
          <ErrorAlert
            errors={errors}
            testId="error-alert-employee-form-error"
          />
          <Button
            onClick={onSaveClick}
            variant="primary"
            isLoading={saveButtonLoading}
            width="100%"
            height={45}
            mb={employeeToEdit ? 4 : 0}
            fullWidth
            data-testid="employee-save-btn"
          >
            {t('general.label.save')}
          </Button>
          {employeeToEdit && (
            <Button
              onClick={() => deleteEmployee(employeeToEdit.id)}
              variant="danger"
              isLoading={deleteButtonLoading}
              width="100%"
              height={45}
              fullWidth
              data-testid="employee-delete-btn"
            >
              {t('general.label.delete')}
            </Button>
          )}
        </Div>
      }
      size="custom"
      maxWidth="500px"
      testId="profile-employees"
    >
      <Div textAlign="center">
        <Heading.h3 mb={6}>
          {isAddNewEmployeeOpen
            ? t('profile.employees.item.new')
            : t('profile.employees.item.edit')}
        </Heading.h3>
      </Div>
      <Text.p pb={5} fontWeight={themeContext.fontWeights.medium}>
        {t('general.label.information')}
      </Text.p>
      <Div mb={7}>
        <Div display="flex" flexDirection="row" width="100%" mb={5}>
          <Input
            value={name}
            name="firstName"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setName(event.target.value)}
            onBlur={(event) =>
              validateEmptyField(event.target.value, event.target.name)
            }
            label={t('general.label.firstName')}
            placeholder={t('general.label.firstName')}
            type="text"
            containersProps={{
              mr: themeContext.space[2],
              width: '100%',
            }}
            error={errors?.firstName}
            testId="employee-first-name"
          />
          <Input
            value={surname}
            name="lastName"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setSurname(event.target.value)}
            onBlur={(event) =>
              validateEmptyField(event.target.value, event.target.name)
            }
            label={t('general.label.lastName')}
            placeholder={t('general.label.lastName')}
            type="text"
            containersProps={{
              width: '100%',
            }}
            error={errors?.lastName}
            testId="employee-last-name"
          />
        </Div>
        <InputContainer>
          <Input
            value={profession}
            name="position"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setProfession(event.target.value)}
            label={t('profile.employees.item.position')}
            placeholder={t('profile.employees.item.position')}
            type="text"
            error={errors?.position}
            testId="employee-position"
          />
        </InputContainer>
        <InputContainer>
          <PhoneInput
            error={errors?.phoneNr}
            name="phoneNr"
            label={t('general.label.phone')}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setPhone(event.target.value)}
            required={false}
            onError={(phoneNr) =>
              phoneNr
                ? setErrors(
                    (prev: GQLErrorState) =>
                      ({ ...prev, phoneNr } as GQLErrorState)
                  )
                : setErrors((prev: GQLErrorState) => Omit('phoneNr', prev))
            }
            placeholder={t('general.label.phone')}
            testId="employee-phone"
            value={phone}
            onFocusOut={({ value }) => setPhone(value)}
          />
        </InputContainer>
        <InputContainer>
          <Input
            value={email}
            name="email"
            onChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setEmail(event.target.value)}
            onBlur={handleEmailValidation}
            label={t('general.label.email')}
            placeholder={t('general.label.email')}
            type="email"
            error={errors?.email}
            testId="employee-email"
          />
        </InputContainer>
      </Div>
      <Text.p pb={5} fontWeight={themeContext.fontWeights.medium}>
        {t('profile.employees.item.image.title')}
      </Text.p>
      <Div mb={7}>
        <Dropzone
          dropzoneType="list"
          files={filePreview}
          setFiles={setFilePreview}
          multiple={false}
          fileType="jpg" // deprecated, but not removed in fireball yet
          allowedFiles={{
            'image/*': IMAGE_FILE_EXTENSIONS,
          }}
          maxSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          invalidSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
              defaultValue: 'Your file exceeds the limit of {{ maxFileSize }} ',
            }
          )}
          dropzoneListUploadInfo={t(
            'component.dropzone.chooseOrDragFiles',
            'Choose files or drag them here to upload'
          )}
          invalidTypeErrorMessage={t(
            'component.dropzone.invalidFileTypeError',
            'Whoops, we cannot upload that file type, try another file.'
          )}
          invalidTotalSizeErrorMessage={t(
            'component.dropzone.invalidTotalFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
              defaultValue:
                'The total size of the files you are trying to upload exceeds the limit of {{maxFileSize}}MB.',
            }
          )}
          invalidMaxFilesErrorMessage={t('component.dropzone.maxFilesError', {
            maxFiles: FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
          })}
          dropzoneListChangeImageButton={t(
            'component.dropzone.changeProfileImage',
            'Change profile image'
          )}
          testId="employee-photo"
        />
      </Div>
      <Text.p pb={6} fontWeight={themeContext.fontWeights.medium}>
        {t('profile.employees.item.previewInfo', { name })}
      </Text.p>
      <Card
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        width="270px"
        marginBottom={5}
        paddingTop={32}
      >
        <Div
          marginTop="-50px"
          padding="5px"
          borderRadius="50%"
          mb={5}
          bg="black.black0"
        >
          <Avatar
            image={filePreview?.previewUrl || undefined}
            initials={getInitials(`${name} ${surname}`)}
            size="l"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            backgroundPosition="center"
            data-testid="employee-photo-preview"
          />
        </Div>
        <Text.p
          fontSize={3}
          margin={0}
          textAlign="center"
          color="primary.darkest"
        >
          {`${name} ${surname}`}
        </Text.p>
        <Text.p
          data-testid="employee-position-preview"
          fontSize={2}
          color="primary.darkest"
          margin={0}
          textAlign="center"
          opacity={0.7}
        >
          {profession}
        </Text.p>
        <Div
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="16px"
          style={{ gap: '8px' }}
        >
          <IconRing>
            <FireIcon
              iconName={IconPickerName.Email}
              width={24}
              height={24}
              fill="primary.darkest"
            />
          </IconRing>
          <IconRing>
            <FireIcon
              iconName={IconPickerName.Phone1}
              width={24}
              height={24}
              fill="primary.darkest"
            />
          </IconRing>
        </Div>
      </Card>
    </Modal>
  );
};

export default ProfileEmployeesEmployeeModal;
