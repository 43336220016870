import gql from 'graphql-tag';

const SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT = gql`
  fragment SimpleAgreementContractBasicFragment on SimpleAgreementContract {
    id
    type
    regTime
    statusEnum
    description
    consumerEmail
    consumerPhone
    businessEmail
    businessPhone
    signedUserTime
    signedCompanyTime
    priceType
    price
    startDate
    endDate
    statusFlags {
      initialized
      acceptedConsumer
      acceptedBusiness
      rejectedConsumer
      rejectedBusiness
      invalidated
    }
  }
`;

export default SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT;
