import { AuthIsLoggedInDocument } from '@internals/business-graphql/src/graphql/generated/types';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { log } from '../log';
import type { ErrorVariant } from '../login/types';
import type { GetState, SetState } from '../types';

export type HandleCheckIsLoggedInErrorHandlers = {
  onGenericError: (error?: ErrorVariant) => void;
};

export const handleCheckIsLoggedIn = async (
  setState: SetState,
  getState: GetState,
  errorHandlers?: HandleCheckIsLoggedInErrorHandlers
) => {
  const { user, authHandlers, loadUser } = getState();

  try {
    const response = await apolloClient.query({
      query: AuthIsLoggedInDocument,
    });

    const { data } = response || {};

    const isLoggedIn = data?.loggedInStatus?.loggedIn ?? false;

    if (isLoggedIn) {
      // If they are logged in, we need to load their user data
      if (!user) {
        await loadUser();
      } else {
        setState({ status: 'authenticated' });
      }
      return;
    }

    if (user) {
      log('User is no longer logged in');
      if (authHandlers?.onLogout) {
        await authHandlers.onLogout('handleCheckIsLoggedIn1');
      }
    }
    // If they are not logged in, we need to make sure user is not set
    setState({ status: 'unauthenticated', user: null });
  } catch (error) {
    bugsnagClient.notify(error);

    if (user) {
      log('User is no longer logged in');
      if (authHandlers?.onLogout) {
        await authHandlers.onLogout('handleCheckIsLoggedIn2');
      }
    }

    // If they are not logged in, we need to make sure user is not set
    setState({ status: 'unauthenticated', user: null });

    errorHandlers?.onGenericError();
  }
};
