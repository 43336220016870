import { GQLOpeningHours } from '../interfaces/graphql/OpeningHours.interface';

export const DAY_KEYS: (keyof GQLOpeningHours)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];
