import { FC } from 'react';

import { Div, Spinner } from '@schibsted-smb/fireball';

interface SettingsLoadingProps {
  specificTestId?: string;
  height?: string;
}

const SettingsLoading: FC<SettingsLoadingProps> = ({
  specificTestId = 'content-loader',
  height = '420px',
}) => (
  <Div
    height={height}
    display="flex"
    alignItems="center"
    justifyContent="center"
    data-testid={specificTestId}
  >
    <Spinner size={6} />
  </Div>
);

export default SettingsLoading;
