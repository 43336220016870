import { UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload } from '../generated/generated';
import { UpdateBusinessEmployeeMutationPayload } from '../mutation/UpdateBusinessEmployee/UpdateBusinessEmployeeMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class UpdateBusinessEmployeeResponseError extends GQLResponseErrorBase<
  UpdateBusinessEmployeeMutationPayload['updateBusinessEmployee'],
  UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'UpdateBusinessEmployeeInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'employeeId', 'danger');
        this.mapDataToError(data, 'position', 'danger');
        this.mapDataToError(data, 'firstName', 'danger');
        this.mapDataToError(data, 'lastName', 'danger');
        this.mapDataToError(data, 'phoneNr', 'danger');
        this.mapDataToError(data, 'email', 'danger');
        this.mapDataToError(data, 'image', 'danger');
        break;
      case 'UpdateBusinessEmployeeNoAccessError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
