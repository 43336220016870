import { FC, useCallback } from 'react';

import { FeatureFlag } from '@components/elements/FeatureFlag';
import { SimpleContractDetails } from '@components/layout/CustomerService/Contract/SimpleContractDetails';
import { useUserBusinessId } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import { useRejectSimpleAgreementContractMutation } from '@internals/business-shared/src/hooks/mutation/useRejectSimpleAgreementContractMutation';
import { useSignSimpleAgreementContractMutation } from '@internals/business-shared/src/hooks/mutation/useSignSimpleAgreementContractMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { isRejectSimpleAgreementContractMutationSuccessResponse } from '@internals/business-shared/src/utils/mutation/SimpleAgreementContract/RejectSimpleAgreementContractMutation';
import { isSignSimpleAgreementContractMutationSuccessResponse } from '@internals/business-shared/src/utils/mutation/SimpleAgreementContract/SignSimpleAgreementContractMutation';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import { Heading, Button, Text, Modal } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { Trans, useTranslation } from 'react-i18next';

type SimpleContractModalProps = {
  contract: SimpleContract;
  onClose: VoidFunction;
  onEdit: VoidFunction;
};

export const SimpleContractSuggestionModal: FC<SimpleContractModalProps> = ({
  contract,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const businessId = useUserBusinessId();
  const [rejectSimpleAgreementContract, { loading: rejectLoading }] =
    useRejectSimpleAgreementContractMutation();
  const [signSimpleAgreementContract, { loading: signLoading }] =
    useSignSimpleAgreementContractMutation();
  const isLoading = rejectLoading || signLoading;
  const contractRegDate = dateFromTimestamp(contract.regTime, 'ddd DD.MM.YYYY');

  const rejectAgreement = useCallback(async () => {
    const result = await rejectSimpleAgreementContract({
      agreementId: contract.id,
    });

    if (
      !isRejectSimpleAgreementContractMutationSuccessResponse(
        result?.data?.rejectSimpleAgreementContract
      )
    ) {
      ToastMessage(t('contract.item.rejectFailed'), 'danger');
      bugsnagClient.notify(
        'Reject simple agreement contract failed due to mutation unknown response type'
      );
      return;
    }
    track(ANEventSpace(ANEvent.Rejected, ANObject.Agreement, ANPage.Customers));
    onClose();
  }, [contract.id, onClose, rejectSimpleAgreementContract, t, track]);

  const acceptAgreement = useCallback(async () => {
    const result = await signSimpleAgreementContract({
      agreementId: contract.id,
    });

    if (
      !isSignSimpleAgreementContractMutationSuccessResponse(
        result?.data?.signSimpleAgreementContract
      )
    ) {
      ToastMessage(t('contract.item.signFailed'), 'danger');
      bugsnagClient.notify(
        'Sign simple agreement contract failed due to mutation unknown response type'
      );
      return;
    }

    track(ANEventSpace(ANEvent.Signed, ANObject.Agreement, ANPage.Customers), {
      bid: businessId,
    });
    onClose();
  }, [businessId, contract.id, onClose, signSimpleAgreementContract, t, track]);

  const onModalClose = useCallback(() => {
    if (isLoading) return;
    onClose();
  }, [isLoading, onClose]);

  return (
    <Modal
      isOpen
      onClose={onModalClose}
      isClosable
      size="custom"
      maxWidth="500px"
      footer={
        <>
          <Button
            onClick={rejectAgreement}
            type="button"
            variant="tertiary"
            isLoading={rejectLoading}
            disabled={isLoading}
            size="tiny"
            data-testid="reject-agreement-btn"
            flexGrow={1}
          >
            {t('contract.item.simpleContract.suggestion.rejectAgreementButton')}
          </Button>
          <FeatureFlag name={FeatureFlags.BizContractNew}>
            <Button
              variant="primary"
              size="tiny"
              type="button"
              onClick={onEdit}
              flexGrow={1}
              mx={1}
              disabled={isLoading}
            >
              {t('contract.item.simpleContract.suggestion.editAgreementButton')}
            </Button>
          </FeatureFlag>
          <Button
            onClick={acceptAgreement}
            type="button"
            variant="primary"
            isLoading={signLoading}
            disabled={isLoading}
            size="tiny"
            data-testid="accept-agreement-btn"
            flexGrow={1}
          >
            {t('contract.item.simpleContract.suggestion.acceptAgreementButton')}
          </Button>
        </>
      }
      contentProps={{ px: '45px', mt: 5 }}
      footerProps={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        minHeight: '60px',
        mt: 6,
        px: '45px',
        mb: 5,
      }}
      headerProps={{ marginBottom: 0 }}
      testId="simple-contract-suggestion"
    >
      <Heading.h2 mb={7} textAlign="center">
        {t('contract.item.simpleContract.proposedFrom')}{' '}
        {contract.user.firstName} {contract.user.lastName}
      </Heading.h2>
      <SimpleContractDetails contract={contract} />
      <Text.p fontSize={1} textAlign="center">
        <Trans
          i18nKey="contract.item.simpleContract.agreementCreated"
          values={{ date: contractRegDate }}
          components={{ bold: <b /> }}
        />
      </Text.p>
    </Modal>
  );
};
