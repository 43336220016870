export const INDUSTRIES_WITH_INSPECTION = [
  '19',
  '20',
  '22',
  '23',
  '24',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '33',
  '34',
  '35',
  '37',
  '41',
];

export const INDUSTRIES_WITHOUT_BOLIGMAPPA = [
  '2', // car
  '25', // transport
  '32', // cleaning
];
