import { FC, useCallback } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { CreateSimpleContractModal } from '@components/elements/CustomerServiceContract/CreateSimpleContractModal';
import { ChatJobAction } from '@components/layout/Messages/ChatJobInfoAction';
import useAnalytics from '@hooks/useAnalytics';
import useSetSearchParams from '@hooks/useSetSearchParams';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { CHAT_JOB_QUERY_job_Job } from '@internals/business-shared/src/utils/generated/generated';
import { SimpleAgreementContract } from '@root/../../packages/business-shared/src/utils/query/ChatJob/ChatJobQuery';
import { Params } from '@router/paths';
import { useTranslation } from 'react-i18next';

interface ChatJobInfoContractProps {
  job: CHAT_JOB_QUERY_job_Job;
  simpleAgreement?: SimpleAgreementContract;
}

const ChatJobInfoContract: FC<ChatJobInfoContractProps> = ({
  job,
  simpleAgreement,
}) => {
  const [
    createContractModalVisible,
    openCreateContractModal,
    hideCreateContractModal,
  ] = useBoolean();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [setParam] = useSetSearchParams();
  const isJobOwnerDeleted = job?.owner?.isDeleted;
  const newContractEnabled = useFlagEnabled(FeatureFlags.BizContractNew);

  const onCreateContractClick = useCallback(() => {
    openCreateContractModal();
    track(ANEventSpace(ANEvent.Opened, ANObject.Agreement, ANPage.Customers), {
      isNewContract: true,
    });
  }, [openCreateContractModal, track]);

  const onOpenContractClick = useCallback(() => {
    if (!simpleAgreement?.id) {
      return;
    }
    setParam(Params.Contract, simpleAgreement.id);
    track(ANEventSpace(ANEvent.Opened, ANObject.Agreement, ANPage.Customers), {
      isNewContract: false,
    });
  }, [setParam, track, simpleAgreement?.id]);

  if (!newContractEnabled) {
    return null;
  }

  if (simpleAgreement?.id) {
    return (
      <ChatJobAction
        text={t('chat.action.openContract')}
        icon={IconPickerName.Contract}
        type="button"
        buttonProps={{
          onClick: onOpenContractClick,
        }}
      />
    );
  }

  return (
    <>
      <ChatJobAction
        text={t('chat.action.createContract')}
        icon={IconPickerName.Contract}
        type="button"
        buttonProps={{
          onClick: onCreateContractClick,
          disabled: isJobOwnerDeleted,
        }}
      />
      <CreateSimpleContractModal
        show={createContractModalVisible}
        onClose={hideCreateContractModal}
        jobId={job.id}
      />
    </>
  );
};

export default ChatJobInfoContract;
