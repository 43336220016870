import * as React from 'react';

import { useMutation } from '@apollo/client';
import CardHeader from '@components/base/CardHeader';
import { UserContext } from '@contexts/UserContext';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  TicketType,
  TicketEntryType,
  TicketRelConst,
  TicketRelType,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  CREATE_TICKET_MUTATION,
  CreateTicketMutationPayload,
  CreateTicketMutationVariables,
} from '@internals/business-shared/src/utils/mutation/CreateTicket/CreateTicketMutation';
import useAnalytics from '@root/src/hooks/useAnalytics';
import { Modal, Div, Text, Button, Spinner } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import JobReportRadioGroup from './JobReportRadioGroup';
import JobReportStatus from './JobReportStatus';

export interface JobReportViewProps {
  jobId: string | undefined;
  onClose: () => void;
}

export const JobReportView: React.FC<
  React.PropsWithChildren<JobReportViewProps>
> = ({ jobId, onClose }: JobReportViewProps) => {
  const [reportReason, setReportReason] = React.useState('');
  const [otherReason, setOtherReason] = React.useState('');
  const themeContext = useTheme();
  const user = React.useContext(UserContext);
  const { track } = useAnalytics();
  const [createTicket, { loading, data }] = useMutation<
    CreateTicketMutationPayload,
    CreateTicketMutationVariables
  >(CREATE_TICKET_MUTATION);
  const { t } = useTranslation();
  const handleRadioClick = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value !== reportReason) {
      setReportReason(e.target.value);
    }
  };

  const onOtherReasonChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setOtherReason(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (reportReason) {
      await createTicket({
        variables: {
          type: TicketType.COMPLAINT,
          title: t('job.item.report.hasBeenReported.title', {
            jobId: jobId ?? '',
          }),
          relations: [
            {
              relId: jobId ?? '',
              relConst: TicketRelConst.JOB,
              relType: TicketRelType.ABOUT,
            },
            {
              relId: user?.operatingAs?.id ?? '',
              relConst: TicketRelConst.MASTER_ID,
              relType: TicketRelType.SENDER,
            },
            {
              relId: user?.id ?? '',
              relConst: TicketRelConst.SYSTEM_USER_MASTER_ID,
              relType: TicketRelType.SENDER,
            },
          ],
          entryType: TicketEntryType.WEB,
          content: t('job.item.report.hasBeenReported.description', {
            userId: user?.operatingAs?.id,
            jobId: jobId ?? '',
            reportReason,
            otherReason,
          }),
        },
      }).then(() => {
        track(ANEventSpace(ANEvent.Sent, ANObject.Report, ANPage.JobCard), {
          reason: reportReason,
        });
      });
    }
  };

  return (
    <Modal
      isOpen
      isClosable
      isSmaller
      onClose={onClose}
      size="custom"
      maxWidth="550px"
      headerProps={{
        m: 0,
        px: 6,
        pb: 5,
      }}
      contentProps={{
        p: 0,
      }}
      header={
        !loading &&
        !data && (
          <CardHeader
            bg="black.black0"
            px="0"
            pt="0"
            pb="0"
            borderBottom="none"
          >
            <Text.span
              fontSize={3}
              mb={0}
              fontWeight={600}
              color="black.black9"
            >
              {t('job.item.report.cardHeader')}
            </Text.span>
          </CardHeader>
        )
      }
    >
      {loading && (
        <Div minHeight="208px" display="flex" alignItems="center">
          <Spinner />
        </Div>
      )}
      {!loading && data && (
        <JobReportStatus
          icon={IconPickerName.CheckmarkCircled}
          iconColor={themeContext.colors.green.base}
          text={t('general.label.sent')}
        />
      )}
      {!loading && !data && (
        <form onSubmit={handleSubmit}>
          <Div
            px={6}
            pt={5}
            borderTop={`1px solid ${themeContext.colors.black.black3}`}
          >
            <JobReportRadioGroup
              reportReason={reportReason}
              otherReason={otherReason}
              handleRadioClick={handleRadioClick}
              onOtherReasonChange={onOtherReasonChange}
            />
            <Button
              type="submit"
              mt={4}
              mb={2}
              mx="auto"
              data-testid="send-job-report-button"
            >
              {t('job.item.report.saveButton')}
            </Button>
            <Button
              variant="linkSecondary"
              mb={4}
              mx="auto"
              border="none"
              onClick={onClose}
            >
              {t('general.label.cancel')}
            </Button>
          </Div>
        </form>
      )}
    </Modal>
  );
};

export default JobReportView;
