import { FC } from 'react';

import { Div, Drawer, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { JobFeedbackForm } from '../../layout/Job/JobRejection/jobRejectionForm';

export interface JobFeedbackModalProps {
  onClose: VoidFunction;
  isOpen: boolean;
  jobId: string;
}

export const JobFeedbackModal: FC<JobFeedbackModalProps> = ({
  onClose,
  isOpen,
  jobId,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position="bottom"
      withCloseButton={false}
      modalOverlayStyles={{ height: '100%' }}
      modalContentStyles={{
        borderTopLeftRadius: '20px',
        borderTopRightRadius: '20px',
      }}
    >
      <Div
        display="flex"
        flexDirection="column"
        alignItems="center"
        py={2}
        mt={6}
        mb={6}
      >
        <Div
          textAlign="center"
          width="100%"
          borderBottom={`2px solid ${themeContext.colors.gray.light}`}
        >
          <Heading.h2 mb={2}>{t('job.item.reject.feedback.title')}</Heading.h2>
          <Heading.h5 mb={5} fontWeight={400}>
            {t('job.item.reject.feedback.subtitle')}
          </Heading.h5>
        </Div>
        <JobFeedbackForm jobId={jobId} onClose={() => onClose()} />
      </Div>
    </Drawer>
  );
};
