import { useMemo } from 'react';

import {
  COUNTY_PARAM_KEY,
  DISTRICT_PARAM_KEY,
  INDUSTRY_PARAM_KEY,
  MUNICIPALITY_PARAM_KEY,
  SEARCH_PARAM_KEY,
  SIZE_PARAM_KEY,
  WORK_SUBSET_PARAM_KEY,
  WORK_TYPE_PARAM_KEY,
  WORK_TYPE_SUBSET_GROUP_PARAM_KEY,
} from '@hooks/useJobListSearchParams';
import { AvailableFilters } from '@internals/business-shared/src/components/SavedFilters/types';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import { getUrlSearchQueryParams } from '@utils/url';
import { useLocation, useParams } from 'react-router-dom';

const filterNameToUrlParamMap: Record<keyof AvailableFilters, string> = {
  query: SEARCH_PARAM_KEY,
  sizes: SIZE_PARAM_KEY,
  municipalityCode: MUNICIPALITY_PARAM_KEY,
  districtId: DISTRICT_PARAM_KEY,
  countyId: COUNTY_PARAM_KEY,
  worktypeIds: WORK_TYPE_PARAM_KEY,
  worktypeSubsets: WORK_SUBSET_PARAM_KEY,
  industryIds: INDUSTRY_PARAM_KEY,
  worktypeSubsetGroups: WORK_TYPE_SUBSET_GROUP_PARAM_KEY,
};

export const useJobListAllFilters = (): AvailableFilters => {
  const { search } = useLocation();
  return useMemo(() => {
    const querySearchParams = getUrlSearchQueryParams(search);
    return {
      ...Object.entries(filterNameToUrlParamMap).reduce(
        (acc, [filterName, urlParam]) => {
          if (filterName === 'query')
            return {
              ...acc,
              [filterName]: querySearchParams[urlParam]?.[0] ?? '',
            };
          return {
            ...acc,
            [filterName]: querySearchParams[urlParam] ?? [],
          };
        },
        {} as AvailableFilters
      ),
    };
  }, [search]);
};

export const useJobListActiveId = (): JobListId => {
  const params = useParams<{ listId?: string }>();
  return useMemo(
    () => (params.listId as JobListId) ?? JobListId.Open,
    [params.listId]
  );
};
