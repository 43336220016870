import * as React from 'react';

import { usePageAnalytics } from '@hooks/useAnalytics';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';

import ChatMessageForm from './ChatMessageForm';
import ChatMessagesList from './ChatMessagesList';
import { ChatConversationContainer } from './styled';

export interface ChatConversationProps {
  jobId: string;
  chatId: string;
  customerName: string;
  chatDisabled?: boolean;
  contractId?: string;
}

const ChatConversation: React.FC<ChatConversationProps> = ({
  jobId,
  chatId,
  customerName,
  chatDisabled,
  contractId,
}) => {
  usePageAnalytics(ANSpace(ANObject.Conversation, ANPage.Messages));

  return (
    <ChatConversationContainer>
      <ChatMessagesList
        name={customerName}
        jobId={jobId}
        chatId={chatId}
        contractId={contractId}
      />
      <ChatMessageForm isDisabled={chatDisabled} chatId={chatId} />
    </ChatConversationContainer>
  );
};

export default ChatConversation;
