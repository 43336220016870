import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import { useMapJobListSearchParamsToQueryVariables } from '@hooks/useJobListSearchParams';
import { filterOutEmptyValueKeys } from '@internals/business-shared/src/utils/objectUtils';
import {
  isJobsTotalCountQueryJobListPayload,
  JOBS_TOTAL_COUNT_QUERY,
  JobsTotalCount,
  JobsTotalCountQueryPayload,
  JobsTotalCountQueryVariables,
} from '@internals/business-shared/src/utils/query/JobsTotalCount/JobsTotalCountQuery';

interface JobsTotalCountQueryResult extends QueryResult {
  data: JobsTotalCount | null;
  previousData: JobsTotalCount | null;
}

export const useJobsTotalCountQuery = (
  queryOptions?: QueryHookOptions<JobsTotalCountQueryPayload>
): JobsTotalCountQueryResult => {
  const {
    listId,
    sizes,
    municipalityCode,
    districtId,
    folderId,
    worktypeIds,
    worktypeSubsets,
    worktypeSubsetGroups,
    query,
    countyId,
    industryIds,
  } = useMapJobListSearchParamsToQueryVariables();
  const { variables, ...options } = queryOptions || {};
  const { data, previousData, ...rest } = useQuery<
    JobsTotalCountQueryPayload,
    JobsTotalCountQueryVariables
  >(JOBS_TOTAL_COUNT_QUERY, {
    variables: filterOutEmptyValueKeys({
      listId,
      sizes,
      municipalityCode,
      districtId,
      folderId,
      worktypeIds,
      worktypeSubsets,
      worktypeSubsetGroups,
      query,
      countyId,
      industryIds,
      ...variables,
      count: 0,
    }),
    fetchPolicy: 'cache-first',
    ...options,
  });

  const totalCount =
    data && isJobsTotalCountQueryJobListPayload(data.jobList)
      ? data.jobList.jobConnection.pageInfo.totalCount
      : null;

  const previousTotalCount =
    previousData && isJobsTotalCountQueryJobListPayload(previousData.jobList)
      ? previousData.jobList.jobConnection.pageInfo.totalCount
      : null;

  return {
    data: totalCount,
    previousData: previousTotalCount,
    ...rest,
  };
};

export default useJobsTotalCountQuery;
