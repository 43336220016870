import * as React from 'react';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { isProd, isStaging } from '@utils/envChecks';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import isLocalhost from '@utils/isLocalhost';
import { getProductId } from '@utils/product';

import packageJson from '../../package.json';

export const bugsnagClient = Bugsnag.start({
  apiKey: getEnvValue(ENV_KEY_NAME.BUGSNAG),
  appVersion: packageJson?.version ?? 'missing',
  plugins: [new BugsnagPluginReact()],
  releaseStage: getEnvValue(ENV_KEY_NAME.ENVIRONMENT),
  enabledReleaseStages: isLocalhost
    ? []
    : ['production', 'staging', 'development'],
  autoTrackSessions: isStaging || isProd,
  collectUserIp: false,
  metadata: {
    app: {
      name: getProductId(), // not necessary now, but in the future we might want to use this to differentiate between different products
    },
  },
});

// eslint-disable-next-line
// @ts-ignore
export const ErrorBoundary = bugsnagClient
  .getPlugin('react')
  ?.createErrorBoundary(React as any);
