import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPicker from '@internals/business-shared/src/components/Icon/IconPicker';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Card, Column, Container, Row } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { ZebraTable, ZebraRow, FloatingTableHeader } from './styled/ZebraTable';

export const Icons: React.FC<React.PropsWithChildren<unknown>> = () => {
  const [fillColor, setFillColor] = React.useState('#5a72DD');
  const [bgColor, setBgColor] = React.useState('#ffffff');
  const themeContext = useTheme();

  return (
    <Container>
      <Row mt={[4, 4, 6]}>
        <Column width={1}>
          <Card boxShadow="xl" p="45px">
            <ZebraTable>
              <thead>
                <tr>
                  <FloatingTableHeader>
                    Name |
                    <input
                      type="color"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFillColor(e.target.value)
                      }
                      value={fillColor}
                    />
                    |
                    <input
                      type="color"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setBgColor(e.target.value)
                      }
                      value={bgColor}
                    />
                  </FloatingTableHeader>
                  <FloatingTableHeader>N Flat</FloatingTableHeader>
                  <FloatingTableHeader>N Color</FloatingTableHeader>
                  <FloatingTableHeader>N Gray</FloatingTableHeader>
                  <FloatingTableHeader>O Flat</FloatingTableHeader>
                  <FloatingTableHeader>O Color</FloatingTableHeader>
                  <FloatingTableHeader>O Gray</FloatingTableHeader>
                </tr>
              </thead>
              <tbody>
                {Object.values(IconPickerName).map((IconName) => {
                  return (
                    <ZebraRow key={IconName}>
                      <td>{IconName}</td>
                      <td>
                        <div
                          style={{
                            width: '32px',
                            height: '32px',
                            backgroundColor: bgColor,
                          }}
                        >
                          <FireIcon fill={fillColor} iconName={IconName} />
                        </div>
                      </td>
                      <td>
                        <Div width="32px" height="32px">
                          <FireIcon
                            fill={themeContext.colors.primary.base}
                            iconName={IconName}
                          />
                        </Div>
                      </td>
                      <td>
                        <Div width="32px" height="32px">
                          <FireIcon
                            fill={themeContext.colors.gray.base}
                            iconName={IconName}
                          />
                        </Div>
                      </td>
                      <td>
                        <Div width="32px" height="32px">
                          <IconPicker
                            fill={fillColor}
                            variant="flat"
                            iconName={IconName}
                          />
                        </Div>
                      </td>
                      <td>
                        <Div width="32px" height="32px">
                          <IconPicker variant="color" iconName={IconName} />
                        </Div>
                      </td>
                      <td>
                        <Div width="32px" height="32px">
                          <IconPicker variant="gray" iconName={IconName} />
                        </Div>
                      </td>
                    </ZebraRow>
                  );
                })}
              </tbody>
            </ZebraTable>
          </Card>
        </Column>
      </Row>
    </Container>
  );
};
