import gql from 'graphql-tag';

import { BADGE_NUMBERS_QUERY as BADGE_NUMBERS_QUERY_Result } from '../../generated/generated';

export type BadgeNumbersQueryPayload = BADGE_NUMBERS_QUERY_Result;
export const BADGE_NUMBERS_QUERY = gql`
  query BADGE_NUMBERS_QUERY {
    badgeNumbers {
      threads
      latestActivityAt
      messages
    }
  }
`;
