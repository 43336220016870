import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  CREATE_JOBS_SAVED_FILTER_MUTATION,
  CreateJobsSavedFilterMutationPayload,
  CreateJobsSavedFilterMutationVariables,
} from '../../utils/mutation/CreateJobsSavedFilter/CreateJobsSavedFilterMutation';

type UseCreateJobsSavedFilterMutationAction = (
  input: CreateJobsSavedFilterMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    CreateJobsSavedFilterMutationPayload,
    CreateJobsSavedFilterMutationVariables
  >
) => Promise<FetchResult<CreateJobsSavedFilterMutationPayload>>;

export const useCreateJobsSavedFilterMutation = (): [
  UseCreateJobsSavedFilterMutationAction,
  MutationResult<CreateJobsSavedFilterMutationPayload>
] => {
  const [createJobsSavedFilter, ...rest] = useMutation<
    CreateJobsSavedFilterMutationPayload,
    CreateJobsSavedFilterMutationVariables
  >(CREATE_JOBS_SAVED_FILTER_MUTATION);

  const mutationFunction: UseCreateJobsSavedFilterMutationAction = useCallback(
    (input, mutationOptions) =>
      createJobsSavedFilter({
        variables: {
          input: {
            ...input,
          },
        },
        ...mutationOptions,
      }),
    [createJobsSavedFilter]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
