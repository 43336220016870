import { FC, useEffect, useState } from 'react';

import { useMessageTemplates } from '@internals/business-shared/src/hooks/useMessageTemplates';
import { Drawer } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { MessageTemplateProps } from '../Modal';
import { MobileTemplateForm as TemplateForm } from './MobileTemplateForm';
import { MobileTemplateList as TemplateList } from './MobileTemplateList';
import { MobileTemplateView as TemplateView } from './MobileTemplateView';

type View = 'TEMPLATE_LIST' | 'TEMPLATE_VIEW' | 'TEMPLATE_EDIT';

export const MobileMessageTemplate: FC<MessageTemplateProps> = ({
  onTemplateUsed,
  isOpen,
  onClose,
}) => {
  const {
    searchQuery,
    onSearchQueryChange,
    templateList,
    filteredTemplates,
    activeTemplateIndex,
    activeTemplate,
    templateListError,
    onTemplateSelect,
    onTemplateCreate,
    onTemplateChange,
    onTemplateDelete,
    onTemplateSave,
    ...loadingProps
  } = useMessageTemplates();
  const { t } = useTranslation();
  const [view, setView] = useState<View>('TEMPLATE_LIST');

  const navigateToListView = () => setView('TEMPLATE_LIST');
  const navigateToEditView = () => setView('TEMPLATE_EDIT');
  const navigateToTemplateView = () => setView('TEMPLATE_VIEW');

  const resetTemplateSelection = () => {
    onTemplateSelect(undefined);
  };

  const handleOnNewTemplate = () => {
    onTemplateCreate(t('chat.item.template.form.title.default'));
    navigateToEditView();
  };

  const handleOnTemplateSelect = (templateIndex: number) => {
    onTemplateSelect(templateIndex);
    navigateToTemplateView();
  };

  const handleOnPasteIn = (templateText: string) => {
    onTemplateUsed(templateText);
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      navigateToListView();
    }
  }, [isOpen]);

  const getContent = () => {
    switch (view) {
      case 'TEMPLATE_EDIT': {
        return (
          <TemplateForm
            onBackClick={navigateToTemplateView}
            template={activeTemplate}
            onSave={(template) =>
              onTemplateSave(template, navigateToTemplateView)
            }
            onDelete={(template) =>
              onTemplateDelete(template, navigateToListView)
            }
            onChange={onTemplateChange}
            {...loadingProps}
          />
        );
      }
      case 'TEMPLATE_VIEW': {
        return (
          <TemplateView
            template={activeTemplate}
            onBackClick={() => {
              navigateToListView();
              resetTemplateSelection();
            }}
            onEditClick={navigateToEditView}
            onPasteInClick={handleOnPasteIn}
          />
        );
      }
      case 'TEMPLATE_LIST':
      default: {
        return (
          <TemplateList
            onNewTemplate={handleOnNewTemplate}
            onTemplateSelect={handleOnTemplateSelect}
            items={filteredTemplates}
            isEmpty={!templateList.length}
            isError={!!templateListError}
            searchQuery={searchQuery}
            onSearchQueryChange={onSearchQueryChange}
            loading={loadingProps.loadingList}
          />
        );
      }
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position="bottom"
      withCloseButton={false}
    >
      {getContent()}
    </Drawer>
  );
};
