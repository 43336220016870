import { FC, useEffect } from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Column,
  Container,
  Div,
  Heading,
  Row,
  Spinner,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { useBuySubscriptionModalContext } from '../ModalContext';

const CUSTOM_GREEN_COLOR = '#9FE3C8';
const REDIRECTION_DELAY_MS = 2000;

export const RedirectionState: FC = () => {
  const [isSuccess, , setSuccessFalse] = useBoolean(true);
  const { handlePayment } = useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();

  useEffect(() => {
    const successTimeout = setTimeout(setSuccessFalse, REDIRECTION_DELAY_MS);
    return () => clearTimeout(successTimeout);
  }, [setSuccessFalse]);

  useEffect(() => {
    if (!isSuccess) {
      handlePayment();
    }
  }, [isSuccess, handlePayment]);

  return (
    <Container
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight={!isTabletOrMobile && 425}
      pt={8}
    >
      <Row px={!isTabletOrMobile && 7}>
        <Column>
          <Div pb={6}>
            {isSuccess ? (
              <FireIcon
                iconName={IconPickerName.SpecialCheck}
                width={60}
                height={60}
                fill={CUSTOM_GREEN_COLOR}
              />
            ) : (
              <CenteredSpinnerContainer>
                <Spinner size={6} />
              </CenteredSpinnerContainer>
            )}
          </Div>
          <Heading.h1 pb={3} color="black.black0">
            {isSuccess
              ? t('job.modal.subscription.redirection.title.success')
              : t('job.modal.subscription.redirection.title.redirecting')}
          </Heading.h1>
          <Text.p textAlign="center" color="black.black0" pb={5}>
            {t('job.modal.subscription.redirection.description')}
          </Text.p>
        </Column>
      </Row>
    </Container>
  );
};
