import { FC, PropsWithChildren } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div, Heading, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

const WIDTH = { narrow: '50%', wide: '80%' };

interface StyleProps {
  width?: string;
}

interface HeaderProps {
  description: JSX.Element;
  title: string;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({
  description,
  title,
}) => {
  const { modalState } = useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      width: isTabletOrMobile ? WIDTH.narrow : WIDTH.wide,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      width: WIDTH.narrow,
    },
    [ModalState.SUMMARY]: {
      width: WIDTH.wide,
    },
  };

  return (
    <Div textAlign="left">
      <Text.span fontSize={1}>
        {t('job.modal.subscription.packageCard.jobPackage')}
      </Text.span>
      <Heading.h2 fontWeight="bold" uppercase>
        {title}
      </Heading.h2>
      <Text.p {...styleProps[modalState]}>{description}</Text.p>
    </Div>
  );
};
