import getConsumerURL from '@internals/business-shared/config/consumer-url';
import {
  getAccountSwitchURL,
  getMainPageUrl,
} from '@internals/business-shared/src/utils/publicUrls';
import Cookies from 'universal-cookie';

import { apolloClient } from '../../apollo/initApollo';
import { TOKEN_COOKIE_NAME } from '../../utils/cookies';
import { getEnvironment } from '../../utils/getEnvironment';
import { bugsnagClient } from '../../utils/initBugsnag';
import isLocalhost from '../../utils/isLocalhost';
import { getProduct } from '../../utils/product';
import type { AuthHandlers } from './login/types';

const cookies = new Cookies(null);

export const authHandlers: AuthHandlers = {
  onBusinessUser: () => {
    bugsnagClient.leaveBreadcrumb('onBusinessUser triggered', {
      level: 'info',
      category: 'auth',
    });
  },
  onConsumerUser: () => {
    bugsnagClient.leaveBreadcrumb('onConsumerUser triggered', {
      level: 'info',
      category: 'auth',
    });

    window.location.replace(getConsumerURL(getEnvironment(), getProduct().id));
  },
  onMultipleProfiles: () => {
    bugsnagClient.leaveBreadcrumb('onMultipleProfiles triggered', {
      level: 'info',
      category: 'auth',
    });

    window.location.replace(
      getAccountSwitchURL(getEnvironment(), getProduct().id)
    );
  },
  onSuccessFallback: () => {
    bugsnagClient.leaveBreadcrumb('onSuccessFallback triggered', {
      level: 'info',
      category: 'auth',
    });

    throw new Error('onSuccessFallback not implemented');
  },
  onLogout: async () => {
    bugsnagClient.leaveBreadcrumb('onLogout triggered', {
      level: 'info',
      category: 'auth',
    });

    bugsnagClient.setUser(null);
    await apolloClient.clearStore();

    if (isLocalhost) {
      cookies.remove(TOKEN_COOKIE_NAME);
      window.location.replace('/');
      return;
    }

    window.location.replace(getMainPageUrl(getEnvironment(), getProduct().id));
  },
  onTokenReceived: (token: string) => {
    bugsnagClient.leaveBreadcrumb('onTokenReceived triggered', {
      level: 'info',
      category: 'auth',
    });

    if (isLocalhost) cookies.set(TOKEN_COOKIE_NAME, token, { path: '/' });
  },
};
