import { useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  SignSimpleAgreementContractMutationPayload,
  SignSimpleAgreementContractMutationVariables,
  SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION,
} from '../../utils/mutation/SimpleAgreementContract/SignSimpleAgreementContractMutation';
import { SIMPLE_CONTRACT_LIST_QUERY } from '../../utils/query/SimpleContractList/SimpleContractListQuery';

type UseSignSimpleAgreementContractMutationAction = (
  input: SignSimpleAgreementContractMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    SignSimpleAgreementContractMutationPayload,
    SignSimpleAgreementContractMutationVariables
  >
) => Promise<FetchResult<SignSimpleAgreementContractMutationPayload>>;

export const useSignSimpleAgreementContractMutation = (): [
  UseSignSimpleAgreementContractMutationAction,
  MutationResult<SignSimpleAgreementContractMutationPayload>
] => {
  const [signSimpleAgreementContract, ...rest] = useMutation<
    SignSimpleAgreementContractMutationPayload,
    SignSimpleAgreementContractMutationVariables
  >(SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION);

  const mutationFunction: UseSignSimpleAgreementContractMutationAction = (
    input,
    mutationOptions
  ) =>
    signSimpleAgreementContract({
      variables: {
        input: {
          ...input,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [SIMPLE_CONTRACT_LIST_QUERY],
      ...mutationOptions,
    });

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
