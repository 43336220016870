import gql from 'graphql-tag';

import USER_FRAGMENT from '../../fragments/UserFragment';
import { CURRENT_USER_QUERY as CURRENT_USER_QUERY_Result } from '../../generated/generated';

export type CurrentUserQueryPayload = CURRENT_USER_QUERY_Result;

export const CURRENT_USER_QUERY = gql`
  ${USER_FRAGMENT}

  query CURRENT_USER_QUERY {
    me {
      ...UserFragment
      isVerifiedWithSecureId
    }
  }
`;
