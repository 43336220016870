import { useFlagEnabled } from '@components/base/CheckFlag';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { useIsCreditsAvailable } from '@hooks/useIsCreditsAvailable';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { CompanySettings } from '@internals/business-shared/src/utils/query/CompanySettings/CompanySettings';

export enum BoostAlertType {
  MobileAlert = 'mobile-alert',
}

export const showBoostClipsAlert = (companySettings: CompanySettings) => {
  if (!companySettings) {
    return false;
  }

  const { BoostCreditsRemaining, NumAnswersRemaining } = companySettings;
  if (NumAnswersRemaining === null) {
    return false;
  }

  const creditsRemaining = BoostCreditsRemaining + NumAnswersRemaining;
  return creditsRemaining === 0;
};

export const useMobileBoostAlert = () => {
  const isCreditsAvailable = useIsCreditsAvailable();
  const isMobile = useIsMobile();
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );

  return isTestBuyMoreClipsFlagEnabled && isMobile && isCreditsAvailable
    ? BoostAlertType.MobileAlert
    : undefined;
};
