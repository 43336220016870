import gql from 'graphql-tag';

import {
  COMPANY_WANTS_CONTACT_ABOUT_PRODUCTVariables,
  COMPANY_WANTS_CONTACT_ABOUT_PRODUCT as COMPANY_WANTS_CONTACT_ABOUT_PRODUCT_Result,
} from '../../generated/generated';

export type CompanyWantsContactAboutProductVariables =
  COMPANY_WANTS_CONTACT_ABOUT_PRODUCTVariables;
export type CompanyWantsContactAboutProductPayload =
  COMPANY_WANTS_CONTACT_ABOUT_PRODUCT_Result;

export const COMPANY_WANTS_CONTACT_ABOUT_PRODUCT = gql`
  mutation COMPANY_WANTS_CONTACT_ABOUT_PRODUCT(
    $input: CompanyWantsContactAboutProductInput!
  ) {
    companyWantsContactAboutProduct(input: $input)
  }
`;
