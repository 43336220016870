import { t } from '@internals/business-translations/src/i18n';

import { SystemMessageOutput, SystemMessageType } from './index';

export const contractSystemMessage = (
  type: SystemMessageType,
  businessName = '',
  consumerName = ''
): SystemMessageOutput | null => {
  switch (type) {
    case SystemMessageType.AGREEMENT_CREATED_CONSUMER:
      return {
        text: t('contract.item.systemMessage.description.agreementCreated', {
          businessOrConsumer: consumerName,
        }),
        title: t('contract.item.systemMessage.title.agreementCreated', {
          businessOrConsumer: consumerName,
        }),
      };
    case SystemMessageType.AGREEMENT_CREATED_BUSINESS:
      return {
        text: t('contract.item.systemMessage.description.agreementCreated', {
          businessOrConsumer: businessName,
        }),
        title: t('contract.item.systemMessage.title.agreementCreated', {
          businessOrConsumer: businessName,
        }),
      };
    case SystemMessageType.AGREEMENT_EDITED_CONSUMER:
      return {
        text: t('contract.item.systemMessage.description.agreementEdited', {
          businessOrConsumer: consumerName,
        }),
        title: t('contract.item.systemMessage.title.agreementEdited', {
          businessOrConsumer: consumerName,
        }),
      };
    case SystemMessageType.AGREEMENT_EDITED_BUSINESS:
      return {
        text: t('contract.item.systemMessage.description.agreementEdited', {
          businessOrConsumer: businessName,
        }),
        title: t('contract.item.systemMessage.title.agreementEdited', {
          businessOrConsumer: businessName,
        }),
      };
    case SystemMessageType.AGREEMENT_SIGNED_BY_BOTH:
      return {
        text: t(
          'contract.item.systemMessage.description.agreementSignedByBoth'
        ),
        title: t('contract.item.systemMessage.title.agreementSignedByBoth'),
      };
    case SystemMessageType.AGREEMENT_REJECTED_CONSUMER:
      return {
        text: t('contract.item.systemMessage.description.agreementRejected', {
          businessOrConsumer: consumerName,
        }),
        title: t('contract.item.systemMessage.title.agreementRejected', {
          businessOrConsumer: consumerName,
        }),
      };
    case SystemMessageType.AGREEMENT_REJECTED_BUSINESS:
      return {
        text: t('contract.item.systemMessage.description.agreementRejected', {
          businessOrConsumer: businessName,
        }),
        title: t('contract.item.systemMessage.title.agreementRejected', {
          businessOrConsumer: businessName,
        }),
      };
    case SystemMessageType.AGREEMENT_INVALIDATED:
      return {
        text: t('contract.item.systemMessage.description.agreementInvalidated'),
        title: t('contract.item.systemMessage.title.agreementInvalidated'),
      };
    default:
      return null;
  }
};
