import dayjs from 'dayjs';

import {
  JobSubscriptionProduct,
  PaidAddon,
  ProductAddon,
  ProductPackageStatus,
  ProductTimeUnit,
  TrialJobSubscriptionProduct,
} from './productTypes';

export const getProductAddonsMock = (): ProductAddon[] => [
  {
    id: 'spotlight',
    slug: 'spotlight',
    name: 'Spotlight',
    status: ProductPackageStatus.Inactive,
    startDate: dayjs().subtract(1, 'month').unix(),
  },
  {
    id: 'boost',
    slug: 'boost',
    name: 'Boost',
    status: ProductPackageStatus.Inactive,
    startDate: dayjs().subtract(1, 'year').unix(),
  },
  {
    id: 'mittanbud-xl',
    slug: 'mittanbud-xl',
    name: 'Mittanbud XL',
    status: ProductPackageStatus.Active,
    startDate: dayjs().subtract(14, 'day').unix(),
  },
];

export const jobPackageMock: JobSubscriptionProduct = {
  id: 'job-package',
  slug: 'job-package',
  name: 'Job Package',
  status: ProductPackageStatus.Active,
  startDate: dayjs().subtract(1, 'months').unix(),
  expirationDate: dayjs().add(11, 'months').unix(),
  price: {
    currency: 'NOK',
    cost: 1000,
    period: ProductTimeUnit.Month,
  },
  numberOfCredits: 30,
  numberOfFreeCredits: 30,
  renewalDate: dayjs().add(3, 'months').unix(),
};

export const freemiumPackageMock: TrialJobSubscriptionProduct = {
  id: 'freemium',
  slug: 'freemium',
  name: 'Job Package',
  status: ProductPackageStatus.TestPeriod,
  startDate: dayjs().subtract(20, 'days').unix(),
  expirationDate: dayjs().add(10, 'days').unix(),
  price: {
    currency: 'NOK',
    cost: 1000,
    period: ProductTimeUnit.Month,
  },
  numberOfCredits: 30,
  numberOfFreeCredits: 30,
};

export const spotlightMock: PaidAddon = {
  id: 'spotlight',
  slug: 'spotlight',
  name: 'Spotlight',
  status: ProductPackageStatus.Inactive,
  startDate: dayjs().subtract(3, 'months').unix(),
  expirationDate: dayjs().add(10, 'days').unix(),
  price: {
    currency: 'NOK',
    cost: 1000,
    period: ProductTimeUnit.Month,
  },
};

export const mittanbudXLMock: ProductAddon = {
  id: 'mittanbud-xl',
  slug: 'mittanbud-xl',
  name: 'Mittanbud XL',
  status: ProductPackageStatus.Inactive,
  startDate: dayjs().subtract(3, 'months').unix(),
};
