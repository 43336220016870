import { useCallback } from 'react';

import { isSizeType } from '@contexts/JobFiltersContext';
import useDebounce from '@hooks/useDebounce';
import useQuerySearchParams from '@hooks/useQuerySearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { getParams } from '@internals/business-shared/src/universal-links/paths';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import { isUnansweredJobsList } from '@internals/business-shared/src/utils/jobListUtils';
import { isEmptyObject } from '@internals/business-shared/src/utils/objectUtils';
import { JobsListQueryVariables } from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import { ValueOf } from '@internals/business-shared/src/utils/types/utilsTypes';
import { JOB_LIST_DEFAULT_COUNT } from '@root/src/apollo/cache';
import { Params } from '@router/paths';
import { useParams } from 'react-router-dom';

interface JobListSearchParams {
  listId: string;
  page: number;
  search: string;
  sizes: string[];
  districts: string[];
  municipalities: string[];
  counties: string[];
  folders: string[];
  workTypes: string[];
  workSubsets: string[];
  industries: string[];
  workSubsetGroups: string[];
}

interface JobListSearchParamsSetters {
  setPageParam: (value: string) => void;
  setSearchParam: (value: string) => void;
  setSizeParam: (value: string | string[]) => void;
  setDistrictParam: (value: string | string[]) => void;
  setMunicipalityParam: (value: string | string[]) => void;
  setCountyParam: (value: string | string[]) => void;
  setFolderParam: (value: string | string[]) => void;
  setWorkTypeParam: (value: string | string[]) => void;
  setWorkSubsetParam: (value: string | string[]) => void;
  setIndustryParam: (value: string | string[]) => void;
  setWorkSubsetGroupParam: (value: string | string[]) => void;
}

interface UseJobListSearchParamsResult
  extends JobListSearchParams,
    JobListSearchParamsSetters {
  hasSearchParams: boolean;
}

export const PAGE_PARAM_KEY = Params.Page;
export const SEARCH_PARAM_KEY = Params.Search;
export const SIZE_PARAM_KEY = Params.Size;
export const MUNICIPALITY_PARAM_KEY = Params.Municipality;
export const DISTRICT_PARAM_KEY = Params.District;
export const COUNTY_PARAM_KEY = Params.County;
export const FOLDER_PARAM_KEY = Params.Folder;
export const WORK_TYPE_PARAM_KEY = Params.WorkType;
export const WORK_SUBSET_PARAM_KEY = Params.WorkSubset;
export const INDUSTRY_PARAM_KEY = Params.Industry;
export const WORK_TYPE_SUBSET_GROUP_PARAM_KEY = Params.WorkSubsetGroup;

type ParamKey = ValueOf<ReturnType<typeof getParams>>;
type ParamSetter = (value: string | string[]) => void;

interface MapParamsResult {
  stringParam: (key: ParamKey) => [string, ParamSetter];
  arrayParam: (key: ParamKey) => [string[], ParamSetter];
  // special type of param, where default is set to 1 and no other param is reset
  pageParam: (key: ParamKey) => [number, ParamSetter];
}

const useMapParams = (): MapParamsResult => {
  const searchParams = useQuerySearchParams();
  const [setParam] = useSetSearchParams();

  const pageParam = useCallback(
    (key: ParamKey): [number, ParamSetter] => {
      const paramValue = searchParams[key];
      const parsedValue = parseInt(paramValue?.[0], 10);
      const value = !paramValue || Number.isNaN(parsedValue) ? 1 : parsedValue;
      const setter = (v: string) => setParam(key, v);
      return [value, setter];
    },
    [searchParams, setParam]
  );
  const stringParam = useCallback(
    (key: ParamKey): [string, ParamSetter] => {
      const paramValue = searchParams[key];
      const value = paramValue?.[0] ?? '';
      const setter = (v: string) => setParam(key, v, PAGE_PARAM_KEY);
      return [value, setter];
    },
    [searchParams, setParam]
  );
  const arrayParam = useCallback(
    (key: ParamKey): [string[], ParamSetter] => {
      const paramValue = searchParams[key];
      const value: string[] = paramValue || [];
      const setter = (v: string | string[]) => setParam(key, v, PAGE_PARAM_KEY);
      return [value, setter];
    },
    [searchParams, setParam]
  );

  return {
    pageParam,
    stringParam,
    arrayParam,
  };
};

export const useJobListSearchParams = (): UseJobListSearchParamsResult => {
  const params = useParams<{ listId?: string }>();
  const { pageParam, stringParam, arrayParam } = useMapParams();

  const listId = params.listId ?? JobListId.Open;
  const [page, setPageParam] = pageParam(PAGE_PARAM_KEY);
  const [search, setSearchParam] = stringParam(SEARCH_PARAM_KEY);
  const [sizes, setSizeParam] = arrayParam(SIZE_PARAM_KEY);
  const [districts, setDistrictParam] = arrayParam(DISTRICT_PARAM_KEY);
  const [municipalities, setMunicipalityParam] = arrayParam(
    MUNICIPALITY_PARAM_KEY
  );
  const [counties, setCountyParam] = arrayParam(COUNTY_PARAM_KEY);
  const [folders, setFolderParam] = arrayParam(FOLDER_PARAM_KEY);
  const [workTypes, setWorkTypeParam] = arrayParam(WORK_TYPE_PARAM_KEY);
  const [workSubsets, setWorkSubsetParam] = arrayParam(WORK_SUBSET_PARAM_KEY);
  const [industries, setIndustryParam] = arrayParam(INDUSTRY_PARAM_KEY);
  const [workSubsetGroups, setWorkSubsetGroupParam] = arrayParam(
    WORK_TYPE_SUBSET_GROUP_PARAM_KEY
  );

  return {
    hasSearchParams: !isEmptyObject(params),
    listId,
    page,
    search,
    sizes,
    districts,
    municipalities,
    counties,
    folders,
    workTypes,
    workSubsets,
    industries,
    workSubsetGroups,
    setPageParam,
    setSearchParam,
    setSizeParam,
    setDistrictParam,
    setMunicipalityParam,
    setCountyParam,
    setFolderParam,
    setWorkTypeParam,
    setWorkSubsetParam,
    setIndustryParam,
    setWorkSubsetGroupParam,
  };
};

export const useMapJobListSearchParamsToQueryVariables =
  (): JobsListQueryVariables => {
    const {
      listId,
      page,
      search,
      sizes,
      districts,
      municipalities,
      counties,
      folders,
      workTypes,
      workSubsets,
      industries,
      workSubsetGroups,
    } = useJobListSearchParams();
    const debouncedInputValue = useDebounce(search, 500);

    return {
      listId,
      page,
      count: JOB_LIST_DEFAULT_COUNT,
      skipAnsweredOnlyFields: isUnansweredJobsList(listId),
      ...(!!sizes.length && { sizes: sizes.filter(isSizeType) }),
      ...(!!municipalities.length && { municipalityCode: municipalities }),
      ...(!!districts.length && { districtId: districts }),
      ...(!!counties.length && { countyId: counties }),
      ...(!!folders.length && { folderId: folders }),
      ...(!!workTypes.length && { worktypeIds: workTypes }),
      ...(!!workSubsets.length && { worktypeSubsets: workSubsets }),
      ...(!!workSubsetGroups.length && {
        worktypeSubsetGroups: workSubsetGroups,
      }),
      ...(debouncedInputValue.trim() !== '' && { query: debouncedInputValue }),
      ...(!!industries.length && { industryIds: industries }),
    };
  };

export default useJobListSearchParams;
