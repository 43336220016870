import { FC, useState, useCallback, useEffect } from 'react';

import PhoneInput from '@components/base/PhoneInput';
import SearchSelect from '@components/base/Select';
import { FormRow } from '@components/elements/CustomerServiceContract/styled/FormRow';
import { useBoligMappaAuthContext } from '@contexts/BoligMappaAuthProvider';
import useAnalytics from '@hooks/useAnalytics';
import { useBoligmappaCreateAccountMutation } from '@internals/business-shared/src/hooks/mutation/useCreateBoligmappaAccount';
import { useBoligmappaDocumentAndProfessionTypesQuery } from '@internals/business-shared/src/hooks/query/useBoligmappaDocumentAndProfessionTypesQuery';
import { CreateBoligmappaAccountFormShape } from '@internals/business-shared/src/types/FormShape/CreateBoligmappaAccount';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_professionTypes } from '@internals/business-shared/src/utils/generated/generated';
import {
  Alert,
  Button,
  Column,
  Container,
  Div,
  Heading,
  Input,
  Modal,
  Row,
} from '@schibsted-smb/fireball';
import InputType from '@utils/constants/inputTypes';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { Props } from 'react-select';
import { useTheme } from 'styled-components';

import {
  getInitialFormValues,
  getInvalidInputErrorMessage,
} from './registerBoligmappaUtils';
import { Success } from './Success';
import { TermsAndConditions } from './TermsAndConditions';
import { UseRegisterBoligmappaFormik } from './useRegisterBoligMappaFormik';

interface RegisterBoligmappaModalProps {
  show: boolean;
  onClose: () => void;
}

type BoligmappaProfessionType =
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_boligmappaDocumentAndProfessionTypes_professionTypes;

enum FormSteps {
  CompanyDetails = 0,
  AccountDetails = 1,
  SuccessScreen = 2,
}

export const RegisterBoligmappaModal: FC<RegisterBoligmappaModalProps> = ({
  show,
  onClose,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const { track } = useAnalytics();

  const [step, setStep] = useState<FormSteps>(FormSteps.CompanyDetails);
  const [error, setError] = useState<string | null>();
  const { data, loading: dropdownLoading } =
    useBoligmappaDocumentAndProfessionTypesQuery();

  const { refetchBoligMappa } = useBoligMappaAuthContext();
  const isLastStepOfForm = useCallback(() => {
    return step === FormSteps.AccountDetails;
  }, [step]);
  const [createBoligmappaAccount, { loading: createAccountLoading }] =
    useBoligmappaCreateAccountMutation();
  useEffect(() => {
    setStep(FormSteps.CompanyDetails);
  }, [show]);
  const formikDefaultValues = getInitialFormValues();

  const onFormikSubmit = useCallback(
    async (
      values: CreateBoligmappaAccountFormShape,
      helpers: FormikHelpers<any>
    ): Promise<void> => {
      await createBoligmappaAccount(
        {
          organizationNumber: values.orgNumber,
          organizationName: values.companyName,
          email: values.email,
          phoneNumber: values.phone,
          firstName: values.firstName,
          lastName: values.lastName,
          acceptTermsAndConditions: 'true',
          professionId: values.professionType || '',
          numberOfEmployees: values.numberOfEmployees,
          password: values.password,
          userName: values.email,
        },
        {
          onCompleted() {
            track(
              ANEventSpace(ANEvent.Saved, ANObject.SignUp, ANPage.BoligMappa)
            );
            setStep(FormSteps.SuccessScreen);
            refetchBoligMappa();
          },
          onError: (e) => {
            const { field, message, generalError } =
              getInvalidInputErrorMessage(e.message);
            if (generalError) {
              setError(t(message));
            } else {
              helpers.setFieldError(field, t(message));
            }
            setStep(FormSteps.CompanyDetails);
          },
        }
      );
    },
    [createBoligmappaAccount, refetchBoligMappa, t, track]
  );

  const formik = UseRegisterBoligmappaFormik(
    formikDefaultValues,
    onFormikSubmit
  );

  const createAccount = useCallback(() => {
    formik.handleSubmit();
  }, [formik]);

  const onClickNext = useCallback(() => {
    formik.setTouched({
      companyName: true,
      orgNumber: true,
      professionType: true,
      numberOfEmployees: true,
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });
    if (
      !formik.errors.companyName &&
      !formik.errors.orgNumber &&
      !formik.errors.professionType &&
      !formik.errors.firstName &&
      !formik.errors.lastName &&
      !formik.errors.email &&
      !formik.errors.phone &&
      !formik.errors.numberOfEmployees
    ) {
      track(
        ANEventSpace(ANEvent.NextClicked, ANObject.SignUp, ANPage.BoligMappa)
      );
      setStep(FormSteps.AccountDetails);
    }
  }, [formik, setStep, track]);

  const renderFooter = useCallback(() => {
    if (step === FormSteps.SuccessScreen) {
      return null;
    }
    return (
      <Div width="100%" display="flex" flexDirection="column">
        <Row justifyContent="space-between" mb={8}>
          <Column>
            {step > FormSteps.CompanyDetails ? (
              <Button
                variant="secondary"
                disabled={formik.isSubmitting}
                onClick={() => setStep(FormSteps.CompanyDetails)}
                maxWidth="150px"
              >
                {t('general.label.back')}
              </Button>
            ) : null}
          </Column>
          <Column>
            {isLastStepOfForm() ? (
              <Button
                variant="primary"
                type="submit"
                onClick={createAccount}
                isLoading={createAccountLoading}
                disabled={createAccountLoading || !formik.isValid}
                maxWidth="150px"
              >
                {formik.isSubmitting
                  ? t('general.label.submitting')
                  : t('general.label.submit')}
              </Button>
            ) : (
              <Button
                variant="primary"
                type="submit"
                onClick={onClickNext}
                isLoading={formik.isSubmitting}
                maxWidth="150px"
              >
                {t('general.label.next')}
              </Button>
            )}
          </Column>
        </Row>
        <Row my={3} display="flex" justifyContent="flex-end">
          {error && (
            <Alert variant="danger" size="medium">
              {error}
            </Alert>
          )}
        </Row>
        <Row>
          <TermsAndConditions />
        </Row>
      </Div>
    );
  }, [
    createAccount,
    createAccountLoading,
    error,
    formik.isSubmitting,
    formik.isValid,
    isLastStepOfForm,
    onClickNext,
    step,
    t,
  ]);

  const renderCompanyDetails = useCallback(() => {
    return (
      <>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.companyName')}
              name="companyName"
              type={InputType.Text}
              value={formik.values.companyName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.companyName && formik.errors.companyName
                  ? { msg: formik.errors.companyName }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.organizationNumber')}
              name="orgNumber"
              type={InputType.Text}
              value={formik.values.orgNumber}
              onChange={formik.handleChange}
              onBlur={(e) => {
                if (e.target.value) {
                  formik.setFieldValue(
                    'orgNumber',
                    e.target.value.replaceAll(' ', '')
                  );
                }
                formik.handleBlur(e);
              }}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.orgNumber && formik.errors.orgNumber
                  ? { msg: formik.errors.orgNumber }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1} justifyItems="start">
            <Div>
              <SearchSelect
                options={(data?.professionTypes ?? []) as Props['options']}
                name="professtionType"
                value={
                  data?.professionTypes.find(
                    (p) => p.value === formik.values.professionType
                  ) ?? null
                }
                required
                onChange={(e: BoligmappaProfessionType) => {
                  formik.setFieldValue('professionType', e.value);
                }}
                noOptionsMessage={() => t('general.label.noOptionsMessage')}
                placeholder={t(
                  'settings.integrations.boligmappa.register.professionType'
                )}
                isLoading={dropdownLoading}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.professionType && formik.errors.professionType
                    ? { msg: formik.errors.professionType }
                    : undefined
                }
              />
            </Div>
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.numberOfEmployees')}
              name="numberOfEmployees"
              type={InputType.Text}
              value={formik.values.numberOfEmployees}
              onChange={formik.handleChange}
              onBlur={(e) => {
                formik.handleBlur(e);
              }}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.numberOfEmployees &&
                formik.errors.numberOfEmployees
                  ? { msg: formik.errors.numberOfEmployees }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1 / 2}>
            <Input
              label={t('general.label.firstName')}
              name="firstName"
              type={InputType.Text}
              value={formik.values.firstName}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.firstName && formik.errors.firstName
                  ? { msg: formik.errors.firstName }
                  : undefined
              }
            />
          </Column>
          <Column width={1 / 2}>
            <Input
              label={t('general.label.lastName')}
              name="lastName"
              value={formik.values.lastName}
              type={InputType.Text}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.lastName && formik.errors.lastName
                  ? { msg: formik.errors.lastName }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.email')}
              name="email"
              type={InputType.Email}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.email && formik.errors.email
                  ? { msg: formik.errors.email }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <PhoneInput
              name="phone"
              label={t('general.label.phone')}
              value={formik.values.phone}
              onFocusOut={(e) => {
                formik.setFieldValue('phone', e.value.replaceAll(' ', ''));
              }}
              onChange={formik.handleChange}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.phone && formik.errors.phone
                  ? { msg: formik.errors.phone }
                  : undefined
              }
            />
          </Column>
        </FormRow>
      </>
    );
  }, [data?.professionTypes, dropdownLoading, formik, t]);

  const renderAccountDetails = useCallback(() => {
    return (
      <>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.username')}
              name="username"
              type={InputType.Text}
              value={formik.values.email}
              disabled
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.password')}
              name="password"
              type={InputType.Password}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.password && formik.errors.password
                  ? { msg: formik.errors.password }
                  : undefined
              }
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={1}>
            <Input
              label={t('general.label.confirmPassword')}
              name="confirmPassword"
              type={InputType.Password}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              inputWrapperMarginBottom={2}
              error={
                formik.touched.confirmPassword && formik.errors.confirmPassword
                  ? { msg: formik.errors.confirmPassword }
                  : undefined
              }
            />
          </Column>
        </FormRow>
      </>
    );
  }, [formik, t]);

  const renderContent = useCallback(() => {
    switch (step) {
      case FormSteps.CompanyDetails:
        return renderCompanyDetails();
      case FormSteps.AccountDetails:
        return renderAccountDetails();
      case FormSteps.SuccessScreen:
        return <Success />;
      default:
        return null;
    }
  }, [renderCompanyDetails, renderAccountDetails, step]);

  if (!show) {
    return null;
  }

  return (
    <Modal
      isOpen
      isClosable
      onClose={onClose}
      shouldCloseOnOverlayClick
      maxWidth="550px"
      size="custom"
      footer={renderFooter()}
      header={
        step !== FormSteps.SuccessScreen && (
          <Div alignItems="center" pl={themeContext.space[4]}>
            <Heading.h3>
              {t('settings.integrations.boligmappa.register.title')}
            </Heading.h3>
          </Div>
        )
      }
    >
      <Container mt={5} data-testid="register-boligmappa-modal">
        {renderContent()}
      </Container>
    </Modal>
  );
};
