import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION,
  RejectSimpleAgreementContractMutationPayload,
  RejectSimpleAgreementContractMutationVariables,
} from '../../utils/mutation/SimpleAgreementContract/RejectSimpleAgreementContractMutation';
import { SIMPLE_CONTRACT_LIST_QUERY } from '../../utils/query/SimpleContractList/SimpleContractListQuery';

type UseRejectSimpleAgreementContractMutationAction = (
  input: RejectSimpleAgreementContractMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    RejectSimpleAgreementContractMutationPayload,
    RejectSimpleAgreementContractMutationVariables
  >
) => Promise<FetchResult<RejectSimpleAgreementContractMutationPayload>>;

export const useRejectSimpleAgreementContractMutation = (): [
  UseRejectSimpleAgreementContractMutationAction,
  MutationResult<RejectSimpleAgreementContractMutationPayload>
] => {
  const [rejectSimpleAgreementContract, ...rest] = useMutation<
    RejectSimpleAgreementContractMutationPayload,
    RejectSimpleAgreementContractMutationVariables
  >(REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION);

  const mutationFunction: UseRejectSimpleAgreementContractMutationAction =
    useCallback(
      (input, mutationOptions) =>
        rejectSimpleAgreementContract({
          variables: {
            input: {
              ...input,
            },
          },
          awaitRefetchQueries: true,
          refetchQueries: [SIMPLE_CONTRACT_LIST_QUERY],
          ...mutationOptions,
        }),
      []
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
