import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import PAGE_INFO_FRAGMENT from '../../fragments/PageInfoFragment';
import {
  JOB_TO_EVALUATE_LIST_QUERYVariables,
  JOB_TO_EVALUATE_LIST_QUERY as JOB_TO_EVALUATE_LIST_QUERY_Result,
  JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload,
  JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection,
  JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node,
} from '../../generated/generated';

export type JobToEvaluateListQueryPayload = JOB_TO_EVALUATE_LIST_QUERY_Result;
export type JobToEvaluateListQueryVariables =
  JOB_TO_EVALUATE_LIST_QUERYVariables;
export type JobToEvaluateListConnection =
  JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection;
export type JobToEvaluate =
  JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node;

export const isJobToEvaluateListQueryJobListPayload = (
  jobList?: JOB_TO_EVALUATE_LIST_QUERY_Result['jobList']
): jobList is JOB_TO_EVALUATE_LIST_QUERY_jobList_JobListPayload =>
  !!jobList && jobList.__typename === 'JobListPayload';

export const JOB_TO_EVALUATE_LIST_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  query JOB_TO_EVALUATE_LIST_QUERY(
    $query: String
    $page: Int
    $count: Int
    $listId: ID
  ) {
    jobList(
      input: { query: $query, page: $page, count: $count, listId: $listId }
    ) {
      ... on JobListPayload {
        jobConnection {
          pageInfo {
            ...PageInfoFragment
          }
          edges {
            node {
              id
              evaluation {
                id
              }
              jobBusinessRelationState {
                hasRequestedEvaluation
                jobId
              }
              owner {
                ...BasicUserDataFragment
              }
              title
            }
          }
        }
      }

      ... on PageOutOfRangeError {
        message
      }
    }
  }
`;
