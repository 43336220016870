import { useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import {
  GQLErrorState,
  UpdateBusinessProfileInfoResponseError,
} from '@internals/business-shared/src/utils/errors';
import {
  UPDATE_BUSINESS_PROFILE_INFO,
  UpdateBusinessProfileInfoPayload,
  UpdateBusinessProfileInfoVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusinessProfileInfo/UpdateBusinessProfileInfo';
import { CURRENT_USER_COMPANY_QUERY } from '@internals/business-shared/src/utils/query/CurrentUserCompany/CurrentUserCompanyQuery';
import { useTranslation } from 'react-i18next';

export const useUpdateProfileBackground = () => {
  const { t } = useTranslation();
  const [error, setError] = useState<GQLErrorState>();

  const clearError = () => setError(undefined);

  const onUpdateBusinessProfileInfoCompleted = useCallback(
    (data: UpdateBusinessProfileInfoPayload) => {
      const updateBusinessProfileInfoResponseError =
        new UpdateBusinessProfileInfoResponseError('Business');
      if (
        !updateBusinessProfileInfoResponseError.hasResponseError(
          data?.updateBusinessProfileInfo,
          setError
        )
      ) {
        clearError();
      }
    },
    []
  );

  const [updateBusinessProfileInfo, { loading }] = useMutation<
    UpdateBusinessProfileInfoPayload,
    UpdateBusinessProfileInfoVariables
  >(UPDATE_BUSINESS_PROFILE_INFO, {
    refetchQueries: [CURRENT_USER_COMPANY_QUERY],
    awaitRefetchQueries: true,
    onCompleted: onUpdateBusinessProfileInfoCompleted,
  });

  const updateBackground = useCallback(
    async (
      background: File,
      businessId: UpdateBusinessProfileInfoVariables['input']['businessId']
    ) => {
      clearError();

      return updateBusinessProfileInfo({
        variables: { input: { businessId, headerImage: background } },
        onError: () => {
          setError({
            general: {
              msg: t('profile.graphic.backgroundImage.edit.error'),
              variant: 'danger',
            },
          });
        },
      });
    },
    [t, updateBusinessProfileInfo]
  );

  const deleteBackground = useCallback(
    async (
      businessId: UpdateBusinessProfileInfoVariables['input']['businessId']
    ) => {
      clearError();

      return updateBusinessProfileInfo({
        variables: { input: { businessId, headerImage: null } },
        onError: () => {
          setError({
            general: {
              msg: t('profile.graphic.backgroundImage.remove.error'),
              variant: 'danger',
            },
          });
        },
      });
    },
    [t, updateBusinessProfileInfo]
  );

  return { loading, updateBackground, deleteBackground, error, clearError };
};

export const checkForUpdateBackgroundErrors = (
  payload: UpdateBusinessProfileInfoPayload
) => {
  if (!payload) {
    return true;
  }

  const updateBusinessProfileInfoResponseError =
    new UpdateBusinessProfileInfoResponseError('Business');
  return updateBusinessProfileInfoResponseError.hasResponseError(
    payload?.updateBusinessProfileInfo
  );
};
