import { FC, ReactNode } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import Linkify from 'react-linkify';
import styled from 'styled-components';

interface JobHtmlContentRowProps {
  children: ReactNode;
}

const MultilineText = styled(Text.span)`
  white-space: pre-line;
  margin-bottom: 0;
`;

export const JobHtmlContentRow: FC<JobHtmlContentRowProps> = ({ children }) => {
  return (
    <Div display="flex" alignItems="center">
      <MultilineText lineHeight="1.35">
        <Linkify>{children}</Linkify>
      </MultilineText>
    </Div>
  );
};
