import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const EvaluationResponseInfo = styled(Text.p)`
  position: relative;
  background: ${(props) => props.theme.colors.black.black0};
  border-radius: ${(props) => props.theme.radii[1]};
  margin: 0 ${(props) => props.theme.space[4]}px
    ${(props) => props.theme.space[6]}px ${(props) => props.theme.space[4]}px;
  padding: ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[4]}px ${(props) => props.theme.space[2]}px
    ${(props) => props.theme.space[4]}px;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.35));

  &::before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    top: 100%;
    left: ${(props) => props.theme.space[5]}px;
    border: 0.625rem solid transparent;
    border-bottom: none;
    border-top-color: ${(props) => props.theme.colors.black.black0};
  }
`;

export default EvaluationResponseInfo;
