import { FC, useMemo } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FilterCountBadge } from '@components/elements/PillNavigation/FilterCountBadge';
import { useJobListAllFilters } from '@hooks/useJobListAllFilters';
import { countFilters } from '@internals/business-shared/src/components/SavedFilters/utils';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  StyledFilterCountBadgeContainer,
  StyledPillNavigationFilterButton,
  StyledPillNavigationFilterButtonContainer,
} from './styled';

interface PillNavigationFilterButtonProps {
  onClick: VoidFunction;
}

export const PillNavigationFilterButton: FC<
  PillNavigationFilterButtonProps
> = ({ onClick }) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const activeFilters = useJobListAllFilters();
  const filterCount = useMemo(
    () => countFilters(activeFilters),
    [activeFilters]
  );

  return (
    <StyledPillNavigationFilterButtonContainer>
      <StyledPillNavigationFilterButton variant="secondary" onClick={onClick}>
        <FireIcon
          iconName={IconPickerName.TuneAlt}
          variant="flat"
          fill={themeContext.colors.primary.darkest}
          width={14}
          height={14}
        />
        <Text.span mb={0} ml={3} fontWeight={themeContext.fontWeights.medium}>
          {t('job.list.filters.open')}
        </Text.span>
      </StyledPillNavigationFilterButton>
      <StyledFilterCountBadgeContainer>
        <FilterCountBadge count={filterCount} />
      </StyledFilterCountBadgeContainer>
    </StyledPillNavigationFilterButtonContainer>
  );
};
