import { useCallback, useRef } from 'react';

export const useAbortController = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const abort = useCallback(() => {
    abortControllerRef.current.abort();
  }, []);

  return { abort, signal: abortControllerRef.current.signal };
};
