import { ChangeEvent, FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { SearchInputClearButton } from '@components/base/SearchInput/SearchInputClearButton';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  InputWrapper,
  SearchInputWrapper,
  IconPickerWrapper,
  InputWrapperProps,
} from './styled';

export interface SearchInputProps {
  inputValue: string;
  changeInputValue: (value: string) => void;
  placeholder?: string;
  width: string;
  height: string;
  borderRadius?: number;
  withClearButton?: boolean;
  stylesWhenFilled?: InputWrapperProps['stylesWhenFilled'];
}

const SearchInput: FC<SearchInputProps> = ({
  inputValue,
  placeholder,
  changeInputValue,
  width,
  height,
  borderRadius = 3,
  withClearButton,
  stylesWhenFilled,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const isFilled = inputValue.length > 0;
  const showClearButton = withClearButton && isFilled;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeInputValue(e.target.value);
  };

  const clearInput = () => {
    changeInputValue('');
  };

  return (
    <SearchInputWrapper>
      <InputWrapper
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder ?? t('general.form.search.placeholder')}
        unlabeled
        width={width}
        height={height}
        lineHeight={height}
        borderRadius={borderRadius}
        containersProps={{
          width,
        }}
        p={0}
        data-testid="search-input"
        {...(showClearButton && {
          trailing: <SearchInputClearButton onClick={clearInput} />,
        })}
        {...(isFilled && {
          stylesWhenFilled,
        })}
      />
      <IconPickerWrapper>
        <FireIcon
          fill={themeContext.colors.black.black7}
          iconName={IconPickerName.Search}
        />
      </IconPickerWrapper>
    </SearchInputWrapper>
  );
};

export default SearchInput;
