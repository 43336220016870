import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  JOB_ANALYTICS,
  JobAnalyticsPayload,
  JobAnalyticsVariables,
} from '../../utils/query/JobAnalytics/JobAnalytics';

interface JobAnalyticsQueryResult extends QueryResult {
  data: {
    isContract: boolean | null;
    isDialog: boolean | null;
    isWon: boolean | null;
  };
}

export const useJobAnalyticsQuery = (
  jobId: JobAnalyticsVariables['jobId'],
  options?: QueryHookOptions<JobAnalyticsPayload, JobAnalyticsVariables>
): JobAnalyticsQueryResult => {
  const { data, ...rest } = useQuery<
    JobAnalyticsPayload,
    JobAnalyticsVariables
  >(JOB_ANALYTICS, {
    ...options,
    // Explicitly set fetchPolicy to 'cache-first' to improve performance,
    // especially when this analytics query hook will grow.
    fetchPolicy: 'cache-first',
    skip: !jobId,
    variables: { jobId },
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
  });

  return useMemo(
    () => ({
      ...rest,
      data: {
        isContract: !!data?.simpleAgreementContractByJobId?.id ?? null,
        isDialog: !!data?.chatByJobIdOptional?.isDialog ?? null,
        isWon: !!data?.jobBusinessRelationState?.isWon ?? null,
      },
    }),
    [data, rest]
  );
};
