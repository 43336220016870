import { Text, TextProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface JobCostIndicatorProps extends TextProps {
  amount: number;
}

export const JobCostIndicator = styled(Text.span)<JobCostIndicatorProps>`
  margin: ${({ theme }) => `0 ${theme.space[4]}px 0 0`};
  height: ${({ theme }) => theme.space[3]}px;
  width: ${({ theme }) => theme.space[3]}px;
  flex-shrink: 0;
  background-color: ${({ amount, theme }) =>
    amount > 0 ? theme.colors.green.base : theme.colors.red.base};
  border-radius: 50%;
`;
