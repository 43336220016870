/* eslint-disable no-alert */
import { useCallback } from 'react';

import { Button } from '@schibsted-smb/fireball';

import { useStore } from '../modules/store';

const Login = () => {
  const loginWithPassword = useStore.use.loginWithPassword();
  const logout = useStore.use.logout();
  const isLoading = useStore.use.loginOperationIsLoading();
  const user = useStore.use.user();

  const onSubmit = useCallback(async () => {
    await loginWithPassword(
      { email: prompt('Username?') || '', password: prompt('Password?') || '' },
      {},
      // Just doing alerts since this is only used in dev
      {
        onInvalidOrMissingInputError: () =>
          alert('onInvalidOrMissingInputError'),
        onRoleUnavailableError: () => alert('onRoleUnavailableError'),
        onAuthNoAccessError: () => alert('onAuthNoAccessError'),
        onGenericError: () => alert('onGenericError'),
      }
    );
  }, [loginWithPassword]);

  if (user) {
    return (
      <div>
        <h5>Logout</h5>
        <Button variant="danger" display="inline" onClick={logout} size="tiny">
          Logout
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h5>Login</h5>
      <Button
        variant="primary"
        isLoading={isLoading}
        display="inline"
        onClick={onSubmit}
        size="tiny"
        data-testid="login-button"
      >
        Login
      </Button>
    </div>
  );
};

export default Login;
