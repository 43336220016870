import * as React from 'react';

import { OPTIMISTIC_ID_PREFIX } from '@internals/business-shared/src/cache/constants';
import Paths from '@router/paths';
import { Alert, Button, Div, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { Trans, useTranslation } from 'react-i18next';

import StyledAlertLink from './styled/StyledAlertLink';

interface JobAnsweredAlertProps {
  firstMessageTimestamp: number;
  chatId: string;
  clipsSpent: number;
}

const JobAnsweredAlert: React.FC<
  React.PropsWithChildren<JobAnsweredAlertProps>
> = ({ firstMessageTimestamp, chatId, clipsSpent }) => {
  const { t } = useTranslation();
  const isAnswerInProgress = chatId.includes(OPTIMISTIC_ID_PREFIX);
  return (
    <Alert
      variant="success"
      size="medium"
      isCentered
      m={0}
      height="60px"
      width="100%"
      data-testid="alert-job-answered"
      borderRadius={1}
    >
      <Div display="flex" alignItems="center">
        <Text.p m={0}>
          {t('job.item.alert.answered', {
            when: dateFromTimestamp(firstMessageTimestamp),
            count: clipsSpent,
          })}
        </Text.p>
        <StyledAlertLink
          to={`${Paths.Messages}/${chatId}`}
          disabled={isAnswerInProgress}
        >
          <Button
            isLoading={isAnswerInProgress}
            variant="primary"
            size="tiny"
            ml={5}
            data-testid="go-to-conversation-button"
          >
            {t('general.action.goToConversation')}
          </Button>
        </StyledAlertLink>
      </Div>
    </Alert>
  );
};

export default JobAnsweredAlert;
