import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  CREATE_ANSWER_TEMPLATE,
  CreateAnswerTemplatePayload,
  CreateAnswerTemplateVariables,
} from '../../utils/mutation/CreateAnswerTemplate/CreateAnswerTemplate';

type useCreateAnswerTemplateAction = (
  input: CreateAnswerTemplateVariables['input'],
  options?: MutationFunctionOptions<
    CreateAnswerTemplatePayload,
    CreateAnswerTemplateVariables
  >
) => Promise<FetchResult<CreateAnswerTemplatePayload>>;

export const useCreateAnswerTemplate = (
  mutationHookOptions?: MutationFunctionOptions<
    CreateAnswerTemplatePayload,
    CreateAnswerTemplateVariables
  >
): [
  useCreateAnswerTemplateAction,
  MutationResult<CreateAnswerTemplatePayload>
] => {
  const [createAnswerTemplate, ...rest] = useMutation<
    CreateAnswerTemplatePayload,
    CreateAnswerTemplateVariables
  >(CREATE_ANSWER_TEMPLATE, {
    ...mutationHookOptions,
  });

  const mutationFn: useCreateAnswerTemplateAction = useCallback(
    (input, options?) => {
      return createAnswerTemplate({
        variables: {
          input,
        },
        ...options,
      });
    },
    [createAnswerTemplate]
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
