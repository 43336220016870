import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const SimpleContractDetailsContainer = styled(Div)`
  background-color: ${({ theme }) => theme.colors.black.black2};
  border-radius: ${({ theme }) => theme.radii[3]};
  padding: ${({ theme }) => theme.space[6]}px;
  margin-bottom: ${({ theme }) => theme.space[7]}px;

  @media print {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: ${({ theme }) => theme.colors.black.black0};
    color: ${({ theme }) => theme.colors.black.black9};
  }
`;
