import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useFoldersContext } from '@contexts/JobFoldersContext';
import { useUserBusinessId } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import useJobListSearchParams from '@hooks/useJobListSearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { useJobAreasQuery } from '@internals/business-shared/src/hooks/query/useJobAreasQuery';
import { useWorkTypesQuery } from '@internals/business-shared/src/hooks/query/useWorkTypes';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  CountiesMunicipality,
  CountyWithMunicipalities,
  getCountiesWithMunicipalities,
  getDistrictIds,
  getMunicipalityCodes,
  isMunicipalityActive,
} from '@internals/business-shared/src/utils/areasUtils';
import { sortByName } from '@internals/business-shared/src/utils/arrayUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { JobSize } from '@internals/business-shared/src/utils/generated/generated';
import { JobFolderId } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import {
  District,
  Municipality,
} from '@internals/business-shared/src/utils/query/JobsAreas/JobsAreasQuery';
import {
  WorkType,
  WorkTypeIndustry,
  WorkTypeSubset,
  WorkTypeSubsetGroup,
} from '@internals/business-shared/src/utils/query/WorkTypes/WorkTypesQuery';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import {
  groupWorkTypes,
  groupSubsets,
  WorkTypeGroup,
  unmergeSelectedWorkTypeIds,
  mergeSelectedWorkTypes,
  getActiveWorkTypeGroups,
} from '@internals/business-shared/src/utils/workTypesUtils';

export const FILTERABLE_SIZES = [JobSize.BIG, JobSize.NORMAL, JobSize.SMALL];
export type SizeType = (typeof FILTERABLE_SIZES)[number];
export const isSizeType = (size: string): size is SizeType =>
  FILTERABLE_SIZES.includes(size as SizeType);
export type WorkTypeFilter = WorkType['id'] | WorkTypeSubset['id'];

interface JobFiltersContextType {
  activeSizes: SizeType[];
  setActiveSizes: (size: SizeType[]) => void;
  areas: CountyWithMunicipalities[];
  activeMunicipalities: Municipality['code'][];
  setActiveMunicipalities: (municipalities: Municipality['code'][]) => void;
  activeDistricts: District['id'][];
  setActiveDistricts: (districts: District['id'][]) => void;
  activeCounties: CountyWithMunicipalities['id'][];
  setActiveCounties: (counties: CountyWithMunicipalities['id'][]) => void;
  clearActiveAreas: VoidFunction;
  activeFolderFilters: JobFolderId[];
  setActiveFolders: (folders: JobFolderId[]) => void;
  clearActiveFolders: () => void;
  searchQuery: string;
  setSearchQuery: (search: string) => void;
  workTypes: WorkTypeGroup[];
  activeWorkTypes: WorkTypeFilter[];
  setActiveWorkTypes: (workTypes: WorkTypeFilter[]) => void;
  activeIndustries: WorkTypeIndustry['id'][];
  setActiveIndustries: (industries: WorkTypeIndustry['id'][]) => void;
  activeSubsetGroups: WorkTypeSubsetGroup['id'][];
  setActiveSubsetGroups: (industries: WorkTypeSubsetGroup['id'][]) => void;
  showFilterButtons: boolean;
  resetAllFilters: VoidFunction;
  canResetFilters: boolean;
}

export const JobFiltersContext = createContext<JobFiltersContextType>({
  activeSizes: [],
  setActiveSizes: () => null,
  areas: [],
  activeMunicipalities: [],
  setActiveMunicipalities: () => null,
  activeDistricts: [],
  setActiveDistricts: () => null,
  activeCounties: [],
  setActiveCounties: () => null,
  clearActiveAreas: () => null,
  activeFolderFilters: [],
  setActiveFolders: () => null,
  clearActiveFolders: () => null,
  searchQuery: '',
  setSearchQuery: () => null,
  workTypes: [],
  activeWorkTypes: [],
  setActiveWorkTypes: () => null,
  activeIndustries: [],
  setActiveIndustries: () => null,
  activeSubsetGroups: [],
  setActiveSubsetGroups: () => null,
  showFilterButtons: false,
  resetAllFilters: () => null,
  canResetFilters: false,
});

export const JobsFiltersProvider: FC<{
  children: ReactElement | ReactElement[];
}> = ({ children }) => {
  const { track } = useAnalytics();
  const filterTreeEnabled = useFlagEnabled(FeatureFlags.BizJobFilterTree);
  const filterVersion = filterTreeEnabled ? 'tree' : 'step-view';
  const businessId = useUserBusinessId();
  const {
    search,
    sizes,
    districts,
    municipalities,
    counties,
    folders,
    workTypes,
    workSubsets,
    industries,
    workSubsetGroups,
    setSearchParam,
    setSizeParam,
    setDistrictParam,
    setMunicipalityParam,
    setCountyParam,
    setFolderParam,
    setWorkTypeParam,
    setWorkSubsetParam,
    setIndustryParam,
    setWorkSubsetGroupParam,
  } = useJobListSearchParams();
  const [, resetParams] = useSetSearchParams();
  const availableFolders = useFoldersContext();
  const { data: jobAreas, loading: jobAreasLoading } =
    useJobAreasQuery(businessId);
  const { data: workTypesData } = useWorkTypesQuery(businessId);

  // map data for better structure
  const countiesWithMunicipalities = useMemo(() => {
    return getCountiesWithMunicipalities(
      jobAreas.municipalitiesAvailable,
      jobAreas.districts
    )
      .map((county) => ({
        ...county,
        // filter out municipalities that are neither fully nor partially (districts) selected in settings
        municipalities: county.municipalities.filter(
          (m) =>
            isMunicipalityActive(
              m,
              getMunicipalityCodes(jobAreas.municipalities)
            ) || !!m.districts.length
        ),
      }))
      .filter((county) => !!county.municipalities.length);
  }, [jobAreas]);

  const allWorkTypes = useMemo(() => {
    if (!workTypesData) return [];
    return [
      ...groupWorkTypes(workTypesData.workTypes),
      ...groupSubsets(workTypesData.subsets),
    ].sort(sortByName);
  }, [workTypesData]);

  // make sure active filter lists contain only valid values
  const activeSizes = sizes.filter(isSizeType);
  const activeMunicipalities = useMemo(
    () =>
      municipalities
        .map(Number)
        .filter((municipalityFilterCode) =>
          jobAreas.municipalitiesAvailable.some(
            (m) => m.code === municipalityFilterCode
          )
        ),
    [jobAreas.municipalitiesAvailable, municipalities]
  );
  const activeDistricts = useMemo(
    () =>
      districts.filter((districtFilterId) =>
        jobAreas.districtsAvailable.some((d) => d.id === districtFilterId)
      ),
    [jobAreas.districtsAvailable, districts]
  );
  const activeIndustries = useMemo(
    () => getActiveWorkTypeGroups(allWorkTypes, industries, 'industry'),
    [allWorkTypes, industries]
  );
  const activeSubsetGroups = useMemo(
    () =>
      getActiveWorkTypeGroups(allWorkTypes, workSubsetGroups, 'subsetGroup'),
    [allWorkTypes, workSubsetGroups]
  );
  const activeCounties = useMemo(
    () =>
      counties.filter((countyId) =>
        jobAreas.municipalitiesAvailable.some((m) => m.county.id === countyId)
      ),
    [jobAreas.municipalitiesAvailable, counties]
  );
  const activeFolderFilters = useMemo(
    () =>
      folders.filter((folderFilterId) =>
        availableFolders?.folderList.some((f) => f.id === folderFilterId)
      ),
    [availableFolders?.folderList, folders]
  );
  const activeWorkTypes = useMemo(
    () =>
      mergeSelectedWorkTypes(workTypes, workSubsets).filter(
        (workTypeFilterId) =>
          allWorkTypes
            .flatMap((workTypeGroup) => workTypeGroup.workTypes)
            .some((workType) => workType.id === workTypeFilterId)
      ),
    [allWorkTypes, workTypes, workSubsets]
  );

  const trackWorkTypeFilterChange = useCallback(
    (value: string[]) => {
      if (value.length) {
        track(
          ANEventSpace(
            ANEvent.Selected,
            ANObject.FilterWorkType,
            ANPage.JobList
          ),
          {
            filter: ANObject.FilterWorkType,
            value,
            filterVersion,
          }
        );
      }
    },
    [track, filterVersion]
  );

  const setActiveWorkTypes = useCallback(
    (value: string[]) => {
      const { selectedWorkTypes, selectedSubsets } =
        unmergeSelectedWorkTypeIds(value);
      setWorkTypeParam(selectedWorkTypes);
      setWorkSubsetParam(selectedSubsets);
      trackWorkTypeFilterChange(value);
    },
    [setWorkTypeParam, setWorkSubsetParam, trackWorkTypeFilterChange]
  );

  const trackAreaFilterChange = useCallback(
    (value: (string | number)[]) => {
      if (value.length) {
        track(
          ANEventSpace(ANEvent.Selected, ANObject.FilterPlace, ANPage.JobList),
          {
            filter: ANObject.FilterPlace,
            value,
            filterVersion,
          }
        );
      }
    },
    [track, filterVersion]
  );

  return (
    <JobFiltersContext.Provider
      value={{
        activeSizes,
        setActiveSizes: (value) => {
          setSizeParam(value);
          track(
            ANEventSpace(ANEvent.Selected, ANObject.FilterSize, ANPage.JobList),
            {
              filter: ANObject.FilterSize,
              value,
              filterVersion,
            }
          );
        },
        areas: countiesWithMunicipalities,
        activeMunicipalities,
        setActiveMunicipalities: (value) => {
          setMunicipalityParam(value.map(String));
          trackAreaFilterChange(value);
        },
        activeDistricts,
        setActiveDistricts: (value) => {
          setDistrictParam(value);
          trackAreaFilterChange(value);
        },
        activeCounties,
        setActiveCounties: (value) => {
          setCountyParam(value);
          trackAreaFilterChange(value);
        },
        clearActiveAreas: () => {
          setMunicipalityParam('');
          setDistrictParam('');
        },
        activeFolderFilters,
        setActiveFolders: (value) => {
          if (value.length) {
            track(
              ANEventSpace(
                ANEvent.Selected,
                ANObject.FilterFolder,
                ANPage.JobList
              ),
              {
                filter: ANObject.FilterFolder,
                value: value.join(','),
                filterVersion,
              }
            );
          }
          setFolderParam(value);
        },
        clearActiveFolders: () => setFolderParam(''),
        searchQuery: search,
        setSearchQuery: setSearchParam,
        workTypes: allWorkTypes,
        activeWorkTypes,
        setActiveWorkTypes,
        activeIndustries,
        setActiveIndustries: (value) => {
          setIndustryParam(value);
          trackWorkTypeFilterChange(value);
        },
        activeSubsetGroups,
        setActiveSubsetGroups: (value) => {
          setWorkSubsetGroupParam(value);
          trackWorkTypeFilterChange(value);
        },
        showFilterButtons: !jobAreasLoading,
        resetAllFilters: resetParams,
        canResetFilters:
          !!search ||
          !!activeMunicipalities.length ||
          !!activeDistricts.length ||
          !!activeSizes.length ||
          !!activeFolderFilters.length ||
          !!activeWorkTypes.length,
      }}
    >
      {children}
    </JobFiltersContext.Provider>
  );
};

export const useJobFiltersContext = (): JobFiltersContextType => {
  return useContext(JobFiltersContext);
};

interface DistrictFilterChangeFn {
  (districtId: string, isActive: boolean): void;
}

export const useDistrictFilterChange = (): DistrictFilterChangeFn => {
  const { activeDistricts, setActiveDistricts } = useJobFiltersContext();
  const handleOnDistrictChange: DistrictFilterChangeFn = (
    districtId,
    isActive
  ) => {
    const updatedActiveDistricts = toggleItemInArray(
      activeDistricts,
      districtId,
      isActive
    );
    setActiveDistricts(updatedActiveDistricts);
  };
  return handleOnDistrictChange;
};

interface MunicipalityFilterChangeFn {
  (municipality: CountiesMunicipality, isActive: boolean): void;
}

export const useMunicipalityFilterChange = (): MunicipalityFilterChangeFn => {
  const {
    activeDistricts,
    activeMunicipalities,
    setActiveDistricts,
    setActiveMunicipalities,
  } = useJobFiltersContext();
  const handleOnMunicipalityChange: MunicipalityFilterChangeFn = (
    municipality,
    isActive
  ) => {
    const municipalityDistricts = getDistrictIds(municipality.districts);
    if (municipalityDistricts.length) {
      const updatedDistricts = municipalityDistricts.reduce(
        (list, d) => toggleItemInArray(list, d, isActive),
        activeDistricts
      );
      setActiveDistricts(updatedDistricts);
      return;
    }
    setActiveMunicipalities(
      toggleItemInArray(activeMunicipalities, municipality.code, isActive)
    );
  };
  return handleOnMunicipalityChange;
};

export const useSelectAllAreaFilters = (): VoidFunction => {
  const { areas, setActiveDistricts, setActiveMunicipalities } =
    useJobFiltersContext();
  const selectAllAreas = (): void => {
    const allDistricts = areas.flatMap((county) => {
      return county.municipalities.flatMap((m) => {
        return m.districts.length ? m.districts.map((d) => d.id) : [];
      });
    });

    const municipalitiesWithoutDistricts = areas.flatMap((county) => {
      return county.municipalities
        .filter((m) => !m.districts.length)
        .map((m) => m.code);
    });

    setActiveMunicipalities(municipalitiesWithoutDistricts);
    setActiveDistricts(allDistricts);
  };
  return selectAllAreas;
};

interface SelectAllCountyAreaFiltersFn {
  (area: CountyWithMunicipalities['id']): void;
}

export const useSelectAllCountyAreaFilters =
  (): SelectAllCountyAreaFiltersFn => {
    const {
      areas,
      activeDistricts,
      setActiveDistricts,
      activeMunicipalities,
      setActiveMunicipalities,
    } = useJobFiltersContext();
    const selectAllAreasInCounty = (
      area: CountyWithMunicipalities['id']
    ): void => {
      const currentArea = areas.find((a) => a.id === area);

      if (!currentArea) return;
      const allDistrictsInCounty = currentArea.municipalities.flatMap((m) => {
        return m.districts.length ? m.districts.map((d) => d.id) : [];
      });
      const updatedDistricts = allDistrictsInCounty.reduce(
        (list, d) => toggleItemInArray(list, d, true),
        activeDistricts
      );

      const municipalitiesWithoutDistrictsInCounty = currentArea.municipalities
        .filter((m) => !m.districts.length)
        .map((m) => m.code);
      const updatedMunicipalities =
        municipalitiesWithoutDistrictsInCounty.reduce(
          (list, m) => toggleItemInArray(list, m, true),
          activeMunicipalities
        );

      setActiveMunicipalities(updatedMunicipalities);
      setActiveDistricts(updatedDistricts);
    };
    return selectAllAreasInCounty;
  };

interface UnselectAllCountyAreaFiltersFn {
  (areaId: CountyWithMunicipalities['id']): void;
}

export const useUnselectAllCountyAreaFilters =
  (): UnselectAllCountyAreaFiltersFn => {
    const {
      areas,
      activeMunicipalities,
      setActiveMunicipalities,
      activeDistricts,
      setActiveDistricts,
    } = useJobFiltersContext();

    const unselectAllAreasInCounty: UnselectAllCountyAreaFiltersFn = (
      areaId
    ) => {
      const currentArea = areas.find((a) => a.id === areaId);
      if (!currentArea) return;

      const allDistrictsInArea = currentArea.municipalities.flatMap((m) => {
        return m.districts.length ? m.districts.map((d) => d.id) : [];
      });
      const updatedActiveDistricts = activeDistricts.filter(
        (districtId) => !allDistrictsInArea.includes(districtId)
      );

      const allMunicipalitiesInArea = currentArea.municipalities.map(
        (m) => m.code
      );
      const updatedActiveMunicipalities = activeMunicipalities.filter(
        (municipalityCode) =>
          !allMunicipalitiesInArea.includes(municipalityCode)
      );

      setActiveMunicipalities(updatedActiveMunicipalities);
      setActiveDistricts(updatedActiveDistricts);
    };

    return unselectAllAreasInCounty;
  };

export default JobFiltersContext;
