import { FC, useCallback } from 'react';

import { RegisterBoligmappaModal } from '@components/layout/Settings/Integrations/Boligmappa/RegisterBoligmappaModal';
import { useBoligMappaAuthContext } from '@contexts/BoligMappaAuthProvider';
import { ReactComponent as LogoBoligMappa } from '@internals/business-shared/src/assets/logos/BoligmappaLogo.svg';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { Button, Div, Heading, Modal, Text } from '@schibsted-smb/fireball';
import { getIntegrationsAbsoluteUrl } from '@utils/url';
import { Trans, useTranslation } from 'react-i18next';

type BoligMappaAuthModalProps = {
  isOpen: boolean;
  onClose?: VoidFunction;
};
export const BoligMappaAuthModal: FC<BoligMappaAuthModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { isAuthorised, authRedirect } = useBoligMappaAuthContext();
  const { t } = useTranslation();
  const [
    registerBoligMappaModalVisible,
    openRegisterBoligMappaModal,
    hideRegisterBoligMappaModal,
  ] = useBoolean();

  const onRegister = () => {
    openRegisterBoligMappaModal();
    onClose?.();
  };

  const onConnect = useCallback(() => {
    authRedirect(getIntegrationsAbsoluteUrl());
  }, [authRedirect]);

  if (isAuthorised) {
    return null;
  }

  if (registerBoligMappaModalVisible) {
    return (
      <RegisterBoligmappaModal show onClose={hideRegisterBoligMappaModal} />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      isClosable
      onClose={onClose}
      header={
        <Heading.h2 mb={0} textAlign="left">
          {t('splash.boligmappa.title')}
        </Heading.h2>
      }
      footer={
        <Div display="flex">
          <Button size="large" type="button" onClick={onConnect}>
            {t('splash.boligmappa.goAuthYourself')}
          </Button>
          <Button
            size="large"
            type="button"
            onClick={onRegister}
            variant="secondary"
            ml={6}
          >
            {t('settings.integrations.boligmappa.register.register')}
          </Button>
        </Div>
      }
      footerProps={{
        justifyContent: 'center',
      }}
      contentProps={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        py: 6,
      }}
    >
      <Div mb={8}>
        <LogoBoligMappa width={300} />
      </Div>
      <Text.p>
        <Trans
          i18nKey="splash.boligmappa.description"
          components={{ break: <br /> }}
        />
      </Text.p>
    </Modal>
  );
};
