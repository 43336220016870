import { FC, useCallback } from 'react';

import { TriggerSource } from '@components/elements/BuySubscriptionModal/constants';
import { useBuySubscriptionModalContext } from '@components/elements/BuySubscriptionModal/ModalContext';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { ReactComponent as RippedPaperGraphic } from '@internals/business-shared/src/assets/illustrations/RippedPaperVertical.svg';
import CarpenterGrayJacket from '@internals/business-shared/src/assets/images/carpenter-gray-jacket.png';
import {
  Button,
  Card,
  Div,
  Heading,
  Image,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const CUSTOM_YELLOW_COLOR = '#FECA95';

interface JobBuySubscriptionAlertProps {
  isJobList?: boolean;
  jobId?: string;
}

export const JobBuySubscriptionAlert: FC<JobBuySubscriptionAlertProps> = ({
  isJobList,
  jobId,
}) => {
  const { loading, handleModalOpen } = useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const handleButtonClick = useCallback(() => {
    handleModalOpen(
      isJobList ? TriggerSource.JOB_LIST : TriggerSource.JOB_CARD,
      jobId
    );
  }, [handleModalOpen, isJobList, jobId]);

  return (
    <Div
      mb={!isJobList && 7}
      boxShadow={
        !isJobList && `-15px -50px 50px 15px ${theme.colors.black.black0}`
      }
      data-testid="job-buy-subscription-alert"
      width={1}
    >
      <Card
        borderRadius={3}
        display="flex"
        overflow="hidden"
        p={0}
        boxShadow={isJobList && 'none'}
      >
        <Div
          display="flex"
          alignItems="end"
          position="relative"
          bg={CUSTOM_YELLOW_COLOR}
          pl={4}
          pr={6}
          mr={4}
        >
          <Div position="absolute" height="100%" right={-10}>
            <RippedPaperGraphic />
          </Div>
          {!isMobile && (
            <Div position="relative" top={30}>
              <Image src={CarpenterGrayJacket} width="150px" />
            </Div>
          )}
        </Div>
        <Div
          p={5}
          display="flex"
          flexDirection="column"
          alignItems={isJobList ? 'baseline' : 'center'}
          justifyContent="center"
          width={1}
        >
          <Div>
            {/* eslint-disable-next-line react/jsx-pascal-case */}
            <Heading.h2
              fontWeight={theme.fontWeights.bold}
              textAlign={isJobList ? 'left' : 'center'}
              mb={4}
            >
              {isJobList
                ? t('job.list.alert.buySubscription.title')
                : t('job.item.alert.buySubscription.title')}
            </Heading.h2>
            <Text.p textAlign={isJobList ? 'left' : 'center'} mb={5}>
              {isJobList
                ? t('job.list.alert.buySubscription.description')
                : t('job.item.alert.buySubscription.description')}
            </Text.p>
          </Div>
          <Button onClick={handleButtonClick} isLoading={loading}>
            {isJobList
              ? t('job.list.alert.buySubscription.action')
              : t('job.item.alert.buySubscription.action')}
          </Button>
        </Div>
      </Card>
    </Div>
  );
};
