import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  CREATE_SIMPLE_AGREEMENT_CONTRACT,
  CreateSimpleAgreementContractMutationPayload,
  CreateSimpleAgreementContractMutationVariables,
} from '../../utils/mutation/CreateSimpleAgreementContract/CreateSimpleAgreementContractMutation';

type UseCreateSimpleAgreementContractMutationAction = (
  input: CreateSimpleAgreementContractMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    CreateSimpleAgreementContractMutationPayload,
    CreateSimpleAgreementContractMutationVariables
  >
) => Promise<FetchResult<CreateSimpleAgreementContractMutationPayload>>;

export const useCreateSimpleAgreementContractMutation = (): [
  UseCreateSimpleAgreementContractMutationAction,
  MutationResult<CreateSimpleAgreementContractMutationPayload>
] => {
  const [createSimpleAgreementContract, ...rest] = useMutation<
    CreateSimpleAgreementContractMutationPayload,
    CreateSimpleAgreementContractMutationVariables
  >(CREATE_SIMPLE_AGREEMENT_CONTRACT);

  const mutationFunction: UseCreateSimpleAgreementContractMutationAction =
    useCallback(
      (input, mutationOptions) =>
        createSimpleAgreementContract({
          variables: {
            input: {
              ...input,
            },
          },
          ...mutationOptions,
        }),
      []
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
