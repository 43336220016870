import { FC, ReactNode } from 'react';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

interface FeatureFlagProps {
  name: string;
  invert?: boolean;
  children: ReactNode;
}

export const FeatureFlag: FC<FeatureFlagProps> = ({
  name,
  invert,
  children,
}) => {
  const { flagsReady } = useFlagsStatus();
  const isEnabled = useFlag(name);

  if (!flagsReady) return null;
  if (isEnabled) {
    return !invert ? <>{children}</> : null;
  }
  return !invert ? null : <>{children}</>;
};
