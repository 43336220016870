import {
  LANGUAGE_EN,
  LANGUAGE_NB,
  LANGUAGE_FI,
  LANGUAGE_DA,
  LANGUAGE_SV,
  LanguageCode,
  LOCALE_EN,
  LOCALE_NB,
  LOCALE_FI,
  LOCALE_DA,
  LOCALE_SV,
  LocaleCode,
} from '@internals/business-translations/src/languages';

const getLanguageCode = (language: LanguageCode): LocaleCode => {
  switch (language) {
    case LANGUAGE_EN:
      return LOCALE_EN;
    case LANGUAGE_NB:
      return LOCALE_NB;
    case LANGUAGE_FI:
      return LOCALE_FI;
    case LANGUAGE_DA:
      return LOCALE_DA;
    case LANGUAGE_SV:
      return LOCALE_SV;
    default:
      return LOCALE_EN;
  }
};

export default getLanguageCode;
