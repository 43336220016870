import { useMemo } from 'react';

import { getFilteredFeatureFlags } from '@internals/business-shared/src/utils/featureFlags';
import { useFlags } from '@unleash/proxy-client-react';

export const useGetEnabledFlagNames = (): string[] => {
  const flags = useFlags();
  return useMemo(() => {
    const filteredFlags = getFilteredFeatureFlags(flags);
    return filteredFlags.map((flag) => flag.name);
  }, [flags]);
};
