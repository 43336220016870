import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const linkStyles = css`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  flex-shrink: 0;
  flex-direction: column;
  padding: ${({ theme }) => `0 0 ${theme.space[4]}px`};
  color: inherit;
  text-decoration: underline;
  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
  @media (min-width: ${({ theme }): string => theme.breakpoints[4]}) {
    padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[6]}px`};
    &,
    &:hover {
      color: ${({ theme }) => theme.colors.black.black0};
      background: ${({ theme }) => theme.colors.primary.base};
      text-decoration: none;
    }
  }
`;

export const ActionLink = styled(Link)`
  ${linkStyles}
`;

export const ActionA = styled('a')`
  ${linkStyles}
`;

export default ActionLink;
