import {
  SMARTPHONE_NAV_MENU_HEIGHT,
  TABLET_NAV_MENU_HEIGHT,
} from '@components/layout/Header/styled/MenuNav';
import { Layer } from '@utils/layer';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';

const StyledToastContainer = styled(ToastContainer)`
  bottom: calc(${SMARTPHONE_NAV_MENU_HEIGHT}px);
  z-index: ${Layer.toast};

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[4]}) {
    bottom: calc(${TABLET_NAV_MENU_HEIGHT}px + 2.25em);
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[5]}) {
    bottom: 0;
  }

  .Toastify__toast {
    font-size: ${({ theme }) => theme.fontSizes[1]};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
  .Toastify__toast--default {
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__progress-bar--default {
    background: ${({ theme }) => theme.colors.primary.base};
  }
  .Toastify__toast--info {
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__progress-bar--info {
    background: ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__close-button--info > svg {
    fill: ${({ theme }) => theme.colors.primary.dark};
  }
  .Toastify__toast--success {
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__progress-bar--success {
    background: ${({ theme }) => theme.colors.green.darker};
  }
  .Toastify__toast--warning {
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__progress-bar--warning {
    background: ${({ theme }) => theme.colors.orange.darker};
  }
  .Toastify__toast--error {
    color: ${({ theme }) => theme.colors.black.black10};
  }
  .Toastify__progress-bar--error {
    background: ${({ theme }) => theme.colors.red.darker};
  }
  .Toastify__toast-icon {
    --toastify-icon-color-error: ${({ theme }) => theme.colors.red.base};
    --toastify-icon-color-default: ${({ theme }) => theme.colors.primary.base};
    --toastify-icon-color-info: ${({ theme }) => theme.colors.primary.base};
    --toastify-icon-color-success: ${({ theme }) => theme.colors.green.base};
    --toastify-icon-color-warning: ${({ theme }) => theme.colors.orange.base};
  }
`;

export default StyledToastContainer;
