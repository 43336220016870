import styled from 'styled-components';

export const SelectImageButton = styled.button<{ isSelected: boolean }>`
  height: 180px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.black.black1};
  padding: 0;
  border-radius: ${({ theme }) => theme.radii[2]};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  &,
  &:focus,
  &:hover {
    border: none;
    outline: ${({ theme, isSelected }) =>
      `3px solid ${
        isSelected ? theme.colors.primary.base : theme.colors.black.black0
      }`};
  }
`;
