enum IconPickerName {
  AddEmployee = 'ADD_EMPLOYEE',
  Address = 'ADDRESS',
  Ads = 'ADS',
  Areas = 'AREAS',
  Arrow2Down = 'ARROW_2_DOWN',
  Arrow2Left = 'ARROW_2_LEFT',
  Arrow2Right = 'ARROW_2_RIGHT',
  Arrow2Up = 'ARROW_2_UP',
  ArrowDropdown = 'ARROW_DROPDOWN',
  ArrowLeft = 'ARROW_LEFT',
  Attachment = 'ATTACHMENT',
  Attention = 'ATTENTION',
  BankIdVerified = 'BANK_ID_VERIFIED',
  RandomCodeLines = 'RANDOM_CODE_LINES',
  Big = 'BIG',
  Book = 'BOOK',
  BoligMappa = 'BOLIG_MAPPA',
  BoligMappaNoCircle = 'BOLIG_MAPPA_NO_CIRCLE',
  BurgerMenu = 'BURGER_MENU',
  BadgeId = 'BADGE_ID',
  Calendar = 'CALENDAR',
  CalendarAlt = 'CALENDAR_ALT',
  Camera = 'CAMERA',
  Caution = 'CAUTION',
  Chat = 'CHAT',
  ChatMessageAlt = 'CHAT_MESSAGE_ALT',
  Check = 'CHECK',
  CancelCircle = 'CANCEL_CIRCLE',
  CheckmarkCircled = 'CHECKMARK_CIRCLED',
  CheckmarkRounded = 'CHECKMARK_ROUNDED',
  ChevronDown = 'CHEVRON_DOWN',
  ChevronLeft = 'CHEVRON_LEFT',
  ChevronRight = 'CHEVRON_RIGHT',
  ChevronUp = 'CHEVRON_UP',
  ChevronLeftBold = 'CHEVRON_LEFT_BOLD',
  ChevronDownBold = 'CHEVRON_DOWN_BOLD',
  ChevronRightBold = 'CHEVRON_RIGHT_BOLD',
  ChevronUpBold = 'CHEVRON_UP_BOLD',
  Clock = 'CLOCK',
  Close = 'CLOSE',
  CompanyInfo = 'COMPANY_INFO',
  Contract = 'CONTRACT',
  Copy = 'COPY',
  CreditCard = 'CREDIT_CARD',
  Cross = 'CROSS',
  Customers = 'CUSTOMERS',
  Decrease = 'DECREASE',
  Delete = 'DELETE',
  Deleted = 'DELETED',
  Doffin = 'DOFFIN',
  Download = 'DOWNLOAD',
  Eclipse = 'ECLIPSE',
  Email = 'EMAIL',
  Ended = 'ENDED',
  Enlarge = 'ENLARGE',
  Evaluation = 'EVALUATION',
  Favourite = 'FAVOURITE',
  FilledInfo = 'FILLED_INFO',
  Fingerprint = 'FINGERPRINT',
  Fire = 'FIRE',
  FlagFI = 'FLAG_FI',
  FlagNO = 'FLAG_NO',
  FlagUK = 'FLAG_UK',
  FlagDK = 'FLAG_DK',
  FlagSV = 'FLAG_SV',
  Folder = 'FOLDER',
  FolderTransparent = 'FOLDER_TRANSPARENT',
  Free = 'FREE',
  Home = 'HOME',
  HomeAlternative = 'HOME_ALTERNATIVE',
  House = 'HOUSE',
  Images = 'IMAGES',
  Info = 'INFO',
  InfoFilled = 'INFO_FILLED',
  Invoice = 'INVOICE',
  InvoiceNav = 'INVOICE_NAV',
  JobList = 'JOB_LIST',
  JobSearch = 'JOB_SEARCH',
  Languages = 'LANGUAGES',
  Map = 'MAP',
  MapPin = 'MAP_PIN',
  Mark = 'MARK',
  MarkInactive = 'MARK_INACTIVE',
  Medal = 'MEDAL',
  Message = 'MESSAGE',
  MetaAccommodationSize = 'accommodation-size',
  MetaAddressFrom = 'address-from',
  MetaAddressTo = 'address-to',
  MetaAdminComment = 'admin-comment',
  MetaCarModel = 'car-model',
  MetaCarRental = 'car-rental',
  MetaCleaningFrequency = 'cleaning-frequency',
  MetaContactPreference = 'contact-preference',
  MetaContainerSize = 'container-size',
  MetaCostEstimate = 'cost-estimate',
  MetaCustomerType = 'customer-type',
  MetaFallback = 'fallback',
  MetaFoodAndDrink = 'food-and-drink',
  MetaFuelType = 'meta-fuel-type',
  MetaLastRoadWorthinessTestDate = 'meta-last-road-worthiness-test-date',
  MetaLeadsSpaceSize = 'leads-space-size',
  MetaMaFeedbackTicket = 'ma-feedback-ticket',
  MetaMaterialsIncluded = 'materials-included',
  MetaMoveOutCleaning = 'move-out-cleaning',
  MetaNextRoadWorthinessTestDate = 'meta-next-road-worthiness-test-date',
  MetaNumberOfGuests = 'number-of-guests',
  MetaShaftCount = 'meta-shaft-count',
  MetaStorageSize = 'storage-size',
  MetaTenderNotApprovedReason = 'tender-not-approved-reason',
  MetaTenderRequestedStartAfterDate = 'tender-requested-start-after-date',
  MetaTenderRequestedStartBeforeDate = 'tender-requested-start-before-date',
  MetaTenderRequestedStartNowDate = 'tender-requested-start-now-date',
  MetaTenderRequestedStartWheneverDate = 'tender-requested-start-whenever-date',
  MetaTenderStartDate = 'tender-start-date',
  MetaTransmissionType = 'transmission-type',
  MetaVehicleMileage = 'vehicle-mileage',
  MetaVehicleRegistrationPlate = 'vehicle-registration-plate',
  MetaWeight = 'meta-weight',
  MittanbudXL = 'MITTANBUD_XL',
  Mobile = 'MOBILE',
  More = 'MORE',
  NavigationFolders = 'NAVIGATION_FOLDERS',
  New = 'NEW',
  Newsletter = 'NEWSLETTER',
  NoImage = 'NO_IMAGE',
  Notifications = 'NOTIFICATIONS',
  Offer = 'OFFER',
  Palette = 'PALETTE',
  Password = 'PASSWORD',
  PasteIn = 'PASTE_IN',
  Pdf = 'PDF',
  Pencil = 'PENCIL',
  Person = 'PERSON',
  PersonInfo = 'PERSON_INFO',
  Phone = 'PHONE',
  Phone1 = 'PHONE1',
  Plus = 'PLUS',
  Ppt = 'PPT',
  Print = 'PRINT',
  Products = 'PRODUCTS',
  Profile = 'PROFILE',
  PadlockUnlock = 'PADLOCK_UNLOCK',
  Qualifications = 'QUALIFICATIONS',
  Receipts = 'RECEIPTS',
  RemoveFavourite = 'REMOVE_FAVOURITE',
  Report = 'REPORT',
  Restore = 'RESTORE',
  Revert = 'REVERT',
  Rocket = 'ROCKET',
  Sad = 'SAD',
  SadFace = 'SAD_FACE',
  Search = 'SEARCH',
  SendMail = 'SEND_MAIL',
  SendOffer = 'SEND_OFFER',
  Settings = 'SETTINGS',
  Share = 'SHARE',
  Shield = 'SHIELD',
  ShieldAlt = 'SHIELD_ALT',
  ShieldCheck = 'SHIELD_CHECK',
  SignOut = 'SIGN_OUT',
  Sparkle = 'SPARKLE',
  Star = 'STAR',
  StarGreen = 'STAR_GREEN',
  Stars = 'STARS',
  Subscription = 'SUBSCRIPTION',
  Swap = 'SWAP',
  Support = 'SUPPORT',
  Team = 'TEAM',
  Templates = 'TEMPLATES',
  Time = 'TIME',
  TagMoney = 'TAG_MONEY',
  Toolbox = 'TOOLBOX',
  TuneAlt = 'TUNE_ALT',
  Unlocked = 'UNLOCKED',
  User = 'USER',
  UserArrowDropdown = 'USER_ARROW_DROPDOWN',
  Wave = 'WAVE',
  Won = 'WON',
  WonGold = 'WON_GOLD',
  Word = 'WORD',
  Worker = 'WORKER',
  WorldWideWeb = 'WORLD_WIDE_WEB',
  Xls = 'XLS',
  Xps = 'XPS',
  Globe = 'GLOBE',

  // New icons name
  NEW_Close = 'NEW_CLOSE',
  NEW_Info = 'NEW_INFO',
  // Desktop Nav
  NEW_ChatFlat = 'NEW_CHAT',
  NEW_JobListFlat = 'NEW_JOBLISTFLAT',
  NEW_CompanyInfoFlat = 'NEW_COMPANYINFOFLAT',
  NEW_ProfileFlat = 'NEW_PROFILEFLAT',
  NEW_SettingsFlat = 'NEW_SETTINGSFLAT',
  // Mobile Nav
  NEW_ChatFlatMobile = 'NEW_CHATMOBILE',
  NEW_PersonInfo = 'NEW_PERSON_INFO',
  NEW_Person = 'NEW_PERSON',
  // Request icons
  TrophyRequested = 'TROPHY_REQUESTED',
  StarRequested = 'STAR_REQUESTED',
  SpecialCheck = 'SPECIAL_CHECK',
  HexHeadFlat = 'HEX_HEAD_FLAT',
  EmailNotification = 'EMAIL_NOTIFICATION',
  ClipboardCancel = 'CLIPBOARD_CANCEL',
}

export default IconPickerName;
