import { FC } from 'react';

import { BoligmappaRequested } from '@components/elements/BoligmappaRequested';
import { JobTraits } from '@components/layout/Job/JobTraits';
import { StyledMobileContentContainer } from '@components/layout/Job/styled/StyledMobileContentContainer';
import { isBoligmappaRequestedForJob } from '@internals/business-shared/src/utils/BoligMappaUtils';
import {
  FileFragment,
  ImageFragment,
  JOB_OWNER_QUERY_job_Job,
  JOB_OWNER_QUERY_job_Job_contactInfo,
  JOB_QUERY_jobMeta,
} from '@internals/business-shared/src/utils/generated/generated';
import { isNullOrUndefined } from '@internals/business-shared/src/utils/objectUtils';
import { Job } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import { useTranslation } from 'react-i18next';

import { JobCarSpec } from './JobCarSpec';
import JobContentOwnerInfo from './JobContentOwnerInfo';
import { JobDataItem } from './JobDataItem';
import { JobDetails } from './JobDetails';
import { JobFiles } from './JobFiles';
import { JobGenericsMetadata } from './JobGenericsMetadata';
import { JobHtmlContentRow } from './JobHtmlContentRow';
import { JobImages } from './JobImages';
import { JobTransportAddress } from './JobTransportAddress';
import { JobContentSection } from './styled/JobContentSection';

interface JobOverviewMobileProps {
  job: Pick<
    Job,
    | 'answerCount'
    | 'answerCountLimit'
    | 'description'
    | 'address'
    | 'addressHint'
    | 'files'
    | 'images'
    | 'id'
  > & {
    jobBusinessRelationState: Pick<
      Job['jobBusinessRelationState'],
      'isWon' | 'isAnswered'
    >;
  };
  meta: JOB_QUERY_jobMeta;
  traits: JobTraitsQueryData | null;
  owner?: JOB_OWNER_QUERY_job_Job['owner'];
  contactDetails: JOB_OWNER_QUERY_job_Job_contactInfo;
  isLoadingOwnerDetails: boolean;
  answerInProgress: boolean;
  onClickAttachment?: (file: ImageFragment | FileFragment) => void;
}

export const JobOverviewMobile: FC<JobOverviewMobileProps> = ({
  job,
  traits,
  meta,
  owner,
  isLoadingOwnerDetails,
  contactDetails,
  answerInProgress,
  onClickAttachment,
}) => {
  const { t } = useTranslation();

  const {
    answerCount,
    answerCountLimit,
    description,
    address,
    addressHint,
    files,
    images,
    id,
    jobBusinessRelationState,
  } = job;
  const { isAnswered } = jobBusinessRelationState;

  return (
    <StyledMobileContentContainer data-testid="job-card-description">
      {meta.toAddress && (
        <JobContentSection>
          <JobTransportAddress
            address={address}
            addressHint={addressHint}
            destinationAddress={meta.toAddress}
            showFullAddress={isAnswered}
          />
        </JobContentSection>
      )}
      {description && (
        <JobContentSection>
          <JobHtmlContentRow>{description}</JobHtmlContentRow>
        </JobContentSection>
      )}
      <JobContentSection>
        <JobDetails
          contactPreference={meta.contactPreference}
          answerCount={answerCount}
          answerCountLimit={answerCountLimit}
          customerType={meta.customerType}
          requestedStart={meta.requestedStart}
        />
      </JobContentSection>
      {!!images?.length && (
        <JobContentSection data-testid="job-card-image-section">
          <JobImages images={images} onClick={onClickAttachment} />
        </JobContentSection>
      )}
      {!!files?.length && (
        <JobContentSection data-testid="job-card-file-section">
          <JobFiles files={files} onClick={onClickAttachment} />
        </JobContentSection>
      )}
      {meta.car && (
        <JobContentSection data-testid="job-card-car-section">
          <JobCarSpec
            carData={meta.car}
            showSensitiveInfo={!answerInProgress && isAnswered}
          />
        </JobContentSection>
      )}
      <JobContentSection>
        {!!meta.generics.length && <JobGenericsMetadata meta={meta.generics} />}
        {!isNullOrUndefined(traits) && <JobTraits traits={traits} />}
        <JobDataItem title={t('job.item.id')} text={id} />
      </JobContentSection>
      <JobContentSection>
        <JobContentOwnerInfo
          isAnswered={!answerInProgress && isAnswered}
          owner={owner}
          address={address}
          addressHint={addressHint}
          showAddressAndMap={!meta.toAddress}
          isLoading={isLoadingOwnerDetails}
          contactDetails={contactDetails}
          contactPreference={meta.contactPreference}
          jobId={id}
          traits={traits}
          isBoligmappaRequested={isBoligmappaRequestedForJob(meta)}
        />
      </JobContentSection>
    </StyledMobileContentContainer>
  );
};
