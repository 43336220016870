import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ReactComponent as XLLogoSquare } from '@internals/business-shared/src/assets/illustrations/XLLogoSquare.svg';
import { ReactComponent as StrokeGraphic } from '@internals/business-shared/src/assets/illustrations/XLStroke.svg';
import HomeRenovations from '@internals/business-shared/src/assets/images/home-renovations.png';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const Card: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const checklistTextItems = [
    t('job.modal.mittanbudXL.card.checklist.item1'),
    t('job.modal.mittanbudXL.card.checklist.item2'),
    t('job.modal.mittanbudXL.card.checklist.item3'),
  ];

  return (
    <>
      <Div
        backgroundImage={`url(${HomeRenovations})`}
        backgroundPosition="center bottom"
        backgroundSize="cover"
        borderTopLeftRadius={theme.radii[2]}
        borderTopRightRadius={theme.radii[2]}
        color="black.black0"
        py={7}
        px={6}
        style={{ clipPath: 'polygon(0 0, 100% 0, 100% 75%, 0 100%)' }}
      >
        <Text.p fontWeight="regular">
          {t('job.modal.mittanbudXL.card.label')}
        </Text.p>
        <Text.p fontWeight="medium" fontSize={5}>
          {t('job.modal.mittanbudXL.card.name')}
        </Text.p>
      </Div>
      <Div position="relative" zIndex={Layer.above}>
        <Div position="absolute" width={1} left={0} bottom={-24} height={60}>
          <StrokeGraphic />
        </Div>
        <Div position="absolute" height={90} right={12} bottom={16}>
          <XLLogoSquare />
        </Div>
      </Div>
      <Div
        bg="black.black0"
        borderBottomLeftRadius={theme.radii[2]}
        borderBottomRightRadius={theme.radii[2]}
        bottom={30}
        pb={6}
        position="relative"
        pt={9}
        px={7}
        style={{ clipPath: 'polygon(0 30px, 100% 0, 100% 100%, 0 100%)' }}
      >
        <Div>
          {checklistTextItems.map((text) => (
            <Div key={text} display="flex" alignItems="center" mb={4}>
              <Div mr={4}>
                <FireIcon
                  width={18}
                  height={18}
                  iconName={IconPickerName.Check}
                  fill="blue.base"
                />
              </Div>
              <Text.p mb={0}>{text}</Text.p>
            </Div>
          ))}
        </Div>
      </Div>
    </>
  );
};
