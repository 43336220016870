import gql from 'graphql-tag';

const JOB_CHAT_META_FRAGMENT = gql`
  fragment JobChatMetaFragment on ChatMeta {
    chatId
    firstMessageTs
  }
`;

export default JOB_CHAT_META_FRAGMENT;
