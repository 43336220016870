import '@utils/i18n';
import { ErrorBoundaryView } from '@components/layout/ErrorBoundary';
import { FeatureFlagsProvider } from '@contexts/FeatureFlagsProvider';
import { UserRole } from '@internals/business-graphql/src/graphql/generated/types';
import log from '@internals/business-shared/src/utils/devLog';
import {
  FireballThemeProvider,
  remppatori,
} from '@schibsted-smb/fireball-themes';
import { ErrorBoundary } from '@utils/initBugsnag';
import { createRoot } from 'react-dom/client';

import App from './App';
import { DeviceSizeProvider } from './contexts/DeviceSizeContext';
import { authHandlers } from './modules/authentication/handlers';
import { store } from './modules/store';

const container = document.getElementById('root');

const root = createRoot(container);

store.getState().initialize(authHandlers, UserRole.Employee);

root.render(
  <ErrorBoundary
    FallbackComponent={ErrorBoundaryView}
    onError={(event) => {
      if (event.errors.length > 0) {
        // eslint-disable-next-line no-param-reassign
        event.errors[0].errorClass = 'TOP LEVEL APP ERROR';
      }
      event.addMetadata('app', { reason: 'TOP LEVEL APP ERROR' });
      log.error('TOP LEVEL APP ERROR: ', event.errors);
    }}
  >
    <FeatureFlagsProvider>
      <FireballThemeProvider theme={remppatori} bodyColor="#f7f8fe">
        <DeviceSizeProvider>
          <App />
        </DeviceSizeProvider>
      </FireballThemeProvider>
    </FeatureFlagsProvider>
  </ErrorBoundary>
);
