import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const CHAT_DETAILS_TOP_BAR_HEIGHT = 60;

const ChatMessageSearchContainer = styled(Div)`
  background-color: ${({ theme }): string => theme.colors.black.black0};
  height: ${CHAT_DETAILS_TOP_BAR_HEIGHT}px;
  min-height: ${CHAT_DETAILS_TOP_BAR_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  padding: ${({ theme }): string =>
    `${theme.space[6]}px ${theme.space[6]}px 0 ${theme.space[6]}px`};
`;

export default ChatMessageSearchContainer;
