import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useMutation } from '@apollo/client';
import { useFlagEnabled } from '@components/base/CheckFlag';
import { BankIdVerification } from '@components/elements/BankIdVerification';
import { CompanyStateAlert } from '@components/elements/CompanyStateAlert';
import { JobListItem } from '@components/elements/JobListItem';
import JobListItemDeleted from '@components/elements/JobListItem/JobListItemDeleted';
import { JobsDirectRegistrationBanner } from '@components/elements/JobsDirectRegistrationBanner';
import NotFound from '@components/elements/NotFound';
import Filters from '@components/layout/Jobs/Filters';
import JobsListHeader from '@components/layout/Jobs/JobsListHeader';
import { JobsListHeaderV2 } from '@components/layout/Jobs/JobsListHeaderV2';
import JobItemsContainer from '@components/layout/Jobs/styled/JobItemsContainer';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { useJobListQueryResult } from '@contexts/JobListContext';
import useAnalytics from '@hooks/useAnalytics';
import useJobListSearchParams, {
  useMapJobListSearchParamsToQueryVariables,
} from '@hooks/useJobListSearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import {
  deleteJobOptimisticResponse,
  deleteJobUpdate,
} from '@internals/business-shared/src/cache/updates/deleteJob';
import {
  favouriteJobOptimisticResponse,
  favouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/favouriteJob';
import {
  restoreJobOptimisticResponse,
  restoreJobUpdate,
} from '@internals/business-shared/src/cache/updates/restoreJob';
import {
  unfavouriteJobOptimisticResponse,
  unfavouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/unfavouriteJob';
import { useCompanyStateIsVerifiedWithSecureId } from '@internals/business-shared/src/contexts/CompanyStateContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges } from '@internals/business-shared/src/utils/generated/generated';
import {
  DELETE_JOB_MUTATION,
  DeleteJobMutationPayload,
  DeleteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/DeleteJob/DeleteJobMutation';
import {
  FAVOURITE_JOB,
  FavouriteJobMutationPayload,
  FavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/FavouriteJob/FavouriteJobMutation';
import {
  RESTORE_JOB_MUTATION,
  RestoreJobMutationPayload,
  RestoreJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/RestoreJob/RestoreJobMutation';
import {
  UNFAVOURITE_JOB,
  UnfavouriteJobMutationPayload,
  UnfavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/UnfavouriteJob/UnfavouriteJobMutation';
import {
  JOBS_LIST_QUERY,
  JobsListQueryVariables,
} from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import {
  JOB_LIST_DEFAULT_COUNT,
  JOB_LIST_FILTERS_VARIABLE_NAMES,
} from '@root/src/apollo/cache';
import { JobsFiltersProvider } from '@root/src/contexts/JobFiltersContext';
import Paths from '@router/paths';
import { Card, Column, Div, Pagination, Row } from '@schibsted-smb/fireball';
import ContentLoader from '@utils/contentLoaders';
import { FilterCounter } from '@utils/jobList';
import { repeatLoaderComponent } from '@utils/repeatComponent';
import { useTranslation } from 'react-i18next';

export interface JobsListProps {
  placeholderCount: FilterCounter['count'];
}

type DeletedJobItem = {
  job: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges;
  jobsListQueryVariables: JobsListQueryVariables;
  index: number;
};

const JobsList: FC<JobsListProps> = ({ placeholderCount }) => {
  const isMobile = useIsMobile();
  const { track, page: pageTracking } = useAnalytics();
  const { t } = useTranslation();
  const { hasSearchParams, search, page, setPageParam } =
    useJobListSearchParams();
  const [, resetParams] = useSetSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const jobsListQueryVariables = useMapJobListSearchParamsToQueryVariables();
  const [filtersOpen, openFilters, hideFilters] = useBoolean();
  const pillNavigationEnabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchPillsView
  );
  const isBusinessBankIDVerificationEnabled = useFlagEnabled(
    FeatureFlags.BizSecureID
  );
  const isCompanyVerifiedWithSecureId = useCompanyStateIsVerifiedWithSecureId();

  const showDirectRegistrationJobsBanner =
    jobsListQueryVariables.listId === JobListId.Direct;

  // stringify variables object to make sure scroll effect is run only on list params change
  const stringifiedJobsListVariables = JSON.stringify(jobsListQueryVariables);
  useEffect(() => {
    if (isMobile) {
      document.body.scrollTo(0, 0);
    }
  }, [isMobile, stringifiedJobsListVariables]);

  useEffect(() => {
    if (!isMobile) {
      window.scrollTo(0, 0);
    }
  }, [isMobile, jobsListQueryVariables.listId, page]);

  const [deletedJobs, setDeletedJobs] = useState<DeletedJobItem[]>([]);

  const hasFilterProperies = (
    obj: JobsListQueryVariables | Record<string, unknown>
  ) =>
    JOB_LIST_FILTERS_VARIABLE_NAMES.some((name) =>
      Object.prototype.hasOwnProperty.call(obj, name)
    );
  const hasFiltersApplied = hasFilterProperies(jobsListQueryVariables);

  const { data, loading } = useJobListQueryResult();
  useEffect(() => {
    if (!data || loading) return;
    // remove deleted items from previous network-only request
    if (hasFiltersApplied) {
      setDeletedJobs((prevDeletedJobs) =>
        prevDeletedJobs.filter(
          (dJob) => !hasFilterProperies(dJob.jobsListQueryVariables)
        )
      );
    }
  }, [loading]);

  const totalPages = data?.jobConnection?.pageInfo?.totalPages ?? 0;
  const nextPage = totalPages > page ? page + 1 : page;

  const [fetchNextPage, { called }] = useLazyQuery(JOBS_LIST_QUERY, {
    fetchPolicy: hasFiltersApplied ? 'network-only' : 'cache-first', // use network-only data when job list is filtered
    variables: { ...jobsListQueryVariables, page: nextPage },
  });

  // find previously deleted items (on this list)
  const currentListDeletedJobs = deletedJobs.filter(
    (item) =>
      JSON.stringify(item.jobsListQueryVariables) ===
      JSON.stringify(jobsListQueryVariables)
  );
  const addDeletedJobsToPayload = (
    payload: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges[],
    deletedJobsArray: DeletedJobItem[]
  ) => {
    const items = [...payload];
    deletedJobsArray.forEach((deletedItem) => {
      const isNotDeletedJobOnTheList = !items.some(
        (item) => item.node.id === deletedItem.job.node.id
      );
      if (isNotDeletedJobOnTheList) {
        items.splice(deletedItem.index, 0, deletedItem.job);
      }
    });
    return items;
  };

  const payload = data?.jobConnection.edges || [];

  // add deleted items to payload (render restore job items)
  const extendedPayload = currentListDeletedJobs.length
    ? addDeletedJobsToPayload(payload, currentListDeletedJobs)
    : payload;

  const jobListPageInfoPayload = data?.jobConnection.pageInfo || null;

  const [removeJobFromFavourites] = useMutation<
    UnfavouriteJobMutationPayload,
    UnfavouriteJobMutationVariables
  >(UNFAVOURITE_JOB);
  const [addJobToFavourites] = useMutation<
    FavouriteJobMutationPayload,
    FavouriteJobMutationVariables
  >(FAVOURITE_JOB);
  const [restoreJobMutation] = useMutation<
    RestoreJobMutationPayload,
    RestoreJobMutationVariables
  >(RESTORE_JOB_MUTATION);
  const [deleteJobMutation] = useMutation<
    DeleteJobMutationPayload,
    DeleteJobMutationVariables
  >(DELETE_JOB_MUTATION);

  const handleJobDeletionToggle = (
    job: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges,
    index: number
  ) => {
    if (job.node.jobBusinessRelationState.isAnswered) {
      return;
    }

    if (job.node.jobBusinessRelationState.isDeleted) {
      restoreJobMutation({
        variables: { jobId: job.node.id },
        optimisticResponse: restoreJobOptimisticResponse,
        update: restoreJobUpdate(job.node, {
          listId: jobsListQueryVariables.listId,
          page,
          count: JOB_LIST_DEFAULT_COUNT,
          index,
        }),
      });
      if (
        deletedJobs.some((deletedJob) => deletedJob.job.node.id === job.node.id)
      ) {
        setDeletedJobs((prevValue) =>
          prevValue.filter(
            (deletedJob) => deletedJob.job.node.id !== job.node.id
          )
        );
      }
    } else {
      track(ANEventSpace(ANEvent.Deleted, ANObject.Job, ANPage.JobList));
      deleteJobMutation({
        variables: { jobId: job.node.id },
        optimisticResponse: deleteJobOptimisticResponse,
        update: deleteJobUpdate(job.node),
      });
      if (
        index !== undefined &&
        !deletedJobs.some((dJob) => dJob.job.node.id === job.node.id)
      )
        setDeletedJobs((prevValue) =>
          [
            ...prevValue,
            {
              // set job.jobBusinessRelationState.isDeleted to true to render restore job item
              job: {
                ...job,
                node: {
                  ...job.node,
                  jobBusinessRelationState: {
                    ...job.node.jobBusinessRelationState,
                    isDeleted: true,
                  },
                },
              },
              jobsListQueryVariables,
              index,
            },
          ].sort((firstJob, secondJob) =>
            firstJob.index > secondJob.index ? 1 : -1
          )
        );
    }
  };

  const handleJobFavouriteToggle = (
    job: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges,
    isFavourite: boolean
  ) => {
    if (isFavourite) {
      removeJobFromFavourites({
        variables: { jobId: job.node.id },
        optimisticResponse: unfavouriteJobOptimisticResponse,
        update: unfavouriteJobUpdate(job.node.id),
      });
    } else {
      track(ANEventSpace(ANEvent.Favoured, ANObject.Job, ANPage.JobList));
      addJobToFavourites({
        variables: { jobId: job.node.id },
        optimisticResponse: favouriteJobOptimisticResponse,
        update: favouriteJobUpdate(job.node),
      });
    }
  };

  useEffect(() => {
    if (!called && totalPages && totalPages >= nextPage && nextPage > page) {
      fetchNextPage();
    }
  }, [called, fetchNextPage, nextPage, page, totalPages]);

  /* Just for the analytics report */
  useEffect(() => {
    if (jobsListQueryVariables.query) {
      track(ANEventSpace(ANEvent.Searched, ANObject.JobCard, ANPage.JobList), {
        searchTerm: jobsListQueryVariables.query,
      });
    }
  }, [jobsListQueryVariables.query]);

  useEffect(() => {
    if (!loading && extendedPayload.length === 0) {
      pageTracking(ANSpace(ANObject.EmptyJobList, ANPage.JobList));
    }
  }, [loading, extendedPayload, track]);

  const ListHeader = useMemo(() => {
    if (pillNavigationEnabled) return <JobsListHeaderV2 />;
    if (isMobile) return null;
    return (
      <JobsListHeader filtersOpen={filtersOpen} onFiltersOpen={openFilters} />
    );
  }, [isMobile, filtersOpen, openFilters, pillNavigationEnabled]);

  const handleOpenJob = (index: number) => {
    track(ANEventSpace(ANEvent.Clicked, ANObject.Job, ANPage.JobList), {
      positionInList: index,
      page,
    });
  };

  return (
    <JobsFiltersProvider>
      <Column
        width={[1, null, null, null, null, 9 / 12, 9 / 12, 8 / 12]}
        px={[0, null, null, null, null, 4]}
        mb={[4, null, null, null, null, 6]}
      >
        <Row mt={0} pb={isMobile ? 0 : 5}>
          <CompanyStateAlert />
        </Row>
        {showDirectRegistrationJobsBanner && !pillNavigationEnabled && (
          <JobsDirectRegistrationBanner />
        )}
        {isBusinessBankIDVerificationEnabled &&
          !isCompanyVerifiedWithSecureId && <BankIdVerification separated />}
        {!isMobile && filtersOpen && (
          <Card boxShadow="l" mb={4} p={0}>
            <Filters onClose={hideFilters} />
          </Card>
        )}
        <Card
          p={0}
          boxShadow={['none', null, null, null, null, 'l']}
          ref={containerRef}
        >
          {ListHeader}
          <JobItemsContainer>
            {!data && loading && (
              <div data-testid="content-loader">
                {repeatLoaderComponent(
                  placeholderCount || 10,
                  ContentLoader.JobsList
                )}
              </div>
            )}
            {!loading && extendedPayload.length === 0 && hasSearchParams && (
              <NotFound
                title={t('job.list.empty.title')}
                text={t('job.list.empty.queryText')}
                queryText={search}
                button={{
                  text: t('job.list.empty.clearQuery'),
                  onClick: () => resetParams(),
                }}
              />
            )}
            {!loading && extendedPayload.length === 0 && !hasSearchParams && (
              <NotFound
                title={t('job.list.empty.title')}
                text={t('job.list.empty.text')}
                link={{
                  url: Paths.SettingsCustomerService,
                  text: t('general.label.customerService'),
                }}
                button={{
                  text: t('job.list.empty.reloadPage'),
                  onClick: () => window.location.reload(),
                }}
              />
            )}
            {showDirectRegistrationJobsBanner && pillNavigationEnabled && (
              <JobsDirectRegistrationBanner mb={0} minHeight="56px" />
            )}
            {extendedPayload?.map((job, index) =>
              jobsListQueryVariables.listId !== JobListId.Deleted &&
              job.node.jobBusinessRelationState.isDeleted ? (
                <JobListItemDeleted
                  key={`LIST-${jobsListQueryVariables.listId}-ITEM-${job.node.id}`}
                  onRestore={() => handleJobDeletionToggle(job, index)}
                  id={job.node.id}
                  title={job.node.title}
                  isLast={
                    payload[payload.length - 1] &&
                    job.node.id === payload[payload.length - 1].node.id
                  }
                />
              ) : (
                <JobListItem
                  key={`LIST-${jobsListQueryVariables.listId}-ITEM-${job.node.id}`}
                  job={job.node}
                  isLast={
                    payload[payload.length - 1] &&
                    job.node.id === payload[payload.length - 1].node.id
                  }
                  jobListId={jobsListQueryVariables.listId}
                  jobListContainerRef={containerRef}
                  handleJobDelete={() => handleJobDeletionToggle(job, index)}
                  handleJobFavourite={() =>
                    handleJobFavouriteToggle(
                      job,
                      job.node.jobBusinessRelationState.isFavourite
                    )
                  }
                  handleOpenJob={() => handleOpenJob(index)}
                />
              )
            )}
          </JobItemsContainer>
        </Card>
        {jobListPageInfoPayload && !!jobListPageInfoPayload.totalCount && (
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={6}
            mb={[6, null, null, null, null, 0]}
            data-testid="jobs-list-pagination"
          >
            <Pagination
              itemsPerPage={jobListPageInfoPayload.pageSizeLimit || 10}
              count={jobListPageInfoPayload.totalCount}
              currentPage={page}
              getCurrentPage={(p: number) => setPageParam(p.toString())}
              visiblePagesOffset={isMobile ? 0.5 : 2}
            />
          </Div>
        )}
      </Column>
    </JobsFiltersProvider>
  );
};

export default JobsList;
