import { DAY_KEYS } from '../constants/days';
import { GQLErrorState } from '../errors';
import { GQLOpeningHours } from '../interfaces/graphql/OpeningHours.interface';
import { OpeningHours } from './OpeningHours.interface';

export enum HourError {
  FromInErrorState = 'FROM_IN_ERROR_STATE',
  FromIsRequired = 'FROM_IS_REQUIRED',
  FromWrongStart = 'FROM_WRONG_START',
  ToInErrorState = 'TO_IN_ERROR_STATE',
  ToIsRequired = 'TO_IS_REQUIRED',
}

export const hasDifferentOpeningHours = (
  openingHours: GQLOpeningHours
): boolean => {
  const WEEKDAYS = [
    DAY_KEYS[0],
    DAY_KEYS[1],
    DAY_KEYS[2],
    DAY_KEYS[3],
    DAY_KEYS[4],
  ];
  const from = openingHours?.monday?.from ?? '';
  const to = openingHours?.monday?.to ?? '';
  return WEEKDAYS.some(
    (day) =>
      from !== (openingHours[day]?.from ?? '') ||
      to !== (openingHours[day]?.to ?? '')
  );
};

export const hasWeekendsOpeningHours = (
  openingHours: GQLOpeningHours
): boolean => {
  const WEEKEND = [DAY_KEYS[5], DAY_KEYS[6]];
  return WEEKEND.some(
    (day) =>
      openingHours[day] &&
      (openingHours[day]?.from ?? '') !== '' &&
      (openingHours[day]?.to ?? '') !== ''
  );
};

export const resetOpeningHours = ({
  DAYS_OF_THE_WEEK,
}: {
  DAYS_OF_THE_WEEK: string[];
}): OpeningHours[] => {
  // Initial state may be helpful in the future, when data comes from GQL
  return Array.from({ length: DAYS_OF_THE_WEEK.length }).map(
    (item, index): OpeningHours => ({
      from: '',
      to: '',
      dayNumber: index, // 0...6
      dayLabel: DAYS_OF_THE_WEEK[index],
      visible: true,
      muted: false,
    })
  );
};

export const getOpeningHourErrorType = (
  hour: OpeningHours,
  errorState: GQLErrorState
): HourError => {
  if (hour.muted) {
    return undefined;
  }

  const isHourFromMissing = !!hour.to && !hour.from;
  const isHourFromLargerOrEqualTo =
    !!hour.from && !!hour.to && hour.from >= hour.to;
  const isHourToMissing = !!hour.from && !hour.to;

  if (`${DAY_KEYS[hour.dayNumber]}from` in errorState) {
    return HourError.FromInErrorState;
  }
  if (`${DAY_KEYS[hour.dayNumber]}to` in errorState) {
    return HourError.ToInErrorState;
  }
  if (isHourFromMissing) {
    return HourError.FromIsRequired;
  }
  if (isHourToMissing) {
    return HourError.ToIsRequired;
  }
  if (isHourFromLargerOrEqualTo) {
    return HourError.FromWrongStart;
  }

  return undefined;
};
