import gql from 'graphql-tag';

import {
  DELETE_JOB_FOLDER as DELETE_JOB_FOLDER_Result,
  DELETE_JOB_FOLDERVariables,
} from '../../generated/generated';

export type DeleteJobFolderMutationVariables = DELETE_JOB_FOLDERVariables;
export type DeleteJobFolderMutationPayload = DELETE_JOB_FOLDER_Result;

export const DELETE_JOB_FOLDER = gql`
  mutation DELETE_JOB_FOLDER($folderId: ID!) {
    deleteJobFolder(input: { folderId: $folderId })
  }
`;
