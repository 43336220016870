import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const JobFormContainer: any = styled(Div)`
  width: 100%;
  position: relative;
  @media print {
    display: none;
  }
`;

export default JobFormContainer;
