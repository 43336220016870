import gql from 'graphql-tag';

import { JOB_ACCESS_CERTIFICATE_FRAGMENT } from './JobAccessCertificateFragment';

const JOB_ACCESS_FRAGMENT = gql`
  ${JOB_ACCESS_CERTIFICATE_FRAGMENT}
  fragment JobAccessFragment on AnswerAccess {
    code
    isOpen
    clip
    missingCertificates {
      ...JobAccessCertificateFragment
    }
  }
`;

export default JOB_ACCESS_FRAGMENT;
