import daTranslation from './translations/da/translation.json';
import enTranslation from './translations/en/translation.json';
import fiTranslation from './translations/fi/translation.json';
import nbTranslation from './translations/nb/translation.json';
import svTranslation from './translations/sv/translation.json';

export const LANGUAGE_KEY = 'lang';
export const LANGUAGE_EN = 'en';
export const LANGUAGE_NB = 'nb';
export const LANGUAGE_FI = 'fi';
export const LANGUAGE_DA = 'da';
export const LANGUAGE_SV = 'sv';
export const LOCALE_EN = 'en-US';
export const LOCALE_NB = 'nb-NO';
export const LOCALE_FI = 'fi-FI';
export const LOCALE_DA = 'da-DK';
export const LOCALE_SV = 'sv-SE';

type Translation = {
  [key: string]: string | Translation;
};

type LanguageListItem = {
  name: string;
  languageCode: LanguageCode;
  localeCode: LocaleCode;
  translation: Translation;
};

export const englishLanguage: LanguageListItem = {
  name: 'english',
  languageCode: LANGUAGE_EN,
  localeCode: LOCALE_EN,
  translation: enTranslation,
};

const norwegianLanguage: LanguageListItem = {
  name: 'norwegian',
  languageCode: LANGUAGE_NB,
  localeCode: LOCALE_NB,
  translation: nbTranslation,
};

const finnishLanguage: LanguageListItem = {
  name: 'finnish',
  languageCode: LANGUAGE_FI,
  localeCode: LOCALE_FI,
  translation: fiTranslation,
};

const danishLanguage: LanguageListItem = {
  name: 'danish',
  languageCode: LANGUAGE_DA,
  localeCode: LOCALE_DA,
  translation: daTranslation,
};

const swedishLanguage: LanguageListItem = {
  name: 'swedish',
  languageCode: LANGUAGE_SV,
  localeCode: LOCALE_SV,
  translation: svTranslation,
};

export const supportedLanguages: LanguageListItem[] = [
  englishLanguage,
  norwegianLanguage,
  finnishLanguage,
  danishLanguage,
  swedishLanguage,
];

export type LanguageCode =
  | typeof LANGUAGE_EN
  | typeof LANGUAGE_NB
  | typeof LANGUAGE_FI
  | typeof LANGUAGE_DA
  | typeof LANGUAGE_SV;
export type LocaleCode =
  | typeof LOCALE_EN
  | typeof LOCALE_NB
  | typeof LOCALE_FI
  | typeof LOCALE_DA
  | typeof LOCALE_SV;
