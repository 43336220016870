import { t } from '@internals/business-translations/src/i18n';

export enum JobBadgeVariant {
  DirectRegistration = 'direct-registration',
}

export type JobBadgeColorVariant = 'green';

export type JobBadgeStyles = Pick<
  CSSStyleDeclaration,
  'color' | 'backgroundColor' | 'borderColor'
>;

interface JobBadgeInfo {
  text: string;
  colorVariant: JobBadgeColorVariant;
}

export const getJobBadgeInfo = (variant: JobBadgeVariant): JobBadgeInfo => {
  const config: Record<JobBadgeVariant, JobBadgeInfo> = {
    [JobBadgeVariant.DirectRegistration]: {
      colorVariant: 'green',
      text: t('job.label.DIRECT_REGISTRATION'),
    },
  };

  return config[variant];
};
