import { FC } from 'react';

import { Logo } from '@components/base/Logo';
import { LogoContainer } from '@components/layout/PrintJob/styled/LogoContainer';
import { Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { PrintPageHeaderContainer } from './styled';

const PrintPageHeader: FC<{ title: string }> = ({ title }) => {
  const themeContext = useTheme();
  return (
    <PrintPageHeaderContainer
      position="relative"
      my={7}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <LogoContainer>
        <Logo height="1cm" isDark />
      </LogoContainer>
      <Text.p
        mb={0}
        height="20px"
        fontSize={2}
        fontWeight={themeContext.fontWeights.medium}
        verticalAlign="center"
      >
        {title}
      </Text.p>
    </PrintPageHeaderContainer>
  );
};

export default PrintPageHeader;
