import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const DropdownFilterButton = styled(Button)`
  display: block;
  width: 100%;
  padding: 15px 0 15px 30px;
  border: none;
  font-size: ${(props): string => props.theme.fontSizes[2]};
  font-weight: ${(props): number => props.theme.fontWeights.regular};
  text-align: left;
  color: ${(props): string => props.theme.colors.black.black9};
  background: ${(props): string => props.theme.colors.black.black0};

  &:hover {
    color: ${(props): string => props.theme.colors.black.black9};
    background: none;
  }

  &:focus {
    outline: none;
  }
`;

export default DropdownFilterButton;
