import { FC, useState } from 'react';

import DropdownItemButton from '@components/base/DropdownItemButton';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Dropdown, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useBoostCreditsModalContext } from '../../ModalContext';

export const CardDropdown: FC = () => {
  // State to control the dropdown due to component issues
  const [isOpen, setIsOpen] = useState(false);
  const { cardList, selectedCard, setSelectedCard, primaryContact } =
    useBoostCreditsModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { space } = useTheme();

  if (cardList.length === 0) {
    return null;
  }

  const handleUnselectCard = () => {
    setSelectedCard(null);
    setIsOpen(false);
  };

  const dropdownHeader =
    isOpen && selectedCard ? (
      <DropdownItemButton onClick={handleUnselectCard} p={5}>
        <Text.span color="black.black9" fontSize={3} mb={0}>
          {t('job.modal.boost.selectPackage.addNewCard')}
        </Text.span>
      </DropdownItemButton>
    ) : null;

  const dropdownItems = isOpen
    ? cardList.map((card) => (
        <DropdownItemButton key={card.id} onClick={() => setSelectedCard(card)}>
          <Text.span color="black.black9" fontSize={3} mb={0}>
            {card?.maskedCardNumber}
          </Text.span>
        </DropdownItemButton>
      ))
    : null;

  return (
    <Dropdown
      isOpen={isOpen}
      getIsDropdownOpen={
        primaryContact?.isCurrentUserPrimaryContact ? setIsOpen : null
      }
      items={dropdownItems}
      width={345}
      openedDropdownMargin={-space[2]}
      header={dropdownHeader}
    >
      <Button
        disabled={!primaryContact?.isCurrentUserPrimaryContact}
        mb={4}
        px={4}
        py={5}
        variant="secondary"
        width={345}
      >
        {selectedCard && (
          <Div mr={5}>
            <FireIcon
              width={24}
              height={24}
              iconName={IconPickerName.CreditCard}
              fill="black.black9"
            />
          </Div>
        )}
        <Text.span
          color="black.black9"
          fontSize={isMobile ? 2 : 3}
          width={321}
          mb={0}
        >
          {selectedCard
            ? selectedCard?.maskedCardNumber
            : t('job.modal.boost.selectPackage.addNewCard')}
        </Text.span>
        <Div ml={5}>
          <FireIcon
            width={24}
            height={24}
            iconName={IconPickerName.ArrowDropdown}
            fill="black.black9"
          />
        </Div>
      </Button>
    </Dropdown>
  );
};
