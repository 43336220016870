import gql from 'graphql-tag';

import {
  UPDATE_ANSWER_TEMPLATEVariables,
  UPDATE_ANSWER_TEMPLATE as UPDATE_ANSWER_TEMPLATE_Result,
} from '../../generated/generated';

export type UpdateAnswerTemplateMutationPayload = UPDATE_ANSWER_TEMPLATE_Result;
export type UpdateAnswerTemplateMutationVariables =
  UPDATE_ANSWER_TEMPLATEVariables;

export const UPDATE_ANSWER_TEMPLATE = gql`
  mutation UPDATE_ANSWER_TEMPLATE($input: UpdateAnswerTemplateInput!) {
    updateAnswerTemplate(input: $input) {
      success
      template {
        id
        title
        text
      }
    }
  }
`;
