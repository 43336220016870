export const ATTACHMENTS_MAX_ROW_ITEMS = 3;
export const ATTACHMENTS_CHEQUER_SIZE = 4;

export const checkIsLastInFirstRow = (
  length: number,
  index: number
): boolean => {
  const isChequer = length % ATTACHMENTS_CHEQUER_SIZE === 0;

  if (isChequer) {
    return index === 1;
  }

  if ((length === 1 && index === 0) || (length === 2 && index === 1)) {
    return true;
  }

  if (index === 2) {
    return true;
  }

  return false;
};

export const checkIsFirstInLastRow = (
  length: number,
  index: number
): boolean => {
  const positionInRow = length % ATTACHMENTS_MAX_ROW_ITEMS;
  const isChequer = length % ATTACHMENTS_CHEQUER_SIZE === 0;

  if (isChequer) {
    return index === length - 2;
  }

  if (positionInRow === 0 && index === length - ATTACHMENTS_MAX_ROW_ITEMS) {
    return true;
  }
  if (positionInRow === 2 && index === length - 2) {
    return true;
  }
  if (positionInRow === 1 && index === length - 1) {
    return true;
  }

  return false;
};

export const checkIsFirst = (length: number, index: number): boolean =>
  index === 0;
export const checkIsLast = (length: number, index: number): boolean =>
  index === length - 1;
export const checkIsChequer = (length: number): boolean =>
  length % ATTACHMENTS_CHEQUER_SIZE === 0;
export const checkIsFirstInPenultimate = (
  length: number,
  index: number
): boolean => (index === 0 && length === 5) || (index === 3 && length === 8);
