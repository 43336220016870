import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  UPLOAD_BUSINESS_ALBUM_IMAGESVariables,
  UPLOAD_BUSINESS_ALBUM_IMAGES as UPLOAD_BUSINESS_ALBUM_IMAGES_Result,
} from '../../generated/generated';

export type UploadBusinessAlbumImagesPayload =
  UPLOAD_BUSINESS_ALBUM_IMAGES_Result;
export type UploadBusinessAlbumImagesVariables =
  UPLOAD_BUSINESS_ALBUM_IMAGESVariables;

export const isUploadBusinessAlbumImagesSuccessResponse = (
  response?: UPLOAD_BUSINESS_ALBUM_IMAGES_Result | null
): response is UPLOAD_BUSINESS_ALBUM_IMAGES_Result => {
  return !!response?.uploadBusinessAlbumImages;
};

export const UPLOAD_BUSINESS_ALBUM_IMAGES = gql`
  ${IMAGE_FRAGMENT}
  mutation UPLOAD_BUSINESS_ALBUM_IMAGES(
    $input: UploadBusinessAlbumImagesInput!
  ) {
    uploadBusinessAlbumImages(input: $input) {
      albumImages {
        id
        description
        image {
          ...ImageFragment
        }
      }
    }
  }
`;
