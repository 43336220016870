import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface ImageContainerProps {
  isSelected: boolean;
}

export const ImageContainer = styled(Div)<ImageContainerProps>`
  bottom: ${({ isSelected }) => (isSelected ? '-15px' : '-30px')};
  filter: ${({ isSelected }) => (isSelected ? 'none' : 'grayscale(1)')};
  position: absolute;
  right: 50px;
  transform: ${({ isSelected }) => (isSelected ? 'none' : 'scale(0.8)')};
  transition: all 0.3s ease-in-out;
`;
