import { FC, useMemo, useState } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { SelectedIconContainer } from '@components/layout/Profile/ProfileGraphic/styled/SelectedIconContainer';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Image, Pagination } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { SampleImagesGalleryGrid } from '../styled/SampleImagesGalleryGrid';
import { SelectImageButton } from '../styled/SelectImageButton';

export interface SampleImageType {
  id: string;
  filename: string;
  url: string;
  previewUrl: string;
  type: string;
}

interface SampleImageProps {
  isSelected: boolean;
  onImageSelect: VoidFunction;
  imageUrl: string;
}

interface SampleImagesGalleryProps {
  images: SampleImageType[];
  selectedImageId?: string;
  onImageSelect: (image: SampleImageType) => void;
}

const SampleImage: FC<SampleImageProps> = ({
  isSelected,
  onImageSelect,
  imageUrl,
}) => {
  const themeContext = useTheme();

  return (
    <SelectImageButton isSelected={isSelected} onClick={onImageSelect}>
      <Image src={imageUrl} width="100%" height="100%" />
      {isSelected && (
        <SelectedIconContainer>
          <FireIcon
            iconName={IconPickerName.CheckmarkCircled}
            width="35px"
            height="35px"
            fill={themeContext.colors.black.black0}
            variant="flat"
          />
        </SelectedIconContainer>
      )}
    </SelectImageButton>
  );
};

const IMAGES_PER_PAGE = 6;

export const SampleImagesGallery: FC<SampleImagesGalleryProps> = ({
  images,
  onImageSelect,
  selectedImageId,
}) => {
  const [page, setPage] = useState(1);
  const displayedImages = useMemo(() => {
    const startIndex = (page - 1) * IMAGES_PER_PAGE;
    const endIndex = startIndex + IMAGES_PER_PAGE;
    return images.slice(startIndex, endIndex);
  }, [page, images]);

  return (
    <Div my={6}>
      <SampleImagesGalleryGrid>
        {displayedImages.map((image) => {
          return (
            <SampleImage
              onImageSelect={() => onImageSelect(image)}
              imageUrl={image.previewUrl}
              isSelected={image.id === selectedImageId}
              key={image.id}
            />
          );
        })}
      </SampleImagesGalleryGrid>
      <Div display="flex" justifyContent="center" mt={6}>
        <Pagination
          itemsPerPage={IMAGES_PER_PAGE}
          count={images.length}
          currentPage={page}
          getCurrentPage={setPage}
        />
      </Div>
    </Div>
  );
};
