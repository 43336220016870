import { FC, useCallback } from 'react';

import { PhoneMask } from '@components/base/PhoneMask/PhoneMask';
import MultilineText from '@components/base/PhoneMask/styled/MultilineText';
import FileAttachment from '@components/elements/ChatMessage/ChatFile';
import { MessageProps } from '@components/elements/ChatMessage/ChatMessage';
import {
  MessageFileLink,
  MessageImagesContainer,
  MessageWrapper,
} from '@components/elements/ChatMessage/styled/index';
import { Gallery, GalleryImageItem } from '@components/elements/Gallery';
import useAnalytics from '@hooks/useAnalytics';
import { useImagesWithDimensions } from '@hooks/useImagesWithDimensions';
import { useJobAnalyticsQuery } from '@internals/business-shared/src/hooks/query/useJobAnalyticsQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  checkIsChequer,
  checkIsFirst,
  checkIsFirstInLastRow,
  checkIsFirstInPenultimate,
  checkIsLast,
  checkIsLastInFirstRow,
} from '@internals/business-shared/src/utils/ChatImagesArrangemetns';
import {
  CHAT_CONVERSATION_QUERY_chatMessages_edges_node_files as ChatFileType,
  CHAT_CONVERSATION_QUERY_chatMessages_edges_node_images as ChatImageType,
} from '@internals/business-shared/src/utils/generated/generated';
import { isOptimisticId } from '@internals/business-shared/src/utils/optimistic/id';
import { hasPhoneNumber } from '@internals/business-shared/src/utils/phoneNumber';
import { ImageProps } from '@schibsted-smb/fireball';
import urlWithProtocol from '@utils/urlWithProtocol';
import Linkify from 'react-linkify';
import { useTheme } from 'styled-components';

export interface MessageBodyProps extends MessageProps {
  background: string;
  businessMessage?: boolean;
  color: string;
  phoneMasking?: boolean;
  jobId?: string;
}

const getContainerStyle = (length: number) => (length % 4 === 0 ? 260 : 400);
const getAttachmentStyle = (
  length: number,
  index: number,
  businessMessage?: boolean
) => {
  const isChequer = checkIsChequer(length);
  const isFirst = checkIsFirst(length, index);
  const isLast = checkIsLast(length, index);
  const isFirstInLastRow = checkIsFirstInLastRow(length, index);
  const isLastInFirstRow = checkIsLastInFirstRow(length, index);
  const isFirstInFirstRow = checkIsFirstInPenultimate(length, index);

  return {
    ...(isFirst &&
      (!isChequer && !businessMessage
        ? { borderTopLeftRadius: '0' }
        : { borderTopLeftRadius: '15px' })),
    ...(isLastInFirstRow &&
      (!isChequer && businessMessage
        ? { borderTopRightRadius: '0' }
        : { borderTopRightRadius: '15px' })),
    ...(isFirstInLastRow && { borderBottomLeftRadius: '15px' }),
    ...(isFirstInFirstRow && { borderBottomLeftRadius: '15px' }),
    ...(isLast && { borderBottomRightRadius: '15px' }),
  };
};

export const MessageBody: FC<MessageBodyProps> = ({
  message,
  businessMessage,
  background,
  color,
  phoneMasking = false,
  jobId,
}) => {
  const themeContext = useTheme();
  const isOptimistic = isOptimisticId(message.id);
  const images = useImagesWithDimensions(message.images || []);
  const { track } = useAnalytics();
  const { data: jobAnalytics } = useJobAnalyticsQuery(jobId);

  const onPhoneReveal = useCallback(() => {
    track(ANEventSpace(ANEvent.Clicked, ANObject.ShowPhone, ANPage.Messages), {
      hasContract: jobAnalytics.isContract,
      isDialogue: jobAnalytics.isDialog,
      wonJob: jobAnalytics.isWon,
    });
  }, [
    jobAnalytics.isContract,
    jobAnalytics.isDialog,
    jobAnalytics.isWon,
    track,
  ]);

  const renderFile = useCallback(
    (file: ChatFileType, index: number, files: ChatFileType[]) =>
      isOptimistic ? (
        <FileAttachment
          key={file.id}
          name={file.name}
          type={file.fileType}
          isLast={checkIsLast(files.length, index)}
          businessMessage={businessMessage}
          inProgress
        />
      ) : (
        <MessageFileLink
          key={file.id}
          href={urlWithProtocol(file.url)}
          download
          target="_blank"
          rel="noreferrer"
        >
          <FileAttachment
            name={file.name}
            type={file.fileType}
            isLast={index === files.length - 1}
            businessMessage={businessMessage}
          />
        </MessageFileLink>
      ),
    [businessMessage, isOptimistic]
  );

  const renderImage = useCallback(
    (image: ChatImageType, index: number, imageList: ChatImageType[]) => {
      const imageCommonProps: Partial<ImageProps> = {
        borderRadius: themeContext.radii[2],
        width: '125px',
        height: '125px',
        objectFit: 'cover',
        ml: businessMessage ? '5px' : '0px',
        mr: !businessMessage ? '5px' : '0px',
        mb: '5px',
        ...getAttachmentStyle(imageList.length, index, businessMessage),
      };

      if (isOptimistic) {
        return (
          <GalleryImageItem
            key={image.id}
            item={image}
            imageProps={imageCommonProps}
            isLoading
          />
        );
      }

      return (
        <GalleryImageItem
          key={image.id}
          item={image}
          imageProps={imageCommonProps}
        />
      );
    },
    [themeContext.radii, businessMessage, isOptimistic]
  );

  const renderMessage = useCallback(
    (text: string) => {
      return (
        <MessageWrapper
          background={background}
          color={color}
          borderRadius="10px"
          width="100%"
          borderBottomRightRadius={businessMessage ? '0' : '10px'}
          borderBottomLeftRadius={businessMessage ? '10px' : '0'}
          data-testid="chat-message"
        >
          <Linkify>
            <MultilineText>{text}</MultilineText>
          </Linkify>
        </MessageWrapper>
      );
    },
    [background, businessMessage, color]
  );

  return (
    <>
      {message.text && businessMessage && renderMessage(message.text)}

      {message.text &&
        !businessMessage &&
        (phoneMasking && hasPhoneNumber(message.text) ? (
          <PhoneMask
            message={message}
            color={color}
            background={background}
            businessMessage={businessMessage}
            onReveal={onPhoneReveal}
          />
        ) : (
          renderMessage(message.text)
        ))}

      {message.files?.map(renderFile)}

      {!!message.images?.length && (
        <MessageImagesContainer
          maxWidth={getContainerStyle(message.images.length)}
        >
          <Gallery row>{images.map(renderImage)}</Gallery>
        </MessageImagesContainer>
      )}
    </>
  );
};
