import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { JobInfoAccordion } from '@components/elements/JobInfoAccordion';
import { BOLIGMAPPA_BRAND_COLOR } from '@internals/business-shared/src/utils/BoligMappaUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';

export const BoligmappaRequested: FC = () => {
  const { t } = useTranslation();
  const isEnabled = useFlagEnabled(FeatureFlags.BizRequestedBoligmappaJobCard);

  if (!isEnabled) return null;

  return (
    <JobInfoAccordion
      title={t('job.item.boligmappaRequested.title')}
      description={t('job.item.boligmappaRequested.text')}
      iconProps={{
        iconName: IconPickerName.BoligMappaNoCircle,
        variant: 'flat',
        fill: BOLIGMAPPA_BRAND_COLOR,
        width: 22,
        height: 22,
      }}
    />
  );
};
