import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  JOBS_SAVED_FILTERS_QUERY,
  JobsSavedFilters,
  JobsSavedFiltersQueryPayload,
} from '../../utils/query/JobsSavedFilters/JobsSavedFiltersQuery';

export interface JobsSavedFiltersQueryResult extends QueryResult {
  data: JobsSavedFilters | null;
}

export const useJobsSavedFiltersQuery = (
  options?: QueryHookOptions<JobsSavedFiltersQueryPayload>
): JobsSavedFiltersQueryResult => {
  const { data, ...rest } = useQuery<JobsSavedFiltersQueryPayload>(
    JOBS_SAVED_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

  return useMemo(
    () => ({ data: data?.jobWatchlistList || null, ...rest }),
    [data, rest]
  );
};
