import { useCallback, useMemo, useState } from 'react';

import { useCompanyWantsContactAboutProduct } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';

export enum ModalState {
  Confirm,
  Completed,
}

export interface ModalProps {
  isOpen: boolean;
  modalState: ModalState;
  loading: boolean;
  closeModal: VoidFunction;
  handleCheckQualify: () => Promise<void>;
}

export const useModal = (): [ModalProps, VoidFunction] => {
  const [isOpen, openModal, closeModal] = useBoolean(false);
  const [modalState, setModalState] = useState<ModalState>(ModalState.Confirm);
  const [requestContact, { loading }] = useCompanyWantsContactAboutProduct();
  const { t } = useTranslation();

  const handleCheckQualify = useCallback(async () => {
    try {
      await requestContact(ProductType.MittanbudXL);
      setModalState(ModalState.Completed);
    } catch (error) {
      ToastMessage(t('general.error.generic'));
    }
  }, [requestContact, t]);

  const onModalOpen = useCallback(() => {
    setModalState(ModalState.Confirm);
    openModal();
  }, [openModal]);

  const modalProps = useMemo<ModalProps>(
    () => ({
      isOpen,
      modalState,
      loading,
      closeModal,
      handleCheckQualify,
    }),
    [isOpen, modalState, loading, closeModal, handleCheckQualify]
  );

  return [modalProps, onModalOpen];
};
