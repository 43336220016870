export interface IframeMessageInterface {
  payload: {
    [key: string]: string | Record<string, any>;
  };
  source: string;
}

type IframeSingleMessage = {
  name: string;
  value: string | Record<string, any>;
};

export const IframeMessage = (
  name: string,
  value: string | Record<string, any>
): IframeMessageInterface => {
  return {
    payload: {
      [name]: value,
    },
    source: 'mittanbud',
  };
};
export const IframeMultiMessage = (
  messages: IframeSingleMessage[]
): IframeMessageInterface => {
  const payload = messages.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.name]: currentValue.value,
    }),
    {} as IframeSingleMessage
  );
  return {
    payload: { ...payload },
    source: 'mittanbud',
  };
};
