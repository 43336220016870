import { Div } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

const InputDiv = styled(Div)<{
  isAdjacent?: boolean;
  isDragActive?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  border: 1px solid;
  border-color: ${(props): string => props.theme.colors.black.black5};
  border-radius: 5px;
  background: ${(props): string =>
    props.disabled
      ? props.theme.colors.black.black2
      : props.theme.colors.black.black0};
  width: 100%;
  overflow: hidden;

  ${(props): string =>
    props.isDragActive
      ? `
  &::after {
    content: '${t('general.label.dragAndDrop')}';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${props.theme.colors.black.black0};
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    z-index: ${Layer.dropdown + Layer.below};
  }
  `
      : ''}

  @media only screen and (min-width: ${(props): string =>
    props.theme.breakpoints[4]}) {
    border: 1px solid ${(props): string => props.theme.colors.black.black5};
    border-radius: 5px;

    ${({ isAdjacent }) =>
      isAdjacent
        ? `
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    `
        : ''}

    &:focus-within {
      box-shadow: 0 0 0 3px rgba(0, 123, 199, 0.5);
      border: 1px solid ${(props): string => props.theme.colors.primary.base};
      border-radius: 0;
      z-index: ${Layer.base + Layer.above};
    }
  }
`;

export default InputDiv;
