import * as React from 'react';

import { Alert, Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface JobDangerAlertProps {
  text: string;
}

const JobDangerAlert: React.FC<
  React.PropsWithChildren<JobDangerAlertProps>
> = ({ text }) => {
  const themeContext = useTheme();
  return (
    <Alert
      variant="danger"
      size="medium"
      isCentered
      m={0}
      height="60px"
      data-testid="alert-job-danger"
      width="100%"
    >
      <Div display="flex" alignItems="center">
        <Text.p fontSize={1} fontWeight={themeContext.fontWeights.medium} m={0}>
          {text}
        </Text.p>
      </Div>
    </Alert>
  );
};

export default JobDangerAlert;
