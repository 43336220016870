import { useMemo, useCallback } from 'react';

import { useMutation } from '@apollo/client';
import { t } from '@internals/business-translations/src/i18n';

import {
  toggleNotificationDestinationOptimisticResponse,
  toggleNotificationDestinationUpdate,
} from '../../cache/updates/toggleNotificationDestination';
import { useBusinessNotificationsQuery } from '../../hooks/query/useBusinessNotificationsQuery';
import { useNotificationsDestinationsEnabledQuery } from '../../hooks/query/useNotificationsDestinationsEnabledQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '../../utils/analyticsNamespace';
import { NotificationDestinationType } from '../../utils/generated/generated';
import {
  TOGGLE_NOTIFICATION_DESTINATION,
  ToggleNotificationDestinationPayload,
  ToggleNotificationDestinationVariables,
} from '../../utils/mutation/ToggleNotificationDestination/ToggleNotificationDestination';
import { BusinessNotificationDestination } from '../../utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';
import {
  NotificationDestinationData,
  NotificationSettingsType,
  NotificationToggleType,
} from './notificationSettingsTypes';
import {
  getEditableNotifications,
  getNotificationEnabledData,
} from './notificationSettingsUtils';

interface StandardNotificationSettingsParams {
  track: (eventName: string, properties?: Record<string, unknown>) => void;
}

export const useStandardNotificationSettings = ({
  track,
}: StandardNotificationSettingsParams): NotificationSettingsType => {
  const { loading, data } = useBusinessNotificationsQuery();
  const { loading: loadingEnabled, data: notificationsEnabled } =
    useNotificationsDestinationsEnabledQuery();

  const [toggleNotificationDestination] = useMutation<
    ToggleNotificationDestinationPayload,
    ToggleNotificationDestinationVariables
  >(TOGGLE_NOTIFICATION_DESTINATION);

  const notifications = useMemo(() => {
    return data && notificationsEnabled
      ? getNotificationEnabledData(
          getEditableNotifications(data.children),
          notificationsEnabled || []
        )
      : [];
  }, [data, notificationsEnabled]);

  const onNotificationChange = useCallback(
    (
      variables: ToggleNotificationDestinationVariables['input'],
      destination: Pick<BusinessNotificationDestination, 'id' | 'type'>
    ) => {
      toggleNotificationDestination({
        variables: { input: variables },
        optimisticResponse: toggleNotificationDestinationOptimisticResponse(
          destination,
          !!variables.enabled,
          notificationsEnabled
        ),
        update: toggleNotificationDestinationUpdate,
      }).then(() => {
        track(
          ANEventSpace(
            ANEvent.Changed,
            ANObject.Notifications,
            ANPage.Settings
          ),
          {
            toggle: destination?.type,
            state: !!variables?.enabled,
          }
        );
      });
    },
    [toggleNotificationDestination, notificationsEnabled, track]
  );

  const createToggle = useCallback(
    (
      destination: NotificationDestinationData,
      notificationSlug: string
    ): NotificationToggleType => {
      const name = `${notificationSlug}-${destination.type}`;
      return {
        id: name,
        name,
        checked: destination.isEnabled,
        onChange: () => {
          onNotificationChange(
            {
              slug: notificationSlug,
              destinationType: destination.type,
              enabled: !destination.isEnabled,
            },
            destination
          );
        },
        type:
          destination.type === NotificationDestinationType.EMAIL
            ? 'email'
            : 'push',
        variant: 'standard',
      };
    },
    [onNotificationChange]
  );

  const notificationsData = useMemo(() => {
    return notifications.map((notification) => ({
      id: notification.id,
      name: t('settings.notifications.slug', {
        context: notification.slug,
        defaultValue: '-',
      }),
      toggles: [
        ...(notification.destinations.email
          ? [createToggle(notification.destinations.email, notification.slug)]
          : []),
        ...(notification.destinations.push
          ? [createToggle(notification.destinations.push, notification.slug)]
          : []),
      ],
    }));
  }, [notifications, createToggle]);

  return useMemo(
    () => ({
      loading: loading || loadingEnabled,
      data: notificationsData,
    }),
    [loading, loadingEnabled, notificationsData]
  );
};
