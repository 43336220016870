import { FC, RefObject, useCallback, useEffect, useRef } from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import ScrollPanel from '@components/base/ScrollPanel';
import ChatMessage from '@components/elements/ChatMessage';
import { ChatMessageListContainer } from '@components/layout/Messages/styled';
import useKeepScrollPosition from '@hooks/useKeepScrollPosition';
import { useChatConversationQuery } from '@internals/business-shared/src/hooks/query/useChatConversationQuery';
import { useChatConversationPolling } from '@internals/business-shared/src/hooks/useChatConversationPolling';
import {
  shouldDisplayMessageDate,
  shouldDisplayWriterAvatar,
} from '@internals/business-shared/src/utils/ChatUtils';
import { SortDirection } from '@internals/business-shared/src/utils/generated/generated';
import { ChatMessageEdge as ChatMessageType } from '@internals/business-shared/src/utils/query/ChatConversation/ChatConversation';
import { Div, Spinner, Text } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';

export interface ChatMessagesListProps {
  name: string;
  jobId: string;
  chatId: string;
  contractId?: string;
}

const MESSAGES_FETCH_MORE_COUNT = 10;
const SCROLLABLE_CONTAINER_ID = 'scrollableChatMessagesList';
const { name: productName } = getProduct();

const ChatMessagesList: FC<ChatMessagesListProps> = ({
  name,
  chatId,
  jobId,
  contractId,
}: ChatMessagesListProps) => {
  const { t } = useTranslation();
  const chatMessagesContainerRef: RefObject<HTMLDivElement> = useRef(null);
  const { data, error, loading, fetchMore } = useChatConversationQuery(chatId, {
    onCompleted: () => {
      // scroll chat view to last message when data is loaded
      if (chatMessagesContainerRef?.current) {
        chatMessagesContainerRef.current.scrollTo({
          top: chatMessagesContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    },
  });

  const messages = data?.edges || [];
  const pageInfo = data?.pageInfo || null;
  const canLoadMore = !error && messages.length < (pageInfo?.totalCount || 0);
  const loadMore = async () => {
    await fetchMore({
      variables: {
        first: MESSAGES_FETCH_MORE_COUNT,
        from: messages.length,
        orderByTime: SortDirection.DESC,
        id: chatId,
        last: undefined,
      },
    });
  };
  useChatConversationPolling({
    variables: { id: chatId, from: pageInfo?.totalCount },
  });

  useKeepScrollPosition(SCROLLABLE_CONTAINER_ID, [messages.length]);

  useEffect(() => {
    // due to known issue with infinite scroll https://github.com/ankeetmaini/react-infinite-scroll-component/issues/217
    // make sure full data is loaded when the list is visible without the need to scroll
    const scrollContainer = document.getElementById(SCROLLABLE_CONTAINER_ID);
    if (
      scrollContainer?.scrollHeight === scrollContainer?.clientHeight &&
      canLoadMore &&
      !loading
    ) {
      loadMore();
    }
  }, [canLoadMore, loading]);

  const renderMessage = useCallback(
    (
      message: ChatMessageType,
      index: number,
      collection: ChatMessageType[]
    ) => {
      return (
        <ChatMessage
          key={message.node.id}
          message={message.node}
          isSender={!!message.node.business}
          isLast={collection.length - 1 === index}
          showDate={shouldDisplayMessageDate(message, collection[index - 1])}
          showAvatar={shouldDisplayWriterAvatar(message, collection[index + 1])}
          name={name}
          contractId={contractId}
          jobId={jobId}
        />
      );
    },
    [contractId, name]
  );

  if (loading && !data) {
    return (
      <Div display="flex" flex={1} alignItems="center">
        <Spinner size={4} />
      </Div>
    );
  }

  return (
    <ScrollPanel
      scrollableNodeProps={{
        id: SCROLLABLE_CONTAINER_ID,
        ref: chatMessagesContainerRef,
      }}
      style={{ height: '100%', overflow: 'auto' }}
    >
      <Div
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <Text.p my={6} fontSize={1} color="black.black7" textAlign="center">
          {t('chat.item.disclaimerBanner', { productName })}
        </Text.p>
        {error && (
          <Div alignSelf="start" width="100%">
            <ErrorAlert
              errors={{
                general: {
                  msg: `${t('chat.item.conversationError')}`,
                  variant: 'danger',
                },
              }}
            />
          </Div>
        )}
        {!!messages.length && (
          <InfiniteScroll
            scrollableTarget={SCROLLABLE_CONTAINER_ID}
            inverse
            next={loadMore}
            hasMore={canLoadMore}
            dataLength={messages.length}
            loader={
              <Div display="flex" justifyContent="space-around" pb={4}>
                <Spinner
                  size={10}
                  horizontal
                  data-testid="loading-more-messages"
                />
              </Div>
            }
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
          >
            <ChatMessageListContainer>
              {messages.map(renderMessage)}
            </ChatMessageListContainer>
          </InfiniteScroll>
        )}
      </Div>
    </ScrollPanel>
  );
};

export default ChatMessagesList;
