import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const BackButton = styled(Button)`
  padding: 0;
  margin: 0;
  border: none;

  &:hover,
  &:focus {
    background: none;
    border: none;
  }
`;

export default BackButton;
