import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { ProductTemplateSlug } from '../../utils/generated/generated';
import {
  TRIGGER_EVENT_SELF_SERVICE_PAYMENT,
  TriggerEventSelfServicePaymentPayload,
  TriggerEventSelfServicePaymentVariables,
} from '../../utils/mutation/TriggerEventSelfServicePayment/TriggerEventSelfServicePayment';

type UseTriggerEventSelfServicePaymentAction = (
  input: Omit<TriggerEventSelfServicePaymentVariables['input'], 'product'>,
  options?: MutationFunctionOptions<
    TriggerEventSelfServicePaymentPayload,
    TriggerEventSelfServicePaymentVariables
  >
) => Promise<FetchResult<TriggerEventSelfServicePaymentPayload>>;

export const useTriggerEventSelfServicePayment = (
  product: ProductTemplateSlug
): [
  UseTriggerEventSelfServicePaymentAction,
  MutationResult<TriggerEventSelfServicePaymentPayload>
] => {
  const [triggerEventSelfServicePayment, ...rest] = useMutation<
    TriggerEventSelfServicePaymentPayload,
    TriggerEventSelfServicePaymentVariables
  >(TRIGGER_EVENT_SELF_SERVICE_PAYMENT);

  const mutationFunction: UseTriggerEventSelfServicePaymentAction = useCallback(
    (input, options) =>
      triggerEventSelfServicePayment({
        variables: {
          input: {
            ...input,
            product,
          },
        },
        ...options,
      }),
    [triggerEventSelfServicePayment]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
