import { FC } from 'react';

import SearchInput from '@components/base/SearchInput';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const Search: FC = () => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const { searchQuery, setSearchQuery } = useJobFiltersContext();

  return (
    <Div py={4}>
      <SearchInput
        borderRadius={4}
        inputValue={searchQuery}
        changeInputValue={setSearchQuery}
        placeholder={t('general.form.search.placeholder')}
        width="100%"
        height="44px"
        withClearButton
        stylesWhenFilled={{
          border: `2px solid ${themeContext.colors.primary.base}`,
          padding: `0 ${themeContext.space[7]}px`,
        }}
      />
    </Div>
  );
};

export default Search;
