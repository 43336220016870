import { InMemoryCache, makeReference, Reference } from '@apollo/client';

import { recalculatePageInfoAfterRemoval } from '../../utils/cache';
import LATEST_MESSAGE_FRAGMENT from '../../utils/fragments/LatestMessageFragment';
import {
  ChatListType,
  MessageStatus,
  SET_CHAT_AS_READ_MUTATION,
} from '../../utils/generated/generated';
import { writeIsUnread } from '../chatWriteQuery/isUnread';

export const setChatAsReadUpdate =
  (chatID: string) =>
  (
    cache: InMemoryCache,
    { data }: { data?: SET_CHAT_AS_READ_MUTATION | null }
  ) => {
    cache.modify({
      id: cache.identify({ id: chatID, __typename: 'Chat' }),
      fields: {
        latestMessage(latestMessageData, { readField }) {
          const latestMessageFragment = cache.readFragment({
            id: cache.identify({
              id: readField('id', latestMessageData),
              __typename: 'Message',
            }),
            fragment: LATEST_MESSAGE_FRAGMENT,
            fragmentName: 'LatestMessageFragment',
          });

          if (latestMessageFragment !== null) {
            return {
              // @ts-ignore
              ...latestMessageFragment,
              ...(data?.setChatAsRead?.success ?? false
                ? { status: MessageStatus.READ }
                : {}),
            };
          }
          return latestMessageData;
        },
      },
    });

    writeIsUnread(cache, chatID, !data?.setChatAsRead?.success);

    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        chatList(existingChatList, { readField }) {
          if (existingChatList.listType !== ChatListType.UNREAD) {
            return existingChatList;
          }
          // remove chat from unread list
          return {
            ...existingChatList,
            chatConnection: {
              ...existingChatList.chatConnection,
              edges: existingChatList.chatConnection.edges.filter(
                (chat: { node: Reference }) => {
                  return readField('id', chat.node) !== chatID;
                }
              ),
              pageInfo: recalculatePageInfoAfterRemoval(
                existingChatList.chatConnection.pageInfo
              ),
            },
          };
        },
      },
    });
  };

export const setChatAsReadOptimisticResponse: SET_CHAT_AS_READ_MUTATION = {
  setChatAsRead: {
    __typename: 'SetChatAsReadPayload',
    success: true,
  },
};
