/*
 * Collection of common UI Layer Z-indexes.
 *
 * In a perfect world it should be taken from the Fireball, but its not present nowadays
 *
 */

type LayerLevel =
  | 'below'
  | 'base'
  | 'above'
  | 'dropdown'
  | 'sticky'
  | 'fixed'
  | 'backdrop'
  | 'modal'
  | 'popover'
  | 'tooltip'
  | 'toast';

/* It seems that 100 is the main value for modals and similar stuff  in the Fireball, so I need to align to that */

export const Layer: Record<LayerLevel, number> = {
  below: -1,
  base: 0,
  above: 1,
  dropdown: 70,
  sticky: 80,
  fixed: 90,
  backdrop: 100, // 100 - is a base value for modals and similar stuff in the Fireball, so we need to align
  modal: 110,
  popover: 120,
  tooltip: 130,
  toast: 0x7fffffff, // due to the Intercom chat
};
