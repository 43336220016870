import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledDateInputContainer = styled(Div)`
  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.8;
    &,
    & input {
      background-color: ${({ theme }) => theme.colors.black.black2};
    }
    button,
    button:hover {
      border: none;
    }
  }
`;
