import { FC } from 'react';

import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import useAnalytics from '@root/src/hooks/useAnalytics';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { useRequestJobPackageAction } from '@utils/companyState/useCompanyStateAction';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { AlertState } from './constants';

interface CreditsAlertProps {
  variant: AlertState;
}

export const CreditsAlert: FC<CreditsAlertProps> = ({ variant }) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { colors, fontWeights } = useTheme();

  const { onClick, text, isLoading, disabled } = useRequestJobPackageAction(
    undefined,
    () => {
      track(
        ANEventSpace(
          ANEvent.Clicked,
          ANObject.AlertBannerContactRequest,
          ANPage.JobListStatistics
        )
      );
    }
  );

  const alertConfig: Record<
    Exclude<AlertState, AlertState.None>,
    {
      testId: string;
      bgColor: string;
      label: string;
    }
  > = {
    [AlertState.Warning]: {
      testId: 'credits-warning-alert',
      bgColor: colors.yellow.lightest,
      label: t('job.list.statistics.lowOnCredits'),
    },
    [AlertState.Danger]: {
      testId: 'credits-danger-alert',
      bgColor: colors.red.lightest,
      label: t('job.list.statistics.outOfCredits'),
    },
  };

  if (variant === AlertState.None) return null;

  const { testId, bgColor, label } = alertConfig[variant];

  return (
    <Div
      display="flex"
      flexDirection="column"
      alignItems="center"
      backgroundColor={bgColor}
      p={5}
      data-testid={testId}
    >
      <Text.p mb={4} fontSize={3} fontWeight={fontWeights.medium}>
        {label}
      </Text.p>
      <Button
        size="tiny"
        fullWidth
        onClick={onClick}
        isLoading={isLoading}
        disabled={disabled}
      >
        <Text.p mb={0} fontSize={2} fontWeight={fontWeights.medium}>
          {text}
        </Text.p>
      </Button>
    </Div>
  );
};
