import { Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const HEADER_HEIGHT = 70;

const Header = styled(Div)`
  height: 0;

  @media print {
    display: none;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    display: flex;
    justify-content: center;
    height: ${HEADER_HEIGHT}px;
    position: sticky;
    z-index: ${Layer.fixed};
    top: 0;
  }
`;

export default Header;
