import { FC } from 'react';

import { CloseButton } from '@components/elements/CloseButton';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Modal } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTheme } from 'styled-components';

import { CompletedState, ConfirmState } from './components';
import { ModalProps, ModalState } from './useModal';

const HEADER_PROPS = { justifyContent: 'flex-end', mb: 0, px: 6, pt: 6 };

export const MittanbudXLModal: FC<ModalProps> = ({
  isOpen,
  modalState,
  loading,
  handleCheckQualify,
  closeModal,
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  if (!isOpen) {
    return null;
  }

  const stateComponent =
    {
      [ModalState.Confirm]: (
        <ConfirmState
          loading={loading}
          handleCheckQualify={handleCheckQualify}
          onClose={closeModal}
        />
      ),
      [ModalState.Completed]: <CompletedState onClose={closeModal} />,
    }[modalState] ?? null;

  const maxWidth =
    !isMobile && modalState === ModalState.Completed ? '480px' : '860px';

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      isClosable={false}
      size="custom"
      verticalSize={isMobile ? 'fullHeight' : 'default'}
      background={theme.colors.blueNavy.base}
      maxWidth={maxWidth}
      header={<CloseButton onClose={closeModal} />}
      headerProps={HEADER_PROPS}
      zIndex={Layer.modal + Layer.above + Layer.above}
      isSmaller
    >
      {stateComponent}
    </Modal>
  );
};
