import { useMemo, RefObject, FC } from 'react';

import ChatListItemActions from '@components/elements/ChatListItem/ChatListItemActions';
import JobLabels from '@components/elements/JobLabels';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import dateFromTimestamp from '@internals/business-shared/src/utils/dateFromTimestamp';
import { CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges } from '@internals/business-shared/src/utils/generated/generated';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import {
  getJobLabelList,
  JobLabel,
} from '@internals/business-shared/src/utils/jobLabels';
import { latestMessageText } from '@internals/business-shared/src/utils/latestMessageText';
import { removeSpecialCharacters } from '@internals/business-shared/src/utils/types/StringUtils';
import { getCustomerFromParticipants } from '@internals/business-shared/src/utils/user';
import { useUserContext } from '@root/src/contexts/UserContext';
import Paths from '@router/paths';
import { Avatar, Text } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import getJobItemSearchParam from '@utils/url';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import uniqid from 'uniqid';

import {
  ChatAvatar,
  ChatContainerV2,
  ChatLinkV2,
  ChatTitle,
  TextRow,
  UnreadIndicator,
} from './styled';
import UnreadDot from './styled/UnreadDot';

export interface ChatListItemProps {
  chat: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges;
  isUnread: boolean;
  isOpen: boolean;
  debouncedInputValue: string;
  isChatInactive: boolean;
  handleChatFavourite: VoidFunction;
  handleChatDelete: VoidFunction;
  handleChatUnread: VoidFunction;
  containerRef: RefObject<any>;
}

const ChatListItemV2: FC<ChatListItemProps> = ({
  chat,
  isUnread,
  isOpen,
  debouncedInputValue,
  isChatInactive,
  handleChatFavourite,
  handleChatDelete,
  handleChatUnread,
  containerRef,
}: ChatListItemProps) => {
  const { track } = useAnalytics();
  const navigate = useNavigate();
  const themeContext = useTheme();
  const user = useUserContext();

  const {
    node: {
      id: chatId,
      job,
      latestUpdateTs,
      isDeleted,
      isEnded,
      participants,
      isDialog,
    },
  } = chat;
  const {
    labels,
    evaluation,
    id: jobId,
    title,
    jobBusinessRelationState,
    isOneOnOne,
  } = job;
  const owner = getCustomerFromParticipants(participants);
  const { i18n, t } = useTranslation();

  const jobLabels = useMemo(() => {
    const EXCLUDED_LABELS = [JobLabel.FREE, JobLabel.BIG];
    return getJobLabelList(
      {
        ...labels,
        ...(isDeleted && { deleted: isDeleted }),
        evaluationRequested: jobBusinessRelationState.hasRequestedEvaluation,
        selectionRequested: jobBusinessRelationState.hasRequestedSelection,
        isEvaluated: !!evaluation,
        isDirectRegistration: isOneOnOne,
      },
      EXCLUDED_LABELS
    );
  }, [
    labels,
    isDeleted,
    evaluation,
    jobBusinessRelationState.hasRequestedEvaluation,
    jobBusinessRelationState.hasRequestedSelection,
  ]);

  const handleHighlightSearchValue = (value: string) => {
    try {
      const searchPhrase = debouncedInputValue.trim();
      if (!searchPhrase) return value;
      const searchPhraseRegexp = new RegExp(
        `(${removeSpecialCharacters(searchPhrase)})`,
        'gi'
      );
      const parts = value.split(searchPhraseRegexp);
      return (
        <span>
          {parts.map((part) => (
            <span
              key={uniqid()}
              style={
                part.toLowerCase() ===
                removeSpecialCharacters(debouncedInputValue.toLowerCase())
                  ? { fontWeight: 'bold' }
                  : {}
              }
            >
              {part}
            </span>
          ))}
        </span>
      );
    } catch (error) {
      bugsnagClient.notify(
        new Error(
          `Error when parsing chat search input: ${debouncedInputValue}, error details: ${error.toString()}`
        )
      );
      return value;
    }
  };

  const handleShowJob = () => {
    track(ANEventSpace(ANEvent.Clicked, ANObject.ShowJob, ANPage.Messages));
    navigate(getJobItemSearchParam(jobId));
  };

  const processedTestId =
    isChatInactive ||
    jobLabels.includes(JobLabel.ENDED) ||
    jobLabels.includes(JobLabel.DELETED)
      ? `inactive-chat-list-item-${chatId}`
      : `chat-list-item-${chatId}`;

  const isChatUnread = isUnread;

  return (
    <ChatContainerV2
      isOpen={isOpen}
      data-testid={processedTestId}
      {...(owner?.isDeleted && { 'data-test': 'deleted-user' })}
    >
      <ChatLinkV2
        to={`${Paths.Messages}/${chatId}`}
        $isChatUnread={isChatUnread}
      >
        <UnreadIndicator>{isChatUnread && <UnreadDot />}</UnreadIndicator>
        <ChatAvatar>
          <Avatar
            size="m"
            colorSchemeVariable={`${owner?.lastName ?? ''}${title}`}
            initials={getInitials(`${owner?.firstName} ${owner?.lastName}`)}
            color={themeContext.colors.black.black7}
          />
        </ChatAvatar>
        <ChatTitle>
          <TextRow
            justifyContent="space-between"
            mb={`${themeContext.space[1] + themeContext.space[1]}px`}
          >
            <Text.span
              data-testid="chatitem-header"
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[4]}
              color={themeContext.colors.black.black7}
              fontWeight={themeContext.fontWeights.medium}
              truncate
              mb={0}
            >
              {handleHighlightSearchValue(`${job.title}`)}
            </Text.span>
            <JobLabels labels={jobLabels} isSmall flexWrap="nowrap" />
          </TextRow>
          <TextRow mb={`${themeContext.space[1] + themeContext.space[1]}px`}>
            <Text.span
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              truncate
              maxWidth={185}
              mb={0}
            >
              {handleHighlightSearchValue(`${job.address?.street}`)}
            </Text.span>
            <Text.span
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              mb={0}
              px={2}
            >
              |
            </Text.span>
            <Text.span
              lineHeight="1"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              maxWidth={185}
              truncate
              mb={0}
            >
              {`${owner?.firstName} ${owner?.lastName} `}
            </Text.span>
          </TextRow>
          <TextRow width="100%" mb={0}>
            <Text.span
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              truncate
              maxWidth={185}
              mb={0}
            >
              {latestMessageText(
                chat.node.latestMessage,
                user,
                owner.firstName,
                false,
                false
              )}
            </Text.span>
            <Text.span
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              mb={0}
              px={2}
            >
              •
            </Text.span>
            <Text.span
              lineHeight="1.25"
              fontSize={themeContext.fontSizes[1]}
              color={themeContext.colors.black.black6}
              fontWeight={themeContext.fontWeights.regular}
              truncate
              mb={0}
            >
              {dateFromTimestamp(
                i18n.language,
                latestUpdateTs,
                undefined,
                {
                  today: '',
                  yesterday: t('general.dateTime.yesterday'),
                },
                true
              )}
            </Text.span>
          </TextRow>
        </ChatTitle>
      </ChatLinkV2>
      <ChatListItemActions
        containerRef={containerRef}
        handleChatDelete={handleChatDelete}
        handleChatFavourite={handleChatFavourite}
        handleShowJob={handleShowJob}
        handleChatUnread={handleChatUnread}
        isChatDeleted={!!isDeleted}
        isChatFavourite={!!labels.favourite}
        isChatInactive={isChatInactive}
        isChatEnded={!!isEnded}
        isChatUnread={isUnread}
        isDialog={!!isDialog}
      />
    </ChatContainerV2>
  );
};

export default ChatListItemV2;
