import { leadingZero } from './leadingZero';
import { timeShift } from './timeShift';

export const formattedChatHours = (
  utcHourStart: number,
  utcHourEnd: number
): {
  startTime: string;
  endTime: string;
} => {
  const { hourShift } = timeShift();

  const startTime =
    hourShift(utcHourStart) < 10
      ? leadingZero(hourShift(utcHourStart))
      : hourShift(utcHourStart).toString();
  const endTime =
    hourShift(utcHourEnd) < 10
      ? leadingZero(hourShift(utcHourEnd))
      : hourShift(utcHourEnd).toString();
  return { startTime, endTime };
};
