import { FC, IframeHTMLAttributes } from 'react';

import log from '@internals/business-shared/src/utils/devLog';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { getProduct } from '@utils/product';

type GoogleMapsEmbedProps = {
  mapParams: string;
  onLoad?: () => void;
  minWidth?: string;
  minHeight?: string;
  styles?: IframeHTMLAttributes<HTMLIFrameElement>['style'];
};

export const GoogleMapsEmbed: FC<GoogleMapsEmbedProps> = ({
  mapParams,
  onLoad,
  minWidth,
  minHeight,
  styles,
}) => {
  const { defaultLanguage } = getProduct();
  log.component(mapParams);
  return (
    <iframe
      title="Google maps embed"
      width="100%"
      height="100%"
      frameBorder="0"
      style={{
        borderRadius: 4,
        border: 0,
        padding: 0,
        margin: 0,
        display: 'block',
        width: '100%',
        minWidth: minWidth || '100%',
        height: '100%',
        minHeight: minHeight || '100%',
        ...(styles || {}),
      }}
      src={`https://www.google.com/maps/embed/v1/place?key=${getEnvValue(
        ENV_KEY_NAME.GOOGLE_MAPS
      )}&language=${defaultLanguage}&q=${mapParams}`}
      allowFullScreen
      loading="lazy"
      onLoad={onLoad}
    />
  );
};
