import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  COMPANY_SETTINGS_QUERY,
  CompanySettings,
  CompanySettingsPayload,
  CompanySettingsVariables,
} from '../../utils/query/CompanySettings/CompanySettings';

interface JobAccessQueryResult extends QueryResult {
  data: CompanySettings;
}

export const useCompanySettingsQuery = (
  userId: CompanySettingsVariables['id'],
  options?: QueryHookOptions<CompanySettingsPayload, CompanySettingsVariables>
): JobAccessQueryResult => {
  const { data, ...rest } = useQuery<
    CompanySettingsPayload,
    CompanySettingsVariables
  >(COMPANY_SETTINGS_QUERY, {
    variables: { id: userId },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return {
    data: data?.businessSettings ?? null,
    ...rest,
  };
};
