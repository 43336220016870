import styled from 'styled-components';

const MenuList = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;

  & li {
    flex: 1;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    justify-content: flex-start;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    flex: 1;

    & li {
      flex: 0 1 auto;
    }

    li:first-child {
      margin-left: auto;
    }

    li:last-child {
      margin-left: auto;
    }
  }
`;

export default MenuList;
