import { Card, Heading, Text } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const CardContainer = styled(Card)`
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr;
  padding: 24px;
  position: relative;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[3]}) {
    grid-template-columns: 1fr auto;
  }
`;

export const ContentContainer = styled.div``;

export const ButtonContainer = styled.div`
  justify-items: center;
  display: grid;
  align-items: center;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[3]}) {
    justify-items: right;
  }
`;

export const BankIdTitle = styled(Heading.h4)`
  font-size: 18px;
  margin-bottom: 8px;
  color: ${(props) => props.theme.text.black};
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: pretty;
  text-align: center;
  width: 100%;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[3]}) {
    text-align: left;
    width: auto;
    justify-content: flex-start;
  }
`;

export const BankIdIconContainer = styled.div`
  display: block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const BankIdText = styled(Text.p)`
  font-size: 14px;
  color: ${(props) => props.theme.text.grayOnGray};
  text-wrap: pretty;
  text-align: center;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[3]}) {
    text-align: left;
  }
`;

export const BankIdDismissButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: ${Layer.base + Layer.above};
`;
