import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const Success: FC = () => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  return (
    <Div alignItems="center">
      <Div flexDirection="row" alignItems="center" mb={8} mt={8}>
        <FireIcon
          variant="color"
          iconName={IconPickerName.Check}
          width={86}
          height={86}
        />
      </Div>
      <Div
        flexDirection="row"
        alignItems="center"
        justifyItems="center"
        mb={8}
        mt={2}
      >
        <Heading.h2
          fontSize={themeContext.fontSizes[4]}
          fontWeight={themeContext.fontWeights.medium}
          color="black.black8"
          mb={2}
        >
          {t('settings.integrations.boligmappa.success.title')}
        </Heading.h2>
        <Text.p
          fontSize={themeContext.fontSizes[1]}
          fontWeight={themeContext.fontWeights.medium}
          color="black.black6"
          mb={0}
          textAlign="center"
        >
          {t('settings.integrations.boligmappa.success.subtitle')}
        </Text.p>
      </Div>
    </Div>
  );
};
