import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ChatListFilterContainer = styled(Div)`
  display: flex;
  min-height: 60px;
  width: 100%;
  padding: 0 12.5px;
  border-bottom: 1px solid ${(props): string => props.theme.colors.black.black3};
  justify-content: space-between;
`;

export default ChatListFilterContainer;
