import { t } from '@internals/business-translations/src/i18n';

import { SystemMessageOutput, SystemMessageType } from './index';

export const nudgedBoligMappaSystemMessage = (
  type: SystemMessageType,
  consumerName = ''
): SystemMessageOutput | null => {
  switch (type) {
    case SystemMessageType.NUDGED_BOLIGMAPPA:
      return {
        title: t('boligmappa.item.systemMessages.consumerNudged.title'),
        text: t('boligmappa.item.systemMessages.consumerNudged.description', {
          consumerName,
        }),
      };
    default:
      return null;
  }
};
