import { ApolloCache } from '@apollo/client';

import { CHOOSE_BUSINESS_WORK_TYPES } from '../../utils/generated/generated';
import {
  ChooseBusinessWorkTypeSubsetsPayload,
  ChooseBusinessWorkTypeSubsetsVariables,
} from '../../utils/mutation/ChooseBusinessWorkTypeSubsets/ChooseBusinessWorkTypeSubsets';

export const chooseBusinessWorkTypeSubsetsUpdate =
  (businessId: string) =>
  (
    cache: ApolloCache<CHOOSE_BUSINESS_WORK_TYPES>,
    {
      data: mutationPayload,
    }: { data?: ChooseBusinessWorkTypeSubsetsPayload | null }
  ) => {
    if (!mutationPayload.chooseBusinessWorkTypeSubsets.subsets) return;
    cache.modify({
      id: cache.identify({ id: businessId, __typename: 'BusinessWorkTypes' }),
      fields: {
        // selected subset ids from payload are mapped to cached entities and referenced in enabled subsets field
        subsets(_, { toReference }) {
          return mutationPayload.chooseBusinessWorkTypeSubsets.subsets.map(
            (subset) =>
              toReference({ id: subset.id, __typename: 'WorkTypeSubset' })
          );
        },
      },
    });
  };

export const chooseBusinessWorkTypeSubsetsOptimisticResponse = (
  selectedWorkTypeIds: ChooseBusinessWorkTypeSubsetsVariables['subsetIds']
): ChooseBusinessWorkTypeSubsetsPayload => {
  return {
    chooseBusinessWorkTypeSubsets: {
      __typename: 'ChooseBusinessWorkTypeSubsetsResponse',
      subsets: selectedWorkTypeIds.map((subsetId) => ({
        id: subsetId,
        __typename: 'WorkTypeSubset',
      })),
    },
  };
};
