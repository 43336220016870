import gql from 'graphql-tag';

import {
  DELETE_JOBS_SAVED_FILTER_MUTATIONVariables,
  DELETE_JOBS_SAVED_FILTER_MUTATION as DELETE_JOBS_SAVED_FILTER_MUTATION_Result,
} from '../../generated/generated';

export type DeleteJobsSavedFilterMutationVariables =
  DELETE_JOBS_SAVED_FILTER_MUTATIONVariables;
export type DeleteJobsSavedFilterMutationPayload =
  DELETE_JOBS_SAVED_FILTER_MUTATION_Result;

export const DELETE_JOBS_SAVED_FILTER_MUTATION = gql`
  mutation DELETE_JOBS_SAVED_FILTER_MUTATION($id: ID!) {
    deleteJobWatchlist(id: $id)
  }
`;
