import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ItemLabel = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default ItemLabel;
