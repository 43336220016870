import gql from 'graphql-tag';

import {
  CREATE_ANSWER_TEMPLATEVariables,
  CREATE_ANSWER_TEMPLATE as CREATE_ANSWER_TEMPLATE_Result,
} from '../../generated/generated';

export type CreateAnswerTemplatePayload = CREATE_ANSWER_TEMPLATE_Result;
export type CreateAnswerTemplateVariables = CREATE_ANSWER_TEMPLATEVariables;

export const CREATE_ANSWER_TEMPLATE = gql`
  mutation CREATE_ANSWER_TEMPLATE($input: CreateAnswerTemplateInput!) {
    createAnswerTemplate(input: $input) {
      success
      template {
        id
        title
        text
      }
    }
  }
`;
