import gql from 'graphql-tag';

import {
  BOLIGMAPPA_CREATE_ACCOUNTVariables,
  BOLIGMAPPA_CREATE_ACCOUNT as BOLIGMAPPA_CREATE_ACCOUNT_Result,
} from '../../generated/generated';

export type BoligmappaCreateAccountMutationPayload =
  BOLIGMAPPA_CREATE_ACCOUNT_Result;
export type BoligmappaCreateAccountMutationVariables =
  BOLIGMAPPA_CREATE_ACCOUNTVariables;

export const isBoligmappaCreateAccountMutationSuccessResponse = (
  boligmappaCreateAccount?: BOLIGMAPPA_CREATE_ACCOUNT_Result['boligmappaCreateAccount']
): boligmappaCreateAccount is BOLIGMAPPA_CREATE_ACCOUNT_Result['boligmappaCreateAccount'] =>
  !!boligmappaCreateAccount;

export const BOLIGMAPPA_CREATE_ACCOUNT = gql`
  mutation BOLIGMAPPA_CREATE_ACCOUNT($input: BoligmappaCreateAccountInput!) {
    boligmappaCreateAccount(input: $input)
  }
`;
