import { useCallback, useMemo } from 'react';

import {
  LazyQueryHookOptions,
  LazyQueryResult,
  useLazyQuery,
} from '@apollo/client';

import {
  isUnpaidInvoicesQuerySuccessResponse,
  UNPAID_INVOICES_QUERY,
  UnpaidInvoice,
  UnpaidInvoicesQueryPayload,
  UnpaidInvoicesQueryVariables,
} from '../../utils/query/UnpaidInvoices/UnpaidInvoicesQuery';

interface FetchUnpaidInvoicesType {
  (id: string): void;
}

interface UnpaidInvoicesLazyQueryResult
  extends Omit<
    LazyQueryResult<UnpaidInvoicesQueryPayload, UnpaidInvoicesQueryVariables>,
    'data'
  > {
  data: UnpaidInvoice[] | null;
}

export const useUnpaidInvoicesLazyQuery = (
  options?: LazyQueryHookOptions<
    UnpaidInvoicesQueryPayload,
    UnpaidInvoicesQueryVariables
  >
): [FetchUnpaidInvoicesType, UnpaidInvoicesLazyQueryResult] => {
  const [fetchUnpaidInvoices, { data, ...rest }] = useLazyQuery<
    UnpaidInvoicesQueryPayload,
    UnpaidInvoicesQueryVariables
  >(UNPAID_INVOICES_QUERY, {
    ...options,
  });

  const fetchInvoices = useCallback(
    (businessId: string) => {
      fetchUnpaidInvoices({ variables: { id: businessId } });
    },
    [fetchUnpaidInvoices]
  );

  const queryResult = useMemo(() => {
    const invoices =
      data && isUnpaidInvoicesQuerySuccessResponse(data.business)
        ? [...data.business.invoicesUnpaid]
        : null;
    const invoicesSortedByPaymentDate =
      invoices?.sort(
        (invoiceA, invoiceB) => invoiceA.dueDate - invoiceB.dueDate
      ) || null;
    return { data: invoicesSortedByPaymentDate, ...rest };
  }, [data, rest]);

  return [fetchInvoices, queryResult];
};
