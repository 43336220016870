import { FC } from 'react';

import { ProductHeader } from '@components/layout/Products/ProductHeader';
import { ProductSummary } from '@components/layout/Products/ProductSummary';
import { StyledProductSectionTitle } from '@components/layout/Products/styled';
import { spotlightMock } from '@internals/business-shared/src/components/Products/productMocks';
import { ProductTimeUnit } from '@internals/business-shared/src/components/Products/productTypes';
import {
  countDuration,
  formatPrice,
  getPackageDuration,
  getProductPackageDate,
  printPrice,
} from '@internals/business-shared/src/components/Products/productUtils';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const PurchaseAddon: FC = () => {
  const { t, i18n } = useTranslation();
  const { name, price, startDate, expirationDate } = spotlightMock;
  const durationInMonths = countDuration(startDate, expirationDate, 'month');
  const totalPrice =
    price.period === ProductTimeUnit.Month
      ? price.cost * durationInMonths
      : price.cost;
  const data = [
    {
      name: t('product.expirationDate'),
      value: getProductPackageDate(expirationDate),
    },
    {
      name: t('general.label.duration'),
      value: getPackageDuration({
        value: durationInMonths,
        unit: ProductTimeUnit.Month,
      }),
    },
    {
      name: t('product.totalPrice'),
      value: printPrice(formatPrice(totalPrice, i18n.language)),
    },
  ];

  return (
    <Div>
      <Div mb={8}>
        <ProductHeader title={t('product.confirmPurchase')} />
        <StyledProductSectionTitle>{name}</StyledProductSectionTitle>
        <ProductSummary data={data} />
      </Div>
      <Button fullWidth>{t('general.action.confirm')}</Button>
    </Div>
  );
};
