import { SEND_MESSAGE_sendMessage_SendMessagePayload_messages_files } from '../../generated/generated';
import getOptimisticId from '../id';

interface FileParams {
  name: string;
  fileType: string;
  id?: string;
  url?: string;
}

const getOptimisticFile = (
  fileData: FileParams
): SEND_MESSAGE_sendMessage_SendMessagePayload_messages_files => {
  const { name, fileType, id = getOptimisticId(), url = '' } = fileData;

  return {
    __typename: 'File',
    id,
    name,
    url,
    fileType,
  };
};

export default getOptimisticFile;
