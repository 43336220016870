import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useUserContext } from '@contexts/UserContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  LatestMessageType,
  latestMessageText,
} from '@internals/business-shared/src/utils/latestMessageText';
import { Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { MessageText } from './styled';

export interface LatestMessageProps {
  latestMessage: LatestMessageType;
}

const LatestMessage: FC<LatestMessageProps> = ({ latestMessage }) => {
  if (!latestMessage) {
    return null;
  }

  const themeContext = useTheme();
  const { t } = useTranslation();
  const user = useUserContext();
  const messageText = latestMessageText(
    latestMessage,
    user,
    t('contract.item.customer')
  );

  return (
    <Div display="flex" alignItems="center">
      <Div
        display="flex"
        alignItems="center"
        mr={2}
        data-testid="job-answered-icon"
      >
        <FireIcon
          iconName={IconPickerName.Revert}
          fill={themeContext.colors.black.black7}
          variant="flat"
          width={14}
          height={14}
        />
      </Div>
      <MessageText
        color="black.black7"
        fontSize={1}
        maxWidth="calc(100% - 25px)"
        display="inline-block"
        m={0}
        truncate
      >
        {messageText}
      </MessageText>
    </Div>
  );
};

export default LatestMessage;
