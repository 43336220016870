import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT,
  InitiateSelfServiceSubscriptionPaymentPayload,
  InitiateSelfServiceSubscriptionPaymentVariables,
} from '../../utils/mutation/InitiateSelfServiceSubscriptionPayment/InitiateSelfServiceSubscriptionPayment';

type UseInitiateSelfServiceSubscriptionPaymentAction = (
  variables: {
    productVariationSlug: InitiateSelfServiceSubscriptionPaymentVariables['input']['productVariationSlug'];
    verificationKey: InitiateSelfServiceSubscriptionPaymentVariables['input']['verificationKey'];
    returnUrl: InitiateSelfServiceSubscriptionPaymentVariables['input']['returnUrl'];
  },
  options?: MutationFunctionOptions<
    InitiateSelfServiceSubscriptionPaymentPayload,
    InitiateSelfServiceSubscriptionPaymentVariables
  >
) => Promise<FetchResult<InitiateSelfServiceSubscriptionPaymentPayload>>;

export const subscriptionConsent = {
  formId: 'subscription',
  iam: true,
  acceptBindingContract: true,
  readTos: true,
};

export const useInitiateSelfServiceSubscriptionPayment = (): [
  UseInitiateSelfServiceSubscriptionPaymentAction,
  MutationResult<InitiateSelfServiceSubscriptionPaymentPayload>
] => {
  const [initiateSelfServiceSubscriptionPayment, ...rest] = useMutation<
    InitiateSelfServiceSubscriptionPaymentPayload,
    InitiateSelfServiceSubscriptionPaymentVariables
  >(INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT);

  const mutationFn: UseInitiateSelfServiceSubscriptionPaymentAction =
    useCallback(
      ({ productVariationSlug, verificationKey, returnUrl }, options) => {
        return initiateSelfServiceSubscriptionPayment({
          variables: {
            input: {
              consent: subscriptionConsent,
              productVariationSlug,
              verificationKey,
              returnUrl,
            },
          },
          ...options,
        });
      },
      [initiateSelfServiceSubscriptionPayment]
    );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
