import { FC, Suspense, useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import StyledToastContainer from '@components/base/Toast';
import DevPanel from '@components/DevPanel';
import FullScreenLoader from '@components/elements/FullScreenLoader/FullScreenLoader';
import { AnalyticsProvider } from '@contexts/AnalyticsProvider';
import { CompanyStateProvider } from '@contexts/CompanyStateProvider';
import { JobFoldersProvider } from '@contexts/JobFoldersContext';
import { UserRole } from '@internals/business-graphql/src/graphql/generated/types';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { initLog } from '@internals/business-shared/src/utils/devLog';
import { Router } from '@router/index';
import { useFlag } from '@unleash/proxy-client-react';
import BugsnagMetadataProbe from '@utils/BugsnagMetadataProbe';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { CookiesProvider } from 'react-cookie';
import { toast, Zoom } from 'react-toastify';

import { apolloClient } from './apollo/initApollo';
import { UserProvider } from './contexts/UserContext';
import { useStore } from './modules/store';
import { isCypress } from './utils/envChecks';
import isLocalhost from './utils/isLocalhost';
import { redirectToExpiredTokenPage, redirectToLoginPage } from './utils/login';

import 'react-toastify/dist/ReactToastify.css';

initLog(getEnvValue(ENV_KEY_NAME.DEBUG));

const App: FC = () => {
  const isLoading = useStore.use.loginOperationIsLoading();
  const loginWithToken = useStore.use.loginWithToken();
  const loginWithPassword = useStore.use.loginWithPassword();
  const user = useStore.use.user();

  const globalMaintenance = useFlag(FeatureFlags.MaintenanceGlobal);
  const localMaintenance = useFlag(FeatureFlags.BizMaintenanceModeWeb);

  useEffect(() => {
    if (globalMaintenance || localMaintenance) {
      window.location.replace(getEnvValue(ENV_KEY_NAME.MAINTENANCE_URL));
    }
  }, [globalMaintenance, localMaintenance]);

  useEffect(() => {
    if (isLoading) return;

    const url = new URL(window.location.href);

    const token = url.searchParams.get('token');
    const scoutToken = url.searchParams.get('scout');
    const companyId = url.searchParams.get('companyId') || null;
    const intendedRole = url.searchParams.get('intendedRole') || null;

    ['token', 'scout', 'companyId', 'intendedRole'].forEach((paramName) =>
      url.searchParams.delete(paramName)
    );

    // Remove the query params from the URL without reloading the page
    window.history.replaceState({}, document.title, url.toString());

    // User is trying to login to the user they are already logged into
    if (companyId && user?.operatingAs?.id === companyId) return;

    if (!user && !token && !isLocalhost) {
      redirectToLoginPage();
      return;
    }

    if (token) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loginWithToken(
        {
          token,
          scoutToken,
          companyId,
          intendedRole: intendedRole as UserRole,
        },
        {},
        {
          onInvalidOrMissingInputError: () => redirectToExpiredTokenPage(),
          onAuthNoAccessError: () => redirectToLoginPage(),
          onGenericError: () => redirectToExpiredTokenPage(),
          onRoleUnavailableError: () => redirectToLoginPage(),
          onUserIsInactive: () => redirectToLoginPage(),
        }
      );
    }
  }, [isLoading, user]);

  // Adding the __DEV_ONLY_LOGIN__ to window to make it programmatically available for test environment
  useEffect(() => {
    if (!isCypress) return;
    // eslint-disable-next-line no-underscore-dangle, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    (window as any).__DEV_ONLY_LOGIN__ = (email: string, password: string) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      loginWithPassword(
        {
          email,
          password,
        },
        {},
        {
          onInvalidOrMissingInputError: () =>
            console.error('onInvalidOrMissingInputError'),
          onRoleUnavailableError: () => console.error('onRoleUnavailableError'),
          onAuthNoAccessError: () => console.error('onAuthNoAccessError'),
          onGenericError: () => console.error('onGenericError'),
        }
      );
    };
  }, [loginWithPassword]);

  if (isLoading || globalMaintenance || localMaintenance) {
    return <FullScreenLoader />;
  }

  if (!user) {
    return process.env.REACT_APP_DEV_PANEL ? (
      <DevPanel />
    ) : (
      <FullScreenLoader />
    );
  }

  return (
    <CookiesProvider>
      <Suspense fallback={<FullScreenLoader />}>
        <ApolloProvider client={apolloClient}>
          <UserProvider>
            <AnalyticsProvider writeKey={getEnvValue(ENV_KEY_NAME.SEGMENT)}>
              <BugsnagMetadataProbe />
              <CompanyStateProvider>
                <>
                  {process.env.REACT_APP_DEV_PANEL && <DevPanel />}
                  <JobFoldersProvider>
                    <Router />
                  </JobFoldersProvider>
                  <StyledToastContainer
                    limit={8}
                    position={toast.POSITION.BOTTOM_RIGHT}
                    transition={Zoom}
                    newestOnTop
                  />
                </>
              </CompanyStateProvider>
            </AnalyticsProvider>
          </UserProvider>
        </ApolloProvider>
      </Suspense>
    </CookiesProvider>
  );
};

export default App;
