import { ApolloError } from '@apollo/client';
import { AuthRequestPasswordlessLoginDocument } from '@internals/business-graphql/src/graphql/generated/types';
import { resolveErrors } from '@schibsted-smb/apollo-graphql-errors/lib/client';
import {
  ErrorTypes,
  GenericErrorCodes,
} from '@schibsted-smb/apollo-graphql-errors/lib/types';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import type { ErrorVariant } from '../login/types';
import type { HandleRequestPasswordlessLoginInput } from '../types';

export type HandleRequestPasswordlessLoginErrorHandlers = {
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onGenericError: (error?: ErrorVariant) => void;
};

export const handleRequestPasswordlessLogin = async (
  input: HandleRequestPasswordlessLoginInput,
  onSuccess: () => void | Promise<void>,
  errorHandlers: HandleRequestPasswordlessLoginErrorHandlers
) => {
  try {
    const { email, domain, path } = input;
    const { data } = await apolloClient.mutate({
      mutation: AuthRequestPasswordlessLoginDocument,
      variables: { input: { email, domain, path } },
    });

    if (data?.requestLoginEmail.__typename === 'LoginWithEmailPayload') {
      // We don't really want to expose to the user if the email was sent or not
      await onSuccess();
    } else {
      throw new Error('Invalid request passwordless login response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
