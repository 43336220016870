import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  DELETE_JOBS_SAVED_FILTER_MUTATION,
  DeleteJobsSavedFilterMutationPayload,
  DeleteJobsSavedFilterMutationVariables,
} from '../../utils/mutation/DeleteJobsSavedFilter/DeleteJobsSavedFilterMutation';

type UseDeleteJobsSavedFilterMutationAction = (
  id: string,
  mutationOptions?: MutationFunctionOptions<
    DeleteJobsSavedFilterMutationPayload,
    DeleteJobsSavedFilterMutationVariables
  >
) => Promise<FetchResult<DeleteJobsSavedFilterMutationPayload>>;

export const useDeleteJobsSavedFilterMutation = (): [
  UseDeleteJobsSavedFilterMutationAction,
  MutationResult<DeleteJobsSavedFilterMutationPayload>
] => {
  const [deleteJobsSavedFilter, ...rest] = useMutation<
    DeleteJobsSavedFilterMutationPayload,
    DeleteJobsSavedFilterMutationVariables
  >(DELETE_JOBS_SAVED_FILTER_MUTATION);

  const mutationFunction: UseDeleteJobsSavedFilterMutationAction = useCallback(
    (id, mutationOptions) =>
      deleteJobsSavedFilter({
        variables: {
          id,
        },
        ...mutationOptions,
      }),
    [deleteJobsSavedFilter]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
