import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY,
  BusinessNotificationsAvailable,
  BusinessNotificationsAvailableQueryPayload,
} from '../../utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';

interface BusinessNotificationsQueryResult extends QueryResult {
  data: BusinessNotificationsAvailable | null;
}

export const useBusinessNotificationsQuery = (
  options?: QueryHookOptions<BusinessNotificationsAvailableQueryPayload>
): BusinessNotificationsQueryResult => {
  const { data, ...rest } =
    useQuery<BusinessNotificationsAvailableQueryPayload>(
      BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY,
      {
        fetchPolicy: 'cache-and-network',
        ...options,
      }
    );

  return useMemo(() => {
    return {
      data: data ? data.businessNotificationsAvailable : null,
      ...rest,
    };
  }, [data, rest]);
};
