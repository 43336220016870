import { FC, useMemo } from 'react';

import { JobDataItem } from '@components/layout/Job/JobDataItem';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { Car } from '@internals/business-shared/src/utils/fragments/JobMetaFragment';
import { getCarInfo } from '@internals/business-shared/src/utils/jobMetadata/car';
import { Button, Div, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface JobCarSpecProps {
  carData: Car;
  showSensitiveInfo: boolean;
}

export const JobCarSpec: FC<JobCarSpecProps> = ({
  carData,
  showSensitiveInfo,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [specExpanded, , , toggleSpec] = useBoolean();

  const data = useMemo(() => {
    if (!carData.info) return null;
    return getCarInfo(carData.info, specExpanded, showSensitiveInfo);
  }, [carData.info, specExpanded, showSensitiveInfo]);

  if (!data) {
    return null;
  }

  return (
    <Div backgroundColor={theme.colors.black.black1} borderRadius={1} p={6}>
      <Heading.h4 textAlign="left">{t('job.item.meta.car.title')}</Heading.h4>
      <Div display="grid" gridTemplateColumns="repeat(2, 1fr)">
        {data.map(({ text, title }) => (
          <JobDataItem key={title} title={title} text={text} />
        ))}
      </Div>
      <Button variant="secondary" onClick={toggleSpec} size="small" mt={4}>
        {specExpanded
          ? t('job.item.meta.car.hideFullSpec')
          : t('job.item.meta.car.showFullSpec')}
      </Button>
    </Div>
  );
};
