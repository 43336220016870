import { FC, PropsWithChildren, useState } from 'react';

import {
  IframeMessage,
  IframeMessageInterface,
} from '@components/base/IframeTalk/IframeMessage';
import IframeTalk from '@components/base/IframeTalk/IframeTalk';
import PageContainer from '@components/base/PageContainer';
import ScrollPanel from '@components/base/ScrollPanel';
import ProfileCompanyInfo from '@components/layout/Profile/ProfileCompanyInfo';
import { ProfileEmployees } from '@components/layout/Profile/ProfileEmployees';
import ProfileEvaluations from '@components/layout/Profile/ProfileEvaluations';
import { ProfileGraphic } from '@components/layout/Profile/ProfileGraphic';
import ProfileNavigation from '@components/layout/Profile/ProfileNavigation';
import ProfileNavigationHeader from '@components/layout/Profile/ProfileNavigation/ProfileNavigationHeader';
import ProfilePictures from '@components/layout/Profile/ProfilePictures';
import ProfileQualifications from '@components/layout/Profile/ProfileQualifications';
import ProfileIframeColumn from '@components/layout/Profile/styled/ProfileIframeColumn';
import ProfileSidebarColumn from '@components/layout/Profile/styled/ProfileSidebarColumn';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useUserBusinessId } from '@contexts/UserContext';
import { usePageAnalytics } from '@hooks/useAnalytics';
import { useRouteChanged } from '@hooks/useRouteChanged';
import { useCompanySettingsQuery } from '@internals/business-shared/src/hooks/query/useCompanySettingsQuery';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { CompanySettings } from '@internals/business-shared/src/utils/query/CompanySettings/CompanySettings';
import Paths from '@router/paths';
import { Alert, Row, Text } from '@schibsted-smb/fireball';
import getCompanyProfilePreviewUrl from '@utils/getCompanyProfilePreviewUrl';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { getProduct } from '@utils/product';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, Link, Outlet } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const SIDEBAR_DESKTOP_WIDTH = 400;
const SIDEBAR_ANIMATION_DURATION = 400;

export interface ProfileOutletContext {
  setIframeMessage: (message: IframeMessageInterface) => void;
}

const Profile: FC = () => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const businessId = useUserBusinessId();
  const isTabletOrMobile = useIsTabletOrMobile();
  const [iframeMessage, setIframeMessage] = useState<IframeMessageInterface>(
    IframeMessage('hello', 'Hello Iframe !!!')
  );
  const [allowProfileEdit, setAllowProfileEdit] =
    useState<CompanySettings['ProfilePage']>(true);

  useCompanySettingsQuery(businessId, {
    onCompleted: (response) => {
      setAllowProfileEdit(response.businessSettings.ProfilePage);
    },
  });

  const location = useLocation();
  const isRouteUpdated = useRouteChanged();
  const isMainPage = location.pathname === Paths.Profile;

  const navigationItems: {
    id: number;
    linkTo: string;
    iconName: IconPickerName;
    label: string;
    Component: FC<PropsWithChildren<any>>;
  }[] = [
    {
      id: 1,
      linkTo: Paths.ProfileCompanyInfo,
      iconName: IconPickerName.CompanyInfo,
      label: t('profile.company.title'),
      Component: ProfileCompanyInfo,
    },
    {
      id: 2,
      linkTo: Paths.ProfileGraphic,
      iconName: IconPickerName.Palette,
      label: t('profile.graphic.title'),
      Component: ProfileGraphic,
    },
    {
      id: 4,
      linkTo: Paths.ProfileQualifications,
      iconName: IconPickerName.Qualifications,
      label: t('profile.qualifications.list.title'),
      Component: ProfileQualifications,
    },
    {
      id: 5,
      linkTo: Paths.ProfilePictures,
      iconName: IconPickerName.Images,
      label: t('profile.pictures.title'),
      Component: ProfilePictures,
    },
    {
      id: 6,
      linkTo: Paths.ProfileEmployees,
      iconName: IconPickerName.Worker,
      label: t('profile.employees.list.title'),
      Component: ProfileEmployees,
    },
    {
      id: 7,
      linkTo: Paths.ProfileEvaluations,
      iconName: IconPickerName.Evaluation,
      label: t('profile.evaluations.list.title'),
      Component: ProfileEvaluations,
    },
  ];

  usePageAnalytics(ANSpace(ANObject.Info, ANPage.Profile));

  return (
    <PageContainer fluid fullScreenHeight>
      <Row
        height="100%"
        width="100%"
        bg="black.black1"
        position="relative"
        overflowX="hidden"
      >
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={location.key}
            timeout={SIDEBAR_ANIMATION_DURATION}
            classNames="sidebar"
            exit={false}
            enter={isRouteUpdated}
          >
            <ProfileSidebarColumn
              isMainPage={isMainPage}
              sidebarDesktopWidth={SIDEBAR_DESKTOP_WIDTH}
              duration={SIDEBAR_ANIMATION_DURATION}
            >
              <ScrollPanel autoHide style={{ height: '100%' }}>
                {location.pathname === Paths.Profile && (
                  <>
                    <ProfileNavigationHeader />
                    {!allowProfileEdit && (
                      <Alert variant="danger" m="5">
                        <Text.p mb={0}>
                          <Trans
                            i18nKey="profile.sidebar.editingDisabled"
                            values={{ productName }}
                          >
                            <Link to={Paths.SettingsCustomerService} />
                          </Trans>
                        </Text.p>
                      </Alert>
                    )}
                    <ProfileNavigation navigationItems={navigationItems} />
                  </>
                )}
                <Outlet context={{ setIframeMessage }} />
              </ScrollPanel>
            </ProfileSidebarColumn>
          </CSSTransition>
        </SwitchTransition>
        {!isTabletOrMobile && (
          <ProfileIframeColumn sidebarDesktopWidth={SIDEBAR_DESKTOP_WIDTH}>
            <IframeTalk
              src={getCompanyProfilePreviewUrl(businessId)}
              title="Business profile preview"
              message={iframeMessage}
              origin={getEnvValue(ENV_KEY_NAME.ORIGIN_URL)}
            />
          </ProfileIframeColumn>
        )}
      </Row>
    </PageContainer>
  );
};

export default Profile;
