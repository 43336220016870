import { useCallback } from 'react';

import useSetSearchParams from './useSetSearchParams';

export const useResetAllParams = (params: Record<string, string>) => {
  const [, reset] = useSetSearchParams();

  return useCallback(
    (exception?: string | string[]) => {
      const paramsToRemove = new Set(
        Array.isArray(exception) ? exception : [exception]
      );
      const updatedParams = Object.values(params).filter(
        (param) => !paramsToRemove.has(param)
      );
      reset(updatedParams);
    },
    [params, reset]
  );
};
