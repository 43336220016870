import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  AuthorizeBoligmappaIntegrationMutationPayload,
  AuthorizeBoligmappaIntegrationMutationVariables,
  AUTHORIZE_BOLIGMAPPA_INTEGRATION,
} from '../../utils/mutation/AuthorizeBoligmappaIntegration/AuthorizeBoligmappaIntegrationMutation';

type UseAuthorizeBoligmappaIntegrationMutationAction = (
  input: AuthorizeBoligmappaIntegrationMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    AuthorizeBoligmappaIntegrationMutationPayload,
    AuthorizeBoligmappaIntegrationMutationVariables
  >
) => Promise<FetchResult<AuthorizeBoligmappaIntegrationMutationPayload>>;
export const useAuthorizeBoligmappaIntegrationMutation = (): [
  UseAuthorizeBoligmappaIntegrationMutationAction,
  MutationResult<AuthorizeBoligmappaIntegrationMutationPayload>
] => {
  const [authorizeBoligmappaIntegration, ...rest] = useMutation<
    AuthorizeBoligmappaIntegrationMutationPayload,
    AuthorizeBoligmappaIntegrationMutationVariables
  >(AUTHORIZE_BOLIGMAPPA_INTEGRATION, {
    fetchPolicy: 'network-only',
  });

  const mutationFunction: UseAuthorizeBoligmappaIntegrationMutationAction =
    useCallback(
      (input, mutationOptions) =>
        authorizeBoligmappaIntegration({
          variables: {
            input: {
              ...input,
            },
          },
          ...mutationOptions,
        }),
      [authorizeBoligmappaIntegration]
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
