import { Dispatch } from 'react';

import { t } from '@internals/business-translations/src/i18n';

import { BoligmappaJobAssociationsQueryResult } from '../hooks/query/useBoligmappaJobAssociationsQuery';
import {
  BoligmappaAddressDetailsBuildingType,
  BoligmappaAddressDetailsPropertyType,
} from '../utils/query/BoligmappaAddressDetails/BoligmappaAddressDetailsQuery';
import { ChatJob } from '../utils/query/ChatJob/ChatJobQuery';

type BoligMappaContentModalProps = {
  title: string;
  message: string; // for errors
  prevStep?: BoligMappaContentKind;
};

type BoligMappaAddressProps = {
  buildingId: string;
  unitId: string;
};

type BoligMappaFindUnitProps = {
  unit:
    | BoligmappaAddressDetailsPropertyType
    | BoligmappaAddressDetailsBuildingType;
};

type BoligMappaAssociationsProps = {
  jobAssociations: BoligmappaJobAssociationsQueryResult['data'];
};

// FIXME - in general this should not be exported. We should only use BoligMappaContentComponentProps
export type BoligMappaContentState = BoligMappaContentModalProps &
  BoligMappaAddressProps &
  BoligMappaFindUnitProps &
  BoligMappaAssociationsProps & { kind: BoligMappaContentAction['type'] };

export type BoligMappaContentAction = {
  type: BoligMappaContentActionType;
  payload?: Partial<BoligMappaContentState>;
};

export type BoligMappaContentComponentProps = {
  boligMappaState: BoligMappaContentState;
  boligMappaDispatch: Dispatch<BoligMappaContentAction>;
  job: ChatJob;
  onClose: VoidFunction;
};

type BoligMappaContentKind =
  | 'SEARCH-ADDRESS'
  | 'FIND-UNIT'
  | 'ASSOCIATE-ADDRESS'
  | 'ADD-DOCUMENTATION'
  | 'SHOW-ASSOCIATED-ADDRESS'
  | 'SUCCESS'
  | 'ERROR';

type BoligMappaContentActionType = BoligMappaContentKind | 'RESET';

export const BoligMappaContentDefaultValue: BoligMappaContentState = {
  kind: 'SEARCH-ADDRESS',
  title: t('boligmappa.findAddress.title'),
  message: '',
  unitId: '',
  buildingId: '',
  unit: null,
  jobAssociations: null,
};

export const BoligMappaModalReducer = (
  state: BoligMappaContentState,
  action: BoligMappaContentAction
): BoligMappaContentState => {
  switch (action.type) {
    case 'RESET': {
      if (state.jobAssociations?.associations) {
        return {
          ...BoligMappaContentDefaultValue,
          jobAssociations: state.jobAssociations, // We want to keep the jobAssociations
          kind: 'SHOW-ASSOCIATED-ADDRESS',
          title: t('boligmappa.showAssociatedAddress.title'),
        };
      }

      return {
        ...BoligMappaContentDefaultValue,
        jobAssociations: state.jobAssociations, // We want to keep the jobAssociations
      };
    }
    case 'SEARCH-ADDRESS': // KIND OF RESET HARD
      return {
        ...BoligMappaContentDefaultValue,
      };
    case 'FIND-UNIT':
      return {
        ...state,
        kind: action.type,
        buildingId: action.payload?.buildingId,
        title: t('boligmappa.findUnit.title'),
        prevStep: 'SEARCH-ADDRESS',
      };
    case 'ASSOCIATE-ADDRESS':
      return {
        ...state,
        unitId: action.payload?.unitId,
        unit: action.payload?.unit,
        kind: action.type,
        title: t('boligmappa.associateAddress.title'),
        prevStep: 'FIND-UNIT',
      };
    case 'ADD-DOCUMENTATION':
      return {
        ...state,
        kind: action.type,
        ...(action.payload?.jobAssociations
          ? { jobAssociations: action.payload?.jobAssociations }
          : {}),
        title: t('boligmappa.addDocumentation.title'),
      };

    case 'SUCCESS':
      return {
        ...state,
        kind: action.type,
        title: '',
      };

    case 'SHOW-ASSOCIATED-ADDRESS':
      return {
        ...state,
        jobAssociations: action.payload?.jobAssociations,
        kind: action.type,
        title: t('boligmappa.showAssociatedAddress.title'),
      };

    case 'ERROR':
      return {
        ...state,
        kind: action.type,
        title: '',
        message: action.payload?.message,
      };

    default: {
      throw new Error(
        `Unknown action type of BoligMappaModalReducer -  ${String(
          action.type
        )}`
      );
    }
  }
};
