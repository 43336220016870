import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  WORK_TYPES_QUERY,
  WorkTypesQueryPayload,
  WorkType,
  WorkTypeSubset,
  WorkTypesQueryVariables,
} from '../../utils/query/WorkTypes/WorkTypesQuery';

export type WorkTypesQueryData = {
  workTypes: WorkType[];
  subsets: WorkTypeSubset[];
} | null;

interface WorkTypesQueryResult extends QueryResult {
  data: WorkTypesQueryData;
}

export const useWorkTypesQuery = (
  businessId: string,
  options?: QueryHookOptions<WorkTypesQueryPayload, WorkTypesQueryVariables>
): WorkTypesQueryResult => {
  const { data, ...rest } = useQuery<
    WorkTypesQueryPayload,
    WorkTypesQueryVariables
  >(WORK_TYPES_QUERY, {
    variables: { id: businessId },
    fetchPolicy: 'cache-first',
    ...options,
  });

  const workTypesData = useMemo(() => {
    if (!data) return null;
    return {
      workTypes: data.businessWorkTypes.workTypes || [],
      subsets: data.businessWorkTypes.subsets || [],
    };
  }, [data]);

  return {
    data: workTypesData,
    ...rest,
  };
};
