import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  ADD_BUSINESS_EMPLOYEE,
  AddBusinessEmployeeMutationPayload,
  AddBusinessEmployeeMutationVariables,
} from '../../utils/mutation/AddBusinessEmployee/AddBusinessEmployeeMutation';

type UseAddBusinessEmployeeMutationAction = (
  input: AddBusinessEmployeeMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    AddBusinessEmployeeMutationPayload,
    AddBusinessEmployeeMutationVariables
  >
) => Promise<FetchResult<AddBusinessEmployeeMutationPayload>>;

export const useAddBusinessEmployeeMutation = (): [
  UseAddBusinessEmployeeMutationAction,
  MutationResult<AddBusinessEmployeeMutationPayload>
] => {
  const [addBusinessEmployee, ...rest] = useMutation<
    AddBusinessEmployeeMutationPayload,
    AddBusinessEmployeeMutationVariables
  >(ADD_BUSINESS_EMPLOYEE);

  const mutationFunction: UseAddBusinessEmployeeMutationAction = useCallback(
    (input, mutationOptions) =>
      addBusinessEmployee({
        variables: {
          input: {
            ...input,
          },
        },
        ...mutationOptions,
      }),
    [addBusinessEmployee]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
