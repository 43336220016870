import { ApolloLink } from '@apollo/client';
import log from '@internals/business-shared/src/utils/devLog';
import { v4 as uuid4 } from 'uuid';

export const TRACE_ID = 'traceId';
export const createTraceIdLink = () =>
  new ApolloLink((operation, forward) => {
    const traceId: string = uuid4();
    log.networking(`Sending request with trace id: ${traceId}`);
    operation.setContext({ ...operation.getContext(), traceId });
    return forward(operation);
  });
