import { t, i18n } from '@internals/business-translations/src/i18n';

import dateFromTimestamp from '../dateFromTimestamp';
import { RequestedStart } from '../fragments/JobMetaFragment';
import { StartAlternative } from '../generated/generated';

export const getRequestedStart = (
  requestedStart: RequestedStart | null,
  onMissingTypeHandling: (error: Error) => void
): string => {
  const fallback = t('general.label.notProvided');

  if (!requestedStart) return fallback;

  const { type, relatedTime } = requestedStart;
  const date = dateFromTimestamp(i18n.language, relatedTime, 'DD. MMM YYYY');
  switch (type) {
    case StartAlternative.NOW:
      return t('general.dateTime.asap');
    case StartAlternative.WHENEVER:
      return t('general.dateTime.anytime');
    case StartAlternative.BEFORE_DATE:
      return t('general.dateTime.before', { date });
    case StartAlternative.AFTER_DATE:
      return t('general.dateTime.after', { date });
    case StartAlternative.ON_DATE:
      return t('general.dateTime.onDate', { date });
    default:
      onMissingTypeHandling(
        new Error(`Job requested start ${type} not handled`)
      );
      return fallback;
  }
};
