import gql from 'graphql-tag';

import EVALUATION_FRAGMENT from '../../fragments/EvaluationFragment';
import FILE_FRAGMENT from '../../fragments/FileFragment';
import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import JOB_BUSINESS_RELATION_STATE_FRAGMENT from '../../fragments/JobBusinessRelationStateFragment';
import JOB_CHAT_META_FRAGMENT from '../../fragments/JobChatMetaFragment';
import JOB_DETAILS_FRAGMENT from '../../fragments/JobDetailsFragment';
import JOB_LABELS_FRAGMENT from '../../fragments/JobLabelsFragment';
import JOB_META_FRAGMENT from '../../fragments/JobMetaFragment';
import {
  JOB_QUERY as JOB_QUERY_Result,
  JOB_QUERYVariables,
  JOB_QUERY_job_Job,
} from '../../generated/generated';

export type JobQueryPayload = JOB_QUERY_Result;
export type JobQueryVariables = JOB_QUERYVariables;
export type Job = JOB_QUERY_job_Job;

export const isJobQuerySuccessResponse = (
  job?: JOB_QUERY_Result['job']
): job is JOB_QUERY_job_Job => !!(job && job.__typename === 'Job');

export const JOB_QUERY = gql`
  ${JOB_DETAILS_FRAGMENT}
  ${JOB_META_FRAGMENT}
  ${JOB_CHAT_META_FRAGMENT}
  ${IMAGE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${JOB_LABELS_FRAGMENT}
  ${EVALUATION_FRAGMENT}
  ${JOB_BUSINESS_RELATION_STATE_FRAGMENT}

  query JOB_QUERY($id: ID!) {
    job(id: $id) {
      ... on Job {
        addressHint {
          city
          id
          lat
          lon
          postalCode
        }
        approvedAt
        description
        files @client {
          ...FileFragment
        }
        id
        images @client {
          ...ImageFragment
        }
        isFree
        isOneOnOne
        labels @client {
          ...JobLabelsFragment
        }
        folders {
          id
          title
          color
        }
        jobBusinessRelationState @client {
          ...JobBusinessRelationStateFragment
        }
        size
        title
        ...JobDetailsFragment
      }
      ... on JobAccessDeniedError {
        message
        reasonCode
      }
      ... on JobNotFoundError {
        message
      }
    }
    jobChatMeta(jobId: $id) {
      ...JobChatMetaFragment
    }
    jobMeta(jobId: $id) {
      ...JobMetaFragment
    }
    jobFiles(input: { tenderId: $id }) {
      ...FileFragment
    }
    jobImages(input: { tenderId: $id }) {
      ...ImageFragment
    }
    jobEvaluation(jobId: $id) {
      ...EvaluationFragment
    }
    jobLabels(input: { jobId: $id }) {
      ...JobLabelsFragment
    }
    jobBusinessRelationState(jobId: $id) {
      ...JobBusinessRelationStateFragment
    }
  }
`;
