import gql from 'graphql-tag';

import {
  JOB_SEARCH_SETTINGS_QUERY as JOB_SEARCH_SETTINGS_QUERY_Result,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas,
  JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes,
  JOB_SEARCH_SETTINGS_QUERYVariables,
} from '../../generated/generated';

export type JobSearchSettingsQueryPayload = JOB_SEARCH_SETTINGS_QUERY_Result;
export type JobSearchSettingsQueryVariables =
  JOB_SEARCH_SETTINGS_QUERYVariables;
export type JobSearchSettingsWorkTypes =
  JOB_SEARCH_SETTINGS_QUERY_businessWorkTypes;
export type JobSearchSettingsJobAreas =
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas;

export const JOB_SEARCH_SETTINGS_QUERY = gql`
  query JOB_SEARCH_SETTINGS_QUERY($id: ID!) {
    businessJobAreas(businessId: $id) {
      id
      districts {
        id
        name
        municipality {
          id
          name
        }
      }
      districtsAvailable {
        id
        name
        municipality {
          id
          name
        }
      }
      municipalities {
        county {
          id
          name
        }
        districts {
          id
          name
        }
        id
        name
        code
      }
      municipalitiesAvailable {
        county {
          id
          name
        }
        districts {
          id
          name
        }
        id
        name
        code
      }
    }
    businessWorkTypes(businessId: $id) {
      id
      workTypes {
        id
        title
        industries {
          id
          title
          slug
          active
          workTypes {
            id
            title
            slug
            active
          }
        }
      }
      workTypesAvailable {
        id
        title
        slug
        active
        industries {
          id
          title
          slug
          active
          workTypes {
            id
            title
            slug
            active
          }
        }
      }
      subsets {
        id
        title
        visible
        partOf {
          id
          slug
          consumerTitle
        }
      }
      subsetsAvailable {
        id
        title
        visible
        partOf {
          id
          consumerTitle
          slug
        }
      }
    }
  }
`;
