import { isLocal } from '@utils/envChecks';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';

export type Environment = 'production' | 'staging' | 'development' | 'local';
export function getEnvironment(): Environment {
  if (isLocal) {
    return 'local';
  }

  const environment = getEnvValue(ENV_KEY_NAME.ENVIRONMENT);
  switch (environment) {
    case 'production':
    case 'development':
    case 'staging':
      return environment;
    default:
      throw new Error(
        `Missing or incorrect REACT_APP_ENVIRONMENT enviroment variable. Received "${environment}"`
      );
  }
}
