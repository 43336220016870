import * as React from 'react';

import {
  AlbumContextType,
  ProfilePicturesContext,
} from '@contexts/ProfilePicturesContext';
import { Div, Heading, Image, Modal } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProfilePicturesAllPicturesProps {
  albumId: AlbumContextType['id'];
  onClose: VoidFunction;
}

const ProfilePicturesAllPictures: React.FC<
  React.PropsWithChildren<ProfilePicturesAllPicturesProps>
> = ({ albumId, onClose }) => {
  const themeContext = useTheme();
  const { getContextAlbum } = React.useContext(ProfilePicturesContext);
  const album = React.useMemo<AlbumContextType | null>(
    () => getContextAlbum(albumId),
    [getContextAlbum, albumId]
  );
  const { t } = useTranslation();
  return (
    <Modal
      isOpen
      isClosable
      header={<Heading.h3>{t('profile.pictures.album.allImages')}</Heading.h3>}
      footer={<Div />}
      onClose={onClose}
      isSmaller
      size="custom"
      maxWidth="740px"
      headerProps={{
        py: 6,
        px: 7,
        m: 0,
        borderBottom: `1px solid ${themeContext.colors.black.black3}`,
      }}
      contentProps={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        py: 6,
        px: 7,
        pb: 0,
      }}
      footerProps={{
        py: 6,
        m: 0,
      }}
      testId="all-pictures"
    >
      {album?.images.map((image, index) => (
        <Image
          src={image.image.mediumPreviewUrl}
          width="155px"
          height="175px"
          key={image.id}
          objectFit="cover"
          m={2}
          data-testid={`all-pictures-preview-${index}`}
        />
      ))}
    </Modal>
  );
};

export default ProfilePicturesAllPictures;
