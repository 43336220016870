import { FC } from 'react';

import { useMutation } from '@apollo/client';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  rejectOneToOneJobOptimisticResponse,
  rejectOneToOneJobUpdate,
} from '@internals/business-shared/src/cache/updates/rejectOneToOneJob';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  RejectOneToOneJobMutationPayload,
  RejectOneToOneJobMutationVariables,
  REJECT_ONE_TO_ONE_JOB,
} from '@internals/business-shared/src/utils/mutation/RejectOneToOneJob/RejectOneToOneJobMutation';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';
import { useTheme } from 'styled-components';

import TextContainer from '../../base/JobLabel/styled/TextContainer';

interface JobRejectionProps {
  isRejected: boolean;
  openRejectionFeedback: (state: boolean) => void;
  jobId: string;
}

export const JobRejection: FC<JobRejectionProps> = ({
  isRejected,
  openRejectionFeedback,
  jobId,
}) => {
  const theme = useTheme();
  const [rejectOneToOneJob] = useMutation<
    RejectOneToOneJobMutationPayload,
    RejectOneToOneJobMutationVariables
  >(REJECT_ONE_TO_ONE_JOB);

  const handleRejectJob = async () => {
    await rejectOneToOneJob({
      variables: { input: { jobId } },
      optimisticResponse: rejectOneToOneJobOptimisticResponse,
      update: rejectOneToOneJobUpdate(jobId),
    });
  };
  return (
    <Div
      display="flex"
      flexWrap="nowrap"
      border={`1px solid ${theme.colors.black.black3}`}
      borderRadius={1}
      px={2}
      py={4}
      width="100%"
    >
      <Div flexDirection="column" alignContent="center" width={1 / 12}>
        <FireIcon
          iconName={IconPickerName.ClipboardCancel}
          width={26}
          height={26}
          variant="gray"
          fill={theme.colors.gray.light}
        />
      </Div>
      <Div width={8 / 12} flexDirection="column" alignContent="center" px={2}>
        <Text.p mb={2} fontWeight={500}>
          {isRejected
            ? t('job.item.reject.title.rejected')
            : t('job.item.reject.title.reject')}
        </Text.p>
        <Text.p>
          {isRejected
            ? t('job.item.reject.description.rejected')
            : t('job.item.reject.description.reject')}
        </Text.p>
      </Div>
      <Div
        width={3 / 12}
        alignContent="center"
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        px={0}
      >
        <Button
          variant="secondary"
          size="small"
          fullWidth={false}
          iconPosition="left"
          height="40px"
          padding="0 10px"
          disabled={isRejected}
          onClick={() => {
            handleRejectJob();
            openRejectionFeedback(true);
          }}
        >
          <Div px={2}>
            {isRejected && (
              <FireIcon
                iconName={IconPickerName.Check}
                width={16}
                height={16}
                fill={theme.colors.gray.base}
                variant="flat"
              />
            )}
          </Div>
          <TextContainer>
            {isRejected
              ? t('job.item.reject.action.rejected')
              : t('job.item.reject.action.reject')}
          </TextContainer>
        </Button>
      </Div>
    </Div>
  );
};
