import {
  Params as BTMParams,
  Paths as BTMPaths,
} from './paths-byggetilbudmatch';
import { Params as MAParams, Paths as MAPaths } from './paths-mittanbud';
import { Params as RTParams, Paths as RTPaths } from './paths-remppatori';
import { Params as SFParams, Paths as SFPaths } from './paths-servicefinder';

export const getPaths = (product = '') => {
  switch (product) {
    case 'remppatori':
      return RTPaths;

    case 'byggetilbudmatch':
      return BTMPaths;

    case 'servicefinder':
      return SFPaths;

    case 'mittanbud':
    default:
      return MAPaths;
  }
};

export const getParams = (product = '') => {
  switch (product) {
    case 'remppatori':
      return RTParams;

    case 'byggetilbudmatch':
      return BTMParams;

    case 'servicefinder':
      return SFParams;

    case 'mittanbud':
    default:
      return MAParams;
  }
};
