import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  REPLY_TO_EVALUATION as REPLY_TO_EVALUATION_Result,
  REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload,
  REPLY_TO_EVALUATIONVariables,
} from '../../generated/generated';

export type ReplyToEvaluationMutationPayload = REPLY_TO_EVALUATION_Result;
export type ReplyToEvaluationMutationVariables = REPLY_TO_EVALUATIONVariables;

export const isReplyToEvaluationMutationSuccessResponse = (
  replyToEvaluation?: REPLY_TO_EVALUATION_Result['replyToEvaluation']
): replyToEvaluation is REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload =>
  !!(
    replyToEvaluation &&
    replyToEvaluation.__typename === 'ReplyToEvaluationPayload'
  );

export const REPLY_TO_EVALUATION = gql`
  ${IMAGE_FRAGMENT}

  mutation REPLY_TO_EVALUATION($input: ReplyToEvaluationInput!) {
    replyToEvaluation(input: $input) {
      ... on ReplyToEvaluationPayload {
        evaluationReply {
          id
          evaluation {
            id
            score
            description
            focus
            writerName
            publishedTime
            job {
              id
              title
              address {
                city
                street
                zip
              }
            }
            reply {
              id
              evaluation {
                id
              }
              description
              business {
                id
                initials
                logo {
                  ...ImageFragment
                }
              }
              publishedTime
            }
            business {
              id
              initials
              logo {
                ...ImageFragment
              }
            }
          }
          business {
            id
            initials
          }
          description
          createdTime
          publishedTime
        }
      }
      ... on ReplyToEvaluationInputError {
        message
        evaluationId {
          message
        }
        description {
          message
        }
      }
      ... on EvaluationNotFoundError {
        message
      }
      ... on AccessDeniedError {
        message
      }
    }
  }
`;
