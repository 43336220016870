import { GraphQLErrors } from '@apollo/client/errors';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import {
  APOLLO_ERROR_HANDLING_HIDE_TOAST,
  APOLLO_ERROR_HANDLING_NO_BUGSNAG,
} from '@internals/business-shared/src/utils/constants/apollo';
import log from '@internals/business-shared/src/utils/devLog';
import {
  filterOutAuthErrors,
  filterOutNonAuthErrors,
  isRetryableError,
} from '@internals/business-shared/src/utils/gqlErrors';
import { t } from '@utils/i18n';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';

import { useStore } from '../modules/store';
import { TRACE_ID } from './createTraceIdLink';

const reportGraphGLErrors = (
  gqlErrors: GraphQLErrors,
  errorResponse: Pick<ErrorResponse, 'response' | 'operation'>,
  traceId: string
) => {
  const { operation, response } = errorResponse;
  const context = operation.getContext();
  const skipReport = context[APOLLO_ERROR_HANDLING_NO_BUGSNAG];

  if (skipReport) return;

  gqlErrors.forEach((gqlError) => {
    log.error(gqlError);
    bugsnagClient.notify(gqlError.message, (event) => {
      // eslint-disable-next-line no-param-reassign
      event.context = `GraphQL:${operation.operationName}`; // Custom context to differentiate serverside errors better.
      // eslint-disable-next-line no-param-reassign
      event.groupingHash = `GraphQL:${operation.operationName}`;

      event.addMetadata('Error details', {
        error: response?.errors,
      });
      event.addMetadata('GQL details', {
        operationName: operation.operationName,
        humio: `https://cloud.humio.com/smb_logs_month/search?columns=%5B%7B%22type%22:%22field%22,%22fieldName%22:%22@timestamp%22,%22format%22:%22datetime%22,%22width%22:%22content%22%7D,%7B%22type%22:%22field%22,%22fieldName%22:%22@rawstring%22,%22format%22:%22json%22%7D%5D&live=false&newestAtBottom=true&query=${traceId}%20%7C%20%22errors%5B0%5D.extensions.code%22%20%3D%20*&showOnlyFirstLine=false&start=30d&tz=Europe/Oslo&widgetType=list-view`,
        ...operation.variables,
      });
      event.addMetadata('Response details', {
        response: response?.data,
        errorType: 'payload',
      });
    });
  });
};

const showErrorToast = (operation: ErrorResponse['operation']) => {
  const context = operation.getContext();
  const showToast = !context[APOLLO_ERROR_HANDLING_HIDE_TOAST];
  if (showToast) {
    ToastMessage(t('general.error.graphQLError'), 'warning', {
      toastId: 'graphQLError',
    });
  }
};

export const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation, response }) => {
    const { [TRACE_ID]: traceId = null } = operation.getContext();

    try {
      if (!traceId) {
        throw new Error('Trace ID is missing in request.');
      }

      if (graphQLErrors) {
        log.networking(
          `Request with traceId "${traceId}" had GQL errors:`,
          graphQLErrors
        );
      }

      if (networkError) {
        log.networking(
          `Request with traceId "${traceId}" had network errors:`,
          networkError
        );
      }

      if (!traceId) {
        throw new Error('Trace ID is missing in request');
      }

      bugsnagClient.addMetadata('traceId', { traceId });
    } catch (error: unknown) {
      log.error('Was unable to get the trace id for the request', {
        error,
        operation,
        response,
        graphQLErrors,
        networkError,
      });
    }

    const authErrors = filterOutNonAuthErrors(graphQLErrors);
    const otherGraphQLErrors = filterOutAuthErrors(graphQLErrors);
    // when auth token expired, errors should not be reported, instead user is redirected to login
    if (authErrors?.length) {
      const user = useStore.use.user();
      const checkIsLoggedIn = useStore.use.checkIsLoggedIn();

      if (user) {
        checkIsLoggedIn();
        reportGraphGLErrors(graphQLErrors, { operation, response }, traceId);
        showErrorToast(operation);
      }

      return undefined;
    }

    // fetch error (temporary unavailable backend services) and other gql retryable errors are passed to retry link
    if (isRetryableError(graphQLErrors)) {
      return undefined;
    }

    // networkError is handled after exceeding allowed number of retries, see src/apollo/createRetryLink.ts
    if (otherGraphQLErrors?.length) {
      reportGraphGLErrors(graphQLErrors, { operation, response }, traceId);
    }
    // We only want to display one general message
    showErrorToast(operation);

    return undefined;
  });
