import gql from 'graphql-tag';

import { BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES as BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_Result } from '../../generated/generated';

export type BoligmappaDocumentAndProfessionTypesQueryPayload =
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_Result;

export type BoligmappaDocumentAndProfessionTypesQueryType =
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_Result['boligmappaDocumentAndProfessionTypes'];
export type BoligmappaDocumentTypesType =
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_Result['boligmappaDocumentAndProfessionTypes']['documentTypes'][number];
export type BoligmappaProfessionTypesType =
  BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES_Result['boligmappaDocumentAndProfessionTypes']['professionTypes'][number];

export const BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES = gql`
  query BOLIGMAPPA_DOCUMENT_AND_PROFESSION_TYPES {
    boligmappaDocumentAndProfessionTypes {
      documentTypes {
        id
        value: id
        label: name
      }
      professionTypes {
        id
        value: id
        label: name
      }
    }
  }
`;
