import dayjs from 'dayjs';

export interface TimeShift {
  hourShift: (utcHour: number) => number;
}

export const timeShift = (): TimeShift => {
  const utcoffset = dayjs().utcOffset();
  const utcoffsetInHours = utcoffset / 60;

  const hourShift = (utcHour: number): number => utcHour + utcoffsetInHours;

  return {
    hourShift,
  };
};
