import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const DescriptionWrapper: any = styled(Div)`
  overflow-wrap: break-word;
  text-align: left;
  ${({ isTextOverflown, isDescriptionCollapsed }: any) =>
    isTextOverflown &&
    !isDescriptionCollapsed &&
    `
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 1));
      }
  `}
`;

export default DescriptionWrapper;
