import { FC, useState } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import { FilterType } from '@components/elements/JobsFilters/SelectedFilters';
import AreasView from '@components/layout/Jobs/Filters/Areas/Areas';
import CountyView from '@components/layout/Jobs/Filters/Areas/County';
import MunicipalityView from '@components/layout/Jobs/Filters/Areas/Municipality';
import {
  useJobFiltersContext,
  useMunicipalityFilterChange,
} from '@contexts/JobFiltersContext';
import {
  anyDistrictInMunicipalityActive,
  CountiesMunicipality,
  CountyWithMunicipalities,
  getMunicipalityBasedOnCode,
  hasSingleMunicipalityWithDistricts,
  isMunicipalityActive,
} from '@internals/business-shared/src/utils/areasUtils';
import { useTranslation } from 'react-i18next';

enum AreaFilterViewType {
  Areas = 'Areas',
  County = 'County',
  Municipality = 'Municipality',
}

interface AreaFilterButtonProps {
  onClick: VoidFunction;
}

interface AreaFilterProps {
  onBackClick: VoidFunction;
}

const AreaFilter: FC<React.PropsWithChildren<AreaFilterProps>> = ({
  onBackClick,
}) => {
  const [activeView, setActiveView] = useState<AreaFilterViewType>(
    AreaFilterViewType.Areas
  );
  const [activeCounty, setActiveCounty] =
    useState<CountyWithMunicipalities | null>(null);
  const [activeMunicipality, setActiveMunicipality] =
    useState<CountiesMunicipality | null>(null);

  if (activeView === AreaFilterViewType.Municipality && activeMunicipality) {
    return (
      <MunicipalityView
        municipality={activeMunicipality}
        onBackClick={() => {
          setActiveView(AreaFilterViewType.County);
          setActiveMunicipality(null);
        }}
      />
    );
  }

  if (activeView === AreaFilterViewType.County && activeCounty) {
    return (
      <CountyView
        county={activeCounty}
        onBackClick={() => {
          setActiveView(AreaFilterViewType.Areas);
          setActiveCounty(null);
        }}
        onMunicipalityExpand={(municipality) => {
          setActiveView(AreaFilterViewType.Municipality);
          setActiveMunicipality(municipality);
        }}
      />
    );
  }

  return (
    <AreasView
      onBackClick={onBackClick}
      onCountyExpand={(county) => {
        // skip county view step for county with only one municipality and selectable districts
        if (hasSingleMunicipalityWithDistricts(county)) {
          const [municipality] = county.municipalities;
          setActiveView(AreaFilterViewType.Municipality);
          setActiveMunicipality(municipality);
          return;
        }
        setActiveView(AreaFilterViewType.County);
        setActiveCounty(county);
      }}
    />
  );
};

export const AreaFilterButton: FC<
  React.PropsWithChildren<AreaFilterButtonProps>
> = ({ onClick }) => {
  const { t } = useTranslation();
  const { areas, activeMunicipalities, activeDistricts, clearActiveAreas } =
    useJobFiltersContext();
  const handleOnMunicipalityChange = useMunicipalityFilterChange();

  const allMunicipalities = areas.flatMap((area) => area.municipalities);

  const selectedMunicipalities = allMunicipalities.reduce<FilterType[]>(
    (selectedMunicipalityFilters, currMunicipality) => {
      const municipalitySelected =
        isMunicipalityActive(currMunicipality, activeMunicipalities) ||
        anyDistrictInMunicipalityActive(currMunicipality, activeDistricts);
      if (!municipalitySelected) return selectedMunicipalityFilters;
      return [
        ...selectedMunicipalityFilters,
        { name: currMunicipality.name, id: currMunicipality.code.toString() },
      ];
    },
    []
  );

  const onSelectedClick = (municipalityFilterId: string | string[]) => {
    const municipality = getMunicipalityBasedOnCode(
      Number(municipalityFilterId),
      allMunicipalities
    );
    if (typeof municipalityFilterId === 'string' && municipality) {
      handleOnMunicipalityChange(municipality as CountiesMunicipality, false);
      return;
    }
    clearActiveAreas();
  };

  if (!areas.length) return null;

  return (
    <FilterGroupButton
      filterName={t('job.list.filters.area.title')}
      onClick={onClick}
      selectedFilters={selectedMunicipalities}
      onSelectedClick={onSelectedClick}
    />
  );
};

export default AreaFilter;
