export const DISCARDED_GENERICS = [
  'actual-cost',
  'admin-comment',
  'adservice-affiliate-id',
  'auto-approve-v2',
  'boligmappa-address-id',
  'boligmappa-associations',
  'boligmappa-doc-requested',
  'consumer-job-survey-contract-written',
  'consumer-job-survey-feedback',
  'consumer-job-survey-select-company',
  'consumer-job-survey-selected-reason',
  'consumer-job-survey-status',
  'consumer-job-survey-why-no-selected',
  'doffin-meta',
  'ga-client-id',
  'job-price-gpt-estimate',
  'ma-feedback-ticket',
  'materials-included',
  'maxl-eligible',
  'new-rel-statuses',
  'no-google-index',
  'project-flags',
  'restrict-tender-companies',
  'review-score',
  'showRegistrationNumber',
  'size-estimate',
  'source-form',
  'storage-size',
  'tender-not-approved-reason',
  'tender-square-meters-estimated',
];
