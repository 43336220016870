import { Card } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const PrintCard = styled(Card)`
  @media print {
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
    box-shadow: none;
  }
`;

export default PrintCard;
