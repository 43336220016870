import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import MESSAGE_FRAGMENT from '../../fragments/MessageFragment';
import {
  CHAT_DETAILS_QUERYVariables,
  CHAT_DETAILS_QUERY as CHAT_DETAILS_QUERY_Result,
  CHAT_DETAILS_QUERY_chat_Chat,
  CHAT_DETAILS_QUERY_chatMessages_edges_node,
  CHAT_DETAILS_QUERY_chatMessages,
  CHAT_DETAILS_QUERY_chat_Chat_participants,
} from '../../generated/generated';

export type ChatDetailsQueryPayload = CHAT_DETAILS_QUERY_Result;
export type ChatDetailsQueryVariables = CHAT_DETAILS_QUERYVariables;
export type ChatDetailsMessages = CHAT_DETAILS_QUERY_chatMessages;
export type ChatDetailsParticipant = CHAT_DETAILS_QUERY_chat_Chat_participants;
export type ChatDetailsMessageNode = CHAT_DETAILS_QUERY_chatMessages_edges_node;

export const isChatDetailsQuerySuccessResponse = (
  chat?: CHAT_DETAILS_QUERY_Result['chat']
): chat is CHAT_DETAILS_QUERY_chat_Chat => !!chat && chat.__typename === 'Chat';

export const CHAT_DETAILS_QUERY = gql`
  ${MESSAGE_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  query CHAT_DETAILS_QUERY($id: ID!, $first: Int, $from: Int, $last: Int) {
    chat(filter: { id: $id }) {
      ... on Chat {
        id
        isEnded
        isDeleted
        isUnread @client
        isWon
        job {
          id
        }
        participants {
          ... on User {
            ...BasicUserDataFragment
          }
          ... on Business {
            id
          }
        }
        latestMessage {
          id
          status
          business {
            id
          }
        }
      }
      ... on ChatInputError {
        message
        idError: id {
          message
        }
      }
      ... on ChatNotFoundError {
        message
      }
      ... on ChatAccessDeniedError {
        message
      }
    }
    chatMessages(
      input: { chatId: $id, first: $first, from: $from, last: $last }
    ) {
      edges {
        node {
          ...MessageFragment
        }
      }
      pageInfo {
        totalPages
        totalCount
        pageSizeLimit
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
