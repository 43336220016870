import { Dispatch, FC, useCallback } from 'react';

import { EditSimpleContractModal } from '@components/elements/CustomerServiceContract/EditSimpleContractModal';
import { SimpleContractDetailsModal } from '@components/layout/CustomerService/Contract/SimpleContractDetailsModal';
import SimpleContractModal from '@components/layout/CustomerService/Contract/SimpleContractModal';
import SimpleContractSuccessModal from '@components/layout/CustomerService/Contract/SimpleContractSuccessModal';
import { SimpleContractSuggestionModal } from '@components/layout/CustomerService/Contract/SimpleContractSuggestionModal';
import useAnalytics from '@hooks/useAnalytics';
import {
  SimpleContractModalAction,
  SimpleContractModalState,
} from '@internals/business-shared/src/reducers/SimpleContractReducer';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div, Modal, Spinner } from '@schibsted-smb/fireball';

interface SimpleModalFactoryProps {
  modalState: SimpleContractModalState;
  dispatchModal: Dispatch<SimpleContractModalAction>;
  resetContractModals: () => void;
}
export const SimpleModalFactory: FC<SimpleModalFactoryProps> = ({
  modalState,
  dispatchModal,
  resetContractModals,
}) => {
  const { track } = useAnalytics();
  const editContractModal = useCallback(() => {
    track(ANEventSpace(ANEvent.Opened, ANObject.Agreement, ANPage.Customers), {
      isNewContract: false,
    });
    dispatchModal({
      type: 'EDIT',
      payload: {
        contract: modalState.contract,
      },
    });
  }, [dispatchModal, modalState.contract, track]);

  if (modalState.modal.STOPPED.visible) {
    return (
      <SimpleContractModal
        contract={modalState.contract}
        onClose={resetContractModals}
        onEdit={editContractModal}
      />
    );
  }
  if (modalState.modal.SIGNED_CONSUMER.visible) {
    return (
      <SimpleContractSuggestionModal
        contract={modalState.contract}
        onClose={resetContractModals}
        onEdit={editContractModal}
      />
    );
  }

  if (modalState.modal.SIGNED_BUSINESS.visible) {
    return (
      <SimpleContractDetailsModal
        contract={modalState.contract}
        onClose={resetContractModals}
        onEdit={editContractModal}
      />
    );
  }

  if (modalState.modal.SIGNED_BOTH.visible) {
    return (
      <SimpleContractSuccessModal
        contract={modalState.contract}
        onClose={resetContractModals}
      />
    );
  }

  if (modalState.modal.EDIT.visible) {
    return (
      <EditSimpleContractModal
        contract={modalState.contract}
        show
        onClose={resetContractModals}
      />
    );
  }

  if (modalState.modal.ITEM_LOADING.visible) {
    return (
      <Modal
        isOpen
        onClose={resetContractModals}
        isClosable
        size="custom"
        maxWidth="420px"
        verticalSize="custom"
        height="610px"
      >
        <Div
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner />
        </Div>
      </Modal>
    );
  }
  return null;
};
