import gql from 'graphql-tag';

import ADDRESS_FRAGMENT from './AddressFragment';
import EVALUATION_FRAGMENT from './EvaluationFragment';

const JOB_DETAILS_FRAGMENT = gql`
  ${ADDRESS_FRAGMENT}
  ${EVALUATION_FRAGMENT}

  fragment JobDetailsFragment on Job {
    address {
      ...AddressFragment
    }
    answerCount
    answerCountLimit
    id
    evaluation @client {
      ...EvaluationFragment
    }
    workTypes {
      id
      industries {
        id
      }
    }
  }
`;
export default JOB_DETAILS_FRAGMENT;
