import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const JobModalRightColumnDetailsContentLoader: React.FC<
  React.PropsWithChildren<LoaderProps>
> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 274 714"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    <rect x="0" y="20" rx="4" ry="4" width="140" height="23" />

    <rect x="0" y="58" rx="4" ry="4" width="80" height="15" />
    <rect x="0" y="78" rx="4" ry="4" width="155" height="20" />

    <rect x="0" y="118" rx="4" ry="4" width="80" height="15" />
    <rect x="0" y="138" rx="4" ry="4" width="155" height="20" />

    <rect x="0" y="178" rx="4" ry="4" width="80" height="15" />
    <rect x="0" y="198" rx="4" ry="4" width="155" height="20" />

    <rect x="0" y="238" rx="4" ry="4" width="80" height="15" />
    <rect x="0" y="258" rx="4" ry="4" width="155" height="20" />
    {/* map */}
    <rect x="0" y="298" rx="4" ry="4" width="273" height="160" />
  </ContentLoader>
);

export default JobModalRightColumnDetailsContentLoader;
