import {
  ChangeEvent,
  KeyboardEvent,
  FC,
  FormEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import InlineErrorMessage from '@components/base/InlineErrorMessage';
import { MessageTemplate } from '@components/elements/NewMessage/MessageTemplate';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useTextareaAutoResize } from '@hooks/useTextareaAutoResize';
import useWindowSize from '@hooks/useWindowSize';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  FILE_UPLOAD_SUPPORTED_EXTENSIONS,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  FileWithPreview,
  getIconName,
  separateImageFiles,
  WebFileWithPreview,
} from '@internals/business-shared/src/utils/FileUtils';
import { isEmptyString } from '@internals/business-shared/src/utils/types/StringUtils';
import { Div, ActionButton, Image } from '@schibsted-smb/fireball';
import isFilesTotalSizeAccepted from '@utils/fileSizeCheck';
import uploadFiles from '@utils/uploadFiles';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import NewMessageFooter from './NewMessageFooter';
import {
  InputDiv,
  RemoveUploadedDocumentButton,
  StyledTextarea,
  StyledScrollPanel,
  UploadedDocumentFileName,
  UploadedDocumentWrapper,
} from './styled';

export interface NewMessageProps {
  message: string;
  setMessage: (value: string) => void;
  onSubmit?: (
    e:
      | FormEvent<HTMLFormElement>
      | KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  files: FileWithPreview[];
  setFiles: (files: FileWithPreview[]) => void;
  acceptAttachmentOnlyMessage?: boolean;
  formDisabled?: boolean;
}

const INPUT_HEIGHT_MIN = 94;
const INPUT_HEIGHT_MIN_MOBILE = 20;
const INPUT_HEIGHT_MAX = 206;
const INPUT_HEIGHT_MAX_MOBILE = 132;
// guaranteed visible height of the content that is covered by the fixed message input
const COVERED_CONTENT_MIN_HEIGHT = 500;

const NewMessage: FC<NewMessageProps> = ({
  message,
  setMessage,
  onSubmit,
  files,
  setFiles,
  acceptAttachmentOnlyMessage,
  formDisabled,
}) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();
  const themeContext = useTheme();
  const { height: windowHeight } = useWindowSize();
  const inputMaxHeight = Math.min(
    windowHeight - COVERED_CONTENT_MIN_HEIGHT,
    INPUT_HEIGHT_MAX
  );
  const footerRef = useRef<HTMLDivElement>(null);
  const scrollBarRef = useRef<HTMLDivElement>(null);
  const { textareaHeight, textareaRef, autoResizeTextarea } =
    useTextareaAutoResize(scrollBarRef);
  const isMobileTabViewFlagEnabled = useFlagEnabled(
    FeatureFlags.BizNewAnswerJobFormWeb
  );

  const [images, nonImageFiles] = useMemo(
    () => separateImageFiles(files),
    [files]
  );
  const [messageError, setMessageError] = useState('');
  const [isTemplateMessageOpen, openTemplateMessage, closeTemplateMessage] =
    useBoolean();

  useEffect(() => {
    autoResizeTextarea();
  }, [message]);

  const hasAttachments = useMemo(() => {
    return !!files.length || !!images.length;
  }, [files.length, images.length]);

  const isTextRequired = useMemo(() => {
    return !acceptAttachmentOnlyMessage || !hasAttachments;
  }, [acceptAttachmentOnlyMessage, hasAttachments]);

  const isSendingMessageDisabled = useMemo(() => {
    const isEmptyMessage = isEmptyString(message?.trim());
    if (isTextRequired) return isEmptyMessage;
    return isEmptyMessage && !hasAttachments;
  }, [message, isTextRequired]);

  const onMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const messageChangeWithSelection: any = async (
    newMessage: string,
    selectionStart: number,
    selectionEnd: number
  ) => {
    await setMessage(newMessage);
    if (textareaRef && textareaRef.current) {
      textareaRef.current.setSelectionRange(selectionStart, selectionEnd);
    }
  };

  const handleKeydown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if ((e.ctrlKey && e.key === 'Enter') || (e.altKey && e.key === 'Enter')) {
      const { selectionStart, selectionEnd } = e.target as HTMLTextAreaElement;
      const messageFirstPart = message.substring(0, selectionStart);
      const messageSecondPart = message.substring(selectionEnd);
      messageChangeWithSelection(
        `${messageFirstPart}\n${messageSecondPart}`,
        selectionStart + 1,
        selectionStart + 1
      );
    } else if (onSubmit) {
      if (
        (e.key === 'Enter' || e.key === 'NumpadEnter') &&
        !e.shiftKey &&
        !e.ctrlKey &&
        !e.metaKey &&
        !e.altKey &&
        !isSendingMessageDisabled
      ) {
        onSubmit(e);
      }
    }
  };

  const handleFileDelete = (file: FileWithPreview): void => {
    setMessageError('');
    setFiles(files.filter((el: FileWithPreview) => el.file !== file.file));
  };

  const onFileUpload = (newFiles: WebFileWithPreview['file'][]) => {
    const filesAll: WebFileWithPreview['file'][] = [
      files.map((f) => f.file as File),
      ...newFiles,
    ].flat();
    if (!isFilesTotalSizeAccepted(filesAll)) {
      setMessageError(
        t('general.form.validation.file.totalSize', {
          mbValue: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
        })
      );
      return;
    }
    setMessageError('');
    const acceptedFilesWithPreview: FileWithPreview[] = [];
    newFiles.forEach((file) => {
      uploadFiles(file, setFiles, files, acceptedFilesWithPreview);
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileUpload,
    accept: FILE_UPLOAD_SUPPORTED_EXTENSIONS,
  });

  const focusOnInput = () => {
    textareaRef.current.focus();
  };

  const onTemplateUsed = (templateMessage: string) => {
    const newMessage = message
      ? `${message} ${templateMessage}`
      : templateMessage;
    setMessage(newMessage);
    closeTemplateMessage();
  };

  return (
    <>
      {isTemplateMessageOpen && (
        <MessageTemplate
          onClose={closeTemplateMessage}
          isOpen={isTemplateMessageOpen}
          onTemplateUsed={onTemplateUsed}
        />
      )}
      <Div
        display="flex"
        margin="0 auto"
        flexDirection="column"
        onClick={focusOnInput}
      >
        <InputDiv
          isAdjacent={false}
          isDragActive={isDragActive}
          {...getRootProps()}
          disabled={formDisabled}
        >
          <StyledScrollPanel
            style={{
              minHeight:
                isTabletOrMobile && !isMobileTabViewFlagEnabled
                  ? `${INPUT_HEIGHT_MIN_MOBILE}px`
                  : `${INPUT_HEIGHT_MIN}px`,
              maxHeight: isTabletOrMobile
                ? `${INPUT_HEIGHT_MAX_MOBILE}px`
                : `${inputMaxHeight}px`,
              // textareaHeight + vertical margin
              height: `calc(${textareaHeight} + ${themeContext.space[6]})`,
              marginTop: themeContext.space[6],
              paddingLeft: themeContext.space[6],
              paddingRight: themeContext.space[6],
            }}
            scrollableNodeProps={{ ref: scrollBarRef }}
          >
            <Div display="flex" alignItems="stretch" flexGrow={3}>
              <StyledTextarea
                textareaRef={textareaRef}
                value={message}
                onChange={onMessageChange}
                onKeyDown={handleKeydown}
                containersProps={{
                  height: '100%',
                  alignItems: 'top',
                  width: '100%',
                }}
                required={isTextRequired}
                unlabeled
                placeholder={t('chat.item.placeholder.newAnswer')}
                data-testid="message-input"
                disabled={formDisabled}
              />
            </Div>
          </StyledScrollPanel>
          {!!messageError && (
            <InlineErrorMessage px={4}>{messageError}</InlineErrorMessage>
          )}
          {!!files.length && (
            <Div display="flex" flexDirection="column">
              <Div display="flex" flexWrap="wrap">
                {images.map(
                  (image) =>
                    image.urlPreview && (
                      <UploadedDocumentWrapper
                        display="flex"
                        margin={4}
                        mr={0}
                        position="relative"
                        key={image.id}
                        data-testid="uploaded-images-wrapper"
                      >
                        <Image
                          src={image.urlPreview.toString()}
                          height="62px"
                          width="62px"
                          borderRadius={themeContext.radii[1]}
                        />
                        <RemoveUploadedDocumentButton
                          position="absolute"
                          top="-4px"
                          right="-4px"
                        >
                          <ActionButton
                            background="black.black7"
                            backgroundHover="black.black7"
                            fill="black.black0"
                            fillHover="black.black0"
                            onClick={(): void => handleFileDelete(image)}
                            data-testid={`delete-${image.file.name}-button`}
                            width="18px"
                            height="18px"
                          >
                            <FireIcon
                              iconName={IconPickerName.Close}
                              variant="flat"
                              height={18}
                              width={18}
                            />
                          </ActionButton>
                        </RemoveUploadedDocumentButton>
                      </UploadedDocumentWrapper>
                    )
                )}
              </Div>
              <Div display="flex" flexWrap="wrap">
                {nonImageFiles.map((file) => (
                  <UploadedDocumentWrapper
                    maxWidth="280px"
                    height="35px"
                    border={`1px solid ${themeContext.colors.black.black4}`}
                    display="flex"
                    flexDirection="row"
                    borderRadius={themeContext.radii[1]}
                    padding="10px 5px"
                    alignItems="center"
                    justifyContent="space-between"
                    mx={3}
                    my={4}
                    key={file.id}
                  >
                    <Div display="flex" alignItems="center" flexDirection="row">
                      <FireIcon
                        iconName={getIconName(file.type)}
                        variant="color"
                        height={24}
                        width={24}
                      />
                      <UploadedDocumentFileName
                        fontSize={0}
                        ml={2}
                        mb={0}
                        color="black.black9"
                        fontWeight={themeContext.fontWeights.bold}
                        maxWidth="200px"
                      >
                        {file.file.name}
                      </UploadedDocumentFileName>
                    </Div>
                    <RemoveUploadedDocumentButton>
                      <ActionButton
                        background="black.black7"
                        backgroundHover="black.black7"
                        fill="black.black0"
                        fillHover="black.black0"
                        onClick={(): void => handleFileDelete(file)}
                        data-testid={`delete-${file.file.name}-button`}
                        width="18px"
                        height="18px"
                      >
                        <FireIcon
                          iconName={IconPickerName.Close}
                          variant="flat"
                          height="18px"
                          width="18px"
                        />
                      </ActionButton>
                    </RemoveUploadedDocumentButton>
                  </UploadedDocumentWrapper>
                ))}
              </Div>
            </Div>
          )}
          <Div ref={footerRef}>
            <NewMessageFooter
              onFileUpload={onFileUpload}
              dropZoneInputProps={getInputProps}
              sendDisabled={isSendingMessageDisabled}
              onMessageTemplateClick={openTemplateMessage}
              formDisabled={formDisabled}
            />
          </Div>
        </InputDiv>
      </Div>
    </>
  );
};

export default NewMessage;
