import gql from 'graphql-tag';

import {
  DELETE_BUSINESS_EMPLOYEEVariables,
  DELETE_BUSINESS_EMPLOYEE as DELETE_BUSINESS_EMPLOYEE_Result,
} from '../../generated/generated';

export type DeleteBusinessEmployeeMutationPayload =
  DELETE_BUSINESS_EMPLOYEE_Result;
export type DeleteBusinessEmployeeMutationVariables =
  DELETE_BUSINESS_EMPLOYEEVariables;

export const DELETE_BUSINESS_EMPLOYEE = gql`
  mutation DELETE_BUSINESS_EMPLOYEE($employeeId: ID!) {
    deleteBusinessEmployee(input: { employeeId: $employeeId }) {
      success
    }
  }
`;
