import gql from 'graphql-tag';

import { UNLINK_BOLIGMAPPA_INTEGRATION as UNLINK_BOLIGMAPPA_INTEGRATION_Result } from '../../generated/generated';

export type UnlinkBoligmappaIntegrationMutationPayload =
  UNLINK_BOLIGMAPPA_INTEGRATION_Result;
export const UNLINK_BOLIGMAPPA_INTEGRATION = gql`
  mutation UNLINK_BOLIGMAPPA_INTEGRATION {
    unlinkBoligmappaIntegration {
      id
      status
    }
  }
`;
