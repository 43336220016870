import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from './BasicUserDataFragment';
import SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT from './SimpleAgreementContractBasicFragment';

const SIMPLE_AGREEMENT_CONTRACT_FRAGMENT = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  fragment SimpleAgreementContractFragment on SimpleAgreementContract {
    ...SimpleAgreementContractBasicFragment
    job {
      id
      title
      address {
        id
        street
        zip
        city
      }
      owner {
        ...BasicUserDataFragment
      }
      contactInfo {
        phone
        email
      }
    }
    company {
      id
      name
    }
    user {
      ...BasicUserDataFragment
    }
  }
`;

export default SIMPLE_AGREEMENT_CONTRACT_FRAGMENT;
