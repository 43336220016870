import { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';

export type SetSearchParamsFnType = (
  param: string,
  value: string | string[],
  toReset?: string | string[]
) => void;
type ResetParamsFnType = (toReset?: string[], exceptions?: string[]) => void;

const useSetSearchParams = (): [
  SetSearchParamsFnType,
  ResetParamsFnType,
  { searchParams: URLSearchParams }
] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const deleteParam = useCallback(
    (p: string) => searchParams.delete(p),
    [searchParams]
  );

  const setSearchParam = useCallback<SetSearchParamsFnType>(
    (param, value, toReset?) => {
      const normalizedValue = value instanceof Array ? value.join(',') : value;
      if (!normalizedValue) {
        deleteParam(param);
      } else {
        searchParams.set(param, normalizedValue);
      }

      if (toReset) {
        const paramsArray = toReset instanceof Array ? toReset : [toReset];
        paramsArray.forEach(deleteParam);
      }

      setSearchParams(searchParams);
    },
    [deleteParam, searchParams, setSearchParams]
  );

  const resetParams = useCallback<ResetParamsFnType>(
    (toReset?, exceptions?) => {
      if (!toReset && !exceptions) {
        setSearchParams(new URLSearchParams());
        return;
      }

      if (toReset) {
        toReset.forEach(deleteParam);
      }

      if (exceptions) {
        Array.from(searchParams.keys())
          .filter((key) => exceptions.includes(key))
          .forEach(deleteParam);
      }

      setSearchParams(searchParams);
    },
    [deleteParam, searchParams, setSearchParams]
  );

  return [setSearchParam, resetParams, { searchParams }];
};

export default useSetSearchParams;
