import { FC } from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { Logo } from '@components/base/Logo';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Button,
  Column,
  Container,
  Divider,
  Heading,
  Row,
  Spinner,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { useBuySubscriptionModalContext } from '../ModalContext';
import { PackageCard } from './PackageCard';

const SupportIcon = () => (
  <FireIcon width={16} height={16} iconName={IconPickerName.Support} />
);

export const SummaryState: FC = () => {
  const { loading, selectedPackage, handleBookOnboarding, handleModalClose } =
    useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Container>
      <Row
        justifyContent="center"
        alignItems="center"
        pt={!isMobile && 15}
        pb={!isMobile && 25}
        px={!isMobile && 35}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Column
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={0}
          pb={isMobile && 40}
          width={1}
          maxWidth={isMobile ? 372 : 330}
          paddingRight={isMobile ? 0 : 40}
        >
          <Logo height="70px" isIconOnly />
          <Heading.h1 color="black.black0" mb={3}>
            {t('job.modal.subscription.summary.title')}
          </Heading.h1>
          <Text.p color="black.black0" textAlign="center">
            {t('job.modal.subscription.summary.description1')}
          </Text.p>
          <Divider bg="gray.light" my={5} />
          <Text.p color="black.black0" textAlign="center" mb={5}>
            {t('job.modal.subscription.summary.description2')}
          </Text.p>
          <Button
            onClick={handleBookOnboarding}
            variant="secondary"
            mb={!isMobile && 7}
            width={1}
            px={6}
            icon={SupportIcon}
          >
            {t('job.modal.subscription.summary.action.bookSession')}
          </Button>
          <Text.p color="black.black0" textAlign="center" my={5}>
            {t('job.modal.subscription.summary.description3')}
          </Text.p>
          <Button onClick={handleModalClose} px={6} width={1}>
            {t('job.modal.subscription.summary.action.getStarted')}
          </Button>
        </Column>
        <Column
          display="flex"
          flexDirection="column"
          alignItems="center"
          px={0}
          pt={isMobile && 40}
          width={isMobile ? 1 : 280}
          maxWidth={280}
        >
          {loading ? (
            <CenteredSpinnerContainer>
              <Spinner size={6} />
            </CenteredSpinnerContainer>
          ) : (
            <PackageCard {...selectedPackage} />
          )}
        </Column>
      </Row>
    </Container>
  );
};
