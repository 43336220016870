import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const EvaluationModalContent = styled(Div)`
  flex: 1 1 660px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.colors.black.black3};
  background-color: ${(props) => props.theme.colors.black.black1};
  padding-top: 20px;
  overflow: auto;
`;

export default EvaluationModalContent;
