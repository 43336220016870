import gql from 'graphql-tag';

import {
  FAVOURITE_JOBVariables,
  FAVOURITE_JOB as FAVOURITE_JOB_Result,
} from '../../generated/generated';

export type FavouriteJobMutationPayload = FAVOURITE_JOB_Result;
export type FavouriteJobMutationVariables = FAVOURITE_JOBVariables;

export const FAVOURITE_JOB = gql`
  mutation FAVOURITE_JOB($jobId: ID!) {
    favouriteJob(input: { jobId: $jobId }) {
      success
    }
  }
`;
