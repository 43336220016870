import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import { useJobListFiltersQuery } from '../../hooks/query/useJobListFiltersQuery';
import { useJobsSavedFiltersQuery } from '../../hooks/query/useJobsSavedFiltersQuery';
import JobListId from '../../utils/constants/jobListIds';
import {
  CLOSE_MODAL,
  filterStateReducer,
  initialFilterState,
  OPEN_MODAL,
  SET_ACTIVE_FILTER,
  SET_EDITED_FILTER,
  SET_FILTER_FORM_CHANGED,
  SET_NEW_FILTER,
} from './filterState';
import {
  AvailableFilters,
  JobListFilter,
  FilterStateType,
  SavedFilterModalType,
} from './types';
import { findActiveFilterNav, prepareFilterNavigation } from './utils';

interface SavedFiltersContextProviderProps {
  children: ReactNode;
  activeFilters: AvailableFilters;
  activeListId: JobListId;
  excludedJobListIds?: JobListId[];
}

export interface SavedFiltersContextValue {
  navigation: JobListFilter[];
  filterState: FilterStateType;
  openModal: (modalType: SavedFilterModalType) => void;
  closeModal: () => void;
  setNewFilter: (
    activeFilters: AvailableFilters,
    clearActive?: boolean
  ) => void;
  setActiveFilter: (activeFilter: JobListFilter) => void;
  setFilterFormChanged: (isChanged: boolean) => void;
  reset: VoidFunction;
}

export const SavedFiltersContext = createContext<
  SavedFiltersContextValue | undefined
>(undefined);

export const SavedFiltersContextProvider: FC<
  SavedFiltersContextProviderProps
> = ({ children, activeFilters, activeListId, excludedJobListIds }) => {
  const { data: jobListFilters, loading: jobListFiltersLoading } =
    useJobListFiltersQuery();
  const { data: savedSearches, loading: savedSearchesLoading } =
    useJobsSavedFiltersQuery();
  const navigation = useMemo(() => {
    if (savedSearchesLoading || jobListFiltersLoading) return [];
    return prepareFilterNavigation({
      jobListFilters,
      savedSearches,
      excludedJobListIds,
    });
  }, [
    jobListFilters,
    jobListFiltersLoading,
    savedSearchesLoading,
    savedSearches,
    excludedJobListIds,
  ]);

  const activeFilter = useMemo(
    () => findActiveFilterNav(navigation, activeFilters, activeListId),
    [navigation, activeFilters, activeListId]
  );

  const [filterState, dispatch] = useReducer(
    filterStateReducer,
    initialFilterState
  );

  useEffect(() => {
    // should be called only on initial page load or when active saved filter is removed,
    // otherwise active filter is set programmatically when user selects it
    if (!activeFilter || !navigation?.length || filterState.activeFilter)
      return;
    dispatch({
      type: SET_ACTIVE_FILTER,
      payload: { activeFilter },
    });
  }, [activeFilter, filterState.activeFilter, navigation?.length]);

  useEffect(() => {
    if (!navigation?.length) return;
    dispatch({
      type: SET_EDITED_FILTER,
      payload: { listId: activeListId, selectedFilters: activeFilters },
    });
  }, [activeListId, activeFilters, navigation?.length]);

  const openModal = useCallback((modalType: SavedFilterModalType) => {
    dispatch({ type: OPEN_MODAL, payload: { modalType } });
  }, []);

  const closeModal = useCallback(() => {
    dispatch({ type: CLOSE_MODAL });
  }, []);

  const setNewFilter = useCallback(
    (activeFilters: AvailableFilters, clearActive?: boolean) => {
      dispatch({
        type: SET_NEW_FILTER,
        payload: { selectedFilters: activeFilters, clearActive },
      });
    },
    []
  );

  const setActiveFilter = useCallback((activeFilter: JobListFilter) => {
    dispatch({
      type: SET_ACTIVE_FILTER,
      payload: { activeFilter },
    });
  }, []);

  const setFilterFormChanged = useCallback((isChanged: boolean) => {
    dispatch({
      type: SET_FILTER_FORM_CHANGED,
      payload: isChanged,
    });
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'init', payload: null });
  }, []);

  const value = useMemo(
    () => ({
      navigation,
      filterState,
      openModal,
      closeModal,
      setNewFilter,
      setActiveFilter,
      setFilterFormChanged,
      reset,
    }),
    [
      navigation,
      filterState,
      openModal,
      closeModal,
      setNewFilter,
      setActiveFilter,
      setFilterFormChanged,
      reset,
    ]
  );

  return (
    <SavedFiltersContext.Provider value={value}>
      {children}
    </SavedFiltersContext.Provider>
  );
};

export const useSavedFiltersContext = () => {
  const context = useContext(SavedFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useSavedFiltersContext must be used within a SavedFiltersContextProvider'
    );
  }
  return context;
};
