import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Button,
  Column,
  Container,
  Div,
  Heading,
  hexToRgba,
  Row,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BLUE_900 } from '../constants';
import { useBoostCreditsModalContext } from '../ModalContext';
import { CheckList } from './CheckList';
import { PackageCard } from './PackageCard';
import { Terms } from './Terms';

export const ConfirmPurchaseState: FC = () => {
  const {
    selectedCard,
    loading,
    selectedPackage,
    handlePayment,
    handleRetryAttempt,
  } = useBoostCreditsModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Container
      bg={!isMobile && hexToRgba(CUSTOM_BLUE_900, 0.4)}
      borderRadius={!isMobile && 8}
      maxWidth={!isMobile && 430}
      p={isMobile ? 2 : 6}
    >
      <Column
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        px={0}
      >
        <Heading.h1 fontSize={isMobile ? 5 : 7} color="black.black0" mb={3}>
          {t('job.modal.boost.confirmPurchase.title')}
        </Heading.h1>
      </Column>
      <Row justifyContent="center">
        <Column alignItems="flex-end" display="flex" my={7} px={0}>
          <PackageCard {...selectedPackage} />
        </Column>
        <CheckList />
        <Column mt={2}>
          <Row
            alignItems="center"
            bg="black.black0"
            borderRadius="4px"
            mb={4}
            px={4}
            py={5}
          >
            <Div mr={5}>
              <FireIcon
                width={24}
                height={24}
                iconName={IconPickerName.CreditCard}
                fill="black.black9"
              />
            </Div>
            <Text.span color="black.black9" fontSize={isMobile ? 1 : 3} mb={0}>
              {selectedCard?.maskedCardNumber}
            </Text.span>
          </Row>
          <Button onClick={handlePayment} isLoading={loading} fullWidth>
            {t('general.label.buy')}
          </Button>
          <Button onClick={handleRetryAttempt} variant="linkPrimary" fullWidth>
            <Text.span color="black.black0">
              {t('general.label.back')}
            </Text.span>
          </Button>
          <Terms />
        </Column>
      </Row>
    </Container>
  );
};
