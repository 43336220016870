import {
  getSystemMessageKind,
  getSystemMessagePayload,
  SystemMessageKind,
} from '../components/SystemMessage';
import { boligMappaSystemMessage } from '../components/SystemMessage/boligMappaSystemMessage';
import { contractSystemMessage } from '../components/SystemMessage/contractSystemMessage';
import { nudgedBoligMappaSystemMessage } from '../components/SystemMessage/nudgedBoligMappaSystemMessage';
import {
  CHAT_DETAILS_QUERY_chat_Chat,
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node,
  MessageType,
} from './generated/generated';
import {
  ChatMessageEdge as ChatMessageType,
  ChatMessageNode,
} from './query/ChatConversation/ChatConversation';
import { ChatDetailsMessageNode } from './query/ChatDetails/ChatDetailsQuery';
import { JobOwner } from './query/JobOwner/JobOwnerQuery';
import { getUserName } from './user';

export enum MessageAuthorType {
  Business = 'business',
  Consumer = 'consumer',
  System = 'system',
}

type AnyChat =
  | CHAT_DETAILS_QUERY_chat_Chat
  | CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node;

export const isChatUnread = (chat: AnyChat): boolean => {
  return !!chat && chat?.isUnread;
};

const ONE_MINUTE_IN_SECONDS = 60;
export const MINUTES_THRESHOLD_FOR_MESSAGE_GROUP = 2;

const isMessagePartOfGroup = (
  testedMessage: ChatMessageType,
  previousMessage: ChatMessageType,
  messageGroupTimeThreshold = MINUTES_THRESHOLD_FOR_MESSAGE_GROUP
) => {
  const secondsBetweenMessages =
    testedMessage.node.regTs - previousMessage.node.regTs;
  return (
    secondsBetweenMessages / ONE_MINUTE_IN_SECONDS <= messageGroupTimeThreshold
  );
};

export const shouldDisplayMessageDate = (
  message: ChatMessageType,
  previousMessage?: ChatMessageType
) => {
  if (!previousMessage) return true;
  return !isMessagePartOfGroup(message, previousMessage);
};

export const shouldDisplayWriterAvatar = (
  message: ChatMessageType,
  nextMessage?: ChatMessageType
) => {
  if (!nextMessage) return true;
  return !isMessagePartOfGroup(nextMessage, message);
};

interface GetMessageWriterNameArgs {
  message: Pick<ChatMessageNode, 'type' | 'business' | 'writer'>;
  consumer: JobOwner['owner'];
  businessName: string;
  productName: string;
}

export const getMessageWriterName = ({
  message,
  consumer,
  businessName,
  productName,
}: GetMessageWriterNameArgs): string => {
  if (message.type === MessageType.SYSTEM) return productName;
  if (message.business) return businessName;
  if (consumer?.id === message?.writer?.id) return getUserName(consumer);
  return '';
};

export const getMessageAuthorType = (
  message: Pick<ChatDetailsMessageNode, 'type' | 'business'>
): MessageAuthorType => {
  if (message.type === MessageType.SYSTEM) return MessageAuthorType.System;
  if (message.business) return MessageAuthorType.Business;
  return MessageAuthorType.Consumer;
};

interface GetMessageTextArgs {
  message: Pick<ChatMessageNode, 'type' | 'text'>;
  businessName: string;
  consumer: JobOwner['owner'];
}

export const getMessageText = ({
  message,
  businessName,
  consumer,
}: GetMessageTextArgs): string | null => {
  if (message.type === MessageType.SYSTEM) {
    const { type } = getSystemMessagePayload(message.text);
    const systemMessageKind = getSystemMessageKind(type);
    switch (systemMessageKind) {
      case SystemMessageKind.BOLIGMAPPA: {
        return boligMappaSystemMessage(type, businessName)?.text;
      }
      case SystemMessageKind.NUDGED_BOLIGMAPPA: {
        return nudgedBoligMappaSystemMessage(type, getUserName(consumer))?.text;
      }
      case SystemMessageKind.CONTRACT: {
        return contractSystemMessage(type, businessName, getUserName(consumer))
          ?.text;
      }
      default: {
        return null;
      }
    }
  }
  return message.text ?? null;
};
