import { sortByName } from './arrayUtils';
import {
  District,
  DistrictAvailable,
  JobsAreas,
  Municipality,
  MunicipalityAvailable,
} from './query/JobsAreas/JobsAreasQuery';

export type DistrictType = Pick<District, 'id' | 'name'>;

export interface CountiesMunicipality
  extends Omit<Municipality, 'county' | 'districts'> {
  districts: DistrictType[];
}

export type MunicipalityType = CountiesMunicipality | Municipality;

export interface CountyWithMunicipalities {
  id: Municipality['id'];
  name: Municipality['name'];
  municipalities: CountiesMunicipality[];
}

export const placeholderJobAreasPayload: JobsAreas = {
  districts: [],
  municipalities: [],
  municipalitiesAvailable: [],
  districtsAvailable: [],
  id: '',
  __typename: 'BusinessJobAreas',
};

export const filterOutNullishDistricts = (
  districtList: (DistrictType | null)[] | null
): DistrictType[] => {
  if (!districtList) return [];
  return districtList.filter(Boolean);
};

// create data structure for easier mapping
export const getCountiesWithMunicipalities = (
  municipalityList: MunicipalityAvailable[] | Municipality[],
  allowedDistrictList: DistrictAvailable[] | District[]
): CountyWithMunicipalities[] => {
  const uniqueCountyIds: string[] = [];
  const countiesWithMunicipalities: CountyWithMunicipalities[] = [];

  municipalityList.forEach((municipality) => {
    const mappedMunicipality: CountiesMunicipality = {
      __typename: 'Municipality',
      id: municipality.id,
      name: municipality.name,
      code: municipality.code,
      districts: filterOutNullishDistricts(municipality.districts)
        // filter out districts that are not available for user (municipality always contains all districts, available & unavailable)
        .filter((d) => allowedDistrictList.some((ad) => ad.id === d.id))
        .sort(sortByName),
    };

    if (!uniqueCountyIds.includes(municipality.county.id)) {
      uniqueCountyIds.push(municipality.county.id);
      countiesWithMunicipalities.push({
        id: municipality.county.id,
        name: municipality.county.name,
        municipalities: [mappedMunicipality],
      });
    } else {
      countiesWithMunicipalities[
        countiesWithMunicipalities.findIndex(
          (county) => county.id === municipality.county.id
        )
      ].municipalities.push(mappedMunicipality);
    }
  });

  return countiesWithMunicipalities
    .sort(sortByName)
    .map((a) => ({ ...a, municipalities: a.municipalities.sort(sortByName) }));
};

export const getMunicipalityBasedOnDistrictId = (
  districtId: DistrictType['id'],
  municipalityList: MunicipalityType[]
): MunicipalityType | undefined => {
  return municipalityList.find((m) =>
    m.districts?.some((district) => district?.id === districtId)
  );
};

export const getMunicipalityBasedOnCode = (
  municipalityCode: MunicipalityType['code'],
  municipalityList: MunicipalityType[]
): MunicipalityType | undefined => {
  return municipalityList.find((m) => m.code === municipalityCode);
};

export const getMunicipalityCodes = (
  municipalityList: MunicipalityType[]
): MunicipalityType['code'][] => municipalityList.map((m) => m.code);

export const getDistrictIds = (
  districtList: DistrictType[]
): DistrictType['id'][] => districtList.map((d) => d.id);

export const isMunicipalityActive = (
  municipality: MunicipalityType,
  activeMunicipalityCodeList: MunicipalityType['code'][]
): boolean => {
  return activeMunicipalityCodeList.includes(municipality.code);
};

export const isDistrictActive = (
  district: DistrictType,
  activeDistrictIdList: DistrictType['id'][]
): boolean => {
  return activeDistrictIdList.includes(district.id);
};

export const isCountyActive = (
  county: CountyWithMunicipalities,
  activeMunicipalityCodeList: MunicipalityType['code'][]
): boolean => {
  return county.municipalities.every((countyMunicipality) =>
    activeMunicipalityCodeList.includes(countyMunicipality.code)
  );
};

export const allDistrictsInMunicipalityActive = (
  municipality: MunicipalityType,
  activeDistrictIdList: DistrictType['id'][]
): boolean => {
  if (!municipality.districts || !municipality.districts.length) return false;
  return filterOutNullishDistricts(municipality.districts).every((d) =>
    isDistrictActive(d, activeDistrictIdList)
  );
};

export const anyDistrictInMunicipalityActive = (
  municipality: MunicipalityType,
  activeDistrictIdList: DistrictType['id'][]
): boolean => {
  return filterOutNullishDistricts(municipality.districts).some((d) =>
    isDistrictActive(d, activeDistrictIdList)
  );
};

export const hasSingleMunicipalityWithDistricts = (
  county: CountyWithMunicipalities
): boolean => {
  return (
    county.municipalities.length === 1 &&
    !!county.municipalities[0].districts.length
  );
};

interface FilterType {
  id: string;
  name: string;
}

export const getActiveDistrictFiltersInCounty = (
  county: CountyWithMunicipalities,
  activeDistricts: DistrictType['id'][]
): FilterType[] => {
  return county.municipalities
    .flatMap((m) =>
      m.districts.filter((district) => activeDistricts.includes(district.id))
    )
    .map(({ name, id }) => ({ name, id }));
};

export const getActiveMunicipalityFiltersInCounty = (
  county: CountyWithMunicipalities,
  activeDistricts: DistrictType['id'][],
  activeMunicipalities: MunicipalityType['code'][]
): FilterType[] => {
  return county.municipalities.reduce<FilterType[]>(
    (selectedMunicipalityFilters, currMunicipality) => {
      const municipalitySelected =
        isMunicipalityActive(currMunicipality, activeMunicipalities) ||
        anyDistrictInMunicipalityActive(currMunicipality, activeDistricts);
      if (!municipalitySelected) return selectedMunicipalityFilters;
      return [
        ...selectedMunicipalityFilters,
        { name: currMunicipality.name, id: currMunicipality.code.toString() },
      ];
    },
    []
  );
};

export const getSelectedFiltersInCounty = (
  county: CountyWithMunicipalities,
  activeDistricts: DistrictType['id'][],
  activeMunicipalities: MunicipalityType['code'][]
) => {
  if (hasSingleMunicipalityWithDistricts(county)) {
    return getActiveDistrictFiltersInCounty(county, activeDistricts);
  }
  return getActiveMunicipalityFiltersInCounty(
    county,
    activeDistricts,
    activeMunicipalities
  );
};

export const anyAreaInCountyActive = (
  county: CountyWithMunicipalities,
  activeDistricts: DistrictType['id'][],
  activeMunicipalities: MunicipalityType['code'][]
): boolean => {
  return (
    getActiveMunicipalityFiltersInCounty(
      county,
      activeDistricts,
      activeMunicipalities
    ).length > 0
  );
};
