import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const EditFormDeleteButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.red.base};
    border-color: ${({ theme }) => theme.colors.black.black3};
  }
`;
