import { FC, MouseEvent } from 'react';

import Badge from '@components/base/Badge';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEventSpace,
  ANEvent,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { NavLinkProps } from 'react-router-dom';

import Link from './styled/Link';
import TextWrapper from './styled/TextWrapper';

export interface LinkProps extends NavLinkProps {
  mr?: number;
  ml?: number;
  mx?: number;
  pl?: number;
  pr?: number;
  px?: number;
  py?: number;
  iconName?: IconPickerName;
  badgeText?: string;
  analyticsObject?: ANObject;
  $hideLinkBackground?: boolean;
  activeOverwrite?: boolean;
}

const NavLink: FC<LinkProps> = ({
  to,
  children,
  mr,
  ml,
  mx,
  pl,
  pr,
  px,
  py,
  iconName,
  badgeText = '',
  analyticsObject = ANObject.None,
  $hideLinkBackground = false,
  activeOverwrite,
  onClick,
  ...props
}) => {
  const { track } = useAnalytics();
  const isTabletOrMobile = useIsTabletOrMobile();

  const onLinkClick = (
    event: MouseEvent<HTMLAnchorElement>,
    analyticsObjectName: ANObject
  ) => {
    onClick?.(event);
    track(ANEventSpace(ANEvent.Clicked, analyticsObjectName, ANPage.Navbar));
  };

  const fillActive = isTabletOrMobile ? '#3960BF' : '#FAEDD5';
  const fillInactive = isTabletOrMobile ? '#58637A' : '#C3C9E4';

  return (
    <Link
      to={to}
      $hideLinkBackground={$hideLinkBackground}
      mr={mr}
      ml={ml}
      mx={mx}
      pl={pl}
      pr={pr}
      px={px}
      py={py}
      {...props}
      onClick={(e) => {
        onLinkClick(e, analyticsObject);
      }}
      {...(activeOverwrite && {
        className: 'active',
      })}
    >
      {({ isActive }) => (
        <>
          {iconName && (
            <FireIcon
              iconName={iconName}
              fill={activeOverwrite || isActive ? fillActive : fillInactive}
            />
          )}
          <TextWrapper fontSize={[1, null, null, null, null, null, 3]}>
            <>
              {children}
              {badgeText && (
                <Badge
                  text={+badgeText > 99 ? '99+' : badgeText}
                  isResponsive
                />
              )}
            </>
          </TextWrapper>
        </>
      )}
    </Link>
  );
};
export default NavLink;
