import isEqual from 'lodash.isequal';

export const isEmptyObject = (obj?: Record<string, unknown>): boolean => {
  return !(obj && Object.keys(obj).length > 0);
};

export const sortKeysAndRemoveDuplicates = <T extends string | number>(
  obj: Record<string, T | T[]>
): Record<string, T | T[]> => {
  const newObj: Record<string, T | T[]> = {};

  Object.entries(obj)
    .sort()
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        newObj[key] = Array.from(new Set(value)).sort();
      } else {
        newObj[key] = value;
      }
    });

  return newObj;
};

export const filterOutEmptyValueKeys = (
  obj: Record<PropertyKey, unknown>
): Record<PropertyKey, unknown> => {
  return Object.entries(obj).reduce((filteredObj, [key, value]) => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === 'string' && value.trim() === '') ||
      (Array.isArray(value) && value.length === 0) ||
      (typeof value === 'object' &&
        isEmptyObject(value as Record<string, unknown>))
    ) {
      return filteredObj;
    }
    return { ...filteredObj, [key]: value };
  }, {});
};

export const pick = <T extends Record<PropertyKey, unknown>, K extends keyof T>(
  obj: T,
  keys: K[]
): Pick<T, K> => {
  return keys.reduce((acc, key) => {
    if (key in obj) {
      return { ...acc, [key]: obj[key] };
    }
    return acc;
  }, {} as Pick<T, K>);
};

export const isEmptyValuesObject = (obj?: Record<string, unknown>): boolean => {
  return isEmptyObject(filterOutEmptyValueKeys(obj || {}));
};

// compares two objects by sorting their keys and removing duplicates
export const isEqualObjects = <T extends string | number>(
  obj1: Record<PropertyKey, T | T[]>,
  obj2: Record<PropertyKey, T | T[]>
): boolean =>
  isEqual(sortKeysAndRemoveDuplicates(obj1), sortKeysAndRemoveDuplicates(obj2));

export const isNullOrUndefined = (value: unknown): boolean => {
  return value === null || value === undefined;
};
