import { useState, useCallback } from 'react';

type ToggleType = readonly [boolean, () => void, () => void, () => void];

/**
 * @method useBoolean
 *
 * @param initialValue - default set to false
 * @returns [value, setTrue, setFalse, toggle] methods
 *
 */

const useBoolean = (initialValue: boolean | undefined = false): ToggleType => {
  const [value, setValue] = useState<boolean>(initialValue === true);
  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((val) => !val), []);
  return [value, setTrue, setFalse, toggle];
};

export default useBoolean;
