import gql from 'graphql-tag';

import {
  ASK_TO_BE_SELECTEDVariables,
  ASK_TO_BE_SELECTED as ASK_TO_BE_SELECTED_Result,
} from '../../generated/generated';

export type AskToBeSelectedPayload = ASK_TO_BE_SELECTED_Result;
export type AskToBeSelectedVariables = ASK_TO_BE_SELECTEDVariables;

export const ASK_TO_BE_SELECTED = gql`
  mutation ASK_TO_BE_SELECTED($jobId: ID!) {
    businessAskToBeSelected(input: { jobId: $jobId })
  }
`;
