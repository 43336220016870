import { GQLErrorState, QualificationListResponseError } from './errors';
import {
  isQualificationListQuerySuccessResponse,
  QualificationListQueryPayload,
} from './query/QualificationList/QualificationListQuery';

export const getQualificationListResponseError = (
  payload: QualificationListQueryPayload
): GQLErrorState => {
  const hasErrors = !isQualificationListQuerySuccessResponse(payload?.business);
  if (hasErrors) {
    const qualificationListResponseError = new QualificationListResponseError(
      'Business'
    );
    return qualificationListResponseError.getErrors();
  }
  return {};
};
