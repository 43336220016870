import { FC } from 'react';

import { ProductActivePackage } from '@components/layout/Products/ProductActivePackage';
import { ProductAddonList } from '@components/layout/Products/ProductAddonList';
import { ProductHeader } from '@components/layout/Products/ProductHeader';
import { ProductRewardCard } from '@components/layout/Products/ProductRewardCard';
import { ProductSection } from '@components/layout/Products/ProductSection';
import Paths from '@router/paths';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Products: FC = () => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const navigate = useNavigate();

  return (
    <>
      <ProductHeader
        title={t('products.title')}
        subtitle={t('products.subtitle', { productName })}
      />
      <ProductSection title={t('products.activePackage.title')}>
        <ProductActivePackage />
      </ProductSection>
      <ProductSection title={t('products.addons.title')}>
        <ProductAddonList />
      </ProductSection>
      <ProductSection title={t('products.rewards.title', { productName })}>
        <ProductRewardCard
          title={t('products.rewards.title', { productName })}
          subtitle={t('products.rewards.subtitle', { productName })}
          action={{
            text: t('products.rewards.action'),
            handler: () => navigate(Paths.SettingsProductRewards),
          }}
        />
      </ProductSection>
    </>
  );
};
