import { UserRole } from '@internals/business-graphql/src/graphql/generated/types';

import { log } from '../log';
import type { User } from '../types';
import type { AuthHandlers } from './types';

export const handleLoginSuccess = async (
  user: User,
  handlers: AuthHandlers,
  intendedRole?: UserRole
) => {
  // Operating as can only be null or a company
  const { isConsumerUser, companies, operatingAs } = user;

  const hasCompany = companies && companies.length >= 1;
  const hasMultipleCompanies = companies && companies.length > 1;
  const hasMultipleProfileTypes =
    isConsumerUser && companies && companies.length >= 1;

  /** If operatingAs is true the user is currently active as a company */
  const isCompany = !!operatingAs;

  // User is a consumer and intended role is consumer
  if (intendedRole === UserRole.Consumer && isConsumerUser && !isCompany) {
    log('User is a consumer and intended role is consumer');
    // TODO: We possibly have to check operatingAs and change to consumer if it's set to a company?
    await handlers.onConsumerUser(user);
    return;
  }

  // User is an employee and intended role is employee
  if (intendedRole === UserRole.Employee && hasCompany) {
    log('User is an employee and intended role is employee');
    // TODO: Here we should check the intended company id, if it doesn't match the operatingAs we should skip to the multiple profiles handler?
    if (isCompany) {
      await handlers.onBusinessUser(user);
      return;
    }

    // If the user has multiple companies, we need to ask them which one they want to operate as
    if (hasMultipleCompanies) {
      log('User has multiple companies');
      await handlers.onMultipleProfiles(user);
      return;
    }

    await handlers.onBusinessUser(user);
    return;
  }

  // There was no intended role, but the user has a company and is a consumer
  if (!intendedRole && hasMultipleProfileTypes) {
    log('User has multiple profile types', user);
    await handlers.onMultipleProfiles(user);
    return;
  }

  // There was no intended role, but the user was logged into a company
  if (operatingAs) {
    log('User is operating as a company');

    if (hasMultipleCompanies) {
      log('User has multiple companies');
      await handlers.onMultipleProfiles(user);
    } else {
      await handlers.onBusinessUser(user);
    }

    return;
  }

  await handlers.onSuccessFallback(user);
};
