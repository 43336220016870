import {
  PRODUCTS_QUERY_business_Business_products,
  ProductTemplateSlug,
} from './generated/generated';

export enum ProductType {
  BasicPackage = 'Grunnpakke',
  JobPackage = 'Jobbpakke',
  ProfessionalPackage = 'Proffpakke',
  Advertising = 'Annonsering',
  CompanySearch = 'Bedriftssøk',
  Homepage = 'Hjemmeside',
  Clips = 'Klipp',
  Boost = 'Boost',
  MittanbudXL = 'MittanbudXL',
  Subscription = 'Subscription',
}

export type ProductsStatus = {
  display: boolean;
  loading: boolean;
  success: boolean;
  owned: boolean;
};

export type ProductDisplayType = Record<
  Exclude<ProductType, 'Boost' | 'MittanbudXL' | 'Subscription'>,
  ProductsStatus
>;

export const setProducts = (
  products?: PRODUCTS_QUERY_business_Business_products
): ProductDisplayType => ({
  [ProductType.BasicPackage]: {
    loading: false,
    display: (products?.basicPackage?.isOwned ?? false) === false,
    success: false,
    owned: products?.basicPackage?.isOwned ?? false,
  },
  [ProductType.JobPackage]: {
    loading: false,
    display: (products?.jobPackage?.isOwned ?? false) === false,
    success: false,
    owned: products?.jobPackage?.isOwned ?? false,
  },
  [ProductType.ProfessionalPackage]: {
    loading: false,
    display: (products?.proPackage?.isOwned ?? false) === false,
    success: false,
    owned: products?.proPackage?.isOwned ?? false,
  },
  [ProductType.Advertising]: {
    loading: false,
    display: (products?.advertising?.isOwned ?? false) === false,
    success: false,
    owned: products?.advertising?.isOwned ?? false,
  },
  [ProductType.CompanySearch]: {
    loading: false,
    display: (products?.companySearch?.isOwned ?? false) === false,
    success: false,
    owned: products?.companySearch?.isOwned ?? false,
  },
  [ProductType.Homepage]: {
    loading: false,
    display: (products?.homepage?.isOwned ?? false) === false,
    success: false,
    owned: products?.homepage?.isOwned ?? false,
  },
  [ProductType.Clips]: {
    loading: false,
    display: true,
    success: false,
    owned: false,
  },
});

export const getProductTemplateSlug = (
  type: ProductType
): ProductTemplateSlug => {
  switch (type) {
    case ProductType.BasicPackage:
      return ProductTemplateSlug.PROFILE_PAGE;
    case ProductType.JobPackage:
      return ProductTemplateSlug.SUBSCRIPTION;
    case ProductType.ProfessionalPackage:
      return ProductTemplateSlug.PRO;
    case ProductType.Advertising:
      return ProductTemplateSlug.ADVERTISEMENT;
    case ProductType.CompanySearch:
      return ProductTemplateSlug.SEARCH;
    case ProductType.Homepage:
      return ProductTemplateSlug.HOMEPAGE;
    case ProductType.Clips:
      return ProductTemplateSlug.SUBSCRIPTION;
    case ProductType.Boost:
      return ProductTemplateSlug.BOOST;
    case ProductType.MittanbudXL:
      return ProductTemplateSlug.MITTANBUD_XL;
    case ProductType.Subscription:
      return ProductTemplateSlug.SUBSCRIPTION;
    default:
      return ProductTemplateSlug.PROFILE_PAGE;
  }
};
