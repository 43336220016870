import { FC, PropsWithChildren } from 'react';

import { Div } from '@schibsted-smb/fireball';

export const BodyContainer: FC<PropsWithChildren> = ({ children }) => (
  <Div
    display="flex"
    flexDirection="column"
    justifyContent="space-between"
    position="relative"
    pb={5}
    pt={4}
    px={5}
  >
    {children}
  </Div>
);
