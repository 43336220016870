import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_BUSINESS_EMPLOYEE,
  UpdateBusinessEmployeeMutationPayload,
  UpdateBusinessEmployeeMutationVariables,
} from '../../utils/mutation/UpdateBusinessEmployee/UpdateBusinessEmployeeMutation';

type UseUpdateBusinessEmployeeMutationAction = (
  input: UpdateBusinessEmployeeMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    UpdateBusinessEmployeeMutationPayload,
    UpdateBusinessEmployeeMutationVariables
  >
) => Promise<FetchResult<UpdateBusinessEmployeeMutationPayload>>;

export const useUpdateBusinessEmployeeMutation = (): [
  UseUpdateBusinessEmployeeMutationAction,
  MutationResult<UpdateBusinessEmployeeMutationPayload>
] => {
  const [updateBusinessEmployee, ...rest] = useMutation<
    UpdateBusinessEmployeeMutationPayload,
    UpdateBusinessEmployeeMutationVariables
  >(UPDATE_BUSINESS_EMPLOYEE);

  const mutationFunction: UseUpdateBusinessEmployeeMutationAction = useCallback(
    (input, mutationOptions) =>
      updateBusinessEmployee({
        variables: {
          input: {
            ...input,
          },
        },
        ...mutationOptions,
      }),
    [updateBusinessEmployee]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
