import { REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload } from '../generated/generated';
import { ReplyToEvaluationMutationPayload } from '../mutation/ReplyToEvaluation/ReplyToEvaluationMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class ReplyToEvaluationResponseError extends GQLResponseErrorBase<
  ReplyToEvaluationMutationPayload['replyToEvaluation'],
  REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'ReplyToEvaluationInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'description', 'danger');
        break;
      case 'EvaluationNotFoundError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      case 'AccessDeniedError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
