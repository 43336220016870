import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const TextContainer = styled((props) => <Text.p {...props} />)`
  font-size: ${(props): string => props.theme.fontSizes[1]};
  font-weight: ${(props): number => props.theme.fontWeights.medium};
  margin-bottom: 0;
`;

export default TextContainer;
