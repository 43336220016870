import { FC } from 'react';

import { CompanyLogoType } from '@components/layout/Profile/ProfileGraphic/index';
import {
  FILE_UPLOAD_TOTAL_SIZE_LIMIT,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
  FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
  IMAGE_FILE_EXTENSIONS,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import { Div, Heading, Dropzone, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProfileGraphicLogoProps {
  companyLogo: CompanyLogoType;
  setCompanyLogo: (logo: CompanyLogoType) => void;
}
export const ProfileGraphicLogo: FC<ProfileGraphicLogoProps> = ({
  companyLogo,
  setCompanyLogo,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  return (
    <Div
      mx={6}
      mb={7}
      borderBottom={`1px solid ${themeContext.colors.black.black3}`}
    >
      <Heading.h3 mb={2}>{t('profile.graphic.logo.title')}</Heading.h3>
      <Text.span mb={2}>{t('profile.graphic.logo.recommendedSize')}</Text.span>
      <Div mb={7}>
        <Dropzone
          dropzoneType="list"
          files={companyLogo}
          setFiles={setCompanyLogo}
          multiple={false}
          objectFit="contain"
          dropzoneListUploadInfo={t('profile.graphic.logo.edit.label')}
          fileType="jpg"
          maxSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          allowedFiles={{
            'image/*': IMAGE_FILE_EXTENSIONS,
          }}
          testId="logo-upload"
          invalidSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidTypeErrorMessage={t('component.dropzone.invalidFileTypeError')}
          invalidTotalSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidMaxFilesErrorMessage={t('component.dropzone.maxFilesError', {
            maxFiles: FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
          })}
          dropzoneListChangeImageButton={t(
            'component.dropzone.changeImage',
            'Change image'
          )}
        />
      </Div>
    </Div>
  );
};
