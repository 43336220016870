import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const CHAT_JOB_COLUMN_PX_WIDTH = '360px';

const ChatJobInfoColumn = styled(Div)<{ hideOnMobile?: boolean }>`
  display: flex;
  border-left: ${({ theme }): string =>
    `1px solid ${theme.colors.black.black3}`};
  width: ${CHAT_JOB_COLUMN_PX_WIDTH};
  min-width: ${CHAT_JOB_COLUMN_PX_WIDTH};
  background-color: ${({ theme }): string => theme.colors.black.black0};
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  height: 100%;

  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[6]}) {
    display: ${({ hideOnMobile }): string => (hideOnMobile ? 'none' : 'block')};
  }

  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    width: 100%;
  }
`;

export default ChatJobInfoColumn;
