import { FC } from 'react';

import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { MobileBackButton } from './MobileBackButton';
import { MobileMessageTemplateLayout } from './MobileMessageTemplateLayout';

interface MobileTemplateViewProps {
  template: MessageTemplate;
  onBackClick: VoidFunction;
  onEditClick: (template: MessageTemplate) => void;
  onPasteInClick: (text: string) => void;
}

export const MobileTemplateView: FC<MobileTemplateViewProps> = ({
  template,
  onPasteInClick,
  onEditClick,
  onBackClick,
}) => {
  const { t } = useTranslation();

  return (
    <MobileMessageTemplateLayout
      header={
        <Div display="flex" justifyContent="space-between" width="100%">
          <MobileBackButton onClick={onBackClick} />
          <Button
            variant="linkPrimary"
            onClick={() => onEditClick(template)}
            px={4}
          >
            {t('general.label.edit')}
          </Button>
        </Div>
      }
      footer={
        <Button fullWidth onClick={() => onPasteInClick(template.text)}>
          {t('chat.item.template.form.paste')}
        </Button>
      }
    >
      <Text.p fontSize={3} fontWeight="medium" mb={5}>
        {template.title}
      </Text.p>
      <Text.p m={0}>{template.text}</Text.p>
    </MobileMessageTemplateLayout>
  );
};
