import { FC, useState } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import SearchInput from '@components/base/SearchInput';
import EvaluationRequestContent from '@components/layout/CustomerService/Evaluation/EvaluationRequest/EvaluationRequestContent';
import { useDebouncedInput } from '@hooks/useDebounce';
import { useJobToEvaluateListQuery } from '@hooks/useJobToEvaluateListQuery';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Heading, Modal } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface EvaluationRequestProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

const EvaluationRequest: FC<EvaluationRequestProps> = ({ isOpen, onClose }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const { inputValue, debouncedInputValue, setInputValue } =
    useDebouncedInput(500);
  const query = debouncedInputValue.trim();
  const { data, loading, error, refetch } = useJobToEvaluateListQuery({
    variables: { page, ...(query && { query }) },
  });
  const clearSearch = () => {
    setInputValue('');
  };
  const onModalClose = () => {
    setPage(1);
    clearSearch();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      isClosable={false}
      onClose={onModalClose}
      contentProps={{ p: 0, m: 0 }}
      headerProps={{
        my: 0,
        p: 6,
        borderBottom: `1px solid ${themeContext.colors.black.black3}`,
      }}
      isSmaller
      header={
        <Div
          width="100%"
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Div display="flex" alignItems="center">
            <Button onClick={onModalClose} variant="linkTertiary" p={0}>
              <FireIcon
                width="24px"
                iconName={IconPickerName.ArrowLeft}
                fill={themeContext.colors.primary.base}
                variant="flat"
              />
            </Button>
            <Heading.h2 mb={0} ml={7} textAlign="left">
              {t('general.action.requestEvaluation')}
            </Heading.h2>
          </Div>
          <Div>
            <SearchInput
              inputValue={inputValue}
              changeInputValue={setInputValue}
              width="100%"
              height="40px"
              borderRadius={25}
            />
          </Div>
        </Div>
      }
    >
      <Div
        minHeight={['250px', null, null, null, null, null, '462px', '620px']}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <EvaluationRequestContent
          onRefetch={refetch}
          clearSearch={clearSearch}
          searchQuery={query}
          loading={loading}
          error={error}
          {...(data && {
            listProps: { jobs: data, page, onPageChange: setPage },
          })}
        />
      </Div>
    </Modal>
  );
};

export default EvaluationRequest;
