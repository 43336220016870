import * as React from 'react';

import { ReactComponent as DeleteIllustration } from '@internals/business-shared/src/assets/illustrations/askforconfirmation.svg';
import { Text, Button, Modal } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProfilePicturesDeleteAlbumModalProps {
  isOpen: boolean;
  onClose: () => void;
  albumTitle: string;
  onDelete: () => void;
}

const ProfilePicturesDeleteAlbumModal: React.FC<
  React.PropsWithChildren<ProfilePicturesDeleteAlbumModalProps>
> = ({ isOpen, onClose, albumTitle, onDelete }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      isClosable
      onClose={onClose}
      isSmaller
      size="custom"
      maxWidth="400px"
      contentProps={{ pt: 7, pb: 4, px: 8 }}
    >
      <DeleteIllustration width="140px" height="126px" />
      <Text.p
        mt={6}
        mb={7}
        textAlign="center"
        fontSize={4}
        fontWeight={themeContext.fontWeights.bold}
        color="black.black9"
      >
        {t('profile.pictures.album.delete.confirm.title')}?
      </Text.p>
      <Text.p mb={7} textAlign="center" fontSize={2} color="black.black9">
        {t('profile.pictures.album.delete.confirm.text', {
          albumName: albumTitle,
        })}
      </Text.p>
      <Button
        variant="danger"
        size="large"
        fullWidth
        onClick={onDelete}
        data-testid="delete-confirm-btn"
      >
        {t('profile.pictures.album.delete.action')}
      </Button>
      <Button
        variant="linkTertiary"
        size="medium"
        fullWidth
        onClick={onClose}
        data-testid="delete-cancel-btn"
      >
        {t('profile.pictures.album.delete.cancel')}
      </Button>
    </Modal>
  );
};

export default ProfilePicturesDeleteAlbumModal;
