import { FC, KeyboardEvent } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import SelectedFilters, {
  FilterType,
} from '@components/elements/JobsFilters/SelectedFilters';
import {
  FilterButtonWrapper,
  FilterItemWrapper,
  FilterName,
} from '@components/elements/JobsFilters/styled';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface FiltersButtonProps {
  filterName: string;
  selectedFilters: FilterType[];
  onSelectedClick: (filterId: string | string[]) => void;
  onClick: VoidFunction;
}

const FilterGroupButton: FC<React.PropsWithChildren<FiltersButtonProps>> = ({
  onClick,
  filterName,
  selectedFilters,
  onSelectedClick,
}) => {
  const themeContext = useTheme();
  const isMobile = useIsMobile();
  const iconSize = isMobile ? '16px' : '12px';
  const iconColor = isMobile
    ? themeContext.colors.black.black10
    : themeContext.colors.black.black7;

  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <FilterButtonWrapper
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      <Div width="100%" alignItems="center" minWidth={0}>
        <FilterItemWrapper>
          <FilterName limitSpace={!!selectedFilters.length} truncate>
            {filterName}
          </FilterName>
          <SelectedFilters
            filterList={selectedFilters}
            onClick={onSelectedClick}
          />
        </FilterItemWrapper>
      </Div>
      <Div display="flex" ml={4} alignItems="center">
        <FireIcon
          iconName={IconPickerName.ChevronRightBold}
          fill={iconColor}
          variant="flat"
          width={iconSize}
          height={iconSize}
        />
      </Div>
    </FilterButtonWrapper>
  );
};

export default FilterGroupButton;
