import {
  initI18n,
  i18n,
  t as _t,
} from '@internals/business-translations/src/i18n';
import {
  LANGUAGE_KEY,
  supportedLanguages,
} from '@internals/business-translations/src/languages';
import { getProduct } from '@utils/product';
import { LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';

import { isDev } from './envChecks';

const { defaultLanguage } = getProduct();

// Checks if query string or cookie is present and
// changes language accordingly, defaults to norwegian
// To use cookie and a cookie named lang and value to be en/no
// To use query string add ?lang={en/nb}, query string will override cookie
// Added mostly for testing/preview purposes, defaults to Norwegian

const cookies = new Cookies();
const languageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  detect: () => {
    const queryLangValue = new URLSearchParams(window.location.search).get(
      LANGUAGE_KEY
    );
    if (
      queryLangValue &&
      supportedLanguages.find((item) => item.localeCode === queryLangValue)
    ) {
      return queryLangValue;
    }
    const langCookie = cookies.get(LANGUAGE_KEY);
    return supportedLanguages.find((item) => item.languageCode === langCookie)
      ? langCookie
      : defaultLanguage;
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  cacheUserLanguage: (language) => {
    cookies.set(LANGUAGE_KEY, language);
  },
};

initI18n(initReactI18next, languageDetector, {
  interpolation: {
    escapeValue: false,
  },
  debug: isDev,
});
export const t = _t;

export default i18n;
