import { FC } from 'react';

import { Div } from '@schibsted-smb/fireball';
import { Outlet } from 'react-router-dom';

export const ProductPageLayout: FC = () => (
  <Div backgroundColor="black.black0" minHeight="100dvh" px={5}>
    <Outlet />
  </Div>
);
