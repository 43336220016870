import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

// text style of input error message from fireball
const InlineErrorMessage = styled(Text.span)`
  color: ${({ theme }) => theme.colors.red.base};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export default InlineErrorMessage;
