import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_JOBS_SAVED_FILTER_MUTATION,
  UpdateJobsSavedFilterMutationPayload,
  UpdateJobsSavedFilterMutationVariables,
} from '../../utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';

type UseUpdateJobsSavedFilterMutationAction = (
  input: UpdateJobsSavedFilterMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    UpdateJobsSavedFilterMutationPayload,
    UpdateJobsSavedFilterMutationVariables
  >
) => Promise<FetchResult<UpdateJobsSavedFilterMutationPayload>>;

export const useUpdateJobsSavedFilterMutation = (): [
  UseUpdateJobsSavedFilterMutationAction,
  MutationResult<UpdateJobsSavedFilterMutationPayload>
] => {
  const [updateJobsSavedFilter, ...rest] = useMutation<
    UpdateJobsSavedFilterMutationPayload,
    UpdateJobsSavedFilterMutationVariables
  >(UPDATE_JOBS_SAVED_FILTER_MUTATION);

  const mutationFunction: UseUpdateJobsSavedFilterMutationAction = useCallback(
    (input, mutationOptions) =>
      updateJobsSavedFilter({
        variables: {
          input: {
            ...input,
          },
        },
        ...mutationOptions,
      }),
    [updateJobsSavedFilter]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
