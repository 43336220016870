import * as React from 'react';

import NotFound from '@components/elements/NotFound';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import { useUserBusinessId } from '@contexts/UserContext';
import { usePageAnalytics } from '@hooks/useAnalytics';
import { useJobSearchSettingsQuery } from '@internals/business-shared/src/hooks/query/useJobSearchSettings';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import Paths from '@router/paths';
import { useTranslation } from 'react-i18next';

import AreasAccordion from './AreasAccordion';
import JobSearchAccordion from './JobSearchAccordion';

const Jobs: React.FC = () => {
  const { t } = useTranslation();
  const businessId = useUserBusinessId();
  const { data, loading, error, refetch } = useJobSearchSettingsQuery(
    businessId,
    {
      notifyOnNetworkStatusChange: true,
    }
  );

  const payloadWorkTypesAvailable = data?.workTypesAvailable ?? [];
  const payloadWorkTypes = data?.workTypes ?? [];
  const payloadSubsets = data?.subsets ?? [];
  const payloadSubsetsAvailable = data?.subsetsAvailable ?? [];
  const payloadMunicipalitiesEnabled = data?.municipalities ?? [];
  const payloadMunicipalitiesAvailable = data?.municipalitiesAvailable ?? [];
  const payloadDistrictsEnabled = data?.districts ?? [];
  const payloadDistrictsAvailable = data?.districtsAvailable ?? [];

  usePageAnalytics(ANSpace(ANObject.JobList, ANPage.Settings));

  return (
    <SettingsContainer>
      {loading && <SettingsLoading />}
      {!loading && error && (
        <NotFound
          title={t('settings.error.title')}
          text={t('settings.error.text')}
          link={{
            url: Paths.SettingsCustomerService,
            text: t('general.label.customerService'),
          }}
          button={{
            text: t('general.error.tryAgain'),
            onClick: () => refetch(),
          }}
        />
      )}
      {!loading && data && (
        <>
          <JobSearchAccordion
            payloadWorkTypesAvailable={payloadWorkTypesAvailable}
            payloadWorkTypes={payloadWorkTypes}
            payloadSubsets={payloadSubsets}
            payloadSubsetsAvailable={payloadSubsetsAvailable}
          />
          <AreasAccordion
            municipalitiesEnabled={payloadMunicipalitiesEnabled}
            municipalitiesAvailable={payloadMunicipalitiesAvailable}
            districtsEnabled={payloadDistrictsEnabled}
            districtsAvailable={payloadDistrictsAvailable}
          />
        </>
      )}
    </SettingsContainer>
  );
};

export default Jobs;
