import gql from 'graphql-tag';

import IMAGE_FRAGMENT from './ImageFragment';

const MESSAGE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}

  fragment MessageFragment on Message {
    id
    text
    type
    business {
      id
    }
    writer {
      id
    }
    status
    regTs
    images {
      ...ImageFragment
    }
    files {
      id
      name
      url
      fileType
    }
  }
`;

export default MESSAGE_FRAGMENT;
