import { FC, useEffect } from 'react';

import FullScreenLoader from '@components/elements/FullScreenLoader/FullScreenLoader';
import { useIsMultiAccountUser } from '@contexts/UserContext';
import { getAccountSwitchURL } from '@internals/business-shared/src/utils/publicUrls';
import PageNotFound from '@pages/PageNotFound';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { getProductId } from '@utils/product';

const AccountSwitch: FC<React.PropsWithChildren<unknown>> = () => {
  const isMultipleAccount = useIsMultiAccountUser();
  useEffect(() => {
    if (!isMultipleAccount) return;
    const accountSwitchUrl = getAccountSwitchURL(
      getEnvValue(ENV_KEY_NAME.ENVIRONMENT),
      getProductId()
    );
    window.location.replace(accountSwitchUrl);
  }, []);

  if (!isMultipleAccount) return <PageNotFound />;
  return <FullScreenLoader />;
};

export default AccountSwitch;
