import { FC, useCallback } from 'react';

import { TreeLabel } from '@components/layout/Jobs/Tree/styled/TreeLabel';
import { TreeSection } from '@components/layout/Jobs/Tree/styled/TreeSection';
import { TreeBranch } from '@components/layout/Jobs/Tree/TreeBranch';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import {
  FilteredJobCount,
  getJobsCountForFilter,
  joinFilterWithCount,
} from '@internals/business-shared/src/utils/jobFilters';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import {
  BaseWorkType,
  getWorkTypeId,
  WorkTypeGroup,
} from '@internals/business-shared/src/utils/workTypesUtils';
import { useTranslation } from 'react-i18next';

interface WorkTypesFilteringTreeProps {
  jobCount?: FilteredJobCount;
}

export const WorkTypesFilteringTree: FC<WorkTypesFilteringTreeProps> = ({
  jobCount,
}) => {
  const { t } = useTranslation();
  const {
    workTypes,
    activeWorkTypes,
    setActiveWorkTypes,
    activeIndustries,
    setActiveIndustries,
  } = useJobFiltersContext();
  const industries = workTypes.filter(
    (workTypeGroup) => workTypeGroup.variant === 'industry'
  );
  const isIndustryActive = useCallback(
    (industry: WorkTypeGroup) => {
      if (
        industry.workTypes.some((workType) =>
          activeWorkTypes.includes(workType.id)
        )
      ) {
        return true;
      }
      return activeIndustries.includes(industry.id);
    },
    [activeWorkTypes, activeIndustries]
  );

  const onIndustryChange = useCallback(
    (industry: WorkTypeGroup, isActive: boolean) => {
      if (!isActive) {
        setActiveWorkTypes(
          toggleItemInArray(
            activeWorkTypes,
            industry.workTypes.map((workType) => workType.id),
            false
          )
        );
      }
      setActiveIndustries(
        toggleItemInArray(activeIndustries, industry.id, isActive)
      );
    },
    [setActiveWorkTypes, activeWorkTypes, setActiveIndustries, activeIndustries]
  );

  const onWorkTypeChange = useCallback(
    (
      workType: BaseWorkType,
      parentIndustry: WorkTypeGroup,
      isActive: boolean
    ) => {
      if (isActive) {
        // remove group filter to include more specific work type one
        setActiveIndustries(
          toggleItemInArray(activeIndustries, parentIndustry.id, false)
        );
      } else {
        // when unselecting last work type filter from group, replace it back with group filter
        const isLastWorkTypeActiveInGroup =
          parentIndustry.workTypes.filter((groupWorkType) =>
            activeWorkTypes.includes(groupWorkType.id)
          ).length <= 1;
        if (isLastWorkTypeActiveInGroup) {
          setActiveIndustries(
            toggleItemInArray(activeIndustries, parentIndustry.id, true)
          );
        }
      }
      setActiveWorkTypes(
        toggleItemInArray(activeWorkTypes, workType.id, isActive)
      );
    },
    [setActiveIndustries, setActiveWorkTypes, activeIndustries, activeWorkTypes]
  );

  const getIndustryLabel = (industry: WorkTypeGroup) => {
    const count = getJobsCountForFilter(jobCount, 'industryIds', industry.id);
    return joinFilterWithCount(industry.name, count);
  };

  const getWorkTypeLabel = (workType: BaseWorkType) => {
    const count = getJobsCountForFilter(
      jobCount,
      'worktypeIds',
      getWorkTypeId(workType.id)
    );

    return joinFilterWithCount(workType.name, count);
  };

  const workTypeBranches = industries?.map((industry) => {
    const isActiveGroup = isIndustryActive(industry);
    return (
      <TreeBranch
        key={`${industry.id}-${industry.name}`}
        branch={{
          id: `${industry.id}-${industry.name}`,
          label: getIndustryLabel(industry),
          checked: isActiveGroup,
          onChange: () => onIndustryChange(industry, !isActiveGroup),
        }}
        leafs={industry.workTypes.map((workType) => {
          const isActiveWorkType = activeWorkTypes.includes(workType.id);
          return {
            id: workType.id,
            label: getWorkTypeLabel(workType),
            checked: isActiveWorkType,
            onChange: () =>
              onWorkTypeChange(workType, industry, !isActiveWorkType),
          };
        })}
      />
    );
  });

  if (!industries.length) return null;

  return (
    <TreeSection>
      <TreeLabel>{t('job.list.filters.workType.title')}</TreeLabel>
      {workTypeBranches}
    </TreeSection>
  );
};
