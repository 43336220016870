import gql from 'graphql-tag';

import {
  BOOST_CREDITS_PRODUCT_DETAILS as BOOST_CREDITS_PRODUCT_DETAILSPayload,
  BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails,
} from '../../generated/generated';

export type BoostCreditsProductDetailsPayload =
  BOOST_CREDITS_PRODUCT_DETAILSPayload;
export type BoostCreditsProductDetails =
  BOOST_CREDITS_PRODUCT_DETAILS_boostCreditsProductDetails;

export const BOOST_CREDITS_PRODUCT_DETAILS = gql`
  query BOOST_CREDITS_PRODUCT_DETAILS {
    boostCreditsProductDetails {
      id
      price
      slug
      clips
      discount {
        discountPercent
        priceAfterDiscount
      }
    }
  }
`;
