import { FC, ReactElement } from 'react';

import FullScreenLoader from '@components/elements/FullScreenLoader/FullScreenLoader';
import { useUserBusinessId } from '@contexts/UserContext';
import { useLogout } from '@hooks/useLogout';
import {
  CompanyStateProvider as SharedCompanyStateProvider,
  InvalidCompanyType,
} from '@internals/business-shared/src/contexts/CompanyStateContext';
import log from '@internals/business-shared/src/utils/devLog';
import { bugsnagClient } from '@utils/initBugsnag';

export const CompanyStateProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const businessId = useUserBusinessId();
  const logout = useLogout();

  const handleDeactivatedCompanies = (status: InvalidCompanyType) => {
    const errorMessage = `User from ${status} company with id: ${businessId} logged to the application. Logging out user.`;
    log.error(errorMessage);
    bugsnagClient.notify(errorMessage, (event) => {
      event.addMetadata('company', { status });
    });
    logout();
  };

  const handleMissingCompanyStateData = () => {
    const errorMessage = 'Failed to get company state data.';
    log.error(errorMessage);
    bugsnagClient.notify(errorMessage);
  };

  return (
    <SharedCompanyStateProvider
      businessId={businessId}
      handleInvalidCompany={handleDeactivatedCompanies}
      handleMissingData={handleMissingCompanyStateData}
      loaderComponent={<FullScreenLoader />}
    >
      {children}
    </SharedCompanyStateProvider>
  );
};
