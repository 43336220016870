import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT from '../../fragments/SimpleAgreementContractBasicFragment';
import {
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID,
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId,
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_IDVariables,
} from '../../generated/generated';

export type SimpleAgreementContractByJobIdPayload =
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID;
export type SimpleAgreementContractByJobIdVariables =
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_IDVariables;
export type SimpleAgreementContractByJobId =
  SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_simpleAgreementContractByJobId;

export const SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_QUERY = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_BASIC_FRAGMENT}

  query SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID($jobId: ID!, $companyId: ID) {
    simpleAgreementContractByJobId(jobId: $jobId, companyId: $companyId) {
      ...SimpleAgreementContractBasicFragment
    }
  }
`;
