import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import {
  isJobToEvaluateListQueryJobListPayload,
  JOB_TO_EVALUATE_LIST_QUERY,
  JobToEvaluateListConnection,
  JobToEvaluateListQueryPayload,
  JobToEvaluateListQueryVariables,
} from '@internals/business-shared/src/utils/query/JobToEvaluateList/JobToEvaluateListQuery';

interface JobToEvaluateListQueryResult extends QueryResult {
  data: JobToEvaluateListConnection | null;
}

export const WON_LIST_ID = JobListId.Won;
export const PAGE_COUNT = 5;

export const useJobToEvaluateListQuery = (
  queryOptions?: QueryHookOptions<JobToEvaluateListQueryPayload>
): JobToEvaluateListQueryResult => {
  const { variables, ...options } = queryOptions || {};
  const { data, ...rest } = useQuery<
    JobToEvaluateListQueryPayload,
    JobToEvaluateListQueryVariables
  >(JOB_TO_EVALUATE_LIST_QUERY, {
    variables: {
      listId: WON_LIST_ID,
      count: PAGE_COUNT,
      ...variables,
    },
    ...options,
  });
  const jobListConnection =
    data && isJobToEvaluateListQueryJobListPayload(data.jobList)
      ? data.jobList.jobConnection
      : null;
  return {
    data: jobListConnection,
    ...rest,
  };
};
