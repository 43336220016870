import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  color: ${(props): string => props.theme.colors.primary.base};

  svg {
    width: 20px;
    height: 20px;
    fill: ${(props): string => props.theme.colors.primary.base};
  }
`;

export default StyledLink;
