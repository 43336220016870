import { FC, useState } from 'react';

import { useMutation } from '@apollo/client';
import ErrorAlert from '@components/base/ErrorAlert';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useFoldersContext } from '@contexts/JobFoldersContext';
import { useUserBusinessId } from '@contexts/UserContext';
import {
  addJobToJobFolderOptimisticResponse,
  addJobToJobFolderUpdate,
} from '@internals/business-shared/src/cache/updates/addJobToJobFolder';
import { deleteJobFolderUpdate } from '@internals/business-shared/src/cache/updates/deleteJobFolder';
import {
  removeJobFromJobFolderOptimisticResponse,
  removeJobFromJobFolderUpdate,
} from '@internals/business-shared/src/cache/updates/removeJobFromJobFolder';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { CreateJobFolderResponseError } from '@internals/business-shared/src/utils/errors/CreateJobFolderResponseError';
import { EditJobFolderResponseError } from '@internals/business-shared/src/utils/errors/EditJobFolderResponseError';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node_folders as JobFolder } from '@internals/business-shared/src/utils/generated/generated';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import {
  ADD_JOB_TO_JOB_FOLDER,
  AddJobToJobFolderMutationPayload,
  AddJobToJobFolderMutationVariables,
} from '@internals/business-shared/src/utils/mutation/AddJobToJobFolder/AddJobToJobFolderMutation';
import {
  CREATE_JOB_FOLDER,
  CreateJobFolderMutationPayload,
  CreateJobFolderMutationVariables,
  isCreateJobFolderMutationSuccessResponse,
} from '@internals/business-shared/src/utils/mutation/CreateJobFolder/CreateJobFolderMutation';
import {
  DELETE_JOB_FOLDER,
  DeleteJobFolderMutationPayload,
  DeleteJobFolderMutationVariables,
} from '@internals/business-shared/src/utils/mutation/DeleteJobFolder/DeleteJobFolderMutation';
import {
  EDIT_JOB_FOLDER,
  EditJobFolderMutationPayload,
  EditJobFolderMutationVariables,
  isEditJobFolderMutationSuccessResponse,
} from '@internals/business-shared/src/utils/mutation/EditJobFolder/EditJobFolderMutation';
import {
  REMOVE_JOB_FROM_JOB_FOLDER,
  RemoveJobFromJobFolderMutationPayload,
  RemoveJobFromJobFolderMutationVariables,
} from '@internals/business-shared/src/utils/mutation/RemoveJobFromJobFolder/RemoveJobFromJobFolderMutation';
import { JOB_FOLDERS_QUERY } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import { Div, Heading, Button, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import FolderCustomization from './FolderCustomization';
import FolderDropdownItem from './FolderDropdownItem';
// import FoldersNavigationButton from './styled/FoldersNavigationButton'

export type FolderValues = Pick<JobFolder, 'id' | 'title' | 'color'>;

interface JobFoldersProps {
  job: JobListOrJobQueryItem;
  favouriteAction: () => void;
  onClose: () => void;
}

const JobFolders: FC<JobFoldersProps> = ({ job, favouriteAction, onClose }) => {
  const { t } = useTranslation();
  const businessId = useUserBusinessId();
  const themeContext = useTheme();
  const foldersContext = useFoldersContext();

  const folders = foldersContext?.folderList;
  const activeFolders = job.folders;

  const [editFolder, setEditFolder] = useState<JobFolder | FolderValues | null>(
    null
  );
  const [editFolderErrors, setEditFolderErrors] = useState<GQLErrorState>({});
  const [newFolder, setNewFolder] = useState<FolderValues | null>(null);
  const [newFolderErrors, setNewFolderErrors] = useState<GQLErrorState>({});
  const isFolderActive = (id: JobFolder['id']) =>
    !!(
      activeFolders &&
      activeFolders.find((activeFolder) => activeFolder.id === id)
    );

  const [addJobToJobFolder] = useMutation<
    AddJobToJobFolderMutationPayload,
    AddJobToJobFolderMutationVariables
  >(ADD_JOB_TO_JOB_FOLDER);
  const [removeJobFromJobFolder] = useMutation<
    RemoveJobFromJobFolderMutationPayload,
    RemoveJobFromJobFolderMutationVariables
  >(REMOVE_JOB_FROM_JOB_FOLDER);

  const onFoldersClose = () => {
    onClose();
    setNewFolder(null);
    setEditFolder(null);
  };
  const onFolderEditClose = () => setEditFolder(null);
  const onAddNewFolderClose = () => {
    setNewFolder(null);
    setNewFolderErrors({});
  };

  const [createJobFolder, { loading: createJobFolderLoading }] = useMutation<
    CreateJobFolderMutationPayload,
    CreateJobFolderMutationVariables
  >(CREATE_JOB_FOLDER, {
    refetchQueries: [JOB_FOLDERS_QUERY],
    awaitRefetchQueries: true,
    onCompleted: (data: CreateJobFolderMutationPayload) => {
      if (isCreateJobFolderMutationSuccessResponse(data.createJobFolder)) {
        onAddNewFolderClose();
      } else {
        const createJobFolderResponseError = new CreateJobFolderResponseError(
          'CreateJobFolderPayload'
        );
        createJobFolderResponseError.hasResponseError(
          data.createJobFolder,
          setNewFolderErrors
        );
      }
    },
  });
  const [editJobFolder, { loading: editJobFolderLoading }] = useMutation<
    EditJobFolderMutationPayload,
    EditJobFolderMutationVariables
  >(EDIT_JOB_FOLDER, {
    onCompleted: (data: EditJobFolderMutationPayload) => {
      if (isEditJobFolderMutationSuccessResponse(data.editJobFolder)) {
        onFolderEditClose();
      } else {
        const editJobFolderResponseError = new EditJobFolderResponseError(
          'EditJobFolderPayload'
        );
        editJobFolderResponseError.hasResponseError(
          data.editJobFolder,
          setEditFolderErrors
        );
      }
    },
  });
  const [deleteJobFolder, { loading: deleteJobFolderLoading }] = useMutation<
    DeleteJobFolderMutationPayload,
    DeleteJobFolderMutationVariables
  >(DELETE_JOB_FOLDER, {
    refetchQueries: [JOB_FOLDERS_QUERY],
    awaitRefetchQueries: true,
    onCompleted: () => onFolderEditClose(),
  });

  const onFolderClick = (id: string) => {
    if (folders) {
      const clickedFolderIndex = folders.findIndex(
        (folder: JobFolder) => folder.id === id
      );
      if (clickedFolderIndex !== -1) {
        if (isFolderActive(folders[clickedFolderIndex].id)) {
          removeJobFromJobFolder({
            variables: {
              jobId: job.id,
              folderId: id,
            },
            optimisticResponse: removeJobFromJobFolderOptimisticResponse,
            update: removeJobFromJobFolderUpdate(job, id),
          });
        } else {
          addJobToJobFolder({
            variables: {
              jobId: job.id,
              folderId: id,
            },
            optimisticResponse: addJobToJobFolderOptimisticResponse,
            update: addJobToJobFolderUpdate(job, folders[clickedFolderIndex]),
          });
        }
      }
    }
  };

  const onFolderCreate = (folder: JobFolder | FolderValues) =>
    createJobFolder({
      variables: {
        businessId,
        title: folder.title.trim(),
        color: folder.color,
        description: '',
      },
    });

  const onFolderUpdate = (folder: JobFolder | FolderValues) =>
    editJobFolder({
      variables: {
        folderId: folder.id,
        title: folder.title.trim(),
        color: folder.color,
      },
    });

  const onFolderDelete = (folder: JobFolder | FolderValues) =>
    deleteJobFolder({
      variables: {
        folderId: folder.id,
      },
      update: deleteJobFolderUpdate(folder.id),
    });

  return (
    <Div>
      {!!(!newFolder && !editFolder) && (
        <>
          <Div
            p={5}
            pb={4}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Heading.h4 mb={0}>{t('job.action.selectFolder')}</Heading.h4>
            {/* FIXME:  Temporary not in use - need to fix Fireball Dropdown issue */}
            {/* <FoldersNavigationButton onClick={onFoldersClose}> */}
            {/*  <FireIcon iconName={IconPickerName.Close} variant="flat" width="16px" height="16px" /> */}
            {/* </FoldersNavigationButton> */}
          </Div>
          <FolderDropdownItem
            key="favourite"
            id="favourite"
            text={t('general.label.favourite')}
            color="orange.base"
            isActive={job.jobBusinessRelationState.isFavourite ?? false}
            onClick={favouriteAction}
          />
          {folders
            ?.filter((f: JobFolder) => !!f)
            .map((f: JobFolder) => (
              <FolderDropdownItem
                key={`job-${job.id}-folder-${f.id}`}
                id={`job-${job.id}-folder-${f.id}`}
                text={f.title}
                color={f.color}
                isActive={isFolderActive(f.id)}
                onClick={() => onFolderClick(f.id)}
                onEditClick={() => setEditFolder(f)}
              />
            ))}
          <Button
            variant="linkPrimary"
            p={0}
            m={5}
            onClick={() => setNewFolder({ id: '', title: '', color: '' })}
          >
            <FireIcon
              iconName={IconPickerName.Plus}
              variant="flat"
              fill={themeContext.colors.primary.base}
              width="12px"
              height="12px"
            />
            <Text.p mb={0} ml={2} fontWeight={themeContext.fontWeights.bold}>
              {t('job.action.createFolder')}
            </Text.p>
          </Button>
        </>
      )}
      {!!newFolder && (
        <>
          <FolderCustomization
            heading={t('job.action.createFolder')}
            onBackButtonClick={onAddNewFolderClose}
            onClose={onFoldersClose}
            folderData={newFolder}
            setFolderData={setNewFolder}
            confirmButton={{
              text: t('general.label.save'),
              loading: createJobFolderLoading,
              onClick: (folder) => onFolderCreate(folder),
            }}
            rejectButton={{
              text: t('general.label.cancel'),
              onClick: onAddNewFolderClose,
            }}
          />
          <ErrorAlert errors={newFolderErrors} />
        </>
      )}
      {!!editFolder && (
        <>
          <FolderCustomization
            heading={t('job.action.editFolder')}
            onBackButtonClick={onFolderEditClose}
            onClose={onFoldersClose}
            folderData={editFolder}
            setFolderData={setEditFolder}
            confirmButton={{
              text: t('general.label.save'),
              loading: editJobFolderLoading,
              onClick: (folder) => onFolderUpdate(folder),
            }}
            rejectButton={{
              text: t('general.label.delete'),
              loading: deleteJobFolderLoading,
              onClick: (folder) => onFolderDelete(folder),
              variant: 'danger',
            }}
          />
          <ErrorAlert errors={editFolderErrors} />
        </>
      )}
    </Div>
  );
};

export default JobFolders;
