import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import CountText from './CountText';

const LinkWrapper = styled(NavLink)`
  display: flex;
  flex: 0 1 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${(props): string => props.theme.colors.black.black8};
  text-decoration: none;
  font-size: ${(props): string => props.theme.fontSizes[2]};

  &:hover {
    background-color: ${(props): string => props.theme.colors.black.black2};
    color: ${(props): string => props.theme.colors.black.black9};
    text-decoration: none;
  }

  &.is-active {
    background-color: ${(props): string => props.theme.colors.primary.lightest};
    color: ${(props): string => props.theme.colors.black.black9};
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &:hover,
  &:focus {
    ${CountText} {
      display: block;
    }
  }
`;
export default LinkWrapper;
