import { CreateBoligmappaAccountFormShape } from '@internals/business-shared/src/types/FormShape/CreateBoligmappaAccount';
import { FormikHelpers, FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

type UseRegisterBoligmappaFormikType = (
  initialValues: CreateBoligmappaAccountFormShape,
  onSubmit: (
    values: CreateBoligmappaAccountFormShape,
    helpers: FormikHelpers<CreateBoligmappaAccountFormShape>
  ) => Promise<void>
) => FormikProps<CreateBoligmappaAccountFormShape>;

export const UseRegisterBoligmappaFormik: UseRegisterBoligmappaFormikType = (
  initialValues,
  onSubmit
) => {
  const { t } = useTranslation();
  const minPasswordLength = 10;
  const validationSchema = Yup.object({
    orgNumber: Yup.string()
      .required(t('general.form.validation.required'))
      .matches(/^([0-9]{4}:)?([0-9]{9})$/, {
        message: t('general.form.validation.orgNumber.invalid'),
      }),
    numberOfEmployees: Yup.string()
      .required(t('general.form.validation.required'))
      .matches(/^[1-9][0-9]*$/, {
        message: t('general.form.validation.numberOfEmployees.invalid'),
      }),
    email: Yup.string().email().required(t('general.form.validation.required')),
    companyName: Yup.string().required(t('general.form.validation.required')),
    firstName: Yup.string().required(t('general.form.validation.required')),
    lastName: Yup.string().required(t('general.form.validation.required')),
    phone: Yup.string().required(t('general.form.validation.required')),
    professionType: Yup.string().required(
      t('general.form.validation.required')
    ),
    username: Yup.string().required(t('general.form.validation.required')),
    password: Yup.string()
      .min(
        minPasswordLength,
        t('general.form.validation.password.minLength', {
          value: minPasswordLength,
        })
      )
      .matches(/[0-9]/, t('general.form.validation.password.mustContainNumber'))
      .matches(/[a-z]/, t('general.form.validation.password.mustContainLower'))
      .matches(/[A-Z]/, t('general.form.validation.password.mustContainUpper'))
      .matches(
        /[^\w]/,
        t('general.form.validation.password.mustContainSpecial')
      )
      .required(t('general.form.validation.required')),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref('password'), null],
        t('general.form.validation.password.mustMatch')
      )
      .required(t('general.form.validation.required')),
  });

  const formik: FormikProps<CreateBoligmappaAccountFormShape> = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return formik;
};
