import GQLOpeningHours2OpeningHours from './GQLOpeningHours2OpeningHours';
import {
  OpeningHours,
  OpeningHoursAction,
  OpeningHoursActions,
} from './OpeningHours.interface';
import { resetOpeningHours } from './OpeningHoursHelper';

const MONDAY = 0;
const WEEKDAYS = [0, 1, 2, 3, 4];
const WEEKEND = [5, 6];

// todo: this entire file should be rewritten with better type safety
export const openingHoursReducer = (
  state: OpeningHours[],
  action: OpeningHoursAction
): OpeningHours[] => {
  switch (action.type) {
    case OpeningHoursActions.SAME_OPENING_HOURS_FALSE:
      return state.map(
        (openingHours): OpeningHours => ({
          ...openingHours,
          visible: true,
          dayLabel: action.payload.translations[openingHours.dayNumber],
        })
      );
    case OpeningHoursActions.SAME_OPENING_HOURS_TRUE:
      return state.map((openingHours): OpeningHours => {
        const isWeekend = WEEKEND.includes(openingHours.dayNumber);
        const isMondayOrWeekend =
          isWeekend || MONDAY === openingHours.dayNumber;

        return {
          ...openingHours,
          from: isWeekend ? openingHours.from : state[MONDAY].from,
          to: isWeekend ? openingHours.to : state[MONDAY].to,
          visible: isMondayOrWeekend,
          dayLabel:
            openingHours.dayNumber === MONDAY
              ? action.payload.translations[
                  action.payload.translations.length - 1
                ]
              : action.payload.translations[openingHours.dayNumber],
        };
      });
    case OpeningHoursActions.CLOSED_ON_WEEKENDS_FALSE:
      return state.map(
        (openingHours): OpeningHours => ({ ...openingHours, muted: false })
      );
    case OpeningHoursActions.CLOSED_ON_WEEKENDS_TRUE:
      return state.map((openingHours): OpeningHours => {
        const isWeekEndDay = WEEKEND.includes(openingHours.dayNumber);
        if (isWeekEndDay) {
          return {
            ...openingHours,
            from: '',
            to: '',
            muted: true,
          };
        }
        return openingHours;
      });
    case OpeningHoursActions.UPDATE_HOURS:
      return action.payload?.sameOpeningHours === true
        ? state.map((openingHours) => {
            const isMondayInput = MONDAY === action.payload.hours.dayNumber;
            const isWeekDay = WEEKDAYS.includes(openingHours.dayNumber);

            if (isWeekDay && isMondayInput) {
              return {
                ...openingHours,
                from: action.payload.hours.from, // it can be only monday
                to: action.payload.hours.to, // it can be only monday
              };
            }
            return openingHours.dayNumber === action.payload.hours.dayNumber
              ? action.payload.hours
              : openingHours;
          })
        : state.map((openingHours) =>
            openingHours.dayNumber === action.payload.hours.dayNumber
              ? action.payload.hours
              : openingHours
          );

    case OpeningHoursActions.RESET:
      return resetOpeningHours({
        DAYS_OF_THE_WEEK: action.payload.translations,
      });

    case OpeningHoursActions.SET_ALL:
      return GQLOpeningHours2OpeningHours(
        action.payload.all,
        action.payload.translations
      );

    default:
      throw new Error();
  }
};

export default openingHoursReducer;
