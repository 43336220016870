import { useState, useEffect } from 'react';

const useDebounce = (value: string, delay: number): string => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useDebouncedInput = (
  delay: number,
  initialValue = ''
): {
  inputValue: string;
  debouncedInputValue: string;
  setInputValue: (v: string) => void;
} => {
  const [inputValue, setInputValue] = useState(initialValue);
  const debouncedInputValue = useDebounce(inputValue, delay);

  return { inputValue, debouncedInputValue, setInputValue };
};

export default useDebounce;
