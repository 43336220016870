import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  StyledProductVerificationContainer,
  StyledProductVerificationText,
  StyledProductVerificationTitle,
} from '@components/layout/Products/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface ProductVerificationFailedProps {
  reason: string;
  onClose: VoidFunction;
}

export const ProductVerificationFailed: FC<ProductVerificationFailedProps> = ({
  reason,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <StyledProductVerificationContainer>
      <Div mb={6}>
        <FireIcon
          iconName={IconPickerName.CancelCircle}
          width={64}
          height={64}
          fill="red.base"
        />
      </Div>
      <StyledProductVerificationTitle>
        {t('product.verification.failed.title')}
      </StyledProductVerificationTitle>
      <StyledProductVerificationText>{reason}</StyledProductVerificationText>
      <Div width="100%" my={6}>
        {/* todo: Implement handler */}
        <Button fullWidth>{t('general.action.contactUs')}</Button>
        <Button fullWidth variant="linkSecondary" onClick={onClose}>
          {t('general.label.close')}
        </Button>
      </Div>
    </StyledProductVerificationContainer>
  );
};
