import { Row } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const FormRow = styled(Row)`
  margin-bottom: ${({ theme }) => `${theme.space[3]}px`};

  @media (min-width: ${({ theme }): string => theme.breakpoints[4]}) {
    margin-bottom: ${({ theme }) => `${theme.space[7]}px`};
  }
`;
