import { SEND_MESSAGE_sendMessage_SendMessagePayload } from '../generated/generated';
import { SendMessageMutationPayload } from '../mutation/SendMessage/SendMessageMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class SendMessageResponseError extends GQLResponseErrorBase<
  SendMessageMutationPayload['sendMessage'],
  SEND_MESSAGE_sendMessage_SendMessagePayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      // case 'SendMessageInputError':
      //   break
      // case 'AccessDeniedError':
      //   break

      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
