import { FC } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import {
  Button,
  Column,
  Container,
  Heading,
  Row,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { Card } from './Card';

interface ConfirmStateProps {
  loading: boolean;
  handleCheckQualify: () => Promise<void>;
  onClose: VoidFunction;
}

export const ConfirmState: FC<ConfirmStateProps> = ({
  loading,
  handleCheckQualify,
  onClose,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const paragraphTextItems = [
    t('job.modal.mittanbudXL.confirm.text.paragraph1'),
    t('job.modal.mittanbudXL.confirm.text.paragraph2'),
    t('job.modal.mittanbudXL.confirm.text.paragraph3'),
    t('job.modal.mittanbudXL.confirm.text.paragraph4'),
  ];

  return (
    <Container maxWidth={isMobile ? '350px' : '700px'} pb={9}>
      <Row flexDirection="column" alignItems="center" mb={isMobile ? 4 : 7}>
        <Heading.h2 color="black.black0" mb={3}>
          {t('job.modal.mittanbudXL.confirm.title')}
        </Heading.h2>
        <Text.p color="black.black0" fontSize={1}>
          {t('job.modal.mittanbudXL.confirm.description')}
        </Text.p>
      </Row>
      <Row flexDirection={isMobile ? 'column' : 'row'}>
        <Column
          alignSelf="flex-start"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width={isMobile ? 1 : 1 / 2}
        >
          <Card />
        </Column>
        <Column width={isMobile ? 1 : 1 / 2}>
          {paragraphTextItems.map((text) => (
            <Text.p key={text} color="black.black0" mb={5}>
              {text}
            </Text.p>
          ))}
          <Button
            onClick={handleCheckQualify}
            isLoading={loading}
            mt={8}
            fullWidth
          >
            {t('job.modal.mittanbudXL.confirm.action.checkIfQualifies')}
          </Button>
          <Button onClick={onClose} variant="linkPrimary" fullWidth>
            <Text.span color="black.black0">
              {t('job.modal.mittanbudXL.confirm.action.notInterested')}
            </Text.span>
          </Button>
        </Column>
      </Row>
    </Container>
  );
};
