import { FC, MouseEvent, useLayoutEffect } from 'react';

import { Logo } from '@components/base/Logo';
import NavLink from '@components/base/NavLink';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { useBadgeNumbersQuery } from '@hooks/useBadgeNumbersQuery';
import { ANObject } from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import NavigationExcludedPaths from '@utils/constants/NavigationExcludedPaths';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation, matchPath } from 'react-router-dom';

import HeaderSettingsLink from './HeaderSettingsLink';
import {
  Header,
  HeaderContainer,
  HeaderRow,
  LogoContainer,
  MenuList,
  MenuNav,
} from './styled';

const HeaderView: FC = () => {
  const { t } = useTranslation();
  const { loading, data } = useBadgeNumbersQuery();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isJobListPathname =
    pathname === Paths.JobsList || pathname === Paths.Homepage;
  const navigateAndClearFilterState = (
    e: MouseEvent<HTMLAnchorElement>,
    path: string
  ) => {
    e.preventDefault();
    navigate(path, { state: { resetActiveFilter: true } });
  };

  const inExcludedPaths = NavigationExcludedPaths.some((path) =>
    matchPath(path, pathname)
  );
  const isMobile = useIsMobile();

  const isNavigationHidden = inExcludedPaths && isMobile;

  // taken from https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition
  // more info here https://reactrouter.com/web/guides/scroll-restoration
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Header as="header" bg="blueNavy.base">
      <HeaderContainer
        width={1}
        maxWidth={1300}
        pl={[0, null, null, null, null, null, 4]}
        mx={[0, null, null, null, null, null, 4]}
        justifyContent="center"
      >
        <HeaderRow
          width="100%"
          display="flex"
          justifyContent={[
            'center',
            null,
            null,
            null,
            null,
            null,
            'flex-start',
          ]}
        >
          <LogoContainer pl={[0, null, null, null, null, null, null, 1]}>
            <Link
              to={Paths.Homepage}
              data-testid="header-logo-link"
              onClick={(e) => navigateAndClearFilterState(e, Paths.Homepage)}
            >
              <Logo height="38" />
            </Link>
          </LogoContainer>
          {!isNavigationHidden && (
            <MenuNav>
              <MenuList>
                <li>
                  <NavLink
                    to={Paths.JobsList}
                    iconName={IconPickerName.NEW_JobListFlat}
                    analyticsObject={ANObject.Jobs}
                    data-testid="header-link-jobs"
                    activeOverwrite={isJobListPathname}
                    onClick={(e) =>
                      navigateAndClearFilterState(e, Paths.JobsList)
                    }
                  >
                    {t('job.list.title')}
                  </NavLink>
                </li>
                <li>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {loading ? (
                    <NavLink
                      to={Paths.Messages}
                      iconName={IconPickerName.NEW_ChatFlat}
                      data-testid="header-link-messages"
                      analyticsObject={ANObject.Messages}
                    >
                      {t('chat.list.title')}
                    </NavLink>
                  ) : data?.badgeNumbers?.threads ? (
                    <NavLink
                      to={Paths.Messages}
                      iconName={IconPickerName.NEW_ChatFlat}
                      badgeText={data.badgeNumbers.threads.toString()}
                      data-testid="header-link-messages"
                      analyticsObject={ANObject.Messages}
                    >
                      {t('chat.list.title')}
                    </NavLink>
                  ) : (
                    <NavLink
                      to={Paths.Messages}
                      iconName={IconPickerName.NEW_ChatFlat}
                      badgeText=""
                      data-testid="header-link-messages"
                      analyticsObject={ANObject.Messages}
                    >
                      {t('chat.list.title')}
                    </NavLink>
                  )}
                </li>
                <li>
                  <NavLink
                    to={Paths.Customer}
                    iconName={IconPickerName.NEW_CompanyInfoFlat}
                    analyticsObject={ANObject.Customers}
                    data-testid="header-link-customers"
                  >
                    {t('customer.list.title')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={Paths.Profile}
                    iconName={IconPickerName.NEW_ProfileFlat}
                    analyticsObject={ANObject.ProfilePage}
                    data-testid="header-link-profile"
                  >
                    {t('profile.title')}
                  </NavLink>
                </li>
                <li>
                  <HeaderSettingsLink
                    data-testid="header-link-settings"
                    to={Paths.Settings}
                    linkText={t('profile.company.title')}
                  />
                </li>
              </MenuList>
            </MenuNav>
          )}
        </HeaderRow>
      </HeaderContainer>
    </Header>
  );
};

export default HeaderView;
