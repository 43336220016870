import * as React from 'react';

import { useMutation, useQuery } from '@apollo/client';
import NotFound from '@components/elements/NotFound';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import {
  setReceiveNewslettersOptimisticResponse,
  setReceiveNewslettersUpdate,
} from '@internals/business-shared/src/cache/updates/setReceiveNewsletters';
import {
  toggleNotificationDestinationOptimisticResponse,
  toggleNotificationDestinationUpdate,
} from '@internals/business-shared/src/cache/updates/toggleNotificationDestination';
import { useBusinessNotificationsQuery } from '@internals/business-shared/src/hooks/query/useBusinessNotificationsQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { NotificationDestinationType } from '@internals/business-shared/src/utils/generated/generated';
import {
  SET_RECEIVE_NEWSLETTERS,
  SetReceiveNewslettersMutationPayload,
  SetReceiveNewslettersMutationVariables,
} from '@internals/business-shared/src/utils/mutation/SetReceiveNewsletters/SetReceiveNewslettersMutation';
import {
  TOGGLE_NOTIFICATION_DESTINATION,
  ToggleNotificationDestinationPayload,
  ToggleNotificationDestinationVariables,
} from '@internals/business-shared/src/utils/mutation/ToggleNotificationDestination/ToggleNotificationDestination';
import { filterNotifications } from '@internals/business-shared/src/utils/NotificationUtils';
import { BusinessNotificationDestination } from '@internals/business-shared/src/utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';
import {
  GET_USER_SETTINGS_QUERY,
  GetUserSettingsQueryPayload,
} from '@internals/business-shared/src/utils/query/GetUserSettings/GetUserSettingsQuery';
import {
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY,
  NotificationDestinationsEnabledQuery,
} from '@internals/business-shared/src/utils/query/NotificationDestinationsEnabled/NotificationDestinationsEnabledQuery';
import Paths from '@router/paths';
import { Div, Text, Toggle } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';

import SettingsNotificationSwitchGroup from './SettingsNotificationSwitchGroup';

const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const { track } = useAnalytics();
  // BUSINESS NOTIFICATIONS START
  const { loading, data, refetch, error } = useBusinessNotificationsQuery();
  const {
    loading: loadingEnabled,
    data: dataEnabled,
    refetch: refetchEnabled,
  } = useQuery<NotificationDestinationsEnabledQuery>(
    NOTIFICATION_DESTINATIONS_ENABLED_QUERY,
    {
      fetchPolicy: 'cache-first',
    }
  );
  const [toggleNotificationDestination] = useMutation<
    ToggleNotificationDestinationPayload,
    ToggleNotificationDestinationVariables
  >(TOGGLE_NOTIFICATION_DESTINATION);

  const notificationsEnabled =
    dataEnabled?.notificationDestinationsEnabled ?? [];

  const emailNotifications = React.useMemo(() => {
    if (!data) return [];
    return filterNotifications(
      data.children ?? [],
      NotificationDestinationType.EMAIL
    );
  }, [data]);

  const pushNotifications = React.useMemo(() => {
    if (!data) return [];
    return filterNotifications(
      data.children ?? [],
      NotificationDestinationType.PUSH
    );
  }, [data]);

  const onNotificationChange = (
    variables: ToggleNotificationDestinationVariables['input'],
    destination: BusinessNotificationDestination
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    toggleNotificationDestination({
      variables: { input: variables },
      optimisticResponse: toggleNotificationDestinationOptimisticResponse(
        destination,
        !!variables.enabled,
        dataEnabled?.notificationDestinationsEnabled
      ),
      update: toggleNotificationDestinationUpdate,
    }).then(() => {
      track(
        ANEventSpace(ANEvent.Changed, ANObject.Notifications, ANPage.Settings),
        {
          toggle: destination?.type,
          state: !!variables?.enabled,
        }
      );
    });
  };
  // BUSINESS NOTIFICATIONS END

  // NEWSLETTER START
  const {
    loading: loadingSettings,
    data: dataSettings,
    refetch: refetchSettings,
  } = useQuery<GetUserSettingsQueryPayload>(GET_USER_SETTINGS_QUERY, {
    fetchPolicy: 'cache-first',
  });
  const [setReceiveNewsletters] = useMutation<
    SetReceiveNewslettersMutationPayload,
    SetReceiveNewslettersMutationVariables
  >(SET_RECEIVE_NEWSLETTERS);
  const newsletterEnabled = !!dataSettings?.getUserSettings.ReceiveNewsletters;
  const onReceiveNewslettersChange = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setReceiveNewsletters({
      variables: { value: !newsletterEnabled },
      update: setReceiveNewslettersUpdate(!newsletterEnabled),
      optimisticResponse: setReceiveNewslettersOptimisticResponse,
    }).then(() => {
      track(
        ANEventSpace(ANEvent.Changed, ANObject.Notifications, ANPage.Settings),
        {
          toggle: 'newsletter',
          state: !newsletterEnabled,
        }
      );
    });
  };
  // NEWSLETTER END

  usePageAnalytics(ANSpace(ANObject.Notifications, ANPage.Settings));

  const shouldDisplayLoading =
    (loading && !data) || loadingEnabled || loadingSettings;
  const shouldDisplayError = !shouldDisplayLoading && error;

  const refresh = () => {
    refetch();
    refetchEnabled();
    refetchSettings();
  };

  return (
    <SettingsContainer>
      {shouldDisplayLoading && <SettingsLoading />}
      {shouldDisplayError && (
        <NotFound
          title={t('settings.error.title')}
          text={t('settings.error.text')}
          link={{
            url: Paths.SettingsCustomerService,
            text: t('general.label.customerService'),
          }}
          button={{ text: t('general.error.tryAgain'), onClick: refresh }}
        />
      )}
      {!shouldDisplayLoading && data && dataEnabled && dataSettings && (
        <>
          <SettingsSection
            icon={IconPickerName.New}
            title={t('general.label.email')}
            testId="email"
          >
            <SettingsNotificationSwitchGroup
              notificationGroup={emailNotifications}
              notificationsEnabled={notificationsEnabled}
              notificationDestinationType={NotificationDestinationType.EMAIL}
              updateNotification={onNotificationChange}
            />
          </SettingsSection>
          <SettingsSection
            icon={IconPickerName.Phone}
            title={t('settings.notifications.push.title')}
            testId="push"
          >
            <Text.span
              mb={6}
              color="black.black8"
              lineHeight={1.5}
              data-testid="settings-notification-push-info"
            >
              {t('settings.notifications.push.info')}
            </Text.span>
            <SettingsNotificationSwitchGroup
              notificationGroup={pushNotifications}
              notificationsEnabled={notificationsEnabled}
              notificationDestinationType={NotificationDestinationType.PUSH}
              updateNotification={onNotificationChange}
            />
          </SettingsSection>
          <SettingsSection
            icon={IconPickerName.Newsletter}
            title={t('settings.notifications.newsletter.title')}
            testId="newsletter"
          >
            <Text.span mb={6} color="black.black7" lineHeight={1.5}>
              {t('settings.notifications.newsletter.info', { productName })}
            </Text.span>
            <Div display="flex" justifyContent="space-between">
              <Text.p mb={0}>
                {t('settings.notifications.newsletter.title')}
              </Text.p>
              <Toggle
                name="newsletter"
                checked={newsletterEnabled}
                onChange={onReceiveNewslettersChange}
                testId="newsletter"
              />
            </Div>
          </SettingsSection>
        </>
      )}
    </SettingsContainer>
  );
};

export default Notifications;
