import { FILE_UPLOAD_TOTAL_SIZE_LIMIT } from '@internals/business-shared/src/utils/constants/fileUpload';

const isFilesTotalSizeAccepted = (
  files: File[],
  sizeLimit = FILE_UPLOAD_TOTAL_SIZE_LIMIT
): boolean => {
  const totalSize: number = files.reduce((sum, file) => sum + file.size, 0);
  return totalSize <= sizeLimit;
};

export default isFilesTotalSizeAccepted;
