import { FC } from 'react';

import { VariantType } from '@internals/business-shared/src/utils/errors';
import { Alert } from '@schibsted-smb/fireball';

export interface ErrorType {
  msg: string;
  variant?: VariantType;
}

interface CustomErrorAlertProps {
  error: ErrorType | null;
  testId?: string;
  children?: never;
}

export const CustomErrorAlert: FC<CustomErrorAlertProps> = ({
  error,
  testId = 'error-alert',
}) => {
  if (!error) return null;
  return (
    <Alert
      variant={error.variant || 'danger'}
      isFullWidth
      isCentered
      my={4}
      mx={2}
      data-testid={testId}
    >
      {error.msg}
    </Alert>
  );
};
