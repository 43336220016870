import { TagVariants } from '@schibsted-smb/fireball/lib/components/elements/Tag/types';

export enum StatusTypes {
  Warning = 'warning',
  Success = 'success',
  Danger = 'danger',
  Muted = 'muted',
  Info = 'info',
}

const statusCheck = (status: StatusTypes): TagVariants => {
  switch (status) {
    case StatusTypes.Warning:
      return 'yellow';
    case StatusTypes.Success:
      return 'green';
    case StatusTypes.Danger:
      return 'red';
    case StatusTypes.Muted:
      return 'gray';
    default:
      return 'primary';
  }
};

export default statusCheck;
