import { FC, PropsWithChildren } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ReactComponent as AskForEvaluation } from '@internals/business-shared/src/assets/illustrations/askforevaluation.svg';
import { ReactComponent as AskToBeChosen } from '@internals/business-shared/src/assets/illustrations/asktobechosen.svg';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Heading, Button, Modal } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface AskModalProps {
  isAskModalOpen: boolean;
  isAskConfirmModalOpen: boolean;
  closeAskModal: () => void;
  openConfirmModal: () => void;
  closeConfirmModal: () => void;
  onAskButtonClick?: () => void;
  askLoading?: boolean;
  illustration?: 'askforevaluation' | 'asktobechosen';
  subtitle?: string;
  title: string;
  text: string;
  buttonText: string;
  confirmTitle?: string;
  confirmText: string;
  confirmButtonText: string;
}

const AskModal: FC<PropsWithChildren<AskModalProps>> = ({
  isAskModalOpen,
  isAskConfirmModalOpen,
  closeAskModal,
  openConfirmModal,
  closeConfirmModal,
  onAskButtonClick,
  askLoading,
  illustration,
  subtitle,
  title,
  text,
  buttonText,
  confirmTitle,
  confirmText,
  confirmButtonText,
}) => {
  const themeContext = useTheme();

  return (
    <>
      {isAskModalOpen && (
        <Modal
          isOpen
          isClosable
          onClose={closeAskModal}
          contentProps={{ p: 0, m: 0 }}
          size="custom"
          maxWidth="592px"
        >
          <Div
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            mx="90px"
            my={5}
          >
            {illustration === 'askforevaluation' && (
              <AskForEvaluation width="155px" />
            )}
            {illustration === 'asktobechosen' && (
              <AskToBeChosen width="155px" />
            )}
            <Text.p
              fontSize={1}
              mt={5}
              mb={0}
              fontWeight={themeContext.fontWeights.bold}
              color={themeContext.colors.yellow.base}
            >
              {subtitle}
            </Text.p>
            <Heading.h3 fontSize={6} mb={4} mt={6}>
              {title}
            </Heading.h3>
            <Text.p fontSize={3} textAlign="center" mb={8} color="black.black8">
              {text}
            </Text.p>
            <Button
              variant="primary"
              isLoading={askLoading}
              width="100%"
              height={55}
              onClick={
                onAskButtonClick ||
                (() => {
                  closeAskModal();
                  openConfirmModal();
                })
              }
              data-testid="ask-modal-ask-btn"
            >
              {buttonText}
            </Button>
          </Div>
        </Modal>
      )}
      {isAskConfirmModalOpen && (
        <Modal
          isOpen
          isClosable
          onClose={closeConfirmModal}
          isSmaller
          size="custom"
          contentProps={{ py: '45px', px: '90px' }}
          maxWidth="592px"
        >
          <Div
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FireIcon
              iconName={IconPickerName.CheckmarkCircled}
              variant="flat"
              fill={themeContext.colors.green.base}
              width="90px"
              height="90px"
            />
            <Heading.h3 fontSize={6} mb={4} mt={7}>
              {confirmTitle}
            </Heading.h3>
            <Text.p fontSize="16px" mb={7} textAlign="center">
              {confirmText}
            </Text.p>
            <Button
              variant="primary"
              height="55px"
              width="100%"
              onClick={closeConfirmModal}
              data-testid="ask-modal-confirm-btn"
            >
              {confirmButtonText}
            </Button>
          </Div>
        </Modal>
      )}
    </>
  );
};

export default AskModal;
