import gql from 'graphql-tag';

import {
  ADD_JOB_TO_JOB_FOLDER as ADD_JOB_TO_JOB_FOLDER_Result,
  ADD_JOB_TO_JOB_FOLDERVariables,
} from '../../generated/generated';

export type AddJobToJobFolderMutationVariables = ADD_JOB_TO_JOB_FOLDERVariables;
export type AddJobToJobFolderMutationPayload = ADD_JOB_TO_JOB_FOLDER_Result;

export const ADD_JOB_TO_JOB_FOLDER = gql`
  mutation ADD_JOB_TO_JOB_FOLDER($folderId: ID!, $jobId: ID!) {
    addJobToJobFolder(input: { folderId: $folderId, jobId: $jobId })
  }
`;
