import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BADGE_NUMBERS_QUERY,
  BadgeNumbersQueryPayload,
} from '../../utils/query/BadgeNumbers/BadgeNumbersQuery';

export const useBadgeNumbersQuery = (
  options?: QueryHookOptions<BadgeNumbersQueryPayload>
): QueryResult<BadgeNumbersQueryPayload> => {
  const queryResult = useQuery<BadgeNumbersQueryPayload>(BADGE_NUMBERS_QUERY, {
    ...options,
  });

  return queryResult;
};
