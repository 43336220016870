import gql from 'graphql-tag';

import {
  EDIT_JOB_FOLDER as EDIT_JOB_FOLDER_Result,
  EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload,
  EDIT_JOB_FOLDERVariables,
} from '../../generated/generated';

export type EditJobFolderMutationVariables = EDIT_JOB_FOLDERVariables;
export type EditJobFolderMutationPayload = EDIT_JOB_FOLDER_Result;

export const isEditJobFolderMutationSuccessResponse = (
  editJobFolder?: EDIT_JOB_FOLDER_Result['editJobFolder']
): editJobFolder is EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload =>
  !!editJobFolder && editJobFolder.__typename === 'EditJobFolderPayload';

export const EDIT_JOB_FOLDER = gql`
  mutation EDIT_JOB_FOLDER(
    $folderId: ID!
    $title: String
    $description: String
    $score: Int
    $color: HexColorCode
  ) {
    editJobFolder(
      input: {
        folderId: $folderId
        title: $title
        description: $description
        score: $score
        color: $color
      }
    ) {
      ... on EditJobFolderPayload {
        folder {
          id
          title
          color
        }
      }
      ... on EditJobFolderInputError {
        message

        titleError: title {
          message
        }
        descriptionError: description {
          message
        }
        scoreError: score {
          message
        }
        colorError: color {
          message
        }
      }
    }
  }
`;
