import gql from 'graphql-tag';

import ADDRESS_FRAGMENT from '../../fragments/AddressFragment';
import JOB_OWNER_FRAGMENT from '../../fragments/JobOwnerFragment';
import {
  JOB_OWNER_QUERY as JOB_OWNER_QUERY_Result,
  JOB_OWNER_QUERYVariables,
  JOB_OWNER_QUERY_job_Job,
} from '../../generated/generated';

export type JobOwnerQueryPayload = JOB_OWNER_QUERY_Result;
export type JobOwnerQueryVariables = JOB_OWNER_QUERYVariables;
export type JobOwner = JOB_OWNER_QUERY_job_Job;

export const isJobOwnerQuerySuccessResponse = (
  job?: JOB_OWNER_QUERY_Result['job']
): job is JOB_OWNER_QUERY_job_Job => !!(job && job.__typename === 'Job');

export const JOB_OWNER_QUERY = gql`
  ${JOB_OWNER_FRAGMENT}
  ${ADDRESS_FRAGMENT}

  query JOB_OWNER_QUERY($id: ID!) {
    job(id: $id) {
      ... on Job {
        id
        ...JobOwnerFragment
        address {
          ...AddressFragment
        }
        contactInfo {
          email
          phone
        }
      }
    }
  }
`;
