import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import { Div, Button, Heading, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const EMPTY_LIST_CONTAINER_MAX_WIDTH = '520px';

const EvaluationRequestNoJobs: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Div
      my={9}
      mx={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      maxWidth={EMPTY_LIST_CONTAINER_MAX_WIDTH}
    >
      <FireIcon iconName={IconPickerName.JobList} variant="gray" width="46px" />
      <Heading.h3 mt={6}>
        {t('evaluation.item.request.emptyList.title')}
      </Heading.h3>
      <Text.p mb={5} textAlign="center">
        {t('evaluation.item.request.emptyList.subtitle')}
      </Text.p>
      <Button onClick={() => navigate(Paths.JobsList)} variant="secondary">
        {t('evaluation.item.request.emptyList.action')}
      </Button>
    </Div>
  );
};

export default EvaluationRequestNoJobs;
