import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

type ColorVariant = 'primary' | 'green';

const Dot = styled(Div)<{ variant?: ColorVariant }>`
  margin: 6px 0 0;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme, variant }) => {
    switch (variant) {
      case 'green':
        return theme.colors.green.dark;
      case 'primary':
      default:
        return theme.colors.primary.base;
    }
  }};
`;

export default Dot;
