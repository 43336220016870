import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { SelfServiceSubscriptions } from '@internals/business-shared/src/utils/query/SelfServiceSubscriptions/SelfServiceSubscriptions';

import { usePackageCardConf } from '../../hooks/usePackageCardConf';
import { Background } from './Background';
import { BodyWrapper } from './BodyWrapper';
import { ChooseButton } from './ChooseButton';
import { Container } from './Container';
import { CostPerMonth } from './CostPerMonth';
import { CreditsPerMonth } from './CreditsPerMonth';
import { Header } from './Header';
import { HeaderWrapper } from './HeaderWrapper';
import { Image } from './Image';
import { MostPopularLabel } from './MostPopularLabel';
import { YourSubscriptionLabel } from './YourSubscriptionLabel';

type PackageCardProps = Pick<
  SelfServiceSubscriptions,
  'clips' | 'price' | 'slug'
>;

export const PackageCard: FC<PackageCardProps> = ({ clips, price, slug }) => {
  const {
    backgroundColor,
    description,
    imageSrc,
    imageWidth,
    mostPopular,
    title,
  } = usePackageCardConf(slug) ?? {};

  const isContactSubscription = useFlagEnabled(
    FeatureFlags.BizTestChooseSubscriptionModal
  );

  return (
    <Container mostPopular={mostPopular}>
      <HeaderWrapper backgroundColor={backgroundColor}>
        <Header description={description} title={title} />
        <Image src={imageSrc} width={imageWidth} />
        <Background />
        {mostPopular && <MostPopularLabel />}
      </HeaderWrapper>
      <BodyWrapper>
        {!isContactSubscription && (
          <CostPerMonth price={price} mostPopular={mostPopular} />
        )}
        <CreditsPerMonth clips={clips} />
        <ChooseButton slug={slug} />
        <YourSubscriptionLabel />
      </BodyWrapper>
    </Container>
  );
};
