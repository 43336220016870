import { useEffect, useState } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';

interface Viewport {
  width: number;
  height: number;
}
const getViewport = (): Viewport => ({
  width: window.innerWidth || 0,
  height: window.innerHeight || 0,
});

// can be used to get 100vw|vh value equivalent on mobile devices
const useMobileDeviceViewport = (): Viewport => {
  const [viewport, setViewport] = useState(getViewport);
  const isMobile = useIsMobile();

  useEffect(() => {
    const handleResize = () => setViewport(getViewport);
    if (isMobile) {
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
    return undefined;
  }, [isMobile]);

  return viewport;
};

export default useMobileDeviceViewport;
