import { SummaryToggleIconClassName } from '@components/base/Details/styled/StyledDetails';
import styled from 'styled-components';

export const StyledSummaryToggleIcon = styled.div.attrs({
  className: SummaryToggleIconClassName,
})`
  display: inline-block;
`;

export const StyledIconContainer = styled.div`
  display: inline-block;
  width: 16px;
`;

export const StyledSummaryContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  & > ${StyledSummaryToggleIcon} {
    margin-left: auto;
  }
`;

export const StyledSummaryLabel = styled.span`
  font-size: ${(props) => props.theme.fontSizes[2]};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  font-style: normal;
  line-height: 1.5em; /* 150% */
  margin: 0 ${(props) => props.theme.space[4]}px;
  text-wrap: wrap;
`;

export const StyledDescription = styled.p`
  font-size: ${(props) => props.theme.fontSizes[1]};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  margin: 0;
  line-height: 20px;
  padding: ${(props) => props.theme.space[4]}px
    ${(props) => props.theme.space[0]}px;
  text-align: left;
`;
