import { InMemoryCache } from '@apollo/client';
import {
  writeFiles,
  writeImages,
  writeEvaluation,
  writeLabels,
  writeMeta,
  writeJobBusinessRelationState,
} from '@internals/business-shared/src/cache/jobWriteQuery';
import {
  mergeConnections,
  readConnections,
  readInfiniteScrollConnections,
  readJobListPage,
  JOBS_LIST_DEFAULT_PAGE_COUNT,
  mergeInfiniteScrollConnections,
  MergeDirection,
  MergeOptions,
} from '@internals/business-shared/src/utils/cache';
import { BusinessEvaluationsQueryVariables } from '@internals/business-shared/src/utils/query/BusinessEvaluations/BusinessEvaluationsQuery';
import { JobsListQueryVariables } from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';

export const JOB_LIST_DEFAULT_COUNT = JOBS_LIST_DEFAULT_PAGE_COUNT;
export const JOB_LIST_FILTERS_VARIABLE_NAMES = [
  'sizes',
  'municipalityCode',
  'districtId',
  'folderId',
  'query',
  'worktypeIds',
  'worktypeSubsets',
  'countyId',
  'industryIds',
  'worktypeSubsetGroups',
];
export const CHAT_LIST_DEFAULT_FROM = 0;
export const CHAT_LIST_DEFAULT_COUNT = 20;
export const EVALUATION_LIST_DEFAULT_COUNT = 10;
export const CHAT_MESSAGE_SORT_DIRECTION: MergeDirection = 'ASC';

const cache = new InMemoryCache({
  typePolicies: {
    JobBusinessRelationState: {
      keyFields: ['jobId'],
    },
    // aggregations values should be cached together with the job list data
    Aggregations: {
      keyFields: false,
    },
    AggregationsValue: {
      keyFields: false,
    },
    Query: {
      fields: {
        jobList: {
          keyArgs: ['input', ['listId', ...JOB_LIST_FILTERS_VARIABLE_NAMES]],
          merge(existing, incoming, { variables }) {
            // jobList query is also used to get jobs total count, we don't want to overwrite and re-trigger jobs data load then
            if (existing && variables?.count === 0) {
              return existing;
            }
            const { page = 1, count = JOB_LIST_DEFAULT_COUNT } =
              variables as JobsListQueryVariables;
            return incoming
              ? {
                  ...incoming,
                  jobConnection: {
                    ...incoming.jobConnection,
                    ...mergeConnections(
                      existing?.jobConnection,
                      incoming.jobConnection,
                      page,
                      count
                    ),
                  },
                }
              : existing;
          },
          read(existing, { args }) {
            const {
              input: { count = JOB_LIST_DEFAULT_COUNT, page = 1 },
            } = args as { input: JobsListQueryVariables };
            return existing ? readJobListPage(existing, page, count) : existing;
          },
        },
        chatList: {
          keyArgs: ['filter', ['type', 'query']],
          merge(existing, incoming, { readField }) {
            return {
              ...incoming,
              chatConnection: {
                ...incoming.chatConnection,
                ...mergeInfiniteScrollConnections(
                  existing?.chatConnection,
                  incoming.chatConnection,
                  {
                    readField,
                    sortDirection: 'DESC',
                    sortField: 'latestUpdateTs',
                  }
                ),
              },
            };
          },
          read(existing) {
            if (!existing) return undefined;
            return {
              ...existing,
              chatConnection: {
                ...existing.chatConnection,
                ...readInfiniteScrollConnections(existing.chatConnection),
              },
            };
          },
        },
        jobFiles: {
          merge(_, incoming, { variables, cache: _cache, readField }) {
            writeFiles(_cache, readField, variables?.id, incoming);
            return incoming;
          },
        },
        jobImages: {
          merge(_, incoming, { cache: _cache, readField, variables }) {
            writeImages(_cache, readField, variables?.id, incoming);
            return incoming;
          },
        },
        jobEvaluation: {
          merge(_, incoming, { cache: _cache, readField, variables }) {
            writeEvaluation(_cache, readField, variables?.id, incoming);
            return incoming;
          },
        },
        jobLabels: {
          merge(_, incoming, { cache: _cache, variables }) {
            writeLabels(_cache, variables?.id, incoming);
            return incoming;
          },
        },
        jobMeta: {
          merge(_, incoming, { cache: _cache, readField, variables }) {
            writeMeta(_cache, readField, variables?.id, incoming);
            return incoming;
          },
        },
        jobBusinessRelationState: {
          merge(_, incoming, { cache: _cache, variables }) {
            const jobId = variables?.id || variables?.jobId;
            writeJobBusinessRelationState(_cache, jobId);
            return incoming;
          },
        },
        chatMessages: {
          keyArgs: ['input', ['chatId']],
          merge(existing, incoming, { readField }) {
            return {
              ...incoming,
              ...mergeInfiniteScrollConnections(existing, incoming, {
                readField,
                sortDirection: CHAT_MESSAGE_SORT_DIRECTION,
              }),
            };
          },
          read(existing, { variables, readField }) {
            if (!existing) return undefined;
            const options: MergeOptions | undefined = variables?.orderByTime
              ? {
                  sortDirection: variables.orderByTime,
                  readField,
                }
              : undefined;
            return {
              ...existing,
              ...readInfiniteScrollConnections(existing, options),
            };
          },
        },
        businessEvaluations: {
          keyArgs: false,
          merge(existing, incoming, { variables }) {
            const {
              input: {
                paging: { size = EVALUATION_LIST_DEFAULT_COUNT, page },
              },
            } = variables as BusinessEvaluationsQueryVariables;

            return {
              ...incoming,
              ...mergeConnections(existing, incoming, page, size),
            };
          },
          read(existing, { args }) {
            const {
              input: {
                paging: { size = EVALUATION_LIST_DEFAULT_COUNT, page },
              },
            } = args as BusinessEvaluationsQueryVariables;

            return {
              ...existing,
              ...readConnections(existing, page, size),
            };
          },
        },
      },
    },
    // return null for fields skipped when fetching job list
    Job: {
      fields: {
        latestMessage: {
          read(existing) {
            if (existing === undefined) return null;
            return existing;
          },
        },
        evaluation: {
          read(existing) {
            if (existing === undefined) return null;
            return existing;
          },
        },
        meta: {
          merge(existing, incoming) {
            if (existing && incoming) {
              return { ...existing, ...incoming };
            }
            return incoming;
          },
        },
      },
    },

    Chat: {
      fields: {
        isUnread: {
          read(existing) {
            // isUnread is not present in cache yet, can happen when opening the chat from an external link. Setting to true to trigger setChatAsRead mutation just in case
            if (typeof existing !== 'boolean') {
              return true;
            }
            return existing;
          },
        },
      },
    },
  },
});

export default cache;
