import gql from 'graphql-tag';

import {
  IDENTITY_QUERY as IDENTITY_QUERY_Result,
  IDENTITY_QUERY_business_Business,
  IDENTITY_QUERYVariables,
} from '../../generated/generated';

export type IdentityQueryPayload = IDENTITY_QUERY_Result;
export type IdentityQueryVariables = IDENTITY_QUERYVariables;

export const isIdentityQuerySuccessResponse = (
  business?: IDENTITY_QUERY_Result['business']
): business is IDENTITY_QUERY_business_Business =>
  !!(business && business.__typename === 'Business');

export const IDENTITY_QUERY = gql`
  query IDENTITY_QUERY($id: ID!) {
    businessAlbums(input: { businessId: $id }) {
      __typename
      id
      images {
        id
      }
    }
    businessEvaluationScore(businessId: $id) {
      average
      totalCount
    }
    businessSettings(businessId: $id) {
      id
      NumAnswers
      NumAnswersRemaining
      NumProfilePageVisits
      JobFolders
      ProfilePage
      BoostCredits
      BoostCreditsRemaining
    }
    businessStates(id: $id) {
      id
      hasProductExpired
      hasProducts
      hasSubscription
      isBlacklisted
      isDeactivated
      isNew
      isOrderUnpaid
      isSubscriptionExpired
      isVerified
      jobAccess
      products
    }
    businessWorkTypes(businessId: $id) {
      id
      workTypes {
        id
        title
        industries {
          id
          title
        }
      }
    }
    businessEmployees(businessId: $id) {
      id
    }
    business(id: $id) {
      ... on Business {
        id
        name
        profileDescription
        email
        phone
        profileHeaderImage
        logo {
          url
          id
        }
        products {
          basicPackage {
            isOwned
          }
          jobPackage {
            isOwned
            clips
          }
          proPackage {
            isOwned
          }
          advertising {
            isOwned
          }
          companySearch {
            isOwned
          }
        }
      }
      ... on BusinessInputError {
        message
      }
      ... on BusinessNotFoundError {
        message
      }
    }
  }
`;
