import gql from 'graphql-tag';

import {
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY as BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_Result,
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable,
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children,
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types,
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types_destinations,
} from '../../generated/generated';

export type BusinessNotificationsAvailableQueryPayload =
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_Result;
export type BusinessNotificationsAvailable =
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable;
export type BusinessNotification =
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children;
export type BusinessNotificationDestination =
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types_destinations;
export type BusinessNotificationType =
  BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY_businessNotificationsAvailable_children_types;

export const BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY = gql`
  query BUSINESS_NOTIFICATIONS_AVAILABLE_QUERY {
    businessNotificationsAvailable {
      children {
        id
        slug
        name
        types {
          id
          slug
          name
          destinations {
            id
            type
            isToggleable
          }
        }
      }
    }
  }
`;
