import { FC, useMemo } from 'react';

import {
  getJobBadgeInfo,
  JobBadgeColorVariant,
  JobBadgeStyles,
  JobBadgeVariant,
} from '@internals/business-shared/src/utils/jobBadge';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface JobBadgeProps {
  variant: JobBadgeVariant;
}

export const JobBadge: FC<JobBadgeProps> = ({ variant }) => {
  const theme = useTheme();
  const badgeInfo = getJobBadgeInfo(variant);
  const styles = useMemo(() => {
    const colors: Record<JobBadgeColorVariant, JobBadgeStyles> = {
      green: {
        backgroundColor: theme.colors.green.lightest,
        borderColor: theme.colors.green.light,
        color: theme.colors.green.darkest,
      },
    };

    return colors[badgeInfo.colorVariant];
  }, [variant, badgeInfo.colorVariant]);

  return (
    <Div
      backgroundColor={styles.backgroundColor}
      border={`1px solid ${styles.borderColor}`}
      p={2}
      borderRadius={2}
    >
      <Text.p color={styles.color} mb={0} fontSize="12px" fontWeight="medium">
        {badgeInfo.text}
      </Text.p>
    </Div>
  );
};
