import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface PackageContainerProps {
  isDiscount: boolean;
}

export const PackageContainer = styled(Div)<PackageContainerProps>`
  align-items: flex-end;
  display: flex;
  height: ${({ isDiscount }) => (isDiscount ? '270px' : '250px')};
  margin-bottom: 30px;
`;
