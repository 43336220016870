import { FC } from 'react';

import { ProductAddonCard } from '@components/layout/Products/ProductAddonCard';
import { getProductAddonsMock } from '@internals/business-shared/src/components/Products/productMocks';
import { getProductPackageText } from '@internals/business-shared/src/components/Products/productUtils';
import Paths from '@router/paths';
import { Button, Div } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { getAddonPath } from '@utils/url';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const ProductAddonList: FC = () => {
  const { name: productName } = getProduct();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const addonList = getProductAddonsMock();

  return (
    <>
      {addonList.map((addon) => {
        const { name, description } = getProductPackageText({
          productSlug: addon.slug,
          productName,
        });
        return (
          <Div key={addon.id} mb={3}>
            <ProductAddonCard
              title={name}
              subtitle={description}
              onClick={() => navigate(getAddonPath(addon.slug))}
              status={addon.status}
            />
          </Div>
        );
      })}
      <Div display="flex" justifyContent="center">
        <Button
          variant="linkPrimary"
          onClick={() => navigate(Paths.SettingsProductAddons)}
        >
          {t('products.addons.action.seeAll')}
        </Button>
      </Div>
    </>
  );
};
