import { FC, RefObject, useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import JobActionButtons from '@components/elements/JobActionButtons';
import { JobBadge } from '@components/elements/JobBadge';
import { JobLabelsV0 } from '@components/elements/JobLabels/JobLabelsV0';
import Dot from '@components/elements/JobListItem/styled/Dot';
import { JobDescription } from '@components/elements/JobListItem/styled/JobDescription';
import { JobTitle } from '@components/elements/JobListItem/styled/JobTitle';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { useIsJobFoldersFeatureEnabled } from '@contexts/UserContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import dateFromTimestamp from '@internals/business-shared/src/utils/dateFromTimestamp';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node } from '@internals/business-shared/src/utils/generated/generated';
import { JobBadgeVariant } from '@internals/business-shared/src/utils/jobBadge';
import { getJobLabelList } from '@internals/business-shared/src/utils/jobLabels';
import { JobsListQueryVariables } from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import selectEvenlyFromSets from '@internals/business-shared/src/utils/selectEvenlyFromArray';
import { Params } from '@router/paths';
import { Column, Container, Div, Row, Text } from '@schibsted-smb/fireball';
import i18n, { t } from '@utils/i18n';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import LatestMessage from './LatestMessage';
import {
  ActionButtonsContainer,
  ApprovalTime,
  JobContainer,
  JobLink,
} from './styled';

export interface JobListItemViewProps {
  job: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node;
  isLast: boolean;
  jobListId: JobsListQueryVariables['listId'];
  jobListContainerRef: RefObject<HTMLDivElement>;
  handleJobDelete: () => void;
  handleJobFavourite: () => void;
  handleOpenJob: () => void;
}

export const JobListItem: FC<JobListItemViewProps> = ({
  job,
  jobListId,
  isLast,
  jobListContainerRef,
  handleJobDelete,
  handleJobFavourite,
  handleOpenJob,
}) => {
  const themeContext = useTheme();
  const hasAttachments = job.images.length > 0 || job.files.length > 0;
  const routerLocation = useLocation();
  const params = new URLSearchParams(routerLocation.search);
  params.append(Params.Job, job.id.toString());
  const linkToJob = `?${params.toString()}`;
  const [actionButtonsVisible, showActionButtons, hideActionButtons] =
    useBoolean(false);
  const jobFoldersEnabled = useIsJobFoldersFeatureEnabled();
  const isSmartPhone = useIsMobile();
  const { jobBusinessRelationState, isOneOnOne } = job;

  const bizVerificationViewEnabled = useFlagEnabled(
    FeatureFlags.BizVerificationView
  );
  const isDirectRegistration = isOneOnOne;
  const isDirectJobListOpen = jobListId === JobListId.Direct;

  const jobLabels = useMemo(() => {
    return getJobLabelList({
      isVerifiedWithSecureId:
        bizVerificationViewEnabled && job.traits?.fromVerifiedUser === true,
      ...job.labels,
      evaluationRequested: jobBusinessRelationState.hasRequestedEvaluation,
      selectionRequested: jobBusinessRelationState.hasRequestedSelection,
      isEvaluated: !!job.evaluation,
      isDirectRegistration: isOneOnOne,
      isRejected: jobBusinessRelationState.isRejected,
    });
  }, [
    bizVerificationViewEnabled,
    job.traits?.fromVerifiedUser,
    job.labels,
    job.evaluation,
    jobBusinessRelationState.hasRequestedEvaluation,
    jobBusinessRelationState.hasRequestedSelection,
    isOneOnOne,
    jobBusinessRelationState.isRejected,
  ]);

  const jobHasEvaluation = job.evaluation && job.labels.won;
  const totalTagsCount = jobHasEvaluation
    ? [...jobLabels, ...(job.folders || [])].length + 1
    : [...jobLabels, ...(job.folders || [])].length;
  // We don't want to display not deleted items in DELETED  job list
  if (
    jobListId === JobListId.Deleted &&
    !job.jobBusinessRelationState.isDeleted
  ) {
    return null;
  }

  const VISIBLE_LABELS_AMOUNT = isSmartPhone ? 1 : 5;
  const [labels, folders] = selectEvenlyFromSets(
    VISIBLE_LABELS_AMOUNT,
    jobLabels,
    job.folders || []
  );
  const showEvaluationLabel =
    jobHasEvaluation && labels.length + folders.length < VISIBLE_LABELS_AMOUNT;

  const itemColor = job.jobBusinessRelationState.isRead
    ? themeContext.colors.black.black7
    : themeContext.colors.black.black10;
  const itemWeight = job.jobBusinessRelationState.isRead
    ? themeContext.fontWeights.regular
    : themeContext.fontWeights.bold;
  return (
    <JobContainer
      showActionButtons={actionButtonsVisible}
      borderBottom={
        isLast ? 'none' : `1px solid ${themeContext.colors.black.black2}`
      }
      {...(isDirectRegistration && { indicatorVariant: 'green' })}
    >
      <JobLink
        to={linkToJob}
        id={`job-${job.id}`}
        data-testid={`job-list-item-active-${job.id}`}
        onClick={handleOpenJob}
      >
        <Container
          py="24px"
          px={[0, 0, 0, 5]}
          color={themeContext.colors.black.black7}
        >
          {isDirectRegistration && !isDirectJobListOpen && (
            <Row mb={4} pl="34px">
              <JobBadge variant={JobBadgeVariant.DirectRegistration} />
            </Row>
          )}
          <Row>
            <Column width="24px">
              {!jobBusinessRelationState.isRead && (
                <Dot variant={isDirectRegistration ? 'green' : 'primary'} />
              )}
            </Column>
            <Column width="calc(100% - 84px)">
              <Div
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                mb={3}
              >
                <JobTitle
                  color={itemColor}
                  fontWeight={itemWeight}
                  data-testid="jobitem-header"
                >
                  {job.title}
                </JobTitle>
              </Div>
              <Div display="flex" alignItems="center" mb={3}>
                <Div flexShrink={0} alignSelf="baseline" mr={3}>
                  <Text.span
                    lineHeight="24px"
                    fontSize="14px"
                    mb={0}
                    color={themeContext.colors.black.black7}
                    fontWeight={400}
                    data-testid={`job-item-address-hint-${job.id}`}
                  >
                    {`${job.addressHint?.postalCode} ${job.addressHint?.city}`}
                  </Text.span>
                </Div>
                <Div display="flex" flexDirection="row">
                  <JobLabelsV0
                    labels={labels}
                    folders={folders}
                    evaluation={
                      showEvaluationLabel ? job.evaluation : undefined
                    }
                  />
                  {totalTagsCount > VISIBLE_LABELS_AMOUNT && (
                    <Div
                      bg={themeContext.colors.black.black2}
                      px={3}
                      display="flex"
                      borderRadius={1}
                      height="16px"
                      width="16px"
                      justifyContent="center"
                      alignItems="center"
                      ml={2}
                    >
                      <Text.p
                        fontSize={0}
                        color={themeContext.colors.black.black9}
                        mt={2}
                      >
                        +{totalTagsCount - VISIBLE_LABELS_AMOUNT}
                      </Text.p>
                    </Div>
                  )}
                </Div>
              </Div>
              {jobBusinessRelationState.isAnswered ? (
                <LatestMessage latestMessage={job.latestMessage} />
              ) : (
                <JobDescription data-testid="job-list-item-description">
                  {job.description}
                </JobDescription>
              )}
            </Column>
            <Column
              width="60px"
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="flex-end"
            >
              <ApprovalTime
                fontSize="12px"
                mb={4}
                color={themeContext.colors.black.black7}
                fontWeight="normal"
                truncate
              >
                {dateFromTimestamp(
                  i18n.language,
                  job.approvedAt ?? 0,
                  undefined,
                  {
                    today: '',
                    yesterday: t('general.dateTime.yesterday'),
                  },
                  true
                )}
                &nbsp;
                <FireIcon
                  variant="flat"
                  iconName={IconPickerName.ChevronRight}
                  fill={themeContext.colors.gray.base}
                  width={12}
                  height={12}
                />
              </ApprovalTime>
              {hasAttachments && (
                <FireIcon
                  variant="flat"
                  iconName={IconPickerName.Attachment}
                  fill={themeContext.colors.gray.base}
                  width={12}
                  height={12}
                />
              )}
            </Column>
          </Row>
        </Container>
      </JobLink>
      <ActionButtonsContainer pt={5} px={[2, 3, 6]}>
        {jobFoldersEnabled ? (
          <JobActionButtons.FolderSelect
            job={job}
            favouriteAction={handleJobFavourite}
            onDropdownOpen={showActionButtons}
            onDropdownClose={hideActionButtons}
            containerRef={jobListContainerRef}
          />
        ) : (
          <JobActionButtons.Favourite
            isFavourite={jobBusinessRelationState.isFavourite}
            onClick={handleJobFavourite}
          />
        )}
        {!jobBusinessRelationState.isAnswered && (
          <JobActionButtons.Delete
            isDeleted={jobBusinessRelationState.isDeleted}
            onClick={handleJobDelete}
          />
        )}
      </ActionButtonsContainer>
    </JobContainer>
  );
};
