import * as React from 'react';

import { Modal, Div, Text, Heading, Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface JobConfirmationModalProps {
  isConfirmationModalOpen: boolean;
  onCloseConfirmationModal: (arg: boolean) => void;
  onClose: () => void;
}

const JobConfirmationModal: React.FC<
  React.PropsWithChildren<JobConfirmationModalProps>
> = ({ isConfirmationModalOpen, onCloseConfirmationModal, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      size="custom"
      isOpen={isConfirmationModalOpen}
      isClosable
      isVerticallyCentered
      onClose={() => onCloseConfirmationModal(false)}
      isSmaller
      maxWidth="540px"
    >
      <Div p={30}>
        <Heading.h3 mb={0}>{t('job.item.unsavedChanges.title')}</Heading.h3>
        <Text.p pt={20} pb={20} textAlign="center" lineHeight={1.5}>
          {t('job.item.unsavedChanges.content')}
        </Text.p>
        <Div display="flex" flexDirection="column">
          <Button onClick={() => onClose()}>
            {t('job.item.unsavedChanges.closeButton')}
          </Button>
          <Button
            variant="linkPrimary"
            onClick={() => onCloseConfirmationModal(false)}
          >
            {t('job.item.unsavedChanges.cancelButton')}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};

export default JobConfirmationModal;
