import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import FilterBadgeContainer from './FilterBadgeContainer';

const FilterMultipleBadge = styled(Div)`
  display: flex;
  min-width: 40%;
  justify-content: flex-end;
  ${FilterBadgeContainer} {
    min-width: ${({ theme }) => `${theme.space[9]}px`};
    justify-content: flex-end;

    @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
      min-width: ${({ theme }) => `${theme.space[10]}px`};
      width: auto;
    }
  }
`;

export default FilterMultipleBadge;
