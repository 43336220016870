import { FC } from 'react';

import { ProductAccordion } from '@components/layout/Products/ProductAccordion';
import { ProductInfoRow } from '@components/layout/Products/ProductInfoRow';
import { ProductPackageStatusTag } from '@components/layout/Products/ProductPackageStatusTag';
import {
  StyledProductInfoLabel,
  StyledProductInfoText,
} from '@components/layout/Products/styled';
import {
  ProductDate,
  ProductPackagePrice,
  ProductPackageStatus,
} from '@internals/business-shared/src/components/Products/productTypes';
import {
  getPackageMonthlyPrice,
  getProductPackageDate,
} from '@internals/business-shared/src/components/Products/productUtils';
import { Button, Div, Divider, Heading } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';

interface ProductPackageAccordionProps {
  packageName: string;
  status: ProductPackageStatus;
  description: string;
  onMoreClick: () => void;
  price: ProductPackagePrice;
  numberOfCredits: number;
  timeDetails:
    | {
        type: 'renewal';
        date: ProductDate;
      }
    | {
        type: 'expiration';
        date: ProductDate;
      };
}

export const ProductPackageAccordion: FC<ProductPackageAccordionProps> = ({
  packageName,
  status,
  description,
  onMoreClick,
  price,
  numberOfCredits,
  timeDetails,
}) => {
  const packagePrice = getPackageMonthlyPrice(price, true);

  return (
    <ProductAccordion
      fixedContent={
        <>
          <Heading.h4 mb={3} fontWeight="medium">
            {packageName}
          </Heading.h4>
          <ProductPackageStatusTag status={status} withIcon />
        </>
      }
      expandedContent={
        <>
          <Div py={5}>
            <StyledProductInfoLabel>
              {t('general.label.description')}
            </StyledProductInfoLabel>
            <StyledProductInfoText>{description}</StyledProductInfoText>
            <Button variant="secondary" onClick={onMoreClick} py={4} px={5}>
              {t('general.label.readMore')}
            </Button>
          </Div>
          <Divider backgroundColor="black.black4" />
          <Div py={5}>
            <StyledProductInfoLabel>
              {t('general.label.details')}
            </StyledProductInfoLabel>
            <ProductInfoRow
              name={t('general.label.price')}
              value={packagePrice}
            />
            <ProductInfoRow
              name={t('product.creditsIncluded')}
              value={numberOfCredits.toString()}
            />
            {timeDetails.type === 'renewal' ? (
              <ProductInfoRow
                name={t('product.renewalDate')}
                value={getProductPackageDate(timeDetails.date)}
              />
            ) : (
              <ProductInfoRow
                isWarning
                name={t('product.expiringSoon')}
                value={getProductPackageDate(timeDetails.date)}
              />
            )}
          </Div>
        </>
      }
    />
  );
};
