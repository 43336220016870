import { FC } from 'react';

import { boligmappaUrls } from '@internals/business-shared/src/utils/constants/urls';
import { Div, Link } from '@schibsted-smb/fireball';
import { Trans } from 'react-i18next';
import { useTheme } from 'styled-components';

export const TermsAndConditions: FC = () => {
  const themeContext = useTheme();

  return (
    <Div
      width="100%"
      display="flex"
      alignItems="center"
      borderTop={`1px solid ${themeContext.colors.black.black3}`}
    >
      <Div mt={5} fontSize={themeContext.fontSizes[0]}>
        <Trans
          i18nKey="settings.integrations.boligmappa.register.termsAndConditions"
          fontSize={themeContext.fontSizes[0]}
          components={{
            a: (
              <Link
                fontSize={themeContext.fontSizes[0]}
                href={boligmappaUrls.termsAndConditions}
                target="_blank"
              />
            ),
            b: (
              <Link
                fontSize={themeContext.fontSizes[0]}
                href={boligmappaUrls.privacyPolicy}
                target="_blank"
              />
            ),
          }}
        />
      </Div>
    </Div>
  );
};
