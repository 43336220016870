import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const LogoContainer = styled(Div)`
  display: none;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export default LogoContainer;
