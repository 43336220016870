import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BUSINESS_EVALUATIONS_QUERY,
  BusinessEvaluationsQueryPayload,
  BusinessEvaluationsQueryVariables,
  BusinessEvaluationsType,
} from '../../utils/query/BusinessEvaluations/BusinessEvaluationsQuery';

interface BusinessEvaluationsQueryResult extends QueryResult {
  data: BusinessEvaluationsType | null;
}

export const useBusinessEvaluationsQuery = (
  options?: QueryHookOptions<
    BusinessEvaluationsQueryPayload,
    BusinessEvaluationsQueryVariables
  >
): BusinessEvaluationsQueryResult => {
  const { data, ...rest } = useQuery<
    BusinessEvaluationsQueryPayload,
    BusinessEvaluationsQueryVariables
  >(BUSINESS_EVALUATIONS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return useMemo(
    () => ({
      data: data?.businessEvaluations ?? null,
      ...rest,
    }),
    [data, rest]
  );
};
