import { FC, ReactElement, useMemo } from 'react';

import getFFConfig from '@internals/business-shared/config/feature-flag-config';
import { FlagProvider, IConfig } from '@unleash/proxy-client-react';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { getProductId } from '@utils/product';

interface FlagsProviderProps {
  children: ReactElement | ReactElement[];
}

const REFRESH_INTERVAL = 5 * 60; // time for flags re-fetch in seconds

export const FeatureFlagsProvider: FC<FlagsProviderProps> = ({ children }) => {
  const FEATURE_FLAGS_CONFIG: IConfig = useMemo(() => {
    const envBasedConfig = getFFConfig(
      getEnvValue(ENV_KEY_NAME.ENVIRONMENT),
      getProductId(),
      getEnvValue(ENV_KEY_NAME.FEATURE_FLAGS)
    );

    return {
      ...envBasedConfig,
      refreshInterval: REFRESH_INTERVAL,
      context: { properties: { product: getProductId(), platform: 'web' } },
    };
  }, []);

  return <FlagProvider config={FEATURE_FLAGS_CONFIG}>{children}</FlagProvider>;
};
