import { ApolloError } from '@apollo/client';
import {
  AuthMeDocument,
  UserRole,
} from '@internals/business-graphql/src/graphql/generated/types';
import { resolveErrors } from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import { handleLoginSuccess } from '../login/handleLoginSuccess';
import type { AuthHandlers, ErrorVariant } from '../login/types';
import type { SetState } from '../types';

export type HandleLoadUserErrorHandlers = {
  onGenericError: (error?: ErrorVariant) => void;
};

export const handleLoadUser = async (
  set: SetState,
  authHandlers: AuthHandlers,
  errorHandlers: HandleLoadUserErrorHandlers,
  defaultIntendedRole?: UserRole
) => {
  try {
    const response = await apolloClient.query({
      query: AuthMeDocument,
      fetchPolicy: 'network-only',
    });

    const { data } = response || {};

    if (data?.me) {
      set({ status: 'authenticated', user: data.me });

      if (authHandlers) {
        await handleLoginSuccess(data.me, authHandlers, defaultIntendedRole);
      }
    } else {
      set({ status: 'unauthenticated', user: null });
      if (authHandlers?.onLogout) await authHandlers.onLogout('handleLoadUser');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors({}, () =>
        defaultErrorHandler(
          (error) => errorHandlers?.onGenericError(error),
          true
        )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers?.onGenericError();
    }
  }
};
