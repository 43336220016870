import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FooterItem = styled(Text.span)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.black.black7};
  margin: 0;
`;

export default FooterItem;
