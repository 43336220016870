import { ApolloCache } from '@apollo/client';

import { SET_JOB_AS_READ_MUTATION } from '../../utils/generated/generated';

const setJobAsReadUpdate =
  (jobId: string | number) =>
  (
    cache: ApolloCache<SET_JOB_AS_READ_MUTATION>,
    { data }: { data?: SET_JOB_AS_READ_MUTATION | null }
  ) => {
    if (data?.setJobAsRead.success) {
      cache.modify({
        id: cache.identify({
          jobId,
          __typename: 'JobBusinessRelationState',
        }),
        fields: {
          isRead() {
            return true;
          },
        },
      });
    }
  };

const setJobAsReadOptimisticResponse: SET_JOB_AS_READ_MUTATION = {
  setJobAsRead: {
    __typename: 'SetJobAsReadPayload',
    success: true,
  },
};

export { setJobAsReadUpdate, setJobAsReadOptimisticResponse };
