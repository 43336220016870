import { createContext, FC, ReactElement, useContext } from 'react';

import {
  BoligMappaAuthHook,
  useBoligMappaAuth,
} from '@hooks/useBoligMappaAuth';

type BoligMappaAuthContextValue = BoligMappaAuthHook;
export const BoligMappaAuthContext = createContext<BoligMappaAuthContextValue>({
  isAuthorised: false,
  isLoading: false,
  authRedirect: async () => {},
  authoriseBoligMappa: async () => {},
  unlinkBoligMappa: async () => {},
  refetchBoligMappa: async () => {},
});
export const BoligMappaAuthProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const boligMappaAuth = useBoligMappaAuth();

  return (
    <BoligMappaAuthContext.Provider value={boligMappaAuth}>
      {children}
    </BoligMappaAuthContext.Provider>
  );
};

export const useBoligMappaAuthContext = (): BoligMappaAuthContextValue => {
  const boligMappaAuthContextValue = useContext(BoligMappaAuthContext);
  if (!boligMappaAuthContextValue) {
    throw new Error(
      'BoligMappaAuth Context used outside provider or before boligMappaAuth data initialization'
    );
  }
  return boligMappaAuthContextValue;
};
