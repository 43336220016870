import { FC, PropsWithChildren } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ReactComponent as XLLogoDark } from '@internals/business-shared/src/assets/illustrations/XLLogoDark.svg';
import { ReactComponent as StrokeGraphic } from '@internals/business-shared/src/assets/illustrations/XLStroke.svg';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  MittanbudXLModal,
  useModal,
} from '@root/src/components/elements/MittanbudXLModal';
import { Button, Card, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const MittanbudXLCard: FC<PropsWithChildren> = () => {
  const [modalProps, onModalOpen] = useModal();
  const { t } = useTranslation();

  return (
    <>
      <Card p={0} mb={6} boxShadow="l">
        <Div
          bg="black.black9"
          pb={6}
          style={{ clipPath: 'polygon(0 0, 100% 0, 100% 75%, 0 100%)' }}
        >
          <Text.p
            mb={0}
            p={5}
            color="black.black0"
            textAlign="center"
            fontWeight="medium"
          >
            {t('job.list.statistics.mittanbudXL.title')}
          </Text.p>
        </Div>
        <Div position="relative" bottom={30}>
          <Div position="absolute">
            <StrokeGraphic />
          </Div>
          <Card
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            boxShadow="l"
            width={70}
            height={24}
            right={0}
            bg="blueNavy.base"
            p={0}
          >
            <Text.p mb={0} color="black.black0" fontWeight="medium">
              {t('general.label.newFeature')}
            </Text.p>
          </Card>
        </Div>
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          px={5}
        >
          <Div height={100} my={5}>
            <XLLogoDark />
          </Div>
          <Text.p textAlign="center" mb={0} fontSize={1} fontWeight="medium">
            {t('job.list.statistics.mittanbudXL.description')}
          </Text.p>
          <Button
            size="tiny"
            icon={() => (
              <FireIcon
                width={12}
                height={12}
                iconName={IconPickerName.ChevronRightBold}
                fill="black.black0"
              />
            )}
            iconPosition="r"
            fullWidth
            onClick={onModalOpen}
            my={5}
          >
            <Text.p mb={0} py={3} fontSize={1} fontWeight="medium">
              {t('job.list.statistics.mittanbudXL.action')}
            </Text.p>
          </Button>
        </Div>
      </Card>
      <MittanbudXLModal {...modalProps} />
    </>
  );
};
