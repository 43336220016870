import { FC } from 'react';

import { ChooseActionSavedFilterModal } from '@components/layout/Jobs/SavedFilters/ChooseActionSavedFilterModal';
import { ConfirmDeleteSavedFilterModal } from '@components/layout/Jobs/SavedFilters/ConfirmDeleteSavedFilterModal';
import { CreateSavedFilterModal } from '@components/layout/Jobs/SavedFilters/CreateSavedFilterModal';
import { EditSavedFilterModal } from '@components/layout/Jobs/SavedFilters/EditSavedFilterModal';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import { SavedFilterModalType } from '@internals/business-shared/src/components/SavedFilters/types';
import { CreatedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/CreateJobsSavedFilter/CreateJobsSavedFilterMutation';
import { EditedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';

interface SavedFilterActionModalProps {
  onFilterCreated: (newFilter: CreatedJobsSavedFilter) => void;
  onFilterEdited: (newFilter: EditedJobsSavedFilter) => void;
}

export const SavedFilterActionModal: FC<SavedFilterActionModalProps> = ({
  onFilterCreated,
  onFilterEdited,
}) => {
  const { filterState } = useSavedFiltersContext();
  switch (filterState.modal) {
    case SavedFilterModalType.Create:
      return <CreateSavedFilterModal onFilterCreated={onFilterCreated} />;
    case SavedFilterModalType.ChooseAction:
      return <ChooseActionSavedFilterModal onFilterEdited={onFilterEdited} />;
    case SavedFilterModalType.ConfirmDelete:
      return <ConfirmDeleteSavedFilterModal />;
    case SavedFilterModalType.Edit:
      return <EditSavedFilterModal onFilterEdited={onFilterEdited} />;
    case SavedFilterModalType.None:
    default:
      return null;
  }
};
