import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const JobModalContentLoader: React.FC<LoaderProps> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 940 860"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    {/* header */}
    <rect x="70" y="30" rx="4" ry="4" width="300" height="20" />
    <rect x="30" y="30" rx="4" ry="4" width="20" height="20" />
    <rect x="0" y="74" rx="4" ry="4" width="940" height="1" />
    <circle cx="893" cy="38" r="17" />
    <circle cx="849" cy="38" r="17" />
    <circle cx="805" cy="38" r="17" />
    {/* description */}
    <rect x="20" y="95" rx="4" ry="4" width="550" height="20" />
    <rect x="20" y="120" rx="4" ry="4" width="500" height="20" />
    <rect x="20" y="145" rx="4" ry="4" width="415" height="20" />
    {/* details */}
    <rect x="20" y="185" rx="4" ry="4" width="584" height="100" />
    {/* images */}
    <rect x="20" y="305" rx="4" ry="4" width="188" height="188" />
    <rect x="218" y="305" rx="4" ry="4" width="188" height="188" />
    <rect x="416" y="305" rx="4" ry="4" width="188" height="188" />
    {/* job id */}
    <rect x="20" y="513" rx="4" ry="4" width="80" height="15" />
    <rect x="20" y="533" rx="4" ry="4" width="155" height="20" />
    {/* alerts/form */}
    <rect x="20" y="628" rx="4" ry="4" width="584" height="212" />
    {/* column delimiter */}
    <rect x="626" y="75" rx="4" ry="4" width="1" height="798" />
    {/* client */}
    <rect x="647" y="95" rx="4" ry="4" width="140" height="23" />

    <rect x="647" y="133" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="153" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="193" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="213" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="253" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="273" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="313" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="333" rx="4" ry="4" width="155" height="20" />
    {/* map */}
    <rect x="647" y="373" rx="4" ry="4" width="273" height="160" />
  </ContentLoader>
);

export default JobModalContentLoader;
