import { t } from '@internals/business-translations/src/i18n';

import { POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress } from '../generated/generated';
import { PostalAddressQueryPayload } from '../query/PostalAddress/PostalAddressQuery';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class PostalAddressResponseError extends GQLResponseErrorBase<
  PostalAddressQueryPayload['postalAddress'],
  POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'PostalAddressNotFoundError':
        this.errors.general = {
          variant: 'danger',
          msg: t('general.form.validation.postalCode.invalid'),
        };
        break;
      default:
        this.errors.general = {
          variant: 'danger',
          msg: t('general.form.validation.postalCode.invalid'),
        };
        break;
    }
  }
}
