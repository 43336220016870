import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  Employee,
  EMPLOYEE_LIST_QUERY,
  EmployeeListQueryPayload,
  EmployeeListQueryVariables,
} from '../../utils/query/EmployeeList/EmployeeListQuery';

interface EmployeeListQueryResult extends QueryResult {
  data: Employee[] | null;
}

export const useEmployeeListQuery = (
  businessId: string,
  options?: QueryHookOptions<
    EmployeeListQueryPayload,
    EmployeeListQueryVariables
  >
): EmployeeListQueryResult => {
  const { data, ...rest } = useQuery<
    EmployeeListQueryPayload,
    EmployeeListQueryVariables
  >(EMPLOYEE_LIST_QUERY, {
    variables: { businessId },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return useMemo(() => {
    return {
      data: data?.businessEmployees || null,
      ...rest,
    };
  }, [data, rest]);
};
