import { hexToRgba } from '@schibsted-smb/fireball-themes';
import styled from 'styled-components';

export const StyledBoostAlertBackground = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  height: calc(100% + 200px);
  margin-left: -20px;
  background: ${() =>
    `linear-gradient(180deg, ${hexToRgba('#ffffff', 0)} 0%, #f1f1f1 60%)`};
  pointer-events: none;
`;
