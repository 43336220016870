import gql from 'graphql-tag';

import {
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY as BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_Result,
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations,
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERYVariables,
} from '../../generated/generated';

export type BoligmappaJobAssociationsQueryPayload =
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_Result;
export type BoligmappaJobAssociationsVariables =
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERYVariables;
export type BoligmappaJobAssociationsType =
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY_boligmappaJobAssociations;

export const BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY = gql`
  query BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY($jobId: ID!) {
    boligmappaJobAssociations(jobId: $jobId) {
      id
      address {
        id
        houseNumber
        houseSubNumber
        streetName
        postalCode
        postalPlace
      }
      associations {
        id
        propertyType
        unitNumber
        documents {
          id
          fileName
        }
      }
    }
  }
`;
