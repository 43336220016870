import { createElement, FC, useCallback, useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { BankIdVerified } from '@components/elements/BankIdVerified';
import { BoligmappaRequested } from '@components/elements/BoligmappaRequested';
import MapPreviewModal from '@components/elements/MapPreviewModal';
import { JobDataHiddenItem } from '@components/layout/Job/JobDataHiddenItem';
import { JobDataItem } from '@components/layout/Job/JobDataItem';
import useAnalytics from '@hooks/useAnalytics';
import { useJobAnalyticsQuery } from '@internals/business-shared/src/hooks/query/useJobAnalyticsQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  JOB_OWNER_QUERY_job_Job,
  JOB_OWNER_QUERY_job_Job_contactInfo,
  JOB_QUERY_job_Job,
  JobContactPreference,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  getEmailContent,
  getPhoneContent,
  isEmailAvailable,
  isPhoneAvailable,
} from '@internals/business-shared/src/utils/jobContactDetails';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import { Div, Heading } from '@schibsted-smb/fireball';
import {
  getAddressForMaps,
  getAddressString,
  getShortAddressString,
} from '@utils/address';
import JobModalRightColumnDetailsContentLoader from '@utils/contentLoaders/JobModalRightColumnDetailsContentLoader';
import { getProduct } from '@utils/product';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';

export interface JobContentOwnerInfoProps {
  isAnswered: boolean;
  owner?: JOB_OWNER_QUERY_job_Job['owner'];
  address: JOB_OWNER_QUERY_job_Job['address'];
  addressHint: JOB_QUERY_job_Job['addressHint'];
  showAddressAndMap: boolean;
  isLoading: boolean;
  contactDetails: JOB_OWNER_QUERY_job_Job_contactInfo;
  contactPreference: JobContactPreference;
  withHeader?: boolean;
  jobId: JOB_QUERY_job_Job['id'];
  traits: JobTraitsQueryData | null;
  isBoligmappaRequested?: boolean;
}

type ClientItem = {
  title: string;
  content: { text: string; disabled?: boolean };
} & (
  | {
      initiallyHidden?: boolean;
      textWhenHidden?: never;
      onReveal?: never;
    }
  | {
      initiallyHidden: true;
      textWhenHidden: string;
      onReveal?: VoidFunction;
    }
);

const JobContentOwnerInfo: FC<JobContentOwnerInfoProps> = ({
  isAnswered,
  owner,
  address,
  addressHint,
  showAddressAndMap,
  isLoading,
  contactDetails,
  contactPreference,
  withHeader,
  jobId,
  traits,
  isBoligmappaRequested,
}) => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const { track } = useAnalytics();
  const showPhoneDespitePreferenceEnabled = useFlagEnabled(
    FeatureFlags.BizShowPhoneDespitePreference
  );
  const bizVerificationViewEnabled = useFlagEnabled(
    FeatureFlags.BizVerificationView
  );

  const { phone, email } = contactDetails || {};
  const showBankVerifiedId =
    bizVerificationViewEnabled && (traits?.fromVerifiedUser || false);

  const { data: jobAnalytics } = useJobAnalyticsQuery(jobId);

  const getConcealedContent = useCallback(
    (text: string): ClientItem['content'] => {
      return {
        text: isAnswered ? text : t('job.item.concealedData'),
        disabled: !isAnswered,
      };
    },
    [isAnswered, t]
  );

  const phoneAvailable = isPhoneAvailable({
    isAnsweredJob: isAnswered,
    showDespitePreference: showPhoneDespitePreferenceEnabled,
    contactPreference,
    phone,
  });

  const getPhoneText = useCallback((): ClientItem['content'] => {
    return {
      text: getPhoneContent({
        phone,
        contactPreference,
        productName,
        isAnsweredJob: isAnswered,
        showDespitePreference: showPhoneDespitePreferenceEnabled,
      }),
      disabled: !phoneAvailable,
    };
  }, [
    phoneAvailable,
    phone,
    contactPreference,
    productName,
    isAnswered,
    showPhoneDespitePreferenceEnabled,
  ]);

  const emailAvailable = isEmailAvailable(email);

  const getEmailText = useCallback((): ClientItem['content'] => {
    return {
      text: getEmailContent({
        email,
        contactPreference,
        productName,
        isAnsweredJob: isAnswered,
      }),
      disabled: !emailAvailable,
    };
  }, [emailAvailable, email, contactPreference, productName, isAnswered]);

  const clientData: ClientItem[] = useMemo(
    () => [
      {
        title: t('general.label.name'),
        content: getConcealedContent(getUserName(owner)),
      },
      {
        title: t('general.label.phone'),
        content: getPhoneText(),
        ...(phoneAvailable && {
          initiallyHidden: true,
          textWhenHidden: t('job.action.showPhone'),
          onReveal: () =>
            track(
              ANEventSpace(ANEvent.Clicked, ANObject.ShowPhone, ANPage.JobList),
              {
                hasContract: jobAnalytics.isContract,
                isDialogue: jobAnalytics.isDialog,
                has: jobAnalytics.isWon,
              }
            ),
        }),
      },
      {
        title: t('general.label.email'),
        content: getEmailText(),
        ...(emailAvailable && {
          initiallyHidden: true,
          textWhenHidden: t('job.action.showEmail'),
          onReveal: () =>
            track(
              ANEventSpace(ANEvent.Clicked, ANObject.ShowEmail, ANPage.JobList)
            ),
        }),
      },
    ],
    [
      t,
      getConcealedContent,
      owner,
      getPhoneText,
      phoneAvailable,
      track,
      jobAnalytics.isContract,
      jobAnalytics.isDialog,
      jobAnalytics.isWon,
      getEmailText,
      emailAvailable,
    ]
  );

  const addressData: ClientItem[] = showAddressAndMap
    ? [
        {
          title: t('general.label.address'),
          content: getConcealedContent(getAddressString(address)),
        },
        {
          title: t('general.label.place'),
          content: { text: getShortAddressString(addressHint) },
        },
      ]
    : [];

  const mapAddress: string | null = useMemo(() => {
    if (!showAddressAndMap) return null;
    if (!isAnswered) {
      return addressHint
        ? getAddressForMaps(addressHint.city, addressHint.postalCode)
        : null;
    }
    return address
      ? getAddressForMaps(address.city, address.zip, address.street)
      : null;
  }, [showAddressAndMap, address, addressHint]);

  const renderItem = useCallback((data: ClientItem) => {
    const props = {
      key: data.title,
      title: data.title,
      text: data.content.text,
      disabled: data.content.disabled,
      textWhenHidden: data.textWhenHidden,
      onReveal: data.onReveal,
    };
    return createElement(
      data.initiallyHidden ? JobDataHiddenItem : JobDataItem,
      props
    );
  }, []);

  if (isLoading) {
    return (
      <JobModalRightColumnDetailsContentLoader uniqueKey="loader-job-right-column" />
    );
  }

  return (
    <Div data-testid="job-content-owner-info">
      {withHeader && (
        <Heading.h3 textAlign="left">{t('general.label.client')}</Heading.h3>
      )}
      {showBankVerifiedId && <BankIdVerified />}
      {isBoligmappaRequested && <BoligmappaRequested />}
      <Div>{[...clientData, ...addressData].map(renderItem)}</Div>
      {mapAddress && (
        <MapPreviewModal
          address={mapAddress}
          containerProps={{ height: '160px' }}
        />
      )}
    </Div>
  );
};

export default JobContentOwnerInfo;
