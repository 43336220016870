import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  BoligmappaCreateAccountMutationPayload,
  BoligmappaCreateAccountMutationVariables,
  BOLIGMAPPA_CREATE_ACCOUNT,
} from '../../utils/mutation/CreateBoligmappaAccount/CreateBoligmappaAccountMutation';

type UseBoligmappaCreateAccountMutationAction = (
  input: BoligmappaCreateAccountMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    BoligmappaCreateAccountMutationPayload,
    BoligmappaCreateAccountMutationVariables
  >
) => Promise<FetchResult<BoligmappaCreateAccountMutationPayload>>;
export const useBoligmappaCreateAccountMutation = (): [
  UseBoligmappaCreateAccountMutationAction,
  MutationResult<BoligmappaCreateAccountMutationPayload>
] => {
  const [createBoligmappaAccount, ...rest] = useMutation<
    BoligmappaCreateAccountMutationPayload,
    BoligmappaCreateAccountMutationVariables
  >(BOLIGMAPPA_CREATE_ACCOUNT, {
    fetchPolicy: 'network-only',
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
  });

  const mutationFunction: UseBoligmappaCreateAccountMutationAction =
    useCallback(
      (input, mutationOptions) =>
        createBoligmappaAccount({
          variables: {
            input: {
              ...input,
            },
          },
          ...mutationOptions,
        }),
      [createBoligmappaAccount]
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
