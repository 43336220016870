const getFFConfig = (environment, product, clientKey) => {
  let url = 'https://app.unleash-hosted.com/brown/api/production/proxy';
  let ffEnvironment = 'production';

  // default product set to mittanbud
  switch (environment) {
    case 'test':
    case 'local':
    case 'staging':
    case 'development':
      url = 'https://app.unleash-hosted.com/brown/api/development/proxy';
      ffEnvironment = 'development';
      break;

    case 'production':
      url = 'https://app.unleash-hosted.com/brown/api/production/proxy';
      ffEnvironment = 'production';
      break;
    default:
      throw new Error(
        `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
      );
  }

  return {
    url,
    environment: ffEnvironment,
    appName: product,
    clientKey,
  };
};

module.exports = getFFConfig;
