import { HEADER_HEIGHT } from '@components/layout/Header/styled/Header';
import {
  SMARTPHONE_NAV_MENU_HEIGHT,
  TABLET_NAV_MENU_HEIGHT,
} from '@components/layout/Header/styled/MenuNav';
import { Container, GridContainerProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface PageContainerStyledProps extends GridContainerProps {
  customHeight?: number;
  fluid?: boolean;
  skipNavigation?: boolean;
}

const PageContainerStyled = styled(Container)<PageContainerStyledProps>`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black.black0};
  ${({ customHeight }) => (customHeight ? `height: ${customHeight}px;` : '')}
  padding-bottom: ${({ skipNavigation }) =>
    skipNavigation ? '0' : SMARTPHONE_NAV_MENU_HEIGHT}px;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[4]}) {
    background-color: transparent;
    min-height: unset;
    ${({ customHeight }) =>
      customHeight ? `height: ${customHeight - HEADER_HEIGHT}px;` : ''}
    padding-bottom: ${({ skipNavigation }) =>
      skipNavigation ? '0' : TABLET_NAV_MENU_HEIGHT}px;
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[5]}) {
    padding-bottom: 0;
  }
`;
export default PageContainerStyled;
