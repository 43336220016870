import { t } from '@internals/business-translations/src/i18n';

const jobAnswerCountLabel = (answerCount: number): string =>
  answerCount > 5 ? '5+' : answerCount.toString();

export const getAnswerCount = (
  count: number | null,
  limit: number | null,
  hideCountToggle = false
): string => {
  if (hideCountToggle && count > 0) {
    return t('general.label.notProvided');
  }
  if (!count) {
    return t('job.item.answers.noAnswers');
  }
  if (limit && count >= limit) {
    return t('job.item.answers.reachedLimit', { answerCount: limit }); // We want to display the limit here, not the count
  }
  return t('job.item.answers.count', {
    answerCount: jobAnswerCountLabel(count),
  });
};
