import gql from 'graphql-tag';

import {
  ANSWER_JOBVariables,
  ANSWER_JOB as ANSWER_JOB_Result,
  ANSWER_JOB_answerJob_AnswerJobPayload,
} from '../../generated/generated';

export type AnswerJobMutationVariables = ANSWER_JOBVariables;
export type AnswerJobMutationPayload = ANSWER_JOB_Result;

export const isAnswerJobMutationSuccessResponse = (
  answerJob?: ANSWER_JOB_Result['answerJob']
): answerJob is ANSWER_JOB_answerJob_AnswerJobPayload =>
  !!answerJob && answerJob.__typename === 'AnswerJobPayload';

export const ANSWER_JOB = gql`
  mutation ANSWER_JOB($jobId: ID!, $text: String!, $files: [Upload!]) {
    answerJob(input: { jobId: $jobId, text: $text, files: $files }) {
      ... on AnswerJobPayload {
        chat {
          id
        }
        job {
          id
          firstMessage {
            id
            regTs
            text
          }
        }
      }
      ... on AnswerJobInputError {
        message
        jobId {
          message
        }
        text {
          message
        }
      }
      ... on AnswerJobAccessError {
        message
        accessCode
      }
    }
  }
`;
