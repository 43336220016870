import { FC, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { StyledCloseButton } from '@components/layout/Job/styled';
import { clearJobLists } from '@internals/business-shared/src/cache/clearJobLists';
import {
  getJobErrorContent,
  JobError,
  JobErrorKind,
} from '@internals/business-shared/src/types/JobError';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import {
  Button,
  Div,
  Heading,
  Modal,
  ModalProps,
  Text,
} from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

type JobErrorModalBaseProps = {
  errorType: JobError;
};

type JobErrorModalProps = ModalProps & JobErrorModalBaseProps;

type JobErrorHeaderProps = {
  onClose: VoidFunction;
};

type JobErrorBodyProps = {
  title: string;
  description: string;
} & (
  | {
      buttonText: string;
      buttonAction: VoidFunction;
    }
  | {
      buttonText?: never;
      buttonAction?: never;
    }
);

const JobErrorModalHeader: FC<JobErrorHeaderProps> = ({ onClose }) => {
  const themeContext = useTheme();

  return (
    <Div
      display="flex"
      alignItems="left"
      pb={6}
      px={7}
      width="100%"
      borderBottom={`1px solid ${themeContext.colors.black.black3}`}
    >
      <StyledCloseButton onClick={onClose}>
        <FireIcon
          iconName={IconPickerName.ArrowLeft}
          fill={themeContext.colors.primary.base}
          variant="flat"
        />
      </StyledCloseButton>
    </Div>
  );
};

const JobErrorModalBody: FC<JobErrorBodyProps> = ({
  title,
  description,
  buttonText,
  buttonAction,
}) => {
  const themeContext = useTheme();

  return (
    <Div display="flex" mt="85px" flexDirection="column" alignItems="center">
      <Div
        bg={themeContext.colors.blue.lightest}
        width="290px"
        height="290px"
        borderRadius="50%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={8}
      >
        <FireIcon
          iconName={IconPickerName.SadFace}
          variant="flat"
          fill={themeContext.colors.blue.dark}
          width="160px"
          height="160px"
        />
      </Div>
      <Div
        maxWidth="560px"
        display="flex"
        alignItems="center"
        flexDirection="column"
      >
        <Heading.h1 mb={6}>{title}</Heading.h1>
        <Text.span lineHeight={1.5} fontSize={3} textAlign="center" mb={6}>
          {description}
        </Text.span>
        {buttonText && buttonAction && (
          <Button
            height="50px"
            width="250px"
            variant="primary"
            onClick={buttonAction}
          >
            {buttonText}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export const JobErrorModal: FC<JobErrorModalProps> = ({
  errorType,
  ...rest
}) => {
  const navigate = useNavigate();
  const { cache } = useApolloClient();

  const text = getJobErrorContent(errorType);

  const buttonAction =
    errorType.kind === JobErrorKind.PAYLOAD
      ? () => {
          clearJobLists(cache);
          navigate(Paths.Homepage);
        }
      : () => {
          navigate(-1);
        };

  useEffect(() => {
    bugsnagClient.notify(errorType.error);
  }, []);

  return (
    <Modal {...rest} header={<JobErrorModalHeader onClose={rest.onClose} />}>
      <JobErrorModalBody
        title={text.title}
        description={text.description}
        buttonText={text.action}
        buttonAction={buttonAction}
      />
    </Modal>
  );
};
