export const Paths = {
  Homepage: '/',
  JobsList: '/jobb/lista',
  JobsListItem: '/jobb/lista/:listId',
  JobsItem: '/jobb/:id',
  JobsItemPrint: '/jobb/:id/print',
  JobsItemTitle: '/jobb/:id/:title',
  Messages: '/meddelanden',
  MessagesItem: '/meddelanden/:chatId',
  MessagesItemName: '/meddelanden/:chatId/:name',
  Icons: '/icons',
  Customer: '/kunder',
  CustomerContract: '/kunder/kontrakt',
  CustomerInvoice: '/kunder/faktura',
  CustomerEvaluation: '/kunder/omdomen',
  Profile: '/profil',
  ProfileEvaluations: '/profil/omdomen',
  ProfileEmployees: '/profil/medarbetare',
  ProfileCompanyInfo: '/profil/foretagsinfo',
  ProfileGraphic: '/profil/grafisk',
  ProfilePictures: '/profil/bilder',
  ProfileReferences: '/profil/referenser',
  ProfileQualifications: '/profil/kvalifakationer',
  SettingsNotifications: '/installningar/notifieringar',
  SettingsJobs: '/installningar/jobbsok',
  SettingsProfile: '/installningar/foretagsinfo',
  SettingsProducts: '/installningar/produkter',
  // todo: add translations
  SettingsProductAddons: '/installningar/produkter/addons',
  SettingsProductAddon: '/installningar/produkter/:slug',
  SettingsProductAddonPurchase: '/installningar/produkter/:slug/purchase',
  SettingsProductRewards: '/installningar/produkter/rewards',
  SettingsPayment: '/installningar/kvitton',
  SettingsCustomerService: '/installningar/kundservice',
  SettingsReceipts: '/installningar/kvitton2',
  SettingsPrivacyAndTerms: '/installningar/integritet',
  SettingsIntegrations: '/installningar/integrationer',
  Settings: '/installningar',
  Logout: '/logga-ut',
  AccountSwitch: '/byt-anvandare',
} as const;

export const Params = {
  Page: 'sida',
  Contract: 'avtal',
  Search: 'sok',
  Size: 'storlek',
  Municipality: 'kommun',
  District: 'distrikt',
  County: 'lan',
  Folder: 'mapp',
  WorkType: 'kategori',
  WorkSubset: 'delmangd',
  Industry: 'huvudkategori',
  WorkSubsetGroup: 'subset-group',
  Job: 'jobb',
  Evaluation: 'omdome',
  Report: 'rapport',
  BoostCreditsModal: 'toppa-upp-klipp-modal',
  BuySubscriptionModal: 'kop-prenumeration-modal',
  SelectedPackage: 'valt-paket',
} as const;
