import { FC, PropsWithChildren } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface HeaderProps {
  clips: number;
}

export const Header: FC<PropsWithChildren<HeaderProps>> = ({ clips }) => {
  const { t } = useTranslation();

  return (
    <Div>
      <Text.p>{t('general.label.boost')}</Text.p>
      <Text.p fontSize={4} fontWeight="bold">
        {t('general.label.credit', {
          count: clips,
        })}
      </Text.p>
    </Div>
  );
};
