import { t } from '@internals/business-translations/src/i18n';

import { JobContactPreference } from '../generated/generated';

export const getContactPreference = (
  contactPreference: JobContactPreference | null,
  productName: string,
  onMissingTypeHandling: (error: Error) => void
): string => {
  if (!contactPreference) return productName;

  switch (contactPreference) {
    case JobContactPreference.SITE_AND_PHONE: {
      return t('job.item.contactPreference.siteAndPhone', { productName });
    }
    case JobContactPreference.SITE_ONLY: {
      return productName;
    }
    default: {
      onMissingTypeHandling(
        new Error(`Job contact preference ${contactPreference} not handled`)
      );
      return productName;
    }
  }
};
