import { FC, KeyboardEventHandler, useCallback, useMemo, useRef } from 'react';

import { CustomErrorAlert } from '@components/base/ErrorAlert/CustomErrorAlert';
import {
  MessageTemplate,
  useMessageTemplates,
} from '@internals/business-shared/src/hooks/useMessageTemplates';
import { Div, Modal, Spinner } from '@schibsted-smb/fireball';
import { getKeyboardListNavigation } from '@utils/getKeyboardListNavigation';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ActiveTemplate } from './ActiveTemplate';
import { EmptyList } from './EmptyList';
import { Footer } from './Footer';
import { Header } from './Header';
import { TemplateList } from './TemplateList';

export interface MessageTemplateProps {
  onClose: VoidFunction;
  isOpen: boolean;
  onTemplateUsed: (message: string) => void;
}

export const MessageTemplateModal: FC<MessageTemplateProps> = ({
  onClose,
  isOpen,
  onTemplateUsed,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const {
    templateList,
    filteredTemplates,
    activeTemplateIndex,
    activeTemplate,
    onTemplateCreate,
    onTemplateChange,
    onTemplateDelete,
    onTemplateSelect,
    onTemplateSave,
    onSearchQueryChange,
    searchQuery,
    templateListError,
    editMode,
    enableEditing,
    disableEditing,
    ...loadingProps
  } = useMessageTemplates();
  const containerRef = useRef<HTMLDivElement>(null);
  const activeTemplateContainerRef = useRef<HTMLDivElement>(null);
  const keyboardNavigate = useMemo(
    () =>
      getKeyboardListNavigation({
        listLength: filteredTemplates.length,
        setIndex: onTemplateSelect,
        onEscape: onClose,
        onEnter: (selectedIndex) => {
          if (filteredTemplates[selectedIndex]) {
            onTemplateUsed(filteredTemplates[selectedIndex].text);
          }
        },
      }),
    [filteredTemplates.length, onTemplateSelect, onClose, onTemplateUsed]
  );
  const onKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      const { target } = event;
      if (activeTemplateContainerRef?.current?.contains(target as Node)) {
        // ignore keyboard navigation when filing in template form
        return;
      }
      keyboardNavigate<HTMLDivElement>(event, activeTemplateIndex);
    },
    [keyboardNavigate, activeTemplateIndex]
  );

  const templateSaveSuccessToast = useCallback(() => {
    ToastMessage(t('chat.item.template.saveSuccess'), 'success', {
      autoClose: 1000,
      position: 'bottom-center',
    });
  }, [t]);

  const onPasteIn = useCallback(
    (message: string) => {
      if (editMode) {
        onTemplateSave(activeTemplate, () => {
          templateSaveSuccessToast();
          onTemplateUsed(message);
        });
        return;
      }
      disableEditing();
      onTemplateUsed(message);
    },
    [
      editMode,
      disableEditing,
      onTemplateUsed,
      onTemplateSave,
      activeTemplate,
      templateSaveSuccessToast,
    ]
  );

  const onNewTemplate = () =>
    onTemplateCreate(t('chat.item.template.form.title.default'));

  const getListContent = () => {
    if (loadingProps.loadingList)
      return (
        <Div width="100%" height="100%" display="flex" alignItems="center">
          <Spinner />
        </Div>
      );
    if (templateListError) {
      return (
        <CustomErrorAlert
          error={{
            msg: t('chat.item.template.listLoadError'),
            variant: 'danger',
          }}
        />
      );
    }
    if (filteredTemplates.length) {
      return (
        <TemplateList
          items={filteredTemplates}
          activeTemplateIndex={activeTemplateIndex}
          onTemplateSelect={onTemplateSelect}
        />
      );
    }
    return !templateList.length ? <EmptyList action={onNewTemplate} /> : null;
  };

  return (
    <Modal
      isOpen={isOpen}
      isClosable={false}
      shouldCloseOnOverlayClick
      onClose={onClose}
      contentProps={{ p: 0 }}
      isSmaller
      isVerticallyCentered
      maxWidth="690px"
      size="custom"
      testId="message-template"
    >
      <Div onKeyDown={onKeyDown} role="button" tabIndex={-1} ref={containerRef}>
        <Header
          onClose={onClose}
          onSearchQueryChange={onSearchQueryChange}
          searchQuery={searchQuery}
        />
        <Div width="100%" display="flex" flexDirection="row">
          <Div
            width="45%"
            borderRight={`1px solid ${themeContext.colors.black.black3}`}
            p={3}
            height="330px"
            overflow="auto"
          >
            {getListContent()}
          </Div>
          {activeTemplate && (
            <Div width="55%" ref={activeTemplateContainerRef}>
              <ActiveTemplate
                key={activeTemplate.id}
                template={activeTemplate}
                onChange={onTemplateChange}
                onSave={(template: MessageTemplate) => {
                  onTemplateSave(template, templateSaveSuccessToast);
                }}
                onDelete={onTemplateDelete}
                editMode={editMode}
                enableEditing={enableEditing}
                disableEditing={disableEditing}
                {...loadingProps}
              />
            </Div>
          )}
        </Div>
        <Footer
          onNewTemplate={onNewTemplate}
          onPasteIn={onPasteIn}
          template={activeTemplate}
          actionInProgress={
            loadingProps.loadingAddMessageTemplate ||
            loadingProps.loadingUpdateMessageTemplate ||
            loadingProps.loadingDeleteMessageTemplate
          }
        />
      </Div>
    </Modal>
  );
};
