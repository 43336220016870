export const Paths = {
  Homepage: '/',
  JobsList: '/jobber',
  JobsListItem: '/jobber/:listId',
  JobsItem: '/jobb/:id',
  JobsItemPrint: '/jobb/:id/print',
  JobsItemTitle: '/jobb/:id/:title',
  Messages: '/meldinger',
  MessagesItem: '/meldinger/:chatId',
  MessagesItemName: '/meldinger/:chatId/:name',
  Icons: '/icons',
  Customer: '/kunder',
  CustomerContract: '/kunder/kontrakt',
  CustomerInvoice: '/kunder/faktura',
  CustomerEvaluation: '/kunder/evalueringer',
  Profile: '/profil',
  ProfileEvaluations: '/profil/evalueringer',
  ProfileEmployees: '/profil/ansatte',
  ProfileCompanyInfo: '/profil/firmainfo',
  ProfileGraphic: '/profil/grafisk',
  ProfilePictures: '/profil/bilder',
  ProfileReferences: '/profil/referanser',
  ProfileQualifications: '/profil/kvalifikasjoner',
  SettingsNotifications: '/innstillinger/varslinger',
  SettingsJobs: '/innstillinger/jobbsok',
  SettingsProfile: '/innstillinger/firmainfo',
  SettingsProducts: '/innstillinger/produkter',
  // todo: add translations
  SettingsProductAddons: '/innstillinger/produkter/addons',
  SettingsProductAddon: '/innstillinger/produkter/addons/:slug',
  SettingsProductAddonPurchase:
    '/innstillinger/produkter/addons/:slug/purchase',
  SettingsProductRewards: '/innstillinger/produkter/rewards',
  SettingsPayment: '/innstillinger/kvitteringer',
  SettingsCustomerService: '/innstillinger/kundeservice',
  SettingsReceipts: '/innstillinger/kvitteringer2',
  SettingsPrivacyAndTerms: '/innstillinger/personvern',
  SettingsIntegrations: '/innstillinger/integrasjoner',
  Settings: '/innstillinger',
  Logout: '/logg-ut',
  AccountSwitch: '/bytt-bruker',
} as const;

export const Params = {
  Page: 'page',
  Contract: 'contract',
  Search: 'search',
  Size: 'size',
  Municipality: 'municipality',
  District: 'district',
  County: 'county',
  Folder: 'folder',
  WorkType: 'worktype',
  WorkSubset: 'subset',
  Industry: 'industry',
  WorkSubsetGroup: 'subset-group',
  Job: 'jobb',
  Evaluation: 'evaluation',
  Report: 'report',
  BoostCreditsModal: 'boost-credits-modal',
  BuySubscriptionModal: 'buy-subscription-modal',
  SelectedPackage: 'selected-package',
} as const;
