import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const SelectedIconContainer = styled.span`
  position: absolute;
  z-index: ${Layer.base + Layer.above};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.35);
`;
