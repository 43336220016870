import { ApolloCache } from '@apollo/client';

import { REJECT_ONE_TO_ONE_JOB } from '../../utils/generated/generated';

const rejectOneToOneJobUpdate =
  (jobId: string | number) =>
  (
    cache: ApolloCache<REJECT_ONE_TO_ONE_JOB>,
    { data }: { data?: REJECT_ONE_TO_ONE_JOB | null }
  ) => {
    if (data?.rejectOneToOneJob.success) {
      cache.modify({
        id: cache.identify({
          jobId,
          __typename: 'JobBusinessRelationState',
        }),
        fields: {
          isRejected() {
            return true;
          },
        },
      });
    }
  };

const rejectOneToOneJobOptimisticResponse: REJECT_ONE_TO_ONE_JOB = {
  rejectOneToOneJob: {
    __typename: 'RejectOneToOneJobPayload',
    success: true,
  },
};

export { rejectOneToOneJobUpdate, rejectOneToOneJobOptimisticResponse };
