import styled from 'styled-components';

export const Stars = styled.span`
  display: inline-block;
  width: auto;
  height: auto;
  margin: 0 5px;
  padding: 0 5px;
  text-align: center;
  color: currentColor;
  border-radius: 20px;
  border: none;
  vertical-align: center;
  background-color: ${(props): string => props.theme.background.white};
`;
