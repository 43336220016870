import { useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useIsJobFoldersFeatureEnabled } from '@contexts/UserContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';

export const useFlagExcludedJobLists = () => {
  const excludeBigJobs = !useFlagEnabled(FeatureFlags.BizBigJobList);
  const excludeFolderJobs = !useIsJobFoldersFeatureEnabled();

  return useMemo(
    () => [
      ...(excludeFolderJobs ? [JobListId.Folder] : []),
      ...(excludeBigJobs ? [JobListId.Big] : []),
    ],
    [excludeFolderJobs, excludeBigJobs]
  );
};
