import { ApolloCache, makeReference, Reference } from '@apollo/client';

import { addJobToCachedJobList } from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import JOB_FOLDER_FRAGMENT from '../../utils/fragments/JobFolderFragment';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { AddJobToJobFolderMutationPayload } from '../../utils/mutation/AddJobToJobFolder/AddJobToJobFolderMutation';
import { JobFolder } from '../../utils/query/JobFolders/JobFoldersQuery';

const addJobToJobFolderUpdate =
  (job: JobListOrJobQueryItem, folder: JobFolder) =>
  (
    cache: ApolloCache<AddJobToJobFolderMutationPayload>,
    { data }: { data?: AddJobToJobFolderMutationPayload | null }
  ) => {
    if (data?.addJobToJobFolder) {
      cache.modify({
        id: cache.identify({ id: job.id, __typename: 'Job' }),
        fields: {
          folders(existingFolders, { readField }) {
            const newFolderRef = cache.writeFragment({
              data: folder,
              fragment: JOB_FOLDER_FRAGMENT,
              fragmentName: 'JobFolderFragment',
            });
            if (existingFolders) {
              const compareMethod = (ref: Reference, secondRef: Reference) => {
                const firstFolderId: string | undefined = readField('id', ref);
                const secondFolderId: string | undefined = readField(
                  'id',
                  secondRef
                );
                if (firstFolderId && secondFolderId) {
                  return (
                    parseInt(firstFolderId, 10) - parseInt(secondFolderId, 10)
                  );
                }
                return 0;
              };
              if (
                existingFolders.some(
                  (ref: Reference) => readField('id', ref) === folder.id
                )
              ) {
                return [...existingFolders].sort(compareMethod);
              }
              return [...existingFolders, newFolderRef].sort(compareMethod);
            }
            return [newFolderRef];
          },
        },
      });

      // add job to folders job list
      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            if (JobListId.Folder === existingJobList.listId) {
              return {
                ...existingJobList,
                jobConnection: addJobToCachedJobList(
                  existingJobList.jobConnection,
                  job,
                  readField,
                  false
                ),
              };
            }
            return existingJobList;
          },
        },
      });

      // update folders job list counter in job list filter
      cache.modify({
        id: cache.identify({
          id: JobListId.Folder,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return job.folders && job.folders.length
              ? currentCount
              : currentCount + 1;
          },
        },
      });
    }
  };

const addJobToJobFolderOptimisticResponse: AddJobToJobFolderMutationPayload = {
  addJobToJobFolder: true,
};

export { addJobToJobFolderUpdate, addJobToJobFolderOptimisticResponse };
