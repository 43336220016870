import { FC } from 'react';

import { TriggerSource } from '@components/elements/BoostCreditsModal/constants';
import { useBoostCreditsModalContext } from '@components/elements/BoostCreditsModal/ModalContext';
import { useIsMobile, useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useTotalRemainingCredits } from '@contexts/UserContext';
import { ReactComponent as RippedPaperGraphic } from '@internals/business-shared/src/assets/illustrations/RippedPaperVertical.svg';
import { JobAccess } from '@internals/business-shared/src/utils/query/JobAccess/JobAccessQuery';
import { JobCostInfo } from '@root/src/components/layout/Job/JobCostInfo';
import {
  Button,
  Card,
  Div,
  Heading,
  Spinner,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledBoostAlertBackground } from './styled/StyledBoostAlertBackground';

interface JobBoostCreditsAlertProps {
  clip: JobAccess['clip'];
  isLoading: boolean;
  jobId: string;
}

export const JobBoostCreditsAlert: FC<JobBoostCreditsAlertProps> = ({
  clip,
  isLoading,
  jobId,
}) => {
  const { colors, fontWeights } = useTheme();
  const { loading, handleModalOpen } = useBoostCreditsModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const remainingCredits = useTotalRemainingCredits();
  const isTabletOrMobile = useIsTabletOrMobile();

  const handleClick = () => handleModalOpen(TriggerSource.JOB_CARD, jobId);

  if (isLoading) {
    return <Spinner size={6} my={8} />;
  }

  return (
    <>
      <StyledBoostAlertBackground />
      <Card
        display="flex"
        position="relative"
        boxShadow="m"
        borderRadius={1}
        maxWidth={isTabletOrMobile && isMobile ? '100%' : 600}
        justifySelf={isTabletOrMobile && isMobile ? 'inherit' : 'start'}
        p={0}
        data-testid="job-boost-credits-alert"
      >
        <Div width={30} bg="yellow.light">
          <Div position="absolute" height="100%" left={20} width={20}>
            <RippedPaperGraphic />
          </Div>
        </Div>
        <Div
          p={isTabletOrMobile && !isMobile ? 9 : 7}
          pl={4}
          pr={7}
          width={1}
          display="flex"
          justifyContent="center"
        >
          <Div
            display="flex"
            position="absolute"
            style={{
              transform: 'translate(0, -30%)',
            }}
            top={0}
            right={30}
            justifyContent="center"
            alignItems="center"
            width={70}
            height={24}
            background={colors.blueNavy.base}
            borderRadius={3}
            p={0}
          >
            <Text.p
              mb={0}
              color={colors.black.black0}
              fontWeight={fontWeights.medium}
            >
              {t('general.label.newFeature')}
            </Text.p>
          </Div>
          <Div
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems={isMobile ? 'center' : 'start'}
            width={isMobile ? 'clamp(200px, 70vw, 570px)' : '100%'}
            style={{ alignItems: 'center' }}
          >
            <Div mb={isMobile && 4} mr={!isMobile && 7} flex={1}>
              <Heading.h2 textAlign={isMobile ? 'center' : 'left'}>
                {remainingCredits === 0
                  ? t('job.item.alert.insufficientFunds.title.outOfCredits')
                  : t(
                      'job.item.alert.insufficientFunds.title.notEnoughCredits'
                    )}
              </Heading.h2>
              <JobCostInfo
                cost={clip}
                centeredText={isMobile}
                textMaxWidth={isMobile && '40ch'}
              />
            </Div>
            <Button
              onClick={handleClick}
              isLoading={loading}
              marginTop={isMobile && '10px'}
              data-testid="button-insufficient-funds"
            >
              {t('job.item.alert.insufficientFunds.action')}
            </Button>
          </Div>
        </Div>
      </Card>
    </>
  );
};
