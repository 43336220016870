import { Input } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledSearchInput = styled(Input)`
  padding: 0;

  &,
  &:hover,
  &:focus {
    border: none;
    padding: 0;
    box-shadow: none;
  }
`;
