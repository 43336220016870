import { FC, ReactElement } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { Div, Button, Text, Avatar } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

export interface EvaluationRequestJobListItemProps {
  onClick: VoidFunction;
  jobOwnerName: string;
  jobTitle: string;
  requestText: string;
  requestInProgress?: boolean;
  requestDisabled?: boolean;
  label?: ReactElement;
}

const EvaluationRequestJobListItem: FC<
  React.PropsWithChildren<EvaluationRequestJobListItemProps>
> = ({
  onClick,
  jobOwnerName,
  jobTitle,
  requestInProgress,
  requestText,
  requestDisabled,
  label,
}) => {
  const themeContext = useTheme();
  const isMobile = useIsMobile();

  return (
    <Div display="flex" width="100%" pt={6} minWidth={0}>
      <Div flexShrink={0} ml={6}>
        <Avatar initials={getInitials(jobOwnerName)} size="s" />
      </Div>
      <Div
        display="flex"
        justifyContent="space-between"
        flexWrap={isMobile ? 'wrap' : 'nowrap'}
        flexGrow={1}
        ml={5}
        pr={6}
        pb={6}
        alignItems="center"
        borderBottom={`1px solid ${themeContext.colors.black.black3}`}
        minWidth={0}
      >
        <Div mb={isMobile ? 2 : 0} mr={3} width="100%" minWidth={0}>
          <Div display="flex" alignItems="center" mb={3}>
            <Text.p mb={0}>{jobOwnerName}</Text.p>
          </Div>
          <Text.p fontSize={1} mb={3} truncate>
            {jobTitle}
          </Text.p>
          {label && label}
        </Div>
        <Button
          disabled={!!requestDisabled}
          onClick={onClick}
          variant="secondary"
          isLoading={requestInProgress}
          size={isMobile ? 'small' : 'medium'}
          flexShrink={0}
        >
          {requestText}
        </Button>
      </Div>
    </Div>
  );
};

export default EvaluationRequestJobListItem;
