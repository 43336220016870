import { Row } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ListItemRow = styled(Row)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  // Just pay attention to also change the :after pseudo element width
  padding-right: ${(props): number => props.theme.space[8]}px;
  padding-left: ${(props): number => props.theme.space[8]}px;

  // Just pay attention to also change the :after pseudo element width
  @media screen and (max-width: ${(props) => props.theme.breakpoints[5]}) {
    padding-left: ${(props): number => props.theme.space[6]}px;
    padding-right: ${(props): number => props.theme.space[6]}px;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.black.black1};
  }

  &:after {
    display: block;
    border-bottom: ${(props) => `1px solid ${props.theme.colors.black.black3}`};
    content: '';
    position: absolute;
    bottom: 0;

    //We need to subtract padding size
    width: calc(
      100% - ${(props): string => (2 * props.theme.space[8]).toString()}px
    );
    @media screen and (max-width: ${(props) => props.theme.breakpoints[5]}) {
      width: calc(
        100% - ${(props): string => (2 * props.theme.space[6]).toString()}px
      );
    }
  }
`;

export default ListItemRow;
