import { JobListId, UNANSWERED_JOBS_LIST_IDS } from './constants/jobListIds';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges as JobListEdge } from './generated/generated';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isKnownJobsList = (listId: any): listId is JobListId => {
  return Object.values(JobListId).includes(listId);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isUnansweredJobsList = (listId: any): boolean => {
  return isKnownJobsList(listId) && UNANSWERED_JOBS_LIST_IDS.includes(listId);
};

export const isJobEdge = (item: unknown): item is JobListEdge => {
  if (!!item && typeof item === 'object') {
    if (Object.prototype.hasOwnProperty.call(item, '__typename')) {
      // eslint-disable-next-line
      // @ts-ignore
      return item.__typename === 'JobEdge';
    }
  }
  return false;
};
