import { FC } from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import ScrollPanel from '@components/base/ScrollPanel';
import CollapsingBox from '@components/elements/CollapsingBox';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import JobShortInfo from '@components/elements/JobShortInfo';
import NotFound from '@components/elements/NotFound';
import { EvaluationResponseForm } from '@components/layout/CustomerService/Evaluation/Evaluated/EvaluationResponseForm';
import ChatJobInfoFiles from '@components/layout/Messages/ChatJobInfoFiles';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { AddressFragment } from '@internals/business-shared/src/utils/generated/generated';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { Div, Button, Modal, Text, Spinner } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import { dateFromTimestamp } from '@utils/date';
import getJobItemSearchParam from '@utils/url';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import JobEvaluatedAlert from '../JobEvaluatedAlert';
import { EvaluationModalContent, EvaluationResponseInfo } from '../styled';
import AvatarHeader from './AvatarHeader';
import EvaluationBubble from './EvaluationBubble';
import { EvaluatedViewProps } from './index';

const DesktopView: FC<EvaluatedViewProps> = ({
  evaluation,
  loading,
  onClose,
  onReportEvaluation,
  replyData,
  replyErrors,
  onSubmit,
  loadingReply,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  const attachmentCount = evaluation?.job
    ? (evaluation?.job?.files?.length ?? 0) +
      (evaluation?.job?.images?.length ?? 0)
    : 0;
  return (
    <Modal
      isOpen
      isClosable
      isSmaller
      onClose={onClose}
      header={
        !loading && evaluation ? (
          <AvatarHeader
            mobileView={false}
            name={evaluation?.writerName}
            score={evaluation?.score}
            address={
              evaluation?.job?.address
                ? getAddressString(evaluation.job.address as AddressFragment)
                : ''
            }
          />
        ) : null
      }
      headerProps={{ p: 0, m: 0, display: 'block' }}
      footerProps={{ p: 0 }}
      contentProps={{ p: 0 }}
      size="custom"
      maxWidth="980px"
      verticalSize="custom"
      height="100%"
      testId="evaluation"
    >
      {!loading && !evaluation ? (
        <NotFound
          title={t('profile.evaluations.item.notFoundTitle')}
          text={t('profile.evaluations.item.notFoundText')}
        />
      ) : (
        <Div display="flex" height="100%" flexDirection="row">
          {loading ? (
            <EvaluationModalContent>
              <Spinner />
            </EvaluationModalContent>
          ) : (
            <>
              <EvaluationModalContent>
                <Div
                  display="flex"
                  flexDirection="column"
                  px={6}
                  height="100%"
                  width="100%"
                >
                  <EvaluationBubble
                    message={evaluation?.description}
                    date={
                      evaluation?.publishedTime
                        ? dateFromTimestamp(evaluation?.publishedTime)
                        : ''
                    }
                    avatar={{ initials: getInitials(evaluation?.writerName) }}
                  />
                  {replyData && (
                    <>
                      {replyData.hasLogo ? (
                        <EvaluationBubble
                          message={replyData.reply.description}
                          date={
                            replyData.reply?.publishedTime
                              ? dateFromTimestamp(replyData.reply.publishedTime)
                              : ''
                          }
                          avatar={{
                            src: evaluation.business?.logo?.smallUrl ?? '',
                          }}
                          isReply
                        />
                      ) : (
                        <EvaluationBubble
                          message={replyData.reply.description}
                          date={
                            replyData.reply?.publishedTime
                              ? dateFromTimestamp(replyData.reply.publishedTime)
                              : ''
                          }
                          avatar={{
                            initials: replyData.reply.business.initials,
                          }}
                          isReply
                        />
                      )}
                    </>
                  )}
                  <ErrorAlert errors={replyErrors} />
                </Div>
                {!replyData ? (
                  <Div mx="70px" mb={6}>
                    <>
                      <EvaluationResponseInfo>
                        {t('evaluation.item.evaluated.responseInfo')}
                      </EvaluationResponseInfo>
                      <EvaluationResponseForm
                        onSubmit={onSubmit}
                        loading={loadingReply}
                      />
                    </>
                  </Div>
                ) : (
                  <JobEvaluatedAlert
                    evaluationTimestamp={evaluation?.publishedTime ?? 0}
                    jobId={evaluation?.job.id}
                  />
                )}
              </EvaluationModalContent>
              <ScrollPanel style={{ flex: '1 1 320px', height: '100%' }}>
                <>
                  <CollapsingBox
                    title={t('evaluation.item.sidebar.actions')}
                    withBorderBottom
                    isDefaultOpen
                  >
                    <Div mb={7}>
                      <FeatureFlag name={FeatureFlags.BizEvaluationReport}>
                        <Button
                          type="button"
                          variant="linkTertiary"
                          onClick={onReportEvaluation}
                          p={0}
                          mb={5}
                          display="flex"
                          justifyContent="flex-start"
                        >
                          <Div width="24px" height="24px">
                            <FireIcon
                              iconName={IconPickerName.Report}
                              fill={themeContext.colors.primary.base}
                              variant="flat"
                            />
                          </Div>
                          <Text.span
                            ml="12px"
                            mb={0}
                            fontSize={2}
                            fontWeight={themeContext.fontWeights.medium}
                            color="primary.base"
                          >
                            {t('evaluation.item.sidebar.reportEvaluation')}
                          </Text.span>
                        </Button>
                      </FeatureFlag>
                      <Link to={getJobItemSearchParam(evaluation?.job?.id)}>
                        <Div display="flex" alignItems="center">
                          <Div width="24px" height="24px">
                            <FireIcon
                              iconName={IconPickerName.Invoice}
                              variant="color"
                            />
                          </Div>
                          <Text.span
                            ml="12px"
                            mb={0}
                            fontSize={2}
                            fontWeight={themeContext.fontWeights.medium}
                            color="primary.base"
                          >
                            {t('evaluation.item.sidebar.seeJob')}
                          </Text.span>
                        </Div>
                      </Link>
                    </Div>
                  </CollapsingBox>
                  <CollapsingBox
                    title={t('evaluation.item.sidebar.aboutJob')}
                    withBorderBottom={attachmentCount !== 0}
                  >
                    <JobShortInfo job={evaluation?.job} />
                  </CollapsingBox>
                  {attachmentCount !== 0 && (
                    <CollapsingBox
                      title={t(
                        'evaluation.item.sidebar.toggleSharedFilesButton'
                      )}
                      isDefaultOpen
                      indicatorCount={attachmentCount}
                    >
                      <ChatJobInfoFiles
                        files={evaluation.job.files}
                        images={evaluation.job.images}
                      />
                    </CollapsingBox>
                  )}
                </>
              </ScrollPanel>
            </>
          )}
        </Div>
      )}
    </Modal>
  );
};

export default DesktopView;
