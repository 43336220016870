import { LanguageCode } from '@internals/business-translations/src/languages';

import { INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node } from '../generated/generated';
import getLanguageCode from '../getLanguageCode';
import groupBy from '../groupBy';
import { InvoiceReceiptListQueryPayload } from '../query/InvoiceReceiptList/InvoiceReceiptListQuery';
import rawDateFromTimestamp from '../rawDateFromTimestamp';

export type InvoiceReceipt = {
  id: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node['id'];
  pdfUrl: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node['pdfUrl'];
  pdfDownloadUrl: INVOICE_RECEIPT_LIST_QUERY_invoiceReceiptList_edges_node['pdfDownloadUrl'];
  paidDate: Date;
  dueDate: Date;
  invoiceDate: Date;
  year: string;
  humanInvoiceDate: string;
};

export type GroupedInvoiceReceipt = {
  [key: string]: InvoiceReceipt[];
};

export const GroupInvoiceReceipt = (
  data: InvoiceReceiptListQueryPayload['invoiceReceiptList']['edges'],
  language = 'no'
): GroupedInvoiceReceipt =>
  groupBy<InvoiceReceipt>(
    data
      .map((item) => {
        const dueDate = rawDateFromTimestamp(item.node.dueDate);
        const invoiceDate = rawDateFromTimestamp(item.node.invoiceDate);
        return {
          ...item.node,
          paidDate: rawDateFromTimestamp(item.node.paidDate),
          dueDate,
          invoiceDate,
          year: dueDate.getFullYear().toString(),
          humanInvoiceDate: invoiceDate.toLocaleString(
            getLanguageCode(language as LanguageCode),
            { day: '2-digit', month: 'long' }
          ),
        };
      })
      .sort((a, b) => a.dueDate.getTime() - b.dueDate.getTime()),
    'year'
  );
