// In case of language related changes keep in mind to update: packages/business-translations/src/languages.ts , packages/business-shared/src/utils/getLanguageCode.ts, packages/business-shared/src/utils/dateFromTimestamp.ts

import { CountryName } from '@internals/business-shared/src/utils/address';
import { ProductId } from '@internals/business-shared/src/utils/appProducts';
import { customerServiceAvailability } from '@internals/business-shared/src/utils/constants/customerServiceAvailability';
import log from '@internals/business-shared/src/utils/devLog';
import {
  LANGUAGE_FI,
  LANGUAGE_NB,
  LANGUAGE_DA,
  LANGUAGE_SV,
} from '@internals/business-translations/src/languages';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import getUrlHostname from '@utils/getUrlHostname';
import phoneMetaByggetilbudMatch from '@utils/phoneMetadata/phone-metadata-byggetilbudmatch.json';
import phoneMetaMittanbud from '@utils/phoneMetadata/phone-metadata-mittanbud.json';
import phoneMetaRemppatori from '@utils/phoneMetadata/phone-metadata-remppatori.json';
import phoneMetaServicefinder from '@utils/phoneMetadata/phone-metadata-servicefinder.json';
import { CountryCode, MetadataJson } from 'libphonenumber-js';

export interface Product {
  id: ProductId;
  name: string;
  phoneFormat: CountryCode;
  defaultLanguage: string;
  phoneMetaData: MetadataJson;
  customerServicePhone: string;
  customerServicePhonePrettyString: string;
  customerServiceEmail: string;
  customerServiceContactUrl: string;
  customerServiceAvailability: { start: number; end: number };
  domain: string;
  termsOfServicePublicPath: string;
  privacySettingsPublicPath: string;
  countryName: CountryName; // Used for Google Maps search results
  currency: string;
}

const getProductDomainName = () =>
  getUrlHostname(getEnvValue(ENV_KEY_NAME.ORIGIN_URL));

export const Products: Record<ProductId, Product> = {
  [ProductId.Mittanbud]: {
    id: ProductId.Mittanbud,
    name: 'Mittanbud',
    phoneFormat: 'NO',
    phoneMetaData: phoneMetaMittanbud as unknown as MetadataJson, // generated metadata type is a valid MetadataJson
    defaultLanguage: LANGUAGE_NB,
    customerServicePhone: '+4722000930',
    customerServicePhonePrettyString: '22 00 09 30',
    customerServiceEmail: 'info@mittanbud.no',
    customerServiceContactUrl: 'https://calendly.com/mittanbud/onboarding',
    customerServiceAvailability:
      customerServiceAvailability[ProductId.Mittanbud],
    domain: getProductDomainName(),
    termsOfServicePublicPath: 'om/avtalevilkaar/avtalevilkaar-proff',
    privacySettingsPublicPath: 'personvern',
    countryName: 'Norway',
    currency: 'NOK',
  },
  [ProductId.Remppatori]: {
    id: ProductId.Remppatori,
    name: 'Remppatori',
    phoneFormat: 'FI',
    phoneMetaData: phoneMetaRemppatori as unknown as MetadataJson, // generated metadata type is a valid MetadataJson
    defaultLanguage: LANGUAGE_FI,
    customerServicePhone: '+358931580450',
    customerServicePhonePrettyString: '09 3158 0450',
    customerServiceEmail: 'info@remppatori.fi',
    customerServiceContactUrl:
      'https://calendly.com/remppatori/remppatori-perehdytys',
    customerServiceAvailability:
      customerServiceAvailability[ProductId.Remppatori],
    domain: getProductDomainName(),
    termsOfServicePublicPath: 'meista/sopimusehdot/kayttoehdot-yritys',
    privacySettingsPublicPath: 'yksityisyys',
    countryName: 'Finland',
    currency: 'EUR',
  },
  [ProductId.ByggetilbudMatch]: {
    id: ProductId.ByggetilbudMatch,
    name: '3byggetilbud Match',
    phoneFormat: 'DK',
    phoneMetaData: phoneMetaByggetilbudMatch as unknown as MetadataJson, // generated metadata type is a valid MetadataJson
    defaultLanguage: LANGUAGE_DA,
    customerServicePhone: '+4578726940',
    customerServicePhonePrettyString: '78 72 69 40',
    customerServiceEmail: 'info@match.3byggetilbud.dk',
    customerServiceContactUrl: 'https://calendly.com/mittanbud/onboarding', // TODO DK
    customerServiceAvailability:
      customerServiceAvailability[ProductId.ByggetilbudMatch],
    domain: getProductDomainName(),
    termsOfServicePublicPath: `om/betingelser/aftalevilkaar`,
    privacySettingsPublicPath: 'privat',
    countryName: 'Denmark',
    currency: 'DKK',
  },
  [ProductId.Servicefinder]: {
    id: ProductId.Servicefinder,
    name: 'Servicefinder',
    phoneFormat: 'SE',
    phoneMetaData: phoneMetaServicefinder as unknown as MetadataJson, // generated metadata type is a valid MetadataJson
    defaultLanguage: LANGUAGE_SV,
    customerServicePhone: '+4686530000',
    customerServicePhonePrettyString: '86 53 00 00',
    customerServiceEmail: 'info@servicefinder.se',
    customerServiceContactUrl: 'https://calendly.com/mittanbud/onboarding', // TODO SE
    customerServiceAvailability:
      customerServiceAvailability[ProductId.Servicefinder],
    domain: getProductDomainName(),
    termsOfServicePublicPath: `TODO`, // TODO SE
    privacySettingsPublicPath: 'integritet',
    countryName: 'Sweden',
    currency: 'SEK',
  },
};

export const getProductId = (): ProductId => {
  const productId = getEnvValue(ENV_KEY_NAME.PRODUCT);

  switch (productId) {
    case ProductId.Remppatori:
    case ProductId.Mittanbud:
    case ProductId.ByggetilbudMatch:
    case ProductId.Servicefinder:
      return productId;
    default:
      if (getEnvValue(ENV_KEY_NAME.ENVIRONMENT) !== 'test') {
        log.error(
          `Missing or incorrect REACT_APP_PRODUCT environment variable. Received "${productId}". Setting as 'mittanbud' as default`
        );
      }
      return ProductId.Mittanbud;
  }
};

export const getProduct = (): Product => Products[getProductId()];

export const getProductTermsOfServiceUrl = (): string => {
  const { domain, termsOfServicePublicPath } = getProduct();
  return `https://${domain}/${termsOfServicePublicPath}`;
};

export const getPrivacySettingsUrl = (): string => {
  const { domain, privacySettingsPublicPath } = getProduct();
  return `https://${domain}/${privacySettingsPublicPath}`;
};

export const isRemppatori = (): boolean =>
  getProductId() === ProductId.Remppatori;
export const isMittanbud = (): boolean =>
  getProductId() === ProductId.Mittanbud;
export const isByggetilbudMatch = (): boolean =>
  getProductId() === ProductId.ByggetilbudMatch;
export const isServicefinder = (): boolean =>
  getProductId() === ProductId.Servicefinder;
