import { contractValidationSchema } from '@components/elements/CustomerServiceContract/ContractValidationSchema';
import { EditContractFormShape } from '@internals/business-shared/src/types/FormShape/EditContract';
import { EditSimpleAgreementContractFormikAdapter } from '@internals/business-shared/src/utils/adapters/EditContract';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import { FormikHelpers, FormikProps, useFormik } from 'formik';

type UseEditSimpleContractFormikType = (
  contract: SimpleContract,
  onSubmit: (
    contract: EditContractFormShape,
    resetFormAction: FormikHelpers<EditContractFormShape>['resetForm']
  ) => Promise<void>
) => FormikProps<EditContractFormShape>;

export const useEditSimpleContractFormik: UseEditSimpleContractFormikType = (
  contract,
  onSubmit
) => {
  const formik: FormikProps<EditContractFormShape> = useFormik({
    initialValues: EditSimpleAgreementContractFormikAdapter(contract),
    validationSchema: contractValidationSchema,
    onSubmit: () => onSubmit(formik.values, formik.resetForm),
  });

  return formik;
};
