import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  color: ${(props): string => props.theme.colors.black.black9};
  border-radius: 0;

  outline: none; //disable button style
  box-shadow: none; //disable button style
  border: none;

  &:hover {
    color: ${(props): string => props.theme.colors.black.black9};
    text-decoration: none;
    background-color: ${(props): string => props.theme.colors.black.black2};
    border: none;
    outline: none; //disable button style
    box-shadow: none; //disable button style
  }
  &:focus,
  &:active,
  &:focus-visible {
    border: none;
    outline: none; //disable button style
    box-shadow: none; //disable button style
  }
`;

export default StyledButton;
