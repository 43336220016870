import { useEffect, useState } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { INVOICE_LIST_DEFAULT_COUNT } from '../../cache/constants';
import {
  INVOICE_RECEIPT_LIST_QUERY,
  InvoiceReceiptListData,
  InvoiceReceiptListQueryPayload,
  InvoiceReceiptListQueryVariables,
  isInvoiceReceiptListQuerySuccessResponse,
} from '../../utils/query/InvoiceReceiptList/InvoiceReceiptListQuery';

export type InvoiceListQueryModel = {
  data: InvoiceReceiptListData;
  isLoading: boolean;
};
export type InvoiceListQueryResult = QueryResult & InvoiceListQueryModel;

export const useInvoiceListQuery = (
  options?: QueryHookOptions<
    InvoiceReceiptListQueryPayload,
    InvoiceReceiptListQueryVariables
  >
): InvoiceListQueryResult => {
  const [from, setFrom] = useState(0);
  const [invoiceListModel, setInvoiceListModel] =
    useState<InvoiceListQueryModel>({
      data: [],
      isLoading: true,
    });

  const { data, ...rest } = useQuery<
    InvoiceReceiptListQueryPayload,
    InvoiceReceiptListQueryVariables
  >(INVOICE_RECEIPT_LIST_QUERY, {
    variables: {
      input: {
        from,
        count: INVOICE_LIST_DEFAULT_COUNT,
      },
    },
    ...options,
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    const successResponse = isInvoiceReceiptListQuerySuccessResponse(
      data?.invoiceReceiptList
    );

    if (!successResponse) {
      setInvoiceListModel((prevInvoiceListModel) => ({
        ...prevInvoiceListModel,
        isLoading: false,
      }));
      return;
    }

    const invoicePayload = data.invoiceReceiptList.edges;
    const pageInfoPayload = data.invoiceReceiptList.pageInfo;
    const hasMore = pageInfoPayload.hasNextPage;

    setInvoiceListModel((prevInvoiceListModel) => ({
      data: [...prevInvoiceListModel.data, ...invoicePayload],
      isLoading: hasMore,
    }));

    if (hasMore) {
      setFrom((prevFrom) => prevFrom + INVOICE_LIST_DEFAULT_COUNT);
    }
  }, [data]);

  return {
    ...rest,
    ...invoiceListModel,
  };
};
