import gql from 'graphql-tag';

import {
  JOBS_AREAS_QUERY as JOBS_AREAS_QUERY_Result,
  JOBS_AREAS_QUERY_businessJobAreas,
  JOBS_AREAS_QUERY_businessJobAreas_districts,
  JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable,
  JOBS_AREAS_QUERY_businessJobAreas_municipalities,
  JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable,
  JOBS_AREAS_QUERYVariables,
} from '../../generated/generated';

export type JobsAreasQueryPayload = JOBS_AREAS_QUERY_Result;
export type JobsAreasQueryVariables = JOBS_AREAS_QUERYVariables;
export type JobsAreas = JOBS_AREAS_QUERY_businessJobAreas;
export type District = JOBS_AREAS_QUERY_businessJobAreas_districts;
export type DistrictAvailable =
  JOBS_AREAS_QUERY_businessJobAreas_districtsAvailable;
export type Municipality = JOBS_AREAS_QUERY_businessJobAreas_municipalities;
export type MunicipalityAvailable =
  JOBS_AREAS_QUERY_businessJobAreas_municipalitiesAvailable;

export const JOBS_AREAS_QUERY = gql`
  query JOBS_AREAS_QUERY($id: ID!) {
    businessJobAreas(businessId: $id) {
      id
      districts {
        id
        name
        municipality {
          id
          name
        }
      }
      districtsAvailable {
        id
        name
        municipality {
          id
          name
        }
      }
      municipalities {
        county {
          id
          name
        }
        districts {
          id
          name
        }
        id
        name
        code
      }
      municipalitiesAvailable {
        county {
          id
          name
        }
        districts {
          id
          name
        }
        id
        name
        code
      }
    }
  }
`;
