import { useCallback } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  useApolloClient,
  useMutation,
} from '@apollo/client';

import { clearJobLists } from '../../cache/clearJobLists';
import {
  chooseBusinessWorkTypesOptimisticResponse,
  chooseBusinessWorkTypesUpdate,
} from '../../cache/updates/chooseBusinessWorkTypes';
import JobListId from '../../utils/constants/jobListIds';
import {
  CHOOSE_BUSINESS_WORK_TYPES,
  ChooseBusinessWorkTypesPayload,
  ChooseBusinessWorkTypesVariables,
} from '../../utils/mutation/ChooseBusinessWorkTypes/ChooseBusinessWorkTypes';
import { JOB_LIST_FILTERS_QUERY } from '../../utils/query/JobListFilters/JobListFiltersQuery';
import { JOBS_LIST_QUERY } from '../../utils/query/JobsList/JobsListQuery';

type useChooseBusinessWorkTypesMutationFn = (
  businessId: ChooseBusinessWorkTypesVariables['businessId'],
  activeWorkTypes: ChooseBusinessWorkTypesVariables['workTypeIds'],
  mutationOptions?: MutationFunctionOptions<
    ChooseBusinessWorkTypesPayload,
    ChooseBusinessWorkTypesVariables
  >
) => Promise<FetchResult<ChooseBusinessWorkTypesPayload>>;

export const useChooseBusinessWorkTypesMutation = (
  mutationHookOptions?: MutationHookOptions<
    ChooseBusinessWorkTypesPayload,
    ChooseBusinessWorkTypesVariables
  >
): [
  useChooseBusinessWorkTypesMutationFn,
  MutationResult<ChooseBusinessWorkTypesPayload>
] => {
  const { cache } = useApolloClient();

  const [chooseBusinessWorkTypes, ...rest] = useMutation<
    ChooseBusinessWorkTypesPayload,
    ChooseBusinessWorkTypesVariables
  >(CHOOSE_BUSINESS_WORK_TYPES, {
    onCompleted: () => clearJobLists(cache),
    refetchQueries: [
      { query: JOB_LIST_FILTERS_QUERY },
      {
        query: JOBS_LIST_QUERY,
        variables: { listId: JobListId.Open, skipAnsweredOnlyFields: true },
      },
    ],
    ...mutationHookOptions,
  });

  const mutationFn: useChooseBusinessWorkTypesMutationFn = useCallback(
    (businessId, activeWorkTypes, options) => {
      return chooseBusinessWorkTypes({
        variables: {
          businessId,
          workTypeIds: activeWorkTypes,
        },
        update: chooseBusinessWorkTypesUpdate(businessId),
        optimisticResponse:
          chooseBusinessWorkTypesOptimisticResponse(activeWorkTypes),
        ...options,
      });
    },
    [chooseBusinessWorkTypes]
  );

  return [mutationFn, ...rest];
};
