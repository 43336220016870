import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const BottomSeparator = styled(Div)`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  background: ${(props): string => props.theme.colors.black.black0};
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.2);
`;

export default BottomSeparator;
