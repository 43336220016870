import { ImageFragment } from '@internals/business-shared/src/utils/generated/generated';

export const getImageDimensions = (
  imageUrl: string
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
    img.onerror = () => {
      // fallback, this will make image take full width
      resolve({ width: 0, height: 0 });
    };
    img.src = imageUrl;
  });
};

export const getImageWithDimensions = async (
  image: ImageFragment
): Promise<ImageFragment> => {
  const { dimensions, bigUrl: url } = image;
  if (dimensions.width && dimensions.height) {
    return image;
  }
  const imageDimensions = await getImageDimensions(url);
  return {
    ...image,
    dimensions: { ...image.dimensions, ...imageDimensions },
  };
};
