import gql from 'graphql-tag';

import {
  ANSWER_TEMPLATES_QUERY as ANSWER_TEMPLATES_QUERY_Result,
  ANSWER_TEMPLATES_QUERY_answerTemplates,
} from '../../generated/generated';

export type AnswerTemplatesQueryPayload = ANSWER_TEMPLATES_QUERY_Result;
export type AnswerTemplate = ANSWER_TEMPLATES_QUERY_answerTemplates;

export const ANSWER_TEMPLATES_QUERY = gql`
  query ANSWER_TEMPLATES_QUERY {
    answerTemplates {
      id
      title
      text
    }
  }
`;
