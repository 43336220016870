import { FC, ReactNode } from 'react';

import { BoostCreditsModalProvider } from '@components/elements/BoostCreditsModal/ModalContext';
import { BuySubscriptionModalProvider } from '@components/elements/BuySubscriptionModal/ModalContext';
import { SavedFiltersContextProvider } from '@components/layout/Jobs/SavedFilters/SavedFiltersContext';
import { BoligMappaAuthProvider } from '@contexts/BoligMappaAuthProvider';
import { BoligMappaModalProvider } from '@contexts/BoligMappaModalProvider';
import { PaymentProvider } from '@contexts/PaymentProvider';
import { SavedFiltersFeatureTourContextProvider } from '@internals/business-shared/src/components/SavedFilters/SavedFiltersFeatureTourContext';

interface CommonProvidersProps {
  children: ReactNode;
}

/* Common providers for multiple routes that require router context */
export const CommonProviders: FC<CommonProvidersProps> = ({ children }) => {
  return (
    <BoligMappaAuthProvider>
      <BoligMappaModalProvider>
        <SavedFiltersContextProvider>
          <SavedFiltersFeatureTourContextProvider>
            <BuySubscriptionModalProvider>
              <BoostCreditsModalProvider>
                <PaymentProvider>{children}</PaymentProvider>
              </BoostCreditsModalProvider>
            </BuySubscriptionModalProvider>
          </SavedFiltersFeatureTourContextProvider>
        </SavedFiltersContextProvider>
      </BoligMappaModalProvider>
    </BoligMappaAuthProvider>
  );
};
