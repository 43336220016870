import styled from 'styled-components';

const ColorLabelCircleContainer = styled.div<{
  color: string;
  isActive: boolean;
}>`
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: ${(props) =>
      props.isActive ? props.theme.colors.black.black0 : props.color};
  }
`;

export default ColorLabelCircleContainer;
