import { useCallback, useMemo, useState } from 'react';

import { ApolloError } from '@apollo/client';

import { removeNullable } from '../utils/types/utilsTypes';
import { useCreateAnswerTemplate } from './mutation/useCreateAnswerTemplate';
import { useDeleteAnswerTemplate } from './mutation/useDeleteMessageTemplate';
import { useUpdateAnswerTemplate } from './mutation/useUpdateMessageTemplate';
import { useAnswerTemplatesQuery } from './query/useAnswerTemplatesQuery';
import useBoolean from './useBoolean';
import { useDebouncedInput } from './useDebounce';

export interface MessageTemplate {
  id: string;
  title: string;
  text: string;
  isNew?: boolean;
}

export interface useMessageTemplatesReturnType {
  loadingList: boolean;
  loadingAddMessageTemplate: boolean;
  loadingUpdateMessageTemplate: boolean;
  loadingDeleteMessageTemplate: boolean;
  templateList: MessageTemplate[];
  filteredTemplates: MessageTemplate[];
  templateListError: ApolloError;
  activeTemplate: MessageTemplate;
  activeTemplateIndex: number;
  onTemplateCreate: (defaultTitle: string) => void;
  onTemplateSelect: (selectedTemplateIndex: number) => void;
  onTemplateChange: (template: MessageTemplate) => void;
  onTemplateSave: (template: MessageTemplate, onSuccess?: VoidFunction) => void;
  onTemplateDelete: (
    template: MessageTemplate,
    onSuccess?: VoidFunction
  ) => void;
  onSearchQueryChange: (query: string) => void;
  searchQuery: string;

  editMode: boolean;
  enableEditing: VoidFunction;
  disableEditing: VoidFunction;
}

export const useMessageTemplates = (): useMessageTemplatesReturnType => {
  const {
    debouncedInputValue: debouncedSearchQuery,
    inputValue: searchQuery,
    setInputValue: setSearchQuery,
  } = useDebouncedInput(300);
  const [templateList, setTemplateList] = useState<MessageTemplate[]>([]);
  const [activeTemplateIndex, setActiveTemplateIndex] = useState<
    number | undefined
  >(undefined);
  const [newTemplateIndex, setNewTemplateIndex] = useState<number>(0);

  const [editMode, enableEditing, disableEditing] = useBoolean(false);

  const [addMessageTemplate, { loading: loadingAddMessageTemplate }] =
    useCreateAnswerTemplate();
  const [updateMessageTemplate, { loading: loadingUpdateMessageTemplate }] =
    useUpdateAnswerTemplate();
  const [deleteMessageTemplate, { loading: loadingDeleteMessageTemplate }] =
    useDeleteAnswerTemplate();

  const { loading: loadingList, error: templateListError } =
    useAnswerTemplatesQuery({
      onCompleted: (res) => {
        const payloadTemplates = removeNullable<MessageTemplate>(
          res.answerTemplates
        );
        setTemplateList((prevList) => {
          // prevent duplicates in case the templates are re-fetched
          const newTemplatesOnly = payloadTemplates.filter(
            (template) =>
              !prevList.some((prevTemplate) => prevTemplate.id === template.id)
          );
          return [...prevList, ...newTemplatesOnly];
        });
      },
    });

  const filteredTemplates = useMemo(() => {
    if (!debouncedSearchQuery.trim()) return templateList;
    return templateList.filter(({ title }) =>
      title.toLowerCase().includes(debouncedSearchQuery.toLowerCase())
    );
  }, [templateList, debouncedSearchQuery]);

  const activeTemplate = useMemo(
    () =>
      activeTemplateIndex !== undefined
        ? filteredTemplates[activeTemplateIndex]
        : null,
    [activeTemplateIndex, filteredTemplates]
  );

  const onTemplateCreate = useCallback(
    (defaultTitle: string) => {
      setSearchQuery('');
      const templateNumber = newTemplateIndex + 1;
      const templateId = templateNumber.toString();
      setTemplateList((prevList) => {
        const newTemplate: MessageTemplate = {
          isNew: true,
          id: templateId,
          title: `${defaultTitle} #${templateNumber}`,
          text: '',
        };
        return [...prevList, newTemplate];
      });
      setActiveTemplateIndex(templateList.length);
      setNewTemplateIndex((prevIndex) => prevIndex + 1);
      enableEditing();
    },
    [
      setSearchQuery,
      templateList.length,
      newTemplateIndex,
      setTemplateList,
      setActiveTemplateIndex,
      setActiveTemplateIndex,
    ]
  );

  const onTemplateSelect = useCallback(
    (selectedTemplateIndex: number) => {
      setActiveTemplateIndex(selectedTemplateIndex);
      disableEditing();
    },
    [setActiveTemplateIndex]
  );

  const onTemplateChange = useCallback(
    (changedTemplate: MessageTemplate) => {
      setTemplateList((prevList) => {
        return prevList.map((prevTemplate) =>
          changedTemplate.id !== prevTemplate.id
            ? prevTemplate
            : changedTemplate
        );
      });
    },
    [setTemplateList]
  );

  const onTemplateSave = useCallback(
    (template: MessageTemplate, onSuccess = () => {}) => {
      const { id, title, text, isNew } = template;
      disableEditing();
      if (!isNew) {
        updateMessageTemplate({ id, title, text }, { onCompleted: onSuccess });
      } else {
        addMessageTemplate(
          { title, text },
          {
            onCompleted: (response) => {
              setTemplateList((prevList) =>
                prevList.map((prevTemplate) =>
                  prevTemplate.id === template.id
                    ? {
                        ...prevTemplate,
                        id: response.createAnswerTemplate.template.id,
                        isNew: false,
                      }
                    : prevTemplate
                )
              );
              onSuccess();
            },
          }
        );
      }
    },
    [updateMessageTemplate, addMessageTemplate, setTemplateList]
  );

  const onTemplateDelete = useCallback(
    (template: MessageTemplate, onSuccess = () => {}) => {
      const updateState = () => {
        setTemplateList((prevList) =>
          prevList.filter((prevTemplate) => prevTemplate.id !== template.id)
        );
        setActiveTemplateIndex(undefined);
      };
      if (!template.isNew) {
        deleteMessageTemplate(template.id, {
          onCompleted: () => {
            updateState();
            onSuccess();
          },
        });
      } else {
        updateState();
        onSuccess();
      }
    },
    [setActiveTemplateIndex, setTemplateList, deleteMessageTemplate]
  );

  const onSearchQueryChange = useCallback(
    (query: string) => {
      setActiveTemplateIndex(undefined);
      setSearchQuery(query);
    },
    [setActiveTemplateIndex, setSearchQuery]
  );

  return {
    loadingList,
    loadingAddMessageTemplate,
    loadingUpdateMessageTemplate,
    loadingDeleteMessageTemplate,
    templateList,
    templateListError,
    filteredTemplates,
    activeTemplate,
    activeTemplateIndex,
    onTemplateCreate,
    onTemplateChange,
    onTemplateDelete,
    onTemplateSelect,
    onTemplateSave,
    onSearchQueryChange,
    searchQuery,
    editMode,
    enableEditing,
    disableEditing,
  };
};
