import { FC, useCallback, useEffect } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { BankIdDismissButton } from '@components/elements/BankIdVerification/BankIdDismissButton';
import {
  BankIdIconContainer,
  BankIdText,
  BankIdTitle,
  ButtonContainer,
  CardContainer,
  ContentContainer,
} from '@components/elements/BankIdVerification/styled';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import { useDisplayOnce } from '@hooks/useDisplayOnce';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { STORE_COMPANY_BANK_ID_BANNER_PREFIX } from '@hooks/useStorage';
import { useCompanyStateIsVerifiedWithSecureId } from '@internals/business-shared/src/contexts/CompanyStateContext';
import {
  AuthCallbackUrlStatus,
  useRequestSecureIdAuthenticationUrl,
} from '@internals/business-shared/src/hooks/mutation/useRequestSecureIdAuthenticationUrl';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import log from '@internals/business-shared/src/utils/devLog';
import { Button } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';

interface BankIdVerificationProps {
  separated?: boolean; // If banner is displayed as a standalone Card (ex: jobs page)
}

export const BankIdVerification: FC<BankIdVerificationProps> = ({
  separated,
}) => {
  const { t } = useTranslation();
  const [requestAuth, { loading: requestAuthLoading }] =
    useRequestSecureIdAuthenticationUrl();
  const isCompanyVerifiedWithSecureId = useCompanyStateIsVerifiedWithSecureId();
  const { id: userId, isVerifiedWithSecureId: isUserVerifiedSecureId } =
    useUserContext();

  const [, , { searchParams }] = useSetSearchParams();
  const authStatus = searchParams.get(AuthCallbackUrlStatus.Name);
  const isBannerEnabled = useFlagEnabled(FeatureFlags.BizSecureID);
  const { track } = useAnalytics();
  const { isDisplayed, display, hide } = useDisplayOnce(
    userId,
    STORE_COMPANY_BANK_ID_BANNER_PREFIX
  );

  const dontShowAnything =
    !isBannerEnabled || // Feature Flag
    isCompanyVerifiedWithSecureId || // Company is already verified
    (separated && !isDisplayed); // User already dismissed the banner

  const showUserWarning =
    isUserVerifiedSecureId && !isCompanyVerifiedWithSecureId; // It's when user is verified but company is not, which simply means user is not allowed to verify the company

  const onAuthRequest = useCallback(async () => {
    track(
      ANEventSpace(ANEvent.Clicked, ANObject.Verification, ANPage.SecureID)
    );
    try {
      const response = await requestAuth(
        new URL(window.location.href).toString()
      );

      const { authenticationUrl } =
        response?.data?.requestSecureIdAuthenticationUrl || {};

      if (authenticationUrl) {
        window.open(authenticationUrl, '_self');
      } else {
        bugsnagClient.notify(
          `SecureID authentication URL is not valid: ${authenticationUrl}`
        );
      }
    } catch (e) {
      bugsnagClient.notify(`SecureID authentication failed: ${e as string}`);
    }
  }, [requestAuth, track]);

  useEffect(() => {
    const bankIdToastOptions = {
      autoClose: false,
      position: 'bottom-center',
    } as const;

    if (!authStatus) {
      log.component('No auth status is given');
      return;
    }

    if (authStatus === AuthCallbackUrlStatus.Success) {
      if (isCompanyVerifiedWithSecureId) {
        ToastMessage(
          t('bankID.banner.auth.successMessage'),
          'success',
          bankIdToastOptions
        );
        track(
          ANEventSpace(ANEvent.Success, ANObject.Verification, ANPage.SecureID)
        );
      } else {
        ToastMessage(
          t('bankID.banner.unableToVerifyCompanyMessage'),
          'warning',
          bankIdToastOptions
        );
      }
    }
    if (authStatus === AuthCallbackUrlStatus.Error) {
      ToastMessage(
        t('bankID.banner.auth.errorMessage'),
        'danger',
        bankIdToastOptions
      );
      bugsnagClient.notify('SecureID authentication error');
    }
    if (authStatus === AuthCallbackUrlStatus.Abort) {
      ToastMessage(
        t('bankID.banner.auth.abortMessage'),
        'info',
        bankIdToastOptions
      );
    }
  }, [authStatus, isCompanyVerifiedWithSecureId, t, track]);

  useEffect(() => {
    display();
  }, []);

  if (dontShowAnything) {
    return null;
  }

  if (showUserWarning) {
    return (
      <CardContainer
        boxShadow={separated ? 'l' : 'none'}
        mb={separated ? '5' : '0'}
      >
        {separated && <BankIdDismissButton onDismiss={hide} />}
        <ContentContainer>
          <BankIdTitle>
            <BankIdIconContainer>
              <FireIcon
                iconName={IconPickerName.Attention}
                width={24}
                height={24}
              />
            </BankIdIconContainer>
            {t('bankID.banner.userNotAllowed.title')}
          </BankIdTitle>
          <BankIdText>
            {t('bankID.banner.userNotAllowed.description')}
          </BankIdText>
        </ContentContainer>
      </CardContainer>
    );
  }

  return (
    <CardContainer
      boxShadow={separated ? 'l' : 'none'}
      mb={separated ? '5' : '0'}
    >
      {separated && <BankIdDismissButton onDismiss={hide} />}
      <ContentContainer>
        <BankIdTitle>
          <BankIdIconContainer>
            <FireIcon
              iconName={IconPickerName.ShieldCheck}
              width={24}
              height={24}
            />
          </BankIdIconContainer>
          {t('bankID.banner.title')}
        </BankIdTitle>
        <BankIdText>{t('bankID.banner.description')}</BankIdText>
      </ContentContainer>
      <ButtonContainer>
        <Button
          variant="primary"
          size="medium"
          isLoading={requestAuthLoading}
          onClick={onAuthRequest}
          icon={() => (
            <FireIcon
              iconName={IconPickerName.RandomCodeLines}
              height={24}
              width={24}
              fill="black.black0"
            />
          )}
          iconPosition="left"
        >
          {t('bankID.banner.buttonText')}
        </Button>
      </ButtonContainer>
    </CardContainer>
  );
};
