import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const CollapseButton = styled(Button)<{ isOpen: boolean }>`
  background: none;
  border-color: transparent;
  color: ${(props): string => props.theme.colors.black.black10};
  width: 100%;
  border-radius: 0;
  outline: none; //disable button style
  box-shadow: none; //disable button style

  &:hover,
  &:active,
  &:focus-within {
    background: none;
    border-color: transparent;
    color: ${(props): string => props.theme.colors.black.black10};
  }

  svg {
    transform: rotate(270deg);
    transition: transform 0.2s;
    ${(props) =>
      props.isOpen &&
      `
  transform: rotate(360deg);`}
  }
`;

export default CollapseButton;
