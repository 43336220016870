import gql from 'graphql-tag';

const META_CAR_FRAGMENT = gql`
  fragment MetaCarFragment on Car {
    id
    regNr
    info {
      VIMNumber
      brand
      licencePlate
      registrationYear
      model
      lastRoadWorthinessTestDate
      nextRoadWorthinessTestDate
      shaftCount
      width
      co2Emissions
      fuelType
      weight
      factoryInstalledParticleFilter
      vehicleGroup
      length
      towbarMaxLoad
      maxRoofLoad
      engineHectowatt
      maxSeats
      engineSize
      trailerMaxWeightWithBreak
      trailerMaxWeightWithoutBreak
      maxTotalWeight
      taxCode
      hybrid
      hybridCatagory
      seatsFront
      __typename
    }
    __typename
  }
`;

export default META_CAR_FRAGMENT;
