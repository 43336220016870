import { JobToEvaluate } from './query/JobToEvaluateList/JobToEvaluateListQuery';

export enum EvaluationRequestStatus {
  None = 1,
  Sent = 2,
  Completed = 3,
}

export const getEvaluationRequestStatus = (
  job: Pick<JobToEvaluate, 'jobBusinessRelationState' | 'evaluation'>
): EvaluationRequestStatus => {
  if (job.evaluation) {
    return EvaluationRequestStatus.Completed;
  }
  return job.jobBusinessRelationState.hasRequestedEvaluation
    ? EvaluationRequestStatus.Sent
    : EvaluationRequestStatus.None;
};
