import { Div, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface MessageFileWrapperProps extends DivProps {
  isLast: boolean;
  businessMessage?: boolean;
}

const MessageFileWrapper = styled(Div)<MessageFileWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-top-left-radius: ${({ businessMessage }): string =>
    businessMessage ? '15px' : '0'};
  border-top-right-radius: ${({ businessMessage }): string =>
    businessMessage ? '0' : '15px'};
  border-bottom-left-radius: ${({ isLast, businessMessage }): string =>
    !businessMessage && !isLast ? '0' : '15px'};
  border-bottom-right-radius: ${({ isLast, businessMessage }): string =>
    businessMessage && !isLast ? '0' : '15px'};
  background-color: ${({ theme }): string => theme.colors.black.black1};
  border: ${({ theme }): string => `1px solid ${theme.colors.black.black4}`};
  height: 40px;
  max-width: 425px;
  margin-bottom: ${({ theme }): number => theme.space[2]}px;
  padding: ${({ theme }): string => `${theme.space[4]}px ${theme.space[5]}px`};
  position: relative;
  overflow: hidden;
`;

export default MessageFileWrapper;
