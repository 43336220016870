import { FC, useState } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import log from '@internals/business-shared/src/utils/devLog';
import { Button, ButtonLink } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

import LanguagePicker from './elements/LanguagePicker';
import Login from './Login';

interface DevBorderProps {
  onClick: () => void;
  isMobile: boolean;
}

const DevContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: ${Layer.toast};

  @media print {
    display: none;
  }
`;

const DevBorder = styled.div<DevBorderProps>`
  width: ${(props): string => (props.isMobile ? '30px' : '100%')};
  height: ${(props): string => (props.isMobile ? '30px' : '10px')};
  margin: ${(props): string => (props.isMobile ? '0px auto 0px auto' : '0px')};
  background: repeating-linear-gradient(
    45deg,
    #000000,
    #000000 10px,
    #f0b000 10px,
    #f0b000 20px
  );
  opacity: 0.5;

  &:hover {
    cursor: pointer;
  }
`;

interface DevContentProps {
  visible: boolean;
  isMobile: boolean;
}

const DevContent = styled.div<DevContentProps>`
  width: 100%;
  background-color: #f0b000;
  display: ${(props): string => (props.visible ? 'grid' : 'none')};
  color: #000000;
  font-size: 12px;
  padding: 6px 10px;
  grid-template-columns: ${(props): string =>
    props.isMobile ? '1fr 1fr' : '100px 100px 100px 100px 1fr'};
  column-gap: 10px;
  align-items: start;
`;

const TriggerError: FC = () => {
  const generateError = (): void => {
    // eslint-disable-next-line no-alert
    const message = prompt('Enter error message');

    if (message) {
      log.error(message);
      bugsnagClient.notify(new Error(message));
    }
  };

  return (
    <div>
      <h5>Trigger error</h5>
      <Button onClick={generateError} size="tiny">
        Make error
      </Button>
    </div>
  );
};

const BundleReport = () => (
  <div>
    <h5>Bundle Analyzer</h5>
    <ButtonLink href="/report.html" size="tiny">
      Show report
    </ButtonLink>
  </div>
);

const DevPanel: FC = () => {
  const [open, setOpen] = useState(false);
  const isMobileScreen = useIsMobile();

  const handleClick = (): void => {
    setOpen((prevState) => !prevState);
  };

  return (
    <DevContainer>
      <DevBorder
        onClick={(): void => handleClick()}
        data-testid="dev-panel"
        isMobile={isMobileScreen}
      />
      <DevContent visible={open} isMobile={isMobileScreen}>
        <div>
          <h5>Set language</h5>
          <LanguagePicker />
        </div>
        <Login />
        <TriggerError />
        <BundleReport />
      </DevContent>
    </DevContainer>
  );
};

/* This is some stuff made for testing/proof of concept, just storing it here for now

  const [isApp, setIsApp] = useState(false)
  AppInfoRoute.Disabled = isApp

  const toggleIsApp: () => void = () => {
    setIsApp(!isApp)
  }
    const paths: string[] = Routes.compile()
    .map((props) => props.path)
    .filter((path) => !isEmpty(path)) as string[]1
  */

export default DevPanel;
