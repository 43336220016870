import { FC, memo } from 'react';

import { Gallery, GalleryImageItem } from '@components/elements/Gallery';
import { ImageFragment } from '@internals/business-shared/src/utils/generated/generated';
import { Div } from '@schibsted-smb/fireball';

interface JobImagesProps {
  images: ImageFragment[];
  onClick?: (file: ImageFragment) => void;
}

const JobImagesForMemo: FC<JobImagesProps> = ({ images, onClick }) => {
  if (!images) return null;
  return (
    <Div display="row">
      <Gallery grid>
        {images.map((image) => (
          <GalleryImageItem
            key={image.id}
            item={image}
            imageProps={{ height: '100%', width: '100%' }}
            onClick={() => onClick && onClick(image)}
          />
        ))}
      </Gallery>
    </Div>
  );
};

export const JobImages = memo(JobImagesForMemo);
