import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  SIMPLE_CONTRACT_QUERY,
  SimpleContract,
  SimpleContractQueryPayload,
  SimpleContractQueryVariables,
} from '../../utils/query/SimpleContract/SimpleContractQuery';

export interface SimpleContractQueryResult extends QueryResult {
  data: SimpleContract | null;
}

export const isSimpleContractType = (
  contract?: SimpleContract | null
): contract is SimpleContract =>
  !!contract && contract.__typename === 'SimpleAgreementContract';
export const useSimpleContractQuery = (
  id: string,
  options?: QueryHookOptions<
    SimpleContractQueryPayload,
    SimpleContractQueryVariables
  >
): SimpleContractQueryResult => {
  const { data, ...rest } = useQuery<
    SimpleContractQueryPayload,
    SimpleContractQueryVariables
  >(SIMPLE_CONTRACT_QUERY, {
    variables: {
      input: {
        id,
      },
    },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const result = useMemo(() => {
    return {
      data: data ? data.simpleAgreementContract : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
