import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import { FolderDropdownItemContainer } from './FolderDropdownItemContainer';

export const EditFolderButton = styled(Button)`
  position: absolute;
  top: 50%;
  right: 23px;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  background: ${(props) => props.theme.colors.black.black0};
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%);

  ${FolderDropdownItemContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
`;

export default EditFolderButton;
