import _i18n, {
  InitOptions,
  LanguageDetectorAsyncModule,
  LanguageDetectorModule,
  Resource,
  TFunction,
  ThirdPartyModule,
} from 'i18next';

import { LANGUAGE_EN, supportedLanguages } from './languages';

export const FALLBACK_LANGUAGE_CODE = LANGUAGE_EN;

const resources: Resource = supportedLanguages.reduce((acc, item) => {
  acc[item.languageCode] = {
    translation: item.translation,
  };
  return acc;
}, {});

export const initI18n = (
  i18nInstance: ThirdPartyModule,
  languageDetector: LanguageDetectorAsyncModule | LanguageDetectorModule,
  initOptions: InitOptions = {}
) => {
  return _i18n
    .use(i18nInstance)
    .use(languageDetector)
    .init({
      resources,
      fallbackLng: FALLBACK_LANGUAGE_CODE,
      ...initOptions,
    });
};

export const i18n = _i18n;
export const t: TFunction = (key, options?) => i18n.t(key, options);
