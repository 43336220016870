import HouseFrame from '@internals/business-shared/src/assets/images/house-frame.png';
import House from '@internals/business-shared/src/assets/images/house.png';
import Planks from '@internals/business-shared/src/assets/images/planks.png';
import { SelfServiceSubscriptionName } from '@internals/business-shared/src/utils/generated/generated';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface PackageCardConfProps {
  backgroundColor: string;
  description: JSX.Element;
  imageSrc: string;
  imageWidth: string;
  title: string;
  mostPopular?: boolean;
}

export const usePackageCardConf = (
  slug: SelfServiceSubscriptionName
): PackageCardConfProps => {
  const { colors } = useTheme();
  const { t } = useTranslation();

  const conf: Partial<
    Record<SelfServiceSubscriptionName, PackageCardConfProps>
  > = {
    [SelfServiceSubscriptionName.SUBSCRIPTION_SMALL]: {
      backgroundColor: colors.blue.light,
      description: (
        <Trans i18nKey="job.modal.subscription.packageCard.small.description" />
      ),
      imageSrc: Planks,
      imageWidth: '130px',
      title: t('job.modal.subscription.packageCard.small.title'),
    },
    [SelfServiceSubscriptionName.SUBSCRIPTION_MEDIUM]: {
      backgroundColor: colors.beige.dark,
      description: (
        <Trans i18nKey="job.modal.subscription.packageCard.medium.description" />
      ),
      imageSrc: HouseFrame,
      imageWidth: '120px',
      title: t('job.modal.subscription.packageCard.medium.title'),
      mostPopular: true,
    },
    [SelfServiceSubscriptionName.SUBSCRIPTION_BIG]: {
      backgroundColor: colors.yellow.light,
      description: (
        <Trans i18nKey="job.modal.subscription.packageCard.large.description" />
      ),
      imageSrc: House,
      imageWidth: '170px',
      title: t('job.modal.subscription.packageCard.large.title'),
    },
  };

  return conf[slug] ?? null;
};
