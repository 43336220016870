import {
  LANGUAGE_DA,
  LANGUAGE_EN,
  LANGUAGE_FI,
  LANGUAGE_NB,
  LANGUAGE_SV,
  LanguageCode,
} from '@internals/business-translations/src/languages';
import dayjs from 'dayjs';

import 'dayjs/locale/en'; // import english localization
import 'dayjs/locale/nb'; // import norwegian localization
import 'dayjs/locale/fi'; // import finnish localization
import 'dayjs/locale/da'; // import danish localization
import 'dayjs/locale/sv'; // import danish localization
import rawDateFromTimestamp from './rawDateFromTimestamp';
import { isEmptyTrimmedString } from './types/StringUtils';

interface DateLocales {
  today: string;
  yesterday: string;
}
type TimePeriod = 'today' | 'yesterday' | 'thisWeek' | 'thisYear' | 'other';

const dateLocales: Record<LanguageCode, DateLocales> = {
  [LANGUAGE_EN]: {
    today: 'today',
    yesterday: 'yesterday',
  },
  [LANGUAGE_FI]: {
    today: 'tänään',
    yesterday: 'eilen',
  },
  [LANGUAGE_NB]: {
    today: 'i dag',
    yesterday: 'i går',
  },
  [LANGUAGE_DA]: {
    today: 'i dag',
    yesterday: 'i går',
  },
  [LANGUAGE_SV]: {
    today: 'idag',
    yesterday: 'igår',
  },
};

const timeFormatConfig: Record<LanguageCode, Record<TimePeriod, string>> = {
  [LANGUAGE_EN]: {
    today: 'HH:mm',
    yesterday: 'HH:mm',
    thisWeek: 'ddd, HH:mm',
    thisYear: 'DD. MMM, HH:mm',
    other: 'DD. MMM YYYY, HH:mm',
  },
  [LANGUAGE_FI]: {
    today: 'HH:mm',
    yesterday: 'HH:mm',
    thisWeek: 'ddd, HH:mm',
    thisYear: 'DD. MMM, HH:mm',
    other: 'DD.MM.YYYY, HH:mm',
  },
  [LANGUAGE_NB]: {
    today: 'HH:mm',
    yesterday: 'HH:mm',
    thisWeek: 'ddd, HH:mm',
    thisYear: 'DD. MMM, HH:mm',
    other: 'DD. MMM YYYY, HH:mm',
  },
  [LANGUAGE_DA]: {
    today: 'HH:mm',
    yesterday: 'HH:mm',
    thisWeek: 'ddd, HH:mm',
    thisYear: 'DD. MMM, HH:mm',
    other: 'DD. MMM YYYY, HH:mm',
  },
  [LANGUAGE_SV]: {
    today: 'HH:mm',
    yesterday: 'HH:mm',
    thisWeek: 'ddd, HH:mm',
    thisYear: 'DD. MMM, HH:mm',
    other: 'DD. MMM YYYY, HH:mm',
  },
};
// Temporary config for new UI
const timeFormatConfigNewUI: Record<
  LanguageCode,
  Record<TimePeriod, string>
> = {
  [LANGUAGE_EN]: {
    today: 'HH:mm',
    yesterday: ' ',
    thisWeek: 'ddd',
    thisYear: 'DD. MMM',
    other: 'DD. MMM YYYY',
  },
  [LANGUAGE_FI]: {
    today: 'HH:mm',
    yesterday: ' ',
    thisWeek: 'ddd',
    thisYear: 'DD. MMM',
    other: 'DD.MM.YYYY',
  },
  [LANGUAGE_NB]: {
    today: 'HH:mm',
    yesterday: ' ',
    thisWeek: 'ddd',
    thisYear: 'DD. MMM',
    other: 'DD. MMM YYYY',
  },
  [LANGUAGE_DA]: {
    today: 'HH:mm',
    yesterday: ' ',
    thisWeek: 'ddd',
    thisYear: 'DD. MMM',
    other: 'DD. MMM YYYY',
  },
  [LANGUAGE_SV]: {
    today: 'HH:mm',
    yesterday: ' ',
    thisWeek: 'ddd',
    thisYear: 'DD. MMM',
    other: 'DD. MMM YYYY',
  },
};

const dateFromTimestamp = (
  language: string,
  timestamp: number,
  format?: string,
  locales?: DateLocales,
  newUI = false
): string => {
  const timestampDate = dayjs(rawDateFromTimestamp(timestamp));
  const localizedTimestampDate = timestampDate.locale(language);

  if (format) {
    // if format is given, then simply use it and quit
    return localizedTimestampDate.format(format);
  }

  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  // Treat English as default fallback language
  const timeFormat = newUI
    ? timeFormatConfigNewUI[language] ?? timeFormatConfigNewUI[LANGUAGE_EN]
    : timeFormatConfig[language] ?? timeFormatConfig[LANGUAGE_EN];
  const locale = locales || dateLocales[language];
  const { today: todayString, yesterday: yesterdayString } = locale;

  const differenceDays = today.diff(timestampDate, 'day');
  const isToday = today.isSame(timestampDate, 'day');
  const isYesterday = yesterday.isSame(timestampDate, 'day');
  const isThisWeek = differenceDays < 7;
  const isThisYear = today.isSame(timestampDate, 'year');

  if (isToday) {
    const time = localizedTimestampDate.format(timeFormat.today);
    const separator =
      isEmptyTrimmedString(todayString) || isEmptyTrimmedString(time)
        ? ''
        : ', ';
    return `${todayString}${separator}${time}`;
  }
  if (isYesterday) {
    const time = localizedTimestampDate.format(timeFormat.yesterday);
    const separator =
      isEmptyTrimmedString(yesterdayString) || isEmptyTrimmedString(time)
        ? ''
        : ', ';

    return `${yesterdayString}${separator}${time}`;
  }
  if (isThisWeek) {
    return localizedTimestampDate.format(timeFormat.thisWeek);
  }
  if (isThisYear) {
    return localizedTimestampDate.format(timeFormat.thisYear);
  }
  return localizedTimestampDate.format(timeFormat.other);
};

export default dateFromTimestamp;
