import gql from 'graphql-tag';

import JOB_ACCESS_FRAGMENT from '../../fragments/JobAccessFragment';
import {
  JOB_ACCESS_QUERY as JOB_ACCESS_QUERY_Result,
  JOB_ACCESS_QUERYVariables,
  JOB_ACCESS_QUERY_jobAccess_JobAccessPayload,
  JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access_missingCertificates,
  JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access,
} from '../../generated/generated';

export type JobAccessQueryPayload = JOB_ACCESS_QUERY_Result;
export type JobAccessQueryVariables = JOB_ACCESS_QUERYVariables;
export type JobAccess = JOB_ACCESS_QUERY_jobAccess_JobAccessPayload['access'];
export type JobAccessData = JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access;
export type JobAccessMissingCertificate =
  JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access_missingCertificates;

export const isJobAccessPayload = (
  job?: JOB_ACCESS_QUERY_Result['jobAccess']
): job is JOB_ACCESS_QUERY_jobAccess_JobAccessPayload =>
  !!(job && job.__typename === 'JobAccessPayload');

export const JOB_ACCESS_QUERY = gql`
  ${JOB_ACCESS_FRAGMENT}
  query JOB_ACCESS_QUERY($id: ID!) {
    jobAccess(jobId: $id) {
      ... on JobAccessPayload {
        access {
          ...JobAccessFragment
        }
      }
      ... on JobAccessInputError {
        message
      }
    }
  }
`;
