import { JobsSavedFilter } from '../../utils/fragments/JobsSavedFilterFragment';
import { UpdateJobsSavedFilterMutationPayload } from '../../utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';

/*
 * Note: update is done automatically by apollo
 */

export const updateJobsSavedFilterOptimisticResponse = (
  updatedFilter: JobsSavedFilter
): UpdateJobsSavedFilterMutationPayload => ({
  updateJobWatchlist: {
    __typename: 'JobWatchlist',
    ...updatedFilter,
  },
});
