import {
  ContractType,
  SimpleAgreementContractStatus,
} from '../../../../utils/generated/generated';
import { SimpleContract } from '../../../../utils/query/SimpleContract/SimpleContractQuery';

const simpleAgreementFlagLabel = (
  flags: SimpleContract['statusFlags']
): string => {
  if (flags.rejectedConsumer === true) {
    return 'simple-stopped-rejectedConsumer';
  }
  if (flags.rejectedBusiness === true) {
    return 'simple-stopped-rejectedBusiness';
  }
  if (flags.invalidated === true) {
    return 'simple-stopped-invalidated';
  }
  return 'unknown';
};

export const contractStatusLabel = (item: SimpleContract): string => {
  if (item.type !== ContractType.SIMPLE_AGREEMENT) {
    return 'unknown';
  }

  switch (item.statusEnum) {
    case SimpleAgreementContractStatus.CREATED:
      return 'simple-created';
    case SimpleAgreementContractStatus.SHARED:
      return 'simple-shared';
    case SimpleAgreementContractStatus.SIGNED_CONSUMER:
      return 'simple-signedOfferor';
    case SimpleAgreementContractStatus.SIGNED_BUSINESS:
      return 'simple-signedOfferee';
    case SimpleAgreementContractStatus.SIGNED_BOTH:
      return 'simple-signedBoth';
    case SimpleAgreementContractStatus.STOPPED:
      return simpleAgreementFlagLabel(item.statusFlags);
    case SimpleAgreementContractStatus.SEE_FLAGS:
      return 'unknown'; // We don't have any idea what to show here, but we to avoid showing the translation key

    default:
      return 'unknown';
  }
};
