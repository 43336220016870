import { FC, ReactElement } from 'react';

import Header from '@components/layout/Header';
import { Outlet } from 'react-router-dom';

interface LayoutProps {
  children?: ReactElement | ReactElement[];
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Header />
      <main>
        {children}
        <Outlet />
      </main>
    </>
  );
};
