import { createElement, FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  ActionA,
  ActionLink,
} from '@components/elements/CompanyStateAlert/styled/ActionLink';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Button, ButtonProps, Div, Text } from '@schibsted-smb/fireball';
import {
  ActionButtonProps,
  ActionLinkProps,
  ActionType,
} from '@utils/companyState/useCompanyStateAction';

const ICON_SIZE = 16;

const CompanyStateActionButton: FC<ActionButtonProps> = ({
  onClick,
  text,
  icon,
  isLoading,
  variant,
  disabled,
}) => {
  const isMobile = useIsMobile();
  const mobileProps: ButtonProps = {
    variant: 'linkTertiary',
    p: 0,
    pb: 4,
    icon: undefined,
  };

  return (
    <Button
      onClick={onClick}
      isLoading={isLoading}
      variant={variant}
      disabled={disabled}
      {...(icon && {
        icon: ({ fill }) => (
          <FireIcon
            width={ICON_SIZE}
            fill={fill}
            height={ICON_SIZE}
            iconName={icon}
          />
        ),
      })}
      {...(isMobile && mobileProps)}
    >
      {text}
    </Button>
  );
};

const CompanyStateActionLink: FC<ActionLinkProps> = ({
  isExternal,
  link,
  text,
  icon,
}) => {
  const isMobile = useIsMobile();
  const showIcon = !isMobile && icon;
  const children = (
    <Div display="flex" alignItems="center">
      {showIcon && (
        <FireIcon
          iconName={icon}
          fill="currentColor"
          height={ICON_SIZE}
          width={ICON_SIZE}
        />
      )}
      <Text.span mb={0} ml={showIcon ? 2 : 0} fontWeight="medium">
        {text}
      </Text.span>
    </Div>
  );
  return isExternal ? (
    <ActionA
      href={link}
      rel="noopener noreferrer"
      data-testid="company-state-alert-action"
    >
      {children}
    </ActionA>
  ) : (
    <ActionLink to={link}>{children}</ActionLink>
  );
};

export const CompanyStateAction: FC<ActionType> = (props) => {
  const { type, props: actionProps } = props;
  if (type === 'link') {
    return createElement(CompanyStateActionLink, actionProps);
  }
  return createElement(CompanyStateActionButton, actionProps);
};
