import { GQLOpeningHours } from '../interfaces/graphql/OpeningHours.interface';
import { OpeningHours } from './OpeningHours.interface';

const OpeningHours2GQLOpeningHours = (
  openingHours: OpeningHours[]
): GQLOpeningHours => {
  type FromTo = Pick<OpeningHours, 'from' | 'to'>;

  const openingHourFor = (dayNumber: OpeningHours['dayNumber']): FromTo => {
    const dayOpeningHour = openingHours.find(
      (oh) => oh.dayNumber === dayNumber
    );
    return dayOpeningHour
      ? { from: dayOpeningHour.from, to: dayOpeningHour.to }
      : { from: '', to: '' };
  };

  const checkDayOpeningHoursExists = (dayOpeningHours: FromTo) =>
    dayOpeningHours.from && dayOpeningHours.to ? dayOpeningHours : null;

  return {
    monday: checkDayOpeningHoursExists(openingHourFor(0)),
    tuesday: checkDayOpeningHoursExists(openingHourFor(1)),
    wednesday: checkDayOpeningHoursExists(openingHourFor(2)),
    thursday: checkDayOpeningHoursExists(openingHourFor(3)),
    friday: checkDayOpeningHoursExists(openingHourFor(4)),
    saturday: checkDayOpeningHoursExists(openingHourFor(5)),
    sunday: checkDayOpeningHoursExists(openingHourFor(6)),
  };
};

export default OpeningHours2GQLOpeningHours;
