import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledMobileTemplateListItem = styled(Button)`
  min-height: 66px;
  width: 100%;
  padding: ${({ theme }) => `${theme.space[5]}px 0`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black.black3}`};
  text-align: left;

  &,
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    text-decoration: none;
  }
`;
