import { FC, MutableRefObject, useMemo, useRef } from 'react';

import Badge from '@components/base/Badge';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import JobListId from '@internals/business-shared/src/utils/constants/jobListIds';
import Paths from '@router/paths';
import { Div, Text } from '@schibsted-smb/fireball';
import { PREDEFINED_JOB_SEARCH } from '@utils/jobList';
import { getUrlSearchQueryParams, getUrlWithFilter } from '@utils/url';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';

import CountText from './styled/CountText';
import ItemLabel from './styled/ItemLabel';
import LinkWrapper from './styled/LinkWrapper';
import ListItem from './styled/ListItem';

interface NavigationModel {
  link: string;
  label: string;
  count?: number;
  tag?: string;
  analyticsObject?: ANObject;
  testId?: string;
}

interface NavItemProps {
  item: NavigationModel;
  isLast: boolean;
  isDefaultActive?: boolean;
  isMobileGray?: boolean;
  countAsBadge?: boolean;
  countOnActive?: boolean;
  isSmaller?: boolean;
}

const NavItemTag: FC<{ tag: string }> = ({ tag }) => {
  return (
    <Div backgroundColor="primary.base" mx={4} borderRadius={2} px={2} py={1}>
      <Text.p m={0} fontSize={1} fontWeight="medium" color="black.black0">
        {tag}
      </Text.p>
    </Div>
  );
};

const NavItem: FC<NavItemProps> = ({
  item,
  isLast,
  isDefaultActive = false,
  isMobileGray,
  countAsBadge,
  countOnActive,
  isSmaller,
}) => {
  const themeContext = useTheme();
  const { track } = useAnalytics();
  const itemLabelRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const matchMedia = window.matchMedia(
    `(min-width: ${themeContext.breakpoints[4]})`
  );
  const couldIconBeActive =
    (!matchMedia.matches && !isMobileGray) || matchMedia.matches;
  const location = useLocation();
  const onLinkClick = (analyticsObject?: ANObject) => {
    if (analyticsObject) {
      track(ANEventSpace(ANEvent.Clicked, item.analyticsObject, ANPage.Navbar));
    }
  };
  const isActive = useMemo(() => {
    const { search, pathname } = location;
    const searchParams = search ? getUrlSearchQueryParams(search) : {};
    // ignore page param when determining active search params
    delete searchParams.page;
    // compare active search params with params in nav link
    const filterUrl = getUrlWithFilter(pathname, searchParams);
    if (PREDEFINED_JOB_SEARCH.includes(filterUrl)) {
      return item.link === filterUrl;
    }
    // compare active pathname with nav link pathname
    return isDefaultActive || pathname === item.link;
  }, [location, item.link, isDefaultActive]);

  return (
    <ListItem isLast={isLast}>
      <LinkWrapper
        to={item.link ? item.link : '#'}
        data-testid={item.testId ?? ''}
        className={isActive ? 'is-active' : ''}
        onClick={() => onLinkClick(item?.analyticsObject)}
      >
        <Div
          display="flex"
          alignItems="center"
          height="100%"
          pt="1px"
          width="100%"
          mx={4}
        >
          <ItemLabel ref={itemLabelRef} minHeight={isSmaller ? '36px' : '48px'}>
            <Div
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text.p
                mb={0}
                width="100%"
                fontSize={2}
                fontWeight={isActive ? 'semibold' : 'regular'}
              >
                {item.label}
              </Text.p>
              {item.tag && <NavItemTag tag={item.tag} />}
            </Div>
            <Div display="flex" alignItems="center" height="100%">
              {(item.count || item.count === 0) && countAsBadge ? (
                <Badge text={item.count.toString()} />
              ) : (
                <CountText
                  data-testid="job-list-count"
                  isHidden={!!countOnActive && !isActive}
                  mb={0}
                  color={
                    couldIconBeActive && isActive
                      ? 'primary.dark'
                      : 'black.black7'
                  }
                  fontSize={1}
                  fontWeight={isActive ? 'semibold' : 'regular'}
                >
                  {item.count}
                </CountText>
              )}
            </Div>
          </ItemLabel>
        </Div>
      </LinkWrapper>
    </ListItem>
  );
};

export default NavItem;
