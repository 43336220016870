import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const DropdownItemButton = styled(Button)<{ iconSize?: string }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  color: ${(props): string => props.theme.colors.black.black9};
  &:disabled {
    background-color: transparent;
  }
  svg {
    width: ${(props): string => (props.iconSize ? props.iconSize : '16px')};
    height: ${(props): string => (props.iconSize ? props.iconSize : '16px')};
  }

  &:hover {
    border-color: transparent;
    color: ${(props): string => props.theme.colors.black.black9};
    background: none;
  }
`;

export default DropdownItemButton;
