import { FC } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import Select, {
  Props,
  StylesConfig,
  ControlProps,
  components,
} from 'react-select';
import { useTheme } from 'styled-components';

const SmallLabel: FC<{ label: string }> = ({ label }) => {
  const themeContext = useTheme();
  return (
    <Div minWidth="100%" display="flex" justifyContent="start" mt={2} ml={4}>
      <Text.p
        fontSize={themeContext.fontSizes[0]}
        fontWeight={themeContext.fontWeights.medium}
        textAlign="center"
        mb={0}
        color={themeContext.colors.black.black8}
      >
        {label}
      </Text.p>
    </Div>
  );
};

const ControlComponent = ({ children, ...controlProps }: ControlProps) => {
  const placeholder = controlProps.selectProps.placeholder as string;
  const { hasValue } = controlProps;
  return (
    <components.Control {...controlProps}>
      {hasValue ? <SmallLabel label={placeholder} /> : null}
      {children}
    </components.Control>
  );
};

const SearchSelect: FC<Props & { error?: { msg: string } }> = (props) => {
  const themeContext = useTheme();
  const { error } = props;

  const selectStyles: StylesConfig = {
    placeholder: (styles) => ({
      ...styles,
      justifyContent: 'start',
      font: themeContext.fonts.primary,
      color: themeContext.colors.black.black6,
      transition: 'all ease-in-out 150ms, height 0ms',
    }),
    input: (styles) => ({
      ...styles,
      justifyContent: 'start',
    }),
    valueContainer: (styles, state) => ({
      ...styles,
      justifyContent: 'start',
      paddingTop: state.hasValue ? '0px' : '8px',
      paddingBottom: state.hasValue ? '2px' : '0px',
      transition: 'all ease-in-out 150ms, height 0ms',
    }),

    menuList: (styles) => ({
      ...styles,
      display: 'column',
      alignContent: 'center',
      justifyContent: 'start',
    }),

    indicatorsContainer: (styles, state) => ({
      ...styles,
      paddingTop: state.hasValue ? '0px' : '8px',
      paddingBottom: state.hasValue ? '8x' : '0px',
    }),
    // when control is clicked enable box shadow
    control: (styles, state) => ({
      ...styles,
      borderRadius: 0,
      minHeight: '55px',
      maxHeight: '55px',
      borderShadow: 'none',
      boxShadow: state.isFocused
        ? error
          ? `0 0 0 3px ${themeContext.colors.red.light} !important`
          : `0 0 0 3px ${themeContext.colors.blue.light} !important`
        : 'none',
      border: error
        ? `1px solid ${themeContext.colors.red.base} !important`
        : state.isFocused
        ? `1px solid ${themeContext.colors.blue.base} !important`
        : `1px solid ${themeContext.colors.black.black3}`,
      ':&hover': {
        border: `4px solid ${themeContext.colors.black.black3} !important`,
      },
      color: themeContext.colors.black.black1,
    }),
    menu: (styles) => ({
      ...styles,
      borderRadius: 0,
      display: 'column',
      alignContent: 'center',
      justifyContent: 'start',
    }),
    option: (styles) => ({
      ...styles,
      font: themeContext.fonts.primary,
      // left position the text
      display: 'flex',
      justifyContent: 'start',

      ':hover': {
        color: themeContext.colors.primary.base,
      },
    }),
  };

  return (
    <Div display="column">
      <Select
        {...props}
        styles={selectStyles}
        components={{
          IndicatorSeparator: () => null,
          Control: ControlComponent,
        }}
      />
      <Div justifyContent="start" display="flex" ml={4} mt={2}>
        {error && (
          <p
            style={{
              color: themeContext.colors.red.base,
              fontSize: themeContext.fontSizes[1],
              fontWeight: themeContext.fontWeights.medium,
            }}
          >
            {error.msg}
          </p>
        )}
      </Div>
    </Div>
  );
};

export default SearchSelect;
