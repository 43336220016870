import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  INITIATE_BOOST_CREDITS_PAYMENT,
  InitiateBoostCreditsPaymentPayload,
  InitiateBoostCreditsPaymentVariables,
} from '../../utils/mutation/InitiateBoostCreditsPayment/InitiateBoostCreditsPayment';

type UseInitiateBoostCreditsPaymentAction = (
  input: InitiateBoostCreditsPaymentVariables['input'],
  options?: MutationFunctionOptions<
    InitiateBoostCreditsPaymentPayload,
    InitiateBoostCreditsPaymentVariables
  >
) => Promise<FetchResult<InitiateBoostCreditsPaymentPayload>>;

export const useInitiateBoostCreditsPayment = (): [
  UseInitiateBoostCreditsPaymentAction,
  MutationResult<InitiateBoostCreditsPaymentPayload>
] => {
  const [initiateBoostCreditsPayment, ...rest] = useMutation<
    InitiateBoostCreditsPaymentPayload,
    InitiateBoostCreditsPaymentVariables
  >(INITIATE_BOOST_CREDITS_PAYMENT);

  const mutationFn: UseInitiateBoostCreditsPaymentAction = useCallback(
    (input, options) => {
      return initiateBoostCreditsPayment({
        variables: {
          input,
        },
        ...options,
      });
    },
    [initiateBoostCreditsPayment]
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
