import {
  AnswerAccessCode,
  JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access,
} from './generated/generated';
import { JobAccess } from './query/JobAccess/JobAccessQuery';

type JobAccessType = JOB_ACCESS_QUERY_jobAccess_JobAccessPayload_access;

export const canAnswerJob = (jobAccess: JobAccess): boolean =>
  !!jobAccess &&
  (jobAccess.isOpen || jobAccess.code === AnswerAccessCode.ALLOWED);

export enum AlertType {
  'NotAvailable' = 'NotAvailable',
  'NoAccessSmall' = 'NoAccessSmall',
  'NoAccessLarge' = 'NoAccessLarge',
  'WrongArea' = 'WrongArea',
  'NotVerifiedUser' = 'NotVerifiedUser',
  'DoffinJob' = 'DoffinJob',
  'InsufficientFunds' = 'InsufficientFunds',
  'LacksSubscription' = 'LacksSubscription',
  'LacksProduct' = 'LacksProduct',
  'LacksFreeClips' = 'LacksFreeClips',
  'LacksCertification' = 'LacksCertification',
  'RemovedUser' = 'RemovedUser',
  'OwnJob' = 'OwnJob',
}

export const mapJobAnswerCodeToAlert = (
  jobAccess: JobAccessType,
  onUnhandledCode?: (code: AnswerAccessCode) => void
): AlertType => {
  switch (jobAccess.code) {
    case AnswerAccessCode.DENIED_COMPANY_NOT_ACCESSIBLE:
    case AnswerAccessCode.DENIED_JOB_AWAITING_APPROVAL:
    case AnswerAccessCode.DENIED_JOB_NOT_FOUND:
    case AnswerAccessCode.DENIED_JOB_UNDER_REGISTRATION:
    case AnswerAccessCode.DENIED_JOB_UNAPPROVED:
    case AnswerAccessCode.DENIED_JOB_PAUSED:
    case AnswerAccessCode.DENIED_LACKING_APPROVAL:
    case AnswerAccessCode.DENIED_JOB_EXPIRED:
    case AnswerAccessCode.DENIED_JOB_CLOSED: {
      return AlertType.NotAvailable;
    }
    case AnswerAccessCode.DENIED_USER_DIFFERENT_JOB_TYPE:
    case AnswerAccessCode.DENIED_NOT_TEST_JOB:
    case AnswerAccessCode.DENIED_LACKING_SUBTYPES: {
      return AlertType.NoAccessSmall;
    }
    case AnswerAccessCode.DENIED_NO_ACCESS:
    case AnswerAccessCode.DENIED_PLUS_JOB: {
      return AlertType.NoAccessLarge;
    }
    case AnswerAccessCode.DENIED_WRONG_AREA: {
      return AlertType.WrongArea;
    }
    case AnswerAccessCode.DENIED_USER_NOT_VERIFIED:
    case AnswerAccessCode.DENIED_SELF_REGISTERED: {
      return AlertType.NotVerifiedUser;
    }
    case AnswerAccessCode.DENIED_DOFFIN: {
      return AlertType.DoffinJob;
    }
    case AnswerAccessCode.DENIED_INSUFFICIENT_FUNDS: {
      return AlertType.InsufficientFunds;
    }
    case AnswerAccessCode.DENIED_COMPANY_LACKS_SUBSCRIPTION: {
      return AlertType.LacksSubscription;
    }
    case AnswerAccessCode.DENIED_COMPANY_LACKS_PRODUCT: {
      return AlertType.LacksProduct;
    }
    case AnswerAccessCode.DENIED_COMPANY_LACKS_FREE_ANSWERS: {
      return AlertType.LacksFreeClips;
    }
    case AnswerAccessCode.DENIED_LACKING_CERTIFICATION: {
      return AlertType.LacksCertification;
    }
    case AnswerAccessCode.DENIED_USER_BLOCKED: {
      return AlertType.RemovedUser;
    }
    case AnswerAccessCode.DENIED_ANSWERING_OWN_JOB: {
      return AlertType.OwnJob;
    }
    default: {
      onUnhandledCode?.(jobAccess.code);
      return AlertType.NoAccessSmall;
    }
  }
};

const isJobAnsweredAccessCode = (jobAccess: JobAccessType): boolean =>
  jobAccess.code === AnswerAccessCode.DENIED_ALREADY_ANSWERED;

export const getJobAnswerAlertType = (
  jobAccess: JobAccess,
  onUnhandledCode?: (code: AnswerAccessCode) => void
): AlertType | null => {
  if (
    !jobAccess ||
    canAnswerJob(jobAccess) ||
    isJobAnsweredAccessCode(jobAccess)
  )
    return null;
  return mapJobAnswerCodeToAlert(jobAccess, onUnhandledCode);
};
