import { FC, useCallback } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import LanguagePicker from '@components/elements/LanguagePicker';
import HeaderMobile from '@components/layout/HeaderMobile';
import NavButton from '@components/layout/HeaderMobile/styled/NavButton';
import Filters from '@components/layout/Jobs/Filters';
import JobsListNavigation, {
  JobsListNavigationProps,
} from '@components/layout/Jobs/JobsListNavigation';
import { Statistics } from '@components/layout/Jobs/Statistics';
import { JobsFiltersProvider } from '@contexts/JobFiltersContext';
import useAnalytics from '@hooks/useAnalytics';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Drawer } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface JobsListHeaderMobileProps {
  title: string;
  navigationProps: JobsListNavigationProps;
}

export const JobsListHeaderMobile: FC<JobsListHeaderMobileProps> = ({
  title,
  navigationProps,
}) => {
  const { track } = useAnalytics();
  const themeContext = useTheme();
  const isPillNavigationEnabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchPillsView
  );

  const [showFilterSelector, openFilterSelector, closeFilterSelector] =
    useBoolean(false);

  const onSearchButtonClick = useCallback(() => {
    openFilterSelector();
    track(ANEventSpace(ANEvent.Opened, ANObject.Filter, ANPage.JobList));
  }, [openFilterSelector, track]);

  if (isPillNavigationEnabled) {
    return null;
  }

  return (
    <>
      <HeaderMobile
        title={title}
        sideNav={
          <>
            <Div px={6}>
              <Statistics />
            </Div>
            <FeatureFlag name={FeatureFlags.BizSavedSearchPillsView} invert>
              <JobsListNavigation {...navigationProps} />
            </FeatureFlag>
            <LanguagePicker />
          </>
        }
        actions={[
          <NavButton
            key="search-action"
            onClick={onSearchButtonClick}
            variant="tertiary"
          >
            <FireIcon
              iconName={IconPickerName.Search}
              variant="flat"
              fill={themeContext.colors.black.black10}
              width={24}
              height={24}
            />
          </NavButton>,
        ]}
      />
      <JobsFiltersProvider>
        <Drawer
          isOpen={showFilterSelector}
          onClose={closeFilterSelector}
          withCloseButton={false}
          modalContentStyles={{ height: '80%' }}
          position="bottom"
        >
          <Filters mobileView onClose={closeFilterSelector} />
        </Drawer>
      </JobsFiltersProvider>
    </>
  );
};
