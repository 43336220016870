import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  UPDATE_BUSINESS_LOGOVariables,
  UPDATE_BUSINESS_LOGO as UPDATE_BUSINESS_LOGO_Result,
  UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload,
} from '../../generated/generated';

export type UpdateBusinessLogoPayload = UPDATE_BUSINESS_LOGO_Result;
export type UpdateBusinessLogoVariables = UPDATE_BUSINESS_LOGOVariables;

export const isUpdateBusinessLogoSuccessResponse = (
  response?: UPDATE_BUSINESS_LOGO_Result['uploadBusinessLogo']
): response is UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload =>
  !!(response && response.__typename === 'ImageUploadPayload');

export const UPDATE_BUSINESS_LOGO = gql`
  ${IMAGE_FRAGMENT}

  mutation UPDATE_BUSINESS_LOGO($input: ImageUploadInput!) {
    uploadBusinessLogo(input: $input) {
      ... on ImageUploadPayload {
        logo {
          ...ImageFragment
        }
      }
      ... on ImageUploadInputError {
        message
      }
      ... on LoginRequiredError {
        message
      }
    }
  }
`;
