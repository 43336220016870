import gql from 'graphql-tag';

const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    city
    description
    id
    lat
    lon
    street
    type
    zip
  }
`;
export default ADDRESS_FRAGMENT;
