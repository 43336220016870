import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const ChatJobInfoContentLoader: React.FC<
  React.PropsWithChildren<LoaderProps>
> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 420 454"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    <rect x="15" y="20" rx="3" ry="3" width="80" height="18" />
    <rect x="389" y="20" rx="3" ry="3" width="16" height="16" />
    <rect x="15" y="73" rx="3" ry="3" width="20" height="20" />
    <rect x="53" y="74" rx="3" ry="3" width="100" height="18" />
    <rect x="15" y="118" rx="3" ry="3" width="20" height="20" />
    <rect x="53" y="119" rx="3" ry="3" width="120" height="18" />
    <rect x="15" y="163" rx="3" ry="3" width="20" height="20" />
    <rect x="15" y="208" rx="3" ry="3" width="20" height="20" />
    <rect x="53" y="164" rx="3" ry="3" width="135" height="18" />
    <rect x="53" y="209" rx="3" ry="3" width="80" height="18" />
    <rect x="0" y="253" rx="3" ry="3" width="420" height="1" />
    <rect x="15" y="279" rx="3" ry="3" width="350" height="18" />
    <rect x="389" y="279" rx="3" ry="3" width="16" height="16" />
    <rect x="15" y="317" rx="3" ry="3" width="370" height="16" />
    <rect x="15" y="336" rx="3" ry="3" width="360" height="16" />
    <rect x="15" y="355" rx="3" ry="3" width="355" height="16" />
    <rect x="15" y="374" rx="3" ry="3" width="370" height="16" />
    <rect x="15" y="393" rx="3" ry="3" width="365" height="16" />
    <rect x="15" y="412" rx="3" ry="3" width="265" height="16" />
    <rect x="15" y="436" rx="3" ry="3" width="50" height="18" />
  </ContentLoader>
);

export default ChatJobInfoContentLoader;
