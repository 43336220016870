import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const JobDescription = styled(Text.span)`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black.black7};
  font-weight: 400;
  margin: 0;

  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
