import {
  SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_BUSINESS,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_CONSUMER,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_BUSINESS,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_CONSUMER,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_BUSINESS,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_CONSUMER,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_INVALIDATED,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_BUSINESS,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_CONSUMER,
  SYSTEM_MESSAGE_TYPE_AGREEMENT_SIGNED_BY_BOTH,
  SYSTEM_MESSAGE_TYPE_BOLIGMAPPA,
  SYSTEM_MESSAGE_TYPE_CARD,
  SYSTEM_MESSAGE_TYPE_CONTRACT_OR_INSURANCE,
  SYSTEM_MESSAGE_TYPE_NUDGED_BOLIGMAPPA,
} from '../../utils/constants/systemMessageType';
import { MessageType } from '../../utils/generated/generated';

export enum SystemMessageType {
  DEFAULT = 0,
  CONTRACT_OR_INSURANCE = SYSTEM_MESSAGE_TYPE_CONTRACT_OR_INSURANCE,
  CARD = SYSTEM_MESSAGE_TYPE_CARD,
  AGREEMENT_CREATED = SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED,
  AGREEMENT_SIGNED_BY_BOTH = SYSTEM_MESSAGE_TYPE_AGREEMENT_SIGNED_BY_BOTH,
  AGREEMENT_ACCEPTED_CONSUMER = SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_CONSUMER,
  AGREEMENT_ACCEPTED_BUSINESS = SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_BUSINESS,
  AGREEMENT_REJECTED_CONSUMER = SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_CONSUMER,
  AGREEMENT_REJECTED_BUSINESS = SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_BUSINESS,
  AGREEMENT_INVALIDATED = SYSTEM_MESSAGE_TYPE_AGREEMENT_INVALIDATED,
  AGREEMENT_EDITED_CONSUMER = SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_CONSUMER,
  AGREEMENT_EDITED_BUSINESS = SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_BUSINESS,
  AGREEMENT_CREATED_CONSUMER = SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_CONSUMER,
  AGREEMENT_CREATED_BUSINESS = SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_BUSINESS,
  BOLIGMAPPA = SYSTEM_MESSAGE_TYPE_BOLIGMAPPA,
  NUDGED_BOLIGMAPPA = SYSTEM_MESSAGE_TYPE_NUDGED_BOLIGMAPPA,
}

export enum SystemMessageKind {
  BOLIGMAPPA = 'BOLIGMAPPA',
  NUDGED_BOLIGMAPPA = 'NUDGED_BOLIGMAPPA',
  CONTRACT = 'CONTRACT',
  UNKNOWN = 'UNKNOWN',
}

export type SystemMessageOutput = {
  text: string;
  title: string;
};

export type SystemMessagePayload = {
  text: string;
  type: SystemMessageType;
};

export const getSystemMessagePayload = (
  jsonPayload: string
): SystemMessagePayload => {
  try {
    const payload = JSON.parse(jsonPayload ?? '') as SystemMessagePayload;
    return {
      text: payload?.text ?? '',
      type: payload?.type ?? 0,
    };
  } catch (e) {
    return {
      text: '',
      type: 0,
    };
  }
};

export const getSystemMessageKind = (
  type: SystemMessageType
): SystemMessageKind => {
  switch (type) {
    case SystemMessageType.NUDGED_BOLIGMAPPA:
      return SystemMessageKind.NUDGED_BOLIGMAPPA;
    case SystemMessageType.BOLIGMAPPA:
      return SystemMessageKind.BOLIGMAPPA;
    // case SystemMessageType.CONTRACT_OR_INSURANCE:    not in use anymore
    case SystemMessageType.AGREEMENT_CREATED:
    case SystemMessageType.AGREEMENT_SIGNED_BY_BOTH:
    case SystemMessageType.AGREEMENT_ACCEPTED_CONSUMER:
    case SystemMessageType.AGREEMENT_ACCEPTED_BUSINESS:
    case SystemMessageType.AGREEMENT_REJECTED_CONSUMER:
    case SystemMessageType.AGREEMENT_REJECTED_BUSINESS:
    case SystemMessageType.AGREEMENT_INVALIDATED:
    case SystemMessageType.AGREEMENT_EDITED_CONSUMER:
    case SystemMessageType.AGREEMENT_EDITED_BUSINESS:
    case SystemMessageType.AGREEMENT_CREATED_CONSUMER:
    case SystemMessageType.AGREEMENT_CREATED_BUSINESS:
      return SystemMessageKind.CONTRACT;
    default:
      return SystemMessageKind.UNKNOWN;
  }
};

export const isSystemMessageType = (
  type: MessageType
): type is MessageType.SYSTEM => type === MessageType.SYSTEM;
export const isBoligmappaSystemMessage = (type: SystemMessageType) =>
  getSystemMessageKind(type) === SystemMessageKind.BOLIGMAPPA;
export const isNudgedBoligmappaSystemMessage = (type: SystemMessageType) =>
  getSystemMessageKind(type) === SystemMessageKind.NUDGED_BOLIGMAPPA;
export const isContractSystemMessage = (type: SystemMessageType) =>
  getSystemMessageKind(type) === SystemMessageKind.CONTRACT;
