import { Div, Image, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export interface GalleryWrapperProps extends DivProps {
  grid: boolean;
  isMobile: boolean;
  row: boolean;
}

export const ImageWrapper = styled(Image)`
  cursor: pointer;
  object-fit: cover;
`;

export const GalleryWrapper = styled(Div)<GalleryWrapperProps>`
  .lg-react-element {
    ${({ grid, isMobile }) =>
      grid &&
      `
      display: grid;
      grid-template-columns: repeat(${isMobile ? 2 : 3}, 1fr);
      grid-gap: 9px;
    `}
    ${({ row }) =>
      row &&
      `
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      flex-wrap: wrap;
    `}
  }
`;
