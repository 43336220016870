import { FC, useEffect, useMemo } from 'react';

import { CustomErrorAlert } from '@components/base/ErrorAlert/CustomErrorAlert';
import {
  CloudinaryAsset,
  CloudinaryAssetsFetchResult,
} from '@internals/business-shared/src/hooks/useCloudinaryAssets';
import { Div, Text, Spinner } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { SampleImagesGallery, SampleImageType } from './SampleImagesGallery';

interface SampleImagesProps {
  imagesFetchResult: CloudinaryAssetsFetchResult;
  onImageSelect: (image: SampleImageType) => void;
  selectedImageId?: string;
}

const mapCloudinaryAsset = (image: CloudinaryAsset): SampleImageType => ({
  id: image.external_id,
  previewUrl: image.delivery_urls.preview,
  url: image.delivery_urls.original,
  filename: image.filename,
  type: image.resource_type,
});

export const SampleImages: FC<SampleImagesProps> = ({
  imagesFetchResult,
  onImageSelect,
  selectedImageId,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const { data, loading, error } = imagesFetchResult;
  const images = useMemo(
    () => (data?.assets ? data.assets.map(mapCloudinaryAsset) : []),
    [data?.assets]
  );

  useEffect(() => {
    if (error) {
      bugsnagClient.notify(
        `Loading sample profile background images resulted in error: ${error}`
      );
    }
    if (data?.assets && !data.assets.length) {
      bugsnagClient.notify('Sample profile background image list is empty');
    }
  }, [error, data]);

  const content = useMemo(() => {
    if (loading) {
      return <Spinner size={6} my={7} mx="auto" />;
    }
    if (error || !images.length) {
      return (
        <CustomErrorAlert
          error={{
            variant: 'danger',
            msg: t('profile.graphic.backgroundImage.sampleImages.error'),
          }}
        />
      );
    }
    return (
      <SampleImagesGallery
        images={images}
        onImageSelect={onImageSelect}
        selectedImageId={selectedImageId}
      />
    );
  }, [loading, error, images, onImageSelect, selectedImageId]);

  return (
    <Div mt={6}>
      <Text.p fontWeight={themeContext.fontWeights.medium} fontSize={3} mb={4}>
        {t('profile.graphic.backgroundImage.sampleImages.title')}
      </Text.p>
      {content}
    </Div>
  );
};
