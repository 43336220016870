import gql from 'graphql-tag';

const COMPANY_STATE_FRAGMENT = gql`
  fragment CompanyStateFragment on BusinessStates {
    id
    isVerified
    isVerifiedWithSecureId
    isDeactivated
    isBlacklisted
    isOrderUnpaid
    isMittanbudXlEligible
    hasProducts
    hasProductExpired
    isNew
    products
    jobAccess
    availableProductSlugs
  }
`;

export default COMPANY_STATE_FRAGMENT;
