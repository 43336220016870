import { CompanySettings } from './query/CompanySettings/CompanySettings';

const DEFAULT_WARNING_THRESHOLD = 0.2;
const BOOST_CLIPS_THRESHOLD = 5;

type SettingsType = CompanySettings | null;

export const showBoostClipsWarning = (companySettings: SettingsType) => {
  if (!companySettings) {
    return false;
  }

  const { BoostCreditsRemaining, NumAnswersRemaining } = companySettings;
  if (NumAnswersRemaining === null) {
    return false;
  }

  const creditsRemaining = BoostCreditsRemaining + NumAnswersRemaining;
  return creditsRemaining > 0 && creditsRemaining <= BOOST_CLIPS_THRESHOLD;
};

export const showClipsWarning = (companySettings: SettingsType) => {
  if (!companySettings) {
    return false;
  }

  const { BoostCreditsRemaining, NumAnswersRemaining, NumAnswers } =
    companySettings;
  if (NumAnswersRemaining === null || NumAnswers === null || NumAnswers < 1) {
    return false;
  }

  const creditsRemaining = BoostCreditsRemaining + NumAnswersRemaining;
  return (
    creditsRemaining > 0 &&
    creditsRemaining / NumAnswers < DEFAULT_WARNING_THRESHOLD
  );
};

export const showClipsDanger = (companySettings: SettingsType) => {
  if (!companySettings) {
    return false;
  }

  const { BoostCreditsRemaining, NumAnswersRemaining } = companySettings;
  return BoostCreditsRemaining + NumAnswersRemaining === 0;
};

export const clipNumberToDisplay = (num: number | null): string => {
  if (!num || num < 0) return '0';
  if (num > 999) return '999+';
  return String(num);
};

const TEN_THOUSANDS = 10000;
const ONE_MILLION = 1000000;

export const displayProfileViews = (
  profileViewsCount?: number | null
): string => {
  if (!profileViewsCount || profileViewsCount <= 0) return '0';
  if (profileViewsCount < TEN_THOUSANDS) return String(profileViewsCount);
  if (profileViewsCount < ONE_MILLION) {
    return `${Math.floor(profileViewsCount / 100) / 10}K`;
  }
  return `${Math.floor(profileViewsCount / 100000) / 10}M`;
};
