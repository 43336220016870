import * as React from 'react';

import { GoogleMapsEmbed } from '@components/base/GoogleMapsEmbed';
import { Button, ButtonProps, Div } from '@schibsted-smb/fireball';

import MapModal from './MapModal';
import MapOverlay from './styled/MapOverlay';

export interface MapPreviewModalProps {
  address: string;
  containerProps?: ButtonProps;
}

const MapPreviewModal: React.FC<
  React.PropsWithChildren<MapPreviewModalProps>
> = ({ address, containerProps }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  if (!address) return null;
  return (
    <Div width="100%" pt={4}>
      <Button
        type="button"
        variant="linkPrimary"
        onClick={() => setIsModalOpen(true)}
        fullWidth
        p={0}
        {...containerProps}
      >
        <MapOverlay>
          <GoogleMapsEmbed
            mapParams={address}
            styles={{ position: 'relative', zIndex: -1 }}
          />
        </MapOverlay>
      </Button>
      <MapModal
        isOpen={isModalOpen}
        address={address}
        onClose={() => setIsModalOpen(false)}
      />
    </Div>
  );
};

export default MapPreviewModal;
