import { useEffect, useMemo, useState } from 'react';

import { ImageFragment } from '@internals/business-shared/src/utils/generated/generated';
import { getImageWithDimensions } from '@utils/image';
import { bugsnagClient } from '@utils/initBugsnag';

export const useImagesWithDimensions = (
  images: ImageFragment[]
): ImageFragment[] => {
  const [imagesWithDimensions, setImagesWithDimensions] =
    useState<ImageFragment[]>(images);
  const imagesLackingDimensions = useMemo(
    () =>
      imagesWithDimensions.filter(
        (image) => !image.dimensions?.width || !image.dimensions?.height
      ),
    [imagesWithDimensions]
  );
  useEffect(() => {
    const updateImagesDimensions = async () => {
      try {
        const updated = await Promise.all(
          imagesLackingDimensions.map((image) => getImageWithDimensions(image))
        );
        setImagesWithDimensions((prevImageList) =>
          prevImageList.map((prevImage) => {
            const updatedImage = updated.find(
              (image) => image.id === prevImage.id
            );
            return updatedImage || prevImage;
          })
        );
      } catch (e) {
        bugsnagClient.notify(new Error(`Error on loading job images: ${e}`));
      }
    };
    if (imagesLackingDimensions.length === 0) {
      return;
    }
    updateImagesDimensions();
  }, [imagesLackingDimensions]);

  return imagesWithDimensions;
};
