import gql from 'graphql-tag';

import {
  JOB_LIST_FILTERS_QUERY as JOB_LIST_FILTERS_QUERY_Result,
  JOB_LIST_FILTERS_QUERY_jobListFilters,
} from '../../generated/generated';

export type JobListFiltersQueryPayload = JOB_LIST_FILTERS_QUERY_Result;
export type JobListFilters = JOB_LIST_FILTERS_QUERY_jobListFilters[];
export type JobListFilter = JOB_LIST_FILTERS_QUERY_jobListFilters;

export const JOB_LIST_FILTERS_QUERY = gql`
  query JOB_LIST_FILTERS_QUERY {
    jobListFilters {
      id
      count
      listType
    }
  }
`;
