import { t } from '@internals/business-translations/src/i18n';

import { JobCustomerType } from '../generated/generated';

export const getConsumerType = (
  consumerType: JobCustomerType | null,
  onMissingTypeHandling: (error: Error) => void
): string => {
  const fallback = t('general.label.notProvided');

  if (!consumerType) return fallback;

  switch (consumerType) {
    case JobCustomerType.COMPANY:
      return t('customer.type.company');
    case JobCustomerType.CONSUMER:
      return t('customer.type.private');
    case JobCustomerType.UNION:
      return t('customer.type.union');
    case JobCustomerType.COOPERATIVE:
      return t('customer.type.cooperative');
    default: {
      onMissingTypeHandling(
        new Error(`Job consumer type ${consumerType} not handled`)
      );
      return fallback;
    }
  }
};
