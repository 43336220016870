import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  StyledProductCard,
  StyledProductContent,
} from '@components/layout/Products/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Heading, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface ProductPackageProps {
  title: string;
  subtitle?: string;
  action: {
    text: string;
    handler: () => void;
  };
  variant: 'centered' | 'with-icon';
}

const ICON_SIZE = 146;
const ICON_MOVE_RIGHT = 30;
const ICON_MOVE_BOTTOM = 20;

export const ProductPackageCard: FC<ProductPackageProps> = ({
  title,
  subtitle,
  action,
  variant,
}) => {
  const themeContext = useTheme();
  const withIcon = variant === 'with-icon';
  const productContentWidth = withIcon
    ? `calc(100% - ${ICON_SIZE - ICON_MOVE_RIGHT}px)`
    : '100%';

  return (
    <StyledProductCard withIcon={withIcon}>
      <StyledProductContent withIcon={withIcon} width={productContentWidth}>
        <Heading.h3 mb={2}>{title}</Heading.h3>
        <Text.p mb={4}>{subtitle}</Text.p>
        <Button px={5} py={4} borderRadius={4} onClick={action.handler}>
          {action.text}
        </Button>
      </StyledProductContent>
      {withIcon && (
        <Div position="relative">
          <Div
            position="absolute"
            bottom={-ICON_MOVE_BOTTOM}
            right={-ICON_MOVE_RIGHT}
            height={ICON_SIZE}
            width={ICON_SIZE}
          >
            <FireIcon
              fill={themeContext.colors.primary.base}
              iconName={IconPickerName.ShieldCheck}
            />
          </Div>
        </Div>
      )}
    </StyledProductCard>
  );
};
