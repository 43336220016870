import { ApolloError } from '@apollo/client';
import {
  AuthRequestUsernameDocument,
  type ForgotUsernameInput,
} from '@internals/business-graphql/src/graphql/generated/types';
import {
  ErrorTypes,
  GenericErrorCodes,
  resolveErrors,
} from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import type { ErrorVariant } from '../login/types';

export type HandleRequestUsernameErrorHandlers = {
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onGenericError: (error?: ErrorVariant) => void;
};

export const handleRequestUsername = async (
  input: ForgotUsernameInput,
  onSuccess: () => void | Promise<void>,
  errorHandlers: HandleRequestUsernameErrorHandlers
) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: AuthRequestUsernameDocument,
      variables: { input },
    });

    if (data?.forgotUsernameV2) {
      await onSuccess();
    } else {
      throw new Error('Invalid request username response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
