import { FC } from 'react';

import { ApolloError } from '@apollo/client';
import { Spinner } from '@schibsted-smb/fireball';

import JobList, {
  RequestEvaluationJobListProps,
} from './EvaluationRequestJobList';
import Error from './EvaluationRequestJobListError';
import NoJobs from './EvaluationRequestNoJobs';
import NoSearchResults from './EvaluationRequestNoSearchResults';

interface EvaluationRequestContentProps {
  loading: boolean;
  error?: ApolloError;
  listProps?: RequestEvaluationJobListProps;
  onRefetch: VoidFunction;
  searchQuery: string;
  clearSearch: VoidFunction;
}

const EvaluationRequestContent: FC<
  React.PropsWithChildren<EvaluationRequestContentProps>
> = ({ loading, error, onRefetch, listProps, clearSearch, searchQuery }) => {
  if (loading) return <Spinner size={6} m="auto" />;
  if (error) return <Error onRefetch={onRefetch} />;
  if (listProps?.jobs.edges.length) return <JobList {...listProps} />;
  return searchQuery ? (
    <NoSearchResults searchQuery={searchQuery} onClear={clearSearch} />
  ) : (
    <NoJobs />
  );
};

export default EvaluationRequestContent;
