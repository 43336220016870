import gql from 'graphql-tag';

// This is a limited version of CHAT_DETAILS_QUERY - used just for chat conversation data refetch
import MessageFragment from '../../fragments/MessageFragment';
import {
  CHAT_CONVERSATION_QUERYVariables,
  CHAT_CONVERSATION_QUERY as CHAT_CONVERSATION_QUERY_Result,
  CHAT_CONVERSATION_QUERY_chatMessages_edges,
  CHAT_CONVERSATION_QUERY_chatMessages,
  CHAT_CONVERSATION_QUERY_chatMessages_edges_node,
} from '../../generated/generated';

export type ChatConversationQueryPayload = CHAT_CONVERSATION_QUERY_Result;
export type ChatConversationQueryVariables = CHAT_CONVERSATION_QUERYVariables;
export type ChatConversation = CHAT_CONVERSATION_QUERY_chatMessages;
export type ChatMessageEdge = CHAT_CONVERSATION_QUERY_chatMessages_edges;
export type ChatMessageNode = CHAT_CONVERSATION_QUERY_chatMessages_edges_node;

export const CHAT_CONVERSATION_QUERY = gql`
  ${MessageFragment}
  query CHAT_CONVERSATION_QUERY(
    $id: ID!
    $first: Int
    $last: Int
    $from: Int
    $orderByTime: SortDirection
  ) {
    chatMessages(
      input: {
        chatId: $id
        first: $first
        from: $from
        last: $last
        orderByTime: $orderByTime
      }
    ) {
      edges {
        node {
          ...MessageFragment
        }
      }
      pageInfo {
        totalPages
        totalCount
        pageSizeLimit
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
