import FeatureFlags from './constants/FeatureFlags';

export type Flag = {
  name: string;
  enabled: boolean;
};

export const getFilteredFeatureFlags = (flags: Flag[]): Flag[] => {
  const featureFlags = Object.values(FeatureFlags);
  const filteredFlags = flags.filter((item) => {
    return featureFlags.includes(item.name);
  });
  return filteredFlags;
};
