import { useCallback } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  APOLLO_ERROR_HANDLING_HIDE_TOAST,
  APOLLO_ERROR_HANDLING_NO_BUGSNAG,
} from '../../utils/constants/apollo';
import {
  RequestPasswordChangeVariables,
  RequestPasswordChangePayload,
  REQUEST_PASSWORD_CHANGE,
} from '../../utils/mutation/ResetPassword/ChangePasswordMutation';

type useRequestPasswordChangeAction = (
  email: RequestPasswordChangeVariables['input']['email'],
  redirectDomain: RequestPasswordChangeVariables['input']['redirectDomain'],
  mutationOptions?: MutationFunctionOptions<
    RequestPasswordChangePayload,
    RequestPasswordChangeVariables
  >
) => Promise<FetchResult<RequestPasswordChangePayload>>;

export const useRequestPasswordChange = (
  mutationHookOptions?: MutationFunctionOptions<
    RequestPasswordChangePayload,
    RequestPasswordChangeVariables
  >
): [
  useRequestPasswordChangeAction,
  MutationResult<RequestPasswordChangePayload>
] => {
  const [requestPasswordChange, ...rest] = useMutation<
    RequestPasswordChangePayload,
    RequestPasswordChangeVariables
  >(REQUEST_PASSWORD_CHANGE, {
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: false,
      [APOLLO_ERROR_HANDLING_NO_BUGSNAG]: false,
    },
    ...mutationHookOptions,
  });

  const mutationFn: useRequestPasswordChangeAction = useCallback(
    (email, redirectDomain, options) => {
      return requestPasswordChange({
        variables: {
          input: {
            email,
            redirectDomain,
          },
        },
        ...options,
      });
    },
    [requestPasswordChange]
  );

  return [mutationFn, ...rest];
};
