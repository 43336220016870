import { FC, useEffect, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { useFlagEnabled } from '@components/base/CheckFlag';
import ErrorAlert from '@components/base/ErrorAlert';
import { BankIdVerification } from '@components/elements/BankIdVerification';
import NotFound from '@components/elements/NotFound';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import { useCompanyStateIsVerifiedWithSecureId } from '@internals/business-shared/src/contexts/CompanyStateContext';
import { useRequestPasswordChange } from '@internals/business-shared/src/hooks/mutation/useRequestPasswordChange';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  CompanyInfoResponseError,
  GQLErrorState,
  UpdateBusinessResponseError,
} from '@internals/business-shared/src/utils/errors';
import { isError } from '@internals/business-shared/src/utils/isError';
import {
  UPDATE_BUSINESS_MUTATION,
  UpdateBusinessMutationPayload,
  UpdateBusinessMutationVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusiness/UpdateBusinessMutation';
import {
  COMPANY_INFO_QUERY,
  CompanyInfoQueryPayload,
  CompanyInfoQueryVariables,
  isCompanyInfoQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/CompanyInfo/CompanyInfoQuery';
import Paths from '@router/paths';
import { Alert, Button, Divider, Text } from '@schibsted-smb/fireball';
import { getPublicDomain } from '@utils/getPublicDomain';
import { useTranslation } from 'react-i18next';

import INPUT_NAME from './constants/inputNames';
import ProfileCompanyInfo from './ProfileCompanyInfo';
import ProfileEditCompanyForm from './ProfileEditCompanyForm';

export interface CompanyInfo {
  companyEmail: string;
  companyPhoneNumber: string;
  companyIdNumber: string;
  companyPostAddressId: string;
  companyPostAddress: string;
  companyPostNumber: string;
  companyCity: string;
  companyVisitPostAddressId: string;
  companyVisitPostAddress: string;
  companyVisitPostNumber: string;
  companyVisitCity: string;
  companyBillingPostAddressId: string;
  companyBillingPostAddress: string;
  companyBillingPostNumber: string;
  companyBillingCity: string;
}

const Profile: FC = () => {
  const user = useUserContext();
  const { track } = useAnalytics();
  const { email, operatingAs } = user;
  const { loading, data, refetch } = useQuery<
    CompanyInfoQueryPayload,
    CompanyInfoQueryVariables
  >(COMPANY_INFO_QUERY, {
    variables: { id: operatingAs.id },
    notifyOnNetworkStatusChange: true,
  });
  const [companyInfo, setCompanyInfo] = useState<CompanyInfo>({
    [INPUT_NAME.COMPANY_EMAIL]: '',
    [INPUT_NAME.COMPANY_PHONE_NUMBER]: '',
    [INPUT_NAME.COMPANY_ID_NUMBER]: '',
    [INPUT_NAME.COMPANY_POST_ID]: '',
    [INPUT_NAME.COMPANY_POST_ADDRESS]: '',
    [INPUT_NAME.COMPANY_POST_NUMBER]: '',
    [INPUT_NAME.COMPANY_CITY]: '',
    [INPUT_NAME.COMPANY_VISIT_POST_ID]: '',
    [INPUT_NAME.COMPANY_VISIT_POST_ADDRESS]: '',
    [INPUT_NAME.COMPANY_VISIT_POST_NUMBER]: '',
    [INPUT_NAME.COMPANY_VISIT_CITY]: '',
    [INPUT_NAME.COMPANY_BILLING_POST_ID]: '',
    [INPUT_NAME.COMPANY_BILLING_POST_ADDRESS]: '',
    [INPUT_NAME.COMPANY_BILLING_POST_NUMBER]: '',
    [INPUT_NAME.COMPANY_BILLING_CITY]: '',
  });
  const [companyInfoErrors, setCompanyInfoErrors] = useState<GQLErrorState>({});
  const [profileErrors, setProfileErrors] = useState<GQLErrorState>({});
  const [editPasswordSent, setEditPasswordSent] = useState<boolean>(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editCompanyIndicator, setEditCompanyIndicator] = useState(false);
  const { t } = useTranslation();
  const [updateBusiness, { data: updateBusinessData }] = useMutation<
    UpdateBusinessMutationPayload,
    UpdateBusinessMutationVariables
  >(UPDATE_BUSINESS_MUTATION);

  const isBusinessBankIDVerificationEnabled = useFlagEnabled(
    FeatureFlags.BizSecureID
  );
  const isCompanyVerifiedWithSecureId = useCompanyStateIsVerifiedWithSecureId();

  const showBanner =
    isBusinessBankIDVerificationEnabled && !isCompanyVerifiedWithSecureId;
  const showBankIdStatus =
    isBusinessBankIDVerificationEnabled && isCompanyVerifiedWithSecureId;

  usePageAnalytics(ANSpace(ANObject.Info, ANPage.Settings));

  const [requestPasswordChange, { loading: changePasswordLoading }] =
    useRequestPasswordChange();

  const onChangePasswordClick = async (): Promise<void> => {
    await requestPasswordChange(user?.email, getPublicDomain(), {
      onCompleted: () => setEditPasswordSent(true),
    });
    track(
      ANEventSpace(ANEvent.Clicked, ANObject.ChangePassword, ANPage.Settings)
    );
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    const payload = isCompanyInfoQuerySuccessResponse(data?.business)
      ? data.business
      : undefined;
    setCompanyInfo({
      [INPUT_NAME.COMPANY_EMAIL]: payload?.email ?? '',
      [INPUT_NAME.COMPANY_PHONE_NUMBER]: payload?.phone ?? '',
      [INPUT_NAME.COMPANY_ID_NUMBER]: payload?.orgNr ?? '',
      [INPUT_NAME.COMPANY_POST_ID]: payload?.addresses?.post?.length
        ? payload.addresses.post[0].id || ''
        : '',
      [INPUT_NAME.COMPANY_POST_ADDRESS]: payload?.addresses?.post?.length
        ? payload.addresses.post[0].street || ''
        : '',
      [INPUT_NAME.COMPANY_POST_NUMBER]: payload?.addresses?.post?.length
        ? payload.addresses.post[0].zip || ''
        : '',
      [INPUT_NAME.COMPANY_CITY]: payload?.addresses?.post?.length
        ? payload.addresses.post[0].city || ''
        : '',
      [INPUT_NAME.COMPANY_VISIT_POST_ID]: payload?.addresses?.visit?.length
        ? payload.addresses.visit[0].id
        : '',
      [INPUT_NAME.COMPANY_VISIT_POST_ADDRESS]: payload?.addresses?.visit?.length
        ? payload.addresses.visit[0].street || ''
        : '',
      [INPUT_NAME.COMPANY_VISIT_POST_NUMBER]: payload?.addresses?.visit?.length
        ? payload.addresses.visit[0].zip || ''
        : '',
      [INPUT_NAME.COMPANY_VISIT_CITY]: payload?.addresses?.visit?.length
        ? payload.addresses.visit[0].city || ''
        : '',
      [INPUT_NAME.COMPANY_BILLING_POST_ID]: payload?.addresses?.billing?.length
        ? payload.addresses.billing[0].id
        : '',
      [INPUT_NAME.COMPANY_BILLING_POST_ADDRESS]: payload?.addresses?.billing
        ?.length
        ? payload.addresses.billing[0].street || ''
        : '',
      [INPUT_NAME.COMPANY_BILLING_POST_NUMBER]: payload?.addresses?.billing
        ?.length
        ? payload.addresses.billing[0].zip || ''
        : '',
      [INPUT_NAME.COMPANY_BILLING_CITY]: payload?.addresses?.billing?.length
        ? payload.addresses.billing[0].city || ''
        : '',
    });

    const companyInfoResponseError = new CompanyInfoResponseError('Business');
    companyInfoResponseError.hasResponseError(
      data?.business,
      setCompanyInfoErrors
    );
  }, [data]);

  useEffect(() => {
    if (!updateBusinessData) {
      return;
    }
    setEditCompanyIndicator(false);
    const updateBusinessError = new UpdateBusinessResponseError('Business');
    if (
      updateBusinessError.hasResponseError(
        updateBusinessData.updateBusiness,
        setProfileErrors
      )
    ) {
      setEditCompany(true); // errors - allow form editing
    } else {
      setEditCompany(false); // success
    }
  }, [updateBusinessData]);

  const saveCompanyData = () => {
    setEditCompanyIndicator(true);

    const existingData =
      data && isCompanyInfoQuerySuccessResponse(data?.business)
        ? data.business
        : undefined;
    const updatedData = existingData
      ? {
          ...(companyInfo.companyEmail &&
            companyInfo.companyEmail !== existingData.email && {
              email: companyInfo.companyEmail,
            }),
          ...(companyInfo.companyPhoneNumber &&
            companyInfo.companyPhoneNumber !== existingData.phone && {
              phone: companyInfo.companyPhoneNumber,
            }),
          address: {
            billing: {
              id: companyInfo.companyBillingPostAddressId,
              street: companyInfo.companyBillingPostAddress,
              zip: companyInfo.companyBillingPostNumber,
            },
            visit: {
              id: companyInfo.companyVisitPostAddressId,
              street: companyInfo.companyVisitPostAddress,
              zip: companyInfo.companyVisitPostNumber,
            },
            postal: {
              id: companyInfo.companyPostAddressId,
              street: companyInfo.companyPostAddress,
              zip: companyInfo.companyPostNumber,
            },
          },
        }
      : {};

    updateBusiness({
      variables: {
        data: updatedData,
      },
    }).then(() => {
      if (!companyInfo.companyEmail || !companyInfo.companyPhoneNumber)
        setCompanyInfo({
          ...companyInfo,
          companyEmail: companyInfo.companyEmail || existingData?.email || '',
          companyPhoneNumber:
            companyInfo.companyPhoneNumber || existingData?.phone || '',
        });
    });
  };
  const shouldDisplayError = !loading && isError(companyInfoErrors);

  return (
    <>
      {showBanner && (
        <>
          <BankIdVerification />
          <Divider bg="gray.light" />
        </>
      )}
      <SettingsContainer>
        {loading && <SettingsLoading />}
        {shouldDisplayError && (
          <NotFound
            title={t('settings.error.title')}
            text={t('settings.error.text')}
            link={{
              url: Paths.SettingsCustomerService,
              text: t('general.label.customerService'),
            }}
            button={{
              text: t('general.error.tryAgain'),
              onClick: () => refetch(),
            }}
          />
        )}
        {!loading && data && (
          <>
            <SettingsSection
              icon={IconPickerName.CompanyInfo}
              title={t('settings.profile.companyForm.title')}
              testId="company-info"
            >
              <Text.p mb={6} color="black.black9" lineHeight={1.5}>
                {t('settings.profile.companyForm.info')}
              </Text.p>
              <ErrorAlert errors={profileErrors} />
              {editCompany && (
                <ProfileEditCompanyForm
                  companyInfo={companyInfo}
                  errors={profileErrors}
                  setCompanyInfo={setCompanyInfo}
                  saveCompanyData={saveCompanyData}
                  setErrors={setProfileErrors}
                  inProgress={editCompanyIndicator}
                />
              )}
              {!editCompany && (
                <ProfileCompanyInfo
                  companyInfo={companyInfo}
                  onClick={() => setEditCompany(true)}
                />
              )}
            </SettingsSection>
            <Divider mb={6} />
            <SettingsSection
              icon={IconPickerName.Password}
              title={t('settings.profile.passwordForm.title')}
            >
              <Text.p mb={6} color="black.black9" lineHeight={1.5}>
                {t('settings.profile.passwordForm.info')}
              </Text.p>
              {editPasswordSent ? (
                <Alert variant="success">
                  {t('settings.profile.passwordForm.sentPasswordAlert', {
                    email,
                  })}
                </Alert>
              ) : (
                <Button
                  variant="secondary"
                  isLoading={changePasswordLoading}
                  onClick={onChangePasswordClick}
                  data-testid="restore-password-btn"
                >
                  {t('settings.profile.passwordForm.setEditPasswordButton')}
                </Button>
              )}
            </SettingsSection>
            {showBankIdStatus && (
              <>
                <Divider mb={6} />
                <SettingsSection
                  icon={IconPickerName.ShieldCheck}
                  title={t('settings.profile.bankIdVerificationSuccess.title')}
                  testId="company-bankid-verification-true"
                >
                  <Text.p mb={6} color="black.black9" lineHeight={1.5}>
                    {t('settings.profile.bankIdVerificationSuccess.info')}
                  </Text.p>
                </SettingsSection>
              </>
            )}
          </>
        )}
      </SettingsContainer>
    </>
  );
};

export default Profile;
