import gql from 'graphql-tag';

import { LOGOUT_MUTATION as LOGOUT_MUTATION_Result } from '../../generated/generated';

export type LogoutMutationPayload = LOGOUT_MUTATION_Result;

export const LOGOUT_MUTATION = gql`
  mutation LOGOUT_MUTATION {
    logout
  }
`;
