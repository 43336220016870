import * as React from 'react';

import {
  ChatJobInfoColumn,
  ChatTopBarContainer,
  ChatContainer,
  CHAT_DETAILS_TOP_BAR_HEIGHT,
} from '@components/layout/Messages/styled';
import { Div, Spinner } from '@schibsted-smb/fireball';
import ContentLoader from '@utils/contentLoaders';

const ChatDetailsLoading: React.FC<React.PropsWithChildren<unknown>> = () => {
  const PERSON_INFO_LOADER_WIDTH = '255px';
  return (
    <ChatContainer data-testid="loading-content">
      <ChatTopBarContainer>
        <Div height="100%" width={PERSON_INFO_LOADER_WIDTH}>
          <ContentLoader.ChatPersonInfo uniqueKey="loader-person-info" />
        </Div>
      </ChatTopBarContainer>
      <Div
        display="flex"
        height={`calc(100% - ${CHAT_DETAILS_TOP_BAR_HEIGHT}px)`}
      >
        <Div display="flex" flex={1} alignItems="center" height="15%">
          <Spinner size={4} />
        </Div>
        <ChatJobInfoColumn className="no-print" hideOnMobile>
          <Div height="100%" width="100%">
            <ContentLoader.ChatJobInfo uniqueKey="loader-job-info" />
          </Div>
        </ChatJobInfoColumn>
      </Div>
    </ChatContainer>
  );
};

export default ChatDetailsLoading;
