import { FC } from 'react';

import InlineErrorMessage from '@components/base/InlineErrorMessage';
import { InputDiv } from '@components/elements/NewMessage/styled';
import { useEvaluationResponseFormik } from '@components/layout/CustomerService/Evaluation/Evaluated/useEvaluationResponseFormik';
import { StyledTextarea } from '@components/layout/CustomerService/Evaluation/styled';
import { Button, ButtonProps, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface EvaluationResponseFormProps {
  onSubmit: (message: string) => void;
  loading: boolean;
  isMobile?: boolean;
}

export const EvaluationResponseForm: FC<EvaluationResponseFormProps> = ({
  onSubmit,
  loading,
  isMobile,
}) => {
  const { t } = useTranslation();
  const formik = useEvaluationResponseFormik(onSubmit);
  const buttonMobileProps: Partial<ButtonProps> = {
    width: '50px',
    size: 'tiny',
    height: 'unset',
  };

  return (
    <InputDiv>
      <StyledTextarea
        {...formik.getFieldProps('text')}
        placeholder={t('evaluation.item.evaluated.inputPlaceholder')}
        height="60px"
        unlabeled
        border="none"
        disabled={loading}
        testId="evaluation-reply"
        autofocus
      />
      <Div display="flex" justifyContent="space-between">
        <Div display="flex" alignItems="end" px={4}>
          {formik.touched.text && formik.errors.text && (
            <InlineErrorMessage>{formik.errors.text}</InlineErrorMessage>
          )}
        </Div>
        <Div
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          px={4}
          py={4}
        >
          <Button
            variant="primary"
            isLoading={loading}
            disabled={!formik.isValid}
            height="40px"
            width="72px"
            size={loading ? 'tiny' : 'medium'}
            onClick={formik.submitForm}
            data-testid="send-reply-btn"
            {...(isMobile && buttonMobileProps)}
          >
            {t('evaluation.item.evaluated.sendButton')}
          </Button>
        </Div>
      </Div>
    </InputDiv>
  );
};
