import JobListId from '../../utils/constants/jobListIds';
import {
  JobList,
  JobsSavedFilterFragment,
} from '../../utils/generated/generated';

export type AvailableFilters = Omit<
  JobsSavedFilterFragment['filters'],
  '__typename'
>;

export type FilterNotificationSettings = Omit<
  JobsSavedFilterFragment['notificationSettings'],
  '__typename'
>;

export type NotificationType = 'emailFrequency' | 'pushFrequency';

export interface DefaultJobListFilter {
  type: 'default';
  id: JobListId;
  listType: JobList;
  label: string;
  filters?: AvailableFilters;
  count?: number;
}

export interface SavedJobListFilter {
  type: 'saved';
  id: string;
  label: string;
  filters: AvailableFilters;
  notificationSettings: FilterNotificationSettings;
}

export type JobListFilter = DefaultJobListFilter | SavedJobListFilter;

export enum SavedFilterNavigationActionType {
  None = 'none',
  Edit = 'edit',
  SaveNew = 'save-new',
  SaveExisting = 'save-existing',
  MissingFilters = 'missing-filters',
}

export enum SavedFilterModalType {
  Edit = 'edit',
  Create = 'create',
  ChooseAction = 'choose-action',
  ConfirmDelete = 'confirm-delete',
  None = 'none',
}

/* types of filter state */
export type InitFilter = {
  type: 'init';
  activeFilter: null;
  editedFilter: null;
  action: SavedFilterNavigationActionType.None;
  modal: SavedFilterModalType.None;
  formChanged: false;
};

export type ActiveEditEnabledFilter = {
  type: 'active-edit-enabled';
  activeFilter: SavedJobListFilter;
  editedFilter: null;
  action: SavedFilterNavigationActionType.Edit;
  modal:
    | SavedFilterModalType.None
    | SavedFilterModalType.Edit
    | SavedFilterModalType.ConfirmDelete;
  formChanged: boolean;
};

export type ActiveEditDisabledFilter = {
  type: 'active-edit-disabled';
  activeFilter: DefaultJobListFilter;
  editedFilter: null;
  action: SavedFilterNavigationActionType.None;
  modal: SavedFilterModalType.None;
  formChanged: false;
};

export type EditedSaveEnabledFilter = {
  type: 'edited-save-enabled';
  activeFilter: SavedJobListFilter;
  editedFilter: SavedJobListFilter;
  action: SavedFilterNavigationActionType.SaveExisting;
  modal: SavedFilterModalType.None | SavedFilterModalType.ChooseAction;
  formChanged: boolean;
};

export type EditedSaveDisabledFilter = {
  type: 'edited-save-disabled';
  activeFilter: JobListFilter;
  editedFilter: JobListFilter;
  action:
    | SavedFilterNavigationActionType.None
    | SavedFilterNavigationActionType.MissingFilters;
  modal: SavedFilterModalType.None;
  formChanged: false;
};

export type NewSaveEnabledFilter = {
  type: 'new-save-enabled';
  activeFilter: JobListFilter | null;
  editedFilter: {
    filters: AvailableFilters;
    notificationSettings: null;
  };
  action: SavedFilterNavigationActionType.SaveNew;
  modal: SavedFilterModalType.None | SavedFilterModalType.Create;
  formChanged: boolean;
};

export type NewSaveDisabledFilter = {
  type: 'new-save-disabled';
  activeFilter: JobListFilter | null;
  editedFilter: Pick<DefaultJobListFilter, 'filters'>;
  action:
    | SavedFilterNavigationActionType.None
    | SavedFilterNavigationActionType.MissingFilters;
  modal: SavedFilterModalType.None;
  formChanged: false;
};

export type FilterStateType =
  | InitFilter
  | ActiveEditEnabledFilter
  | ActiveEditDisabledFilter
  | EditedSaveEnabledFilter
  | EditedSaveDisabledFilter
  | NewSaveEnabledFilter
  | NewSaveDisabledFilter;
