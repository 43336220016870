import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  JOB_LIST_FILTERS_QUERY,
  JobListFilters,
  JobListFiltersQueryPayload,
} from '../../utils/query/JobListFilters/JobListFiltersQuery';

interface JobListFiltersQueryResult extends QueryResult {
  data: JobListFilters | null;
}

export const useJobListFiltersQuery = (
  queryOptions?: QueryHookOptions<JobListFiltersQueryPayload>
): JobListFiltersQueryResult => {
  const { data, ...rest } = useQuery<JobListFiltersQueryPayload>(
    JOB_LIST_FILTERS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...(queryOptions || {}),
    }
  );

  return useMemo(
    () => ({
      data: data?.jobListFilters || null,
      ...rest,
    }),
    [data, rest]
  );
};
