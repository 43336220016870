import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const AddButton = styled(Button)`
  width: 35px;
  height: 35px;
  background: ${(props): string => props.theme.colors.black.black0};
  border-radius: ${(props): string => props.theme.radii[4]};
  border: 1px solid ${(props): string => props.theme.colors.black.black4};
  margin: 0 20px 0 0;
  padding: 0;
  line-height: 0;

  svg {
    width: 11px;
    height: 11px;
  }

  &:hover {
    background: ${(props): string => props.theme.colors.black.black0};
  }
`;

export default AddButton;
