import { FC } from 'react';

import { JobDataItemText } from '@components/layout/Job/styled/JobDataItemText';
import { JobDataItemTitle } from '@components/layout/Job/styled/JobDataItemTitle';
import { isNullOrUndefined } from '@internals/business-shared/src/utils/objectUtils';
import { Div } from '@schibsted-smb/fireball';

export interface JobDataItemType {
  title: string;
  text: string;
}

export interface JobDataItemProps extends JobDataItemType {
  disabled?: boolean;
  testId?: string;
}

export const JobDataItem: FC<JobDataItemProps> = ({
  text,
  title,
  disabled,
  testId,
}) => {
  const hasTestId = !isNullOrUndefined(testId);
  return (
    <Div py={4}>
      <JobDataItemTitle data-testid={hasTestId ? `${testId}-title` : null}>
        {title}
      </JobDataItemTitle>
      <JobDataItemText
        disabled={disabled}
        data-testid={hasTestId ? `${testId}-text` : null}
      >
        {text}
      </JobDataItemText>
    </Div>
  );
};
