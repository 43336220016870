import { FC, useCallback, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import { useFlagEnabled } from '@components/base/CheckFlag';
import ScrollPanel from '@components/base/ScrollPanel';
import AskModal from '@components/elements/AskModal';
import CollapsingBox from '@components/elements/CollapsingBox';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import JobShortInfo from '@components/elements/JobShortInfo';
import {
  ChatJobAction,
  ChatJobInfoAction,
} from '@components/layout/Messages/ChatJobInfoAction';
import ChatJobInfoContract from '@components/layout/Messages/ChatJobInfoContract';
import ChatJobInfoFiles from '@components/layout/Messages/ChatJobInfoFiles';
import { ChatJobInfoItem } from '@components/layout/Messages/ChatJobInfoItem';
import { useBoligMappaAuthContext } from '@contexts/BoligMappaAuthProvider';
import { useBoligMappaModalContext } from '@contexts/BoligMappaModalProvider';
import useAnalytics from '@hooks/useAnalytics';
import {
  askToBeEvaluatedOptimisticResponse,
  askToBeEvaluatedUpdate,
} from '@internals/business-shared/src/cache/updates/askToBeEvaluated';
import {
  askToBeSelectedOptimisticResponse,
  askToBeSelectedUpdate,
} from '@internals/business-shared/src/cache/updates/askToBeSelected';
import { useJobAnalyticsQuery } from '@internals/business-shared/src/hooks/query/useJobAnalyticsQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  getEmailContent,
  getPhoneContent,
  isEmailAvailable,
  isPhoneAvailable,
} from '@internals/business-shared/src/utils/jobContactDetails';
import {
  ASK_TO_BE_EVALUATED,
  AskToBeEvaluatedPayload,
  AskToBeEvaluatedVariables,
} from '@internals/business-shared/src/utils/mutation/AskToBeEvaluated/AskToBeEvaluated';
import {
  ASK_TO_BE_SELECTED,
  AskToBeSelectedPayload,
  AskToBeSelectedVariables,
} from '@internals/business-shared/src/utils/mutation/AskToBeSelected/AskToBeSelected';
import {
  ChatJob,
  SimpleAgreementContract,
} from '@internals/business-shared/src/utils/query/ChatJob/ChatJobQuery';
import { isJobWithBoligMappa } from '@internals/business-shared/src/utils/workTypesUtils';
import Paths from '@router/paths';
import { Div } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import ToastMessage from '@utils/ToastMessage';
import { getJobAbsoluteUrl } from '@utils/url';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';

import ChatEvaluationRequest from './ChatEvaluationRequest';
import { ChatJobInfoColumn } from './styled';

export interface ChatJobInfoProps {
  job: ChatJob;
  simpleAgreement?: SimpleAgreementContract;
  children?: never;
  chatId: string;
}

const ChatJobInfo: FC<ChatJobInfoProps> = ({
  job,
  simpleAgreement,
  chatId,
}) => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const { isAuthorised } = useBoligMappaAuthContext();
  const showPhoneDespitePreferenceEnabled = useFlagEnabled(
    FeatureFlags.BizShowPhoneDespitePreference
  );
  const [isAskToBeChosenModalOpen, setIsAskToBeChosenModalOpen] =
    useState(false);
  const [isAskToBeChosenConfirmModalOpen, setIsAskToBeChosenConfirmModalOpen] =
    useState(false);
  const [isAskToBeEvaluatedModalOpen, setIsAskToBeEvaluatedModalOpen] =
    useState(false);
  const [
    isAskToBeEvaluatedConfirmModalOpen,
    setIsAskToBeEvaluatedConfirmModalOpen,
  ] = useState(false);

  const { openModal: openBoligMappaModal } = useBoligMappaModalContext();

  const { data: jobAnalytics } = useJobAnalyticsQuery(job.id);

  const [askToBeChosen, { loading: askToBeChosenLoading }] = useMutation<
    AskToBeSelectedPayload,
    AskToBeSelectedVariables
  >(ASK_TO_BE_SELECTED, {
    variables: { jobId: job.id },
    optimisticResponse: askToBeSelectedOptimisticResponse,
    update: askToBeSelectedUpdate(job.id),
  });

  const [askToBeEvaluated, { loading: askToBeEvaluatedLoading }] = useMutation<
    AskToBeEvaluatedPayload,
    AskToBeEvaluatedVariables
  >(ASK_TO_BE_EVALUATED, {
    variables: { jobId: job.id },
    optimisticResponse: askToBeEvaluatedOptimisticResponse,
    update: askToBeEvaluatedUpdate(job.id),
  });
  const attachmentCount = job
    ? (job?.files?.length ?? 0) + (job?.images?.length ?? 0)
    : 0;
  const isJobOwnerDeleted = job?.owner?.isDeleted;

  const { track } = useAnalytics();

  const trackClick = useMemo(
    () => ({
      jobModalOpen: () =>
        track(ANEventSpace(ANEvent.Clicked, ANObject.ShowJob, ANPage.Messages)),
      address: () =>
        track(
          ANEventSpace(ANEvent.Clicked, ANObject.ShowAddress, ANPage.Messages)
        ),
      phone: () =>
        track(
          ANEventSpace(ANEvent.Clicked, ANObject.ShowPhone, ANPage.Messages),
          {
            hasContract: jobAnalytics.isContract,
            isDialogue: jobAnalytics.isDialog,
            wonJob: jobAnalytics.isWon,
          }
        ),
      email: () =>
        track(
          ANEventSpace(ANEvent.Clicked, ANObject.ShowEmail, ANPage.Messages)
        ),
    }),
    [jobAnalytics.isContract, jobAnalytics.isDialog, jobAnalytics.isWon, track]
  );

  const onAskToBeChosenButtonClick = () => {
    askToBeChosen().then(() => {
      track(
        ANEventSpace(ANEvent.Clicked, ANObject.MarkedWin, ANPage.Messages),
        { messageId: chatId, emailExperimentEnabled: true }
      );
      setIsAskToBeChosenModalOpen(false);
      setIsAskToBeChosenConfirmModalOpen(true);
    });
  };

  const onAskToBeEvaluatedButtonClick = () => {
    askToBeEvaluated().then(() => {
      setIsAskToBeEvaluatedModalOpen(false);
      setIsAskToBeEvaluatedConfirmModalOpen(true);
    });
  };

  const onBoligMappaClick = useCallback(() => {
    track(ANEventSpace(ANEvent.Clicked, ANObject.BoligMappa, ANPage.Messages), {
      messageId: chatId,
      authorised: isAuthorised,
    });

    openBoligMappaModal?.();
  }, [chatId, isAuthorised, openBoligMappaModal, track]);

  const isJobOwnerPhoneAvailable = isPhoneAvailable({
    phone: job?.contactInfo?.phone,
    isAnsweredJob: true,
    contactPreference: job?.meta?.contactPreference,
    showDespitePreference: showPhoneDespitePreferenceEnabled,
  });

  const jobOwnerPhone = useMemo(
    () =>
      getPhoneContent({
        phone: job?.contactInfo?.phone,
        isAnsweredJob: true,
        contactPreference: job?.meta?.contactPreference,
        productName,
        showDespitePreference: showPhoneDespitePreferenceEnabled,
      }),
    [
      job?.meta?.contactPreference,
      job?.contactInfo?.phone,
      productName,
      showPhoneDespitePreferenceEnabled,
    ]
  );

  const isJobOwnerEmailAvailable = isEmailAvailable(job?.contactInfo?.email);

  const jobOwnerEmail = useMemo(
    () =>
      getEmailContent({
        email: job?.contactInfo?.email,
        isAnsweredJob: true,
        contactPreference: job?.meta?.contactPreference,
        productName,
      }),
    [job?.contactInfo?.email, job?.meta?.contactPreference, productName]
  );

  const onCopyToClipboard = useCallback(() => {
    if (isJobOwnerDeleted || !navigator?.clipboard?.writeText) {
      return;
    }

    track(
      ANEventSpace(ANEvent.Clicked, ANObject.CopyJobDetails, ANPage.Messages),
      { jobId: job.id }
    );

    const clipboardText = [
      `${t('general.label.title')}: ${job.title}`,
      `${t('general.label.link')}: ${getJobAbsoluteUrl(job.id)}`,
      `${t('general.label.address')}: ${getAddressString(job?.address)}`,
      `${t('general.label.name')}: ${getUserName(job?.owner)}`,
    ];

    if (isPhoneAvailable) {
      clipboardText.push(`${t('general.label.phone')}: ${jobOwnerPhone}`);
    }

    if (isJobOwnerEmailAvailable) {
      clipboardText.push(`${t('general.label.email')}: ${jobOwnerEmail}`);
    }

    navigator.clipboard
      ?.writeText(clipboardText.join('\n'))
      .then(() => {
        ToastMessage(t('chat.action.clipboard.copySuccess'), 'success');
      })
      .catch((reason) => {
        ToastMessage(t('chat.action.clipboard.copyFailed'), 'warning');
        bugsnagClient.notify(reason);
      });
  }, [
    isJobOwnerDeleted,
    job?.address,
    job.id,
    job?.owner,
    job.title,
    jobOwnerPhone,
    jobOwnerEmail,
    isJobOwnerEmailAvailable,
    t,
    track,
  ]);

  return (
    <ChatJobInfoColumn className="no-print">
      {isAskToBeChosenModalOpen || isAskToBeChosenConfirmModalOpen ? (
        <AskModal
          isAskModalOpen={isAskToBeChosenModalOpen}
          isAskConfirmModalOpen={isAskToBeChosenConfirmModalOpen}
          closeAskModal={() => setIsAskToBeChosenModalOpen(false)}
          openConfirmModal={() => setIsAskToBeChosenConfirmModalOpen(true)}
          closeConfirmModal={() => setIsAskToBeChosenConfirmModalOpen(false)}
          onAskButtonClick={onAskToBeChosenButtonClick}
          askLoading={askToBeChosenLoading}
          illustration="asktobechosen"
          title={t('chat.item.askToBeChosen.title')}
          text={t('customer.item.askToBeChosen.evaluation.text')}
          buttonText={t('chat.item.askToBeChosen.confirmButton')}
          confirmText={t('chat.item.askToBeChosenConfirm.description')}
          confirmButtonText={t('general.label.close')}
        />
      ) : (
        ''
      )}
      {isAskToBeEvaluatedModalOpen || isAskToBeEvaluatedConfirmModalOpen ? (
        <AskModal
          isAskModalOpen={isAskToBeEvaluatedModalOpen}
          isAskConfirmModalOpen={isAskToBeEvaluatedConfirmModalOpen}
          closeAskModal={() => setIsAskToBeEvaluatedModalOpen(false)}
          openConfirmModal={() => setIsAskToBeEvaluatedConfirmModalOpen(true)}
          closeConfirmModal={() => setIsAskToBeEvaluatedConfirmModalOpen(false)}
          onAskButtonClick={onAskToBeEvaluatedButtonClick}
          askLoading={askToBeEvaluatedLoading}
          illustration="askforevaluation"
          title={t('general.action.requestEvaluation')}
          text={t('customer.item.askToBeChosen.evaluation.text')}
          buttonText={t('general.action.requestEvaluation')}
          confirmText={t('chat.item.askToBeEvaluatedConfirm.description')}
          confirmButtonText={t('general.label.close')}
        />
      ) : (
        ''
      )}
      {job && (
        <ScrollPanel style={{ width: '100%', height: '100%' }}>
          <Div display="flex" flexDirection="column">
            <CollapsingBox
              title={t('chat.action.toggleOwnerInfoButton')}
              isDefaultOpen
              withBorderBottom
            >
              <ChatEvaluationRequest
                job={job}
                onClick={() => setIsAskToBeEvaluatedModalOpen(true)}
              />
              {!job.jobBusinessRelationState.isWon &&
                !job.jobBusinessRelationState.isLost &&
                ChatJobAction({
                  text: `${t('chat.action.markAsWon')}`,
                  icon: IconPickerName.Won,
                  type: 'button',
                  buttonProps: {
                    onClick: () => setIsAskToBeChosenModalOpen(true),
                    disabled:
                      job.jobBusinessRelationState.hasRequestedSelection ||
                      isJobOwnerDeleted,
                  },
                })}
              <FeatureFlag name={FeatureFlags.BizBoligMappa}>
                {isJobWithBoligMappa(job.workTypes) && (
                  <ChatJobAction
                    type="button"
                    text={
                      job.jobBusinessRelationState.isWon
                        ? t('chat.action.boligMappa')
                        : t('chat.action.boligMappaDisabled')
                    }
                    icon={IconPickerName.BoligMappa}
                    buttonProps={{
                      onClick: onBoligMappaClick,
                      disabled: !job.jobBusinessRelationState.isWon,
                    }}
                  />
                )}
              </FeatureFlag>
              {!isJobOwnerDeleted && (
                <>
                  {isJobOwnerPhoneAvailable ? (
                    <ChatJobInfoAction
                      textStatic={jobOwnerPhone}
                      textAction={t('chat.action.showPhoneNumber')}
                      icon={IconPickerName.Mobile}
                      onClick={trackClick.phone}
                    />
                  ) : (
                    <ChatJobInfoItem
                      text={jobOwnerPhone}
                      icon={IconPickerName.Mobile}
                    />
                  )}
                  {isJobOwnerEmailAvailable ? (
                    <ChatJobInfoAction
                      textStatic={jobOwnerEmail}
                      textAction={t('chat.action.showEmail')}
                      icon={IconPickerName.Email}
                      onClick={trackClick.email}
                    />
                  ) : (
                    <ChatJobInfoItem
                      text={jobOwnerEmail}
                      icon={IconPickerName.Email}
                    />
                  )}
                  <ChatJobInfoAction
                    textStatic={getAddressString(job.address)}
                    textAction={t('chat.action.showAddress')}
                    icon={IconPickerName.Home}
                    onClick={trackClick.address}
                  />
                </>
              )}
              <ChatJobInfoContract
                job={job}
                simpleAgreement={simpleAgreement}
              />
              {ChatJobAction({
                text: `${t('job.action.print')}`,
                icon: IconPickerName.Print,
                type: 'link',
                linkProps: {
                  href: Paths.JobsItemPrint.replace(':id', job.id),
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
              })}
              {!isJobOwnerDeleted && (
                <ChatJobAction
                  type="button"
                  text={t('chat.action.clipboard.copyToJobDetails')}
                  icon={IconPickerName.Copy}
                  buttonProps={{
                    onClick: onCopyToClipboard,
                  }}
                />
              )}
            </CollapsingBox>
            <CollapsingBox
              title={t('chat.action.toggleJobInfoButton')}
              isDefaultOpen
              withBorderBottom
            >
              <JobShortInfo
                job={job}
                onJobModalOpen={trackClick.jobModalOpen}
              />
            </CollapsingBox>
            {attachmentCount !== 0 && (
              <CollapsingBox
                title={t('chat.action.toggleSharedFilesButton')}
                isDefaultOpen
                indicatorCount={attachmentCount}
                withBorderBottom
              >
                <ChatJobInfoFiles files={job.files} images={job.images} />
              </CollapsingBox>
            )}
          </Div>
        </ScrollPanel>
      )}
    </ChatJobInfoColumn>
  );
};

export default ChatJobInfo;
