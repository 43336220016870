import gql from 'graphql-tag';

import {
  WORK_TYPES_QUERY as WORK_TYPES_QUERY_Result,
  WORK_TYPES_QUERY_businessWorkTypes_subsets,
  WORK_TYPES_QUERY_businessWorkTypes_subsets_partOf,
  WORK_TYPES_QUERY_businessWorkTypes_workTypes,
  WORK_TYPES_QUERY_businessWorkTypes_workTypes_industries,
  WORK_TYPES_QUERYVariables,
} from '../../generated/generated';

export type WorkTypesQueryPayload = WORK_TYPES_QUERY_Result;
export type WorkTypesQueryVariables = WORK_TYPES_QUERYVariables;
export type WorkType = WORK_TYPES_QUERY_businessWorkTypes_workTypes;
export type WorkTypeIndustry =
  WORK_TYPES_QUERY_businessWorkTypes_workTypes_industries;
export type WorkTypeSubset = WORK_TYPES_QUERY_businessWorkTypes_subsets;
export type WorkTypeSubsetGroup =
  WORK_TYPES_QUERY_businessWorkTypes_subsets_partOf;

export const WORK_TYPES_QUERY = gql`
  query WORK_TYPES_QUERY($id: ID!) {
    businessWorkTypes(businessId: $id) {
      id
      workTypes {
        id
        title
        industries {
          id
          title
        }
      }
      subsets {
        id
        title
        partOf {
          id
          consumerTitle
        }
      }
    }
  }
`;
