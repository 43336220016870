import { JobLabelsFragment } from './generated/generated';
import { Omit } from './Omit';

export type JobLabelsType = JobLabelsFragment & {
  evaluationRequested?: boolean;
  selectionRequested?: boolean;
  isEvaluated?: boolean;
  isDirectRegistration?: boolean;
  isVerifiedWithSecureId?: boolean;
  isRejected?: boolean;
};
export type JobLabelFlag = keyof Omit<JobLabelsType, '__typename'>;

export enum JobLabel {
  BIG = 'BIG',
  CLIP_COUNT = 'CLIP_COUNT',
  DELETED = 'DELETED',
  DOFFIN = 'DOFFIN',
  ENDED = 'ENDED',
  FAVOURITE = 'FAVOURITE',
  FREE = 'FREE',
  PAUSED = 'PAUSED',
  WON = 'WON',
  EVALUATION = 'EVALUATION',
  FOLDER = 'FOLDER',
  EVALUATION_REQUESTED = 'EVALUATION_REQUESTED',
  SELECTION_REQUESTED = 'SELECTION_REQUESTED',
  DIRECT_REGISTRATION = 'DIRECT_REGISTRATION',
  VERIFIED_SECURE_ID = 'VERIFIED_SECURE_ID',
  REJECTED = 'REJECTED',
}

export const jobLabelMap: Record<JobLabelFlag, JobLabel> = {
  big: JobLabel.BIG,
  clipCount: JobLabel.CLIP_COUNT,
  deleted: JobLabel.DELETED,
  doffin: JobLabel.DOFFIN,
  ended: JobLabel.ENDED,
  favourite: JobLabel.FAVOURITE,
  free: JobLabel.FREE,
  paused: JobLabel.PAUSED,
  won: JobLabel.WON,
  evaluationRequested: JobLabel.EVALUATION_REQUESTED,
  selectionRequested: JobLabel.SELECTION_REQUESTED,
  isEvaluated: JobLabel.EVALUATION,
  isDirectRegistration: JobLabel.DIRECT_REGISTRATION,
  isVerifiedWithSecureId: JobLabel.VERIFIED_SECURE_ID,
  isRejected: JobLabel.REJECTED,
};

/**
 * Returns a list of job labels, filtered and ordered in the right order
 *
 * @param labels - just an array of labels you want to display
 * @param exclude - array of labels you want to exclude
 *
 * JobLabel.PAUSED, JobLabel.CLIP_COUNT are always excluded from display
 * JobLabel.EVALUATION, JobLabel.FOLDER are excluded due to different ui handling
 */

const EXCLUDED_LABELS = [
  JobLabel.PAUSED,
  JobLabel.CLIP_COUNT,
  JobLabel.EVALUATION,
  JobLabel.FOLDER,
] as const;
export type DisplayedJobLabel = Exclude<
  JobLabel,
  (typeof EXCLUDED_LABELS)[number]
>;

export const getJobLabelList = (
  labels: JobLabelsType,
  exclude?: JobLabel[]
): DisplayedJobLabel[] => {
  let excludedLabels: JobLabel[] = [...EXCLUDED_LABELS];
  if (exclude?.length) {
    excludedLabels = [...excludedLabels, ...exclude];
  }
  if (labels?.won) {
    excludedLabels.push(JobLabel.SELECTION_REQUESTED);
  }
  if (labels?.isEvaluated) {
    excludedLabels.push(JobLabel.EVALUATION_REQUESTED);
  }

  const filteredLabels = Omit('__typename', labels);
  const filteredList = (Object.keys(filteredLabels) as JobLabelFlag[])
    // filter out labels set to false
    .filter((label) => labels[label])
    // map to common enum type
    .map((label) => jobLabelMap[label])
    // filter out labels unused on ui
    .filter((label) => !excludedLabels.includes(label)) as DisplayedJobLabel[];

  // move favourite to the top - as long as isVerifiedWithSecureId is not present
  if (filteredList.includes(JobLabel.FAVOURITE)) {
    filteredList.splice(filteredList.indexOf(JobLabel.FAVOURITE), 1);
    filteredList.unshift(JobLabel.FAVOURITE);
  }

  // display isVerifiedWithSecureId first
  if (filteredList.includes(JobLabel.VERIFIED_SECURE_ID)) {
    filteredList.splice(filteredList.indexOf(JobLabel.VERIFIED_SECURE_ID), 1);
    filteredList.unshift(JobLabel.VERIFIED_SECURE_ID);
  }

  return filteredList;
};
