import gql from 'graphql-tag';

import {
  CREATE_JOB_FOLDER as CREATE_JOB_FOLDER_Result,
  CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload,
  CREATE_JOB_FOLDERVariables,
} from '../../generated/generated';

export type CreateJobFolderMutationVariables = CREATE_JOB_FOLDERVariables;
export type CreateJobFolderMutationPayload = CREATE_JOB_FOLDER_Result;

export const isCreateJobFolderMutationSuccessResponse = (
  createJobFolder?: CREATE_JOB_FOLDER_Result['createJobFolder']
): createJobFolder is CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload =>
  !!createJobFolder && createJobFolder.__typename === 'CreateJobFolderPayload';

export const CREATE_JOB_FOLDER = gql`
  mutation CREATE_JOB_FOLDER(
    $businessId: ID!
    $title: String!
    $description: String!
    $color: HexColorCode
  ) {
    createJobFolder(
      input: {
        businessId: $businessId
        title: $title
        description: $description
        color: $color
      }
    ) {
      ... on CreateJobFolderPayload {
        folder {
          id
          title
          color
        }
      }
      ... on CreateJobFolderInputError {
        message

        businessIdError: businessId {
          message
        }
        titleError: title {
          message
        }
        descriptionError: description {
          message
        }
        colorError: color {
          message
        }
      }
    }
  }
`;
