import * as React from 'react';

import { ColorPickerContainer } from '@components/elements/ColorPicker/styled';

import ColorRadioItem from './ColorRadioItem';

interface ColorPickerProps {
  colorList: string[];
  activeColor: string;
  onActiveColorChange: (color: string) => void;
}

const ColorPicker: React.FC<React.PropsWithChildren<ColorPickerProps>> = ({
  colorList,
  activeColor,
  onActiveColorChange,
}) => {
  return (
    <ColorPickerContainer>
      {colorList.map((color) => (
        <ColorRadioItem
          key={color}
          id={color}
          color={color}
          checked={color.toLowerCase() === activeColor.toLowerCase()}
          onChange={() => onActiveColorChange(color)}
        />
      ))}
    </ColorPickerContainer>
  );
};

export default ColorPicker;
