import { useMemo, useCallback } from 'react';

import { updateJobsSavedFilterOptimisticResponse } from '../../cache/updates/updateJobsSavedFilter';
import { useUpdateJobsSavedFilterMutation } from '../../hooks/mutation/useUpdateJobsSavedFilterMutation';
import { useJobsSavedFiltersQuery } from '../../hooks/query/useJobsSavedFiltersQuery';
import { JobsSavedFilter } from '../../utils/fragments/JobsSavedFilterFragment';
import { JobWatchlistNotificationFrequency } from '../../utils/generated/generated';
import {
  mapJobsSavedFilterToFilterInput,
  notificationsEnabled,
} from '../SavedFilters/utils';
import {
  NotificationSettingsType,
  NotificationToggleType,
} from './notificationSettingsTypes';

export type NotificationType = 'email' | 'push';

export const useSavedFilterNotificationSettings = (
  onChange?: (type: NotificationType, changedValue: boolean) => Promise<void>
): NotificationSettingsType => {
  const { data, loading } = useJobsSavedFiltersQuery();
  const [editSavedFilterMutation] = useUpdateJobsSavedFilterMutation();

  const saveNotification = useCallback(
    (
      filter: JobsSavedFilter,
      notificationType: NotificationType,
      isEnabled: boolean
    ) => {
      const updatedNotification = {
        [notificationType]: isEnabled
          ? JobWatchlistNotificationFrequency.IMMEDIATE
          : JobWatchlistNotificationFrequency.NEVER,
      };
      const filterInput = mapJobsSavedFilterToFilterInput(filter);
      const updatedFilterInput = {
        ...filterInput,
        notificationSettings: {
          ...filterInput.notificationSettings,
          ...updatedNotification,
        },
      };
      editSavedFilterMutation(updatedFilterInput, {
        optimisticResponse: updateJobsSavedFilterOptimisticResponse({
          ...filter,
          notificationSettings: {
            ...filter.notificationSettings,
            ...updatedNotification,
          },
        }),
      });
    },
    [editSavedFilterMutation]
  );

  const createToggle = useCallback(
    (
      filter: JobsSavedFilter,
      notificationType: NotificationType
    ): NotificationToggleType => ({
      id: `${filter.id}-${notificationType}`,
      name: `${filter.title}-${notificationType}`,
      checked: notificationsEnabled(
        filter.notificationSettings[notificationType]
      ),
      onChange: async () => {
        const isEnabled = !notificationsEnabled(
          filter.notificationSettings[notificationType]
        );
        await onChange?.(notificationType, isEnabled);
        saveNotification(filter, notificationType, isEnabled);
      },
      type: notificationType,
      variant: 'saved',
    }),
    [saveNotification, onChange]
  );

  const notificationsData = useMemo(() => {
    return data?.map((filter) => ({
      id: filter.id,
      name: filter.title,
      toggles: [createToggle(filter, 'email'), createToggle(filter, 'push')],
    }));
  }, [data, createToggle]);

  return useMemo(
    () => ({
      loading,
      data: notificationsData,
    }),
    [loading, notificationsData]
  );
};
