import { FC, useCallback, useEffect, useState } from 'react';

import { useMutation } from '@apollo/client';
import ErrorAlert from '@components/base/ErrorAlert';
import { IframeMultiMessage } from '@components/base/IframeTalk/IframeMessage';
import { UnsavedChangesModal } from '@components/elements/UnsavedChangesModal/UnsavedChangesModal';
import { ProfileGraphicLogo } from '@components/layout/Profile/ProfileGraphic/ProfileGraphicLogo';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import {
  useDeleteProfileLogo,
  useDeleteProfileLogoErrors,
} from '@hooks/useDeleteProfileLogo';
import { useUpdateProfileBackground } from '@hooks/useUpdateProfileBackground';
import { updateBusinessLogoUpdate } from '@internals/business-shared/src/cache/updates/updateBusinessLogo';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { BusinessUser } from '@internals/business-shared/src/types/User';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  GQLErrorState,
  UpdateBusinessLogoResponseError,
} from '@internals/business-shared/src/utils/errors';
import { CURRENT_USER_COMPANY_QUERY_business_Business } from '@internals/business-shared/src/utils/generated/generated';
import {
  UPDATE_BUSINESS_LOGO,
  UpdateBusinessLogoPayload,
  UpdateBusinessLogoVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusinessLogo/UpdateBusinessLogo';
import { ProfileOutletContext } from '@pages/Profile/Profile';
import Paths from '@router/paths';
import { useTranslation } from 'react-i18next';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { ProfileGraphicBackgroundImage } from './BackgroundImage';

export type CompanyLogoType = { file: { path: string }; previewUrl: string };
export const ProfileGraphic: FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const user = useUserContext();
  const { setIframeMessage } = useOutletContext<ProfileOutletContext>();
  const [errors, setErrors] = useState<GQLErrorState>({});
  const [deleteProfileLogoGQLErrors, setDeleteProfileLogoGQLErrors] =
    useState<GQLErrorState>({});

  const logoFromUser = useCallback(
    (u: BusinessUser) => ({
      file: u.operatingAs?.logo?.smallUrl
        ? { path: u.operatingAs?.logo?.name ?? '' }
        : null,
      previewUrl: u.operatingAs?.logo?.smallUrl ?? '',
    }),
    []
  );

  const [companyLogo, setCompanyLogo] = useState(logoFromUser(user));
  const [isFormDirty, setFormDirtyTrue, setFormDirtyFalse] = useBoolean(false);
  const [
    isUnsavedChangesModalOpen,
    openUnsavedChangesModal,
    closeUnsavedChangesModal,
  ] = useBoolean(false);
  const navigate = useNavigate();

  const [backgroundImage, setBackgroundImage] = useState<
    CURRENT_USER_COMPANY_QUERY_business_Business['profileHeaderImage']
  >(user.operatingAs.profileHeaderImage ?? '');

  const [
    uploadBusinessLogo,
    { data: uploadBusinessLogoData, loading: uploadBusinessLogoLoading },
  ] = useMutation<UpdateBusinessLogoPayload, UpdateBusinessLogoVariables>(
    UPDATE_BUSINESS_LOGO,
    {
      // TODO: May not be required to manually update the cache, if business logo data will be already in the query
      update: updateBusinessLogoUpdate(user.operatingAs.id),
    }
  );

  const { loading: deleteProfileLogoLoading, deleteLogo } =
    useDeleteProfileLogo();
  const [deleteProfileLogoErrors, checkForProfileLogoErrors] =
    useDeleteProfileLogoErrors();
  const {
    loading: deleteBackgroundLoading,
    deleteBackground,
    error: deleteBackgroundError,
  } = useUpdateProfileBackground();

  const handleBackButton = () => {
    if (
      !isFormDirty ||
      uploadBusinessLogoLoading ||
      deleteProfileLogoLoading ||
      deleteBackgroundLoading
    ) {
      navigate(Paths.Profile);
    }
    openUnsavedChangesModal();
  };

  const handleModalNavigateBack = () => {
    closeUnsavedChangesModal();
    setFormDirtyFalse();
    setIframeMessage(
      IframeMultiMessage([
        { name: 'logo', value: { url: logoFromUser(user).previewUrl || '' } },
        {
          name: 'profileHeaderImage',
          value: user.operatingAs.profileHeaderImage ?? '',
        },
      ])
    );

    navigate(Paths.Profile);
  };

  const handleSaveGraphics = useCallback(async () => {
    setFormDirtyFalse();
    setErrors({});
    track(ANEventSpace(ANEvent.Clicked, ANObject.SaveDesign, ANPage.Profile));

    if (backgroundImage === '') {
      await deleteBackground(user.operatingAs.id);
    }
    // File was removed by Dropzone component
    if (companyLogo?.file === null && companyLogo?.previewUrl === undefined) {
      await deleteLogo({
        onCompleted: (payload) => {
          // It's just for avoid the situation we want to delete image that was just picked from dropzone,and doesn't exist on the backend
          if (user.operatingAs?.logo !== null) {
            checkForProfileLogoErrors(payload);
          }
        },
        onError: () => {
          setDeleteProfileLogoGQLErrors({
            general: {
              msg: t('profile.graphic.logo.remove.error'),
              variant: 'danger',
            },
          });
        },
      });
      return;
    }
    // File was added with Dropzone component
    if (companyLogo?.file instanceof File) {
      await uploadBusinessLogo({
        variables: { input: { file: companyLogo.file } },
      });
    }
  }, [
    backgroundImage,
    checkForProfileLogoErrors,
    companyLogo.file,
    companyLogo?.previewUrl,
    deleteBackground,
    deleteLogo,
    setFormDirtyFalse,
    t,
    track,
    uploadBusinessLogo,
    user.operatingAs.id,
    user.operatingAs?.logo,
  ]);

  const handleModalSave = useCallback(async () => {
    await handleSaveGraphics();
    closeUnsavedChangesModal();
    navigate(Paths.Profile);
  }, [closeUnsavedChangesModal, handleSaveGraphics, navigate]);

  const handleSetLogoImage = useCallback(
    (bgImage: CompanyLogoType) => {
      setFormDirtyTrue();
      setCompanyLogo(bgImage);
    },
    [setFormDirtyTrue]
  );

  useEffect(() => {
    setCompanyLogo(logoFromUser(user));
    setBackgroundImage(user.operatingAs.profileHeaderImage ?? '');
  }, [logoFromUser, user]);

  useEffect(() => {
    if (!uploadBusinessLogoData) {
      return;
    }

    const uploadBusinessLogoError = new UpdateBusinessLogoResponseError(
      'ImageUploadPayload'
    );
    if (
      uploadBusinessLogoError.hasResponseError(
        uploadBusinessLogoData.uploadBusinessLogo,
        setErrors
      )
    ) {
      return;
    }
    setErrors({});
  }, [uploadBusinessLogoData]);

  useEffect(() => {
    setIframeMessage(
      IframeMultiMessage([
        { name: 'profileHeaderImage', value: backgroundImage ?? '' },
        {
          name: 'logo',
          value: {
            url:
              companyLogo.previewUrl || user.operatingAs.logo?.mediumUrl || '',
          },
        },
      ])
    );
  }, [
    backgroundImage,
    companyLogo,
    setIframeMessage,
    user.operatingAs.logo?.mediumUrl,
  ]);

  usePageAnalytics(ANSpace(ANObject.Design, ANPage.Profile));

  return (
    <>
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        isLoading={
          deleteProfileLogoLoading ||
          uploadBusinessLogoLoading ||
          deleteBackgroundLoading
        }
        onClose={closeUnsavedChangesModal}
        onSave={handleModalSave}
        onBackButton={handleModalNavigateBack}
      />
      <SectionHeader
        title={t('profile.graphic.title')}
        buttonText={t('general.label.save')}
        onButtonClick={handleSaveGraphics}
        onBackButton={handleBackButton}
        px={6}
        isButtonInProgress={
          uploadBusinessLogoLoading ||
          deleteProfileLogoLoading ||
          deleteBackgroundLoading
        }
      />
      <ProfileGraphicLogo
        companyLogo={companyLogo}
        setCompanyLogo={handleSetLogoImage}
      />
      <ErrorAlert errors={errors} />
      <ErrorAlert errors={deleteProfileLogoErrors} />
      <ErrorAlert errors={deleteProfileLogoGQLErrors} />
      <ErrorAlert errors={deleteBackgroundError} />
      <ProfileGraphicBackgroundImage
        setIframeMessage={setIframeMessage}
        companyLogo={companyLogo}
        backgroundImage={backgroundImage}
        setBackgroundImage={setBackgroundImage}
        onBackgroundImageDelete={setFormDirtyTrue}
      />
    </>
  );
};
