import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const Currency = styled(Text.p)`
  font-size: ${({ theme }) => theme.fontSizes[0]};
  display: block;
  margin: 0;
  padding: 0;
  text-align: center;
`;
