import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  QUALIFICATION_LIST_QUERYVariables,
  QUALIFICATION_LIST_QUERY as QUALIFICATION_LIST_QUERY_Result,
  QUALIFICATION_LIST_QUERY_business_Business,
  QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node,
} from '../../generated/generated';

export type QualificationListQueryPayload = QUALIFICATION_LIST_QUERY_Result;
export type QualificationListQueryVariables = QUALIFICATION_LIST_QUERYVariables;
export type QualificationListData =
  QUALIFICATION_LIST_QUERY_business_Business_certificateConnection_edges_node[];

export const isQualificationListQuerySuccessResponse = (
  business?: QUALIFICATION_LIST_QUERY_Result['business']
): business is QUALIFICATION_LIST_QUERY_business_Business =>
  !!(business && business.__typename === 'Business');

export const QUALIFICATION_LIST_QUERY = gql`
  ${IMAGE_FRAGMENT}

  query QUALIFICATION_LIST_QUERY($id: ID!) {
    business(id: $id) {
      ... on Business {
        id
        certificateConnection {
          edges {
            node {
              description
              id
              name
              logos {
                big {
                  ...ImageFragment
                }
                small {
                  ...ImageFragment
                }
                icon {
                  ...ImageFragment
                }
              }
            }
          }
        }
      }
      ... on BusinessInputError {
        message
        idError: id {
          message
        }
      }
      ... on BusinessNotFoundError {
        message
      }
    }
  }
`;
