import { FC } from 'react';

import { Div, Text, Avatar } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

type EvaluationBubbleProps = {
  message: string | null;
  date: string;
  avatar:
    | {
        src: string;
        initials?: never;
      }
    | {
        initials: string;
        src?: never;
      };
  isReply?: boolean;
};

const EvaluationBubble: FC<EvaluationBubbleProps> = ({
  message,
  date,
  avatar,
  isReply,
}) => {
  const themeContext = useTheme();

  const { t } = useTranslation();
  const displayMessage = message || t('evaluation.item.noDescription');
  const avatarComponent = (
    <Avatar
      image={avatar.src}
      initials={avatar.initials}
      backgroundSize={avatar.src ? 'contain' : null}
      mr={isReply ? 0 : 4}
      ml={isReply ? 4 : 0}
    />
  );

  return (
    <>
      <Text.p
        color={themeContext.colors.black.black6}
        fontWeight={themeContext.fontWeights.medium}
        textAlign="center"
        mb={0}
      >
        {date}
      </Text.p>
      <Div
        display="flex"
        alignItems="flex-end"
        justifyContent={isReply ? 'flex-end' : 'flex-start'}
        my={6}
      >
        {!isReply && avatarComponent}
        <Div
          background={
            isReply
              ? themeContext.colors.primary.base
              : themeContext.colors.black.black2
          }
          color={
            isReply
              ? themeContext.colors.black.black0
              : themeContext.colors.black.black9
          }
          px={5}
          py={4}
          borderRadius="15px"
          borderBottomLeftRadius={isReply ? '15px' : 0}
          borderBottomRightRadius={isReply ? 0 : '15px'}
          maxWidth="490px"
        >
          <Text.p
            mb={0}
            data-testid="message-bubble-text"
            fontStyle={message ? '' : 'italic'}
          >
            {displayMessage}
          </Text.p>
        </Div>
        {isReply && avatarComponent}
      </Div>
    </>
  );
};

export default EvaluationBubble;
