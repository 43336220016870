export const formatCardNumber = (cardNumber: string) => {
  const formattedSections = cardNumber
    .match(/.{1,4}/g)
    .map((section) =>
      section
        .replace(/./g, (char) => (/\d/.test(char) ? char : `${char} `))
        .trim()
    );
  return formattedSections.join('   ').replace(/ /g, '\u00A0');
};
