import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const PrintDataContainer = styled(Div)`
  & > * {
    @media print {
      page-break-after: always;
    }
  }
`;

export default PrintDataContainer;
