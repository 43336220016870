import { Div, hexToRgba } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export interface DivActionButtonProps {
  background: string;
  backgroundHover?: string;
  outlineColor?: string;
  fill: string;
  fillHover?: string;
  size?: string;
}

const setSizes = (props: any): { buttonSize: number; iconSize: number } => {
  switch (props.size) {
    case 'big':
      return { buttonSize: 40, iconSize: 20 };
    case 'medium':
      return { buttonSize: 30, iconSize: 16 };
    case 'small':
      return { buttonSize: 24, iconSize: 12 };
    default:
      return { buttonSize: 30, iconSize: 16 };
  }
};

const DivActionButton = styled(Div)<DivActionButtonProps>`
  display: flex;
  align-items: center;
  width: ${(props): number => setSizes(props).buttonSize}px;
  height: ${(props): number => setSizes(props).buttonSize}px;
  padding: 0;
  margin-left: 5px;
  justify-content: center;
  background: ${(props): string => props.background};
  border: 0;
  border-radius: 50%;
  border: 1px solid ${(props): string => props.theme.colors.black.black4};
  transition: all ease-in-out
    ${(props): string => props.theme.animationSpeed.fast};
  transition: box-shadow ease-in-out
    ${(props): string => props.theme.animationSpeed.xfast};

  &:focus {
    border: 2px solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0 3px
      ${(props): string =>
        hexToRgba(props.outlineColor || props.theme.colors.primary.base, 0.25)};
  }

  &:active {
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  svg {
    height: ${(props): number => setSizes(props).iconSize}px;
    width: ${(props): number => setSizes(props).iconSize}px;

    path {
      fill: ${(props): string => props.fill};
    }
  }

  &:hover {
    ${(props): string =>
      props.outlineColor
        ? `box-shadow: 0 0 0 3px ${hexToRgba(
            props.outlineColor || props.theme.colors.primary.base,
            0.25
          )};`
        : ''}
    ${(props): string =>
      props.backgroundHover
        ? `
    box-shadow: 0 0 0 3px ${hexToRgba(
      props.outlineColor || props.backgroundHover,
      0.25
    )};
    background: ${props.backgroundHover};
    `
        : ''}
    ${(props): string =>
      props.fillHover
        ? `
        svg {
      path {
        fill: ${props.fillHover};
      }
    }
    `
        : ''}
  }
`;

export default DivActionButton;
