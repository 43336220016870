import gql from 'graphql-tag';

import {
  CHOOSE_BUSINESS_WORK_TYPESVariables,
  CHOOSE_BUSINESS_WORK_TYPES as CHOOSE_BUSINESS_WORK_TYPES_Result,
} from '../../generated/generated';

export type ChooseBusinessWorkTypesPayload = CHOOSE_BUSINESS_WORK_TYPES_Result;
export type ChooseBusinessWorkTypesVariables =
  CHOOSE_BUSINESS_WORK_TYPESVariables;

export const CHOOSE_BUSINESS_WORK_TYPES = gql`
  mutation CHOOSE_BUSINESS_WORK_TYPES($businessId: ID!, $workTypeIds: [ID!]!) {
    chooseBusinessWorkTypes(
      input: { businessId: $businessId, workTypeIds: $workTypeIds }
    ) {
      workTypes {
        id
      }
    }
  }
`;
