import gql from 'graphql-tag';

import {
  JOB_FOLDERS_QUERY as JobFoldersQueryType,
  JOB_FOLDERS_QUERY_jobFolders,
  JOB_FOLDERS_QUERY_jobFolders_folders,
} from '../../generated/generated';

export type JobFoldersQueryPayload = JobFoldersQueryType;
export type JobFolders = JOB_FOLDERS_QUERY_jobFolders;
export type JobFolder = JOB_FOLDERS_QUERY_jobFolders_folders;
export type JobFolderId = JobFolder['id'];

export const JOB_FOLDERS_QUERY = gql`
  query JOB_FOLDERS_QUERY {
    jobFolders {
      folders {
        id
        title
        color
      }
    }
  }
`;
