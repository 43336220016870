import { FC } from 'react';

import { SimpleContractDetailsContainer } from '@components/layout/CustomerService/Contract/styled/SimpleContractDetailsContainer';
import { StyledContractDetailsPrint } from '@components/layout/CustomerService/Contract/styled/StyledContractDetailsPrint';
import { StyledDescription } from '@components/layout/CustomerService/Contract/styled/StyledDescription';
import { StyledInfo } from '@components/layout/CustomerService/Contract/styled/StyledInfo';
import { StyledLabel } from '@components/layout/CustomerService/Contract/styled/StyledLabel';
import { StyledTitle } from '@components/layout/CustomerService/Contract/styled/StyledTitle';
import contractPriceLabel from '@internals/business-shared/src/components/Customers/Contract/ContractLabels/contractPriceLabel';
import { ContractPriceType } from '@internals/business-shared/src/utils/generated/generated';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import { isEmptyTrimmedString } from '@internals/business-shared/src/utils/types/StringUtils';
import { dateFromTimestamp } from '@utils/date';
import { useTranslation } from 'react-i18next';

export const SimpleContractDetails: FC<{ contract: SimpleContract }> = ({
  contract,
}) => {
  const { t } = useTranslation();

  return (
    <SimpleContractDetailsContainer>
      <StyledContractDetailsPrint />
      <StyledTitle fontSize={4} mb={6}>
        {contract.job.title}
      </StyledTitle>
      <StyledLabel>{t('general.label.client')}</StyledLabel>
      <StyledInfo>
        {contract.user.firstName} {contract.user.lastName}
      </StyledInfo>
      <StyledLabel>{t('general.label.clientEmail')}</StyledLabel>
      <StyledInfo>{contract.job.contactInfo?.email || '-'}</StyledInfo>
      <StyledLabel>{t('general.label.clientPhone')}</StyledLabel>
      <StyledInfo>{contract.job.contactInfo?.phone || '-'}</StyledInfo>
      <StyledLabel>{t('contract.item.supplier')}</StyledLabel>
      <StyledInfo>{contract.company.name}</StyledInfo>
      <StyledLabel>{t('contract.item.supplierEmail')}</StyledLabel>
      <StyledInfo>{contract.businessEmail || '-'}</StyledInfo>
      <StyledLabel>{t('contract.item.supplierPhone')}</StyledLabel>
      <StyledInfo>{contract.businessPhone || '-'}</StyledInfo>
      {contract.priceType !== ContractPriceType.OTHER && (
        <>
          <StyledLabel>
            {t('contract.item.simpleContract.totalAmount')}
          </StyledLabel>
          <StyledLabel>
            {/*
              t('contract.item.priceLabel_fixed')
              t('contract.item.priceLabel_hourComputation')
              t('contract.item.priceLabel_unknown')
            */}
            {t('contract.item.priceLabel', {
              context: contractPriceLabel(contract.priceType),
              price: contract.price,
              defaultValue: '-',
            })}
          </StyledLabel>
          <StyledInfo />
        </>
      )}
      {contract.startDate && (
        <>
          <StyledLabel>{t('general.dateTime.startDate')}</StyledLabel>
          <StyledInfo>
            {dateFromTimestamp(contract.startDate, 'DD MMMM YYYY')}
          </StyledInfo>
        </>
      )}
      {contract.endDate && (
        <>
          <StyledLabel>{t('general.dateTime.endDate')}</StyledLabel>
          <StyledInfo>
            {dateFromTimestamp(contract.endDate, 'DD MMMM YYYY')}
          </StyledInfo>
        </>
      )}
      <StyledLabel>{t('contract.item.id')}</StyledLabel>
      <StyledInfo>{contract.id}</StyledInfo>
      {!isEmptyTrimmedString(contract.description) && (
        <>
          <StyledLabel>{t('general.label.description')}</StyledLabel>
          <StyledDescription>{contract.description}</StyledDescription>
        </>
      )}
    </SimpleContractDetailsContainer>
  );
};
