import { ButtonLink } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FileLink = styled(ButtonLink)`
  border-radius: ${({ theme }) => theme.radii[1]};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[5]}px;
  margin: ${({ theme }) => `0 ${theme.space[4]}px ${theme.space[4]}px 0`};
  &,
  &:hover,
  &:focus {
    border-width: 1px;
  }
`;

export default FileLink;
