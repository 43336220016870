import { useEffect } from 'react';

import { JobWatchlistNotificationFrequency } from '@internals/business-shared/src/utils/generated/generated';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export interface SavedFilterFormValues {
  title: string;
  emailFrequency: JobWatchlistNotificationFrequency;
  pushFrequency: JobWatchlistNotificationFrequency;
}

export const SAVED_FILTER_TITLE_MAX_LENGTH = 50;

export const supportedNotificationFrequencyValues = [
  JobWatchlistNotificationFrequency.NEVER,
  JobWatchlistNotificationFrequency.IMMEDIATE,
];

type UseSavedFilterFormikType = (
  onSubmit: (values: SavedFilterFormValues) => void,
  onDirty: (isChanged: boolean) => void,
  initialValues?: SavedFilterFormValues
) => FormikProps<SavedFilterFormValues>;

export const useSavedFilterFormik: UseSavedFilterFormikType = (
  onSubmit,
  onDirty,
  initialValues
) => {
  const { t } = useTranslation();

  const formik: FormikProps<SavedFilterFormValues> = useFormik({
    initialValues: {
      title: '',
      emailFrequency: JobWatchlistNotificationFrequency.IMMEDIATE,
      pushFrequency: JobWatchlistNotificationFrequency.IMMEDIATE,
      ...(initialValues || {}),
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .trim()
        .required(t('general.form.validation.required'))
        .max(
          SAVED_FILTER_TITLE_MAX_LENGTH,
          t('general.form.validation.string.maxLength', {
            value: SAVED_FILTER_TITLE_MAX_LENGTH,
          })
        ),
      emailFrequency: Yup.string()
        .oneOf(supportedNotificationFrequencyValues)
        .required(),
      pushFrequency: Yup.string()
        .oneOf(supportedNotificationFrequencyValues)
        .required(),
    }),
    onSubmit: ({ title, emailFrequency, pushFrequency }) =>
      onSubmit({
        title: title.trim(),
        emailFrequency,
        pushFrequency,
      }),
  });

  useEffect(() => {
    if (formik.dirty) {
      onDirty(true);
    }
  }, [onDirty, formik.dirty]);

  return formik;
};
