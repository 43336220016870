import { getProductZipCodeLength } from './constants/addressFormat';
import {
  AddressFragment,
  JOB_QUERY_jobMeta,
  JOB_QUERY_job_Job,
  JOB_QUERY_job_Job_address,
  POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress,
} from './generated/generated';
import { CompanyInfoQueryBusiness } from './query/CompanyInfo/CompanyInfoQuery';

export type BasicPostalAddressType = Pick<
  POSTAL_ADDRESS_QUERY_postalAddress_PostalAddress,
  'postalCode' | 'city'
>;

export type BusinessAddressKind = 'billing' | 'visit' | 'post';
export type CountryName = 'Finland' | 'Norway' | 'Denmark' | 'Sweden' | '';
export type JobAddress = JOB_QUERY_job_Job['address'];
export type JobAddressHint = JOB_QUERY_job_Job['addressHint'];
export type JobAddressTo = JOB_QUERY_jobMeta['toAddress'];
export type JobWithAddress = Pick<
  JOB_QUERY_job_Job,
  'address' | 'addressHint'
> & {
  jobBusinessRelationState: {
    isAnswered: boolean;
  };
};

export type JobMapAddress = Partial<JOB_QUERY_job_Job_address> & {
  country: CountryName;
};

export const formatZipCode = (zipCode: string, productId: string): string => {
  const length = getProductZipCodeLength(productId);
  const input = zipCode.replace(/[^0-9]/g, '').substring(0, length);

  switch (productId) {
    case 'servicefinder':
      return input.replace(/(\d{3})(\d{2})?/, '$1 $2').trim();

    default:
      return input;
  }
};

const isGQLAddress = (
  address: AddressFragment | BasicPostalAddressType
): address is AddressFragment => 'zip' in address;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isAddressFragment = (address: any): address is AddressFragment => {
  return !!address && address.__typename === 'Address';
};

export const businessAddressString = (
  addresses: CompanyInfoQueryBusiness['addresses'],
  type: BusinessAddressKind,
  productId: string
): string => {
  const address: Partial<AddressFragment> = addresses?.[type]?.[0] ?? {
    street: '',
    city: '',
    zip: '',
  };
  return `${address.street}, ${formatZipCode(address.zip, productId)} ${
    address.city
  }`;
};

export const addressString = (
  productId: string,
  address?: Pick<AddressFragment, 'street' | 'zip' | 'city'>
): string => {
  if (!address) {
    return '';
  }

  return `${address?.street ?? ''}, ${
    formatZipCode(address?.zip, productId) ?? ''
  } ${address?.city ?? ''}`;
};

export const addressStringShort = (
  address: AddressFragment | BasicPostalAddressType,
  productId: string
): string => {
  if (!address) {
    return '';
  }

  return isGQLAddress(address)
    ? `${formatZipCode(address?.zip ?? '', productId)} ${address?.city ?? ''}`
    : `${formatZipCode(address?.postalCode ?? '', productId)} ${
        address?.city ?? ''
      }`;
};

export const getJobMapAddress = (
  job: JobWithAddress,
  countryName: CountryName,
  productId: string
): JobMapAddress => {
  const { address, addressHint } = job || {};

  if (!address && !addressHint) {
    return null;
  }
  const zip = formatZipCode(
    address?.zip ?? addressHint?.postalCode ?? '',
    productId
  );

  return {
    lat: address?.lat ?? addressHint?.lat ?? '',
    lon: address?.lon ?? addressHint?.lon ?? '',
    street: address?.street ?? '',
    city: address?.city ?? addressHint?.city ?? '',
    zip,
    country: countryName,
  };
};

export const jobMapAddressToString = (
  mapAddress: JobMapAddress,
  productId: string
): string => {
  if (!mapAddress) {
    return '';
  }

  const { street = '', zip, city, country } = mapAddress;
  return `${street} ${formatZipCode(zip, productId)} ${city} ${country}`.trim();
};

export const getAddressForMaps = (JobMapAddress: JobMapAddress): string => {
  if (!JobMapAddress?.city) return JobMapAddress.country;
  if (!JobMapAddress?.zip)
    return `${JobMapAddress.city}, ${JobMapAddress.country}`;
  const partialAddress = `${JobMapAddress?.zip} ${JobMapAddress?.city}, ${JobMapAddress?.country}`;
  return encodeURIComponent(
    JobMapAddress.street
      ? `${JobMapAddress.street} ${partialAddress}`
      : partialAddress
  );
};
