export enum PaymentStatus {
  INIT = 'INIT',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  RESET = 'RESET',
}

export type PaymentState =
  | {
      status: PaymentStatus.INIT;
      loading: true;
      errorMessage: null;
      success: false;
    }
  | {
      status: PaymentStatus.SUCCEEDED;
      loading: false;
      errorMessage: null;
      success: true;
    }
  | {
      status: PaymentStatus.FAILED;
      loading: false;
      errorMessage: string;
      success: false;
    }
  | {
      status: PaymentStatus.RESET;
      loading: false;
      errorMessage: null;
      success: false;
    };

export type PaymentAction = {
  type: PaymentStatus;
  errorMessage?: string;
};

export const initialState: PaymentState = {
  status: PaymentStatus.RESET,
  loading: false,
  errorMessage: null,
  success: false,
};

export const PaymentReducer = (
  state: PaymentState,
  action: PaymentAction
): PaymentState => {
  switch (action.type) {
    case PaymentStatus.INIT:
      return {
        ...state,
        status: PaymentStatus.INIT,
        loading: true,
        errorMessage: null,
        success: false,
      };
    case PaymentStatus.SUCCEEDED:
      return {
        ...state,
        status: PaymentStatus.SUCCEEDED,
        loading: false,
        errorMessage: null,
        success: true,
      };
    case PaymentStatus.FAILED:
      return {
        ...state,
        status: PaymentStatus.FAILED,
        loading: false,
        errorMessage: action?.errorMessage ?? 'Unknown error',
        success: false,
      };
    case PaymentStatus.RESET:
      return initialState;
    default:
      throw Error(`Unknown action: ${action.type}`);
  }
};
