// utils for customerServiceAvailability for all platforms, in UTC time
import { ProductId } from '../appProducts';

export const customerServiceAvailability: Record<
  ProductId,
  { start: number; end: number }
> = {
  [ProductId.Mittanbud]: {
    start: 7, // 09 in oslo time
    end: 14, // 16 in oslo time
  },
  [ProductId.Remppatori]: {
    start: 6, // 07 in oslo time
    end: 14, // 15 in oslo time
  },
  [ProductId.ByggetilbudMatch]: {
    start: 7, // 08 in oslo time
    end: 14, // 16 in oslo time
  },
  [ProductId.Servicefinder]: {
    start: 7, // 09 in oslo time
    end: 14, // 16 in oslo time
  },
};
