import gql from 'graphql-tag';

import {
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY as NOTIFICATION_DESTINATIONS_ENABLED_QUERY_Result,
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled,
} from '../../generated/generated';

export type NotificationDestinationsEnabledQuery =
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY_Result;

export type NotificationDestinationsEnabled =
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled;

export const NOTIFICATION_DESTINATIONS_ENABLED_QUERY = gql`
  query NOTIFICATION_DESTINATIONS_ENABLED_QUERY {
    notificationDestinationsEnabled {
      id
      type
      defaultOn
      isToggleable
      delay
    }
  }
`;
