import * as React from 'react';

import { Div, Text, Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface JobListItemDeletedProps {
  id: string;
  title: string;
  isLast: boolean;
  onRestore: VoidFunction;
}

const JobListItemDeleted: React.FC<
  React.PropsWithChildren<JobListItemDeletedProps>
> = ({ id, title, isLast, onRestore }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      display="flex"
      mx={6}
      borderBottom={
        isLast ? 'none' : `1px solid ${themeContext.colors.black.black2}`
      }
      data-testid={`job-list-item-deleted-${id}`}
    >
      <Div
        height="40px"
        borderRadius={themeContext.radii[1]}
        backgroundColor="yellow.lightest"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        px={4}
        my={6}
        alignItems="center"
      >
        <Text.span mb={0} fontWeight={themeContext.fontWeights.medium}>
          {t('job.list.item.hasBeenDeleted', {
            jobTitle: title.length > 16 ? `${title.slice(0, 16)}...` : title,
          })}
        </Text.span>
        <Div marginLeft="10px">
          <Button
            variant="primary"
            size="tiny"
            height="26px"
            onClick={onRestore}
          >
            {t('general.label.undelete')}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default JobListItemDeleted;
