import gql from 'graphql-tag';

import {
  TOGGLE_NOTIFICATION_DESTINATIONVariables,
  TOGGLE_NOTIFICATION_DESTINATION as TOGGLE_NOTIFICATION_DESTINATION_Result,
} from '../../generated/generated';

export type ToggleNotificationDestinationPayload =
  TOGGLE_NOTIFICATION_DESTINATION_Result;
export type ToggleNotificationDestinationVariables =
  TOGGLE_NOTIFICATION_DESTINATIONVariables;

export const TOGGLE_NOTIFICATION_DESTINATION = gql`
  mutation TOGGLE_NOTIFICATION_DESTINATION(
    $input: ToggleNotificationDestinationInput!
  ) {
    toggleNotificationDestination(input: $input) {
      success
      destinations {
        id
        type
        defaultOn
        isToggleable
        delay
      }
    }
  }
`;
