import { ApolloCache } from '@apollo/client';
import { InternalRefetchQueryDescriptor } from '@apollo/client/core/types';

import { clearJobLists } from '../../cache/clearJobLists';
import JobListId from '../constants/jobListIds';
import { JOB_LIST_FILTERS_QUERY } from '../query/JobListFilters/JobListFiltersQuery';
import { JOBS_LIST_QUERY } from '../query/JobsList/JobsListQuery';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onCompleted = (cache: ApolloCache<any>): void => {
  clearJobLists(cache);
};

export const refetchQueries: InternalRefetchQueryDescriptor[] = [
  { query: JOB_LIST_FILTERS_QUERY },
  {
    query: JOBS_LIST_QUERY,
    variables: { listId: JobListId.Open, skipAnsweredOnlyFields: true },
  },
];

const JobSearchSettingsMutationOptions = {
  onCompleted,
  refetchQueries,
};

export default JobSearchSettingsMutationOptions;
