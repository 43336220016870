import { FC, useCallback } from 'react';

import { FeatureFlag } from '@components/elements/FeatureFlag';
import { SimpleContractDetails } from '@components/layout/CustomerService/Contract/SimpleContractDetails';
import useAnalytics from '@hooks/useAnalytics';
import { useRejectSimpleAgreementContractMutation } from '@internals/business-shared/src/hooks/mutation/useRejectSimpleAgreementContractMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { isRejectSimpleAgreementContractMutationSuccessResponse } from '@internals/business-shared/src/utils/mutation/SimpleAgreementContract/RejectSimpleAgreementContractMutation';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import { Button, Heading, Modal, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { Trans, useTranslation } from 'react-i18next';

type SimpleContractModalProps = {
  contract: SimpleContract;
  onClose: VoidFunction;
  onEdit: VoidFunction;
};
export const SimpleContractDetailsModal: FC<SimpleContractModalProps> = ({
  contract,
  onClose,
  onEdit,
}) => {
  const date = dateFromTimestamp(contract.regTime, 'ddd DD.MM.YYYY');
  const { track } = useAnalytics();
  const { t } = useTranslation();

  const [rejectSimpleAgreementContract, { loading: rejectLoading }] =
    useRejectSimpleAgreementContractMutation();

  const rejectAgreement = useCallback(async () => {
    const result = await rejectSimpleAgreementContract({
      agreementId: contract.id,
    });

    if (
      !isRejectSimpleAgreementContractMutationSuccessResponse(
        result?.data?.rejectSimpleAgreementContract
      )
    ) {
      ToastMessage(t('contract.item.rejectFailed'), 'danger');
      bugsnagClient.notify(
        'Reject simple agreement contract failed due to mutation unknown response type'
      );
      return;
    }
    track(
      ANEventSpace(ANEvent.Cancelled, ANObject.Agreement, ANPage.Customers)
    );
    onClose();
  }, [contract.id, onClose, rejectSimpleAgreementContract, t, track]);

  const onModalClose = useCallback(() => {
    if (rejectLoading) return;
    onClose();
  }, [onClose, rejectLoading]);

  return (
    <Modal
      isOpen
      onClose={onModalClose}
      isClosable
      size="custom"
      maxWidth="500px"
      header={
        <Heading.h2 mb={7} textAlign="center">
          {contract.user.firstName} {contract.user.lastName}
        </Heading.h2>
      }
      footer={
        <>
          <Button
            onClick={rejectAgreement}
            type="button"
            variant="tertiary"
            isLoading={rejectLoading}
            disabled={rejectLoading}
            size="tiny"
            data-testid="reject-agreement-btn"
            flexGrow={1}
          >
            {t('contract.item.simpleContract.suggestion.rejectAgreementButton')}
          </Button>
          <FeatureFlag name={FeatureFlags.BizContractNew}>
            <Button
              variant="primary"
              size="small"
              type="button"
              onClick={onEdit}
              disabled={rejectLoading}
            >
              {t('contract.item.simpleContract.suggestion.editAgreementButton')}
            </Button>
          </FeatureFlag>
        </>
      }
      contentProps={{ px: '45px', mt: 5 }}
      footerProps={{
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'stretch',
        minHeight: '60px',
        mt: 6,
        px: '45px',
        mb: 5,
      }}
      headerProps={{ marginBottom: 0 }}
      testId="simple-contract-details"
    >
      <SimpleContractDetails contract={contract} />
      <Text.p fontSize={1} textAlign="center">
        <Trans
          i18nKey="contract.item.simpleContract.agreementCreated"
          values={{ date }}
          components={{ bold: <b /> }}
        />
      </Text.p>
    </Modal>
  );
};
