import { FC } from 'react';

import { StyledFilterCountBadge } from '@components/elements/PillNavigation/styled';
import { Text } from '@schibsted-smb/fireball';

interface FilterCountBadgeProps {
  count: number;
}

const MAX_DISPLAYED_COUNT = 9;
const EXCEEDED_DISPLAYED_COUNT = '9+';

export const FilterCountBadge: FC<FilterCountBadgeProps> = ({ count }) => {
  if (!count) {
    return null;
  }

  const displayedCount =
    count <= MAX_DISPLAYED_COUNT ? count : EXCEEDED_DISPLAYED_COUNT;

  return (
    <StyledFilterCountBadge>
      <Text.span mb={0} ml="1px" fontSize={0}>
        {displayedCount}
      </Text.span>
    </StyledFilterCountBadge>
  );
};
