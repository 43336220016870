import { GraphQLErrors } from '@apollo/client/errors';

export const AUTH_ERROR_CODES = ['AUTH_NOT_LOGGED_IN', 'AUTH_NOT_COMPANY'];
// TODO: revert after error handling in gql is fixed
// export const RETRYABLE_ERROR_CODES = ['FETCH_ERROR'];
export const RETRYABLE_ERROR_CODES = [];

export const filterOutNonAuthErrors = (
  graphQLErrors?: GraphQLErrors
): GraphQLErrors => {
  if (!graphQLErrors) return [];
  return graphQLErrors?.filter((gqlError) =>
    AUTH_ERROR_CODES.includes(gqlError?.extensions?.code)
  );
};

export const filterOutAuthErrors = (
  graphQLErrors?: GraphQLErrors
): GraphQLErrors => {
  if (!graphQLErrors) return [];
  return graphQLErrors?.filter(
    (gqlError) => !AUTH_ERROR_CODES.includes(gqlError?.extensions?.code)
  );
};

export const isRetryableError = (graphQLErrors?: GraphQLErrors): boolean => {
  return graphQLErrors?.some((gqlError) =>
    RETRYABLE_ERROR_CODES.includes(gqlError?.extensions?.code)
  );
};

export const isAuthNoAccessError = (graphQLErrors?: GraphQLErrors): boolean => {
  return graphQLErrors?.some(
    (graphQLError) => graphQLError.extensions?.code === 'AUTH_NO_ACCESS'
  );
};
