import { FC } from 'react';

import { ReactComponent as XLLogoWhite } from '@internals/business-shared/src/assets/illustrations/XLLogoWhite.svg';
import {
  Button,
  Column,
  Container,
  Div,
  Heading,
  Row,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface CompletedStateProps {
  onClose: VoidFunction;
}

export const CompletedState: FC<CompletedStateProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="360px" pb={9}>
      <Row>
        <Column>
          <Div width={90} mx="auto" mb={6}>
            <XLLogoWhite />
          </Div>
          <Div color="black.black0" mb={8}>
            <Heading.h2 pb={3}>
              {t('job.modal.mittanbudXL.completed.title')}
            </Heading.h2>
            <Text.p textAlign="center">
              {t('job.modal.mittanbudXL.completed.description')}
            </Text.p>
          </Div>
          <Button onClick={onClose} fullWidth>
            {t('general.label.close')}
          </Button>
        </Column>
      </Row>
    </Container>
  );
};
