import { FC, useState } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import WorkTypeGroupView from '@components/layout/Jobs/Filters/WorkTypes/WorkTypeGroup';
import WorkTypeView from '@components/layout/Jobs/Filters/WorkTypes/WorkTypeGroupSelect';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import {
  BaseWorkType,
  WorkTypeGroup as WorkTypeGroupType,
} from '@internals/business-shared/src/utils/workTypesUtils';
import { useTranslation } from 'react-i18next';

enum WorkTypeFilterViewType {
  WorkTypeGroupSelect = 'WorkTypeGroupSelect',
  WorkTypeGroup = 'WorkTypeGroup',
}

interface WorkTypeFilterButtonProps {
  onClick: VoidFunction;
  children?: never;
}

interface WorkTypeFilterProps {
  onBackClick: VoidFunction;
  children?: never;
}

export const WorkTypeFilter: FC<WorkTypeFilterProps> = ({ onBackClick }) => {
  const [activeView, setActiveView] = useState<WorkTypeFilterViewType>(
    WorkTypeFilterViewType.WorkTypeGroupSelect
  );
  const [activeWorkTypeGroup, setActiveWorkTypeGroup] =
    useState<WorkTypeGroupType | null>(null);

  const handleWorkTypeGroupChange = (workTypeGroup: WorkTypeGroupType) => {
    setActiveView(WorkTypeFilterViewType.WorkTypeGroup);
    setActiveWorkTypeGroup(workTypeGroup);
  };

  if (
    activeView === WorkTypeFilterViewType.WorkTypeGroup &&
    activeWorkTypeGroup
  ) {
    return (
      <WorkTypeGroupView
        workTypeGroup={activeWorkTypeGroup}
        onBackClick={() =>
          setActiveView(WorkTypeFilterViewType.WorkTypeGroupSelect)
        }
      />
    );
  }

  return (
    <WorkTypeView
      onBackClick={onBackClick}
      onWorkTypeGroupExpand={handleWorkTypeGroupChange}
    />
  );
};

export const WorkTypeFilterButton: FC<WorkTypeFilterButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation();
  const {
    workTypes,
    activeWorkTypes: activeWorkTypeIds,
    setActiveWorkTypes,
  } = useJobFiltersContext();
  const allWorkTypes = workTypes.flatMap(
    (workTypeGroup) => workTypeGroup.workTypes
  );
  const activeWorkTypes = allWorkTypes.reduce<BaseWorkType[]>((list, item) => {
    if (
      !activeWorkTypeIds.includes(item.id) ||
      list.some((wt) => wt.id === item.id)
    )
      return list;
    return [...list, item];
  }, []);

  const onSelectedClick = (workTypeFilterId: string | string[]) => {
    if (typeof workTypeFilterId === 'string') {
      setActiveWorkTypes(
        toggleItemInArray(activeWorkTypeIds, workTypeFilterId)
      );
      return;
    }
    setActiveWorkTypes([]);
  };

  if (!workTypes.length) return null;

  return (
    <FilterGroupButton
      filterName={t('job.list.filters.workType.title')}
      onClick={onClick}
      selectedFilters={activeWorkTypes}
      onSelectedClick={onSelectedClick}
    />
  );
};

export default WorkTypeFilter;
