import { FC, useMemo } from 'react';

import { ReactComponent as ByggetilbudMatchLogo } from '@internals/business-shared/src/assets/icons/ByggetilbudMatchLogo.svg';
import { ReactComponent as ByggetilbudMatchLogoDark } from '@internals/business-shared/src/assets/icons/ByggetilbudMatchLogoDark.svg';
import { ReactComponent as MittanbudLogo } from '@internals/business-shared/src/assets/icons/MittanbudLogo.svg';
import { ReactComponent as MittanbudLogoDark } from '@internals/business-shared/src/assets/icons/MittanbudLogoDark.svg';
import { ReactComponent as LogoMark } from '@internals/business-shared/src/assets/icons/MittanbudLogoMark.svg';
import { ReactComponent as RemppatoriLogo } from '@internals/business-shared/src/assets/icons/RemppatoriLogo.svg';
import { ReactComponent as RemppatoriLogoDark } from '@internals/business-shared/src/assets/icons/RemppatoriLogoDark.svg';
import { ReactComponent as ServicefinderLogo } from '@internals/business-shared/src/assets/icons/ServicefinderLogo.svg';
import { ReactComponent as ServicefinderLogoDark } from '@internals/business-shared/src/assets/icons/ServicefinderLogoDark.svg';
import { ProductId } from '@internals/business-shared/src/utils/appProducts';
import { getProductId } from '@utils/product';

interface LogoProps {
  isDark?: boolean;
  isIconOnly?: boolean;
  height?: string;
}

const DEFAULT_HEIGHT = '24px';

export const Logo: FC<LogoProps> = ({
  isDark,
  isIconOnly,
  height = DEFAULT_HEIGHT,
}) => {
  const productId = getProductId();

  const getLogo = () => {
    if (isIconOnly) {
      return <LogoMark height={height} />;
    }

    if (productId === ProductId.Mittanbud) {
      return isDark ? (
        <MittanbudLogoDark height={height} />
      ) : (
        <MittanbudLogo height={height} />
      );
    }

    if (productId === ProductId.Remppatori) {
      return isDark ? (
        <RemppatoriLogoDark height={height} />
      ) : (
        <RemppatoriLogo height={height} />
      );
    }

    if (productId === ProductId.ByggetilbudMatch) {
      return isDark ? (
        <ByggetilbudMatchLogoDark height={height} />
      ) : (
        <ByggetilbudMatchLogo height={height} />
      );
    }

    if (productId === ProductId.Servicefinder) {
      return isDark ? (
        <ServicefinderLogoDark height={height} />
      ) : (
        <ServicefinderLogo height={height} />
      );
    }

    return <MittanbudLogo height={height} />;
  };

  return useMemo(getLogo, [isDark, isIconOnly, height, productId]);
};
