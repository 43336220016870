import * as React from 'react';

import JobReportView from './JobReport';

export interface JobReportProps {
  jobId: string | undefined;
  onClose: () => void;
}

const JobReport: React.FC<React.PropsWithChildren<JobReportProps>> = ({
  jobId,
  onClose,
}: JobReportProps) => {
  return <JobReportView jobId={jobId} onClose={onClose} />;
};

export default JobReport;
