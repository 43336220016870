/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, ReactElement, useMemo } from 'react';

import { ImageFragment } from '@internals/business-shared/src/utils/generated/generated';
import { useIsMobile } from '@root/src/contexts/DeviceSizeContext';
import { getEnvValue, ENV_KEY_NAME } from '@root/src/utils/getEnvValue';
import { Div, DivProps, ImageProps } from '@schibsted-smb/fireball';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-transitions.css';
import { useTheme } from 'styled-components';

import { MessageLoader } from '../ChatMessage/styled';
import { GalleryWrapper, ImageWrapper } from './styled';

interface GalleryImageItemProps {
  item: ImageFragment;
  containerProps?: DivProps;
  imageProps?: Partial<Omit<ImageProps, 'src' | 'alt'>>;
  isLoading?: boolean;
  onClick?: () => void;
}

interface GalleryProps {
  children: ReactElement | ReactElement[];
  grid?: boolean;
  row?: boolean;
}

export const GalleryImageItem: React.FC<GalleryImageItemProps> = ({
  item,
  imageProps,
  isLoading,
  onClick,
}) => {
  const theme = useTheme();
  const mappedItem = useMemo(() => {
    return {
      id: item.id,
      src: item.url,
      thumb: item.smallUrl,
      alt: item.description,
      width: item.dimensions?.width,
      height: item.dimensions?.height,
    };
  }, [item]);

  const mergedImageProps = useMemo(() => {
    return {
      src: mappedItem?.src,
      alt: mappedItem?.alt,
      borderRadius: theme.radii[1],
      ...imageProps,
    };
  }, [mappedItem?.src, mappedItem?.alt, theme.radii, imageProps]);
  return (
    <Div
      data-lg-size={`${mappedItem?.width}-${mappedItem?.height}`}
      className="gallery-item"
      data-src={mappedItem.src}
      position="relative"
      onClick={onClick}
    >
      <ImageWrapper {...mergedImageProps} src={mappedItem.thumb} />
      {isLoading && <MessageLoader {...mergedImageProps} />}
    </Div>
  );
};

export const Gallery: FC<GalleryProps> = ({ children, grid, row }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  return (
    <GalleryWrapper grid={grid} isMobile={isMobile} row={row}>
      <style>
        {`
        .lg-outer .lg-thumb-item:hover {
          border-color: ${theme.colors.primary.base};
          pointer: cursor;
        }
        .lg-outer .lg-thumb-item.active {
          border-color: ${theme.colors.primary.darker};
        }
      `}
      </style>
      <LightGallery
        licenseKey={getEnvValue(ENV_KEY_NAME.LIGHTGALLERY)}
        speed={500}
        plugins={[lgThumbnail]}
        mode="lg-fade"
        download={false}
      >
        {children}
      </LightGallery>
    </GalleryWrapper>
  );
};
