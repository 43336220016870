import { t } from '@internals/business-translations/src/i18n';

import { SystemMessageOutput, SystemMessageType } from './index';

export const boligMappaSystemMessage = (
  type: SystemMessageType,
  businessName = ''
): SystemMessageOutput | null => {
  switch (type) {
    case SystemMessageType.BOLIGMAPPA:
      return {
        title: t('boligmappa.item.systemMessages.documentsUploaded.title'),
        text: t(
          'boligmappa.item.systemMessages.documentsUploaded.description',
          {
            businessName,
          }
        ),
      };
    default:
      return null;
  }
};
