import styled from 'styled-components';

const NavigationContainer = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: ${(props): string => props.theme.radii[3]}px;
`;

export default NavigationContainer;
