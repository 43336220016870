import { FC } from 'react';

import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import { Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export interface ToggleAllBaseProps {
  action: VoidFunction;
  selectedFilterCount: number;
  totalFilterCount: number;
}

interface ToggleAllProps extends ToggleAllBaseProps {
  filterName: string;
}

export const ToggleAllMobile: FC<
  React.PropsWithChildren<ToggleAllBaseProps>
> = ({ selectedFilterCount, totalFilterCount, action }) => {
  const { t } = useTranslation();
  return (
    <Button variant="linkPrimary" m={0} p={0} ml="auto" onClick={action}>
      {selectedFilterCount === totalFilterCount
        ? t('job.list.filters.resetAll')
        : t('job.list.filters.setAll')}
    </Button>
  );
};

export const ToggleAll: FC<React.PropsWithChildren<ToggleAllProps>> = ({
  filterName,
  selectedFilterCount,
  totalFilterCount,
  action,
}) => {
  const { t } = useTranslation();
  return (
    <FilterToggle
      isGroupControl
      name={filterName}
      label={t('general.label.partial', {
        part: selectedFilterCount,
        all: totalFilterCount,
      })}
      isChecked={selectedFilterCount === totalFilterCount}
      onChange={action}
    />
  );
};
