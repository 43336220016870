import { ApolloError } from '@apollo/client';
import { t } from '@internals/business-translations/src/i18n';

import { GQLErrorState } from '../utils/errors';
import { isAuthNoAccessError } from '../utils/gqlErrors';
import { isError } from '../utils/isError';

export enum JobErrorKind {
  UNKNOWN = 'UNKNOWN',
  APOLLO_GENERAL = 'APOLLO_GENERAL',
  APOLLO_AUTH = 'APOLLO_AUTH',
  PAYLOAD = 'PAYLOAD',
}

export type JobError = {
  kind: JobErrorKind;
  error: Error;
};

export type JobErrorParams = {
  jobId?: string;
  apolloError?: ApolloError;
  payloadError?: GQLErrorState;
};

export const getJobError = ({
  apolloError,
  payloadError,
  jobId,
}: JobErrorParams): JobError | null => {
  if (!jobId) {
    return null;
  }

  if (apolloError) {
    return {
      kind: isAuthNoAccessError(apolloError.graphQLErrors)
        ? JobErrorKind.APOLLO_AUTH
        : JobErrorKind.APOLLO_GENERAL,
      error: apolloError,
    };
  }

  if (isError(payloadError)) {
    return {
      kind: JobErrorKind.PAYLOAD,
      error: new Error(
        `Job ID:${jobId} fetching error: ${payloadError.general.msg}`
      ),
    };
  }

  return null;
};

export const getJobErrorContent = (
  errorType: JobError
): { title: string; description: string; action: string } => {
  const defaultContent = {
    title: t('job.item.error.general.title'),
    description: t('job.item.error.general.description'),
    action: t('general.label.return'),
  };
  switch (errorType.kind) {
    case JobErrorKind.APOLLO_AUTH: {
      return {
        ...defaultContent,
        title: t('job.item.error.unavailable.title'),
        description: t('job.item.error.unavailable.description'),
      };
    }
    case JobErrorKind.PAYLOAD: {
      return {
        ...defaultContent,
        action: t('job.item.error.action.updateJobs'),
      };
    }
    default: {
      return defaultContent;
    }
  }
};
