import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink: any = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
`;

export default StyledLink;
