import {
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
  RefObject,
} from 'react';

import { bugsnagClient } from '@utils/initBugsnag';

interface useTextareaAutoResizeReturnValue {
  textareaRef: RefObject<HTMLTextAreaElement>;
  textareaHeight: CSSStyleDeclaration['height'];
  autoResizeTextarea: VoidFunction;
}

export const useTextareaAutoResize = (
  scrollBarRef?: RefObject<HTMLDivElement>
): useTextareaAutoResizeReturnValue => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [textareaHeight, setTextareaHeight] = useState<string>('0');

  const autoResizeTextarea = useCallback(() => {
    try {
      if (textareaRef?.current) {
        // if used inside ScrollPanel component, preserve scrollbar position
        const scrollTop = scrollBarRef?.current?.scrollTop;
        textareaRef.current.style.height = '0';
        const size = `${textareaRef.current.scrollHeight}px`;
        textareaRef.current.style.height = size;
        if (scrollBarRef?.current) {
          // eslint-disable-next-line no-param-reassign
          scrollBarRef.current.scrollTop = scrollTop;
        }
        setTextareaHeight(size);
      }
    } catch (e) {
      setTextareaHeight('auto');
      bugsnagClient.notify(
        new Error(`Error when auto-resizing message input, details: ${e}`)
      );
    }
  }, [textareaRef?.current, setTextareaHeight]);

  useLayoutEffect(() => {
    autoResizeTextarea();
  }, [autoResizeTextarea]);

  return { textareaRef, textareaHeight, autoResizeTextarea };
};
