import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  EvaluationQueryVariables,
  EVALUATION_QUERY,
  EvluationQueryPayload,
  isEvaluationQuerySuccessResponse,
  Evaluation,
} from '../../utils/query/Evaluation/EvaluationQuery';

interface EvaluationQueryResult extends QueryResult {
  data: Evaluation;
}
export const useEvaluationQuery = (
  evaluationId: EvaluationQueryVariables['id'],
  options?: QueryHookOptions<EvluationQueryPayload, EvaluationQueryVariables>
): EvaluationQueryResult => {
  const { data, ...rest } = useQuery<
    EvluationQueryPayload,
    EvaluationQueryVariables
  >(EVALUATION_QUERY, {
    variables: { id: evaluationId },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const result = useMemo(() => {
    return {
      data:
        data && isEvaluationQuerySuccessResponse(data.evaluation)
          ? data.evaluation.evaluation
          : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
