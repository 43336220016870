import { FC, useCallback, useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { BadgeAvatar } from '@components/elements/BadgeAvatar';
import JobLabels from '@components/elements/JobLabels';
import ChatTopBarContainer from '@components/layout/Messages/styled/ChatTopBarContainer';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JOB_QUERY_job_Job_evaluation } from '@internals/business-shared/src/utils/generated/generated';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import {
  getJobLabelList,
  JobLabelsType,
} from '@internals/business-shared/src/utils/jobLabels';
import Paths from '@router/paths';
import { Div, Text, Button, Avatar } from '@schibsted-smb/fireball';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export interface ChatDetailsTopBarProps {
  name?: string;
  address?: string;
  labels?: JobLabelsType;
  evaluation?: JOB_QUERY_job_Job_evaluation | null;
  isChatJobInfoVisible: boolean;
  setIsChatJobInfoVisible: (arg: boolean) => void;
  isMobile: boolean;
  isVerifiedWithSecureId: boolean;
}

const DetailsWrapper = styled(Div)`
  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    margin: 0 auto;
  }
`;

const BackIconWrapper = styled(Div)`
  display: none;
  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    display: block;
  }
`;

const StyledButton: any = styled(Button)`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &:hover {
    background: ${(props): string => props.theme.colors.black.black0};
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[6]}) {
    display: none;
  }

  ${({ isChatJobInfoVisible }: any) =>
    isChatJobInfoVisible &&
    `
    visibility: hidden;
  `}
`;

const ChatDetailsTopBar: FC<ChatDetailsTopBarProps> = ({
  name,
  address,
  labels,
  isChatJobInfoVisible,
  setIsChatJobInfoVisible,
  isMobile,
  isVerifiedWithSecureId,
}: ChatDetailsTopBarProps) => {
  const navigate = useNavigate();
  const bizVerificationViewEnabled = useFlagEnabled(
    FeatureFlags.BizVerificationView
  );

  const jobLabels = useMemo(() => {
    return labels ? getJobLabelList(labels) : [];
  }, [labels]);

  const AvatarComponent = useMemo(
    () =>
      isVerifiedWithSecureId && bizVerificationViewEnabled
        ? BadgeAvatar
        : Avatar,
    [bizVerificationViewEnabled, isVerifiedWithSecureId]
  );

  const handleBackButton = useCallback(() => {
    setIsChatJobInfoVisible(false);

    if (!isChatJobInfoVisible) {
      navigate(Paths.Messages);
    }
  }, [isChatJobInfoVisible, navigate]);

  return (
    <ChatTopBarContainer>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <BackIconWrapper>
          <StyledButton onClick={handleBackButton}>
            <FireIcon
              iconName={IconPickerName.ChevronLeft}
              width={24}
              height={24}
              variant="flat"
            />
          </StyledButton>
        </BackIconWrapper>
        <DetailsWrapper display="flex" alignItems="center">
          <AvatarComponent
            initials={getInitials(name)}
            size="s"
            mr={4}
            iconName={IconPickerName.ShieldCheck}
          />
          <Div>
            <Div display="flex" flexDirection="row" alignItems="center">
              <Text.span
                fontSize={2}
                mb={0}
                color="black.black9"
                fontWeight={600}
              >
                {name}
              </Text.span>
              {!isMobile && <JobLabels labels={jobLabels} />}
            </Div>
            <Text.span fontSize={1} color="black.black7" my={0}>
              {address}
            </Text.span>
          </Div>
        </DetailsWrapper>
        <StyledButton
          onClick={() => setIsChatJobInfoVisible(!isChatJobInfoVisible)}
          isChatJobInfoVisible={isChatJobInfoVisible}
        >
          <FireIcon
            iconName={IconPickerName.BurgerMenu}
            variant="flat"
            width={24}
            height={24}
          />
        </StyledButton>
      </Div>
    </ChatTopBarContainer>
  );
};

export default ChatDetailsTopBar;
