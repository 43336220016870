import { Container } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const HeaderContainer = styled(Container)`
  height: 100%;
  display: flex;
  justify-content: center;
`;

export default HeaderContainer;
