import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY,
  NotificationDestinationsEnabled,
  NotificationDestinationsEnabledQuery,
} from '../../utils/query/NotificationDestinationsEnabled/NotificationDestinationsEnabledQuery';

interface NotificationsDestinationsEnabledQueryResult extends QueryResult {
  data: NotificationDestinationsEnabled[] | null;
}

export const useNotificationsDestinationsEnabledQuery = (
  options?: QueryHookOptions<NotificationDestinationsEnabledQuery>
): NotificationsDestinationsEnabledQueryResult => {
  const { data, ...rest } = useQuery<NotificationDestinationsEnabledQuery>(
    NOTIFICATION_DESTINATIONS_ENABLED_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

  return useMemo(
    () => ({
      data: data ? data.notificationDestinationsEnabled : null,
      ...rest,
    }),
    [data, rest]
  );
};
