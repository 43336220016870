import { FC, MouseEvent } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  FilterBadgeIconContainer,
  FilterBadgeContainer,
} from '@components/elements/JobsFilters/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

export interface FilterBadgeProps {
  onClick: VoidFunction;
  text: string;
}
const FilterBadge: FC<React.PropsWithChildren<FilterBadgeProps>> = ({
  onClick,
  text,
}) => {
  const themeContext = useTheme();
  const handleOnClick = (e?: MouseEvent<HTMLElement>) => {
    e?.stopPropagation();
    onClick();
  };
  return (
    <FilterBadgeContainer>
      <Button
        onClick={handleOnClick}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={1}
        py={2}
        pl={4}
        pr={7}
        width="auto"
        maxWidth="100%"
      >
        <Text.span truncate mb={0} fontSize="12px">
          {text}
        </Text.span>
        <FilterBadgeIconContainer>
          <FireIcon
            iconName={IconPickerName.Close}
            width="12px"
            height="12px"
            fill={themeContext.colors.primary.base}
            variant="flat"
          />
        </FilterBadgeIconContainer>
      </Button>
    </FilterBadgeContainer>
  );
};

export default FilterBadge;
