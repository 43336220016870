import { FC } from 'react';

import { JobLabelV0 } from '@components/base/JobLabel/JobLabelV0';
import { FolderValues } from '@components/elements/JobFolders';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node } from '@internals/business-shared/src/utils/generated/generated';
import {
  JobLabel,
  DisplayedJobLabel,
} from '@internals/business-shared/src/utils/jobLabels';
import { JobFolder } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import { Div } from '@schibsted-smb/fireball';

interface JobLabelsProps {
  labels: DisplayedJobLabel[];
  folders?: (JobFolder | FolderValues)[] | null;
  evaluation?: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node['evaluation'];
  isGray?: boolean;
  isSmall?: boolean;
}

export const JobLabelsV0: FC<JobLabelsProps> = ({
  labels,
  folders,
  evaluation,
  isGray,
  isSmall,
}) => (
  <Div display="flex" flexWrap="wrap">
    {labels.map((label) => (
      <JobLabelV0
        key={label}
        labelName={label}
        isGray={isGray}
        isSmall={isSmall}
      />
    ))}
    {!!(evaluation && labels.includes(JobLabel.WON)) && (
      <JobLabelV0
        labelName={JobLabel.EVALUATION}
        evaluationValue={evaluation.score}
        isGray={isGray}
        isSmall={isSmall}
      />
    )}
    {folders &&
      folders.map((folder) => (
        <JobLabelV0
          key={folder.id}
          labelName={JobLabel.FOLDER}
          folderName={folder.title}
          folderColor={folder.color || ''}
          isGray={isGray}
          isSmall={isSmall}
        />
      ))}
  </Div>
);
