import { ApolloCache, makeReference } from '@apollo/client';

import { SET_RECEIVE_NEWSLETTERS } from '../../utils/generated/generated';
import { SetReceiveNewslettersMutationPayload } from '../../utils/mutation/SetReceiveNewsletters/SetReceiveNewslettersMutation';

const setReceiveNewslettersUpdate =
  (isEnabled: boolean) =>
  (
    cache: ApolloCache<SET_RECEIVE_NEWSLETTERS>,
    {
      data: mutationPayload,
    }: { data?: SetReceiveNewslettersMutationPayload | null }
  ) => {
    if (!mutationPayload) return;
    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        getUserSettings(existing) {
          if (!existing || !mutationPayload.setReceiveNewsletters)
            return existing;
          return { ...existing, ReceiveNewsletters: isEnabled };
        },
      },
    });
  };

const setReceiveNewslettersOptimisticResponse: SetReceiveNewslettersMutationPayload =
  { setReceiveNewsletters: true };

export { setReceiveNewslettersUpdate, setReceiveNewslettersOptimisticResponse };
