import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_FRAGMENT from '../../fragments/SimpleAgreementContractFragment';
import {
  SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables,
  SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION as SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONResult,
} from '../../generated/generated';

export type SignSimpleAgreementContractMutationPayload =
  SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONResult;
export type SignSimpleAgreementContractMutationVariables =
  SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables;

export const isSignSimpleAgreementContractMutationSuccessResponse = (
  signSimpleAgreementContract?: SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONResult['signSimpleAgreementContract']
): signSimpleAgreementContract is SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATIONResult['signSimpleAgreementContract'] =>
  !!signSimpleAgreementContract &&
  signSimpleAgreementContract.__typename ===
    'SignSimpleAgreementContractPayload';

export const SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_FRAGMENT}

  mutation SIGN_SIMPLE_AGREEMENT_CONTRACT_MUTATION(
    $input: SignSimpleAgreementContractInput!
  ) {
    signSimpleAgreementContract(input: $input) {
      contract {
        ...SimpleAgreementContractFragment
        chat {
          id
        }
      }
    }
  }
`;
