import React from 'react';

import EvaluationView from '@components/layout/CustomerService/Evaluation';
import withNavigationLayout from '@components/layout/WithNavigation';
import { CustomerServiceNavigation } from '@pages/CustomerService';
import { useTranslation } from 'react-i18next';

export const CustomerServiceEvaluation: React.FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: CustomerServiceNavigation,
    pageName: t('customer.list.title'),
    pageDetails: {
      PageContent: EvaluationView,
      subpageName: t('evaluation.list.title'),
      disableMobileHeader: true,
    },
  })();
};
