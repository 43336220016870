import * as React from 'react';

import AreasAccordionTagContainer from '@components/elements/AreasAccordionTag/styled/AreasAccordionTagContainer';
import { Tag } from '@schibsted-smb/fireball';
import statusCheck, { StatusTypes } from '@utils/statusCheck';
import { useTranslation } from 'react-i18next';

interface AreasAccordionTagProps {
  active: number;
  all: number;
  isPartial?: boolean;
}

const AreasAccordionTag: React.FC<
  React.PropsWithChildren<AreasAccordionTagProps>
> = ({ active, all, isPartial }) => {
  const { t } = useTranslation();

  const getStatus = () => {
    if (!active && !isPartial) {
      return StatusTypes.Muted;
    }
    return active === all ? StatusTypes.Success : StatusTypes.Warning;
  };

  return (
    <AreasAccordionTagContainer>
      <Tag variant={statusCheck(getStatus())} noBorder>
        {active} {t('settings.jobSearch.of')} {all}
      </Tag>
    </AreasAccordionTagContainer>
  );
};

export default AreasAccordionTag;
