import { useUserContext } from '@contexts/UserContext';
import { CreateBoligmappaAccountFormShape } from '@internals/business-shared/src/types/FormShape/CreateBoligmappaAccount';

export const getInvalidInputErrorMessage = (message: string) => {
  let field = '';
  let errorMessage = '';
  let generalError = false;

  switch (message) {
    case 'INVALID_EMAIL_ADDRESS':
      field = 'email';
      errorMessage = 'general.form.validation.email.invalidFormat';
      break;
    case 'EMAIL_ALREADY_EXISTS':
      field = 'email';
      errorMessage = 'general.form.validation.email.alreadyExists';
      break;
    case 'INVALID_ORG_NUMBER':
      field = 'orgNumber';
      errorMessage = 'general.form.validation.orgNumber.invalid';
      break;
    case 'COMPANY_ALREADY_REGISTERED':
      field = 'orgNumber';
      errorMessage = 'general.form.validation.orgNumber.alreadyExists';
      break;
    case 'COMPANY_NOT_FOUND':
      field = 'orgNumber';
      errorMessage = 'general.form.validation.orgNumber.notFound';
      break;
    case 'INVALID_PHONE_NUMBER':
      field = 'phone';
      errorMessage = 'general.form.validation.phone.invalidFormat';
      break;
    case 'INVALID_EMAIL_OR_PASSWORD':
      errorMessage = 'general.form.validation.invalidEmailOrPassword';
      generalError = true;
      break;
    default:
      errorMessage = 'general.form.validation.default';
      generalError = true;
  }

  return { field, message: errorMessage, generalError };
};

export const getInitialFormValues = (): CreateBoligmappaAccountFormShape => {
  const user = useUserContext();
  return {
    orgNumber: user.operatingAs.orgNr || '',
    email: user.email || '',
    companyName: user.operatingAs.name || '',
    firstName: user.lastName || '',
    lastName: user.lastName || '',
    phone: user.phone || '',
    professionType: '',
    numberOfEmployees: '',
    username: user.email || '',
    password: '',
    confirmPassword: '',
  };
};
