import { FC } from 'react';

import { SavedFilterModal } from '@components/layout/Jobs/SavedFilters/SavedFilterModal';
import useAnalytics from '@hooks/useAnalytics';
import { deleteJobsSavedFilterUpdate } from '@internals/business-shared/src/cache/updates/deleteJobsSavedFilter';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import { ActiveEditEnabledFilter } from '@internals/business-shared/src/components/SavedFilters/types';
import { useDeleteJobsSavedFilterMutation } from '@internals/business-shared/src/hooks/mutation/useDeleteJobsSavedFilterMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div, Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const ConfirmDeleteSavedFilterModal: FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { closeModal, filterState, setNewFilter } = useSavedFiltersContext();
  const { activeFilter } = filterState as ActiveEditEnabledFilter;
  const [deleteMutation, { loading }] = useDeleteJobsSavedFilterMutation();

  const onDelete = () => {
    track(ANEventSpace(ANEvent.Deleted, ANObject.FilterPrompt, ANPage.JobList));
    deleteMutation(activeFilter.id, {
      onCompleted: () => {
        closeModal();
        setNewFilter(activeFilter.filters, true);
      },
      update: deleteJobsSavedFilterUpdate(activeFilter.id),
    });
  };

  return (
    <SavedFilterModal
      isOpen
      onClose={closeModal}
      maxWidth="400px"
      contentProps={{ p: 7 }}
      title={t('job.list.savedFilters.action.confirmDelete.title')}
    >
      <Div mt={7}>
        <Button
          variant={loading ? 'inProgress' : 'danger'}
          fullWidth
          mb={2}
          onClick={onDelete}
        >
          {t('general.label.delete')}
        </Button>
        <Button
          variant="linkPrimary"
          onClick={closeModal}
          fullWidth
          disabled={loading}
        >
          {t('general.label.cancel')}
        </Button>
      </Div>
    </SavedFilterModal>
  );
};
