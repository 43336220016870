import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const LogoContainer = styled(Div)`
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 300px !important;
  height: 35px !important;
  min-height: 35px !important;
  max-height: 35px !important;

  svg {
    display: block;
    width: 160px !important;
    height: 35px !important;
    min-height: 35px !important;
    max-height: 35px !important;
  }

  @media print {
    min-height: 1cm !important;
    max-height: 1cm !important;
    height: 1cm !important;

    svg {
      display: block;
      min-height: 1cm !important;
      max-height: 1cm !important;
      height: 1cm !important;
    }
  }
`;
