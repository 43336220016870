import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  DELETE_BUSINESS_EMPLOYEE,
  DeleteBusinessEmployeeMutationPayload,
  DeleteBusinessEmployeeMutationVariables,
} from '../../utils/mutation/DeleteBusinessEmployee/DeleteBusinessEmployeeMutation';

type UseDeleteBusinessEmployeeMutationAction = (
  input: DeleteBusinessEmployeeMutationVariables,
  mutationOptions?: MutationFunctionOptions<
    DeleteBusinessEmployeeMutationPayload,
    DeleteBusinessEmployeeMutationVariables
  >
) => Promise<FetchResult<DeleteBusinessEmployeeMutationPayload>>;

export const useDeleteBusinessEmployeeMutation = (): [
  UseDeleteBusinessEmployeeMutationAction,
  MutationResult<DeleteBusinessEmployeeMutationPayload>
] => {
  const [deleteBusinessEmployee, ...rest] = useMutation<
    DeleteBusinessEmployeeMutationPayload,
    DeleteBusinessEmployeeMutationVariables
  >(DELETE_BUSINESS_EMPLOYEE);

  const mutationFunction: UseDeleteBusinessEmployeeMutationAction = useCallback(
    (input, mutationOptions) =>
      deleteBusinessEmployee({
        variables: input,
        ...mutationOptions,
      }),
    [deleteBusinessEmployee]
  );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
