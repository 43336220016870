import * as React from 'react';

import useWindowSize from '@hooks/useWindowSize';
import { GridContainerProps } from '@schibsted-smb/fireball';

import PageContainerStyled from './styled/PageContainerStyled';

interface PageContainerProps extends GridContainerProps {
  fullScreenHeight?: boolean;
  skipNavigation?: boolean;
}

const PageContainer: React.FC<React.PropsWithChildren<PageContainerProps>> = ({
  children,
  ...props
}) => {
  const { height } = useWindowSize();
  return (
    <PageContainerStyled
      {...props}
      customHeight={props?.fullScreenHeight ? height : undefined}
    >
      {children}
    </PageContainerStyled>
  );
};

export default PageContainer;
