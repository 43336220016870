import { FC } from 'react';

import { FireIcon, FireIconProps } from '@components/base/FireIcon/FireIcon';
import {
  BadgeAvatarContainer,
  BadgeAvatarIconContainer,
} from '@components/elements/BadgeAvatar/styled/StyledBadgeAvatar';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Avatar, AvatarProps } from '@schibsted-smb/fireball';

type BadgeAvatarProps = AvatarProps &
  Omit<FireIconProps, 'variant'> & { iconVariant?: FireIconProps['variant'] };

export const BadgeAvatar: FC<BadgeAvatarProps> = ({ ...props }) => {
  return (
    <BadgeAvatarContainer>
      <Avatar {...props} />
      <BadgeAvatarIconContainer>
        <FireIcon
          iconName={props.iconName || IconPickerName.Favourite}
          variant={props.iconVariant}
          fill={props.fill}
          width={14}
          height={14}
        />
      </BadgeAvatarIconContainer>
    </BadgeAvatarContainer>
  );
};
