import gql from 'graphql-tag';

import {
  BOLIGMAPPA_REMOVE_ASSOCIATIONVariables,
  BOLIGMAPPA_REMOVE_ASSOCIATION as BOLIGMAPPA_REMOVE_ASSOCIATION_Result,
} from '../../generated/generated';

export type BoligmappaRemoveAssociationMutationVariables =
  BOLIGMAPPA_REMOVE_ASSOCIATIONVariables;
export type BoligmappaRemoveAssociationMutationPayload =
  BOLIGMAPPA_REMOVE_ASSOCIATION_Result;

export const BOLIGMAPPA_REMOVE_ASSOCIATION = gql`
  mutation BOLIGMAPPA_REMOVE_ASSOCIATION(
    $input: BoligmappaRemoveAssociationInput!
  ) {
    boligmappaRemoveAssociation(input: $input) {
      id
      address {
        houseNumber
        houseSubNumber
        id
        postalCode
        postalPlace
        streetName
      }
      associations {
        id
        propertyType
        unitNumber
        documents {
          fileName
          id
        }
      }
    }
  }
`;
