import styled from 'styled-components';

const SectionContainer = styled.section`
  display: block;
  margin: 0 0 45px 0;
  padding: 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints[5]}) {
    padding: 0 0 0 56px;
  }
`;

export default SectionContainer;
