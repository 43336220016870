import { InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';

import JOB_BUSINESS_RELATION_STATE_FRAGMENT from '../../utils/fragments/JobBusinessRelationStateFragment';

const query = gql`
  ${JOB_BUSINESS_RELATION_STATE_FRAGMENT}
  query JOB_BUSINESS_RELATION_STATE($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        jobBusinessRelationState {
          ...JobBusinessRelationStateFragment
        }
      }
    }
  }
`;

export const writeJobBusinessRelationState = (
  cache: InMemoryCache,
  jobId: string
) => {
  if (!jobId) return;
  const id = cache.identify({
    __typename: 'JobBusinessRelationState',
    jobId,
  });
  const jobBusinessRelationState = cache.readFragment({
    fragment: JOB_BUSINESS_RELATION_STATE_FRAGMENT,
    fragmentName: 'JobBusinessRelationStateFragment',
    id,
  });
  cache.writeQuery({
    data: { job: { __typename: 'Job', id: jobId, jobBusinessRelationState } },
    variables: { id: jobId },
    query,
  });
};
