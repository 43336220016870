import { ApolloError } from '@apollo/client';
import {
  AuthLoginWithPasswordDocument,
  type LoginInput,
  UserRole,
} from '@internals/business-graphql/src/graphql/generated/types';
import {
  AuthenticationErrorCodes,
  ErrorTypes,
  GenericErrorCodes,
  resolveErrors,
} from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import { handleLoginSuccess } from '../login/handleLoginSuccess';
import type { AuthHandlers, ErrorVariant } from '../login/types';
import type { SetState } from '../types';

export type HandleLoginWithPasswordErrorHandlers = {
  onGenericError: (error?: ErrorVariant) => void;
  onRoleUnavailableError: (error: ErrorVariant) => void;
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onAuthNoAccessError: (error: ErrorVariant) => void;
};

export const handleLoginWithPassword = async (
  setState: SetState,
  input: LoginInput,
  authHandlers: AuthHandlers,
  errorHandlers: HandleLoginWithPasswordErrorHandlers,
  defaultIntendedRole?: UserRole
) => {
  const intendedRole = input.intendedRole || defaultIntendedRole;

  try {
    const { data } = await apolloClient.mutate({
      mutation: AuthLoginWithPasswordDocument,
      variables: { input: { ...input, intendedRole } },
    });

    if (data?.loginWithPassword.user) {
      if (authHandlers.onTokenReceived && data.loginWithPassword.token) {
        await authHandlers.onTokenReceived(data.loginWithPassword.token);
      }

      setState({
        status: 'authenticated',
        user: data.loginWithPassword.user,
      });

      await handleLoginSuccess(
        data.loginWithPassword.user,
        authHandlers,
        intendedRole
      );
    } else {
      throw new Error('Invalid login with password response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
          [ErrorTypes.AUTHENTICATION]: [
            [
              AuthenticationErrorCodes.LOGIN_ROLE_UNAVAILABLE,
              (error) => errorHandlers.onRoleUnavailableError(error),
            ],
            [
              AuthenticationErrorCodes.AUTH_NO_ACCESS,
              (error) => errorHandlers.onAuthNoAccessError(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
