import gql from 'graphql-tag';

import { SOCIAL_MEDIA_FRAGMENT } from '../../fragments/SocialMediaFragment';
import {
  COMPANY_INFO_QUERY as COMPANY_INFO_QUERY_Result,
  COMPANY_INFO_QUERYVariables,
  COMPANY_INFO_QUERY_business_Business,
  COMPANY_INFO_QUERY_businessSocialMedia,
} from '../../generated/generated';

export type CompanyInfoQueryPayload = COMPANY_INFO_QUERY_Result;
export type CompanyInfoQueryVariables = COMPANY_INFO_QUERYVariables;
export type CompanyInfoQueryBusiness = COMPANY_INFO_QUERY_business_Business;
export type CompanyInfoQuerySocialMedia =
  COMPANY_INFO_QUERY_businessSocialMedia;
export type CompanyInfoQueryData = CompanyInfoQueryBusiness & {
  businessSocialMedia: CompanyInfoQuerySocialMedia;
};

export const isCompanyInfoQuerySuccessResponse = (
  business?: COMPANY_INFO_QUERY_Result['business']
): business is COMPANY_INFO_QUERY_business_Business =>
  !!(business && business.__typename === 'Business');

export const COMPANY_INFO_QUERY = gql`
  ${SOCIAL_MEDIA_FRAGMENT}
  query COMPANY_INFO_QUERY($id: ID!) {
    business(id: $id) {
      ... on Business {
        addresses {
          id
          billing {
            city
            description
            id
            lat
            lon
            street
            type
            zip
          }
          post {
            city
            description
            id
            lat
            lon
            street
            type
            zip
          }
          visit {
            city
            description
            id
            lat
            lon
            street
            type
            zip
          }
        }
        id
        initials
        isActive
        logo {
          bigPreviewUrl
          description
          dimensions {
            width
            height
          }
          iconUrl
          id
          mediumPreviewUrl
          mediumUrl
          name
          size
          smallPreviewUrl
          smallUrl
          url
        }
        profileDescription
        name
        orgNr
        phone
        email
        openingHours {
          monday {
            from
            to
          }
          tuesday {
            from
            to
          }
          wednesday {
            from
            to
          }
          thursday {
            from
            to
          }
          friday {
            from
            to
          }
          saturday {
            from
            to
          }
          sunday {
            from
            to
          }
        }
      }
      ... on BusinessInputError {
        message
        idError: id {
          message
        }
      }
      ... on BusinessNotFoundError {
        message
      }
    }
    businessSocialMedia(businessId: $id) {
      ...SocialMediaFragment
    }
  }
`;
