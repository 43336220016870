// A simple hook to easily use local in a standardize way for Business app
// All items are stored as stringified objects with a timestamp and data key

// TODO:
// - Fix typing
// - Return proper errors and not null?
// - Clear function, should only clear items with the prefix or maybe a specific key. Not all elements in local storage.
// - Delete the saved message when message is sent (Both in job modal and chat)
// - Find a good place to use clearOld to avoid the object getting massive

export const STORE_JOB_INQUIRY_PREFIX = 'job-msg-draft';
export const STORE_CHAT_PREFIX = 'chat-msg-draft';
export const STORE_CONTRACT_PREFIX = 'contract-msg-draft';
export const STORE_BOLIG_MAPPA_MODAL = 'boligmappa-modal';
export const STORE_JOB_ANSWER_TIP_PREFIX = 'job-answer-tip';
export const STORE_SAVED_FILTER_INFO_PREFIX = 'saved-filter-info';
export const STORE_COMPANY_BANK_ID_BANNER_PREFIX = 'company-bankid-banner';

export const isObject = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch {
    return false;
  }
  return true;
};

interface StorageObject {
  [key: string]: string;
}

const useStorage = (prefix: string): any => {
  const storage = window.localStorage;
  const timestamp = Math.floor(Date.now() / 1000);
  const timestampString = timestamp.toString();

  // Teste saving på window unload?
  const set = (key: string, data: StorageObject): void => {
    const newKey = `${prefix}-${key}-${timestamp}`;
    const newKeyData = { data: { ...data }, timestamp: timestampString };
    const savedKeys = storage.getItem('keys');

    let keys: string[] = [];

    if (savedKeys) {
      keys = isObject(savedKeys) ? JSON.parse(savedKeys) : [];
      const existingKeys: number[] = [];

      if (!Array.isArray(keys)) return;

      keys.forEach((element, index) => {
        if (element.startsWith(`${prefix}-${key}`)) existingKeys.push(index);
      });

      // Delete key if it exists to avoid duplicates
      if (existingKeys.length) {
        existingKeys.forEach((_val, i) => {
          keys.splice(existingKeys[i], 1);
        });
      }

      keys.push(newKey);
    }

    storage.setItem(`${prefix}-${key}`, JSON.stringify(newKeyData));
    storage.setItem('keys', JSON.stringify(keys));
  };

  const get = (key: string): StorageObject | null => {
    const data = storage.getItem(`${prefix}-${key}`);

    if (!data || !isObject(data)) return null;

    return JSON.parse(data) as StorageObject;
  };

  const remove = (key: string): void => {
    storage.removeItem(`${prefix}-${key}`);
  };

  const clear = (): void => {
    storage.clear();
  };

  const clearOld = (): void => {
    const maxDiff = 1209600; // 14 days
    const now = timestamp;
    const data = storage.getItem('keys');

    if (!data || !isObject(data)) return;

    const keys = JSON.parse(data);
    const deletedKeys: number[] = [];

    keys.forEach((key: string, index: number) => {
      const keyTs = key.slice(key.lastIndexOf('-') + 1, key.length);
      const keyId = key.slice(0, key.lastIndexOf('-'));

      if (keyTs && keyId) {
        // eslint-disable-next-line radix
        const ts = parseInt(keyTs);

        if (now - ts >= maxDiff) {
          deletedKeys.push(index);
          storage.removeItem(keyId);
        }
      }
    });

    if (deletedKeys.length) {
      deletedKeys.forEach((_val, i) => {
        keys.splice(deletedKeys[i], 1);
      });
    }

    storage.setItem('keys', JSON.stringify(keys));
  };

  return {
    storage,
    set,
    get,
    remove,
    clear,
    clearOld,
  };
};

export default useStorage;
