import { FC, ReactElement, ReactNode, useState } from 'react';

import { useUserBusinessId } from '@contexts/UserContext';
import { useBusinessEvaluationsQuery } from '@internals/business-shared/src/hooks/query/useBusinessEvaluationsQuery';
import { BusinessEvaluationsType } from '@internals/business-shared/src/utils/query/BusinessEvaluations/BusinessEvaluationsQuery';
import { EVALUATION_LIST_DEFAULT_COUNT } from '@root/src/apollo/cache';
import { Div, Pagination } from '@schibsted-smb/fireball';

interface EvaluationListProps {
  renderEvaluation: (
    evaluation: BusinessEvaluationsType['edges'][0]
  ) => ReactNode;
  notFoundComponent: ReactElement;
  loaderComponent: ReactElement;
}

const EvaluationList: FC<EvaluationListProps> = ({
  renderEvaluation,
  notFoundComponent,
  loaderComponent,
}) => {
  const [page, setPage] = useState(1);
  const businessId = useUserBusinessId();

  const { data, loading } = useBusinessEvaluationsQuery({
    variables: {
      input: {
        businessId,
        paging: {
          page,
          size: EVALUATION_LIST_DEFAULT_COUNT,
        },
      },
    },
  });

  const evaluatedPayload = data ? data?.edges : [];
  const evaluatedPageInfoPayload = data ? data?.pageInfo : null;
  const showLoaders = loading && !evaluatedPayload.length;

  return (
    <>
      {showLoaders && loaderComponent}
      {!loading && !evaluatedPayload.length && notFoundComponent}
      {!!evaluatedPayload.length && (
        <Div pb={[0, null, null, null, null, 9]}>
          {evaluatedPayload.map(renderEvaluation)}
        </Div>
      )}
      {evaluatedPageInfoPayload &&
        !!evaluatedPageInfoPayload.totalPages &&
        evaluatedPageInfoPayload.totalPages > 1 &&
        evaluatedPageInfoPayload.totalCount && (
          <Div
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={6}
            mb={6}
            pb={6}
          >
            <Pagination
              currentPage={page}
              count={evaluatedPageInfoPayload.totalCount}
              itemsPerPage={
                evaluatedPageInfoPayload.pageSizeLimit ||
                EVALUATION_LIST_DEFAULT_COUNT
              }
              getCurrentPage={setPage}
            />
          </Div>
        )}
    </>
  );
};

export default EvaluationList;
