import gql from 'graphql-tag';

import {
  JOBS_TOTAL_COUNT_QUERYVariables,
  JOBS_TOTAL_COUNT_QUERY as JOBS_TOTAL_COUNT_QUERY_Result,
  JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload,
  JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection_pageInfo,
} from '../../generated/generated';

export type JobsTotalCountQueryPayload = JOBS_TOTAL_COUNT_QUERY_Result;
export type JobsTotalCountQueryVariables = JOBS_TOTAL_COUNT_QUERYVariables;
export type JobsTotalCount =
  JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload_jobConnection_pageInfo['totalCount'];

export const isJobsTotalCountQueryJobListPayload = (
  jobList?: JOBS_TOTAL_COUNT_QUERY_Result['jobList']
): jobList is JOBS_TOTAL_COUNT_QUERY_jobList_JobListPayload =>
  !!jobList && jobList.__typename === 'JobListPayload';

export const JOBS_TOTAL_COUNT_QUERY = gql`
  query JOBS_TOTAL_COUNT_QUERY(
    $count: Int
    $query: String
    $size: JobSize
    $sizes: [JobSize!]
    $listId: ID
    $districtId: [ID!]
    $countyId: [ID!]
    $municipalityCode: [ID!]
    $folderId: [ID!]
    $worktypeIds: [ID!]
    $worktypeSubsets: [ID!]
    $worktypeSubsetGroups: [ID!]
    $industryIds: [ID!]
  ) {
    jobList(
      input: {
        count: $count
        query: $query
        size: $size
        sizes: $sizes
        listId: $listId
        districtId: $districtId
        municipalityCode: $municipalityCode
        countyId: $countyId
        folderId: $folderId
        worktypeIds: $worktypeIds
        worktypeSubsets: $worktypeSubsets
        worktypeSubsetGroups: $worktypeSubsetGroups
        industryIds: $industryIds
      }
    ) {
      ... on JobListPayload {
        jobConnection {
          pageInfo {
            totalCount
          }
        }
      }
    }
  }
`;
