import { CreateContractFormShape } from '@internals/business-shared/src/types/FormShape/CreateContract';
import { SimpleAgreementPriceType } from '@internals/business-shared/src/utils/generated/generated';
import { prettyNumberString } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';
import { getProduct } from '@utils/product';
import { parsePhoneNumber } from 'libphonenumber-js/core';
import * as Yup from 'yup';

export const HUNDRED_MILLION = 100000000;

const { phoneFormat, phoneMetaData } = getProduct();
export const contractValidationSchema = Yup.object<CreateContractFormShape>({
  start: Yup.date().nullable(),
  end: Yup.date()
    .nullable()
    .when('start', {
      is: (start: CreateContractFormShape['start']) =>
        start !== undefined && start !== null,
      then: (schema) =>
        schema.min(
          Yup.ref('start'),
          t('general.form.validation.date.mustBeLaterThanStart')
        ),
      otherwise: (schema) => schema.nullable(),
    }),
  priceType: Yup.mixed()
    .oneOf([
      SimpleAgreementPriceType.FIXED,
      SimpleAgreementPriceType.HOUR_COMPUTATION,
      SimpleAgreementPriceType.OTHER,
    ])
    .required(t('general.form.validation.required')),
  price: Yup.number().when('priceType', {
    is: (priceType: CreateContractFormShape['priceType']) =>
      priceType === SimpleAgreementPriceType.FIXED ||
      priceType === SimpleAgreementPriceType.HOUR_COMPUTATION,
    then: (schema) =>
      schema
        .required(t('general.form.validation.required'))
        .integer(t('general.form.validation.number.mustBeInteger'))
        .min(
          1,
          t('general.form.validation.number.mustBeGreaterThan', { value: '0' })
        )
        .max(
          HUNDRED_MILLION,
          t('general.form.validation.number.mustBeLessThan', {
            value: prettyNumberString(HUNDRED_MILLION),
          })
        ),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  description: Yup.string().when('priceType', {
    is: (priceType: CreateContractFormShape['priceType']) =>
      priceType === SimpleAgreementPriceType.OTHER,
    then: (schema) => schema.required(t('general.form.validation.required')),
    otherwise: (schema) => schema.nullable().notRequired(),
  }),
  businessEmail: Yup.string()
    .required(t('general.form.validation.required'))
    .email(t('general.form.validation.email.invalidFormat')),

  businessPhone: Yup.string()
    .required(t('general.form.validation.required'))
    .test({
      name: 'phone',
      message: t('general.form.validation.phone.invalidFormat'),
      test: (value) => {
        if (!value) {
          return false;
        }
        try {
          const result = parsePhoneNumber(value, phoneFormat, phoneMetaData);
          return result.isValid();
        } catch (e) {
          return false;
        }
      },
    }),
});

export const contractFormInitValues: CreateContractFormShape = {
  start: undefined,
  end: undefined,
  priceType: SimpleAgreementPriceType.FIXED,
  price: undefined,
  description: '',
  businessEmail: '',
  businessPhone: '',
};
