const MEGABYTE = 1024 * 1024;
const GIGABYTE = 1024 * MEGABYTE;

export const BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE = 2 * GIGABYTE; // 100MB
export const BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE_HUMAN_STRING = '2GB'; // 100MB
export const BOLIGMAPPA_FILE_UPLOAD_FORBIDDEN_EXTENSIONS: string[] = [
  '',
  'application',
  'asa',
  'asp',
  'aspx',
  'bat',
  'cert',
  'cmd',
  'com',
  'cpl',
  'dll',
  'docm',
  'dotm',
  'exe',
  'gadget',
  'hta',
  'inf',
  'jar',
  'js',
  'jse',
  'jsp',
  'jspx',
  'lnk',
  'msc',
  'msh',
  'msh1',
  'msh1xml',
  'msh2',
  'msh2xml',
  'mshxml',
  'msi',
  'msp',
  'php',
  'php5',
  'phtml',
  'pif',
  'potm',
  'ppam',
  'ppsm',
  'pptm',
  'ps1',
  'ps1xml',
  'ps2',
  'ps2xml',
  'psc1',
  'psc2',
  'reg',
  'scf',
  'scr',
  'shtml',
  'sldm',
  'vb',
  'vbe',
  'vbs',
  'ws',
  'wsc',
  'wsf',
  'wsh',
  'xlam',
  'xlsm',
  'xltm',
];

export const FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB = 19.5; // GraphQL current limitation
export const FILE_UPLOAD_TOTAL_SIZE_LIMIT =
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB * MEGABYTE; // 19.5MB
export const FILE_UPLOAD_MAX_FILES_LIMIT = 100;
export const FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT = 1;
export const IMAGE_FILE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
/**
 * Excel files are not supported by the backend yet
 */
export const FILE_UPLOAD_SUPPORTED_EXTENSIONS_ARRAY: string[] = [
  'ai',
  'doc',
  'docx',
  'eml',
  'eps',
  'fodg',
  'fodp',
  'fods',
  'fodt',
  'html',
  'odt',
  'pdf',
  'ppt',
  'pptx',
  'psd',
  'rar',
  'rtf',
  'skp',
  'svg',
  'tif',
  'txt',
  // 'xls',
  // 'xlsx',
  'xml',
  'zip',
  ...IMAGE_FILE_EXTENSIONS,
];
export const FILE_UPLOAD_SUPPORTED_EXTENSIONS =
  FILE_UPLOAD_SUPPORTED_EXTENSIONS_ARRAY.map(
    (extension) => `.${extension}`
  ).join(',');
