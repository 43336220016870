import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ProductAccordion } from '@components/layout/Products/ProductAccordion';
import { StyledProductTitle } from '@components/layout/Products/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';

interface ProductInfoItemProps {
  icon: IconPickerName;
  text: string;
}

interface ProductInfoListAccordionProps {
  title: string;
  infoItems: ProductInfoItemProps[];
}

const ProductInfoItem: FC<ProductInfoItemProps> = ({ icon, text }) => (
  <Div display="flex" alignItems="flex-end" mb={5}>
    <Div mr={3}>
      <FireIcon width={20} height={20} iconName={icon} fill="primary.base" />
    </Div>
    <Text.p mb={0}>{text}</Text.p>
  </Div>
);

export const ProductInfoListAccordion: FC<ProductInfoListAccordionProps> = ({
  title,
  infoItems,
}) => (
  <ProductAccordion
    initialExpanded
    fixedContent={<StyledProductTitle mb={0}>{title}</StyledProductTitle>}
    expandedContent={
      <Div py={5}>
        {infoItems.map(({ text, icon }) => (
          <ProductInfoItem text={text} icon={icon} key={icon} />
        ))}
      </Div>
    }
  />
);
