import * as React from 'react';

const useIsOverflown = (ref: React.RefObject<any>): boolean => {
  return (
    ref.current.scrollHeight > ref.current.clientHeight ||
    ref.current.scrollWidth > ref.current.clientWidth
  );
};

export default useIsOverflown;
