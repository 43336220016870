import { FC, useMemo } from 'react';

import { JobDataItem } from '@components/layout/Job/JobDataItem';
import {
  JobAddressHint,
  JobAddress,
  JobAddressTo,
} from '@internals/business-shared/src/utils/address';
import { AddressFragment } from '@internals/business-shared/src/utils/generated/generated';
import { Div, Link } from '@schibsted-smb/fireball';
import {
  getAddressString,
  getShortAddressString,
  getAddressForMaps,
} from '@utils/address';
import { t } from '@utils/i18n';
import { useTheme } from 'styled-components';

interface JobTransportAddressProps {
  showFullAddress: boolean;
  address: JobAddress;
  addressHint: JobAddressHint;
  destinationAddress: JobAddressTo;
}

export const JobTransportAddress: FC<JobTransportAddressProps> = ({
  showFullAddress,
  address,
  addressHint,
  destinationAddress,
}) => {
  const theme = useTheme();
  if (!destinationAddress || !addressHint) return null;

  const mapUrl = useMemo(() => {
    const originPoint =
      showFullAddress && address
        ? getAddressForMaps(address.city, address.zip, address.street)
        : getAddressForMaps(addressHint.city, addressHint.postalCode);
    const destinationPoint = getAddressForMaps(
      destinationAddress.city,
      destinationAddress.zip,
      showFullAddress ? destinationAddress.street : undefined
    );
    return `https://www.google.com/maps/dir/${originPoint}/${destinationPoint}`;
  }, [showFullAddress, address, addressHint, destinationAddress]);

  const origin =
    showFullAddress && address
      ? getAddressString(address)
      : getShortAddressString(addressHint);
  const destination = showFullAddress
    ? getAddressString(destinationAddress as AddressFragment)
    : getShortAddressString(destinationAddress as AddressFragment);
  const border = `1px solid ${theme.colors.black.black3}`;

  return (
    <Div display="flex" flexDirection="column" border={border} borderRadius={1}>
      <Div display="flex" borderBottom={border}>
        <Div
          width="50%"
          px={4}
          borderRight={border}
          data-testid="job-card-content-transport-origin"
        >
          <JobDataItem title={t('general.map.origin')} text={origin} />
        </Div>
        <Div
          width="50%"
          px={4}
          data-testid="job-card-content-transport-destination"
        >
          <JobDataItem
            title={t('general.map.destination')}
            text={destination}
          />
        </Div>
      </Div>
      <Div p={4}>
        <Link
          variant="primary"
          href={mapUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('general.map.action')}
        </Link>
      </Div>
    </Div>
  );
};
