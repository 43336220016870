import { FC } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Column, Container, Row, hexToRgba } from '@schibsted-smb/fireball';

import { CUSTOM_BLUE_900 } from '../../constants';
import { CheckList } from '../CheckList';
import { ActionButtons } from './ActionButtons';
import { CardDropdown } from './CardDropdown';
import { Header } from './Header';
import { Packages } from './Packages';
import { PermissionAlert } from './PermissionAlert';

export const SelectPackageState: FC = () => {
  const isMobile = useIsMobile();

  return (
    <Container
      bg={!isMobile && hexToRgba(CUSTOM_BLUE_900, 0.4)}
      borderRadius={!isMobile && 8}
      maxWidth={!isMobile && 580}
      p={isMobile ? 2 : 6}
    >
      <Header />
      <Row justifyContent="center">
        <Packages />
        <CheckList />
        <Column width={345} mt={2} px={0}>
          <CardDropdown />
          <PermissionAlert />
          <ActionButtons />
        </Column>
      </Row>
    </Container>
  );
};
