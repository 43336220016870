import { FC } from 'react';

import { FeatureFlag } from '@components/elements/FeatureFlag';
import JobView from '@components/layout/Job/Job';
import JobsView from '@components/layout/Jobs';
import { SavedFiltersTour } from '@components/layout/Jobs/SavedFilters/SavedFiltersTour';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { JobNavigationProvider } from '@contexts/JobNavigationContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import Paths from '@router/paths';
import { useParams, useNavigate } from 'react-router-dom';

type MatchParams =
  | {
      id: string;
      listId: never;
    }
  | {
      listId?: string;
      id: never;
    };

const Jobs: FC = () => {
  const params = useParams<MatchParams>();
  const navigate = useNavigate();

  const closeJob = () => navigate(Paths.JobsList);
  const isMobile = useIsMobile();

  return (
    <JobNavigationProvider>
      {params.id && <JobView id={params.id} onClose={closeJob} />}
      {!isMobile && (
        <FeatureFlag name={FeatureFlags.BizSavedSearchFeatureTour}>
          <SavedFiltersTour />
        </FeatureFlag>
      )}
      <JobsView listId={params.listId} />
    </JobNavigationProvider>
  );
};

export default Jobs;
