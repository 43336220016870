import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { ListWrapper, ListElement, StyledLink } from './styled';

interface NavigationItem {
  id: number;
  linkTo: string;
  iconName: IconPickerName;
  label: string;
}

interface NavigationItems {
  navigationItems: NavigationItem[];
}

const ProfileNavigation = ({
  navigationItems,
}: NavigationItems): React.ReactElement => {
  const themeContext = useTheme();

  return (
    <ListWrapper>
      {navigationItems.map((item: NavigationItem) => (
        <ListElement key={item.id}>
          <StyledLink
            to={item.linkTo}
            data-testid={`profile-navigation-${item.iconName}`}
          >
            <Div display="flex" alignItems="center">
              <FireIcon
                iconName={item.iconName}
                width={24}
                height={24}
                fill={themeContext.colors.black.black9}
              />
              <Text.span mb={0} ml="18px" fontSize={3}>
                {item.label}
              </Text.span>
            </Div>
            <FireIcon
              iconName={IconPickerName.ChevronRight}
              width={16}
              height={16}
              fill={themeContext.colors.black.black6}
            />
          </StyledLink>
          <Div
            borderBottom={`1px solid ${themeContext.colors.black.black3}`}
            ml="70px"
          />
        </ListElement>
      ))}
    </ListWrapper>
  );
};

export default ProfileNavigation;
