import { CreateContractFormShape } from '../../types/FormShape/CreateContract';
import { CHAT_JOB_QUERY_job_Job } from '../generated/generated';
import { CreateSimpleAgreementContractMutationVariables } from '../mutation/CreateSimpleAgreementContract/CreateSimpleAgreementContractMutation';
import { rawDateToTimestamp } from '../rawDateToTimestamp';

export const CreateSimpleAgreementContractAdapter = (
  formikValues: CreateContractFormShape,
  jobId: CHAT_JOB_QUERY_job_Job['id']
): CreateSimpleAgreementContractMutationVariables['input'] => ({
  jobId,
  priceType: formikValues.priceType,
  description: formikValues.description,
  sign: true,

  ...(formikValues.price && {
    price: parseInt(formikValues.price.toString(), 10),
  }),
  ...(formikValues.start && {
    startDate: rawDateToTimestamp(formikValues.start),
  }),
  ...(formikValues.end && { endDate: rawDateToTimestamp(formikValues.end) }),
  businessEmail: formikValues.businessEmail,
  businessPhone: formikValues.businessPhone,
});
