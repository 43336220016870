import { useMemo } from 'react';

import { canAnswerJob } from '../utils/AnswerAccessUtils';
import { Job } from '../utils/query/Job/JobQuery';
import { JobAccess } from '../utils/query/JobAccess/JobAccessQuery';

interface useCanAnswerJobCheckType {
  (
    job: Pick<Job, 'id' | 'isFree'> & {
      jobBusinessRelationState: { isAnswered: boolean; isDeleted: boolean };
    },
    answerAccess: JobAccess | null,
    flagCheckHook: (flagName: string) => boolean
  ): boolean;
}

export const useCanAnswerJobCheck: useCanAnswerJobCheckType = (
  job,
  answerAccess
): boolean => {
  return useMemo(() => {
    if (
      !job ||
      job.jobBusinessRelationState.isDeleted ||
      job.jobBusinessRelationState.isAnswered
    )
      return false;
    if (!answerAccess) return false;
    return canAnswerJob(answerAccess);
  }, [job, answerAccess]);
};
