import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface MobileBackButtonProps {
  onClick: VoidFunction;
}

export const MobileBackButton: FC<MobileBackButtonProps> = ({ onClick }) => {
  const themeContext = useTheme();

  return (
    <Button variant="linkTertiary" onClick={onClick} px={4}>
      <FireIcon
        iconName={IconPickerName.ChevronLeftBold}
        width={18}
        height={18}
        fill={themeContext.colors.black.black9}
      />
    </Button>
  );
};
