import { ANSWER_JOB_answerJob_AnswerJobPayload } from '../generated/generated';
import { AnswerJobMutationPayload } from '../mutation/AnswerJob/AnswerJobMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class AnswerJobResponseError extends GQLResponseErrorBase<
  AnswerJobMutationPayload['answerJob'],
  ANSWER_JOB_answerJob_AnswerJobPayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'AnswerJobInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'jobId', 'danger');
        this.mapDataToError(data, 'text', 'danger');
        break;

      // case 'AnswerJobAccessError':
      //   this.errors.general = { variant: 'danger', msg: data.message }
      //   break
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
