import { useMemo } from 'react';

import {
  LazyQueryHookOptions,
  QueryResult,
  useLazyQuery,
} from '@apollo/client';

import {
  PRIMARY_BUSINESS_CONTACT,
  PrimaryBusinessContact,
  PrimaryBusinessContactPayload,
} from '../../utils/query/PrimaryBusinessContact/PrimaryBusinessContact';

interface PrimaryBusinessContactQueryResult extends QueryResult {
  data: PrimaryBusinessContact;
}

type FetchPrimaryBusinessContactType = () => Promise<
  QueryResult<PrimaryBusinessContactPayload>
>;

export const usePrimaryBusinessContactLazyQuery = (
  options?: LazyQueryHookOptions<PrimaryBusinessContactPayload>
): [FetchPrimaryBusinessContactType, PrimaryBusinessContactQueryResult] => {
  const [fetchPrimaryContact, { data, ...rest }] =
    useLazyQuery<PrimaryBusinessContactPayload>(PRIMARY_BUSINESS_CONTACT, {
      ...options,
    });

  const memoizedData = useMemo(
    () => ({
      data: data?.primaryBusinessContact ?? null,
      ...rest,
    }),
    [data, rest]
  );

  return [fetchPrimaryContact, memoizedData];
};
