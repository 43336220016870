import { UPDATE_BUSINESS_MUTATION_updateBusiness_Business } from '../generated/generated';
import { UpdateBusinessMutationPayload } from '../mutation/UpdateBusiness/UpdateBusinessMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class UpdateBusinessResponseError extends GQLResponseErrorBase<
  UpdateBusinessMutationPayload['updateBusiness'],
  UPDATE_BUSINESS_MUTATION_updateBusiness_Business
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    const billing = data?.addressError?.billing ?? null;
    const visit = data?.addressError?.visit ?? null;
    const postal = data?.addressError?.postal ?? null;

    switch (data.__typename) {
      case 'UpdateBusinessInputError':
        this.mapDataToError(data, 'nameError', 'danger');
        this.mapDataToError(data, 'phoneError', 'danger');
        this.mapDataToError(data, 'emailError', 'danger');
        this.mapDataToError(data, 'descriptionError', 'danger');
        this.mapDataToError(data, 'websiteError', 'danger');
        this.mapDataToError(data, 'facebookError', 'danger');
        this.mapDataToError(data, 'addressError', 'danger');

        if (billing) {
          if (billing.message) {
            this.errors.addressBilling = {
              variant: 'danger',
              msg: billing.message,
            };
          }
          if (billing.street) {
            this.errors.addressBillingStreet = {
              variant: 'danger',
              msg: billing.street.message,
            };
          }
          if (billing.zip) {
            this.errors.addressBillingZip = {
              variant: 'danger',
              msg: billing.zip.message,
            };
          }
        }

        if (visit) {
          if (visit.message) {
            this.errors.addressVisit = {
              variant: 'danger',
              msg: visit.message,
            };
          }
          if (visit.street) {
            this.errors.addressVisitStreet = {
              variant: 'danger',
              msg: visit.street.message,
            };
          }
          if (visit.zip) {
            this.errors.addressVisitZip = {
              variant: 'danger',
              msg: visit.zip.message,
            };
          }
        }

        if (postal) {
          if (postal.message) {
            this.errors.addressPostal = {
              variant: 'danger',
              msg: postal.message,
            };
          }
          if (postal.street) {
            this.errors.addressPostalStreet = {
              variant: 'danger',
              msg: postal.street.message,
            };
          }
          if (postal.zip) {
            this.errors.addressPostalZip = {
              variant: 'danger',
              msg: postal.zip.message,
            };
          }
        }

        // We only want to display general error, if there's no any others
        if (Object.keys(this.errors).length === 0) {
          this.errors.general = { variant: 'danger', msg: data.message };
        }
        break;

      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
