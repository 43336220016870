import gql from 'graphql-tag';

import {
  QUERY_BOLIGMAPPA_INTEGRATION_STATE as QUERY_BOLIGMAPPA_INTEGRATION_STATE_Result,
  QUERY_BOLIGMAPPA_INTEGRATION_STATE_boligmappaIntegrationState,
} from '../../generated/generated';

export type BoligmappaIntegrationStateQueryPayload =
  QUERY_BOLIGMAPPA_INTEGRATION_STATE_Result;
export type BoligmappaIntegrationStateType =
  QUERY_BOLIGMAPPA_INTEGRATION_STATE_boligmappaIntegrationState;
export const QUERY_BOLIGMAPPA_INTEGRATION_STATE = gql`
  query QUERY_BOLIGMAPPA_INTEGRATION_STATE {
    boligmappaIntegrationState {
      id
      status
    }
  }
`;
