import gql from 'graphql-tag';

import {
  ASK_TO_BE_EVALUATEDVariables,
  ASK_TO_BE_EVALUATED as ASK_TO_BE_EVALUATED_Result,
} from '../../generated/generated';

export type AskToBeEvaluatedPayload = ASK_TO_BE_EVALUATED_Result;
export type AskToBeEvaluatedVariables = ASK_TO_BE_EVALUATEDVariables;
export const ASK_TO_BE_EVALUATED = gql`
  mutation ASK_TO_BE_EVALUATED($jobId: ID!) {
    businessAskToBeEvaluated(input: { jobId: $jobId })
  }
`;
