import { FC } from 'react';

import NotFound from '@components/elements/NotFound';
import { EMPTY_LIST_CONTAINER_MAX_WIDTH } from '@components/layout/CustomerService/Evaluation/EvaluationRequest/EvaluationRequestNoJobs';
import { useTranslation } from 'react-i18next';

interface RequestEvaluationNoSearchResultsProps {
  searchQuery: string;
  onClear: VoidFunction;
}

const EvaluationRequestNoSearchResults: FC<
  React.PropsWithChildren<RequestEvaluationNoSearchResultsProps>
> = ({ searchQuery, onClear }) => {
  const { t } = useTranslation();

  return (
    <NotFound
      maxWidth={EMPTY_LIST_CONTAINER_MAX_WIDTH}
      title={t('evaluation.item.request.noSearchResults.title', {
        query: searchQuery,
      })}
      text={t('evaluation.item.request.noSearchResults.text')}
      button={{
        text: t('evaluation.item.request.noSearchResults.action'),
        onClick: onClear,
      }}
    />
  );
};

export default EvaluationRequestNoSearchResults;
