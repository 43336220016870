import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { Search } from './Search';
import { StyledHeader } from './styled/StyledHeader';

interface HeaderProps {
  onClose: VoidFunction;
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
}

export const Header: FC<HeaderProps> = ({
  onClose,
  searchQuery,
  onSearchQueryChange,
}) => {
  const themeContext = useTheme();

  return (
    <StyledHeader>
      <Search value={searchQuery} onChange={onSearchQueryChange} />
      <Div pl={6} borderLeft={`1px solid ${themeContext.colors.black.black4}`}>
        <Button
          onClick={onClose}
          variant="linkTertiary"
          p={0}
          data-testid="modal-header-message-template-close"
        >
          <FireIcon
            iconName={IconPickerName.Cross}
            variant="flat"
            fill={themeContext.colors.black.black9}
            width={20}
            height={20}
          />
        </Button>
      </Div>
    </StyledHeader>
  );
};
