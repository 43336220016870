import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const ChatPersonInfoContentLoader: React.FC<
  React.PropsWithChildren<LoaderProps>
> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 255 59"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    <circle cx="15" cy="30" r="15" />
    <rect x="40" y="10" rx="3" ry="3" width="155" height="18" />
    <rect x="40" y="33" rx="3" ry="3" width="210" height="16" />
  </ContentLoader>
);

export default ChatPersonInfoContentLoader;
