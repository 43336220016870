import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ActionButtonsContainer = styled(Div)`
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  display: flex;
`;

export default ActionButtonsContainer;
