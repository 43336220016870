import ScrollPanel from '@components/base/ScrollPanel';
import styled from 'styled-components';

const StyledScrollPanel = styled(ScrollPanel)`
  .simplebar-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    height: auto;
  }
`;

export default StyledScrollPanel;
