import { FC } from 'react';

import { PrivacyAndTerms } from '@components/layout/Settings/PrivacyAndTerms';
import withNavigationLayout from '@components/layout/WithNavigation';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsPrivacyAndTerms: FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: PrivacyAndTerms,
      subpageName: t('settings.privacyAndTerms.title'),
    },
  })();
};
