import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobFolder } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import { Checkbox } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { FolderDropdownItemContainer, EditFolderButton } from './styled';

export interface FolderDropdownItemProps {
  id: string;
  text: JobFolder['title'];
  color: JobFolder['color'];
  isActive: boolean;
  onClick: () => void;
  onEditClick?: () => void;
}

const FolderDropdownItem: React.FC<
  React.PropsWithChildren<FolderDropdownItemProps>
> = ({ id, text, color, isActive, onClick, onEditClick }) => {
  const themeContext = useTheme();

  return (
    <FolderDropdownItemContainer>
      <Checkbox
        id={id}
        label={text}
        name={text}
        px={4}
        py={5}
        m={0}
        color={color ? 'black.black0' : 'black.black10'}
        backgroundColor={color}
        checked={isActive}
        onChange={onClick}
        borderRadius={themeContext.radii[1]}
      />
      {!!onEditClick && (
        <EditFolderButton onClick={onEditClick} variant="secondary">
          <FireIcon
            iconName={IconPickerName.Pencil}
            variant="flat"
            fill={themeContext.colors.primary.base}
            width="16px"
            height="16px"
          />
        </EditFolderButton>
      )}
    </FolderDropdownItemContainer>
  );
};

export default FolderDropdownItem;
