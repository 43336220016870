import { ContractPriceType } from '../../../../utils/generated/generated';

const contractPriceLabel = (priceType: ContractPriceType): string => {
  switch (priceType) {
    case ContractPriceType.FIXED:
      return 'fixed';
    case ContractPriceType.HOUR_COMPUTATION:
      return 'hourComputation';
    case ContractPriceType.NO_COMPUTATION:
      return 'unknown';
    case ContractPriceType.OTHER:
      return 'unknown';
    default:
      return 'unknown';
  }
};

export default contractPriceLabel;
