import { FC } from 'react';

import { Logo } from '@components/base/Logo';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import {
  Button,
  Column,
  Div,
  hexToRgba,
  Heading,
  Row,
  Text,
  Container,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BLUE_900 } from '../constants';
import { useBoostCreditsModalContext } from '../ModalContext';
import { PackageCard } from './PackageCard';

export const SummaryState: FC = () => {
  const { selectedPackage, handleModalClose } = useBoostCreditsModalContext();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Container
      bg={!isMobile && hexToRgba(CUSTOM_BLUE_900, 0.4)}
      borderRadius={!isMobile && 8}
      maxWidth={!isMobile && 700}
      p={isMobile ? 2 : 6}
    >
      <Row flexDirection={isMobile ? 'column' : 'row'} py={8}>
        <Column
          display="flex"
          flexDirection="column"
          alignItems="center"
          width={isMobile ? 1 : 1 / 2}
          mb={isMobile ? 9 : 0}
          pl={0}
          pr={isMobile ? 0 : 5}
        >
          <Div mb={7}>
            <Logo height="70px" isIconOnly />
          </Div>
          <Heading.h2 color="black.black0" mb={4}>
            {t('job.modal.boost.summary.title')}
          </Heading.h2>
          <Text.p
            color="black.black0"
            textAlign="center"
            lineHeight={1.45}
            fontSize={3}
            mb={8}
          >
            {t('job.modal.boost.summary.description')}
          </Text.p>
          <Button onClick={handleModalClose}>
            {t('general.action.goBackToJob')}
          </Button>
        </Column>
        <Column
          display="flex"
          flexDirection="column"
          justifyContent={isMobile ? 'center' : 'flex-start'}
          width={isMobile ? 1 : 1 / 2}
          pl={isMobile ? 0 : 5}
          pr={0}
        >
          <Div p={isMobile ? 0 : 20}>
            <Text.p color="black.black0" fontSize={3}>
              {t('job.modal.boost.summary.yourPurchase')}
            </Text.p>
            <PackageCard {...selectedPackage} />
          </Div>
        </Column>
      </Row>
    </Container>
  );
};
