import { FC } from 'react';

import FilterBadge from '@components/elements/JobsFilters/FilterBadge';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Div } from '@schibsted-smb/fireball';

import { FilterMultipleBadge } from './styled';

export interface FilterType {
  id: string;
  name: string;
}

interface SelectedFiltersProps {
  filterList: FilterType[];
  onClick: (filterId: string | string[]) => void;
}

interface MultipleSelectedFiltersProps {
  filterList: FilterType[];
  onItemClick: (filterId: string) => void;
}

interface CombinedSelectedFiltersProps {
  filterList: FilterType[];
  onClick: (filterId: string[]) => void;
}

const MultipleSelectedFilters: FC<
  React.PropsWithChildren<MultipleSelectedFiltersProps>
> = ({ filterList, onItemClick }) => (
  <FilterMultipleBadge>
    {filterList.map((filter) => (
      <FilterBadge
        onClick={() => onItemClick(filter.id)}
        text={filter.name}
        key={filter.id}
      />
    ))}
  </FilterMultipleBadge>
);

const CombinedSelectedFilters: FC<
  React.PropsWithChildren<CombinedSelectedFiltersProps>
> = ({ filterList, onClick }) => {
  const filterCount = filterList.length;
  const text = `(${filterCount}) ${filterList.map((f) => f.name).join(', ')}`;
  const handleOnClick = () => {
    onClick(filterList.map((filter) => filter.id));
  };
  return (
    <Div minWidth="40%" display="flex" justifyContent="flex-end">
      <FilterBadge onClick={handleOnClick} text={text} />
    </Div>
  );
};

const SelectedFilters: FC<React.PropsWithChildren<SelectedFiltersProps>> = ({
  filterList,
  onClick,
}) => {
  const isMobile = useIsMobile();
  // when exceeding this number, selected filters are merged into one item
  const FILTER_SEPARATE_DISPLAY_LIMIT = isMobile ? 2 : 1;
  const filterCount = filterList.length;
  if (!filterCount) return null;
  if (filterCount <= FILTER_SEPARATE_DISPLAY_LIMIT) {
    return (
      <MultipleSelectedFilters filterList={filterList} onItemClick={onClick} />
    );
  }
  return <CombinedSelectedFilters filterList={filterList} onClick={onClick} />;
};

export default SelectedFilters;
