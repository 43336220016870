import { FC } from 'react';

import { ProductPackageStatusTag } from '@components/layout/Products/ProductPackageStatusTag';
import {
  StyledProductAddonSubtitle,
  StyledProductTitle,
} from '@components/layout/Products/styled';
import { ProductPackageStatus } from '@internals/business-shared/src/components/Products/productTypes';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProductAddonCardProps {
  title: string;
  subtitle: string;
  onClick: VoidFunction;
  status?: ProductPackageStatus;
}

export const ProductAddonCard: FC<ProductAddonCardProps> = ({
  title,
  subtitle,
  status,
  onClick,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      border={`1px solid ${themeContext.colors.black.black4}`}
      borderRadius={3}
      p={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Div mr={3}>
        <Div display="flex" alignItems="center" mb={1}>
          <StyledProductTitle mr={2} mb={0}>
            {title}
          </StyledProductTitle>
          <ProductPackageStatusTag status={status} />
        </Div>
        <StyledProductAddonSubtitle>{subtitle}</StyledProductAddonSubtitle>
      </Div>
      <Button
        variant="tertiary"
        onClick={onClick}
        flexShrink={0}
        flexWrap="nowrap"
        px={5}
        py={3}
        borderRadius={4}
      >
        {t('general.label.readMore')}
      </Button>
    </Div>
  );
};
