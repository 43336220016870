import { FC } from 'react';

import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import {
  useDistrictFilterChange,
  useJobFiltersContext,
  useMunicipalityFilterChange,
} from '@contexts/JobFiltersContext';
import {
  allDistrictsInMunicipalityActive,
  CountiesMunicipality,
  isDistrictActive,
  isMunicipalityActive,
} from '@internals/business-shared/src/utils/areasUtils';

interface MunicipalityViewProps {
  onBackClick: VoidFunction;
  municipality: CountiesMunicipality;
}

const MunicipalityView: FC<React.PropsWithChildren<MunicipalityViewProps>> = ({
  onBackClick,
  municipality,
}) => {
  const { activeMunicipalities, activeDistricts } = useJobFiltersContext();
  const handleOnMunicipalityChange = useMunicipalityFilterChange();
  const handleOnDistrictChange = useDistrictFilterChange();

  const isActive =
    isMunicipalityActive(municipality, activeMunicipalities) ||
    allDistrictsInMunicipalityActive(municipality, activeDistricts);
  const activeDistrictsInMunicipality = municipality.districts.filter(
    (district) => activeDistricts.includes(district.id)
  );
  const municipalityDistrictsCount = municipality.districts.length;

  const toggleAllAction = {
    action: () => handleOnMunicipalityChange(municipality, !isActive),
    selectedFilterCount: activeDistrictsInMunicipality.length,
    totalFilterCount: municipalityDistrictsCount,
  };

  return (
    <FilterView
      title={municipality.name}
      onBackClick={onBackClick}
      toggleAllAction={toggleAllAction}
    >
      <FilterGrid>
        {municipality.districts.map((district) => {
          const districtActive = isDistrictActive(district, activeDistricts);
          return (
            <FilterToggle
              key={district.id}
              name={district.name}
              isChecked={districtActive}
              onChange={() =>
                handleOnDistrictChange(district.id, !districtActive)
              }
            />
          );
        })}
      </FilterGrid>
    </FilterView>
  );
};

export default MunicipalityView;
