import gql from 'graphql-tag';

import EMPLOYEE_FRAGMENT from '../../fragments/EmployeeFragment';
import {
  UPDATE_BUSINESS_EMPLOYEEVariables,
  UPDATE_BUSINESS_EMPLOYEE as UPDATE_BUSINESS_EMPLOYEE_Result,
  UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload,
} from '../../generated/generated';

export type UpdateBusinessEmployeeMutationPayload =
  UPDATE_BUSINESS_EMPLOYEE_Result;
export type UpdateBusinessEmployeeMutationVariables =
  UPDATE_BUSINESS_EMPLOYEEVariables;

export const isUpdateBusinessEmployeeSuccessResponse = (
  updateBusinessEmployee?: UPDATE_BUSINESS_EMPLOYEE_Result['updateBusinessEmployee']
): updateBusinessEmployee is UPDATE_BUSINESS_EMPLOYEE_updateBusinessEmployee_UpdateBusinessEmployeePayload =>
  !!updateBusinessEmployee &&
  updateBusinessEmployee.__typename === 'UpdateBusinessEmployeePayload';

export const UPDATE_BUSINESS_EMPLOYEE = gql`
  ${EMPLOYEE_FRAGMENT}

  mutation UPDATE_BUSINESS_EMPLOYEE($input: UpdateBusinessEmployeeInput!) {
    updateBusinessEmployee(input: $input) {
      ... on UpdateBusinessEmployeePayload {
        employee {
          ...EmployeeFragment
          __typename
        }
        __typename
      }

      ... on UpdateBusinessEmployeeInputError {
        message
        employeeId {
          message
        }
        position {
          message
        }
        firstName {
          message
        }
        lastName {
          message
        }
        phoneNr {
          message
        }
        email {
          message
        }
        image {
          message
        }
      }
      ... on UpdateBusinessEmployeeNoAccessError {
        message
      }
    }
  }
`;
