import { FC } from 'react';

import { Button, Alert, Div, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { getJobItemPath } from '@utils/url';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

interface JobAnsweredAlertProps {
  evaluationTimestamp: number;
  jobId: string | undefined;
}

const JobEvaluatedAlert: FC<JobAnsweredAlertProps> = ({
  evaluationTimestamp,
  jobId,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  return (
    <Alert
      variant="success"
      size="medium"
      isCentered
      m={0}
      height="60px"
      borderRadius={0}
      width="100%"
    >
      <Div
        display="flex"
        alignItems="center"
        data-testid="alert-evaluation-replied"
      >
        <Text.p fontSize={1} fontWeight={themeContext.fontWeights.medium} m={0}>
          {t('evaluation.item.evaluated.evaluatedDateText', {
            when: dateFromTimestamp(evaluationTimestamp),
          })}
        </Text.p>
        {jobId && (
          <Link to={getJobItemPath(jobId)}>
            <Button variant="primary" size="tiny" ml={5}>
              {t('evaluation.item.evaluated.goToJob')}
            </Button>
          </Link>
        )}
      </Div>
    </Alert>
  );
};

export default JobEvaluatedAlert;
