import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterButtonWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.black.black4}`};
  min-width: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-bottom: 0;
  }
`;

export default FilterButtonWrapper;
