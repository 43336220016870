import { GQLErrorState, SetGQLErrorState, VariantType } from '.';

export abstract class GQLResponseErrorBase<
  PAYLOAD_DATA_TYPE extends { __typename: string },
  SUCCESS_PAYLOAD_DATA_TYPE extends { __typename: string }
> {
  private readonly RESPONSE_OK: string = 'RESPONSE_OK';

  protected errors: GQLErrorState = {};

  /**
   * Just pass the success response __typename, for class to be able to check if payload type is an error or success
   * @constructor
   *
   * @param successResponseTypename
   *
   */
  protected constructor(successResponseTypename: string) {
    this.RESPONSE_OK = successResponseTypename;
    this.errors = {};
  }

  /**
   * Check if errors object is not empty
   * @method
   *
   */
  private anyErrors(): boolean {
    return this.errors ? Object.keys(this.errors).length > 0 : false;
  }

  /**
   * Check if payload data is set and the response type is an error
   * @method
   *
   * @param data
   *
   */
  private payloadError(data: PAYLOAD_DATA_TYPE): boolean {
    if (!data) {
      return false; // we probably have general error
    }
    return data.__typename !== this.RESPONSE_OK;
  }

  /**
   * Check if payload data can be treated as an payload error data
   * @method
   *
   *
   * @param data
   *
   */
  private isErrorResponseData(
    data: PAYLOAD_DATA_TYPE
  ): data is Exclude<PAYLOAD_DATA_TYPE, SUCCESS_PAYLOAD_DATA_TYPE> {
    return data.__typename !== this.RESPONSE_OK;
  }

  /**
   * It's the only method you need to implement. For given payload data, go use `__typename` field to deal with an error type
   * @method
   *
   * @param data - payload data
   *
   */
  protected abstract handleErrors(
    data: Exclude<PAYLOAD_DATA_TYPE, SUCCESS_PAYLOAD_DATA_TYPE>
  ): void;

  /**
   * General method for response error checking
   * @method
   *
   * @param data  - payload data
   * @param errorSetter - optional state setter
   *
   */
  public hasResponseError(
    data: PAYLOAD_DATA_TYPE,
    errorSetter?: SetGQLErrorState
  ): boolean {
    if (!this.payloadError(data)) {
      this.errors = {};
      errorSetter && errorSetter({});
      return false;
    }

    this.isErrorResponseData(data) && this.handleErrors(data); // must be implemented

    // setter is optional
    errorSetter && errorSetter(this.errors);

    return this.anyErrors();
  }

  /**
   * Errors object getter
   * @method
   *
   */
  public getErrors(): GQLErrorState {
    return this.errors;
  }

  /**
   * Utility class for setting errors from data
   * @method
   *
   * @param data - error payload data
   * @param field - error field
   * @param variant - alert variant
   * @param addAsGeneralError - should error treated as a general error
   */
  public mapDataToError(
    data: Exclude<PAYLOAD_DATA_TYPE, SUCCESS_PAYLOAD_DATA_TYPE>,
    field: string,
    variant: VariantType,
    addAsGeneralError = false
  ): void {
    // @ts-ignore
    if (data[field]) {
      if (addAsGeneralError) {
        // @ts-ignore
        this.errors.general = { msg: data[field].message, variant };
      } else {
        // @ts-ignore
        this.errors[field] = { msg: data[field].message, variant };
      }
    }
  }
}
