import { FC } from 'react';

import CardHeader from '@components/base/CardHeader';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import { Button, Div } from '@schibsted-smb/fireball';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import AddButton from './styled/AddButton';
import StyledTitle from './styled/StyledTitle';

interface CustomServiceCardHeader {
  iconName: IconPickerName;
  title: string;
  buttonText: string;
  onButtonClick: () => void;
  buttonPadding?: number | string;
  isNewButtonVisible?: boolean;
}

const CustomerServiceCardHeader: FC<CustomServiceCardHeader> = ({
  iconName,
  title,
  buttonText,
  onButtonClick,
  buttonPadding,
  isNewButtonVisible = true,
}) => {
  const themeContext = useTheme();
  return (
    <CardHeader
      pt={[5, null, null, null, null, 35]}
      pb="0px"
      px={[0, null, null, null, null, 8]}
      bg={['#FFFFFF', null, null, null, null, '#F2F4F5']}
    >
      <Div display="flex" flexDirection="column" width="100%">
        <Div
          display="flex"
          justifyContent="space-between"
          mb={25}
          flex="1"
          position="relative"
        >
          <Div
            display="flex"
            alignItems="center"
            px={[6, null, null, null, null, 0]}
          >
            <Div display={['block', null, null, null, null, 'none']}>
              <Link to={Paths.Customer}>
                <Div width={24} height={24} lineHeight={0}>
                  <FireIcon
                    iconName={IconPickerName.ChevronLeft}
                    variant="flat"
                  />
                </Div>
              </Link>
            </Div>
            <Div
              width={32}
              height={32}
              display={['none', null, null, null, null, 'block']}
            >
              <FireIcon iconName={iconName} variant="color" />
            </Div>
            <StyledTitle
              fontSize={[4, null, null, null, null, 5]}
              fontWeight={themeContext.fontWeights.bold}
            >
              {title}
            </StyledTitle>
          </Div>
          {isNewButtonVisible && (
            <Button
              variant="primary"
              minWidth="160px"
              onClick={onButtonClick}
              display={['none', null, null, null, null, 'block']}
            >
              {buttonText}
            </Button>
          )}
          {isNewButtonVisible && (
            <AddButton
              onClick={onButtonClick}
              display={['block', null, null, null, null, 'none']}
              p={buttonPadding}
            >
              <FireIcon iconName={IconPickerName.Plus} variant="flat" />
            </AddButton>
          )}
        </Div>
      </Div>
    </CardHeader>
  );
};

export default CustomerServiceCardHeader;
