import * as React from 'react';

import ListItemRow from '@components/elements/CustomerServiceListItem/styled/ListItemRow';
import StyledLink from '@components/elements/CustomerServiceListItem/styled/StyledLink';
import { Column } from '@schibsted-smb/fireball';

interface CustomerServiceListItemProps {
  py?: number | string;
  link?: string;
  firstColumn: React.ReactElement;
  secondColumn?: React.ReactElement;
  thirdColumn?: React.ReactElement;
  circledButton?: React.ReactElement;
  onClick?: () => void;
  personDataName?: string;
  personDataDetails?: string;
  costInfo?: string;
  costInfoDetails?: string;
  infoWithIconTitle?: string;
  infoWithIconDetails?: string;
  infoWithIconStatusDetails?: string;
  evaluationValue?: number;
  actionButtons?: Record<string, unknown>[];
  testId?: string;
}

const CustomerServiceListItem: React.FC<
  React.PropsWithChildren<CustomerServiceListItemProps>
> = ({
  py = 6,
  link,
  firstColumn,
  secondColumn,
  thirdColumn,
  circledButton,
  onClick,
  personDataName,
  personDataDetails,
  costInfo,
  costInfoDetails,
  infoWithIconTitle,
  infoWithIconDetails,
  infoWithIconStatusDetails,
  testId,
}) => {
  const componentContent = (
    <>
      <Column
        {...(secondColumn ? { width: 4 / 12 } : { flex: 1 })}
        pl={2}
        py={py}
        onClick={onClick}
        data-testid={`customer-list-item-col-1-${testId}`}
      >
        {firstColumn}
      </Column>
      {secondColumn && (
        <Column
          flex="1"
          p={0}
          py={py}
          onClick={onClick}
          data-testid={`customer-list-item-col-2-${testId}`}
        >
          {secondColumn}
        </Column>
      )}
      {thirdColumn && (
        <Column
          p={0}
          pr={15}
          py={py}
          onClick={onClick}
          data-testid={`customer-list-item-col-3-${testId}`}
        >
          {thirdColumn}
        </Column>
      )}
    </>
  );

  return link ? (
    <ListItemRow
      py={py}
      onClick={onClick}
      data-testid={`list-item-row-link-${testId}`}
    >
      <Column flex="1" p={0}>
        <StyledLink
          to={{
            search: link,
            state: {
              personDataName,
              personDataDetails,
              costInfo,
              costInfoDetails,
              infoWithIconTitle,
              infoWithIconDetails,
              infoWithIconStatusDetails,
            },
          }}
        >
          {componentContent}
        </StyledLink>
      </Column>
      <Column width={35} p={0} alignSelf="center">
        {circledButton}
      </Column>
    </ListItemRow>
  ) : (
    <ListItemRow data-testid={`list-item-row-${testId}`}>
      {componentContent}
      <Column width={35} p={0} alignSelf="center">
        {circledButton}
      </Column>
    </ListItemRow>
  );
};

export default CustomerServiceListItem;
