import { Input } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledTitleInput = styled(Input)`
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};

  &,
  &:hover,
  &:focus {
    border: none;
    padding: 0;
    box-shadow: none;
  }
`;
