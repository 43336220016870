import { FC } from 'react';

import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { WorkTypeGroup } from '@internals/business-shared/src/utils/workTypesUtils';

interface WorkTypeGroupViewProps {
  onBackClick: VoidFunction;
  workTypeGroup: WorkTypeGroup;
}

const WorkTypeGroupView: FC<
  React.PropsWithChildren<WorkTypeGroupViewProps>
> = ({ onBackClick, workTypeGroup }) => {
  const { activeWorkTypes, setActiveWorkTypes } = useJobFiltersContext();
  const numberOfSelected = workTypeGroup.workTypes.filter((workType) =>
    activeWorkTypes.includes(workType.id)
  ).length;
  const availableToSelect = workTypeGroup.workTypes.length;
  const allSelected = numberOfSelected === availableToSelect;

  const selectAll = () => {
    const updatedWorkTypes = workTypeGroup.workTypes.reduce(
      (list, workType) => toggleItemInArray(list, workType.id, true),
      activeWorkTypes
    );
    setActiveWorkTypes(updatedWorkTypes);
  };

  const unselectAll = () => {
    const updatedWorkTypes = workTypeGroup.workTypes.reduce(
      (list, workType) => toggleItemInArray(list, workType.id, false),
      activeWorkTypes
    );
    setActiveWorkTypes(updatedWorkTypes);
  };

  const toggleAll = () => {
    if (allSelected) {
      unselectAll();
      return;
    }
    selectAll();
  };

  const toggleFilter = (workTypeId: string) => {
    setActiveWorkTypes(toggleItemInArray(activeWorkTypes, workTypeId));
  };

  const toggleAllAction = {
    action: toggleAll,
    selectedFilterCount: numberOfSelected,
    totalFilterCount: availableToSelect,
  };

  return (
    <FilterView
      title={workTypeGroup.name}
      onBackClick={onBackClick}
      toggleAllAction={toggleAllAction}
    >
      <FilterGrid>
        {workTypeGroup.workTypes.map((workType) => (
          <FilterToggle
            key={`${workType.id}-${workType.name}`}
            name={workType.name}
            isChecked={activeWorkTypes.includes(workType.id)}
            onChange={() => toggleFilter(workType.id)}
          />
        ))}
      </FilterGrid>
    </FilterView>
  );
};

export default WorkTypeGroupView;
