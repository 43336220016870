import gql from 'graphql-tag';

import {
  UPDATE_BUSINESS_MUTATIONVariables,
  UPDATE_BUSINESS_MUTATION as UPDATE_BUSINESS_MUTATION_Result,
} from '../../generated/generated';

export type UpdateBusinessMutationPayload = UPDATE_BUSINESS_MUTATION_Result;
export type UpdateBusinessMutationVariables = UPDATE_BUSINESS_MUTATIONVariables;

export const UPDATE_BUSINESS_MUTATION = gql`
  mutation UPDATE_BUSINESS_MUTATION($data: UpdateBusinessInput!) {
    updateBusiness(data: $data) {
      ... on Business {
        id
        name
        phone
        email
        website
        facebook
        addresses {
          id
          billing {
            id
            type
            description
            city
            street
            lat
            lon
            zip
          }
          visit {
            id
            type
            description
            city
            street
            lat
            lon
            zip
          }
          post {
            id
            type
            description
            city
            street
            lat
            lon
            zip
          }
        }
      }
      ... on UpdateBusinessInputError {
        message

        nameError: name {
          message
        }
        phoneError: phone {
          message
        }
        emailError: email {
          message
        }
        descriptionError: description {
          message
        }
        websiteError: website {
          message
        }
        facebookError: facebook {
          message
        }
        addressError: address {
          message
          billing {
            message
            street {
              message
            }
            zip {
              message
            }
          }
          visit {
            message
            street {
              message
            }
            zip {
              message
            }
          }
          postal {
            message
            street {
              message
            }
            zip {
              message
            }
          }
        }
      }
    }
  }
`;
