import { FC, PropsWithChildren } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import Navigation, { NavigationModel } from '@components/elements/Navigation';
import Contract from '@components/layout/CustomerService/Contract';
import Evaluation from '@components/layout/CustomerService/Evaluation';
import withNavigationLayout from '@components/layout/WithNavigation';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import { useTranslation } from 'react-i18next';

export const CustomerServiceNavigation: FC<
  PropsWithChildren<{ isFirstDefaultActive: boolean }>
> = ({ isFirstDefaultActive }) => {
  const { t } = useTranslation();
  const customerServiceModel: NavigationModel[] = [];
  const contractEnabled = useFlagEnabled(FeatureFlags.BizContract);

  if (contractEnabled) {
    customerServiceModel.push({
      link: Paths.CustomerContract,
      icon: IconPickerName.Contract,
      label: t('contract.item.title'),
      testId: 'CONTRACT',
    });
  }

  customerServiceModel.push({
    link: Paths.CustomerEvaluation,
    icon: IconPickerName.Evaluation,
    label: t('evaluation.list.title'),
    testId: 'EVALUATION',
  });

  return (
    <Navigation
      navigationItems={customerServiceModel}
      isFirstDefaultActive={isFirstDefaultActive}
      isMobileGray
      countAsBadge
    />
  );
};

const CustomerServiceDefaultPage: FC<PropsWithChildren<any>> = () => {
  const contractEnabled = useFlagEnabled(FeatureFlags.BizContract);

  const getDefaultPageComponent = () => {
    if (contractEnabled) {
      return Contract;
    }

    return Evaluation;
  };

  const DefaultPageComponent = getDefaultPageComponent();

  return DefaultPageComponent && <DefaultPageComponent />;
};

export const CustomerService: FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: () => CustomerServiceNavigation({ isFirstDefaultActive: true }),
    pageName: t('customer.list.title'),
    isMainPage: true,
    pageDetails: {
      PageContent: CustomerServiceDefaultPage,
      disableMobileHeader: true,
    },
  })();
};
