import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  PROFILE_PICTURES_QUERY as PROFILE_PICTURES_QUERY_Result,
  PROFILE_PICTURES_QUERYVariables,
} from '../../generated/generated';

export type ProfilePicturesQueryPayload = PROFILE_PICTURES_QUERY_Result;
export type ProfilePicturesQueryVariables = PROFILE_PICTURES_QUERYVariables;

export const PROFILE_PICTURES_QUERY = gql`
  ${IMAGE_FRAGMENT}
  query PROFILE_PICTURES_QUERY($id: ID!) {
    businessAlbums(input: { businessId: $id }) {
      id
      images {
        id
        description
        sort
        album {
          id
        }
        image {
          ...ImageFragment
        }
        sort
      }
      name
    }
  }
`;
