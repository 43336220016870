import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import StyledButton from '@components/elements/ProfileEditListItem/styled/StyledButton';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Image, Avatar } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface ProfileEditListItemProps {
  image?: string | null;
  text: Record<string, string>;
  renderText: (text: Record<string, string>) => React.ReactNode;
  icon?: IconPickerName;
  renderComponent?: () => React.ReactNode;
  onClick: () => void;
  wideBottomBorder?: boolean;
  testId: string;
}

const ProfileEditListItem: React.FC<
  React.PropsWithChildren<ProfileEditListItemProps>
> = ({
  image,
  text,
  renderText,
  icon,
  renderComponent,
  onClick,
  wideBottomBorder,
  testId,
}) => {
  const themeContext = useTheme();
  const initials: string[] = [];

  if (text.firstName && text.lastName) {
    text.firstName
      .split(' ')
      .forEach((name, index) => index < 2 && initials.push(name.slice(0, 1)));
    initials.push(text.lastName.slice(0, 1));
  }

  return (
    <>
      <StyledButton
        onClick={onClick}
        width="100%"
        display="flex"
        justifyContent="space-between"
        variant="tertiary"
        border="none"
        m={0}
        p={6}
        data-testid={`profile-list-item-${testId}`}
      >
        <Div
          display="flex"
          alignItems="center"
          maxWidth="100%"
          overflow="hidden"
        >
          {image && (
            <Image
              width="65px"
              height="65px"
              borderRadius={themeContext.radii[2]}
              src={image}
              mr={5}
              border={`1px solid ${themeContext.colors.black.black3}`}
              data-testid={`profile-list-item-thumbnail-${testId}`}
              flexShrink={0}
            />
          )}
          {!image && initials.length > 0 && (
            <Avatar initials={initials.join('')} size="l" mr={4} />
          )}
          <Div
            display="flex"
            flexDirection="column"
            justifyContent="center"
            overflow="hidden"
          >
            {renderText(text)}
          </Div>
        </Div>
        <Div ml={6}>
          {icon && (
            <FireIcon
              iconName={icon}
              variant="flat"
              width="16px"
              height="16px"
            />
          )}
          {renderComponent && renderComponent()}
        </Div>
      </StyledButton>
      <Div
        borderBottom={`1px solid ${themeContext.colors.black.black3}`}
        ml={wideBottomBorder ? '20px' : '100px'}
      />
    </>
  );
};

export default ProfileEditListItem;
