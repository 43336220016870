import { getExtensionFromImageType } from '../../FileUtils';
import { SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images } from '../../generated/generated';
import getOptimisticId from '../id';

interface ImageParams {
  name: string;
  size: number;
  fileType: File['type'];
  id?: string;
  url?: string;
}

export const getOptimisticImage = (
  imageData: ImageParams
): SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images => {
  const { name, size, id = getOptimisticId(), url = '', fileType } = imageData;
  const fileExtension = getExtensionFromImageType(fileType);

  return {
    __typename: 'Image',
    id,
    name,
    size,
    description: '',
    url,
    dimensions: {
      __typename: 'Dimension',
      width: 125,
      height: 125,
    },
    mediumUrl: url,
    smallUrl: url,
    bigUrl: url,
    bigPreviewUrl: url,
    mediumPreviewUrl: url,
    smallPreviewUrl: url,
    fileType: fileExtension,
  };
};

export default getOptimisticImage;
