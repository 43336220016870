import gql from 'graphql-tag';

import {
  SET_RECEIVE_NEWSLETTERSVariables,
  SET_RECEIVE_NEWSLETTERS as SET_RECEIVE_NEWSLETTERS_Result,
} from '../../generated/generated';

export type SetReceiveNewslettersMutationPayload =
  SET_RECEIVE_NEWSLETTERS_Result;
export type SetReceiveNewslettersMutationVariables =
  SET_RECEIVE_NEWSLETTERSVariables;

export const SET_RECEIVE_NEWSLETTERS = gql`
  mutation SET_RECEIVE_NEWSLETTERS($value: Boolean!) {
    setReceiveNewsletters(value: $value)
  }
`;
