import { JOB_MODAL_MAX_WIDTH } from '@components/layout/Job/styled/constants';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const StyledJobNavigation = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: space-between;
  z-index: ${Layer.modal + Layer.above};
  width: 100%;
  pointer-events: none;
  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    width: ${JOB_MODAL_MAX_WIDTH};
  }
`;
