import { FC } from 'react';

import { ProductAddonCardExtended } from '@components/layout/Products/ProductAddonCardExtended';
import { StyledProductSectionTitle } from '@components/layout/Products/styled';
import { getProductAddonsMock } from '@internals/business-shared/src/components/Products/productMocks';
import { getProductAddonContent } from '@internals/business-shared/src/components/Products/productUtils';
import { Div } from '@schibsted-smb/fireball';
import { getAddonPath } from '@utils/url';
import { useNavigate } from 'react-router-dom';

export const AllAddonsList: FC = () => {
  const navigate = useNavigate();
  const addonList = getProductAddonsMock();

  return (
    <>
      {addonList.map((addon) => {
        const { addonName, title, description, icon } = getProductAddonContent({
          addonSlug: addon.slug,
        });
        return (
          <Div key={addon.id} mb={9}>
            <StyledProductSectionTitle>{title}</StyledProductSectionTitle>
            <ProductAddonCardExtended
              title={addonName}
              subtitle={description}
              onClick={() => navigate(getAddonPath(addon.slug))}
              icon={icon}
              status={addon.status}
            />
          </Div>
        );
      })}
    </>
  );
};
