import {
  FC,
  PropsWithChildren,
  Reducer,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';

import {
  BoostPaymentInput,
  PaymentResult,
  SubscriptionPaymentInput,
  useProductPaymentResult,
} from '@hooks/useProductPaymentResult';
import { useResetAllParams } from '@hooks/useResetAllParams';
import {
  PaymentAction,
  PaymentReducer,
  PaymentState,
  PaymentStatus,
  initialState,
} from '@internals/business-shared/src/reducers/PaymentReducer';
import { LatestPaymentAttemptResultCode } from '@internals/business-shared/src/utils/generated/generated';
import { bugsnagClient } from '@utils/initBugsnag';
import { useSearchParams } from 'react-router-dom';

interface PaymentContextType {
  state: PaymentState;
  initBoostPayment: (input: BoostPaymentInput) => void;
  initSubscriptionPayment: (input: SubscriptionPaymentInput) => void;
  resetPayment: VoidFunction;
}

const SEARCH_PARAMS_KEYS = { RESULT_CODE: 'resultCode' };
const PAID_RESULT_CODES = [LatestPaymentAttemptResultCode.PAID, '10'];

const PaymentContext = createContext<PaymentContextType | undefined>(undefined);

export const PaymentProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getBoostPaymentResult, getSubscriptionPaymentResult } =
    useProductPaymentResult();
  const [searchParams] = useSearchParams();
  const [state, dispatch] = useReducer<Reducer<PaymentState, PaymentAction>>(
    PaymentReducer,
    initialState
  );
  const resetAllParams = useResetAllParams(SEARCH_PARAMS_KEYS);

  const handlePaymentResult = useCallback(
    ({ paymentUrl, resultCode }: PaymentResult) => {
      if (paymentUrl) {
        window.open(paymentUrl, '_self');
      } else if (PAID_RESULT_CODES.includes(resultCode)) {
        dispatch({ type: PaymentStatus.SUCCEEDED });
      } else {
        throw new Error('Payment not completed');
      }
    },
    []
  );

  const handlePaymentError = useCallback((error: Error | unknown) => {
    const errorMessage =
      error instanceof Error ? error.message : 'An unknown error occurred';
    dispatch({ type: PaymentStatus.FAILED, errorMessage });
    bugsnagClient.notify(`Payment failed: ${errorMessage}`);
  }, []);

  const initBoostPayment = useCallback(
    async (input: BoostPaymentInput) => {
      try {
        dispatch({ type: PaymentStatus.INIT });
        const paymentResult = await getBoostPaymentResult(input);
        handlePaymentResult(paymentResult);
      } catch (error) {
        handlePaymentError(error);
      }
    },
    [getBoostPaymentResult, handlePaymentError, handlePaymentResult]
  );

  const initSubscriptionPayment = useCallback(
    async (input: SubscriptionPaymentInput) => {
      try {
        dispatch({ type: PaymentStatus.INIT });
        const paymentResult = await getSubscriptionPaymentResult(input);
        handlePaymentResult(paymentResult);
      } catch (error) {
        handlePaymentError(error);
      }
    },
    [getSubscriptionPaymentResult, handlePaymentError, handlePaymentResult]
  );

  const resetPayment = useCallback(() => {
    resetAllParams();
    dispatch({ type: PaymentStatus.RESET });
  }, [resetAllParams]);

  useEffect(() => {
    // TODO: Improve efficiency of this check when we have more data
    const resultCode = searchParams.get(SEARCH_PARAMS_KEYS.RESULT_CODE);

    if (resultCode) {
      handlePaymentResult({ resultCode });
    }
  }, [handlePaymentResult, searchParams]);

  const contextValue = useMemo(
    () => ({
      initBoostPayment,
      initSubscriptionPayment,
      resetPayment,
      state,
    }),
    [initBoostPayment, initSubscriptionPayment, resetPayment, state]
  );

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};

export const usePaymentContext = () => {
  const context = useContext(PaymentContext);
  if (!context) {
    throw new Error('usePaymentContext must be used within a PaymentProvider');
  }
  return context;
};
