import { FC } from 'react';

import { useMutation } from '@apollo/client';
import { PAGE_COUNT } from '@hooks/useJobToEvaluateListQuery';
import {
  askToBeEvaluatedOptimisticResponse,
  askToBeEvaluatedUpdate,
} from '@internals/business-shared/src/cache/updates/askToBeEvaluated';
import {
  getEvaluationRequestStatus,
  EvaluationRequestStatus,
} from '@internals/business-shared/src/utils/evaluationRequestStatus';
import {
  ASK_TO_BE_EVALUATED,
  AskToBeEvaluatedPayload,
  AskToBeEvaluatedVariables,
} from '@internals/business-shared/src/utils/mutation/AskToBeEvaluated/AskToBeEvaluated';
import {
  JobToEvaluate,
  JobToEvaluateListConnection,
} from '@internals/business-shared/src/utils/query/JobToEvaluateList/JobToEvaluateListQuery';
import { Div, Pagination } from '@schibsted-smb/fireball';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';

import RequestItem from './EvaluationRequestJobListItem';
import RequestStatusLabel from './EvaluationRequestStatusLabel';

export interface RequestEvaluationJobListProps {
  jobs: JobToEvaluateListConnection;
  page: number;
  onPageChange: (newPage: number) => void;
}

const EvaluationRequestJobList: FC<
  React.PropsWithChildren<RequestEvaluationJobListProps>
> = ({ jobs, page, onPageChange }) => {
  const { t } = useTranslation();
  const [requestEvaluation] = useMutation<
    AskToBeEvaluatedPayload,
    AskToBeEvaluatedVariables
  >(ASK_TO_BE_EVALUATED);
  const onRequestClick = (jobId: string) => {
    requestEvaluation({
      variables: { jobId },
      optimisticResponse: askToBeEvaluatedOptimisticResponse,
      update: askToBeEvaluatedUpdate(jobId),
    });
  };
  const renderJob = (edge: { node: JobToEvaluate }) => {
    const { node: job } = edge;
    const status = getEvaluationRequestStatus(job);
    return (
      <RequestItem
        key={job.id}
        jobOwnerName={getUserName(job.owner)}
        label={<RequestStatusLabel status={status} />}
        jobTitle={job.title}
        onClick={() => onRequestClick(job.id)}
        requestText={t('general.action.requestEvaluation')}
        requestDisabled={status !== EvaluationRequestStatus.None}
      />
    );
  };
  const { edges: jobsToEvaluate, pageInfo } = jobs;

  return (
    <Div
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
      minHeight="inherit"
    >
      <div>{jobsToEvaluate.map(renderJob)}</div>
      {!!pageInfo?.totalCount && (
        <Div
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={5}
          mb={6}
        >
          <Pagination
            itemsPerPage={PAGE_COUNT}
            count={pageInfo.totalCount}
            currentPage={page}
            getCurrentPage={onPageChange}
          />
        </Div>
      )}
    </Div>
  );
};

export default EvaluationRequestJobList;
