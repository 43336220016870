import gql from 'graphql-tag';

import {
  DELETE_BUSINESS_EMPLOYEE_IMAGEVariables,
  DELETE_BUSINESS_EMPLOYEE_IMAGE as DELETE_BUSINESS_EMPLOYEE_IMAGE_Result,
} from '../../generated/generated';

export type DeleteBusinessEmployeeImageMutationPayload =
  DELETE_BUSINESS_EMPLOYEE_IMAGE_Result;
export type DeleteBusinessEmployeeImageMutationVariables =
  DELETE_BUSINESS_EMPLOYEE_IMAGEVariables;

export const DELETE_BUSINESS_EMPLOYEE_IMAGE = gql`
  mutation DELETE_BUSINESS_EMPLOYEE_IMAGE($employeeId: ID!) {
    deleteBusinessEmployeeImage(employeeId: $employeeId)
  }
`;
