import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ChatMessageListContainer: any = styled(Div)`
  padding: ${({ theme }) => theme.space[4]};
  margin: 0 auto;
  width: 100%;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[6]}) {
    position: relative;
    max-width: 638px;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 800px;
  }
`;

export default ChatMessageListContainer;
