import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledAlertLink = styled(Link)<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
  pointer-events: none;

  &:hover {
    text-decoration: none;
  }`}
`;

export default StyledAlertLink;
