export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export const Omit = <OT extends object, K extends keyof OT>(
  propertyToOmit: K,
  objectToReduce: OT
): Omit<OT, K> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.fromEntries(
    Object.entries(objectToReduce).filter(([key]) => key !== propertyToOmit)
  );

export const omitRecursively = <OT extends object, K extends keyof OT>(
  propertyToOmit: K,
  objectToReduce: OT
): Omit<OT, K> =>
  Object.entries(objectToReduce).reduce<Omit<OT, K>>((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      acc[key] = omitRecursively(propertyToOmit, value);
    } else if (key !== propertyToOmit) {
      acc[key] = value;
    }
    return acc;
  }, {} as Omit<OT, K>);

export const OmitMany = <OT extends object, K extends keyof OT>(
  propertiesToOmit: K[],
  objectToReduce: OT
): Omit<OT, K> =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.fromEntries(
    Object.entries(objectToReduce).filter(
      ([key]) => !propertiesToOmit.includes(key as K)
    )
  );
