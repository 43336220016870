import { LinkProps } from '@components/base/NavLink';
import { NavLink as NL } from 'react-router-dom';
import styled from 'styled-components';
import { space } from 'styled-system';

const Link = styled(NL)<LinkProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${(props): string => props.theme.fontSizes[0]};
  color: ${(props): string => props.theme.colors.black.black9};
  padding: ${(props): number => {
      return props.py ?? props.theme.space[3];
    }}px
    ${(props): number => {
      return props.px ?? props.theme.space[5];
    }}px;

  &:hover {
    color: ${(props): string => props.theme.colors.black.black9};
    text-decoration: none;
  }

  &.active {
    color: ${(props): string => props.theme.colors.black.black9};
    font-weight: ${(props): number => props.theme.fontWeights.bold};
    text-decoration: none;
  }

  svg {
    width: 26px;
    height: 26px;
  }

  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    flex-direction: column;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    flex-direction: row;
    font-size: ${(props): string => props.theme.fontSizes[2]};

    &.active {
      position: relative;
    }

    svg {
      margin-right: 10px;
    }
  }
  // Question for anyone who sees this: Can we do this with breakpoints? Didn't work for me.
  @media only screen and (max-width: 1100px) and (min-width: 991px) {
    font-size: 14px !important;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    height: calc(100% - 10px);
    margin: ${(props): number => props.theme.space[2]}px 0;
    border-radius: ${(props): string => props.theme.radii[3]};
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    position: relative;
    flex: 1;
    margin: 0 ${(props): number => props.theme.space[5]}px;
    vertical-align: middle;
    color: white;
    font-size: ${(props): string => props.theme.fontSizes[2]};

    &:hover {
      color: white;
      text-decoration: none;
    }

    &.active {
      color: ${(props): string => props.theme.colors.beige.base};
      ${(props): string => {
        return props.$hideLinkBackground
          ? ''
          : `background-color: ${props.theme.colors.blue.lightest}20`;
      }};
      border-radius: ${(): string => '500px'};
    }
  }
  ${space}
`;

export default Link;
