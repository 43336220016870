import { ButtonLink } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const DownloadButton = styled(ButtonLink)`
  display: flex;
  flex-basis: 100%;
  padding: 10px;
  height: 45px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;
