import { InMemoryCache, makeReference } from '@apollo/client';

import { recalculatePageInfoAfterAddition, sortEdges } from '../../utils/cache';
import LATEST_MESSAGE_FRAGMENT from '../../utils/fragments/LatestMessageFragment';
import {
  ChatListType,
  MessageStatus,
  SET_CHAT_AS_UNREAD_MUTATION,
} from '../../utils/generated/generated';
import { SetChatAsUnreadMutationVariables } from '../../utils/mutation/SetChatAsUnreadMutation/SetChatAsUnreadMutation';

export const setChatAsUnreadUpdate =
  (chatId: SetChatAsUnreadMutationVariables['chatId']) =>
  (
    cache: InMemoryCache,
    { data }: { data?: SET_CHAT_AS_UNREAD_MUTATION | null }
  ) => {
    /*
    This is very similar to writeIsUnread in chatWriteQuery.ts, but the difference is that
     here we're operating on the property that already exists in cache, so we don't need to create it
     */

    if (data?.setChatAsUnread?.isUnread !== true) {
      return;
    }
    cache.modify({
      id: cache.identify({ id: chatId, __typename: 'Chat' }),
      fields: {
        isUnread() {
          return true;
        },
        latestMessage(latestMessageData, { readField }) {
          const latestMessageFragment = cache.readFragment({
            id: cache.identify({
              id: readField('id', latestMessageData),
              __typename: 'Message',
            }),
            fragment: LATEST_MESSAGE_FRAGMENT,
            fragmentName: 'LatestMessageFragment',
          });

          if (latestMessageFragment !== null) {
            return {
              // @ts-ignore
              ...latestMessageFragment,
              status: MessageStatus.SENT,
            };
          }
          return latestMessageData;
        },
      },
    });

    cache.modify({
      id: cache.identify({ id: 'ROOT_QUERY', __typename: 'Query' }),
      fields: {
        chatList(existingChatList, { readField }) {
          if (existingChatList.listType !== ChatListType.UNREAD) {
            return existingChatList;
          }
          // add chat to unread list
          return {
            ...existingChatList,
            chatConnection: {
              ...existingChatList.chatConnection,
              edges: sortEdges(
                [
                  ...existingChatList.chatConnection.edges,
                  { node: makeReference(`Chat:${chatId}`) },
                ],
                readField,
                'DESC',
                'latestUpdateTs'
              ),
              pageInfo: recalculatePageInfoAfterAddition(
                existingChatList.chatConnection.pageInfo
              ),
            },
          };
        },
      },
    });
  };

export const getChatAsUnreadOptimisticResponse: (
  chatId: SetChatAsUnreadMutationVariables['chatId']
) => SET_CHAT_AS_UNREAD_MUTATION = (chatId) => ({
  setChatAsUnread: {
    __typename: 'SetChatAsUnreadPayload',
    id: chatId,
    isUnread: true,
  },
});
