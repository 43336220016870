import gql from 'graphql-tag';

import {
  UNPAID_INVOICES_QUERY as UNPAID_INVOICES_QUERY_Result,
  UNPAID_INVOICES_QUERY_business_Business,
  UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid,
  UNPAID_INVOICES_QUERYVariables,
} from '../../generated/generated';

export type UnpaidInvoicesQueryPayload = UNPAID_INVOICES_QUERY_Result;
export type UnpaidInvoicesQueryVariables = UNPAID_INVOICES_QUERYVariables;
export type UnpaidInvoice =
  UNPAID_INVOICES_QUERY_business_Business_invoicesUnpaid;

export const isUnpaidInvoicesQuerySuccessResponse = (
  business?: UnpaidInvoicesQueryPayload['business']
): business is UNPAID_INVOICES_QUERY_business_Business =>
  !!business && business.__typename === 'Business';

export const UNPAID_INVOICES_QUERY = gql`
  query UNPAID_INVOICES_QUERY($id: ID!) {
    business(id: $id) {
      ... on Business {
        id
        invoicesUnpaid {
          id
          dueDate
          payType
          paymentLink
          latestAttempt
        }
      }
      ... on BusinessInputError {
        message
        idError: id {
          message
          code
        }
      }
      ... on BusinessNotFoundError {
        message
      }
    }
  }
`;
