import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const PrintPageHeaderContainer = styled(Div)`
  @media print {
    margin-top: 0;
  }
`;

export default PrintPageHeaderContainer;
