import gql from 'graphql-tag';

import {
  UPDATE_BUSINESS_MUNICIPALITIESVariables,
  UPDATE_BUSINESS_MUNICIPALITIES as UPDATE_BUSINESS_MUNICIPALITIES_Result,
} from '../../generated/generated';

export type UpdateBusinessMunicipalitiesMutationPayload =
  UPDATE_BUSINESS_MUNICIPALITIES_Result;
export type UpdateBusinessMunicipalitiesMutationVariables =
  UPDATE_BUSINESS_MUNICIPALITIESVariables;

export const UPDATE_BUSINESS_MUNICIPALITIES = gql`
  mutation UPDATE_BUSINESS_MUNICIPALITIES(
    $toggleMunicipalities: [ToggleMunicipalitiesInput!]!
  ) {
    updateBusinessMunicipalities(
      input: { toggleMunicipalities: $toggleMunicipalities }
    ) {
      municipalities {
        id
        name
      }
    }
  }
`;
