import { Column } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

const ProfileIframeColumn = styled(Column)<{ sidebarDesktopWidth: number }>`
  background: ${({ theme }) => theme.colors.black.black1};
  width: calc(100% - ${({ sidebarDesktopWidth }) => sidebarDesktopWidth}px);
  height: 100%;
  padding: 0;
  margin-left: ${({ sidebarDesktopWidth }) => sidebarDesktopWidth}px;
  z-index: ${Layer.base};
`;

export default ProfileIframeColumn;
