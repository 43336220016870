import { FC } from 'react';

import { Div, Image } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface SelectedImagePreviewProps {
  imageSrc?: string;
}

export const SelectedImagePreview: FC<SelectedImagePreviewProps> = ({
  imageSrc,
}) => {
  const themeContext = useTheme();

  return (
    <Div
      backgroundColor={themeContext.colors.black.black4}
      width="100%"
      height="200px"
      mb={6}
      borderRadius={themeContext.radii[2]}
    >
      {!!imageSrc && (
        <Image
          src={imageSrc}
          width="100%"
          height="100%"
          objectFit="cover"
          borderRadius={themeContext.radii[2]}
        />
      )}
    </Div>
  );
};
