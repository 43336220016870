import { useEffect } from 'react';

import { QueryHookOptions, QueryResult, useApolloClient } from '@apollo/client';
import { useChatListFilters } from '@hooks/useChatFilters';
import { useBadgeNumbersQuery as useSharedBadgeNumbersQuery } from '@internals/business-shared/src/hooks/query/useBadgeNumbersQuery';
import { useAllChatListsFetch } from '@internals/business-shared/src/hooks/useAllChatListFetch';
import { hasNewActivity } from '@internals/business-shared/src/utils/badgeNumbers';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '@internals/business-shared/src/utils/constants/apollo';
import { BadgeNumbersQueryPayload } from '@internals/business-shared/src/utils/query/BadgeNumbers/BadgeNumbersQuery';
import {
  CHAT_LIST_DEFAULT_COUNT,
  CHAT_LIST_DEFAULT_FROM,
} from '@root/src/apollo/cache';

interface BadgeNumbersQueryResult
  extends QueryResult<BadgeNumbersQueryPayload> {
  data: BadgeNumbersQueryPayload | null;
}

const POLL_INTERVAL = 10000; // 10s

export const useBadgeNumbersQuery = (
  options?: QueryHookOptions<BadgeNumbersQueryPayload>
): BadgeNumbersQueryResult => {
  const apolloClient = useApolloClient();
  const chatFilterList = useChatListFilters().map((filter) => filter.id);
  const { data, previousData, ...rest } = useSharedBadgeNumbersQuery({
    pollInterval: POLL_INTERVAL,
    skipPollAttempt: () => document.hidden,
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
    ...options,
  });
  const chatListsRefetch = useAllChatListsFetch(apolloClient, chatFilterList, {
    variables: {
      from: CHAT_LIST_DEFAULT_FROM,
      count: CHAT_LIST_DEFAULT_COUNT,
    },
  });

  // refetch chat lists to get update
  useEffect(() => {
    if (!data || !previousData) return;
    if (hasNewActivity(data, previousData)) {
      chatListsRefetch();
    }
  }, [data, previousData]);

  return {
    data: data || null,
    previousData,
    ...rest,
  };
};
