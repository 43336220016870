export const isEmptyString = (str: string | null | undefined): boolean =>
  !str || str.length === 0;
export const isEmptyTrimmedString = (str: string | null | undefined): boolean =>
  isEmptyString(str?.trim());
export const removeSpecialCharacters = (str: string): string =>
  str.replace(/[^a-zA-Z0-9\s]/g, '');
export const trimmedStingLength = (str: string | null | undefined): number =>
  str?.trim().length || 0;

/**
 * Returns a string with the prefix and suffix added if the string is not empty
 * @param str
 * @param prefix
 * @param suffix
 */
export const optionalString = (
  str: string | null | undefined,
  prefix = '',
  suffix = ''
): string => {
  if (isEmptyTrimmedString(str)) {
    return '';
  }
  return `${prefix}${str.trim()}${suffix}`;
};
