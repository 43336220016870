import * as React from 'react';
import { useEffect } from 'react';

import { IframeMessage } from '@components/base/IframeTalk/IframeMessage';
import InlineErrorMessage from '@components/base/InlineErrorMessage';
import { FileUploadInput } from '@components/elements/NewMessage/styled';
import ProfileEditListItem from '@components/elements/ProfileEditListItem';
import ProfilePicturesEditAlbumModal from '@components/layout/Profile/ProfilePictures/ProfilePicturesEditAlbumModal';
import ProfilePicturesNewPicturesModal from '@components/layout/Profile/ProfilePictures/ProfilePicturesNewPicturesModal';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import {
  AlbumContextType,
  ALL_IMAGES_ALBUM_ID,
  ProfilePicturesContext,
} from '@contexts/ProfilePicturesContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB } from '@internals/business-shared/src/utils/constants/fileUpload';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { PROFILE_PICTURES_QUERY_businessAlbums } from '@internals/business-shared/src/utils/generated/generated';
import { ProfileOutletContext } from '@pages/Profile/Profile';
import { Alert, Text } from '@schibsted-smb/fireball';
import isFilesTotalSizeAccepted from '@utils/fileSizeCheck';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ProfilePicturesAllPictures from './ProfilePicturesAllPictures';

const ProfilePictures: React.FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const themeContext = useTheme();
  const { setIframeMessage } = useOutletContext<ProfileOutletContext>();
  const { albums, countAllPhotos, getAlbumCover, isError } = React.useContext(
    ProfilePicturesContext
  );
  const [previewAlbumId, setPreviewAlbumId] = React.useState<
    AlbumContextType['id'] | null
  >(null);
  const [showAllImagesAlbum, setShowAllImagesAlbum] = React.useState(false);
  const [fileError, setFileError] = React.useState('');

  const fileInput: any = React.useRef<any>();
  const [uploadFiles, setUploadFiles] = React.useState<File[]>([]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!e.target.files) return;
    const newFiles = Array.from(e.target.files);
    if (!isFilesTotalSizeAccepted(newFiles)) {
      setFileError(
        t('general.form.validation.file.totalSize', {
          mbValue: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
        })
      );
      return;
    }
    setFileError('');
    setUploadFiles(newFiles);
    track(ANEventSpace(ANEvent.Clicked, ANObject.NewImage, ANPage.Profile));
  };

  const onAllImagesAlbumClick = () => {
    setFileError('');
    setShowAllImagesAlbum(true);
  };

  const onAlbumClick = (id: PROFILE_PICTURES_QUERY_businessAlbums['id']) => {
    setFileError('');
    setPreviewAlbumId(id);
  };

  const closeNewImagesModal = () => {
    setUploadFiles([]);
  };

  usePageAnalytics(ANSpace(ANObject.Images, ANPage.Profile));

  useEffect(() => {
    setIframeMessage(IframeMessage('albums', albums));
  }, [albums]);

  if (isError) {
    return (
      <>
        <SectionHeader title={t('profile.pictures.title')} px={6} />
        <Alert variant="danger" isFullWidth isCentered my={4} mx={2}>
          {t('general.error.generic')}
        </Alert>
      </>
    );
  }

  return (
    <>
      {uploadFiles.length > 0 && (
        <ProfilePicturesNewPicturesModal
          files={uploadFiles}
          onClose={closeNewImagesModal}
        />
      )}
      {!!previewAlbumId && (
        <ProfilePicturesEditAlbumModal
          albumId={previewAlbumId}
          onClose={() => setPreviewAlbumId(null)}
        />
      )}
      {showAllImagesAlbum && (
        <ProfilePicturesAllPictures
          albumId={ALL_IMAGES_ALBUM_ID}
          onClose={() => setShowAllImagesAlbum(false)}
        />
      )}

      <FileUploadInput
        type="file"
        multiple
        accept="image/*"
        onChange={handleImageUpload}
        ref={fileInput}
        data-testid="profile-pictures-input"
      />
      <SectionHeader
        title={t('profile.pictures.title')}
        buttonText={t('general.label.new')}
        onButtonClick={(): void => fileInput.current.click()}
        px={6}
      />
      <InlineErrorMessage>{fileError}</InlineErrorMessage>
      <ProfileEditListItem
        key={ALL_IMAGES_ALBUM_ID}
        image={
          albums?.[0]?.images?.length > 0
            ? albums[0].images[0].image.smallPreviewUrl
            : null
        }
        text={{
          title: t('profile.pictures.album.allImages'),
          description: t('profile.pictures.image.item', {
            count: countAllPhotos,
          }),
        }}
        renderText={(text) => (
          <>
            <Text.p fontSize={3} mb={4} data-testid="album-title-all-pictures">
              {text.title}
            </Text.p>
            <Text.p
              fontSize={2}
              mb={0}
              data-testid="album-description-all-pictures"
            >
              {text.description}
            </Text.p>
          </>
        )}
        icon={IconPickerName.ChevronRight}
        onClick={onAllImagesAlbumClick}
        wideBottomBorder
        testId="profile-all-pictures"
      />
      <Text.p
        ml={6}
        mt={7}
        mb={4}
        fontSize={3}
        fontWeight={themeContext.fontWeights.medium}
      >
        {t('profile.pictures.album.title')}
      </Text.p>
      {albums.map((album) => (
        <ProfileEditListItem
          key={album.id}
          image={getAlbumCover(album)}
          text={{
            title: album.name,
            description: t('profile.pictures.image.item', {
              count: album.images.length,
            }),
          }}
          renderText={(text) => (
            <>
              <Text.p
                fontSize={3}
                mb={4}
                data-testid={`album-title-${album.name}-${album.id}`}
                truncate
              >
                {text.title}
              </Text.p>
              <Text.p
                fontSize={2}
                mb={0}
                data-testid={`album-description-${album.name}-${album.id}`}
                truncate
              >
                {text.description}
              </Text.p>
            </>
          )}
          icon={IconPickerName.ChevronRight}
          onClick={() => onAlbumClick(album.id)}
          testId={`profile-pictures-${album.id}`}
        />
      ))}
    </>
  );
};

export default ProfilePictures;
