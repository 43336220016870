import { ApolloCache, makeReference, Reference } from '@apollo/client';

import { removeJobFromCachedJobList } from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { RemoveJobFromJobFolderMutationPayload } from '../../utils/mutation/RemoveJobFromJobFolder/RemoveJobFromJobFolderMutation';

const removeJobFromJobFolderUpdate =
  (job: JobListOrJobQueryItem, folderId: string) =>
  (
    cache: ApolloCache<RemoveJobFromJobFolderMutationPayload>,
    { data }: { data?: RemoveJobFromJobFolderMutationPayload | null }
  ) => {
    if (data?.removeJobFromJobFolder) {
      cache.modify({
        id: cache.identify({ id: job.id, __typename: 'Job' }),
        fields: {
          folders(existingFolders, { readField }) {
            return existingFolders
              ? [...existingFolders].filter(
                  (ref: Reference) => folderId !== readField('id', ref)
                )
              : [];
          },
        },
      });

      // remove job from folders job list
      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            if (existingJobList.listId === JobListId.Folder) {
              const jobToUpdate = existingJobList.jobConnection.edges.find(
                (edge: { node: Reference }) => {
                  return readField('id', edge.node) === job.id;
                }
              );
              if (jobToUpdate) {
                const jobFolders = readField('folders', jobToUpdate.node) || [];

                if (Array.isArray(jobFolders) && !jobFolders.length) {
                  return {
                    ...existingJobList,
                    jobConnection: removeJobFromCachedJobList(
                      existingJobList.jobConnection,
                      job.id,
                      readField
                    ),
                  };
                }
              }
            }
            return existingJobList;
          },
        },
      });

      // update folders job list counter in job list filter
      cache.modify({
        id: cache.identify({
          id: JobListId.Folder,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            if (!job.folders || !(job.folders.length - 1)) {
              return currentCount - 1 >= 0 ? currentCount - 1 : 0;
            }
            return currentCount;
          },
        },
      });
    }
  };

const removeJobFromJobFolderOptimisticResponse: RemoveJobFromJobFolderMutationPayload =
  {
    removeJobFromJobFolder: true,
  };

export {
  removeJobFromJobFolderUpdate,
  removeJobFromJobFolderOptimisticResponse,
};
