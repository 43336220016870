import { FC, useCallback } from 'react';

import NotFound from '@components/elements/NotFound';
import Paths from '@router/paths';
import { Card, Column, Container, Row } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PageNotFound: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToMainPage = useCallback(() => {
    navigate(Paths.Homepage, { replace: true });
  }, [navigate]);

  return (
    <Container>
      <Row mt={[4, 4, 6]}>
        <Column width={1}>
          <Card boxShadow="xl" p="45px">
            <NotFound
              title={t('general.error.pageNotFound.title')}
              text={t('general.error.pageNotFound.text')}
              link={{
                url: Paths.SettingsCustomerService,
                text: t('general.label.customerService'),
              }}
              button={{
                text: t('general.error.pageNotFound.textButton'),
                onClick: goToMainPage,
              }}
            />
          </Card>
        </Column>
      </Row>
    </Container>
  );
};

export default PageNotFound;
