import {
  Variant,
  VariantType,
} from '@internals/business-shared/src/utils/errors';
import { toast, ToastOptions } from 'react-toastify';

const ToastMessage = (
  message: string,
  variant: VariantType = 'warning',
  options?: ToastOptions
): void => {
  const toastArgs: Parameters<typeof toast> = [message, options];
  switch (variant) {
    case Variant.info:
      toast.info(...toastArgs);
      break;
    case Variant.success:
      toast.success(...toastArgs);
      break;
    case Variant.warning:
      toast.warning(...toastArgs);
      break;
    case Variant.danger:
      toast.error(...toastArgs);
      break;
    case Variant.muted:
    case Variant.action:
    default:
      toast(...toastArgs);
      break;
  }
};
export default ToastMessage;
