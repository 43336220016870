import { FC } from 'react';

import { useTotalRemainingCredits } from '@contexts/UserContext';
import { Text } from '@schibsted-smb/fireball';
import { getCurrentMonthName } from '@utils/date';
import { Trans, useTranslation } from 'react-i18next';

import { StyledAlertNumber } from './styled';

interface JobCostInfoProps {
  cost: number;
  centeredText?: boolean;
  textMaxWidth?: string;
  hasAccess?: boolean;
}

export const JobCostInfo: FC<JobCostInfoProps> = ({
  cost,
  centeredText,
  textMaxWidth,
  hasAccess = true,
}) => {
  const { t } = useTranslation();
  const remainingCredits = useTotalRemainingCredits();

  if (remainingCredits === null) {
    return null;
  }

  return (
    <Text.p
      mb={0}
      textAlign={centeredText ? 'center' : 'left'}
      maxWidth={textMaxWidth}
    >
      {hasAccess ? (
        <Trans
          i18nKey="job.item.creditInfo"
          values={{
            month: getCurrentMonthName(),
            remaining: t('general.label.credit', { count: remainingCredits }),
            cost: t('general.label.credit', { count: cost }),
          }}
          components={{
            bold: <StyledAlertNumber />,
          }}
        />
      ) : (
        <Trans
          i18nKey="job.item.creditInfoNoAccess"
          values={{
            cost: t('general.label.credit', { count: cost }),
          }}
          components={{
            bold: <StyledAlertNumber />,
          }}
        />
      )}
    </Text.p>
  );
};
