import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { EvaluationRequestStatus } from '@internals/business-shared/src/utils/evaluationRequestStatus';
import { Div, Text } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';
import { useTheme } from 'styled-components';

type LabelVariant = 'danger' | 'warning' | 'success';

const getEvaluationRequestLabelConfig = (
  status: EvaluationRequestStatus
): { variant: string; icon: IconPickerName; text: string } =>
  ({
    [EvaluationRequestStatus.None]: {
      variant: 'danger',
      icon: IconPickerName.Attention,
      text: t('evaluation.item.request.missing'),
    },
    [EvaluationRequestStatus.Sent]: {
      variant: 'warning',
      icon: IconPickerName.Time,
      text: t('general.label.requestSent'),
    },
    [EvaluationRequestStatus.Completed]: {
      variant: 'success',
      icon: IconPickerName.CheckmarkCircled,
      text: t('evaluation.item.request.completed'),
    },
  }[status]);

interface RequestEvaluationStatusProps {
  status: EvaluationRequestStatus;
}

const EvaluationRequestStatusLabel: FC<
  React.PropsWithChildren<RequestEvaluationStatusProps>
> = ({ status }) => {
  const config = getEvaluationRequestLabelConfig(status);
  const themeContext = useTheme();
  const { variant, icon, text } = config;
  const colors: Record<LabelVariant, string> = {
    danger: themeContext.colors.red.base,
    warning: themeContext.colors.orange.base,
    success: themeContext.colors.green.base,
  };
  return (
    <Div display="flex" minHeight="18px" alignItems="center">
      <FireIcon
        iconName={icon}
        variant="flat"
        width="17px"
        fill={colors[variant]}
      />
      <Text.span
        color={colors[variant]}
        fontWeight={themeContext.fontWeights.medium}
        m={0}
        ml={2}
        fontSize={1}
      >
        {text}
      </Text.span>
    </Div>
  );
};

export default EvaluationRequestStatusLabel;
