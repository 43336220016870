import { FC } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterType } from '@components/elements/JobsFilters/SelectedFilters';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { useFoldersContext } from '@contexts/JobFoldersContext';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { useTranslation } from 'react-i18next';

interface FolderFilterButtonProps {
  onClick: VoidFunction;
}

interface FolderFilterProps {
  onBackClick: VoidFunction;
}

export const FolderFilter: FC<React.PropsWithChildren<FolderFilterProps>> = ({
  onBackClick,
}) => {
  const { t } = useTranslation();
  const folders = useFoldersContext();
  const folderList = folders?.folderList || [];
  const { activeFolderFilters, setActiveFolders, clearActiveFolders } =
    useJobFiltersContext();
  const numberOfSelected = activeFolderFilters.length;
  const availableToSelect = folderList.length;
  const allSelected = numberOfSelected === availableToSelect;

  const selectAll = () => {
    setActiveFolders(folderList.map((f) => f.id));
  };

  const toggleAll = () => {
    if (allSelected) {
      clearActiveFolders();
      return;
    }
    selectAll();
  };

  const toggleFilter = (folderId: string) => {
    const isActive = activeFolderFilters.includes(folderId);
    setActiveFolders(
      toggleItemInArray(activeFolderFilters, folderId, !isActive)
    );
  };

  const toggleAllAction = {
    action: toggleAll,
    selectedFilterCount: numberOfSelected,
    totalFilterCount: availableToSelect,
  };

  return (
    <FilterView
      title={t('job.list.filters.folder.title')}
      onBackClick={onBackClick}
      toggleAllAction={toggleAllAction}
    >
      <FilterGrid>
        {folderList.map((folder) => (
          <FilterToggle
            key={folder.id}
            name={folder.title}
            isChecked={activeFolderFilters.includes(folder.id)}
            onChange={() => toggleFilter(folder.id)}
          />
        ))}
      </FilterGrid>
    </FilterView>
  );
};

export const FolderFilterButton: FC<
  React.PropsWithChildren<FolderFilterButtonProps>
> = ({ onClick }) => {
  const { t } = useTranslation();
  const folders = useFoldersContext();
  const folderList = folders?.folderList || [];
  const { activeFolderFilters, setActiveFolders, clearActiveFolders } =
    useJobFiltersContext();

  const activeFolders = activeFolderFilters
    .map((folderId) => {
      const activeFolder = folderList.find((f) => f.id === folderId);
      return activeFolder
        ? { id: activeFolder.id, name: activeFolder.title }
        : null;
    })
    .filter(Boolean) as FilterType[];

  const removeFilters = (folderId: string | string[]) => {
    if (typeof folderId === 'string') {
      setActiveFolders(toggleItemInArray(activeFolderFilters, folderId, false));
      return;
    }
    clearActiveFolders();
  };

  if (!folderList.length) return null;

  return (
    <FilterGroupButton
      filterName={t('job.list.filters.folder.title')}
      onClick={onClick}
      selectedFilters={activeFolders}
      onSelectedClick={removeFilters}
    />
  );
};
