import {
  getExpiredTokenPageURL,
  getLoginURL,
} from '@internals/business-shared/src/utils/publicUrls';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { getProductId } from '@utils/product';

const REDIRECT_PARAM = 'redir';

export const getLoginRedirectUrl = (): string => {
  const LOGIN_URL = getLoginURL(
    getEnvValue(ENV_KEY_NAME.ENVIRONMENT),
    getProductId()
  );
  const finalRedirectLocation = encodeURIComponent(window.location.toString());

  return `${LOGIN_URL}?${REDIRECT_PARAM}=${finalRedirectLocation}`;
};

export const redirectToLoginPage = () => {
  const redirectUrl = getLoginRedirectUrl();
  window.location.href = redirectUrl;
};

export const redirectToExpiredTokenPage = () => {
  const redirectUrl = getExpiredTokenPageURL(
    getEnvValue(ENV_KEY_NAME.ENVIRONMENT),
    getProductId()
  );
  window.location.href = redirectUrl;
};
