import { UpdateBusinessProfileInfoInput } from '../generated/generated';
import { CompanyInfoQueryData } from '../query/CompanyInfo/CompanyInfoQuery';
import { isEmptyTrimmedString } from '../types/StringUtils';
import Address2GQLAddressInput from './Address2GQLAddressInput';
import { OpeningHours } from './OpeningHours.interface';
import OpeningHours2GQLOpeningHours from './OpeningHours2GQLOpeningHours';

type Input = Pick<
  CompanyInfoQueryData,
  'id' | 'phone' | 'addresses' | 'profileDescription' | 'businessSocialMedia'
>;

const GQLBusiness2GQLUpdateBusinessProfileInput = (
  { id, phone, addresses, businessSocialMedia, profileDescription }: Input,
  openingHours: OpeningHours[]
): UpdateBusinessProfileInfoInput => {
  const updateAddress =
    addresses?.visit &&
    addresses.visit[0] &&
    !isEmptyTrimmedString(addresses.visit[0].street) &&
    !isEmptyTrimmedString(addresses.visit[0].zip);
  const { website, instagram, facebook, youtube, tiktok } =
    businessSocialMedia || {};

  return {
    businessId: id,
    phone,
    address: updateAddress
      ? Address2GQLAddressInput(
          addresses.visit[0].id,
          addresses.visit[0].street,
          addresses.visit[0].zip
        )
      : null,
    website,
    instagram,
    facebook,
    youtube,
    tiktok,
    description: profileDescription,
    openingHours: OpeningHours2GQLOpeningHours(openingHours),
  };
};

export default GQLBusiness2GQLUpdateBusinessProfileInput;
