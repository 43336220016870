import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface DescriptionContainerProps {
  isSelected: boolean;
}

export const DescriptionContainer = styled(Div)<DescriptionContainerProps>`
  margin-bottom: ${({ isSelected }) => (isSelected ? '20px' : '5px')};
  transition: all 0.3s ease-in-out;
`;
