import { useMutation } from '@apollo/client';
import useTokenCookies from '@hooks/useTokenCookies';
import {
  LOGOUT_MUTATION,
  LogoutMutationPayload,
} from '@internals/business-shared/src/utils/mutation/Logout/LogoutMutation';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';
import { bugsnagClient } from '@utils/initBugsnag';

const redirectOnLogout = () => {
  window.location.href = getEnvValue(ENV_KEY_NAME.LOGOUT_URL);
};

export const useLogout = (): (() => void) => {
  const { removeTokenCookie } = useTokenCookies();

  const onMutationCompleted = () => {
    removeTokenCookie();
    redirectOnLogout();
  };

  const [logout] = useMutation<LogoutMutationPayload>(LOGOUT_MUTATION, {
    onCompleted: onMutationCompleted,
    onError: (er) => {
      bugsnagClient.notify(er);
      onMutationCompleted();
    },
  });

  return logout;
};
