import { FC } from 'react';

import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { Alert } from '@schibsted-smb/fireball';

interface ErrorAlertProps {
  errors: GQLErrorState;
  testId?: string;
}

const ErrorAlert: FC<ErrorAlertProps> = ({
  errors,
  testId = 'error-alert',
}) => {
  if (!errors?.general) {
    return null;
  }

  return (
    <Alert
      variant={errors.general.variant}
      isFullWidth
      isCentered
      my={4}
      mx={2}
      data-testid={testId}
    >
      {errors.general.msg}
    </Alert>
  );
};

ErrorAlert.displayName = 'ErrorAlert';

export default ErrorAlert;
