import * as React from 'react';

import NavItem from '@components/elements/NavItem/NavItem';
import { ANObject } from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';

import NavigationContainer from './styled/NavigationContainer';

export interface NavigationModel {
  link: string;
  icon?: IconPickerName;
  label: string;
  tag?: string;
  count?: number;
  analyticsObject?: ANObject;
  testId?: string;
}

interface NavigationProps {
  navigationItems: NavigationModel[];
  isFirstDefaultActive?: boolean;
  isMobileGray?: boolean;
  countAsBadge?: boolean;
  countOnActive?: boolean;
  isSmaller?: boolean;
}

const Navigation: React.FC<React.PropsWithChildren<NavigationProps>> = ({
  navigationItems,
  isFirstDefaultActive = false,
  isMobileGray,
  countAsBadge,
  countOnActive,
  isSmaller,
}) => {
  return (
    <Div px={isSmaller ? 0 : [0, null, null, 2]}>
      <NavigationContainer>
        {navigationItems.map((item: NavigationModel, index) => {
          return (
            <NavItem
              item={item}
              key={item.label}
              isLast={navigationItems.length - 1 === index}
              isDefaultActive={isFirstDefaultActive && index === 0}
              isMobileGray={isMobileGray}
              countAsBadge={countAsBadge}
              countOnActive={countOnActive}
              isSmaller={isSmaller}
            />
          );
        })}
      </NavigationContainer>
    </Div>
  );
};

export default Navigation;
