import {
  FileWithPreview,
  getFileExtension,
  isImage,
  WebFileWithPreview,
} from '@internals/business-shared/src/utils/FileUtils';
import uniqid from 'uniqid';

const uploadFiles = (
  file: WebFileWithPreview['file'],
  setFiles: (files: FileWithPreview[]) => void,
  files: FileWithPreview[],
  filesWithPreview: FileWithPreview[]
): void => {
  const isImageType = isImage(file.name);
  const reader = new FileReader();
  const fileToUpload: FileWithPreview = {
    id: uniqid(),
    type: getFileExtension(file.name),
    file,
  };
  reader.onload = () => {
    if (isImageType) {
      const binaryStr = reader.result;
      filesWithPreview.push({ ...fileToUpload, urlPreview: binaryStr });
    } else {
      filesWithPreview.push(fileToUpload);
    }
    setFiles([...files, ...filesWithPreview]);
  };
  reader.readAsDataURL(file);
};

interface AssetType {
  url: string;
  filename: string;
  type: string;
}

export const convertAssetUrlToFile = async (
  asset: AssetType
): Promise<File> => {
  const response = await fetch(asset.url);
  const data = await response.blob();
  return new File([data], asset.filename, { type: asset.type });
};

export default uploadFiles;
