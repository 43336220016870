import {
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { PILL_NAVIGATION_BUTTON_WIDTH } from '@components/elements/PillNavigation/styled';

const SCROLL_STEP = 50;
const SCROLL_INTERVAL = 16; // approximately 60fps

interface UsePillNavigationScroll {
  (items: { isActive: boolean }[]): {
    itemsContainerRef: RefObject<HTMLDivElement>;
    itemRefs: RefObject<HTMLDivElement[]>;
    showLeftButton: boolean;
    showRightButton: boolean;
    handleScroll: (direction: 'right' | 'left') => void;
    stopScroll: () => void;
  };
}

interface ScrollToItemParams {
  itemsContainerRef: RefObject<HTMLDivElement>;
  itemRefs: RefObject<HTMLDivElement[]>;
  onScroll?: (newScrollPosition: number) => void;
  isMobile?: boolean;
}

export const scrollToItem = ({
  itemRefs,
  itemsContainerRef,
  onScroll,
  isMobile,
}: ScrollToItemParams) => {
  return (activeItemIndex: number) => {
    if (!itemsContainerRef.current || !itemRefs.current) return;

    const container = itemsContainerRef.current;
    const activeElement = itemRefs.current[activeItemIndex];

    if (activeElement) {
      const containerViewLeftEdge = container.scrollLeft;
      const containerOffsetLeft = isMobile
        ? container.getBoundingClientRect().left
        : 0;
      const containerViewRightEdge =
        containerViewLeftEdge + container.clientWidth;
      const elementLeftEdge = activeElement.offsetLeft;
      const elementHalfWidth = activeElement.scrollWidth / 2;
      const isElementOutsideView =
        elementLeftEdge + elementHalfWidth <
          containerViewLeftEdge + containerOffsetLeft ||
        elementLeftEdge + elementHalfWidth - containerOffsetLeft >
          containerViewRightEdge;

      if (isElementOutsideView) {
        const offsetLeft = isMobile
          ? containerOffsetLeft + 10
          : PILL_NAVIGATION_BUTTON_WIDTH;
        const newScrollPosition = Math.min(
          Math.max(activeElement.offsetLeft - offsetLeft, 0),
          container.scrollWidth - container.clientWidth
        );

        container.scrollLeft = newScrollPosition;
        onScroll?.(newScrollPosition);
      }
    }
  };
};

export const usePillNavigationScroll: UsePillNavigationScroll = (items) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);
  const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const stopScroll = useCallback(() => {
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
      scrollIntervalRef.current = null;
    }
  }, [scrollIntervalRef.current]);

  const handleScroll = useCallback(
    (direction: 'right' | 'left') => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }

      scrollIntervalRef.current = setInterval(() => {
        if (!itemsContainerRef.current) return;

        const container = itemsContainerRef.current;
        const maxScrollPosition = container.scrollWidth - container.clientWidth;
        let newScrollPosition = container.scrollLeft;

        if (direction === 'left') {
          newScrollPosition = Math.max(newScrollPosition - SCROLL_STEP, 0);
        } else {
          newScrollPosition = Math.min(
            newScrollPosition + SCROLL_STEP,
            maxScrollPosition
          );
        }

        container.scrollLeft = newScrollPosition;
        setScrollPosition(newScrollPosition);

        if (newScrollPosition <= 0 || newScrollPosition >= maxScrollPosition) {
          stopScroll();
        }
      }, SCROLL_INTERVAL);
    },
    [itemsContainerRef.current, scrollIntervalRef.current, stopScroll]
  );

  const updateButtonVisibility = useCallback(() => {
    if (!itemsContainerRef.current) return;

    const container = itemsContainerRef.current;
    setShowLeftButton(container.scrollLeft > 0);
    setShowRightButton(
      container.scrollLeft < container.scrollWidth - container.clientWidth
    );
  }, [itemsContainerRef.current]);

  useEffect(() => {
    updateButtonVisibility();
  }, [scrollPosition, items]);

  useLayoutEffect(() => {
    const activeItemIndex = items.findIndex((item) => item.isActive);
    if (activeItemIndex !== -1) {
      scrollToItem({
        itemsContainerRef,
        itemRefs,
        onScroll: setScrollPosition,
      })(activeItemIndex);
    }
  }, [items]);

  return {
    itemsContainerRef,
    itemRefs,
    showLeftButton,
    showRightButton,
    handleScroll,
    stopScroll,
  };
};
