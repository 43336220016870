import { useCallback } from 'react';

import {
  LazyQueryHookOptions,
  LazyQueryResult,
  useLazyQuery,
} from '@apollo/client';

import {
  BOLIGMAPPA_LOGIN_URL,
  BoligmappaLoginUrlQueryPayload,
  BoligmappaLoginUrlQueryVariables,
} from '../../utils/query/BoligmappaLoginUrl/BoligmappaLoginUrlQuery';

export type BoligmappaLoginUrlQueryResult = (
  redirectUrl: string
) => Promise<
  LazyQueryResult<
    BoligmappaLoginUrlQueryPayload,
    BoligmappaLoginUrlQueryVariables
  >
>;

export type UseBoligmappaLoginUrlQueryResult = [
  BoligmappaLoginUrlQueryResult,
  LazyQueryResult<
    BoligmappaLoginUrlQueryPayload,
    BoligmappaLoginUrlQueryVariables
  >
];

export const UseBoligmappaLazyLoginUrlQuery = (
  options?: LazyQueryHookOptions<
    BoligmappaLoginUrlQueryPayload,
    BoligmappaLoginUrlQueryVariables
  >
): UseBoligmappaLoginUrlQueryResult => {
  const [boligMappaLoginUrl, ...rest] = useLazyQuery<
    BoligmappaLoginUrlQueryPayload,
    BoligmappaLoginUrlQueryVariables
  >(BOLIGMAPPA_LOGIN_URL, {
    fetchPolicy: 'network-only',
    ...options,
  });

  const boligMappaLoginUrlQuery = useCallback(
    (redirectUrl: string) =>
      boligMappaLoginUrl({
        variables: {
          input: {
            redirectUrl,
          },
        },
      }),
    [boligMappaLoginUrl]
  );

  return [boligMappaLoginUrlQuery, ...rest];
};
