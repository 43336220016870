import { FC, Fragment } from 'react';

import {
  StyledNotificationGrid,
  StyledNotificationToggleContainer,
  StyledNotificationToggleText,
} from '@components/layout/Settings/NotificationsV2/styled';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import useAnalytics from '@hooks/useAnalytics';
import { useSavedFilterNotificationSettings } from '@internals/business-shared/src/components/NotificationSettings/useSavedFilterNotificationSettings';
import { useStandardNotificationSettings } from '@internals/business-shared/src/components/NotificationSettings/useStandardNotificationSettings';
import { Heading, Text, Toggle } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const NotificationSettings: FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { data: standardNotifications, loading: standardNotificationsLoading } =
    useStandardNotificationSettings({ track });
  const {
    data: savedFilterNotifications,
    loading: savedFilterNotificationsLoading,
  } = useSavedFilterNotificationSettings();
  const showLoading =
    (standardNotificationsLoading && !standardNotificationsLoading) ||
    (savedFilterNotificationsLoading && !savedFilterNotifications);
  const notifications = [
    ...(standardNotifications || []),
    ...(savedFilterNotifications || []),
  ];

  if (showLoading) {
    return <SettingsLoading height="220px" />;
  }

  return (
    <div>
      <Heading.h3 mb={4}>{t('settings.notifications.title')}</Heading.h3>
      <Text.p mb={[7, null, null, null, 4]}>
        {t('settings.notifications.description')}
      </Text.p>
      <StyledNotificationGrid>
        <span />
        <StyledNotificationToggleText>
          {t('general.label.email')}
        </StyledNotificationToggleText>
        <StyledNotificationToggleText>
          {t('general.label.mobile')}
        </StyledNotificationToggleText>
        {notifications.map((notification) => (
          <Fragment key={`${notification.name}-${notification.id}`}>
            <StyledNotificationToggleText>
              {notification.name}
            </StyledNotificationToggleText>
            {notification.toggles.map(({ type, variant, ...toggle }) => (
              <StyledNotificationToggleContainer
                key={toggle.id}
                data-testid={
                  variant === 'standard'
                    ? `section-container-${type}`
                    : `section-container-saved-${type}`
                }
              >
                <Toggle
                  testId={
                    variant === 'standard'
                      ? `notification-${type}`
                      : `notification-saved-${type}`
                  }
                  {...toggle}
                />
              </StyledNotificationToggleContainer>
            ))}
          </Fragment>
        ))}
      </StyledNotificationGrid>
    </div>
  );
};
