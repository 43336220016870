import styled from 'styled-components';

export const IconRing = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px ${({ theme }) => theme.colors.black.black3};
  text-decoration: none;
  transition: all 350ms;
`;
