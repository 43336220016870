import { useFlagEnabled } from '@components/base/CheckFlag';
import { TriggerSource } from '@components/elements/BoostCreditsModal/constants';
import { useBoostCreditsModalContext } from '@components/elements/BoostCreditsModal/ModalContext';
import { useTotalRemainingCredits } from '@contexts/UserContext';
import { useIsCreditsAvailable } from '@hooks/useIsCreditsAvailable';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { AlertType } from '@internals/business-shared/src/utils/AnswerAccessUtils';
import { getJoinedCertificateNames } from '@internals/business-shared/src/utils/certificates';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { JobAccess } from '@internals/business-shared/src/utils/query/JobAccess/JobAccessQuery';
import { JobAlertProps } from '@root/src/components/elements/JobAlertCard';
import { JobCostInfo } from '@root/src/components/layout/Job/JobCostInfo';
import useAnalytics from '@root/src/hooks/useAnalytics';
import { TestBuyMoreClipsNames } from '@root/src/utils/constants/TestBuyMoreClipsNames';
import Paths from '@router/paths';
import { useRequestJobPackageAction } from '@utils/companyState/useCompanyStateAction';
import { t } from '@utils/i18n';
import { getProduct } from '@utils/product';

export const useAnswerAccessAlertData = (
  alertType: AlertType,
  accessData: JobAccess,
  jobId: string
): JobAlertProps | null => {
  const { track } = useAnalytics();
  const { name: productName } = getProduct();
  const { loading, handleModalOpen } = useBoostCreditsModalContext();
  const remainingCredits = useTotalRemainingCredits();
  const isCreditsAvailable = useIsCreditsAvailable();
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );

  const trackProperties =
    alertType === AlertType.InsufficientFunds
      ? {
          abTest:
            isTestBuyMoreClipsFlagEnabled && isCreditsAvailable
              ? TestBuyMoreClipsNames.BuyCTA
              : TestBuyMoreClipsNames.ContactCTA,
        }
      : undefined;

  const onRequestContactSuccess = () => {
    track(
      ANEventSpace(
        ANEvent.Clicked,
        ANObject.AlertBannerContactRequest,
        ANPage.JobCard
      ),
      trackProperties
    );
  };

  const requestContactAction = useRequestJobPackageAction(
    jobId,
    onRequestContactSuccess
  );

  if (!alertType || !accessData) {
    return null;
  }

  switch (alertType) {
    case AlertType.NotAvailable: {
      return {
        variant: 'danger',
        size: 'small',
        title: t('job.item.alert.notAvailable.text'),
      };
    }
    case AlertType.NoAccessSmall: {
      return {
        variant: 'danger',
        size: 'small',
        title: t('job.item.alert.noAccess.title'),
      };
    }
    case AlertType.NoAccessLarge: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.noAccess.title'),
        message: t('job.item.alert.noAccessLarge.message'),
        messageShort: t('job.item.alert.noAccessLarge.message'),
        action: {
          type: 'link',
          href: Paths.SettingsProducts,
          external: false,
          text: t('job.item.alert.noAccessLarge.button'),
          testId: 'button-lacks-product',
        },
      };
    }
    case AlertType.WrongArea: {
      return {
        variant: 'danger',
        size: 'small',
        title: t('job.item.alert.noAccess.title'),
      };
    }
    case AlertType.NotVerifiedUser: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.notVerifiedUser.title'),
        message: t('job.item.alert.notVerifiedUser.message'),
        messageShort: t('job.item.alert.notVerifiedUser.message'),
        action: {
          testId: 'button-not-verified-user',
          text: t('job.item.alert.notVerifiedUser.button'),
          type: 'button',
          ...requestContactAction,
        },
      };
    }
    case AlertType.DoffinJob: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.doffinJob.title'),
        message: t('job.item.alert.doffinJob.message', { productName }),
        messageShort: t('job.item.alert.doffinJob.message', { productName }),
        action: {
          type: 'link',
          href: 'https://doffin.no',
          external: true,
          text: t('job.item.alert.doffinJob.button'),
          testId: 'button-doffin-job',
        },
      };
    }
    case AlertType.OwnJob: {
      return {
        variant: 'danger',
        size: 'small',
        title: t('job.item.alert.ownJob.title'),
      };
    }
    case AlertType.RemovedUser: {
      return {
        variant: 'danger',
        size: 'small',
        title: t('job.item.alert.removedUser.title', { productName }),
      };
    }
    case AlertType.InsufficientFunds:
      return {
        variant: 'danger',
        size: 'big',
        title:
          remainingCredits === 0
            ? t('job.item.alert.insufficientFunds.title.outOfCredits')
            : t('job.item.alert.insufficientFunds.title.notEnoughCredits'),
        message: <JobCostInfo cost={accessData.clip} />,
        messageShort: <JobCostInfo cost={accessData.clip} />,
        action:
          isTestBuyMoreClipsFlagEnabled && isCreditsAvailable
            ? {
                type: 'button',
                testId: 'button-insufficient-funds',
                onClick: () => handleModalOpen(TriggerSource.JOB_CARD, jobId),
                isLoading: loading,
                text: t('job.item.alert.insufficientFunds.action'),
              }
            : {
                type: 'button',
                testId: 'button-insufficient-funds',
                ...requestContactAction,
                text: requestContactAction.disabled
                  ? t('general.action.requestContactSent')
                  : t('general.action.requestContact'),
              },
      };
    case AlertType.LacksSubscription: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.companyLacksSubscription.title'),
        message: t('job.item.alert.companyLacksSubscription.message', {
          productName,
        }),
        messageShort: t(
          'job.item.alert.companyLacksSubscription.messageShort',
          { productName }
        ),
        action: {
          type: 'button',
          testId: 'button-lacks-subscription',
          ...requestContactAction,
        },
      };
    }
    case AlertType.LacksCertification: {
      return {
        variant: 'danger',
        size: 'big',
        title: t('job.item.alert.noAccess.title'),
        message: t('job.item.alert.companyLacksCertification.message', {
          certificates: getJoinedCertificateNames(
            accessData.missingCertificates
          ),
          count: accessData.missingCertificates.length || 1,
          interpolation: { escapeValue: false }, // needed to include quotation marks
        }),
        messageShort: t(
          'job.item.alert.companyLacksCertification.messageShort',
          {
            certificates: getJoinedCertificateNames(
              accessData.missingCertificates
            ),
            count: accessData.missingCertificates.length || 1,
            interpolation: { escapeValue: false }, // needed to include quotation marks
          }
        ),
        testId: 'job-alert-lacks-certification',
        action: {
          type: 'link',
          href: Paths.SettingsCustomerService,
          external: false,
          text: t('job.item.alert.companyLacksCertification.button'),
          testId: 'button-lacks-product',
        },
      };
    }
    case AlertType.LacksProduct: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.companyLacksProduct.title'),
        message: t('job.item.alert.companyLacksProduct.message'),
        messageShort: t('job.item.alert.companyLacksProduct.message'),
        action: {
          type: 'button',
          testId: 'button-lacks-product',
          ...requestContactAction,
        },
      };
    }
    case AlertType.LacksFreeClips: {
      return {
        variant: 'default',
        size: 'big',
        title: t('job.item.alert.companyLacksFreeClips.title'),
        message: t('job.item.alert.companyLacksFreeClips.message'),
        messageShort: t('job.item.alert.companyLacksFreeClips.message'),
        action: {
          type: 'button',
          testId: 'button-lacks-free-clips',
          ...requestContactAction,
        },
      };
    }
    default: {
      throw new Error(`Missing job alert implementation for ${alertType}`);
    }
  }
};
