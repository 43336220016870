import { FC } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';
import { CreditsTooltip } from './CreditsTooltip';

interface StyleProps {
  borderBottom?: string;
  mb?: number;
}

interface CreditsPerMonthProps {
  clips: number;
}

export const CreditsPerMonth: FC<CreditsPerMonthProps> = ({ clips }) => {
  const { colors } = useTheme();
  const { modalState } = useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      mb: isTabletOrMobile ? 3 : 5,
      borderBottom: !isTabletOrMobile && `1px solid ${colors.gray.light}`,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      mb: 3,
    },
    [ModalState.SUMMARY]: {
      mb: 8,
      borderBottom: `1px solid ${colors.gray.light}`,
    },
  };

  const { mb, borderBottom } = styleProps[modalState];

  return (
    <Div display="flex" justifyContent="end" mb={mb}>
      <Div display="flex" alignItems="center" borderBottom={borderBottom}>
        <Text.span mb={0} fontSize={8} fontWeight="bold">
          {clips}
        </Text.span>
        <Text.span mb={0} mx={4}>
          {t('job.modal.subscription.packageCard.creditsPerMonth')}
        </Text.span>
        <CreditsTooltip />
      </Div>
    </Div>
  );
};
