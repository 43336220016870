import styled from 'styled-components';

const StandardMessagesIconPickerWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
`;

export default StandardMessagesIconPickerWrapper;
