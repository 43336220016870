import { useCallback } from 'react';

import { SimpleAgreementPriceType } from '@internals/business-shared/src/utils/generated/generated';
import { RadioGroupProps } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const useContractTypeRadioOptions = () => {
  const { t } = useTranslation();
  const getRadioGroupOptions = useCallback(
    (
      priceType: SimpleAgreementPriceType,
      handleChange: RadioGroupProps['options'][number]['onChange']
    ): RadioGroupProps['options'] => {
      return [
        {
          id: SimpleAgreementPriceType.FIXED,
          label: t('contract.item.simpleContract.priceType.fixed'),
          value: SimpleAgreementPriceType.FIXED,
          onChange: handleChange,
          checked: priceType === SimpleAgreementPriceType.FIXED,
        },
        {
          id: SimpleAgreementPriceType.HOUR_COMPUTATION,
          label: t('contract.item.simpleContract.priceType.hourComputation'),
          value: SimpleAgreementPriceType.HOUR_COMPUTATION,
          onChange: handleChange,
          checked: priceType === SimpleAgreementPriceType.HOUR_COMPUTATION,
        },
        {
          id: SimpleAgreementPriceType.OTHER,
          label: t('general.label.other'),
          value: SimpleAgreementPriceType.OTHER,
          onChange: handleChange,
          checked: priceType === SimpleAgreementPriceType.OTHER,
        },
      ];
    },
    [t]
  );

  return { getRadioGroupOptions };
};
