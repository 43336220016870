import { FC, useState } from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import ErrorAlert from '@components/base/ErrorAlert';
import TextInfoBox from '@components/base/TextInfoBox';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import { useUserBusinessId } from '@contexts/UserContext';
import { usePageAnalytics } from '@hooks/useAnalytics';
import { useQualificationListQuery } from '@internals/business-shared/src/hooks/query/useQualificationListQuery';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { getQualificationListResponseError } from '@internals/business-shared/src/utils/getQualificationListResponseError';
import { isEmptyObject } from '@internals/business-shared/src/utils/objectUtils';
import { Div, Text, Avatar, Spinner } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

const ProfileQualifications: FC = () => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const businessId = useUserBusinessId();
  const [qualificationListErrors, setQualificationListErrors] =
    useState<GQLErrorState>({});
  const { loading, data: payload } = useQualificationListQuery({
    variables: {
      id: businessId,
    },
    onCompleted: (result) => {
      const responseError = getQualificationListResponseError(result);
      if (!isEmptyObject(responseError)) {
        setQualificationListErrors(responseError);
      }
    },
  });
  const emptyPayload = payload !== null && payload.length === 0;

  usePageAnalytics(ANSpace(ANObject.Certificates, ANPage.Profile));

  return (
    <Div px={6}>
      <SectionHeader title={t('profile.qualifications.list.title')} />
      <TextInfoBox text={t('profile.qualifications.list.info')} />
      <Div mb={8}>
        <Text.p
          mb={6}
          fontSize={3}
          fontWeight={themeContext.fontWeights.medium}
        >
          {t('profile.qualifications.list.section.approvals')}
        </Text.p>
        <ErrorAlert errors={qualificationListErrors} />
        {loading && emptyPayload && (
          <CenteredSpinnerContainer>
            <Spinner size={6} />
          </CenteredSpinnerContainer>
        )}
        {payload?.map((certificate) => (
          <Div key={certificate.id} display="flex" alignItems="center" mb={6}>
            <Avatar
              image={certificate.logos?.small?.url || ''}
              initials={getInitials(certificate.name)}
              borderRadius={themeContext.radii[3]}
              backgroundSize={certificate.logos?.small?.url ? 'contain' : null}
            />
            <Text.p
              ml={5}
              fontSize={3}
              fontWeight={themeContext.fontWeights.medium}
            >
              {certificate.name}
            </Text.p>
          </Div>
        ))}
      </Div>
    </Div>
  );
};
export default ProfileQualifications;
