import { FC, PropsWithChildren } from 'react';

import { TriggerSource } from '@components/elements/BoostCreditsModal/constants';
import { useBoostCreditsModalContext } from '@components/elements/BoostCreditsModal/ModalContext';
import { ReactComponent as RippedPaperGraphic } from '@internals/business-shared/src/assets/illustrations/RippedPaperHorizontal.svg';
import { Button, Card, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const BoostCreditsCard: FC<PropsWithChildren> = () => {
  const { packageList, loading, handleModalOpen } =
    useBoostCreditsModalContext();
  const { t } = useTranslation();
  const handleClick = () => handleModalOpen(TriggerSource.JOB_LIST);

  return (
    <Card height={190} p={0} mb={6} boxShadow="l">
      <Div height={110} position="relative" bg="yellow.light">
        <Div p={5}>
          <Text.p>{t('general.label.boost')}</Text.p>
          <Text.p fontSize={4} fontWeight="medium">
            {t('general.label.credit', {
              count: packageList?.[0]?.clips ?? 0,
            })}
          </Text.p>
        </Div>
        <Div position="absolute" left={0} bottom={-2} width={1}>
          <RippedPaperGraphic />
        </Div>
        <Card
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          boxShadow="l"
          width={70}
          height={24}
          right={0}
          bottom={20}
          bg="blueNavy.base"
          p={0}
        >
          <Text.p mb={0} color="black.black0" fontWeight="medium">
            {t('general.label.newFeature')}
          </Text.p>
        </Card>
      </Div>
      <Div
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        position="relative"
        bottom={4}
        px={5}
      >
        <Text.p mb={4} fontSize={1}>
          {t('job.list.statistics.boostCard.amountDescription', {
            count: packageList?.[0]?.clips ?? 0,
          })}
        </Text.p>
        <Button size="tiny" fullWidth onClick={handleClick} isLoading={loading}>
          <Text.p mb={0} fontSize={3} fontWeight="medium">
            {t('job.list.statistics.boostCard.action')}
          </Text.p>
        </Button>
      </Div>
    </Card>
  );
};
