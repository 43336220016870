import { createGlobalStyle } from 'styled-components';

// override modal styles for the print view
export const StyledContractDetailsPrint = createGlobalStyle`
  @media print {
    .ReactModal__Overlay {
      position: absolute !important;
      button {
        display: none;
      }
    }
  }
`;
