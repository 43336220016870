import { useMemo } from 'react';

import {
  LazyQueryHookOptions,
  QueryResult,
  useLazyQuery,
} from '@apollo/client';

import { formatCardNumber } from '../../utils/formatCardNumber';
import {
  CARD_LIST,
  CardList,
  CardListPayload,
} from '../../utils/query/CardList/CardList';

interface CardListQueryResult extends QueryResult {
  data: CardList[];
}

type FetchCardListType = () => Promise<QueryResult<CardListPayload>>;

export const useCardListLazyQuery = (
  options?: LazyQueryHookOptions<CardListPayload>
): [FetchCardListType, CardListQueryResult] => {
  const [fetchCardList, { data, ...rest }] = useLazyQuery<CardListPayload>(
    CARD_LIST,
    {
      ...options,
    }
  );

  const memoizedData = useMemo(
    () => ({
      data:
        data?.cardList.map((card) => ({
          ...card,
          maskedCardNumber: formatCardNumber(card.maskedCardNumber),
        })) || [],
      ...rest,
    }),
    [data, rest]
  );

  return [fetchCardList, memoizedData];
};
