import { FC } from 'react';

import { getProduct } from '@root/src/utils/product';
import { Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const Terms: FC = () => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();

  return (
    <Text.span color="black.black5" fontSize={1} textAlign="center" mb={0}>
      {t('job.modal.boost.terms', { productName })}
    </Text.span>
  );
};
