import { FC, lazy, LazyExoticComponent } from 'react';

import {
  BoligMappaContentAction,
  BoligMappaContentComponentProps,
} from '@internals/business-shared/src/reducers/BoligMappaModalReducer';

const SearchAddressContent = lazy<FC<BoligMappaContentComponentProps>>(
  () => import('@components/elements/BoligMappa/content/SearchAddressContent')
);

const FindUnitContent = lazy<FC<BoligMappaContentComponentProps>>(
  () => import('@components/elements/BoligMappa/content/FindUnitContent')
);

const AssociateAddressContent = lazy<FC<BoligMappaContentComponentProps>>(
  () =>
    import('@components/elements/BoligMappa/content/AssociateAddressContent')
);
const AddDocumentationContent = lazy<FC<BoligMappaContentComponentProps>>(
  () =>
    import('@components/elements/BoligMappa/content/AddDocumentationContent')
);

const SuccessContent = lazy<FC<BoligMappaContentComponentProps>>(
  () => import('@components/elements/BoligMappa/content/SuccessContent')
);

const ShowAssociatedAddressContent = lazy<FC<BoligMappaContentComponentProps>>(
  () =>
    import(
      '@components/elements/BoligMappa/content/ShowAssociatedAddressContent'
    )
);

const ErrorContent = lazy<FC<BoligMappaContentComponentProps>>(
  () => import('@components/elements/BoligMappa/content/ErrorContent')
);

export const getBoligModalContent = (
  kind: BoligMappaContentAction['type']
): LazyExoticComponent<FC<BoligMappaContentComponentProps>> | null => {
  switch (kind) {
    case 'RESET':
    case 'SEARCH-ADDRESS':
      return SearchAddressContent;
    case 'FIND-UNIT':
      return FindUnitContent;
    case 'ASSOCIATE-ADDRESS':
      return AssociateAddressContent;
    case 'ADD-DOCUMENTATION':
      return AddDocumentationContent;
    case 'SUCCESS':
      return SuccessContent;
    case 'SHOW-ASSOCIATED-ADDRESS':
      return ShowAssociatedAddressContent;
    case 'ERROR':
      return ErrorContent;
    default: {
      return null;
    }
  }
};
