import getPublicURL from '../../config/public-url';

export const getLoginURL = (environment?: string, product?: string): string => {
  const publicUrl = getPublicURL(environment, product);

  switch (product) {
    case 'mittanbud':
      return `${publicUrl}/logg-inn`;

    case 'remppatori':
      return `${publicUrl}/kirjaudu-sisaan`;

    case 'byggetilbudmatch':
      return `${publicUrl}/log-ind`;

    case 'servicefinder':
      return `${publicUrl}/logga-in`;

    default:
      return `${publicUrl}/login`;
  }
};

export const getLoginWithPasswordURL = (
  environment?: string,
  product?: string
): string => {
  const publicUrl = getPublicURL(environment, product);

  switch (product) {
    case 'mittanbud':
      return `${publicUrl}/logg-inn/med-passord`;

    case 'remppatori':
      return `${publicUrl}/kirjaudu-sisaan/salasanalla`;

    case 'byggetilbudmatch':
      return `${publicUrl}/log-ind/med-kode`;

    case 'servicefinder':
      return `${publicUrl}/logga-in/med-lösenord`;

    default:
      return `${publicUrl}/login/med-passord`;
  }
};

export const getAccountSwitchURL = (
  environment: string,
  product: string
): string => {
  const publicUrl = getPublicURL(environment, product);

  switch (product) {
    case 'mittanbud':
      return `${publicUrl}/bytt-bruker`;

    case 'remppatori':
      return `${publicUrl}/vaihda-kayttajaa`;

    case 'byggetilbudmatch':
      return `${publicUrl}/skift-bruger`;

    case 'servicefinder':
      return `${publicUrl}/byt-anvandare`;

    default:
      return `${publicUrl}/change-user`;
  }
};

export const getExpiredTokenPageURL = (
  environment: string,
  product: string
): string => {
  const publicUrl = getPublicURL(environment, product);

  switch (product) {
    case 'mittanbud':
      return `${publicUrl}/logg-inn/utlopt`;

    case 'remppatori':
      return `${publicUrl}/kirjaudu-sisaan/vanhentunut`;

    case 'byggetilbudmatch':
      return `${publicUrl}/log-ind/udloebet`;

    case 'servicefinder':
      return `${publicUrl}/logga-in/utlopt`;

    default:
      return `${publicUrl}/login/expired`;
  }
};

export const getMainPageUrl = (
  environment?: string,
  product?: string
): string => getPublicURL(environment, product);

export default {
  getLoginURL,
  getMainPageUrl,
  getAccountSwitchURL,
};
