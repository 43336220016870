export const isURL = (url: string): boolean => {
  try {
    /* eslint-disable no-new */
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const locationRedirect = (url: string) => {
  if (!isURL(url)) {
    throw new Error(`Invalid URL: ${url}`);
  }

  window.location.href = url;
};
