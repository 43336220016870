import gql from 'graphql-tag';

const FILE_FRAGMENT = gql`
  fragment FileFragment on File {
    description
    iconUrl
    id
    name
    size
    url
    fileType
  }
`;
export default FILE_FRAGMENT;
