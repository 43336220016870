import styled from 'styled-components';

export const StyledNotificationSection = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.black.black4};
  padding: ${({ theme }) => `${theme.space[6]}px ${theme.space[5]}px`};
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${({ theme }) => theme.space[7]}px;
  }
`;

export const StyledNotificationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: ${({ theme }) => `${theme.space[6]}px ${theme.space[6]}px`};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    grid-gap: ${({ theme }) => `${theme.space[6]}px ${theme.space[8]}px`};
  }
`;

export const StyledNotificationToggleText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

export const StyledNotificationToggleContainer = styled.div`
  min-width: 50px;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-width: 55px;
  }
`;
