const getConsumerURL = (environment, product) => {
  switch (product) {
    case 'mittanbud': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5020/';
        case 'development':
          return 'https://minside.ma-no-dev.click/';
        case 'staging':
          return 'https://minside.ma-no-pre.click/';
        case 'production':
          return 'https://minside.mittanbud.no/';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'remppatori': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5020/';
        case 'development':
          return 'https://mysite.ma-fi-dev.click/';
        case 'staging':
          return 'https://mysite.ma-fi-pre.click/';
        case 'production':
          return 'https://mysite.remppatori.fi/';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'byggetilbudmatch': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5020/';
        case 'development':
          return 'https://minside.ma-dk-dev.click/';
        case 'staging':
          return 'https://minside.ma-dk-pre.click/';
        case 'production':
          return 'https://minside.3byggetilbud.dk/';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'servicefinder': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5020/';
        case 'development':
          return 'https://minasidor.ma-se-dev.click/';
        case 'staging':
          return 'https://minasidor.ma-se-pre.click/';
        case 'production':
          return 'https://minasidor.ma-se-pro.click/';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    default:
      throw new Error(
        `Missing or incorrect product parameter. You must specify an product explicitly. Received "${product}"`
      );
  }
};

module.exports = getConsumerURL;
