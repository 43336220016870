import { AddressFragment, AddressInput } from '../generated/generated';

const Address2GQLAddressInput = (
  id: AddressFragment['id'],
  street: AddressFragment['street'],
  zip: AddressFragment['zip']
): AddressInput => ({
  id,
  street,
  zip,
});

export default Address2GQLAddressInput;
