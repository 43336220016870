import { FC } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import {
  useDistrictFilterChange,
  useJobFiltersContext,
  useMunicipalityFilterChange,
  useSelectAllCountyAreaFilters,
  useUnselectAllCountyAreaFilters,
} from '@contexts/JobFiltersContext';
import {
  allDistrictsInMunicipalityActive,
  CountiesMunicipality,
  CountyWithMunicipalities,
  isMunicipalityActive,
} from '@internals/business-shared/src/utils/areasUtils';

interface CountyViewProps {
  onBackClick: VoidFunction;
  county: CountyWithMunicipalities;
  onMunicipalityExpand: (municipality: CountiesMunicipality) => void;
}

const CountyView: FC<React.PropsWithChildren<CountyViewProps>> = ({
  onBackClick,
  county,
  onMunicipalityExpand,
}) => {
  const { activeMunicipalities, activeDistricts } = useJobFiltersContext();
  const handleOnMunicipalityChange = useMunicipalityFilterChange();
  const handleOnDistrictChange = useDistrictFilterChange();
  const selectAllAreasInCounty = useSelectAllCountyAreaFilters();
  const unselectAllAreasInCounty = useUnselectAllCountyAreaFilters();

  // municipality w/o districts is displayed as toggle, municipality w/ districts is open in next view (on click)
  const municipalitiesWithoutDistricts = county.municipalities.filter(
    (municipality) => !municipality.districts.length
  );
  const municipalitiesWithoutDistrictsCount =
    municipalitiesWithoutDistricts.length;
  const activeMunicipalitiesInCounty = municipalitiesWithoutDistricts.filter(
    (municipality) =>
      isMunicipalityActive(municipality, activeMunicipalities) ||
      allDistrictsInMunicipalityActive(municipality, activeDistricts)
  );
  const allMunicipalitiesSelected =
    municipalitiesWithoutDistrictsCount === activeMunicipalitiesInCounty.length;

  const onSelectedClick = (
    municipality: CountiesMunicipality,
    districtId: string | string[]
  ) => {
    if (typeof districtId === 'string') {
      handleOnDistrictChange(districtId, false);
      return;
    }
    handleOnMunicipalityChange(municipality, false);
  };

  const toggleAll = () =>
    allMunicipalitiesSelected
      ? unselectAllAreasInCounty(county.id)
      : selectAllAreasInCounty(county.id);

  const toggleAllAction = municipalitiesWithoutDistrictsCount
    ? {
        action: toggleAll,
        selectedFilterCount: activeMunicipalitiesInCounty.length,
        totalFilterCount: municipalitiesWithoutDistrictsCount,
      }
    : undefined;

  return (
    <FilterView
      title={county.name}
      onBackClick={onBackClick}
      toggleAllAction={toggleAllAction}
    >
      <FilterGrid>
        {county.municipalities.map((municipality) => {
          if (municipality.districts.length) {
            const activeDistrictsInMunicipality = municipality.districts.filter(
              (district) => activeDistricts.includes(district.id)
            );
            return (
              <FilterGroupButton
                key={municipality.id}
                filterName={municipality.name}
                onClick={() => onMunicipalityExpand(municipality)}
                selectedFilters={activeDistrictsInMunicipality}
                onSelectedClick={(districtId) =>
                  onSelectedClick(municipality, districtId)
                }
              />
            );
          }
          const isActive = isMunicipalityActive(
            municipality,
            activeMunicipalities
          );
          return (
            <FilterToggle
              key={municipality.id}
              name={municipality.name}
              isChecked={isActive}
              onChange={() =>
                handleOnMunicipalityChange(municipality, !isActive)
              }
            />
          );
        })}
      </FilterGrid>
    </FilterView>
  );
};

export default CountyView;
