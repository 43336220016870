import { Textarea } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  resize: none;
  min-height: 0;
  border: none;
  padding: 0;
  overflow: hidden;
  background-color: inherit;
  &:invalid,
  &:focus,
  &:hover {
    box-shadow: none;
    border: none;
  }
`;

export default StyledTextarea;
