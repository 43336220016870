import { useCallback } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  useApolloClient,
  useMutation,
} from '@apollo/client';

import { clearJobLists } from '../../cache/clearJobLists';
import {
  chooseBusinessWorkTypeSubsetsOptimisticResponse,
  chooseBusinessWorkTypeSubsetsUpdate,
} from '../../cache/updates/chooseBusinessWorkTypeSubsets';
import JobListId from '../../utils/constants/jobListIds';
import {
  CHOOSE_BUSINESS_WORK_TYPE_SUBSETS,
  ChooseBusinessWorkTypeSubsetsVariables,
  ChooseBusinessWorkTypeSubsetsPayload,
} from '../../utils/mutation/ChooseBusinessWorkTypeSubsets/ChooseBusinessWorkTypeSubsets';
import { JOB_LIST_FILTERS_QUERY } from '../../utils/query/JobListFilters/JobListFiltersQuery';
import { JOBS_LIST_QUERY } from '../../utils/query/JobsList/JobsListQuery';

type useChooseBusinessWorkSubsetsMutationFn = (
  businessId: ChooseBusinessWorkTypeSubsetsVariables['businessId'],
  activeWorkSubsets: ChooseBusinessWorkTypeSubsetsVariables['subsetIds'],
  mutationOptions?: MutationFunctionOptions<
    ChooseBusinessWorkTypeSubsetsPayload,
    ChooseBusinessWorkTypeSubsetsVariables
  >
) => Promise<FetchResult<ChooseBusinessWorkTypeSubsetsPayload>>;

export const useChooseBusinessWorkSubsetsMutation = (
  mutationHookOptions?: MutationHookOptions<
    ChooseBusinessWorkTypeSubsetsPayload,
    ChooseBusinessWorkTypeSubsetsVariables
  >
): [
  useChooseBusinessWorkSubsetsMutationFn,
  MutationResult<ChooseBusinessWorkTypeSubsetsPayload>
] => {
  const { cache } = useApolloClient();

  const [chooseBusinessWorkSubsets, ...rest] = useMutation<
    ChooseBusinessWorkTypeSubsetsPayload,
    ChooseBusinessWorkTypeSubsetsVariables
  >(CHOOSE_BUSINESS_WORK_TYPE_SUBSETS, {
    onCompleted: () => clearJobLists(cache),
    refetchQueries: [
      { query: JOB_LIST_FILTERS_QUERY },
      {
        query: JOBS_LIST_QUERY,
        variables: { listId: JobListId.Open, skipAnsweredOnlyFields: true },
      },
    ],
    ...mutationHookOptions,
  });

  const mutationFn: useChooseBusinessWorkSubsetsMutationFn = useCallback(
    (businessId, activeWorkSubsets, options) => {
      return chooseBusinessWorkSubsets({
        variables: {
          businessId,
          subsetIds: activeWorkSubsets,
        },
        update: chooseBusinessWorkTypeSubsetsUpdate(businessId),
        optimisticResponse:
          chooseBusinessWorkTypeSubsetsOptimisticResponse(activeWorkSubsets),
        ...options,
      });
    },
    [chooseBusinessWorkSubsets]
  );

  return [mutationFn, ...rest];
};
