import { FC } from 'react';

import { Details } from '@components/base/Details';
import { FireIcon, FireIconProps } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTheme } from 'styled-components';

import {
  StyledIconContainer,
  StyledDescription,
  StyledSummaryContainer,
  StyledSummaryLabel,
  StyledSummaryToggleIcon,
} from './styled';

interface JobInfoAccordionProps {
  title: string;
  description: string;
  iconProps: FireIconProps;
}

export const JobInfoAccordion: FC<JobInfoAccordionProps> = ({
  title,
  description,
  iconProps,
}) => {
  const theme = useTheme();

  return (
    <Details
      summary={
        <StyledSummaryContainer>
          <StyledIconContainer>
            <FireIcon width={16} height={16} {...iconProps} />
          </StyledIconContainer>
          <StyledSummaryLabel>{title}</StyledSummaryLabel>
          <StyledSummaryToggleIcon>
            <FireIcon
              iconName={IconPickerName.ChevronDown}
              width={16}
              height={16}
              variant="flat"
              fill={theme.colors.black.black10}
            />
          </StyledSummaryToggleIcon>
        </StyledSummaryContainer>
      }
      space={theme.space[6]}
      border={`1px solid ${theme.colors.black.black3}`}
    >
      <StyledDescription>{description}</StyledDescription>
    </Details>
  );
};
