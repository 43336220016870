import { FC, ReactNode } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledKeyIconContainer } from './styled/StyledKeyIconContainer';

type KeyIconType =
  | { type: 'regular'; name: IconPickerName }
  | { type: 'text'; content: string };
interface KeyIconProps {
  icon: KeyIconType;
  withMargin?: boolean;
}
interface KeyProps {
  text: string;
  children: ReactNode;
}
interface FooterProps {
  onNewTemplate: VoidFunction;
  onPasteIn: (message: string) => void;
  template: MessageTemplate;
  actionInProgress: boolean;
}

const KeyIcon: FC<KeyIconProps> = ({ icon, withMargin }) => {
  const themeContext = useTheme();

  return (
    <StyledKeyIconContainer mr={withMargin ? 2 : 0}>
      {icon.type === 'regular' ? (
        <FireIcon
          iconName={icon.name}
          variant="flat"
          fill={themeContext.colors.black.black9}
          width={16}
          height={16}
        />
      ) : (
        <Text.span m={0}>{icon.content}</Text.span>
      )}
    </StyledKeyIconContainer>
  );
};

const Key: FC<KeyProps> = ({ children, text }) => (
  <Div display="flex" alignItems="center" mr={5}>
    <>
      {children}
      <Text.span fontSize={1} ml={3} mb={0}>
        {text}
      </Text.span>
    </>
  </Div>
);

const KeyboardNavigationOverview: FC = () => {
  const { t } = useTranslation();

  return (
    <Div display="flex" ml={5}>
      <Key text={t('chat.item.template.keyboard.navigate')}>
        <KeyIcon
          icon={{ type: 'regular', name: IconPickerName.Arrow2Up }}
          withMargin
        />
        <KeyIcon icon={{ type: 'regular', name: IconPickerName.Arrow2Down }} />
      </Key>
      <Key text={t('chat.item.template.keyboard.close')}>
        <KeyIcon icon={{ type: 'text', content: 'Esc' }} />
      </Key>
    </Div>
  );
};

export const Footer: FC<FooterProps> = ({
  onNewTemplate,
  onPasteIn,
  template,
  actionInProgress,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      borderTop={`1px solid ${themeContext.colors.black.black3}`}
      px={6}
      pt={3}
      pb={4}
    >
      <Div display="flex">
        <Button
          onClick={onNewTemplate}
          variant="secondary"
          size="tiny"
          py={2}
          px={3}
          data-testid="modal-footer-message-template-new"
          icon={() => (
            <Text.span m={0} fontSize={1} display="flex">
              <FireIcon iconName={IconPickerName.Plus} height={13} width={13} />
            </Text.span>
          )}
        >
          {t('chat.item.template.new')}
        </Button>

        <KeyboardNavigationOverview />
      </Div>
      <Button
        onClick={() => onPasteIn(template.text)}
        size="tiny"
        disabled={actionInProgress}
        iconPosition="left"
        icon={() => (
          <FireIcon
            iconName={IconPickerName.PasteIn}
            width={16}
            height={16}
            fill="black.black0"
          />
        )}
      >
        {t('chat.item.template.form.paste')}
      </Button>
    </Div>
  );
};
