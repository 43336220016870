import { FC, useCallback } from 'react';

import { TreeLabel } from '@components/layout/Jobs/Tree/styled/TreeLabel';
import { TreeSection } from '@components/layout/Jobs/Tree/styled/TreeSection';
import { TreeBranch } from '@components/layout/Jobs/Tree/TreeBranch';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import {
  FilteredJobCount,
  getJobsCountForFilter,
  joinFilterWithCount,
} from '@internals/business-shared/src/utils/jobFilters';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import {
  BaseWorkType,
  getSubsetId,
  WorkTypeGroup,
} from '@internals/business-shared/src/utils/workTypesUtils';
import { useTranslation } from 'react-i18next';

interface AdditionalFilteringTreeProps {
  jobCount?: FilteredJobCount;
}

export const AdditionalFilteringTree: FC<AdditionalFilteringTreeProps> = ({
  jobCount,
}) => {
  const { t } = useTranslation();
  const {
    workTypes,
    activeWorkTypes,
    setActiveWorkTypes,
    activeSubsetGroups,
    setActiveSubsetGroups,
  } = useJobFiltersContext();
  const subsetsGroups = workTypes.filter(
    (workTypeGroup) => workTypeGroup.variant === 'subsetGroup'
  );
  const isSubsetGroupActive = useCallback(
    (subsetGroup: WorkTypeGroup) => {
      if (
        subsetGroup.workTypes.some((subset) =>
          activeWorkTypes.includes(subset.id)
        )
      ) {
        return true;
      }
      return activeSubsetGroups.includes(subsetGroup.id);
    },
    [activeWorkTypes, activeSubsetGroups]
  );

  const onSubsetGroupChange = useCallback(
    (subsetGroup: WorkTypeGroup, isActive: boolean) => {
      if (!isActive) {
        setActiveWorkTypes(
          toggleItemInArray(
            activeWorkTypes,
            subsetGroup.workTypes.map((subset) => subset.id),
            false
          )
        );
      }
      setActiveSubsetGroups(
        toggleItemInArray(activeSubsetGroups, subsetGroup.id, isActive)
      );
    },
    [setActiveWorkTypes, activeWorkTypes, activeSubsetGroups]
  );

  const onSubsetChange = useCallback(
    (subset: BaseWorkType, parentGroup: WorkTypeGroup, isActive: boolean) => {
      if (isActive) {
        // remove group filter to include more specific work type one
        setActiveSubsetGroups(
          toggleItemInArray(activeSubsetGroups, parentGroup.id, false)
        );
      } else {
        // when unselecting last subset filter from group, replace it back with group filter
        const isLastSubsetActiveInGroup =
          parentGroup.workTypes.filter((groupWorkType) =>
            activeWorkTypes.includes(groupWorkType.id)
          ).length <= 1;
        if (isLastSubsetActiveInGroup) {
          setActiveSubsetGroups(
            toggleItemInArray(activeSubsetGroups, parentGroup.id, true)
          );
        }
      }
      setActiveWorkTypes(
        toggleItemInArray(activeWorkTypes, subset.id, isActive)
      );
    },
    [
      setActiveSubsetGroups,
      setActiveWorkTypes,
      activeWorkTypes,
      activeSubsetGroups,
    ]
  );

  const getSubsetGroupLabel = (subsetGroup: WorkTypeGroup) => {
    const count = getJobsCountForFilter(
      jobCount,
      'worktypeSubsetGroups',
      subsetGroup.id
    );
    return joinFilterWithCount(subsetGroup.name, count);
  };

  const getSubsetLabel = (subset: BaseWorkType) => {
    const count = getJobsCountForFilter(
      jobCount,
      'worktypeSubsets',
      getSubsetId(subset.id)
    );
    return joinFilterWithCount(subset.name, count);
  };

  const subsetBranches = subsetsGroups?.map((subsetGroup) => {
    const isActiveGroup = isSubsetGroupActive(subsetGroup);
    return (
      <TreeBranch
        key={`${subsetGroup.id}-${subsetGroup.name}`}
        branch={{
          id: `${subsetGroup.id}-${subsetGroup.name}`,
          label: getSubsetGroupLabel(subsetGroup),
          checked: isActiveGroup,
          onChange: () => onSubsetGroupChange(subsetGroup, !isActiveGroup),
        }}
        leafs={subsetGroup.workTypes.map((subset) => {
          const isActiveSubset = activeWorkTypes.includes(subset.id);
          return {
            id: subset.id,
            label: getSubsetLabel(subset),
            checked: isActiveSubset,
            onChange: () =>
              onSubsetChange(subset, subsetGroup, !isActiveSubset),
          };
        })}
      />
    );
  });

  if (!subsetsGroups.length) return null;

  return (
    <TreeSection>
      <TreeLabel>{t('job.list.filters.additional.title')}</TreeLabel>
      {subsetBranches}
    </TreeSection>
  );
};
