import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const TextWrapper = styled(Div)`
  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    margin-top: ${(props): number => props.theme.space[2]}px;
  }
`;

export default TextWrapper;
