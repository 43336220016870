import {
  getUserName as _getUserName,
  BasicUser,
} from '@internals/business-shared/src/utils/user';
import { t } from '@utils/i18n';

export const getUserName = (user?: BasicUser | null): string =>
  _getUserName(user, {
    empty: '',
    deletedUser: t('general.label.deletedUser'),
  });
