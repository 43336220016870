import { Link } from 'react-router-dom';
import styled from 'styled-components';

const GoBackLink = styled(Link)`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export default GoBackLink;
