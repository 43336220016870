import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  isJobsListQuerySuccessResponse,
  JOBS_LIST_QUERY,
  JobsListQueryData,
  JobsListQueryPayload,
  JobsListQueryVariables,
} from '../../utils/query/JobsList/JobsListQuery';

export interface JobListQueryResult extends QueryResult {
  data: JobsListQueryData | null;
  previousData: JobsListQueryData | null;
}
export const useJobListQuery = (
  options: QueryHookOptions<JobsListQueryPayload, JobsListQueryVariables>
): JobListQueryResult => {
  const { data, previousData, ...rest } = useQuery<JobsListQueryPayload>(
    JOBS_LIST_QUERY,
    {
      fetchPolicy: 'cache-first',
      ...options,
    }
  );

  return useMemo(
    () => ({
      data:
        data && isJobsListQuerySuccessResponse(data.jobList)
          ? data.jobList
          : null,
      previousData:
        previousData && isJobsListQuerySuccessResponse(previousData.jobList)
          ? previousData.jobList
          : null,
      ...rest,
    }),
    [data, previousData, rest]
  );
};
