import useFetch, { IncomingOptions, CachePolicies, UseFetch } from 'use-http';

export interface CloudinaryAsset {
  public_id: string;
  external_id: string;
  resource_type: string;
  status: string;
  format: string;
  filename: string;
  delivery_urls: {
    original: string;
    thumbnail: string;
    preview: string;
  };
}

export interface CloudinaryCollection {
  id: string;
  collection_name: string;
  assets: CloudinaryAsset[];
}

export type CloudinaryAssetsFetchResult = UseFetch<CloudinaryCollection>;

const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;

export const useCloudinaryAssets = (
  cloudinaryUrl: string
): CloudinaryAssetsFetchResult => {
  const cloudinaryCollectionUrl = `${cloudinaryUrl}?mode=json&max_results=200`;
  const fetchOptions: IncomingOptions = {
    cacheLife: DAY_IN_MILLISECONDS,
    cachePolicy: CachePolicies.CACHE_FIRST,
    responseType: 'json',
    data: {
      assets: [],
    } as CloudinaryCollection,
    method: 'GET',
  };
  return useFetch<CloudinaryCollection>(
    cloudinaryCollectionUrl,
    fetchOptions,
    []
  );
};
