import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const JobModalContentLoader: React.FC<LoaderProps> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 940 860"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    {/* description */}
    <rect x="20" y="20" rx="4" ry="4" width="550" height="20" />
    <rect x="20" y="45" rx="4" ry="4" width="500" height="20" />
    <rect x="20" y="70" rx="4" ry="4" width="415" height="20" />
    {/* details */}
    <rect x="20" y="110" rx="4" ry="4" width="584" height="100" />
    {/* images */}
    <rect x="20" y="230" rx="4" ry="4" width="188" height="188" />
    <rect x="218" y="230" rx="4" ry="4" width="188" height="188" />
    <rect x="416" y="230" rx="4" ry="4" width="188" height="188" />
    {/* job id */}
    <rect x="20" y="438" rx="4" ry="4" width="80" height="15" />
    <rect x="20" y="458" rx="4" ry="4" width="155" height="20" />
    {/* alerts/form */}
    <rect x="20" y="554" rx="4" ry="4" width="584" height="212" />
    {/* column delimiter */}
    <rect x="626" y="0" rx="4" ry="4" width="1" height="798" />
    {/* client */}
    <rect x="647" y="20" rx="4" ry="4" width="140" height="23" />

    <rect x="647" y="58" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="78" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="118" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="138" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="178" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="198" rx="4" ry="4" width="155" height="20" />

    <rect x="647" y="238" rx="4" ry="4" width="80" height="15" />
    <rect x="647" y="258" rx="4" ry="4" width="155" height="20" />
    {/* map */}
    <rect x="647" y="288" rx="4" ry="4" width="273" height="160" />
  </ContentLoader>
);

export default JobModalContentLoader;
