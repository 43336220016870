import { FC, PropsWithChildren } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div } from '@schibsted-smb/fireball';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

interface StyleProps {
  pt?: number;
  pb?: number;
  overflow?: string;
}

export const BodyWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { modalState } = useBuySubscriptionModalContext();

  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      pt: isTabletOrMobile ? 14 : 6,
    },
    [ModalState.SUMMARY]: {
      pt: 14,
      pb: 30,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      pt: 14,
    },
  };

  return (
    <Div
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb={6}
      position="relative"
      px={6}
      zIndex={1}
      {...styleProps[modalState]}
    >
      {children}
    </Div>
  );
};
