const getAPIEndpoint = (environment, product) => {
  switch (product) {
    case 'mittanbud': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5000';
        case 'development':
          return 'https://ma-no-dev.click';
        case 'staging':
          return 'https://ma-no-pre.click';
        case 'production':
          return 'https://mittanbud.no';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'remppatori': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5000';
        case 'staging':
          return 'https://ma-fi-pre.click';
        case 'development':
          return 'https://ma-fi-dev.click';
        case 'production':
          return 'https://remppatori.fi';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'byggetilbudmatch': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5000';
        case 'staging':
          return 'https://match.ma-dk-pre.click';
        case 'development':
          return 'https://match.ma-dk-dev.click';
        case 'production':
          return 'https://match.3byggetilbud.dk';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    case 'servicefinder': {
      switch (environment) {
        case 'test':
        case 'local':
          return 'http://localhost:5000';
        case 'staging':
          return 'https://ma-se-pre.click';
        case 'development':
          return 'https://ma-se-dev.click';
        case 'production':
          return 'https://www.servicefinder.se';
        default:
          throw new Error(
            `Missing or incorrect environment parameter. You must specify an environment explicitly. Received "${environment}"`
          );
      }
    }

    default:
      throw new Error(
        `Missing or incorrect product parameter. You must specify an product explicitly. Received "${product}"`
      );
  }
};

module.exports = getAPIEndpoint;
