import gql from 'graphql-tag';

const BASIC_USER_DATA_FRAGMENT = gql`
  fragment BasicUserDataFragment on User {
    id
    firstName
    lastName
    isDeleted
    isVerifiedWithSecureId
  }
`;
export default BASIC_USER_DATA_FRAGMENT;
