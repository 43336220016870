import { FC } from 'react';

import { BusinessNotificationType } from '@internals/business-shared/src/utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';
import { Div, Text, Toggle } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface SettingsNotificationSwitchProps {
  type: BusinessNotificationType;
  enabled: boolean;
  onChange: VoidFunction;
  testId: string;
}

const SettingsNotificationSwitch: FC<SettingsNotificationSwitchProps> = ({
  type,
  enabled = false,
  onChange,
  testId,
}) => {
  const { t } = useTranslation();

  return (
    <Div display="flex" justifyContent="space-between" mb={4}>
      {/*
        t('settings.notifications.slug_ma-business-5-star-evaluation')
        t('settings.notifications.slug_ma-business-consumer-requests-contract')
        t('settings.notifications.slug_ma-business-chosen')
        t('settings.notifications.slug_ma-business-consumer-created-contract')
        t('settings.notifications.slug_ma-business-contract-signed')
        t('settings.notifications.slug_ma-business-declined-contract')
        t('settings.notifications.slug_ma-business-new-chat-message')
        t('settings.notifications.slug_ma-business-new-tender')
        t('settings.notifications.slug_ma-business-received-evaluation')
        t('settings.notifications.slug_ma-business-new-direct-tender')
        t('ma-business-invitation-to-answer-tender-job-survey')
      */}
      <Text.p>
        {t('settings.notifications.slug', {
          context: type.slug,
          defaultValue: '-',
        })}
      </Text.p>
      <Toggle
        name={type.slug}
        checked={enabled}
        onChange={onChange}
        testId={testId}
      />
    </Div>
  );
};

export default SettingsNotificationSwitch;
