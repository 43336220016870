import gql from 'graphql-tag';

import {
  CHAT_BY_JOB_ID_QUERY as CHAT_BY_JOB_ID_QUERY_Result,
  CHAT_BY_JOB_ID_QUERYVariables,
} from '../../generated/generated';

export type ChatByJobIdQueryPayload = CHAT_BY_JOB_ID_QUERY_Result;
export type ChatByJobIdQueryVariables = CHAT_BY_JOB_ID_QUERYVariables;
export type ChatByJobId = CHAT_BY_JOB_ID_QUERY_Result['chatByJobId'];
export const CHAT_BY_JOB_ID_QUERY = gql`
  query CHAT_BY_JOB_ID_QUERY($jobId: ID!) {
    chatByJobId(jobId: $jobId) {
      id
    }
  }
`;
