import { FC } from 'react';

import { Button, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { useBoostCreditsModalContext } from '../../ModalContext';
import { Terms } from '../Terms';

export const ActionButtons: FC = () => {
  const {
    loading,
    primaryContact,
    selectedCard,
    selectedPackage,
    handleCheckout,
    handleModalClose,
    handlePayment,
  } = useBoostCreditsModalContext();
  const { t } = useTranslation();

  return (
    <>
      <Button
        disabled={!primaryContact?.isCurrentUserPrimaryContact}
        fullWidth
        isLoading={loading}
        onClick={selectedCard ? handleCheckout : handlePayment}
      >
        {selectedCard
          ? t('general.action.buyCredits', {
              count: selectedPackage?.clips ?? 0,
            })
          : t('general.action.goToCheckout')}
      </Button>
      <Button onClick={handleModalClose} variant="linkPrimary" fullWidth>
        <Text.span color="black.black0">{t('general.label.cancel')}</Text.span>
      </Button>
      {!selectedCard && <Terms />}
    </>
  );
};
