import { HEADER_HEIGHT } from '@components/layout/Header/styled/Header';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

const ZebraTable = styled.table`
  width: 100%;
`;

const FloatingTableHeader = styled.th`
  position: sticky;
  top: ${HEADER_HEIGHT}px;
  z-index: ${Layer.sticky};
  padding: 0.5rem 1rem;
  color: ${(props): string => props.theme.colors.black.black0};
  background-color: ${(props): string => props.theme.colors.primary.base};
`;

const ZebraRow = styled.tr`
  &:nth-child(2n) {
    background-color: ${(props): string => props.theme.colors.black.black2};
  }
`;

export { ZebraTable, FloatingTableHeader, ZebraRow };
