import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledKeyIconContainer = styled(Div)`
  padding: ${({ theme }) => `${theme.space[1]}px ${theme.space[2]}px`};
  border: 1px solid ${({ theme }) => theme.colors.black.black3};
  border-radius: ${({ theme }) => theme.radii[3]};
  min-width: 30px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
