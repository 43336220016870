import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BOOST_CREDITS_PRODUCTS_PAIR,
  BoostCreditsProductsPair,
  BoostCreditsProductsPairPayload,
} from '../../utils/query/BoostCreditsProductsPair/BoostCreditsProductsPair';

interface BoostCreditsProductsPairQueryResult extends QueryResult {
  data: BoostCreditsProductsPair[];
}

export const useBoostCreditsProductsPairQuery = (
  options?: QueryHookOptions<BoostCreditsProductsPairPayload>
): BoostCreditsProductsPairQueryResult => {
  const { data, ...rest } = useQuery<BoostCreditsProductsPairPayload>(
    BOOST_CREDITS_PRODUCTS_PAIR,
    {
      ...options,
    }
  );

  return useMemo(
    () => ({
      data: data?.boostCreditsProductsPair ?? [],
      ...rest,
    }),
    [data, rest]
  );
};
