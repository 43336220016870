import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Tooltip } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { Trans } from 'react-i18next';

export const CreditsTooltip: FC = () => (
  <Div display="flex" zIndex={Layer.tooltip}>
    <Tooltip
      width={180}
      offSet={[-40, 80]}
      content={
        <Text.p mb={0}>
          <Trans i18nKey="job.modal.subscription.packageCard.tooltipCreditsContent" />
        </Text.p>
      }
    >
      <FireIcon
        width={16}
        height={16}
        iconName={IconPickerName.Info}
        fill="black.black10"
      />
    </Tooltip>
  </Div>
);
