import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { JobDetailsColumn } from '@components/layout/Job/styled/JobDetailsColumn';
import { JobDetailsText } from '@components/layout/Job/styled/JobDetailsText';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { RequestedStart } from '@internals/business-shared/src/utils/fragments/JobMetaFragment';
import {
  JobContactPreference,
  JobCustomerType,
} from '@internals/business-shared/src/utils/generated/generated';
import { getAnswerCount } from '@internals/business-shared/src/utils/jobMetadata/answers';
import { getConsumerType } from '@internals/business-shared/src/utils/jobMetadata/consumerType';
import { getContactPreference } from '@internals/business-shared/src/utils/jobMetadata/contactPreference';
import { getRequestedStart } from '@internals/business-shared/src/utils/jobMetadata/requestedStart';
import { Div, Text } from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import { useTheme } from 'styled-components';

interface JobDetailsProps {
  contactPreference: JobContactPreference | null;
  customerType: JobCustomerType | null;
  requestedStart: RequestedStart | null;
  answerCount: number | null;
  answerCountLimit: number | null;
}

interface JobDetailsItemProps {
  icon: IconPickerName;
  title: string;
  text: string;
  testId?: string;
}

const JobDetailsItem: FC<JobDetailsItemProps> = ({
  icon,
  text,
  title,
  testId,
}) => {
  const theme = useTheme();

  return (
    <JobDetailsColumn data-testid={testId || 'job-details'}>
      <Div mr={4}>
        <FireIcon
          iconName={icon}
          width={26}
          height={26}
          variant="flat"
          fill={theme.colors.black.black6}
        />
      </Div>
      <Div>
        <Text.p fontSize={0} mb={1}>
          {title}
        </Text.p>
        <JobDetailsText>{text}</JobDetailsText>
      </Div>
    </JobDetailsColumn>
  );
};

export const JobDetails: FC<JobDetailsProps> = ({
  contactPreference,
  answerCountLimit,
  answerCount,
  requestedStart,
  customerType,
}) => {
  const theme = useTheme();
  const { name: productName } = getProduct();
  const hideCountToggle = useFlagEnabled(
    FeatureFlags.BizJobCardHideAnswerCount
  );

  const data = [
    {
      icon: IconPickerName.Team,
      title: t('job.item.answerCount.title'),
      text: getAnswerCount(answerCount, answerCountLimit, hideCountToggle),
      testId: 'job-details-answer-count',
    },
    {
      icon: IconPickerName.PersonInfo,
      title: t('job.item.contactPreference.title'),
      text: getContactPreference(contactPreference, productName, (e) =>
        bugsnagClient.notify(e)
      ),
      testId: 'job-details-contact',
    },
    {
      icon: IconPickerName.Person,
      title: t('general.label.client'),
      text: getConsumerType(customerType, (e) => bugsnagClient.notify(e)),
      testId: 'job-details-consumer',
    },
    {
      icon: IconPickerName.Clock,
      title: t('job.item.requestedStart.title'),
      text: getRequestedStart(requestedStart, (e) => bugsnagClient.notify(e)),
      testId: 'job-details-time-start',
    },
  ];

  return (
    <Div
      display="flex"
      flexWrap="wrap"
      border={`1px solid ${theme.colors.black.black3}`}
      borderRadius={1}
      px={6}
      py={2}
      width="100%"
    >
      {data.map(({ text, title, icon, testId }) => (
        <JobDetailsItem
          key={title}
          icon={icon}
          title={title}
          text={text}
          testId={testId}
        />
      ))}
    </Div>
  );
};
