import { useCallback } from 'react';

import { ApolloClient, ApolloQueryResult, QueryOptions } from '@apollo/client';

import { ChatListType } from '../utils/generated/generated';
import {
  CHAT_LIST_QUERY,
  ChatListQueryPayload,
  ChatListQueryVariables,
} from '../utils/query/ChatList/ChatListQuery';

type ChatListFetch = Promise<ApolloQueryResult<ChatListQueryPayload>>;
type ChatListFetchFnType = (chatType: ChatListType) => ChatListFetch;
type AllChatListsFetchFnType = () => Promise<Awaited<ChatListFetch>[]>;

type Options = Omit<
  QueryOptions<ChatListQueryVariables, ChatListQueryPayload>,
  'query'
>;
export const useChatListFetch = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apolloClient: ApolloClient<Record<string, any>>,
  queryOptions?: Options
): ChatListFetchFnType => {
  const fetchChatList = useCallback((chatType: ChatListType) => {
    const { variables = {}, ...options } = queryOptions || {};
    // client used directly instead of useLazyQuery hook which calls the same query only once (with last set of variables)
    // and aborts request on component unmount
    return apolloClient.query({
      query: CHAT_LIST_QUERY,
      variables: {
        type: chatType,
        ...variables,
      },
      fetchPolicy: 'network-only',
      ...options,
    });
  }, []);

  return fetchChatList;
};

export const useAllChatListsFetch = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apolloClient: ApolloClient<Record<string, any>>,
  chatFilterList: ChatListType[],
  options?: Options
): AllChatListsFetchFnType => {
  const chatListFetch = useChatListFetch(apolloClient, options);

  const fetchChatLists = useCallback(() => {
    const chatListsQueries = chatFilterList.map(chatListFetch);
    return Promise.all(chatListsQueries);
  }, [chatListFetch]);

  return fetchChatLists;
};
