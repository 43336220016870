import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface CollapseDivProps {
  collapsed: boolean;
}

const CollapseDiv = styled(Div)<CollapseDivProps>`
  display: ${(props): string => (props.collapsed ? 'none' : 'flex')};
  overflow: ${(props): string => (props.collapsed ? 'hidden' : 'unset')};
`;

export default CollapseDiv;
