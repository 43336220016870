import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import {
  CustomErrorAlert,
  ErrorType,
} from '@components/base/ErrorAlert/CustomErrorAlert';
import ScrollPanel from '@components/base/ScrollPanel';
import useAnalytics from '@hooks/useAnalytics';
import { useChatListFilters } from '@hooks/useChatFilters';
import useDebounce from '@hooks/useDebounce';
import {
  deleteChatOptimisticResponse,
  deleteChatUpdate,
} from '@internals/business-shared/src/cache/updates/deleteChat';
import {
  favouriteJobOptimisticResponse,
  favouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/favouriteJob';
import {
  restoreChatOptimisticResponse,
  restoreChatUpdate,
} from '@internals/business-shared/src/cache/updates/restoreChat';
import {
  unfavouriteJobOptimisticResponse,
  unfavouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/unfavouriteJob';
import { useSetChatAsUnreadMutation } from '@internals/business-shared/src/hooks/mutation/useSetChatAsUnread';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { isChatUnread } from '@internals/business-shared/src/utils/ChatUtils';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '@internals/business-shared/src/utils/constants/apollo';
import {
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node,
  CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job,
  ChatListType,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  DELETE_CHAT_MUTATION,
  DeleteChatMutationPayload,
  DeleteChatMutationVariables,
} from '@internals/business-shared/src/utils/mutation/DeleteChat/DeleteChatMutation';
import {
  FAVOURITE_JOB,
  FavouriteJobMutationPayload,
  FavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/FavouriteJob/FavouriteJobMutation';
import {
  RESTORE_CHAT_MUTATION,
  RestoreChatMutationPayload,
  RestoreChatMutationVariables,
} from '@internals/business-shared/src/utils/mutation/RestoreChat/RestoreChatMutation';
import {
  UNFAVOURITE_JOB,
  UnfavouriteJobMutationPayload,
  UnfavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/UnfavouriteJob/UnfavouriteJobMutation';
import { CHAT_DETAILS_QUERY } from '@internals/business-shared/src/utils/query/ChatDetails/ChatDetailsQuery';
import {
  CHAT_LIST_QUERY,
  ChatListEdge,
  ChatListQueryPayload,
  ChatListQueryVariables,
  isChatListQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/ChatList/ChatListQuery';
import { JOB_QUERY } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { isEmptyString } from '@internals/business-shared/src/utils/types/StringUtils';
import {
  CHAT_LIST_DEFAULT_COUNT,
  CHAT_LIST_DEFAULT_FROM,
} from '@root/src/apollo/cache';
import { Div, Spinner } from '@schibsted-smb/fireball';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from 'styled-components';

import ChatListItemV2 from '../../elements/ChatListItem/ChatListItemV2';
import ChatListFilterV2 from './ChatListFilterV2';
import { ChatListNotFound } from './ChatListNotFound';
import ChatListColumn from './styled/ChatListColumn';

export interface ChatListProps {
  chatId?: string;
  isMobile?: boolean;
}

const DEFAULT_CHAT_LIST_TYPE = ChatListType.ACTIVE;
const STORABLE_CHAT_LIST_TYPE = [ChatListType.ACTIVE, ChatListType.INACTIVE];
const SAVED_LIST_STORAGE_KEY = 'activeChatFilter';

const ChatList: FC<ChatListProps> = ({ chatId, isMobile = false }) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const { track, page } = useAnalytics();
  const chatListRef = useRef<HTMLDivElement>(null);
  const [inputValue, changeInputValue] = useState('');

  const debouncedInputValue = useDebounce(inputValue, 500);
  const isSearchedByQuery = !isEmptyString(debouncedInputValue.trim());
  const filters = useChatListFilters();
  const [chatListType, setChatListType] = useState<ChatListType>(
    () =>
      (localStorage.getItem(SAVED_LIST_STORAGE_KEY) ||
        DEFAULT_CHAT_LIST_TYPE) as ChatListType
  );
  const [errors, setErrors] = useState<ErrorType | null>(null);
  const [setChatAsUnread] = useSetChatAsUnreadMutation();

  const chatListScrollContainerRef = useRef();
  const chatListScrollContainerId = 'chatListScrollContainer';
  const scrollContainer = document.getElementById(chatListScrollContainerId);

  const handleDataError = (data: ChatListQueryPayload) => {
    if (!isChatListQuerySuccessResponse(data?.chatList)) {
      setErrors({ msg: t('chat.list.error') });
    } else {
      setErrors(null);
    }
  };

  const { data, loading, fetchMore } = useQuery<
    ChatListQueryPayload,
    ChatListQueryVariables
  >(CHAT_LIST_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      type: chatListType,
      from: CHAT_LIST_DEFAULT_FROM,
      count: CHAT_LIST_DEFAULT_COUNT,
      ...(isSearchedByQuery && { query: debouncedInputValue }),
    },
    onCompleted: (res: ChatListQueryPayload) => {
      if (!isChatListQuerySuccessResponse(res.chatList)) {
        handleDataError(res);
      }
    },
    onError: () => {
      setErrors({ msg: t('chat.list.error') });
    },
  });

  const chatList = useMemo(() => {
    if (data && isChatListQuerySuccessResponse(data.chatList)) {
      const localChatList = data.chatList.chatConnection.edges;

      // sort edges by latestUpdateTs
      return [...localChatList].sort((a, b) => {
        const aDate = new Date(a.node.latestUpdateTs);
        const bDate = new Date(b.node.latestUpdateTs);
        return bDate.getTime() - aDate.getTime();
      });
    }
    return [];
  }, [data]);

  const totalChatsNumber =
    data && isChatListQuerySuccessResponse(data.chatList)
      ? data?.chatList.chatConnection.pageInfo.totalCount
      : 0;
  const hasChats = !!chatList.length;

  const [deleteChat] = useMutation<
    DeleteChatMutationPayload,
    DeleteChatMutationVariables
  >(DELETE_CHAT_MUTATION, {
    awaitRefetchQueries: true,
  });
  const [restoreChat] = useMutation<
    RestoreChatMutationPayload,
    RestoreChatMutationVariables
  >(RESTORE_CHAT_MUTATION, {
    awaitRefetchQueries: true,
  });
  const [unFavouriteJob] = useMutation<
    UnfavouriteJobMutationPayload,
    UnfavouriteJobMutationVariables
  >(UNFAVOURITE_JOB, {
    awaitRefetchQueries: true,
  });
  const [favouriteJob] = useMutation<
    FavouriteJobMutationPayload,
    FavouriteJobMutationVariables
  >(FAVOURITE_JOB, {
    awaitRefetchQueries: true,
  });
  const handleChatDelete = (chatNode: ChatListEdge['node']) => {
    if (chatNode.isDeleted) {
      restoreChat({
        refetchQueries: [
          { query: CHAT_DETAILS_QUERY, variables: { id: chatNode.id } },
        ],
        variables: { chatId: chatNode.id },
        optimisticResponse: restoreChatOptimisticResponse,
        update: restoreChatUpdate(chatNode.id, chatNode.job.labels.won, false),
      });
    } else if (!(chatListType === ChatListType.INACTIVE)) {
      deleteChat({
        refetchQueries: [
          { query: CHAT_DETAILS_QUERY, variables: { id: chatNode.id } },
        ],
        variables: { chatId: chatNode.id },
        optimisticResponse: deleteChatOptimisticResponse,
        update: deleteChatUpdate(chatNode.id),
      });

      track(ANEventSpace(ANEvent.Deleted, ANObject.Message, ANPage.Messages));
    }
  };

  const handleChatFavourite = (
    job: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node_job,
    jobLatestMessage: CHAT_LIST_QUERY_chatList_ChatListPayload_chatConnection_edges_node['latestMessage'],
    isFavourite: boolean
  ) => {
    if (isFavourite) {
      unFavouriteJob({
        refetchQueries: [{ query: JOB_QUERY, variables: { id: job.id } }],
        variables: { jobId: job.id },
        optimisticResponse: unfavouriteJobOptimisticResponse,
        update: unfavouriteJobUpdate(job.id),
      });
    } else {
      favouriteJob({
        refetchQueries: [{ query: JOB_QUERY, variables: { id: job.id } }],
        variables: { jobId: job.id },
        optimisticResponse: favouriteJobOptimisticResponse,
        update: favouriteJobUpdate({ ...job, latestMessage: jobLatestMessage }),
      });

      track(ANEventSpace(ANEvent.Favoured, ANObject.Job, ANPage.Messages));
    }
  };

  const handleChatUnread = useCallback(
    (chatNode: ChatListEdge['node']) => {
      if (isChatUnread(chatNode)) {
        return;
      }

      // We don't want to re-fetch BADGE_NUMBERS_QUERY when we mark a chat as unread, because it's triggering the chat list re-fetch. But in most cases they are not having the latest data.
      setChatAsUnread(chatNode.id, {
        context: {
          [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
        },
        onError: () => {
          ToastMessage(t('chat.item.markAsUnread.error'), 'danger');
        },
      });
    },
    [setChatAsUnread, t]
  );

  const setActiveFilterWithLocalStorage = (id: ChatListType) => {
    setChatListType(id);
    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0);
    }
    if (STORABLE_CHAT_LIST_TYPE.includes(id)) {
      localStorage.setItem(SAVED_LIST_STORAGE_KEY, `${id}`);
    }

    track(ANEventSpace(ANEvent.Selected, ANObject.Filter, ANPage.Messages), {
      filter: id,
    });
  };

  useEffect(() => {
    if (isSearchedByQuery) {
      track(ANEventSpace(ANEvent.Searched, ANObject.Search, ANPage.Messages), {
        searchTerm: debouncedInputValue,
      });
    }
  }, [isSearchedByQuery, debouncedInputValue, track]);

  useEffect(() => {
    if (!chatId) {
      return;
    }
    page(ANSpace(ANObject.List, ANPage.Messages), { filter: chatListType });
  }, []);

  const onNextPage = () =>
    fetchMore({
      variables: {
        type: chatListType,
        from: chatList.length,
        count: CHAT_LIST_DEFAULT_COUNT,
        ...(isSearchedByQuery && { query: debouncedInputValue }),
      },
    }).then((res) => handleDataError(res.data));

  const commonChatlistProps = {
    filters,
    activeFilter: chatListType,
    setActiveFilter: setActiveFilterWithLocalStorage,
    inputValue,
    changeInputValue,
    parentRef: chatListRef,
  };

  const renderContent = () => {
    if (hasChats) {
      return (
        <ScrollPanel
          scrollableNodeProps={{
            id: chatListScrollContainerId,
            ref: chatListScrollContainerRef,
          }}
          style={{ height: '100%', overflow: 'auto' }}
        >
          <InfiniteScroll
            style={{
              height: '100%',
              overflow: 'visible',
            }}
            dataLength={chatList.length}
            next={onNextPage}
            scrollThreshold={0.8}
            hasMore={chatList.length < totalChatsNumber}
            loader={
              <Div display="flex" justifyContent="space-around" pb={6}>
                <Spinner size={10} horizontal />
              </Div>
            }
            scrollableTarget={chatListScrollContainerId}
          >
            {chatList.map((chat) => (
              <ChatListItemV2
                key={chat.node.id}
                chat={chat}
                isUnread={isChatUnread(chat.node)}
                isOpen={(chatId || '') === chat.node.id}
                debouncedInputValue={debouncedInputValue}
                isChatInactive={chatListType === ChatListType.INACTIVE}
                handleChatFavourite={() =>
                  chat.node.job &&
                  handleChatFavourite(
                    chat.node.job,
                    chat.node.latestMessage,
                    chat.node.job.jobBusinessRelationState.isFavourite
                  )
                }
                handleChatDelete={() => handleChatDelete(chat.node)}
                handleChatUnread={() => handleChatUnread(chat.node)}
                containerRef={chatListScrollContainerRef}
              />
            ))}
          </InfiniteScroll>
        </ScrollPanel>
      );
    }
    if (loading && !data)
      return (
        <Div
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          data-testid="content-loader"
        >
          <Spinner size={6} />
        </Div>
      );
    if (errors) return <CustomErrorAlert error={errors} />;
    return <ChatListNotFound withQuery={isSearchedByQuery} />;
  };

  return (
    <ChatListColumn
      maxWidth="375px"
      minWidth="375px"
      width={isMobile ? '100%' : '25%'}
      height="100%"
      overflow="hidden"
      display="flex"
      backgroundColor="black.black0"
      borderRight={`1px solid ${themeContext.colors.black.black3}`}
      p={0}
      className="no-print"
      ref={chatListRef}
    >
      <Div
        width="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        overflow="hidden"
      >
        <ChatListFilterV2 {...commonChatlistProps} />
        {renderContent()}
      </Div>
    </ChatListColumn>
  );
};

export default ChatList;
