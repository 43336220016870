import { bugsnagClient } from '../../utils/initBugsnag';
import type { CreateSlice } from '../store/types';
import { log } from './log';
import type { AuthHandlers } from './login/types';
import { handleCheckIsLoggedIn } from './operations/handleCheckIsLoggedIn';
import { handleConfirmNewPassword } from './operations/handleConfirmNewPassword';
import { handleLoadUser } from './operations/handleLoadUser';
import { handleLoginWithOtp } from './operations/handleLoginWithOtp';
import { handleLoginWithPassword } from './operations/handleLoginWithPassword';
import { handleLoginWithToken } from './operations/handleLoginWithToken';
import { handleLogout } from './operations/handleLogout';
import { handleRequestPasswordlessLogin } from './operations/handleRequestPasswordlessLogin';
import { handleRequestPasswordReset } from './operations/handleRequestPasswordReset';
import { handleRequestUsername } from './operations/handleRequestUsername';
import type { AuthenticationSlice } from './types';

export const createAuthenticationSlice: CreateSlice<AuthenticationSlice> = (
  set,
  get
) => ({
  status: 'undetermined',
  user: null,
  isAdmin: false,
  loginOperationIsLoading: false,
  authHandlers: null,
  defaultRole: null,

  initialize: async (handlers, defaultRole) => {
    log('Initializing authentication store');
    bugsnagClient.leaveBreadcrumb('Initialising', {
      level: 'info',
      category: 'auth',
    });
    set({ authHandlers: handlers, defaultRole });

    const { checkIsLoggedIn } = get();

    await checkIsLoggedIn();
  },

  loadUser: async (errorHandlers) => {
    const { authHandlers, defaultRole } = get();
    const handlers = authHandlers;

    return handleLoadUser(set, handlers, errorHandlers, defaultRole);
  },

  checkIsLoggedIn: async (errorHandlers) => {
    set({ loginOperationIsLoading: true });
    bugsnagClient.leaveBreadcrumb('Checking if logged in', {
      level: 'info',
      category: 'auth',
    });

    await handleCheckIsLoggedIn(set, get, errorHandlers);
    set({ loginOperationIsLoading: false });
  },

  loginWithPassword: async (input, authHandlerOverrides, errorHandlers) => {
    const { authHandlers, defaultRole } = get();
    const handlers = {
      ...authHandlers,
      ...authHandlerOverrides,
    } as AuthHandlers;

    set({ loginOperationIsLoading: true });
    await handleLoginWithPassword(
      set,
      input,
      handlers,
      errorHandlers,
      defaultRole
    );
    set({ loginOperationIsLoading: false });
  },

  loginWithToken: async (input, authHandlerOverrides, errorHandlers) => {
    const { authHandlers, defaultRole } = get();
    const handlers = {
      ...authHandlers,
      ...authHandlerOverrides,
    } as AuthHandlers;

    bugsnagClient.leaveBreadcrumb('Logging in with token', {
      level: 'info',
      category: 'auth',
    });

    set({ loginOperationIsLoading: true });
    await handleLoginWithToken(
      set,
      input,
      handlers,
      errorHandlers,
      defaultRole
    );
    set({ loginOperationIsLoading: false });
  },

  logout: async () => {
    const { authHandlers } = get();

    await handleLogout(set, authHandlers);
  },

  requestPasswordlessLogin: async (input, onSuccess, errorHandlers) => {
    set({ loginOperationIsLoading: true });
    await handleRequestPasswordlessLogin(input, onSuccess, errorHandlers);
    set({ loginOperationIsLoading: false });
  },

  loginWithOtp: async (input, authHandlerOverrides, errorHandlers) => {
    const { authHandlers, defaultRole } = get();
    const handlers = {
      ...authHandlers,
      ...authHandlerOverrides,
    } as AuthHandlers;

    set({ loginOperationIsLoading: true });
    await handleLoginWithOtp(set, input, handlers, errorHandlers, defaultRole);
    set({ loginOperationIsLoading: false });
  },

  requestUsername: async (input, onSuccess, errorHandlers) => {
    set({ loginOperationIsLoading: true });
    await handleRequestUsername(input, onSuccess, errorHandlers);
    set({ loginOperationIsLoading: false });
  },

  requestPasswordReset: async (input, onSuccess, errorHandlers) => {
    set({ loginOperationIsLoading: true });
    await handleRequestPasswordReset(input, onSuccess, errorHandlers);
    set({ loginOperationIsLoading: false });
  },

  confirmNewPassword: async (input, onSuccess, errorHandlers) => {
    set({ loginOperationIsLoading: true });
    await handleConfirmNewPassword(input, onSuccess, errorHandlers);
    set({ loginOperationIsLoading: false });
  },
});
