import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import { NetworkStatus } from '@apollo/client';

import { useCompanyStateQuery } from '../hooks/query/useCompanyState';
import { useUnpaidInvoicesLazyQuery } from '../hooks/query/useUnpaidInvoices';
import useBoolean from '../hooks/useBoolean';
import {
  CompanyStateAlertType,
  getCompanyStateType,
  getCompanyUnpaidOrderState,
} from '../utils/companyState/getCompanyState';
import { disabledClipsAlertStates } from '../utils/companyState/getDisabledClipsAlertStates';
import getPaymentLink from '../utils/companyState/getPaymentLink';
import { CompanySettingsJobAccess } from '../utils/generated/generated';
import { CompanyState } from '../utils/query/CompanyState/CompanyStateQuery';
import { UnpaidInvoice } from '../utils/query/UnpaidInvoices/UnpaidInvoicesQuery';

export enum InvalidCompanyType {
  BlackListed = 'black-listed',
  Deactivated = 'deactivated',
}

export interface CompanyStateAlert {
  status: CompanyStateAlertType | null;
  checkInProgress: boolean;
}

type AlertVisibility = ReturnType<typeof useBoolean>;

export interface CompanyStateContextValue {
  companyState: CompanyState | null;
  unpaidInvoices: UnpaidInvoice[] | null;
  alertStatus: CompanyStateAlert;
  alertVisibility: AlertVisibility;
}

interface CompanyStateContextProps {
  businessId: string;
  children: ReactElement;
  handleMissingData: VoidFunction;
  handleInvalidCompany: (invalidCompanyReason: InvalidCompanyType) => void;
  loaderComponent: ReactElement;
}

export const CompanyStateContext = createContext<
  CompanyStateContextValue | undefined
>(undefined);

const isCompanyStateDeterminedByOrder = (jobAccess: CompanySettingsJobAccess) =>
  jobAccess === CompanySettingsJobAccess.ORDER_DECIDES;

export const CompanyStateProvider: FC<CompanyStateContextProps> = ({
  businessId,
  handleInvalidCompany,
  handleMissingData,
  loaderComponent,
  children,
}) => {
  const alertVisibility = useBoolean(true);
  const { data, loading, networkStatus } = useCompanyStateQuery(businessId);
  const [
    fetchUnpaidInvoices,
    {
      data: unpaidInvoices,
      loading: loadingUnpaidInvoices,
      called: unpaidInvoicesCalled,
    },
  ] = useUnpaidInvoicesLazyQuery();

  useEffect(() => {
    if (loading) return;
    if (!data) {
      handleMissingData();
      return;
    }
    const { isBlacklisted, isDeactivated, isOrderUnpaid, jobAccess } = data;
    if (isDeactivated || isBlacklisted) {
      handleInvalidCompany(
        isBlacklisted
          ? InvalidCompanyType.BlackListed
          : InvalidCompanyType.Deactivated
      );
      return;
    }
    if (isOrderUnpaid && isCompanyStateDeterminedByOrder(jobAccess)) {
      fetchUnpaidInvoices(businessId);
    }
  }, [loading, data]);

  const alertStatus: CompanyStateAlert = useMemo(() => {
    if (!data) {
      return {
        status: null,
        checkInProgress: loading,
      };
    }

    if (!isCompanyStateDeterminedByOrder(data.jobAccess)) {
      return {
        status: null,
        checkInProgress: false,
      };
    }

    const loadingUnpaidOrderDetails =
      (data?.isOrderUnpaid && !unpaidInvoicesCalled) || loadingUnpaidInvoices;
    if (loading || loadingUnpaidOrderDetails) {
      return {
        status: null,
        checkInProgress: true,
      };
    }

    const companyStateType = getCompanyStateType(data);
    const detailedUnpaidState = unpaidInvoices
      ? getCompanyUnpaidOrderState(unpaidInvoices, data.isNew)
      : null;
    return {
      checkInProgress: false,
      status: detailedUnpaidState || companyStateType,
    };
  }, [
    data,
    loading,
    unpaidInvoices,
    loadingUnpaidInvoices,
    unpaidInvoicesCalled,
  ]);

  const value: CompanyStateContextValue = useMemo(() => {
    return {
      companyState: data || null,
      unpaidInvoices,
      alertStatus,
      alertVisibility,
    };
  }, [data, unpaidInvoices, alertStatus, alertVisibility]);

  if (loading && networkStatus !== NetworkStatus.refetch) {
    return loaderComponent;
  }

  return (
    <CompanyStateContext.Provider value={value}>
      {children}
    </CompanyStateContext.Provider>
  );
};

export const useCompanyStateContext = (): CompanyStateContextValue => {
  const context = useContext(CompanyStateContext);
  if (!context) {
    throw new Error('CompanyState context used outside provider');
  }
  return context;
};

export const useCompanyState = (): CompanyState | null => {
  const context = useCompanyStateContext();
  return context.companyState;
};

export const useUnpaidInvoices = (): UnpaidInvoice[] | null => {
  const context = useCompanyStateContext();
  return context.unpaidInvoices;
};

export const useUnpaidInvoiceLink = (): string | null => {
  const unpaidInvoices = useUnpaidInvoices();
  return unpaidInvoices ? getPaymentLink(unpaidInvoices) : null;
};

export const useCompanyStateAlertStatus = (): CompanyStateAlertType | null => {
  const context = useCompanyStateContext();
  return context.alertStatus?.status || null;
};

export const useIsMittanbudXlEligible = (): boolean => {
  const { isMittanbudXlEligible } = useCompanyState() || {};
  return !!isMittanbudXlEligible;
};

export const useCompanyStateAlert = (): CompanyStateAlert => {
  const context = useCompanyStateContext();
  return context.alertStatus;
};

export const useIsClipsAlertDisabledByCompanyState = (): boolean => {
  const companyStateAlert = useCompanyStateAlert();
  return (
    companyStateAlert?.checkInProgress ||
    disabledClipsAlertStates.includes(companyStateAlert?.status)
  );
};

export const useCompanyStateAlertDisplay = (): AlertVisibility => {
  const context = useCompanyStateContext();
  return context.alertVisibility;
};

export const useCompanyStateIsVerifiedWithSecureId = (): boolean => {
  const companyState = useCompanyState();
  return !!companyState?.isVerifiedWithSecureId;
};
