import { EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload } from '../generated/generated';
import { EditJobFolderMutationPayload } from '../mutation/EditJobFolder/EditJobFolderMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class EditJobFolderResponseError extends GQLResponseErrorBase<
  EditJobFolderMutationPayload['editJobFolder'],
  EDIT_JOB_FOLDER_editJobFolder_EditJobFolderPayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'EditJobFolderInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'titleError', 'danger');
        this.mapDataToError(data, 'descriptionError', 'warning');
        this.mapDataToError(data, 'scoreError', 'warning');
        this.mapDataToError(data, 'colorError', 'danger');
        break;

      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
