import { Operation } from '@apollo/client/link/core';
import { RetryLink } from '@apollo/client/link/retry';
import { RetryFunctionOptions } from '@apollo/client/link/retry/retryFunction';
import { APOLLO_RETRY_ON_GQL_ERROR } from '@internals/business-shared/src/utils/constants/apollo';
import log from '@internals/business-shared/src/utils/devLog';
import { t } from '@utils/i18n';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';

const MAX_RETRIES = 4;

/**
 * Error codes can be broadly categorized into two groups:
 * client errors (4xx) and server errors (5xx).
 * Generally, it is appropriate to retry server errors as they might be temporary,
 * while client errors should typically not be retried,
 * as they usually indicate a problem with the request itself.
 */
export const retryIf = (
  error: Error & { statusCode?: number },
  operation?: Operation
) => {
  let isErrorReported = false;
  if (!(error instanceof Error)) {
    const reportedError = new Error(
      'Unknown error received when trying to fetch data'
    );
    bugsnagClient.notify(reportedError);
    isErrorReported = true;
  }

  const doNotRetryCodes = [400, 401, 403, 404, 500];
  const shouldRetryOnGglError =
    !!operation?.getContext()?.[APOLLO_RETRY_ON_GQL_ERROR];
  const shouldRetry =
    (!!error && !doNotRetryCodes.includes(error.statusCode)) ||
    shouldRetryOnGglError;

  if (error.statusCode === 429) {
    log.networking('Too Many Requests (429), increasing delay');
    return true;
  }

  if (error.name === 'AbortError') {
    // AbortError is very intentional and should not be retried
    return false;
  }

  if (!shouldRetry && !isErrorReported) {
    log.error('Non-retriable error:', error);
    ToastMessage(t('general.error.graphQLNetworkError'), 'danger', {
      toastId: 'graphQLNetworkError',
    });
  }

  return shouldRetry;
};

export const customDelayFunction = (
  count: number,
  operation: any,
  error: Error & { statusCode?: number }
) => {
  const baseDelay = count * 1000 + 2000 * Math.random();
  if (error.statusCode === 429) {
    // Increase the delay by a factor of 2 for the 429 error code
    return baseDelay * 2;
  }
  return baseDelay;
};

const attemptsOptions: RetryFunctionOptions = {
  max: MAX_RETRIES,
  retryIf,
};

export const createRetryLink = () =>
  new RetryLink({
    delay: customDelayFunction,
    attempts: attemptsOptions,
  });
