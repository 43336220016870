import { createElement, FC, ReactElement } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ChatJobInfoItem } from '@components/layout/Messages/ChatJobInfoItem';
import { useHidden } from '@internals/business-shared/src/hooks/useHidden';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Text, ButtonProps, Div } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { ShowButton, StyledLink } from './styled';

interface JobActionProps {
  text: string;
  icon: IconPickerName;
  type: 'button' | 'link';
}
interface JobActionButtonProps extends JobActionProps {
  type: 'button';
  buttonProps: ButtonProps;
  linkProps?: never;
}
interface JobActionLinkProps extends JobActionProps {
  type: 'link';
  buttonProps?: never;
  linkProps: {
    href: string;
    target?: string;
    rel?: string;
  };
}

/**
 * Simply return a button or a link with an icon and a text.
 */
export const ChatJobAction = ({
  text,
  icon,
  type,
  buttonProps,
  linkProps = { href: '#' },
}: JobActionLinkProps | JobActionButtonProps): ReactElement => {
  const themeContext = useTheme();
  const componentContent = (
    <Div display="flex" alignItems="center">
      <Div flexShrink={0}>
        <FireIcon iconName={icon} variant="flat" width={20} height={20} />
      </Div>
      <Text.span
        ml={2}
        mb={0}
        fontSize={2}
        fontWeight={themeContext.fontWeights.medium}
        data-testid={`action-link-${icon}`}
      >
        {text}
      </Text.span>
    </Div>
  );
  return type === 'button'
    ? createElement(
        ShowButton,
        {
          variant: 'linkPrimary',
          ...buttonProps,
          'data-testid': `action-button-${icon}`,
        },
        componentContent
      )
    : createElement(
        StyledLink,
        {
          to: linkProps.href,
          key: text,
          target: linkProps.target,
          rel: linkProps.rel,
        },
        componentContent
      );
};

export interface ChatJobInfoActionProps {
  textAction: string;
  textStatic: string;
  icon: IconPickerName;
  onClick: VoidFunction;
}

/**
 *  This is used for displaying a conditional content of the chat job info action.
 */
export const ChatJobInfoAction: FC<ChatJobInfoActionProps> = ({
  icon,
  textAction,
  textStatic,
  onClick,
}) => {
  const [isInfoHidden, showInfo] = useHidden(onClick);

  return !isInfoHidden ? (
    <ChatJobInfoItem text={textStatic} icon={icon} />
  ) : (
    ChatJobAction({
      text: textAction,
      icon,
      type: 'button',
      buttonProps: {
        onClick: showInfo,
      },
    })
  );
};
