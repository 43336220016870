import {
  createContext,
  FC,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import CloseJobConfirmationModal from '@components/layout/Job/JobConfirmationModal';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import useAnalytics from '@hooks/useAnalytics';
import { useIsJobListOpen } from '@hooks/useIsJobListOpen';
import { useJobListActiveId } from '@hooks/useJobListAllFilters';
import { useMapJobListSearchParamsToQueryVariables } from '@hooks/useJobListSearchParams';
import useQuerySearchParams from '@hooks/useQuerySearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import useStorage, { STORE_JOB_INQUIRY_PREFIX } from '@hooks/useStorage';
import { useJobListQuery } from '@internals/business-shared/src/hooks/query/useJobList';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  isJobsListQuerySuccessResponse,
  JobsListItem,
} from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import { isEmptyTrimmedString } from '@internals/business-shared/src/utils/types/StringUtils';
import { Params } from '@router/paths';
import { Div } from '@schibsted-smb/fireball';
import { isEventTriggeredFromInput } from '@utils/isEventTriggeredFromInput';

export type JobNavigationDirection = 'next' | 'previous';

export interface JobNavigationContextValue {
  showNavigation: boolean;
  onPreviousJob: () => void;
  onNextJob: () => void;
  hasNextJob: boolean;
  hasPreviousJob: boolean;
  isNavigationUsed: boolean;
}

interface JobNavigationContextProps {
  children: ReactElement | ReactElement[];
}

export const JobNavigationContext = createContext<
  JobNavigationContextValue | undefined
>(undefined);

export const JobNavigationProvider: FC<JobNavigationContextProps> = ({
  children,
}) => {
  const isJobListOpen = useIsJobListOpen();
  const jobListId = useJobListActiveId();
  const jobId = useQuerySearchParams()[Params.Job]?.[0];
  const jobsListQueryVariables = useMapJobListSearchParamsToQueryVariables();
  const [setSearchParam] = useSetSearchParams();
  const { get: getFromStorage } = useStorage(STORE_JOB_INQUIRY_PREFIX);
  const [
    isCloseJobConfirmationModalOpen,
    showCloseJobConfirmationModal,
    hideCloseJobConfirmationModal,
  ] = useBoolean();
  const [navigationDirection, setNavigationDirection] =
    useState<JobNavigationDirection | null>(null);
  // navigation is not supported on mobile yet
  const isTabletOrMobile = useIsTabletOrMobile();
  // used only for tracking
  const [isNavigationUsed, setIsNavigationUsed] = useBoolean();
  const { track } = useAnalytics();

  const { data, fetchMore, previousData } = useJobListQuery({
    fetchPolicy: 'cache-first',
    variables: { ...jobsListQueryVariables, listId: jobListId },
  });
  const { page: initialPage } = jobsListQueryVariables;
  const [isFetchingMoreItems, setFetchingMoreItems, setStopFetchingMoreItems] =
    useBoolean();
  // keep track of fetched pages - user can open job on i.e. 4th page and start browsing through multiple pages back and forth
  const [latestFetchedStartPage, setLatestFetchedStartPage] =
    useState<number>(initialPage);
  const [latestFetchedNextPage, setLatestFetchedNextPage] =
    useState<number>(initialPage);

  const totalPages = data?.jobConnection?.pageInfo?.totalPages ?? 0;
  const hasNextPage = totalPages > latestFetchedNextPage;
  const nextPage = latestFetchedNextPage + 1;
  const hasPreviousPage = latestFetchedStartPage > 1;
  const previousPage = latestFetchedStartPage - 1;

  const [jobListItems, setJobListItems] = useState<JobsListItem[]>([]);
  // initial page data loading
  useEffect(() => {
    if (data && !previousData) {
      const jobs = data.jobConnection.edges.map((edge) => edge.node);
      setJobListItems(jobs);
    }
  }, [data, previousData]);

  const jobIndexOnList = useMemo(
    () => jobListItems.findIndex((job) => job.id === jobId),
    [jobListItems, jobId]
  );

  const nextItem = useMemo(() => {
    if (!jobListItems.length) return null;
    if (jobIndexOnList === -1) return jobListItems[0];
    const nextJob = jobListItems[jobIndexOnList + 1];
    return nextJob ?? null;
  }, [jobListItems, jobIndexOnList]);

  const previousItem = useMemo(() => {
    if (!jobListItems.length || jobIndexOnList === -1) return null;
    const previousJob = jobListItems[jobIndexOnList - 1];
    return previousJob ?? null;
  }, [jobListItems, jobIndexOnList]);

  const fetchNextItems = async () => {
    setFetchingMoreItems();
    const nextListData = await fetchMore({ variables: { page: nextPage } });
    if (
      nextListData?.data &&
      isJobsListQuerySuccessResponse(nextListData.data.jobList)
    ) {
      const nextItems = nextListData.data.jobList.jobConnection?.edges.map(
        (edge) => edge.node
      );
      setJobListItems((prev) => {
        return [...prev, ...nextItems];
      });
      setLatestFetchedNextPage(nextPage);
    }
    setStopFetchingMoreItems();
  };

  const fetchPreviousItems = async () => {
    setFetchingMoreItems();
    const previousListData = await fetchMore({
      variables: { page: previousPage },
    });
    if (
      previousListData?.data &&
      isJobsListQuerySuccessResponse(previousListData.data.jobList)
    ) {
      const previousItems =
        previousListData.data.jobList.jobConnection?.edges.map(
          (edge) => edge.node
        );
      setJobListItems((prev) => [...previousItems, ...prev]);
      setLatestFetchedStartPage(previousPage);
    }
    setStopFetchingMoreItems();
  };

  // start fetching next jobs when user is visiting last loaded job
  useEffect(() => {
    if (
      jobListItems.length &&
      !nextItem &&
      hasNextPage &&
      !isFetchingMoreItems
    ) {
      fetchNextItems();
    }
  }, [
    nextItem,
    hasNextPage,
    jobListItems.length,
    fetchNextItems,
    isFetchingMoreItems,
  ]);

  // start fetching previous jobs when user is visiting first loaded job
  useEffect(() => {
    if (
      jobListItems.length &&
      !previousItem &&
      hasPreviousPage &&
      !isFetchingMoreItems
    ) {
      fetchPreviousItems();
    }
  }, [
    previousItem,
    hasPreviousPage,
    jobListItems.length,
    fetchPreviousItems,
    isFetchingMoreItems,
  ]);

  const onNextJob = useCallback(() => {
    setIsNavigationUsed();
    track(ANEventSpace(ANEvent.Clicked, ANObject.NextCard, ANPage.JobCard));
    setSearchParam(Params.Job, nextItem?.id ?? null);
  }, [nextItem?.id]);

  const onPreviousJob = useCallback(() => {
    setIsNavigationUsed();
    track(ANEventSpace(ANEvent.Clicked, ANObject.PreviousCard, ANPage.JobCard));
    setSearchParam(Params.Job, previousItem?.id ?? null);
  }, [previousItem?.id]);

  const onNavigateToAnotherJob = useCallback(
    (direction: JobNavigationDirection) => {
      const isJobAnswerInProgress = !isEmptyTrimmedString(
        getFromStorage(jobId)?.data?.message ?? ''
      );
      if (isJobAnswerInProgress) {
        showCloseJobConfirmationModal();
        setNavigationDirection(direction);
        return;
      }
      setNavigationDirection(null);
      if (direction === 'next') {
        onNextJob();
      } else {
        onPreviousJob();
      }
    },
    [onPreviousJob, onNextJob]
  );

  const onConfirmNavigationToOtherJob = useCallback(() => {
    if (!navigationDirection) return;
    hideCloseJobConfirmationModal();
    if (navigationDirection === 'next') {
      onNextJob();
      return;
    }
    onPreviousJob();
  }, [navigationDirection, onNextJob, onPreviousJob]);

  const showNavigation = isJobListOpen && !isTabletOrMobile;

  const navigateToNextJob = useCallback(() => {
    if (!nextItem) return;
    onNavigateToAnotherJob('next');
  }, [nextItem, onNavigateToAnotherJob]);

  const navigateToPreviousJob = useCallback(() => {
    if (!previousItem) return;
    onNavigateToAnotherJob('previous');
  }, [previousItem, onNavigateToAnotherJob]);

  const navigateWithKeyboard: KeyboardEventHandler<HTMLDivElement> =
    useCallback(
      (event) => {
        const { target, key } = event;
        if (!showNavigation || isEventTriggeredFromInput(target)) {
          return;
        }
        switch (key) {
          case 'ArrowLeft':
            navigateToPreviousJob();
            break;
          case 'ArrowRight':
            navigateToNextJob();
            break;
          default:
            break;
        }
      },
      [navigateToPreviousJob, navigateToNextJob, showNavigation]
    );

  const value: JobNavigationContextValue = useMemo(
    () => ({
      hasPreviousJob: !!previousItem,
      hasNextJob: !!nextItem,
      showNavigation,
      onNextJob: navigateToNextJob,
      onPreviousJob: navigateToPreviousJob,
      isNavigationUsed,
    }),
    [
      previousItem?.id,
      nextItem?.id,
      navigateToNextJob,
      navigateToPreviousJob,
      isNavigationUsed,
      showNavigation,
    ]
  );

  return (
    <JobNavigationContext.Provider value={value}>
      <Div onKeyDown={navigateWithKeyboard}>{children}</Div>
      <CloseJobConfirmationModal
        isConfirmationModalOpen={isCloseJobConfirmationModalOpen}
        onCloseConfirmationModal={hideCloseJobConfirmationModal}
        onClose={onConfirmNavigationToOtherJob}
      />
    </JobNavigationContext.Provider>
  );
};

export const useJobNavigationContext = (): JobNavigationContextValue => {
  const context = useContext(JobNavigationContext);
  if (!context) {
    throw new Error('JobNavigationContext used outside provider');
  }
  return context;
};
