import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTheme } from 'styled-components';

export enum Device {
  Smartphone = 'SMARTPHONE',
  Tablet = 'TABLET',
  Desktop = 'DESKTOP',
  Widescreen = 'WIDESCREEN',
}

export const DeviceSizeContext = createContext<Device | undefined>(undefined);

export const DeviceSizeProvider: FC<PropsWithChildren> = ({ children }) => {
  const themeContext = useTheme();

  // breakpoints ['321px', '376px', '415px', '576px', '769px', '992px', '1200px'];

  const wideScreenQuery = window.matchMedia(
    `(min-width: ${themeContext.breakpoints[6]})`
  );
  const desktopQuery = window.matchMedia(
    `(min-width: ${themeContext.breakpoints[5]})`
  );
  const tabletQuery = window.matchMedia(
    `(min-width: ${themeContext.breakpoints[4]})`
  );

  const getCurrentDevice = () => {
    if (wideScreenQuery.matches) return Device.Widescreen;
    if (desktopQuery.matches) return Device.Desktop;
    if (tabletQuery.matches) return Device.Tablet;
    return Device.Smartphone;
  };

  const [device, setDevice] = useState<Device>(getCurrentDevice());

  const updateActiveDevice = () => {
    setDevice(getCurrentDevice());
  };

  useEffect(() => {
    tabletQuery.addEventListener('change', updateActiveDevice);
    desktopQuery.addEventListener('change', updateActiveDevice);
    wideScreenQuery.addEventListener('change', updateActiveDevice);

    return () => {
      tabletQuery.removeEventListener('change', updateActiveDevice);
      desktopQuery.removeEventListener('change', updateActiveDevice);
      wideScreenQuery.removeEventListener('change', updateActiveDevice);
    };
  }, []);

  return (
    <DeviceSizeContext.Provider value={device}>
      {children}
    </DeviceSizeContext.Provider>
  );
};

export const useDeviceContext = (): Device => {
  const deviceSizeContext = useContext(DeviceSizeContext);
  if (!deviceSizeContext) {
    throw new Error('DeviceSize context used outside provider');
  }
  return deviceSizeContext;
};

export const useIsMobile = (): boolean => {
  const device = useDeviceContext();
  return device === Device.Smartphone;
};

export const useIsTablet = (): boolean => {
  const device = useDeviceContext();
  return device === Device.Tablet;
};

export const useIsTabletOrMobile = (): boolean => {
  const device = useDeviceContext();
  return device === Device.Smartphone || device === Device.Tablet;
};

export const useIsWidescreen = (): boolean => {
  const device = useDeviceContext();
  return device === Device.Widescreen;
};
