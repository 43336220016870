import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import LanguagePicker from '@components/elements/LanguagePicker';
import Navigation, { NavigationModel } from '@components/elements/Navigation';
import { Statistics } from '@components/layout/Jobs/Statistics';
import Profile from '@components/layout/Settings/Profile';
import withNavigationLayout from '@components/layout/WithNavigation';
import { useIsMobile, useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useIsMultiAccountUser } from '@contexts/UserContext';
import { ANObject } from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import { Div, Divider } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface SettingsNavigationProps {
  isFirstDefaultActive: boolean;
}

export const SettingsNavigation: FC<SettingsNavigationProps> = ({
  isFirstDefaultActive,
}) => {
  const { t } = useTranslation();
  const showAccountSwitchLink = useIsMultiAccountUser();
  const isTabletOrMobile = useIsTabletOrMobile();
  const showIntegrations = useFlagEnabled(FeatureFlags.BizSettingsIntegrations);

  const settingsModel: NavigationModel[] = [
    {
      link: Paths.SettingsProfile,
      icon: IconPickerName.CompanyInfo,
      label: t('settings.profile.title'),
      analyticsObject: ANObject.None,
      testId: 'COMPANY_INFO',
    },
    {
      link: Paths.SettingsNotifications,
      icon: IconPickerName.Notifications,
      label: t('settings.notifications.title'),
      analyticsObject: ANObject.None,
      testId: 'NOTIFICATIONS',
    },
    {
      link: Paths.SettingsJobs,
      icon: IconPickerName.JobSearch,
      label: t('settings.jobSearch.title'),
      analyticsObject: ANObject.None,
      testId: 'JOB_SEARCH',
    },
    {
      link: Paths.SettingsProducts,
      icon: IconPickerName.Products,
      label: t('settings.products.title'),
      analyticsObject: ANObject.None,
      testId: 'PRODUCTS',
    },
    {
      link: Paths.SettingsPayment,
      icon: IconPickerName.Receipts,
      label: t('settings.receipts.title'),
      analyticsObject: ANObject.None,
      testId: 'RECEIPTS',
    },
    {
      link: Paths.SettingsCustomerService,
      icon: IconPickerName.Support,
      label: t('settings.customerService.title'),
      analyticsObject: ANObject.CustomerService,
      testId: 'SUPPORT',
    },
    ...(isTabletOrMobile
      ? [
          {
            link: Paths.SettingsPrivacyAndTerms,
            icon: IconPickerName.Fingerprint,
            label: t('settings.privacyAndTerms.title'),
            analyticsObject: ANObject.None,
            testId: 'PRIVACY_AND_TERMS',
          },
        ]
      : []),
    ...(showIntegrations
      ? [
          {
            link: Paths.SettingsIntegrations,
            icon: IconPickerName.Globe,
            label: t('settings.integrations.title'),
            analyticsObject: ANObject.Integrations,
            testId: 'INTEGRATIONS',
          },
        ]
      : []),
    ...(showAccountSwitchLink
      ? [
          {
            link: Paths.AccountSwitch,
            icon: IconPickerName.Profile,
            label: t('settings.switchAccount.title'),
            testId: 'PROFILE',
          },
        ]
      : []),
    {
      link: Paths.Logout,
      icon: IconPickerName.SignOut,
      label: t('settings.logout'),
      analyticsObject: ANObject.LogOut,
      testId: 'SIGN_OUT',
    },
  ];

  return (
    <Navigation
      navigationItems={settingsModel}
      isFirstDefaultActive={isFirstDefaultActive}
      isMobileGray
    />
  );
};

export const Settings: FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const isPillNavigationEnabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchPillsView
  );

  return withNavigationLayout({
    Navigation: () => SettingsNavigation({ isFirstDefaultActive: true }),
    pageName: t('settings.title'),
    isMainPage: true,
    pageDetails: {
      PageContent: Profile,
    },
    ...(isMobile &&
      isPillNavigationEnabled && {
        aboveNavigation: <Statistics />,
        belowNavigation: (
          <>
            <Divider />
            <Div mx={4} my={5}>
              <LanguagePicker />
            </Div>
          </>
        ),
      }),
  })();
};
