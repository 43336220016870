import { FC, useMemo } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Column, Div, Text } from '@schibsted-smb/fireball';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const expirationDate = dayjs()
  .add(2, 'month')
  .startOf('month')
  .format('DD.MM.YY');

export const CheckList: FC = () => {
  const { t } = useTranslation();

  const listItems = useMemo(
    () => [
      t('job.modal.boost.checkList.checkItem1'),
      t('job.modal.boost.checkList.checkItem2'),
      t('job.modal.boost.checkList.checkItem3', { date: expirationDate ?? 0 }),
    ],
    [t]
  );

  return (
    <Column px={0}>
      {listItems.map((text, index) => (
        <Div key={text} display="flex" mb={5}>
          <Div mr={5}>
            <FireIcon
              width={24}
              height={24}
              iconName={IconPickerName.CheckmarkRounded}
              fill="black.black0"
            />
          </Div>
          <Text.p
            color="black.black0"
            fontSize={2}
            fontWeight={index === listItems.length - 1 ? 'bold' : 'regular'}
          >
            {text}
          </Text.p>
        </Div>
      ))}
    </Column>
  );
};
