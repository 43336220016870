import { KeyboardEvent } from 'react';

interface keyboardListNavigationParams {
  setIndex: (selectedIndex: number) => void;
  listLength: number;
  onEnter: (selectedIndex: number) => void;
  onEscape: (selectedIndex: number) => void;
}

interface keyboardListNavigate {
  <T>(event: KeyboardEvent<T>, currentIndex: number): void;
}

export const getKeyboardListNavigation: (
  params: keyboardListNavigationParams
) => keyboardListNavigate = ({ setIndex, listLength, onEnter, onEscape }) => {
  return <T>(event: KeyboardEvent<T>, currentIndex?: number) => {
    const maxIndex = listLength - 1;
    switch (event.key) {
      case 'Escape':
        onEscape(currentIndex);
        break;
      case 'Enter':
        onEnter(currentIndex);
        break;
      case 'ArrowDown':
        setIndex(
          currentIndex === undefined || currentIndex === maxIndex
            ? 0
            : currentIndex + 1
        );
        break;
      case 'ArrowUp':
        setIndex(
          currentIndex === undefined || currentIndex === 0
            ? maxIndex
            : currentIndex - 1
        );
        break;
      default:
        break;
    }
  };
};
