import * as React from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import AreasAccordionTag from '@components/elements/AreasAccordionTag';
import SettingsSection from '@components/elements/SettingsSection';
import ContentGridContainer from '@components/layout/Settings/Jobs/styled/ContentGridContainer';
import { UserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  updateBusinessDistrictsUpdate,
  updateBusinessDistrictsOptimisticResponse,
} from '@internals/business-shared/src/cache/updates/updateBusinessDistricts';
import {
  updateBusinessMunicipalitiesOptimisticResponse,
  updateBusinessMunicipalitiesUpdate,
} from '@internals/business-shared/src/cache/updates/updateBusinessMunicipalities';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  allDistrictsInMunicipalityActive,
  anyDistrictInMunicipalityActive,
  CountiesMunicipality,
  DistrictType,
  getCountiesWithMunicipalities,
  getDistrictIds,
  getMunicipalityCodes,
  isCountyActive,
  isDistrictActive,
  isMunicipalityActive,
} from '@internals/business-shared/src/utils/areasUtils';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  onCompleted,
  refetchQueries,
} from '@internals/business-shared/src/utils/mutation-options/JobSearchSettingsMutationOptions';
import {
  UPDATE_BUSINESS_DISTRICTS,
  UpdateBusinessDistrictsPayload,
  UpdateBusinessDistrictsVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusinessDistricts/UpdateBusinessDistricts';
import {
  UPDATE_BUSINESS_MUNICIPALITIES,
  UpdateBusinessMunicipalitiesMutationPayload,
  UpdateBusinessMunicipalitiesMutationVariables,
} from '@internals/business-shared/src/utils/mutation/UpdateBusinessMunicipalities/UpdateBusinessMunicipalities';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { Div, Toggle, Text, Accordion, Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export interface AreasAccordionProps {
  municipalitiesAvailable: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalitiesAvailable[];
  municipalitiesEnabled: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities[];
  districtsAvailable: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable[];
  districtsEnabled: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts[];
}

const AreasAccordion: React.FC<
  React.PropsWithChildren<AreasAccordionProps>
> = ({
  municipalitiesAvailable,
  municipalitiesEnabled,
  districtsAvailable,
  districtsEnabled,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const user = React.useContext(UserContext);
  const { cache } = useApolloClient();
  const [expandedCountyRows, setExpandedCountyRows] = React.useState<string[]>(
    []
  );
  const onExpandCountyRow = (
    expandStatus: { id: string; isExpanded: boolean }[]
  ) => {
    const expandedRows = expandStatus.filter((item) => item.isExpanded);
    setExpandedCountyRows(expandedRows.map((row) => row.id));
  };

  const [updateBusinessMunicipalities] = useMutation<
    UpdateBusinessMunicipalitiesMutationPayload,
    UpdateBusinessMunicipalitiesMutationVariables
  >(UPDATE_BUSINESS_MUNICIPALITIES, {
    onCompleted: () => onCompleted(cache),
    refetchQueries,
  });
  const [updateBusinessDistricts] = useMutation<
    UpdateBusinessDistrictsPayload,
    UpdateBusinessDistrictsVariables
  >(UPDATE_BUSINESS_DISTRICTS, {
    onCompleted: () => onCompleted(cache),
    refetchQueries,
  });
  const countiesWithMunicipalities = getCountiesWithMunicipalities(
    municipalitiesAvailable,
    districtsAvailable
  );

  const selectedMunicipalitiesCodes = React.useMemo(() => {
    return getMunicipalityCodes(municipalitiesEnabled);
  }, [municipalitiesEnabled]);

  const selectedDistrictsIds = React.useMemo(() => {
    return getDistrictIds(districtsEnabled);
  }, [districtsEnabled]);

  const handleOnDistrictChange = (
    district: DistrictType,
    municipality: CountiesMunicipality,
    isActive: boolean
  ) => {
    const updatedActiveDistricts = toggleItemInArray(
      selectedDistrictsIds,
      district.id,
      isActive
    );
    track(ANEventSpace(ANEvent.Changed, ANObject.JobArea, ANPage.Settings));

    const isMunicipalityNowActive = allDistrictsInMunicipalityActive(
      municipality,
      updatedActiveDistricts
    );
    const isMunicipalityValueChanged =
      isMunicipalityNowActive !==
      selectedMunicipalitiesCodes.includes(municipality.code);
    const updatedMunicipality = isMunicipalityValueChanged
      ? { id: municipality.id, enabled: isMunicipalityNowActive }
      : undefined;

    updateBusinessDistricts({
      variables: {
        toggleDistricts: [{ districtId: district.id, enable: isActive }],
      },
      update: updateBusinessDistrictsUpdate(
        user?.operatingAs?.id ?? '',
        updatedMunicipality
      ),
      optimisticResponse: updateBusinessDistrictsOptimisticResponse(
        districtsEnabled,
        { id: district.id, name: district.name },
        isActive
      ),
    });
  };

  const handleOnMunicipalityChange = (
    municipalities: CountiesMunicipality[],
    isActive: boolean
  ) => {
    track(ANEventSpace(ANEvent.Changed, ANObject.JobArea, ANPage.Settings));
    updateBusinessMunicipalities({
      variables: {
        toggleMunicipalities: municipalities.map((municipality) => ({
          municipalityId: municipality.id,
          enable: isActive,
        })),
      },
      update: updateBusinessMunicipalitiesUpdate(
        user?.operatingAs?.id ?? '',
        municipalities.map((m) => m.id),
        isActive
      ),
      optimisticResponse: updateBusinessMunicipalitiesOptimisticResponse(
        municipalitiesEnabled,
        municipalities,
        isActive
      ),
    });
  };

  return (
    <SettingsSection
      icon={IconPickerName.Areas}
      title={t('settings.jobSearch.areasTitle')}
    >
      <Text.span mb="35px" color="black.black9" lineHeight={1.5}>
        {t('settings.jobSearch.areasBaseText')}
      </Text.span>
      {countiesWithMunicipalities && (
        <Accordion
          colorContext="light"
          onExpandChange={onExpandCountyRow}
          items={countiesWithMunicipalities.map((county) => {
            const countyActive = isCountyActive(
              county,
              selectedMunicipalitiesCodes
            );
            const isRowExpanded = expandedCountyRows.includes(county.id);
            return {
              key: county.id,
              id: county.id,
              rowLeftLabel: county.name,
              rowRightLabel: (
                <>
                  {isRowExpanded ? (
                    <Button
                      variant="linkPrimary"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOnMunicipalityChange(
                          county.municipalities,
                          !countyActive
                        );
                      }}
                      p={0}
                    >
                      {countyActive
                        ? t('settings.jobSearch.deselectAll')
                        : t('settings.jobSearch.selectAll')}
                    </Button>
                  ) : (
                    <AreasAccordionTag
                      all={county.municipalities.length}
                      active={
                        county.municipalities.filter((m) =>
                          isMunicipalityActive(m, selectedMunicipalitiesCodes)
                        ).length
                      }
                      isPartial={county.municipalities.some((m) =>
                        anyDistrictInMunicipalityActive(m, selectedDistrictsIds)
                      )}
                    />
                  )}
                </>
              ),
              expandedRowContent: (
                <ContentGridContainer>
                  {county.municipalities.map((municipality) => {
                    const isActive = isMunicipalityActive(
                      municipality,
                      selectedMunicipalitiesCodes
                    );
                    return (
                      <Div key={municipality.id}>
                        <Div
                          key={municipality.id}
                          padding="10px"
                          margin="0 -10px"
                          height="45px"
                          display="flex"
                          justifyContent="space-between"
                        >
                          <Div
                            data-testid={`municipality-name-${municipality.name}`}
                          >
                            {municipality.name}
                          </Div>
                          <Toggle
                            name={municipality.name}
                            checked={isActive}
                            onChange={() =>
                              handleOnMunicipalityChange(
                                [municipality],
                                !isActive
                              )
                            }
                            testId={`municipality-${municipality.name}`}
                          />
                        </Div>
                        {municipality.districts.map((district) => {
                          const isActiveDistrict = isDistrictActive(
                            district,
                            selectedDistrictsIds
                          );
                          return (
                            <Div
                              key={district.id}
                              padding="10px"
                              margin="0 -10px"
                              height="45px"
                              display="flex"
                              justifyContent="space-between"
                            >
                              <Div>{district.name}</Div>
                              <Toggle
                                scale="xs"
                                checked={isActiveDistrict}
                                name={district.name}
                                onChange={() =>
                                  handleOnDistrictChange(
                                    district,
                                    municipality,
                                    !isActiveDistrict
                                  )
                                }
                                testId={`district-${district.name}`}
                              />
                            </Div>
                          );
                        })}
                      </Div>
                    );
                  })}
                </ContentGridContainer>
              ),
            };
          })}
          testId="areas"
        />
      )}
    </SettingsSection>
  );
};

export default AreasAccordion;
