import { FC, useState } from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import { SampleImageType } from '@components/layout/Profile/ProfileGraphic/BackgroundImage/SampleImagesGallery';
import { SAMPLE_PROFILE_BACKGROUND_SOURCE_URL } from '@config/index';
import { useUserBusinessId } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  useUpdateProfileBackground,
  checkForUpdateBackgroundErrors,
} from '@hooks/useUpdateProfileBackground';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { useCloudinaryAssets } from '@internals/business-shared/src/hooks/useCloudinaryAssets';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  FILE_UPLOAD_TOTAL_SIZE_LIMIT,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
  FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
  IMAGE_FILE_EXTENSIONS,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { Div, Button, Dropzone, Heading, Modal } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { convertAssetUrlToFile } from '@utils/uploadFiles';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { SampleImages } from './SampleImages';
import { SelectedImagePreview } from './SelectedImagePreview';

export interface DropzoneFile {
  file: File;
  previewUrl: string;
}

export interface SelectedDropzoneFile extends DropzoneFile {
  id: string;
  variant: 'dropzoneImage';
}

export interface SelectedSampleFile extends SampleImageType {
  variant: 'sampleImage';
}

type SelectedFile = SelectedDropzoneFile | SelectedSampleFile;

interface SelectImageModalProps {
  isOpen: boolean;
  activeBackgroundImage: string;
  onClose: VoidFunction;
  onImageChanged: (bgImage: DropzoneFile) => void;
}

export const SelectImageModal: FC<SelectImageModalProps> = ({
  isOpen,
  onClose,
  onImageChanged,
  activeBackgroundImage,
}) => {
  const themeContext = useTheme();
  const businessId = useUserBusinessId();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [errors, setErrors] = useState<GQLErrorState>({});
  const [backgroundImage, setBackgroundImage] = useState<
    SelectedFile | undefined
  >();
  const [
    savingImageInProgress,
    startSavingImageProgress,
    stopSavingImageProgress,
  ] = useBoolean();
  const sampleImages = useCloudinaryAssets(
    SAMPLE_PROFILE_BACKGROUND_SOURCE_URL
  );
  const {
    updateBackground,
    error: updateBackgroundError,
    clearError: clearUpdateBackgroundError,
  } = useUpdateProfileBackground();

  const handleDropzoneImageSelect = (dropzoneFile: DropzoneFile) => {
    setBackgroundImage({
      ...dropzoneFile,
      id: dropzoneFile.file.name,
      variant: 'dropzoneImage',
    });
  };

  const handleSampleImageSelect = (image: SampleImageType) => {
    setBackgroundImage({ ...image, variant: 'sampleImage' });
  };

  const convertSampleImageToFile = async (
    image: SampleImageType
  ): Promise<File | undefined> => {
    try {
      const file = await convertAssetUrlToFile(image);
      setErrors({});
      return file;
    } catch (e) {
      setErrors({
        general: {
          msg: t('profile.graphic.backgroundImage.sampleImages.selectError'),
          variant: 'danger',
        },
      });
      bugsnagClient.notify(
        `Could not select sample profile background image, error: ${e}`
      );
      return undefined;
    }
  };

  const getImageToSave = async (image: SelectedFile) => {
    const file =
      image.variant === 'sampleImage'
        ? await convertSampleImageToFile(image)
        : image.file;
    return file;
  };

  const handleOnClose = () => {
    onClose();
    setErrors({});
    clearUpdateBackgroundError();
    // prevent previous image pop up during modal close animation
    setTimeout(() => setBackgroundImage(undefined), 500);
  };

  const handleOnSave = async () => {
    if (!backgroundImage) return;
    startSavingImageProgress();
    const file = await getImageToSave(backgroundImage);
    if (file) {
      track(ANEventSpace(ANEvent.Clicked, ANObject.SaveDesign, ANPage.Profile));
      const updateResult = await updateBackground(file, businessId);

      stopSavingImageProgress();
      if (!checkForUpdateBackgroundErrors(updateResult?.data)) {
        onImageChanged({ file, previewUrl: backgroundImage.previewUrl });
        handleOnClose();
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      isClosable
      header={
        <Div
          pl={7}
          pb={5}
          borderBottom={`1px solid ${themeContext.colors.black.black3}`}
          width="100%"
          textAlign="left"
        >
          <Heading.h3>{t('profile.graphic.backgroundImage.header')}</Heading.h3>
        </Div>
      }
      testId="profile-background-image"
      headerProps={{
        p: 0,
        m: 0,
      }}
      contentProps={{
        pt: 6,
        pb: 0,
        px: 7,
      }}
      footerProps={{ mt: 7, mb: 0 }}
      footer={
        <Button
          m="0 auto"
          variant="primary"
          isLoading={savingImageInProgress}
          onClick={handleOnSave}
          data-testid="profile-background-image-save-btn"
        >
          {t('general.label.save')}
        </Button>
      }
    >
      <SelectedImagePreview
        imageSrc={backgroundImage?.previewUrl || activeBackgroundImage}
      />
      <Div height="200px">
        <Dropzone
          files={backgroundImage ? [backgroundImage] : []}
          setFiles={handleDropzoneImageSelect}
          dropzoneType="grid"
          isImagePreviewEnabled={false}
          maxFiles={FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT}
          multiple={false}
          allowedFiles={{
            'image/*': IMAGE_FILE_EXTENSIONS,
          }}
          maxSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          invalidSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidTypeErrorMessage={t('component.dropzone.invalidFileTypeError')}
          invalidMaxFilesErrorMessage={t('component.dropzone.maxFilesError', {
            maxFiles: FILE_UPLOAD_MAX_FILES_SINGLE_LIMIT,
          })}
          dropzoneGridUploadInfo={t('component.dropzone.gridUploadInfo')}
          dropzoneGridEmptyUploadInfo={t(
            'component.dropzone.chooseOrDragFiles'
          )}
          testId="profile-background-image"
          fullheight
        />
      </Div>
      <ErrorAlert errors={errors} />
      <ErrorAlert errors={updateBackgroundError} />
      <SampleImages
        imagesFetchResult={sampleImages}
        onImageSelect={handleSampleImageSelect}
        selectedImageId={backgroundImage?.id}
      />
    </Modal>
  );
};
