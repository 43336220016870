import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const MessageImagesContainer = styled(Div)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export default MessageImagesContainer;
