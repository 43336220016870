import * as React from 'react';

import SimpleBar from 'simplebar-react';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/dist/simplebar.min.css';
import './simplebar-override.css';

interface ScrollPanelProps extends React.HTMLAttributes<HTMLElement> {
  scrollableNodeProps?: Record<string, unknown>;
  options?: Record<string, unknown>;
  autoHide?: boolean;
  // forceVisible?: string
  // direction?: string
  // timeout?: number
  // clickOnTrack?: boolean
  // scrollbarMinSize?: number
  // scrollbarMaxSize?: number
}

const ScrollPanel: React.FC<React.PropsWithChildren<ScrollPanelProps>> = ({
  children,
  ...simpleBarOptions
}) => {
  return (
    <SimpleBar
      {...simpleBarOptions}
      autoHide={simpleBarOptions.autoHide}
      forceVisible={false}
      direction="ltr"
      clickOnTrack
      scrollbarMinSize={25}
      scrollbarMaxSize={0}
    >
      {children}
    </SimpleBar>
  );
};

export default ScrollPanel;
