import { Button, Text } from '@schibsted-smb/fireball';
import { Theme } from '@schibsted-smb/fireball-themes';
import styled, { css } from 'styled-components';

const getItemStyles = ({ theme }: { theme: Theme }) => css`
  font-size: ${theme.fontSizes[2]};
  color: ${theme.colors.black.black9};
  margin-bottom: 0;
  word-break: break-word;
  white-space: pre-line;
`;

export const JobDataItemText = styled(Text.p)<{ disabled?: boolean }>`
  ${({ theme }) => getItemStyles({ theme })};
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.black.black6 : theme.colors.black.black9};
`;

export const JobDataHiddenItemText = styled(Button)`
  ${({ theme }) => getItemStyles({ theme })};
  padding: 0;
  font-weight: normal;
`;
