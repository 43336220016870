import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledAlertNumber = styled(Text.span)`
  display: inline;
  font-weight: bold;
  margin-bottom: 0;
`;

export default StyledAlertNumber;
