import { FC } from 'react';

import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  getEvaluationRequestStatus,
  EvaluationRequestStatus,
} from '@internals/business-shared/src/utils/evaluationRequestStatus';
import { ChatJob } from '@internals/business-shared/src/utils/query/ChatJob/ChatJobQuery';
import { useTranslation } from 'react-i18next';

import { ChatJobAction } from './ChatJobInfoAction';

interface ChatEvaluationRequestProps {
  job: ChatJob;
  onClick: VoidFunction;
}

const ChatEvaluationRequest: FC<ChatEvaluationRequestProps> = ({
  job,
  onClick,
}) => {
  const { t } = useTranslation();
  if (!job || !job.jobBusinessRelationState.isWon) return null;

  const isJobOwnerDeleted = !!job.owner?.isDeleted;
  const evaluationRequestStatus = getEvaluationRequestStatus(job);

  const text = ((evaluationStatus: EvaluationRequestStatus) => {
    if (isJobOwnerDeleted) return t('chat.item.evaluation.deleted');
    switch (evaluationStatus) {
      case EvaluationRequestStatus.Completed:
        return t('evaluation.item.request.completed');
      case EvaluationRequestStatus.Sent:
        return t('chat.item.evaluation.sent');
      case EvaluationRequestStatus.None:
      default:
        return t('general.action.requestEvaluation');
    }
  })(evaluationRequestStatus);

  return ChatJobAction({
    text,
    icon: IconPickerName.Evaluation,
    type: 'button',
    buttonProps: {
      onClick,
      disabled:
        evaluationRequestStatus !== EvaluationRequestStatus.None ||
        isJobOwnerDeleted,
    },
  });
};

export default ChatEvaluationRequest;
