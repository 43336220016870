import useStorage from '@hooks/useStorage';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';

interface DisplayOnce {
  isDisplayed: boolean;
  display: () => void;
  hide: () => void;
  removeDisplayStoreRecord: () => void;
}

export const useDisplayOnce = (
  recordId: string,
  storagePrefix: string
): DisplayOnce => {
  const storeRecord = useStorage(storagePrefix);
  const [isDisplayed, display, hide] = useBoolean();

  const displayElement = () => {
    const displayedBefore = storeRecord.get(recordId)?.data?.closed;
    if (!displayedBefore) {
      display();
    }
  };

  const hideElement = () => {
    hide();
    storeRecord.set(recordId, { closed: true });
  };

  const removeDisplayStoreRecord = () => {
    storeRecord.remove(recordId);
  };

  return {
    isDisplayed,
    display: displayElement,
    hide: hideElement,
    removeDisplayStoreRecord,
  };
};
