import { FC, ReactElement } from 'react';

import {
  StyledDetails,
  StyledSummary,
} from '@components/base/Details/styled/StyledDetails';
import { DefaultComposeProps } from '@schibsted-smb/fireball';

interface DetailsProps extends DefaultComposeProps {
  open?: boolean;
  name?: string;
  backgroundColor?: string;
  border?: string;
  space?: number;
  summary: ReactElement;
  children: ReactElement;
}

export const Details: FC<DetailsProps> = ({
  open,
  name,
  backgroundColor,
  border,
  space,
  summary,
  children,
}) => {
  return (
    <StyledDetails
      open={open}
      name={name}
      backgroundColor={backgroundColor}
      space={space}
      border={border}
    >
      <StyledSummary>{summary}</StyledSummary>
      {children}
    </StyledDetails>
  );
};
