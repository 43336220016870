import { ApolloCache } from '@apollo/client';

import { ASK_TO_BE_SELECTED } from '../../utils/generated/generated';

export const askToBeSelectedUpdate =
  (jobId: string) =>
  (
    cache: ApolloCache<ASK_TO_BE_SELECTED>,
    { data }: { data?: ASK_TO_BE_SELECTED | null }
  ) => {
    if (!data) return;
    cache.modify({
      id: cache.identify({
        jobId,
        __typename: 'JobBusinessRelationState',
      }),
      fields: {
        hasRequestedSelection() {
          return data.businessAskToBeSelected;
        },
      },
    });
  };

export const askToBeSelectedOptimisticResponse: ASK_TO_BE_SELECTED = {
  businessAskToBeSelected: true,
};
