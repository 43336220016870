import { Radio } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledRadio = styled(Radio)`
  border: 1px solid ${(props): string => props.theme.colors.black.black4};
  background: ${(props): string => props.theme.colors.black.black0};
  padding: 10px;
`;

export default StyledRadio;
