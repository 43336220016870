import gql from 'graphql-tag';

import {
  TRIGGER_EVENT_SELF_SERVICE_PAYMENTVariables,
  TRIGGER_EVENT_SELF_SERVICE_PAYMENT as TRIGGER_EVENT_SELF_SERVICE_PAYMENTPayload,
} from '../../generated/generated';

export type TriggerEventSelfServicePaymentVariables =
  TRIGGER_EVENT_SELF_SERVICE_PAYMENTVariables;
export type TriggerEventSelfServicePaymentPayload =
  TRIGGER_EVENT_SELF_SERVICE_PAYMENTPayload;

export const TRIGGER_EVENT_SELF_SERVICE_PAYMENT = gql`
  mutation TRIGGER_EVENT_SELF_SERVICE_PAYMENT(
    $input: TriggerEventSelfServicePaymentInput!
  ) {
    triggerEventSelfServicePayment(input: $input)
  }
`;
