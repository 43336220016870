import { ApolloCache, makeReference, Reference } from '@apollo/client';

import {
  recalculatePageInfoAfterAddition,
  recalculatePageInfoAfterRemoval,
} from '../../utils/cache';
import {
  ChatListType,
  RESTORE_CHAT_MUTATION,
} from '../../utils/generated/generated';

const restoreChatUpdate =
  (
    chatId: string | undefined,
    isChatWon: boolean | null,
    chatHasContract: boolean | null
  ) =>
  (
    cache: ApolloCache<RESTORE_CHAT_MUTATION>,
    { data }: { data?: RESTORE_CHAT_MUTATION | null }
  ) => {
    cache.modify({
      id: cache.identify({ id: chatId, __typename: 'Chat' }),
      fields: {
        isDeleted() {
          return !data?.restoreChat.success;
        },
      },
    });
    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        chatList(existingChatList, { readField }) {
          if (existingChatList.listType === ChatListType.INACTIVE) {
            const chatToUpdate = existingChatList.chatConnection.edges.find(
              (edge: { node: Reference }) => {
                return readField('id', edge.node) === chatId;
              }
            );
            if (chatToUpdate) {
              return {
                ...existingChatList,
                chatConnection: {
                  ...existingChatList.chatConnection,
                  edges: existingChatList.chatConnection.edges.filter(
                    (chat: { node: Reference }) => chat !== chatToUpdate
                  ),
                  pageInfo: recalculatePageInfoAfterRemoval(
                    existingChatList.chatConnection.pageInfo
                  ),
                },
              };
            }
          } else if (
            existingChatList.listType === ChatListType.ACTIVE ||
            (existingChatList.listType === ChatListType.WON && isChatWon) ||
            (existingChatList.listType === ChatListType.CONTRACT &&
              chatHasContract)
          ) {
            return {
              ...existingChatList,
              chatConnection: {
                ...existingChatList.chatConnection,
                edges: [
                  ...existingChatList.chatConnection.edges,
                  { node: makeReference(`Chat:${chatId}`) },
                ].sort((chatA, chatB) => {
                  const aChatLatestUpdateTs =
                    readField('latestUpdateTs', chatA.node) ?? 0;
                  const bChatLatestUpdateTs =
                    readField('latestUpdateTs', chatB.node) ?? 0;
                  return aChatLatestUpdateTs < bChatLatestUpdateTs ? 1 : -1;
                }),
                pageInfo: recalculatePageInfoAfterAddition(
                  existingChatList.chatConnection.pageInfo
                ),
              },
            };
          }
          return existingChatList;
        },
      },
    });
  };

const restoreChatOptimisticResponse: RESTORE_CHAT_MUTATION = {
  restoreChat: {
    __typename: 'RestoreChatPayload',
    success: true,
  },
};

export { restoreChatUpdate, restoreChatOptimisticResponse };
