import { UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload } from '../generated/generated';
import { UpdateBusinessLogoPayload } from '../mutation/UpdateBusinessLogo/UpdateBusinessLogo';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class UpdateBusinessLogoResponseError extends GQLResponseErrorBase<
  UpdateBusinessLogoPayload['uploadBusinessLogo'],
  UPDATE_BUSINESS_LOGO_uploadBusinessLogo_ImageUploadPayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'ImageUploadInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      case 'LoginRequiredError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
