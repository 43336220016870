import { FC } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { Trans } from 'react-i18next';

import { CUSTOM_YELLOW_100 } from '../../constants';
import { useBoostCreditsModalContext } from '../../ModalContext';

export const PermissionAlert: FC = () => {
  const { primaryContact } = useBoostCreditsModalContext();

  if (!primaryContact || primaryContact.isCurrentUserPrimaryContact) {
    return null;
  }

  return (
    <Div bg={CUSTOM_YELLOW_100} mb={3}>
      <Text.p
        fontSize={1}
        textAlign="center"
        fontWeight="medium"
        mb={0}
        px={4}
        py={3}
      >
        <Trans
          i18nKey="job.modal.boost.selectPackage.permissionAlert"
          values={{ email: primaryContact?.primaryContactEmail }}
        />
      </Text.p>
    </Div>
  );
};
