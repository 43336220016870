import { ApolloCache, Reference } from '@apollo/client';
import gql from 'graphql-tag';

import { CountiesMunicipality } from '../../utils/areasUtils';
import { filterOutNullishValues } from '../../utils/arrayUtils';
import {
  CURRENT_USER_COMPANY_QUERY_business_Business,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities,
  UPDATE_BUSINESS_MUNICIPALITIES,
} from '../../utils/generated/generated';
import { UpdateBusinessMunicipalitiesMutationPayload } from '../../utils/mutation/UpdateBusinessMunicipalities/UpdateBusinessMunicipalities';

const updateBusinessMunicipalitiesUpdate =
  (
    businessId: CURRENT_USER_COMPANY_QUERY_business_Business['id'],
    updatedMunicipalityIds: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities['id'][],
    isEnabled: boolean
  ) =>
  (
    cache: ApolloCache<UPDATE_BUSINESS_MUNICIPALITIES>,
    {
      data: mutationPayload,
    }: { data?: UpdateBusinessMunicipalitiesMutationPayload | null }
  ) => {
    if (!mutationPayload) return;
    const businessCacheId = cache.identify({
      id: businessId,
      __typename: 'BusinessJobAreas',
    });
    const municipalityCacheIds = updatedMunicipalityIds.map(
      (updatedMunicipalityId) =>
        cache.identify({
          id: updatedMunicipalityId,
          __typename: 'Municipality',
        })
    );

    if (
      !businessCacheId ||
      !filterOutNullishValues(municipalityCacheIds).length
    )
      return;
    cache.modify({
      id: businessCacheId,
      fields: {
        municipalities(existing, { toReference }) {
          // from payload we get municipality ids,
          // we map them to cached entities and put them in enabled municipalities field
          return mutationPayload.updateBusinessMunicipalities.municipalities
            ?.map((municipality) =>
              cache.identify({
                id: municipality.id,
                __typename: 'Municipality',
              })
            )
            .map((municipalityCacheId) =>
              municipalityCacheId
                ? toReference(municipalityCacheId)
                : municipalityCacheId
            )
            .filter(Boolean);
        },
        districts(existing, { readField, toReference }) {
          if (!existing) return existing;

          // disable all districts that belong to disabled municipality
          if (!isEnabled) {
            return existing.filter((districtRef: Reference) => {
              const parentMunicipalityRef = readField(
                'municipality',
                districtRef
              );
              const parentMunicipalityCacheId = parentMunicipalityRef
                ? cache.identify(parentMunicipalityRef as Reference)
                : parentMunicipalityRef;
              return !municipalityCacheIds.includes(
                parentMunicipalityCacheId as string
              );
            });
          }
          // get all districts
          const cachedDistrictsAvailableData: {
            districtsAvailable: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districtsAvailable[];
          } | null = cache.readFragment({
            id: businessCacheId,
            fragment: gql`
              fragment CachedDistrictsAvailable on BusinessJobAreas {
                districtsAvailable {
                  id
                  municipality {
                    id
                  }
                }
              }
            `,
            fragmentName: 'CachedDistrictsAvailable',
          });
          if (!cachedDistrictsAvailableData) return existing;

          // filter out districts that are from different municipality or are already enabled
          const enabledDistrictRefs =
            cachedDistrictsAvailableData.districtsAvailable
              .filter(
                (district) =>
                  !!district.municipality &&
                  updatedMunicipalityIds.includes(district.municipality.id) &&
                  !existing.some((alreadyEnabledDistrictRef: Reference) => {
                    const alreadyEnabledDistrictId = readField(
                      'id',
                      alreadyEnabledDistrictRef
                    );
                    return alreadyEnabledDistrictId === district.id;
                  })
              )
              // map district to cache entity
              .map((district) =>
                cache.identify({ id: district.id, __typename: 'District' })
              )
              .map((districtCacheId) =>
                districtCacheId ? toReference(districtCacheId) : districtCacheId
              )
              .filter(Boolean);

          return [...existing, ...enabledDistrictRefs];
        },
      },
    });
  };

type UpdateMunicipalityType = Pick<CountiesMunicipality, 'id' | 'name'>;

const updateBusinessMunicipalitiesOptimisticResponse = (
  municipalitiesEnabled: UpdateMunicipalityType[],
  updatedMunicipalities: UpdateMunicipalityType[],
  enabled: boolean
): UpdateBusinessMunicipalitiesMutationPayload => {
  let updatedMunicipalitiesEnabled = municipalitiesEnabled.filter(
    (enabledMunicipality) =>
      !updatedMunicipalities.some(
        (updatedMunicipality) =>
          updatedMunicipality.id === enabledMunicipality.id
      )
  );
  if (enabled) {
    updatedMunicipalitiesEnabled = [
      ...updatedMunicipalitiesEnabled,
      ...updatedMunicipalities,
    ];
  }
  return {
    updateBusinessMunicipalities: {
      __typename: 'UpdateBusinessMunicipalitiesPayload',
      municipalities: updatedMunicipalitiesEnabled.map(({ id, name }) => ({
        id,
        name,
        __typename: 'Municipality',
      })),
    },
  };
};
export {
  updateBusinessMunicipalitiesUpdate,
  updateBusinessMunicipalitiesOptimisticResponse,
};
