import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';

interface RadioButtonProps {
  isSelected: boolean;
}

export const RadioButton: FC<RadioButtonProps> = ({ isSelected }) => (
  <Div position="absolute" top="10px" right="10px">
    <FireIcon
      iconName={isSelected ? IconPickerName.Mark : IconPickerName.MarkInactive}
      width={24}
      height={24}
      fill={isSelected ? 'primary.base' : 'black.black7'}
    />
  </Div>
);
