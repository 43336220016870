import gql from 'graphql-tag';

import {
  EDIT_SIMPLE_AGREEMENT_CONTRACT as EDIT_SIMPLE_AGREEMENT_CONTRACT_Result,
  EDIT_SIMPLE_AGREEMENT_CONTRACTVariables,
} from '../../generated/generated';

export type EditSimpleAgreementContractMutationVariables =
  EDIT_SIMPLE_AGREEMENT_CONTRACTVariables;
export type EditSimpleAgreementContractMutationPayload =
  EDIT_SIMPLE_AGREEMENT_CONTRACT_Result;

export const isEditSimpleAgreementContractMutationSuccessResponse = (
  editSimpleAgreementContract?: EDIT_SIMPLE_AGREEMENT_CONTRACT_Result['editSimpleAgreementContract']
): editSimpleAgreementContract is EDIT_SIMPLE_AGREEMENT_CONTRACT_Result['editSimpleAgreementContract'] =>
  !!editSimpleAgreementContract &&
  editSimpleAgreementContract.__typename ===
    'EditSimpleAgreementContractPayload';

export const EDIT_SIMPLE_AGREEMENT_CONTRACT = gql`
  mutation EDIT_SIMPLE_AGREEMENT_CONTRACT(
    $input: EditSimpleAgreementContractInput!
  ) {
    editSimpleAgreementContract(input: $input) {
      contract {
        id
        endDate
        statusEnum
        statusFlags {
          acceptedBusiness
          acceptedConsumer
          initialized
          invalidated
          rejectedBusiness
          rejectedConsumer
        }
        startDate
        signedUserTime
        signedCompanyTime
        priceType
        price
        description
        businessEmail
        businessPhone
        newAgreement {
          id
        }
        oldAgreement {
          id
        }
        regTime
        type
      }
    }
  }
`;
