import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  ButtonPosition,
  StyledPillNavigationButton,
  StyledPillNavigationButtonWrapper,
} from '@components/elements/PillNavigation/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTheme } from 'styled-components';

interface PillNavigationButtonProps {
  position: ButtonPosition;
  onMousePressStart: VoidFunction;
  onMousePressEnd: VoidFunction;
}

export const PillNavigationButton: FC<PillNavigationButtonProps> = ({
  position,
  onMousePressStart,
  onMousePressEnd,
}) => {
  const themeContext = useTheme();
  return (
    <StyledPillNavigationButtonWrapper position={position}>
      <StyledPillNavigationButton
        position={position}
        type="button"
        onMouseDown={onMousePressStart}
        onMouseUp={onMousePressEnd}
        onMouseOut={onMousePressEnd}
        onPointerDown={onMousePressStart}
        onPointerUp={onMousePressEnd}
        onPointerOut={onMousePressEnd}
      >
        <FireIcon
          iconName={
            position === 'right'
              ? IconPickerName.Arrow2Right
              : IconPickerName.Arrow2Left
          }
          width={18}
          height={18}
          fill={themeContext.colors.primary.darkest}
        />
      </StyledPillNavigationButton>
    </StyledPillNavigationButtonWrapper>
  );
};
