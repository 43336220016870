import {
  createContext,
  FC,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

export enum SavedFiltersFeatureTourStep {
  Intro = 'saved-filter-tour-intro',
  SelectFilter = 'saved-filter-tour-select-filter',
  SaveFilter = 'saved-filter-tour-save-filter',
  Settings = 'saved-filter-tour-settings',
}

interface SavedFiltersFeatureTourContextProviderProps {
  children: ReactNode;
}

export interface SavedFiltersFeatureTourContextValue {
  activeStep: SavedFiltersFeatureTourStep;
  startGuide: VoidFunction;
  endGuide: VoidFunction;
  goToStep: (step: SavedFiltersFeatureTourStep) => void;
  selectFilterTooltipRef: RefObject<unknown>;
  saveFilterTooltipRef: RefObject<unknown>;
  settingsTooltipRef: RefObject<unknown>;
}

export const SavedFiltersFeatureTourContext = createContext<
  SavedFiltersFeatureTourContextValue | undefined
>(undefined);

export const savedFiltersGuideSteps = [
  SavedFiltersFeatureTourStep.Intro,
  SavedFiltersFeatureTourStep.SelectFilter,
  SavedFiltersFeatureTourStep.SaveFilter,
  SavedFiltersFeatureTourStep.Settings,
];

export const SavedFiltersFeatureTourContextProvider: FC<
  SavedFiltersFeatureTourContextProviderProps
> = ({ children }) => {
  const [activeStep, setActiveStep] =
    useState<SavedFiltersFeatureTourStep | null>(null);

  const selectFilterTooltipRef = useRef(null);
  const saveFilterTooltipRef = useRef(null);
  const settingsTooltipRef = useRef(null);

  const startGuide = useCallback(() => {
    setActiveStep(savedFiltersGuideSteps[0]);
  }, []);

  const goToStep = useCallback((step: SavedFiltersFeatureTourStep) => {
    if (savedFiltersGuideSteps.includes(step)) {
      setActiveStep(step);
    }
  }, []);

  const endGuide = useCallback(() => {
    setActiveStep(null);
  }, []);

  const value = useMemo(
    () => ({
      activeStep,
      startGuide,
      endGuide,
      goToStep,
      selectFilterTooltipRef,
      saveFilterTooltipRef,
      settingsTooltipRef,
    }),
    [
      activeStep,
      startGuide,
      endGuide,
      goToStep,
      selectFilterTooltipRef,
      saveFilterTooltipRef,
      settingsTooltipRef,
    ]
  );

  return (
    <SavedFiltersFeatureTourContext.Provider value={value}>
      {children}
    </SavedFiltersFeatureTourContext.Provider>
  );
};

export const useSavedFiltersFeatureTour =
  (): SavedFiltersFeatureTourContextValue => {
    const context = useContext(SavedFiltersFeatureTourContext);
    if (!context) {
      throw new Error(
        'useSavedFiltersFeatureTour must be used within a SavedFiltersFeatureTourContextProvider'
      );
    }
    return context;
  };
