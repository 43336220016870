import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FoldersNavigationButton = styled(Button)`
  padding: 4px;
  margin: 0;
  background: none;
  border: none;

  &:hover {
    background: none;
    border: none;
  }
`;

export default FoldersNavigationButton;
