import { FC } from 'react';

import { Column, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const ChatDetailsNoChatSelected: FC = () => {
  const { t } = useTranslation();

  return (
    <Column
      display="flex"
      flex="1"
      backgroundColor="black.black1"
      alignItems="center"
      justifyContent="center"
    >
      <Heading.h3>{t('chat.action.chooseConversation')}</Heading.h3>
    </Column>
  );
};
