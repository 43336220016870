export const getProductZipCodeLength = (productId: string): number => {
  switch (productId) {
    case 'servicefinder':
      return 5;
    case 'mittanbud':
      return 4;
    case 'byggetilbudMatch':
      return 4;
    case 'remppatori':
      return 5;
    default:
      return 4;
  }
};
