import { InMemoryCache, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import gql from 'graphql-tag';

import ADDRESS_FRAGMENT from '../../utils/fragments/AddressFragment';
import JOB_META_FRAGMENT from '../../utils/fragments/JobMetaFragment';
import META_CAR_FRAGMENT from '../../utils/fragments/MetaCarFragment';
import { JobMetaFragment } from '../../utils/generated/generated';

const query = gql`
  ${JOB_META_FRAGMENT}

  query JOB_META($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        meta {
          ...JobMetaFragment
        }
      }
    }
  }
`;

export const writeMeta = (
  cache: InMemoryCache,
  readField: ReadFieldFunction,
  jobId?: string,
  value?: JobMetaFragment
) => {
  const meta = { ...value };

  // car and toAddress are stored in cache as reference
  if (value?.car) {
    const carFragment: JobMetaFragment['car'] | null = cache.readFragment({
      fragment: META_CAR_FRAGMENT,
      fragmentName: 'MetaCarFragment',
      id: `Car:${readField('id', value.car as unknown as Reference)}`,
    });
    if (carFragment) {
      meta.car = carFragment;
    }
  }
  if (value?.toAddress) {
    const toAddressFragment: JobMetaFragment['toAddress'] | null =
      cache.readFragment({
        fragment: ADDRESS_FRAGMENT,
        fragmentName: 'AddressFragment',
        id: `Car:${readField('id', value.toAddress as unknown as Reference)}`,
      });
    if (toAddressFragment) {
      meta.toAddress = toAddressFragment;
    }
  }
  cache.writeQuery({
    data: { job: { __typename: 'Job', id: jobId, meta } },
    variables: { id: jobId },
    query,
  });
};
