import { FC, useEffect, useRef, useState } from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import ScrollPanel from '@components/base/ScrollPanel';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import NotFound from '@components/elements/NotFound';
import { EvaluationResponseForm } from '@components/layout/CustomerService/Evaluation/Evaluated/EvaluationResponseForm';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { Div, Button, Modal, Text, Spinner } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import { dateFromTimestamp } from '@utils/date';
import getJobItemSearchParam from '@utils/url';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';

import JobEvaluatedAlert from '../JobEvaluatedAlert';
import AvatarHeader from './AvatarHeader';
import EvaluationBubble from './EvaluationBubble';
import { EvaluatedViewProps } from './index';

const MobileView: FC<EvaluatedViewProps> = ({
  evaluation,
  replyErrors,
  replyData,
  loading,
  onClose,
  onSubmit,
  onReportEvaluation,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const [showReplyView, setShowReplyView] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [showReplyView]);

  return (
    <Modal
      isOpen
      isClosable={false}
      isSmaller
      header={
        showReplyView || loading ? (
          <SectionHeader
            px={6}
            title={t('evaluation.item.evaluated.inputPlaceholder')}
            onBackButton={() => setShowReplyView(false)}
          />
        ) : (
          <SectionHeader
            px={6}
            title={evaluation?.writerName}
            onBackButton={onClose}
          />
        )
      }
      headerProps={{ p: 0, m: 0, display: 'block' }}
      footerProps={{ p: 0 }}
      contentProps={{ p: 0, height: '100%', overflow: 'hidden' }}
      verticalSize="custom"
      height="100%"
      maxHeight="100%"
      top="0"
    >
      <Div height="100%" overflow="hidden">
        <ScrollPanel autoHide style={{ maxHeight: '100%' }}>
          {!loading && !evaluation ? (
            <NotFound
              title={t('profile.evaluations.item.notFoundTitle')}
              text={t('profile.evaluations.item.notFoundText')}
            />
          ) : (
            <Div display="flex" flexDirection="column">
              {loading ? (
                <Spinner horizontal />
              ) : (
                <>
                  <Div
                    flex="1 1"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    borderRight={`1px solid ${themeContext.colors.black.black3}`}
                    pt={6}
                    backgroundColor={themeContext.colors.black.black1}
                  >
                    <Div display="flex" flexDirection="column" px={6}>
                      <EvaluationBubble
                        message={evaluation?.description}
                        date={
                          evaluation?.publishedTime
                            ? dateFromTimestamp(evaluation.publishedTime)
                            : ''
                        }
                        avatar={{
                          initials: getInitials(evaluation?.writerName),
                        }}
                      />
                      {replyData && (
                        <>
                          {replyData.hasLogo ? (
                            <EvaluationBubble
                              message={replyData.reply.description}
                              date={
                                replyData.reply?.publishedTime
                                  ? dateFromTimestamp(
                                      replyData.reply.publishedTime
                                    )
                                  : ''
                              }
                              avatar={{
                                src: evaluation.business?.logo?.smallUrl ?? '',
                              }}
                              isReply
                            />
                          ) : (
                            <EvaluationBubble
                              message={replyData.reply.description}
                              date={
                                replyData.reply?.publishedTime
                                  ? dateFromTimestamp(
                                      replyData.reply.publishedTime
                                    )
                                  : ''
                              }
                              avatar={{
                                initials: replyData.reply.business.initials,
                              }}
                              isReply
                            />
                          )}
                        </>
                      )}
                      <ErrorAlert errors={replyErrors} />
                    </Div>
                    {!replyData ? (
                      <Div mx={4} mb={6}>
                        {!showReplyView ? (
                          <Button
                            onClick={() => setShowReplyView(true)}
                            fullWidth
                          >
                            {t('evaluation.item.evaluated.inputPlaceholder')}
                          </Button>
                        ) : (
                          <EvaluationResponseForm
                            onSubmit={onSubmit}
                            loading={loading}
                            isMobile
                          />
                        )}
                      </Div>
                    ) : (
                      <JobEvaluatedAlert
                        evaluationTimestamp={evaluation.publishedTime ?? 0}
                        jobId={evaluation.job.id}
                      />
                    )}
                  </Div>
                  {!showReplyView && (
                    <>
                      <AvatarHeader
                        mobileView
                        name={evaluation?.writerName}
                        score={evaluation?.score}
                        address={getAddressString(evaluation?.job?.address)}
                      />
                      <Div px={6} pb="120px">
                        <FeatureFlag name={FeatureFlags.BizEvaluationReport}>
                          <Div
                            borderBottom={`1px solid ${themeContext.colors.black.black3}`}
                            p={4}
                            mb={5}
                          >
                            <Button
                              type="button"
                              variant="linkTertiary"
                              onClick={onReportEvaluation}
                              p={0}
                              display="flex"
                              justifyContent="flex-start"
                              fullWidth
                            >
                              <Div width="32px" height="32px">
                                <FireIcon
                                  iconName={IconPickerName.Report}
                                  fill={themeContext.colors.primary.base}
                                  variant="flat"
                                />
                              </Div>
                              <Text.span
                                ml="12px"
                                mb={0}
                                fontSize={2}
                                fontWeight={themeContext.fontWeights.medium}
                                color="primary.base"
                              >
                                {t('evaluation.item.sidebar.reportEvaluation')}
                              </Text.span>
                              <Div width="16px" height="16px" ml="auto">
                                <FireIcon
                                  iconName={IconPickerName.ChevronRight}
                                  variant="flat"
                                />
                              </Div>
                            </Button>
                          </Div>
                        </FeatureFlag>
                        <Link to={getJobItemSearchParam(evaluation.job.id)}>
                          <Div
                            display="flex"
                            alignItems="center"
                            borderBottom={`1px solid ${themeContext.colors.black.black3}`}
                            p={4}
                          >
                            <Div width="32px" height="32px">
                              <FireIcon
                                iconName={IconPickerName.Invoice}
                                variant="color"
                              />
                            </Div>
                            <Text.span
                              ml="12px"
                              mb={0}
                              fontSize={2}
                              fontWeight={themeContext.fontWeights.medium}
                              color="primary.base"
                            >
                              {t('evaluation.item.sidebar.seeJob')}
                            </Text.span>
                            <Div width="16px" height="16px" ml="auto">
                              <FireIcon
                                iconName={IconPickerName.ChevronRight}
                                variant="flat"
                              />
                            </Div>
                          </Div>
                        </Link>
                      </Div>
                    </>
                  )}
                </>
              )}
            </Div>
          )}
        </ScrollPanel>
      </Div>
    </Modal>
  );
};

export default MobileView;
