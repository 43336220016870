import useQuerySearchParams from '@hooks/useQuerySearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { Params } from '@router/paths';

interface JobReport {
  isReportOpen: boolean;
  onReportShow: VoidFunction;
  onReportClose: VoidFunction;
}

const REPORT_PARAM_KEY = Params.Report;
const REPORT_PARAM_VALUE = 'true';

const useJobReport = (): JobReport => {
  const params = useQuerySearchParams();
  const [setParam] = useSetSearchParams();
  const isReportOpen = params[REPORT_PARAM_KEY]?.[0] === REPORT_PARAM_VALUE;
  const onReportShow = () => setParam(REPORT_PARAM_KEY, REPORT_PARAM_VALUE);
  const onReportClose = () => setParam(REPORT_PARAM_KEY, '');

  return {
    isReportOpen,
    onReportShow,
    onReportClose,
  };
};

export default useJobReport;
