import gql from 'graphql-tag';

import {
  DELETE_JOBVariables,
  DELETE_JOB as DELETE_JOB_Result,
} from '../../generated/generated';

export type DeleteJobMutationPayload = DELETE_JOB_Result;
export type DeleteJobMutationVariables = DELETE_JOBVariables;

export const DELETE_JOB_MUTATION = gql`
  mutation DELETE_JOB($jobId: ID!) {
    deleteJob(input: { jobId: $jobId }) {
      success
    }
  }
`;
