import { FC } from 'react';

import ProductImageMatch from '@internals/business-shared/src/assets/productScreens/savedFilters/saved-filters-intro-match.png';
import ProductImageMittanbud from '@internals/business-shared/src/assets/productScreens/savedFilters/saved-filters-intro-mittanbud.png';
import ProductImageRemppatori from '@internals/business-shared/src/assets/productScreens/savedFilters/saved-filters-intro-remppatori.png';
import {
  Button,
  Div,
  Heading,
  Image,
  Modal,
  Text,
} from '@schibsted-smb/fireball';
import { getProductId } from '@utils/product';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface SavedFiltersTourIntroModalProps {
  onClose: VoidFunction;
}

const getProductImage = (productId: string) => {
  const imageProps = {
    width: '100%',
    objectFit: 'cover',
  } as const;
  switch (productId) {
    case 'remppatori':
      return <Image src={ProductImageRemppatori} {...imageProps} />;
    case 'byggetilbudmatch':
      return <Image src={ProductImageMatch} {...imageProps} />;
    case 'mittanbud':
    case 'servicefinder':
    default:
      return <Image src={ProductImageMittanbud} {...imageProps} />;
  }
};

export const SavedFiltersTourIntroModal: FC<
  SavedFiltersTourIntroModalProps
> = ({ onClose }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const productId = getProductId();

  return (
    <Modal
      isOpen
      isClosable={false}
      onClose={onClose}
      isVerticallyCentered
      isSmaller
      maxWidth="665px"
      size="custom"
      contentProps={{ p: 0 }}
    >
      <Div display="flex" flexDirection="row">
        <Div flex={1.5} py={8} px={7} textAlign="left">
          <Div
            py={2}
            px={3}
            backgroundColor={themeContext.colors.primary.lightest}
            borderRadius={2}
            display="inline-block"
            mb={5}
          >
            <Text.p
              fontSize={2}
              fontWeight="medium"
              m={0}
              color={themeContext.colors.primary.darker}
            >
              {t('job.list.savedFilters.tour.intro.label')}
            </Text.p>
          </Div>
          <Heading.h2 fontWeight="semibold" mb={3}>
            {t('job.list.savedFilters.tour.intro.title')}
          </Heading.h2>
          <Text.p color={themeContext.colors.gray.darker} mb={6}>
            {t('job.list.savedFilters.tour.intro.description')}
          </Text.p>
          <Button onClick={onClose} fullWidth>
            {t('job.list.savedFilters.tour.intro.button')}
          </Button>
        </Div>
        <Div mt={8} ml={5} width="264px" overflow="hidden">
          {getProductImage(productId)}
        </Div>
      </Div>
    </Modal>
  );
};
