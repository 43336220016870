import { FC, ReactElement, ReactNode } from 'react';

import { Div } from '@schibsted-smb/fireball';

interface MobileMessageTemplateLayoutProps {
  header: ReactElement;
  footer: ReactElement;
  children: ReactNode;
}

export const MobileMessageTemplateLayout: FC<
  MobileMessageTemplateLayoutProps
> = ({ header, children, footer }) => {
  return (
    <Div
      height="70dvh"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      py={2}
      px={5}
      mt={6}
      mb="90px"
    >
      <Div
        width="100%"
        display="flex"
        justifyContent="space-between"
        mb={6}
        flexShrink={0}
        minHeight="50px"
      >
        {header}
      </Div>
      <Div
        width="100%"
        mb={6}
        flexGrow={1}
        overflow="auto"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Div>
      <Div width="100%" flexShrink={0}>
        {footer}
      </Div>
    </Div>
  );
};
