import { FC, useCallback } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { MessageBubblesColumn } from '@components/base/PhoneMask/styled/MessageBubblesColumn';
import MultilineText from '@components/base/PhoneMask/styled/MultilineText';
import { Stars } from '@components/base/PhoneMask/styled/Stars';
import { ViewMore } from '@components/base/PhoneMask/styled/ViewMore';
import { MessageBodyProps } from '@components/elements/ChatMessage/ChatMessageBody';
import { MessageWrapper } from '@components/elements/ChatMessage/styled/index';
import { useHidden } from '@internals/business-shared/src/hooks/useHidden';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  isPhoneNumberPart,
  splitMessageByPhoneNumber,
} from '@internals/business-shared/src/utils/phoneNumber';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useTheme } from 'styled-components';

interface PhoneMaskProps extends MessageBodyProps {
  onReveal?: VoidFunction;
}

export const PhoneMask: FC<PhoneMaskProps> = ({
  message,
  background,
  color,
  businessMessage = false,
  onReveal,
}) => {
  const [isInfoHidden, showInfo] = useHidden(onReveal);
  const { t } = useTranslation();
  const themeContext = useTheme();
  const messageParts = splitMessageByPhoneNumber(message.text);

  const insertMask = useCallback((currentStringItem: string, index: number) => {
    if (!isPhoneNumberPart(currentStringItem, index)) {
      return currentStringItem;
    }
    return (
      <Stars key={`${currentStringItem}-${index}`}>
        &bull;&bull;&nbsp;&bull;&bull;&nbsp;&bull;&bull;&nbsp;&bull;&bull;
      </Stars>
    );
  }, []);

  const insertPhoneLink = useCallback(
    (currentStringItem: string, index: number) => {
      if (!isPhoneNumberPart(currentStringItem, index)) {
        return currentStringItem;
      }

      return (
        <a
          href={`tel:${currentStringItem}`}
          key={`${currentStringItem}-${index}`}
        >
          {currentStringItem}
        </a>
      );
    },
    []
  );

  if (!isInfoHidden) {
    return (
      <MessageWrapper
        background={background}
        color={color}
        borderRadius="10px"
        borderBottomRightRadius={businessMessage ? '0' : '10px'}
        borderBottomLeftRadius={businessMessage ? '10px' : '0'}
        width="100%"
        data-testid="chat-message"
      >
        <Linkify>
          <MultilineText>{messageParts.map(insertPhoneLink)}</MultilineText>
        </Linkify>
      </MessageWrapper>
    );
  }

  return (
    <MessageBubblesColumn>
      <MessageWrapper
        background={background}
        color={color}
        borderRadius="10px"
        borderBottomRightRadius={businessMessage ? '0' : '10px'}
        borderBottomLeftRadius={businessMessage ? '10px' : '0'}
        width="100%"
        data-testid="chat-message"
      >
        <Linkify>
          <MultilineText>{messageParts.map(insertMask)}</MultilineText>
        </Linkify>
      </MessageWrapper>

      <ViewMore onClick={showInfo} variant="primary">
        <FireIcon
          iconName={IconPickerName.Phone1}
          fill={themeContext.colors.black.black0}
          height="15px"
        />
        &nbsp;{t('component.phoneMask.show')}
      </ViewMore>
    </MessageBubblesColumn>
  );
};
