import * as React from 'react';

import { Button, Div, Label, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { CompanyInfo } from './index';

interface ProfileEmployeeCompanyInfoProps {
  companyInfo: CompanyInfo;
  onClick: () => void;
}

const ProfileCompanyInfo: React.FC<
  React.PropsWithChildren<ProfileEmployeeCompanyInfoProps>
> = ({ companyInfo, onClick }: ProfileEmployeeCompanyInfoProps) => {
  const { t } = useTranslation();
  const showPostalAddress =
    !!companyInfo.companyPostAddress && !!companyInfo.companyPostNumber;
  const showVisitingAddress =
    !!companyInfo.companyVisitPostAddress &&
    !!companyInfo.companyVisitPostNumber;
  const showBillingAddress =
    !!companyInfo.companyBillingPostAddress &&
    !!companyInfo.companyBillingPostNumber;
  return (
    <Div>
      <Label pb={2}>{t('settings.profile.companyForm.companyEmail')}</Label>
      <Text.p mb={5} data-testid="company-email-txt">
        {companyInfo.companyEmail}
      </Text.p>
      <Label pb={2}>{t('settings.profile.companyForm.companyPhone')}</Label>
      <Text.p mb={5} data-testid="company-phone-txt">
        {companyInfo.companyPhoneNumber}
      </Text.p>
      <Label pb={2}>
        {t('settings.profile.companyForm.organizationNumber')}
      </Label>
      <Text.p mb={5}>{companyInfo.companyIdNumber}</Text.p>
      {showPostalAddress && (
        <>
          <Label pb={2}>
            {t('settings.profile.companyForm.mailingAddress')}
          </Label>
          <Text.p data-testid="company-post-address-txt">
            {companyInfo.companyPostAddress}
          </Text.p>
          <Text.p mb={5} data-testid="company-post-number-txt">
            {companyInfo.companyPostNumber} {companyInfo.companyCity}
          </Text.p>
        </>
      )}
      {showVisitingAddress && (
        <>
          <Label pb={2}>
            {t('settings.profile.companyForm.visitingAddress')}
          </Label>
          <Text.p data-testid="company-visit-address-txt">
            {companyInfo.companyVisitPostAddress}
          </Text.p>
          <Text.p mb={5} data-testid="company-visit-post-number-txt">
            {companyInfo.companyVisitPostNumber} {companyInfo.companyVisitCity}
          </Text.p>
        </>
      )}
      {showBillingAddress && (
        <>
          <Label pb={2}>
            {t('settings.profile.companyForm.billingAddress')}
          </Label>
          <Text.p data-testid="company-billing-address-txt">
            {companyInfo.companyBillingPostAddress}
          </Text.p>
          <Text.p mb={5} data-testid="company-billing-post-number-txt">
            {companyInfo.companyBillingPostNumber}{' '}
            {companyInfo.companyBillingCity}
          </Text.p>
        </>
      )}
      <Button
        variant="secondary"
        onClick={onClick}
        data-testid="company-info-edit-btn"
      >
        {t('settings.profile.companyForm.editButton')}
      </Button>
    </Div>
  );
};

export default ProfileCompanyInfo;
