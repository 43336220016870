import { useFlagEnabled } from '@components/base/CheckFlag';
import { useCompanyState } from '@internals/business-shared/src/contexts/CompanyStateContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { ProductTemplateSlug } from '@internals/business-shared/src/utils/generated/generated';

// TODO: This hook will be replaced with boolean from API, when becomes available
// https://app.clickup.com/t/2628532/MA-19347
export const useIsBuySubscription = () => {
  const { isOrderUnpaid, products, availableProductSlugs } =
    useCompanyState() || {};
  const isSubscription = products?.includes(ProductTemplateSlug.SUBSCRIPTION);
  const isBuySubscriptionFlagEnabled = useFlagEnabled(
    FeatureFlags.BizBuySubscription
  );

  const subscriptionIsAvailable = availableProductSlugs?.includes(
    ProductTemplateSlug.SUBSCRIPTION
  );

  return (
    isBuySubscriptionFlagEnabled &&
    !isOrderUnpaid &&
    !isSubscription &&
    subscriptionIsAvailable
  );
};
