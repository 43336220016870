import styled from 'styled-components';

export const JobDetailsColumn = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  margin: ${({ theme }) => theme.space[4]}px 0;
  &:nth-child(odd) {
    padding-right: ${({ theme }) => theme.space[5]}px;
  }
`;
