import { FC, useCallback, useMemo } from 'react';

import { useUserContext } from '@contexts/UserContext';
import { useEvaluationReport } from '@internals/business-shared/src/hooks/mutation/useEvaluationReport';
import { EvaluationReportFormShape } from '@internals/business-shared/src/types/FormShape/EvaluationReport';
import { TicketEntryType } from '@internals/business-shared/src/utils/generated/generated';
import {
  Button,
  Heading,
  Modal,
  Text,
  Textarea,
} from '@schibsted-smb/fireball';
import { Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface EvaluationReportModalProps {
  evaluationId: string;
  onClose: VoidFunction;
}

const EvaluationReportModal: FC<EvaluationReportModalProps> = ({
  evaluationId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { id: userId, operatingAs } = useUserContext();
  const [sendReport] = useEvaluationReport();

  const evaluationReportValidationSchema = useMemo(
    () =>
      Yup.object<EvaluationReportFormShape>({
        description: Yup.string()
          .trim()
          .required(t('general.form.validation.required')),
      }),
    [t]
  );

  const onFormikSubmit = useCallback(
    async (
      values: EvaluationReportFormShape,
      { setSubmitting }: FormikHelpers<EvaluationReportFormShape>
    ) => {
      setSubmitting(true);
      await sendReport({
        evaluationId,
        userId,
        businessId: operatingAs.id,
        title: t('evaluation.item.report.create.title', {
          businessId: operatingAs.id,
          evaluationId,
        }),
        description: values.description,
        source: TicketEntryType.WEB,
      });

      setSubmitting(false);
      onClose();
    },
    [evaluationId, onClose, operatingAs.id, sendReport, t, userId]
  );

  return (
    <Modal
      isOpen
      isClosable
      onClose={onClose}
      size="custom"
      maxWidth="580px"
      header={<Heading.h1>{t('evaluation.item.report.title')}</Heading.h1>}
    >
      <Formik
        initialValues={{ description: '' }}
        validationSchema={evaluationReportValidationSchema}
        onSubmit={onFormikSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <Text.p mt={5} mb={7}>
              {t('evaluation.item.report.description')}
            </Text.p>
            <Textarea
              label={t('general.label.description')}
              height="200px"
              error={
                formik.touched.description && formik.errors.description
                  ? { msg: formik.errors.description, variant: 'danger' }
                  : null
              }
              {...formik.getFieldProps('description')}
            />
            <Button
              width="100%"
              mb={5}
              mt={7}
              type="submit"
              variant="primary"
              isLoading={formik.isSubmitting}
            >
              {t('evaluation.item.report.submitButtonText')}
            </Button>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EvaluationReportModal;
