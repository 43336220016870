import { GQLErrorState } from '.';
import { DAY_KEYS } from '../constants/days';
import {
  UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business,
  UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError,
} from '../generated/generated';
import { UpdateBusinessProfileInfoPayload } from '../mutation/UpdateBusinessProfileInfo/UpdateBusinessProfileInfo';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class UpdateBusinessProfileInfoResponseError extends GQLResponseErrorBase<
  UpdateBusinessProfileInfoPayload['updateBusinessProfileInfo'],
  UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  protected mapOpeningHoursError(
    openingHoursError: UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_UpdateBusinessProfileInfoInputError_openingHoursError
  ): Record<string, GQLErrorState> {
    return DAY_KEYS.reduce(
      (previousValue, currentValue) => ({
        ...previousValue,
        // @ts-ignore
        ...(openingHoursError?.[currentValue]?.from?.message && {
          [`${currentValue}from`]: {
            // @ts-ignore
            msg: openingHoursError?.[currentValue]?.from?.message ?? '',
            variant: 'danger',
          },
        }),
        // @ts-ignore
        ...(openingHoursError?.[currentValue]?.to?.message && {
          [`${currentValue}to`]: {
            // @ts-ignore
            msg: openingHoursError?.[currentValue]?.to?.message ?? null,
            variant: 'danger',
          },
        }),
      }),
      {}
    );
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'UpdateBusinessProfileInfoInputError':
        this.mapDataToError(data, 'businessIdError', 'danger', true);
        this.mapDataToError(data, 'phoneError', 'danger');
        this.mapDataToError(data, 'websiteError', 'danger');
        this.mapDataToError(data, 'facebookError', 'danger');
        this.mapDataToError(data, 'instagramError', 'danger');
        this.mapDataToError(data, 'youtubeError', 'danger');
        this.mapDataToError(data, 'tiktokError', 'danger');
        this.mapDataToError(data, 'titleError', 'danger');
        this.mapDataToError(data, 'descriptionError', 'danger');
        this.mapDataToError(data, 'headerImageError', 'danger', true);

        if (data?.addressError?.street) {
          this.errors.addressStreet = {
            variant: 'danger',
            msg: data.addressError.street.message,
          };
        }

        if (data?.addressError?.zip) {
          this.errors.addressZip = {
            variant: 'danger',
            msg: data.addressError.zip.message,
          };
        }

        if (data.openingHoursError) {
          Object.assign(
            this.errors,
            this.mapOpeningHoursError(data.openingHoursError)
          );
        }

        break;

      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
