import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import { DownloadButton } from '.';

export const DownloadButtonLabelAction = styled(Text.span)`
  margin: 0;
  padding: 0;
  display: none;

  ${DownloadButton}:hover &,${DownloadButton}:focus &,${DownloadButton}:active & {
    display: inline-block;
  }
`;
