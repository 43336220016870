import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export interface ChatStatusProps {
  active: boolean;
}
const ChatStatus = styled(Div)<ChatStatusProps>`
  display: block;
  width: 100%;
  height: auto;
  margin: 0.5rem 0 0.5rem 1rem;
  font-size: ${(props): string => props.theme.fontSizes[1]}; //13
  text-align: center;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[4]}) {
    width: 190px;
    text-align: left;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: ${(props): string =>
      props.active
        ? props.theme.colors.green.base
        : props.theme.colors.red.base};
    border-radius: 50%;
  }
`;

export default ChatStatus;
