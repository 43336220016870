import * as React from 'react';

import PageContainer from '@components/base/PageContainer';
import { ChatDetailsNoChatSelected } from '@components/layout/Messages/ChatDetailsNoChatSelected';
import { useIsMobile, useIsWidescreen } from '@contexts/DeviceSizeContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Row } from '@schibsted-smb/fireball';

import ChatDetails from './ChatDetails';
import ChatList from './ChatList';

export interface MessagesProps {
  chatId?: string;
}

const Messages: React.FC<MessagesProps> = ({ chatId }) => {
  const [isChatJobInfoVisible, setIsChatJobInfoVisible] = React.useState(false);

  const isMobileScreen = useIsMobile();
  const isWideScreen = useIsWidescreen();
  const { track, page } = useAnalytics();

  React.useEffect(() => {
    page(ANSpace(ANObject.None, ANPage.Messages));
    if (chatId) {
      track(
        ANEventSpace(ANEvent.Opened, ANObject.Conversation, ANPage.Messages)
      );
    }
  }, [chatId]);

  return (
    <PageContainer
      fluid
      fullScreenHeight
      skipNavigation={isMobileScreen && !!chatId}
    >
      <Row flexWrap="nowrap" alignItems="stretch" height="100%">
        {isMobileScreen && (
          <>
            {chatId ? (
              <ChatDetails
                chatId={chatId}
                isChatJobInfoVisible={isChatJobInfoVisible}
                setIsChatJobInfoVisible={setIsChatJobInfoVisible}
                isMobile
                isDesktop={false}
              />
            ) : (
              <ChatList isMobile />
            )}
          </>
        )}

        {!isMobileScreen && (
          <>
            {chatId ? (
              <>
                <ChatList chatId={chatId} isMobile={false} />
                <ChatDetails
                  chatId={chatId}
                  isChatJobInfoVisible={isChatJobInfoVisible}
                  setIsChatJobInfoVisible={setIsChatJobInfoVisible}
                  isMobile={false}
                  isDesktop={isWideScreen}
                />
              </>
            ) : (
              <>
                <ChatList />
                <ChatDetailsNoChatSelected />
              </>
            )}
          </>
        )}
      </Row>
    </PageContainer>
  );
};

export default Messages;
