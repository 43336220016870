import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  StyledProductEmphasizedValue,
  StyledProductOptionButton,
} from '@components/layout/Products/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';

interface ProductOptionProps {
  isSelected: boolean;
  value: number;
  valueText: string;
  onClick: VoidFunction;
  extraText?: string;
}

export const ProductOption: FC<ProductOptionProps> = ({
  isSelected,
  value,
  valueText,
  extraText,
  onClick,
}) => {
  return (
    <StyledProductOptionButton
      variant="linkTertiary"
      onClick={onClick}
      isSelected={isSelected}
    >
      <Div>
        <FireIcon
          iconName={
            isSelected ? IconPickerName.Mark : IconPickerName.MarkInactive
          }
          width={24}
          height={24}
          fill={isSelected ? 'primary.base' : 'black.black7'}
        />
      </Div>
      <Div ml={5}>
        <Div display="flex" alignItems="baseline" mb={1}>
          <StyledProductEmphasizedValue>{value}</StyledProductEmphasizedValue>
          <Text.span mb={0} ml={1} fontWeight="medium">
            {valueText}
          </Text.span>
        </Div>
        <Text.p mb={0} fontSize={1} color="black.black7">
          {extraText}
        </Text.p>
      </Div>
    </StyledProductOptionButton>
  );
};
