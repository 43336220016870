import { FC, PropsWithChildren } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Heading, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface IntegrationSubsectionProps {
  icon: IconPickerName;
  title: string;
  description?: string;
  testId: string;
}

const IntegrationSubsection: FC<
  PropsWithChildren<IntegrationSubsectionProps>
> = ({ icon, title, description, testId = 'default', children }) => {
  const themeContext = useTheme();
  return (
    <Div p={6} mt={7} data-testid={`integration-subsection-${testId}`}>
      <Div display="flex" alignItems="center" mb={4}>
        <Div width={32} height={32}>
          <FireIcon iconName={icon} variant="color" />
        </Div>
        <Heading.h4 ml={5} fontWeight={themeContext.fontWeights.medium}>
          {title}
        </Heading.h4>
      </Div>
      <Text.p
        ml={[47, null, null, null, null, 0, 47]}
        color="black.black8"
        lineHeight={1.5}
        fontSize={2}
      >
        {description}
      </Text.p>
      <Div mt={5} ml={[47, null, null, null, null, 0, 47]}>
        {children}
      </Div>
    </Div>
  );
};

export default IntegrationSubsection;
