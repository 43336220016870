import { Dispatch, SetStateAction } from 'react';

export const Variant = {
  action: 'action',
  danger: 'danger',
  info: 'info',
  warning: 'warning',
  muted: 'muted',
  success: 'success',
};

export type VariantType = keyof typeof Variant;
export type InputError = { msg: string; variant: VariantType };
export type GQLErrorState = Record<string, InputError>;
export type SetGQLErrorState = Dispatch<SetStateAction<GQLErrorState>>;

export { AnswerJobResponseError } from './AnswerJobResponseError';
export { BusinessProductsResponseError } from './BusinessProductsResponseError';
export { ChatDetailsResponseError } from './ChatDetailsResponseError';
export { ChatListResponseError } from './ChatListResponseError';
export { CompanyInfoResponseError } from './CompanyInfoResponseError';
export { CreateJobFolderResponseError } from './CreateJobFolderResponseError';
export { EditJobFolderResponseError } from './EditJobFolderResponseError';
export { EvaluationResponseError } from './EvaluationResponseError';
export { GQLResponseErrorBase } from './GQLResponseErrorBase';
export { JobResponseError } from './JobResponseError';
export { LoginResponseError } from './LoginResponseError';
export { QualificationListResponseError } from './QualificationListResponseError';
export { ReplyToEvaluationResponseError } from './ReplyToEvaluationResponseError';
export { SendMessageResponseError } from './SendMessageResponseError';
export { UpdateBusinessLogoResponseError } from './UpdateBusinessLogoResponseError';
export { UpdateBusinessProfileInfoResponseError } from './UpdateBusinessProfileInfoResponseError';
export { UpdateBusinessResponseError } from './UpdateBusinessResponseError';
