import { FC } from 'react';

import NoData from '@components/elements/NoData';
import NotFound from '@components/elements/NotFound';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import { usePageAnalytics } from '@hooks/useAnalytics';
import {
  InvoiceListQueryResult,
  useInvoiceListQuery,
} from '@internals/business-shared/src/hooks/query/useInvoiceListQuery';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  GroupInvoiceReceipt,
  InvoiceReceipt,
} from '@internals/business-shared/src/utils/Receipts/GroupInvoiceReceipt';
import Paths from '@router/paths';
import { Accordion, Text } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import urlWithProtocol from '@utils/urlWithProtocol';
import { useTranslation } from 'react-i18next';

import {
  CollapsedRow,
  DownloadButton,
  DownloadButtonLabel,
  DownloadButtonLabelAction,
} from './styled';

const Payment: FC = () => {
  const { t, i18n } = useTranslation();
  const { name: productName } = getProduct();
  const { data, refetch, isLoading, error }: InvoiceListQueryResult =
    useInvoiceListQuery();
  const invoiceReceipts = GroupInvoiceReceipt(data, i18n.language);
  const noData = !isLoading && data?.length === 0;

  usePageAnalytics(ANSpace(ANObject.Receipts, ANPage.Settings));

  if (error) {
    return (
      <SettingsContainer>
        <NotFound
          title={t('settings.error.title')}
          text={t('settings.error.text')}
          link={{
            url: Paths.SettingsCustomerService,
            text: t('general.label.customerService'),
          }}
          button={{
            text: t('general.error.tryAgain'),
            onClick: () => refetch(),
          }}
        />
      </SettingsContainer>
    );
  }

  if (isLoading) {
    return (
      <SettingsContainer>
        <SettingsLoading />
      </SettingsContainer>
    );
  }

  if (noData) {
    return (
      <SettingsContainer>
        <NoData
          title={t('settings.receipts.emptyList.title')}
          text={t('settings.receipts.emptyList.text')}
        />
      </SettingsContainer>
    );
  }

  return (
    <SettingsContainer>
      <SettingsSection
        icon={IconPickerName.Receipts}
        title={t('settings.receipts.title')}
        testId="receipts"
      >
        <Text.span mb="35px" color="black.black9" lineHeight={1.5}>
          {t('settings.receipts.description', { productName })}
        </Text.span>
        <Accordion
          colorContext="light"
          items={Object.entries(invoiceReceipts)
            .reverse()
            .map(([year, invoices]) => ({
              key: year,
              id: year,
              rowLeftLabel: year,
              expandedRowContent: invoices.map((invoice: InvoiceReceipt) => (
                <CollapsedRow
                  key={invoice.id}
                  data-testid={`accordion-item-${invoice.humanInvoiceDate
                    .replace(' ', '-')
                    .toLowerCase()}`}
                >
                  <DownloadButton
                    variant="linkPrimary"
                    href={urlWithProtocol(invoice.pdfUrl)}
                    download
                    target="_blank"
                    rel="noreferrer"
                    data-testid="receipt-download-btn"
                  >
                    <DownloadButtonLabel>
                      {invoice.humanInvoiceDate}
                    </DownloadButtonLabel>
                    <DownloadButtonLabelAction>
                      {t('general.label.download')}
                    </DownloadButtonLabelAction>
                  </DownloadButton>
                </CollapsedRow>
              )),
            }))}
          testId="receipts"
        />
      </SettingsSection>
    </SettingsContainer>
  );
};

export default Payment;
