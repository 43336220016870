import { FC, PropsWithChildren } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div, hexToRgba } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTheme } from 'styled-components';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

const MAX_WIDTH = { narrow: 285, wide: 382 };
const MIN_WIDTH = { narrow: 240, wide: 300 };
const TOP_SPACE = 40;

interface StyleProps {
  bottom?: number;
  boxShadow?: string;
  maxWidth?: number;
  mb?: number;
  minWidth?: number;
  mt?: number;
  mx?: number;
  zIndex?: number;
}

interface ContainerProps {
  mostPopular?: boolean;
}

export const Container: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  mostPopular,
}) => {
  const { modalState } = useBuySubscriptionModalContext();
  const { colors, radii } = useTheme();
  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      bottom: mostPopular && !isTabletOrMobile && 5,
      boxShadow:
        mostPopular &&
        !isTabletOrMobile &&
        `0 0 40px 0 ${hexToRgba(colors.blueNavy.base, 0.5)}`,
      maxWidth: isTabletOrMobile ? MAX_WIDTH.wide : MAX_WIDTH.narrow,
      mb: isTabletOrMobile && 5,
      minWidth: isTabletOrMobile && MIN_WIDTH.narrow,
      mx: !isTabletOrMobile && 1,
      zIndex: mostPopular && !isTabletOrMobile && Layer.above,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      maxWidth: MAX_WIDTH.wide,
      minWidth: MIN_WIDTH.narrow,
    },
    [ModalState.SUMMARY]: {
      mt: TOP_SPACE,
      maxWidth: MAX_WIDTH.narrow,
      minWidth: MIN_WIDTH.narrow,
    },
  };

  return (
    <Div
      bg="black.black0"
      borderRadius={radii[2]}
      position="relative"
      width={1}
      {...styleProps[modalState]}
    >
      {children}
    </Div>
  );
};
