import { FC } from 'react';

import { useUserContext } from '@contexts/UserContext';
import { Div } from '@schibsted-smb/fireball';
import {
  getPrivacySettingsUrl,
  getProduct,
  getProductTermsOfServiceUrl,
} from '@utils/product';
import { useTranslation } from 'react-i18next';

import LanguagePicker from '../LanguagePicker';
import { FooterItem, FooterLinkItem } from './styled';

const { customerServiceEmail, customerServicePhone } = getProduct();
const termsOfServiceUrl = getProductTermsOfServiceUrl();
const privacySettingsUrl = getPrivacySettingsUrl();

const JobsFooter: FC = () => {
  const { t } = useTranslation();
  const { operatingAs: company } = useUserContext();

  if (!company?.name) return null;

  return (
    <Div my={6} fontSize={1}>
      <FooterItem>{`${company.name} (${company.id})`}</FooterItem>
      <FooterLinkItem href={termsOfServiceUrl}>
        {t('general.label.termsOfService')}
      </FooterLinkItem>
      <FooterLinkItem href={privacySettingsUrl}>
        {t('general.label.privacy')}
      </FooterLinkItem>
      <FooterLinkItem href={`mailto:${customerServiceEmail}`}>
        {customerServiceEmail}
      </FooterLinkItem>
      <FooterLinkItem href={`tel:${customerServicePhone}`}>
        {customerServicePhone}
      </FooterLinkItem>
      <LanguagePicker />
    </Div>
  );
};

export default JobsFooter;
