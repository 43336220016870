import { i18n, t } from '@internals/business-translations/src/i18n';

import { CarInfo } from '../fragments/JobMetaFragment';
import { HybridCarType } from '../generated/generated';
import { localizeDateString } from '../localizeDateString';

interface CarInfoItem {
  title: string;
  text?: string | number | null;
}

const mapCarInfo = (
  carMeta: CarInfo,
  getAll: boolean,
  showSensitiveInfo: boolean
): CarInfoItem[] => {
  const basicCarInfo = [
    {
      title: t('job.item.meta.car.brand.title'),
      text: carMeta.brand,
    },
    {
      title: t('job.item.meta.car.model.title'),
      text: carMeta.model,
    },
    {
      title: t('job.item.meta.car.registrationYear.title'),
      text: carMeta.registrationYear,
    },
    {
      title: t('job.item.meta.car.weight.title'),
      text: carMeta.weight
        ? t('general.metrics.inKilograms', { value: carMeta.weight })
        : '',
    },
    {
      title: t('job.item.meta.car.registrationNumber.title'),
      text: showSensitiveInfo
        ? carMeta.licencePlate
        : t('job.item.concealedData'),
    },
    {
      title: t('job.item.meta.car.vin.title'),
      text: showSensitiveInfo ? carMeta.VIMNumber : t('job.item.concealedData'),
    },
  ];

  if (!getAll) {
    return basicCarInfo;
  }

  const remainingCarInfo = [
    {
      title: t('job.item.meta.car.fuelType.title'),
      /*
        t('job.item.meta.car.fuelType.value_DIESEL')
        t('job.item.meta.car.fuelType.value_ELECTRICITY')
        t('job.item.meta.car.fuelType.value_GAS')
        t('job.item.meta.car.fuelType.value_OTHER')
      */
      text: t('job.item.meta.car.fuelType.value', {
        context: carMeta.fuelType,
        defaultValue: '-',
      }),
    },
    {
      title: t('job.item.meta.car.co2Emissions.title'),
      text: carMeta.co2Emissions
        ? t('general.metrics.inKilograms', { value: carMeta.co2Emissions })
        : '',
    },
    {
      title: t('job.item.meta.car.hybrid.title'),
      /*
        t('job.item.meta.car.hybrid.value_NO')
        t('job.item.meta.car.hybrid.value_OTHER')
        t('job.item.meta.car.hybrid.value_YES')
      */
      text: carMeta.hybrid
        ? t('job.item.meta.car.hybrid.value', {
            context: carMeta.hybrid,
            defaultValue: '-',
          })
        : '',
    },
    {
      title: t('job.item.meta.car.hybridCategory.title'),
      text:
        carMeta.hybrid !== HybridCarType.NO && carMeta.hybridCatagory
          ? /*
              t('job.item.meta.car.hybridCategory.value_CHARGABLE')
              t('job.item.meta.car.hybridCategory.value_NONE')
              t('job.item.meta.car.hybridCategory.value_NOTCHARGABLE')
              t('job.item.meta.car.hybridCategory.value_OTHER')
            */
            t('job.item.meta.car.hybridCategory.value', {
              context: carMeta.hybridCatagory.replace('_', ''),
              defaultValue: '-',
            })
          : '',
    },
    {
      title: t('job.item.meta.car.particleFilter.title'),
      text: carMeta.factoryInstalledParticleFilter
        ? t('job.item.meta.car.particleFilter.valueTrue')
        : t('job.item.meta.car.particleFilter.valueFalse'),
    },
    {
      title: t('job.item.meta.car.vehicleGroup.title'),
      text: carMeta.vehicleGroup,
    },
    {
      title: t('job.item.meta.car.seatsFront.title'),
      text: carMeta.seatsFront,
    },
    {
      title: t('job.item.meta.car.maxSeats.title'),
      text: carMeta.maxSeats,
    },
    {
      title: t('job.item.meta.car.engineSize.title'),
      text: carMeta.engineSize
        ? t('job.item.meta.car.engineSize.value', { value: carMeta.engineSize })
        : '',
    },
    {
      title: t('job.item.meta.car.engineHectowatt.title'),
      text: carMeta.engineHectowatt
        ? t('general.metrics.inHectoWatt', { value: carMeta.engineHectowatt })
        : '',
    },
    {
      title: t('job.item.meta.car.lastRoadWorthinessTestDate.title'),
      text: carMeta.lastRoadWorthinessTestDate
        ? localizeDateString(i18n.language, carMeta.lastRoadWorthinessTestDate)
        : '',
    },
    {
      title: t('job.item.meta.car.nextRoadWorthinessTestDate.title'),
      text: carMeta.nextRoadWorthinessTestDate
        ? localizeDateString(i18n.language, carMeta.nextRoadWorthinessTestDate)
        : '',
    },
    {
      title: t('job.item.meta.car.shaftCount.title'),
      text: carMeta.shaftCount
        ? t('job.item.meta.car.shaftCount.value', { count: carMeta.shaftCount })
        : '',
    },
    {
      title: t('job.item.meta.car.width.title'),
      text: carMeta.width
        ? t('general.metrics.inCentimeters', { value: carMeta.width })
        : '',
    },
    {
      title: t('job.item.meta.car.length.title'),
      text: carMeta.length
        ? t('general.metrics.inCentimeters', { value: carMeta.length })
        : '',
    },
    {
      title: t('job.item.meta.car.maxTotalWeight.title'),
      text: carMeta.maxTotalWeight
        ? t('general.metrics.inKilograms', { value: carMeta.maxTotalWeight })
        : '',
    },
    {
      title: t('job.item.meta.car.towbarMaxLoad.title'),
      text: carMeta.towbarMaxLoad
        ? t('general.metrics.inKilograms', { value: carMeta.towbarMaxLoad })
        : '',
    },
    {
      title: t('job.item.meta.car.maxRoofLoad.title'),
      text: carMeta.maxRoofLoad
        ? t('general.metrics.inKilograms', { value: carMeta.maxRoofLoad })
        : '',
    },
    {
      title: t('job.item.meta.car.trailerMaxWeightWithBreak.title'),
      text: carMeta.trailerMaxWeightWithBreak
        ? t('general.metrics.inKilograms', {
            value: carMeta.trailerMaxWeightWithBreak,
          })
        : '',
    },
    {
      title: t('job.item.meta.car.trailerMaxWeightWithoutBreak.title'),
      text: carMeta.trailerMaxWeightWithoutBreak
        ? t('general.metrics.inKilograms', {
            value: carMeta.trailerMaxWeightWithoutBreak,
          })
        : '',
    },
    {
      title: t('job.item.meta.car.taxCode.title'),
      text: carMeta.taxCode,
    },
  ];

  return [...basicCarInfo, ...remainingCarInfo];
};

export const getCarInfo = (
  carMeta: CarInfo,
  getAll: boolean,
  showSensitiveInfo: boolean
) =>
  mapCarInfo(carMeta, getAll, showSensitiveInfo).map(({ title, text }) => ({
    title,
    text: text ? text.toString() : '-',
  }));
