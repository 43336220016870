import { FC } from 'react';

import NotFound from '@components/elements/NotFound';
import Paths from '@router/paths';
import { useTranslation } from 'react-i18next';

import { EMPTY_LIST_CONTAINER_MAX_WIDTH } from './EvaluationRequestNoJobs';

interface RequestEvaluationJobListErrorProps {
  onRefetch: VoidFunction;
}

const EvaluationRequestJobListError: FC<
  React.PropsWithChildren<RequestEvaluationJobListErrorProps>
> = ({ onRefetch }) => {
  const { t } = useTranslation();

  return (
    <NotFound
      maxWidth={EMPTY_LIST_CONTAINER_MAX_WIDTH}
      title={t('evaluation.item.request.listError.title')}
      text={t('evaluation.item.request.listError.text')}
      link={{
        url: Paths.SettingsCustomerService,
        text: t('general.label.customerService'),
      }}
      button={{ text: t('general.error.tryAgain'), onClick: onRefetch }}
    />
  );
};

export default EvaluationRequestJobListError;
