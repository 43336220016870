import { FC, useCallback } from 'react';

import CardHeader from '@components/base/CardHeader';
import { useFlagEnabled } from '@components/base/CheckFlag';
import FilterBadge from '@components/elements/JobsFilters/FilterBadge';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import useAnalytics from '@hooks/useAnalytics';
import useJobsTotalCountQuery from '@hooks/useJobsTotalCountQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { Div, Heading, Button } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface JobsListHeaderNewVersionProps {
  onFiltersOpen: VoidFunction;
  filtersOpen: boolean;
}

const JobsListHeader: FC<JobsListHeaderNewVersionProps> = ({
  onFiltersOpen,
  filtersOpen,
}) => {
  const { t } = useTranslation();
  const { data: totalCount } = useJobsTotalCountQuery();
  const themeContext = useTheme();
  const { canResetFilters, showFilterButtons, resetAllFilters } =
    useJobFiltersContext();
  const { track } = useAnalytics();
  const filtersInHeader = !useFlagEnabled(FeatureFlags.BizJobFilterTree);

  const onFilterOpen = useCallback(() => {
    onFiltersOpen();
    track(ANEventSpace(ANEvent.Opened, ANObject.Filter, ANPage.JobList));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [onFiltersOpen, track]);

  if (filtersOpen) return null;

  return (
    <Div position="sticky" top="70px" zIndex={Layer.sticky}>
      <CardHeader pt="14px" pb="14px" bg="black.black0" height="56px">
        <Div
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Div display="flex">
            <Heading.h4 m={0} fontWeight={themeContext.fontWeights.medium}>
              {totalCount !== null
                ? t('job.list.titleWithCount', { count: totalCount })
                : t('job.list.title')}
            </Heading.h4>
          </Div>
          {filtersInHeader && showFilterButtons && (
            <Div display="flex">
              {canResetFilters && (
                <FilterBadge
                  onClick={resetAllFilters}
                  text={t('job.list.filters.clear')}
                />
              )}
              <Button
                onClick={onFilterOpen}
                variant="primary"
                size="tiny"
                fontSize={1}
                ml={3}
              >
                {t('job.list.filters.open')}
              </Button>
            </Div>
          )}
        </Div>
      </CardHeader>
    </Div>
  );
};

export default JobsListHeader;
