import { NotificationDestinationType } from '../../utils/generated/generated';
import {
  BusinessNotification,
  BusinessNotificationType,
} from '../../utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';
import { NotificationDestinationsEnabled } from '../../utils/query/NotificationDestinationsEnabled/NotificationDestinationsEnabledQuery';
import { NotificationEnabledData } from './notificationSettingsTypes';

const EDITABLE_NOTIFICATIONS = ['ma-business-new-tender'];

export const getEditableNotifications = (
  notificationsData: BusinessNotification[]
): BusinessNotificationType[] => {
  return notificationsData.flatMap((notificationGroup) =>
    notificationGroup.types.filter(
      (type) =>
        EDITABLE_NOTIFICATIONS.includes(type.slug) &&
        type.destinations.some((destination) => destination.isToggleable)
    )
  );
};

export const getNotificationEnabledData = (
  notificationsData: BusinessNotificationType[],
  notificationsEnabledData: NotificationDestinationsEnabled[]
): NotificationEnabledData => {
  const getEnabledStatus = (notificationId: string) =>
    !!notificationsEnabledData.find(
      (destination) => destination.id === notificationId
    );

  return notificationsData.map((notification) => {
    const emailSettings = notification.destinations.find(
      (dest) => dest.type === NotificationDestinationType.EMAIL
    );
    const pushSettings = notification.destinations.find(
      (dest) => dest.type === NotificationDestinationType.PUSH
    );
    return {
      id: notification.id,
      slug: notification.slug,
      destinations: {
        email: emailSettings
          ? {
              id: emailSettings.id,
              isEnabled: getEnabledStatus(emailSettings.id),
              type: NotificationDestinationType.EMAIL,
            }
          : null,
        push: pushSettings
          ? {
              id: pushSettings.id,
              isEnabled: getEnabledStatus(pushSettings.id),
              type: NotificationDestinationType.PUSH,
            }
          : null,
      },
    };
  });
};
