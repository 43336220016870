import { FC } from 'react';

import { ProductPackageAccordion } from '@components/layout/Products/ProductPackageAccordion';
import { ProductPackageCard } from '@components/layout/Products/ProductPackageCard';
import {
  freemiumPackageMock,
  jobPackageMock,
} from '@internals/business-shared/src/components/Products/productMocks';
import { ActivePackageStatus } from '@internals/business-shared/src/components/Products/productTypes';
import {
  getProductPackageStatusText,
  getProductPackageText,
} from '@internals/business-shared/src/components/Products/productUtils';
import { t } from '@utils/i18n';
import { getProduct } from '@utils/product';

// todo: this will be concluded based on company state & product list
const getStatus = (): ActivePackageStatus =>
  ActivePackageStatus.JobPackageActive;

export const ProductActivePackage: FC = () => {
  const { name: productName } = getProduct();
  const activePackageStatus: ActivePackageStatus = getStatus();

  switch (activePackageStatus) {
    case ActivePackageStatus.CompanyNotVerified: {
      const { title, description } = getProductPackageStatusText(
        activePackageStatus,
        productName
      );
      return (
        <ProductPackageCard
          variant="with-icon"
          title={title}
          subtitle={description}
          action={{
            text: t('product.package.action.verifyCompany'),
            // todo: implement handlers
            handler: () => {},
          }}
        />
      );
    }
    case ActivePackageStatus.CompanyVerified: {
      const { title, description } = getProductPackageStatusText(
        activePackageStatus,
        productName
      );
      return (
        <ProductPackageCard
          variant="centered"
          title={title}
          subtitle={description}
          action={{
            text: t('product.package.action.startTrial'),
            handler: () => {},
          }}
        />
      );
    }
    case ActivePackageStatus.FreemiumActive: {
      const { name, description } = getProductPackageText({
        productSlug: 'freemium',
        productName,
        packageDetails: freemiumPackageMock,
      });
      return (
        <ProductPackageAccordion
          packageName={name}
          status={freemiumPackageMock.status}
          description={description}
          onMoreClick={() => {}}
          price={freemiumPackageMock.price}
          numberOfCredits={freemiumPackageMock.numberOfCredits}
          timeDetails={{
            type: 'expiration',
            date: freemiumPackageMock.expirationDate,
          }}
        />
      );
    }
    case ActivePackageStatus.FreemiumExpired: {
      const { title, description } = getProductPackageStatusText(
        activePackageStatus,
        productName
      );
      return (
        <ProductPackageCard
          variant="centered"
          title={title}
          subtitle={description}
          action={{
            text: t('product.package.action.buySubscription'),
            handler: () => {},
          }}
        />
      );
    }
    case ActivePackageStatus.JobPackageActive: {
      const { name, description } = getProductPackageText({
        productSlug: 'job-package',
        productName,
        packageDetails: jobPackageMock,
      });
      return (
        <ProductPackageAccordion
          packageName={name}
          status={jobPackageMock.status}
          description={description}
          onMoreClick={() => {}}
          price={jobPackageMock.price}
          numberOfCredits={jobPackageMock.numberOfCredits}
          timeDetails={{
            type: 'renewal',
            date: jobPackageMock.renewalDate,
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};
