import * as React from 'react';

import { useUserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div, Text, ButtonLink, Avatar } from '@schibsted-smb/fireball';
import getCompanyProfilePreviewUrl from '@utils/getCompanyProfilePreviewUrl';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { HeaderWrapper } from './styled';

const ProfileNavigationHeader = (): React.ReactElement => {
  const { t } = useTranslation();
  const { operatingAs: company } = useUserContext();
  const themeContext = useTheme();
  const { track } = useAnalytics();

  const companyId = company.id;
  const companyName = company.name ?? '';
  const companyLogo = company.logo?.smallPreviewUrl ?? null;

  const onProfilePreviewClick = () => {
    track(
      ANEventSpace(ANEvent.Clicked, ANObject.PreviewProfile, ANPage.Profile)
    );
  };

  return (
    <HeaderWrapper>
      <Avatar
        initials={companyName[0]}
        image={companyLogo}
        size="l"
        backgroundColor={companyLogo ? 'black.black0' : null}
        backgroundSize={companyLogo ? 'contain' : null}
        borderColor="black.black4"
        borderRadius="4px"
        borderStyle={companyLogo ? 'solid' : null}
        borderWidth="4px"
      />
      <Div
        display="flex"
        flexDirection="column"
        ml="15px"
        alignItems="flex-start"
      >
        <Text.span fontSize={4} fontWeight={themeContext.fontWeights.bold}>
          {companyName}
        </Text.span>
        <ButtonLink
          variant="linkPrimary"
          href={getCompanyProfilePreviewUrl(companyId, false)}
          target="_blank"
          p={0}
          onClick={onProfilePreviewClick}
        >
          {t('profile.sidebar.openPage')}
        </ButtonLink>
      </Div>
    </HeaderWrapper>
  );
};

export default ProfileNavigationHeader;
