import { Dispatch, FC, SetStateAction } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  IframeMessageInterface,
  IframeMultiMessage,
} from '@components/base/IframeTalk/IframeMessage';
import { useUserContext } from '@contexts/UserContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { CURRENT_USER_COMPANY_QUERY_business_Business } from '@internals/business-shared/src/utils/generated/generated';
import {
  Div,
  Heading,
  Image,
  ActionButton,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { DropzoneFile, SelectImageModal } from './SelectImageModal';

interface ProfileGraphicBackgroundImageProps {
  setIframeMessage: Dispatch<SetStateAction<IframeMessageInterface>>;
  companyLogo: { file: unknown; previewUrl: string };
  backgroundImage: CURRENT_USER_COMPANY_QUERY_business_Business['profileHeaderImage'];
  setBackgroundImage: Dispatch<
    SetStateAction<
      CURRENT_USER_COMPANY_QUERY_business_Business['profileHeaderImage']
    >
  >;
  onBackgroundImageDelete: VoidFunction;
}

export const ProfileGraphicBackgroundImage: FC<
  ProfileGraphicBackgroundImageProps
> = ({
  setIframeMessage,
  companyLogo,
  backgroundImage,
  setBackgroundImage,
  onBackgroundImageDelete,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const user = useUserContext();
  const [
    isBackgroundImageModalOpen,
    openBackgroundImageModal,
    closeBackgroundImageModal,
  ] = useBoolean();

  const handleImageChanged = (image: DropzoneFile) => {
    setBackgroundImage(image.previewUrl);
    setIframeMessage(
      IframeMultiMessage([
        { name: 'profileHeaderImageV2', value: image.previewUrl },
        {
          name: 'logo',
          value: {
            url: companyLogo.previewUrl || user.operatingAs.logo?.url || '',
          },
        },
      ])
    );
  };
  const handleImageDeleted = () => {
    setBackgroundImage('');
    onBackgroundImageDelete();
  };

  return (
    <Div mx={6}>
      <Heading.h3 mb={2}>
        {t('profile.graphic.backgroundImage.title')}
      </Heading.h3>
      <Text.span mb={2}>
        {t('profile.graphic.backgroundImage.recommendedSize')}
      </Text.span>
      <Div
        mb={7}
        width="100%"
        height="100px"
        position="relative"
        backgroundColor={themeContext.colors.black.black4}
        borderRadius={themeContext.radii[2]}
      >
        {backgroundImage && (
          <Image
            src={backgroundImage}
            width="100%"
            height="100%"
            objectFit="cover"
            borderRadius={themeContext.radii[2]}
          />
        )}
        <Div
          position="absolute"
          top="6px"
          right="6px"
          display="flex"
          flexDirection="row"
        >
          <ActionButton
            width="30px"
            height="30px"
            background="black.black0"
            backgroundHover="primary.base"
            fill="primary.base"
            fillHover="black.black0"
            onClick={openBackgroundImageModal}
            testId="edit-background-image-btn"
          >
            <FireIcon iconName={IconPickerName.Pencil} />
          </ActionButton>
          {backgroundImage && (
            <ActionButton
              width="30px"
              height="30px"
              background="black.black0"
              backgroundHover="red.base"
              fill="red.base"
              fillHover="black.black0"
              onClick={handleImageDeleted}
              testId="delete-background-image-btn"
            >
              <FireIcon iconName={IconPickerName.Delete} />
            </ActionButton>
          )}
        </Div>
      </Div>
      <SelectImageModal
        isOpen={isBackgroundImageModalOpen}
        activeBackgroundImage={backgroundImage ?? ''}
        onImageChanged={handleImageChanged}
        onClose={closeBackgroundImageModal}
      />
    </Div>
  );
};
