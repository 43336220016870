import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { StyledJobNavigationButton } from '@components/layout/Job/styled/StyledJobNavigationButton';
import { useJobNavigationContext } from '@contexts/JobNavigationContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTheme } from 'styled-components';

import { StyledJobNavigation } from './styled/StyledJobNavigation';

export const JobNavigation: FC = () => {
  const themeContext = useTheme();
  const {
    onNextJob,
    onPreviousJob,
    hasNextJob,
    hasPreviousJob,
    showNavigation,
  } = useJobNavigationContext();

  if (!showNavigation) return null;

  return (
    <StyledJobNavigation>
      <StyledJobNavigationButton
        onClick={onPreviousJob}
        variant="tertiary"
        disabled={!hasPreviousJob}
        position="left"
      >
        <FireIcon
          variant="flat"
          iconName={IconPickerName.ChevronLeft}
          fill={
            hasPreviousJob
              ? themeContext.colors.black.black9
              : themeContext.colors.black.black5
          }
          width={24}
          height={24}
        />
      </StyledJobNavigationButton>
      <StyledJobNavigationButton
        onClick={onNextJob}
        variant="tertiary"
        disabled={!hasNextJob}
        position="right"
      >
        <FireIcon
          variant="flat"
          iconName={IconPickerName.ChevronRight}
          fill={
            hasNextJob
              ? themeContext.colors.black.black9
              : themeContext.colors.black.black5
          }
          width={24}
          height={24}
        />
      </StyledJobNavigationButton>
    </StyledJobNavigation>
  );
};
