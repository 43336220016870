import { CSSProperties } from 'react';

import { Input } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
`;

const IconPickerWrapper = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
`;

export interface InputWrapperProps {
  stylesWhenFilled: Pick<CSSProperties, 'border' | 'padding'>;
}

const InputWrapper = styled(Input)<InputWrapperProps>`
  font-size: 14px;
  margin: 0;

  &,
  &:focus {
    padding: 0 0 0 ${(props) => props.theme.space[7]}px;
  }

  ${({ stylesWhenFilled }) =>
    stylesWhenFilled &&
    `
      &, &:hover, &:focus {
        border: ${stylesWhenFilled.border};
        padding: ${stylesWhenFilled.padding};
      }
    `}
`;

export { SearchInputWrapper, IconPickerWrapper, InputWrapper };
