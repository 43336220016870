import { isEven } from './numberUtils';

export const PHONE_REGEXP = /\b([0-9\s-]{7,}\d)\b/gm;
export const MIN_PHONE_LENGTH = 8;

const phoneReplace = (replacement: string) => (match: string) =>
  match.trim().slice(0, -replacement.length) + replacement;
export const replaceWithString = (message: string, replacement = '***') => {
  const replacementLength = replacement.length;
  const userString = message.replaceAll(/\s+/gm, ' ').trim();
  const userStringDigitsLength = userString.replace(/\D/g, '').length;

  if (userString.length <= replacementLength) {
    return replacement; // Return unchanged if the input is too short
  }

  if (userStringDigitsLength < MIN_PHONE_LENGTH) {
    return userString; // Return unchanged if the input is shorter than the minimum phone length
  }

  return userString.replace(
    new RegExp(PHONE_REGEXP),
    phoneReplace(replacement)
  );
};

export const hasPhoneNumber = (message: string): boolean =>
  new RegExp(PHONE_REGEXP).test(message);

/*
  This function is used to split the message by phone number and return the array of strings
 */

export const splitMessageByPhoneNumber = (message: string): string[] =>
  message
    .replace(/[^\S\n]+/gm, ' ')
    .trim()
    .split(new RegExp(PHONE_REGEXP));

/*
  This function is used to check if the message chunk (from splitMessageByPhoneNumber)  is a phone number
 */
export const isPhoneNumberPart = (
  phoneLikeString: string,
  index: number
): boolean => {
  if (isEven(index)) {
    return false;
  }
  const userStringDigitsLength = phoneLikeString.replace(/\D/g, '').length;

  return userStringDigitsLength >= MIN_PHONE_LENGTH;
};
