import { FC } from 'react';

import useAnalytics from '@hooks/useAnalytics';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import { SavedFiltersFeatureTourStep } from '@internals/business-shared/src/components/SavedFilters/SavedFiltersFeatureTourContext';
import { SavedFilterNavigationActionType } from '@internals/business-shared/src/components/SavedFilters/types';
import {
  getSavedFilterNavigationActionProps,
  SavedFilterButtonActionProps,
} from '@internals/business-shared/src/components/SavedFilters/utils';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Button, Div, Text } from '@schibsted-smb/fireball';

export const SavedFilterNavigationAction: FC = () => {
  const { track } = useAnalytics();
  const {
    filterState: { action },
    openModal,
  } = useSavedFiltersContext();

  if (action === SavedFilterNavigationActionType.None) {
    return null;
  }

  const actionContent = getSavedFilterNavigationActionProps(action);

  const onClick = (actionProps: SavedFilterButtonActionProps) => {
    openModal(actionProps.modalToTrigger);
    track(ANEventSpace(ANEvent.Clicked, ANObject.FilterPrompt, ANPage.JobList));
  };

  return (
    <Div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={4}
      px={6}
      backgroundColor="black.black1"
      minHeight="56px"
    >
      <Text.p fontWeight="medium" m={0} fontSize="14px">
        {actionContent.text}
      </Text.p>
      {actionContent.variant === 'button' && (
        <Button
          ml={4}
          size="tiny"
          variant={actionContent.buttonVariant}
          onClick={() => onClick(actionContent)}
          {...(action === SavedFilterNavigationActionType.SaveNew && {
            'data-tooltip-id': SavedFiltersFeatureTourStep.SaveFilter,
          })}
        >
          {actionContent.buttonText}
        </Button>
      )}
    </Div>
  );
};
