import gql from 'graphql-tag';

import {
  PRIMARY_BUSINESS_CONTACT as PRIMARY_BUSINESS_CONTACTPayload,
  PRIMARY_BUSINESS_CONTACT_primaryBusinessContact,
} from '../../generated/generated';

export type PrimaryBusinessContactPayload = PRIMARY_BUSINESS_CONTACTPayload;
export type PrimaryBusinessContact =
  PRIMARY_BUSINESS_CONTACT_primaryBusinessContact;

export const PRIMARY_BUSINESS_CONTACT = gql`
  query PRIMARY_BUSINESS_CONTACT {
    primaryBusinessContact {
      id
      isCurrentUserPrimaryContact
      primaryContactEmail
    }
  }
`;
