import { FC, PropsWithChildren, ReactNode } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Heading } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import SectionContainer from './styled/SectionContainer';
import SectionIconContainer from './styled/SectionIconContainer';

interface SettingsSectionProps {
  icon: IconPickerName;
  title: string;
  children: ReactNode;
  testId?: string;
}

const SettingsSection: FC<PropsWithChildren<SettingsSectionProps>> = ({
  icon,
  title,
  children,
  testId,
}: SettingsSectionProps) => {
  const themeContext = useTheme();
  return (
    <SectionContainer data-testid={`section-container-${testId}`}>
      <Div
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        mb={5}
      >
        <SectionIconContainer>
          <FireIcon
            iconName={icon}
            fill={themeContext.colors.primary.base}
            variant="color"
          />
        </SectionIconContainer>
        <Heading.h3 mb={0} data-testid={`section-title-${testId}`}>
          {title}
        </Heading.h3>
      </Div>
      {children}
    </SectionContainer>
  );
};

export default SettingsSection;
