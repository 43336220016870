import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { ButtonLink, Div, Text } from '@schibsted-smb/fireball';
import {
  getPrivacySettingsUrl,
  getProductTermsOfServiceUrl,
} from '@utils/product';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface PrivacyAndTermsLinkProps {
  title: string;
  url: string;
}

const PrivacyAndTermsLink: FC<PrivacyAndTermsLinkProps> = ({ title, url }) => {
  const themeContext = useTheme();

  return (
    <ButtonLink variant="secondary" href={url} fullWidth py={6} px={5} mb={5}>
      <Div
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Text.p color={themeContext.text.black} mb={0}>
          {title}
        </Text.p>
        <FireIcon
          iconName={IconPickerName.Share}
          variant="flat"
          height={24}
          width={24}
          fill={themeContext.colors.primary.base}
        />
      </Div>
    </ButtonLink>
  );
};

export const PrivacyAndTerms: FC = () => {
  const { t } = useTranslation();
  const termsOfServiceUrl = getProductTermsOfServiceUrl();
  const privacySettingsUrl = getPrivacySettingsUrl();

  return (
    <SettingsContainer>
      <SettingsSection
        icon={IconPickerName.Fingerprint}
        title={t('settings.privacyAndTerms.title')}
      >
        <PrivacyAndTermsLink
          title={t('settings.privacyAndTerms.privacy')}
          url={privacySettingsUrl}
        />
        <PrivacyAndTermsLink
          title={t('settings.privacyAndTerms.terms')}
          url={termsOfServiceUrl}
        />
      </SettingsSection>
    </SettingsContainer>
  );
};
