import * as React from 'react';

import { ReactComponent as Illustration } from '@internals/business-shared/src/assets/illustrations/articlenotfound.svg';
import { Div, Heading, Button, Text } from '@schibsted-smb/fireball';
import { Link } from 'react-router-dom';

interface NotFoundProps {
  title: string;
  text: string;
  queryText?: string;
  link?: {
    url: string;
    text: string;
  };
  button?: {
    text: string;
    onClick: () => void;
  };
  maxWidth?: string;
}

const NotFound: React.FC<React.PropsWithChildren<NotFoundProps>> = ({
  title,
  text,
  queryText,
  link,
  button,
  maxWidth = '315px',
}) => {
  return (
    <Div
      py={7}
      minHeight="420px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-testid="not-found"
    >
      <Div
        maxWidth={maxWidth}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Illustration width="260px" />
        <Heading.h4 my={5}>{title}</Heading.h4>
        <Text.p mb={5} textAlign="center">
          {text}{' '}
          {!!queryText && (
            <Text.span display="inline" fontStyle="italic">
              &quot;{queryText}&quot;
            </Text.span>
          )}
          {!!link && <Link to={link.url}> {link.text}.</Link>}
        </Text.p>
        {!!button && (
          <Button variant="primary" onClick={button.onClick} px="25px" py={4}>
            {button.text}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default NotFound;
