import gql from 'graphql-tag';

import {
  AUTHORIZE_BOLIGMAPPA_INTEGRATIONVariables,
  AUTHORIZE_BOLIGMAPPA_INTEGRATION as AUTHORIZE_BOLIGMAPPA_INTEGRATION_Result,
} from '../../generated/generated';

export type AuthorizeBoligmappaIntegrationMutationVariables =
  AUTHORIZE_BOLIGMAPPA_INTEGRATIONVariables;
export type AuthorizeBoligmappaIntegrationMutationPayload =
  AUTHORIZE_BOLIGMAPPA_INTEGRATION_Result;
export const AUTHORIZE_BOLIGMAPPA_INTEGRATION = gql`
  mutation AUTHORIZE_BOLIGMAPPA_INTEGRATION(
    $input: AuthorizeBoligmappaIntegrationInput!
  ) {
    authorizeBoligmappaIntegration(input: $input) {
      id
      status
    }
  }
`;
