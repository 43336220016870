import gql from 'graphql-tag';

import {
  CARD_LIST as CARD_LISTPayload,
  CARD_LIST_cardList,
} from '../../generated/generated';

export type CardListPayload = CARD_LISTPayload;
export type CardList = CARD_LIST_cardList;

export const CARD_LIST = gql`
  query CARD_LIST {
    cardList {
      id
      maskedCardNumber
    }
  }
`;
