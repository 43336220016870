const INPUT_TAGS = ['INPUT', 'TEXTAREA'];

export const isEventTriggeredFromInput = (
  eventTarget: EventTarget
): boolean => {
  return (
    eventTarget instanceof HTMLElement &&
    INPUT_TAGS.includes(eventTarget.tagName)
  );
};
