import gql from 'graphql-tag';

import {
  SET_JOB_AS_READ_MUTATIONVariables,
  SET_JOB_AS_READ_MUTATION as SET_JOB_AS_READ_MUTATION_Result,
} from '../../generated/generated';

export type SetJobAsReadMutationPayload = SET_JOB_AS_READ_MUTATION_Result;
export type SetJobAsReadMutationVariables = SET_JOB_AS_READ_MUTATIONVariables;

export const SET_JOB_AS_READ_MUTATION = gql`
  mutation SET_JOB_AS_READ_MUTATION($id: ID!, $cost: Int) {
    setJobAsRead(input: { jobId: $id, cost: $cost }) {
      success
    }
  }
`;
