export const selectEvenlyFromSets = <T1, T2>(
  max: number,
  set1: T1[],
  set2: T2[]
): [T1[], T2[]] => {
  // We want to get equal amount of elements from each set so we find half the max
  const med = max / 2;
  // We want to prioritize set1 therefore floor count2 and round up for count 1
  const count1 = Math.ceil(med);
  const count2 = Math.floor(med);
  // If set2 doesn't have enought elements to fulfill the count2 add the difference to count1
  const count1prime = count1 - Math.min(set2.length - count2, 0);
  // If set1 doesn't have enough elements to fulfill the count1 add the difference to count2
  // Since count1prime can only be grater than count1 if set2 cannot fulfill count2,
  // we can use count1 in our condition, as in such a case there is no point in increasing count2
  let count2prime = count2;
  if (count1 > set1.length) count2prime -= Math.min(set1.length - count1, 0);
  // return the calculated counts of elements from each set
  return [set1.slice(0, count1prime), set2.slice(0, count2prime)];
};

export default selectEvenlyFromSets;
