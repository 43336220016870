import gql from 'graphql-tag';

import {
  GET_USER_SETTINGS_QUERY as GET_USER_SETTINGS_QUERY_Result,
  GET_USER_SETTINGS_QUERY_getUserSettings,
} from '../../generated/generated';

export type GetUserSettingsQueryPayload = GET_USER_SETTINGS_QUERY_Result;
export type UserSettings = GET_USER_SETTINGS_QUERY_getUserSettings;

export const GET_USER_SETTINGS_QUERY = gql`
  query GET_USER_SETTINGS_QUERY {
    getUserSettings {
      ReceiveNewsletters
    }
  }
`;
