import { FC } from 'react';

import { useIsMobile, useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Column } from '@schibsted-smb/fireball';

import { useBuySubscriptionModalContext } from '../../ModalContext';
import { PackageCard } from '../PackageCard';

export const Packages: FC = () => {
  const { packageList } = useBuySubscriptionModalContext();
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Column
      alignItems={isMobile && 'center'}
      display="flex"
      flexDirection={isTabletOrMobile ? 'column' : 'row'}
      justifyContent="center"
      pb={!isTabletOrMobile && 5}
      pt={!isTabletOrMobile && 80}
      px={0}
    >
      {packageList.map(({ id, ...rest }) => (
        <PackageCard key={id} {...rest} />
      ))}
    </Column>
  );
};
