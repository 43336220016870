import gql from 'graphql-tag';

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    phone
    operatingAs {
      id
    }
  }
`;
export default USER_FRAGMENT;
