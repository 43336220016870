import { ApolloCache } from '@apollo/client';

import { UserCompany } from '../../types/User';
import { UPDATE_BUSINESS_LOGO } from '../../utils/generated/generated';
import {
  isUpdateBusinessLogoSuccessResponse,
  UpdateBusinessLogoPayload,
} from '../../utils/mutation/UpdateBusinessLogo/UpdateBusinessLogo';

const updateBusinessLogoUpdate =
  (businessId?: UserCompany['id']) =>
  (
    cache: ApolloCache<UPDATE_BUSINESS_LOGO>,
    { data: mutationPayload }: { data?: UpdateBusinessLogoPayload | null }
  ) => {
    if (
      !businessId ||
      !isUpdateBusinessLogoSuccessResponse(mutationPayload?.uploadBusinessLogo)
    )
      return;
    const updatedLogo = mutationPayload?.uploadBusinessLogo.logo;
    cache.modify({
      id: cache.identify({ id: businessId, __typename: 'Business' }),
      fields: {
        logo() {
          return updatedLogo;
        },
      },
    });
  };

export { updateBusinessLogoUpdate };
