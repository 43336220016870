import * as React from 'react';

import { useMutation } from '@apollo/client';
import ErrorAlert from '@components/base/ErrorAlert';
import NewMessage from '@components/elements/NewMessage/NewMessage';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import useStorage, { STORE_CHAT_PREFIX } from '@hooks/useStorage';
import {
  sendMessageOptimisticResponse,
  sendMessageUpdate,
} from '@internals/business-shared/src/cache/updates/sendMessage';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { SendMessageResponseError } from '@internals/business-shared/src/utils/errors/SendMessageResponseError';
import { FileWithPreview } from '@internals/business-shared/src/utils/FileUtils';
import {
  SEND_MESSAGE,
  SendMessageMutationPayload,
  SendMessageMutationVariables,
} from '@internals/business-shared/src/utils/mutation/SendMessage/SendMessageMutation';
import { CHAT_MESSAGE_SORT_DIRECTION } from '@root/src/apollo/cache';

import ChatMessageListContainer from './styled/ChatMessageListContainer';
import MessageForm from './styled/MessageForm';

export interface ChatMessageFormProps {
  chatId: string;
  isDisabled?: boolean;
}

const ChatMessageForm: React.FC<ChatMessageFormProps> = ({
  chatId,
  isDisabled,
}) => {
  const { get, set, remove } = useStorage(STORE_CHAT_PREFIX);
  const initialMessage = get(chatId)?.data?.message ?? '';
  const [message, setMessage] = React.useState(initialMessage);
  const [files, setFiles] = React.useState<FileWithPreview[]>([]);
  const [sendMessageErrors, setSendMessageErrors] =
    React.useState<GQLErrorState>({});
  const user = useUserContext();
  const { track } = useAnalytics();

  const [sendMessage] = useMutation<
    SendMessageMutationPayload,
    SendMessageMutationVariables
  >(SEND_MESSAGE, {
    onCompleted: (data) => {
      const sendMessageResponseError = new SendMessageResponseError(
        'SendMessagePayload'
      );
      if (
        sendMessageResponseError.hasResponseError(
          data.sendMessage,
          setSendMessageErrors
        )
      ) {
        return;
      }
      setSendMessageErrors({});
      track(ANEventSpace(ANEvent.Sent, ANObject.Message, ANPage.Messages));
    },
    update: sendMessageUpdate(chatId, CHAT_MESSAGE_SORT_DIRECTION, user),
  });

  const handleSetMessage = (value: string): void => {
    set(chatId, { message: value });
    setMessage(value);
  };

  const handleSubmit = (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const newFiles = files.map((file) => file.file);
    e.preventDefault();
    sendMessage({
      variables: {
        chatId,
        message,
        ...(newFiles.length && { files: newFiles }),
      },
      optimisticResponse: sendMessageOptimisticResponse(message, files),
    });
    setMessage('');
    setFiles([]);
    remove(chatId);
  };

  React.useEffect(() => {
    setMessage(get(chatId)?.data?.message ?? '');
  }, [chatId]);

  return (
    <ChatMessageListContainer>
      <MessageForm
        onSubmit={handleSubmit}
        data-testid="message-form"
        className="no-print"
      >
        <fieldset disabled={isDisabled}>
          <NewMessage
            message={message}
            setMessage={handleSetMessage}
            files={files}
            setFiles={setFiles}
            acceptAttachmentOnlyMessage
          />
          {sendMessageErrors && <ErrorAlert errors={sendMessageErrors} />}
        </fieldset>
      </MessageForm>
    </ChatMessageListContainer>
  );
};

export default ChatMessageForm;
