import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  isJobAccessPayload,
  JOB_ACCESS_QUERY,
  JobAccess,
  JobAccessQueryPayload,
  JobAccessQueryVariables,
} from '../../utils/query/JobAccess/JobAccessQuery';

interface JobAccessQueryResult extends QueryResult {
  data: JobAccess;
}

export const useJobAccessQuery = (
  jobId: JobAccessQueryVariables['id'],
  options?: QueryHookOptions<JobAccessQueryPayload, JobAccessQueryVariables>
): JobAccessQueryResult => {
  const { data, ...rest } = useQuery<
    JobAccessQueryPayload,
    JobAccessQueryVariables
  >(JOB_ACCESS_QUERY, {
    variables: { id: jobId },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const result = useMemo(() => {
    return {
      data:
        data && isJobAccessPayload(data.jobAccess)
          ? data.jobAccess.access
          : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
