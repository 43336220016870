import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import AskModal from '@components/elements/AskModal';
import CustomerServiceCardHeader from '@components/elements/CustomerServiceCardHeader';
import CustomerServiceDropdown from '@components/elements/CustomerServiceDropdown';
import CustomerServiceListItem from '@components/elements/CustomerServiceListItem';
import CustomerServicePersonData from '@components/elements/CustomerServicePersonData';
import NotFound from '@components/elements/NotFound';
import Evaluated from '@components/layout/CustomerService/Evaluation/Evaluated';
import EvaluationList from '@components/layout/CustomerService/Evaluation/EvaluationList';
import EvaluationRequest from '@components/layout/CustomerService/Evaluation/EvaluationRequest';
import EvaluationValue from '@components/layout/CustomerService/Evaluation/EvaluationValue';
import { usePageAnalytics } from '@hooks/useAnalytics';
import useQuerySearchParams from '@hooks/useQuerySearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { BusinessEvaluationsItem } from '@internals/business-shared/src/utils/query/BusinessEvaluations/BusinessEvaluationsQuery';
import Paths, { Params } from '@router/paths';
import { getAddressString } from '@utils/address';
import ContentLoader from '@utils/contentLoaders';
import getJobItemSearchParam from '@utils/url';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Evaluation: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState(1);
  const containerRef = useRef<any>();
  const [isChooseCustomerOpen, setIsChooseCustomerOpen] = useState(false);
  const [askCustomerToBeChosen, setAskCustomerToBeChosen] = useState(false);
  const [askCustomerToBeChosenConfirm, setAskCustomerToBeChosenConfirm] =
    useState(false);
  const [openedEvaluation, setOpenedEvaluation] = useState<string | null>(null);
  const { evaluation } = useQuerySearchParams();
  const [, resetParams] = useSetSearchParams();

  const onEvaluationClose = useCallback(() => {
    setOpenedEvaluation(null);
    resetParams([Params.Evaluation]);
  }, [resetParams]);

  // In case of evaluation ID is given in URL, open the evaluation
  useEffect(() => {
    if (!evaluation) {
      return;
    }
    setOpenedEvaluation(evaluation[0]);
  }, [evaluation]);

  const evaluatedDropdownItems = [
    {
      id: '1',
      text: t('customer.item.dropdown.replyToEvaluationButton'),
      onClick: (currentEvaluationNode: BusinessEvaluationsItem) => {
        setOpenedEvaluation(currentEvaluationNode.id);
      },
    },
    {
      id: '2',
      text: t('customer.item.dropdown.seeJobButton'),
      onClick: (currentEvaluationNode: BusinessEvaluationsItem) => {
        navigate({
          search: getJobItemSearchParam(currentEvaluationNode.job.id),
        });
      },
    },
  ];

  usePageAnalytics(ANSpace(ANObject.Evaluation, ANPage.Customers), {
    list: 1,
  });

  return (
    <>
      <EvaluationRequest
        isOpen={isChooseCustomerOpen}
        onClose={() => setIsChooseCustomerOpen(false)}
      />

      {(askCustomerToBeChosen || askCustomerToBeChosenConfirm) && (
        <AskModal
          isAskModalOpen={askCustomerToBeChosen}
          isAskConfirmModalOpen={askCustomerToBeChosenConfirm}
          closeAskModal={() => setAskCustomerToBeChosen(false)}
          openConfirmModal={() => setAskCustomerToBeChosenConfirm(true)}
          closeConfirmModal={() => setAskCustomerToBeChosenConfirm(false)}
          illustration="askforevaluation"
          title={t('general.action.requestEvaluation')}
          text={t('customer.item.askToBeChosen.evaluation.text')}
          buttonText={t('general.action.requestEvaluation')}
          confirmTitle={t('general.label.requestSent')}
          confirmText={t('customer.item.askToBeChosen.evaluation.text')}
          confirmButtonText={t(
            'customer.item.askToBeChosenConfirm.evaluation.returnToEvaluationOverviewButton'
          )}
        />
      )}
      {openedEvaluation && (
        <Evaluated
          onClose={onEvaluationClose}
          evaluationId={openedEvaluation}
        />
      )}
      <CustomerServiceCardHeader
        iconName={IconPickerName.Evaluation}
        title={t('evaluation.list.title')}
        buttonText={t('general.action.requestEvaluation')}
        onButtonClick={() => setIsChooseCustomerOpen(true)}
        buttonPadding={4}
      />
      <div ref={containerRef}>
        <EvaluationList
          renderEvaluation={(singleEvaluation) => (
            <CustomerServiceListItem
              key={singleEvaluation.node.id}
              firstColumn={
                <CustomerServicePersonData
                  name={getUserName(singleEvaluation.node.job.owner)}
                  details={getAddressString(singleEvaluation.node.job.address)}
                />
              }
              circledButton={
                <div
                  data-tooltip-id="tooltip-eval-item"
                  data-tooltip-place="bottom"
                  data-tooltip-content={t('chat.item.seeMore')}
                >
                  <CustomerServiceDropdown
                    parentElement={containerRef}
                    items={evaluatedDropdownItems}
                    evaluation={singleEvaluation.node}
                  />
                </div>
              }
              thirdColumn={
                <EvaluationValue
                  value={singleEvaluation.node.score}
                  unfilled={singleEvaluation.node.score < 4}
                />
              }
              onClick={() => setOpenedEvaluation(singleEvaluation.node.id)}
              testId={`evaluation-${singleEvaluation.node.id}`}
            />
          )}
          notFoundComponent={
            <NotFound
              title={t('evaluation.list.noEvaluations')}
              text={t('evaluation.list.text')}
              link={{
                url: Paths.SettingsCustomerService,
                text: t('general.label.customerService'),
              }}
              button={{
                text: t('evaluation.list.reloadPage'),
                onClick: () => window.location.reload(),
              }}
            />
          }
          loaderComponent={
            <ContentLoader.CustomersListItems uniqueKey="loader-customer-list-item" />
          }
        />
        <CustomTooltip id="tooltip-eval-item" />
      </div>
    </>
  );
};

export default Evaluation;
