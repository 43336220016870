import { FC, useState } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, DefaultComposeProps } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { FireIcon } from '../../base/FireIcon/FireIcon';

type JobsDirectRegistrationBannerProps = DefaultComposeProps;

export const JobsDirectRegistrationBanner: FC<
  JobsDirectRegistrationBannerProps
> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isClosed, setIsClosed] = useState(false);

  const handleClose = () => {
    setIsClosed(true);
  };

  if (isClosed) {
    return null;
  }

  return (
    <Div
      backgroundColor={theme.colors.gray.lightest}
      flexDirection="row"
      px={5}
      py={5}
      alignItems="center"
      justifyContent="space-between"
      mb={isMobile ? 0 : 5}
      {...props}
    >
      <Div display="flex" justifyContent="space-between">
        <Text.p mb={2} fontSize={theme.fontSizes[3]} fontWeight={450}>
          {t('job.list.banner.directRegistration.title')}
        </Text.p>
        <Div onClick={handleClose}>
          <FireIcon
            iconName={IconPickerName.Cross}
            fill={theme.colors.primary.darkest}
            width={10}
            height={10}
          />
        </Div>
      </Div>
      <Div>
        <Text.p mb={0}>
          {t('job.list.banner.directRegistration.description')}
        </Text.p>
      </Div>
    </Div>
  );
};
