import { useCallback, useMemo } from 'react';

import { TOKEN_COOKIE_NAME } from '@utils/cookies';
import isLocalhost from '@utils/isLocalhost';
import { useCookies } from 'react-cookie';
import { CookieSetOptions } from 'universal-cookie';

import { getPublicDomain } from '../utils/getPublicDomain';

interface useTokenCookiesReturnType {
  tokenCookie: string;
  setTokenCookie: (value: string, options?: CookieSetOptions) => void;
  removeTokenCookie: (options?: CookieSetOptions) => void;
}

const getDefaultCookieDomain = (): string => {
  if (isLocalhost) return 'localhost';
  return `.${getPublicDomain()}`;
};

const useTokenCookies = (): useTokenCookiesReturnType => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const tokenCookie = useMemo(() => cookies[TOKEN_COOKIE_NAME], [cookies]);

  const setTokenCookie = useCallback(
    (
      value: string,
      options = {
        domain: getDefaultCookieDomain(),
        path: '/',
      } as CookieSetOptions
    ) => {
      // only set cookie on localhost, gql sets it automatically for us on server side
      if (isLocalhost) {
        setCookie(TOKEN_COOKIE_NAME, value, options);
      }
    },
    [setCookie]
  );

  const removeTokenCookie = useCallback(
    (options = { domain: getDefaultCookieDomain() } as CookieSetOptions) => {
      // remove all instances of the MA-Auth that are set manually
      removeCookie(TOKEN_COOKIE_NAME, options);
      removeCookie(TOKEN_COOKIE_NAME);
    },
    [removeCookie]
  );

  const tokenCookiesUtils = useMemo(
    () => ({ tokenCookie, setTokenCookie, removeTokenCookie }),
    [tokenCookie, setTokenCookie, removeTokenCookie]
  );
  return tokenCookiesUtils;
};

export default useTokenCookies;
