import { FC, FocusEvent } from 'react';

import InlineErrorMessage from '@components/base/InlineErrorMessage';
import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { StyledTextInput } from '../styled/StyledTextInput';
import { StyledTitleInput } from '../styled/StyledTitleInput';
import { useMessageTemplateFormik } from '../useMessageTemplateFormik';
import { MobileBackButton } from './MobileBackButton';
import { MobileMessageTemplateLayout } from './MobileMessageTemplateLayout';

interface TemplateFormProps {
  template: MessageTemplate;
  onBackClick: VoidFunction;
  onChange: (template: Pick<MessageTemplate, 'title' | 'text'>) => void;
  onSave: (template: MessageTemplate) => void;
  onDelete: (template: MessageTemplate) => void;
  loadingAddMessageTemplate: boolean;
  loadingUpdateMessageTemplate: boolean;
  loadingDeleteMessageTemplate: boolean;
}

export const MobileTemplateForm: FC<TemplateFormProps> = ({
  template,
  onBackClick,
  onSave,
  onDelete,
  onChange,
  loadingUpdateMessageTemplate,
  loadingDeleteMessageTemplate,
  loadingAddMessageTemplate,
}) => {
  const { t } = useTranslation();
  const formik = useMessageTemplateFormik(template, onSave, onChange);
  const actionInProgress =
    loadingAddMessageTemplate ||
    loadingUpdateMessageTemplate ||
    loadingDeleteMessageTemplate;

  const onInputBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    formik.handleBlur(e);
    if (formik.isValid && formik.dirty && !actionInProgress) {
      formik.submitForm();
    }
  };
  return (
    <MobileMessageTemplateLayout
      header={<MobileBackButton onClick={onBackClick} />}
      footer={
        <Div>
          <Button
            fullWidth
            onClick={formik.submitForm}
            isLoading={
              loadingAddMessageTemplate || loadingUpdateMessageTemplate
            }
            variant="primary"
            disabled={!formik.isValid || actionInProgress}
            mb={4}
          >
            {t('general.label.save')}
          </Button>
          <Button
            fullWidth
            onClick={() => onDelete(template)}
            isLoading={loadingDeleteMessageTemplate}
            variant="danger"
            disabled={actionInProgress}
          >
            {t('chat.item.template.form.delete')}
          </Button>
        </Div>
      }
    >
      <Div width="100%" mb={5}>
        <StyledTitleInput
          {...formik.getFieldProps('title')}
          unlabeled
          placeholder={t('chat.item.template.form.title.placeholder')}
          onBlur={onInputBlur}
        />
        {formik.touched.title && formik.errors.title && (
          <InlineErrorMessage>{formik.errors.title}</InlineErrorMessage>
        )}
      </Div>
      <Div
        flexGrow={1}
        display="flex"
        width="100%"
        flexDirection="column"
        alignContent="stretch"
      >
        <StyledTextInput
          {...formik.getFieldProps('text')}
          containersProps={{ flexGrow: 1, alignItems: 'stretch' }}
          unlabeled
          placeholder={t('chat.item.template.form.text.placeholder')}
          onBlur={onInputBlur}
        />
        {formik.touched.text && formik.errors.text && (
          <InlineErrorMessage>{formik.errors.text}</InlineErrorMessage>
        )}
      </Div>
    </MobileMessageTemplateLayout>
  );
};
