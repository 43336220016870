import gql from 'graphql-tag';

import {
  INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT as INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENTPayload,
  INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENTVariables,
} from '../../generated/generated';

export type InitiateSelfServiceSubscriptionPaymentPayload =
  INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENTPayload;
export type InitiateSelfServiceSubscriptionPaymentVariables =
  INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENTVariables;

export const INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT = gql`
  mutation INITIATE_SELF_SERVICE_SUBSCRIPTION_PAYMENT(
    $input: InitiateSelfServiceSubscriptionPaymentInput!
  ) {
    initiateSelfServiceSubscriptionPayment(input: $input)
  }
`;
