import * as React from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { ActionButton } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

interface JobFavouriteButtonProps {
  isFavourite: boolean;
  onClick: VoidFunction;
}

const uniqidConst = uniqid();

const JobFavouriteButton: React.FC<
  React.PropsWithChildren<JobFavouriteButtonProps>
> = ({ isFavourite, onClick }) => {
  const { t } = useTranslation();

  return isFavourite ? (
    <div>
      <CustomTooltip id={uniqidConst} />
      <ActionButton
        background="orange.base"
        backgroundHover="black.black7"
        borderColor="orange.base"
        fill="black.black0"
        fillHover="black.black0"
        data-tooltip-id={uniqidConst}
        data-tooltip-place="bottom"
        data-tooltip-content={t('job.action.unfavourite')}
        data-testid="remove-job-from-favourites-action-button"
        onClick={onClick}
      >
        <FireIcon iconName={IconPickerName.Favourite} variant="flat" />
      </ActionButton>
    </div>
  ) : (
    <div>
      <CustomTooltip id={uniqidConst} />
      <ActionButton
        background="black.black0"
        backgroundHover="orange.base"
        fill="orange.base"
        fillHover="black.black0"
        data-tooltip-id={uniqidConst}
        data-tooltip-place="bottom"
        data-tooltip-content={t('job.label.FAVOURITE')}
        data-testid="add-job-to-favourites-action-button"
        onClick={onClick}
      >
        <FireIcon iconName={IconPickerName.Favourite} variant="flat" />
      </ActionButton>
    </div>
  );
};

export default JobFavouriteButton;
