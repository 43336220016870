import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterBadgeContainer = styled(Div)`
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  &:not(:first-of-type) {
    padding-left: ${({ theme }) => `${theme.space[3]}px`};
  }
`;

export default FilterBadgeContainer;
