import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const UnreadDot = styled(Div)`
  margin: 0 0 0;
  height ${({ theme }) => theme.space[4] - theme.space[1]}px;
  width ${({ theme }) => theme.space[4] - theme.space[1]}px;
  background-color: ${({ theme }) => theme.colors.primary.base};
  border-radius: 50%;
  display: inline-block;
`;

export default UnreadDot;
