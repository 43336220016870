import gql from 'graphql-tag';

import {
  COMPANY_SETTINGS_QUERY as COMPANY_SETTINGS_QUERY_Result,
  COMPANY_SETTINGS_QUERYVariables,
  COMPANY_SETTINGS_QUERY_businessSettings,
} from '../../generated/generated';

export type CompanySettingsPayload = COMPANY_SETTINGS_QUERY_Result;
export type CompanySettingsVariables = COMPANY_SETTINGS_QUERYVariables;
export type CompanySettings = COMPANY_SETTINGS_QUERY_businessSettings;

export const COMPANY_SETTINGS_QUERY = gql`
  query COMPANY_SETTINGS_QUERY($id: ID!) {
    businessSettings(businessId: $id) {
      id
      JobFolders
      NumAnswers
      NumAnswersRemaining
      NumProfilePageVisits
      ProfilePage
      BoostCredits
      BoostCreditsRemaining
    }
  }
`;
