const FOLDER_COLORS = [
  '#5cb1e6',
  '#009dff',
  '#2d965d',
  '#e59895',
  '#c43631',
  '#ebcb2f',
  '#e0c0f0',
  '#8132a8',
  '#e5bd8a',
  '#d18016',
];

export default FOLDER_COLORS;
