const _toggleItemInSet = <T extends number | string>(
  set: Set<T>,
  item: T,
  shouldBeInSet?: boolean
): Set<T> => {
  const addToSet = shouldBeInSet !== undefined ? shouldBeInSet : !set.has(item);
  if (addToSet) {
    set.add(item);
  } else set.delete(item);
  return set;
};

export const toggleItemInArray = <T extends number | string>(
  array: T[],
  items: T | T[],
  shouldBeInArray?: boolean
): T[] => {
  const uniqueItems = new Set(array);
  const itemsToToggle = [].concat(items);
  itemsToToggle.forEach((item) =>
    _toggleItemInSet(uniqueItems, item, shouldBeInArray)
  );
  return Array.from(uniqueItems);
};
