import { FC, useCallback } from 'react';

import { SelfServiceSubscriptions } from '@internals/business-shared/src/utils/query/SelfServiceSubscriptions/SelfServiceSubscriptions';
import { Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

type ChooseButtonProps = Pick<SelfServiceSubscriptions, 'slug'>;

export const ChooseButton: FC<ChooseButtonProps> = ({ slug }) => {
  const { modalState, handleSelectPackage } = useBuySubscriptionModalContext();
  const { t } = useTranslation();

  const handleButtonClick = useCallback(
    () => handleSelectPackage(slug),
    [slug, handleSelectPackage]
  );

  if (modalState !== ModalState.SELECT_PACKAGE) {
    return null;
  }

  return (
    <Button fullWidth size="tiny" alignSelf="end" onClick={handleButtonClick}>
      {t('general.action.choose')}
    </Button>
  );
};
