import gql from 'graphql-tag';

import {
  INITIATE_BOOST_CREDITS_PAYMENT as INITIATE_BOOST_CREDITS_PAYMENTPayload,
  INITIATE_BOOST_CREDITS_PAYMENTVariables,
} from '../../generated/generated';

export type InitiateBoostCreditsPaymentPayload =
  INITIATE_BOOST_CREDITS_PAYMENTPayload;
export type InitiateBoostCreditsPaymentVariables =
  INITIATE_BOOST_CREDITS_PAYMENTVariables;

export const INITIATE_BOOST_CREDITS_PAYMENT = gql`
  mutation INITIATE_BOOST_CREDITS_PAYMENT($input: SelfServiceOrderInput!) {
    initiateBoostCreditsPayment(input: $input) {
      id
      resultCode
      returnUrl
    }
  }
`;
