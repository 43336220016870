import gql from 'graphql-tag';

import {
  SET_CHAT_AS_READ_MUTATIONVariables,
  SET_CHAT_AS_READ_MUTATION as SET_CHAT_AS_READ_MUTATION_Reply,
} from '../../generated/generated';

export type SetChatAsReadMutationPayload = SET_CHAT_AS_READ_MUTATION_Reply;
export type SetChatAsReadMutationVariables = SET_CHAT_AS_READ_MUTATIONVariables;

export const SET_CHAT_AS_READ_MUTATION = gql`
  mutation SET_CHAT_AS_READ_MUTATION($id: ID!) {
    setChatAsRead(input: { id: $id }) {
      success
    }
  }
`;
