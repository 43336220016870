const generateApolloClientVersion = ({
  environment,
  versionNumber,
}: {
  environment: 'production' | 'staging' | 'development' | 'local';
  versionNumber: string;
}): string => {
  return {
    production: `${versionNumber}-prod`,
    staging: `${versionNumber}-staging`,
    development: `${versionNumber}-dev`,
    local: `local`,
  }[environment];
};

export default generateApolloClientVersion;
