import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  JOB_TRAITS_QUERY,
  JobTraitsQueryData,
  JobTraitsQueryPayload,
  JobTraitsQueryVariables,
} from '../../utils/query/JobTraits/JobTraits';

interface JobTraitsQueryResult extends QueryResult {
  data: JobTraitsQueryData | null;
}

export const useJobTraitsQuery = (
  queryOptions?: QueryHookOptions<
    JobTraitsQueryPayload,
    JobTraitsQueryVariables
  >
): JobTraitsQueryResult => {
  const { data, ...rest } = useQuery<
    JobTraitsQueryPayload,
    JobTraitsQueryVariables
  >(JOB_TRAITS_QUERY, {
    fetchPolicy: 'cache-and-network',
    ...(queryOptions || {}),
  });

  return useMemo(
    () => ({
      data: data?.jobTraits || null,
      ...rest,
    }),
    [data, rest]
  );
};
