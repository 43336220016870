import styled from 'styled-components';

const ColorPickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${(props) => props.theme.space[5]}px;
`;

export default ColorPickerContainer;
