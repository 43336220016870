import { FC, useEffect, useMemo, useState } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { BoostCreditsModal } from '@components/elements/BoostCreditsModal';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import {
  useTotalRemainingCredits,
  useUserBusinessId,
} from '@contexts/UserContext';
import { useIsCreditsAvailable } from '@hooks/useIsCreditsAvailable';
import {
  useIsClipsAlertDisabledByCompanyState,
  useIsMittanbudXlEligible,
} from '@internals/business-shared/src/contexts/CompanyStateContext';
import { useCompanySettingsQuery } from '@internals/business-shared/src/hooks/query/useCompanySettingsQuery';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  clipNumberToDisplay,
  displayProfileViews,
  showBoostClipsWarning,
  showClipsDanger,
  showClipsWarning,
} from '@internals/business-shared/src/utils/JobClips';
import { Div } from '@schibsted-smb/fireball';
import ContentLoader from '@utils/contentLoaders';
import { useTranslation } from 'react-i18next';

import { BoostCreditsCard } from './BoostCreditsCard';
import { AlertState } from './constants';
import { CounterBadge } from './CounterBadge';
import { CreditsAlert } from './CreditsAlert';
import { MittanbudXLCard } from './MittanbudXLCard';

export const Statistics: FC = () => {
  const [alertState, setAlertStatus] = useState<AlertState>(AlertState.None);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const businessId = useUserBusinessId();
  const isMittanbudXlEligible = useIsMittanbudXlEligible();
  const isClipsAlertDisabled = useIsClipsAlertDisabledByCompanyState();
  const remainingCredits = useTotalRemainingCredits();
  const isCreditsAvailable = useIsCreditsAvailable();
  const isMittanbudXLFlagEnabled = useFlagEnabled(FeatureFlags.BizMittanbudXL);
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );
  const showXLCard =
    !isMobile && isMittanbudXLFlagEnabled && isMittanbudXlEligible;

  const { data, loading } = useCompanySettingsQuery(businessId);

  const tooltipTextCredits = useMemo(() => {
    switch (alertState) {
      case AlertState.Warning:
        return t('job.list.statistics.tooltipLowOnCredits');
      case AlertState.Danger:
        return t('job.list.statistics.tooltipOutOfCredits');
      default:
        return t('job.list.statistics.tooltipRemainingCredits');
    }
  }, [alertState, t]);

  useEffect(() => {
    if (isClipsAlertDisabled) {
      setAlertStatus(AlertState.None);
      return;
    }

    if (
      isCreditsAvailable ? showBoostClipsWarning(data) : showClipsWarning(data)
    ) {
      setAlertStatus(AlertState.Warning);
    } else if (showClipsDanger(data)) {
      setAlertStatus(AlertState.Danger);
    }
  }, [data, isCreditsAvailable, isClipsAlertDisabled]);

  if (loading) {
    return <ContentLoader.ClipsAndProfileVisits uniqueKey="loader-clips" />;
  }

  return (
    <>
      <Div
        display="flex"
        flexDirection={
          alertState === AlertState.None || isMobile ? 'row' : 'column-reverse'
        }
      >
        <CounterBadge
          amount={clipNumberToDisplay(remainingCredits)}
          label={t('job.list.statistics.credits')}
          tooltipText={tooltipTextCredits}
          variant={alertState}
        />
        <CounterBadge
          amount={displayProfileViews(data?.NumProfilePageVisits)}
          label={t('job.list.statistics.profilePageHits')}
          tooltipText={t('job.list.statistics.tooltipProfileHits')}
          variant={alertState === AlertState.None && AlertState.None}
        />
      </Div>
      {isTestBuyMoreClipsFlagEnabled && isCreditsAvailable && (
        <Div display="flex" justifyContent="center" width="100%">
          <BoostCreditsCard />
          <BoostCreditsModal />
        </Div>
      )}
      {!isTestBuyMoreClipsFlagEnabled && isCreditsAvailable && (
        <CreditsAlert variant={alertState} />
      )}
      {showXLCard && <MittanbudXLCard />}
    </>
  );
};
