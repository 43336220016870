import { GQLOpeningHours } from '../interfaces/graphql/OpeningHours.interface';

export interface OpeningHours {
  from: string;
  to: string;
  dayNumber: number;
  dayLabel: string;
  visible: boolean;
  muted: boolean;
}

export enum OpeningHoursActions {
  SAME_OPENING_HOURS_FALSE = 'SAME_OPENING_HOURS_FALSE',
  SAME_OPENING_HOURS_TRUE = 'SAME_OPENING_HOURS_TRUE',
  CLOSED_ON_WEEKENDS_FALSE = 'CLOSED_ON_WEEKENDS_FALSE',
  CLOSED_ON_WEEKENDS_TRUE = 'CLOSED_ON_WEEKENDS_TRUE',
  UPDATE_HOURS = 'UPDATE_HOURS',
  RESET = 'RESET',
  SET_ALL = 'SET_ALL',
}

export interface OpeningHoursAction {
  type: OpeningHoursActions;
  payload?: {
    translations?: string[];
    hours?: OpeningHours;
    sameOpeningHours?: boolean;
    all?: GQLOpeningHours;
  };
}
