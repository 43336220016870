import gql from 'graphql-tag';

import { JOBS_SAVED_FILTER_FRAGMENT } from '../../fragments/JobsSavedFilterFragment';
import {
  UPDATE_JOBS_SAVED_FILTER_MUTATIONVariables,
  UPDATE_JOBS_SAVED_FILTER_MUTATION as UPDATE_JOBS_SAVED_FILTER_MUTATION_Result,
  UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist,
} from '../../generated/generated';

export type UpdateJobsSavedFilterMutationVariables =
  UPDATE_JOBS_SAVED_FILTER_MUTATIONVariables;
export type UpdateJobsSavedFilterMutationInput =
  UpdateJobsSavedFilterMutationVariables['input'];
export type UpdateJobsSavedFilterMutationPayload =
  UPDATE_JOBS_SAVED_FILTER_MUTATION_Result;
export type EditedJobsSavedFilter =
  UPDATE_JOBS_SAVED_FILTER_MUTATION_updateJobWatchlist;

export const UPDATE_JOBS_SAVED_FILTER_MUTATION = gql`
  ${JOBS_SAVED_FILTER_FRAGMENT}
  mutation UPDATE_JOBS_SAVED_FILTER_MUTATION($input: UpdateJobWatchlistInput!) {
    updateJobWatchlist(input: $input) {
      ...JobsSavedFilterFragment
    }
  }
`;
