import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import DropdownButton from '@components/elements/DropdownButton';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Dropdown } from '@schibsted-smb/fireball';

interface Item {
  text: string;
  onClick: (evaluationNode?: any) => void;
  id: string;
}

interface CustomerServiceDropdownProps {
  parentElement: React.RefObject<any>;
  items: Item[];
  evaluation?: any;
}

const CustomerServiceDropdown: React.FC<
  React.PropsWithChildren<CustomerServiceDropdownProps>
> = ({ parentElement, items, evaluation }) => (
  <Dropdown
    width={150}
    parentElement={parentElement}
    items={Array.from(items, (item) => (
      <Div key={item.id} pl={6} onClick={() => item.onClick(evaluation)}>
        <Text.p fontSize={1} mb={0}>
          {item.text}
        </Text.p>
      </Div>
    ))}
  >
    <DropdownButton>
      <FireIcon iconName={IconPickerName.More} variant="flat" />
    </DropdownButton>
  </Dropdown>
);

export default CustomerServiceDropdown;
