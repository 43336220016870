import { useState, createElement, useEffect, FC } from 'react';

import { useMutation } from '@apollo/client';
import EvaluationReportModal from '@components/layout/CustomerService/Evaluation/EvaluationReportModal';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useEvaluationQuery } from '@internals/business-shared/src/hooks/query/useEvaluationQuery';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  GQLErrorState,
  ReplyToEvaluationResponseError,
} from '@internals/business-shared/src/utils/errors';
import {
  EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation,
  EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply,
  REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  ReplyToEvaluationMutationPayload,
  ReplyToEvaluationMutationVariables,
  REPLY_TO_EVALUATION,
  isReplyToEvaluationMutationSuccessResponse,
} from '@internals/business-shared/src/utils/mutation/ReplyToEvaluation/ReplyToEvaluationMutation';

import DesktopView from './DesktopView';
import MobileView from './MobileView';

export interface EvaluatedProps {
  onClose: () => void;
  evaluationId: string;
}

export interface EvaluatedViewProps {
  onClose: () => void;
  evaluation: EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation;
  loading: boolean;
  replyData: {
    reply:
      | REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply
      | EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply;
    hasLogo: boolean;
  } | null;
  replyErrors: GQLErrorState;
  loadingReply: boolean;
  onSubmit: (message: string) => void;
  onReportEvaluation: VoidFunction;
}

const replyHasLogo = (
  rdata:
    | EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply
    | REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply
): rdata is EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply =>
  rdata?.business && 'logo' in rdata.business;

// Input state is managed here to prevent loss of input when user resizes their window.
const Evaluated: FC<EvaluatedProps> = ({ onClose, evaluationId }) => {
  const isTabletOrMobileView = useIsTabletOrMobile();
  const [replyData, setReplyData] = useState<{
    reply:
      | REPLY_TO_EVALUATION_replyToEvaluation_ReplyToEvaluationPayload_evaluationReply
      | EVALUATION_QUERY_evaluation_EvaluationPayload_evaluation_reply;
    hasLogo: boolean;
  } | null>(null);
  const [replyErrors, setReplyErrors] = useState<GQLErrorState>({});
  const [
    isEvaluationReportModalOpen,
    openEvaluationReportModal,
    closeEvaluationReportModal,
  ] = useBoolean(false);

  const { data: evaluation, loading } = useEvaluationQuery(evaluationId);

  const [replyToEvaluation, { loading: loadingReply }] = useMutation<
    ReplyToEvaluationMutationPayload,
    ReplyToEvaluationMutationVariables
  >(REPLY_TO_EVALUATION);

  const onSubmit = async (message: string) => {
    await replyToEvaluation({
      variables: {
        input: {
          evaluationId: evaluation.id,
          description: message,
        },
      },
      onCompleted: (response) => {
        const replyToEvaluationResponseError =
          new ReplyToEvaluationResponseError('ReplyToEvaluationPayload');
        if (
          replyToEvaluationResponseError.hasResponseError(
            response.replyToEvaluation,
            setReplyErrors
          )
        ) {
          return;
        }

        if (
          isReplyToEvaluationMutationSuccessResponse(response.replyToEvaluation)
        ) {
          setReplyData({
            reply: response.replyToEvaluation.evaluationReply,
            hasLogo: replyHasLogo(response.replyToEvaluation.evaluationReply),
          });
        }
      },
    });
  };
  const EvaluationView = isTabletOrMobileView ? MobileView : DesktopView;
  const EvaluationViewProps = {
    evaluation,
    loading,
    onClose,
    replyData,
    replyErrors,
    loadingReply,
    onSubmit,
    onReportEvaluation: openEvaluationReportModal,
  };

  const Evaluation = createElement(EvaluationView, EvaluationViewProps);

  useEffect(() => {
    if (!evaluation?.reply) {
      return;
    }

    setReplyData({
      reply: evaluation.reply,
      hasLogo: replyHasLogo(evaluation.reply),
    });
  }, [evaluation]);

  return (
    <>
      {Evaluation}
      {isEvaluationReportModalOpen && (
        <EvaluationReportModal
          evaluationId={evaluation.id}
          onClose={closeEvaluationReportModal}
        />
      )}
    </>
  );
};

export default Evaluated;
