import { FC, createElement } from 'react';

import { useIsMobile } from '@contexts/DeviceSizeContext';

import { MobileMessageTemplate } from './Mobile';
import { MessageTemplateModal, MessageTemplateProps } from './Modal';

export const MessageTemplate: FC<MessageTemplateProps> = (props) => {
  const isMobile = useIsMobile();
  return createElement(
    isMobile ? MobileMessageTemplate : MessageTemplateModal,
    props
  );
};
