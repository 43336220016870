import { ApolloCache, makeReference, Reference } from '@apollo/client';

import {
  recalculatePageInfoAfterAddition,
  recalculatePageInfoAfterRemoval,
} from '../../utils/cache';
import { DELETE_CHAT, ChatListType } from '../../utils/generated/generated';

const deleteChatUpdate =
  (chatId: string | undefined) =>
  (
    cache: ApolloCache<DELETE_CHAT>,
    { data }: { data?: DELETE_CHAT | null }
  ) => {
    cache.modify({
      id: cache.identify({ id: chatId, __typename: 'Chat' }),
      fields: {
        isDeleted() {
          return data?.deleteChat.success;
        },
      },
    });
    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        chatList(existingChatList, { readField }) {
          if (existingChatList.listType === ChatListType.UNREAD) {
            // no changes needed on unread list
            return existingChatList;
          }
          if (existingChatList.listType === ChatListType.INACTIVE) {
            // skip chat list manipulation when deleted chat is already in inactive list
            const isChatAlreadyInList =
              existingChatList.chatConnection.edges.some(
                (edge: { node: Reference }) =>
                  readField('id', edge.node) === chatId
              );
            if (isChatAlreadyInList) return existingChatList;
            return {
              ...existingChatList,
              chatConnection: {
                ...existingChatList.chatConnection,
                edges: [
                  ...existingChatList.chatConnection.edges,
                  { node: makeReference(`Chat:${chatId}`) },
                ].sort((chatA, chatB) => {
                  const aChatLatestUpdateTs =
                    readField('latestUpdateTs', chatA.node) ?? 0;
                  const bChatLatestUpdateTs =
                    readField('latestUpdateTs', chatB.node) ?? 0;
                  return aChatLatestUpdateTs < bChatLatestUpdateTs ? 1 : -1;
                }),
                pageInfo: recalculatePageInfoAfterAddition(
                  existingChatList.chatConnection.pageInfo
                ),
              },
            };
          }
          const chatToUpdate = existingChatList.chatConnection.edges.find(
            (edge: { node: Reference }) => {
              return readField('id', edge.node) === chatId;
            }
          );
          if (chatToUpdate) {
            return {
              ...existingChatList,
              chatConnection: {
                ...existingChatList.chatConnection,
                edges: existingChatList.chatConnection.edges.filter(
                  (chat: { node: Reference }) => chat !== chatToUpdate
                ),
                pageInfo: recalculatePageInfoAfterRemoval(
                  existingChatList.chatConnection.pageInfo
                ),
              },
            };
          }

          return existingChatList;
        },
      },
    });
  };

const deleteChatOptimisticResponse: DELETE_CHAT = {
  deleteChat: {
    __typename: 'DeleteChatPayload',
    success: true,
  },
};

export { deleteChatUpdate, deleteChatOptimisticResponse };
