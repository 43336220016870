import { FC, ReactNode } from 'react';

import { useFlagExcludedJobLists } from '@hooks/useFlagExcludedJobLists';
import {
  useJobListActiveId,
  useJobListAllFilters,
} from '@hooks/useJobListAllFilters';
import { SavedFiltersContextProvider as SharedSavedFiltersContextProvider } from '@internals/business-shared/src/components/SavedFilters/context';

interface SavedFiltersContextProviderProps {
  children: ReactNode;
}

export const SavedFiltersContextProvider: FC<
  SavedFiltersContextProviderProps
> = ({ children }) => {
  const excludedJobListIds = useFlagExcludedJobLists();
  const activeFilters = useJobListAllFilters();
  const activeListId = useJobListActiveId();

  return (
    <SharedSavedFiltersContextProvider
      activeFilters={activeFilters}
      activeListId={activeListId}
      excludedJobListIds={excludedJobListIds}
    >
      {children}
    </SharedSavedFiltersContextProvider>
  );
};
