import { setContext } from '@apollo/client/link/context';
import { getTokenCookie } from '@utils/cookies';
import isLocalhost from '@utils/isLocalhost';
import { getProduct } from '@utils/product';

const X_TRACE_ID_HEADER = 'x-trace-id';
export const createHeadersLink = () =>
  setContext((_, previousContext) => {
    // this token cookie is used only in local env, when performing login mutation directly
    // in other envs the login is done through public app and httpOnly auth cookie is set
    const token = getTokenCookie();
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...previousContext.headers,
        ...(token && { authorization: token }),
        ...(!isLocalhost && {
          [X_TRACE_ID_HEADER]: previousContext?.traceId ?? '',
        }),
        'Accept-Language': getProduct().defaultLanguage,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        'x-device':
          navigator.platform || (navigator as any)?.userAgentData?.platform,
        'x-platform': 'web', // Used to differenciate native and web (x-device is used to differenciate ios and android)
        'x-application': 'business',
      },
    };
  });
