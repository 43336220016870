import * as React from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface TextInfoBoxProps {
  text: string;
  width?: string | number;
  mb?: string | number;
}

const TextInfoBox: React.FC<React.PropsWithChildren<TextInfoBoxProps>> = ({
  text,
  width = '100%',
  mb = 7,
}) => {
  const themeContext = useTheme();
  return (
    <Div
      width={width}
      bg="orange.lightest"
      py={5}
      px={6}
      mb={mb}
      borderRadius={themeContext.radii[3]}
    >
      <Text.p m={0} fontSize={2}>
        {text}
      </Text.p>
    </Div>
  );
};

export default TextInfoBox;
