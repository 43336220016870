import { FC } from 'react';
import * as React from 'react';

import { Div, Text, Toggle } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { FilterItemWrapper, FilterName } from './styled';

interface FilterToggleProps {
  name: string;
  isChecked: boolean;
  onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  isGroupControl?: boolean;
}

const FilterToggle: FC<React.PropsWithChildren<FilterToggleProps>> = ({
  name,
  isChecked,
  onChange,
  label,
  isGroupControl,
}) => {
  const themeContext = useTheme();
  return (
    <Div
      borderBottom={[
        `1px solid ${themeContext.colors.black.black4}`,
        null,
        null,
        null,
        null,
        'none',
      ]}
      width="100%"
      maxWidth="100%"
    >
      <FilterItemWrapper>
        <FilterName fontWeight={isGroupControl ? 'medium' : 'normal'} truncate>
          {name}
        </FilterName>
        <Div display="flex" flexShrink={0} alignItems="center">
          {label && (
            <Text.p
              mr={4}
              fontSize={1}
              fontWeight={themeContext.fontWeights.medium}
            >
              {label}
            </Text.p>
          )}
          <Div onClick={(e) => e.stopPropagation()}>
            <Toggle name={name} checked={isChecked} onChange={onChange} />
          </Div>
        </Div>
      </FilterItemWrapper>
    </Div>
  );
};

export default FilterToggle;
