import * as React from 'react';

import SettingsSection from '@components/elements/SettingsSection';
import { useUserBusinessId } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import { useChooseBusinessWorkSubsetsMutation } from '@internals/business-shared/src/hooks/mutation/useChooseBusinessWorkSubsetsMutation';
import { useChooseBusinessWorkTypesMutation } from '@internals/business-shared/src/hooks/mutation/useChooseBusinessWorkTypesMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import GQLWorkType from '@internals/business-shared/src/utils/interfaces/graphql/WorkType.interface';
import { JobSearchSettingsWorkTypes } from '@internals/business-shared/src/utils/query/JobsSearchSettings/JobSearchSettingsQuery';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { Div, Text, Accordion, Toggle } from '@schibsted-smb/fireball';
import getBadge from '@utils/getBadge';
import { useTranslation } from 'react-i18next';

import ContentGridContainer from './styled/ContentGridContainer';

export interface JobSearchAccordionProps {
  payloadWorkTypesAvailable: JobSearchSettingsWorkTypes['workTypesAvailable'];
  payloadWorkTypes: JobSearchSettingsWorkTypes['workTypes'];
  payloadSubsets: JobSearchSettingsWorkTypes['subsets'];
  payloadSubsetsAvailable: JobSearchSettingsWorkTypes['subsetsAvailable'];
}

type IndustryWorkType = Omit<
  GQLWorkType,
  | 'industries'
  | 'description'
  | 'consumerTitle'
  | 'slug'
  | 'prio'
  | 'restricted'
  | 'autoapprove'
  | 'synonyms'
  | 'active'
>;

interface IndustryWithWorkTypes {
  id: string;
  title: string;
  workTypes: IndustryWorkType[];
}

const JobSearchAccordion: React.FC<JobSearchAccordionProps> = ({
  payloadWorkTypesAvailable,
  payloadWorkTypes,
  payloadSubsets,
  payloadSubsetsAvailable,
}) => {
  const businessId = useUserBusinessId();
  const { t } = useTranslation();
  const { track } = useAnalytics();

  const [updateBusinessWorkTypes] = useChooseBusinessWorkTypesMutation();
  const [updateBusinessWorkTypeSubsets] =
    useChooseBusinessWorkSubsetsMutation();

  const selectedSubsetsId = React.useMemo(() => {
    if (!payloadSubsets) return [];
    return payloadSubsets.map((subset) => subset.id);
  }, [payloadSubsets]);

  const selectedWorkTypeIds = React.useMemo(() => {
    if (!payloadWorkTypes) return [];
    return payloadWorkTypes.map((workType) => workType.id);
  }, [payloadWorkTypes]);

  const isAccordionItemChecked = (itemId: string, items: string[]): boolean => {
    return items.includes(itemId);
  };

  const handleToggleWorkType = (workTypeItemId: string): void => {
    const updatedWorkTypes = toggleItemInArray(
      selectedWorkTypeIds,
      workTypeItemId
    );
    updateBusinessWorkTypes(businessId, updatedWorkTypes);
    track(ANEventSpace(ANEvent.Changed, ANObject.JobTypes, ANPage.Settings));
  };

  const uniqueIndustryIds: string[] = [];
  const industryWithWorktypes: IndustryWithWorkTypes[] = [];
  const uniqueSubsetsAvailableIds: any =
    payloadSubsetsAvailable &&
    payloadSubsetsAvailable
      .filter((subsetAvailable) => subsetAvailable.visible)
      .map((item) => item.partOf)
      .filter((value, index, self) => self.indexOf(value) === index);
  const subsetsAvailableToDisplay: {
    id: string;
    consumerTitle: string;
    subsets: { id: string; title: string }[];
  }[] = [];

  for (let j = 0; j < uniqueSubsetsAvailableIds.length; j += 1) {
    const uniqueId = uniqueSubsetsAvailableIds[j].id;

    subsetsAvailableToDisplay.push({
      id: uniqueSubsetsAvailableIds[j].id,
      consumerTitle: uniqueSubsetsAvailableIds[j].consumerTitle,
      subsets: [],
    });

    if (payloadSubsetsAvailable) {
      for (let i = 0; i < payloadSubsetsAvailable.length; i += 1) {
        const availableSubsetsPartOfId = payloadSubsetsAvailable[i]?.partOf?.id;
        if (uniqueId === availableSubsetsPartOfId) {
          subsetsAvailableToDisplay[j].subsets.push({
            id: payloadSubsetsAvailable[i].id,
            title: payloadSubsetsAvailable[i].title,
          });
        }
      }
    }
  }

  for (let i = 0; i < payloadWorkTypesAvailable.length; i += 1) {
    const industries = payloadWorkTypesAvailable[i].industries ?? [];
    for (let j = 0; j < industries.length; j += 1) {
      const industry = industries[j] ?? null;
      if (industry) {
        if (!uniqueIndustryIds.includes(industry.id)) {
          uniqueIndustryIds.push(industry.id);
          industryWithWorktypes.push({
            id: industry.id,
            title: industry.title,
            workTypes: [
              {
                id: payloadWorkTypesAvailable[i].id,
                title: payloadWorkTypesAvailable[i].title,
              },
            ],
          });
        } else {
          const industryIndex = industryWithWorktypes.findIndex(
            (ind) => ind.id === industry.id
          );
          if (
            industryWithWorktypes[industryIndex].workTypes.findIndex(
              (workType) => workType.id === payloadWorkTypesAvailable[i].id
            ) === -1
          ) {
            industryWithWorktypes[industryIndex].workTypes.push({
              id: payloadWorkTypesAvailable[i].id,
              title: payloadWorkTypesAvailable[i].title,
            });
          }
        }
      } // if industry
    }
  }

  const handleToggleSubsetItemChecked = (subsetItemId: string) => {
    const selectedSubsets = toggleItemInArray(selectedSubsetsId, subsetItemId);
    updateBusinessWorkTypeSubsets(businessId, selectedSubsets);
    track(ANEventSpace(ANEvent.Changed, ANObject.JobTypes, ANPage.Settings));
  };

  const accordionSubsets = subsetsAvailableToDisplay.map(
    (subsetAvailableToDisplay) => ({
      key: `SUBSET-${subsetAvailableToDisplay.id}`,
      id: `SUBSET-${subsetAvailableToDisplay.id}`,
      rowLeftLabel: subsetAvailableToDisplay.consumerTitle,
      rowRightLabel: getBadge(
        subsetAvailableToDisplay.subsets,
        selectedSubsetsId
      ),
      expandedRowContent: (
        <ContentGridContainer>
          {subsetAvailableToDisplay.subsets
            .sort((a: any, b: any) => a.title.localeCompare(b.title))
            .map((subsetItem, index) => (
              <Div
                key={subsetItem.id}
                padding="10px"
                margin="0 -10px"
                height="45px"
                display="flex"
                justifyContent="space-between"
              >
                <Div>{subsetItem.title}</Div>
                <Toggle
                  name={subsetItem.title}
                  checked={isAccordionItemChecked(
                    subsetItem.id,
                    selectedSubsetsId
                  )}
                  onChange={() => handleToggleSubsetItemChecked(subsetItem.id)}
                  testId={`subset-item-${index}`}
                />
              </Div>
            ))}
        </ContentGridContainer>
      ),
    })
  );

  const accordionWorkTypes = industryWithWorktypes.map(
    (industryItem: IndustryWithWorkTypes) => ({
      key: industryItem.id,
      id: industryItem.id,
      rowLeftLabel: industryItem.title,
      rowRightLabel: getBadge(industryItem.workTypes, selectedWorkTypeIds),
      expandedRowContent: (
        <ContentGridContainer>
          {industryItem.workTypes
            .sort((a: IndustryWorkType, b: IndustryWorkType) =>
              a.title.localeCompare(b.title)
            )
            .map((workTypeItem: IndustryWorkType, index) => (
              <Div
                key={workTypeItem.id}
                padding="10px"
                margin="0 -10px"
                height="45px"
                display="flex"
                justifyContent="space-between"
              >
                <Div>{workTypeItem.title}</Div>
                <Toggle
                  name={workTypeItem.title}
                  checked={isAccordionItemChecked(
                    workTypeItem.id,
                    selectedWorkTypeIds
                  )}
                  onChange={() => handleToggleWorkType(workTypeItem.id)}
                  testId={`work-type-item-${index}`}
                />
              </Div>
            ))}
        </ContentGridContainer>
      ),
    })
  );

  const accordionItems = [...accordionSubsets, ...accordionWorkTypes].sort(
    (a: any, b: any) => a.rowLeftLabel.localeCompare(b.rowLeftLabel)
  );

  return (
    <SettingsSection
      icon={IconPickerName.JobSearch}
      title={t('settings.jobSearch.title')}
      testId="areas-accordion"
    >
      <Text.span mb="35px" color="black.black9" lineHeight={1.5}>
        {t('settings.jobSearch.baseText')}
      </Text.span>
      {payloadWorkTypesAvailable && (
        <Accordion
          colorContext="light"
          items={accordionItems}
          testId="work-types"
        />
      )}
    </SettingsSection>
  );
};

export default JobSearchAccordion;
