import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const DropdownButton = styled(Button)<Record<string, unknown>>`
  display: flex;
  width: 35px;
  height: 35px;
  padding: 0;
  margin: 0;
  background: ${(props): string => props.theme.colors.black.black0};
  border: 1px solid ${(props): string => props.theme.colors.black.black4};
  border-radius: ${(props): string => props.theme.radii[4]};

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(0, 123, 199, 0.5);
  }

  &:hover {
    background: ${(props): string => props.theme.colors.black.black0};
    border: 1px solid ${(props): string => props.theme.colors.black.black4};
  }

  svg {
    height: 16px;
    width: 16px;
    path {
      fill: ${(props): string => props.theme.colors.black.black9};
    }
  }
`;

export default DropdownButton;
