import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  ToggleAll,
  ToggleAllBaseProps,
  ToggleAllMobile,
} from '@components/elements/JobsFilters/FilterToggleAll';
import { FilterHeader } from '@components/elements/JobsFilters/styled';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Heading, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface FilterViewProps {
  title: string;
  onBackClick: VoidFunction;
  toggleAllAction?: ToggleAllBaseProps;
}

const FilterViewMobile: FC<React.PropsWithChildren<FilterViewProps>> = ({
  title,
  onBackClick,
  toggleAllAction,
  children,
}) => {
  const themeContext = useTheme();

  return (
    <div>
      <FilterHeader>
        <Button variant="linkTertiary" onClick={onBackClick} p={0}>
          <FireIcon
            iconName={IconPickerName.ChevronLeftBold}
            fill={themeContext.colors.black.black10}
            variant="flat"
            width="16px"
            height="16px"
          />
        </Button>
        <Heading.h4 m={0} truncate width="100%">
          {title}
        </Heading.h4>
        {toggleAllAction && <ToggleAllMobile {...toggleAllAction} />}
      </FilterHeader>
      <div>{children}</div>
    </div>
  );
};

const FilterView: FC<React.PropsWithChildren<FilterViewProps>> = (props) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const isMobile = useIsMobile();

  if (isMobile) return <FilterViewMobile {...props} />;

  const { title, onBackClick, toggleAllAction, children } = props;

  return (
    <div>
      <FilterHeader>
        <Button variant="linkTertiary" onClick={onBackClick} p={0}>
          <Div display="flex" mr={4} alignItems="center">
            <FireIcon
              iconName={IconPickerName.ArrowLeft}
              fill={themeContext.colors.black.black10}
              variant="flat"
              width="16px"
              height="16px"
            />
          </Div>
          <Text.span fontWeight={themeContext.fontWeights.medium} m={0}>
            {t('general.label.back')}
          </Text.span>
        </Button>
        <Heading.h4 m={0}>{title}</Heading.h4>
      </FilterHeader>
      {toggleAllAction && <ToggleAll {...toggleAllAction} filterName={title} />}
      <div>{children}</div>
    </div>
  );
};

export default FilterView;
