import styled from 'styled-components';

const IframeContainer = styled.iframe<{ frameLoaded: boolean }>`
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  background-image: ${(props): string =>
    props.frameLoaded
      ? 'none'
      : `linear-gradient(
    45deg,
    ${props.theme.background.gray} 25%,
    transparent 25%,
    transparent 50%,
    ${props.theme.background.gray} 50%,
    ${props.theme.background.gray} 75%,
    transparent 75%,
    transparent 100%
  )`};
  background-size: ${(props): number => props.theme.space[8]}px
    ${(props): number => props.theme.space[8]}px;
  animation: ${(props): string =>
    props.frameLoaded ? 'none' : 'blink 1s linear infinite'};

  @keyframes blink {
    0% {
      background-position: 0 50%;
    }
    100% {
      background-position: ${(props): number => props.theme.space[8]}px 50%;
    }
  }
`;

export default IframeContainer;
