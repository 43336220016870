import { ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload } from '../generated/generated';
import { AddBusinessEmployeeMutationPayload } from '../mutation/AddBusinessEmployee/AddBusinessEmployeeMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class AddBusinessEmployeeResponseError extends GQLResponseErrorBase<
  AddBusinessEmployeeMutationPayload['addBusinessEmployee'],
  ADD_BUSINESS_EMPLOYEE_addBusinessEmployee_AddBusinessEmployeePayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'AddBusinessEmployeeInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'businessId', 'danger');
        this.mapDataToError(data, 'position', 'danger');
        this.mapDataToError(data, 'firstName', 'danger');
        this.mapDataToError(data, 'lastName', 'danger');
        this.mapDataToError(data, 'phoneNr', 'danger');
        this.mapDataToError(data, 'email', 'danger');
        this.mapDataToError(data, 'image', 'danger');
        break;
      case 'AddBusinessEmployeeNoAccessError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
