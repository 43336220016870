import { Column } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ChatListColumn: any = styled(Column)`
  @media only screen and (max-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    max-width: 100%;
    width: 100%;
    height: calc(100dvh - 70px);
  }
`;

export default ChatListColumn;
