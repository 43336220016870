import { createContext, FC, ReactElement, useContext } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';

type BoligMappaModalContextValueType = {
  isOpen: boolean;
  openModal: VoidFunction;
  closeModal: VoidFunction;
};

const BoligMappaModalContext = createContext<BoligMappaModalContextValueType>({
  isOpen: false,
  openModal: () => {},
  closeModal: () => {},
});

export const BoligMappaModalProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const [isOpen, openModal, closeModal] = useBoolean(false);
  const boligMappaEnabled = useFlagEnabled(FeatureFlags.BizBoligMappa);

  if (!boligMappaEnabled) {
    return <>{children}</>;
  }

  return (
    <BoligMappaModalContext.Provider value={{ isOpen, openModal, closeModal }}>
      {children}
    </BoligMappaModalContext.Provider>
  );
};

export const useBoligMappaModalContext =
  (): BoligMappaModalContextValueType => {
    const boligMappaModalContextValue = useContext(BoligMappaModalContext);
    if (!boligMappaModalContextValue) {
      throw new Error(
        'BoligMappaModal Context used outside provider or before boligMappaModal data initialization'
      );
    }

    return boligMappaModalContextValue;
  };
