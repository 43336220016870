import { createElement, FC, useEffect, useState } from 'react';

import { NetworkStatus, useMutation } from '@apollo/client';
import { useFlagEnabled } from '@components/base/CheckFlag';
import { BoostCreditsModal } from '@components/elements/BoostCreditsModal';
import { BuySubscriptionModal } from '@components/elements/BuySubscriptionModal';
import { JobContentMobile } from '@components/layout/Job/JobContentMobile';
import { JobErrorModal } from '@components/layout/Job/JobErrorModal';
import { JobNavigation } from '@components/layout/Job/JobNavigation';
import { JOB_MODAL_MAX_WIDTH } from '@components/layout/Job/styled/constants';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import {
  useTotalRemainingCredits,
  useUserContext,
} from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import { useJobQuery } from '@hooks/useJobQuery';
import useJobReport from '@hooks/useJobReport';
import useMobileDeviceViewport from '@hooks/useMobileDeviceViewport';
import useStorage, { STORE_JOB_INQUIRY_PREFIX } from '@hooks/useStorage';
import {
  deleteJobOptimisticResponse,
  deleteJobUpdate,
} from '@internals/business-shared/src/cache/updates/deleteJob';
import {
  favouriteJobOptimisticResponse,
  favouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/favouriteJob';
import {
  restoreJobOptimisticResponse,
  restoreJobUpdate,
} from '@internals/business-shared/src/cache/updates/restoreJob';
import {
  setJobAsReadOptimisticResponse,
  setJobAsReadUpdate,
} from '@internals/business-shared/src/cache/updates/setJobAsRead';
import {
  unfavouriteJobOptimisticResponse,
  unfavouriteJobUpdate,
} from '@internals/business-shared/src/cache/updates/unfavouriteJob';
import { useJobAccessQuery } from '@internals/business-shared/src/hooks/query//useJobAccessQuery';
import { useJobOwnerQuery } from '@internals/business-shared/src/hooks/query/useJobOwnerQuery';
import { useJobTraitsQuery } from '@internals/business-shared/src/hooks/query/useJobTraitsQuery';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { useCanAnswerJobCheck } from '@internals/business-shared/src/hooks/useCanAnswerJobCheck';
import { getJobError } from '@internals/business-shared/src/types/JobError';
import { ANSWER_TIP_THREE_TIPS_VARIATION } from '@internals/business-shared/src/utils/analyticsConstants';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { isBoligmappaRequestedForJob } from '@internals/business-shared/src/utils/BoligMappaUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  GQLErrorState,
  JobResponseError,
} from '@internals/business-shared/src/utils/errors';
import {
  ImageFragment,
  FileFragment,
} from '@internals/business-shared/src/utils/generated/generated';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import {
  DELETE_JOB_MUTATION,
  DeleteJobMutationPayload,
  DeleteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/DeleteJob/DeleteJobMutation';
import {
  FAVOURITE_JOB,
  FavouriteJobMutationPayload,
  FavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/FavouriteJob/FavouriteJobMutation';
import {
  RESTORE_JOB_MUTATION,
  RestoreJobMutationPayload,
  RestoreJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/RestoreJob/RestoreJobMutation';
import {
  SET_JOB_AS_READ_MUTATION,
  SetJobAsReadMutationPayload,
  SetJobAsReadMutationVariables,
} from '@internals/business-shared/src/utils/mutation/SetJobAsRead/SetJobAsReadMutation';
import {
  UNFAVOURITE_JOB,
  UnfavouriteJobMutationPayload,
  UnfavouriteJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/UnfavouriteJob/UnfavouriteJobMutation';
import { isJobQuerySuccessResponse } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { Div, Modal, ModalProps, Spinner } from '@schibsted-smb/fireball';
import ContentLoader from '@utils/contentLoaders';
import { useTheme } from 'styled-components';

import { JobAlert } from './JobAlert';
import JobConfirmationModal from './JobConfirmationModal';
import JobContent from './JobContent';
import { FooterContainer } from './JobFooterContainer';
import JobForm from './JobForm';
import JobHeader from './JobHeader';
import JobReport from './JobReport';

export interface JobProps {
  id: string;
  onClose: () => void;
}

export const JOB_MODAL_MAX_HEIGHT = 860;

const JobView: FC<JobProps> = ({ id, onClose }) => {
  const { get } = useStorage(STORE_JOB_INQUIRY_PREFIX);
  const { isReportOpen, onReportShow, onReportClose } = useJobReport();
  const deviceViewportHeight = useMobileDeviceViewport().height;
  const [isConfirmationModalOpen, changeIsConfirmationModalOpen] =
    useState(false);
  const [jobAnswerInProgress, setJobAnswerInProgress, setJobAnswerCompleted] =
    useBoolean(false);
  const { track } = useAnalytics();
  const themeContext = useTheme();
  const isTabletOrMobile = useIsTabletOrMobile();
  const isMobileTabViewFlagEnabled = useFlagEnabled(
    FeatureFlags.BizNewAnswerJobFormWeb
  );
  const isAnswerTipEnabled = useFlagEnabled(FeatureFlags.BizAnswerTip);
  const user = useUserContext();
  const isMobileTabVersion = isTabletOrMobile && isMobileTabViewFlagEnabled;
  const { data, partialData, loading, error } = useJobQuery(id);
  const {
    data: jobContact,
    loading: ownerLoading,
    networkStatus: ownerQueryNetworkStatus,
  } = useJobOwnerQuery(id, { notifyOnNetworkStatusChange: true });
  const [jobQueryErrors, setJobQueryErrors] = useState<GQLErrorState>({});

  const { data: jobAnswerAccess, loading: jobAnswerAccessLoading } =
    useJobAccessQuery(id);

  const { data: jobTraits, loading: jobTraitsLoading } = useJobTraitsQuery({
    variables: { jobId: id },
  });
  const allDataLoaded =
    !loading && !jobAnswerAccessLoading && !jobTraitsLoading;

  const creditsLeft = useTotalRemainingCredits();
  if (data && !isJobQuerySuccessResponse(data.job)) {
    const jobQueryResponseError = new JobResponseError('Job');
    jobQueryResponseError.hasResponseError(data.job, setJobQueryErrors);
  }

  const [setJobAsRead] = useMutation<
    SetJobAsReadMutationPayload,
    SetJobAsReadMutationVariables
  >(SET_JOB_AS_READ_MUTATION, {
    optimisticResponse: setJobAsReadOptimisticResponse,
    update: setJobAsReadUpdate(id),
  });
  const payload = isJobQuerySuccessResponse(data?.job) ? data?.job : undefined;
  const metaData = data?.jobMeta;
  const chatMetaData = data?.jobChatMeta;
  // part of component can use already available data fetched in job list
  const jobBasicData = payload || partialData;

  const allowAnswer = useCanAnswerJobCheck(
    payload,
    jobAnswerAccess,
    useFlagEnabled
  );

  const [removeJobFromFavourites] = useMutation<
    UnfavouriteJobMutationPayload,
    UnfavouriteJobMutationVariables
  >(UNFAVOURITE_JOB);
  const [addJobToFavourites] = useMutation<
    FavouriteJobMutationPayload,
    FavouriteJobMutationVariables
  >(FAVOURITE_JOB);
  const [restoreJobMutation] = useMutation<
    RestoreJobMutationPayload,
    RestoreJobMutationVariables
  >(RESTORE_JOB_MUTATION);
  const [deleteJobMutation] = useMutation<
    DeleteJobMutationPayload,
    DeleteJobMutationVariables
  >(DELETE_JOB_MUTATION);

  const handleJobDeletionToggle = (job: JobListOrJobQueryItem) => {
    if (job.jobBusinessRelationState.isAnswered) {
      return;
    }

    if (job.jobBusinessRelationState.isDeleted) {
      restoreJobMutation({
        variables: { jobId: job.id },
        optimisticResponse: restoreJobOptimisticResponse,
        update: restoreJobUpdate(job),
      });
    } else {
      track(ANEventSpace(ANEvent.Deleted, ANObject.Job, ANPage.JobCard));
      deleteJobMutation({
        variables: { jobId: job.id },
        optimisticResponse: deleteJobOptimisticResponse,
        update: deleteJobUpdate(job),
      });
    }
  };

  const handleJobFavouriteToggle = (
    job: JobListOrJobQueryItem,
    isFavourite: boolean
  ) => {
    if (isFavourite) {
      removeJobFromFavourites({
        variables: { jobId: job.id },
        optimisticResponse: unfavouriteJobOptimisticResponse,
        update: unfavouriteJobUpdate(job.id),
      });
    } else {
      track(ANEventSpace(ANEvent.Favoured, ANObject.Job, ANPage.JobCard));
      addJobToFavourites({
        variables: { jobId: job.id },
        optimisticResponse: favouriteJobOptimisticResponse,
        update: favouriteJobUpdate(job),
      });
    }
  };

  const handleOnCloseModal = () => {
    const message = get(id)?.data?.message ?? '';
    if (message.trim() !== '') {
      changeIsConfirmationModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (payload && jobAnswerAccess) {
      if (!payload.jobBusinessRelationState.isRead) {
        setJobAsRead({ variables: { id, cost: jobAnswerAccess.clip } });
      }
    }
  }, [payload?.id, jobAnswerAccess]);

  useEffect(() => {
    if (!payload || !allDataLoaded) {
      return;
    }

    track(ANEventSpace(ANEvent.Opened, ANObject.JobCard, ANPage.JobList), {
      isRead: payload.jobBusinessRelationState.isRead.toString(),
      jobTitle: payload.title,
      jobId: payload.id,
      answerCount: payload?.answerCount,
      creditCost: jobAnswerAccess?.clip,
      bId: user.operatingAs.id,
      tipVisible: isAnswerTipEnabled
        ? ANSWER_TIP_THREE_TIPS_VARIATION
        : undefined,

      isVerifiedWithSecureId: jobTraits?.fromVerifiedUser,
      wantsBoligmappa: isBoligmappaRequestedForJob(metaData),
      questionsAnswered: !!jobTraits.questionsRegForm?.length,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    payload?.id,
    track,
    user.operatingAs.id,
    isAnswerTipEnabled,
    jobTraits?.fromVerifiedUser,
    allDataLoaded,
    metaData,
  ]);

  const getLoadingIndicator = () => {
    if (isTabletOrMobile) {
      //  fireball modal default height
      const contentHeight = 'calc(100dvh - 160px)';
      return (
        <Div height={contentHeight} display="flex" alignItems="center">
          <Spinner size={6} />
        </Div>
      );
    }
    if (partialData)
      return (
        <ContentLoader.JobModalContent uniqueKey="loader-job-modal-content" />
      );
    return <ContentLoader.JobModal uniqueKey="loader-job-modal" />;
  };

  const modalCommonProps: ModalProps & { onClose: VoidFunction } = {
    verticalSize: 'custom',
    isOpen: true,
    isClosable: false,
    isSmaller: true,
    onClose: handleOnCloseModal,
    size: 'custom',
    maxWidth: JOB_MODAL_MAX_WIDTH,
    headerProps: {
      p: 0,
      pt: 6,
      m: 0,
    },
    contentProps: { p: 0 },
    ...(isTabletOrMobile
      ? {
          top: '0',
          height: `${deviceViewportHeight}px`,
          maxHeight: `${deviceViewportHeight}px`,
        }
      : {
          isVerticallyCentered: true,
          height: '100dvh',
          maxHeight: `${JOB_MODAL_MAX_HEIGHT}px`,
        }),
  };

  const jobError = getJobError({
    apolloError: error,
    payloadError: jobQueryErrors,
    jobId: id,
  });

  const onClickAttachment = (file: ImageFragment | FileFragment) => {
    track(ANEventSpace(ANEvent.Clicked, ANObject.Attachment, ANPage.JobCard), {
      jobId: id,
      creditsLeft,
      fileType: file.fileType,
    });
  };

  const jobContent = createElement(
    isMobileTabVersion ? JobContentMobile : JobContent,
    {
      job: payload,
      meta: metaData,
      traits: jobTraits,
      owner: jobContact?.owner,
      contactDetails: jobContact?.contactInfo,
      isLoadingOwnerDetails:
        (ownerLoading && !jobContact) ||
        ownerQueryNetworkStatus === NetworkStatus.refetch,
      allowAnswer,
      chatId: chatMetaData?.chatId,
      answerInProgress: jobAnswerInProgress,
      onClickAttachment,
      alert: (
        <JobAlert
          job={payload}
          answerAccess={jobAnswerAccess}
          answerAccessLoading={jobAnswerAccessLoading}
          deletedJobAction={() => handleJobDeletionToggle(payload)}
          chatMeta={chatMetaData}
        />
      ),
      answerForm: (
        <FooterContainer>
          <JobForm
            jobId={id}
            onAnswer={setJobAnswerInProgress}
            onAnswerCompleted={setJobAnswerCompleted}
            jobCost={jobAnswerAccess?.clip || 0}
            key={id}
            disabled={!allowAnswer}
            jobTraits={jobTraits}
            jobMeta={metaData}
            {...(isMobileTabVersion && { version: 'mobile' })}
          />
        </FooterContainer>
      ),
    }
  );

  if (jobError) {
    return <JobErrorModal {...modalCommonProps} errorType={jobError} />;
  }

  return (
    <>
      <JobNavigation />
      <Modal
        testId="job-modal"
        {...modalCommonProps}
        background={themeContext.colors.black.black0}
        header={
          jobBasicData && (
            <JobHeader
              jobTraits={jobTraits}
              job={jobBasicData}
              deleteAction={() => handleJobDeletionToggle(jobBasicData)}
              favouriteAction={() =>
                handleJobFavouriteToggle(
                  jobBasicData,
                  jobBasicData.jobBusinessRelationState.isFavourite
                )
              }
              showReportModal={onReportShow}
              handleClose={handleOnCloseModal}
            />
          )
        }
      >
        {loading && (
          <Div height="100%" data-testid="content-loader">
            {getLoadingIndicator()}
          </Div>
        )}
        {payload && metaData && (
          <>
            {jobContent}
            <BoostCreditsModal />
            <BuySubscriptionModal />
          </>
        )}
      </Modal>
      <JobConfirmationModal
        isConfirmationModalOpen={isConfirmationModalOpen}
        onCloseConfirmationModal={changeIsConfirmationModalOpen}
        onClose={onClose}
      />
      {isReportOpen && <JobReport jobId={id} onClose={onReportClose} />}
    </>
  );
};

export default JobView;
