import { FC } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

const JobInfoContactRow: FC<{
  category: string;
  text: string;
  mb?: string | number;
  display?: 'grid' | 'list';
}> = ({ category, text, mb, display = 'grid' }) => {
  const themeContext = useTheme();
  const isInGrid = display === 'grid';

  return (
    <Div
      mb={mb || 6}
      {...(isInGrid && {
        display: 'grid',
        gridTemplateColumns: 'minmax(120px, 15%) 1fr',
      })}
    >
      <Text.p
        mb={isInGrid ? 0 : 3}
        mr={6}
        fontSize={2}
        fontWeight={themeContext.fontWeights.medium}
      >
        {category}
      </Text.p>
      <Text.p mb={isInGrid ? 0 : 3} fontSize={2}>
        {text}
      </Text.p>
    </Div>
  );
};

export default JobInfoContactRow;
