import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ImagesContainer = styled(Div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default ImagesContainer;
