import getGraphQLEndpoint from '@internals/business-shared/config/graphql-endpoint';
import { getEnvironment } from '@utils/getEnvironment';
import { getProductId } from '@utils/product';
import { createUploadLink as createApolloUploadLink } from 'apollo-upload-client';

export const createUploadLink = () =>
  createApolloUploadLink({
    uri: getGraphQLEndpoint(getEnvironment(), getProductId()),
    credentials: 'include',
  });
