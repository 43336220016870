import React from 'react';

import ReceiptsView from '@components/layout/Settings/Receipts';
import withNavigationLayout from '@components/layout/WithNavigation';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsReceipts: React.FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: ReceiptsView,
      subpageName: t('settings.receipts.title'),
    },
  })();
};
