export enum JobListId {
  Open = '1',
  Favourite = '4',
  Big = '10',
  Free = '2',
  Answered = '3',
  Won = '6',
  Folder = '12',
  Deleted = '5',
  Direct = '15',
}

// job lists containing only unanswered jobs
export const UNANSWERED_JOBS_LIST_IDS = [
  JobListId.Open,
  JobListId.Free,
  JobListId.Big,
];

export const JOB_LISTS_IN_ORDER: JobListId[] = [
  JobListId.Open,
  JobListId.Direct,
  JobListId.Favourite,
  JobListId.Big,
  JobListId.Free,
  JobListId.Answered,
  JobListId.Won,
  JobListId.Folder,
  JobListId.Deleted,
];

export const DEFAULT_JOB_LIST_ID = JobListId.Open;

export default JobListId;
