import { ChangeEvent, FC, useCallback, useContext, useState } from 'react';

import { UnsavedChangesModal } from '@components/elements/UnsavedChangesModal/UnsavedChangesModal';
import ProfilePicturesDeleteAlbumModal from '@components/layout/Profile/ProfilePictures/ProfilePicturesDeleteAlbumModal';
import {
  ALBUM_NAME_MAX_LENGTH,
  AlbumContextType,
  AlbumImageContextType,
  DropzoneFileType,
  ProfilePicturesContext,
  UploadFileType,
} from '@contexts/ProfilePicturesContext';
import { useAbortController } from '@internals/business-shared/src/hooks/useAbortController';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '@internals/business-shared/src/utils/constants/apollo';
import {
  FILE_UPLOAD_TOTAL_SIZE_LIMIT,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
  IMAGE_FILE_EXTENSIONS,
  FILE_UPLOAD_MAX_FILES_LIMIT,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import { getFileExtension } from '@internals/business-shared/src/utils/FileUtils';
import {
  Div,
  Button,
  Heading,
  Modal,
  Input,
  Dropzone,
  FilesDetails,
  UploadedFile,
} from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProfilePicturesEditAlbumModalProps {
  albumId: AlbumContextType['id'];
  onClose: VoidFunction;
}

const ProfilePicturesEditAlbumModal: FC<ProfilePicturesEditAlbumModalProps> = ({
  albumId,
  onClose,
}) => {
  const themeContext = useTheme();
  const {
    getContextAlbum,
    deleteAlbum,
    updateAlbum,
    uploadAlbumImages,
    updateAlbumImages,
    deleteAlbumImages,
    rotateProperty,
  } = useContext(ProfilePicturesContext);
  const album = getContextAlbum(albumId);
  const dropzoneFileDecorator = (
    albumImages: AlbumImageContextType[]
  ): DropzoneFileType[] =>
    albumImages.map(
      (image): DropzoneFileType => ({
        id: image.id.toString(),
        name: image.image.name,
        size: image.image.size,
        description: image.description ?? '',
        previewUrl: image.image.mediumPreviewUrl,
        rotationDegrees: 0,
        fileType: image.image.fileType,
        modified: false,
      })
    );
  const { t } = useTranslation();
  const [dropzoneFiles, setDropzoneFiles] = useState<DropzoneFileType[]>(
    dropzoneFileDecorator(album?.images ?? [])
  );
  const [saving, setSaving, hideSaving] = useBoolean(false);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [albumName, setAlbumName] = useState(album?.name ?? '');
  const [filesToDelete, setFilesToDelete] = useState<FilesDetails['id'][]>([]);
  const [
    isUnsavedChangesModalOpen,
    openUnsavedChangesModal,
    closeUnsavedChangesModal,
  ] = useBoolean(false);
  const { signal, abort } = useAbortController();

  const dropzoneRawFileDecorator = (rawFiles: File[]): DropzoneFileType[] => {
    return rawFiles.map(
      (file, index): DropzoneFileType => ({
        id: `${index}${file.size}${index}`,
        name: file.name,
        size: file.size,
        description: '',
        previewUrl: URL.createObjectURL(file),
        rotationDegrees: 0,
        fileType: getFileExtension(file.name),
        file,
        modified: false,
      })
    );
  };
  const onAlbumDelete = (albumToDelete: AlbumContextType) => {
    deleteAlbum(albumToDelete.id)
      .then(() => {
        setDeleteConfirmModalOpen(false);
        onClose();
      })
      .catch(() => {
        ToastMessage(t('profile.pictures.album.delete.error'));
      });
  };

  const onFileDelete = (fileToDelete: FilesDetails) => {
    if (!album) {
      return;
    }

    if (fileToDelete?.file instanceof File) {
      return;
    }

    setFilesToDelete((prevState) => [...prevState, fileToDelete.id]);
  };

  const onFileUpload = (allNewFiles: File[] | FilesDetails) => {
    if (Array.isArray(allNewFiles)) {
      setDropzoneFiles((prevState) => [
        ...prevState,
        ...dropzoneRawFileDecorator(allNewFiles),
      ]);
    }
  };

  const onFileChange = (file: FilesDetails | UploadedFile) => {
    // set Modified flag to true
    setDropzoneFiles((prevState) =>
      prevState.map((dropzoneFile) => {
        if (dropzoneFile.id === file.id) {
          return {
            ...dropzoneFile,
            modified: true,
          };
        }
        return dropzoneFile;
      })
    );
  };

  const onSave = async () => {
    if (!album) {
      return;
    }

    setSaving();
    try {
      await deleteAlbumImages(
        filesToDelete.map(
          (id) =>
            ({
              id: id.toString(),
              album: {
                id: album?.id,
              },
            } as AlbumContextType['images'][0])
        )
      );
      setFilesToDelete([]);

      await updateAlbumImages(
        album.id,
        dropzoneFiles
          .filter((df) => !(df?.file instanceof File)) // update only modified files
          .map((df) => ({
            albumImageId: df.id.toString(),
            description: df.description ?? '',
            rotate: df.rotationDegrees ?? 0,
            file: df.file,
            modified: df.modified,
          }))
      );

      const newAddedFiles = dropzoneFiles.filter(
        (df) => df?.file instanceof File
      );
      if (newAddedFiles.length > 0) {
        await uploadAlbumImages(
          {
            albumId: album.id,
            images: newAddedFiles.map((df): UploadFileType['images'][0] => ({
              image: df.file,
              description: df.description,
              modified: df.modified,
              ...rotateProperty(df.rotationDegrees ?? 0),
            })),
          },
          {
            context: {
              [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
              fetchOptions: {
                signal,
              },
            },
          }
        );
      }

      await updateAlbum(album.id, albumName);
      hideSaving();
      onClose();
    } catch (e: unknown) {
      setFilesToDelete([]);
      hideSaving();
      if (!signal.aborted) {
        ToastMessage(t('profile.pictures.album.edit.error'));
        bugsnagClient.notify(e as Error);
      } else {
        bugsnagClient.notify(
          'User aborted upload intentionally (by confirming the warning modal)'
        );
      }
      onClose();
    }
  };
  const onCloseModalRequest = useCallback(() => {
    if (!saving) {
      onClose();
      return;
    }
    openUnsavedChangesModal();
  }, [onClose, openUnsavedChangesModal, saving]);

  const handleUnsavedChangesModalBackButton = useCallback(() => {
    abort();
    onClose();
  }, [abort, onClose]);

  if (!album) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen
        isClosable
        onClose={onCloseModalRequest}
        isSmaller
        size="custom"
        maxWidth="740px"
        headerProps={{
          py: 6,
          px: 7,
          m: 0,
          border: `1px solid ${themeContext.colors.black.black3}`,
        }}
        contentProps={{ pt: 6, px: 7 }}
        footerProps={{ px: 7, mt: 6, mb: 7, pb: 0 }}
        header={
          <Heading.h3 m={0} fontSize={5}>
            {t('profile.pictures.album.edit.header')}
          </Heading.h3>
        }
        footer={
          <Div display="flex" flexDirection="column" width="100%">
            <Button
              onClick={onSave}
              variant="primary"
              isLoading={saving}
              fullWidth
              mb={2}
              size="xlarge"
              data-testid="edit-pictures-save-btn"
            >
              {t('general.label.save')}
            </Button>
            <Button
              onClick={() => setDeleteConfirmModalOpen(true)}
              variant="linkDanger"
              data-testid="edit-delete-album-btn"
            >
              {t('profile.pictures.album.delete.action')}
            </Button>
          </Div>
        }
        testId="edit-pictures"
      >
        <Input
          value={albumName}
          maxLength={ALBUM_NAME_MAX_LENGTH}
          onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            setAlbumName(e.target.value)
          }
          label={t('profile.pictures.album.name')}
          mb={7}
          testId="album-name"
        />
        <Dropzone
          dropzoneType="grid"
          multiple
          isImagePreviewEnabled
          maxFiles={FILE_UPLOAD_MAX_FILES_LIMIT}
          files={dropzoneFiles}
          setFiles={setDropzoneFiles}
          filesDetails={dropzoneFiles}
          inputLabel={t('general.label.description')}
          uploadImageCallback={onFileUpload}
          removeImageCallback={onFileDelete}
          rotateImageCallback={onFileChange}
          updateDescriptionCallback={onFileChange}
          maxSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          maxTotalSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          invalidSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidTypeErrorMessage={t('component.dropzone.invalidFileTypeError')}
          invalidTotalSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidMaxFilesErrorMessage={t('component.dropzone.maxFilesError', {
            maxFiles: FILE_UPLOAD_MAX_FILES_LIMIT,
          })}
          dropzoneGridUploadInfo={t('component.dropzone.gridUploadInfo')}
          dropzoneGridEmptyUploadInfo={t(
            'component.dropzone.chooseOrDragFiles'
          )}
          allowedFiles={{
            'image/*': IMAGE_FILE_EXTENSIONS,
          }}
          testId="edit-pictures"
        />
      </Modal>
      <ProfilePicturesDeleteAlbumModal
        isOpen={deleteConfirmModalOpen && !!album}
        onClose={() => setDeleteConfirmModalOpen(false)}
        albumTitle={album.name}
        onDelete={() => onAlbumDelete(album)}
      />
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        isLoading={false}
        onClose={closeUnsavedChangesModal}
        onSave={closeUnsavedChangesModal}
        onBackButton={handleUnsavedChangesModalBackButton}
      />
    </>
  );
};

export default ProfilePicturesEditAlbumModal;
