import gql from 'graphql-tag';

import {
  CREATE_TICKET_MUTATIONVariables,
  CREATE_TICKET_MUTATION as CREATE_TICKET_MUTATION_Result,
} from '../../generated/generated';

export type CreateTicketMutationPayload = CREATE_TICKET_MUTATION_Result;
export type CreateTicketMutationVariables = CREATE_TICKET_MUTATIONVariables;

export const CREATE_TICKET_MUTATION = gql`
  mutation CREATE_TICKET_MUTATION(
    $type: TicketType!
    $title: String!
    $metadata: String
    $relations: [RelationsInput!]
    $entryType: TicketEntryType!
    $content: String!
  ) {
    createTicket(
      type: $type
      title: $title
      metadata: $metadata
      relations: $relations
      entryType: $entryType
      content: $content
    ) {
      id
      guid
      type
      status
      title
    }
  }
`;
