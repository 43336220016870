import { ApolloCache, makeReference, Reference } from '@apollo/client';

import { DELETE_JOBS_SAVED_FILTER_MUTATION } from '../../utils/generated/generated';
import { DeleteJobsSavedFilterMutationPayload } from '../../utils/mutation/DeleteJobsSavedFilter/DeleteJobsSavedFilterMutation';

export const deleteJobsSavedFilterUpdate =
  (filterId: string) =>
  (
    cache: ApolloCache<DELETE_JOBS_SAVED_FILTER_MUTATION>,
    { data }: { data?: DeleteJobsSavedFilterMutationPayload | null }
  ) => {
    if (!data?.deleteJobWatchlist) return;

    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        jobWatchlistList(existing = [], { toReference }) {
          const deletedFilterRef = toReference({
            __typename: 'JobWatchlist',
            id: filterId,
          });
          return existing.filter(
            (existingFilterRef: Reference) =>
              existingFilterRef.__ref !== deletedFilterRef.__ref
          );
        },
      },
    });

    cache.gc();
  };
