import styled from 'styled-components';

const ColorLabel = styled.label`
  display: inline-block;
  width: ${(props) => props.theme.space[8]}px;
  height: ${(props) => props.theme.space[8]}px;
  cursor: pointer;
`;

export default ColorLabel;
