import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ShowButton = styled(Button)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: ${(props): number => props.theme.fontWeights.regular};
  padding: 0;
  margin: 0 0 20px 0;

  svg {
    width: 20px;
    height: 20px;
    fill: ${(props): string => props.theme.colors.primary.base};
  }

  &:disabled {
    background: transparent;
    color: ${(props): string => props.theme.colors.black.black5};
    svg {
      fill: ${(props): string => props.theme.colors.black.black5};
    }
    &:hover {
      background: transparent;
      color: ${(props): string => props.theme.colors.black.black5};
    }
  }
`;

export default ShowButton;
