import { FC, useEffect } from 'react';

import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import log from '@internals/business-shared/src/utils/devLog';
import { useIsIntercomAvailable } from '@root/src/hooks/useIsIntercomAvailable';
import {
  Button,
  ButtonLink,
  Div,
  Divider,
  Text,
} from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';

import ChatStatus from './styled/ChatStatus';
import { isChatActive, localChatHours } from './utils';

const CustomerService: FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const chatActive = isChatActive(new Date());
  const { startTime, endTime } = localChatHours();
  const {
    customerServiceEmail,
    customerServicePhone,
    customerServicePhonePrettyString,
  } = getProduct();
  const isIntercomAvailable = useIsIntercomAvailable();

  const triggerChat = () => {
    if (isIntercomAvailable) {
      window.Intercom('showNewMessage');
    }
    track(
      ANEventSpace(ANEvent.Clicked, ANObject.CustomerService, ANPage.Settings),
      { type: 'Chat' }
    );
  };
  useEffect(() => {
    if (!isIntercomAvailable) {
      bugsnagClient.notify(new Error('Intercom Chat is not available'));
      log.error('Intercom Chat is not available');
    }
  }, []);

  usePageAnalytics(ANSpace(ANObject.CustomerService, ANPage.Settings));

  return (
    <SettingsContainer>
      {isIntercomAvailable && (
        <>
          <SettingsSection
            icon={IconPickerName.Chat}
            title={t('settings.customerService.chat.title')}
          >
            <Text.p mb={6}>
              {t('settings.customerService.chat.infoShort')}
              <br />
              {t('settings.customerService.chat.openingHoursInfo', {
                start: startTime,
                end: endTime,
              })}
            </Text.p>
            <Div display="flex" alignItems="center" flexWrap="wrap">
              <Button
                variant="primary"
                onClick={triggerChat}
                data-testid="trigger-chat-button"
              >
                {t('settings.customerService.chat.triggerChatButton')}
              </Button>
              <ChatStatus active={chatActive}>
                {chatActive
                  ? t('settings.customerService.chat.chatStatusInfo')
                  : t('settings.customerService.chat.chatStatusClosed')}
              </ChatStatus>
            </Div>
          </SettingsSection>
          <Divider
            display={['block', null, null, null, null, 'none']}
            mb={['45px']}
          />
        </>
      )}
      <SettingsSection
        icon={IconPickerName.Email}
        title={t('general.label.email')}
      >
        <Text.p mb={6}>{t('settings.customerService.email.info')}</Text.p>
        <ButtonLink
          variant="secondary"
          href={`mailto:${customerServiceEmail}`}
          data-testid="trigger-mail-button"
          onClick={() => {
            track(
              ANEventSpace(
                ANEvent.Clicked,
                ANObject.CustomerService,
                ANPage.Settings
              ),
              { type: 'Email' }
            );
          }}
        >
          {t('settings.customerService.email.triggerMailButton')}
        </ButtonLink>
      </SettingsSection>
      <Divider
        display={['block', null, null, null, null, 'none']}
        mb={['45px']}
      />
      <SettingsSection
        icon={IconPickerName.Phone}
        title={t('general.label.phone')}
      >
        <Text.p mb={6}>{t('settings.customerService.phone.info')}</Text.p>
        <ButtonLink
          variant="secondary"
          href={`tel:${customerServicePhone}`}
          data-testid="trigger-call-button"
          onClick={() => {
            track(
              ANEventSpace(
                ANEvent.Clicked,
                ANObject.CustomerService,
                ANPage.Settings
              ),
              { type: 'Phone' }
            );
          }}
        >
          {t('settings.customerService.phone.triggerCallButton', {
            customerServicePhonePrettyString,
          })}
        </ButtonLink>
      </SettingsSection>
    </SettingsContainer>
  );
};

export default CustomerService;
