import { ApolloCache, makeReference, Reference } from '@apollo/client';

import { removeJobFromCachedJobList } from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import { DELETE_JOB_FOLDER } from '../../utils/generated/generated';
import { DeleteJobFolderMutationPayload } from '../../utils/mutation/DeleteJobFolder/DeleteJobFolderMutation';

const deleteJobFolderUpdate =
  (folderId: string) =>
  (
    cache: ApolloCache<DELETE_JOB_FOLDER>,
    { data: mutationPayload }: { data?: DeleteJobFolderMutationPayload | null }
  ) => {
    if (!mutationPayload.deleteJobFolder) return;

    let jobsWithoutOtherFoldersCount = 0;

    // remove jobs without other folders from folder job list
    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        jobList(existingJobList, { readField }) {
          if (existingJobList.listId !== JobListId.Folder) {
            return existingJobList;
          }
          const updatedJobConnection =
            existingJobList.jobConnection.edges.reduce((jobConnection, job) => {
              const jobId = readField('id', job.node);
              const jobFolders = readField('folders', job.node) as Reference[];
              const hasOtherFolders = !!jobFolders?.some(
                (folder) => readField('id', folder) !== folderId
              );
              if (hasOtherFolders) {
                return jobConnection;
              }
              jobsWithoutOtherFoldersCount += 1;
              return removeJobFromCachedJobList(
                jobConnection,
                jobId as string,
                readField
              );
            }, existingJobList.jobConnection);

          return {
            ...existingJobList,
            jobConnection: updatedJobConnection,
          };
        },
      },
    });
    // decrease folder job list counter
    cache.modify({
      id: cache.identify({ id: JobListId.Folder, __typename: 'JobListFilter' }),
      fields: {
        count(currentCount) {
          return currentCount - jobsWithoutOtherFoldersCount;
        },
      },
    });
    // remove folders
    const normalizedId = cache.identify({
      id: folderId,
      __typename: 'JobFolder',
    });
    cache.evict({ id: normalizedId });
    cache.gc();
  };

export { deleteJobFolderUpdate };
