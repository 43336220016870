import { FC } from 'react';

import Link from '@components/base/NavLink/styled/Link';
import HeaderSettingsText from '@components/layout/Header/styled/HeaderSettingsText';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import {
  ANEventSpace,
  ANEvent,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Avatar, Div } from '@schibsted-smb/fireball';
import { NavLinkProps } from 'react-router-dom';

export interface LinkProps extends NavLinkProps {
  py?: number;
  analyticsObject?: ANObject;
  linkText?: string;
}

const mobileSize = {
  width: '26px',
  height: '26px',
};

const HeaderSettingsLink: FC<LinkProps> = ({
  to,
  children,
  py,
  linkText = '',
  analyticsObject = ANObject.None,
  ...props
}) => {
  const { operatingAs: company } = useUserContext();
  const { track } = useAnalytics();
  const isTabletOrMobile = useIsTabletOrMobile();

  const avatarFontColor = isTabletOrMobile ? 'white' : 'blueDarkest.base';
  const fillActive = isTabletOrMobile ? 'blue.base' : 'beigeLightest.base';
  const fillInactive = isTabletOrMobile ? '#58637A' : '#C3C9E4';

  const companyName = company.name ?? '';
  const companyLogo = company.logo?.smallPreviewUrl ?? null;
  const onLinkClick = (analyticsObjectName: ANObject) =>
    track(ANEventSpace(ANEvent.Clicked, analyticsObjectName, ANPage.Navbar));

  return (
    <Link
      py={0}
      to={to}
      $hideLinkBackground
      {...props}
      onClick={() => onLinkClick(analyticsObject)}
    >
      {({ isActive }) => (
        <>
          <HeaderSettingsText
            fontSize={[1, null, null, null, null, null, 3]}
            {...(isTabletOrMobile
              ? { textAlign: 'center' }
              : { textAlign: 'right' })}
          >
            {isTabletOrMobile ? linkText.trim() : companyName.trim()}
          </HeaderSettingsText>
          <Div order={isTabletOrMobile ? -1 : 2}>
            <Avatar
              image={companyLogo}
              color={avatarFontColor}
              backgroundColor={isActive ? fillActive : fillInactive}
              initials={companyName[0]}
              borderRadius="4px"
              {...(isTabletOrMobile ? mobileSize : { size: 's' })}
              {...(isTabletOrMobile ? { mr: 2 } : { ml: 4 })}
            />
          </Div>
        </>
      )}
    </Link>
  );
};

export default HeaderSettingsLink;
