import { CSSProperties, FC, ReactElement } from 'react';

import { FireIcon, FireIconProps } from '@components/base/FireIcon/FireIcon';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Alert, Button, Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface DismissibleAlertProps {
  children?: ReactElement | string;
  icon?: FireIconProps;
  space?: CSSProperties['margin'];
}

export const DismissibleAlert: FC<DismissibleAlertProps> = ({
  icon,
  children,
  space = 0,
}) => {
  const [visible, , hide] = useBoolean(true);
  const themeContext = useTheme();

  if (!visible) return null;

  return (
    <Div m={space}>
      <Alert variant="action" p={0} m={0} width="100%">
        <Div display="flex" alignItems="center" py={4} width="100%">
          {icon && (
            <Div ml={5} display="flex" alignItems="center" flexShrink={0}>
              <FireIcon width={24} height={24} {...icon} />
            </Div>
          )}
          <Div ml={5} flexGrow={1}>
            {typeof children === 'string' ? (
              <Text.p mb={0}>{children}</Text.p>
            ) : (
              children
            )}
          </Div>
          <Button onClick={hide} variant="linkTertiary" p={5} flexShrink={0}>
            <FireIcon
              iconName={IconPickerName.Cross}
              variant="flat"
              fill={themeContext.colors.black.black9}
              width={12}
              height={12}
            />
          </Button>
        </Div>
      </Alert>
    </Div>
  );
};
