import { FC } from 'react';

import { CustomErrorAlert } from '@components/base/ErrorAlert/CustomErrorAlert';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Heading, Button, Spinner } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { EmptyList } from '../EmptyList';
import { Search } from '../Search';
import { StyledMobileTemplateListItem } from '../styled/StyledMobileTemplateListItem';
import { MobileMessageTemplateLayout } from './MobileMessageTemplateLayout';

interface ListProps {
  items: MessageTemplate[];
  onTemplateSelect: (templateIndex: number) => void;
}

const List: FC<ListProps> = ({ items, onTemplateSelect }) => {
  const themeContext = useTheme();

  return (
    <Div>
      {items.map((template, index) => {
        return (
          <StyledMobileTemplateListItem
            variant="linkTertiary"
            onClick={() => onTemplateSelect(index)}
            key={template.id}
          >
            <Div overflow="hidden">
              <Heading.h5 mb={0} maxWidth="100%" truncate>
                {template.title}
              </Heading.h5>
              <Text.p fontSize={1} mb={0} maxWidth="100%" truncate>
                {template.text}
              </Text.p>
            </Div>
            <Div flexShrink={0} ml={4}>
              <FireIcon
                iconName={IconPickerName.ChevronRightBold}
                width={12}
                height={12}
                fill={themeContext.colors.black.black5}
              />
            </Div>
          </StyledMobileTemplateListItem>
        );
      })}
    </Div>
  );
};

interface MobileTemplateListProps extends ListProps {
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
  onNewTemplate: VoidFunction;
  loading: boolean;
  isError: boolean;
  isEmpty: boolean;
}

export const MobileTemplateList: FC<MobileTemplateListProps> = ({
  searchQuery,
  onSearchQueryChange,
  onNewTemplate,
  loading,
  onTemplateSelect,
  items,
  isError,
  isEmpty,
}) => {
  const { t } = useTranslation();

  const getContent = () => {
    if (loading)
      return (
        <Div width="100%" height="100%" display="flex" alignItems="center">
          <Spinner />
        </Div>
      );
    if (isError) {
      return (
        <CustomErrorAlert
          error={{
            msg: t('chat.item.template.listLoadError'),
            variant: 'danger',
          }}
        />
      );
    }
    if (items.length) {
      return <List items={items} onTemplateSelect={onTemplateSelect} />;
    }
    return isEmpty ? <EmptyList /> : null;
  };

  return (
    <MobileMessageTemplateLayout
      header={
        <Div width="100%">
          <Heading.h2 mb={5}>{t('chat.item.template.titleMobile')}</Heading.h2>
          <Search value={searchQuery} onChange={onSearchQueryChange} />
        </Div>
      }
      footer={
        <Button variant="secondary" fullWidth onClick={onNewTemplate}>
          {t('chat.item.template.new')}
        </Button>
      }
    >
      {getContent()}
    </MobileMessageTemplateLayout>
  );
};
