import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { sortKeysAndRemoveDuplicates } from '@internals/business-shared/src/utils/objectUtils';
import { Paths, Params } from '@router/paths';
import {
  getBusinessAbsoluteUrl,
  getBusinessLocalhostAbsoluteUrl,
} from '@utils/getBusinessDomain';
import isLocalhost from '@utils/isLocalhost';

const joinAbsoluteUrlWithPath = (url: string, path: string): string =>
  url + path.replace(String('/'), String(''));

export const getJobItemSearchParam = (
  id: JobListOrJobQueryItem['id']
): string => {
  return `?${Params.Job}=${id}`;
};

export const getJobItemPath = (id: JobListOrJobQueryItem['id']): string => {
  return Paths.JobsItem.replace(':id', id);
};

export const getAddonPath = (addonSlug: string): string => {
  return Paths.SettingsProductAddon.replace(':slug', addonSlug);
};

export const getAddonPurchasePath = (addonSlug: string): string => {
  return Paths.SettingsProductAddonPurchase.replace(':slug', addonSlug);
};

export const getJobAbsoluteUrl = (id: JobListOrJobQueryItem['id']): string =>
  joinAbsoluteUrlWithPath(getBusinessAbsoluteUrl(), getJobItemPath(id));

export const getIntegrationsAbsoluteUrl = (): string => {
  if (isLocalhost) {
    return joinAbsoluteUrlWithPath(
      getBusinessLocalhostAbsoluteUrl(),
      Paths.SettingsIntegrations
    );
  }
  return joinAbsoluteUrlWithPath(
    getBusinessAbsoluteUrl(),
    Paths.SettingsIntegrations
  );
};

type Filters = Record<string, string | string[]>;
export const getUrlWithFilter = (path: string, filters: Filters): string => {
  const sanitizedFilterObj = sortKeysAndRemoveDuplicates(filters);
  const searchParams = new URLSearchParams();
  Object.keys(sanitizedFilterObj).forEach((key) => {
    const value = sanitizedFilterObj[key];
    const normalizedValue = Array.isArray(value) ? value.join(',') : value;
    searchParams.set(key, normalizedValue);
  });
  return `${path}?${searchParams.toString()}`;
};

export type SearchParamsType = Record<string, string[]>;
export const getUrlSearchQueryParams = (search: string): SearchParamsType => {
  const searchParams = new URLSearchParams(search);
  const searchParamsObject: Record<string, string[]> = Array.from(
    searchParams.entries()
  ).reduce((acc, [param, value]) => {
    if (value === undefined) {
      return {
        ...acc,
      };
    }
    // handle param with multiple values
    const parsedValue = value.includes(',')
      ? value.split(',')
      : [].concat(value);
    const filteredValues = parsedValue.filter(
      (v) => v || typeof v === 'number'
    );
    return filteredValues.length === 0
      ? { ...acc }
      : { ...acc, [param]: filteredValues };
  }, {});
  return sortKeysAndRemoveDuplicates(searchParamsObject) as SearchParamsType;
};

export default getJobItemSearchParam;
