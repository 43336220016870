// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ObjectWithNameProp = { name: string; [key: string]: any };

export const sortByName = (
  a: ObjectWithNameProp,
  b: ObjectWithNameProp
): number => a.name.localeCompare(b.name);

export const hasAllElements = <T extends number | string>(
  arrayToCheck: T[],
  arrayToCheckIn: T[]
): boolean => {
  return arrayToCheck.every((element) => arrayToCheckIn.includes(element));
};

type NotNullish<T> = Exclude<T, undefined | null>;
export const filterOutNullishValues = <T>(
  arr: (NotNullish<T> | undefined | null)[]
): NotNullish<T>[] => {
  return arr.filter((element) => element !== undefined && element !== null);
};

export default {
  sortByName,
};
