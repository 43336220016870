import { FC } from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import MultilineText from '@components/base/PhoneMask/styled/MultilineText';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div } from '@schibsted-smb/fireball';
import { ITooltip } from 'react-tooltip';
import { useTheme } from 'styled-components';

export interface CloseableTooltipProps extends ITooltip {
  tooltipId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const CloseableTooltip: FC<CloseableTooltipProps> = ({
  tooltipId,
  isOpen,
  onClose,
  content,
  style,
  children,
  ...rest
}) => {
  const themeContext = useTheme();

  return (
    <CustomTooltip
      id={tooltipId}
      isOpen={isOpen}
      clickable
      style={{
        padding: `${themeContext.space[5]}px`,
        ...style,
      }}
      {...rest}
    >
      <Div display="flex" alignItems="center" justifyContent="space-between">
        {typeof content === 'string' ? (
          <MultilineText>{content}</MultilineText>
        ) : (
          <>{children}</>
        )}
        <Button
          onClick={onClose}
          variant="linkTertiary"
          p={1}
          ml={4}
          flexShrink={0}
        >
          <FireIcon
            iconName={IconPickerName.Cross}
            variant="flat"
            fill={themeContext.colors.black.black0}
            width={12}
            height={12}
          />
        </Button>
      </Div>
    </CustomTooltip>
  );
};
