import gql from 'graphql-tag';

import { JOBS_SAVED_FILTER_FRAGMENT } from '../../fragments/JobsSavedFilterFragment';
import {
  CREATE_JOBS_SAVED_FILTER_MUTATIONVariables,
  CREATE_JOBS_SAVED_FILTER_MUTATION as CREATE_JOBS_SAVED_FILTER_MUTATION_Result,
} from '../../generated/generated';

export type CreateJobsSavedFilterMutationVariables =
  CREATE_JOBS_SAVED_FILTER_MUTATIONVariables;
export type CreateJobsSavedFilterMutationPayload =
  CREATE_JOBS_SAVED_FILTER_MUTATION_Result;
export type CreatedJobsSavedFilter =
  CreateJobsSavedFilterMutationPayload['createJobWatchlist'];

export const CREATE_JOBS_SAVED_FILTER_MUTATION = gql`
  ${JOBS_SAVED_FILTER_FRAGMENT}
  mutation CREATE_JOBS_SAVED_FILTER_MUTATION($input: CreateJobWatchlistInput!) {
    createJobWatchlist(input: $input) {
      ...JobsSavedFilterFragment
    }
  }
`;
