import { InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';

import JOB_LABELS_FRAGMENT from '../../utils/fragments/JobLabelsFragment';
import { JobLabelsFragment } from '../../utils/generated/generated';

const query = gql`
  ${JOB_LABELS_FRAGMENT}

  query JOB_LABELS($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        labels {
          ...JobLabelsFragment
        }
      }
    }
  }
`;

export const writeLabels = (
  cache: InMemoryCache,
  jobId?: string,
  value?: JobLabelsFragment
) => {
  cache.writeQuery({
    data: { job: { __typename: 'Job', id: jobId, labels: value } },
    variables: { id: jobId },
    query,
  });
};
