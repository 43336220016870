import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  ContractType,
  SimpleAgreementContractStatus,
} from '@internals/business-shared/src/utils/generated/generated';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';

const contractStatusStyle = (
  item: SimpleContract
):
  | { color: string; icon?: { iconName: IconPickerName; color: string } }
  | undefined => {
  if (item.type === ContractType.SIMPLE_AGREEMENT) {
    switch (item.statusEnum) {
      case SimpleAgreementContractStatus.SIGNED_CONSUMER:
        return { color: 'red.base' };
      case SimpleAgreementContractStatus.SIGNED_BUSINESS:
        return { color: 'red.base' };
      case SimpleAgreementContractStatus.SIGNED_BOTH:
        return { color: 'green.base' };
      default:
        return undefined;
    }
  }

  return undefined;
};

export default contractStatusStyle;
