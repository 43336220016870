import { Div, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface BadgeWrapperProps extends DivProps {
  isResponsive: boolean;
  theme: any;
}

const BadgeWrapper = styled(Div)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  margin-left: 4px;
  background-color: ${(props: any): string => props.theme.colors.red.base};
  height: 20px;
  width: 20px;
  border-radius: ${(props: any): string => props.theme.radii[4]};
  color: ${(props: any): string => props.theme.colors.black.black0};
  font-size: ${(props: any): string => props.theme.fontSizes[0]};

  ${(props: BadgeWrapperProps): string =>
    props.isResponsive
      ? `
   @media only screen and (max-width: ${props.theme.breakpoints[5]}) {
     width: ${props.theme.space[4]}px;
     height: ${props.theme.space[4]}px;
     top: ${props.theme.space[4]}px;
     font-size: 0
   }

   @media only screen and (max-width: ${props.theme.breakpoints[4]}) {
     top: 30px;
     margin-left: 0;
   }
  `
      : ``}
`;

export default BadgeWrapper;
