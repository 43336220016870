import ChatJobInfo from './ChatJobInfoContentLoader';
import ChatPersonInfo from './ChatPersonInfoContentLoader';
import ClipsAndProfileVisits from './ClipsAndProfileVisitsContentLoader';
import CustomersListItems from './CustomersListItemsContentLoader';
import JobModal from './JobModalContentLoader';
import JobModalContent from './JobModalPartialContentLoader';
import JobModalRightColumnDetailsContentLoader from './JobModalRightColumnDetailsContentLoader';
import JobsList from './JobsListContentLoader';

export interface LoaderProps {
  // props required for correct snapshot generating
  uniqueKey: string;
}

const ContentLoader = {
  JobModal,
  JobModalContent,
  JobModalRightColumnDetailsContentLoader,
  JobsList,
  ChatJobInfo,
  ChatPersonInfo,
  CustomersListItems,
  ClipsAndProfileVisits,
};

export default ContentLoader;
