import { ChangeEvent, FC } from 'react';

import { UseJobRejectionFormik } from '@components/layout/Job/hooks/useJobRejectionFormik';
import useAnalytics from '@hooks/useAnalytics';
import { useRejectionFeedbackOptions } from '@hooks/useRejectionFeedbackOptions';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { RejectFeedbackOptions } from '@internals/business-shared/src/utils/rejectJobUtils';
import {
  Button,
  Column,
  Input,
  RadioGroup,
  Div,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface JobFeedbackFormProps {
  jobId: string;
  onClose: () => void;
}

export const JobFeedbackForm: FC<JobFeedbackFormProps> = ({
  jobId,
  onClose,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { getRadioGroupOptions } = useRejectionFeedbackOptions();

  const onFormikSubmit = (e) => {
    const reason =
      e.feedback === RejectFeedbackOptions.OTHER ? e.reason : e.feedback;
    track(
      ANEventSpace(
        ANEvent.Sent,
        ANObject.RejectOneToOneJobFeedback,
        ANPage.JobCard
      ),
      { jobId, rejectionReason: reason }
    );
  };

  const formik = UseJobRejectionFormik(onFormikSubmit);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('reason', e.target.value);
  };

  const feedbackOptions = getRadioGroupOptions(
    formik.values.feedback,
    formik.handleChange
  );

  const handleSubmit = () => {
    formik.handleSubmit();
    onClose();
  };

  return (
    <Div>
      <Column width={[1]}>
        <RadioGroup name="feedback" options={feedbackOptions} perLine={1} />
        {formik.values.feedback === RejectFeedbackOptions.OTHER && (
          <Input
            name="reason"
            type="text"
            label={t('job.item.reject.feedback.inputPlaceholder')}
            width="100%"
            mt={2}
            onChange={handleInputChange}
          />
        )}
        <Button mt={4} width="100%" type="submit" onClick={handleSubmit}>
          {t('job.item.reject.feedback.submit')}
        </Button>
      </Column>
    </Div>
  );
};
