import {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';

import { useQuery } from '@apollo/client';
import { useCurrentUserAccountsQuery } from '@internals/business-shared/src/hooks/query/useCurrentUserAccountsQuery';
import { BusinessUser } from '@internals/business-shared/src/types/User';
import log from '@internals/business-shared/src/utils/devLog';
import {
  CURRENT_USER_QUERY,
  CurrentUserQueryPayload,
} from '@internals/business-shared/src/utils/query/CurrentUser/CurrentUserQuery';
import {
  BusinessSettings,
  CURRENT_USER_COMPANY_QUERY,
  CurrentUserCompanyQueryPayload,
  isCurrentUserCompanyQuerySuccessResponse,
} from '@internals/business-shared/src/utils/query/CurrentUserCompany/CurrentUserCompanyQuery';
import { isMultiAccountUser } from '@internals/business-shared/src/utils/user';
import { useUnleashClient } from '@unleash/proxy-client-react';
import { bugsnagClient } from '@utils/initBugsnag';

import FullScreenLoader from '../components/elements/FullScreenLoader/FullScreenLoader';

type UserContextValue = BusinessUser | null;

export const UserContext = createContext<UserContextValue>(null);

export const UserConsumer = UserContext.Consumer;

export const UserProvider = ({ children }: PropsWithChildren) => {
  const { data: userData, loading: currentUserLoading } =
    useQuery<CurrentUserQueryPayload>(CURRENT_USER_QUERY);
  const { data: accountsData } = useCurrentUserAccountsQuery();
  const businessId = userData?.me?.operatingAs?.id;
  const { data: companyData } = useQuery<CurrentUserCompanyQueryPayload>(
    CURRENT_USER_COMPANY_QUERY,
    {
      variables: { id: businessId },
      skip: !businessId,
    }
  );

  const currentUserData = useMemo(() => {
    let user = userData?.me;
    if (!user) return null;

    if (accountsData) {
      user = { ...user, ...accountsData };
    }

    if (
      !companyData?.business ||
      !isCurrentUserCompanyQuerySuccessResponse(companyData.business)
    ) {
      return user;
    }

    return {
      ...user,
      operatingAs: {
        ...companyData.business,
        businessSettings: companyData.businessSettings,
      },
    };
  }, [userData, companyData, accountsData]);

  const unleash = useUnleashClient();
  useEffect(() => {
    if (!businessId) return;

    const updateFFUserId = async () => {
      try {
        const { properties } = unleash.getContext();
        await unleash.updateContext({
          properties: { ...properties },
          userId: businessId,
        });
      } catch (e) {
        const errorMsg = `Failed to update FF context with user id, error details: ${JSON.stringify(
          e
        )}`;
        bugsnagClient.notify(errorMsg);
        log.error(errorMsg);
      }
    };
    updateFFUserId();
  }, [businessId]);

  if (currentUserLoading) {
    return <FullScreenLoader />;
  }

  return (
    <UserContext.Provider value={currentUserData}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = (): BusinessUser => {
  const userContextValue = useContext(UserContext);
  if (!userContextValue) {
    throw new Error(
      'User Context used outside provider or before user data initialization'
    );
  }
  if (!userContextValue?.operatingAs?.id) {
    throw new Error('User is not a valid business user');
  }
  return userContextValue;
};

export const useUserBusinessId = (): BusinessUser['operatingAs']['id'] => {
  const user = useUserContext();
  return user.operatingAs.id;
};

export const useUserSettings = (): BusinessSettings | undefined => {
  const user = useUserContext();
  return user.operatingAs.businessSettings;
};

export const useTotalRemainingCredits = (): number | null => {
  const settings = useUserSettings();

  if (!settings) {
    return null;
  }

  const { NumAnswersRemaining = 0, BoostCreditsRemaining = 0 } = settings;
  return NumAnswersRemaining + BoostCreditsRemaining;
};

export const useIsJobFoldersFeatureEnabled = (): boolean => {
  const settings = useUserSettings();

  return !!settings?.JobFolders;
};

export const useIsMultiAccountUser = (): boolean => {
  const user = useUserContext();
  return isMultiAccountUser(user);
};

export const isUserVerifiedWithSecureId = (): boolean => {
  const user = useUserContext();
  return user.isVerifiedWithSecureId;
};
