import { FC, useEffect, useRef } from 'react';

import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import { Div } from '@schibsted-smb/fireball';

import { StyledTemplateListItem } from './styled/StyledTemplateListItem';

interface TemplateListProps {
  items: MessageTemplate[];
  activeTemplateIndex: number | undefined;
  onTemplateSelect: (templateIndex: number) => void;
}

export const TemplateList: FC<TemplateListProps> = ({
  items,
  onTemplateSelect,
  activeTemplateIndex,
}) => {
  const listRef = useRef<HTMLDivElement>(null);

  // Make sure the selected element is always visible
  useEffect(() => {
    if (listRef.current && activeTemplateIndex !== undefined) {
      const selectedElement = listRef.current.querySelector(
        `[data-index="${activeTemplateIndex}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [activeTemplateIndex]);

  return (
    <Div ref={listRef}>
      {items.map((template, index) => {
        return (
          <StyledTemplateListItem
            key={template.id}
            onClick={() => onTemplateSelect(index)}
            variant="tertiary"
            isSelected={activeTemplateIndex === index}
            fullWidth
            data-index={index}
            data-testid={`template-list-item-${index}`}
          >
            {template.title}
          </StyledTemplateListItem>
        );
      })}
    </Div>
  );
};
