import { useCallback, useMemo } from 'react';

import {
  LazyQueryHookOptions,
  QueryHookOptions,
  QueryResult,
  useLazyQuery,
  useQuery,
} from '@apollo/client';

import { isOptimisticId } from '../../utils/optimistic/id';
import {
  CHAT_CONVERSATION_QUERY,
  ChatConversation,
  ChatConversationQueryPayload,
  ChatConversationQueryVariables,
} from '../../utils/query/ChatConversation/ChatConversation';

interface ChatConversationQueryResult extends QueryResult {
  data: ChatConversation | null;
}

interface FetchChatConversationType {
  (variables: ChatConversationQueryVariables): Promise<
    QueryResult<ChatConversationQueryPayload, ChatConversationQueryVariables>
  >;
}

export const MESSAGES_INITIAL_COUNT = 25;

export const useChatConversationQuery = (
  chatId: string,
  options?: QueryHookOptions<
    ChatConversationQueryPayload,
    ChatConversationQueryVariables
  >
): ChatConversationQueryResult => {
  const { data, ...rest } = useQuery<
    ChatConversationQueryPayload,
    ChatConversationQueryVariables
  >(CHAT_CONVERSATION_QUERY, {
    variables: {
      id: chatId,
      last: MESSAGES_INITIAL_COUNT,
    },
    fetchPolicy: 'cache-and-network',
    skip: !chatId || isOptimisticId(chatId),
    ...options,
  });

  return useMemo(() => {
    return {
      data: data?.chatMessages || null,
      ...rest,
    };
  }, [data, rest]);
};

export const useChatConversationLazyQuery = (
  options?: LazyQueryHookOptions<
    ChatConversationQueryPayload,
    ChatConversationQueryVariables
  >
): [FetchChatConversationType, ChatConversationQueryResult] => {
  const [fetchChatConversation, { data, ...rest }] = useLazyQuery<
    ChatConversationQueryPayload,
    ChatConversationQueryVariables
  >(CHAT_CONVERSATION_QUERY, {
    ...options,
  });

  const chatConversationFetch = useCallback(
    (variables: ChatConversationQueryVariables) =>
      fetchChatConversation({ variables }),
    [fetchChatConversation]
  );

  return useMemo(
    () => [
      chatConversationFetch,
      { data: data?.chatMessages || null, ...rest },
    ],
    [chatConversationFetch, data, rest]
  );
};
