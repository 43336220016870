import { t } from '@internals/business-translations/src/i18n';

import {
  BOLIGMAPPA_DOCUMENT_TYPE_UNDEFINED,
  BOLIGMAPPA_PROFESSION_TYPE_UNDEFINED,
} from '../../hooks/query/useBoligmappaDocumentAndProfessionTypesQuery';
import { MapOrEntries, UseMapState } from '../../hooks/useMap';
import {
  BoligmappaFile,
  PutResponseDecorator,
} from '../../hooks/useUploadBoligmappaAsset';
import {
  BOLIGMAPPA_FILE_UPLOAD_FORBIDDEN_EXTENSIONS,
  BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE,
  BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE_HUMAN_STRING,
} from '../constants/fileUpload';
import { getFileExtension, getFileMapKey } from '../FileUtils';
import { ConfirmBoligmappaFileIdInput } from '../mutation/ConfirmBoligmappaFileUpload/ConfirmBoligmappaFileUpload';
import { InitiateBoligmappaFile } from '../mutation/InitiateBoligmappaFileUploadInput/InitiateBoligmappaFileUploadInput';
import { OmitMany } from '../Omit';
import { BoligmappaJobAssociationsType } from '../query/BoligmappaJobAssociations/BoligmappaJobAssociationsQuery';

const boligmappaFileValidator = (
  file: File
): { message: string; status: boolean | undefined } => {
  const { size, name } = file;
  const extension = getFileExtension(name);

  if (BOLIGMAPPA_FILE_UPLOAD_FORBIDDEN_EXTENSIONS.includes(extension)) {
    return {
      status: false,
      message: t('boligmappa.files.fileTypeNotAllowed', { extension }),
    };
  }

  if (size > BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE) {
    return {
      status: false,
      message: t('boligmappa.files.fileTooBig', {
        size: BOLIGMAPPA_FILE_UPLOAD_MAX_SIZE_HUMAN_STRING,
      }),
    };
  }

  return {
    status: undefined,
    message: '',
  };
};

export const countValidBoligmappaFiles = (
  documents: UseMapState<string, BoligmappaFile>
): number =>
  Array.from(documents).filter(([, d]) => d.putResponse?.status !== false)
    .length;

export const hasAnyBoligmappaFiles = (
  associations: BoligmappaJobAssociationsType
): boolean =>
  associations?.associations?.length > 0 &&
  associations?.associations[0]?.documents?.length > 0; // Should it be that we check every association for documents?

export const hasSomeValidBoligmappaFiles = (
  documents: UseMapState<string, BoligmappaFile>
): boolean => countValidBoligmappaFiles(documents) > 0;

export const boligmappaFileAdapter = (
  files: File[]
): MapOrEntries<string, BoligmappaFile> =>
  files.map((file) => {
    const { status, message } = boligmappaFileValidator(file);
    const mapKey = getFileMapKey(file);
    return [
      mapKey,
      {
        rawFile: file,
        putResponse: {
          uploadTarget: {
            filename: '',
            id: '',
            uploadLink: '',
            uniqueId: mapKey,
          },
          status,
          message,
          documentKey: '',
        },
        uniqueId: mapKey,
        fileName: file.name,
        title: file.name,
        documentType: {
          id: BOLIGMAPPA_DOCUMENT_TYPE_UNDEFINED,
        },
        professionType: {
          id: BOLIGMAPPA_PROFESSION_TYPE_UNDEFINED,
        },
      },
    ];
  });

export const confirmBoligmappaFileUploadAdapter = (
  documents: MapOrEntries<string, BoligmappaFile>
): ConfirmBoligmappaFileIdInput[] => {
  return Array.from(documents)
    .filter(([, d]) => d.putResponse.status !== false)
    .map<ConfirmBoligmappaFileIdInput>(
      ([, d]) => d.putResponse.uploadTarget.id
    );
};

export const initiateBoligmappaFileAdapter = (
  documents: UseMapState<string, BoligmappaFile>
): InitiateBoligmappaFile[] =>
  Array.from(documents)
    .filter(([, d]) => d.putResponse?.status !== false)
    .map<InitiateBoligmappaFile>(
      ([, d]) =>
        OmitMany(['rawFile', 'putResponse'], d) as InitiateBoligmappaFile
    );

export const applyPutResponseDecorator = (
  documents: UseMapState<string, BoligmappaFile>,
  uploadResult: PutResponseDecorator[]
): MapOrEntries<string, BoligmappaFile> => {
  const copy = new Map<string, BoligmappaFile>(documents);

  uploadResult.forEach((ur) => {
    const document = copy.get(ur.documentKey);

    if (!document) {
      return;
    }
    copy.set(ur.documentKey, {
      ...document,
      putResponse: { ...ur },
    });
  });

  return copy;
};
