import { FC, useCallback } from 'react';

import { CompanyStateBanner } from '@components/elements/CompanyStateAlert/CompanyStateBanner';
import { JobBuySubscriptionAlert } from '@components/elements/JobBuySubscriptionAlert';
import useAnalytics from '@hooks/useAnalytics';
import { useIsBuySubscription } from '@hooks/useIsBuySubscription';
import {
  useCompanyStateAlertDisplay,
  useCompanyStateAlertStatus,
  useUnpaidInvoiceLink,
} from '@internals/business-shared/src/contexts/CompanyStateContext';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div } from '@schibsted-smb/fireball';
import { getCompanyStateBannerData } from '@utils/companyState/getCompanyStateBannerData';
import { useCompanyStateAction } from '@utils/companyState/useCompanyStateAction';
import { getProduct } from '@utils/product';

import { useMobileBoostAlert } from './useMobileBoostAlert';

export const CompanyStateAlert: FC = () => {
  const [isAlertVisible, , hideAlert] = useCompanyStateAlertDisplay();
  const { name: productName, customerServicePhonePrettyString } = getProduct();
  const boostMobileAlert = useMobileBoostAlert();
  const companyStateAlert = useCompanyStateAlertStatus();
  const getAction = useCompanyStateAction();
  const isBuySubscription = useIsBuySubscription();
  const paymentLink = useUnpaidInvoiceLink();
  const { track } = useAnalytics();

  const onBannerClose = useCallback(() => {
    hideAlert();
    track(
      ANEventSpace(
        ANEvent.Clicked,
        ANObject.AlertBannerContactRequestClose,
        ANPage.JobList
      )
    );
  }, [hideAlert, track]);

  if (isBuySubscription) {
    return <JobBuySubscriptionAlert isJobList />;
  }

  const alertData = getCompanyStateBannerData(
    companyStateAlert || boostMobileAlert
  );

  if (!alertData || (alertData?.dismissible && !isAlertVisible)) {
    return null;
  }

  const action = alertData.action
    ? getAction(alertData.action, paymentLink)
    : undefined;
  const testId = `company-state-alert--${companyStateAlert}`;
  const text = alertData.text({
    productName,
    phoneNumber: customerServicePhonePrettyString,
  });

  return (
    <Div
      width="100%"
      display="flex"
      justifyContent="center"
      mt={[0, null, null, null, null, null, null, 6]}
      data-testid={testId}
    >
      <CompanyStateBanner
        text={text}
        action={action}
        variant={alertData.variant}
        icon={alertData.icon}
        {...(alertData.dismissible && { onClose: onBannerClose })}
      />
    </Div>
  );
};
