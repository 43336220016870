import { Div, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface MessageWrapperProps extends DivProps {
  color: string;
}

const MessageWrapper = styled(Div)<MessageWrapperProps>`
  max-width: 290px;
  overflow-wrap: break-word;
  padding: ${(props): number => props.theme.space[4]}px
    ${(props): number => props.theme.space[5]}px;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[2]}) {
    max-width: 360px;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[3]}) {
    max-width: 425px;
  }

  & a {
    text-decoration: underline !important;
    color: ${(props): string => props.color};
    &:hover {
      color: ${(props): string => props.color};
    }
  }
`;

export default MessageWrapper;
