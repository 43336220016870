import { FC } from 'react';

import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import { NewsletterSettings } from '@components/layout/Settings/NotificationsV2/NewsletterSettings';
import { NotificationSettings } from '@components/layout/Settings/NotificationsV2/NotificationSettings';
import { StyledNotificationSection } from '@components/layout/Settings/NotificationsV2/styled';
import { usePageAnalytics } from '@hooks/useAnalytics';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';

export const NotificationSettingsV2: FC = () => {
  const { t } = useTranslation();
  usePageAnalytics(ANSpace(ANObject.Notifications, ANPage.Settings));

  return (
    <SettingsContainer>
      <SettingsSection
        title={t('settings.notifications.pageTitle')}
        icon={IconPickerName.Email}
      >
        <StyledNotificationSection>
          <NotificationSettings />
        </StyledNotificationSection>
        <StyledNotificationSection>
          <NewsletterSettings />
        </StyledNotificationSection>
      </SettingsSection>
    </SettingsContainer>
  );
};
