import { FC } from 'react';

import {
  NotificationDestinationType,
  TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination,
} from '@internals/business-shared/src/utils/generated/generated';
import { ToggleNotificationDestinationVariables } from '@internals/business-shared/src/utils/mutation/ToggleNotificationDestination/ToggleNotificationDestination';
import {
  BusinessNotification,
  BusinessNotificationDestination,
} from '@internals/business-shared/src/utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';

import SettingsNotificationSwitch from './SettingsNotificationSwitch';

interface SettingsNotificationSwitchGroupProps {
  notificationGroup: BusinessNotification[];
  notificationsEnabled: TOGGLE_NOTIFICATION_DESTINATION_toggleNotificationDestination['destinations'];
  notificationDestinationType: NotificationDestinationType;
  updateNotification: (
    variables: ToggleNotificationDestinationVariables['input'],
    destination: BusinessNotificationDestination
  ) => void;
}

const SettingsNotificationSwitchGroup: FC<
  SettingsNotificationSwitchGroupProps
> = ({
  notificationGroup,
  notificationsEnabled,
  notificationDestinationType,
  updateNotification,
}) => (
  <>
    {notificationGroup.map((notification) =>
      notification.types.map((type) =>
        type.destinations.map((destination) => {
          const isEnabled = notificationsEnabled.some(
            (item) => destination.id === item.id
          );

          return (
            <SettingsNotificationSwitch
              type={type}
              key={type.id}
              enabled={isEnabled}
              onChange={() =>
                updateNotification(
                  {
                    slug: type.slug,
                    destinationType: notificationDestinationType,
                    enabled: !isEnabled,
                  },
                  destination
                )
              }
              testId={`notification-${type.id}`}
            />
          );
        })
      )
    )}
  </>
);

export default SettingsNotificationSwitchGroup;
