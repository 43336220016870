import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  GET_USER_SETTINGS_QUERY,
  GetUserSettingsQueryPayload,
  UserSettings,
} from '../../utils/query/GetUserSettings/GetUserSettingsQuery';

interface UseGetUserSettingsQueryResult extends QueryResult {
  data: UserSettings | null;
}

export const useGetUserSettingsQuery = (
  options?: QueryHookOptions<GetUserSettingsQueryPayload>
): UseGetUserSettingsQueryResult => {
  const { data, ...rest } = useQuery<GetUserSettingsQueryPayload>(
    GET_USER_SETTINGS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );
  return useMemo(() => {
    return {
      data: data ? data.getUserSettings : null,
      ...rest,
    };
  }, [data, rest]);
};
