import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterGrid = styled(Div)`
  display: grid;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ${({ theme }) => `${theme.space[6]}px`};
  }
`;

export default FilterGrid;
