import gql from 'graphql-tag';

import { DELETE_BUSINESS_LOGO_MUTATION as DELETE_BUSINESS_LOGO_MUTATION_Result } from '../../generated/generated';

export type DeleteBusinessLogoMutationPayload =
  DELETE_BUSINESS_LOGO_MUTATION_Result;
export const DELETE_BUSINESS_LOGO_MUTATION = gql`
  mutation DELETE_BUSINESS_LOGO_MUTATION {
    deleteBusinessLogo
  }
`;
