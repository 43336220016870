import gql from 'graphql-tag';

const PAGE_INFO_FRAGMENT = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    pageSizeLimit
    startCursor
    totalCount
    totalPages
  }
`;

export default PAGE_INFO_FRAGMENT;
