import { ApolloClient } from '@apollo/client';
import gql from 'graphql-tag';

import { isSystemMessageType } from '../../components/SystemMessage';
import LATEST_MESSAGE_FRAGMENT from '../../utils/fragments/LatestMessageFragment';
import {
  MessageFragment,
  LatestMessageFragment,
} from '../../utils/generated/generated';
import {
  CHAT_DETAILS_QUERY,
  ChatDetailsQueryPayload,
  isChatDetailsQuerySuccessResponse,
} from '../../utils/query/ChatDetails/ChatDetailsQuery';
import {
  CurrentUserQueryPayload,
  CURRENT_USER_QUERY,
} from '../../utils/query/CurrentUser/CurrentUserQuery';

export const CHAT_LATEST_MESSAGE_QUERY = gql`
  ${LATEST_MESSAGE_FRAGMENT}

  query CHAT_LATEST_MESSAGE_QUERY($id: ID) {
    chat(filter: { id: $id }) {
      ... on Chat {
        id
        latestMessage {
          ...LatestMessageFragment
        }
        latestUpdateTs
      }
    }
  }
`;

export const writeLatestMessage = (
  client: ApolloClient<object>,
  chatId: string,
  message: MessageFragment
) => {
  const latestMessage = { ...message } as LatestMessageFragment;

  if (isSystemMessageType(message.type)) {
    latestMessage.writer = {
      ...message.writer,
      firstName: null,
      lastName: null,
      isDeleted: false,
      isVerifiedWithSecureId: false,
    };
  } else if (!message.business) {
    const data = client.readQuery<ChatDetailsQueryPayload>({
      query: CHAT_DETAILS_QUERY,
      variables: { id: chatId },
    });

    if (data && isChatDetailsQuerySuccessResponse(data.chat)) {
      const foundParticipant = data.chat.participants.find(
        (participant) => participant.id === message.writer.id
      );
      if (foundParticipant && foundParticipant.__typename === 'User') {
        latestMessage.writer = {
          ...foundParticipant,
          isDeleted: false,
        };
      }
    }
  } else {
    const data = client.readQuery<CurrentUserQueryPayload>({
      query: CURRENT_USER_QUERY,
    });

    if (data) {
      latestMessage.writer = {
        ...latestMessage.writer,
        firstName: data?.me?.firstName,
        lastName: data?.me?.lastName,
        isDeleted: false,
        isVerifiedWithSecureId: false, // Businesses don't have secureId
      };
    }
  }

  client.writeQuery({
    data: {
      chat: {
        __typename: 'Chat',
        id: chatId,
        latestMessage,
        latestUpdateTs: latestMessage.regTs,
      },
    },
    variables: { id: chatId },
    query: CHAT_LATEST_MESSAGE_QUERY,
  });
};
