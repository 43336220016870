import * as React from 'react';

import { useStore } from '../../modules/store';

const Logout = () => {
  const logout = useStore.use.logout();

  React.useEffect(() => {
    logout();
  }, [logout]);

  return null;
};

export default Logout;
