const groupBy = <ITEM>(
  items: ITEM[],
  key: string | number
): Record<string | number, ITEM[]> =>
  items.reduce(
    (result, item: ITEM) => ({
      ...result,
      // @ts-ignore
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export default groupBy;
