import { Div } from '@schibsted-smb/fireball';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ChatContainerProps {
  $isChatUnread: boolean;
}

export const ChatLinkV2 = styled(Link)<ChatContainerProps>`
  display: grid;
  grid-template-columns: 15px 1fr;
  text-decoration: none;
  min-width: 0;

  &:hover {
    text-decoration: none;
  }

  @media (min-width: ${({ theme }): string => theme.breakpoints[2]}) {
    // We only want to display the avatar tablet and up
    grid-template-columns: 10px 60px 1fr;
  }
`;

export const UnreadIndicator = styled.div`
  display: grid;
  padding: 0;
  justify-items: start;
  align-items: center;
`;

export const ChatAvatar = styled.div`
  display: none;
  place-items: center;
  padding: 0;
  @media (min-width: ${({ theme }): string => theme.breakpoints[2]}) {
    // We only want to display the avatar tablet and up
    display: grid;
  }
`;

export const ChatTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  padding: 0;
  overflow: hidden;
`;

export const TextRow = styled(Div)`
  display: flex;
  align-items: center;
`;
