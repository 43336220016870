import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_FRAGMENT from '../../fragments/SimpleAgreementContractFragment';
import {
  SIMPLE_CONTRACT_QUERY as SIMPLE_CONTRACT_QUERY_Result,
  SIMPLE_CONTRACT_QUERY_simpleAgreementContract,
  SIMPLE_CONTRACT_QUERYVariables,
} from '../../generated/generated';

export type SimpleContractQueryPayload = SIMPLE_CONTRACT_QUERY_Result;
export type SimpleContractQueryVariables = SIMPLE_CONTRACT_QUERYVariables;
export type SimpleContract = SIMPLE_CONTRACT_QUERY_simpleAgreementContract;

export const SIMPLE_CONTRACT_QUERY = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_FRAGMENT}

  query SIMPLE_CONTRACT_QUERY($input: SimpleAgreementContractInput!) {
    simpleAgreementContract(input: $input) {
      ...SimpleAgreementContractFragment
    }
  }
`;
