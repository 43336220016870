import { ApolloCache } from '@apollo/client';

import { CHOOSE_BUSINESS_WORK_TYPES } from '../../utils/generated/generated';
import {
  ChooseBusinessWorkTypesPayload,
  ChooseBusinessWorkTypesVariables,
} from '../../utils/mutation/ChooseBusinessWorkTypes/ChooseBusinessWorkTypes';

export const chooseBusinessWorkTypesUpdate =
  (businessId: string) =>
  (
    cache: ApolloCache<CHOOSE_BUSINESS_WORK_TYPES>,
    { data: mutationPayload }: { data?: ChooseBusinessWorkTypesPayload | null }
  ) => {
    if (!mutationPayload.chooseBusinessWorkTypes.workTypes) return;
    cache.modify({
      id: cache.identify({ id: businessId, __typename: 'BusinessWorkTypes' }),
      fields: {
        // selected workType ids from payload are mapped to cached entities and referenced in enabled workTypes field
        workTypes(_, { toReference }) {
          return mutationPayload.chooseBusinessWorkTypes.workTypes.map(
            (workType) =>
              toReference({ id: workType.id, __typename: 'WorkType' })
          );
        },
      },
    });
  };

export const chooseBusinessWorkTypesOptimisticResponse = (
  selectedWorkTypeIds: ChooseBusinessWorkTypesVariables['workTypeIds']
): ChooseBusinessWorkTypesPayload => {
  return {
    chooseBusinessWorkTypes: {
      __typename: 'ChooseBusinessWorkTypesResponse',
      workTypes: selectedWorkTypeIds.map((workTypeId) => ({
        id: workTypeId,
        __typename: 'WorkType',
      })),
    },
  };
};
