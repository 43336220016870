import { FC } from 'react';

import { ReactComponent as ArticleNotFound } from '@internals/business-shared/src/assets/illustrations/articlenotfound.svg';
import { Div, Text, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export interface ChatListNotFoundProps {
  withQuery?: boolean;
}

export const ChatListNotFound: FC<ChatListNotFoundProps> = ({ withQuery }) => {
  const { t } = useTranslation();

  if (withQuery) {
    return (
      <Div
        py={7}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <ArticleNotFound width="260px" />
        <Heading.h4 my={5}>{t('chat.list.searchEmptyList.title')}</Heading.h4>
        <Text.p mb={5} mx={6} textAlign="center">
          {t('chat.list.searchEmptyList.text')}
        </Text.p>
      </Div>
    );
  }
  return (
    <Text.p
      fontSize={2}
      color="black.black8"
      textAlign="center"
      mt={6}
      data-testid="alert-no-chats"
    >
      {t('chat.list.emptyList.title')}
    </Text.p>
  );
};
