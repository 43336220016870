import { useDisplayOnce } from '@hooks/useDisplayOnce';
import { STORE_JOB_ANSWER_TIP_PREFIX } from '@hooks/useStorage';

interface AnswerTipDisplay {
  isAnswerTipOpen: boolean;
  openAnswerTip: () => void;
  closeAnswerTip: () => void;
  removeAnswerTipStoreRecord: () => void;
}

export const useAnswerTipDisplay = (jobId: string): AnswerTipDisplay => {
  const { isDisplayed, display, hide, removeDisplayStoreRecord } =
    useDisplayOnce(jobId, STORE_JOB_ANSWER_TIP_PREFIX);
  return {
    isAnswerTipOpen: isDisplayed,
    openAnswerTip: display,
    closeAnswerTip: hide,
    removeAnswerTipStoreRecord: removeDisplayStoreRecord,
  };
};
