import { FC, ReactNode } from 'react';

import { CloseButton } from '@components/elements/CloseButton';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { Modal } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTheme } from 'styled-components';

import { ConfirmPurchaseState } from './components/ConfirmPurchaseState';
import { NotCompletedState } from './components/NotCompletedState';
import { RedirectionState } from './components/RedirectionState';
import { SelectPackageState } from './components/SelectPackageState';
import { SummaryState } from './components/SummaryState';
import { ModalState } from './constants';
import { useBuySubscriptionModalContext } from './ModalContext';

const getModalState = (state: ModalState): ReactNode =>
  ({
    [ModalState.SELECT_PACKAGE]: <SelectPackageState />,
    [ModalState.CONFIRM_PURCHASE]: <ConfirmPurchaseState />,
    [ModalState.REDIRECTION]: <RedirectionState />,
    [ModalState.SUMMARY]: <SummaryState />,
    [ModalState.NOT_COMPLETED]: <NotCompletedState />,
  }[state] ?? getModalState(ModalState.SELECT_PACKAGE));

export const BuySubscriptionModal: FC = () => {
  const { isOpen, modalState, handleModalClose } =
    useBuySubscriptionModalContext();
  const isMobile = useIsMobile();
  const theme = useTheme();

  const isClosable = modalState !== ModalState.REDIRECTION;

  return (
    <Modal
      background={theme.colors.blueNavy.base}
      contentProps={{
        px: isMobile ? 4 : 8,
        py: 8,
        width: '100vw',
      }}
      data-testid="buy-subscription-modal"
      header={isClosable && <CloseButton onClose={handleModalClose} />}
      headerProps={{
        justifyContent: 'flex-end',
        m: 0,
        p: 7,
      }}
      isClosable={false}
      isOpen={isOpen}
      isSmaller
      maxWidth="max-content"
      onClose={handleModalClose}
      shouldCloseOnOverlayClick={isClosable}
      size="custom"
      verticalSize="fullHeight"
      zIndex={Layer.modal + Layer.above + Layer.above}
    >
      {getModalState(modalState)}
    </Modal>
  );
};
