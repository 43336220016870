import { InMemoryCache } from '@apollo/client';
import gql from 'graphql-tag';

const query = gql`
  query CHAT_IS_UNREAD($id: ID!) {
    chat(filter: { id: $id }) {
      ... on Chat {
        __typename
        id
        isUnread
      }
    }
  }
`;

export const writeIsUnread = (
  cache: InMemoryCache,
  chatId?: string,
  value?: boolean
) => {
  cache.writeQuery({
    data: { chat: { __typename: 'Chat', id: chatId, isUnread: value } },
    variables: { id: chatId },
    query,
  });
};
