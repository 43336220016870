import { Div, Heading } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const SystemMessageContainer = styled(Div)`
  display: grid;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;

  margin: ${({ theme }): number => theme.space[4]}px
    ${({ theme }): number => theme.space[4]}px
    ${({ theme }): number => theme.space[7]}px
    ${({ theme }): number => theme.space[4]}px;
  border: 1px solid ${({ theme }): string => theme.colors.black.black4};
  border-radius: 14px;
  padding: ${({ theme }): number => theme.space[6]}px
    ${({ theme }): number => theme.space[7]}px;

  overflow: hidden;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    margin: ${({ theme }): number => theme.space[4]}px auto
      ${({ theme }): number => theme.space[7]}px auto;
    max-width: 60%;
  }
`;

export const SystemMessageTitle = styled(Heading.h3)`
  text-align: center;
  word-break: break-word;
  margin-bottom: ${({ theme }): number => theme.space[5]}px;
`;
export const SystemMessageText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  word-break: break-word;

  text-align: center;
  margin-bottom: ${({ theme }): number => theme.space[5]}px;
`;
