import { FC } from 'react';

import { isSystemMessageType } from '@internals/business-shared/src/components/SystemMessage';
import { ChatMessageEdge as ChatMessageType } from '@internals/business-shared/src/utils/query/ChatConversation/ChatConversation';

import { ReceivedMessage, SentMessage, SystemMessage } from './ChatMessage';

export interface ChatMessageProps {
  message: ChatMessageType['node'];
  isSender: boolean;
  isLast: boolean;
  showDate: boolean;
  showAvatar: boolean;
  jobId: string;
  name?: string;
  contractId?: string;
}
const ChatMessage: FC<ChatMessageProps> = ({
  message,
  isSender,
  isLast,
  showDate,
  showAvatar,
  name,
  contractId,
  jobId,
}: ChatMessageProps) => {
  const commonProps = {
    key: message.id,
    message,
    showDate,
    showAvatar,
    jobId,
  };
  if (isSystemMessageType(message.type)) {
    return (
      <SystemMessage {...commonProps} name={name} contractId={contractId} />
    );
  }
  return isSender ? (
    <SentMessage {...commonProps} isLast={isLast} />
  ) : (
    <ReceivedMessage {...commonProps} name={name} />
  );
};

export default ChatMessage;
