import { FC } from 'react';

import { isTouchableDevice } from '@utils/isTouchableDevice';
import { Layer } from '@utils/layer';
import { ITooltip, Tooltip } from 'react-tooltip';

type CustomTooltipProps = ITooltip;

export const CustomTooltip: FC<CustomTooltipProps> = ({ style, ...props }) => {
  if (isTouchableDevice) {
    return null;
  }
  return <Tooltip style={{ zIndex: Layer.tooltip, ...style }} {...props} />;
};
