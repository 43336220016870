import { useCallback } from 'react';

import { RejectFeedbackOptions } from '@internals/business-shared/src/utils/rejectJobUtils';
import { RadioGroupProps } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const useRejectionFeedbackOptions = () => {
  const { t } = useTranslation();
  const getRadioGroupOptions = useCallback(
    (
      feedbackType: RejectFeedbackOptions,
      handleChange: RadioGroupProps['options'][number]['onChange']
    ): RadioGroupProps['options'] => {
      return [
        {
          id: RejectFeedbackOptions.FULL_CALENDAR,
          label: t('job.item.reject.feedback.options.calendar'),
          value: RejectFeedbackOptions.FULL_CALENDAR,
          checked: feedbackType === RejectFeedbackOptions.FULL_CALENDAR,
          onChange: handleChange,
        },
        {
          id: RejectFeedbackOptions.NOT_RELEVANT,
          label: t('job.item.reject.feedback.options.relevance'),
          value: RejectFeedbackOptions.NOT_RELEVANT,
          checked: feedbackType === RejectFeedbackOptions.NOT_RELEVANT,
          onChange: handleChange,
        },
        {
          id: RejectFeedbackOptions.OTHER,
          label: t('job.item.reject.feedback.options.other'),
          value: RejectFeedbackOptions.OTHER,
          checked: feedbackType === RejectFeedbackOptions.OTHER,
          onChange: handleChange,
        },
      ];
    },
    [t]
  );
  return { getRadioGroupOptions };
};
