import styled from 'styled-components';

export const StyledButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  @media print {
    display: none;
  }
`;
