import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { BankIdDismissButtonContainer } from '@components/elements/BankIdVerification/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button } from '@schibsted-smb/fireball';

interface BankIdDismissButtonProps {
  onDismiss: VoidFunction;
}

export const BankIdDismissButton: FC<BankIdDismissButtonProps> = ({
  onDismiss,
}) => {
  return (
    <BankIdDismissButtonContainer>
      <Button onClick={onDismiss} variant="linkTertiary" p={5} flexShrink={0}>
        <FireIcon
          iconName={IconPickerName.Cross}
          variant="flat"
          fill="black.black9"
          width={12}
          height={12}
        />
      </Button>
    </BankIdDismissButtonContainer>
  );
};
