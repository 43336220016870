export enum ENV_KEY_NAME {
  GOOGLE_MAPS = 'REACT_APP_GOOGLE_MAPS_API_KEY',
  BUGSNAG = 'REACT_APP_BUGSNAG_API_KEY',
  FEATURE_FLAGS = 'REACT_APP_FEATURE_FLAGS_API_KEY',
  COMPANY_URL = 'REACT_APP_COMPANY_URL',
  LOGOUT_URL = 'REACT_APP_LOGOUT_URL',
  ORIGIN_URL = 'REACT_APP_IFRAME_ORIGIN_URL',
  ENVIRONMENT = 'REACT_APP_ENVIRONMENT',
  SEGMENT = 'REACT_APP_SEGMENT_API_KEY',
  DEBUG = 'REACT_APP_DEBUG',
  STATUS_URL = 'REACT_APP_STATUS_URL',
  MAINTENANCE_URL = 'REACT_APP_MAINTENANCE_URL',
  PRODUCT = 'REACT_APP_PRODUCT',
  LIGHTGALLERY = 'REACT_APP_LIGHTGALLERY_KEY',
}

// add optional evironment variable keys here
const optional: ENV_KEY_NAME[] = [ENV_KEY_NAME.DEBUG, ENV_KEY_NAME.PRODUCT];

export const getEnvValue = (name: ENV_KEY_NAME): string => {
  const value = process?.env?.[name];

  if (optional.includes(name)) {
    return value || '';
  }

  if (process?.env?.[ENV_KEY_NAME.ENVIRONMENT] !== 'test' && !value) {
    throw new Error(`Missing env variable: "${name}"`);
  }

  return value || '';
};
