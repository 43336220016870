import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  BoligmappaRemoveAssociationMutationPayload,
  BoligmappaRemoveAssociationMutationVariables,
  BOLIGMAPPA_REMOVE_ASSOCIATION,
} from '../../utils/mutation/BoligmappaRemoveAssociation/BoligmappaRemoveAssociation';

type UseBoligmappaRemoveAssociationMutationAction = (
  input: BoligmappaRemoveAssociationMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    BoligmappaRemoveAssociationMutationPayload,
    BoligmappaRemoveAssociationMutationVariables
  >
) => Promise<FetchResult<BoligmappaRemoveAssociationMutationPayload>>;
export const useBoligmappaRemoveAssociationMutation = (): [
  UseBoligmappaRemoveAssociationMutationAction,
  MutationResult<BoligmappaRemoveAssociationMutationPayload>
] => {
  const [boligmappaRemoveAssociation, ...rest] = useMutation<
    BoligmappaRemoveAssociationMutationPayload,
    BoligmappaRemoveAssociationMutationVariables
  >(BOLIGMAPPA_REMOVE_ASSOCIATION);

  const mutationFunction: UseBoligmappaRemoveAssociationMutationAction =
    useCallback(
      (input, mutationOptions) =>
        boligmappaRemoveAssociation({
          variables: {
            input: {
              ...input,
            },
          },
          ...mutationOptions,
        }),
      [boligmappaRemoveAssociation]
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
