import { FC } from 'react';

import { Div, Heading, Text } from '@schibsted-smb/fireball';

interface ProductHeaderProps {
  title: string;
  subtitle?: string;
}

export const ProductHeader: FC<ProductHeaderProps> = ({ title, subtitle }) => {
  return (
    <Div mb={7}>
      <Heading.h1 fontSize="36px" mb={3}>
        {title}
      </Heading.h1>
      {subtitle && (
        <Text.p fontSize="16px" color="black.black7" mb={0}>
          {subtitle}
        </Text.p>
      )}
    </Div>
  );
};
