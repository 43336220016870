import { ChangeEvent, FocusEvent, FC, useCallback } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import ColorPicker from '@components/elements/ColorPicker';
import { FolderValues } from '@components/elements/JobFolders/index';
import FoldersNavigationButton from '@components/elements/JobFolders/styled/FoldersNavigationButton';
import FOLDER_COLORS from '@internals/business-shared/src/utils/constants/folderColors';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobFolder } from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';
import { isEmptyTrimmedString } from '@internals/business-shared/src/utils/types/StringUtils';
import {
  Button,
  ButtonProps,
  Div,
  Heading,
  Input,
  Text,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface FolderButtonProps {
  text: string;
  onClick: (folder: JobFolder | FolderValues) => void;
  loading?: boolean;
  variant?: ButtonProps['variant'];
}

interface FolderCustomizationProps {
  heading: string;
  onBackButtonClick: () => void;
  onClose: () => void;
  folderData: JobFolder | FolderValues;
  setFolderData: (folder: JobFolder | FolderValues) => void;
  confirmButton: FolderButtonProps;
  rejectButton: FolderButtonProps;
}

const FolderCustomization: FC<FolderCustomizationProps> = ({
  heading,
  onBackButtonClick,
  onClose,
  folderData,
  setFolderData,
  confirmButton,
  rejectButton,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  const onNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFolderData({ ...folderData, title: e.target.value });
    },
    [folderData, setFolderData]
  );

  const onNameChangeEdited = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      setFolderData({ ...folderData, title: e.target.value.trim() });
    },
    [folderData, setFolderData]
  );

  return (
    <Div>
      <Div
        p={5}
        pb={4}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <FoldersNavigationButton onClick={onBackButtonClick}>
          <FireIcon
            iconName={IconPickerName.ChevronLeft}
            variant="flat"
            width="16px"
            height="16px"
          />
        </FoldersNavigationButton>
        <Heading.h4 mb={0}>{heading}</Heading.h4>
        <FoldersNavigationButton onClick={onClose}>
          <FireIcon
            iconName={IconPickerName.Close}
            variant="flat"
            width="16px"
            height="16px"
          />
        </FoldersNavigationButton>
      </Div>
      <Div px={5}>
        <Text.p fontSize={2} fontWeight={themeContext.fontWeights.bold} mb={4}>
          {t('job.folder.item.name')}
        </Text.p>
        <Input
          name="folderName"
          type="text"
          value={folderData?.title}
          onChange={onNameChange}
          onBlur={onNameChangeEdited}
          unlabeled
        />
        <Text.p
          fontSize={2}
          fontWeight={themeContext.fontWeights.bold}
          mt={5}
          mb={4}
        >
          {t('job.folder.item.color')}
        </Text.p>
        <ColorPicker
          colorList={FOLDER_COLORS}
          activeColor={folderData?.color ?? ''}
          onActiveColorChange={(color) =>
            setFolderData({ ...folderData, color })
          }
        />
        <Div display="flex" mb={5}>
          <Button
            variant={rejectButton.variant || 'secondary'}
            isLoading={rejectButton.loading}
            width="100%"
            size="tiny"
            onClick={() => rejectButton.onClick(folderData)}
          >
            {rejectButton.text}
          </Button>
          <Button
            variant="primary"
            isLoading={confirmButton.loading}
            disabled={
              isEmptyTrimmedString(folderData.title) || !folderData.color
            }
            ml={4}
            width="100%"
            size="tiny"
            onClick={() => confirmButton.onClick(folderData)}
          >
            {confirmButton.text}
          </Button>
        </Div>
      </Div>
    </Div>
  );
};

export default FolderCustomization;
