import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const FolderDropdownItemContainer = styled(Div)`
  position: relative;
  width: 100%;
  padding: ${(props) => `${props.theme.space[2]}px ${props.theme.space[5]}px`};
  margin: 0;

  &:hover {
    background-color: ${(props) => props.theme.colors.black.black0};
  }
`;

export default FolderDropdownItemContainer;
