import { FC, useEffect } from 'react';

import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';

export const CheckFlag: FC<{ flagName: string; redirectUrl?: string }> = ({
  flagName = '',
  redirectUrl = '/',
}) => {
  const { flagsReady } = useFlagsStatus();
  const isEnabled = useFlag(flagName);

  useEffect(() => {
    if (!flagsReady) {
      return;
    }

    if (!isEnabled) {
      window.location.replace(redirectUrl);
    }
  }, [isEnabled, flagsReady]);

  return null;
};

export const useFlagEnabled = (flagName = ''): boolean => {
  return useFlag(flagName);
};
