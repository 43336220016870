import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledTemplateListItem = styled(Button)<{ isSelected: boolean }>`
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[4]}px`};
  justify-content: flex-start;
  text-align: left;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 50px;

  &,
  &:hover,
  &:focus {
    border: none;
    box-shadow: none;
    text-decoration: none;
  }
  ${({ isSelected, theme }) =>
    isSelected &&
    `
    &,&:hover {
    background-color: ${theme.colors.primary.lightest};
  }`}
`;
