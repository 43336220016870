import { useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  setReceiveNewslettersOptimisticResponse,
  setReceiveNewslettersUpdate,
} from '../../cache/updates/setReceiveNewsletters';
import {
  SET_RECEIVE_NEWSLETTERS,
  SetReceiveNewslettersMutationPayload,
  SetReceiveNewslettersMutationVariables,
} from '../../utils/mutation/SetReceiveNewsletters/SetReceiveNewslettersMutation';

type useSetReceiveNewslettersAction = (
  isEnabled: boolean
) => Promise<FetchResult<SetReceiveNewslettersMutationPayload>>;

export const useSetReceiveNewslettersMutation = (
  mutationHookOptions?: MutationFunctionOptions<
    SetReceiveNewslettersMutationPayload,
    SetReceiveNewslettersMutationVariables
  >
): [
  useSetReceiveNewslettersAction,
  MutationResult<SetReceiveNewslettersMutationPayload>
] => {
  const [setReceiveNewsletters, ...rest] = useMutation<
    SetReceiveNewslettersMutationPayload,
    SetReceiveNewslettersMutationVariables
  >(SET_RECEIVE_NEWSLETTERS, {
    ...mutationHookOptions,
  });

  const mutationFn: useSetReceiveNewslettersAction = (isEnabled) => {
    return setReceiveNewsletters({
      variables: { value: isEnabled },
      update: setReceiveNewslettersUpdate(isEnabled),
      optimisticResponse: setReceiveNewslettersOptimisticResponse,
    });
  };

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
