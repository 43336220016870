import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ProductPackageStatus } from '@internals/business-shared/src/components/Products/productTypes';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Tag, Text } from '@schibsted-smb/fireball';
import { TagVariants } from '@schibsted-smb/fireball/lib/components/elements/Tag/types';
import { t } from '@utils/i18n';

type StatusVariant = Extract<TagVariants, 'green' | 'yellow'>;

const statusVariantMap: Record<
  Exclude<ProductPackageStatus, ProductPackageStatus.Inactive>,
  { variant: StatusVariant; text: string; color: string; icon: IconPickerName }
> = {
  [ProductPackageStatus.Active]: {
    variant: 'green',
    color: 'green.base',
    text: t('general.status.active'),
    icon: IconPickerName.Eclipse,
  },
  [ProductPackageStatus.TestPeriod]: {
    variant: 'yellow',
    color: 'yellow.darkest',
    text: t('general.status.testPeriod'),
    icon: IconPickerName.Clock,
  },
};

interface ProductPackageStatusTagProps {
  status: ProductPackageStatus;
  withIcon?: boolean;
}

export const ProductPackageStatusTag: FC<ProductPackageStatusTagProps> = ({
  status,
  withIcon,
}) => {
  if (!status || status === ProductPackageStatus.Inactive) {
    return null;
  }

  const { color, text, variant, icon } = statusVariantMap[status];

  return (
    <Tag variant={variant} noBorder display="inline-flex" alignItems="center">
      {withIcon && (
        <FireIcon iconName={icon} width={10} height={10} fill={color} />
      )}
      <Text.span mb={0} ml={withIcon ? 2 : 0}>
        {text}
      </Text.span>
    </Tag>
  );
};
