import * as React from 'react';

import SettingsContainer from '@components/elements/SettingsContainer';

const Receipts: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <SettingsContainer>
      <p>Opplæring settings default content</p>
    </SettingsContainer>
  );
};

export default Receipts;
