import gql from 'graphql-tag';

import {
  JOB_META_job_Job_meta_car,
  JOB_META_job_Job_meta_car_info,
  JOB_META_job_Job_meta_requestedStart,
} from '../generated/generated';
import ADDRESS_FRAGMENT from './AddressFragment';
import META_CAR_FRAGMENT from './MetaCarFragment';

export type RequestedStart = JOB_META_job_Job_meta_requestedStart;
export type Car = JOB_META_job_Job_meta_car;
export type CarInfo = JOB_META_job_Job_meta_car_info;

const JOB_META_FRAGMENT = gql`
  ${META_CAR_FRAGMENT}
  ${ADDRESS_FRAGMENT}

  fragment JobMetaFragment on JobMeta {
    actualStartDate
    customerType
    contactPreference
    requestedStart {
      type
      relatedTime
      __typename
    }
    generics {
      slug
      title
      value
      __typename
    }
    car {
      ...MetaCarFragment
    }
    toAddress {
      ...AddressFragment
    }
    __typename
  }
`;

export default JOB_META_FRAGMENT;
