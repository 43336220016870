import { FC, useEffect } from 'react';

import { ProductAddonImage } from '@components/layout/Products/ProductAddonImage';
import { ProductAlreadySubscribedBadge } from '@components/layout/Products/ProductAlreadySubscribedBadge';
import { ProductHeader } from '@components/layout/Products/ProductHeader';
import { ProductInfoListAccordion } from '@components/layout/Products/ProductInfoListAccordion';
import { ProductVerification } from '@components/layout/Products/ProductVerification';
import { ProductVerificationFailed } from '@components/layout/Products/ProductVerificationFailed';
import {
  StyledAddonDescription,
  StyledAddonInfoText,
  StyledProductInfoRow,
} from '@components/layout/Products/styled';
import XL from '@internals/business-shared/src/assets/images/xl.png';
import { spotlightMock } from '@internals/business-shared/src/components/Products/productMocks';
import {
  ProductVerificationStatus,
  ProductPackageStatus,
  ProductTimeUnit,
} from '@internals/business-shared/src/components/Products/productTypes';
import {
  countDuration,
  getPackageDuration,
  getPackageMonthlyPrice,
  getVerificationFailReasonText,
  useProductVerification,
} from '@internals/business-shared/src/components/Products/productUtils';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Drawer } from '@schibsted-smb/fireball';
import { getAddonPurchasePath } from '@utils/url';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

export const Spotlight: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();

  const { name, slug, status, price, startDate, expirationDate } =
    spotlightMock;
  const isActive = status === ProductPackageStatus.Active;
  const duration = getPackageDuration({
    value: countDuration(startDate, expirationDate, 'month'),
    unit: ProductTimeUnit.Month,
  });
  const { verificationState, startVerification, resetVerification } =
    useProductVerification();
  const showDrawer =
    verificationState.status === ProductVerificationStatus.InProgress ||
    verificationState.status === ProductVerificationStatus.Declined;
  const verificationFailReason =
    verificationState.status === ProductVerificationStatus.Declined
      ? getVerificationFailReasonText(verificationState.reason)
      : '';

  useEffect(() => {
    if (verificationState.status === ProductVerificationStatus.Approved) {
      navigate(getAddonPurchasePath(slug));
    }
  }, [verificationState.status]);

  return (
    <Div>
      <ProductHeader title={name} />
      {isActive && <ProductAlreadySubscribedBadge />}
      {/* todo: replace with final image */}
      <ProductAddonImage src={XL} />
      <Div mb={5}>
        <StyledProductInfoRow>
          <StyledAddonInfoText>
            {t('products.price.monthly')}
          </StyledAddonInfoText>
          <StyledAddonInfoText>
            {getPackageMonthlyPrice(price)}
          </StyledAddonInfoText>
        </StyledProductInfoRow>
        <StyledProductInfoRow>
          <StyledAddonInfoText>
            {t('general.label.duration')}
          </StyledAddonInfoText>
          <StyledAddonInfoText>{duration}</StyledAddonInfoText>
        </StyledProductInfoRow>
      </Div>
      <StyledAddonDescription>
        {t('products.spotlight.descriptionFull')}
      </StyledAddonDescription>
      <Div mb={8}>
        <ProductInfoListAccordion
          title={t('products.spotlight.requirements.title')}
          infoItems={[
            {
              icon: IconPickerName.Person,
              text: t(
                'products.spotlight.requirements.subscriptionPeriod.title'
              ),
            },
            {
              icon: IconPickerName.Star,
              text: t('products.spotlight.requirements.evaluationCount.title'),
            },
            {
              icon: IconPickerName.Sparkle,
              text: t('products.spotlight.requirements.evaluationScore.title'),
            },
          ]}
        />
      </Div>
      {!isActive && (
        <Button fullWidth onClick={startVerification}>
          {t('general.action.continue')}
        </Button>
      )}
      <Drawer
        isOpen={showDrawer}
        position="bottom"
        modalContentStyles={{ minHeight: '30%', paddingTop: theme.space[7] }}
        withCloseButton={false}
      >
        {verificationState.status === ProductVerificationStatus.InProgress && (
          <ProductVerification />
        )}
        {verificationState.status === ProductVerificationStatus.Declined && (
          <ProductVerificationFailed
            reason={verificationFailReason}
            onClose={resetVerification}
          />
        )}
      </Drawer>
    </Div>
  );
};
