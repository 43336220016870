import _dateFromTimestamp from '@internals/business-shared/src/utils/dateFromTimestamp';
import _localizeDateString from '@internals/business-shared/src/utils/localizeDateString';
import i18n, { t } from '@utils/i18n';

export const dateFromTimestamp = (
  timestamp: number,
  format?: string
): string => {
  const locales = {
    today: t('general.dateTime.today'),
    yesterday: t('general.dateTime.yesterday'),
  };

  return _dateFromTimestamp(i18n.language, timestamp, format, locales);
};

export const localizeDateString = (
  dateString: string,
  format?: string
): string => {
  return _localizeDateString(i18n.language, dateString, format);
};

export const getCurrentMonthName = (): string => {
  const currentMonth = new Date().getMonth();

  const monthLabels = [
    t('general.dateTime.months.january'),
    t('general.dateTime.months.february'),
    t('general.dateTime.months.march'),
    t('general.dateTime.months.april'),
    t('general.dateTime.months.may'),
    t('general.dateTime.months.june'),
    t('general.dateTime.months.july'),
    t('general.dateTime.months.august'),
    t('general.dateTime.months.september'),
    t('general.dateTime.months.october'),
    t('general.dateTime.months.november'),
    t('general.dateTime.months.december'),
  ];

  return monthLabels[currentMonth];
};

export default {
  dateFromTimestamp,
  localizeDateString,
  getCurrentMonthName,
};
