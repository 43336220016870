import { FC, ReactElement } from 'react';

import { StyledProductSectionTitle } from '@components/layout/Products/styled';
import { Div } from '@schibsted-smb/fireball';

interface ProductSectionProps {
  title: string;
  children: ReactElement | ReactElement[];
}

export const ProductSection: FC<ProductSectionProps> = ({
  title,
  children,
}) => (
  <Div mb={8}>
    <StyledProductSectionTitle>{title}</StyledProductSectionTitle>
    <Div>{children}</Div>
  </Div>
);
