import { IMAGE_FILE_EXTENSIONS } from './constants/fileUpload';
import IconPickerName from './constants/iconPickerNames';

const ICONS = new Map<string, IconPickerName>([
  ['jpg', IconPickerName.Images],
  ['jpeg', IconPickerName.Images],
  ['png', IconPickerName.Images],
  ['gif', IconPickerName.Images],
  ['webp', IconPickerName.Images],
  ['pdf', IconPickerName.Pdf],
  ['ppt', IconPickerName.Ppt],
  ['xls', IconPickerName.Xls],
  ['xps', IconPickerName.Xps],
  ['docx', IconPickerName.Word],
  ['doc', IconPickerName.Word],
]);

const sanitizeFileName = (filename: string): string =>
  filename
    .trim()
    .replace(/[^a-zA-Z0-9]+/g, '')
    .slice(0, 1024);

const getFileMapKey = (file: File): string =>
  `${sanitizeFileName(file.name)}${file.size}${file.lastModified}`;

const getFileExtension = (filename: string) => {
  if (filename?.split('.').length < 2) return '';
  return (filename?.split('.').pop() ?? '').toLowerCase();
};

const getIconName = (fileType: string): IconPickerName =>
  ICONS.get(fileType) ?? IconPickerName.Attachment;

const isImage = (filename: string): boolean =>
  IMAGE_FILE_EXTENSIONS.includes(getFileExtension(filename));

const isImageType = (fileType: File['type']): boolean =>
  fileType.includes('image');

const getExtensionFromImageType = (fileType: File['type']): string =>
  fileType.replace('image/', '');

export type NativeFile = {
  uri: string;
  type: string;
  name: string;
  size: number;
};

export type WebFile = File;

export type WebFileWithPreview = {
  id: string;
  file: WebFile;
  type: string;
  urlPreview?: string | ArrayBuffer | null;
};

export type NativeFileWithPreview = {
  id?: never;
  file: NativeFile;
  type: string;
  urlPreview: string;
};

export type FileWithPreview = WebFileWithPreview | NativeFileWithPreview;

const separateImageFiles = (
  files: FileWithPreview[]
): [FileWithPreview[], FileWithPreview[]] => {
  const images: FileWithPreview[] = [];
  const others: FileWithPreview[] = [];
  files.forEach((f) => {
    if (isImageType(f.file.type)) {
      images.push(f);
      return;
    }
    others.push(f);
  });
  return [images, others];
};

const createMockPDF = (name: string): FileWithPreview => {
  const fileBlob = new Blob(['']);
  return {
    urlPreview: '',
    file: {
      ...fileBlob,
      type: 'application/pdf',
      lastModified: 1652277344312,
      name,
    } as File,
    type: 'pdf',
    id: '1',
  };
};

const createMockImage = (name: string): FileWithPreview => {
  const fileBlob = new Blob(['']);
  return {
    file: {
      ...fileBlob,
      type: 'image/png',
      lastModified: 1652277344312,
      name,
    } as File,
    type: 'png',
    id: '2',
  };
};

export {
  getFileMapKey,
  getFileExtension,
  getIconName,
  isImage,
  isImageType,
  separateImageFiles,
  getExtensionFromImageType,
  createMockPDF,
  createMockImage,
};
