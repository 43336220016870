import gql from 'graphql-tag';

import {
  REMOVE_JOB_FROM_JOB_FOLDER as REMOVE_JOB_FROM_JOB_FOLDER_Result,
  REMOVE_JOB_FROM_JOB_FOLDERVariables,
} from '../../generated/generated';

export type RemoveJobFromJobFolderMutationVariables =
  REMOVE_JOB_FROM_JOB_FOLDERVariables;
export type RemoveJobFromJobFolderMutationPayload =
  REMOVE_JOB_FROM_JOB_FOLDER_Result;

export const REMOVE_JOB_FROM_JOB_FOLDER = gql`
  mutation REMOVE_JOB_FROM_JOB_FOLDER($folderId: ID!, $jobId: ID!) {
    removeJobFromJobFolder(input: { folderId: $folderId, jobId: $jobId })
  }
`;
