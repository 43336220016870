import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  CURRENT_USER_ACCOUNTS_QUERY,
  CurrentUserAccountsQueryPayload,
  UserWithAccounts,
} from '../../utils/query/CurrentUserAccounts/CurrentUserAccountsQuery';

export interface CurrentUserAccountsQueryResult extends QueryResult {
  data: UserWithAccounts | null;
}

export const useCurrentUserAccountsQuery = (
  options?: QueryHookOptions<CurrentUserAccountsQueryPayload>
): CurrentUserAccountsQueryResult => {
  const { data, ...rest } = useQuery<CurrentUserAccountsQueryPayload>(
    CURRENT_USER_ACCOUNTS_QUERY,
    {
      fetchPolicy: 'cache-and-network',
      ...options,
    }
  );

  const result = useMemo(() => {
    return {
      data: data ? data.me : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
