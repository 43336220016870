import { FC, FocusEvent } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import InlineErrorMessage from '@components/base/InlineErrorMessage';
import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledTextInput } from './styled/StyledTextInput';
import { StyledTitleInput } from './styled/StyledTitleInput';
import { useMessageTemplateFormik } from './useMessageTemplateFormik';

interface ActiveTemplateProps {
  template: MessageTemplate;
  onChange: (template: Pick<MessageTemplate, 'title' | 'text'>) => void;
  onSave: (template: MessageTemplate) => void;
  onDelete: (template: MessageTemplate) => void;
  enableEditing: VoidFunction;
  disableEditing: VoidFunction;
  editMode: boolean;
  loadingAddMessageTemplate: boolean;
  loadingUpdateMessageTemplate: boolean;
  loadingDeleteMessageTemplate: boolean;
}

export const ActiveTemplate: FC<ActiveTemplateProps> = ({
  template,
  onSave,
  onDelete,
  onChange,
  enableEditing,
  disableEditing,
  editMode,
  loadingUpdateMessageTemplate,
  loadingDeleteMessageTemplate,
  loadingAddMessageTemplate,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const formik = useMessageTemplateFormik(template, onSave, onChange);
  const actionInProgress =
    loadingAddMessageTemplate ||
    loadingUpdateMessageTemplate ||
    loadingDeleteMessageTemplate;

  return (
    <Div
      display="flex"
      flexDirection="column"
      height="100%"
      data-testid="modal-active-template"
    >
      <Div borderBottom={`1px solid ${themeContext.colors.black.black3}`}>
        <Div
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          py={3}
        >
          <Button
            variant="linkPrimary"
            size="tiny"
            onClick={enableEditing}
            disabled={actionInProgress || editMode}
          >
            {t('general.label.edit')}
          </Button>

          <Button
            onClick={formik.submitForm}
            isLoading={
              loadingAddMessageTemplate || loadingUpdateMessageTemplate
            }
            variant="linkPrimary"
            size="tiny"
            disabled={!formik.isValid || actionInProgress || !editMode}
          >
            {t('general.label.save')}
          </Button>
          <Button
            onClick={() => onDelete(template)}
            isLoading={loadingDeleteMessageTemplate}
            variant="linkDanger"
            size="tiny"
            disabled={actionInProgress}
            data-testid="modal-active-template-delete-button"
            iconPosition="center"
            icon={() => (
              <FireIcon
                iconName={IconPickerName.Delete}
                width={16}
                height={16}
                fill="red.base"
              />
            )}
          />
        </Div>
      </Div>
      <Div
        p={5}
        backgroundColor={
          editMode ? 'transparent' : themeContext.colors.black.black2
        }
      >
        <StyledTitleInput
          {...formik.getFieldProps('title')}
          unlabeled
          data-testid="modal-active-template-title"
          placeholder={t('chat.item.template.form.title.placeholder')}
          disabled={!editMode}
        />
        {formik.touched.title && formik.errors.title && (
          <InlineErrorMessage>{formik.errors.title}</InlineErrorMessage>
        )}
      </Div>
      <Div
        p={5}
        pt={0}
        height="100%"
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignContent="stretch"
        backgroundColor={
          editMode ? 'transparent' : themeContext.colors.black.black2
        }
      >
        <StyledTextInput
          {...formik.getFieldProps('text')}
          containersProps={{ flexGrow: 1, alignItems: 'stretch' }}
          unlabeled
          placeholder={t('chat.item.template.form.text.placeholder')}
          disabled={!editMode}
        />
        {formik.touched.text && formik.errors.text && (
          <InlineErrorMessage>{formik.errors.text}</InlineErrorMessage>
        )}
      </Div>
    </Div>
  );
};
