import gql from 'graphql-tag';

const EMPLOYEE_FRAGMENT = gql`
  fragment EmployeeFragment on BusinessEmployee {
    id
    position
    firstName
    lastName
    phoneNr
    email
    imageUrl
    image {
      id
      name
    }
  }
`;
export default EMPLOYEE_FRAGMENT;
