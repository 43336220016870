import { Heading } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const Title = styled(Heading.h2)`
  justify-self: center;
  max-width: 100%;
  margin-bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export default Title;
