import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useGetEnabledFlagNames } from '@hooks/useGetEnabledFlagNames';
import { useIdentifyData } from '@internals/business-shared/src/hooks/query/useIdentifyData';
import log from '@internals/business-shared/src/utils/devLog';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { isCypress } from '@utils/envChecks';
import { bugsnagClient } from '@utils/initBugsnag';

import { useUserContext } from './UserContext';

type AnalyticsProviderProps = {
  writeKey: string;
};

type AnalyticsContextType = {
  analytics: AnalyticsBrowser;
  integrations?: Record<string, boolean>;
};
type AnalyticsProperties = { userId: string } & Record<string, unknown>;

export const AnalyticsContext = createContext<AnalyticsContextType>({
  analytics: null,
  integrations: {},
});

export const AnalyticsProvider: React.FC<
  React.PropsWithChildren<AnalyticsProviderProps>
> = ({ children, writeKey }) => {
  const analytics = useMemo(() => new AnalyticsBrowser(), []);
  const user = useUserContext();
  const { data, loading } = useIdentifyData(user);
  const filteredFlags = useGetEnabledFlagNames();
  const [identifyData, setIdentifyData] =
    useState<AnalyticsProperties>(undefined);

  const CONSENT_REQUIRED = true;
  const CONSENT_FUNCTIONALITY = true;
  const CONSENT_MARKETING = true;

  const integrations = useMemo(
    () => ({
      All: true,
      Amplitude: CONSENT_FUNCTIONALITY,
      'Actions Amplitude': CONSENT_FUNCTIONALITY,
      Appcues: CONSENT_FUNCTIONALITY,
      'Bing Ads': CONSENT_MARKETING,
      Bugsnag: CONSENT_REQUIRED,
      'Facebook Pixel': CONSENT_MARKETING,
      'Google Analytics': CONSENT_MARKETING,
      'Google Tag Manager': CONSENT_MARKETING,
      Intercom: CONSENT_FUNCTIONALITY,
      'Intercom Web (Actions)': CONSENT_FUNCTIONALITY,
      'Visual Website Optimizer': CONSENT_FUNCTIONALITY,
      Webhooks: CONSENT_FUNCTIONALITY,
    }),
    [CONSENT_FUNCTIONALITY, CONSENT_MARKETING, CONSENT_REQUIRED]
  );

  const load = useCallback(async () => {
    if (writeKey.trim() === '') {
      bugsnagClient.notify(new Error('Segment Analytics API KEY is empty'));
      log.analytics('Segment Analytics API KEY is empty');
      return;
    }

    if (analytics?.load) {
      try {
        await analytics.load(
          { writeKey },
          { integrations, initialPageview: false }
        );
        log.analytics('SEGMENT LOADED !!!');
      } catch (error) {
        log.error('Segment Analytics load call has triggered error: ', error);
      }
    } else {
      bugsnagClient.notify(
        new Error('Segment Analytics load failed'),
        (event) => {
          event.addMetadata('Error details', { analyticsValue: analytics });
        }
      );
      log.error('Segment Analytics load failed: ', {
        analyticsValue: analytics,
      });
    }
  }, [analytics, writeKey, integrations]);

  const identify = useCallback(
    async (properties: AnalyticsProperties) => {
      if (analytics?.identify) {
        try {
          await analytics.identify(properties.userId, properties);
          log.analytics('SEGMENT IDENTIFIED !!!');
        } catch (error) {
          bugsnagClient.notify(
            new Error('Segment Analytics identify failed'),
            (event) => {
              event.addMetadata('Error details', { error });
            }
          );
          log.error('Segment Analytics identify failed');
        }
      } else {
        bugsnagClient.notify(
          new Error('Segment Analytics identify failed'),
          (event) => {
            event.addMetadata('Error details', { analyticsValue: analytics });
          }
        );
        log.error('Segment Analytics identify failed');
      }
    },
    [analytics]
  );

  useEffect(() => {
    if (data && filteredFlags && !loading && !identifyData) {
      setIdentifyData({ ...data, featureFlags: filteredFlags });
    }
  }, [data, filteredFlags, loading, identifyData]);

  useEffect(() => {
    if (isCypress || !identifyData) {
      return;
    }
    const loadAndIdentify = async () => {
      await load();
      await identify(identifyData);
    };
    loadAndIdentify();
  }, [identifyData, load, identify]);

  const value = useMemo(() => {
    return {
      analytics,
      integrations,
    };
  }, [analytics, integrations]);

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};
