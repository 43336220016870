import { Column } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const ChatContainer = styled(Column)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  min-width: 0;
  position: relative;
  background-color: ${({ theme }): string => theme.colors.black.black0};
  padding: 0;
`;
