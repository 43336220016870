import { FC } from 'react';

import NoData from '@components/elements/NoData';
import { ReactComponent as PaintSplash } from '@internals/business-shared/src/assets/illustrations/paint-splash2.svg';
import WoodenChipsImage from '@internals/business-shared/src/assets/images/wooden-chips.png';
import { Div, Image } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface EmptyListProps {
  action?: VoidFunction;
}

export const EmptyList: FC<EmptyListProps> = ({ action }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  return (
    <Div py={4} px={6}>
      <NoData
        autoSize
        title={t('chat.item.template.emptyList.title')}
        text={t('chat.item.template.emptyList.text')}
        image={
          <Div
            position="relative"
            display="flex"
            alignItems="center"
            width="85px"
          >
            <PaintSplash fill={themeContext.colors.beige.base} width="72px" />
            <Div position="absolute" width="100%">
              <Image src={WoodenChipsImage} width="100%" height="100%" />
            </Div>
          </Div>
        }
        {...(action && {
          button: {
            onClick: action,
            variant: 'secondary',
            text: t('chat.item.template.new'),
          },
        })}
      />
    </Div>
  );
};
