import { ContactAboutProductRequestDetails } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';

export const getContactAboutProductRequestDetails = (
  details: Omit<ContactAboutProductRequestDetails, 'url'>
): ContactAboutProductRequestDetails => {
  return {
    url: window.location.href,
    ...details,
  };
};
