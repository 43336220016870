import { BusinessUser } from '../types/User';
import {
  IdentityQueryPayload,
  isIdentityQuerySuccessResponse,
} from './query/Identity/IdentityQuery';

export type UserTraits = Record<string, unknown> & {
  userId: string;
};

export const userToTraits = (
  user: BusinessUser,
  identityData: IdentityQueryPayload
): UserTraits => {
  const {
    business: businessPayload,
    businessAlbums,
    businessEvaluationScore,
    businessSettings,
    businessStates,
    businessWorkTypes,
    businessEmployees,
  } = identityData || {};
  const business = isIdentityQuerySuccessResponse(businessPayload)
    ? businessPayload
    : null;
  const products = business?.products || null;

  return {
    ...(user && {
      userId: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      company: {
        id: user.operatingAs.id,
      },
      ...(business && {
        ...(products && {
          products_BasicPackage: products.basicPackage.isOwned,
          products_JobPackage: products.jobPackage.isOwned,
          products_ProPackage: products.proPackage.isOwned,
          products_Advertising: products.advertising.isOwned,
          products_CompanySearch: products.companySearch.isOwned,
        }),
        ...(businessStates && {
          isVerified: businessStates.isVerified,
          isDeactivated: businessStates.isDeactivated,
          isBlacklisted: businessStates.isBlacklisted,
          isOrderUnpaid: businessStates.isOrderUnpaid,
          hasProducts: businessStates.hasProducts,
          hasProductExpired: businessStates.hasProductExpired,
          isNew: businessStates.isNew,
          products: businessStates.products,
          jobAccess: businessStates.jobAccess,
        }),
        ...(businessWorkTypes && {
          workTypes: businessWorkTypes.workTypes.map((workType) => ({
            id: workType.id,
            title: workType.title,
            industries: workType?.industries?.map((industry) => ({
              id: industry.id,
              title: industry.title,
            })),
          })),
        }),
        ...(businessEmployees && {
          profile_Employees: businessEmployees.length,
        }),

        profile_Description: String(business?.profileDescription).length,
        profile_Logo: user.operatingAs.logo !== null,
        profile_CoverPhoto:
          (user.operatingAs?.profileHeaderImage ?? '').trim() !== '',
        profile_Images: businessAlbums.reduce(
          (acc, album) => acc + album.images.length,
          0
        ),

        reviews_Score: businessEvaluationScore.average,
        reviews_Number: businessEvaluationScore.totalCount,
        jobs_ClipsRemaining:
          Number(businessSettings.NumAnswersRemaining) +
          Number(businessSettings.BoostCreditsRemaining),
        jobs_Answered: businessSettings.NumAnswers,
      }),
    }),
  };
};
