import { useEffect } from 'react';

import { MessageTemplate } from '@internals/business-shared/src/hooks/useMessageTemplates';
import { STANDARD_ANSWER_TITLE_MAX_LENGTH } from '@internals/business-shared/src/utils/constants/messages';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Form {
  title: string;
  text: string;
}

type UseMessageTemplateFormikType = (
  template: MessageTemplate,
  onSubmit: (template: MessageTemplate) => void,
  onChange: (template: MessageTemplate) => void
) => FormikProps<Form>;

export const useMessageTemplateFormik: UseMessageTemplateFormikType = (
  template,
  onSubmit,
  onChange
) => {
  const { t } = useTranslation();
  const { title, text } = template;

  const formik: FormikProps<Form> = useFormik({
    initialValues: {
      title,
      text,
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .trim()
        .required(t('general.form.validation.required'))
        .max(
          STANDARD_ANSWER_TITLE_MAX_LENGTH,
          t('general.form.validation.string.maxLength', {
            value: STANDARD_ANSWER_TITLE_MAX_LENGTH,
          })
        ),
      text: Yup.string().trim().required(t('general.form.validation.required')),
    }),
    onSubmit: () => onSubmit(template),
  });

  useEffect(() => {
    onChange({ ...template, ...formik.values });
  }, [formik.values]);

  useEffect(() => {
    formik.validateForm();
  }, [template.id]);

  return formik;
};
