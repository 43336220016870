import { FOOTER_BOX_SHADOW } from '@components/layout/Job/styled/constants';
import { Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const StyledMobileFooterContainer = styled(Div)`
  position: sticky;
  width: 100%;
  z-index: ${Layer.sticky};
  bottom: 0;
  left: 0;
  padding: ${({ theme }) => `0 ${theme.space[6]}px`};
  background-color: ${({ theme }) => theme.background.white};
  box-shadow: ${FOOTER_BOX_SHADOW};
  border-top: 1px solid ${({ theme }) => theme.colors.black.black3};
  flex-direction: column;
  align-items: end;
  > * {
    width: 100%;
  }
`;
