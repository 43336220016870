import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';

import { Container } from './styled/Container';

interface CloseButtonProps {
  onClose: VoidFunction;
}

export const CloseButton: FC<CloseButtonProps> = ({ onClose }) => (
  <Container onClick={onClose}>
    <FireIcon
      width={30}
      height={30}
      iconName={IconPickerName.CancelCircle}
      fill="black.black0"
    />
  </Container>
);
