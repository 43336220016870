import { useEffect, useRef } from 'react';

export const useSetDocumentTitle = (title: string, keep = false) => {
  const currentTitle = useRef(document.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    return () => {
      if (!keep) {
        document.title = currentTitle?.current;
      }
    };
  }, [keep]);
};
