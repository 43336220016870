import { ApolloCache } from '@apollo/client';

import { ASK_TO_BE_EVALUATED } from '../../utils/generated/generated';

const askToBeEvaluatedUpdate =
  (jobId: string) =>
  (
    cache: ApolloCache<ASK_TO_BE_EVALUATED>,
    { data }: { data?: ASK_TO_BE_EVALUATED | null }
  ) => {
    if (!data?.businessAskToBeEvaluated) return;
    cache.modify({
      id: cache.identify({
        jobId,
        __typename: 'JobBusinessRelationState',
      }),
      fields: {
        hasRequestedEvaluation() {
          return true;
        },
      },
    });
  };

const askToBeEvaluatedOptimisticResponse: ASK_TO_BE_EVALUATED = {
  businessAskToBeEvaluated: true,
};

export { askToBeEvaluatedUpdate, askToBeEvaluatedOptimisticResponse };
