import gql from 'graphql-tag';

import {
  DELETE_ANSWER_TEMPLATEVariables,
  DELETE_ANSWER_TEMPLATE as DELETE_ANSWER_TEMPLATE_Result,
} from '../../generated/generated';

export type DeleteAnswerTemplateMutationPayload = DELETE_ANSWER_TEMPLATE_Result;
export type DeleteAnswerTemplateMutationVariables =
  DELETE_ANSWER_TEMPLATEVariables;

export const DELETE_ANSWER_TEMPLATE = gql`
  mutation DELETE_ANSWER_TEMPLATE($input: DeleteAnswerTemplateInput!) {
    deleteAnswerTemplate(input: $input) {
      success
    }
  }
`;
