import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const FilterBadgeIconContainer = styled(Div)`
  padding: 1px;
  position: absolute;
  right: ${({ theme }) => `${theme.space[4]}px`};
  border: ${({ theme }) => `1px solid ${theme.colors.black.black0}`};
  width: 12px;
  height: 12px;
  border-radius: ${({ theme }) => theme.radii[4]};
  background-color: ${({ theme }) => theme.colors.black.black0};
  display: flex;
  align-items: center;
`;

export default FilterBadgeIconContainer;
