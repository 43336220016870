import gql from 'graphql-tag';

import {
  SELF_SERVICE_SUBSCRIPTIONS as SELF_SERVICE_SUBSCRIPTIONSPayload,
  SELF_SERVICE_SUBSCRIPTIONS_selfServiceSubscriptions,
} from '../../generated/generated';

export type SelfServiceSubscriptionsPayload = SELF_SERVICE_SUBSCRIPTIONSPayload;
export type SelfServiceSubscriptions =
  SELF_SERVICE_SUBSCRIPTIONS_selfServiceSubscriptions;

export const SELF_SERVICE_SUBSCRIPTIONS = gql`
  query SELF_SERVICE_SUBSCRIPTIONS {
    selfServiceSubscriptions {
      id
      price
      slug
      clips
    }
  }
`;
