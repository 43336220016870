import { FC } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

export const YourSubscriptionLabel: FC = () => {
  const { modalState } = useBuySubscriptionModalContext();
  const { radii } = useTheme();
  const { t } = useTranslation();

  if (modalState !== ModalState.SUMMARY) {
    return null;
  }

  return (
    <Div
      bg="green.lightest"
      position="absolute"
      left={0}
      bottom={0}
      width={1}
      borderBottomLeftRadius={radii[2]}
      borderBottomRightRadius={radii[2]}
    >
      <Text.p fontSize={1} textAlign="center" fontWeight="medium" mb={0} py={3}>
        {t('job.modal.subscription.packageCard.yourSubscription')}
      </Text.p>
    </Div>
  );
};
