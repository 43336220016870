import gql from 'graphql-tag';

const JOB_LABELS_FRAGMENT = gql`
  fragment JobLabelsFragment on JobLabels {
    big
    clipCount
    deleted
    doffin
    ended
    favourite
    free
    paused
    won
  }
`;

export default JOB_LABELS_FRAGMENT;
