import * as React from 'react';

import { useApolloClient, useMutation } from '@apollo/client';
import { useFlagEnabled } from '@components/base/CheckFlag';
import ErrorAlert from '@components/base/ErrorAlert';
import { TriggerSource } from '@components/elements/BoostCreditsModal/constants';
import { useBoostCreditsModalContext } from '@components/elements/BoostCreditsModal/ModalContext';
import NewMessage from '@components/elements/NewMessage/NewMessage';
import { JobAnswerTip } from '@components/layout/Job/JobAnswerTip';
import { JobFormMobile } from '@components/layout/Job/JobFormMobile';
import { JobContentSection } from '@components/layout/Job/styled/JobContentSection';
import { JobCostIndicator } from '@components/layout/Job/styled/JobCostIndicator';
import JobFormContainer from '@components/layout/Job/styled/JobFormContainer';
import { useAnswerTipDisplay } from '@components/layout/Job/useAnswerTipDisplay';
import { useJobNavigationContext } from '@contexts/JobNavigationContext';
import {
  useTotalRemainingCredits,
  useUserContext,
} from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import { useIsCreditsAvailable } from '@hooks/useIsCreditsAvailable';
import useStorage, { STORE_JOB_INQUIRY_PREFIX } from '@hooks/useStorage';
import {
  answerJobOptimisticResponse,
  answerJobUpdate,
} from '@internals/business-shared/src/cache/updates/answerJob';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import { useChatListFetch } from '@internals/business-shared/src/hooks/useAllChatListFetch';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { ANSWER_TIP_THREE_TIPS_VARIATION } from '@internals/business-shared/src/utils/analyticsConstants';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { isBoligmappaRequestedForJob } from '@internals/business-shared/src/utils/BoligMappaUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  AnswerJobResponseError,
  GQLErrorState,
} from '@internals/business-shared/src/utils/errors';
import { FileWithPreview } from '@internals/business-shared/src/utils/FileUtils';
import {
  ChatListType,
  JOB_QUERY_job_Job,
  JOB_QUERY_jobMeta,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  ANSWER_JOB,
  AnswerJobMutationPayload,
  AnswerJobMutationVariables,
} from '@internals/business-shared/src/utils/mutation/AnswerJob/AnswerJobMutation';
import { COMPANY_SETTINGS_QUERY } from '@internals/business-shared/src/utils/query/CompanySettings/CompanySettings';
import { JOB_OWNER_QUERY } from '@internals/business-shared/src/utils/query/JobOwner/JobOwnerQuery';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import {
  CHAT_LIST_DEFAULT_COUNT,
  CHAT_LIST_DEFAULT_FROM,
} from '@root/src/apollo/cache';
import { JobCostInfo } from '@root/src/components/layout/Job/JobCostInfo';
import { Div } from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';

export interface JobFormProps {
  jobId: JOB_QUERY_job_Job['id'];
  onAnswerCompleted: VoidFunction;
  onAnswer: VoidFunction;
  jobCost: number;
  disabled?: boolean;
  version?: 'mobile' | 'regular';
  jobTraits: JobTraitsQueryData | null;
  jobMeta: Pick<JOB_QUERY_jobMeta, 'generics'>;
}

const CHAT_REFETCH_AFTER_JOB_RESPONSE_DELAY = 5000; // 5s
const ANSWER_TIP_ID = 'answer-tip';

const JobForm: React.FC<JobFormProps> = ({
  jobId,
  onAnswer,
  onAnswerCompleted,
  jobCost,
  disabled,
  version = 'regular',
  jobTraits,
  jobMeta,
}) => {
  const { get, set, remove } = useStorage(STORE_JOB_INQUIRY_PREFIX);
  const apolloClient = useApolloClient();
  const user = useUserContext();
  const initialMessage = get(jobId)?.data?.message ?? '';
  const { track } = useAnalytics();
  const { showNavigation, isNavigationUsed } = useJobNavigationContext();
  const isMobileVersion = version === 'mobile';
  const {
    filterState: { activeFilter },
  } = useSavedFiltersContext();

  const [formVisible, showForm, hideForm] = useBoolean(true);
  const [message, setMessage] = React.useState(initialMessage);
  const [files, setFiles] = React.useState<FileWithPreview[]>([]);
  const [errors, setErrors] = React.useState<GQLErrorState>({});

  const {
    isAnswerTipOpen,
    openAnswerTip,
    closeAnswerTip,
    removeAnswerTipStoreRecord,
  } = useAnswerTipDisplay(jobId);
  const isAnswerTipEnabled = useFlagEnabled(FeatureFlags.BizAnswerTip);

  const remainingCredits = useTotalRemainingCredits();
  const { handleModalOpen } = useBoostCreditsModalContext();
  const isCreditsAvailable = useIsCreditsAvailable();
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );

  const chatListFetch = useChatListFetch(apolloClient, {
    variables: {
      from: CHAT_LIST_DEFAULT_FROM,
      count: CHAT_LIST_DEFAULT_COUNT,
    },
  });

  const showBoostCreditsModal = () => {
    const spentCredits = remainingCredits - jobCost;

    const hasSpentLastCredits = spentCredits <= 0;
    const isNotClipFreeJob = jobCost > 0;

    if (
      isTestBuyMoreClipsFlagEnabled &&
      isCreditsAvailable &&
      isNotClipFreeJob &&
      hasSpentLastCredits
    ) {
      handleModalOpen(TriggerSource.AUTO_OPEN, jobId);
    }
  };

  const [answerJob, { loading }] = useMutation<
    AnswerJobMutationPayload,
    AnswerJobMutationVariables
  >(ANSWER_JOB, {
    onCompleted: (resultData) => {
      const answerJobResponseError = new AnswerJobResponseError(
        'AnswerJobPayload'
      );
      if (
        !answerJobResponseError.hasResponseError(
          resultData.answerJob,
          setErrors
        )
      ) {
        setMessage('');
        setErrors({});
        setFiles([]);
        remove(jobId);
        removeAnswerTipStoreRecord();
        showBoostCreditsModal();

        // on successful job response, refetch active chat list with updated job
        // currently on the client we're not able to determine exact moment the elastic search indexing
        // completed (when the job is present in the chat list response), hence the delay
        setTimeout(() => {
          chatListFetch(ChatListType.ACTIVE);
        }, CHAT_REFETCH_AFTER_JOB_RESPONSE_DELAY);
      } else {
        showForm();
      }
      onAnswerCompleted();
    },
    onError: (e) => {
      showForm();
      onAnswerCompleted();
      bugsnagClient.notify(
        new Error(`Error when answering the job (${jobId}):  ${e.message}`)
      );
    },
    update: answerJobUpdate(jobId, user, jobCost),
    refetchQueries: [
      { query: COMPANY_SETTINGS_QUERY, variables: { id: user.operatingAs.id } },
      JOB_OWNER_QUERY,
    ],
  });

  const handleSetMessage = (value: string) => {
    set(jobId, { message: value });
    setMessage(value);
  };

  const trackAnswerJob = () => {
    track(ANEventSpace(ANEvent.Answered, ANObject.Job, ANPage.JobCard), {
      jobId,
      bId: user.operatingAs.id,
      tipVisible: isAnswerTipEnabled
        ? ANSWER_TIP_THREE_TIPS_VARIATION
        : undefined,

      usedGalleryButton: isNavigationUsed,
      galleryButtonVisible: showNavigation,
      source: activeFilter?.label,
      isVerifiedWithSecureId: jobTraits?.fromVerifiedUser,
      wantsBoligmappa: isBoligmappaRequestedForJob(jobMeta),
      questionsAnswered: !!jobTraits?.questionsRegForm?.length,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    const newFiles = files.map((file) => file.file);
    e.preventDefault();
    trackAnswerJob();
    answerJob({
      variables: {
        jobId,
        text: message,
        files: newFiles,
      },
      optimisticResponse: answerJobOptimisticResponse(jobId, message),
    });
    onAnswer();
    hideForm();
  };

  React.useEffect(() => {
    // Specify how to clean up after this effect:
    return (): void => {
      if (!loading) {
        setMessage(get(jobId)?.data?.message ?? '');
      }
    };
  }, [jobId]);

  if (!formVisible && !isMobileVersion) return null;

  if (isMobileVersion) {
    return (
      <JobFormMobile
        errors={errors}
        jobCost={jobCost}
        remainingCredits={remainingCredits}
        message={message}
        handleSetMessage={handleSetMessage}
        files={files}
        setFiles={setFiles}
        handleSubmit={handleSubmit}
        disabled={disabled}
      />
    );
  }
  return (
    <Div mb={0}>
      <JobAnswerTip
        tooltipId={ANSWER_TIP_ID}
        isOpen={isAnswerTipOpen}
        onClose={closeAnswerTip}
      />

      <JobContentSection>
        <JobFormContainer>
          <form
            onSubmit={handleSubmit}
            data-testid="job-form"
            data-tooltip-id={ANSWER_TIP_ID}
            onFocus={openAnswerTip}
          >
            <NewMessage
              message={message}
              setMessage={handleSetMessage}
              files={files}
              setFiles={setFiles}
            />
          </form>
        </JobFormContainer>
      </JobContentSection>
      <ErrorAlert errors={errors} />
      {remainingCredits !== null && (
        <Div
          display="flex"
          alignItems="center"
          backgroundColor="black.black1"
          mt={5}
          p={4}
        >
          <JobCostIndicator amount={remainingCredits} />
          <JobCostInfo cost={jobCost} />
        </Div>
      )}
    </Div>
  );
};

export default JobForm;
