import { FC } from 'react';

import { JobDataItemProps } from '@components/layout/Job/JobDataItem';
import {
  JobDataHiddenItemText,
  JobDataItemText,
} from '@components/layout/Job/styled/JobDataItemText';
import { JobDataItemTitle } from '@components/layout/Job/styled/JobDataItemTitle';
import { useHidden } from '@internals/business-shared/src/hooks/useHidden';
import { Div } from '@schibsted-smb/fireball';

interface JobDataHiddenItemProps extends JobDataItemProps {
  textWhenHidden: string;
  onReveal?: VoidFunction;
}

export const JobDataHiddenItem: FC<JobDataHiddenItemProps> = ({
  text,
  title,
  textWhenHidden,
  onReveal,
  disabled,
}) => {
  const [isHidden, handleReveal] = useHidden(onReveal);

  return (
    <Div py={4}>
      <JobDataItemTitle>{title}</JobDataItemTitle>
      {isHidden && !disabled ? (
        <JobDataHiddenItemText variant="linkTertiary" onClick={handleReveal}>
          {textWhenHidden}
        </JobDataHiddenItemText>
      ) : (
        <JobDataItemText disabled={disabled}>{text}</JobDataItemText>
      )}
    </Div>
  );
};
