import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  UNLINK_BOLIGMAPPA_INTEGRATION,
  UnlinkBoligmappaIntegrationMutationPayload,
} from '../../utils/mutation/UnlinkBoligmappaIntegration/UnlinkBoligmappaIntegrationMutation';

type UseUnlinkBoligmappaIntegrationMutationAction = (
  mutationOptions?: MutationFunctionOptions<UnlinkBoligmappaIntegrationMutationPayload>
) => Promise<FetchResult<UnlinkBoligmappaIntegrationMutationPayload>>;

export const useUnlinkBoligmappaIntegrationMutation = (): [
  UseUnlinkBoligmappaIntegrationMutationAction,
  MutationResult<UnlinkBoligmappaIntegrationMutationPayload>
] => {
  const [unlinkBoligmappaIntegration, ...rest] =
    useMutation<UnlinkBoligmappaIntegrationMutationPayload>(
      UNLINK_BOLIGMAPPA_INTEGRATION,
      {
        fetchPolicy: 'network-only',
      }
    );

  const mutationFunction: UseUnlinkBoligmappaIntegrationMutationAction =
    useCallback(
      (mutationOptions) => unlinkBoligmappaIntegration({ ...mutationOptions }),
      []
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
