import gql from 'graphql-tag';

import PAGE_INFO_FRAGMENT from '../../fragments/PageInfoFragment';
import {
  INVOICE_RECEIPT_LIST_QUERYVariables,
  INVOICE_RECEIPT_LIST_QUERY as INVOICE_RECEIPT_LIST_QUERY_Result,
} from '../../generated/generated';

export type InvoiceReceiptListQueryPayload = INVOICE_RECEIPT_LIST_QUERY_Result;
export type InvoiceReceiptListQueryVariables =
  INVOICE_RECEIPT_LIST_QUERYVariables;
export type InvoiceReceiptListData =
  INVOICE_RECEIPT_LIST_QUERY_Result['invoiceReceiptList']['edges'];

export const isInvoiceReceiptListQuerySuccessResponse = (
  invoiceReceiptList?: INVOICE_RECEIPT_LIST_QUERY_Result['invoiceReceiptList']
): invoiceReceiptList is INVOICE_RECEIPT_LIST_QUERY_Result['invoiceReceiptList'] =>
  !!invoiceReceiptList &&
  invoiceReceiptList.__typename === 'InvoiceReceiptConnection';

export const INVOICE_RECEIPT_LIST_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}

  query INVOICE_RECEIPT_LIST_QUERY($input: ReceiptListInput) {
    invoiceReceiptList(input: $input) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          paidDate
          invoiceDate
          dueDate
          pdfUrl
          pdfDownloadUrl
        }
      }
    }
  }
`;
