import * as React from 'react';

import { useTranslation } from 'react-i18next';

export const ErrorBoundaryView: React.FC<null> = () => {
  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: '10px', fontFamily: 'system-ui, sans-serif' }}>
      <div style={{ width: '128px', margin: '0 auto 0 auto' }}>
        <svg
          viewBox="0 0 128 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M90.8 52.098c-3.2-4.6-10-4.6-13.2 0l-3.2 4.6H84c2.1 0 3.8 1.5 4 3.6.2 2.4-1.5 4.4-3.8 4.4H68.7l-4.7 6.7 8.1 11.5v30.1c0 4.4-3.6 8-8.1 8h40.4c4.5 0 8.1-3.6 8.1-8v-30.2l-21.7-30.7z"
            fill="#BDE4FC"
          />
          <path
            d="M112.5 102c0 4.4-3.6 8-8.1 8H72.1v3c0 4.4-3.6 8-8.1 8h40.4c4.5 0 8.1-3.6 8.1-8v-11"
            fill="#00629F"
          />
          <path
            d="M64 120.998H23.6c-4.5 0-8.1-3.6-8.1-8v-30.2l21.7-30.7c3.2-4.6 10-4.6 13.2 0l21.7 30.7v30.2c0 4.4-3.6 8-8.1 8z"
            fill="#007BC7"
          />
          <path
            d="M64 123.999H23.6c-6.1 0-11.1-4.9-11.1-11v-30.2c0-.6.2-1.2.5-1.7l21.7-30.7c2.1-2.9 5.5-4.7 9.1-4.7 3.6 0 7 1.8 9.1 4.7l21.7 30.7c.4.5.5 1.1.5 1.7V113c0 6.1-5 11-11.1 11zm-45.5-40.2V113c0 2.8 2.3 5 5.1 5H64c2.8 0 5.1-2.3 5.1-5v-29.2L48 53.8c-1-1.4-2.5-2.2-4.2-2.2-1.7 0-3.2.8-4.2 2.2l-21.1 30z"
            fill="#00253B"
          />
          <path
            d="M104.4 124.002H64.8c-1.7 0-3-1.3-3-3s1.3-3 3-3h39.6c2.8 0 5.1-2.3 5.1-5v-29.2l-21.1-30c-1-1.4-2.5-2.2-4.2-2.2-1.7 0-3.2.8-4.2 2.2l-2.4 3.4c-1 1.4-2.8 1.7-4.2.7-1.4-1-1.7-2.8-.7-4.2l2.4-3.4c2.1-2.9 5.5-4.7 9.1-4.7 3.6 0 7 1.8 9.1 4.7l21.7 30.8c.4.5.5 1.1.5 1.7v30.2c0 6.1-5 11-11.1 11z"
            fill="#00253B"
          />
          <path
            d="M112.5 85.8H72.1c-1.7 0-3-1.3-3-3s1.3-3 3-3h40.4c1.7 0 3 1.3 3 3s-1.3 3-3 3z"
            fill="#00253B"
          />
          <path d="M43.8 55.7a5 5 0 1 0 0 10 5 5 0 0 0 0-10z" fill="#fff" />
          <path
            d="M64 74.4c-.6 0-1.2-.2-1.7-.5-1.4-.9-1.7-2.8-.7-4.2l4-5.7c.6-.8 1.5-1.3 2.5-1.3H80c1.2 0 2.2-.9 2.2-2s-1-2-2.2-2H63.7c-1.7 0-3-1.3-3-3s1.3-3 3-3H80c4.5 0 8.2 3.6 8.2 8s-3.7 8-8.2 8H69.5l-3.1 4.4c-.5.8-1.5 1.3-2.4 1.3zM40.5 35c-1.1 0-2.2-.6-2.7-1.7l-5-10c-.7-1.5-.1-3.3 1.3-4 1.5-.7 3.3-.1 4 1.3l5 10c.7 1.5.1 3.3-1.3 4-.4.3-.8.4-1.3.4zm23 0c-1.7 0-3-1.3-3-3V17c0-1.7 1.3-3 3-3s3 1.3 3 3v15c0 1.7-1.3 3-3 3zm23 0c-.5 0-.9-.1-1.3-.3-1.5-.7-2.1-2.5-1.3-4l5-10c.7-1.5 2.5-2.1 4-1.3 1.5.7 2.1 2.5 1.3 4l-5 10c-.5 1-1.6 1.6-2.7 1.6z"
            fill="#00253B"
          />
        </svg>
      </div>
      <h1 style={{ textAlign: 'center', fontSize: '32px' }}>
        {t('general.error.generic') ?? 'An error occured'}
      </h1>
      <p style={{ textAlign: 'center', fontSize: '18px', color: '#777C80' }}>
        {t('general.error.contactIfPersists') ??
          'If the error persists, contact customer service.'}
      </p>
    </div>
  );
};
