import { FC, PropsWithChildren } from 'react';

import { Div } from '@schibsted-smb/fireball';

import { CUSTOM_YELLOW_200 } from '../../constants';

export const HeaderContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Div
      bg={CUSTOM_YELLOW_200}
      borderTopLeftRadius={8}
      borderTopRightRadius={8}
      p={6}
      position="relative"
    >
      {children}
    </Div>
  );
};
