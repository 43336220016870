import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ChatListFilterContainerV2 = styled(Div)`
  display: flex;
  min-height: ${({ theme }): string => `${theme.space[9]}px`}; // 60px
  width: 100%;
  padding: ${({ theme }): string =>
    `0 ${theme.space[7]}px 0 ${theme.space[7]}px`};
  border-bottom: ${({ theme }): string =>
    `${theme.space[1]}px solid ${theme.colors.black.black3}`};
  justify-content: space-between;
`;

export default ChatListFilterContainerV2;
