import { useEffect, useMemo } from 'react';

import { QueryResult } from '@apollo/client';

import { SortDirection } from '../utils/generated/generated';
import { isOptimisticId } from '../utils/optimistic/id';
import { ChatConversation } from '../utils/query/ChatConversation/ChatConversation';
import { useChatConversationQuery } from './query/useChatConversationQuery';

interface ChatConversationQueryResult extends QueryResult {
  data: ChatConversation | null;
}

const MESSAGES_COUNT = 25;

export const useLoadAllChatConversation = (
  chatId?: string
): ChatConversationQueryResult => {
  const {
    data: conversationData,
    error: chatConversationError,
    loading: chatConversationLoading,
    fetchMore,
    ...rest
  } = useChatConversationQuery(chatId, {
    variables: {
      id: chatId,
      first: MESSAGES_COUNT,
      orderByTime: SortDirection.ASC,
    },
    notifyOnNetworkStatusChange: true,
    skip: !chatId || isOptimisticId(chatId),
  });

  const chatMessages = useMemo(
    () => conversationData?.edges ?? [],
    [conversationData?.edges]
  );

  const totalCount = useMemo(
    () => conversationData?.pageInfo?.totalCount ?? 0,
    [conversationData?.pageInfo?.totalCount]
  );

  const canFetchMoreMessages = useMemo(() => {
    const hasMoreMessages = chatMessages.length < totalCount;
    return (
      !chatConversationError && !chatConversationLoading && hasMoreMessages
    );
  }, [
    chatMessages?.length,
    chatConversationLoading,
    chatConversationError,
    totalCount,
  ]);

  useEffect(() => {
    if (canFetchMoreMessages) {
      fetchMore({
        variables: {
          first: MESSAGES_COUNT,
          from: chatMessages.length,
          orderByTime: SortDirection.ASC,
          id: chatId,
          last: undefined,
        },
      });
    }
  }, [fetchMore, chatId, canFetchMoreMessages, chatMessages.length]);

  return useMemo(
    () => ({
      data: conversationData,
      error: chatConversationError,
      loading: chatConversationLoading,
      fetchMore,
      ...rest,
    }),
    [
      conversationData,
      chatConversationError,
      chatConversationLoading,
      fetchMore,
      rest,
    ]
  );
};
