import { FC } from 'react';

import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Add,
  Alert,
  Arrow2Left,
  AwardRibbon,
  BankidIcon,
  Book,
  Box,
  Briefcase,
  BurgerMenu2,
  Calendar,
  Camera,
  Cancel,
  ChatMessage,
  Check,
  CheckCircle,
  Clipboard,
  ContactCard,
  Content,
  Delivery,
  Document,
  Download,
  Fire,
  Folder,
  Frown,
  Headset,
  Heart,
  HeartOff,
  History,
  Home,
  Image,
  Image2,
  Info2,
  LargeProjects,
  MapLocation,
  MoneyCircle,
  Paperclip,
  Pencil,
  Phone,
  Printer,
  Receipt,
  Search,
  SendOut,
  Settings,
  SimpleArrowDown,
  SimpleArrowLeft,
  SimpleArrowRight,
  SimpleArrowUp,
  SpecialDiscount,
  Star,
  Star2,
  Timer,
  Toolbox,
  Trash,
  Trophy,
  Unlock,
  User,
  UserAdd,
  Warning,
  World,
  CancelCircle,
  UserCircle,
  Contacts,
  MetaAccommodationSize,
  MetaAddressFrom,
  MetaAddressTo,
  MetaCarModel,
  MetaCarRental,
  MetaCleaningFrequency,
  MetaContactPreference,
  MetaContainerSize,
  MetaCostEstimate,
  MetaCustomerType,
  MetaFoodAndDrink,
  MetaFuelType,
  MetaLastRoadWorthinessTestDate,
  MetaLeadsSpaceSize,
  MetaMaFeedbackTicket,
  MetaAdminComment,
  MetaMaterialsIncluded,
  MetaMoveOutCleaning,
  MetaNextRoadWorthinessTestDate,
  MetaNumberOfGuests,
  MetaShaftCount,
  MetaStorageSize,
  MetaTenderNotApprovedReason,
  MetaTenderRequestedStartAfterDate,
  MetaTenderRequestedStartBeforeDate,
  MetaTenderRequestedStartNowDate,
  MetaTenderRequestedStartWheneverDate,
  MetaTenderStartDate,
  MetaTransmissionType,
  MetaVehicleMileage,
  MetaVehicleRegistrationPlate,
  MetaWeight,
  CaretRight,
  CaretLeft,
  CaretUp,
  Megaphone,
  BigBen,
  CapitolBuilding,
  Mail,
  BagMoney,
  RadioButton,
  RadioButtonActive,
  Info,
  Ellipsis,
  Newspaper,
  BellRing,
  Palette,
  Padlock,
  FilePdf,
  FileDoc,
  FileExe,
  ArrowExpand,
  ArrowShrink,
  Languages,
  FlagFi,
  FlagNo,
  FlagUk,
  Wave,
  Phone1,
  CreditCard,
  Clock,
  Team,
  StarRequested,
  TrophyRequested,
  SendMail,
  ChatMessageAlt,
  Cross,
  Copy,
  Fingerprint,
  Share,
  Reply,
  Arrow2Up,
  Arrow2Down,
  PasteIn,
  BoligmappaFlat,
  BoligmappaIcon,
  InfoFilled,
  HomeAlternative,
  Arrow2Right,
  SpecialCheck,
  PadlockUnlock,
  TagMoney,
  BadgeId,
  CalendarAlt,
  ShieldCheck,
  CheckmarkRounded,
  TuneAlt,
  Medal,
  CaretDown,
  Easy,
  Stars,
  ShieldAlt,
  ClipboardCancel,
} from '@schibsted-smb/fireball-icons-remppatori';
import { bugsnagClient } from '@utils/initBugsnag';

const icons: { [index: string]: FC<IconProps> } = {
  [IconPickerName.AddEmployee]: UserAdd,
  [IconPickerName.Address]: MapLocation,
  [IconPickerName.Ads]: Megaphone,
  [IconPickerName.Areas]: World,
  [IconPickerName.Arrow2Down]: Arrow2Down,
  [IconPickerName.Arrow2Left]: Arrow2Left,
  [IconPickerName.Arrow2Right]: Arrow2Right,
  [IconPickerName.Arrow2Up]: Arrow2Up,
  [IconPickerName.ArrowDropdown]: SimpleArrowDown,
  [IconPickerName.ArrowLeft]: Arrow2Left,
  [IconPickerName.Attachment]: Paperclip,
  [IconPickerName.Attention]: Warning,
  [IconPickerName.RandomCodeLines]: BankidIcon,
  [IconPickerName.Big]: BigBen,
  [IconPickerName.Book]: Book,
  [IconPickerName.BoligMappa]: BoligmappaFlat,
  [IconPickerName.BoligMappaNoCircle]: BoligmappaIcon,
  [IconPickerName.BurgerMenu]: BurgerMenu2,
  [IconPickerName.BadgeId]: BadgeId,
  [IconPickerName.Calendar]: Calendar,
  [IconPickerName.Camera]: Camera,
  [IconPickerName.Caution]: Warning,
  [IconPickerName.Chat]: ChatMessage,
  [IconPickerName.ChatMessageAlt]: ChatMessageAlt,
  [IconPickerName.Check]: Check,
  [IconPickerName.CheckmarkCircled]: CheckCircle,
  [IconPickerName.ChevronDown]: SimpleArrowDown,
  [IconPickerName.ChevronLeft]: SimpleArrowLeft,
  [IconPickerName.ChevronRight]: SimpleArrowRight,
  [IconPickerName.ChevronUp]: SimpleArrowUp,
  [IconPickerName.ChevronDownBold]: CaretDown,
  [IconPickerName.ChevronLeftBold]: CaretLeft,
  [IconPickerName.ChevronRightBold]: CaretRight,
  [IconPickerName.ChevronUpBold]: CaretUp,
  [IconPickerName.Clock]: Clock,
  [IconPickerName.Close]: Cancel,
  [IconPickerName.CompanyInfo]: Briefcase,
  [IconPickerName.Copy]: Copy,
  [IconPickerName.Contract]: Pencil,
  [IconPickerName.CreditCard]: CreditCard,
  [IconPickerName.Cross]: Cross,
  [IconPickerName.CancelCircle]: CancelCircle,
  [IconPickerName.CheckmarkRounded]: CheckmarkRounded,
  [IconPickerName.Decrease]: ArrowShrink,
  [IconPickerName.Delete]: Trash,
  [IconPickerName.Deleted]: Trash,
  [IconPickerName.Doffin]: CapitolBuilding,
  [IconPickerName.Download]: Download,
  [IconPickerName.Eclipse]: Easy,
  [IconPickerName.Email]: Mail,
  [IconPickerName.Ended]: Cancel,
  [IconPickerName.Enlarge]: ArrowExpand,
  [IconPickerName.Evaluation]: Star2,
  [IconPickerName.Favourite]: Heart,
  [IconPickerName.FilledInfo]: Info2,
  [IconPickerName.Fingerprint]: Fingerprint,
  [IconPickerName.Fire]: Fire,
  [IconPickerName.FlagFI]: FlagFi,
  [IconPickerName.FlagNO]: FlagNo,
  [IconPickerName.FlagUK]: FlagUk,
  [IconPickerName.Folder]: Folder,
  [IconPickerName.FolderTransparent]: Folder,
  [IconPickerName.Free]: SpecialDiscount,
  [IconPickerName.Home]: Home,
  [IconPickerName.HomeAlternative]: HomeAlternative,
  [IconPickerName.House]: Home,
  [IconPickerName.Images]: Image2,
  [IconPickerName.Info]: Info2,
  [IconPickerName.InfoFilled]: InfoFilled,
  [IconPickerName.Invoice]: BagMoney,
  [IconPickerName.InvoiceNav]: BagMoney,
  [IconPickerName.JobList]: Clipboard,
  [IconPickerName.JobSearch]: Clipboard,
  [IconPickerName.Languages]: Languages,
  [IconPickerName.Map]: MapLocation,
  [IconPickerName.MarkInactive]: RadioButton,
  [IconPickerName.Mark]: RadioButtonActive,
  [IconPickerName.Medal]: Medal,
  [IconPickerName.Message]: ChatMessage,
  [IconPickerName.MetaAccommodationSize]: MetaAccommodationSize,
  [IconPickerName.MetaAddressFrom]: MetaAddressFrom,
  [IconPickerName.MetaAddressTo]: MetaAddressTo,
  [IconPickerName.MetaAdminComment]: MetaAdminComment,
  [IconPickerName.MetaCarModel]: MetaCarModel,
  [IconPickerName.MetaCarRental]: MetaCarRental,
  [IconPickerName.MetaCleaningFrequency]: MetaCleaningFrequency,
  [IconPickerName.MetaContactPreference]: MetaContactPreference,
  [IconPickerName.MetaContainerSize]: MetaContainerSize,
  [IconPickerName.MetaCostEstimate]: MetaCostEstimate,
  [IconPickerName.MetaCustomerType]: MetaCustomerType,
  [IconPickerName.MetaFallback]: Info,
  [IconPickerName.MetaFoodAndDrink]: MetaFoodAndDrink,
  [IconPickerName.MetaFuelType]: MetaFuelType,
  [IconPickerName.MetaLastRoadWorthinessTestDate]:
    MetaLastRoadWorthinessTestDate,
  [IconPickerName.MetaLeadsSpaceSize]: MetaLeadsSpaceSize,
  [IconPickerName.MetaMaFeedbackTicket]: MetaMaFeedbackTicket,
  [IconPickerName.MetaMaterialsIncluded]: MetaMaterialsIncluded,
  [IconPickerName.MetaMoveOutCleaning]: MetaMoveOutCleaning,
  [IconPickerName.MetaNextRoadWorthinessTestDate]:
    MetaNextRoadWorthinessTestDate,
  [IconPickerName.MetaNumberOfGuests]: MetaNumberOfGuests,
  [IconPickerName.MetaShaftCount]: MetaShaftCount,
  [IconPickerName.MetaStorageSize]: MetaStorageSize,
  [IconPickerName.MetaTenderNotApprovedReason]: MetaTenderNotApprovedReason,
  [IconPickerName.MetaTenderRequestedStartAfterDate]:
    MetaTenderRequestedStartAfterDate,
  [IconPickerName.MetaTenderRequestedStartBeforeDate]:
    MetaTenderRequestedStartBeforeDate,
  [IconPickerName.MetaTenderRequestedStartNowDate]:
    MetaTenderRequestedStartNowDate,
  [IconPickerName.MetaTenderRequestedStartWheneverDate]:
    MetaTenderRequestedStartWheneverDate,
  [IconPickerName.MetaTenderStartDate]: MetaTenderStartDate,
  [IconPickerName.MetaTransmissionType]: MetaTransmissionType,
  [IconPickerName.MetaVehicleMileage]: MetaVehicleMileage,
  [IconPickerName.MetaVehicleRegistrationPlate]: MetaVehicleRegistrationPlate,
  [IconPickerName.MetaWeight]: MetaWeight,
  [IconPickerName.Mobile]: Phone,
  [IconPickerName.More]: Ellipsis,
  [IconPickerName.NavigationFolders]: Folder,
  [IconPickerName.New]: Mail,
  [IconPickerName.Newsletter]: Newspaper,
  [IconPickerName.NoImage]: Image,
  [IconPickerName.Notifications]: BellRing,
  [IconPickerName.Offer]: Delivery,
  [IconPickerName.Palette]: Palette,
  [IconPickerName.Password]: Padlock,
  [IconPickerName.PasteIn]: PasteIn,
  [IconPickerName.Pdf]: FilePdf,
  [IconPickerName.Pencil]: Pencil,
  [IconPickerName.Person]: User,
  [IconPickerName.PersonInfo]: ContactCard,
  [IconPickerName.Phone]: Phone,
  [IconPickerName.Phone1]: Phone1,
  [IconPickerName.Plus]: Add,
  [IconPickerName.Ppt]: Document,
  [IconPickerName.Print]: Printer,
  [IconPickerName.Products]: Box,
  [IconPickerName.Profile]: ContactCard,
  [IconPickerName.PadlockUnlock]: PadlockUnlock,
  [IconPickerName.Qualifications]: AwardRibbon,
  [IconPickerName.Receipts]: Receipt,
  [IconPickerName.RemoveFavourite]: HeartOff,
  [IconPickerName.Report]: Alert,
  [IconPickerName.Restore]: History,
  [IconPickerName.Revert]: Reply,
  [IconPickerName.Sad]: Frown,
  [IconPickerName.SadFace]: Frown,
  [IconPickerName.Search]: Search,
  [IconPickerName.SendMail]: SendMail,
  [IconPickerName.SendOffer]: Delivery,
  [IconPickerName.Settings]: Settings,
  [IconPickerName.Share]: Share,
  [IconPickerName.Shield]: ShieldCheck,
  [IconPickerName.ShieldAlt]: ShieldAlt,
  [IconPickerName.SignOut]: SendOut,
  [IconPickerName.Sparkle]: Stars,
  [IconPickerName.Star]: Star,
  [IconPickerName.StarGreen]: Star,
  [IconPickerName.Stars]: Star2,
  [IconPickerName.Subscription]: MoneyCircle,
  [IconPickerName.Support]: Headset,
  [IconPickerName.TagMoney]: TagMoney,
  [IconPickerName.Team]: Team,
  [IconPickerName.Templates]: Content,
  [IconPickerName.Time]: Timer,
  [IconPickerName.Toolbox]: Toolbox,
  [IconPickerName.TuneAlt]: TuneAlt,
  [IconPickerName.Unlocked]: Unlock,
  [IconPickerName.UserArrowDropdown]: SimpleArrowDown,
  [IconPickerName.Wave]: Wave,
  [IconPickerName.Won]: Trophy,
  [IconPickerName.WonGold]: Trophy,
  [IconPickerName.Word]: FileDoc,
  [IconPickerName.Worker]: LargeProjects,
  [IconPickerName.WorldWideWeb]: World,
  [IconPickerName.Xls]: FileExe,
  [IconPickerName.Xps]: Document,
  [IconPickerName.Globe]: World,
  [IconPickerName.CalendarAlt]: CalendarAlt,

  // New icons map
  [IconPickerName.NEW_Close]: Cancel,
  [IconPickerName.NEW_Info]: Alert,
  // Header Desktop Nav
  [IconPickerName.NEW_ChatFlat]: ChatMessage,
  [IconPickerName.NEW_JobListFlat]: Clipboard,
  [IconPickerName.NEW_CompanyInfoFlat]: Contacts,
  [IconPickerName.NEW_ProfileFlat]: UserCircle,
  [IconPickerName.NEW_SettingsFlat]: Settings,
  [IconPickerName.TrophyRequested]: TrophyRequested,
  [IconPickerName.StarRequested]: StarRequested,
  [IconPickerName.SpecialCheck]: SpecialCheck,
  [IconPickerName.ShieldCheck]: ShieldCheck,
  [IconPickerName.ClipboardCancel]: ClipboardCancel,
};
type FireIconVariant = 'flat' | 'color' | 'gray';
export interface FireIconProps {
  iconName: IconPickerName;
  width?: number | string;
  height?: number | string;
  fill?: string;
  variant?: FireIconVariant;
}

type IconProps = Pick<FireIconProps, 'fill' | 'width' | 'height'>;

const DEFAULT_COLOR = '#3960BF'; // theme primary
const DEFAULT_GRAY = '#404C67'; // TextGrayDark, IconsGray        35
const getFillColor = (
  variant: FireIconVariant,
  defaultFillColor: string
): string => {
  switch (variant) {
    case 'flat':
      return defaultFillColor;
    case 'color':
      return DEFAULT_COLOR;
    case 'gray':
      return DEFAULT_GRAY;
    default:
      return defaultFillColor;
  }
};

export const FireIcon: FC<FireIconProps> = ({
  iconName,
  width,
  height,
  fill = DEFAULT_COLOR,
  variant = 'flat',
}: FireIconProps) => {
  const Component: FC<IconProps> | null = icons?.[iconName] ?? null;
  const fillColor = getFillColor(variant, fill);

  if (!Component) {
    bugsnagClient.notify(`Fire Icons is missing: ${iconName}`);
    return null;
  }

  return <Component width={width} height={height} fill={fillColor} />;
};
