import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { JobAlertCard } from '@components/elements/JobAlertCard';
import { JobBuySubscriptionAlert } from '@components/elements/JobBuySubscriptionAlert';
import JobAnsweredAlert from '@components/layout/Job/JobAnsweredAlert';
import { JobBoostCreditsAlert } from '@components/layout/Job/JobBoostCreditsAlert';
import { JobDeletedAlert } from '@components/layout/Job/JobDeletedAlert';
import { FooterContainer } from '@components/layout/Job/JobFooterContainer';
import { useIsBuySubscription } from '@hooks/useIsBuySubscription';
import { useIsCreditsAvailable } from '@hooks/useIsCreditsAvailable';
import {
  AlertType,
  getJobAnswerAlertType,
} from '@internals/business-shared/src/utils/AnswerAccessUtils';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { JOB_QUERY_jobChatMeta } from '@internals/business-shared/src/utils/generated/generated';
import { Job } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { JobAccess } from '@internals/business-shared/src/utils/query/JobAccess/JobAccessQuery';
import { bugsnagClient } from '@utils/initBugsnag';

import { useAnswerAccessAlertData } from './hooks/useAnswerAccessAlertData';
import { JobCostInfo } from './JobCostInfo';

interface JobAlertProps {
  answerAccess: JobAccess | null;
  answerAccessLoading: boolean;
  chatMeta: Pick<JOB_QUERY_jobChatMeta, 'chatId' | 'firstMessageTs'> | null;
  job:
    | (Pick<Job, 'id' | 'isFree'> & {
        jobBusinessRelationState: {
          isAnswered: boolean;
          isDeleted: boolean;
          clipsSpent: number;
        };
      })
    | null;
  deletedJobAction: () => void;
}

export const JobAlert: FC<JobAlertProps> = ({
  answerAccess,
  answerAccessLoading,
  job,
  chatMeta,
  deletedJobAction,
}) => {
  const isBuySubscription = useIsBuySubscription();
  const isCreditsAvailable = useIsCreditsAvailable();
  const isTestBuyMoreClipsFlagEnabled = useFlagEnabled(
    FeatureFlags.BizTestBuyMoreClips
  );
  const alertType = getJobAnswerAlertType(answerAccess, (code: string) =>
    bugsnagClient.notify(new Error(`Unhandled answer access code: ${code}`))
  );
  const answerAccessAlertData = useAnswerAccessAlertData(
    alertType,
    answerAccess,
    job?.id
  );

  if (!job) {
    return null;
  }

  if (job.jobBusinessRelationState.isAnswered && chatMeta) {
    return (
      <FooterContainer>
        <JobAnsweredAlert
          firstMessageTimestamp={chatMeta.firstMessageTs}
          chatId={chatMeta.chatId}
          clipsSpent={job.jobBusinessRelationState.clipsSpent}
        />
      </FooterContainer>
    );
  }
  if (job.jobBusinessRelationState.isDeleted) {
    return (
      <FooterContainer>
        <JobDeletedAlert onActionClick={deletedJobAction} />
      </FooterContainer>
    );
  }
  if (
    isBuySubscription &&
    [AlertType.LacksSubscription, AlertType.LacksProduct].includes(alertType)
  ) {
    return (
      <>
        {answerAccess?.clip ? (
          <JobCostInfo cost={answerAccess.clip} hasAccess={false} />
        ) : null}
        <JobBuySubscriptionAlert jobId={job?.id} />
      </>
    );
  }

  if (
    isTestBuyMoreClipsFlagEnabled &&
    isCreditsAvailable &&
    alertType === AlertType.InsufficientFunds
  ) {
    return (
      <FooterContainer noBackground>
        <JobBoostCreditsAlert
          clip={answerAccess?.clip ?? 0}
          isLoading={answerAccessLoading}
          jobId={job?.id}
        />
      </FooterContainer>
    );
  }

  return alertType ? (
    <FooterContainer>
      {alertType !== AlertType.InsufficientFunds && answerAccess?.clip > 0 ? (
        <JobCostInfo cost={answerAccess.clip} hasAccess={false} />
      ) : null}
      <JobAlertCard
        {...answerAccessAlertData}
        isLoading={answerAccessLoading}
      />
    </FooterContainer>
  ) : null;
};
