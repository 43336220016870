import styled from 'styled-components';

const HeaderWrapper = styled.div`
  margin: ${(props) => props.theme.space[6]}px
    ${(props) => props.theme.space[7]}px;
  height: 80px;
  display: flex;
  align-items: center;
`;

export default HeaderWrapper;
