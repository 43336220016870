import { useTotalRemainingCredits } from '@contexts/UserContext';
import {
  useCompanyState,
  useIsClipsAlertDisabledByCompanyState,
} from '@internals/business-shared/src/contexts/CompanyStateContext';
import {
  CompanySettingsJobAccess,
  ProductTemplateSlug,
} from '@internals/business-shared/src/utils/generated/generated';

const CREDITS_THRESHOLD = 5;

// TODO: Replace this hook, when API will ready to handle this logic
export const useIsCreditsAvailable = () => {
  const { jobAccess, products } = useCompanyState() || {};
  const isClipsAlertDisabled = useIsClipsAlertDisabledByCompanyState();
  const isSubscription = products?.includes(ProductTemplateSlug.SUBSCRIPTION);
  const isJobAccessAllowed = ![
    CompanySettingsJobAccess.BLOCKED,
    CompanySettingsJobAccess.FREE_ONLY,
  ].includes(jobAccess);
  const totalRemainingCredits = useTotalRemainingCredits();

  return (
    !isClipsAlertDisabled &&
    isJobAccessAllowed &&
    isSubscription &&
    totalRemainingCredits <= CREDITS_THRESHOLD
  );
};
