import { useCallback } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useInitiateBoostCreditsPayment } from '@internals/business-shared/src/hooks/mutation/useInitiateBoostCreditsPayment';
import { useInitiateSelfServiceSubscriptionPayment } from '@internals/business-shared/src/hooks/mutation/useInitiateSelfServiceSubscriptionPayment';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  InitiateSelfServiceSubscriptionPaymentInput,
  SelfServiceOrderInput,
} from '@internals/business-shared/src/utils/generated/generated';

export interface PaymentResult {
  paymentUrl?: string;
  resultCode?: string;
}

export type BoostPaymentInput = SelfServiceOrderInput;

export type SubscriptionPaymentInput = Omit<
  InitiateSelfServiceSubscriptionPaymentInput,
  'consent'
>;

type UseProductPaymentResult = {
  getBoostPaymentResult: (input: BoostPaymentInput) => Promise<PaymentResult>;
  getSubscriptionPaymentResult: (
    input: SubscriptionPaymentInput
  ) => Promise<PaymentResult>;
};

export const useProductPaymentResult = (): UseProductPaymentResult => {
  const [initiateBoostPayment] = useInitiateBoostCreditsPayment();
  const [initiateSubscriptionPayment] =
    useInitiateSelfServiceSubscriptionPayment();
  const isBoostCreditsPair = useFlagEnabled(
    FeatureFlags.BizBoostCreditsChoosePackage
  );

  const getBoostPaymentResult = useCallback(
    async ({
      cardId,
      productName,
      returnUrl,
    }: BoostPaymentInput): Promise<PaymentResult> => {
      const response = await initiateBoostPayment({
        cardId: cardId ?? null,
        returnUrl,
        ...(isBoostCreditsPair ? { productName } : {}),
      });

      const { returnUrl: paymentUrl, resultCode } =
        response?.data?.initiateBoostCreditsPayment || {};

      return { paymentUrl, resultCode };
    },
    [initiateBoostPayment, isBoostCreditsPair]
  );

  const getSubscriptionPaymentResult = useCallback(
    async ({
      productVariationSlug,
      verificationKey,
      returnUrl,
    }: SubscriptionPaymentInput): Promise<PaymentResult> => {
      if (!productVariationSlug || !verificationKey) {
        throw new Error('Missing required payment parameters');
      }

      const response = await initiateSubscriptionPayment({
        productVariationSlug,
        verificationKey,
        returnUrl,
      });

      const paymentUrl = response?.data?.initiateSelfServiceSubscriptionPayment;

      return { paymentUrl };
    },
    [initiateSubscriptionPayment]
  );

  return { getBoostPaymentResult, getSubscriptionPaymentResult };
};
