import { FC, ReactElement, useCallback } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import Rocket from '@internals/business-shared/src/assets/images/rocket.png';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { Image } from '@schibsted-smb/fireball';

import { ModalState } from '../../constants';
import { PackageItem, useBoostCreditsModalContext } from '../../ModalContext';
import { Background } from './Background';
import { BodyContainer } from './BodyContainer';
import { Description } from './Description';
import { Header } from './Header';
import { HeaderContainer } from './HeaderContainer';
import { Price } from './Price';
import { RadioButton } from './RadioButton';
import { Container } from './styled/Container';
import { DescriptionContainer } from './styled/DescriptionContainer';
import { ImageContainer } from './styled/ImageContainer';

interface PackageCardProps
  extends Pick<PackageItem, 'clips' | 'discount' | 'price' | 'slug'> {
  children?: ReactElement;
}

export const PackageCard: FC<PackageCardProps> = ({
  children,
  clips,
  discount,
  price,
  slug,
}) => {
  const { modalState, packageList, selectedPackage, handleSelectPackage } =
    useBoostCreditsModalContext();
  const isBoostCreditsDiscountFlagEnabled = useFlagEnabled(
    FeatureFlags.BizBoostCreditsDiscount
  );
  const isSelected = selectedPackage?.slug === slug;
  const isSinglePackage =
    modalState !== ModalState.SELECT_PACKAGE || packageList?.length === 1;

  const handlePackageClick = useCallback(() => {
    if (isSinglePackage) {
      return;
    }

    handleSelectPackage(slug);
  }, [handleSelectPackage, isSinglePackage, slug]);

  return (
    <Container
      isSelected={isSelected}
      isSinglePackage={isSinglePackage}
      onClick={handlePackageClick}
    >
      <HeaderContainer>
        <Header clips={clips} />
        <ImageContainer isSelected={isSelected}>
          <Image src={Rocket} width="68px" />
        </ImageContainer>
        {!isSinglePackage && <RadioButton isSelected={isSelected} />}
        <Background />
      </HeaderContainer>
      <BodyContainer>
        <DescriptionContainer isSelected={isSelected}>
          {children || <Description clips={clips} />}
        </DescriptionContainer>
        <Price
          price={price}
          {...(isBoostCreditsDiscountFlagEnabled &&
            discount?.priceAfterDiscount && {
              priceAfterDiscount: discount.priceAfterDiscount,
            })}
        />
      </BodyContainer>
    </Container>
  );
};
