import { FC, PropsWithChildren } from 'react';

import { Div } from '@schibsted-smb/fireball';

interface FooterContainerProps extends PropsWithChildren {
  noBackground?: boolean;
}
export const FooterContainer: FC<FooterContainerProps> = ({
  children,
  noBackground,
}) => (
  <Div
    py={6}
    display="grid"
    backgroundColor={noBackground ? 'transparent' : 'black.black0'}
  >
    {children}
  </Div>
);
