import { useCallback, useContext, useEffect } from 'react';

import { NotifiableError } from '@bugsnag/js';
import log from '@internals/business-shared/src/utils/devLog';
import { isProd } from '@utils/envChecks';
import { bugsnagClient } from '@utils/initBugsnag';
import { platform } from 'process';

import { AnalyticsContext } from '../contexts/AnalyticsProvider';

export type AnalyticsProperties = Record<string, unknown>;

interface IntegrationProperties {
  [key: string]: boolean;
}

export type IdentifyProperties = AnalyticsProperties;

interface UseAnalytics {
  track: (
    eventName: string,
    properties?: AnalyticsProperties,
    options?: IntegrationProperties
  ) => void;
  page: (
    name: string,
    properties?: AnalyticsProperties,
    options?: IntegrationProperties
  ) => void;
}

const useAnalytics = (): UseAnalytics => {
  const { analytics, integrations } = useContext(AnalyticsContext);

  if (!analytics) {
    bugsnagClient.notify(new Error('Segment Analytics not loaded'));
    log.analytics('Segment Analytics not loaded');
  }

  const logToConsole = !isProd;

  const track = useCallback(
    (
      eventName: string,
      properties?: AnalyticsProperties,
      options?: IntegrationProperties
    ): void => {
      if (!analytics || !eventName) return;

      const customIntegration = { ...integrations, ...options };

      if (logToConsole) {
        log.analytics(
          'TRACK TRIGGERED',
          eventName,
          { ...properties },
          { integrations: customIntegration }
        );
      }

      analytics
        .track(
          eventName,
          {
            isApp: false,
            os: platform,
            ...properties,
          },
          { integrations: customIntegration }
        )
        .catch((error: NotifiableError) => {
          bugsnagClient.notify(error);
          log.error(error);
        });
    },
    [analytics, integrations, logToConsole]
  );

  const page = useCallback(
    (
      name: string,
      properties?: AnalyticsProperties,
      options?: IntegrationProperties
    ): void => {
      if (!analytics || !name) return;

      const customIntegration = { ...integrations, ...options };

      if (logToConsole) {
        log.analytics(
          'PAGE TRIGGERED',
          name,
          { ...properties },
          { integrations: customIntegration }
        );
      }
      analytics
        .page(name, { ...properties }, { integrations: customIntegration })
        .catch((error: NotifiableError) => {
          bugsnagClient.notify(error);
          log.error(error);
        });
    },
    [analytics, integrations, logToConsole]
  );

  return { track, page };
};

export const usePageAnalytics = (
  name: string,
  properties?: AnalyticsProperties,
  options?: IntegrationProperties
): void => {
  const { page } = useAnalytics();
  useEffect(() => page(name, properties, options), [page]);
};

export default useAnalytics;
