import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY,
  BoligmappaJobAssociationsQueryPayload,
  BoligmappaJobAssociationsType,
} from '../../utils/query/BoligmappaJobAssociations/BoligmappaJobAssociationsQuery';

export interface BoligmappaJobAssociationsQueryResult extends QueryResult {
  data: BoligmappaJobAssociationsType | null;
}

export const hasBoligmappaJobAssociations = (
  data: BoligmappaJobAssociationsQueryPayload
) => !!data.boligmappaJobAssociations;

export const useBoligmappaJobAssociationsQuery = (
  options?: QueryHookOptions<BoligmappaJobAssociationsQueryPayload>
): BoligmappaJobAssociationsQueryResult => {
  const { data, ...rest } = useQuery<BoligmappaJobAssociationsQueryPayload>(
    BOLIGMAPPA_JOB_ASSOCIATIONS_QUERY,
    {
      ...options,
    }
  );

  return useMemo(() => {
    return {
      data: data ? data.boligmappaJobAssociations : null,
      ...rest,
    };
  }, [data, rest]);
};

// TODO - prepare lazy query for fetching data

export const useLazyBoligmappaJobAssociationsQuery = () => {};
