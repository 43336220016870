import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  isJobQuerySuccessResponse,
  Job,
  JOB_QUERY,
  JobQueryPayload,
  JobQueryVariables,
} from '../../utils/query/Job/JobQuery';

interface JobData extends Omit<JobQueryPayload, 'job'> {
  job: Job | null;
}

interface JobQueryResult
  extends QueryResult<JobQueryPayload, JobQueryVariables> {
  data: JobData | null;
}
export const useJobQuery = (
  id: string,
  options?: QueryHookOptions<JobQueryPayload, JobQueryVariables>
): JobQueryResult => {
  const { data, ...rest } = useQuery<JobQueryPayload, JobQueryVariables>(
    JOB_QUERY,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
      context: {
        [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
      },
      ...options,
    }
  );

  const jobData = useMemo(() => {
    const job = isJobQuerySuccessResponse(data?.job) ? data.job : null;
    return job ? { ...data, job } : null;
  }, [data]);

  return useMemo(
    () => ({
      data: jobData,
      ...rest,
    }),
    [jobData, rest]
  );
};
