import styled from 'styled-components';

const MessageForm = styled.form`
  background-color: ${(props): string => props.theme.colors.black.black0};
  max-width: 100%;
  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    padding: ${(props): string =>
      `${props.theme.space[4]}px ${props.theme.space[9]}px`};
  }

  textarea:disabled {
    background-color: transparent;
  }

  button:disabled * {
    pointer-events: none;
  }
`;

export default MessageForm;
