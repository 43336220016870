import * as React from 'react';

import { useQuery } from '@apollo/client';
import PhoneInput from '@components/base/PhoneInput';
import {
  GQLErrorState,
  SetGQLErrorState,
} from '@internals/business-shared/src/utils/errors';
import { PostalAddressResponseError } from '@internals/business-shared/src/utils/errors/PostalAddressResponseError';
import { Omit } from '@internals/business-shared/src/utils/Omit';
import {
  isPostalAddressQuerySuccessResponse,
  POSTAL_ADDRESS_QUERY,
  PostalAddressQueryPayload,
  PostalAddressQueryVariables,
} from '@internals/business-shared/src/utils/query/PostalAddress/PostalAddressQuery';
import {
  Alert,
  Button,
  Div,
  Input,
  Label,
  Spinner,
  Text,
} from '@schibsted-smb/fireball';
import InputType from '@utils/constants/inputTypes';
import { useTranslation } from 'react-i18next';

import INPUT_NAME from './constants/inputNames';
import { CompanyInfo } from './index';

interface ProfileEmployeeEditCompanyFormProps {
  errors: GQLErrorState;
  companyInfo: CompanyInfo;
  saveCompanyData: () => void;
  setCompanyInfo: React.Dispatch<React.SetStateAction<CompanyInfo>>;
  setErrors: SetGQLErrorState;
  inProgress: boolean | undefined;
}

const ProfileEditCompanyForm: React.FC<
  React.PropsWithChildren<ProfileEmployeeEditCompanyFormProps>
> = ({
  companyInfo,
  errors,
  saveCompanyData,
  setCompanyInfo,
  setErrors,
  inProgress,
}: ProfileEmployeeEditCompanyFormProps) => {
  const { t } = useTranslation();

  const [companyPostNumber, setCompanyPostNumber] = React.useState(
    companyInfo.companyPostNumber
  );
  const [companyVisitPostNumber, setCompanyVisitPostNumber] = React.useState(
    companyInfo.companyVisitPostNumber
  );
  const [companyBillingPostNumber, setCompanyBillingPostNumber] =
    React.useState(companyInfo.companyBillingPostNumber);

  const handlePostalAddresResponse = (
    data: PostalAddressQueryPayload,
    companyInfoField: string,
    errorInfoField: string
  ) => {
    if (isPostalAddressQuerySuccessResponse(data?.postalAddress)) {
      const city = data.postalAddress?.city;
      setCompanyInfo((prev) => ({ ...prev, [companyInfoField]: city ?? '' }));
      setErrors((prev: GQLErrorState) => Omit(errorInfoField, prev));
    } else {
      const postalAddressResponseError = new PostalAddressResponseError(
        'PostalAddress'
      );
      if (postalAddressResponseError.hasResponseError(data?.postalAddress)) {
        const valiadtionErrors = postalAddressResponseError.getErrors();
        setCompanyInfo((prev) => ({ ...prev, [companyInfoField]: '' }));
        setErrors((prev: GQLErrorState) => ({
          ...prev,
          [errorInfoField]: { ...valiadtionErrors.general },
        }));
      }
    }
  };

  const { loading: companyCityLoading } = useQuery<
    PostalAddressQueryPayload,
    PostalAddressQueryVariables
  >(POSTAL_ADDRESS_QUERY, {
    variables: {
      postalCode: companyPostNumber,
    },
    skip: companyPostNumber.trim() === '',
    onCompleted: (data: PostalAddressQueryPayload) =>
      handlePostalAddresResponse(data, 'companyCity', 'addressPostalZip'),
  });

  const { loading: companyVisitCityLoading } = useQuery<
    PostalAddressQueryPayload,
    PostalAddressQueryVariables
  >(POSTAL_ADDRESS_QUERY, {
    variables: {
      postalCode: companyVisitPostNumber,
    },
    skip: companyVisitPostNumber.trim() === '',
    onCompleted: (data: PostalAddressQueryPayload) =>
      handlePostalAddresResponse(data, 'companyVisitCity', 'addressVisitZip'),
  });

  const { loading: companyBillingCityLoading } = useQuery<
    PostalAddressQueryPayload,
    PostalAddressQueryVariables
  >(POSTAL_ADDRESS_QUERY, {
    variables: {
      postalCode: companyBillingPostNumber,
    },
    skip: companyBillingPostNumber.trim() === '',
    onCompleted: (data: PostalAddressQueryPayload) =>
      handlePostalAddresResponse(
        data,
        'companyBillingCity',
        'addressBillingZip'
      ),
  });

  const handleChangeCompanyInfo = (event: React.BaseSyntheticEvent) => {
    setCompanyInfo((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const validateEmptyField = (value: string, errorField: string) => {
    if (value.trim().length === 0) {
      setErrors((prev: GQLErrorState) => ({
        ...prev,
        [errorField]: {
          msg: t('general.form.validation.required'),
          variant: 'danger',
        },
      }));
    } else {
      setErrors((prev: GQLErrorState) => Omit(errorField, prev));
    }
  };

  /*
  In case of introducing Formik in WEB project, feel free to use https://github.com/schibsted-smb/mittanbud-business/blob/dev/native/src/components/Settings/SettingsProfileSchema.ts
  As long as we don't have Formik, we need to use this approach
 */

  const validateAddress =
    ({
      streetErrorField,
      zipErrorField,
      zipValueField,
    }: {
      streetErrorField: string;
      zipErrorField: string;
      zipValueField: keyof CompanyInfo;
    }) =>
    (e: React.BaseSyntheticEvent) => {
      const address = e.target.value.trim();
      const zip = companyInfo[zipValueField].trim();
      const addressIsEmpty = address.length === 0;
      const zipIsEmpty = zip.length === 0;

      if (addressIsEmpty) {
        if (zipIsEmpty) {
          // clear errors
          setErrors((prev: GQLErrorState) => Omit(streetErrorField, prev));
          setErrors((prev: GQLErrorState) => Omit(zipErrorField, prev));
        } else {
          // street is required
          setErrors((prev: GQLErrorState) => ({
            ...prev,
            [streetErrorField]: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          }));
        }
      } else {
        // address is not empty
        // eslint-disable-next-line no-lonely-if
        if (zipIsEmpty) {
          // zip is required
          setErrors((prev: GQLErrorState) => ({
            ...prev,
            [zipErrorField]: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          }));
        } else {
          // clear errors
          setErrors((prev: GQLErrorState) => Omit(streetErrorField, prev));
          setErrors((prev: GQLErrorState) => Omit(zipErrorField, prev));
        }
      }
    };

  const validatePostNumber =
    ({
      streetErrorField,
      zipErrorField,
      streetValueField,
      cityValueField,
    }: {
      streetErrorField: string;
      zipErrorField: string;
      streetValueField: keyof CompanyInfo;
      cityValueField: keyof CompanyInfo;
    }) =>
    (e: React.BaseSyntheticEvent) => {
      const zip = e.currentTarget.value.trim();
      const street = companyInfo[streetValueField].trim();
      const zipIsEmpty = zip.length === 0;
      const streetIsEmpty = street.length === 0;

      if (zipIsEmpty) {
        // ZIP is empty
        setCompanyInfo((prev) => ({ ...prev, [cityValueField]: '' }));

        if (streetIsEmpty) {
          // street is empty - remove zip and street error
          setErrors((prev: GQLErrorState) => Omit(streetErrorField, prev));
          setErrors((prev: GQLErrorState) => Omit(zipErrorField, prev));
        } else {
          // street is not empty so ZIP is required
          setErrors((prev: GQLErrorState) => ({
            ...prev,
            [zipErrorField]: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          }));
        }
      } else {
        // ZIP is not empty
        // eslint-disable-next-line no-lonely-if
        if (streetIsEmpty) {
          //  street is empty, so mark is as required
          setErrors((prev: GQLErrorState) => ({
            ...prev,
            [streetErrorField]: {
              msg: t('general.form.validation.required'),
              variant: 'danger',
            },
          }));
        } else {
          // neither ZIP nor street is empty, so clear error
          setErrors((prev: GQLErrorState) => Omit(streetErrorField, prev));
          setErrors((prev: GQLErrorState) => Omit(zipErrorField, prev));
        }
      }
    };

  const handleOnSubmit = (event: React.BaseSyntheticEvent) => {
    event.preventDefault();
    saveCompanyData();
  };

  return (
    <form onSubmit={handleOnSubmit} noValidate>
      <Input
        label={t('settings.profile.companyForm.companyEmail')}
        name={INPUT_NAME.COMPANY_EMAIL}
        type={InputType.Email}
        value={companyInfo.companyEmail}
        error={errors?.emailError}
        onChange={handleChangeCompanyInfo}
        onBlur={(e) => validateEmptyField(e.target.value, 'emailError')}
        inputWrapperMarginBottom={5}
        required
        testId="company-email"
      />
      <PhoneInput
        error={errors?.phoneError}
        inputWrapperMarginBottom={5}
        label={t('settings.profile.companyForm.companyPhone')}
        name={INPUT_NAME.COMPANY_PHONE_NUMBER}
        onChange={handleChangeCompanyInfo}
        onError={(phoneError) =>
          phoneError
            ? setErrors(
                (prev: GQLErrorState) =>
                  ({ ...prev, phoneError } as GQLErrorState)
              )
            : setErrors((prev: GQLErrorState) => Omit('phoneError', prev))
        }
        onFocusOut={({ value }) => {
          setCompanyInfo((prev) => ({
            ...prev,
            [INPUT_NAME.COMPANY_PHONE_NUMBER]: value,
          }));
        }}
        testId="company-phone"
        value={companyInfo.companyPhoneNumber}
      />
      <Div mb={5} px={4}>
        <Label pb={2}>
          {t('settings.profile.companyForm.organizationNumber')}
        </Label>
        <Text.p>{companyInfo.companyIdNumber}</Text.p>
      </Div>
      {errors?.addressPostal?.msg && (
        <Alert variant="danger" isCentered isFullWidth>
          {errors.addressPostal.msg}
        </Alert>
      )}
      <Input
        label={t('settings.profile.companyForm.mailingAddress')}
        name={INPUT_NAME.COMPANY_POST_ADDRESS}
        type={InputType.Text}
        value={companyInfo.companyPostAddress}
        error={errors?.addressPostalStreet}
        onChange={handleChangeCompanyInfo}
        onBlur={validateAddress({
          streetErrorField: 'addressPostalStreet',
          zipErrorField: 'addressPostalZip',
          zipValueField: 'companyPostNumber',
        })}
        inputWrapperMarginBottom={5}
        testId="company-post-address"
      />
      <Div mb={5} width="140px" position="relative">
        <Input
          label={t('settings.profile.companyForm.zipCode')}
          name={INPUT_NAME.COMPANY_POST_NUMBER}
          type={InputType.Number}
          value={companyInfo.companyPostNumber}
          error={errors?.addressPostalZip}
          onChange={handleChangeCompanyInfo}
          onBlur={(e) => {
            validatePostNumber({
              streetErrorField: 'addressPostalStreet',
              zipErrorField: 'addressPostalZip',
              streetValueField: 'companyPostAddress',
              cityValueField: 'companyCity',
            })(e);
            setCompanyPostNumber(e.target.value);
          }}
          testId="company-postal"
          preventNumberDefault
        />
        <Div ml="150px" position="absolute" top={15}>
          {companyCityLoading ? (
            <Div width="100px">
              <Spinner horizontal size={8} />
            </Div>
          ) : (
            <>{companyInfo.companyCity}</>
          )}
        </Div>
      </Div>
      {errors?.addressVisit?.msg && (
        <Alert variant="danger" isCentered isFullWidth>
          {errors.addressVisit.msg}
        </Alert>
      )}
      <Input
        label={t('settings.profile.companyForm.visitingAddress')}
        name={INPUT_NAME.COMPANY_VISIT_POST_ADDRESS}
        type={InputType.Text}
        value={companyInfo.companyVisitPostAddress}
        error={errors?.addressVisitStreet}
        onChange={handleChangeCompanyInfo}
        onBlur={(e) => validateEmptyField(e.target.value, 'addressVisitStreet')}
        inputWrapperMarginBottom={5}
        required
        testId="company-visit-address"
      />
      <Div mb={5} width="140px" position="relative">
        <Input
          label={t('settings.profile.companyForm.zipCode')}
          name={INPUT_NAME.COMPANY_VISIT_POST_NUMBER}
          type={InputType.Number}
          value={companyInfo.companyVisitPostNumber}
          error={errors?.addressVisitZip}
          onChange={handleChangeCompanyInfo}
          onBlur={(e) => setCompanyVisitPostNumber(e.currentTarget.value)}
          required
          testId="company-visit-postal"
          preventNumberDefault
        />
        <Div ml="150px" position="absolute" top={15}>
          {companyVisitCityLoading ? (
            <Div width="100px">
              <Spinner horizontal size={8} />
            </Div>
          ) : (
            <>{companyInfo.companyVisitCity}</>
          )}
        </Div>
      </Div>
      {errors?.addressBilling?.msg && (
        <Alert variant="danger" isCentered isFullWidth>
          {errors.addressBilling.msg}
        </Alert>
      )}
      <Input
        label={t('settings.profile.companyForm.billingAddress')}
        name={INPUT_NAME.COMPANY_BILLING_POST_ADDRESS}
        type={InputType.Text}
        value={companyInfo.companyBillingPostAddress}
        error={errors?.addressBillingStreet}
        onChange={handleChangeCompanyInfo}
        onBlur={validateAddress({
          streetErrorField: 'addressBillingStreet',
          zipErrorField: 'addressBillingZip',
          zipValueField: 'companyBillingPostNumber',
        })}
        inputWrapperMarginBottom={5}
        testId="company-billing-address"
      />
      <Div mb={5} width="140px" position="relative">
        <Input
          label={t('settings.profile.companyForm.zipCode')}
          name={INPUT_NAME.COMPANY_BILLING_POST_NUMBER}
          type={InputType.Number}
          value={companyInfo.companyBillingPostNumber}
          error={errors?.addressBillingZip}
          onChange={handleChangeCompanyInfo}
          onBlur={(e) => {
            validatePostNumber({
              streetErrorField: 'addressBillingStreet',
              zipErrorField: 'addressBillingZip',
              streetValueField: 'companyBillingPostAddress',
              cityValueField: 'companyBillingCity',
            })(e);
            setCompanyBillingPostNumber(e.target.value);
          }}
          testId="company-billing-postal"
          preventNumberDefault
        />
        <Div ml="150px" position="absolute" top={15}>
          {companyBillingCityLoading ? (
            <Div width="100px">
              <Spinner horizontal size={8} />
            </Div>
          ) : (
            <>{companyInfo.companyBillingCity}</>
          )}
        </Div>
      </Div>
      <Button
        variant="primary"
        type="submit"
        isLoading={inProgress}
        data-testid="company-info-save-btn"
      >
        {t('general.label.save')}
      </Button>
    </form>
  );
};

export default ProfileEditCompanyForm;
