import { useCallback } from 'react';

import { useCompanyWantsContactAboutProduct } from '@internals/business-shared/src/hooks/mutation/useCompanyWantsContactAboutProduct';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';
import {
  useContactRequestedCookie,
  ContactRequestSentCookiePrefix,
} from '@utils/companyState/useCompanyStateAction';

interface UseRequestContactProps {
  requestContact: () => Promise<void>;
  requestContactLoading: boolean;
}

export const useRequestContact = (
  cookiePrefix: ContactRequestSentCookiePrefix,
  productType: ProductType
): UseRequestContactProps => {
  const [companyWantsContactAboutProduct, { loading }] =
    useCompanyWantsContactAboutProduct();
  const [isContactRequested, saveIsContactRequested] =
    useContactRequestedCookie(cookiePrefix);

  const requestContact = useCallback(async () => {
    if (!isContactRequested) {
      await companyWantsContactAboutProduct(productType);
      saveIsContactRequested();
    }
  }, [
    companyWantsContactAboutProduct,
    isContactRequested,
    productType,
    saveIsContactRequested,
  ]);

  return { requestContact, requestContactLoading: loading };
};
