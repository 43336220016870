import { FC } from 'react';

import {
  StyledProductVerificationContainer,
  StyledProductVerificationText,
  StyledProductVerificationTitle,
} from '@components/layout/Products/styled';
import { Div, Spinner } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const ProductVerification: FC = () => {
  const { t } = useTranslation();

  return (
    <StyledProductVerificationContainer>
      <Div mb={6}>
        <Spinner size={6} />
      </Div>
      <StyledProductVerificationTitle>
        {t('product.verification.inProgress.title')}
      </StyledProductVerificationTitle>
      <StyledProductVerificationText>
        {t('product.verification.inProgress.subtitle')}
      </StyledProductVerificationText>
    </StyledProductVerificationContainer>
  );
};
