import * as React from 'react';

import {
  IframeMessage,
  IframeMessageInterface,
} from '@components/base/IframeTalk/IframeMessage';
import IframeContainer from '@components/base/IframeTalk/styled/IframeContainer';
import usePrev from '@hooks/usePrev';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import log from '@internals/business-shared/src/utils/devLog';
import { bugsnagClient } from '@utils/initBugsnag';

interface IframeTalkProps {
  src: string;
  title: string;
  message: IframeMessageInterface;
  origin: string;
  onFrameLoaded?: () => void;
}

const IframeTalk: React.FC<React.PropsWithChildren<IframeTalkProps>> = ({
  src,
  title,
  origin,
  message = IframeMessage('hello', 'world'),
  onFrameLoaded,
}) => {
  const frameRef = React.useRef<HTMLIFrameElement>(null);
  const prevMessage = usePrev(message);
  const [frameLoaded, markFrameLoaded] = useBoolean(false);

  const sendMessage = (m: IframeMessageInterface, force = false) => {
    if (!frameLoaded && !force) {
      return;
    }

    try {
      frameRef?.current?.contentWindow?.postMessage(m, origin);
      log.component('IframeTalk', m);
    } catch {
      log.error(
        `failed to send message: ${JSON.stringify(m)} with origin: ${origin}`
      );
      bugsnagClient.notify(
        `failed to send Iframe message: ${JSON.stringify(
          m
        )} with origin: ${origin}`
      );
    }
  };
  const forceSendMessage = (m: IframeMessageInterface) => sendMessage(m, true);

  const onFrameLoad = () => {
    markFrameLoaded();
    forceSendMessage(message);

    if (onFrameLoaded) {
      onFrameLoaded();
    }
  };

  React.useEffect(() => {
    frameRef.current?.addEventListener('load', onFrameLoad);
  }, []);

  React.useEffect(() => {
    if (prevMessage !== message) {
      sendMessage(message);
    }
  }, [message]);

  return (
    <IframeContainer
      src={src}
      title={title}
      ref={frameRef}
      frameLoaded={frameLoaded}
    />
  );
};

export default IframeTalk;
