import { ChangeEvent, FC, useCallback, useState } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { useUserContext } from '@contexts/UserContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  Button,
  Checkbox,
  Column,
  Container,
  Div,
  Heading,
  Link,
  Row,
  Text,
} from '@schibsted-smb/fireball';
import { getProduct, getProductTermsOfServiceUrl } from '@utils/product';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBuySubscriptionModalContext } from '../ModalContext';
import { PackageCard } from './PackageCard';

const StyledLink = styled(Link)`
  display: inline;
  color: ${({ theme }) => theme.colors.black.black0};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: ${({ theme }) => theme.colors.black.black0};
  }
`;

export const ConfirmPurchaseState: FC = () => {
  const [checkboxes, setCheckboxes] = useState({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });
  const [isAlert, showAlert, hideAlert] = useBoolean();
  const { firstName, lastName, operatingAs: company } = useUserContext();
  const { name: productName } = getProduct();
  const { loading, selectedPackage, handleSecureIdAuth, handleRetryAttempt } =
    useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();
  const termsURL = getProductTermsOfServiceUrl();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckboxes((prevState) => ({ ...prevState, [name]: checked }));
    hideAlert();
  };

  const handleButtonClick = useCallback(() => {
    const allChecked = Object.values(checkboxes).every(Boolean);

    if (!allChecked) {
      showAlert();
      return;
    }

    handleSecureIdAuth();
  }, [checkboxes, handleSecureIdAuth, showAlert]);

  return (
    <Container minWidth={!isTabletOrMobile && 740}>
      <Row justifyContent="center">
        <Column px={0}>
          <Heading.h1 color="black.black0" mb={3}>
            {t('job.modal.subscription.confirmPurchase.title')}
          </Heading.h1>
          <Text.p color="black.black0" textAlign="center">
            {t('job.modal.subscription.confirmPurchase.description')}
          </Text.p>
        </Column>
      </Row>
      <Row justifyContent="center">
        <Column
          display="flex"
          justifyContent="center"
          maxWidth={330}
          flex={1}
          py={5}
          px={0}
        >
          <PackageCard {...selectedPackage} />
        </Column>
      </Row>
      <Row justifyContent="center" maxWidth={330} margin="auto">
        <Column flex={1} px={0}>
          <Checkbox
            id="checkbox1"
            name="checkbox1"
            label={t('job.modal.subscription.confirmPurchase.authority', {
              firstName,
              lastName,
              companyName: company.name,
            })}
            alignItems="baseline"
            color="black.black0"
            checked={checkboxes.checkbox1}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            id="checkbox2"
            name="checkbox2"
            label={t('job.modal.subscription.confirmPurchase.contract')}
            alignItems="baseline"
            color="black.black0"
            checked={checkboxes.checkbox2}
            onChange={handleCheckboxChange}
          />
          <Checkbox
            id="checkbox3"
            name="checkbox3"
            label=""
            alignItems="flex-start"
            color="black.black0"
            checked={checkboxes.checkbox3}
            onChange={handleCheckboxChange}
            pb={5}
          >
            <Text.span display="inline" color="black.black0" mb={0}>
              {t('job.modal.subscription.confirmPurchase.terms')}
            </Text.span>
            <StyledLink href={termsURL}>
              {t('job.modal.subscription.confirmPurchase.termsLink', {
                productName,
              })}
            </StyledLink>
          </Checkbox>
          {isAlert && (
            <Div bg="gray.lightest" p={1} mb={5}>
              <Text.p fontSize={1} textAlign="center" mb={0} py={3}>
                {t('job.modal.subscription.confirmPurchase.alert')}
              </Text.p>
            </Div>
          )}
          <Button onClick={handleButtonClick} isLoading={loading} fullWidth>
            {t('general.label.buy')}
          </Button>
          <Button onClick={handleRetryAttempt} variant="linkPrimary" fullWidth>
            <Text.span color="black.black0">
              {t('general.label.back')}
            </Text.span>
          </Button>
        </Column>
      </Row>
    </Container>
  );
};
