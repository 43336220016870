import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  getChatAsUnreadOptimisticResponse,
  setChatAsUnreadUpdate,
} from '../../cache/updates/setChatAsUnread';
import {
  SET_CHAT_AS_UNREAD_MUTATION,
  SetChatAsUnreadMutationPayload,
  SetChatAsUnreadMutationVariables,
} from '../../utils/mutation/SetChatAsUnreadMutation/SetChatAsUnreadMutation';

type useSetChatAsUnreadAction = (
  chatId: SetChatAsUnreadMutationVariables['chatId'],
  options?: MutationFunctionOptions<
    SetChatAsUnreadMutationPayload,
    SetChatAsUnreadMutationVariables
  >
) => Promise<FetchResult<SetChatAsUnreadMutationPayload>>;

export const useSetChatAsUnreadMutation = (
  mutationHookOptions?: MutationFunctionOptions<
    SetChatAsUnreadMutationPayload,
    SetChatAsUnreadMutationVariables
  >
): [
  useSetChatAsUnreadAction,
  MutationResult<SetChatAsUnreadMutationPayload>
] => {
  const [setChatAsUnread, ...rest] = useMutation<
    SetChatAsUnreadMutationPayload,
    SetChatAsUnreadMutationVariables
  >(SET_CHAT_AS_UNREAD_MUTATION, {
    ...mutationHookOptions,
  });

  const mutationFn: useSetChatAsUnreadAction = useCallback(
    (chatId, options?) => {
      return setChatAsUnread({
        variables: {
          chatId,
        },
        optimisticResponse: getChatAsUnreadOptimisticResponse(chatId),
        update: setChatAsUnreadUpdate(chatId),
        ...options,
      });
    },
    []
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
