import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_FRAGMENT from '../../fragments/SimpleAgreementContractFragment';
import {
  SIMPLE_CONTRACT_LIST_QUERYVariables,
  SIMPLE_CONTRACT_LIST_QUERY as SIMPLE_CONTRACT_LIST_QUERY_Result,
  SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList,
} from '../../generated/generated';

export type SimpleContractListQueryPayload = SIMPLE_CONTRACT_LIST_QUERY_Result;
export type SimpleContractListQueryVariables =
  SIMPLE_CONTRACT_LIST_QUERYVariables;
export type SimpleContractListData =
  SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList;

export const isSimpleContractListQuerySuccessResponse = (
  simpleContractList?: SIMPLE_CONTRACT_LIST_QUERY_Result['simpleAgreementContractList']
): simpleContractList is SIMPLE_CONTRACT_LIST_QUERY_simpleAgreementContractList =>
  !!simpleContractList &&
  simpleContractList.__typename === 'SimpleAgreementContractListPayload';

export const SIMPLE_CONTRACT_LIST_QUERY = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_FRAGMENT}

  query SIMPLE_CONTRACT_LIST_QUERY($input: SimpleAgreementContractListInput!) {
    simpleAgreementContractList(input: $input) {
      list {
        ...SimpleAgreementContractFragment
      }
      pageInfo {
        totalCount
      }
    }
  }
`;
