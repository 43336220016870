import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { convertToThousandSpaces } from '@internals/business-shared/src/utils/convertToThousandSpaces';
import { Column, Div, Row, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import {
  CUSTOM_GREEN_50,
  CUSTOM_GREEN_700,
  CUSTOM_GREEN_800,
  CUSTOM_GREY_600,
} from '../../constants';

interface PriceProps {
  price: number;
  priceAfterDiscount?: number;
}

export const Price: FC<PriceProps> = ({ price, priceAfterDiscount }) => {
  const { t } = useTranslation();

  return (
    <Row alignItems="center" justifyContent="space-between">
      <Column px={0}>
        {priceAfterDiscount && (
          <Div
            alignItems="center"
            bg={CUSTOM_GREEN_50}
            borderRadius="4px"
            display="flex"
            flexDirection="row"
            position="relative"
            px={2}
            py={1}
            top="3px"
          >
            <Div display="flex" mr={2}>
              <FireIcon
                fill={CUSTOM_GREEN_700}
                height={12}
                iconName={IconPickerName.Free}
                width={12}
              />
            </Div>

            <Text.p
              color={CUSTOM_GREEN_800}
              fontSize={2}
              fontWeight="medium"
              mb={0}
            >
              {t('job.modal.boost.packageCard.discount', {
                discount: convertToThousandSpaces(price - priceAfterDiscount),
              })}
            </Text.p>
          </Div>
        )}
      </Column>
      <Column px={0}>
        {priceAfterDiscount && (
          <Text.p
            color={CUSTOM_GREY_600}
            fontSize={3}
            fontWeight="medium"
            mb={1}
            textAlign="right"
          >
            <s>
              {t('job.modal.boost.packageCard.price', {
                price: convertToThousandSpaces(price),
              })}
            </s>
          </Text.p>
        )}
        <Text.p fontSize={4} fontWeight="bold" mb={1} textAlign="right">
          {t('job.modal.boost.packageCard.price', {
            price: convertToThousandSpaces(priceAfterDiscount || price),
          })}
        </Text.p>
        <Text.p fontSize={1} fontWeight="medium" mb={0} textAlign="right">
          {t('job.modal.boost.packageCard.priceVat')}
        </Text.p>
      </Column>
    </Row>
  );
};
