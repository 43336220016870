import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { placeholderJobAreasPayload } from '../../utils/areasUtils';
import {
  JOBS_AREAS_QUERY,
  JobsAreas,
  JobsAreasQueryPayload,
  JobsAreasQueryVariables,
} from '../../utils/query/JobsAreas/JobsAreasQuery';

interface JobAreasQueryResult extends QueryResult {
  data: JobsAreas;
}

export const useJobAreasQuery = (
  businessId: string,
  options?: QueryHookOptions<JobsAreasQueryPayload, JobsAreasQueryVariables>
): JobAreasQueryResult => {
  const { data, ...rest } = useQuery<JobsAreasQueryPayload>(JOBS_AREAS_QUERY, {
    variables: { id: businessId },
    fetchPolicy: 'cache-first',
    ...options,
  });

  return useMemo(
    () => ({
      data: data ? data.businessJobAreas : placeholderJobAreasPayload,
      ...rest,
    }),
    [data, rest]
  );
};
