import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  SEND_MESSAGEVariables,
  SEND_MESSAGE as SEND_MESSAGE_Result,
  SEND_MESSAGE_sendMessage_SendMessagePayload,
} from '../../generated/generated';

export type SendMessageMutationPayload = SEND_MESSAGE_Result;
export type SendMessageMutationVariables = SEND_MESSAGEVariables;

export const isSendMessageMutationSuccessResponse = (
  sendMessage?: SEND_MESSAGE_Result['sendMessage']
): sendMessage is SEND_MESSAGE_sendMessage_SendMessagePayload =>
  !!sendMessage && sendMessage.__typename === 'SendMessagePayload';

export const SEND_MESSAGE = gql`
  ${IMAGE_FRAGMENT}

  mutation SEND_MESSAGE($chatId: ID!, $message: String!, $files: [Upload!]) {
    sendMessage(input: { chatId: $chatId, text: $message, files: $files }) {
      ... on SendMessagePayload {
        messages {
          id
          text
          regTs
          images {
            ...ImageFragment
          }
          files {
            id
            name
            url
            fileType
          }
        }
      }
      ... on SendMessageInputError {
        message
      }
      ... on AccessDeniedError {
        message
      }
    }
  }
`;
