import * as React from 'react';

import PageContainer from '@components/base/PageContainer';
import WithNavigationPageHeading from '@components/elements/WithNavigationPageHeading/SettingsPageHeading';
import { Heading, Card, Row, Column } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface WithNavigationLayoutProps {
  Navigation: React.FC<React.PropsWithChildren<any>>;
  aboveNavigation?: React.ReactElement;
  belowNavigation?: React.ReactElement;
  pageName?: string;
  isMainPage?: boolean;
  pageDetails: {
    subpageName?: string;
    PageContent: React.FC<React.PropsWithChildren<any>> | null;
    disableNavigationColumn?: boolean;
    disableMobileHeader?: boolean;
  };
}

const withNavigationLayout = ({
  Navigation,
  pageName,
  isMainPage,
  pageDetails: {
    disableNavigationColumn = false,
    subpageName,
    PageContent,
    disableMobileHeader = false,
  },
  aboveNavigation,
  belowNavigation,
}: WithNavigationLayoutProps) => {
  return function navigationLayout(): React.ReactElement {
    const themeContext = useTheme();
    return (
      <PageContainer width={1} maxWidth={1300}>
        <Row height="100%" width="100%" display="flex" flexDirection="column">
          {pageName && (
            <Heading.h3
              fontSize={[7, null, null, null, null, 4]}
              mt={[8, null, null, null, null, 0]}
              mb={[7, null, null, null, null, 0]}
              ml={[5, null, null, null, null, 0]}
              py={[0, null, null, null, null, 5]}
              width={['auto', null, null, null, null, '100%']}
              backgroundColor={['none', null, null, null, null, 'black.black0']}
              borderBottom={[
                'none',
                null,
                null,
                null,
                null,
                `1px solid ${themeContext.colors.black.black3}`,
              ]}
              textAlign={['left', null, null, null, null, 'center']}
              display={
                isMainPage
                  ? ['block', null, null, null, null, null, 'none']
                  : ['none', null, null, null, null, 'block', 'none']
              }
            >
              {pageName}
            </Heading.h3>
          )}
          <Row mt={[0, null, null, null, null, 4, 6]} height="100%">
            <Column
              width={[1, null, null, null, null, 3 / 12]}
              height="100%"
              pl={[4, null, null, null, null, null, null, 0]}
              position="sticky"
              top="90px"
              display={
                isMainPage
                  ? [
                      'block',
                      null,
                      null,
                      null,
                      null,
                      `${disableNavigationColumn ? 'none' : 'block'}`,
                    ]
                  : [
                      'none',
                      null,
                      null,
                      null,
                      null,
                      `${disableNavigationColumn ? 'none' : 'block'}`,
                    ]
              }
            >
              {aboveNavigation}
              <Navigation />
              {belowNavigation}
            </Column>
            {PageContent && !disableNavigationColumn && (
              <Column
                width={
                  disableNavigationColumn
                    ? 1
                    : [1, null, null, null, null, 9 / 12]
                }
                display={
                  isMainPage
                    ? ['none', null, null, null, null, 'block']
                    : ['block']
                }
                pl={[0, null, null, null, null, 4]}
                pr={[0, null, null, null, null, 4, null, 0]}
              >
                {subpageName && !disableMobileHeader && (
                  <WithNavigationPageHeading title={subpageName} />
                )}
                <Card
                  boxShadow={['none', null, null, null, null, 'l']}
                  p={0}
                  mb={0}
                >
                  <PageContent />
                </Card>
              </Column>
            )}
            {disableNavigationColumn && isMainPage && PageContent && (
              <Column
                width={1}
                display={['none', null, null, null, null, 'block']}
                px={[0, null, null, null, null, 4, null, 0]}
                mb={[8, null, null, null, null, '50px', 0]}
              >
                <PageContent />
              </Column>
            )}
          </Row>
        </Row>
      </PageContainer>
    );
  };
};
export default withNavigationLayout;
