import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BoligmappaIntegrationStateQueryPayload,
  BoligmappaIntegrationStateType,
  QUERY_BOLIGMAPPA_INTEGRATION_STATE,
} from '../../utils/query/BoligmappaIntegrationState/BoligmappaIntegrationStateQuery';

export interface BoligmappaIntegrationStateQueryResult extends QueryResult {
  data: BoligmappaIntegrationStateType | null;
}
export const useBoligmappaIntegrationStateQuery = (
  options?: QueryHookOptions<BoligmappaIntegrationStateQueryPayload>
): BoligmappaIntegrationStateQueryResult => {
  const { data, ...rest } = useQuery<BoligmappaIntegrationStateQueryPayload>(
    QUERY_BOLIGMAPPA_INTEGRATION_STATE,
    {
      fetchPolicy: 'network-only',
      ...options,
    }
  );

  return useMemo(() => {
    return {
      data: data ? data.boligmappaIntegrationState : null,
      ...rest,
    };
  }, [data, rest]);
};
