import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const CUSTOM_BLUE_600 = '#365FC0';

export const PaginationContainer = styled(Div)`
  &.flicking-pagination {
    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;

    .flicking-pagination-bullet {
      background-color: ${({ theme }) => theme.colors.gray.light};
    }

    .flicking-pagination-bullet-active {
      background-color: ${CUSTOM_BLUE_600};
      border: 1px solid ${({ theme }) => theme.colors.gray.light};
      height: 10px;
      width: 10px;
    }
  }
`;
