import { t } from '@internals/business-translations/src/i18n';

import { JobSize } from './generated/generated';
import { JobsListQueryData } from './query/JobsList/JobsListQuery';

export type JobsListAggregations =
  JobsListQueryData['jobConnection']['pageInfo']['aggregations'][0];

export const getSizeLabel = (
  jobSize: JobSize | '',
  defaultText = ''
): string => {
  switch (jobSize) {
    case JobSize.BIG:
      return t('job.list.filters.size.value.big');
    case JobSize.NORMAL:
      return t('job.list.filters.size.value.normal');
    case JobSize.SMALL:
      return t('job.list.filters.size.value.small');
    default:
      return defaultText;
  }
};

export type FilteredJobCount = Record<string, Record<string, number>>;
/*
  map filter aggregations array returned from gql to structure:
  {
    filterName: {
      filterId: count
    }
  }
  where filterName should be one of the filters used in JobListInput
*/
export const mapFilteredJobAggregations = (
  aggregations?: JobsListAggregations[]
): FilteredJobCount => {
  if (!aggregations?.length) {
    return {};
  }
  return aggregations.reduce((allFilters, filter) => {
    return {
      ...allFilters,
      [filter.type]: filter.values.reduce((filterGroup, filterValue) => {
        return { ...filterGroup, [filterValue.id]: filterValue.count };
      }, {}),
    };
  }, {});
};

export const getJobsCountForFilter = (
  filteredJobCount: FilteredJobCount,
  filterName: string,
  filterId: string
): number | null => {
  const filterGroup = filteredJobCount?.[filterName];
  if (!filterGroup) {
    return null;
  }
  return filterGroup[filterId] ?? 0;
};

export const joinFilterWithCount = (name: string, count?: number): string => {
  if (typeof count !== 'number') {
    return name;
  }
  return `${name} (${count})`;
};
