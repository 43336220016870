import { FC } from 'react';

import {
  StyledProductInfoRow,
  StyledProductInfoText,
} from '@components/layout/Products/styled';

interface ProductInfoRowProps {
  name: string;
  value: string;
  isWarning?: boolean;
}

export const ProductInfoRow: FC<ProductInfoRowProps> = ({
  name,
  value,
  isWarning,
}) => (
  <StyledProductInfoRow>
    <StyledProductInfoText isWarning={isWarning}>{name}</StyledProductInfoText>
    <StyledProductInfoText>{value}</StyledProductInfoText>
  </StyledProductInfoRow>
);
