import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  StyledProductCard,
  StyledProductContent,
  StyledProductRewardIconContainer,
} from '@components/layout/Products/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Heading, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface ProductRewardCardProps {
  title: string;
  subtitle?: string;
  action: {
    text: string;
    handler: () => void;
  };
}

const ICON_SIZE = 98;
const ICON_MOVE_RIGHT = 10;
const ICON_MOVE_TOP = 5;

export const ProductRewardCard: FC<ProductRewardCardProps> = ({
  title,
  action,
  subtitle,
}) => {
  const themeContext = useTheme();

  return (
    <StyledProductCard position="relative">
      <StyledProductContent
        withIcon
        width={`calc(100% - ${ICON_SIZE - ICON_MOVE_RIGHT}px)`}
      >
        <Heading.h3 mb={2}>{title}</Heading.h3>
        <Text.p mb={4}>{subtitle}</Text.p>
        <Button px={6} py={5} onClick={action.handler}>
          {action.text}
        </Button>
      </StyledProductContent>
      <StyledProductRewardIconContainer
        top={-ICON_MOVE_TOP}
        right={-ICON_MOVE_RIGHT}
        height={ICON_SIZE}
        width={ICON_SIZE}
      >
        <FireIcon
          fill={themeContext.colors.primary.darkest}
          iconName={IconPickerName.Medal}
        />
      </StyledProductRewardIconContainer>
    </StyledProductCard>
  );
};
