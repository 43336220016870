import { useEffect } from 'react';

import { useUserContext } from '@contexts/UserContext';

import { isProd } from './envChecks';
import { bugsnagClient } from './initBugsnag';

const BugsnagMetadataProbe = (): null => {
  const userContext = useUserContext();

  useEffect(() => {
    if (!userContext) {
      return;
    }

    const { id, email, operatingAs } = userContext;

    if (id) {
      if (isProd) {
        bugsnagClient.setUser(id);
        bugsnagClient.addMetadata('company', { companyId: operatingAs?.id });
      } else {
        bugsnagClient.setUser(id, email || '');
        bugsnagClient.addMetadata('company', {
          companyId: operatingAs?.id,
          email: operatingAs?.email,
        });
      }
    } else {
      bugsnagClient.setUser(undefined);
      bugsnagClient.clearMetadata('company');
    }
  }, [userContext]);

  return null;
};

export default BugsnagMetadataProbe;
