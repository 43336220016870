import styled from 'styled-components';

export const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.space[5]}px ${theme.space[6]}px`};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black.black3};
`;
