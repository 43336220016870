import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import ErrorAlert from '@components/base/ErrorAlert';
import { IframeMessage } from '@components/base/IframeTalk/IframeMessage';
import TextInfoBox from '@components/base/TextInfoBox';
import ProfileEditListItem from '@components/elements/ProfileEditListItem';
import SectionHeader from '@components/layout/Profile/SectionHeader';
import { useUserBusinessId } from '@contexts/UserContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import { useAddBusinessEmployeeMutation } from '@internals/business-shared/src/hooks/mutation/useAddBusinessEmployee';
import { useDeleteBusinessEmployeeMutation } from '@internals/business-shared/src/hooks/mutation/useDeleteBusinessEmployee';
import { useDeleteBusinessEmployeeImageMutation } from '@internals/business-shared/src/hooks/mutation/useDeleteBusinessEmployeeImage';
import { useUpdateBusinessEmployeeMutation } from '@internals/business-shared/src/hooks/mutation/useUpdateBusinessEmployee';
import { useEmployeeListQuery } from '@internals/business-shared/src/hooks/query/useEmployeeListQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { AddBusinessEmployeeResponseError } from '@internals/business-shared/src/utils/errors/AddBusinessEmployeeResponseError';
import { UpdateBusinessEmployeeResponseError } from '@internals/business-shared/src/utils/errors/UpdateBusinessEmployeeResponseError';
import { isAddBusinessEmployeeSuccessResponse } from '@internals/business-shared/src/utils/mutation/AddBusinessEmployee/AddBusinessEmployeeMutation';
import { isUpdateBusinessEmployeeSuccessResponse } from '@internals/business-shared/src/utils/mutation/UpdateBusinessEmployee/UpdateBusinessEmployeeMutation';
import {
  Employee,
  EMPLOYEE_LIST_QUERY,
} from '@internals/business-shared/src/utils/query/EmployeeList/EmployeeListQuery';
import { ProfileOutletContext } from '@pages/Profile/Profile';
import { Div, Spinner, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';
import { useTheme } from 'styled-components';

import ProfileEmployeesEmployeeModal from './ProfileEmployeesEmployeeModal';

export type EmployeeData = {
  firstName: string;
  lastName: string;
  position: string;
  phoneNr: string;
  email: string;
  image: File | { path: string };
};

export const ProfileEmployees: FC = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { setIframeMessage } = useOutletContext<ProfileOutletContext>();
  const [employeeToEdit, setEmployeeToEdit] = useState<undefined | Employee>();
  const [isAddNewEmployeeOpen, setIsAddNewEmployeeOpen] = useState(false);
  const [errors, setErrors] = useState<GQLErrorState>({});
  const themeContext = useTheme();
  const businessId = useUserBusinessId();

  const {
    loading,
    data: employees,
    error: employeeListError,
  } = useEmployeeListQuery(businessId);

  const [addBusinessEmployee, { loading: addLoading }] =
    useAddBusinessEmployeeMutation();

  const [deleteBusinessEmployee, { loading: deleteLoading }] =
    useDeleteBusinessEmployeeMutation();

  const [updateBusinessEmployee, { loading: updateLoading }] =
    useUpdateBusinessEmployeeMutation();

  const [deleteBusinessEmployeeImage, { loading: deleteImageLoading }] =
    useDeleteBusinessEmployeeImageMutation();

  const saveButtonLoading = useMemo(
    () => addLoading || updateLoading || deleteImageLoading,
    [addLoading, updateLoading, deleteImageLoading]
  );

  const addEmployee = useCallback(
    async (newEmployee: EmployeeData) => {
      await addBusinessEmployee(
        {
          businessId,
          ...newEmployee,
        },
        {
          refetchQueries: [EMPLOYEE_LIST_QUERY],
          awaitRefetchQueries: true,

          onCompleted: (data) => {
            if (
              isAddBusinessEmployeeSuccessResponse(data.addBusinessEmployee)
            ) {
              setIsAddNewEmployeeOpen(false);
            } else {
              const addBusinessEmployeeResponseError =
                new AddBusinessEmployeeResponseError(
                  'AddBusinessEmployeePayload'
                );
              addBusinessEmployeeResponseError.hasResponseError(
                data.addBusinessEmployee,
                setErrors
              );
            }
          },
          onError: () => {
            setIsAddNewEmployeeOpen(false);
          },
        }
      );
      track(
        ANEventSpace(ANEvent.Clicked, ANObject.NewEmployee, ANPage.Profile)
      );
    },
    [addBusinessEmployee, businessId, track]
  );

  const deleteEmployee = useCallback(
    async (employeeId: string) => {
      await deleteBusinessEmployee(
        { employeeId },
        {
          refetchQueries: [EMPLOYEE_LIST_QUERY],
          awaitRefetchQueries: true,
          onCompleted: () => {
            setEmployeeToEdit(undefined);
          },
        }
      );
    },
    [deleteBusinessEmployee]
  );

  const deleteEmployeeImage = useCallback(
    async (employeeId: string) => {
      await deleteBusinessEmployeeImage(
        { employeeId },
        {
          refetchQueries: [EMPLOYEE_LIST_QUERY],
          awaitRefetchQueries: true,
        }
      );
    },
    [deleteBusinessEmployeeImage]
  );

  const updateEmployee = useCallback(
    async (updatedEmployee: EmployeeData) => {
      await updateBusinessEmployee(
        {
          employeeId: employeeToEdit?.id,
          ...updatedEmployee,
        },
        {
          refetchQueries: [EMPLOYEE_LIST_QUERY],
          awaitRefetchQueries: true,
          onCompleted: (data) => {
            if (
              isUpdateBusinessEmployeeSuccessResponse(
                data?.updateBusinessEmployee
              )
            ) {
              setEmployeeToEdit(undefined);
            } else {
              const updateBusinessEmployeeResponseError =
                new UpdateBusinessEmployeeResponseError(
                  'UpdateBusinessEmployeePayload'
                );
              updateBusinessEmployeeResponseError.hasResponseError(
                data.updateBusinessEmployee,
                setErrors
              );
            }
          },
        }
      );
    },
    [employeeToEdit?.id, updateBusinessEmployee]
  );

  const closeModal = () => {
    setEmployeeToEdit(undefined);
    setIsAddNewEmployeeOpen(false);
  };

  useEffect(() => {
    setIframeMessage(IframeMessage('employees', employees ?? []));
  }, [employees]);

  usePageAnalytics(ANSpace(ANObject.Employees, ANPage.Profile));

  return (
    <>
      <ProfileEmployeesEmployeeModal
        employeeToEdit={employeeToEdit}
        isAddNewEmployeeOpen={isAddNewEmployeeOpen}
        closeModal={closeModal}
        addEmployee={addEmployee}
        deleteEmployee={deleteEmployee}
        deleteEmployeeImage={deleteEmployeeImage}
        updateEmployee={updateEmployee}
        saveButtonLoading={saveButtonLoading}
        deleteButtonLoading={deleteLoading}
        errors={errors}
        setErrors={setErrors}
      />
      <SectionHeader
        title={t('profile.employees.list.title')}
        buttonText={t('general.label.new')}
        onButtonClick={() => setIsAddNewEmployeeOpen(true)}
        px={6}
      />
      <Div px={6}>
        <TextInfoBox mb={4} text={t('profile.employees.list.boxInfo')} />
      </Div>
      {loading && (
        <CenteredSpinnerContainer>
          <Spinner size={6} />
        </CenteredSpinnerContainer>
      )}
      {employeeListError && (
        <ErrorAlert
          errors={{
            general: {
              msg: `${t('profile.employees.list.error')}`,
              variant: 'danger',
            },
          }}
        />
      )}
      {!loading &&
        employees?.map((employeeItem) => (
          <ProfileEditListItem
            key={employeeItem.id}
            image={employeeItem.imageUrl ?? undefined}
            text={{
              firstName: employeeItem.firstName,
              lastName: employeeItem.lastName,
              position: employeeItem.position,
              phoneNr: employeeItem.phoneNr,
            }}
            renderText={(text) => (
              <>
                <Text.p
                  fontSize={3}
                  fontWeight={themeContext.fontWeights.bold}
                  data-testid="employee-name"
                  truncate
                >
                  {text.firstName} {text.lastName}
                </Text.p>
                <Text.p mb={4} truncate>
                  {text.position}
                </Text.p>
                <Text.p mb={0}>{text.phoneNr}</Text.p>
              </>
            )}
            icon={IconPickerName.ChevronRight}
            onClick={() => setEmployeeToEdit(employeeItem)}
            testId={`employee-${employeeItem.id}`}
          />
        ))}
    </>
  );
};
