import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const SettingsContainer = styled(Div)`
  padding: ${(props): number => props.theme.space[5]}px
    ${(props): number => props.theme.space[5]}px
    ${(props): number => props.theme.space[9]}px
    ${(props): number => props.theme.space[5]}px;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    padding: 45px;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    margin-bottom: 0;
  }
`;

export default SettingsContainer;
