import { FC, useMemo } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Column, Div, Text, hexToRgba } from '@schibsted-smb/fireball';
import { isRemppatori } from '@utils/product';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const Features: FC = () => {
  const { colors } = useTheme();
  const { t } = useTranslation();
  const isRemppatoriProduct = isRemppatori();
  const isTabletOrMobile = useIsTabletOrMobile();

  const productInfo = useMemo(
    () =>
      [
        {
          description: t('job.modal.subscription.selectPackage.profilePage'),
          iconName: IconPickerName.BadgeId,
        },
        !isRemppatoriProduct && {
          description: t(
            'job.modal.subscription.selectPackage.visibilitySearch'
          ),
          iconName: IconPickerName.Search,
        },
        {
          description: t('job.modal.subscription.selectPackage.period'),
          iconName: IconPickerName.CalendarAlt,
        },
      ].filter(Boolean),
    [isRemppatoriProduct, t]
  );

  const lastElementIndex = productInfo.length - 1;

  return (
    <Column
      bg={isTabletOrMobile && hexToRgba(colors.black.black1, 0.05)}
      display="flex"
      justifyContent={isTabletOrMobile ? 'flex-start' : 'center'}
      mb={isTabletOrMobile && 5}
      p={5}
      pb={!isTabletOrMobile && 8}
    >
      <Div color="black.black0">
        <Text.p
          color="black.black0"
          fontSize={3}
          fontWeight="medium"
          mb={4}
          textAlign="center"
        >
          {t('job.modal.subscription.selectPackage.allProducts')}
        </Text.p>
        <Div display="flex" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
          {productInfo.map(({ description, iconName }, index) => (
            <Div
              key={iconName}
              display="flex"
              mr={index !== lastElementIndex && !isTabletOrMobile && 6}
              mb={index !== lastElementIndex && isTabletOrMobile && 3}
            >
              <Div display="flex" mr={3}>
                <FireIcon
                  fill="black.black0"
                  height={18}
                  iconName={iconName}
                  width={18}
                />
              </Div>
              <Text.p mb={0}>{t(description)}</Text.p>
            </Div>
          ))}
        </Div>
      </Div>
    </Column>
  );
};
