import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const NavButton = styled(Button)`
  &,
  &:hover {
    background-color: transparent;
    border-color: transparent;
    padding: 0;
  }

  &:focus,
  &:focus-within {
    border-color: transparent;
  }
`;

export default NavButton;
