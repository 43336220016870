import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  isJobOwnerQuerySuccessResponse,
  JOB_OWNER_QUERY,
  JobOwner,
  JobOwnerQueryPayload,
  JobOwnerQueryVariables,
} from '../../utils/query/JobOwner/JobOwnerQuery';

interface JobOwnerQueryResult extends QueryResult {
  data: JobOwner;
}

export const useJobOwnerQuery = (
  jobId: JobOwnerQueryVariables['id'],
  options?: QueryHookOptions<JobOwnerQueryPayload, JobOwnerQueryVariables>
): JobOwnerQueryResult => {
  const { data, ...rest } = useQuery<
    JobOwnerQueryPayload,
    JobOwnerQueryVariables
  >(JOB_OWNER_QUERY, {
    variables: { id: jobId },
    fetchPolicy: 'cache-and-network',
    ...options,
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
  });

  const result = useMemo(() => {
    return {
      data: data && isJobOwnerQuerySuccessResponse(data.job) ? data.job : null,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
