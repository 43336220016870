import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import FILE_FRAGMENT from '../../fragments/FileFragment';
import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  EVALUATION_QUERY as EVALUATION_QUERY_Result,
  EVALUATION_QUERYVariables,
  EVALUATION_QUERY_evaluation_EvaluationPayload,
} from '../../generated/generated';

export type EvluationQueryPayload = EVALUATION_QUERY_Result;
export type EvaluationQueryVariables = EVALUATION_QUERYVariables;
export type Evaluation =
  EVALUATION_QUERY_evaluation_EvaluationPayload['evaluation'];

export const isEvaluationQuerySuccessResponse = (
  evaluation?: EVALUATION_QUERY_Result['evaluation']
): evaluation is EVALUATION_QUERY_evaluation_EvaluationPayload =>
  evaluation?.__typename === 'EvaluationPayload';

export const EVALUATION_QUERY = gql`
  ${IMAGE_FRAGMENT}
  ${FILE_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  query EVALUATION_QUERY($id: ID!) {
    evaluation(input: { evaluationId: $id }) {
      ... on EvaluationPayload {
        evaluation {
          id
          score
          description
          focus
          writerName
          publishedTime
          job {
            id
            title
            description
            address {
              city
              street
              zip
            }
            owner {
              ...BasicUserDataFragment
            }
            images {
              ...ImageFragment
            }
            files {
              ...FileFragment
            }
          }
          reply {
            id
            createdTime
            evaluation {
              id
            }
            description
            business {
              id
              initials
              logo {
                ...ImageFragment
              }
            }
            publishedTime
          }
          business {
            id
            initials
            logo {
              ...ImageFragment
            }
          }
        }
      }
      ... on EvaluationInputError {
        message
        evaluationId {
          message
        }
      }
      ... on EvaluationNotFoundError {
        message
      }
    }
  }
`;
