import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { ProductPageLayout } from '@components/layout/Products/ProductPageLayout';
import ProductsView from '@components/layout/Settings/Products';
import withNavigationLayout from '@components/layout/WithNavigation';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsProducts: FC = () => {
  const { t } = useTranslation();
  const isNewProductPageEnabled = useFlagEnabled(
    FeatureFlags.BizNewProductsPage
  );

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: isNewProductPageEnabled ? ProductPageLayout : ProductsView,
      subpageName: t('settings.products.title'),
    },
  })();
};
