import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  DELETE_ANSWER_TEMPLATE,
  DeleteAnswerTemplateMutationPayload,
  DeleteAnswerTemplateMutationVariables,
} from '../../utils/mutation/DeleteAnswerTemplate/DeleteAnswerTemplateMutation';

type useDeleteAnswerTemplateAction = (
  templateId: DeleteAnswerTemplateMutationVariables['input']['id'],
  options?: MutationFunctionOptions<
    DeleteAnswerTemplateMutationPayload,
    DeleteAnswerTemplateMutationVariables
  >
) => Promise<FetchResult<DeleteAnswerTemplateMutationPayload>>;

export const useDeleteAnswerTemplate = (
  mutationHookOptions?: MutationFunctionOptions<
    DeleteAnswerTemplateMutationPayload,
    DeleteAnswerTemplateMutationVariables
  >
): [
  useDeleteAnswerTemplateAction,
  MutationResult<DeleteAnswerTemplateMutationPayload>
] => {
  const [deleteAnswerTemplate, ...rest] = useMutation<
    DeleteAnswerTemplateMutationPayload,
    DeleteAnswerTemplateMutationVariables
  >(DELETE_ANSWER_TEMPLATE, {
    ...mutationHookOptions,
  });

  const mutationFn: useDeleteAnswerTemplateAction = useCallback(
    (templateId, options?) => {
      return deleteAnswerTemplate({
        variables: {
          input: { id: templateId },
        },
        ...options,
      });
    },
    [deleteAnswerTemplate]
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
