import { BadgeNumbersQueryPayload } from './query/BadgeNumbers/BadgeNumbersQuery';

export const hasNewActivity = (
  data: BadgeNumbersQueryPayload,
  previousData: BadgeNumbersQueryPayload
) => {
  const latestActiveAt = data.badgeNumbers.latestActivityAt;
  const previousLatestActiveAt = previousData.badgeNumbers.latestActivityAt;

  return latestActiveAt > previousLatestActiveAt;
};
