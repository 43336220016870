import { FC, useCallback, useEffect, useState } from 'react';

import { DateInput } from '@components/base/DateInput';
import PhoneInput from '@components/base/PhoneInput';
import { Currency } from '@components/elements/CustomerServiceContract/styled/Currency';
import { FormLabel } from '@components/elements/CustomerServiceContract/styled/FormLabel';
import { FormRow } from '@components/elements/CustomerServiceContract/styled/FormRow';
import { useContractTypeRadioOptions } from '@components/elements/CustomerServiceContract/useContractTypeRadioOptions';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { useUserContext } from '@contexts/UserContext';
import useAnalytics from '@hooks/useAnalytics';
import useStorage, { STORE_CONTRACT_PREFIX } from '@hooks/useStorage';
import { useCreateSimpleAgreementContractMutation } from '@internals/business-shared/src/hooks/mutation/useCreateSimpleAgreementContractMutation';
import { CreateContractFormShape } from '@internals/business-shared/src/types/FormShape/CreateContract';
import { CreateSimpleAgreementContractAdapter } from '@internals/business-shared/src/utils/adapters/CreateContract';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  CHAT_JOB_QUERY_job_Job,
  SimpleAgreementPriceType,
} from '@internals/business-shared/src/utils/generated/generated';
import { isCreateSimpleAgreementContractMutationSuccessResponse } from '@internals/business-shared/src/utils/mutation/CreateSimpleAgreementContract/CreateSimpleAgreementContractMutation';
import { CHAT_JOB_QUERY } from '@internals/business-shared/src/utils/query/ChatJob/ChatJobQuery';
import {
  Button,
  Column,
  Container,
  Heading,
  Input,
  Modal,
  RadioGroup,
  Row,
  Textarea,
} from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import ToastMessage from '@utils/ToastMessage';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  contractFormInitValues,
  contractValidationSchema,
  HUNDRED_MILLION,
} from './ContractValidationSchema';

interface CreateSimpleContractModalProps {
  show: boolean;
  onClose: VoidFunction;
  jobId: CHAT_JOB_QUERY_job_Job['id'];
}

export const CreateSimpleContractModal: FC<CreateSimpleContractModalProps> = ({
  show,
  onClose,
  jobId,
}) => {
  const { getRadioGroupOptions } = useContractTypeRadioOptions();
  const { t } = useTranslation();
  const user = useUserContext();
  const [createSimpleAgreementContract] =
    useCreateSimpleAgreementContractMutation();
  const [formInitValues, setFormInitValues] = useState<CreateContractFormShape>(
    contractFormInitValues
  );
  const { currency } = getProduct();
  const { track } = useAnalytics();
  const { get, set, remove } = useStorage(STORE_CONTRACT_PREFIX);
  const isMobile = useIsMobile();

  const onFormikSubmit = useCallback(
    async (
      values: CreateContractFormShape,
      actions: FormikHelpers<CreateContractFormShape>
    ) => {
      actions.setSubmitting(true);

      try {
        const response = await createSimpleAgreementContract(
          CreateSimpleAgreementContractAdapter(values, jobId),
          {
            refetchQueries: [CHAT_JOB_QUERY],
            awaitRefetchQueries: true,
          }
        );

        if (
          isCreateSimpleAgreementContractMutationSuccessResponse(
            response.data.createSimpleAgreementContract
          )
        ) {
          track(
            ANEventSpace(ANEvent.Created, ANObject.Agreement, ANPage.Customers),
            {
              priceType: values.priceType,
              price: values.price,
            }
          );
          ToastMessage(
            t('contract.item.simpleContract.createSuccess'),
            'success'
          );
          remove(jobId);
          actions.resetForm();
          onClose();
        } else {
          ToastMessage(
            t('contract.item.simpleContract.createFailed'),
            'danger'
          );
          bugsnagClient.notify(
            'Create simple agreement contract failed due to mutation unknown response type'
          );
        }
      } catch (error) {
        ToastMessage(t('contract.item.simpleContract.createError'), 'danger');
        bugsnagClient.notify(error);
      } finally {
        actions.setSubmitting(false);
      }
    },
    [createSimpleAgreementContract, jobId, onClose, t, track]
  );

  useEffect(() => {
    if (!show) {
      setFormInitValues(contractFormInitValues);
      return;
    }

    const draft = get(jobId);
    setFormInitValues((prevState) => ({
      ...prevState,
      description: draft?.data?.description ?? prevState.description,
      businessEmail: user.operatingAs.email || '',
      businessPhone: user.operatingAs.phone || '',
    }));
  }, [show, user.operatingAs.email, user.operatingAs.phone]);

  if (!show) return null;

  return (
    <Modal
      isOpen
      isClosable
      onClose={onClose}
      shouldCloseOnOverlayClick
      header={
        <Heading.h3>
          {t('contract.item.simpleContract.addInformation')}
        </Heading.h3>
      }
    >
      <Formik
        initialValues={formInitValues}
        validationSchema={contractValidationSchema}
        onSubmit={onFormikSubmit}
        enableReinitialize
      >
        {(formikProps: FormikProps<CreateContractFormShape>) => {
          const priceOptions = getRadioGroupOptions(
            formikProps.values.priceType,
            formikProps.handleChange
          );
          return (
            <Form noValidate>
              <Container>
                <FormRow>
                  <Column width={[1]}>
                    <RadioGroup
                      name="priceType"
                      options={priceOptions}
                      perLine={3}
                    />
                  </Column>
                </FormRow>
                {formikProps.values.priceType !==
                  SimpleAgreementPriceType.OTHER && (
                  <FormRow>
                    <Column width={[1]}>
                      {formikProps.values.priceType ===
                        SimpleAgreementPriceType.FIXED && (
                        <FormLabel>
                          {t('contract.item.simpleContract.price.fixed')}{' '}
                          {t('general.label.includingVat')}
                        </FormLabel>
                      )}
                      {formikProps.values.priceType ===
                        SimpleAgreementPriceType.HOUR_COMPUTATION && (
                        <FormLabel>
                          {t('contract.item.simpleContract.price.hourlyRate')}{' '}
                          {t('general.label.includingVat')}
                        </FormLabel>
                      )}
                      <Input
                        {...formikProps.getFieldProps('price')}
                        value={formikProps.values?.price?.toString() || ''}
                        inputMode="numeric"
                        type="number"
                        unlabeled
                        min="0"
                        max={HUNDRED_MILLION.toString()}
                        maxLength={9}
                        error={
                          formikProps.touched.price && formikProps.errors.price
                            ? {
                                msg: formikProps.errors.price,
                                variant: 'danger',
                              }
                            : null
                        }
                        trailing={<Currency>{currency}</Currency>}
                      />
                    </Column>
                  </FormRow>
                )}
                <FormRow>
                  <Column width={isMobile ? 1 : [1 / 2]}>
                    <FormLabel>{t('general.dateTime.startDate')}</FormLabel>
                    <DateInput
                      name="start"
                      value={formikProps.values.start}
                      onChange={(d) => {
                        formikProps.setFieldTouched('start', true, true);
                        formikProps.setFieldValue('start', d);
                      }}
                      error={
                        formikProps.touched.start && formikProps.errors.start
                          ? String(formikProps.errors.start)
                          : null
                      }
                      clearError={() => formikProps.setFieldError('start', '')}
                      validate={() => formikProps.validateField('start')}
                    />
                  </Column>
                  <Column width={isMobile ? 1 : [1 / 2]}>
                    <FormLabel>{t('general.dateTime.endDate')}</FormLabel>
                    <DateInput
                      name="end"
                      value={formikProps.values.end}
                      onChange={(d) => {
                        formikProps.setFieldTouched('end', true, true);
                        formikProps.setFieldValue('end', d);
                      }}
                      error={
                        formikProps.touched.end && formikProps.errors.end
                          ? String(formikProps.errors.end)
                          : null
                      }
                      clearError={() => formikProps.setFieldError('end', '')}
                      validate={() => formikProps.validateField('end')}
                    />
                  </Column>
                </FormRow>
                <FormRow>
                  <Column width={isMobile ? 1 : [1 / 2]}>
                    <FormLabel>{t('general.label.companyEmail')}</FormLabel>
                    <Input
                      {...formikProps.getFieldProps('businessEmail')}
                      inputMode="email"
                      type="email"
                      unlabeled
                      error={
                        formikProps.touched.businessEmail &&
                        formikProps.errors.businessEmail
                          ? {
                              msg: formikProps.errors.businessEmail,
                              variant: 'danger',
                            }
                          : null
                      }
                    />
                  </Column>
                  <Column width={isMobile ? 1 : [1 / 2]}>
                    <FormLabel>{t('general.label.companyPhone')}</FormLabel>
                    <PhoneInput
                      {...formikProps.getFieldProps('businessPhone')}
                      required
                      unlabeled
                      error={
                        formikProps.touched.businessPhone &&
                        formikProps.errors.businessPhone
                          ? {
                              msg: formikProps.errors.businessPhone,
                              variant: 'danger',
                            }
                          : null
                      }
                      onFocusOut={({ value, isError }) => {
                        formikProps.setFieldTouched(
                          'businessPhone',
                          true,
                          true
                        );
                        formikProps.setFieldValue('businessPhone', value ?? '');
                        formikProps.setFieldError(
                          'businessPhone',
                          isError
                            ? t('general.form.validation.phone.invalidFormat')
                            : ''
                        );
                      }}
                    />
                  </Column>
                </FormRow>
                <FormRow>
                  <Column width={[1]}>
                    <FormLabel>
                      {t('contract.item.simpleContract.addDescription')}
                    </FormLabel>
                    <Textarea
                      label={t('general.label.description')}
                      height="150px"
                      error={
                        formikProps.touched.description &&
                        formikProps.errors.description
                          ? {
                              msg: String(formikProps.errors.description),
                              variant: 'danger',
                            }
                          : null
                      }
                      value={formikProps.values.description}
                      onChange={(e) => {
                        formikProps.handleChange('description')(e);
                        set(jobId, { description: e.target.value });
                      }}
                      onBlur={formikProps.handleBlur('description')}
                    />
                  </Column>
                </FormRow>
                <Row justifyContent="flex-end">
                  <Column
                    width={[1 / 2]}
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                  >
                    <Button
                      type="reset"
                      variant="linkPrimary"
                      onClick={onClose}
                    >
                      {t('general.label.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      isLoading={formikProps.isSubmitting}
                    >
                      {t('contract.item.simpleContract.createContract')}
                    </Button>
                  </Column>
                </Row>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
