import { Link } from 'react-router-dom';
import styled from 'styled-components';

export interface ChatContainerProps {
  $isChatUnread: boolean;
}

const ChatLink = styled(Link)<ChatContainerProps>`
  text-decoration: none;
  position: relative;
  min-width: 0;
  flex-grow: 1;

  &:hover {
    text-decoration: none;
  }

  ${({ theme, $isChatUnread }): string =>
    $isChatUnread
      ? `
      padding-right: ${theme.space[6]}px;
      &:after {
        content: '';
        position: absolute;
        right: ${theme.space[4]}px;
        top: 50%;
        transform: translateY(-50%);
        width: ${theme.space[4]}px;
        height: ${theme.space[4]}px;
        background: ${theme.colors.primary.base};
        border-radius: ${theme.radii[4]};
      }
    `
      : ''};
`;

export default ChatLink;
