import { CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload } from '../generated/generated';
import { CreateJobFolderMutationPayload } from '../mutation/CreateJobFolder/CreateJobFolderMutation';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class CreateJobFolderResponseError extends GQLResponseErrorBase<
  CreateJobFolderMutationPayload['createJobFolder'],
  CREATE_JOB_FOLDER_createJobFolder_CreateJobFolderPayload
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'CreateJobFolderInputError':
        this.errors.general = { variant: 'danger', msg: data.message };
        this.mapDataToError(data, 'businessIdError', 'danger');
        this.mapDataToError(data, 'titleError', 'danger');
        this.mapDataToError(data, 'descriptionError', 'warning');
        this.mapDataToError(data, 'colorError', 'danger');
        break;

      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
