import gql from 'graphql-tag';

import {
  CHOOSE_BUSINESS_WORK_TYPE_SUBSETSVariables,
  CHOOSE_BUSINESS_WORK_TYPE_SUBSETS as CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_Result,
} from '../../generated/generated';

export type ChooseBusinessWorkTypeSubsetsPayload =
  CHOOSE_BUSINESS_WORK_TYPE_SUBSETS_Result;
export type ChooseBusinessWorkTypeSubsetsVariables =
  CHOOSE_BUSINESS_WORK_TYPE_SUBSETSVariables;

export const CHOOSE_BUSINESS_WORK_TYPE_SUBSETS = gql`
  mutation CHOOSE_BUSINESS_WORK_TYPE_SUBSETS(
    $businessId: ID!
    $subsetIds: [ID!]!
  ) {
    chooseBusinessWorkTypeSubsets(
      input: { businessId: $businessId, subsetIds: $subsetIds }
    ) {
      subsets {
        id
      }
    }
  }
`;
