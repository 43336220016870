import { Container } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const PrintContainer = styled(Container)<{
  pageWidth: number;
  pageHeight: number;
}>`
  @media print {
    width: ${(props) => props.pageWidth}mm;
    height: ${(props) => props.pageHeight}mm;
  }
`;

export default PrintContainer;
