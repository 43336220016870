import { Button, Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

export type ButtonPosition = 'right' | 'left';
export const PILL_NAVIGATION_BUTTON_WIDTH = 44;

export const StyledPillNavigationButtonWrapper = styled.div<{
  position: ButtonPosition;
}>`
  position: absolute;
  z-index: ${Layer.base + Layer.above};
  top: -1px;
  height: ${PILL_NAVIGATION_BUTTON_WIDTH}px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 150%;
    height: 100%;
    z-index: ${Layer.base + Layer.below};
  }

  ${({ position }) =>
    position === 'right'
      ? `
    right: 0;
    &::before {
      left: -50%;
      background: linear-gradient(270deg, #FFF 4.82%, rgba(255, 255, 255, 0.00) 96.43%);
    }
    `
      : `
    left: 0;
    &::before {
      right: -50%;
      background: linear-gradient(90deg, #FFF 4.82%, rgba(255, 255, 255, 0.00) 96.43%);
    }
    `}
`;

export const StyledPillNavigationButton = styled.button<{
  position: ButtonPosition;
}>`
  border: 1px solid ${({ theme }) => theme.colors.black.black4};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.background.white};
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  ${({ position }) => (position === 'right' ? 'right: 0;' : 'left: 0;')};

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme }) => theme.colors.black.black2};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    height: 44px;
    width: 44px;
  }
`;

export const StyledPillNavigationMobile = styled.div`
  display: flex;
  align-items: center;
  min-height: 35px;
  width: 100%;
  overflow-x: auto;
  padding: ${({ theme }) => `0 ${theme.space[5]}px`};
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledPillNavigationFilterButton = styled(Button)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[4]}px`};
  border-radius: ${({ theme }) => theme.radii[4]};
  flex-shrink: 0;
  align-items: center;
  &,
  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.colors.black.black1};
    color: ${({ theme }) => theme.colors.primary.darkest};
    border: ${({ theme }) => `1px solid ${theme.colors.black.black4}`};
    box-shadow: none;
  }
`;

export const StyledPillNavigationFilterButtonContainer = styled(Div)`
  padding: ${({ theme }) => `0 ${theme.space[5]}px`};
  position: relative;
  display: flex;
  &::after {
    content: '';
    display: block;
    height: 20px;
    width: 1px;
    background-color: ${({ theme }) => theme.colors.black.black4};
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const StyledFilterCountBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.space[6]}px;
  height: ${({ theme }) => theme.space[6]}px;
  border-radius: ${({ theme }) => theme.radii[4]};
  background-color: ${({ theme }) => theme.colors.primary.base};
  color: ${({ theme }) => theme.colors.black.black0};
`;

export const StyledFilterCountBadgeContainer = styled.div`
  position: absolute;
  right: ${({ theme }) => theme.space[3]}px;
  top: -${({ theme }) => theme.space[3]}px;
`;
