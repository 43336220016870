import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ReactComponent as PaintSplash } from '@internals/business-shared/src/assets/illustrations/paint-splash.svg';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface CompanyStateAlertIconProps {
  icon: IconPickerName;
}

export const CompanyStateAlertIcon: FC<CompanyStateAlertIconProps> = ({
  icon,
}) => {
  const themeContext = useTheme();

  return (
    <Div
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="106px"
    >
      <PaintSplash fill={themeContext.colors.primary.light} />
      <Div position="absolute">
        <FireIcon
          iconName={icon}
          variant="flat"
          fill={themeContext.colors.black.black9}
          width="38px"
          height="38px"
        />
      </Div>
    </Div>
  );
};
