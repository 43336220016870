import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ReactComponent as ConfettiGraphic } from '@internals/business-shared/src/assets/illustrations/confetti.svg';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Heading, Text } from '@schibsted-smb/fireball';

interface ProductSuccessfulPurchaseProps {
  title: string;
  subtitle: string;
}

export const ProductSuccessfulPurchase: FC<ProductSuccessfulPurchaseProps> = ({
  title,
  subtitle,
}) => (
  <Div>
    <Div height="270px" position="relative" overflow="hidden" mb={6}>
      <Div
        position="absolute"
        width="100%"
        left={0}
        bottom={0}
        maxHeight="340px"
      >
        <ConfettiGraphic />
      </Div>
    </Div>
    <Div display="flex" flexDirection="column" alignItems="center">
      <FireIcon
        iconName={IconPickerName.CheckmarkCircled}
        width={64}
        height={64}
        fill="#30CA97"
      />
      <Heading.h3 mt={6} mb={4}>
        {title}
      </Heading.h3>
      <Text.p>{subtitle}</Text.p>
    </Div>
  </Div>
);
