import {
  ChangeEvent,
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { UnsavedChangesModal } from '@components/elements/UnsavedChangesModal/UnsavedChangesModal';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import {
  ALBUM_NAME_MAX_LENGTH,
  AlbumContextType,
  DropzoneFileType,
  ProfilePicturesContext,
  UploadFileType,
} from '@contexts/ProfilePicturesContext';
import { useAbortController } from '@internals/business-shared/src/hooks/useAbortController';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '@internals/business-shared/src/utils/constants/apollo';
import {
  FILE_UPLOAD_MAX_FILES_LIMIT,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT,
  IMAGE_FILE_EXTENSIONS,
  FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
} from '@internals/business-shared/src/utils/constants/fileUpload';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { getFileExtension } from '@internals/business-shared/src/utils/FileUtils';
import {
  Button,
  Checkbox,
  Div,
  Dropzone,
  FilesDetails,
  Heading,
  Input,
  Modal,
  Text,
} from '@schibsted-smb/fireball';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProfilePicturesNewPicturesModalProps {
  files: File[];
  onClose: () => void;
}

const ProfilePicturesNewPicturesModal: FC<
  ProfilePicturesNewPicturesModalProps
> = ({ files, onClose }) => {
  const themeContext = useTheme();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const {
    albums,
    toggleAlbum,
    createAlbum,
    uploadAlbumImages,
    unselectAllAlbums,
    rotateProperty,
  } = useContext(ProfilePicturesContext);
  const inputRef = useRef<HTMLInputElement>(null);

  const [dropzoneFiles, setDropzoneFiles] = useState<DropzoneFileType[]>([]);
  const [addNewAlbum, setAddNewAlbum] = useState(false);
  const [newAlbumName, setNewAlbumName] = useState('');
  const [saving, setSaving, hideSaving] = useBoolean(false);
  const [
    isUnsavedChangesModalOpen,
    openUnsavedChangesModal,
    closeUnsavedChangesModal,
  ] = useBoolean(false);
  const { signal, abort } = useAbortController();

  // For now only one is available
  const selectedAlbums = useMemo(
    (): AlbumContextType['id'][] =>
      albums.filter((a) => a.selected).map((album) => album.id),
    [albums]
  );
  const albumAndFilesSelected = useMemo(
    (): boolean => selectedAlbums.length > 0 && dropzoneFiles.length > 0,
    [selectedAlbums, dropzoneFiles]
  );

  const dropzoneFileDecorator = (rawFiles: File[]): DropzoneFileType[] =>
    rawFiles.map(
      (file, index): DropzoneFileType => ({
        id: `${index}${file.size}${index}`,
        name: file.name,
        size: file.size,
        description: '',
        previewUrl: URL.createObjectURL(file),
        rotationDegrees: 0,
        fileType: getFileExtension(file.name),
        file,
        modified: false,
      })
    );

  const createNewAlbum = () => {
    if (newAlbumName.trim() === '') {
      setAddNewAlbum(false);
      return;
    }
    createAlbum(newAlbumName)
      .then((albumData) => {
        setNewAlbumName('');
        setAddNewAlbum(false);
        if (albumData) {
          toggleAlbum(albumData.createBusinessAlbum.album.id, true);
        }
      })
      .catch(() => {
        ToastMessage(t('profile.pictures.album.create.error'));
        setNewAlbumName('');
        setAddNewAlbum(false);
      });
  };

  const onSave = async () => {
    if (!albumAndFilesSelected) {
      return;
    }
    setSaving();

    const uploadImagesPromises = albums
      .filter((album) => selectedAlbums.includes(album.id))
      .map(async (album) =>
        uploadAlbumImages(
          {
            albumId: album.id,
            images: dropzoneFiles.map((ui): UploadFileType['images'][0] => ({
              image: ui.file,
              description: ui.description,
              modified: ui.modified,
              ...rotateProperty(ui.rotationDegrees ?? 0),
            })),
          },
          {
            context: {
              [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
              fetchOptions: {
                signal,
              },
            },
          }
        )
      );

    // should have Promise.allSettled in the future
    await Promise.all(uploadImagesPromises)
      .then(() => {
        setAddNewAlbum(false);
        setNewAlbumName('');
        hideSaving();
        onClose();
      })
      .catch(() => {
        setAddNewAlbum(false);
        setNewAlbumName('');
        hideSaving();
        onClose();
      });
  };

  const onFileUpload = (allNewFiles: File[] | FilesDetails) => {
    if (Array.isArray(allNewFiles)) {
      setDropzoneFiles((prevState) => [
        ...prevState,
        ...dropzoneFileDecorator(allNewFiles),
      ]);
    }
  };

  const onCloseModalRequest = useCallback(() => {
    if (!saving) {
      onClose();
      return;
    }
    openUnsavedChangesModal();
  }, [onClose, openUnsavedChangesModal, saving]);

  const handleUnsavedChangesModalBackButton = useCallback(() => {
    abort();
    onClose();
  }, [abort, onClose]);

  useEffect(() => {
    if (!addNewAlbum) {
      return;
    }
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [addNewAlbum]);

  useEffect(() => {
    if (files?.length > 0) {
      setDropzoneFiles(dropzoneFileDecorator(files));
    }
  }, [files]);

  // Unselect all albums when exit the screen
  useEffect(
    () => () => {
      unselectAllAlbums();
    },
    []
  );

  return (
    <>
      <Modal
        isOpen
        isClosable
        onClose={onCloseModalRequest}
        isSmaller
        size="custom"
        maxWidth="740px"
        headerProps={{
          py: 6,
          px: [4, null, null, 7, null, null, null],
          m: 0,
          border: `1px solid ${themeContext.colors.black.black3}`,
        }}
        contentProps={{ pt: 6, px: [0, null, 4, 7, null, null, null] }}
        footerProps={{
          px: [4, null, null, 7, null, null, null],
          mt: 6,
          mb: 7,
          pb: 0,
        }}
        header={
          <Heading.h3 m={0} fontSize={5}>
            {t('profile.pictures.album.uploadImages')}
          </Heading.h3>
        }
        footer={
          <Button
            onClick={onSave}
            disabled={!albumAndFilesSelected}
            variant="primary"
            isLoading={saving}
            size="xlarge"
            fullWidth
            data-testid="new-pictures-send-btn"
          >
            {t('general.label.save')}
          </Button>
        }
        testId="new-pictures"
      >
        <Dropzone
          dropzoneType="grid"
          multiple
          isImagePreviewEnabled
          files={dropzoneFiles}
          setFiles={setDropzoneFiles}
          filesDetails={dropzoneFiles}
          inputLabel={t('general.label.description')}
          uploadImageCallback={onFileUpload}
          maxTotalSize={FILE_UPLOAD_TOTAL_SIZE_LIMIT}
          maxFiles={FILE_UPLOAD_MAX_FILES_LIMIT}
          invalidSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidTypeErrorMessage={t('component.dropzone.invalidFileTypeError')}
          invalidTotalSizeErrorMessage={t(
            'component.dropzone.invalidFileSizeError',
            {
              maxFileSize: FILE_UPLOAD_TOTAL_SIZE_LIMIT_MB,
            }
          )}
          invalidMaxFilesErrorMessage={t('component.dropzone.maxFilesError', {
            maxFiles: FILE_UPLOAD_MAX_FILES_LIMIT,
          })}
          dropzoneGridUploadInfo={t('component.dropzone.gridUploadInfo')}
          dropzoneGridEmptyUploadInfo={t(
            'component.dropzone.chooseOrDragFiles'
          )}
          allowedFiles={{
            'image/*': IMAGE_FILE_EXTENSIONS,
          }}
          testId="new-pictures"
        />
        <Div
          display="flex"
          flexDirection="column"
          width="100%"
          bg="black.black1"
          p={7}
        >
          <Div>
            <Div
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
            >
              <Text.p
                fontSize={4}
                fontWeight={themeContext.fontWeights.bold}
                mb={5}
              >
                {t('profile.pictures.album.addImages.title')}
              </Text.p>
              <Text.p textAlign="center" mx={9} fontSize={2}>
                {t('profile.pictures.album.addImages.description')}
              </Text.p>
              <Div
                width="100%"
                pt={7}
                mb={6}
                display="grid"
                gridTemplateColumns={`repeat(${
                  isMobile ? 2 : 3
                }, minmax(0, 1fr))`}
                justifyContent={
                  albums.length > 1 ? 'space-between' : 'flex-start'
                }
                gridGap={4}
              >
                {albums.map((album) => (
                  <Div key={album.id} data-testid="modal-footer-new-pictures">
                    <Checkbox
                      id={album.id.toString()}
                      label={album.name}
                      name={album.id}
                      height="55px"
                      width="100%"
                      m={0}
                      bg="black.black0"
                      borderColor="black.black4"
                      variant="boxed"
                      checked={album.selected}
                      onChange={() => toggleAlbum(album.id, !album.selected)}
                      testId={album.id}
                      truncateLabel
                    />
                  </Div>
                ))}
                {addNewAlbum ? (
                  <Input
                    label={t('profile.pictures.album.name')}
                    value={newAlbumName}
                    onChange={(
                      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => setNewAlbumName(e.target.value)}
                    maxLength={ALBUM_NAME_MAX_LENGTH}
                    width="100%"
                    height="55px"
                    borderColor="black.black4"
                    inputRef={inputRef}
                    onBlur={createNewAlbum}
                    testId="new-album"
                  />
                ) : (
                  <Button
                    variant="tertiary"
                    width="100%"
                    height="55px"
                    px={4}
                    justifyContent="flex-start"
                    borderColor="black.black4"
                    onClick={() => setAddNewAlbum(true)}
                    data-testid="add-new-album-btn"
                  >
                    <Div display="flex" alignItems="center">
                      <FireIcon
                        iconName={IconPickerName.Plus}
                        variant="flat"
                        width="16px"
                        height="16px"
                      />
                      <Text.p fontSize={2} ml={4} mb={0} color="black.black9">
                        {t('profile.pictures.album.create.title')}
                      </Text.p>
                    </Div>
                  </Button>
                )}
              </Div>
            </Div>
          </Div>
        </Div>
      </Modal>
      <UnsavedChangesModal
        isOpen={isUnsavedChangesModalOpen}
        isLoading={false}
        onClose={closeUnsavedChangesModal}
        onSave={closeUnsavedChangesModal}
        onBackButton={handleUnsavedChangesModalBackButton}
      />
    </>
  );
};

export default ProfilePicturesNewPicturesModal;
