import * as React from 'react';

import { Div } from '@schibsted-smb/fireball';

interface CenteredSpinnerContainerProps {
  height?: string | number;
  specificTestId?: string;
}

const CenteredSpinnerContainer: React.FC<
  React.PropsWithChildren<CenteredSpinnerContainerProps>
> = ({ height, children, specificTestId = 'content-loader' }) => (
  <Div
    height={height || '100%'}
    width="100%"
    display="flex"
    justifyContent="center"
    alignItems="center"
    data-testid={specificTestId}
  >
    {children}
  </Div>
);

export default CenteredSpinnerContainer;
