export enum ANProduct {
  MyCompany = 'MyCompany',
}

export enum ANPage {
  None = '',
  Navbar = 'Navbar',
  JobList = 'JobList',
  JobListStatistics = 'JobListStatistics',
  JobListFilter = 'Filter',
  Messages = 'Messages',
  Customers = 'Customers',
  Profile = 'Profile',
  Settings = 'Settings',
  Welcome = 'Welcome',
  JobCard = 'JobCard',
  BoostModal = 'BoostModal',
  SubscriptionModal = 'SubscriptionModal',
  MessageInput = 'MessageInput',
  BoligMappa = 'BoligMappa',
  Backgrounded = 'backgrounded',
  SecureID = 'SecureID',
}

export enum ANObject {
  None = '',
  Info = 'Info',
  List = 'List',

  // Navbar
  Messages = 'Messages',
  Customers = 'Customers',
  ProfilePage = 'ProfilePage',
  Settings = 'Settings',
  Jobs = 'Jobs',

  // JobList
  JobList = 'JobList',
  JobCard = 'JobCard',
  Job = 'Job',
  Search = 'Search',
  EmptyJobList = 'EmptyJobList',
  // Filter = 'Filter',
  FilterSize = 'FilterSize',
  FilterFolder = 'FilterFolder',
  FilterPlace = 'FilterPlace',
  FilterWorkType = 'FilterWorkType',
  Pill = 'Pill',
  FilterTour = 'FilterTour',
  FilterPrompt = 'FilterPrompt',

  // Messages
  Message = 'Message',
  Conversation = 'Conversation',
  ShowJob = 'ShowJob',
  Filter = 'Filter',
  ShowPhone = 'ShowPhone',
  ShowAddress = 'ShowAddress',
  ShowEmail = 'ShowEmail',
  MarkedWin = 'MarkedWin',
  CopyJobDetails = 'CopyJobDetails',
  BoligMappa = 'BoligMappa',

  // Customers
  Inspection = 'Inspection',
  Offer = 'Offer',
  Contract = 'Contract',
  Agreement = 'Agreement',
  Invoice = 'Invoice',
  Evaluation = 'Evaluation',

  // Profile
  Company = 'Company',
  Design = 'Design',
  References = 'References',
  Certificates = 'Certificates',
  Images = 'Images',
  Employees = 'Employees',
  Reviews = 'Reviews',
  SaveInfo = 'SaveInfo',
  NewImage = 'NewImage',
  SaveDesign = 'SaveDesign',
  NewEmployee = 'NewEmployee',
  PreviewProfile = 'PreviewProfile',

  // Settings
  Notifications = 'Notifications',
  Products = 'Products',
  Receipts = 'Receipts',
  CustomerService = 'CustomerService',
  LogOut = 'LogOut',
  ChangePassword = 'ChangePassword',
  JobTypes = 'JobTypes',
  JobArea = 'JobArea',
  Language = 'Language',
  Integrations = 'Integrations',

  // Welcome
  RegisterCompany = 'RegisterCompany',
  Login = 'Login',

  // JobCard
  Report = 'Report',
  AnswerTab = 'AnswerTab',
  NextCard = 'NextCard',
  PreviousCard = 'PreviousCard',
  Attachment = 'Attachment',
  RejectOneToOneJob = 'RejectOneToOneJob',
  RejectOneToOneJobFeedback = 'RejectOneToOneJobFeedback',

  // Alerts
  AlertBannerContactRequest = 'AlertBannerContactRequest',
  AlertBannerContactRequestClose = 'AlertBannerContactRequestClose',
  AlertBannerBuyBoost = 'AlertBannerBuyBoost',

  // Subscription request
  SubscriptionModal = 'SubscriptionModal',
  ContactRequest = 'ContactRequest',
  Subscription = 'Subscription',

  // Subscription & Boost
  BuySubscription = 'BuySubscription',
  BoostCredits = 'BoostPackage',
  SelectPackage = 'SelectPackage',
  ConfirmPurchase = 'ConfirmPurchase',
  CancelPayment = 'CancelPayment',
  PaymentFailed = 'PaymentFailed',
  RetryAttempt = 'RetryAttempt',
  Redirection = 'Redirection',
  Summary = 'Summary',
  CompletePayment = 'CompletePayment',
  NotCompleted = 'NotCompleted',
  OpenCustomerService = 'OpenCustomerService',
  BookOnboarding = 'BookOnboarding',

  // MessageInput
  BottomSheet = 'BottomSheet',

  // BoligMappa
  Account = 'Account',
  Address = 'Address',
  Documents = 'Documents',
  SignUp = 'SignUp',
  Unit = 'Unit',

  // Backgrounded
  Notification = 'Notification',

  // BankIdVerification
  Verification = 'Verification',
}

export enum ANEvent {
  Answered = 'Answered',
  Cancelled = 'Cancelled',
  Changed = 'Changed',
  Clicked = 'Clicked',
  Closed = 'Closed',
  Connected = 'Connected',
  Created = 'Created',
  Deleted = 'Deleted',
  Disconnected = 'Disconnected',
  Edited = 'Edited',
  Favoured = 'Favoured',
  Opened = 'Opened',
  NextClicked = 'NextClicked',
  Rejected = 'Rejected',
  Searched = 'Searched',
  Saved = 'Saved',
  Selected = 'Selected',
  Sent = 'Sent',
  Signed = 'Signed',
  Success = 'Success',
  Expanded = 'Expanded',
}

// Usage:  usePageAnalytics(ANSpace(ANObject.None, ANPage.Settings))
// eslint-disable-next-line @typescript-eslint/default-param-last
export const ANSpace = (
  ob: ANObject = ANObject.None,
  page: ANPage,
  product: ANProduct = ANProduct.MyCompany
): string => `${product}: ${page ?? ''}${ob ? ` - ${ob}` : ''}`;

export const ANEventSpace = (
  ev: ANEvent,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  ob: ANObject = ANObject.None,
  page: ANPage,
  product: ANProduct = ANProduct.MyCompany
): string => `${ANSpace(ob, page, product)} ${ev}`;
