import {
  FC,
  FocusEvent,
  MouseEventHandler,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { StyledDateInputContainer } from '@components/base/DateInput/StyledDateInputContainer';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FormError } from '@components/elements/CustomerServiceContract/styled/FormError';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Calendar, Div } from '@schibsted-smb/fireball';

interface DateInputProps {
  name: string;
  value: Date | null;
  error: string | null;
  onChange: (date: Date | null) => void;
  validate: (event: FocusEvent<HTMLInputElement>) => void;
  clearError: () => void;
  disabled?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  value,
  onChange,
  validate,
  error,
  clearError,
  disabled,
  name,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const [inputActive, setInputActive, setInputInactive] = useBoolean();
  const containerRef = useRef<HTMLDivElement>(null);
  const clearDateButtonWrapperRef = useRef<HTMLDivElement>(null);

  const clearDate = () => onChange(null);

  const onDateInputClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const clickedOnClearDate = clearDateButtonWrapperRef.current?.contains(
      e.target as Node
    );
    if (disabled) {
      e.stopPropagation();
      e.preventDefault();
      return;
    }
    if (isTabletOrMobile && !clickedOnClearDate) {
      if (!inputActive) {
        e.stopPropagation();
      }
      setInputActive();
    }
  };

  const handleOnChange = (v: Date) => {
    setInputInactive();
    onChange(v);
  };

  const handleClickOutsideCalendar = useCallback(
    (event: MouseEvent) => {
      const clickedInsideCalendar = containerRef.current?.contains(
        event.target as Node
      );
      if (!clickedInsideCalendar) {
        setInputInactive();
      }
    },
    [containerRef, setInputInactive]
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (inputActive) {
      document.addEventListener('mousedown', handleClickOutsideCalendar);
      return () => {
        document.removeEventListener('mousedown', handleClickOutsideCalendar);
      };
    }
  }, [inputActive, handleClickOutsideCalendar]);

  return (
    <StyledDateInputContainer
      onClickCapture={onDateInputClick} // different handlers are used to capture event on calendar button and input
      onMouseDown={onDateInputClick}
      aria-disabled={disabled}
      ref={containerRef}
    >
      <Calendar
        variant={inputActive ? 'Full' : 'IconInput'}
        selectedDate={value}
        name={name}
        shownDate={value}
        placeholder=""
        onDateSelect={handleOnChange}
        trailing={
          value ? (
            <Div ref={clearDateButtonWrapperRef}>
              <Button
                type="button"
                variant="linkPrimary"
                size="tiny"
                onClick={clearDate}
              >
                <FireIcon
                  iconName={IconPickerName.Close}
                  variant="gray"
                  width={16}
                  height={16}
                />
              </Button>
            </Div>
          ) : null
        }
        onBlur={validate}
        onFocus={clearError}
        top={55}
      />
      {error && <FormError>{error}</FormError>}
    </StyledDateInputContainer>
  );
};
