import * as React from 'react';

import { GoogleMapsEmbed } from '@components/base/GoogleMapsEmbed';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Modal } from '@schibsted-smb/fireball';

interface MapModalProps {
  isOpen: boolean;
  onClose: () => void;
  address: string;
}

const MapModal: React.FC<React.PropsWithChildren<MapModalProps>> = ({
  isOpen,
  onClose,
  address,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Modal
      isOpen={isOpen}
      isClosable
      onClose={onClose}
      isSmaller
      header={false}
      headerProps={{ p: 0, m: 0 }}
      footerProps={{ p: 0 }}
      contentProps={{ p: 0, height: '100%', overflow: 'hidden' }}
      size="custom"
      maxWidth="1160px"
      verticalSize={isTabletOrMobile ? 'fullHeight' : 'custom'}
      {...(!isTabletOrMobile && {
        height: '100%',
        maxHeight: '95vh',
        isVerticallyCentered: true,
      })}
    >
      <GoogleMapsEmbed mapParams={address} />
    </Modal>
  );
};

export default MapModal;
