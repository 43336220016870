import { JOB_QUERY_job_Job } from '../generated/generated';
import { JobQueryPayload } from '../query/Job/JobQuery';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class JobResponseError extends GQLResponseErrorBase<
  JobQueryPayload['job'],
  JOB_QUERY_job_Job
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'JobAccessDeniedError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      case 'JobNotFoundError':
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
