import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { ActionButton } from '@schibsted-smb/fireball';

interface SearchInputClearButtonProps {
  onClick: VoidFunction;
}

export const SearchInputClearButton: FC<SearchInputClearButtonProps> = ({
  onClick,
}) => (
  <ActionButton
    fill="black.black7"
    fillHover="black.black7"
    backgroundHover="black.black0"
    background="black.black0"
    p={0}
    height="unset"
    width="unset"
    borderWidth={0}
    onClick={onClick}
  >
    <FireIcon iconName={IconPickerName.CancelCircle} width={18} height={18} />
  </ActionButton>
);
