import { FC } from 'react';

import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Heading, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { FireIcon } from '../../base/FireIcon/FireIcon';
import { FooterContainer } from './JobFooterContainer';
import { JobFeedbackForm } from './JobRejection/jobRejectionForm';

interface JobRejectionFooterProps {
  toggleRejectionFeedback: (state: boolean) => void;
  jobId: string;
}

export const JobRejectionFooter: FC<JobRejectionFooterProps> = ({
  toggleRejectionFeedback,
  jobId,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  return (
    <FooterContainer>
      <Div px={100}>
        <Div flexDirection="row" width="auto" justifyContent="space-between">
          <Heading.h2 fontWeight="bold" color="black">
            {t('job.item.reject.feedback.title')}
          </Heading.h2>
          <Div style={{ position: 'absolute', top: '10px', right: '10px' }}>
            <Button
              onClick={() => toggleRejectionFeedback(false)}
              variant="linkTertiary"
              p={0}
              data-testid="modal-header-message-template-close"
            >
              <FireIcon
                iconName={IconPickerName.Cross}
                variant="flat"
                fill={themeContext.colors.black.black9}
                width={20}
                height={20}
              />
            </Button>
          </Div>

          <Text.p lineHeight={1.5} fontSize={3} textAlign="center" mb={6}>
            {t('job.item.reject.feedback.subtitle')}
          </Text.p>
        </Div>
        <JobFeedbackForm
          jobId={jobId}
          onClose={() => toggleRejectionFeedback(false)}
        />
      </Div>
    </FooterContainer>
  );
};
