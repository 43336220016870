import { useFlagEnabled } from '@components/base/CheckFlag';
import { useIsJobFoldersFeatureEnabled } from '@contexts/UserContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  JobListId,
  JOB_LISTS_IN_ORDER,
} from '@internals/business-shared/src/utils/constants/jobListIds';
import { BETA_TAG } from '@internals/business-shared/src/utils/constants/tags';
import { getJobListMockedNavigation, JobsNavigation } from '@utils/jobList';

export const useJobListNavigation = (
  navigation?: JobsNavigation
): JobsNavigation => {
  const excludeBigJobNavItem = !useFlagEnabled(FeatureFlags.BizBigJobList);
  const excludeFolderNavItem = !useIsJobFoldersFeatureEnabled();
  const navigationMock = getJobListMockedNavigation();

  const navItems = (navigation || navigationMock).filter((navItem) => {
    if (!JOB_LISTS_IN_ORDER.includes(navItem.id as JobListId)) {
      return false;
    }
    if (navItem.id === JobListId.Direct) {
      return navItem.count > 0;
    }
    return !(
      (excludeFolderNavItem && navItem.id === JobListId.Folder) ||
      (excludeBigJobNavItem && navItem.id === JobListId.Big)
    );
  });
  navItems.sort(
    (a, b) =>
      JOB_LISTS_IN_ORDER.indexOf(a.id as JobListId) -
      JOB_LISTS_IN_ORDER.indexOf(b.id as JobListId)
  );

  return navItems.map((item) => {
    if (item.id === JobListId.Direct) {
      return {
        ...item,
        tag: BETA_TAG,
      };
    }
    return item;
  });
};
