import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

const usePreviousLocation = () => {
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    previousLocation.current = location;
  }, [location]);

  return previousLocation.current;
};

export const useRouteChanged = (): boolean => {
  const location = useLocation();
  const previousLocation = usePreviousLocation();
  return previousLocation.pathname !== location.pathname;
};
