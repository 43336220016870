import * as React from 'react';

import { Tag } from '@schibsted-smb/fireball';

import statusCheck, { StatusTypes } from './statusCheck';

const getBadge = (
  itemsArray: any[],
  activeItems: string[],
  isMunicipalityCode = false
): React.ReactElement => {
  const total = itemsArray.length;
  const active = itemsArray.reduce(
    (acc, current) =>
      activeItems.findIndex((id) =>
        isMunicipalityCode ? id === current.code : id === current.id
      ) > -1
        ? acc + 1
        : acc,
    0
  );
  // eslint-disable-next-line no-nested-ternary
  const variant: any =
    active === 0
      ? statusCheck(StatusTypes.Danger)
      : total === active
      ? statusCheck(StatusTypes.Success)
      : statusCheck(StatusTypes.Info);
  return <Tag variant={variant} noBorder>{`${active}/${total}`}</Tag>;
};

export default getBadge;
