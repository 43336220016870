import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  EDIT_SIMPLE_AGREEMENT_CONTRACT,
  EditSimpleAgreementContractMutationPayload,
  EditSimpleAgreementContractMutationVariables,
} from '../../utils/mutation/EditSimpleAgreementContract/EditSimpleAgreementContract';
import { SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_QUERY } from '../../utils/query/SimpleContractByJobId/SimpleAgreementContractByJobId';
import { SIMPLE_CONTRACT_LIST_QUERY } from '../../utils/query/SimpleContractList/SimpleContractListQuery';

type UseEditSimpleAgreementContractMutationAction = (
  input: EditSimpleAgreementContractMutationVariables['input'],
  mutationOptions?: MutationFunctionOptions<
    EditSimpleAgreementContractMutationPayload,
    EditSimpleAgreementContractMutationVariables
  >
) => Promise<FetchResult<EditSimpleAgreementContractMutationPayload>>;

export const useEditSimpleAgreementContractMutation = (): [
  UseEditSimpleAgreementContractMutationAction,
  MutationResult<EditSimpleAgreementContractMutationPayload>
] => {
  const [editSimpleAgreementContract, ...rest] = useMutation<
    EditSimpleAgreementContractMutationPayload,
    EditSimpleAgreementContractMutationVariables
  >(EDIT_SIMPLE_AGREEMENT_CONTRACT);

  const mutationFunction: UseEditSimpleAgreementContractMutationAction =
    useCallback(
      (input, mutationOptions) =>
        editSimpleAgreementContract({
          variables: {
            input: {
              ...input,
            },
          },
          refetchQueries: [
            {
              query: SIMPLE_AGREEMENT_CONTRACT_BY_JOB_ID_QUERY,
              variables: { jobId: input.jobId, companyId: input.businessId },
            },
            SIMPLE_CONTRACT_LIST_QUERY,
          ],
          awaitRefetchQueries: true,
          ...mutationOptions,
        }),
      []
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
