import * as React from 'react';

import { Div } from '@schibsted-smb/fireball';

interface CardHeaderProps {
  borderRadius?: (number | string | null)[] | number | string;
  borderTopLeftRadius?: (number | string | null)[] | number | string;
  borderTopRightRadius?: (number | string | null)[] | number | string;
  pt?: (number | string | null)[] | number | string;
  pb?: (number | string | null)[] | number | string;
  px?: (number | string | null)[] | number | string;
  bg?: (string | null)[] | string;
  borderBottom?: string;
  height?: string;
  children?: React.ReactNode;
  ref?: React.Ref<HTMLDivElement>;
}

const CardHeader: React.FC<React.PropsWithChildren<CardHeaderProps>> = ({
  borderRadius,
  borderTopLeftRadius,
  borderTopRightRadius,
  pt,
  pb,
  px,
  bg,
  borderBottom,
  children,
  height,
  ref,
}) => {
  return (
    <Div
      height={height}
      display="flex"
      justifyContent="space-between"
      bg={bg || 'black.black1'}
      pt={pt}
      pb={pb}
      px={px || 6}
      borderBottom={borderBottom || '1px solid red'}
      borderColor="black.black3"
      borderRadius={borderRadius}
      borderTopLeftRadius={borderTopLeftRadius}
      borderTopRightRadius={borderTopRightRadius}
      top="0"
      alignItems="center"
      ref={ref}
    >
      {children}
    </Div>
  );
};

export default CardHeader;
