import { Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import styled from 'styled-components';

import MoreActionsChatButton from './MoreActionsChatButton';

export interface ChatContainerProps {
  isOpen: boolean;
}

const ChatContainerV2 = styled(Div)<ChatContainerProps>`
  display: grid;
  grid-template-columns: 1fr 45px;
  justify-items: stretch;
  align-items: center;

  transition: background 0.3s;
  background-color: ${(props): string =>
    props.isOpen
      ? props.theme.colors.primary.lightest
      : props.theme.colors.black.black0};
  width: 100%;
  max-width: 100%;

  padding: ${({ theme }): string => `${theme.space[4]}px`};
  margin-left: 0;
  margin-right: 0;
  color: ${({ theme }): string => theme.colors.black.black7};

  @media (min-width: ${({ theme }): string => theme.breakpoints[4]}) {
    ${MoreActionsChatButton} {
      display: none;
      position: absolute;
      z-index: ${Layer.base + Layer.above};
      right: 0;
      top: 0;
      bottom: 0;
    }

    &:hover {
      background-color: ${({ theme }): string => theme.colors.black.black1};
      ${MoreActionsChatButton} {
        display: block;
      }
    }
  }
`;

export default ChatContainerV2;
