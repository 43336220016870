import { t } from '@internals/business-translations/src/i18n';

import JobListId from '../../utils/constants/jobListIds';
import { JobsSavedFilter } from '../../utils/fragments/JobsSavedFilterFragment';
import { JobWatchlistNotificationFrequency } from '../../utils/generated/generated';
import { UpdateJobsSavedFilterMutationInput } from '../../utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';
import { isEmptyValuesObject, isEqualObjects } from '../../utils/objectUtils';
import { Omit, OmitMany } from '../../utils/Omit';
import { JobListFilters } from '../../utils/query/JobListFilters/JobListFiltersQuery';
import { JobsSavedFilters } from '../../utils/query/JobsSavedFilters/JobsSavedFiltersQuery';
import { getDefaultJobListFiltersConfig } from './defaultJobListFiltersConfig';
import {
  AvailableFilters,
  SavedJobListFilter,
  DefaultJobListFilter,
  JobListFilter,
  FilterStateType,
  SavedFilterModalType,
  SavedFilterNavigationActionType,
  NotificationType,
} from './types';

export const availableFilters: AvailableFilters = {
  query: '',
  sizes: [],
  municipalityCode: [],
  districtId: [],
  countyId: [],
  worktypeIds: [],
  worktypeSubsets: [],
  industryIds: [],
  worktypeSubsetGroups: [],
};

// add count and ensure default list hardcoded id consistency with backend data
export const enhanceJobListFiltersData = (
  jobListFiltersConfig: DefaultJobListFilter[],
  jobListFiltersData: JobListFilters | null
): DefaultJobListFilter[] => {
  if (!jobListFiltersData) {
    return jobListFiltersConfig;
  }
  return jobListFiltersConfig.map((filterItem) => {
    const filterData = jobListFiltersData.find((jobListFilter) => {
      return jobListFilter.listType === filterItem.listType;
    });
    return filterData
      ? {
          ...filterItem,
          id: filterData.id as JobListId,
          count: filterData.count,
        }
      : filterItem;
  });
};

// display saved searches after direct job list if exists, otherwise as second item
const findSavedSearchesPosition = (filterList: JobListFilter[]) => {
  const SAVED_SEARCHES_POSITION_DEFAULT_INDEX = 1;
  const directJobListIndex = filterList.findIndex(
    (filter) => (filter.id as JobListId) === JobListId.Direct
  );
  return directJobListIndex !== -1
    ? directJobListIndex + 1
    : SAVED_SEARCHES_POSITION_DEFAULT_INDEX;
};

interface PrepareNavigationProps {
  jobListFilters: JobListFilters | null;
  excludedJobListIds?: JobListId[];
  savedSearches: JobsSavedFilters | null;
}

export const mapSavedSearchToFilter = (
  savedSearch: Pick<
    JobsSavedFilter,
    'id' | 'title' | 'filters' | 'notificationSettings'
  >
): SavedJobListFilter => ({
  id: savedSearch.id,
  label: savedSearch.title,
  filters: Omit('__typename', savedSearch.filters),
  type: 'saved',
  notificationSettings: Omit('__typename', savedSearch.notificationSettings),
});

// map and merge default job list filters and saved searches data to navigation items
export const prepareFilterNavigation = ({
  jobListFilters,
  excludedJobListIds,
  savedSearches,
}: PrepareNavigationProps): JobListFilter[] => {
  const defaultFilters: DefaultJobListFilter[] = enhanceJobListFiltersData(
    getDefaultJobListFiltersConfig(),
    jobListFilters
  ).filter(
    (filter) =>
      !excludedJobListIds.includes(filter.id) &&
      !(filter.id === JobListId.Direct && !filter.count)
  );
  const savedSearchesFilters: SavedJobListFilter[] =
    savedSearches?.map(mapSavedSearchToFilter) || [];
  const mergedFilters: JobListFilter[] = [...defaultFilters];
  mergedFilters.splice(
    findSavedSearchesPosition(mergedFilters),
    0,
    ...savedSearchesFilters
  );
  return mergedFilters;
};

export const findActiveFilterNav = (
  navigationItems: JobListFilter[],
  activeFilters: AvailableFilters,
  activeListId: JobListId
): JobListFilter | undefined => {
  const hasActiveFilters = !isEmptyValuesObject(activeFilters);
  const filterMatch = hasActiveFilters
    ? navigationItems.find((filter) =>
        isEqualObjects(filter.filters || {}, activeFilters)
      )
    : null;
  return (
    filterMatch ||
    navigationItems.find((filter) => (filter.id as JobListId) === activeListId)
  );
};

export const isJobListFilter = (filter: unknown): filter is JobListFilter => {
  return typeof filter === 'object' && filter !== null && 'type' in filter;
};

export const isJobListSavedFilter = (
  filter: unknown
): filter is SavedJobListFilter => {
  return isJobListFilter(filter) && filter.type === 'saved';
};

export const isJobListDefaultFilter = (
  filter: unknown
): filter is DefaultJobListFilter => {
  return isJobListFilter(filter) && filter.type === 'default';
};

export const assertProperFormChangedValue = (
  state: FilterStateType,
  isFormChanged: boolean
): FilterStateType => {
  switch (state.type) {
    case 'init':
    case 'active-edit-disabled':
    case 'edited-save-disabled':
    case 'new-save-disabled': {
      return {
        ...state,
        formChanged: false,
      };
    }
    case 'active-edit-enabled':
    case 'edited-save-enabled':
    case 'new-save-enabled': {
      return {
        ...state,
        formChanged: isFormChanged,
      };
    }
    default: {
      throw new Error('Unhandled state type');
    }
  }
};

export const assertProperModalType = (
  state: FilterStateType,
  modal: SavedFilterModalType
): FilterStateType => {
  switch (state.type) {
    case 'init': {
      if (modal === SavedFilterModalType.None) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error('Invalid modal type for state of type init');
    }
    case 'active-edit-enabled': {
      if (
        modal === SavedFilterModalType.None ||
        modal === SavedFilterModalType.Edit ||
        modal === SavedFilterModalType.ConfirmDelete
      ) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error(
        'Invalid modal type for state of type active-edit-enabled'
      );
    }
    case 'active-edit-disabled': {
      if (modal === SavedFilterModalType.None) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error(
        'Invalid modal type for state of type active-edit-disabled'
      );
    }
    case 'edited-save-enabled': {
      if (
        modal === SavedFilterModalType.None ||
        modal === SavedFilterModalType.ChooseAction
      ) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error(
        'Invalid modal type for state of type edited-save-enabled'
      );
    }
    case 'edited-save-disabled': {
      if (modal === SavedFilterModalType.None) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error(
        'Invalid modal type for state of type edited-save-disabled'
      );
    }
    case 'new-save-enabled': {
      if (
        modal === SavedFilterModalType.None ||
        modal === SavedFilterModalType.Create
      ) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error('Invalid modal type for state of type new-save-enabled');
    }
    case 'new-save-disabled': {
      if (modal === SavedFilterModalType.None) {
        return {
          ...state,
          modal,
        };
      }
      throw new Error('Invalid modal type for state of type new-save-disabled');
    }
    default: {
      throw new Error('Unhandled state type');
    }
  }
};

export const createJobListFilterNavId = (
  id: string,
  filterType: JobListFilter['type']
) => `nav-${filterType}-${id}`;

export interface SavedFilterTextActionProps {
  variant: 'text';
  text: string;
}

export interface SavedFilterButtonActionProps {
  variant: 'button';
  text: string;
  buttonVariant: 'primary' | 'secondary';
  buttonText: string;
  modalToTrigger: SavedFilterModalType;
}

export const getSavedFilterNavigationActionProps = (
  actionType: Omit<
    SavedFilterNavigationActionType,
    SavedFilterNavigationActionType.None
  >
): SavedFilterButtonActionProps | SavedFilterTextActionProps => {
  switch (actionType) {
    case SavedFilterNavigationActionType.Edit:
      return {
        variant: 'button',
        text: t('job.list.savedFilters.action.edit.text'),
        buttonVariant: 'secondary',
        buttonText: t('job.list.savedFilters.action.edit.button'),
        modalToTrigger: SavedFilterModalType.Edit,
      };
    case SavedFilterNavigationActionType.SaveExisting:
      return {
        variant: 'button',
        text: t('job.list.savedFilters.action.save.text'),
        buttonVariant: 'primary',
        buttonText: t('job.list.savedFilters.action.save.title'),
        modalToTrigger: SavedFilterModalType.ChooseAction,
      };
    case SavedFilterNavigationActionType.SaveNew:
      return {
        variant: 'button',
        text: t('job.list.savedFilters.action.save.text'),
        buttonVariant: 'primary',
        buttonText: t('job.list.savedFilters.action.save.title'),
        modalToTrigger: SavedFilterModalType.Create,
      };
    case SavedFilterNavigationActionType.MissingFilters:
      return {
        variant: 'text',
        text: t('job.list.savedFilters.action.missingFilters.text'),
      };
    default: {
      throw new Error('Missing handling for action type');
    }
  }
};

export const NOTIFICATION_OPTIONS: {
  id: NotificationType;
  title: string;
  text: string;
}[] = [
  {
    id: 'emailFrequency',
    title: t('general.label.email'),
    text: t('job.list.savedFilters.form.emailFrequency.label'),
  },
  {
    id: 'pushFrequency',
    title: t('general.label.push'),
    text: t('job.list.savedFilters.form.pushFrequency.label'),
  },
];

export const getJobListLabel = (listId: string): string =>
  getDefaultJobListFiltersConfig().find(
    (filter) => filter.id === (listId as JobListId)
  )?.label || t('job.list.title');

export const countFilters = (filters: Record<PropertyKey, unknown>): number =>
  Object.entries(filters).reduce((acc, [key, value]) => {
    if (key in availableFilters) {
      if (key === 'query' && typeof value === 'string' && value.trim() !== '') {
        return acc + 1;
      }
      if (Array.isArray(value)) {
        return acc + value.length;
      }
    }
    return acc;
  }, 0);

export const notificationsEnabled = (
  frequency?: JobWatchlistNotificationFrequency
) => !!frequency && frequency !== JobWatchlistNotificationFrequency.NEVER;

export const mapJobsSavedFilterToFilterInput = (
  filter: JobsSavedFilter
): UpdateJobsSavedFilterMutationInput => ({
  id: filter.id,
  title: filter.title,
  ...Omit('__typename', filter.filters),
  notificationSettings: OmitMany(
    ['id', '__typename'],
    filter.notificationSettings
  ),
});
