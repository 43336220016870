import gql from 'graphql-tag';

import ADDRESS_FRAGMENT from '../../fragments/AddressFragment';
import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import PAGE_INFO_FRAGMENT from '../../fragments/PageInfoFragment';
import {
  BUSINESS_EVALUATIONS_QUERYVariables,
  BUSINESS_EVALUATIONS_QUERY as BUSINESS_EVALUATIONS_QUERY_Result,
  BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node,
  BUSINESS_EVALUATIONS_QUERY_businessEvaluations,
} from '../../generated/generated';

export type BusinessEvaluationsQueryVariables =
  BUSINESS_EVALUATIONS_QUERYVariables;
export type BusinessEvaluationsQueryPayload = BUSINESS_EVALUATIONS_QUERY_Result;
export type BusinessEvaluationsType =
  BUSINESS_EVALUATIONS_QUERY_businessEvaluations;
export type BusinessEvaluationsItem =
  BUSINESS_EVALUATIONS_QUERY_businessEvaluations_edges_node;

export const BUSINESS_EVALUATIONS_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}
  ${ADDRESS_FRAGMENT}

  query BUSINESS_EVALUATIONS_QUERY($input: BusinessEvaluationsInput!) {
    businessEvaluations(input: $input) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        node {
          id
          score
          description
          focus
          createdTime
          lastUpdatedTime
          approvedTime
          publishedTime
          verifiedTime
          deletedTime
          business {
            id
          }
          job {
            id
            address {
              ...AddressFragment
            }
            owner {
              ...BasicUserDataFragment
            }
          }
          writerName
          reply {
            id
            description
            createdTime
            publishedTime
          }
          isPublished
        }
      }
    }
  }
`;
