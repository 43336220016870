import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import SearchInput from '@components/base/SearchInput';
import { FeatureFlag } from '@components/elements/FeatureFlag';
import LanguagePicker from '@components/elements/LanguagePicker';
import Navigation from '@components/elements/Navigation';
import { Statistics } from '@components/layout/Jobs/Statistics';
import { AdditionalFilteringTree } from '@components/layout/Jobs/Tree/AdditionalFilteringTree';
import { AreaFilteringTree } from '@components/layout/Jobs/Tree/AreaFilteringTree';
import { JobsFilteringTreeWrapper } from '@components/layout/Jobs/Tree/styled/JobsFilteringTreeWrapper';
import { TreeLabel } from '@components/layout/Jobs/Tree/styled/TreeLabel';
import { TreeSection } from '@components/layout/Jobs/Tree/styled/TreeSection';
import { TreeLeaf } from '@components/layout/Jobs/Tree/TreeLeaf';
import { WorkTypesFilteringTree } from '@components/layout/Jobs/Tree/WorkTypesFilteringTree';
import {
  FILTERABLE_SIZES,
  SizeType,
  useJobFiltersContext,
} from '@contexts/JobFiltersContext';
import { useFoldersContext } from '@contexts/JobFoldersContext';
import { useJobListAggregations } from '@contexts/JobListContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import {
  JOB_FOLDERS_QUERY_jobFolders_folders,
  JobSize,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  getJobsCountForFilter,
  getSizeLabel,
  joinFilterWithCount,
} from '@internals/business-shared/src/utils/jobFilters';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { Div } from '@schibsted-smb/fireball';
import { getTreeViewJobListNavigation, JobsNavigation } from '@utils/jobList';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface JobsFilteringTreeProps {
  navigationItems: JobsNavigation;
  isFirstDefaultActive?: boolean;
}

export const JobsFilteringTree: FC<JobsFilteringTreeProps> = ({
  navigationItems,
  isFirstDefaultActive,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();
  const folders = useFoldersContext();
  const {
    activeSizes,
    setActiveSizes,
    activeFolderFilters,
    setActiveFolders,
    searchQuery,
    setSearchQuery,
  } = useJobFiltersContext();
  const isBigJobEnabled = useFlagEnabled(FeatureFlags.BizBigJobList);
  const availableSizes = isBigJobEnabled
    ? FILTERABLE_SIZES
    : FILTERABLE_SIZES.filter((size) => size !== JobSize.BIG);
  const filteredJobCount = useJobListAggregations();

  const toggleSizeFilter = (size: SizeType) => {
    setActiveSizes(toggleItemInArray(activeSizes, size));
  };
  const toggleFolderFilter = (folderId: string) => {
    const isActive = activeFolderFilters.includes(folderId);
    setActiveFolders(
      toggleItemInArray(activeFolderFilters, folderId, !isActive)
    );
  };

  const getSizeFilterLabel = (size: SizeType) => {
    const name = getSizeLabel(size);
    const count = getJobsCountForFilter(filteredJobCount, 'sizes', size);
    return joinFilterWithCount(name, count);
  };
  const getFolderFilterLabel = (
    folder: JOB_FOLDERS_QUERY_jobFolders_folders
  ) => {
    const count = getJobsCountForFilter(filteredJobCount, 'folders', folder.id);
    return joinFilterWithCount(folder.title, count);
  };

  const navigation = getTreeViewJobListNavigation(navigationItems);

  return (
    <JobsFilteringTreeWrapper>
      <FeatureFlag name={FeatureFlags.BizSavedSearchPillsView} invert>
        <TreeSection>
          <TreeLabel>{t('job.list.filters.quickSelection')}</TreeLabel>
          <Div pt={2}>
            <Navigation
              navigationItems={navigation}
              isFirstDefaultActive={isFirstDefaultActive}
              countOnActive
              isSmaller
            />
          </Div>
        </TreeSection>
      </FeatureFlag>
      <FeatureFlag name={FeatureFlags.BizJobFilterTreeQuerySearchField}>
        <TreeSection>
          <TreeLabel>{t('job.list.filters.query.title')}</TreeLabel>
          <SearchInput
            inputValue={searchQuery}
            changeInputValue={setSearchQuery}
            width="100%"
            height="40px"
            withClearButton
            borderRadius={2}
            stylesWhenFilled={{
              border: `2px solid ${themeContext.colors.primary.base}`,
              padding: `0 ${themeContext.space[7]}px`,
            }}
          />
        </TreeSection>
      </FeatureFlag>
      <TreeSection>
        <TreeLabel>{t('job.list.filters.size.title')}</TreeLabel>
        {availableSizes.map((size) => (
          <TreeLeaf
            key={size}
            id={size}
            label={getSizeFilterLabel(size)}
            checked={activeSizes.includes(size)}
            onChange={(jobSize: SizeType) => toggleSizeFilter(jobSize)}
          />
        ))}
      </TreeSection>
      <AreaFilteringTree jobCount={filteredJobCount} />
      <WorkTypesFilteringTree jobCount={filteredJobCount} />
      <AdditionalFilteringTree jobCount={filteredJobCount} />
      {!!folders?.folderList.length && (
        <TreeSection>
          <TreeLabel>{t('job.list.filters.folder.title')}</TreeLabel>
          {folders.folderList.map((folder) => (
            <TreeLeaf
              id={folder.id}
              key={folder.id}
              label={getFolderFilterLabel(folder)}
              checked={activeFolderFilters.includes(folder.id)}
              onChange={(id: string) => toggleFolderFilter(id)}
            />
          ))}
        </TreeSection>
      )}
      <Div display={['none', null, null, null, null, 'block', null, 'none']}>
        <Statistics />
        <LanguagePicker />
      </Div>
    </JobsFilteringTreeWrapper>
  );
};
