import gql from 'graphql-tag';

import IMAGE_FRAGMENT from './ImageFragment';

const COMPANY_PROFILE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  fragment CompanyProfileFragment on Business {
    id
    logo {
      ...ImageFragment
    }
    profileHeaderImage
    name
    orgNr
    phone
    email
  }
`;

export default COMPANY_PROFILE_FRAGMENT;
