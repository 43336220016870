import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledPillNavigationItemContainer = styled.div`
  padding-right: 1px; // 1px padding to fix overflow cropping content issue in some browsers
  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.space[4]}px;
  }
`;

export const StyledPillNavigationItem = styled(NavLink)`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  padding: ${({ theme }) => `${theme.space[3]}px ${theme.space[4]}px`};
  background-color: ${({ theme }) => theme.colors.black.black0};
  color: ${({ theme }) => theme.colors.primary.darkest};
  border-radius: ${({ theme }) => theme.radii[4]};
  border: ${({ theme }) => `1px solid ${theme.colors.black.black4}`};
  white-space: nowrap;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-shrink: 0;
  -webkit-touch-callout: none;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.darkest};
    border-color: ${({ theme }) => theme.colors.primary.base};
    text-decoration: none;
  }

  &.is-active {
    color: ${({ theme }) => theme.colors.black.black0};
    background-color: ${({ theme }) => theme.colors.primary.base};
    border-color: ${({ theme }) => theme.colors.primary.base};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[5]}px`};
  }
`;
