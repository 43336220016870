import { ApolloCache, makeReference } from '@apollo/client';
import gql from 'graphql-tag';

import { User } from '../../types/User';
import {
  addJobToCachedJobList,
  removeJobFromCachedJobList,
} from '../../utils/cache';
import JobListId from '../../utils/constants/jobListIds';
import JOB_ACCESS_FRAGMENT from '../../utils/fragments/JobAccessFragment';
import JOB_CHAT_META_FRAGMENT from '../../utils/fragments/JobChatMetaFragment';
import {
  AnswerAccessCode,
  ANSWER_JOB,
  JOB_QUERY_job_Job,
} from '../../utils/generated/generated';
import { JobListOrJobQueryItem } from '../../utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import {
  AnswerJobMutationPayload,
  isAnswerJobMutationSuccessResponse,
} from '../../utils/mutation/AnswerJob/AnswerJobMutation';
import getOptimisticId from '../../utils/optimistic/id';
import { JOB_QUERY } from '../../utils/query/Job/JobQuery';

const ANSWER_JOB_RESPONSE_QUERY = gql`
  ${JOB_CHAT_META_FRAGMENT}
  ${JOB_ACCESS_FRAGMENT}

  query ANSWER_JOB_RESPONSE_QUERY($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        answerCount
        latestMessage {
          id
          regTs
          text
          writer {
            id
            firstName
            lastName
          }
        }
        firstMessage {
          id
          regTs
          text
          writer {
            id
            firstName
            lastName
          }
        }
      }
    }
    jobChatMeta(jobId: $id) {
      ...JobChatMetaFragment
    }
    jobAccess(jobId: $id) {
      ... on JobAccessPayload {
        access {
          ...JobAccessFragment
        }
      }
    }
    jobBusinessRelationState(jobId: $id) {
      jobId
      isAnswered
      clipsSpent
    }
  }
`;

const answerJobUpdate =
  (jobId: JobListOrJobQueryItem['id'], user: User, jobCost: number) =>
  (
    cache: ApolloCache<ANSWER_JOB>,
    { data: answerData }: { data?: ANSWER_JOB | null }
  ) => {
    if (!answerData) return;

    const cachedJobData: { job: JOB_QUERY_job_Job } | null = cache.readQuery({
      query: JOB_QUERY,
      variables: {
        id: jobId,
      },
    });
    const cachedJob = cachedJobData?.job;

    if (isAnswerJobMutationSuccessResponse(answerData.answerJob) && cachedJob) {
      const { chat, job } = answerData.answerJob;
      const message = { ...(job.firstMessage || {}), writer: user };
      const updatedJobData = {
        __typename: 'Job',
        id: jobId,
        firstMessage: message,
        latestMessage: message,
        answerCount:
          typeof cachedJob.answerCount === 'number'
            ? cachedJob.answerCount + 1
            : 1,
      };

      cache.writeQuery({
        query: ANSWER_JOB_RESPONSE_QUERY,
        variables: {
          id: jobId,
        },
        data: {
          job: updatedJobData,
          jobChatMeta: {
            __typename: 'ChatMeta',
            chatId: chat.id,
            firstMessageTs: job.firstMessage?.regTs,
          },
          jobAccess: {
            __typename: 'JobAccessPayload',
            access: {
              __typename: 'AnswerAccess',
              code: AnswerAccessCode.DENIED_ALREADY_ANSWERED,
              isOpen: false,
              missingCertificates: [],
              clip: 0,
            },
          },
          jobBusinessRelationState: {
            __typename: 'JobBusinessRelationState',
            jobId,
            isAnswered: true,
            clipsSpent: jobCost,
          },
        },
      });

      cache.modify({
        id: cache.identify(makeReference('ROOT_QUERY')),
        fields: {
          jobList(existingJobList, { readField }) {
            // remove job from 'open' and 'free' lists
            if (
              JobListId.Open === existingJobList.listId ||
              JobListId.Free === existingJobList.listId
            ) {
              return {
                ...existingJobList,
                jobConnection: removeJobFromCachedJobList(
                  existingJobList.jobConnection,
                  jobId,
                  readField
                ),
              };
            }
            // add job to 'answered' list
            if (JobListId.Answered === existingJobList.listId) {
              return {
                ...existingJobList,
                jobConnection: addJobToCachedJobList(
                  existingJobList.jobConnection,
                  cachedJob,
                  readField,
                  true
                ),
              };
            }
            return existingJobList;
          },
        },
      });

      // update list counters
      cache.modify({
        id: cache.identify({
          id: JobListId.Answered,
          __typename: 'JobListFilter',
        }),
        fields: {
          count(currentCount) {
            return currentCount + 1;
          },
        },
      });
      // open list
      if (
        !cachedJob.jobBusinessRelationState.isWon &&
        !cachedJob.jobBusinessRelationState.isLost &&
        !cachedJob.jobBusinessRelationState.isDeleted
      ) {
        cache.modify({
          id: cache.identify({
            id: JobListId.Open,
            __typename: 'JobListFilter',
          }),
          fields: {
            count(currentCount) {
              return currentCount - 1 > 0 ? currentCount - 1 : 0;
            },
          },
        });
      }

      if (cachedJob.isFree) {
        cache.modify({
          id: cache.identify({
            id: JobListId.Free,
            __typename: 'JobListFilter',
          }),
          fields: {
            count(currentCount) {
              return currentCount - 1 > 0 ? currentCount - 1 : 0;
            },
          },
        });
      }
    }
  };

const answerJobOptimisticResponse = (
  jobId: JobListOrJobQueryItem['id'],
  text: string
): AnswerJobMutationPayload => ({
  answerJob: {
    __typename: 'AnswerJobPayload',
    chat: {
      __typename: 'Chat',
      id: getOptimisticId('chat'),
    },
    job: {
      __typename: 'Job',
      id: jobId,
      firstMessage: {
        __typename: 'Message',
        id: getOptimisticId('message'),
        regTs: Date.now() / 1000,
        text,
      },
    },
  },
});

export { answerJobUpdate, answerJobOptimisticResponse };
