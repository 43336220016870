import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Button,
  Column,
  Container,
  Div,
  Heading,
  Row,
  Text,
} from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';

import { useBuySubscriptionModalContext } from '../ModalContext';

export const NotCompletedState: FC = () => {
  const { handleRetryAttempt, handleOpenCustomerService } =
    useBuySubscriptionModalContext();
  const { name: productName } = getProduct();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Container maxWidth={!isTabletOrMobile && 460}>
      <Row justifyContent="center" pb={6}>
        <Column>
          <Div pb={6}>
            <FireIcon
              iconName={IconPickerName.Attention}
              width={50}
              height={50}
              fill="black.black0"
            />
          </Div>
          <Div color="black.black0">
            <Heading.h1 pb={3}>
              {t('job.modal.subscription.notCompleted.title')}
            </Heading.h1>
            <Text.p textAlign="center" pb={5}>
              {t('job.modal.subscription.notCompleted.description1')}
            </Text.p>
            <Text.p textAlign="center">
              {t('job.modal.subscription.notCompleted.description2', {
                productName,
              })}
            </Text.p>
          </Div>
        </Column>
      </Row>
      <Row justifyContent="center" pb={4}>
        <Column>
          <Button onClick={handleRetryAttempt} mb={5} fullWidth>
            {t('general.error.tryAgain')}
          </Button>
          <Button
            onClick={handleOpenCustomerService}
            variant="secondary"
            fullWidth
          >
            {t('general.error.screen.contactCustomerService')}
          </Button>
        </Column>
      </Row>
    </Container>
  );
};
