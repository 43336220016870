import { useCallback, useMemo } from 'react';

import useBoolean from './useBoolean';

export const useHidden = (onReveal?: VoidFunction): [boolean, VoidFunction] => {
  const [isHidden, , show] = useBoolean(true);
  const handleReveal = useCallback(() => {
    show();
    onReveal?.();
  }, [onReveal]);

  return useMemo(() => [isHidden, handleReveal], [isHidden, handleReveal]);
};
