import React from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import NotificationsView from '@components/layout/Settings/Notifications';
import { NotificationSettingsV2 } from '@components/layout/Settings/NotificationsV2';
import withNavigationLayout from '@components/layout/WithNavigation';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsNotifications: React.FC = () => {
  const { t } = useTranslation();
  const isNotificationSettingsV2Enabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchNotificationSettingsPage
  );

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: isNotificationSettingsV2Enabled
        ? NotificationSettingsV2
        : NotificationsView,
      subpageName: t('settings.notifications.title'),
    },
  })();
};
