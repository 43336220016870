import { FC, FormEvent } from 'react';

import ErrorAlert from '@components/base/ErrorAlert';
import NewMessage from '@components/elements/NewMessage/NewMessage';
import { JobAnswerTipMobile } from '@components/layout/Job/JobAnswerTipMobile';
import { JobCostInfo } from '@components/layout/Job/JobCostInfo';
import { JobContentSection } from '@components/layout/Job/styled/JobContentSection';
import { JobCostIndicator } from '@components/layout/Job/styled/JobCostIndicator';
import { JobDataItemTitle } from '@components/layout/Job/styled/JobDataItemTitle';
import JobFormContainer from '@components/layout/Job/styled/JobFormContainer';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { FileWithPreview } from '@internals/business-shared/src/utils/FileUtils';
import { Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface JobFormMobileProps {
  jobCost: number;
  remainingCredits: number | null;
  message: string;
  files: FileWithPreview[];
  errors: GQLErrorState;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  handleSetMessage: (message: string) => void;
  setFiles: (files: FileWithPreview[]) => void;
  disabled?: boolean;
}

export const JobFormMobile: FC<JobFormMobileProps> = ({
  jobCost,
  remainingCredits,
  message,
  files,
  errors,
  handleSubmit,
  setFiles,
  handleSetMessage,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Div px={6}>
      <JobAnswerTipMobile />
      {remainingCredits !== null && (
        <JobContentSection>
          <Div
            display="flex"
            alignItems="center"
            backgroundColor="black.black1"
            p={4}
          >
            <JobCostIndicator amount={remainingCredits} />
            <JobCostInfo cost={jobCost} />
          </Div>
        </JobContentSection>
      )}

      <JobContentSection>
        <JobDataItemTitle>{t('job.item.answer.label')}</JobDataItemTitle>
        <JobFormContainer>
          <form onSubmit={handleSubmit} data-testid="job-form">
            <NewMessage
              message={message}
              setMessage={handleSetMessage}
              files={files}
              setFiles={setFiles}
              formDisabled={disabled}
            />
          </form>
        </JobFormContainer>
        <ErrorAlert errors={errors} />
      </JobContentSection>
    </Div>
  );
};
