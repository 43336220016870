import gql from 'graphql-tag';

import {
  UNFAVOURITE_JOBVariables,
  UNFAVOURITE_JOB as UNFAVOURITE_JOB_Result,
} from '../../generated/generated';

export type UnfavouriteJobMutationPayload = UNFAVOURITE_JOB_Result;
export type UnfavouriteJobMutationVariables = UNFAVOURITE_JOBVariables;

export const UNFAVOURITE_JOB = gql`
  mutation UNFAVOURITE_JOB($jobId: ID!) {
    unfavouriteJob(input: { jobId: $jobId }) {
      success
    }
  }
`;
