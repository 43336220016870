import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  COMPANY_STATE_QUERY,
  CompanyStateQueryPayload,
  CompanyStateQueryVariables,
  CompanyState,
} from '../../utils/query/CompanyState/CompanyStateQuery';

interface CompanyStateQueryResult extends QueryResult {
  data: CompanyState | null;
}

const DEFAULT_POLLING_INTERVAL = 1000 * 60 * 10; // 10 minutes

export const useCompanyStateQuery = (
  id: CompanyStateQueryVariables['id'],
  options?: QueryHookOptions<
    CompanyStateQueryPayload,
    CompanyStateQueryVariables
  >
): CompanyStateQueryResult => {
  const { data, ...rest } = useQuery<
    CompanyStateQueryPayload,
    CompanyStateQueryVariables
  >(COMPANY_STATE_QUERY, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    pollInterval: DEFAULT_POLLING_INTERVAL,
    ...options,
  });

  return useMemo(
    () => ({
      data: data?.businessStates || null,
      ...rest,
    }),
    [data, rest]
  );
};
