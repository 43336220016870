import { FC, useCallback } from 'react';

import { SimpleContractDetails } from '@components/layout/CustomerService/Contract/SimpleContractDetails';
import { StyledButtonContainer } from '@components/layout/CustomerService/Contract/styled/StyledButtonContainer';
import { StyledHeaderTitle } from '@components/layout/CustomerService/Contract/styled/StyledHeaderTitle';
import { StyledInfo } from '@components/layout/CustomerService/Contract/styled/StyledInfo';
import { useSetDocumentTitle } from '@hooks/useSetDocumentTitle';
import { useChatByJobIdQuery } from '@internals/business-shared/src/hooks/query/useChatByJobIdQuery';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import Paths from '@router/paths';
import { Button, Modal, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface SimpleContractSuccessModalProps {
  contract: SimpleContract;
  onClose: () => void;
}

const SimpleContractSuccessModal: FC<SimpleContractSuccessModalProps> = ({
  contract,
  onClose,
}) => {
  const { t } = useTranslation();
  const date = dateFromTimestamp(contract.regTime, 'ddd DD.MM.YYYY');
  const { data: chatData, loading: chatLoading } = useChatByJobIdQuery(
    contract.job.id,
    {
      onCompleted: (res) => {
        if (!res.chatByJobId) {
          ToastMessage(t('chat.item.chatLoadError'));
          bugsnagClient.notify(
            new Error(
              `Chat information for contract id: ${contract.id} returned invalid data`
            )
          );
        }
      },
    }
  );

  useSetDocumentTitle(
    `${t('contract.item.simpleContract.proposedFrom')} ${
      contract.user.firstName
    } ${contract.user.lastName}`
  );

  const onPrintClick = useCallback(() => {
    window.print();
  }, []);

  return (
    <Modal
      isOpen
      onClose={onClose}
      isClosable
      size="custom"
      maxWidth="500px"
      footer={
        chatData ? (
          <StyledButtonContainer>
            <Button
              variant="secondary"
              type="button"
              size="large"
              onClick={onPrintClick}
            >
              {t('general.label.print')}
            </Button>
            <Link
              to={`${Paths.Messages}/${chatData?.id}`}
              data-testid="go-to-conversation-btn"
            >
              <Button size="large" isLoading={chatLoading} type="button">
                {t('general.action.goToConversation')}
              </Button>
            </Link>
          </StyledButtonContainer>
        ) : (
          <Button size="large" isLoading={chatLoading} disabled type="button">
            {t('general.action.goToConversation')}
          </Button>
        )
      }
      contentProps={{ px: '45px', mt: 5 }}
      footerProps={{
        mb: 5,
        px: '45px',
        pr: '55px',
        width: '100%',
      }}
      headerProps={{ marginBottom: 0 }}
      testId="simple-contract-success"
    >
      <StyledHeaderTitle data-testid="simple-contract-success-title">
        {t('contract.item.simpleContract.proposedFrom')}{' '}
        {contract.user.firstName} {contract.user.lastName}
      </StyledHeaderTitle>
      <StyledInfo fontSize={2} textAlign="center" mb={6} lineHeight="1.5">
        {t('contract.item.simpleContract.success.subtitle')}
      </StyledInfo>
      <SimpleContractDetails contract={contract} />
      <Text.p fontSize={1} textAlign="center">
        <Trans
          i18nKey="contract.item.simpleContract.agreementCreated"
          values={{ date }}
          components={{ bold: <b /> }}
        />
      </Text.p>
    </Modal>
  );
};
export default SimpleContractSuccessModal;
