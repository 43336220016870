import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const UploadedDocumentFileName = styled(Text.p)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default UploadedDocumentFileName;
