import gql from 'graphql-tag';

import {
  PRODUCTS_QUERYVariables,
  PRODUCTS_QUERY as PRODUCTS_QUERY_Result,
  PRODUCTS_QUERY_business_Business,
} from '../../generated/generated';

export type ProductsQueryPayload = PRODUCTS_QUERY_Result;
export type ProductsQueryVariables = PRODUCTS_QUERYVariables;

export const isProductsQuerySuccessResponse = (
  business?: PRODUCTS_QUERY_Result['business']
): business is PRODUCTS_QUERY_business_Business =>
  !!(business && business.__typename === 'Business');

export const PRODUCTS_QUERY = gql`
  query PRODUCTS_QUERY($id: ID!) {
    business(id: $id) {
      ... on Business {
        id
        products {
          basicPackage {
            isOwned
          }
          jobPackage {
            isOwned
            clips
          }
          proPackage {
            isOwned
          }
          advertising {
            isOwned
          }
          companySearch {
            isOwned
          }
          homepage {
            isOwned
          }
        }
      }
      ... on BusinessInputError {
        message
        code
        idError: id {
          message
          code
        }
      }
      ... on BusinessNotFoundError {
        message
      }
    }
  }
`;
