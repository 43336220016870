enum REPORT_REASON {
  Illicit = 'ILLICIT',
  Junk = 'JUNK',
  WrongPlace = 'WRONG_PLACE',
  WrongCategory = 'WRONG_CATEGORY',
  ManpowerRequest = 'MANPOWER_REQUEST',
  Other = 'OTHER',
}

export default REPORT_REASON;
