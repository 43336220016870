import { FC } from 'react';

import { TreeLabel } from '@components/layout/Jobs/Tree/styled/TreeLabel';
import { TreeSection } from '@components/layout/Jobs/Tree/styled/TreeSection';
import { TreeBranch } from '@components/layout/Jobs/Tree/TreeBranch';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { SavedFiltersFeatureTourStep } from '@internals/business-shared/src/components/SavedFilters/SavedFiltersFeatureTourContext';
import {
  anyAreaInCountyActive,
  CountyWithMunicipalities,
  DistrictType,
  getActiveDistrictFiltersInCounty,
  getDistrictIds,
  getMunicipalityCodes,
  isDistrictActive,
  isMunicipalityActive,
  MunicipalityType,
} from '@internals/business-shared/src/utils/areasUtils';
import {
  FilteredJobCount,
  getJobsCountForFilter,
  joinFilterWithCount,
} from '@internals/business-shared/src/utils/jobFilters';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { useTranslation } from 'react-i18next';

/*
   Note: municipalities with districts should be skipped in the tree view
   and the child districts should be treated on the same level as other municipalities.
*/

interface AreaFilteringTreeProps {
  jobCount?: FilteredJobCount;
}

export const AreaFilteringTree: FC<AreaFilteringTreeProps> = ({ jobCount }) => {
  const { t } = useTranslation();
  const {
    areas,
    activeMunicipalities,
    activeDistricts,
    activeCounties,
    setActiveDistricts,
    setActiveMunicipalities,
    setActiveCounties,
  } = useJobFiltersContext();

  const toggleCountyFilter = (
    county: CountyWithMunicipalities,
    isActive: boolean
  ) => {
    if (!isActive) {
      setActiveMunicipalities(
        toggleItemInArray(
          activeMunicipalities,
          getMunicipalityCodes(county.municipalities),
          false
        )
      );
      setActiveDistricts(
        toggleItemInArray(
          activeDistricts,
          getDistrictIds(
            getActiveDistrictFiltersInCounty(county, activeDistricts)
          ),
          false
        )
      );
    }
    setActiveCounties(toggleItemInArray(activeCounties, county.id, isActive));
  };

  const toggleMunicipalityFilter = (
    updatedMunicipality: MunicipalityType,
    parentCounty: CountyWithMunicipalities,
    isActive: boolean
  ) => {
    if (isActive) {
      // remove county filter to include more specific municipality one
      setActiveCounties(
        toggleItemInArray(activeCounties, parentCounty.id, false)
      );
    } else {
      // when unselecting last municipality filter from county, replace it back with county filter
      const isLastActiveMunicipalityInCounty =
        parentCounty.municipalities.filter((countyMunicipalities) =>
          activeMunicipalities.includes(countyMunicipalities.code)
        ).length <= 1;
      if (isLastActiveMunicipalityInCounty) {
        setActiveCounties(
          toggleItemInArray(activeCounties, parentCounty.id, true)
        );
      }
    }
    setActiveMunicipalities(
      toggleItemInArray(
        activeMunicipalities,
        updatedMunicipality.code,
        isActive
      )
    );
  };

  const toggleDistrictFilter = (
    updatedDistrict: DistrictType,
    parentCounty: CountyWithMunicipalities,
    isActive: boolean
  ) => {
    if (isActive) {
      // remove county filter to include more specific district one
      setActiveCounties(
        toggleItemInArray(activeCounties, parentCounty.id, false)
      );
    } else {
      // when unselecting last district filter from county, replace it back with county filter
      const isLastActiveDistrictInCounty =
        getActiveDistrictFiltersInCounty(parentCounty, activeDistricts)
          .length <= 1;
      if (isLastActiveDistrictInCounty) {
        setActiveCounties(
          toggleItemInArray(activeCounties, parentCounty.id, true)
        );
      }
    }
    setActiveDistricts(
      toggleItemInArray(activeDistricts, updatedDistrict.id, isActive)
    );
  };

  const getAreaLabel = (area: CountyWithMunicipalities) => {
    const count = getJobsCountForFilter(jobCount, 'countyId', area.id);
    return joinFilterWithCount(area.name, count);
  };

  const getMunicipalityLabel = (municipality: MunicipalityType) => {
    const count = getJobsCountForFilter(
      jobCount,
      'municipalityCode',
      municipality.code.toString()
    );
    return joinFilterWithCount(municipality.name, count);
  };

  const getDistrictLabel = (district: DistrictType) => {
    const count = getJobsCountForFilter(jobCount, 'districtId', district.id);
    return joinFilterWithCount(district.name, count);
  };

  const areaBranches = areas?.map((area) => {
    const isActiveArea =
      activeCounties.includes(area.id) ||
      anyAreaInCountyActive(area, activeDistricts, activeMunicipalities);
    return (
      <TreeBranch
        key={area.id}
        branch={{
          id: area.id,
          label: getAreaLabel(area),
          checked: isActiveArea,
          onChange: () => toggleCountyFilter(area, !isActiveArea),
        }}
        leafs={area.municipalities.flatMap((municipality) => {
          // handling for municipality with districts
          if (municipality.districts?.length) {
            return municipality.districts.map((district) => {
              const isActiveDistrict = isDistrictActive(
                district,
                activeDistricts
              );
              return {
                id: district.id,
                label: getDistrictLabel(district),
                checked: isActiveDistrict,
                onChange: () =>
                  toggleDistrictFilter(district, area, !isActiveDistrict),
              };
            });
          }
          // handling for regular municipality
          const isActiveMunicipality = isMunicipalityActive(
            municipality,
            activeMunicipalities
          );
          return {
            id: municipality.id,
            label: getMunicipalityLabel(municipality),
            checked: isActiveMunicipality,
            onChange: () =>
              toggleMunicipalityFilter(
                municipality,
                area,
                !isActiveMunicipality
              ),
          };
        })}
      />
    );
  });

  return (
    <TreeSection data-tooltip-id={SavedFiltersFeatureTourStep.SelectFilter}>
      <TreeLabel>{t('job.list.filters.area.title')}</TreeLabel>
      {areaBranches}
    </TreeSection>
  );
};
