import gql from 'graphql-tag';

import IMAGE_FRAGMENT from '../../fragments/ImageFragment';
import {
  UPDATE_BUSINESS_ALBUM_IMAGEVariables,
  UPDATE_BUSINESS_ALBUM_IMAGE as UPDATE_BUSINESS_ALBUM_IMAGE_Result,
} from '../../generated/generated';

export type UpdateBusinessAlbumImagePayload =
  UPDATE_BUSINESS_ALBUM_IMAGE_Result;
export type UpdateBusinessAlbumImageVariables =
  UPDATE_BUSINESS_ALBUM_IMAGEVariables;

export const isUpdateBusinessAlbumImageSuccessResponse = (
  response?: UPDATE_BUSINESS_ALBUM_IMAGE_Result | null
): response is UPDATE_BUSINESS_ALBUM_IMAGE_Result => {
  return !!response?.updateBusinessAlbumImage;
};

export const UPDATE_BUSINESS_ALBUM_IMAGE = gql`
  ${IMAGE_FRAGMENT}
  mutation UPDATE_BUSINESS_ALBUM_IMAGE($input: UpdateBusinessAlbumImageInput!) {
    updateBusinessAlbumImage(input: $input) {
      albumImage {
        id
        description
        image {
          ...ImageFragment
        }
      }
    }
  }
`;
