import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/nb';
import 'dayjs/locale/fi';
import 'dayjs/locale/da';

const timeFormat: Record<string, string> = {
  en: 'DD. MMM YYYY',
  fi: 'DD.MM.YYYY',
  nb: 'DD. MMM YYYY',
  da: 'DD. MMM YYYY',
};
export const localizeDateString = (
  language: string,
  dateString: string,
  format?: string
): string => {
  const date = dayjs(dateString);
  const localizedDate = date.locale(language);
  const resultFormat = format ?? timeFormat[language];
  return localizedDate.format(resultFormat);
};

export default localizeDateString;
