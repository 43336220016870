import { EVALUATION_RESPONSE_MAX_LENGTH } from '@internals/business-shared/src/utils/constants/evaluation';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

interface Form {
  text: string;
}

type UseEvaluationResponseFormikType = (
  onSubmit: (evaluationResponse: Form['text']) => void
) => FormikProps<Form>;

export const useEvaluationResponseFormik: UseEvaluationResponseFormikType = (
  onSubmit
) => {
  const { t } = useTranslation();

  const formik: FormikProps<Form> = useFormik({
    initialValues: {
      text: '',
    },
    validationSchema: Yup.object({
      text: Yup.string()
        .trim()
        .required(t('general.form.validation.required'))
        .max(
          EVALUATION_RESPONSE_MAX_LENGTH,
          t('general.form.validation.string.maxLength', {
            value: EVALUATION_RESPONSE_MAX_LENGTH,
          })
        ),
    }),
    onSubmit: (values) => onSubmit(values.text.trim()),
    validateOnMount: true,
  });

  return formik;
};
