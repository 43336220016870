import {
  getParams,
  getPaths,
} from '@internals/business-shared/src/universal-links/paths';
import { getProductId } from '@utils/product';

const product = getProductId();
export const Params = getParams(product);
export const Paths = getPaths(product);

export default Paths;
