import { FC } from 'react';

import { useIsMobile, useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div, Image as FireballImage } from '@schibsted-smb/fireball';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

const INNER_POSITION = 4;
const OUTER_POSITION = -40;

interface StyleProps {
  top?: number;
  right?: number;
}

interface ImageProps {
  src: string;
  width: string;
}

export const Image: FC<ImageProps> = ({ ...rest }) => {
  const { modalState } = useBuySubscriptionModalContext();
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      top: isTabletOrMobile ? INNER_POSITION : OUTER_POSITION,
      right: isMobile ? OUTER_POSITION : INNER_POSITION,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      top: INNER_POSITION,
      right: INNER_POSITION,
    },
    [ModalState.SUMMARY]: {
      top: OUTER_POSITION,
      right: INNER_POSITION,
    },
  };

  return (
    <Div position="absolute" {...styleProps[modalState]}>
      <FireballImage {...rest} />
    </Div>
  );
};
