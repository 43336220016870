import { createStore } from 'zustand/vanilla';

import { createAuthenticationSlice } from '../authentication';
import { createSelectors } from './selectors';
import type { Slices } from './types';

export const store = createStore<Slices>()((...all) => ({
  ...createAuthenticationSlice(...all),
}));

export const useStore = createSelectors(store);
