import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  DELETE_BUSINESS_EMPLOYEE_IMAGE,
  DeleteBusinessEmployeeImageMutationPayload,
  DeleteBusinessEmployeeImageMutationVariables,
} from '../../utils/mutation/DeleteBusinessEmployeeImage/DeleteBusinessEmployeeImageMutation';

type UseDeleteBusinessEmployeeImageMutationAction = (
  input: DeleteBusinessEmployeeImageMutationVariables,
  mutationOptions?: MutationFunctionOptions<
    DeleteBusinessEmployeeImageMutationPayload,
    DeleteBusinessEmployeeImageMutationVariables
  >
) => Promise<FetchResult<DeleteBusinessEmployeeImageMutationPayload>>;

export const useDeleteBusinessEmployeeImageMutation = (): [
  UseDeleteBusinessEmployeeImageMutationAction,
  MutationResult<DeleteBusinessEmployeeImageMutationPayload>
] => {
  const [deleteBusinessEmployeeImage, ...rest] = useMutation<
    DeleteBusinessEmployeeImageMutationPayload,
    DeleteBusinessEmployeeImageMutationVariables
  >(DELETE_BUSINESS_EMPLOYEE_IMAGE);

  const mutationFunction: UseDeleteBusinessEmployeeImageMutationAction =
    useCallback(
      (input, mutationOptions) =>
        deleteBusinessEmployeeImage({
          variables: input,
          ...mutationOptions,
        }),
      [deleteBusinessEmployeeImage]
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
