import { FC, useMemo } from 'react';

import CenteredSpinnerContainer from '@components/base/CenteredSpinnerContainer';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import JobDangerAlert from '@components/layout/Job/JobDangerAlert';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Omit } from '@internals/business-shared/src/utils/Omit';
import {
  Div,
  Text,
  Heading,
  Card,
  Button,
  ButtonProps,
  Spinner,
} from '@schibsted-smb/fireball';

import { ActionATag, ActionLink } from './styled/ActionLink';

interface AlertActionProps {
  text: string;
  testId: string;
  icon?: IconPickerName;
}

type ActionLinkProps = AlertActionProps & {
  type: 'link';
  href: string;
  external: boolean;
};
type ActionButtonProps = AlertActionProps &
  ({ type: 'button' } & Pick<
    ButtonProps,
    'onClick' | 'disabled' | 'isLoading'
  >);

interface JobSmallAlertProps {
  title: string;
  variant: 'danger';
  size: 'small';
  message?: never;
  messageShort?: never;
  action?: never;
  testId?: string;
}

interface JobBigAlertProps {
  title: string;
  message: string | JSX.Element;
  messageShort: string | JSX.Element;
  // 'danger-intense' - intense red alert that matches company state banner
  variant: 'default' | 'danger' | 'warning' | 'danger-intense';
  size: 'big';
  action?: ActionLinkProps | ActionButtonProps;
  testId?: string;
}

export type JobAlertProps = (JobSmallAlertProps | JobBigAlertProps) & {
  isLoading?: boolean;
};

const JobActionButton: FC<Omit<ActionButtonProps, 'type'>> = ({
  onClick,
  disabled,
  isLoading,
  testId = 'job-alert-action',
  icon,
  text,
}) => {
  return (
    <Button
      onClick={onClick}
      data-testid={testId}
      disabled={disabled}
      isLoading={isLoading}
      py={[3, null, null, null, null, null, 5]}
      px={[5, null, null, null, null, null, 8]}
      {...(icon && {
        icon: ({ fill }) => (
          <FireIcon width={16} fill={fill} height={16} iconName={icon} />
        ),
      })}
    >
      {text}
    </Button>
  );
};

const JobActionLink: FC<Omit<ActionLinkProps, 'type'>> = ({
  external,
  href,
  testId = 'job-alert-action',
  icon,
  text,
}) => {
  const children = (
    <Div display="flex" alignItems="center">
      {icon && (
        <FireIcon iconName={icon} fill="currentColor" height={16} width={16} />
      )}
      <Text.span mb={0} ml={icon ? 2 : 0} fontWeight="medium">
        {text}
      </Text.span>
    </Div>
  );
  return external ? (
    <ActionATag href={href} rel="noopener noreferrer" data-testid={testId}>
      {children}
    </ActionATag>
  ) : (
    <ActionLink to={href} data-testid={testId}>
      {children}
    </ActionLink>
  );
};

const getColor = (variant: JobBigAlertProps['variant']) => {
  switch (variant) {
    case 'danger': {
      return { background: 'red.lightest', text: 'black.black9' };
    }
    case 'danger-intense': {
      return { background: 'red.base', text: 'black.black0' };
    }
    case 'warning': {
      return { background: 'yellow.lightest', text: 'black.black9' };
    }
    case 'default':
    default: {
      return { background: 'primary.lightest', text: 'black.black9' };
    }
  }
};

export const JobAlertCard: FC<JobAlertProps> = ({
  title,
  message,
  messageShort,
  action,
  size,
  variant,
  testId,
  isLoading,
}) => {
  const isMobileOrTablet = useIsTabletOrMobile();
  const isDanger = variant === 'danger';
  const colors = getColor(variant);

  const actionElement = useMemo(() => {
    if (!action) return null;
    if (action.type === 'button') {
      const props = isMobileOrTablet
        ? (Omit('icon', action) as ActionButtonProps)
        : action;
      return <JobActionButton {...props} />;
    }
    const linkProps = isMobileOrTablet
      ? (Omit('icon', action) as ActionLinkProps)
      : action;
    return <JobActionLink {...linkProps} />;
  }, [action, isMobileOrTablet]);

  if (isDanger && size === 'small') {
    return <JobDangerAlert text={title} />;
  }

  return (
    <Card
      p={[5, null, null, null, null, null, 8]}
      backgroundColor={colors.background}
      color={colors.text}
      textAlign="center"
      data-testid={testId ?? (isDanger ? 'alert-job-danger' : 'job-alert')}
      boxShadow="none"
      borderRadius={1}
    >
      {isLoading ? (
        <CenteredSpinnerContainer height={160}>
          <Spinner size={6} />
        </CenteredSpinnerContainer>
      ) : (
        <>
          {!isMobileOrTablet && <Heading.h1 mb={5}>{title}</Heading.h1>}
          <Text.p
            textAlign="center"
            fontSize={[2, null, null, null, null, null, 3]}
            mb={[5, null, null, null, null, null, 6]}
          >
            {isMobileOrTablet ? messageShort : message}
          </Text.p>
          {action && (
            <Div display="flex" justifyContent="center" flexDirection="row">
              {actionElement}
            </Div>
          )}
        </>
      )}
    </Card>
  );
};

export default JobAlertCard;
