import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const BadgeAvatarContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  position: relative;
`;

export const BadgeAvatarIconContainer = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  background-color: ${({ theme }) => theme.colors.black.black0};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: auto;
  top: auto;
  right: 5px;
  bottom: -2px;
  z-index: ${Layer.base + Layer.above};
`;
