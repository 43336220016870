import { FC } from 'react';

import { JobInfoAccordion } from '@components/elements/JobInfoAccordion';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';

export const BankIdVerified: FC = () => {
  const { t } = useTranslation();

  return (
    <JobInfoAccordion
      title={t('job.item.owner.bankIdVerified.title')}
      description={t('job.item.owner.bankIdVerified.text')}
      iconProps={{ iconName: IconPickerName.ShieldCheck }}
    />
  );
};
