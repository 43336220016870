import gql from 'graphql-tag';

import {
  DELETE_BUSINESS_ALBUM_IMAGEVariables,
  DELETE_BUSINESS_ALBUM_IMAGE as DELETE_BUSINESS_ALBUM_IMAGE_Result,
} from '../../generated/generated';

export type DeleteBusinessAlbumImagePayload =
  DELETE_BUSINESS_ALBUM_IMAGE_Result;
export type DeleteBusinessAlbumImageVariables =
  DELETE_BUSINESS_ALBUM_IMAGEVariables;

export const DELETE_BUSINESS_ALBUM_IMAGE = gql`
  mutation DELETE_BUSINESS_ALBUM_IMAGE($input: DeleteBusinessAlbumImageInput!) {
    deleteBusinessAlbumImage(input: $input) {
      success
    }
  }
`;
