import { FC } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { WorkTypeGroup } from '@internals/business-shared/src/utils/workTypesUtils';
import { useTranslation } from 'react-i18next';

interface WorkTypeViewProps {
  onBackClick: VoidFunction;
  onWorkTypeGroupExpand: (workTypes: WorkTypeGroup) => void;
}

const WorkTypeView: FC<React.PropsWithChildren<WorkTypeViewProps>> = ({
  onBackClick,
  onWorkTypeGroupExpand,
}) => {
  const { t } = useTranslation();
  const { workTypes, activeWorkTypes, setActiveWorkTypes } =
    useJobFiltersContext();

  const getSelectedWorkTypesInGroup = (workTypeGroup: WorkTypeGroup) =>
    workTypeGroup.workTypes.filter((workType) =>
      activeWorkTypes.includes(workType.id)
    );

  const unselectAllWorkTypesInGroup = (workTypeGroup: WorkTypeGroup) => {
    const updatedWorkTypes = activeWorkTypes.filter((activeWorkType) =>
      workTypeGroup.workTypes.every(
        (workType) => activeWorkType !== workType.id
      )
    );
    setActiveWorkTypes(updatedWorkTypes);
  };

  const onSelectedClick = (
    workTypeGroup: WorkTypeGroup,
    workTypesFilterId: string | string[]
  ) => {
    if (typeof workTypesFilterId === 'string') {
      setActiveWorkTypes(toggleItemInArray(activeWorkTypes, workTypesFilterId));
      return;
    }
    unselectAllWorkTypesInGroup(workTypeGroup);
  };

  return (
    <FilterView
      title={t('job.list.filters.workType.title')}
      onBackClick={onBackClick}
    >
      <FilterGrid>
        {workTypes.map((workTypeGroup) => (
          <FilterGroupButton
            key={`${workTypeGroup.id}-${workTypeGroup.name}`}
            filterName={workTypeGroup.name}
            onClick={() => onWorkTypeGroupExpand(workTypeGroup)}
            selectedFilters={getSelectedWorkTypesInGroup(workTypeGroup)}
            onSelectedClick={(workTypesFilterId) =>
              onSelectedClick(workTypeGroup, workTypesFilterId)
            }
          />
        ))}
      </FilterGrid>
    </FilterView>
  );
};

export default WorkTypeView;
