import { useState } from 'react';

import { useMutation } from '@apollo/client';
import { MutationHookOptions } from '@apollo/client/react/types/types';
import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import {
  DELETE_BUSINESS_LOGO_MUTATION,
  DeleteBusinessLogoMutationPayload,
} from '@internals/business-shared/src/utils/mutation/DeleteBusinessLogo/DeleteBusinessLogo';
import { CURRENT_USER_COMPANY_QUERY } from '@internals/business-shared/src/utils/query/CurrentUserCompany/CurrentUserCompanyQuery';
import { useTranslation } from 'react-i18next';

type DeleteBusinessLogoMutationErrorsResult = [
  errors: GQLErrorState,
  errorCheckFn: (payload: DeleteBusinessLogoMutationPayload) => boolean
];

export const useDeleteProfileLogo = (
  options?: MutationHookOptions<DeleteBusinessLogoMutationPayload>
) => {
  const [deleteBusinessLogo, { loading, error }] =
    useMutation<DeleteBusinessLogoMutationPayload>(
      DELETE_BUSINESS_LOGO_MUTATION,
      {
        refetchQueries: [CURRENT_USER_COMPANY_QUERY],
        ...options,
      }
    );

  return { loading, error, deleteLogo: deleteBusinessLogo };
};

export const useDeleteProfileLogoErrors =
  (): DeleteBusinessLogoMutationErrorsResult => {
    const [deleteProfileLogoErrors, setDeleteProfileLogoErrors] =
      useState<GQLErrorState>({});
    const { t } = useTranslation();
    const checkForErrors = (payload: DeleteBusinessLogoMutationPayload) => {
      const hasErrors = payload?.deleteBusinessLogo !== true;
      if (hasErrors) {
        setDeleteProfileLogoErrors({
          general: {
            msg: t('profile.graphic.logo.remove.error'),
            variant: 'danger',
          },
        });
      } else {
        setDeleteProfileLogoErrors({});
      }
      return hasErrors;
    };
    return [deleteProfileLogoErrors, checkForErrors];
  };
