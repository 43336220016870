import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const ProductAlreadySubscribedBadge: FC = () => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  return (
    <Div
      backgroundColor="#ECF4EE"
      border={`1px solid ${themeContext.colors.black.black4}`}
      borderRadius={3}
      px={6}
      py={7}
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={7}
    >
      <Div mb={5}>
        <FireIcon
          iconName={IconPickerName.CheckmarkCircled}
          width={32}
          height={32}
          fill="#30CA97"
        />
      </Div>
      <Heading.h4 fontWeight="medium" mb={0}>
        {t('product.alreadySubscribed')}
      </Heading.h4>
    </Div>
  );
};
