import { FC } from 'react';

import { SizeType } from '@contexts/JobFiltersContext';
import { Checkbox } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

export interface TreeLeafProps {
  id: string;
  label: string;
  checked: boolean;
  onChange: (id: SizeType | string | unknown) => void;
}

export const TreeLeaf: FC<TreeLeafProps> = ({
  id,
  label,
  checked,
  onChange,
}) => {
  const themeContext = useTheme();

  return (
    <Checkbox
      id={id}
      key={id}
      name={label}
      label={label}
      checked={checked}
      onChange={() => onChange(id)}
      labelFontSize={themeContext.fontSizes[2]}
    />
  );
};
