import { FC } from 'react';

import JobView from '@components/layout/Job';
import { Params } from '@router/paths';
import { useLocation, useNavigate } from 'react-router-dom';

const Job: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const stringId = params.get(Params.Job) ?? null;
  const extractedJobId = stringId?.split('/')[0];
  const onClose = (): void => {
    params.delete(Params.Job);
    navigate({ search: `?${params.toString()}` });
  };

  return (
    <>{extractedJobId && <JobView id={extractedJobId} onClose={onClose} />}</>
  );
};

export default Job;
