import { FC } from 'react';

import { MittanbudXL } from '@components/layout/Products/Addons/MittanbudXL';
import { Spotlight } from '@components/layout/Products/Addons/Spotlight';
import { isHandledAddonPage } from '@internals/business-shared/src/components/Products/productUtils';
import { Navigate, useParams } from 'react-router-dom';

export const ProductAddon: FC = () => {
  const params = useParams();
  const { slug } = params;
  const isHandled = isHandledAddonPage(slug);

  if (!isHandled) {
    return <Navigate to="/404" />;
  }

  switch (slug) {
    case 'spotlight':
      return <Spotlight />;
    case 'mittanbud-xl':
      return <MittanbudXL />;
    default:
      return <div>{slug} placeholder</div>;
  }
};
