import gql from 'graphql-tag';

const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    bigUrl
    bigPreviewUrl
    description
    dimensions {
      width
      height
    }
    id
    mediumPreviewUrl
    mediumUrl
    name
    size
    smallPreviewUrl
    smallUrl
    url
    fileType
  }
`;

export default IMAGE_FRAGMENT;
