import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const JobsListContentLoader: React.FC<React.PropsWithChildren<LoaderProps>> = ({
  uniqueKey,
}) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 820 121"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    <rect x="50" y="25" rx="4" ry="4" width="300" height="20" />
    <rect x="750" y="25" rx="4" ry="4" width="50" height="15" />
    <rect x="50" y="55" rx="4" ry="4" width="150" height="15" />
    <rect x="780" y="50" rx="4" ry="4" width="20" height="15" />
    <rect x="50" y="80" rx="4" ry="4" width="500" height="15" />
    <rect x="0" y="120" rx="4" ry="4" width="820" height="2" />
  </ContentLoader>
);

export default JobsListContentLoader;
