import styled from 'styled-components';

interface StyledDetailsProps {
  backgroundColor?: string;
  space?: number;
  border?: string;
}

export const SummaryToggleIconClassName = 'styled-summary-toggle-icon';

export const StyledDetails = styled.details<StyledDetailsProps>`
  display: block;
  text-align: justify;
  background-color: ${(props) =>
    props.backgroundColor ?? props.theme.colors.black.black0};
  padding: ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[5]}px;
  border-radius: ${(props) => props.theme.radii[2]};

  &[open] .${SummaryToggleIconClassName} {
    transform: rotate(180deg);
  }
  margin-bottom: ${(props) => props.space ?? 0}px;
  margin-top: ${(props) => props.space ?? 0}px;
  border: ${(props) => props.border ?? 'none'};
`;

export const StyledSummary = styled.summary.attrs({
  className: 'styled-summary',
})`
  display: block;
  cursor: pointer;
  margin: 0;
  padding: ${(props) => props.theme.space[0]}px;

  &::-webkit-details-marker,
  &::marker {
    display: none;
  }
`;
