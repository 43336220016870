import gql from 'graphql-tag';

import {
  UPDATE_BUSINESS_PROFILE_INFOVariables,
  UPDATE_BUSINESS_PROFILE_INFO as UPDATE_BUSINESS_PROFILE_INFO_Result,
  UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_monday,
} from '../../generated/generated';

export type UpdateBusinessProfileInfoPayload =
  UPDATE_BUSINESS_PROFILE_INFO_Result;
export type UpdateBusinessProfileInfoVariables =
  UPDATE_BUSINESS_PROFILE_INFOVariables;
export type BusinessOpeningHours =
  UPDATE_BUSINESS_PROFILE_INFO_updateBusinessProfileInfo_Business_openingHours_monday;

export const UPDATE_BUSINESS_PROFILE_INFO = gql`
  mutation UPDATE_BUSINESS_PROFILE_INFO(
    $input: UpdateBusinessProfileInfoInput!
  ) {
    updateBusinessProfileInfo(input: $input) {
      ... on Business {
        addresses {
          id
          visit {
            id
            street
            zip
          }
        }
        profileDescription
        id
        phone
        socialMedia {
          id
          website
          facebook
          instagram
          youtube
          tiktok
        }
        openingHours {
          monday {
            from
            to
          }
          tuesday {
            from
            to
          }
          wednesday {
            from
            to
          }
          thursday {
            from
            to
          }
          friday {
            from
            to
          }
          saturday {
            from
            to
          }
          sunday {
            from
            to
          }
        }
      }
      ... on UpdateBusinessProfileInfoInputError {
        message
        businessIdError: businessId {
          message
        }
        phoneError: phone {
          message
        }
        addressError: address {
          message
          street {
            message
          }
          zip {
            message
          }
        }
        websiteError: website {
          message
        }
        facebookError: facebook {
          message
        }
        instagramError: instagram {
          message
        }
        youtubeError: youtube {
          message
        }
        tiktokError: tiktok {
          message
        }
        titleError: title {
          message
        }
        descriptionError: description {
          message
        }
        openingHoursError: openingHours {
          message
          monday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          tuesday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          wednesday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          thursday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          friday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          saturday {
            message
            from {
              message
            }
            to {
              message
            }
          }
          sunday {
            message
            from {
              message
            }
            to {
              message
            }
          }
        }
      }
    }
  }
`;
