import { t } from '@internals/business-translations/src/i18n';

import { DISCARDED_GENERICS } from '../constants/jobDiscardedGenerics';
import { JOB_QUERY_jobMeta } from '../generated/generated';

interface GenericMetadataItem {
  title: string;
  text: string;
}

const handleGenerics = (
  slug: string,
  value: string,
  onMissingTypeHandling: (unhandledType: string) => void
): GenericMetadataItem | null => {
  switch (slug) {
    case 'cleaning-frequency': {
      return {
        title: t('job.item.meta.cleaningFrequency.title'),
        /*
          t('job.item.meta.cleaningFrequency.value_once')
          t('job.item.meta.cleaningFrequency.value_other')
          t('job.item.meta.cleaningFrequency.value_quadweekly')
          t('job.item.meta.cleaningFrequency.value_triweekly')
          t('job.item.meta.cleaningFrequency.value_weekly')
          t('job.item.meta.cleaningFrequency.value_biweekly')
        */
        text: t('job.item.meta.cleaningFrequency.value', {
          context: value,
          defaultValue: '-',
        }),
      };
    }
    case 'accommodation-size': {
      return {
        title: t('job.item.meta.accommodationSize.title'),
        text: t('general.metrics.inSquareMeters', { value }),
      };
    }
    case 'container-size': {
      return {
        title: t('job.item.meta.containerSize.title'),
        text: value ? t('general.metrics.inCubicMeters', { value }) : '-',
      };
    }
    case 'vehicle-mileage': {
      return {
        title: t('job.item.meta.vehicleMileage.title'),
        text: value || '-',
      };
    }
    case 'transmission-type': {
      return {
        title: t('job.item.meta.transmissionType.title'),
        /*
          t('job.item.meta.transmissionType.value_Automat')
          t('job.item.meta.transmissionType.value_Manuell')
        */
        text: t('job.item.meta.transmissionType.value', {
          context: value,
          defaultValue: '-',
        }),
      };
    }
    case 'car-rental': {
      return {
        title: t('job.item.meta.carRental.title'),
        /*
          t('job.item.meta.carRental.value_Ja')
          t('job.item.meta.carRental.value_Nei')
        */
        text: t('job.item.meta.carRental.value', {
          context: value,
          defaultValue: '-',
        }),
      };
    }
    case 'move-out-cleaning': {
      return {
        title: t('job.item.meta.moveOutCleaning.title'),
        /*
        value is a stringified boolean
          t('job.item.meta.moveOutCleaning.value_true')
          t('job.item.meta.moveOutCleaning.value_false')
        */
        text: t('job.item.meta.moveOutCleaning.value', {
          context: value,
          defaultValue: '-',
        }),
      };
    }
    default:
      onMissingTypeHandling(slug);
      return null;
  }
};

export const getGenericsMetadata = (
  meta: JOB_QUERY_jobMeta['generics'],
  onMissingTypeHandling?: (unhandledGenerics: string[]) => void
): GenericMetadataItem[] => {
  const unhandled: string[] = [];
  const metadata: GenericMetadataItem[] = [];
  const addSlugToUnhandledList = (unhandledSlug: string) => {
    unhandled.push(unhandledSlug);
  };
  meta.forEach((generic) => {
    if (DISCARDED_GENERICS.includes(generic.slug)) {
      return;
    }
    const genericsItem = handleGenerics(
      generic.slug,
      generic.value,
      addSlugToUnhandledList
    );
    if (genericsItem) {
      metadata.push(genericsItem);
    }
  });
  if (unhandled.length) {
    onMissingTypeHandling?.(unhandled);
  }
  return metadata;
};
