import { useEffect, FC, useState } from 'react';

import { isEmptyString } from '@internals/business-shared/src/utils/types/StringUtils';
import { Input, InputError, InputProps } from '@schibsted-smb/fireball';
import InputType from '@utils/constants/inputTypes';
import { t } from '@utils/i18n';
import { getProduct } from '@utils/product';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js/core';
import { ValidatePhoneNumberLengthResult } from 'libphonenumber-js/types';

interface PhoneInputProps extends InputProps {
  onError?: (error: InputError) => void;
  onFocusOut?: ({
    value,
    isError,
  }: {
    value: string;
    isError: boolean;
  }) => void;
}

const { phoneFormat, phoneMetaData } = getProduct();

type InvalidPhoneFormatType =
  | ValidatePhoneNumberLengthResult
  | 'EMPTY'
  | 'GENERAL';

const validatePhoneNumber = (
  value?: string
): InvalidPhoneFormatType | undefined => {
  const isEmpty = isEmptyString(value?.trim());
  if (isEmpty) {
    return 'EMPTY';
  }
  try {
    const phoneNumber = parsePhoneNumber(value, phoneFormat, phoneMetaData);
    // recognize invalid phone number for country based on particular digit set
    const isValid = phoneNumber.isValid();
    return isValid ? undefined : 'GENERAL';
  } catch (err) {
    // recognize parse errors like wrong country code, wrong length etc
    return err.message;
  }
};

const getInvalidInputErrorMessage = (message: InvalidPhoneFormatType) => {
  switch (message) {
    case 'EMPTY':
      return t('general.form.validation.required');

    case 'INVALID_COUNTRY':
      return t('general.form.validation.phone.countryCode');

    case 'TOO_LONG':
      return t('general.form.validation.phone.tooLong');

    case 'TOO_SHORT':
      return t('general.form.validation.phone.tooShort');

    case 'GENERAL':
    case 'NOT_A_NUMBER':
    default:
      return t('general.form.validation.phone.invalidFormat');
  }
};

const PhoneInput: FC<React.PropsWithChildren<PhoneInputProps>> = ({
  error: customError,
  onError,
  onFocusOut,
  value = '',
  required = true,
  ...props
}) => {
  const [error, setError] = useState<InputError>(customError);
  // prevent from typing invalid characters and format phone
  const formattedValue = new AsYouType(phoneFormat, phoneMetaData).input(value);

  const handleError = (errorType: InvalidPhoneFormatType | undefined) => {
    const err: InputError = errorType
      ? { msg: getInvalidInputErrorMessage(errorType), variant: 'danger' }
      : undefined;
    setError(err);
    if (onError) {
      onError(err);
    }
  };

  const handleFocusOut = () => {
    const validationError = validatePhoneNumber(formattedValue);
    if (!required && validationError === 'EMPTY') return;
    handleError(validationError);
    if (!onFocusOut) return;
    if (validationError) {
      onFocusOut({ value: formattedValue, isError: true });
      return;
    }
    // add country code if not provided by user
    const phoneNumber = parsePhoneNumber(
      formattedValue,
      phoneFormat,
      phoneMetaData
    );
    onFocusOut({
      value: phoneNumber.formatInternational(),
      isError: false,
    });
  };

  useEffect(() => {
    setError(customError);
  }, [customError]);

  return (
    <Input
      value={formattedValue}
      error={error}
      type={InputType.Tel}
      required={required}
      {...props}
      onBlur={handleFocusOut}
    />
  );
};

export default PhoneInput;
