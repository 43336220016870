import { useState } from 'react';

import { useDisplayOnce } from '@hooks/useDisplayOnce';
import { STORE_SAVED_FILTER_INFO_PREFIX } from '@hooks/useStorage';
import { createJobListFilterNavId } from '@internals/business-shared/src/components/SavedFilters/utils';

interface DisplayTooltip {
  tooltipId: string | undefined;
  isTooltipDisplayed: boolean;
  displayTooltip: ({ id }: { id: string }) => void;
  onTooltipClose: () => void;
}

export const useDisplayTooltip = (): DisplayTooltip => {
  const [tooltipId, setTooltipId] = useState<string | undefined>();
  const { display, hide, isDisplayed } = useDisplayOnce(
    'tooltip',
    STORE_SAVED_FILTER_INFO_PREFIX
  );
  const displayTooltip = ({ id }: { id: string }) => {
    const tooltipAnchorId = createJobListFilterNavId(id, 'saved');
    setTooltipId(tooltipAnchorId);
    display();
  };
  const onTooltipClose = () => {
    setTooltipId(undefined);
    hide();
  };
  return {
    tooltipId,
    isTooltipDisplayed: isDisplayed,
    displayTooltip,
    onTooltipClose,
  };
};
