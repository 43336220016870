import { FC } from 'react';

import { Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

const CUSTOM_BLUE_COLOR = '#6080CC';

export const MostPopularLabel: FC = () => {
  const { modalState } = useBuySubscriptionModalContext();
  const { t } = useTranslation();

  if (
    modalState !== ModalState.SELECT_PACKAGE &&
    modalState !== ModalState.CONFIRM_PURCHASE
  ) {
    return null;
  }

  return (
    <Div
      bg={CUSTOM_BLUE_COLOR}
      position="absolute"
      px={5}
      py={2}
      right={0}
      bottom={0}
    >
      <Text.p mb={0} color="black.black0">
        {t('job.modal.subscription.packageCard.mostPopular')}
      </Text.p>
    </Div>
  );
};
