import gql from 'graphql-tag';

import {
  JOB_TRAITS_QUERYVariables,
  JOB_TRAITS_QUERY as JOB_TRAITS_QUERY_Result,
  JOB_TRAITS_QUERY_jobTraits,
} from '../../generated/generated';

export type JobTraitsQueryVariables = JOB_TRAITS_QUERYVariables;
export type JobTraitsQueryPayload = JOB_TRAITS_QUERY_Result;
export type JobTraitsQueryData = JOB_TRAITS_QUERY_jobTraits;

export const JOB_TRAITS_QUERY = gql`
  query JOB_TRAITS_QUERY($jobId: ID!) {
    jobTraits(jobId: $jobId) {
      id
      big
      ended
      estimatedSquareMeters
      fromVerifiedUser
      questionsRegForm {
        type
        question
        answer
      }
    }
  }
`;
