import { FC } from 'react';

import { Button, Div, Heading, Modal, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface UnsavedChangesModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onSave: VoidFunction;
  onBackButton: VoidFunction;
}

export const UnsavedChangesModal: FC<UnsavedChangesModalProps> = ({
  onSave,
  onClose,
  onBackButton,
  isOpen,
  isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      size="custom"
      maxWidth="500px"
      onClose={onClose}
      isClosable
      isVerticallyCentered
    >
      <Div m={5} data-testid="unsaved-changes-modal">
        <Heading.h2 mb={3}>{t('general.form.unsavedChanges.title')}</Heading.h2>
        <Text.p mb={7} textAlign="center">
          {t('general.form.unsavedChanges.description')}
        </Text.p>
        <Div display="flex">
          <Button
            variant="danger"
            width="100%"
            mr={5}
            disabled={isLoading}
            onClick={onBackButton}
          >
            {t('general.label.discard')}
          </Button>
          <Button
            variant="primary"
            width="100%"
            isLoading={isLoading}
            onClick={onSave}
          >
            {t('general.label.save')}
          </Button>
        </Div>
      </Div>
    </Modal>
  );
};
