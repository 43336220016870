import { FC, useCallback } from 'react';

import { DateInput } from '@components/base/DateInput';
import PhoneInput from '@components/base/PhoneInput';
import { HUNDRED_MILLION } from '@components/elements/CustomerServiceContract/ContractValidationSchema';
import { Currency } from '@components/elements/CustomerServiceContract/styled/Currency';
import { FormLabel } from '@components/elements/CustomerServiceContract/styled/FormLabel';
import { FormRow } from '@components/elements/CustomerServiceContract/styled/FormRow';
import { useContractTypeRadioOptions } from '@components/elements/CustomerServiceContract/useContractTypeRadioOptions';
import { useEditSimpleContractFormik } from '@components/elements/CustomerServiceContract/useEditSimpleContractFormik';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import useAnalytics from '@hooks/useAnalytics';
import { useEditSimpleAgreementContractMutation } from '@internals/business-shared/src/hooks/mutation/useEditSimpleAgreementContractMutation';
import { EditContractFormShape } from '@internals/business-shared/src/types/FormShape/EditContract';
import { EditSimpleAgreementContractAdapter } from '@internals/business-shared/src/utils/adapters/EditContract';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { SimpleAgreementPriceType } from '@internals/business-shared/src/utils/generated/generated';
import { isEditSimpleAgreementContractMutationSuccessResponse } from '@internals/business-shared/src/utils/mutation/EditSimpleAgreementContract/EditSimpleAgreementContract';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import {
  Button,
  Column,
  Container,
  Heading,
  Input,
  Modal,
  RadioGroup,
  Row,
  Textarea,
} from '@schibsted-smb/fireball';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import ToastMessage from '@utils/ToastMessage';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';

interface EditSimpleContractModalProps {
  show: boolean;
  onClose: VoidFunction;
  contract: SimpleContract;
}

export const EditSimpleContractModal: FC<EditSimpleContractModalProps> = ({
  show,
  onClose,
  contract,
}) => {
  const { t } = useTranslation();
  const { getRadioGroupOptions } = useContractTypeRadioOptions();
  const [editSimpleAgreementContract] =
    useEditSimpleAgreementContractMutation();
  const { currency } = getProduct();
  const { track } = useAnalytics();
  const isMobile = useIsMobile();

  const onFormikSubmit = useCallback(
    async (
      fromValues: EditContractFormShape,
      resetForm: FormikHelpers<EditContractFormShape>['resetForm']
    ) => {
      try {
        const response = await editSimpleAgreementContract(
          EditSimpleAgreementContractAdapter(fromValues, contract.job.id)
        );

        if (
          isEditSimpleAgreementContractMutationSuccessResponse(
            response?.data?.editSimpleAgreementContract
          )
        ) {
          track(
            ANEventSpace(ANEvent.Edited, ANObject.Agreement, ANPage.Customers),
            {
              priceType: fromValues.priceType,
              price: fromValues.price,
            }
          );

          ToastMessage(
            t('contract.item.simpleContract.editSuccess'),
            'success'
          );
          resetForm();
          onClose();
        } else {
          ToastMessage(t('contract.item.simpleContract.editFailed'), 'danger');
          bugsnagClient.notify(
            'Edit simple agreement contract failed due to mutation unknown response type'
          );
        }
      } catch (error) {
        ToastMessage(t('contract.item.simpleContract.editError'), 'danger');
        bugsnagClient.notify(error);
      }
    },
    [contract?.job?.id, editSimpleAgreementContract, onClose, t, track]
  );

  const formik = useEditSimpleContractFormik(contract, onFormikSubmit);

  const priceOptions = getRadioGroupOptions(
    formik.values.priceType,
    formik.handleChange
  );

  const onModalClose = useCallback(() => {
    if (formik.isSubmitting) return;
    track(ANEventSpace(ANEvent.Closed, ANObject.Agreement, ANPage.Customers));
    onClose();
  }, [formik.isSubmitting, track, onClose]);

  if (!show) return null;

  return (
    <Modal
      isOpen
      isClosable
      onClose={onModalClose}
      shouldCloseOnOverlayClick
      header={
        <Heading.h3>
          {t('contract.item.simpleContract.editInformation')}
        </Heading.h3>
      }
    >
      <Container>
        <FormRow>
          <Column width={[1]}>
            <RadioGroup name="priceType" options={priceOptions} perLine={3} />
          </Column>
        </FormRow>
        {formik.values.priceType !== SimpleAgreementPriceType.OTHER && (
          <FormRow>
            <Column width={[1]}>
              {formik.values.priceType === SimpleAgreementPriceType.FIXED && (
                <FormLabel>
                  {t('contract.item.simpleContract.price.fixed')}{' '}
                  {t('general.label.includingVat')}
                </FormLabel>
              )}
              {formik.values.priceType ===
                SimpleAgreementPriceType.HOUR_COMPUTATION && (
                <FormLabel>
                  {t('contract.item.simpleContract.price.hourlyRate')}{' '}
                  {t('general.label.includingVat')}
                </FormLabel>
              )}
              <Input
                {...formik.getFieldProps('price')}
                inputMode="numeric"
                type="number"
                unlabeled
                min="0"
                max={HUNDRED_MILLION.toString()}
                maxLength={9}
                error={
                  formik.touched.price && formik.errors.price
                    ? { msg: formik.errors.price, variant: 'danger' }
                    : null
                }
                trailing={<Currency>{currency}</Currency>}
              />
            </Column>
          </FormRow>
        )}
        <FormRow>
          <Column width={isMobile ? 1 : [1 / 2]}>
            <FormLabel>{t('general.dateTime.startDate')}</FormLabel>
            <DateInput
              name="start"
              value={formik.values.start}
              onChange={(d) => {
                formik.setFieldTouched('start', true, true);
                formik.setFieldValue('start', d);
              }}
              error={
                formik.touched.start && formik.errors.start
                  ? String(formik.errors.start)
                  : null
              }
              clearError={() => formik.setFieldError('start', '')}
              validate={() => formik.validateField('start')}
            />
          </Column>
          <Column width={isMobile ? 1 : [1 / 2]}>
            <FormLabel>{t('general.dateTime.endDate')}</FormLabel>
            <DateInput
              name="end"
              value={formik.values.end}
              onChange={(d) => {
                formik.setFieldTouched('end', true, true);
                formik.setFieldValue('end', d);
              }}
              error={
                formik.touched.end && formik.errors.end
                  ? String(formik.errors.end)
                  : null
              }
              clearError={() => formik.setFieldError('end', '')}
              validate={() => formik.validateField('end')}
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={isMobile ? 1 : [1 / 2]}>
            <FormLabel>{t('general.label.companyEmail')}</FormLabel>
            <Input
              {...formik.getFieldProps('businessEmail')}
              inputMode="email"
              type="email"
              unlabeled
              error={
                formik.touched.businessEmail && formik.errors.businessEmail
                  ? {
                      msg: formik.errors.businessEmail,
                      variant: 'danger',
                    }
                  : null
              }
            />
          </Column>
          <Column width={isMobile ? 1 : [1 / 2]}>
            <FormLabel>{t('general.label.companyPhone')}</FormLabel>
            <PhoneInput
              {...formik.getFieldProps('businessPhone')}
              required
              unlabeled
              error={
                formik.touched.businessPhone && formik.errors.businessPhone
                  ? {
                      msg: formik.errors.businessPhone,
                      variant: 'danger',
                    }
                  : null
              }
              onFocusOut={({ value, isError }) => {
                formik.setFieldTouched('businessPhone', true, true);
                formik.setFieldValue('businessPhone', value ?? '');
                formik.setFieldError(
                  'businessPhone',
                  isError
                    ? t('general.form.validation.phone.invalidFormat')
                    : ''
                );
              }}
            />
          </Column>
        </FormRow>
        <FormRow>
          <Column width={[1]}>
            <FormLabel>
              {t('contract.item.simpleContract.addDescription')}
            </FormLabel>
            <Textarea
              label={t('general.label.description')}
              height="150px"
              error={
                formik.touched.description && formik.errors.description
                  ? {
                      msg: String(formik.errors.description),
                      variant: 'danger',
                    }
                  : null
              }
              {...formik.getFieldProps('description')}
            />
          </Column>
        </FormRow>
        <Row justifyContent="flex-end">
          <Column
            width={[1 / 2]}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
          >
            <Button
              type="reset"
              variant="linkPrimary"
              onClick={onModalClose}
              disabled={formik.isSubmitting}
            >
              {t('general.label.cancel')}
            </Button>
            <Button
              type="submit"
              variant="primary"
              isLoading={formik.isSubmitting}
              onClick={formik.submitForm}
            >
              {t('contract.item.simpleContract.editContract')}
            </Button>
          </Column>
        </Row>
      </Container>
    </Modal>
  );
};
