import styled from 'styled-components';

export const SampleImagesGalleryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space[5]}px;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
