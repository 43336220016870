import { FC, useCallback, useMemo } from 'react';

import { StyledNotificationToggleContainer } from '@components/layout/Settings/NotificationsV2/styled';
import SettingsLoading from '@components/layout/Settings/SettingsLoading';
import useAnalytics from '@hooks/useAnalytics';
import { useSetReceiveNewslettersMutation } from '@internals/business-shared/src/hooks/mutation/useSetReceiveNewslettersMutation';
import { useGetUserSettingsQuery } from '@internals/business-shared/src/hooks/query/useGetUserSettingsQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div, Heading, Text, Toggle } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';

export const NewsletterSettings: FC = () => {
  const { t } = useTranslation();
  const { name: productName } = getProduct();
  const { track } = useAnalytics();

  const { data, loading } = useGetUserSettingsQuery();
  const newsletterEnabled = useMemo(() => !!data?.ReceiveNewsletters, [data]);

  const [setReceiveNewsletters] = useSetReceiveNewslettersMutation();

  const onReceiveNewslettersChange = useCallback(() => {
    setReceiveNewsletters(!newsletterEnabled);
    track(
      ANEventSpace(ANEvent.Changed, ANObject.Notifications, ANPage.Settings),
      {
        toggle: 'newsletter',
        state: !newsletterEnabled,
      }
    );
  }, [setReceiveNewsletters, track]);

  if (loading && !data) return <SettingsLoading height="80px" />;

  return (
    <Div>
      <Div display="flex" justifyContent="space-between">
        <Div mr={8}>
          <Heading.h3 mb={4} data-testid="section-title-newsletter">
            {t('settings.notifications.newsletter.title')}
          </Heading.h3>
        </Div>
        <StyledNotificationToggleContainer>
          <Toggle
            name="newsletter"
            checked={newsletterEnabled}
            onChange={onReceiveNewslettersChange}
            testId="newsletter"
          />
        </StyledNotificationToggleContainer>
      </Div>
      <Text.p mb={0} color="black.black7" lineHeight={1.5}>
        {t('settings.notifications.newsletter.info', { productName })}
      </Text.p>
    </Div>
  );
};
