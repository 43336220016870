import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterItemWrapper = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.space[3]}px 0`};
  min-width: 0;
  height: 64px;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    height: ${({ theme }) => `${theme.space[8]}px`};
  }
`;

export default FilterItemWrapper;
