import { Column } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const JobsFilteringTreeWrapper = styled(Column)`
  flex: 1;
  padding-left: ${(props) => props.theme.space[6]}px;
  margin-top: ${(props) => props.theme.space[5]}px;

  @media only screen and (min-width: ${(props) => props.theme.breakpoints[6]}) {
    padding-left: ${(props) => props.theme.space[4]}px;
  }

  @media print {
    display: none;
  }
`;
