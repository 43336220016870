import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import MapPreviewModal from '@components/elements/MapPreviewModal';
import useIsOverflown from '@hooks/useIsOverflown';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Job } from '@internals/business-shared/src/utils/query/Job/JobQuery';
import { Div, Heading, Button, ButtonLink } from '@schibsted-smb/fireball';
import { getAddressForMaps } from '@utils/address';
import getJobItemSearchParam from '@utils/url';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { GoToJobLink, DescriptionWrapper } from './styled';

type JobAddress = Pick<Job['address'], 'city' | 'zip' | 'street'>;

interface JobShortInfoProps {
  job: Pick<Job, 'id' | 'title' | 'description'> & { address: JobAddress };
  onJobModalOpen?: () => void;
}

const JobShortInfo: React.FC<React.PropsWithChildren<JobShortInfoProps>> = ({
  job,
  onJobModalOpen,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const [isDescriptionCollapsed, setIsDescriptionCollapsed] =
    React.useState(false);
  const [isTextOverflown, setIsTextOverflown] = React.useState(false);
  const descriptionWrapperRef: any = React.useRef(null);

  React.useEffect(() => {
    if (descriptionWrapperRef.current) {
      setIsTextOverflown(useIsOverflown(descriptionWrapperRef));
    }
  }, [descriptionWrapperRef]);

  return (
    <Div
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width="100%"
      data-testid="job-short-info"
    >
      <Heading.h4 mb={4} textAlign="left">
        {job.title}
      </Heading.h4>
      <DescriptionWrapper
        width="100%"
        height="100%"
        mb={3}
        overflow={isDescriptionCollapsed ? 'visible' : 'hidden'}
        maxHeight={isDescriptionCollapsed ? '100%' : '120px'}
        ref={descriptionWrapperRef}
        isTextOverflown={isTextOverflown}
        isDescriptionCollapsed={isDescriptionCollapsed}
      >
        {job.description}
      </DescriptionWrapper>
      {isTextOverflown && (
        <Div display="flex" alignItems="left">
          <ButtonLink
            variant="linkPrimary"
            onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
            px={0}
            mx={0}
          >
            {t('job.action.readMoreButton')}
            <Div ml={2}>
              <FireIcon
                variant="flat"
                iconName={
                  isDescriptionCollapsed
                    ? IconPickerName.ChevronUp
                    : IconPickerName.ChevronDown
                }
                width={12}
                height={12}
                fill={themeContext.colors.primary.base}
              />
            </Div>
          </ButtonLink>
        </Div>
      )}
      <MapPreviewModal
        address={getAddressForMaps(
          job.address?.city,
          job.address?.zip,
          job.address?.street
        )}
        containerProps={{ height: '190px' }}
      />
      <GoToJobLink
        to={getJobItemSearchParam(job.id)}
        data-testid="go-to-job-link"
      >
        <Button
          type="button"
          variant="secondary"
          width="100%"
          onClick={onJobModalOpen}
        >
          {t('job.action.goToJobLink')}
        </Button>
      </GoToJobLink>
    </Div>
  );
};

export default JobShortInfo;
