import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Button } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { BackButton, SectionTitleWrapper } from './styled';

interface SectionHeaderProps {
  title: string;
  buttonText?: string;
  onButtonClick?: () => void;
  isButtonInProgress?: boolean;
  px?: string | number;
  onBackButton?: () => void;
}

const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  buttonText,
  onButtonClick,
  isButtonInProgress,
  px,
  onBackButton,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onBackButtonClick = onBackButton || (() => navigate(-1));
  return (
    <Div
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      my={6}
      position="relative"
      px={px}
      minHeight="38px"
    >
      <Div>
        <BackButton
          variant="tertiary"
          onClick={onBackButtonClick}
          aria-label={t('general.label.back')}
        >
          <FireIcon
            variant="flat"
            iconName={IconPickerName.ChevronLeft}
            width="16px"
            height="16px"
          />
        </BackButton>
      </Div>
      <SectionTitleWrapper position="absolute" top="50%" left="50%">
        <Text.span
          mb={0}
          fontSize={3}
          fontWeight={themeContext.fontWeights.bold}
          textAlign="center"
        >
          {title}
        </Text.span>
      </SectionTitleWrapper>
      {buttonText && onButtonClick && (
        <Button
          onClick={onButtonClick}
          size="tiny"
          ml="50px"
          variant="primary"
          isLoading={isButtonInProgress}
          data-testid="profile-toolbar-action-btn"
        >
          {buttonText}
        </Button>
      )}
    </Div>
  );
};

export default SectionHeader;
