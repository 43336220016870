import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  UPDATE_ANSWER_TEMPLATE,
  UpdateAnswerTemplateMutationPayload,
  UpdateAnswerTemplateMutationVariables,
} from '../../utils/mutation/UpdateAnswerTemplate/UpdateAnswerTemplateMutation';

type useUpdateAnswerTemplateAction = (
  input: UpdateAnswerTemplateMutationVariables['input'],
  options?: MutationFunctionOptions<
    UpdateAnswerTemplateMutationPayload,
    UpdateAnswerTemplateMutationVariables
  >
) => Promise<FetchResult<UpdateAnswerTemplateMutationPayload>>;

export const useUpdateAnswerTemplate = (
  mutationHookOptions?: MutationFunctionOptions<
    UpdateAnswerTemplateMutationPayload,
    UpdateAnswerTemplateMutationVariables
  >
): [
  useUpdateAnswerTemplateAction,
  MutationResult<UpdateAnswerTemplateMutationPayload>
] => {
  const [updateAnswerTemplate, ...rest] = useMutation<
    UpdateAnswerTemplateMutationPayload,
    UpdateAnswerTemplateMutationVariables
  >(UPDATE_ANSWER_TEMPLATE, {
    ...mutationHookOptions,
  });

  const mutationFn: useUpdateAnswerTemplateAction = useCallback(
    (input, options?) => {
      return updateAnswerTemplate({
        variables: {
          input,
        },
        ...options,
      });
    },
    [updateAnswerTemplate]
  );

  return useMemo(() => [mutationFn, ...rest], [mutationFn, rest]);
};
