import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const CustomerServiceContentContainer = styled(Div)`
  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    padding-bottom: ${(props): number => props.theme.space[7]}px;
  }
`;

export default CustomerServiceContentContainer;
