import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const CHAT_DETAILS_TOP_BAR_HEIGHT = 60;

const ChatTopBarContainer = styled(Div)`
  background-color: ${({ theme }): string => theme.colors.black.black0};
  border-bottom: ${({ theme }): string =>
    `1px solid ${theme.colors.black.black3}`};
  width: 100%;
  height: ${CHAT_DETAILS_TOP_BAR_HEIGHT}px;
  min-height: ${CHAT_DETAILS_TOP_BAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }): string => `0 ${theme.space[5]}px`};

  @media only screen and (max-width: ${({ theme }): string =>
      theme.breakpoints[4]}) {
    text-align: center;
    justify-content: center;
  }
`;

export default ChatTopBarContainer;
