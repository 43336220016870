import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import Paths from '@router/paths';
import { Div, Heading } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import GoBackLink from './styled/GoBackLink';

interface WithNavigationPageHeadingProps {
  title: string;
}

const WithNavigationPageHeading: React.FC<
  React.PropsWithChildren<WithNavigationPageHeadingProps>
> = ({ title }) => {
  const themeContext = useTheme();
  return (
    <Div
      display={['block', null, null, null, null, 'none']}
      width="100%"
      borderBottom={`1px solid ${themeContext.colors.black.black2}`}
      position="relative"
      py={5}
      mb={7}
    >
      <GoBackLink to={Paths.Settings}>
        <FireIcon iconName={IconPickerName.ChevronLeft} variant="flat" />
      </GoBackLink>
      <Heading.h3 textAlign="center" mb={0}>
        {title}
      </Heading.h3>
    </Div>
  );
};

export default WithNavigationPageHeading;
