import { FC, useEffect } from 'react';

import { CloseableTooltip } from '@components/base/CloseableTooltip';
import { SavedFiltersTourIntroModal } from '@components/layout/Jobs/SavedFilters/SavedFiltersTourIntroModal';
import useAnalytics from '@hooks/useAnalytics';
import { useDisplayOnce } from '@hooks/useDisplayOnce';
import { STORE_SAVED_FILTER_INFO_PREFIX } from '@hooks/useStorage';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import {
  SavedFiltersFeatureTourStep,
  useSavedFiltersFeatureTour,
} from '@internals/business-shared/src/components/SavedFilters/SavedFiltersFeatureTourContext';
import {
  SavedFilterModalType,
  SavedFilterNavigationActionType,
} from '@internals/business-shared/src/components/SavedFilters/types';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const SavedFiltersTour: FC = () => {
  const { activeStep, startGuide, goToStep, endGuide } =
    useSavedFiltersFeatureTour();
  const themeContext = useTheme();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { filterState } = useSavedFiltersContext();
  const {
    display,
    hide,
    isDisplayed: isDisplayedForAFirstTime,
  } = useDisplayOnce('tour', STORE_SAVED_FILTER_INFO_PREFIX);

  useEffect(() => {
    startGuide();
    display();
  }, []);

  useEffect(() => {
    if (isDisplayedForAFirstTime) {
      track(ANEventSpace(ANEvent.Opened, ANObject.FilterTour, ANPage.JobList));
    }
  }, [track, isDisplayedForAFirstTime]);

  useEffect(() => {
    if (
      activeStep === SavedFiltersFeatureTourStep.SelectFilter &&
      filterState.action === SavedFilterNavigationActionType.SaveNew
    ) {
      goToStep(SavedFiltersFeatureTourStep.SaveFilter);
    }
  }, [activeStep, filterState.action]);

  useEffect(() => {
    if (
      activeStep === SavedFiltersFeatureTourStep.SaveFilter &&
      filterState.modal === SavedFilterModalType.Create
    ) {
      goToStep(SavedFiltersFeatureTourStep.Settings);
    }
  }, [activeStep, filterState.modal]);

  useEffect(() => {
    if (
      (activeStep === SavedFiltersFeatureTourStep.Settings &&
        filterState.modal === SavedFilterModalType.None) ||
      filterState.formChanged
    ) {
      endGuide();
    }
  }, [activeStep, filterState.modal, filterState.formChanged]);

  const onModalClose = () => {
    hide();
    goToStep(SavedFiltersFeatureTourStep.SelectFilter);
    track(ANEventSpace(ANEvent.Clicked, ANObject.FilterTour, ANPage.JobList), {
      optionChosen: 'tour',
    });
  };

  const tooltipStyles = {
    backgroundColor: themeContext.colors.primary.darkest,
    zIndex: Layer.sticky + Layer.above,
  };

  if (!activeStep) {
    return null;
  }

  if (
    isDisplayedForAFirstTime &&
    activeStep === SavedFiltersFeatureTourStep.Intro
  ) {
    return <SavedFiltersTourIntroModal onClose={onModalClose} />;
  }

  if (activeStep === SavedFiltersFeatureTourStep.SelectFilter) {
    return (
      <CloseableTooltip
        tooltipId={SavedFiltersFeatureTourStep.SelectFilter}
        isOpen
        onClose={() => goToStep(SavedFiltersFeatureTourStep.SaveFilter)}
        content={t('job.list.savedFilters.tour.selectFilter')}
        style={tooltipStyles}
        place="right"
        middlewares={[]}
      />
    );
  }

  if (
    activeStep === SavedFiltersFeatureTourStep.SaveFilter &&
    filterState.action === SavedFilterNavigationActionType.SaveNew
  ) {
    return (
      <CloseableTooltip
        tooltipId={SavedFiltersFeatureTourStep.SaveFilter}
        isOpen
        onClose={() => goToStep(SavedFiltersFeatureTourStep.Settings)}
        content={t('job.list.savedFilters.tour.saveFilter')}
        style={tooltipStyles}
        place="bottom"
      />
    );
  }

  if (
    activeStep === SavedFiltersFeatureTourStep.Settings &&
    filterState.modal === SavedFilterModalType.Create
  ) {
    return (
      <CloseableTooltip
        tooltipId={SavedFiltersFeatureTourStep.Settings}
        isOpen
        onClose={endGuide}
        content={t('job.list.savedFilters.tour.settings')}
        style={tooltipStyles}
        place="top"
        delayShow={100}
      />
    );
  }

  return null;
};
