import { Column } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const ProfileSidebarColumn = styled(Column)<{
  isMainPage: boolean;
  sidebarDesktopWidth: number;
  duration: number;
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  background: ${(props) => props.theme.colors.black.black0};
  transform-origin: ${({ isMainPage }) => (isMainPage ? 'right' : 'left')};
  transition: transform ${({ duration }) => duration}ms;
  border-right: 1px solid ${(props): string => props.theme.colors.black.black3};

  &.sidebar-enter {
    transform: translateX(
      ${({ isMainPage }) => (isMainPage ? '-100%' : '100%')}
    );
  }
  &.sidebar-enter-active {
    transform: translateX(0);
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    width: ${({ sidebarDesktopWidth }) => sidebarDesktopWidth}px;
  }
`;

export default ProfileSidebarColumn;
