import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from './BasicUserDataFragment';

const JOB_OWNER_FRAGMENT = gql`
  ${BASIC_USER_DATA_FRAGMENT}

  fragment JobOwnerFragment on Job {
    owner {
      ...BasicUserDataFragment
    }
  }
`;
export default JOB_OWNER_FRAGMENT;
