import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { ProductPackageStatusTag } from '@components/layout/Products/ProductPackageStatusTag';
import {
  StyledProductAddonSubtitle,
  StyledProductTitle,
} from '@components/layout/Products/styled';
import IconPicker from '@internals/business-shared/src/components/Icon/IconPicker';
import { ProductPackageStatus } from '@internals/business-shared/src/components/Products/productTypes';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

interface ProductAddonCardExtendedProps {
  title: string;
  subtitle: string;
  onClick: VoidFunction;
  icon: IconPickerName;
  status?: ProductPackageStatus;
}

export const ProductAddonCardExtended: FC<ProductAddonCardExtendedProps> = ({
  title,
  subtitle,
  onClick,
  icon,
  status,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();

  return (
    <Div
      border={`1px solid ${themeContext.colors.black.black4}`}
      borderRadius={3}
      p={5}
    >
      <Div display="flex" mb={4}>
        <Div>
          <IconPicker
            iconName={icon}
            fill="black.black9"
            width={24}
            height={24}
            variant="flat"
          />
        </Div>
        <Div ml={5}>
          <Div display="flex" alignItems="center">
            <StyledProductTitle mb={2}>{title}</StyledProductTitle>
            <Div mb={2} ml={2}>
              <ProductPackageStatusTag status={status} />
            </Div>
          </Div>
          <StyledProductAddonSubtitle>{subtitle}</StyledProductAddonSubtitle>
        </Div>
      </Div>
      <Div display="flex" justifyContent="flex-end">
        <Button
          variant="linkSecondary"
          onClick={onClick}
          flexShrink={0}
          flexWrap="nowrap"
          px={5}
          py={3}
          borderRadius={4}
        >
          {t('product.action.order')}
          <Text.span mb={0} ml={2}>
            <FireIcon
              iconName={IconPickerName.ChevronRightBold}
              fill="primary.base"
              width={16}
              height={16}
            />
          </Text.span>
        </Button>
      </Div>
    </Div>
  );
};
