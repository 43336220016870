import {
  ApolloClient,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import generateApolloClientVersion from '@utils/generateApolloClientVersion';
import { Environment, getEnvironment } from '@utils/getEnvironment';

import packageJson from '../../package.json';
import { isDev } from '../utils/envChecks';
import cache from './cache';
import { createErrorLink } from './createErrorLink';
import { createHeadersLink } from './createHeadersLink';
import { createRetryLink } from './createRetryLink';
import { createRetryOnGglErrorLink } from './createRetryOnGglErrorLink';
import { createTraceIdLink } from './createTraceIdLink';
import { createUploadLink } from './createUploadLink';

if (isDev) {
  loadDevMessages();
  loadErrorMessages();
}

const createApolloClient = (
  environment: Environment
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: ApolloLink.from([
      createRetryLink(),
      createTraceIdLink(),
      createErrorLink(),
      createRetryOnGglErrorLink(),
      createHeadersLink(),
      createUploadLink() as any,
    ]),
    cache,
    resolvers: {},
    name: 'mittanbud-business-web',
    version: generateApolloClientVersion({
      environment,
      versionNumber: packageJson.version || 'na',
    }),
    connectToDevTools: environment !== 'production',
  });
};

export const apolloClient = createApolloClient(getEnvironment());
