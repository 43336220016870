import { FC } from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import {
  useJobFiltersContext,
  useMunicipalityFilterChange,
  useUnselectAllCountyAreaFilters,
} from '@contexts/JobFiltersContext';
import {
  CountiesMunicipality,
  CountyWithMunicipalities,
  getMunicipalityBasedOnCode,
  getSelectedFiltersInCounty,
} from '@internals/business-shared/src/utils/areasUtils';
import { useTranslation } from 'react-i18next';

interface AreasViewProps {
  onBackClick: VoidFunction;
  onCountyExpand: (county: CountyWithMunicipalities) => void;
}

const AreasView: FC<React.PropsWithChildren<AreasViewProps>> = ({
  onBackClick,
  onCountyExpand,
}) => {
  const { t } = useTranslation();
  const { areas, activeMunicipalities, activeDistricts } =
    useJobFiltersContext();
  const unselectAllAreasInCounty = useUnselectAllCountyAreaFilters();
  const handleOnMunicipalityChange = useMunicipalityFilterChange();

  const onSelectedClick = (
    county: CountyWithMunicipalities,
    municipalityFilterId: string | string[]
  ) => {
    const municipality = getMunicipalityBasedOnCode(
      Number(municipalityFilterId),
      county.municipalities
    );
    if (typeof municipalityFilterId === 'string' && municipality) {
      handleOnMunicipalityChange(municipality as CountiesMunicipality, false);
      return;
    }
    unselectAllAreasInCounty(county.id);
  };

  return (
    <FilterView
      title={t('job.list.filters.area.title')}
      onBackClick={onBackClick}
    >
      <FilterGrid>
        {areas.map((county) => (
          <FilterGroupButton
            key={county.id}
            filterName={county.name}
            onClick={() => onCountyExpand(county)}
            selectedFilters={getSelectedFiltersInCounty(
              county,
              activeDistricts,
              activeMunicipalities
            )}
            onSelectedClick={(municipalityFilterId) =>
              onSelectedClick(county, municipalityFilterId)
            }
          />
        ))}
      </FilterGrid>
    </FilterView>
  );
};

export default AreasView;
