import { hexToRgba } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const Container = styled.button`
  background: none;
  border-radius: ${({ theme }) => theme.radii[4]};
  border: 0;
  padding: 0;

  &:hover,
  &:focus {
    outline: 3px solid ${({ theme }) => theme.background.white};
    svg {
      fill: ${({ theme }) => hexToRgba(theme.colors.black.black1, 0.5)};
    }
  }
`;
