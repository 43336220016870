import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const linkStyles = css`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  flex-shrink: 0;
  padding: ${({ theme }) => `${theme.space[4]}px ${theme.space[6]}px`};
  color: ${({ theme }) => theme.colors.black.black0};
  background: ${({ theme }) => theme.colors.primary.base};

  &:hover {
    color: ${({ theme }) => theme.colors.black.black0};
    text-decoration: none;
  }
`;

export const ActionLink = styled(Link)`
  ${linkStyles}
`;

export const ActionATag = styled('a')`
  ${linkStyles}
`;

export default ActionLink;
