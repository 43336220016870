import { PRODUCTS_QUERY_business_Business } from '../generated/generated';
import { ProductsQueryPayload } from '../query/Products/ProductsQuery';
import { GQLResponseErrorBase } from './GQLResponseErrorBase';

export class BusinessProductsResponseError extends GQLResponseErrorBase<
  ProductsQueryPayload['business'],
  PRODUCTS_QUERY_business_Business
> {
  constructor(successResponseTypename: string) {
    super(successResponseTypename);
  }

  // @ts-ignore
  protected handleErrors(data): void {
    switch (data.__typename) {
      case 'BusinessInputError':
        this.errors.general = { variant: 'danger', msg: data.idError.message };
        this.mapDataToError(data, 'idError', 'danger', true);
        break;
      default:
        this.errors.general = { variant: 'danger', msg: data.message };
        break;
    }
  }
}
