import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import {
  CloseableTooltip,
  CloseableTooltipProps,
} from '@components/base/CloseableTooltip';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

type JobAnswerTipProps = Pick<
  CloseableTooltipProps,
  'tooltipId' | 'isOpen' | 'onClose'
>;

export const JobAnswerTip: FC<JobAnswerTipProps> = ({
  tooltipId,
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const isEnabled = useFlagEnabled(FeatureFlags.BizAnswerTip);
  const themeContext = useTheme();

  if (!isEnabled) return null;

  return (
    <CloseableTooltip
      tooltipId={tooltipId}
      isOpen={isOpen}
      clickable
      onClose={onClose}
      style={{
        backgroundColor: themeContext.colors.primary.darkest,
        width: '80%',
      }}
      content={t('job.item.answerTip.text')}
    />
  );
};
