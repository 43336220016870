import * as React from 'react';

import { Textarea } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import REPORT_REASON from './constants/reportReasons';
import StyledRadio from './styled/StyledRadio';

export interface JobReportRadioGroupProps {
  reportReason: string;
  otherReason: string;
  handleRadioClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onOtherReasonChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

const JobReportRadioGroup: React.FC<
  React.PropsWithChildren<JobReportRadioGroupProps>
> = ({
  reportReason,
  otherReason,
  handleRadioClick,
  onOtherReasonChange,
}: JobReportRadioGroupProps) => {
  const { t } = useTranslation();
  return (
    <>
      <StyledRadio
        id="radio-illicit"
        name="report-reason"
        value={REPORT_REASON.Illicit}
        checked={reportReason === REPORT_REASON.Illicit}
        onChange={handleRadioClick}
        label={t('job.item.report.reason.illicit')}
        variant="boxed"
        data-testid="illicit-radio"
      />
      <StyledRadio
        id="radio-junk"
        name="report-reason"
        value={REPORT_REASON.Junk}
        checked={reportReason === REPORT_REASON.Junk}
        onChange={handleRadioClick}
        label={t('job.item.report.reason.junk')}
        variant="boxed"
      />
      <StyledRadio
        id="radio-wrong-place"
        name="report-reason"
        value={REPORT_REASON.WrongPlace}
        checked={reportReason === REPORT_REASON.WrongPlace}
        onChange={handleRadioClick}
        label={t('job.item.report.reason.wrongPlace')}
        variant="boxed"
      />
      <StyledRadio
        id="radio-wrong-category"
        name="report-reason"
        value={REPORT_REASON.WrongCategory}
        checked={reportReason === REPORT_REASON.WrongCategory}
        onChange={handleRadioClick}
        label={t('job.item.report.reason.wrongCategory')}
        variant="boxed"
      />
      <StyledRadio
        id="radio-manpower-request"
        name="report-reason"
        value={REPORT_REASON.ManpowerRequest}
        checked={reportReason === REPORT_REASON.ManpowerRequest}
        onChange={handleRadioClick}
        label={t('job.item.report.reason.manpowerRequest')}
        variant="boxed"
      />
      <StyledRadio
        id="radio-other"
        name="report-reason"
        value={REPORT_REASON.Other}
        checked={reportReason === REPORT_REASON.Other}
        onChange={handleRadioClick}
        label={t('general.label.other')}
        variant="boxed"
        after={
          reportReason === REPORT_REASON.Other ? (
            <Textarea
              unlabeled
              required
              placeholder={t('general.label.description')}
              value={otherReason}
              onChange={onOtherReasonChange}
              height={110}
              ml={6}
              mr={4}
            />
          ) : (
            ''
          )
        }
      />
    </>
  );
};

export default JobReportRadioGroup;
