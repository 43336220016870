import gql from 'graphql-tag';

import {
  RESTORE_CHAT_MUTATIONVariables,
  RESTORE_CHAT_MUTATION as RESTORE_CHAT_MUTATION_Result,
} from '../../generated/generated';

export type RestoreChatMutationPayload = RESTORE_CHAT_MUTATION_Result;
export type RestoreChatMutationVariables = RESTORE_CHAT_MUTATIONVariables;

export const RESTORE_CHAT_MUTATION = gql`
  mutation RESTORE_CHAT_MUTATION($chatId: ID!) {
    restoreChat(input: { id: $chatId }) {
      success
    }
  }
`;
