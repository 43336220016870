import * as React from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const CustomersListItemsContentLoader: React.FC<
  React.PropsWithChildren<LoaderProps>
> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 845 480"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
    data-testid="list-content-loader"
  >
    <rect x="97" y="20" rx="3" ry="3" width="200" height="14" />
    <rect x="97" y="45" rx="3" ry="3" width="150" height="11" />
    <rect x="330" y="20" rx="3" ry="3" width="200" height="14" />
    <rect x="330" y="45" rx="3" ry="3" width="150" height="11" />
    <circle cx="790" cy="40" r="17" />
    <rect x="97" y="80" rx="3" ry="3" width="710" height="2" />

    <rect x="97" y="100" rx="3" ry="3" width="200" height="14" />
    <rect x="97" y="125" rx="3" ry="3" width="150" height="11" />
    <rect x="330" y="100" rx="3" ry="3" width="200" height="14" />
    <rect x="330" y="125" rx="3" ry="3" width="150" height="11" />
    <circle cx="790" cy="120" r="17" />
    <rect x="97" y="160" rx="3" ry="3" width="710" height="2" />

    <rect x="97" y="180" rx="3" ry="3" width="200" height="14" />
    <rect x="97" y="205" rx="3" ry="3" width="150" height="11" />
    <rect x="330" y="180" rx="3" ry="3" width="200" height="14" />
    <rect x="330" y="205" rx="3" ry="3" width="150" height="11" />
    <circle cx="790" cy="200" r="17" />
    <rect x="97" y="240" rx="3" ry="3" width="710" height="2" />

    <rect x="97" y="260" rx="3" ry="3" width="200" height="14" />
    <rect x="97" y="285" rx="3" ry="3" width="150" height="11" />
    <rect x="330" y="260" rx="3" ry="3" width="200" height="14" />
    <rect x="330" y="285" rx="3" ry="3" width="150" height="11" />
    <circle cx="790" cy="280" r="17" />
    <rect x="97" y="320" rx="3" ry="3" width="710" height="2" />

    <rect x="97" y="340" rx="3" ry="3" width="200" height="14" />
    <rect x="97" y="365" rx="3" ry="3" width="150" height="11" />
    <rect x="330" y="340" rx="3" ry="3" width="200" height="14" />
    <rect x="330" y="365" rx="3" ry="3" width="150" height="11" />
    <circle cx="790" cy="360" r="17" />
    <rect x="97" y="400" rx="3" ry="3" width="710" height="2" />
  </ContentLoader>
);

export default CustomersListItemsContentLoader;
