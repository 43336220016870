import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { DismissibleAlert } from '@components/base/DismissibleAlert';
import MultilineText from '@components/base/PhoneMask/styled/MultilineText';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

export const JobAnswerTipMobile: FC = () => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const isEnabled = useFlagEnabled(FeatureFlags.BizAnswerTip);

  if (!isEnabled) return null;

  return (
    <DismissibleAlert
      icon={{
        iconName: IconPickerName.Info,
        fill: themeContext.colors.primary.darkest,
      }}
      space={`0 0 ${themeContext.space[5]}px`}
    >
      <MultilineText>{t('job.item.answerTip.text')}</MultilineText>
    </DismissibleAlert>
  );
};
