import gql from 'graphql-tag';

import {
  SET_CHAT_AS_UNREAD_MUTATION as SET_CHAT_AS_UNREAD_MUTATION_Reply,
  SET_CHAT_AS_UNREAD_MUTATIONVariables,
} from '../../generated/generated';

export type SetChatAsUnreadMutationPayload = SET_CHAT_AS_UNREAD_MUTATION_Reply;
export type SetChatAsUnreadMutationVariables =
  SET_CHAT_AS_UNREAD_MUTATIONVariables;
export const SET_CHAT_AS_UNREAD_MUTATION = gql`
  mutation SET_CHAT_AS_UNREAD_MUTATION($chatId: ID!) {
    setChatAsUnread(chatId: $chatId) {
      id
      isUnread
    }
  }
`;
