import * as React from 'react';

import MessagesView from '@components/layout/Messages';
import { useParams } from 'react-router-dom';

type MatchParams = {
  chatId?: string;
  name?: string;
};

const Messages: React.FC<React.PropsWithChildren<unknown>> = () => {
  const params: MatchParams = useParams();
  return params.chatId ? (
    <MessagesView chatId={params.chatId} />
  ) : (
    <MessagesView />
  );
};

export default Messages;
