import React, { FC } from 'react';

import { SavedFilterNotification } from '@components/layout/Jobs/SavedFilters/SavedFilterNotification';
import { SavedFilterFormValues } from '@components/layout/Jobs/SavedFilters/useSavedFilterFormik';
import { NotificationType } from '@internals/business-shared/src/components/SavedFilters/types';
import { NOTIFICATION_OPTIONS } from '@internals/business-shared/src/components/SavedFilters/utils';
import { JobWatchlistNotificationFrequency } from '@internals/business-shared/src/utils/generated/generated';
import { Div, Heading, Input, Text } from '@schibsted-smb/fireball';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

interface SavedFilterFormProps {
  formik: FormikProps<SavedFilterFormValues>;
}

export const SavedFilterForm: FC<SavedFilterFormProps> = ({ formik }) => {
  const { t } = useTranslation();

  const onNotificationChange = (
    option: NotificationType,
    isActive: boolean
  ) => {
    formik.setFieldValue(
      option,
      isActive
        ? JobWatchlistNotificationFrequency.IMMEDIATE
        : JobWatchlistNotificationFrequency.NEVER
    );
  };

  return (
    <Div>
      <Input
        {...formik.getFieldProps('title')}
        name="title"
        unlabeled
        placeholder={t('general.label.title')}
        error={
          formik.touched.title && formik.errors.title
            ? { msg: formik.errors.title }
            : undefined
        }
        testId="jobs-saved-filters-title"
      />
      <Text.span mt={2} mb={7} fontSize="12px" color="gray.darker">
        {t('job.list.savedFilters.form.title.hint')}
      </Text.span>
      <Heading.h5 fontWeight="medium" textAlign="left">
        {t('job.list.savedFilters.form.notifications.title')}
      </Heading.h5>
      {NOTIFICATION_OPTIONS.map((field) => {
        const isActive =
          formik.values[field.id] !== JobWatchlistNotificationFrequency.NEVER;
        return (
          <SavedFilterNotification
            key={field.id}
            value={isActive}
            type={field.id}
            title={field.title}
            text={field.text}
            onChange={() => onNotificationChange(field.id, !isActive)}
          />
        );
      })}
    </Div>
  );
};
