import { FC } from 'react';

import { ReactComponent as RippedPaperGraphic } from '@internals/business-shared/src/assets/illustrations/RippedPaperHorizontal.svg';
import { Div } from '@schibsted-smb/fireball';

export const Background: FC = () => (
  <Div bottom={-40} left={0} overflow="hidden" position="absolute" width={1}>
    <Div width={382}>
      <RippedPaperGraphic />
    </Div>
  </Div>
);
