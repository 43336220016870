import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useRequestContact } from '@hooks/useRequestContact';
import { useResetAllParams } from '@hooks/useResetAllParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { useInitiateSelfServiceSubscriptionPayment } from '@internals/business-shared/src/hooks/mutation/useInitiateSelfServiceSubscriptionPayment';
import {
  AuthCallbackUrlStatus,
  useRequestSecureIdAuthenticationUrl,
} from '@internals/business-shared/src/hooks/mutation/useRequestSecureIdAuthenticationUrl';
import { useSelfServiceSubscriptionsLazyQuery } from '@internals/business-shared/src/hooks/query/useSelfServiceSubscriptionsLazyQuery';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { SelfServiceSubscriptionName } from '@internals/business-shared/src/utils/generated/generated';
import { ProductType } from '@internals/business-shared/src/utils/ProductUtils';
import { SelfServiceSubscriptions } from '@internals/business-shared/src/utils/query/SelfServiceSubscriptions/SelfServiceSubscriptions';
import Paths from '@router/paths';
import { bugsnagClient } from '@utils/initBugsnag';
import { getProduct } from '@utils/product';
import { useNavigate } from 'react-router-dom';

import {
  AnalyticsEvent,
  CONTACT_SUBSCRIPTION_REQUEST_SENT,
  ModalState,
  PAYMENT_RESULT_SUCCESS_CODE,
  SUBSCRIPTION_PARAMS,
  TriggerSource,
} from './constants';
import { useSubscriptionAnalytics } from './hooks/useSubscriptionAnalytics';

interface ModalContextType {
  isOpen: boolean;
  loading: boolean;
  modalState: ModalState;
  packageList: SelfServiceSubscriptions[];
  selectedPackage: SelfServiceSubscriptions;
  handleBookOnboarding: VoidFunction;
  handleModalClose: VoidFunction;
  handleModalOpen: (
    triggerSource: TriggerSource,
    jobId?: string
  ) => Promise<void>;
  handleOpenCustomerService: VoidFunction;
  handlePayment: VoidFunction;
  handleRetryAttempt: VoidFunction;
  handleSecureIdAuth: VoidFunction;
  handleSelectPackage: (slug: SelfServiceSubscriptionName) => Promise<void>;
}

const ModalContext = createContext<ModalContextType>({
  isOpen: false,
  loading: false,
  modalState: null,
  packageList: [],
  selectedPackage: null,
  handleBookOnboarding: () => null,
  handleModalClose: () => null,
  handleModalOpen: () => null,
  handleOpenCustomerService: () => null,
  handlePayment: () => null,
  handleRetryAttempt: () => null,
  handleSecureIdAuth: () => null,
  handleSelectPackage: () => null,
});

export const BuySubscriptionModalProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [fetchPackageList, { data: packageList, loading: packageListLoading }] =
    useSelfServiceSubscriptionsLazyQuery();
  const [initiatePayment] = useInitiateSelfServiceSubscriptionPayment();
  const [isOpen, openModal, closeModal] = useBoolean();
  const [modalState, setModalState] = useState<ModalState>(
    ModalState.SELECT_PACKAGE
  );
  const [requestAuth] = useRequestSecureIdAuthenticationUrl();
  const [
    secureIdAuthLoading,
    setSecureIdAuthLoadingTrue,
    setSecureIdAuthLoadingFalse,
  ] = useBoolean();
  const [selectedPackage, setSelectedPackage] =
    useState<SelfServiceSubscriptions>(null);
  const [setSearchParams, , { searchParams }] = useSetSearchParams();
  const { customerServiceContactUrl } = getProduct();
  const handleSubscriptionAnalytics = useSubscriptionAnalytics();
  const navigate = useNavigate();
  const isBuySubscription = useFlagEnabled(FeatureFlags.BizBuySubscription);
  const isContactSubscription = useFlagEnabled(
    FeatureFlags.BizTestChooseSubscriptionModal
  );
  const { requestContact, requestContactLoading } = useRequestContact(
    CONTACT_SUBSCRIPTION_REQUEST_SENT,
    ProductType.Subscription
  );
  const resetAllParams = useResetAllParams(SUBSCRIPTION_PARAMS);

  const getPackageBySlug = useCallback(
    (slug: SelfServiceSubscriptionName) =>
      packageList.find((packageItem) => packageItem.slug === slug),
    [packageList]
  );

  const getPaymentUrl = useCallback(async () => {
    const currentUrl = new URL(window.location.href);
    const productVariationSlug = currentUrl.searchParams.get(
      SUBSCRIPTION_PARAMS.SELECTED_PACKAGE_SLUG
    ) as SelfServiceSubscriptionName;
    const verificationKey = currentUrl.searchParams.get(
      SUBSCRIPTION_PARAMS.AUTH_SESSION_ID
    );

    if (!productVariationSlug || !verificationKey) {
      throw new Error('Missing required payment parameters');
    }

    currentUrl.searchParams.delete(SUBSCRIPTION_PARAMS.AUTH_STATUS);
    currentUrl.searchParams.delete(SUBSCRIPTION_PARAMS.AUTH_SESSION_ID);

    const returnUrl = currentUrl.href;

    const response = await initiatePayment({
      productVariationSlug,
      verificationKey,
      returnUrl,
    });

    return response?.data?.initiateSelfServiceSubscriptionPayment;
  }, [initiatePayment]);

  const handleSetModalState = useCallback(
    (state: ModalState) => {
      setModalState(state);
      handleSubscriptionAnalytics(state);
    },
    [handleSubscriptionAnalytics]
  );

  const handleSelectPackage = useCallback(
    async (slug: SelfServiceSubscriptionName) => {
      const newSelectedPackage = getPackageBySlug(slug);
      setSelectedPackage(newSelectedPackage);

      if (isContactSubscription) {
        handleSetModalState(ModalState.SUMMARY);
      } else {
        handleSetModalState(ModalState.CONFIRM_PURCHASE);
      }
      setSearchParams(
        SUBSCRIPTION_PARAMS.SELECTED_PACKAGE_SLUG,
        newSelectedPackage.slug
      );
      handleSubscriptionAnalytics(AnalyticsEvent.PACKAGE_SELECTED, {
        selectedPackage: newSelectedPackage,
      });
    },
    [
      getPackageBySlug,
      handleSetModalState,
      handleSubscriptionAnalytics,
      isContactSubscription,
      setSearchParams,
    ]
  );

  const handleSecureIdAuth = useCallback(async () => {
    try {
      setSecureIdAuthLoadingTrue();
      const response = await requestAuth(window.location.href);
      const { authenticationUrl } =
        response?.data?.requestSecureIdAuthenticationUrl || {};

      if (authenticationUrl) {
        window.open(authenticationUrl, '_self');
        handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_CONFIRMED, {
          selectedPackage,
        });
      }
    } catch (e) {
      handleSetModalState(ModalState.NOT_COMPLETED);
      setSecureIdAuthLoadingFalse();
      handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_FAILED, {
        selectedPackage,
      });
      bugsnagClient.notify(`SecureID authentication failed: ${e as string}`);
    }
  }, [
    setSecureIdAuthLoadingTrue,
    requestAuth,
    handleSubscriptionAnalytics,
    selectedPackage,
    handleSetModalState,
    setSecureIdAuthLoadingFalse,
  ]);

  const handlePaymentResult = useCallback(
    (resultCode: string) => {
      if (resultCode === PAYMENT_RESULT_SUCCESS_CODE) {
        handleSetModalState(ModalState.SUMMARY);
        handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_SUCCESS);
      } else {
        handleSetModalState(ModalState.NOT_COMPLETED);
        handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_FAILED);
      }
    },
    [handleSetModalState, handleSubscriptionAnalytics]
  );

  const handlePayment = useCallback(async () => {
    try {
      const paymentUrl = await getPaymentUrl();

      if (paymentUrl) {
        window.open(paymentUrl, '_self');
      }
    } catch (e) {
      handleSetModalState(ModalState.NOT_COMPLETED);
      handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_FAILED, {
        selectedPackage,
      });
      bugsnagClient.notify(`Payment initiation failed: ${e as string}`);
    }
  }, [
    getPaymentUrl,
    handleSetModalState,
    handleSubscriptionAnalytics,
    selectedPackage,
  ]);

  const handleRetryAttempt = useCallback(() => {
    setSelectedPackage(null);
    handleSetModalState(ModalState.SELECT_PACKAGE);
    resetAllParams(SUBSCRIPTION_PARAMS.MODAL);
    handleSubscriptionAnalytics(AnalyticsEvent.RETRY_ATTEMPTED, {
      selectedPackage,
    });
  }, [
    handleSetModalState,
    handleSubscriptionAnalytics,
    resetAllParams,
    selectedPackage,
  ]);

  const handleModalOpen = useCallback(
    async (triggerSource: TriggerSource, jobId?: string) => {
      try {
        if (!isBuySubscription) {
          return;
        }
        await fetchPackageList();
        setSelectedPackage(null);
        handleSetModalState(ModalState.SELECT_PACKAGE);
        setSearchParams(SUBSCRIPTION_PARAMS.MODAL, triggerSource);
        openModal();
        handleSubscriptionAnalytics(
          {
            [TriggerSource.JOB_LIST]: AnalyticsEvent.ALERT_JOB_LIST_CLICKED,
            [TriggerSource.JOB_CARD]: AnalyticsEvent.ALERT_JOB_CARD_CLICKED,
          }[triggerSource],
          { jobId }
        );
      } catch (e) {
        handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_CANCELED);
        bugsnagClient.notify(`Error opening modal: ${e as string}`);
      } finally {
        await requestContact();
      }
    },
    [
      fetchPackageList,
      handleSetModalState,
      handleSubscriptionAnalytics,
      isBuySubscription,
      openModal,
      requestContact,
      setSearchParams,
    ]
  );

  const handleModalClose = useCallback(() => {
    resetAllParams();
    closeModal();
    handleSubscriptionAnalytics(AnalyticsEvent.MODAL_CLOSED, {
      selectedPackage,
    });
    if (modalState !== ModalState.SUMMARY) {
      handleSubscriptionAnalytics(AnalyticsEvent.PURCHASE_CANCELED, {
        selectedPackage,
      });
    }
  }, [
    resetAllParams,
    closeModal,
    handleSubscriptionAnalytics,
    selectedPackage,
    modalState,
  ]);

  const handleOpenCustomerService = useCallback(() => {
    handleModalClose();
    navigate(Paths.SettingsCustomerService);
    handleSubscriptionAnalytics(AnalyticsEvent.CUSTOMER_SERVICE_OPENED, {
      selectedPackage,
    });
  }, [
    handleModalClose,
    handleSubscriptionAnalytics,
    navigate,
    selectedPackage,
  ]);

  const handleBookOnboarding = useCallback(() => {
    handleModalClose();
    window.open(customerServiceContactUrl, '_blank');
    handleSubscriptionAnalytics(AnalyticsEvent.ONBOARDING_BOOKED, {
      selectedPackage,
    });
  }, [
    customerServiceContactUrl,
    handleModalClose,
    handleSubscriptionAnalytics,
    selectedPackage,
  ]);

  useEffect(() => {
    if (selectedPackage) {
      return;
    }

    const selectedPackageSlug = searchParams.get(
      SUBSCRIPTION_PARAMS.SELECTED_PACKAGE_SLUG
    ) as SelfServiceSubscriptionName;

    if (selectedPackageSlug) {
      const newSelectedPackage = getPackageBySlug(selectedPackageSlug);
      setSelectedPackage(newSelectedPackage);
    }
  }, [getPackageBySlug, searchParams, selectedPackage]);

  useEffect(() => {
    if (isOpen) {
      return;
    }

    const isSubscriptionModal = searchParams.has(SUBSCRIPTION_PARAMS.MODAL);
    const authStatus = searchParams.get(SUBSCRIPTION_PARAMS.AUTH_STATUS);
    const paymentResult = searchParams.get(SUBSCRIPTION_PARAMS.PAYMENT_RESULT);

    if (isSubscriptionModal) {
      if (authStatus) {
        handleSetModalState(
          authStatus === AuthCallbackUrlStatus.Success
            ? ModalState.REDIRECTION
            : ModalState.NOT_COMPLETED
        );
        openModal();
      } else if (paymentResult) {
        handlePaymentResult(paymentResult);
        openModal();
      } else {
        handleModalClose();
      }
    }
  }, [
    handleModalClose,
    handlePaymentResult,
    handleSetModalState,
    isOpen,
    openModal,
    searchParams,
  ]);

  const contextValue = useMemo(
    () => ({
      isOpen,
      loading:
        packageListLoading || requestContactLoading || secureIdAuthLoading,
      modalState,
      packageList,
      selectedPackage,
      handleBookOnboarding,
      handleModalClose,
      handleModalOpen,
      handleOpenCustomerService,
      handlePayment,
      handleRetryAttempt,
      handleSecureIdAuth,
      handleSelectPackage,
    }),
    [
      isOpen,
      packageListLoading,
      requestContactLoading,
      secureIdAuthLoading,
      modalState,
      packageList,
      selectedPackage,
      handleBookOnboarding,
      handleModalClose,
      handleModalOpen,
      handleOpenCustomerService,
      handlePayment,
      handleRetryAttempt,
      handleSecureIdAuth,
      handleSelectPackage,
    ]
  );

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useBuySubscriptionModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error(
      'useBuySubscriptionModalContext must be used within a BuySubscriptionModalProvider'
    );
  }
  return context;
};
