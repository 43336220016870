import { FC, useMemo, useState } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import AreaFilter, {
  AreaFilterButton,
} from '@components/layout/Jobs/Filters/Areas';
import {
  FolderFilterButton,
  FolderFilter,
} from '@components/layout/Jobs/Filters/Folders';
import Search from '@components/layout/Jobs/Filters/Search';
import {
  SizeFilterButton,
  SizeFilter,
} from '@components/layout/Jobs/Filters/Size';
import { FilterHeader } from '@components/layout/Jobs/Filters/styled/FilterHeader';
import {
  WorkTypeFilter,
  WorkTypeFilterButton,
} from '@components/layout/Jobs/Filters/WorkTypes';
import { useJobFiltersContext } from '@contexts/JobFiltersContext';
import useJobsTotalCountQuery from '@hooks/useJobsTotalCountQuery';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

enum FilterViewType {
  Size = 'Size',
  Folder = 'Folder',
  Area = 'Area',
  WorkType = 'WorkType',
}

interface FiltersProps {
  onClose: VoidFunction;
  mobileView?: boolean;
}

const Filters: FC<FiltersProps> = ({ onClose, mobileView }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const { data: totalCount, loading: totalCountLoading } =
    useJobsTotalCountQuery();
  const totalCountAvailable = totalCount !== null;
  const { resetAllFilters, canResetFilters } = useJobFiltersContext();
  const [activeFilterType, setActiveFilterType] =
    useState<FilterViewType | null>(null);

  const availableFilters = useMemo(
    () => ({
      [FilterViewType.Size]: {
        view: SizeFilter,
        selector: SizeFilterButton,
      },
      [FilterViewType.Folder]: {
        view: FolderFilter,
        selector: FolderFilterButton,
      },
      [FilterViewType.Area]: {
        view: AreaFilter,
        selector: AreaFilterButton,
      },
      [FilterViewType.WorkType]: {
        view: WorkTypeFilter,
        selector: WorkTypeFilterButton,
      },
    }),
    []
  );

  const FilterView = useMemo(() => {
    if (!activeFilterType) return null;
    const ViewComponent = availableFilters[activeFilterType].view;
    return <ViewComponent onBackClick={() => setActiveFilterType(null)} />;
  }, [availableFilters, activeFilterType, setActiveFilterType]);

  const FilterViewSelectors = useMemo(
    () =>
      Object.keys(availableFilters).map((viewType) => {
        const SelectorComponent =
          availableFilters[viewType as FilterViewType].selector;
        return (
          <SelectorComponent
            key={viewType}
            onClick={() => setActiveFilterType(viewType as FilterViewType)}
          />
        );
      }),
    [availableFilters, setActiveFilterType]
  );

  const showResultsText = totalCountAvailable
    ? t('job.list.filters.showResultsWithCountButton', { count: totalCount })
    : t('job.list.filters.showResultsButton');

  if (mobileView)
    return (
      <Div py={7} mb={9} px={6}>
        {FilterView || (
          <>
            <Div
              display="grid"
              mb={4}
              gridTemplateColumns="1fr auto 1fr"
              justifyItems="start"
              alignItems="center"
            >
              <FilterHeader>{t('job.list.filters.title')}</FilterHeader>
              <Button
                variant="linkPrimary"
                disabled={!canResetFilters}
                onClick={() => resetAllFilters()}
                mr={3}
                p={0}
                ml="auto"
              >
                {t('job.list.filters.resetAll')}
              </Button>
            </Div>
            <Search />
            <Div mt={6} mb={7}>
              {FilterViewSelectors}
            </Div>
            <Button
              variant="primary"
              isLoading={totalCountLoading}
              width={['100%', null, null, null, 'auto']}
              mx="auto"
              onClick={onClose}
            >
              {showResultsText}
            </Button>
          </>
        )}
      </Div>
    );

  return (
    <Div py={4} px={6}>
      <Div
        display="flex"
        mb={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text.span
          fontWeight={themeContext.fontWeights.medium}
          fontSize={3}
          marginBottom={0}
          marginTop="1px"
        >
          {totalCountAvailable &&
            t('job.list.filters.counter', { count: totalCount })}
        </Text.span>
        <Div display="flex" justifyContent="end">
          <Button
            variant="tertiary"
            disabled={!canResetFilters}
            fontSize={1}
            size="tiny"
            onClick={() => resetAllFilters()}
            mr={3}
          >
            {t('job.list.filters.resetAll')}
          </Button>
          <Button variant="linkTertiary" onClick={onClose} p={0}>
            <Text.span fontWeight={themeContext.fontWeights.medium} m={0}>
              {t('job.list.filters.close')}
            </Text.span>
            <Div display="flex" ml={4} alignItems="center">
              <FireIcon
                iconName={IconPickerName.ChevronUpBold}
                fill={themeContext.colors.black.black7}
                variant="flat"
                width="12px"
                height="12px"
              />
            </Div>
          </Button>
        </Div>
      </Div>
      <Search />
      {FilterView || <FilterGrid>{FilterViewSelectors}</FilterGrid>}
      <Button
        onClick={onClose}
        fullWidth
        mt={6}
        mb={4}
        variant="primary"
        isLoading={totalCountLoading}
      >
        {showResultsText}
      </Button>
    </Div>
  );
};

export default Filters;
