import { FC } from 'react';

import { Div, Image } from '@schibsted-smb/fireball';

interface ProductAddonImageProps {
  src: string;
}

export const ProductAddonImage: FC<ProductAddonImageProps> = ({ src }) => (
  <Div
    maxHeight="220px"
    position="relative"
    overflow="hidden"
    borderRadius={3}
    mb={6}
  >
    <Image src={src} width="100%" height="100%" objectFit="contain" />
    <Div
      position="absolute"
      top={0}
      left={0}
      bottom={0}
      right={0}
      backgroundColor="blue.darkest"
      opacity="0.7"
    />
  </Div>
);
