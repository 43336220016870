import * as React from 'react';

import { LoaderProps } from '@utils/contentLoaders';

export const repeatLoaderComponent = (
  count: number,
  Placeholder: React.FC<React.PropsWithChildren<LoaderProps>>
): JSX.Element[] => {
  const placeholders = [];

  for (let i = 0; i < count; i += 1) {
    const uniqueKey = `loader-id-${i}`;
    placeholders.push(<Placeholder uniqueKey={uniqueKey} key={uniqueKey} />);
  }

  return placeholders;
};

export default repeatLoaderComponent;
