import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import ScrollPanel from '@components/base/ScrollPanel';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Drawer } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import Actions from './styled/Actions';
import Header from './styled/Header';
import NavButton from './styled/NavButton';
import Title from './styled/Title';

interface HeaderMobileProps {
  title: string;
  sideNav: React.ReactNode;
  actions?: React.ReactNode[];
}

const MIN_DRAWER_MARGIN = 50; // space that allows us to close the drawer

const HeaderMobile: React.FC<React.PropsWithChildren<HeaderMobileProps>> = ({
  title,
  actions = [],
  sideNav,
}) => {
  const themeContext = useTheme();
  const [showNav, setShowNav] = React.useState(false);

  return (
    <>
      <Header>
        <NavButton onClick={() => setShowNav(true)} variant="tertiary">
          <FireIcon
            iconName={IconPickerName.BurgerMenu}
            variant="flat"
            fill={themeContext.colors.primary.base}
            width={24}
            height={24}
          />
        </NavButton>
        <Title>{title}</Title>
        <Actions>{actions}</Actions>
      </Header>
      <Drawer
        isOpen={showNav}
        onClose={() => setShowNav(false)}
        withCloseButton={false}
        contentProps={{ overflow: 'hidden' }}
      >
        <Div
          width={[
            240 - MIN_DRAWER_MARGIN,
            321 - MIN_DRAWER_MARGIN,
            376 - MIN_DRAWER_MARGIN,
          ]}
          height="100dvh"
        >
          <ScrollPanel autoHide style={{ maxHeight: '100%' }}>
            <Div p="25px 10px">{sideNav}</Div>
          </ScrollPanel>
        </Div>
      </Drawer>
    </>
  );
};

export default HeaderMobile;
