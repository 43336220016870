const FeatureFlags = {
  BizContract: 'global.contracts',
  BizContractNew: 'business.contracts-new',
  BizEvaluationReport: 'business.evaluation-report',
  BizMaintenanceModeNative: 'business.maintenance-mode-native',
  BizMaintenanceModeWeb: 'business.maintenance-mode-web',
  BizProductsSearch: 'business.products-search',
  MaintenanceGlobal: 'global.maintenance-mode',
  DevFeatures: 'business.dev-features',
  BizTestChooseSubscriptionModal: 'business.test-choose-subscription-modal',
  BizTestBuyMoreClips: 'business.test-buy-more-clips',
  BizPhoneMasking: 'business.phone-masking',
  BizNewAnswerJobFormWeb: 'business.new-answer-job-form-web',
  BizPushNotificationsSettings: 'business.push-notifications-settings',
  BizBigJobList: 'business.big-job-list',
  BizJobFilterTree: 'business.job-filter-tree',
  BizJobFilterTreeQuerySearchField:
    'business.job-filter-tree-query-search-field',
  BizShowPhoneDespitePreference: 'business.job-phone-show-despite-preference',
  BizBoligMappa: 'business.boligmappa',
  BizSettingsIntegrations: 'business.settings-integrations',
  BizMittanbudXL: 'business.mittanbud-xl',
  BizBuySubscription: 'business.buy-subscription',
  BizAnswerTip: 'business.answer-tip',
  BizSavedSearchPillsView: 'business.saved-search-pills-view',
  BizSavedSearchActions: 'business.saved-search-actions',
  BizSavedSearchFeatureTour: 'business.saved-search-feature-tour',
  BizSavedSearchNotificationSettingsPage:
    'business.saved-search-notifications-settings-page',
  BizForceNativeUpdate: 'business.force-native-update',
  BizPleaseUpdateModal: 'business.please-update-modal',
  BizUpdateCheck: 'business.update-check',
  BizVerificationView: 'business.verification-view',
  BizInstantSearchOnFilterChange: 'business.instant-search-on-filter-change',
  BizBoostCreditsChoosePackage: 'business.boost-credits-choose-package',
  BizSecureID: 'business.secureID',
  BizBoostCreditsDiscount: 'business.boost-credits-discount',
  BizJobCardHideAnswerCount: 'business.job-card-hide-answer-count',
  BizNewProductsPage: 'business.new-products-page',
  BizRejectionOneToOneJobs: 'business.rejection-one-to-one-jobs',
  BizRegFromAnswersJobCard: 'business.reg-form-answers-on-job',
  BizRequestedBoligmappaJobCard: 'business.requested-boligmappa-on-job',
};

export default FeatureFlags;
