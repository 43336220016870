import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledTitle = styled(Text.p)`
  margin-left: ${(props): number => props.theme.space[4]}px;
  margin-bottom: 0;

  @media only screen and (max-width: 768px) {
    margin-left: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default StyledTitle;
