import { FC, PropsWithChildren, useState } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import CollapseButton from './styled/CollapseButton';
import CollapseDiv from './styled/CollapseDiv';

interface CollapsingBoxProps {
  title: string;
  withBorderBottom?: boolean;
  isDefaultOpen?: boolean;
  indicatorCount?: number;
}

const CollapsingBox: FC<PropsWithChildren<CollapsingBoxProps>> = ({
  title,
  withBorderBottom,
  isDefaultOpen = false,
  children,
  indicatorCount = 0,
}) => {
  const themeContext = useTheme();
  const [isCollapsed, toggleCollapse] = useState(!isDefaultOpen);

  return (
    <Div
      borderBottom={
        withBorderBottom ? `1px solid ${themeContext.colors.black.black3}` : ''
      }
    >
      <CollapseButton
        onClick={() => toggleCollapse((value) => !value)}
        height={60}
        alignItems="center"
        py={6}
        px={6}
        m={0}
        isOpen={!isCollapsed}
        outline={false}
      >
        <Div
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Div display="flex" alignItems="center">
            <Text.span
              fontSize={2}
              fontWeight={600}
              mb={0}
              color="black.black9"
            >
              {title}
            </Text.span>
            {indicatorCount !== 0 && (
              <Div
                ml={2}
                height="17px"
                width="17px"
                bg="black.black6"
                color="black.black0"
                borderRadius={themeContext.radii[2]}
              >
                {indicatorCount}
              </Div>
            )}
          </Div>
          <Div width="16px" height="16px">
            <FireIcon
              iconName={IconPickerName.UserArrowDropdown}
              fill={themeContext.colors.black.black7}
              variant="flat"
            />
          </Div>
        </Div>
      </CollapseButton>
      <CollapseDiv
        collapsed={isCollapsed}
        display="flex"
        flexDirection="column"
        px={6}
        pt={4}
      >
        {children}
      </CollapseDiv>
    </Div>
  );
};

export default CollapsingBox;
