import { FC, ReactElement } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div, Divider } from '@schibsted-smb/fireball';

interface ProductAccordionProps {
  fixedContent: ReactElement | ReactElement[];
  expandedContent: ReactElement | ReactElement[];
  initialExpanded?: boolean;
}

export const ProductAccordion: FC<ProductAccordionProps> = ({
  fixedContent,
  expandedContent,
  initialExpanded = false,
}) => {
  const [isExpanded, , , toggleExpand] = useBoolean(initialExpanded);

  return (
    <Div backgroundColor="black.black1" borderRadius={3}>
      <Div display="flex" justifyContent="space-between" alignItems="center">
        <Div p={5}>{fixedContent}</Div>
        <Button variant="linkTertiary" onClick={toggleExpand} p={5}>
          <FireIcon
            iconName={
              isExpanded
                ? IconPickerName.ChevronUpBold
                : IconPickerName.ChevronDownBold
            }
            width={24}
            height={24}
            fill="black.black9"
          />
        </Button>
      </Div>
      {isExpanded && (
        <Div px={5}>
          <Divider backgroundColor="black.black4" m={0} />
          {expandedContent}
        </Div>
      )}
    </Div>
  );
};
