import gql from 'graphql-tag';

import {
  CREATE_BUSINESS_ALBUMVariables,
  CREATE_BUSINESS_ALBUM as CREATE_BUSINESS_ALBUM_Result,
} from '../../generated/generated';

export type CreateBusinessAlbumPayload = CREATE_BUSINESS_ALBUM_Result;
export type CreateBusinessAlbumVariables = CREATE_BUSINESS_ALBUMVariables;

export const isCreateBusinessAlbumSuccessResponse = (
  response?: CREATE_BUSINESS_ALBUM_Result | null
): response is CREATE_BUSINESS_ALBUM_Result => {
  return !!response?.createBusinessAlbum;
};

export const CREATE_BUSINESS_ALBUM = gql`
  mutation CREATE_BUSINESS_ALBUM($input: CreateBusinessAlbumInput!) {
    createBusinessAlbum(input: $input) {
      album {
        id
        name
      }
    }
  }
`;
