import * as React from 'react';

import { Div, Text } from '@schibsted-smb/fireball';

interface CustomerServicePersonDataProps {
  name: string;
  details?: string;
  mb?: number | string;
}

const CustomerServicePersonData: React.FC<
  React.PropsWithChildren<CustomerServicePersonDataProps>
> = ({ name, details, mb = 0 }) => {
  if (details) {
    return (
      <Div mb={mb}>
        <Text.p mb={4} fontSize={2} color="black.black9">
          {name}
        </Text.p>
        <Text.p mb={0} fontSize={1} color="black.black7">
          {details}
        </Text.p>
      </Div>
    );
  }
  return (
    <Text.p mb={mb} fontSize={2} color="black.black9">
      {name}
    </Text.p>
  );
};

export default CustomerServicePersonData;
