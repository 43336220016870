import { ApolloError } from '@apollo/client';
import {
  AuthLoginWithOtpDocument,
  type LoginWithOneTimePasswordInput,
  UserRole,
} from '@internals/business-graphql/src/graphql/generated/types';
import {
  AuthenticationErrorCodes,
  ErrorTypes,
  GenericErrorCodes,
  resolveErrors,
} from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import { handleLoginSuccess } from '../login/handleLoginSuccess';
import type { AuthHandlers, ErrorVariant } from '../login/types';
import type { SetState } from '../types';

export type HandleLoginWithOtpErrorHandlers = {
  onGenericError: (error?: ErrorVariant) => void;
  onRoleUnavailableError: (error: ErrorVariant) => void;
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onAuthNoAccessError: (error: ErrorVariant) => void;
  onUserIsInactive: (error: ErrorVariant) => void;
};

export const handleLoginWithOtp = async (
  set: SetState,
  input: LoginWithOneTimePasswordInput,
  authHandlers: AuthHandlers,
  errorHandlers: HandleLoginWithOtpErrorHandlers,
  defaultIntendedRole?: UserRole
) => {
  try {
    const intendedRole = input.intendedRole || defaultIntendedRole;

    const { data } = await apolloClient.mutate({
      mutation: AuthLoginWithOtpDocument,
      variables: { input: { ...input, intendedRole } },
    });

    if (data?.loginWithOneTimePasswordTmp.user) {
      if (
        authHandlers.onTokenReceived &&
        data.loginWithOneTimePasswordTmp.token
      ) {
        await authHandlers.onTokenReceived(
          data.loginWithOneTimePasswordTmp.token
        );
      }

      set({
        status: 'authenticated',
        user: data.loginWithOneTimePasswordTmp.user,
      });

      await handleLoginSuccess(
        data.loginWithOneTimePasswordTmp.user,
        authHandlers,
        intendedRole
      );
    } else {
      throw new Error('Invalid login with otp response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
          [ErrorTypes.AUTHENTICATION]: [
            [
              AuthenticationErrorCodes.LOGIN_ROLE_UNAVAILABLE,
              (error) => errorHandlers.onRoleUnavailableError(error),
            ],
            [
              AuthenticationErrorCodes.AUTH_NO_ACCESS,
              (error) => errorHandlers.onAuthNoAccessError(error),
            ],
            [
              AuthenticationErrorCodes.USER_IS_INACTIVE,
              (error) => errorHandlers.onUserIsInactive(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
