import { useCallback, useMemo } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  REQUEST_SECURE_ID_AUTHENTICATION_URL,
  RequestSecureIdAuthenticationUrlPayload,
  RequestSecureIdAuthenticationUrlVariables,
} from '../../utils/mutation/RequestSecureIdAuthenticationUrl/RequestSecureIdAuthenticationUrl';

export enum AuthCallbackUrlStatus {
  Name = 'secure-id-status',
  Success = 'success',
  Error = 'error',
  Abort = 'abort',
}

type UseRequestSecureIdAuthenticationUrlAction = (
  callbackUrl: string,
  options?: MutationFunctionOptions<
    RequestSecureIdAuthenticationUrlPayload,
    RequestSecureIdAuthenticationUrlVariables
  >
) => Promise<FetchResult<RequestSecureIdAuthenticationUrlPayload>>;

export const useRequestSecureIdAuthenticationUrl = (): [
  UseRequestSecureIdAuthenticationUrlAction,
  MutationResult<RequestSecureIdAuthenticationUrlPayload>
] => {
  const [requestSecureIdAuthenticationUrl, ...rest] = useMutation<
    RequestSecureIdAuthenticationUrlPayload,
    RequestSecureIdAuthenticationUrlVariables
  >(REQUEST_SECURE_ID_AUTHENTICATION_URL);

  const mutationFunction: UseRequestSecureIdAuthenticationUrlAction =
    useCallback(
      (callbackUrl, options) => {
        const successUrl = new URL(callbackUrl);
        const errorUrl = new URL(callbackUrl);
        const abortUrl = new URL(callbackUrl);

        successUrl.searchParams.set(
          AuthCallbackUrlStatus.Name,
          AuthCallbackUrlStatus.Success
        );
        errorUrl.searchParams.set(
          AuthCallbackUrlStatus.Name,
          AuthCallbackUrlStatus.Error
        );
        abortUrl.searchParams.set(
          AuthCallbackUrlStatus.Name,
          AuthCallbackUrlStatus.Abort
        );

        return requestSecureIdAuthenticationUrl({
          variables: {
            input: {
              callbackUrls: {
                success: successUrl.href,
                error: errorUrl.href,
                abort: abortUrl.href,
              },
            },
          },
          ...options,
        });
      },
      [requestSecureIdAuthenticationUrl]
    );

  return useMemo(() => [mutationFunction, ...rest], [mutationFunction, rest]);
};
