import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { getProduct } from '@root/src/utils/product';
import {
  Button,
  Column,
  Container,
  Div,
  Heading,
  Row,
  Text,
  hexToRgba,
} from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { CUSTOM_BLUE_900 } from '../constants';
import { useBoostCreditsModalContext } from '../ModalContext';

export const NotCompletedState: FC = () => {
  const { handleRetryAttempt, handleOpenCustomerService } =
    useBoostCreditsModalContext();
  const { name: productName } = getProduct();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Container
      bg={!isMobile && hexToRgba(CUSTOM_BLUE_900, 0.4)}
      borderRadius={!isMobile && 8}
      maxWidth={!isMobile && 430}
      p={isMobile ? 2 : 6}
    >
      <Row justifyContent="center" pb={6}>
        <Column>
          <Div pb={6}>
            <FireIcon
              iconName={IconPickerName.Attention}
              width={50}
              height={50}
              fill="black.black0"
            />
          </Div>
          <Div color="black.black0">
            <Heading.h2 pb={3}>
              {t('job.modal.boost.notCompleted.title')}
            </Heading.h2>
            <Text.p textAlign="center" pb={5}>
              {t('job.modal.boost.notCompleted.description1')}
            </Text.p>
            <Text.p textAlign="center">
              {t('job.modal.boost.notCompleted.description2', {
                productName,
              })}
            </Text.p>
          </Div>
        </Column>
      </Row>
      <Row justifyContent="center" pb={4}>
        <Column>
          <Button onClick={handleRetryAttempt} mb={5} fullWidth>
            {t('general.error.tryAgain')}
          </Button>
          <Button
            onClick={handleOpenCustomerService}
            variant="secondary"
            fullWidth
          >
            {t('general.error.screen.contactCustomerService')}
          </Button>
        </Column>
      </Row>
    </Container>
  );
};
