import JobListId from '../../utils/constants/jobListIds';
import { isEmptyValuesObject, isEqualObjects } from '../../utils/objectUtils';
import {
  AvailableFilters,
  FilterStateType,
  JobListFilter,
  SavedFilterModalType,
  SavedFilterNavigationActionType,
} from './types';
import {
  assertProperFormChangedValue,
  assertProperModalType,
  isJobListDefaultFilter,
  isJobListSavedFilter,
} from './utils';

/*
  Handles state of the saved filters, where:
  - only active saved filters can be further edited,
  - new and changed filters can be saved, but only when active list id is 'all new jobs/open jobs'.
*/

export const INIT = 'init';
export const SET_ACTIVE_FILTER = 'set-active-filter';
export const SET_EDITED_FILTER = 'set-edited-filter';
export const SET_NEW_FILTER = 'set-new-filter';
export const OPEN_MODAL = 'open-modal';
export const CLOSE_MODAL = 'close-modal';
export const SET_FILTER_FORM_CHANGED = 'set-filter-form-changed';

export const initialFilterState: FilterStateType = {
  type: 'init',
  activeFilter: null,
  editedFilter: null,
  action: SavedFilterNavigationActionType.None,
  modal: SavedFilterModalType.None,
  formChanged: false,
};

type Action =
  | {
      type: typeof INIT;
      payload: null;
    }
  | {
      type: typeof SET_ACTIVE_FILTER;
      payload: { activeFilter: JobListFilter };
    }
  | {
      type: typeof SET_EDITED_FILTER;
      payload: {
        listId: JobListId;
        selectedFilters: AvailableFilters;
      };
    }
  | {
      type: typeof SET_NEW_FILTER;
      payload: {
        selectedFilters: AvailableFilters;
        clearActive?: boolean;
      };
    }
  | {
      type: typeof OPEN_MODAL;
      payload: {
        modalType: SavedFilterModalType;
      };
    }
  | {
      type: typeof CLOSE_MODAL;
    }
  | {
      type: typeof SET_FILTER_FORM_CHANGED;
      payload: boolean;
    };

export const filterStateReducer = (
  prevState: FilterStateType,
  action: Action
): FilterStateType => {
  switch (action.type) {
    case INIT:
      return {
        ...prevState,
        type: 'init',
        activeFilter: null,
        editedFilter: null,
        action: SavedFilterNavigationActionType.None,
        modal: SavedFilterModalType.None,
        formChanged: false,
      };
    case SET_ACTIVE_FILTER: {
      if (action.payload.activeFilter.type === 'saved') {
        return {
          ...prevState,
          type: 'active-edit-enabled',
          activeFilter: action.payload.activeFilter,
          editedFilter: null,
          action: SavedFilterNavigationActionType.Edit,
          modal: SavedFilterModalType.None,
          formChanged: false,
        };
      }
      return {
        ...prevState,
        type: 'active-edit-disabled',
        activeFilter: action.payload.activeFilter,
        editedFilter: null,
        action: SavedFilterNavigationActionType.None,
        modal: SavedFilterModalType.None,
        formChanged: false,
      };
    }
    case SET_EDITED_FILTER: {
      const hasSelectedFilters = !isEmptyValuesObject(
        action.payload.selectedFilters
      );
      const isSameFilterSet = isEqualObjects(
        prevState.activeFilter?.filters || {},
        action.payload.selectedFilters
      );
      if (
        isJobListDefaultFilter(prevState.activeFilter) &&
        !hasSelectedFilters
      ) {
        return {
          ...prevState,
          type: 'active-edit-disabled',
          activeFilter: prevState.activeFilter,
          editedFilter: null,
          action: SavedFilterNavigationActionType.None,
          modal: SavedFilterModalType.None,
          formChanged: false,
        };
      }
      if (isJobListSavedFilter(prevState.activeFilter) && isSameFilterSet) {
        return {
          ...prevState,
          type: 'active-edit-enabled',
          activeFilter: prevState.activeFilter,
          editedFilter: null,
          action: SavedFilterNavigationActionType.Edit,
          modal: SavedFilterModalType.None,
          formChanged: false,
        };
      }
      const prevFilter = prevState.activeFilter || prevState.editedFilter;
      if (action.payload.listId === JobListId.Open && hasSelectedFilters) {
        if (isJobListSavedFilter(prevFilter)) {
          return {
            ...prevState,
            type: 'edited-save-enabled',
            activeFilter: prevFilter,
            editedFilter: {
              ...prevFilter,
              filters: action.payload.selectedFilters,
            },
            action: SavedFilterNavigationActionType.SaveExisting,
            modal: SavedFilterModalType.None,
            formChanged: false,
          };
        }
        return {
          ...prevState,
          type: 'new-save-enabled',
          activeFilter: prevState.activeFilter,
          editedFilter: {
            filters: action.payload.selectedFilters,
            notificationSettings: null,
          },
          action: SavedFilterNavigationActionType.SaveNew,
          modal: SavedFilterModalType.None,
          formChanged: false,
        };
      }
      if (isJobListSavedFilter(prevFilter)) {
        return {
          ...prevState,
          type: 'edited-save-disabled',
          activeFilter: prevState.activeFilter,
          editedFilter: {
            ...prevFilter,
            filters: action.payload.selectedFilters,
          },
          action: hasSelectedFilters
            ? SavedFilterNavigationActionType.None
            : SavedFilterNavigationActionType.MissingFilters,
          modal: SavedFilterModalType.None,
          formChanged: false,
        };
      }
      return {
        ...prevState,
        type: 'new-save-disabled',
        activeFilter: prevState.activeFilter,
        editedFilter: {
          filters: action.payload.selectedFilters,
        },
        action: hasSelectedFilters
          ? SavedFilterNavigationActionType.None
          : SavedFilterNavigationActionType.MissingFilters,
        modal: SavedFilterModalType.None,
        formChanged: false,
      };
    }
    case SET_NEW_FILTER: {
      return {
        ...prevState,
        type: 'new-save-enabled',
        activeFilter: action.payload.clearActive
          ? null
          : prevState.activeFilter,
        editedFilter: {
          filters: action.payload.selectedFilters,
          notificationSettings: null,
        },
        action: SavedFilterNavigationActionType.SaveNew,
        modal: SavedFilterModalType.None,
        formChanged: false,
      };
    }
    case OPEN_MODAL: {
      return assertProperModalType(prevState, action.payload.modalType);
    }
    case CLOSE_MODAL: {
      return {
        ...prevState,
        modal: SavedFilterModalType.None,
      };
    }
    case SET_FILTER_FORM_CHANGED: {
      return assertProperFormChangedValue(prevState, action.payload);
    }
    default:
      return prevState;
  }
};
