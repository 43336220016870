import { FC } from 'react';
import * as React from 'react';

import FilterGroupButton from '@components/elements/JobsFilters/FilterGroupButton';
import FilterToggle from '@components/elements/JobsFilters/FilterToggle';
import FilterView from '@components/elements/JobsFilters/FilterView';
import { FilterGrid } from '@components/elements/JobsFilters/styled';
import {
  FILTERABLE_SIZES,
  isSizeType,
  SizeType,
  useJobFiltersContext,
} from '@contexts/JobFiltersContext';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { JobSize } from '@internals/business-shared/src/utils/generated/generated';
import { getSizeLabel } from '@internals/business-shared/src/utils/jobFilters';
import { toggleItemInArray } from '@internals/business-shared/src/utils/toggleItemInArray';
import { useFlagEnabled } from '@root/src/components/base/CheckFlag';
import { useTranslation } from 'react-i18next';

interface SizeFilterButtonProps {
  onClick: VoidFunction;
}

interface SizeFilterProps {
  onBackClick: VoidFunction;
}

export const SizeFilter: FC<React.PropsWithChildren<SizeFilterProps>> = ({
  onBackClick,
}) => {
  const { t } = useTranslation();
  const { activeSizes, setActiveSizes } = useJobFiltersContext();
  const numberOfSelected = activeSizes.length;
  const isBigJobEnabled = useFlagEnabled(FeatureFlags.BizBigJobList);
  const availableSizes = isBigJobEnabled
    ? FILTERABLE_SIZES
    : FILTERABLE_SIZES.filter((size) => size !== JobSize.BIG);
  const availableToSelect = availableSizes.length;
  const allSelected = numberOfSelected === availableToSelect;

  const selectAll = () => {
    setActiveSizes([...availableSizes]);
  };

  const toggleAll = () => {
    if (allSelected) {
      setActiveSizes([]);
      return;
    }
    selectAll();
  };
  const toggleFilter = (size: SizeType) => {
    setActiveSizes(toggleItemInArray(activeSizes, size));
  };

  const toggleAllAction = {
    action: toggleAll,
    selectedFilterCount: numberOfSelected,
    totalFilterCount: availableToSelect,
  };

  return (
    <FilterView
      title={t('job.list.filters.size.title')}
      onBackClick={onBackClick}
      toggleAllAction={toggleAllAction}
    >
      <FilterGrid>
        {availableSizes.map((size) => (
          <FilterToggle
            key={size}
            name={getSizeLabel(size)}
            isChecked={activeSizes.includes(size)}
            onChange={() => toggleFilter(size)}
          />
        ))}
      </FilterGrid>
    </FilterView>
  );
};

export const SizeFilterButton: React.FC<
  React.PropsWithChildren<SizeFilterButtonProps>
> = ({ onClick }) => {
  const { t } = useTranslation();
  const { activeSizes, setActiveSizes } = useJobFiltersContext();

  const removeFilters = (size: string | string[]) => {
    if (typeof size === 'string' && isSizeType(size)) {
      setActiveSizes(toggleItemInArray(activeSizes, size));
      return;
    }
    setActiveSizes([]);
  };

  return (
    <FilterGroupButton
      filterName={t('job.list.filters.size.title')}
      onClick={onClick}
      selectedFilters={activeSizes.map((sizeFilter) => ({
        id: sizeFilter,
        name: getSizeLabel(sizeFilter),
      }))}
      onSelectedClick={removeFilters}
    />
  );
};
