import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface ChatJobInfoItemProps {
  text: string;
  icon: IconPickerName;
}

export const ChatJobInfoItem: FC<ChatJobInfoItemProps> = ({ text, icon }) => {
  const themeContext = useTheme();

  return (
    <Div display="flex" mb={6} lineHeight={0}>
      <Div width="20px" height="20px" flexShrink={0}>
        <FireIcon
          iconName={icon}
          fill={themeContext.colors.primary.base}
          variant="flat"
        />
      </Div>
      <Text.span ml={2} mb={0} fontSize={2} color="black.black9">
        {text}
      </Text.span>
    </Div>
  );
};
