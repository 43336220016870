import gql from 'graphql-tag';

import {
  UPDATE_BUSINESS_ALBUMVariables,
  UPDATE_BUSINESS_ALBUM as UPDATE_BUSINESS_ALBUM_Result,
} from '../../generated/generated';

export type UpdateBusinessAlbumPayload = UPDATE_BUSINESS_ALBUM_Result;
export type UpdateBusinessAlbumVariables = UPDATE_BUSINESS_ALBUMVariables;

export const isUpdateBusinessAlbumSuccessResponse = (
  response?: UPDATE_BUSINESS_ALBUM_Result | null
): response is UPDATE_BUSINESS_ALBUM_Result => {
  return !!response?.updateBusinessAlbum;
};

export const UPDATE_BUSINESS_ALBUM = gql`
  mutation UPDATE_BUSINESS_ALBUM($input: UpdateBusinessAlbumInput!) {
    updateBusinessAlbum(input: $input) {
      album {
        id
        name
      }
    }
  }
`;
