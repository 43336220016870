import { useEffect } from 'react';

import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '@internals/business-shared/src/utils/constants/apollo';
import JOB_BASIC_DATA_FRAGMENT from '@internals/business-shared/src/utils/fragments/JobBasicDataFragment';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node } from '@internals/business-shared/src/utils/generated/generated';
import {
  JOB_DETAILS_QUERY,
  JobDetailsQueryPayload,
  JobDetailsQueryVariables,
} from '@internals/business-shared/src/utils/query/Job/JobDetailsQuery';
import {
  JOB_QUERY,
  JobQueryPayload,
  JobQueryVariables,
} from '@internals/business-shared/src/utils/query/Job/JobQuery';

interface JobQueryResult {
  data?: JobQueryPayload;
  partialData?: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node | null;
  loading: boolean;
  error?: ApolloError;
}

export const useJobQuery = (id: string): JobQueryResult => {
  // partial job data could be already fetched in job list and present in cache
  const partialData: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node | null =
    useApolloClient().readFragment({
      id: `Job:${id}`,
      fragment: JOB_BASIC_DATA_FRAGMENT,
      fragmentName: 'JobBasicDataFragment',
    });
  // fetch the rest of job data when partial data is present (that will update cache)
  const { error: jobDetailsError, loading: jobDetailsLoading } = useQuery<
    JobDetailsQueryPayload,
    JobDetailsQueryVariables
  >(JOB_DETAILS_QUERY, {
    variables: { id },
    skip: !partialData,
    fetchPolicy: 'cache-first',
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
    notifyOnNetworkStatusChange: true,
  });

  // full query run only when partial data are not present, otherwise get data from cache
  const { data, loading, error, refetch } = useQuery<
    JobQueryPayload,
    JobQueryVariables
  >(JOB_QUERY, {
    variables: { id },
    fetchPolicy: !partialData ? 'cache-first' : 'cache-only',
    skip: jobDetailsLoading,
    notifyOnNetworkStatusChange: true,
    context: {
      [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
    },
  });

  useEffect(() => {
    if (!jobDetailsLoading && !loading) {
      refetch();
    }
    // jobDetailsLoading intentionally skipped in deps
  }, [id]);

  return partialData && !data
    ? {
        partialData,
        loading: jobDetailsLoading,
        error: jobDetailsError,
      }
    : {
        data,
        partialData,
        loading: loading && !data,
        error,
      };
};
