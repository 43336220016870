import { useCallback } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import {
  TicketEntryType,
  TicketRelConst,
  TicketRelType,
  TicketType,
} from '../../utils/generated/generated';
import {
  CREATE_TICKET_MUTATION,
  CreateTicketMutationPayload,
  CreateTicketMutationVariables,
} from '../../utils/mutation/CreateTicket/CreateTicketMutation';

type UseEvaluationReportActionParams = {
  evaluationId: string;
  userId: string;
  businessId: string;
  title: string;
  description: string;
  source: TicketEntryType;
  mutationOptions?: MutationFunctionOptions<
    CreateTicketMutationPayload,
    CreateTicketMutationVariables
  >;
};

type UseEvaluationReportAction = (
  params: UseEvaluationReportActionParams
) => Promise<FetchResult<CreateTicketMutationPayload>>;

export const useEvaluationReport = (): [
  UseEvaluationReportAction,
  MutationResult<CreateTicketMutationPayload>
] => {
  const [createTicket, ...rest] = useMutation<
    CreateTicketMutationPayload,
    CreateTicketMutationVariables
  >(CREATE_TICKET_MUTATION);

  const mutationFunction: UseEvaluationReportAction = useCallback(
    ({
      evaluationId,
      userId,
      businessId,
      title,
      description,
      source,
      mutationOptions,
    }) =>
      createTicket({
        variables: {
          type: TicketType.COMPLAINT,
          title,
          relations: [
            {
              relId: evaluationId,
              relConst: TicketRelConst.EVALUATION,
              relType: TicketRelType.ABOUT,
            },
            {
              relId: businessId,
              relConst: TicketRelConst.MASTER_ID,
              relType: TicketRelType.SENDER,
            },
            {
              relId: userId,
              relConst: TicketRelConst.SYSTEM_USER_MASTER_ID,
              relType: TicketRelType.SENDER,
            },
          ],
          entryType: source,
          content: description,
        },
        ...mutationOptions,
      }),
    []
  );
  return [mutationFunction, ...rest];
};
