import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FileLink } from '@components/layout/Job/styled';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  getFileExtension,
  getIconName,
} from '@internals/business-shared/src/utils/FileUtils';
import { FileFragment } from '@internals/business-shared/src/utils/generated/generated';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface JobFilesProps {
  files: FileFragment[];
  onClick?: (file: FileFragment) => void;
}

export const JobFiles: FC<JobFilesProps> = ({ files, onClick }) => {
  const themeContext = useTheme();

  return (
    <Div display="flex" flexWrap="wrap">
      {files.map((file) => (
        <FileLink
          key={file.id}
          variant="tertiary"
          href={file.url}
          target="_blank"
          rel="noopener noreferrer"
          download
          onClick={() => onClick && onClick(file)}
        >
          <Div display="flex" alignItems="center" mr={4}>
            <Text.span fontSize={1} mr={2} mb={0}>
              <FireIcon
                iconName={getIconName(getFileExtension(file.name))}
                height={26}
                width={26}
                variant="flat"
                fill={themeContext.colors.black.black6}
              />
            </Text.span>
            <Text.span fontSize={1} mb={0} maxWidth="200px" truncate>
              {file.name}
            </Text.span>
          </Div>
          <FireIcon
            iconName={IconPickerName.Download}
            height={20}
            width={20}
            variant="flat"
            fill={themeContext.colors.primary.base}
          />
        </FileLink>
      ))}
    </Div>
  );
};
