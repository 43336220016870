import { BoligMappaContentState } from '../reducers/BoligMappaModalReducer';
import {
  BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings,
  JOB_QUERY_jobMeta,
} from './generated/generated';
import {
  BoligmappaAddressDetailsBuildingType,
  BoligmappaAddressDetailsPropertyType,
} from './query/BoligmappaAddressDetails/BoligmappaAddressDetailsQuery';

export const BOLIGMAPPA_BRAND_COLOR = '#fd6764';
const BOLIGMAPPA_REQUESTED_SLUG = 'boligmappa-doc-requested';
const BOLIGMAPPA_REQUESTED_VALUE = '1';

export const isUnitBuilding = (
  unit: BoligMappaContentState['unit']
): unit is BoligmappaAddressDetailsBuildingType =>
  !!unit && unit.__typename === 'BoligmappaBuilding';
export const findBoligMappaBuilding = (
  buildings: BoligmappaAddressDetailsBuildingType[],
  unitId: string
): BoligmappaAddressDetailsBuildingType =>
  buildings.find(({ id }) => id === unitId) ?? null;

export const findBoligMappaProperty = (
  buildings: BoligmappaAddressDetailsBuildingType[],
  unitId: string
) =>
  buildings.reduce<BoligmappaAddressDetailsPropertyType>(
    (
      acc: BoligmappaAddressDetailsPropertyType,
      b: BOLIGMAPPA_ADDRESS_DETAILS_boligmappaAddressDetails_buildings
    ): BoligmappaAddressDetailsPropertyType => {
      const foundProperty =
        b.properties.find(({ id }) => id === unitId) ?? null;
      if (foundProperty) {
        return foundProperty;
      }
      return acc;
    },
    null
  );

export const isBoligmappaRequestedForJob = (
  jobMeta: Pick<JOB_QUERY_jobMeta, 'generics'>
): boolean => {
  if (!jobMeta?.generics?.length) {
    return false;
  }
  return (
    jobMeta.generics.find(
      (generic) => generic.slug === BOLIGMAPPA_REQUESTED_SLUG
    )?.value === BOLIGMAPPA_REQUESTED_VALUE
  );
};
