import { FC, PropsWithChildren } from 'react';

import { useIsMobile, useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { Div } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

interface StyleProps {
  pt?: number;
  pb?: number;
  overflow?: string;
}

interface HeaderWrapperProps {
  backgroundColor: string;
}

export const HeaderWrapper: FC<PropsWithChildren<HeaderWrapperProps>> = ({
  children,
  backgroundColor,
}) => {
  const { modalState } = useBuySubscriptionModalContext();
  const { radii } = useTheme();
  const isMobile = useIsMobile();
  const isTabletOrMobile = useIsTabletOrMobile();

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: {
      pt: isTabletOrMobile ? 20 : 40,
      pb: 40,
      overflow: isMobile ? 'hidden' : 'visible',
    },
    [ModalState.CONFIRM_PURCHASE]: {
      pt: 20,
      pb: 40,
      overflow: 'hidden',
    },
    [ModalState.SUMMARY]: {
      pt: 9,
      pb: 45,
    },
  };

  return (
    <Div
      bg={backgroundColor}
      borderTopLeftRadius={radii[2]}
      borderTopRightRadius={radii[2]}
      position="relative"
      px={6}
      {...styleProps[modalState]}
    >
      {children}
    </Div>
  );
};
