import getBusinessURL from '@internals/business-shared/config/business-url';

import { ENV_KEY_NAME, getEnvValue } from './getEnvValue';
import getUrlHostname from './getUrlHostname';

const ENVIRONMENT = getEnvValue(ENV_KEY_NAME.ENVIRONMENT);
const PRODUCT = getEnvValue(ENV_KEY_NAME.PRODUCT);

export const getBusinessDomain = (): string =>
  getUrlHostname(getBusinessURL(ENVIRONMENT, PRODUCT));
export const getBusinessAbsoluteUrl = (): string =>
  getBusinessURL(ENVIRONMENT, PRODUCT) as string;

export const getBusinessLocalhostAbsoluteUrl = (): string =>
  getBusinessURL('local', PRODUCT) as string;
