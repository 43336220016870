import { FC } from 'react';

import { PurchaseAddon } from '@components/layout/Products/Addons/PurchaseAddon';
import { isHandledAddonPage } from '@internals/business-shared/src/components/Products/productUtils';
import { Navigate, useParams } from 'react-router-dom';

export const ProductAddonPurchase: FC = () => {
  const params = useParams();
  const { slug } = params;
  const isHandled = isHandledAddonPage(slug);

  if (!isHandled) {
    return <Navigate to="/404" />;
  }

  return <PurchaseAddon />;
};
