import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { CONTRACT_LIST_DEFAULT_COUNT } from '../../cache/constants';
import { ContractList } from '../../utils/generated/generated';
import {
  isSimpleContractListQuerySuccessResponse,
  SIMPLE_CONTRACT_LIST_QUERY,
  SimpleContractListData,
  SimpleContractListQueryPayload,
  SimpleContractListQueryVariables,
} from '../../utils/query/SimpleContractList/SimpleContractListQuery';

export interface SimpleContractListQueryResult extends QueryResult {
  data: SimpleContractListData | null;
}

export const useSimpleContractListQuery = (
  variables?: Partial<SimpleContractListQueryVariables['input']>,
  options?: QueryHookOptions<
    SimpleContractListQueryPayload,
    SimpleContractListQueryVariables
  >
): SimpleContractListQueryResult => {
  const { data, ...rest } = useQuery<
    SimpleContractListQueryPayload,
    SimpleContractListQueryVariables
  >(SIMPLE_CONTRACT_LIST_QUERY, {
    variables: {
      input: {
        list: ContractList.ALL,
        count: CONTRACT_LIST_DEFAULT_COUNT,
        page: 1,
        ...variables,
      },
    },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  const result = useMemo(() => {
    const contractData =
      data &&
      isSimpleContractListQuerySuccessResponse(
        data?.simpleAgreementContractList
      )
        ? data.simpleAgreementContractList
        : null;
    return {
      data: contractData,
      ...rest,
    };
  }, [data, rest]);

  return result;
};
