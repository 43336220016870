import gql from 'graphql-tag';

const JOB_FOLDER_FRAGMENT = gql`
  fragment JobFolderFragment on JobFolder {
    id
    title
    color
  }
`;

export default JOB_FOLDER_FRAGMENT;
