import { FC } from 'react';

import { Alert, Button, Div, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface JobDeletedAlertProps {
  onActionClick: VoidFunction;
}

export const JobDeletedAlert: FC<JobDeletedAlertProps> = ({
  onActionClick,
}) => {
  const { t } = useTranslation();
  return (
    <Alert
      variant="danger"
      width="100%"
      size="medium"
      isCentered
      m={0}
      height="60px"
      data-testid="job-alert"
      borderRadius={1}
    >
      <Div display="flex" alignItems="center">
        <Text.p fontSize={1} fontWeight="medium" m={0}>
          {t('job.item.alert.deleted')}
        </Text.p>
        <Button
          data-testid="button-deleted"
          variant="primary"
          size="tiny"
          ml={5}
          type="button"
          onClick={onActionClick}
        >
          {t('job.action.restore')}
        </Button>
      </Div>
    </Alert>
  );
};
