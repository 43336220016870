import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from './BasicUserDataFragment';
import IMAGE_FRAGMENT from './ImageFragment';

const LATEST_MESSAGE_FRAGMENT = gql`
  ${IMAGE_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  fragment LatestMessageFragment on Message {
    id
    text
    type
    business {
      id
    }
    status
    writer {
      ...BasicUserDataFragment
    }
    regTs
    images {
      ...ImageFragment
    }
    files {
      id
      name
      url
      fileType
    }
  }
`;

export default LATEST_MESSAGE_FRAGMENT;
