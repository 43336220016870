import gql from 'graphql-tag';

import {
  DELETE_BUSINESS_ALBUMVariables,
  DELETE_BUSINESS_ALBUM as DELETE_BUSINESS_ALBUM_Result,
} from '../../generated/generated';

export type DeleteBusinessAlbumPayload = DELETE_BUSINESS_ALBUM_Result;
export type DeleteBusinessAlbumVariables = DELETE_BUSINESS_ALBUMVariables;

export const isDeleteBusinessAlbumSuccessResponse = (
  response?: DELETE_BUSINESS_ALBUM_Result | null
): response is DELETE_BUSINESS_ALBUM_Result => {
  return !!response?.deleteBusinessAlbum.success;
};
export const DELETE_BUSINESS_ALBUM = gql`
  mutation DELETE_BUSINESS_ALBUM($input: DeleteBusinessAlbumInput!) {
    deleteBusinessAlbum(input: $input) {
      success
    }
  }
`;
