import { ApolloCache, makeReference, Reference } from '@apollo/client';

import { User } from '../../types/User';
import {
  getChatIdFromCachedChatMessages,
  MergeDirection,
  removeIncomingEdgeDuplicates,
  sortDataByRegTs,
  sortEdges,
} from '../../utils/cache';
import { FileWithPreview, separateImageFiles } from '../../utils/FileUtils';
import LATEST_MESSAGE_FRAGMENT from '../../utils/fragments/LatestMessageFragment';
import {
  MessageStatus,
  MessageType,
  SEND_MESSAGE,
  SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images,
} from '../../utils/generated/generated';
import {
  isSendMessageMutationSuccessResponse,
  SendMessageMutationPayload,
} from '../../utils/mutation/SendMessage/SendMessageMutation';
import { getOptimisticId } from '../../utils/optimistic';
import {
  getOptimisticFile,
  getOptimisticImage,
  getOptimisticMessage,
} from '../../utils/optimistic/sendMessage';

export type Writer = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'operatingAs'
>;

const sendMessageUpdate =
  (
    chatId: string | undefined,
    messageMergeDirection: MergeDirection = 'ASC',
    user: Writer
  ) =>
  (
    cache: ApolloCache<SEND_MESSAGE>,
    { data }: { data?: SendMessageMutationPayload | null }
  ) => {
    if (!data) return undefined;

    // create cache entity for each of new messages
    const messagesData =
      (isSendMessageMutationSuccessResponse(data.sendMessage) &&
        data.sendMessage.messages) ||
      [];
    if (!messagesData.length) return undefined;
    const sortFromOldestToNewest = messageMergeDirection === 'ASC';
    const sortedMessages = sortDataByRegTs(messagesData, messageMergeDirection);
    const newMessageRefs = sortedMessages.map((message) =>
      cache.writeFragment({
        fragment: LATEST_MESSAGE_FRAGMENT,
        fragmentName: 'LatestMessageFragment',
        data: {
          ...message,
          type:
            message.files?.length || message.images?.length
              ? MessageType.ATTACHMENT
              : MessageType.TEXT,
          writer: { ...user, isDeleted: false },
          status: MessageStatus.SENT,
          business: {
            id: user.operatingAs.id,
          },
        },
      })
    );
    const newMessageEdges = newMessageRefs.map((ref) => {
      return {
        __typename: 'MessageEdge',
        node: ref,
      };
    });
    const lastMessage = sortFromOldestToNewest
      ? sortedMessages[sortedMessages.length - 1]
      : sortedMessages[0];
    const lastMessageRef = sortFromOldestToNewest
      ? newMessageRefs[newMessageRefs.length - 1]
      : newMessageRefs[0];

    cache.modify({
      id: cache.identify({ id: chatId, __typename: 'Chat' }),
      fields: {
        latestMessage() {
          return lastMessageRef;
        },
        latestUpdateTs() {
          return lastMessage.regTs;
        },
      },
    });

    cache.modify({
      id: cache.identify(makeReference('ROOT_QUERY')),
      fields: {
        chatMessages(existing, { readField, storeFieldName }) {
          const id = getChatIdFromCachedChatMessages(storeFieldName);
          if (!existing || id !== chatId) return existing;
          const existingEdges = existing.edges || [];
          const uniqueIncomingEdges = removeIncomingEdgeDuplicates({
            existingEdges,
            incomingEdges: newMessageEdges,
          });

          return {
            ...existing,
            edges: sortEdges(
              [...existingEdges, ...uniqueIncomingEdges],
              readField,
              messageMergeDirection
            ),
          };
        },
      },
    });

    // reorder chat list, chat with the latest message should be at the beginning of the list
    cache.modify({
      fields: {
        chatList(existing, { readField }) {
          if (!existing?.chatConnection?.edges) return existing;
          const isTheSameChat = (edge: { node: Reference }) =>
            edge?.node && chatId === readField('id', edge.node);

          const updatedChat = existing.chatConnection.edges.find(isTheSameChat);
          if (!updatedChat) return existing;

          const otherChats = existing.chatConnection.edges.filter(
            (edge: { node: Reference }) => !isTheSameChat(edge)
          );
          return {
            ...existing,
            chatConnection: {
              ...existing.chatConnection,
              edges: [updatedChat, ...otherChats],
            },
          };
        },
      },
    });
  };

const sendMessageOptimisticResponse = (
  message: string,
  attachments: FileWithPreview[] = []
): SEND_MESSAGE => {
  const [images, files] = separateImageFiles(attachments);

  const imagesMock: SEND_MESSAGE_sendMessage_SendMessagePayload_messages_images[] =
    images.map((image, index) =>
      getOptimisticImage({
        id: getOptimisticId(`image-${index}`),
        name: image.file.name,
        size: image.file.size,
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        url: image?.urlPreview?.toString(),
        fileType: image.type,
      })
    );

  const filesMock = files.map(({ type, file }, index) =>
    getOptimisticFile({
      id: getOptimisticId(`file-${index}`),
      name: file.name,
      fileType: type,
    })
  );

  const messageMock = getOptimisticMessage({ text: message });

  return {
    sendMessage: {
      __typename: 'SendMessagePayload',
      // if sent message contains files, it's split into several messages [text, files, images]
      messages: [
        messageMock,
        ...(filesMock.length
          ? [
              {
                ...messageMock,
                text: '',
                id: getOptimisticId('files'),
                files: filesMock,
                regTs: messageMock.regTs + 1,
              },
            ]
          : []),
        ...(imagesMock.length
          ? [
              {
                ...messageMock,
                text: '',
                id: getOptimisticId('images'),
                images: imagesMock,
                regTs: messageMock.regTs + 2,
              },
            ]
          : []),
      ],
    },
  };
};

export { sendMessageUpdate, sendMessageOptimisticResponse };
