import gql from 'graphql-tag';

import {
  BOLIGMAPPA_LOGIN_URLVariables,
  BOLIGMAPPA_LOGIN_URL as BOLIGMAPPA_LOGIN_URL_Result,
} from '../../generated/generated';

export type BoligmappaLoginUrlQueryVariables = BOLIGMAPPA_LOGIN_URLVariables;
export type BoligmappaLoginUrlQueryPayload = BOLIGMAPPA_LOGIN_URL_Result;

export const BOLIGMAPPA_LOGIN_URL = gql`
  query BOLIGMAPPA_LOGIN_URL($input: BoligmappaLoginInput!) {
    boligmappaLoginUrl(input: $input)
  }
`;
