import { FC, useCallback, useState } from 'react';

import { CheckFlag } from '@components/base/CheckFlag';
import CustomerServiceCardHeader from '@components/elements/CustomerServiceCardHeader';
import CustomerServiceContentContainer from '@components/elements/CustomerServiceContentContainer';
import CustomerServiceInfoWithIcon from '@components/elements/CustomerServiceInfoWithIcon';
import CustomerServiceListItem from '@components/elements/CustomerServiceListItem';
import CustomerServicePersonData from '@components/elements/CustomerServicePersonData';
import NoData from '@components/elements/NoData';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import useAnalytics, { usePageAnalytics } from '@hooks/useAnalytics';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { CONTRACT_LIST_DEFAULT_COUNT } from '@internals/business-shared/src/cache/constants';
import { contractStatusLabel } from '@internals/business-shared/src/components/Customers/Contract/ContractLabels/contractStatusLabel';
import { useSimpleContractListQuery } from '@internals/business-shared/src/hooks/query/useSimpleContractListQuery';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import log from '@internals/business-shared/src/utils/devLog';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import Paths, { Params } from '@router/paths';
import { Div, Pagination } from '@schibsted-smb/fireball';
import { getAddressString } from '@utils/address';
import ContentLoader from '@utils/contentLoaders';
import { getUserName } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import contractStatusStyle from './contractStatusStyle';

const Contract: FC = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { track } = useAnalytics();
  const [setParam] = useSetSearchParams();
  const [page, setPage] = useState(1);

  const { loading: listLoading, data: payload } = useSimpleContractListQuery({
    page,
    count: CONTRACT_LIST_DEFAULT_COUNT,
  });

  const contractList = payload?.list;
  const totalContractCount = payload?.pageInfo.totalCount;
  const emptyList = payload !== null && contractList.length === 0;

  const onContractClick = useCallback(
    (item: SimpleContract) => {
      track(
        ANEventSpace(ANEvent.Opened, ANObject.Agreement, ANPage.Customers),
        { isNewContract: false }
      );
      setParam(Params.Contract, item.id);
    },
    [setParam, track]
  );

  usePageAnalytics(ANSpace(ANObject.Contract, ANPage.Customers), {
    list: 0,
  });

  return (
    <>
      <CheckFlag flagName={FeatureFlags.BizContract} />
      <CustomerServiceCardHeader
        iconName={IconPickerName.Contract}
        title={t('contract.item.title')}
        buttonText={t('contract.action.new')}
        onButtonClick={() => log.component('Ny kontrakt clicked')}
        isNewButtonVisible={false} // implementation of request new contract feature on this page is postponed
      />
      {listLoading && !payload && (
        <ContentLoader.CustomersListItems uniqueKey="loader-customer-list-item" />
      )}
      {!listLoading && emptyList && (
        <NoData
          title={t('contract.list.title')}
          text={t('contract.list.text')}
          button={{
            text: t('contract.list.buttonLabel'),
            onClick: () => {
              navigate(Paths.Messages);
            },
          }}
        />
      )}
      {contractList?.length > 0 && (
        <CustomerServiceContentContainer>
          {contractList.map((item) => (
            <CustomerServiceListItem
              key={item.id}
              onClick={() => onContractClick(item)}
              firstColumn={
                <CustomerServiceInfoWithIcon
                  title={{
                    text: getUserName(item.user),
                    style: { color: 'black.black9' },
                  }}
                  details={{
                    /*
                      t('contract.list.label_simple-created')
                      t('contract.list.label_simple-shared')
                      t('contract.list.label_simple-signedBoth')
                      t('contract.list.label_simple-signedOfferee')
                      t('contract.list.label_simple-signedOfferor')
                      t('contract.list.label_simple-stopped-invalidated')
                      t('contract.list.label_simple-stopped-rejectedBusiness')
                      t('contract.list.label_simple-stopped-rejectedConsumer')
                      t('contract.list.label_unknown')
                    */
                    text: t('contract.list.label', {
                      context: contractStatusLabel(item),
                      defaultValue: '-',
                    }),
                    style: contractStatusStyle(item),
                  }}
                />
              }
              secondColumn={
                <CustomerServicePersonData
                  name={item.job?.title}
                  details={getAddressString(item.job?.address)}
                />
              }
              testId={`contract-${item.job?.id}`}
            />
          ))}
          {!!totalContractCount && (
            <Div
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={6}
              mb={[6, null, null, null, null, 0]}
            >
              <Pagination
                itemsPerPage={CONTRACT_LIST_DEFAULT_COUNT}
                count={totalContractCount}
                currentPage={page}
                getCurrentPage={setPage}
                visiblePagesOffset={isMobile ? 0.5 : 2}
              />
            </Div>
          )}
        </CustomerServiceContentContainer>
      )}
    </>
  );
};

export default Contract;
