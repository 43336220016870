import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const SMARTPHONE_NAV_MENU_HEIGHT = 70;
export const TABLET_NAV_MENU_HEIGHT = 50;

const MenuNav = styled.nav`
  width: 100%;
  height: ${SMARTPHONE_NAV_MENU_HEIGHT}px;
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${(props): string => props.theme.colors.black.black0};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2), 0 10px 40px 0 rgba(0, 0, 0, 0.2);
  z-index: ${Layer.fixed};

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    height: ${TABLET_NAV_MENU_HEIGHT}px;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    position: relative;
    height: 100%;
    background: none;
    box-shadow: none;
    display: flex;
    flex: 1;
  }
`;

export default MenuNav;
