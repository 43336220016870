import React from 'react';

import ContractView from '@components/layout/CustomerService/Contract';
import withNavigationLayout from '@components/layout/WithNavigation';
import { CustomerServiceNavigation } from '@pages/CustomerService';
import { useTranslation } from 'react-i18next';

export const CustomerServiceContract: React.FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: CustomerServiceNavigation,
    pageName: t('customer.list.title'),
    pageDetails: {
      PageContent: ContractView,
      subpageName: t('contract.item.title'),
      disableMobileHeader: true,
    },
  })();
};
