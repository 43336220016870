import { OPTIMISTIC_ID_PREFIX } from '../../cache/constants';

const getOptimisticId = (prefix?: string): string => {
  const timestamp = Math.floor(Date.now() / 1000);
  const pre = prefix ? `${prefix}-` : '';
  return `${OPTIMISTIC_ID_PREFIX}-${pre}${timestamp}`;
};

export const isOptimisticId = (id: string): boolean =>
  id.includes(OPTIMISTIC_ID_PREFIX);

export default getOptimisticId;
