import { FC } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { Products } from '@components/layout/Products';
import { ProductComponents } from '@components/layout/Products/ProductComponents';
import ProfileCompanyInfo from '@components/layout/Profile/ProfileCompanyInfo';
import { ProfileEmployees } from '@components/layout/Profile/ProfileEmployees';
import ProfileEvaluations from '@components/layout/Profile/ProfileEvaluations';
import { ProfileGraphic } from '@components/layout/Profile/ProfileGraphic';
import ProfilePictures from '@components/layout/Profile/ProfilePictures';
import ProfileQualifications from '@components/layout/Profile/ProfileQualifications';
import { CommonProviders } from '@contexts/CommonProviders';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import AccountSwitch from '@pages/AccountSwitch';
import { CustomerService } from '@pages/CustomerService';
import { CustomerServiceContract } from '@pages/CustomerService/Contract';
import { CustomerServiceEvaluation } from '@pages/CustomerService/Evaluation';
import Contract from '@pages/CustomerService/OpenContract';
import { Icons } from '@pages/Icons';
import Job from '@pages/Job';
import Jobs from '@pages/Jobs';
import Logout from '@pages/Logout';
import Messages from '@pages/Messages';
import PageNotFound from '@pages/PageNotFound';
import PrintJob from '@pages/PrintJob';
import Profile from '@pages/Profile';
import { Settings } from '@pages/Settings';
import { SettingsCustomerService } from '@pages/Settings/CustomerService';
import { SettingsIntegrations } from '@pages/Settings/Integrations';
import { SettingsJobs } from '@pages/Settings/Jobs';
import { SettingsNotifications } from '@pages/Settings/Notifications';
import { SettingsPayment } from '@pages/Settings/Payment';
import { SettingsPrivacyAndTerms } from '@pages/Settings/PrivacyAndTerms';
import { ProductAddon } from '@pages/Settings/ProductAddon';
import { ProductAddonPurchase } from '@pages/Settings/ProductAddonPurchase';
import { ProductAddons } from '@pages/Settings/ProductAddons';
import { ProductRewards } from '@pages/Settings/ProductRewards';
import { SettingsProducts } from '@pages/Settings/Products';
import { SettingsProfile } from '@pages/Settings/Profile';
import { SettingsReceipts } from '@pages/Settings/Receipts';
import { Layout } from '@router/Layout';
import Paths from '@router/paths';
import { isDev } from '@utils/envChecks';
import { RouteObject, useRoutes } from 'react-router-dom';

const routeConfig: RouteObject[] = [
  { path: Paths.Homepage, element: <Jobs /> },
  { path: Paths.JobsListItem, element: <Jobs /> },
  { path: Paths.JobsList, element: <Jobs /> },
  { path: Paths.JobsItemPrint, element: <PrintJob /> },
  { path: Paths.JobsItemTitle, element: <Jobs /> },
  { path: Paths.JobsItem, element: <Jobs /> },
  { path: Paths.MessagesItemName, element: <Messages /> },
  { path: Paths.MessagesItem, element: <Messages /> },
  { path: Paths.Messages, element: <Messages /> },
  { path: Paths.CustomerContract, element: <CustomerServiceContract /> },
  { path: Paths.CustomerEvaluation, element: <CustomerServiceEvaluation /> },
  { path: Paths.Customer, element: <CustomerService /> },
  {
    path: Paths.Profile,
    element: <Profile />,
    children: [
      { path: Paths.ProfileCompanyInfo, element: <ProfileCompanyInfo /> },
      { path: Paths.ProfileGraphic, element: <ProfileGraphic /> },
      { path: Paths.ProfileQualifications, element: <ProfileQualifications /> },
      { path: Paths.ProfilePictures, element: <ProfilePictures /> },
      { path: Paths.ProfileEmployees, element: <ProfileEmployees /> },
      { path: Paths.ProfileEvaluations, element: <ProfileEvaluations /> },
    ],
  },
  { path: Paths.SettingsNotifications, element: <SettingsNotifications /> },
  { path: Paths.SettingsJobs, element: <SettingsJobs /> },
  { path: Paths.SettingsProfile, element: <SettingsProfile /> },
  {
    path: Paths.SettingsProducts,
    element: <SettingsProducts />,
    children: [
      {
        path: '',
        element: <Products />,
      },
      {
        path: Paths.SettingsProductAddon,
        element: <ProductAddon />,
      },
      {
        path: Paths.SettingsProductAddons,
        element: <ProductAddons />,
      },
      {
        path: Paths.SettingsProductAddonPurchase,
        element: <ProductAddonPurchase />,
      },
      {
        path: Paths.SettingsProductRewards,
        element: <ProductRewards />,
      },
    ],
  },
  { path: Paths.SettingsPayment, element: <SettingsPayment /> },
  { path: Paths.SettingsCustomerService, element: <SettingsCustomerService /> },
  { path: Paths.SettingsReceipts, element: <SettingsReceipts /> },
  { path: Paths.SettingsPrivacyAndTerms, element: <SettingsPrivacyAndTerms /> },
  { path: Paths.SettingsIntegrations, element: <SettingsIntegrations /> },
  { path: Paths.Settings, element: <Settings /> },
  { path: Paths.Logout, element: <Logout /> },
  { path: Paths.AccountSwitch, element: <AccountSwitch /> },
  { path: '*', element: <PageNotFound /> }, // Catch-all route for 404
];

if (isDev) {
  routeConfig.push({ path: Paths.Icons, element: <Icons /> });
  // todo: it's just a temporary place to showcase product components, to be removed after proper implementation
  routeConfig.push({
    path: '/product-components',
    element: <ProductComponents />,
  });
}

export const Routes: FC = () => {
  const featureFlagsRoutesMap = new Map<string, boolean>([
    [Paths.CustomerContract, useFlagEnabled(FeatureFlags.BizContract)],
    [
      Paths.SettingsIntegrations,
      useFlagEnabled(FeatureFlags.BizSettingsIntegrations),
    ],
    [
      Paths.SettingsProductAddon,
      useFlagEnabled(FeatureFlags.BizNewProductsPage),
    ],
    [
      Paths.SettingsProductAddons,
      useFlagEnabled(FeatureFlags.BizNewProductsPage),
    ],
    [
      Paths.SettingsProductAddonPurchase,
      useFlagEnabled(FeatureFlags.BizNewProductsPage),
    ],
    [
      Paths.SettingsProductRewards,
      useFlagEnabled(FeatureFlags.BizNewProductsPage),
    ],
  ]);

  const enabledRoutes = routeConfig.filter(({ path }) => {
    if (!featureFlagsRoutesMap.has(path)) {
      return true;
    }
    return featureFlagsRoutesMap.get(path);
  });

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <CommonProviders>
          <Layout>
            <Job />
            <Contract />
          </Layout>
        </CommonProviders>
      ),
      children: enabledRoutes,
    },
  ]);

  return routes;
};
