import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const FormError = styled(Text.p)`
  margin-top: ${({ theme }) => `${theme.space[3]}px`};
  margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
  color: ${({ theme }) => theme.colors.red.base};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;
