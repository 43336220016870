import { NotificationDestinationType } from './generated/generated';
import { BusinessNotification } from './query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';

export const filterNotifications = (
  notifications: BusinessNotification[],
  typeFilter: NotificationDestinationType
): BusinessNotification[] =>
  // return notification groups containing only passed destination type (email/push etc) and allowed to be updated by user
  notifications
    .map((notification) => {
      return {
        ...notification,
        types: notification.types
          .map((type) => ({
            ...type,
            destinations: type.destinations.filter(
              (destination) =>
                destination.type === typeFilter && destination.isToggleable
            ),
          }))
          .filter((type) => !!type.destinations.length),
      };
    })
    .filter((notification) => !!notification.types.length);
