import { useEffect } from 'react';

import { LazyQueryHookOptions } from '@apollo/client';

import { hasNewActivity } from '../utils/badgeNumbers';
import {
  ChatConversationQueryPayload,
  ChatConversationQueryVariables,
} from '../utils/query/ChatConversation/ChatConversation';
import { useBadgeNumbersQuery } from './query/useBadgeNumbersQuery';
import { useChatConversationLazyQuery } from './query/useChatConversationQuery';

export const useChatConversationPolling = (
  options?: LazyQueryHookOptions<
    ChatConversationQueryPayload,
    ChatConversationQueryVariables
  >
) => {
  const [fetchChatConversation] = useChatConversationLazyQuery({
    fetchPolicy: 'network-only',
    ...options,
  });
  // the BADGE_NUMBERS_QUERY is polled every 10s, here we get updates every time the data change
  const { data: badgeData, previousData: prevBadgeData } = useBadgeNumbersQuery(
    {
      fetchPolicy: 'cache-only',
    }
  );

  useEffect(() => {
    if (!badgeData || !prevBadgeData) return;
    if (
      badgeData.badgeNumbers?.messages &&
      hasNewActivity(badgeData, prevBadgeData)
    ) {
      fetchChatConversation(options.variables);
    }
  }, [badgeData, prevBadgeData]);
};
