import { FC } from 'react';

import { TreeBranchWrapper } from '@components/layout/Jobs/Tree/styled/TreeBranchWrapper';
import { TreeLeaf, TreeLeafProps } from '@components/layout/Jobs/Tree/TreeLeaf';

type TreeBranchType = TreeLeafProps;
interface TreeBranchProps {
  branch: TreeBranchType;
  leafs: TreeLeafProps[];
}

export const TreeBranch: FC<TreeBranchProps> = ({ branch, leafs }) => {
  if (leafs.length === 0) {
    // Would be great to have disabled props for checkbox
    return (
      <TreeLeaf
        id={branch.id}
        label={branch.label}
        checked={false}
        onChange={() => {}}
      />
    );
  }
  return (
    <>
      <TreeLeaf
        id={branch.id}
        label={branch.label}
        checked={branch.checked}
        onChange={() => branch.onChange(branch.id)}
      />
      {branch.checked && (
        <TreeBranchWrapper>
          {leafs.map((leaf) => (
            <TreeLeaf
              id={leaf.id}
              key={leaf.id}
              label={leaf.label}
              checked={leaf.checked}
              onChange={() => leaf.onChange(leaf.id)}
            />
          ))}
        </TreeBranchWrapper>
      )}
    </>
  );
};
