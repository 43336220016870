import * as React from 'react';

import { GQLErrorState } from '@internals/business-shared/src/utils/errors';
import { OpeningHours } from '@internals/business-shared/src/utils/OpeningHours/OpeningHours.interface';
import {
  getOpeningHourErrorType,
  HourError,
} from '@internals/business-shared/src/utils/OpeningHours/OpeningHoursHelper';
import {
  Div,
  Text,
  Input,
  Divider,
  InputProps,
  InputError,
} from '@schibsted-smb/fireball';
import { t } from '@utils/i18n';

const HOUR_INPUT_HEIGHT = '60px';
const HOUR_INPUT_WIDTH = '100px';

const DayName: React.FC<
  React.PropsWithChildren<Pick<OpeningHours, 'dayLabel'>>
> = ({ dayLabel }) => (
  <Div
    display="flex"
    alignItems="center"
    alignSelf="baseline"
    height={HOUR_INPUT_HEIGHT}
  >
    <Text.p width="105px" mb={0}>
      {dayLabel}
    </Text.p>
  </Div>
);

const HourInput: React.FC<React.PropsWithChildren<InputProps>> = ({
  ...props
}) => (
  <Div width={HOUR_INPUT_WIDTH}>
    <Input type="time" height={HOUR_INPUT_HEIGHT} {...props} />
  </Div>
);

const HourDivider: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Div width="15px" mx={6}>
    <Divider bg="black.black9" />
  </Div>
);

const ClosedHourInput: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Div
    width={HOUR_INPUT_WIDTH}
    height={HOUR_INPUT_HEIGHT}
    bg="black.black3"
    border="1px solid red"
    borderColor="black.black6"
    borderRadius="3px"
    display="flex"
    alignItems="center"
    px={4}
  >
    <Text.p mb={0} color="black.black6">
      {t('profile.company.closed')}
    </Text.p>
  </Div>
);

const getErrorMessageHourFrom = (error: HourError) => {
  switch (error) {
    case HourError.FromInErrorState:
      return t('general.error.graphQLError');
    case HourError.FromIsRequired:
      return t('general.form.validation.required');
    case HourError.FromWrongStart:
      return t('profile.company.openingHours.wrongFromStart');
    default:
      return '';
  }
};

const getErrorMessageHourTo = (error: HourError) => {
  switch (error) {
    case HourError.ToInErrorState:
      return t('general.error.graphQLError');
    case HourError.ToIsRequired:
      return t('general.form.validation.required');
    default:
      return '';
  }
};

interface OpeningHoursInputProps {
  errorState: GQLErrorState;
  hour: OpeningHours;
  onBlur: VoidFunction;
  onChange: (updatedHours: OpeningHours) => void;
}

export const OpeningHoursInput: React.FC<
  React.PropsWithChildren<OpeningHoursInputProps>
> = ({ errorState, hour, onBlur, onChange }) => {
  const [hoursItem, setHoursItem] = React.useState(hour);

  const errorHourFrom: InputError = React.useMemo(() => {
    const msg = getErrorMessageHourFrom(
      getOpeningHourErrorType(hour, errorState)
    );
    return msg ? { msg, variant: 'danger' } : undefined;
  }, [hour, errorState]);

  const errorHourTo: InputError = React.useMemo(() => {
    const msg = getErrorMessageHourTo(
      getOpeningHourErrorType(hour, errorState)
    );
    return msg ? { msg, variant: 'danger' } : undefined;
  }, [hour, errorState]);

  const handleChangeHourFrom = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setHoursItem({ ...hoursItem, from: e.target.value });

  const handleChangeHourTo = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setHoursItem({ ...hoursItem, to: e.target.value });

  React.useEffect(() => {
    setHoursItem(hour);
  }, [hour.muted, hour.dayLabel, hour.from, hour.to]);

  React.useEffect(() => {
    onChange(hoursItem);
  }, [hoursItem]);

  return (
    <Div display="flex" alignItems="center" mb={5}>
      <DayName dayLabel={hoursItem.dayLabel} />
      {!hoursItem.muted ? (
        <Div display="flex" alignItems="baseline">
          <HourInput
            error={errorHourFrom}
            label={t('general.label.from')}
            onBlur={onBlur}
            onChange={handleChangeHourFrom}
            testId={`profile-company-from-time-${hour.dayNumber}`}
            value={hoursItem.from}
          />
          <HourDivider />
          <HourInput
            error={errorHourTo}
            label={t('general.label.to')}
            onBlur={onBlur}
            onChange={handleChangeHourTo}
            testId={`profile-company-to-time-${hour.dayNumber}`}
            value={hoursItem.to}
          />
        </Div>
      ) : (
        <ClosedHourInput />
      )}
    </Div>
  );
};
