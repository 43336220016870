import { useCallback } from 'react';

import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import { bugsnagClient } from '@utils/initBugsnag';

export const useCopyToClipboard = (): [boolean, (text: string) => void] => {
  const [isInClipboard, setAddedToClipboard, setRemovedFromClipboard] =
    useBoolean();

  const handleCopyToClipboard = useCallback(
    async (text: string) => {
      try {
        await navigator.clipboard.writeText(text);
        setAddedToClipboard();
      } catch (e) {
        setRemovedFromClipboard();
        bugsnagClient.notify(
          new Error(
            `Couldn't copy text: ${text} to clipboard, error details ${e}`
          )
        );
      }
    },
    [setAddedToClipboard]
  );

  return [isInClipboard, handleCopyToClipboard];
};
