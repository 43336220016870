import { useCallback } from 'react';

import {
  FetchResult,
  MutationFunctionOptions,
  MutationResult,
  useMutation,
} from '@apollo/client';

import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import { CompanyWantsContactAboutProductInput } from '../../utils/generated/generated';
import {
  COMPANY_WANTS_CONTACT_ABOUT_PRODUCT,
  CompanyWantsContactAboutProductPayload,
  CompanyWantsContactAboutProductVariables,
} from '../../utils/mutation/CompanyWantsContactAboutProduct/CompanyWantsContactAboutProduct';
import { getProductTemplateSlug, ProductType } from '../../utils/ProductUtils';

export type ContactAboutProductRequestDetails = Omit<
  CompanyWantsContactAboutProductInput,
  'productTemplateSlug'
>;
type UseCompanyWantsContactAboutProductAction = (
  productType: ProductType,
  requestDetails?: ContactAboutProductRequestDetails,
  mutationOptions?: MutationFunctionOptions<
    CompanyWantsContactAboutProductPayload,
    CompanyWantsContactAboutProductVariables
  >
) => Promise<FetchResult<CompanyWantsContactAboutProductPayload>>;
export const useCompanyWantsContactAboutProduct = (): [
  UseCompanyWantsContactAboutProductAction,
  MutationResult<CompanyWantsContactAboutProductPayload>
] => {
  const [companyWantsContactAboutProduct, ...rest] = useMutation<
    CompanyWantsContactAboutProductPayload,
    CompanyWantsContactAboutProductVariables
  >(COMPANY_WANTS_CONTACT_ABOUT_PRODUCT);

  const mutationFunction: UseCompanyWantsContactAboutProductAction =
    useCallback(
      (productType, requestDetails = {}, mutationOptions) =>
        companyWantsContactAboutProduct({
          variables: {
            input: {
              productTemplateSlug: getProductTemplateSlug(productType),
              ...requestDetails,
            },
          },
          context: {
            [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
          },
          ...mutationOptions,
        }),
      []
    );

  return [mutationFunction, ...rest];
};
