import { FC } from 'react';

import { FeatureFlag } from '@components/elements/FeatureFlag';
import { SimpleContractDetails } from '@components/layout/CustomerService/Contract/SimpleContractDetails';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { SimpleContract } from '@internals/business-shared/src/utils/query/SimpleContract/SimpleContractQuery';
import { Modal, Button, Text, Heading } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface SimpleContractModalProps {
  contract: SimpleContract;
  onClose: VoidFunction;
  onEdit: VoidFunction;
}

const SimpleContractModal: FC<SimpleContractModalProps> = ({
  contract,
  onClose,
  onEdit,
}) => {
  const { t } = useTranslation();

  const title =
    contract.statusFlags.rejectedConsumer === true
      ? t('contract.item.simpleContract.stoppedRejected.title')
      : t('contract.item.simpleContract.stopped.title');
  const text =
    contract.statusFlags.rejectedConsumer === true
      ? t('contract.item.simpleContract.stoppedRejected.text')
      : t('contract.item.simpleContract.stopped.text');

  return (
    <Modal
      isOpen
      onClose={onClose}
      isClosable
      size="custom"
      maxWidth="500px"
      footer={
        <FeatureFlag name={FeatureFlags.BizContractNew}>
          <Button variant="primary" size="large" type="button" onClick={onEdit}>
            {t('contract.item.simpleContract.suggestion.editAgreementButton')}
          </Button>
        </FeatureFlag>
      }
      footerProps={{
        alignItems: 'stretch',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        mb: 5,
        minHeight: '60px',
        mt: 6,
        px: '45px',
        width: '100%',
      }}
      headerProps={{ marginBottom: 0 }}
      testId="simple-contract"
    >
      <Heading.h2 mb={5} mt={5} textAlign="center">
        {title}
      </Heading.h2>
      <Text.p px={7} mb={6} textAlign="center">
        {text}
      </Text.p>
      <SimpleContractDetails contract={contract} />
    </Modal>
  );
};

export default SimpleContractModal;
