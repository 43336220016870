import { SimpleAgreementContractStatus } from './generated/generated';
import { SimpleContract } from './query/SimpleContract/SimpleContractQuery';

export const unsupportedContracts = [
  SimpleAgreementContractStatus.CREATED,
  SimpleAgreementContractStatus.SHARED,
  SimpleAgreementContractStatus.SEE_FLAGS,
];
export const isContractSupported = (contract: SimpleContract): boolean =>
  !unsupportedContracts.includes(contract.statusEnum);

export const onUnsupportedContractType = (typeStatus: string) => {
  throw new Error(`Unsupported contract type: ${typeStatus}`);
};

export const throwContractIsRequired = () => {
  throw new Error('Contract is required');
};
