import {
  RejectFeedbackOptions,
  RejectJobFeedbackValues,
} from '@internals/business-shared/src/utils/rejectJobUtils';
import { FormikProps, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export const UseJobRejectionFormik = (onSubmit) => {
  const { t } = useTranslation();
  const initialValues = {
    feedback: RejectFeedbackOptions.FULL_CALENDAR,
    reason: '',
  };
  const validationSchema = Yup.object({
    feedback: Yup.string().required(t('job.item.reject.feedback.required')),
    reason: Yup.string().optional(),
  });

  const formik: FormikProps<RejectJobFeedbackValues> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: () => onSubmit(formik.values),
  });

  return formik;
};
