import gql from 'graphql-tag';

import {
  DELETE_CHATVariables,
  DELETE_CHAT as DELETE_CHAT_Result,
} from '../../generated/generated';

export type DeleteChatMutationPayload = DELETE_CHAT_Result;
export type DeleteChatMutationVariables = DELETE_CHATVariables;

export const DELETE_CHAT_MUTATION = gql`
  mutation DELETE_CHAT($chatId: ID!) {
    deleteChat(input: { id: $chatId }) {
      success
    }
  }
`;
