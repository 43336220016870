import { FC, useMemo } from 'react';

import { JobDataItem } from '@components/layout/Job/JobDataItem';
import { JOB_QUERY_jobMeta } from '@internals/business-shared/src/utils/generated/generated';
import { getGenericsMetadata } from '@internals/business-shared/src/utils/jobMetadata/generics';

export interface JobGenericsMetadataProps {
  meta: JOB_QUERY_jobMeta['generics'];
}

export const JobGenericsMetadata: FC<JobGenericsMetadataProps> = ({ meta }) => {
  const genericMetadataList = useMemo(() => getGenericsMetadata(meta), [meta]);

  return (
    <>
      {genericMetadataList.map(({ title, text }) => (
        <JobDataItem key={title} title={title} text={text} />
      ))}
    </>
  );
};
