import { useMemo } from 'react';

import {
  LazyQueryHookOptions,
  QueryResult,
  useLazyQuery,
} from '@apollo/client';

import {
  SELF_SERVICE_SUBSCRIPTIONS,
  SelfServiceSubscriptions,
  SelfServiceSubscriptionsPayload,
} from '../../utils/query/SelfServiceSubscriptions/SelfServiceSubscriptions';

interface SelfServiceSubscriptionsQueryResult extends QueryResult {
  data: SelfServiceSubscriptions[];
}

type FetchSelfServiceSubscriptionsType = () => Promise<
  QueryResult<SelfServiceSubscriptionsPayload>
>;

export const useSelfServiceSubscriptionsLazyQuery = (
  options?: LazyQueryHookOptions<SelfServiceSubscriptionsPayload>
): [FetchSelfServiceSubscriptionsType, SelfServiceSubscriptionsQueryResult] => {
  const [fetchSelfServiceSubscriptions, { data, ...rest }] =
    useLazyQuery<SelfServiceSubscriptionsPayload>(SELF_SERVICE_SUBSCRIPTIONS, {
      ...options,
    });

  const memoizedData = useMemo(
    () => ({
      data: data?.selfServiceSubscriptions ?? [],
      ...rest,
    }),
    [data, rest]
  );

  return [fetchSelfServiceSubscriptions, memoizedData];
};
