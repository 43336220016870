import { GQLUnpaidInvoice } from './getCompanyState';

const getPaymentLink = (
  unpaidInvoices: GQLUnpaidInvoice[]
): GQLUnpaidInvoice['paymentLink'] | null => {
  if (!unpaidInvoices.length) return null;

  const [mostDelayedInvoice] = unpaidInvoices;
  const { paymentLink } = mostDelayedInvoice;
  return paymentLink;
};

export default getPaymentLink;
