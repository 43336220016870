import styled from 'styled-components';

const StyledCloseButton = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  background: none;
  border: none;
  margin: 0 20px 0 0;
`;

export default StyledCloseButton;
