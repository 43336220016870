import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Column, Div, Text, hexToRgba } from '@schibsted-smb/fireball';
import { Trans } from 'react-i18next';
import { useTheme } from 'styled-components';

const columnsInfo = [
  {
    id: 'customersAbility',
    description: (
      <Trans i18nKey="job.modal.subscription.selectPackage.customersAbility" />
    ),
    iconName: IconPickerName.BadgeId,
  },
  {
    id: 'creditUsage',
    description: (
      <Trans i18nKey="job.modal.subscription.selectPackage.creditUsage" />
    ),
    iconName: IconPickerName.TagMoney,
  },
  {
    id: 'creditDetails',
    description: (
      <Trans i18nKey="job.modal.subscription.selectPackage.creditDetails" />
    ),
    iconName: IconPickerName.PadlockUnlock,
  },
];

export const Footer: FC = () => {
  const { colors, radii } = useTheme();
  const isTabletOrMobile = useIsTabletOrMobile();

  return (
    <Column
      bg={!isTabletOrMobile && hexToRgba(colors.black.black1, 0.05)}
      borderRadius={!isTabletOrMobile && radii[2]}
      color="black.black0"
      display="flex"
      flexDirection={isTabletOrMobile ? 'column' : 'row'}
      px={isTabletOrMobile ? 0 : 6}
      py={!isTabletOrMobile && 4}
    >
      {columnsInfo.map(({ description, iconName, id }, index) => {
        const isOddElement = index % 2 === 1;

        return (
          <Div
            alignItems={isOddElement && !isTabletOrMobile && 'center'}
            bg={isTabletOrMobile && hexToRgba(colors.black.black1, 0.05)}
            borderLeft={
              isOddElement &&
              !isTabletOrMobile &&
              `1px solid ${hexToRgba(colors.black.black1, 0.1)}`
            }
            borderRight={
              isOddElement &&
              !isTabletOrMobile &&
              `1px solid ${hexToRgba(colors.black.black1, 0.1)}`
            }
            display="flex"
            flex={isOddElement && !isTabletOrMobile ? 'none' : 1}
            flexDirection="column"
            key={id}
            mx={isOddElement && !isTabletOrMobile && 5}
            my={isOddElement && isTabletOrMobile && 5}
            px={isOddElement || isTabletOrMobile ? 5 : 0}
            py={isTabletOrMobile && 5}
          >
            <Div
              display="flex"
              justifyContent={isTabletOrMobile ? 'flex-start' : 'center'}
              mb={4}
            >
              <FireIcon
                fill="black.black0"
                height={18}
                iconName={iconName}
                width={18}
              />
            </Div>
            <Text.p mb={0}>{description}</Text.p>
          </Div>
        );
      })}
    </Column>
  );
};
