import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import {
  CURRENT_USER_ACCOUNTS_QUERY as CURRENT_USER_ACCOUNTS_QUERY_Result,
  CURRENT_USER_ACCOUNTS_QUERY_me,
} from '../../generated/generated';

export type CurrentUserAccountsQueryPayload =
  CURRENT_USER_ACCOUNTS_QUERY_Result;
export type UserAccounts = Pick<
  CURRENT_USER_ACCOUNTS_QUERY_me,
  'companies' | 'isConsumerUser'
>;
export type UserWithAccounts = CURRENT_USER_ACCOUNTS_QUERY_me;

export const CURRENT_USER_ACCOUNTS_QUERY = gql`
  ${BASIC_USER_DATA_FRAGMENT}

  query CURRENT_USER_ACCOUNTS_QUERY {
    me {
      ...BasicUserDataFragment
      isConsumerUser
      operatingAs {
        id
      }
      initials
      companies {
        id
        name
        logo {
          id
          smallPreviewUrl
        }
        isActive
      }
    }
  }
`;
