import { t } from '@internals/business-translations/src/i18n';

import { JobContactPreference } from './generated/generated';

export interface GetEmailContentParams {
  email: string | null | undefined;
  isAnsweredJob: boolean;
  contactPreference: JobContactPreference;
  productName: string;
}
export const getEmailContent = ({
  email,
  contactPreference,
  productName,
  isAnsweredJob,
}: GetEmailContentParams): string => {
  if (email) {
    return email;
  }
  if (contactPreference === JobContactPreference.SITE_ONLY) {
    return t('job.item.contactThroughService', { productName });
  }
  return isAnsweredJob ? '-' : t('job.item.concealedData');
};

export const isEmailAvailable = (email: string | undefined | null): boolean =>
  !!email;

export interface GetPhoneContentParams {
  phone: string | null;
  isAnsweredJob: boolean;
  contactPreference: JobContactPreference;
  showDespitePreference: boolean;
  productName: string;
}

// TODO: simplify it once FeatureFlags.BizShowPhoneDespitePreference is removed
export const getPhoneContent = ({
  phone,
  isAnsweredJob,
  contactPreference,
  showDespitePreference,
  productName,
}: GetPhoneContentParams): string => {
  if (phone) {
    if (contactPreference === JobContactPreference.SITE_AND_PHONE) {
      return phone;
    }
    if (showDespitePreference) {
      return t('job.item.contact.phone.siteOnlyPreference', {
        phone,
        productName,
      });
    }
    return t('job.item.contactThroughService', { productName });
  }
  if (contactPreference === JobContactPreference.SITE_ONLY) {
    return t('job.item.contactThroughService', { productName });
  }
  return isAnsweredJob ? '-' : t('job.item.concealedData');
};

export interface IsPhoneAvailableParams {
  isAnsweredJob: boolean;
  contactPreference: JobContactPreference;
  showDespitePreference: boolean;
  phone: string | null;
}

export const isPhoneAvailable = ({
  isAnsweredJob,
  showDespitePreference,
  contactPreference,
  phone,
}: IsPhoneAvailableParams): boolean => {
  return (
    isAnsweredJob &&
    phone &&
    (contactPreference === JobContactPreference.SITE_AND_PHONE ||
      showDespitePreference)
  );
};
