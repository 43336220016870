import { GQLErrorState } from './errors';
import { isEmptyObject } from './objectUtils';

export const isError = (err: undefined | null | GQLErrorState): boolean => {
  if (!err) return false;
  return !isEmptyObject(err);
};

export default {
  isError,
};
