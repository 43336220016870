import * as React from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import { ActionButton } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import uniqid from 'uniqid';

export interface JobDeleteButtonProps {
  isDeleted: boolean;
  onClick: VoidFunction;
}

const uniqidConst = uniqid();

const JobDeleteButton: React.FC<
  React.PropsWithChildren<JobDeleteButtonProps>
> = ({ isDeleted, onClick }) => {
  const { t } = useTranslation();

  return isDeleted ? (
    <div>
      <CustomTooltip id={uniqidConst} />
      <ActionButton
        background="black.black0"
        backgroundHover="green.base"
        fill="green.base"
        fillHover="black.black0"
        data-tooltip-id={uniqidConst}
        data-tooltip-place="bottom"
        data-tooltip-content={t('general.label.undelete')}
        data-testid="job-action-button-restore"
        onClick={onClick}
      >
        <FireIcon iconName={IconPickerName.Restore} variant="flat" />
      </ActionButton>
    </div>
  ) : (
    <div>
      <CustomTooltip id={uniqidConst} />
      <ActionButton
        background="black.black0"
        backgroundHover="red.base"
        fill="red.base"
        fillHover="black.black0"
        data-tooltip-id={uniqidConst}
        data-tooltip-place="bottom"
        data-tooltip-content={t('general.label.delete')}
        data-testid="job-action-button-delete"
        onClick={onClick}
      >
        <FireIcon iconName={IconPickerName.Delete} variant="flat" />
      </ActionButton>
    </div>
  );
};

export default JobDeleteButton;
