enum INPUT_NAME {
  COMPANY_EMAIL = 'companyEmail',
  COMPANY_PHONE_NUMBER = 'companyPhoneNumber',
  COMPANY_ID_NUMBER = 'companyIdNumber',
  COMPANY_POST_ID = 'companyPostAddressId',
  COMPANY_POST_ADDRESS = 'companyPostAddress',
  COMPANY_POST_NUMBER = 'companyPostNumber',
  COMPANY_CITY = 'companyCity',
  COMPANY_VISIT_POST_ID = 'companyVisitPostAddressId',
  COMPANY_VISIT_POST_ADDRESS = 'companyVisitPostAddress',
  COMPANY_VISIT_POST_NUMBER = 'companyVisitPostNumber',
  COMPANY_VISIT_CITY = 'companyVisitCity',
  COMPANY_BILLING_POST_ID = 'companyBillingPostAddressId',
  COMPANY_BILLING_POST_ADDRESS = 'companyBillingPostAddress',
  COMPANY_BILLING_POST_NUMBER = 'companyBillingPostNumber',
  COMPANY_BILLING_CITY = 'companyBillingCity',
}

export default INPUT_NAME;
