import { FC, ReactNode } from 'react';

import { Div, Modal, Heading, ModalProps } from '@schibsted-smb/fireball';
import { DivProps } from '@schibsted-smb/fireball/lib/components/base/Div';

interface CreateSavedFilterModalProps extends Omit<ModalProps, 'isClosable'> {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  isClosable?: boolean;
  contentContainerProps?: Omit<DivProps, 'children' | 'as'>;
}

export const SavedFilterModal: FC<CreateSavedFilterModalProps> = ({
  isOpen,
  onClose,
  title,
  children,
  isClosable = true,
  contentContainerProps,
  ...rest
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    isClosable={isClosable}
    shouldCloseOnOverlayClick
    contentProps={{ p: 0 }}
    headerProps={{ p: 0 }}
    isSmaller
    size="custom"
    maxWidth="450px"
    isVerticallyCentered
    {...rest}
  >
    <Div px={6} py={5} {...contentContainerProps}>
      {title && (
        <Heading.h3 mb={5} textAlign="left">
          {title}
        </Heading.h3>
      )}
      {children}
    </Div>
  </Modal>
);
