import * as React from 'react';

import { SvgProps } from 'react-native-svg';

import { ReactComponent as AddEmployee } from '../../assets/icons/AddEmployee.svg';
import { ReactComponent as AddressGray } from '../../assets/icons/AddressGray.svg';
import { ReactComponent as AdsColor } from '../../assets/icons/AdsColor.svg';
import { ReactComponent as AdsGray } from '../../assets/icons/AdsGray.svg';
import { ReactComponent as AreasFlat } from '../../assets/icons/AreasFlat.svg';
import { ReactComponent as AreasGray } from '../../assets/icons/AreasGray.svg';
import { ReactComponent as ArrowDropdown } from '../../assets/icons/ArrowDropdown.svg';
import { ReactComponent as ArrowLeft } from '../../assets/icons/ArrowLeft.svg';
import { ReactComponent as AttachmentColor } from '../../assets/icons/AttachmentColor.svg';
import { ReactComponent as AttachmentFlat } from '../../assets/icons/AttachmentFlat.svg';
import { ReactComponent as AttachmentGray } from '../../assets/icons/AttachmentGray.svg';
import { ReactComponent as AttentionFlat } from '../../assets/icons/AttentionFlat.svg';
import { ReactComponent as BigBenColor } from '../../assets/icons/BigBenColor.svg';
import { ReactComponent as BoligMappaNoCircle } from '../../assets/icons/BoligMappaNoCircle.svg';
import { ReactComponent as BookColor } from '../../assets/icons/BookColor.svg';
import { ReactComponent as BookGray } from '../../assets/icons/BookGray.svg';
import { ReactComponent as BurgerMenu } from '../../assets/icons/BurgerMenu.svg';
import { ReactComponent as CalendarColor } from '../../assets/icons/CalendarColor.svg';
import { ReactComponent as CalendarFlat } from '../../assets/icons/CalendarFlat.svg';
import { ReactComponent as CalendarGray } from '../../assets/icons/CalendarGray.svg';
import { ReactComponent as CameraFlat } from '../../assets/icons/Camera.svg';
import { ReactComponent as CameraColor } from '../../assets/icons/CameraColor.svg';
import { ReactComponent as CancelCircle } from '../../assets/icons/CancelCircle.svg';
import { ReactComponent as CautionColor } from '../../assets/icons/CautionColor.svg';
import { ReactComponent as NEW_ChatColorFlat } from '../../assets/icons/ChatColorFlat.svg';
import { ReactComponent as NEW_ChatColorFlatMobile } from '../../assets/icons/ChatColorFlatMobile.svg';
import { ReactComponent as NEW_ChatFlat } from '../../assets/icons/ChatFlat.svg';
import { ReactComponent as NEW_ChatFlatMobile } from '../../assets/icons/ChatFlatMobile.svg';
import { ReactComponent as ChatGray } from '../../assets/icons/ChatGray.svg';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { ReactComponent as CheckmarkCircled } from '../../assets/icons/CheckmarkCircled.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronLeft } from '../../assets/icons/ChevronLeft.svg';
import { ReactComponent as ChevronLeftBold } from '../../assets/icons/ChevronLeftBold.svg';
import { ReactComponent as ChevronRight } from '../../assets/icons/ChevronRight.svg';
import { ReactComponent as ChevronRightBold } from '../../assets/icons/ChevronRightBold.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/ChevronUp.svg';
import { ReactComponent as ChevronUpBold } from '../../assets/icons/ChevronUpBold.svg';
import { ReactComponent as ClipboardCancelColor } from '../../assets/icons/ClipboardCancelColor.svg';
import { ReactComponent as ClipboardCancelFlat } from '../../assets/icons/ClipboardCancelFlat.svg';
import { ReactComponent as Clock } from '../../assets/icons/Clock.svg';
import { ReactComponent as Close } from '../../assets/icons/Close.svg';
import { ReactComponent as CloseColor } from '../../assets/icons/CloseColor.svg';
import { ReactComponent as ClosedBlack } from '../../assets/icons/ClosedBlack.svg';
import { ReactComponent as ClosedGray } from '../../assets/icons/ClosedGray.svg';
import { ReactComponent as CompanyColor } from '../../assets/icons/CompanyColor.svg';
import { ReactComponent as CompanyFlat } from '../../assets/icons/CompanyFlat.svg';
import { ReactComponent as ContractColor } from '../../assets/icons/ContractColor.svg';
import { ReactComponent as ContractFlat } from '../../assets/icons/ContractFlat.svg';
import { ReactComponent as CopyFlat } from '../../assets/icons/CopyFlat.svg';
import { ReactComponent as CrossedOutHeartFlat } from '../../assets/icons/CrossedOutHeart.svg';
import { ReactComponent as CustomersColor } from '../../assets/icons/CustomersColor.svg';
import { ReactComponent as CustomersFlat } from '../../assets/icons/CustomersFlat.svg';
import { ReactComponent as Decrease } from '../../assets/icons/Decrease.svg';
import { ReactComponent as DeleteColor } from '../../assets/icons/DeleteColor.svg';
import { ReactComponent as DeleteFlat } from '../../assets/icons/DeleteFlat.svg';
import { ReactComponent as DeleteGray } from '../../assets/icons/DeleteGray.svg';
import { ReactComponent as DiscountColor } from '../../assets/icons/DiscountColor.svg';
import { ReactComponent as DoffinColor } from '../../assets/icons/DoffinColor.svg';
import { ReactComponent as DoffinGray } from '../../assets/icons/DoffinGray.svg';
import { ReactComponent as DownloadFlat } from '../../assets/icons/DownloadFlat.svg';
import { ReactComponent as EmailColor } from '../../assets/icons/EmailColor.svg';
import { ReactComponent as EmailFlat } from '../../assets/icons/EmailFlat.svg';
import { ReactComponent as EmailNotificationColor } from '../../assets/icons/EmailNotificationColor.svg';
import { ReactComponent as EmailNotificationFlat } from '../../assets/icons/EmailNotificationFlat.svg';
import { ReactComponent as Enlarge } from '../../assets/icons/Enlarge.svg';
import { ReactComponent as FavouriteColor } from '../../assets/icons/Favorite.svg';
import { ReactComponent as FavouriteGray } from '../../assets/icons/FavoriteGray2.svg';
import { ReactComponent as FilledInfo } from '../../assets/icons/FilledInfoFlat.svg';
import { ReactComponent as FireFlat } from '../../assets/icons/fire-flat.svg';
import { ReactComponent as FlgDk } from '../../assets/icons/FlagDk.svg';
import { ReactComponent as FlgFi } from '../../assets/icons/FlagFi.svg';
import { ReactComponent as FlgNo } from '../../assets/icons/FlagNo.svg';
import { ReactComponent as FlgSe } from '../../assets/icons/FlagSe.svg';
import { ReactComponent as FlgUk } from '../../assets/icons/FlagUk.svg';
import { ReactComponent as Folder } from '../../assets/icons/Folder.svg';
import { ReactComponent as FolderColor } from '../../assets/icons/FolderColor.svg';
import { ReactComponent as FolderGray } from '../../assets/icons/FolderGray.svg';
import { ReactComponent as FolderTransparent } from '../../assets/icons/FolderTransparent.svg';
import { ReactComponent as Globe } from '../../assets/icons/Globe.svg';
import { ReactComponent as HappyGray } from '../../assets/icons/HappyGray.svg';
import { ReactComponent as Favourite } from '../../assets/icons/HeartFlat.svg';
import { ReactComponent as HexHeadFlat } from '../../assets/icons/HexHeadFlat.svg';
import { ReactComponent as Home } from '../../assets/icons/Home.svg';
import { ReactComponent as House } from '../../assets/icons/House.svg';
import { ReactComponent as ImagesColor } from '../../assets/icons/ImagesColor.svg';
import { ReactComponent as ImagesFlat } from '../../assets/icons/ImagesFlat.svg';
import { ReactComponent as ImagesGray } from '../../assets/icons/ImagesGray.svg';
import { ReactComponent as IncorrectIcon } from '../../assets/icons/IncorrectIcon.svg';
import { ReactComponent as Info } from '../../assets/icons/Info.svg';
import { ReactComponent as InfoGray } from '../../assets/icons/InfoGray.svg';
import { ReactComponent as InvoiceColor } from '../../assets/icons/InvoiceColor.svg';
import { ReactComponent as InvoiceFlat } from '../../assets/icons/InvoiceFlat.svg';
import { ReactComponent as InvoiceGray } from '../../assets/icons/InvoiceGray.svg';
import { ReactComponent as InvoiceNavColor } from '../../assets/icons/InvoiceNavColor.svg';
import { ReactComponent as InvoiceNavGray } from '../../assets/icons/InvoiceNavGray.svg';
import { ReactComponent as JobListColor } from '../../assets/icons/JobListColor.svg';
import { ReactComponent as JobListFlat } from '../../assets/icons/JobListFlat.svg';
import { ReactComponent as JobSearchFlat } from '../../assets/icons/JobSearchFlat.svg';
import { ReactComponent as LanguagesFlat } from '../../assets/icons/LanguagesFlat.svg';
import { ReactComponent as LanguagesGray } from '../../assets/icons/LanguagesGray.svg';
import { ReactComponent as Map } from '../../assets/icons/Map.svg';
import { ReactComponent as MapGray } from '../../assets/icons/MapGray.svg';
import { ReactComponent as MapPinFlat } from '../../assets/icons/MapPinFlat.svg';
import { ReactComponent as Mark } from '../../assets/icons/Mark.svg';
import { ReactComponent as MarkColor } from '../../assets/icons/MarkColor.svg';
import { ReactComponent as MessageColor } from '../../assets/icons/MessageColor.svg';
import { ReactComponent as MessageFlat } from '../../assets/icons/MessageFlat.svg';
import { ReactComponent as MetaAccommodationSize } from '../../assets/icons/meta-accommodation-size.svg';
import { ReactComponent as MetaAddressFrom } from '../../assets/icons/meta-address-from.svg';
import { ReactComponent as MetaAddressTo } from '../../assets/icons/meta-address-to.svg';
import { ReactComponent as MetaAdminComment } from '../../assets/icons/meta-admin-comment.svg';
import { ReactComponent as MetaCarModel } from '../../assets/icons/meta-car-model.svg';
import { ReactComponent as MetaCarRental } from '../../assets/icons/meta-car-rental.svg';
import { ReactComponent as MetaCleaningFrequency } from '../../assets/icons/meta-cleaning-frequency.svg';
import { ReactComponent as MetaContactPreference } from '../../assets/icons/meta-contact-preference.svg';
import { ReactComponent as MetaContainerSize } from '../../assets/icons/meta-container-size.svg';
import { ReactComponent as MetaCostEstimate } from '../../assets/icons/meta-cost-estimate.svg';
import { ReactComponent as MetaCustomerType } from '../../assets/icons/meta-customer-type.svg';
import { ReactComponent as MetaFoodAndDrink } from '../../assets/icons/meta-food-and-drink.svg';
import { ReactComponent as MetaFuelType } from '../../assets/icons/meta-fuel-type.svg';
import { ReactComponent as MetaLastRoadWorthinessTestDate } from '../../assets/icons/meta-last-road-worthiness-test-date.svg';
import { ReactComponent as MetaLeadsSpaceSize } from '../../assets/icons/meta-leads-space-size.svg';
import { ReactComponent as MetaMaFeedbackTicket } from '../../assets/icons/meta-ma-feedback-ticket.svg';
import { ReactComponent as MetaMaterialsIncluded } from '../../assets/icons/meta-materials-included.svg';
import { ReactComponent as MetaMoveOutCleaning } from '../../assets/icons/meta-move-out-cleaning.svg';
import { ReactComponent as MetaNextRoadWorthinessTestDate } from '../../assets/icons/meta-next-road-worthiness-test-date.svg';
import { ReactComponent as MetaNumberOfGuests } from '../../assets/icons/meta-number-of-guests.svg';
import { ReactComponent as MetaShaftCount } from '../../assets/icons/meta-shaft-count.svg';
import { ReactComponent as MetaStorageSize } from '../../assets/icons/meta-storage-size.svg';
import { ReactComponent as MetaTenderNotApprovedReason } from '../../assets/icons/meta-tender-not-approved-reason.svg';
import { ReactComponent as MetaTenderRequestedStartAfterDate } from '../../assets/icons/meta-tender-requested-start-after-date.svg';
import { ReactComponent as MetaTenderRequestedStartBeforeDate } from '../../assets/icons/meta-tender-requested-start-before-date.svg';
import { ReactComponent as MetaTenderRequestedStartNowDate } from '../../assets/icons/meta-tender-requested-start-now-date.svg';
import { ReactComponent as MetaTenderRequestedStartWheneverDate } from '../../assets/icons/meta-tender-requested-start-whenever-date.svg';
import { ReactComponent as MetaTenderStartDate } from '../../assets/icons/meta-tender-start-date.svg';
import { ReactComponent as MetaTransmissionType } from '../../assets/icons/meta-transmission-type.svg';
import { ReactComponent as MetaVehicleMileage } from '../../assets/icons/meta-vehicle-mileage.svg';
import { ReactComponent as MetaVehicleRegistrationPlate } from '../../assets/icons/meta-vehicle-registration-plate.svg';
import { ReactComponent as MetaWeight } from '../../assets/icons/meta-weight.svg';
import { ReactComponent as MissingIcon } from '../../assets/icons/MissingIcon.svg';
import { ReactComponent as MittanbudXL } from '../../assets/icons/MittanbudXL.svg';
import { ReactComponent as MobileFlat } from '../../assets/icons/MobileFlat.svg';
import { ReactComponent as MobileGray } from '../../assets/icons/MobileGray.svg';
import { ReactComponent as More } from '../../assets/icons/MoreFlat.svg';
import { ReactComponent as MountainGray } from '../../assets/icons/MountainGray.svg';
import { ReactComponent as NEW_Close } from '../../assets/icons/NEW_Close.svg';
import { ReactComponent as NEW_Info } from '../../assets/icons/NEW_Info.svg';
import { ReactComponent as NEW_Person } from '../../assets/icons/NEW_Person.svg';
import { ReactComponent as NEW_PersonInfo } from '../../assets/icons/NEW_PersonInfo.svg';
import { ReactComponent as NewsletterColor } from '../../assets/icons/NewsletterColor.svg';
import { ReactComponent as NewsletterFlat } from '../../assets/icons/NewsletterFlat.svg';
import { ReactComponent as NoImage } from '../../assets/icons/NoImage.svg';
import { ReactComponent as NoImageColor } from '../../assets/icons/NoImageColor.svg';
import { ReactComponent as NotificationFlat } from '../../assets/icons/NotificationFlat.svg';
import { ReactComponent as NotificationsGray } from '../../assets/icons/NotificationGray.svg';
import { ReactComponent as JobSearchGray } from '../../assets/icons/NotificationsGray.svg';
import { ReactComponent as OfferColor } from '../../assets/icons/OfferColor.svg';
import { ReactComponent as OfferGray } from '../../assets/icons/OfferGray.svg';
import { ReactComponent as PaletteColor } from '../../assets/icons/PaletteColor.svg';
import { ReactComponent as PaletteFlat } from '../../assets/icons/PaletteFlat.svg';
import { ReactComponent as PasswordFlat } from '../../assets/icons/PasswordFlat.svg';
import { ReactComponent as PasswordGray } from '../../assets/icons/PasswordGray.svg';
import { ReactComponent as PdfColor } from '../../assets/icons/PdfColor.svg';
import { ReactComponent as PencilFlat } from '../../assets/icons/PencilFlat.svg';
import { ReactComponent as Person } from '../../assets/icons/Person.svg';
import { ReactComponent as PersonColor } from '../../assets/icons/PersonColor.svg';
import { ReactComponent as PersonGray } from '../../assets/icons/PersonGray.svg';
import { ReactComponent as PhoneFlat } from '../../assets/icons/Phone.svg';
import { ReactComponent as PhoneGray } from '../../assets/icons/PhoneGray.svg';
import { ReactComponent as Plus } from '../../assets/icons/Plus.svg';
import { ReactComponent as PptColor } from '../../assets/icons/PptColor.svg';
import { ReactComponent as Print } from '../../assets/icons/PrintFlat.svg';
import { ReactComponent as ProductsFlat } from '../../assets/icons/ProductsFlat.svg';
import { ReactComponent as ProductsGray } from '../../assets/icons/ProductsGray.svg';
import { ReactComponent as ProfileColor } from '../../assets/icons/ProfileColor.svg';
import { ReactComponent as ProfileFlat } from '../../assets/icons/ProfileFlat.svg';
import { ReactComponent as QualificationsFlat } from '../../assets/icons/QualificationsFlat.svg';
import { ReactComponent as RandomCodeLines } from '../../assets/icons/RandomCodeLines.svg';
import { ReactComponent as ReceiptsFlat } from '../../assets/icons/ReceiptsFlat.svg';
import { ReactComponent as ReceiptsGray } from '../../assets/icons/ReceiptsGray.svg';
import { ReactComponent as Restore } from '../../assets/icons/RestoreBlack.svg';
import { ReactComponent as Revert } from '../../assets/icons/Revert.svg';
import { ReactComponent as Rocket } from '../../assets/icons/Rocket.svg';
import { ReactComponent as Sad } from '../../assets/icons/Sad.svg';
import { ReactComponent as SadFaceFlat } from '../../assets/icons/SadFaceFlat.svg';
import { ReactComponent as Search } from '../../assets/icons/Search.svg';
import { ReactComponent as SendMail } from '../../assets/icons/SendMail.svg';
import { ReactComponent as SendOfferFlat } from '../../assets/icons/SendOfferFlat.svg';
import { ReactComponent as SentBlack } from '../../assets/icons/SentBlack.svg';
import { ReactComponent as SettingsColor } from '../../assets/icons/SettingsColor.svg';
import { ReactComponent as SettingsFlat } from '../../assets/icons/SettingsFlat.svg';
import { ReactComponent as Share } from '../../assets/icons/Share.svg';
import { ReactComponent as ShieldCheckColor } from '../../assets/icons/shield-check-color.svg';
import { ReactComponent as ShieldCheckGray } from '../../assets/icons/shield-check-gray.svg';
import { ReactComponent as ShieldCheckFlat } from '../../assets/icons/shield-check.svg';
import { ReactComponent as ShieldFlat } from '../../assets/icons/ShieldFlat.svg';
import { ReactComponent as SignOutFlat } from '../../assets/icons/SignOutFlat.svg';
import { ReactComponent as SignOutGray } from '../../assets/icons/SignOutGray.svg';
import { ReactComponent as StarColor } from '../../assets/icons/StarColor.svg';
import { ReactComponent as StarFlat } from '../../assets/icons/StarFlat.svg';
import { ReactComponent as StarGreenColor } from '../../assets/icons/StarGreenColor.svg';
import { ReactComponent as StarRequestedColor } from '../../assets/icons/StarRequestedColor.svg';
import { ReactComponent as StarRequestedFlat } from '../../assets/icons/StarRequestedFlat.svg';
import { ReactComponent as StarRequestedGray } from '../../assets/icons/StarRequestedGray.svg';
import { ReactComponent as StarsColor } from '../../assets/icons/StarsColor.svg';
import { ReactComponent as SubscriptionFlat } from '../../assets/icons/SubscriptionFlat.svg';
import { ReactComponent as SubscriptionGray } from '../../assets/icons/SubscriptionGray.svg';
import { ReactComponent as SupportFlat } from '../../assets/icons/SupportFlat.svg';
import { ReactComponent as SupportGray } from '../../assets/icons/SupportGray.svg';
import { ReactComponent as SwapFlat } from '../../assets/icons/SwapFlat.svg';
import { ReactComponent as Team } from '../../assets/icons/Team.svg';
import { ReactComponent as Templates } from '../../assets/icons/TemplateFlat.svg';
import { ReactComponent as TemplatesColor } from '../../assets/icons/TemplatesColor.svg';
import { ReactComponent as TimeColor } from '../../assets/icons/TimeColor.svg';
import { ReactComponent as TimeFlat } from '../../assets/icons/TimeFlat.svg';
import { ReactComponent as ToolBoxColor } from '../../assets/icons/ToolBoxColor.svg';
import { ReactComponent as TrashBlack } from '../../assets/icons/TrashBlack.svg';
import { ReactComponent as TrashColor } from '../../assets/icons/TrashColor.svg';
import { ReactComponent as TrashGray } from '../../assets/icons/TrashGray.svg';
import { ReactComponent as WonFlat } from '../../assets/icons/TrophyFlat.svg';
import { ReactComponent as TrophyRequestedColor } from '../../assets/icons/TrophyRequestedColor.svg';
import { ReactComponent as TrophyRequestedFlat } from '../../assets/icons/TrophyRequestedFlat.svg';
import { ReactComponent as TrophyRequestedGray } from '../../assets/icons/TrophyRequestedGray.svg';
import { ReactComponent as TuneAlt } from '../../assets/icons/TuneAlt.svg';
import { ReactComponent as UnlockedColor } from '../../assets/icons/UnlockedColor.svg';
import { ReactComponent as UnlockedGray } from '../../assets/icons/UnlockedGray.svg';
import { ReactComponent as UserArrowDropdown } from '../../assets/icons/UserArrowDropdown.svg';
import { ReactComponent as UserColor } from '../../assets/icons/UserColor.svg';
import { ReactComponent as Report } from '../../assets/icons/WarningFlat.svg';
import { ReactComponent as WonColor } from '../../assets/icons/WonColor.svg';
import { ReactComponent as WonGold } from '../../assets/icons/WonGold.svg';
import { ReactComponent as WonGray } from '../../assets/icons/WonGray.svg';
import { ReactComponent as WordColor } from '../../assets/icons/WordColor.svg';
import { ReactComponent as WorkerFlat } from '../../assets/icons/WorkerFlat.svg';
import { ReactComponent as WorldWideWeb } from '../../assets/icons/WorldWideWeb.svg';
import { ReactComponent as XlsColor } from '../../assets/icons/XlsColor.svg';
import { ReactComponent as XpsColor } from '../../assets/icons/XpsColor.svg';
import IconPickerName from '../../utils/constants/iconPickerNames';

const icons: { [index: string]: IconVariantProps } = {
  [IconPickerName.AddEmployee]: {
    flat: AddEmployee,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Address]: {
    flat: Home,
    color: MissingIcon,
    gray: AddressGray,
  },
  [IconPickerName.Ads]: { flat: MissingIcon, color: AdsColor, gray: AdsGray },
  [IconPickerName.Areas]: {
    flat: AreasFlat,
    color: IncorrectIcon,
    gray: AreasGray,
  },
  [IconPickerName.ArrowDropdown]: {
    flat: ArrowDropdown,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ArrowLeft]: {
    flat: ArrowLeft,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Attachment]: {
    flat: AttachmentFlat,
    color: AttachmentColor,
    gray: AttachmentGray,
  },
  [IconPickerName.Attention]: {
    flat: AttentionFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.RandomCodeLines]: {
    flat: RandomCodeLines,
    color: RandomCodeLines,
    gray: RandomCodeLines,
  },
  [IconPickerName.BankIdVerified]: {
    flat: ShieldCheckFlat,
    color: ShieldCheckColor,
    gray: ShieldCheckGray,
  },
  [IconPickerName.Big]: {
    flat: MissingIcon,
    color: BigBenColor,
    gray: MountainGray,
  },
  [IconPickerName.BoligMappaNoCircle]: {
    flat: BoligMappaNoCircle,
    color: BoligMappaNoCircle,
    gray: IncorrectIcon,
  },
  [IconPickerName.Book]: {
    flat: IncorrectIcon,
    color: BookColor,
    gray: BookGray,
  },
  [IconPickerName.BurgerMenu]: {
    flat: BurgerMenu,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Calendar]: {
    flat: CalendarFlat,
    color: CalendarColor,
    gray: CalendarGray,
  },
  [IconPickerName.Camera]: {
    flat: CameraFlat,
    color: CameraColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.CancelCircle]: {
    flat: CancelCircle,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Caution]: {
    flat: MissingIcon,
    color: CautionColor,
    gray: MissingIcon,
  },
  [IconPickerName.Chat]: {
    flat: NEW_ChatFlat,
    color: IncorrectIcon,
    gray: ChatGray,
  },
  [IconPickerName.Check]: {
    flat: Check,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.CheckmarkCircled]: {
    flat: CheckmarkCircled,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronDown]: {
    flat: ChevronDown,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronLeft]: {
    flat: ChevronLeft,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronRight]: {
    flat: ChevronRight,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronUp]: {
    flat: ChevronUp,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronLeftBold]: {
    flat: ChevronLeftBold,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronRightBold]: {
    flat: ChevronRightBold,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.ChevronUpBold]: {
    flat: ChevronUpBold,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Copy]: {
    flat: CopyFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Close]: {
    flat: Close,
    color: CloseColor,
    gray: IncorrectIcon,
  },

  [IconPickerName.CompanyInfo]: {
    flat: CompanyFlat,
    color: CompanyColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Customers]: {
    flat: CustomersFlat,
    color: CustomersColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Contract]: {
    flat: ContractFlat,
    color: ContractColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Decrease]: {
    flat: Decrease,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Delete]: {
    flat: DeleteFlat,
    color: DeleteColor,
    gray: DeleteGray,
  },
  [IconPickerName.Deleted]: {
    flat: TrashBlack,
    color: TrashColor,
    gray: TrashGray,
  },
  [IconPickerName.Doffin]: {
    flat: IncorrectIcon,
    color: DoffinColor,
    gray: DoffinGray,
  },
  [IconPickerName.Download]: {
    flat: DownloadFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Email]: {
    flat: EmailFlat,
    color: EmailColor,
    gray: MissingIcon,
  },
  [IconPickerName.Ended]: {
    flat: ClosedBlack,
    color: CloseColor,
    gray: ClosedGray,
  },
  [IconPickerName.Enlarge]: {
    flat: Enlarge,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Evaluation]: {
    flat: StarFlat,
    color: StarColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.EmailNotification]: {
    flat: EmailNotificationFlat,
    color: EmailNotificationColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Favourite]: {
    flat: Favourite,
    color: FavouriteColor,
    gray: FavouriteGray,
  },
  [IconPickerName.FilledInfo]: {
    flat: FilledInfo,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Fire]: {
    flat: FireFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.FlagFI]: {
    flat: IncorrectIcon,
    color: FlgFi,
    gray: IncorrectIcon,
  },
  [IconPickerName.FlagNO]: {
    flat: IncorrectIcon,
    color: FlgNo,
    gray: IncorrectIcon,
  },
  [IconPickerName.FlagUK]: {
    flat: IncorrectIcon,
    color: FlgUk,
    gray: IncorrectIcon,
  },
  [IconPickerName.FlagSV]: {
    flat: IncorrectIcon,
    color: FlgSe,
    gray: IncorrectIcon,
  },
  [IconPickerName.FlagDK]: {
    flat: IncorrectIcon,
    color: FlgDk,
    gray: IncorrectIcon,
  },
  [IconPickerName.Folder]: {
    flat: Folder,
    color: FolderColor,
    gray: FolderGray,
  },
  [IconPickerName.FolderTransparent]: {
    flat: FolderTransparent,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Free]: {
    flat: MissingIcon,
    color: DiscountColor,
    gray: HappyGray,
  },
  [IconPickerName.Home]: { flat: Home, color: MissingIcon, gray: MissingIcon },
  [IconPickerName.House]: {
    flat: House,
    color: MissingIcon,
    gray: AddressGray,
  },
  [IconPickerName.Images]: {
    flat: ImagesFlat,
    color: ImagesColor,
    gray: ImagesGray,
  },
  [IconPickerName.Info]: { flat: Info, color: MissingIcon, gray: InfoGray },
  [IconPickerName.Invoice]: {
    flat: InvoiceFlat,
    color: InvoiceColor,
    gray: InvoiceGray,
  },
  [IconPickerName.InvoiceNav]: {
    flat: IncorrectIcon,
    color: InvoiceNavColor,
    gray: InvoiceNavGray,
  },
  [IconPickerName.JobList]: {
    flat: JobListFlat,
    color: JobListColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.JobSearch]: {
    flat: JobSearchFlat,
    color: IncorrectIcon,
    gray: JobSearchGray,
  },
  [IconPickerName.Languages]: {
    flat: LanguagesFlat,
    color: IncorrectIcon,
    gray: LanguagesGray,
  },
  [IconPickerName.Map]: { flat: Map, color: MissingIcon, gray: MapGray },
  [IconPickerName.MapPin]: {
    flat: MapPinFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Mark]: { flat: Mark, color: MarkColor, gray: MissingIcon },
  [IconPickerName.Message]: {
    flat: MessageFlat,
    color: MessageColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.MetaAccommodationSize]: {
    flat: MetaAccommodationSize,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaAddressFrom]: {
    flat: MetaAddressFrom,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaAddressTo]: {
    flat: MetaAddressTo,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaAdminComment]: {
    flat: MetaAdminComment,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaCarModel]: {
    flat: MetaCarModel,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaCarRental]: {
    flat: MetaCarRental,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaCleaningFrequency]: {
    flat: MetaCleaningFrequency,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaContactPreference]: {
    flat: MetaContactPreference,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaContainerSize]: {
    flat: MetaContainerSize,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaCostEstimate]: {
    flat: MetaCostEstimate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaCustomerType]: {
    flat: MetaCustomerType,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaFallback]: {
    flat: Info,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaFoodAndDrink]: {
    flat: MetaFoodAndDrink,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaFuelType]: {
    flat: MetaFuelType,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaLastRoadWorthinessTestDate]: {
    flat: MetaLastRoadWorthinessTestDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaLeadsSpaceSize]: {
    flat: MetaLeadsSpaceSize,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaMaFeedbackTicket]: {
    flat: MetaMaFeedbackTicket,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaMaterialsIncluded]: {
    flat: MetaMaterialsIncluded,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaMoveOutCleaning]: {
    flat: MetaMoveOutCleaning,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaNextRoadWorthinessTestDate]: {
    flat: MetaNextRoadWorthinessTestDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaNumberOfGuests]: {
    flat: MetaNumberOfGuests,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaShaftCount]: {
    flat: MetaShaftCount,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaStorageSize]: {
    flat: MetaStorageSize,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderNotApprovedReason]: {
    flat: MetaTenderNotApprovedReason,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderRequestedStartAfterDate]: {
    flat: MetaTenderRequestedStartAfterDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderRequestedStartBeforeDate]: {
    flat: MetaTenderRequestedStartBeforeDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderRequestedStartNowDate]: {
    flat: MetaTenderRequestedStartNowDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderRequestedStartWheneverDate]: {
    flat: MetaTenderRequestedStartWheneverDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTenderStartDate]: {
    flat: MetaTenderStartDate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaTransmissionType]: {
    flat: MetaTransmissionType,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaVehicleMileage]: {
    flat: MetaVehicleMileage,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaVehicleRegistrationPlate]: {
    flat: MetaVehicleRegistrationPlate,
    color: MissingIcon,
    gray: MissingIcon,
  },
  [IconPickerName.MetaWeight]: {
    flat: MetaWeight,
    color: MissingIcon,
    gray: MissingIcon,
  },
  // [IconPickerName.MittanbudLogo]: { flat: MissingIconFlat, color: MittanbudLogo, gray: MittanbudLogoDark },
  [IconPickerName.MittanbudXL]: {
    flat: MittanbudXL,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Mobile]: {
    flat: MobileFlat,
    color: IncorrectIcon,
    gray: MobileGray,
  },
  [IconPickerName.More]: {
    flat: More,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  // [IconPickerName.MyAnswers]: { flat: SentBlack, color: SentColor, gray: SentGray },
  // [IconPickerName.NavigationAnswered]: { flat: IncorrectIcon, color: SentColor, gray: SentGray },
  // [IconPickerName.NavigationBig]: { flat: IncorrectIcon, color: NavigationBigColor, gray: NavigationBigGray },
  // [IconPickerName.NavigationDeleted]: {
  //   flat: IncorrectIcon,
  //   color: NavigationDeletedColor,
  //   gray: NavigationDeletedGray,
  // },
  // [IconPickerName.NavigationFavourite]: {
  //   flat: IncorrectIcon,
  //   color: NavigationFavouriteColor,
  //   gray: NavigationFavouriteGray,
  // },
  [IconPickerName.NavigationFolders]: {
    flat: Folder,
    color: FolderColor,
    gray: FolderGray,
  },
  // [IconPickerName.NavigationFree]: { flat: IncorrectIcon, color: NavigationFreeColor, gray: NavigationFreeGray },
  // [IconPickerName.NavigationOpen]: { flat: IncorrectIcon, color: JobListColorHard, gray: JobListGray },
  // [IconPickerName.NavigationWon]: { flat: IncorrectIcon, color: NavigationWonColor, gray: NavigationWonGray },
  // [IconPickerName.New]: {
  //   flat: IncorrectIcon,
  //   color: JobListColorHard,
  //   gray: JobListGray,
  // },
  [IconPickerName.Newsletter]: {
    flat: NewsletterFlat,
    color: NewsletterColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.NoImage]: {
    flat: NoImage,
    color: NoImageColor,
    gray: MissingIcon,
  },
  [IconPickerName.Notifications]: {
    flat: NotificationFlat,
    color: IncorrectIcon,
    gray: NotificationsGray,
  },
  [IconPickerName.Offer]: {
    flat: IncorrectIcon,
    color: OfferColor,
    gray: OfferGray,
  },
  [IconPickerName.Palette]: {
    flat: PaletteFlat,
    color: PaletteColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Password]: {
    flat: PasswordFlat,
    color: IncorrectIcon,
    gray: PasswordGray,
  },
  [IconPickerName.Pdf]: {
    flat: IncorrectIcon,
    color: PdfColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Pencil]: {
    flat: PencilFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Person]: {
    flat: Person,
    color: PersonColor,
    gray: PersonGray,
  },
  // [IconPickerName.PersonInfo]: {
  //   flat: PersonInfo,
  //   color: ProfileColorHard,
  //   gray: ProfileGray,
  // },
  [IconPickerName.Phone]: {
    flat: PhoneFlat,
    color: IncorrectIcon,
    gray: PhoneGray,
  },
  [IconPickerName.Plus]: {
    flat: Plus,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Ppt]: { flat: Plus, color: PptColor, gray: IncorrectIcon },
  [IconPickerName.Print]: {
    flat: Print,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Products]: {
    flat: ProductsFlat,
    color: IncorrectIcon,
    gray: ProductsGray,
  },
  [IconPickerName.Profile]: {
    flat: ProfileFlat,
    color: ProfileColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Qualifications]: {
    flat: QualificationsFlat,
    color: IncorrectIcon,
    gray: MissingIcon,
  },
  [IconPickerName.Receipts]: {
    flat: ReceiptsFlat,
    color: IncorrectIcon,
    gray: ReceiptsGray,
  },
  [IconPickerName.RemoveFavourite]: {
    flat: CrossedOutHeartFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Report]: {
    flat: Report,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Restore]: {
    flat: Restore,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  // [IconPickerName.RemppatoriLogo]: { flat: MissingIconFlat, color: RemppatoriLogo, gray: RemppatoriLogoDark },
  [IconPickerName.Revert]: {
    flat: Revert,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Rocket]: {
    flat: Rocket,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Sad]: {
    flat: Sad,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Team]: {
    flat: Team,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Clock]: {
    flat: Clock,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.NEW_PersonInfo]: {
    flat: NEW_PersonInfo,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.NEW_Person]: {
    flat: NEW_Person,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.SadFace]: {
    flat: SadFaceFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Search]: {
    flat: Search,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.SendMail]: {
    flat: SendMail,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.SendOffer]: {
    flat: SendOfferFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Settings]: {
    flat: SettingsFlat,
    color: SettingsColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Share]: {
    flat: Share,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Shield]: {
    flat: ShieldFlat,
    color: IncorrectIcon,
    gray: SignOutGray,
  },
  [IconPickerName.ShieldCheck]: {
    flat: ShieldCheckFlat,
    color: ShieldCheckColor,
    gray: ShieldCheckGray,
  },
  [IconPickerName.SignOut]: {
    flat: SignOutFlat,
    color: IncorrectIcon,
    gray: SignOutGray,
  },
  [IconPickerName.Star]: {
    flat: StarFlat,
    color: StarColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.StarGreen]: {
    flat: MissingIcon,
    color: StarGreenColor,
    gray: MissingIcon,
  },
  [IconPickerName.Stars]: {
    flat: MissingIcon,
    color: StarsColor,
    gray: MissingIcon,
  },
  [IconPickerName.Subscription]: {
    flat: SubscriptionFlat,
    color: IncorrectIcon,
    gray: SubscriptionGray,
  },
  [IconPickerName.Support]: {
    flat: SupportFlat,
    color: IncorrectIcon,
    gray: SupportGray,
  },
  [IconPickerName.Swap]: {
    flat: SwapFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Templates]: {
    flat: Templates,
    color: TemplatesColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Time]: {
    flat: TimeFlat,
    color: TimeColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Toolbox]: {
    flat: IncorrectIcon,
    color: ToolBoxColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.TuneAlt]: {
    flat: TuneAlt,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Unlocked]: {
    flat: SentBlack,
    color: UnlockedColor,
    gray: UnlockedGray,
  },
  [IconPickerName.User]: {
    flat: IncorrectIcon,
    color: UserColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.UserArrowDropdown]: {
    flat: UserArrowDropdown,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Won]: { flat: WonFlat, color: WonColor, gray: WonGray },
  [IconPickerName.WonGold]: {
    flat: MissingIcon,
    color: WonGold,
    gray: IncorrectIcon,
  },
  [IconPickerName.Word]: {
    flat: IncorrectIcon,
    color: WordColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Worker]: {
    flat: WorkerFlat,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.WorldWideWeb]: {
    flat: WorldWideWeb,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.Xls]: {
    flat: IncorrectIcon,
    color: XlsColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Xps]: {
    flat: IncorrectIcon,
    color: XpsColor,
    gray: IncorrectIcon,
  },
  [IconPickerName.Globe]: {
    flat: Globe,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },

  // New icons map
  [IconPickerName.NEW_Close]: {
    flat: NEW_Close,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  [IconPickerName.NEW_Info]: {
    flat: NEW_Info,
    color: IncorrectIcon,
    gray: IncorrectIcon,
  },
  // Header Desktop Nav
  [IconPickerName.NEW_ChatFlat]: {
    flat: NEW_ChatFlat,
    color: NEW_ChatColorFlat,
    gray: NEW_ChatFlat,
  },
  // [IconPickerName.NEW_JobListFlat]: {
  //   flat: NEW_JobListFlat,
  //   color: NEW_JobListColorFlat,
  //   gray: NEW_JobListFlat,
  // },

  // [IconPickerName.NEW_ProfileFlat]: {
  //   flat: NEW_ProfileFlat,
  //   color: NEW_ProfileColorFlat,
  //   gray: NEW_ProfileFlat,
  // },
  // [IconPickerName.NEW_SettingsFlat]: {
  //   flat: NEW_SettingsNewFlat,
  //   color: NEW_SettingsNewColorFlat,
  //   gray: NEW_SettingsNewFlat,
  // },
  // Mobile Nav
  [IconPickerName.NEW_ChatFlatMobile]: {
    flat: NEW_ChatFlatMobile,
    color: NEW_ChatColorFlatMobile,
    gray: NEW_ChatFlatMobile,
  },
  // [IconPickerName.NEW_JobListFlatMobile]: {
  //   flat: NEW_JobListFlatMobile,
  //   color: NEW_JobListColorFlatMobile,
  //   gray: NEW_JobListFlatMobile,

  // [IconPickerName.NEW_ProfileFlatMobile]: {
  //   flat: NEW_ProfileFlatMobile,
  //   color: NEW_ProfileColorFlatMobile,
  //   gray: NEW_ProfileFlatMobile,
  // },
  // [IconPickerName.NEW_SettingsFlatMobile]: {
  //   flat: NEW_SettingsNewFlatMobile,
  //   color: NEW_SettingsNewColorFlatMobile,
  //   gray: NEW_SettingsNewFlatMobile,
  // },
  [IconPickerName.StarRequested]: {
    flat: StarRequestedFlat,
    color: StarRequestedColor,
    gray: StarRequestedGray,
  },
  [IconPickerName.TrophyRequested]: {
    flat: TrophyRequestedFlat,
    color: TrophyRequestedColor,
    gray: TrophyRequestedGray,
  },
  [IconPickerName.HexHeadFlat]: {
    flat: HexHeadFlat,
    color: HexHeadFlat,
    gray: IncorrectIcon,
  },
  [IconPickerName.ClipboardCancel]: {
    flat: ClipboardCancelFlat,
    color: ClipboardCancelColor,
    gray: IncorrectIcon,
  },
};

interface IconVariantProps {
  flat: React.FC<IconProps>;
  color?: React.FC<IconProps>;
  gray?: React.FC<IconProps>;
}

export type IconVariant = 'flat' | 'color' | 'gray';

type IconProps = Pick<SvgProps, 'fill' | 'width' | 'height'>;

export type PickerProps = {
  iconName: IconPickerName;
  variant: IconVariant;
} & IconProps;

const IconPicker = ({
  iconName,
  variant,
  width,
  height,
  fill = '#000000',
}: PickerProps) => {
  const Component = icons[iconName]?.[variant];

  if (!Component) {
    return null;
  }

  const iconProps: SvgProps =
    variant === 'flat' ? { width, height, fill } : { width, height };

  return <Component {...iconProps} />;
};

export default IconPicker;
