import gql from 'graphql-tag';

export const SOCIAL_MEDIA_FRAGMENT = gql`
  fragment SocialMediaFragment on BusinessSocialMedia {
    facebook
    id
    instagram
    tiktok
    website
    youtube
  }
`;
