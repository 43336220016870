import { useContext, useMemo } from 'react';

import { NotifiableError } from '@bugsnag/js';
import DropdownItemButton from '@components/base/DropdownItemButton';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  LanguageCode,
  supportedLanguages,
} from '@internals/business-translations/src/languages';
import { bugsnagClient } from '@root/src/utils/initBugsnag';
import ToastMessage from '@root/src/utils/ToastMessage';
import { Dropdown, Text, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';

import { FireIcon } from '../../base/FireIcon/FireIcon';
import FooterItem from './styled/FooterItem';

const LanguagePicker: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { i18n, t } = useTranslation();

  const languageLabel = useMemo(
    (): Record<LanguageCode, string> => ({
      en: t('settings.languagePicker.languages.english'),
      nb: t('settings.languagePicker.languages.norwegian'),
      fi: t('settings.languagePicker.languages.finnish'),
      da: t('settings.languagePicker.languages.danish'),
      sv: t('settings.languagePicker.languages.swedish'),
    }),
    [t]
  );
  const handleLangChange = (langValue: string): void => {
    i18n.changeLanguage(langValue).catch((error: NotifiableError) => {
      ToastMessage(t('settings.languagePicker.error'), 'danger');
      bugsnagClient.notify(error);
    });
  };

  return (
    <Div mt={2}>
      <Dropdown
        openedDropdownMargin={theme.space[2]}
        borderRadius={theme.radii[3]}
        width={244}
        items={supportedLanguages.map(({ languageCode, name }) => (
          <DropdownItemButton
            iconSize="small"
            key={`dropdown-item-${name}`}
            onClick={() => handleLangChange(languageCode)}
          >
            {languageCode === i18n.language ? (
              <FireIcon
                width={24}
                height={24}
                iconName={IconPickerName.Check}
                variant="flat"
                fill={theme.colors.black.black7}
              />
            ) : (
              <Div width={24} height={24} />
            )}
            <Text.span ml={4} mb={0}>
              {languageLabel[languageCode]}
            </Text.span>
          </DropdownItemButton>
        ))}
        closeOnItemClick
      >
        <div>
          <Div
            width="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Div
              margin={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FireIcon
                width={12}
                height={12}
                iconName={IconPickerName.Globe}
                variant="flat"
                fill={theme.colors.black.black7}
              />
            </Div>
            <FooterItem>
              {languageLabel[i18n.language as LanguageCode]}
            </FooterItem>
            <Div
              ml={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <FireIcon
                width={12}
                height={12}
                iconName={IconPickerName.ArrowDropdown}
                variant="flat"
                fill={theme.colors.black.black7}
              />
            </Div>
          </Div>
        </div>
      </Dropdown>
    </Div>
  );
};

export default LanguagePicker;
