import { Link, Text } from '@schibsted-smb/fireball';
import styled, { css } from 'styled-components';

const styles = css`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme }) => theme.colors.black.black7};
  margin: 0;

  &:not(:last-child) {
    &:after {
      content: '\\00B7'; // vertically centered dot
      margin: 0 5px;
    }
  }
`;

export const FooterItem = styled(Text.span)`
  ${styles}
`;

export const FooterLinkItem = styled(Link)`
  ${styles};
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.black.black7};
    text-decoration: none;
  }
`;
