import { FC, useCallback, useEffect, useRef } from 'react';

import { PillNavigationFilterButton } from '@components/elements/PillNavigation/PillNavigationFilterButton';
import { scrollToItem } from '@components/elements/PillNavigation/usePillNavigationScroll';
import {
  PillNavigationItem,
  PillNavigationItemType,
} from '@components/elements/PillNavigationItem';
import { StyledPillNavigationItemContainer } from '@components/elements/PillNavigationItem/styled';
import Filters from '@components/layout/Jobs/Filters';
import { JobsFiltersProvider } from '@contexts/JobFiltersContext';
import useAnalytics from '@hooks/useAnalytics';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Div, Drawer, Heading } from '@schibsted-smb/fireball';

import { StyledPillNavigationMobile } from './styled';

interface PillNavigationMobileProps {
  items: PillNavigationItemType[];
  title: string;
}

export const PillNavigationMobile: FC<PillNavigationMobileProps> = ({
  items,
  title,
}) => {
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { track } = useAnalytics();
  const [showFilterSelector, openFilterSelector, closeFilterSelector] =
    useBoolean(false);

  useEffect(() => {
    const activeItemIndex = items.findIndex((item) => item.isActive);
    if (activeItemIndex !== -1) {
      scrollToItem({ itemsContainerRef, itemRefs, isMobile: true })(
        activeItemIndex
      );
    }
  }, [items]);

  const onFilterClick = useCallback(() => {
    openFilterSelector();
    track(ANEventSpace(ANEvent.Opened, ANObject.Filter, ANPage.JobList));
  }, [openFilterSelector, track]);

  return (
    <JobsFiltersProvider>
      <Heading.h3 mt={3} mb={5} truncate textAlign="center">
        {title}
      </Heading.h3>
      <Div display="flex" alignItems="center" minHeight="36px">
        <PillNavigationFilterButton onClick={onFilterClick} />
        <StyledPillNavigationMobile ref={itemsContainerRef}>
          {items.map((item, index) => (
            <StyledPillNavigationItemContainer
              key={item.id}
              ref={(el) => {
                itemRefs.current[index] = el;
              }}
            >
              <PillNavigationItem item={item} />
            </StyledPillNavigationItemContainer>
          ))}
        </StyledPillNavigationMobile>
      </Div>
      <Drawer
        isOpen={showFilterSelector}
        onClose={closeFilterSelector}
        withCloseButton={false}
        modalContentStyles={{ height: '80%' }}
        position="bottom"
      >
        <Filters mobileView onClose={closeFilterSelector} />
      </Drawer>
    </JobsFiltersProvider>
  );
};
