import { FC } from 'react';

import { StyledPillNavigationItem } from '@components/elements/PillNavigationItem/styled';

export interface PillNavigationItemType {
  id: string;
  label: string;
  link: string;
  isActive: boolean;
  testId?: string;
  onClick?: () => void;
}

interface PillNavigationItemProps {
  item: PillNavigationItemType;
}

export const PillNavigationItem: FC<PillNavigationItemProps> = ({ item }) => {
  return (
    <StyledPillNavigationItem
      id={item.id}
      to={item.link}
      className={item.isActive ? 'is-active' : ''}
      data-testid={item.testId ?? `nav-item-${item.id}`}
      data-tooltip-id={item.id}
      onClick={item.onClick}
    >
      {item.label}
    </StyledPillNavigationItem>
  );
};
