import { Textarea } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledTextInput = styled(Textarea)`
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]};

  &,
  &:hover,
  &:focus {
    border: none;
    padding: 0;
    box-shadow: none;
  }
`;
