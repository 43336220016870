import { FC } from 'react';

import { Integrations } from '@components/layout/Settings/Integrations';
import withNavigationLayout from '@components/layout/WithNavigation';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsIntegrations: FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: Integrations,
      subpageName: t('settings.integrations.title'),
    },
  })();
};
