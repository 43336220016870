import { DAY_KEYS } from '../constants/days';
import { GQLOpeningHours } from '../interfaces/graphql/OpeningHours.interface';
import { OpeningHours } from './OpeningHours.interface';

const GQLOpeningHours2OpeningHours = (
  openingHours: GQLOpeningHours,
  dayLabels: string[]
): OpeningHours[] => {
  return DAY_KEYS.map((day, index) => ({
    dayNumber: index,
    from: openingHours ? openingHours[day]?.from || '' : '',
    to: openingHours ? openingHours[day]?.to || '' : '',
    dayLabel: dayLabels[index],
    visible: true,
    muted: false,
  }));
};

export default GQLOpeningHours2OpeningHours;
