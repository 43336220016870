import { FC } from 'react';

import { MultilineText } from '@components/base/MultilineText';
import Error from '@components/layout/CustomerService/Evaluation/EvaluationRequest/EvaluationRequestJobListError';
import { useChatFirstMessageQuery } from '@internals/business-shared/src/hooks/query/useChatFirstMessage';
import { Div, Heading, Spinner } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

import { JobContentSection } from './styled/JobContentSection';

interface JobAnsweredMobileProps {
  chatId: string;
}

export const JobAnsweredMobile: FC<JobAnsweredMobileProps> = ({ chatId }) => {
  const { t } = useTranslation();
  const { data, error, loading, refetch } = useChatFirstMessageQuery(chatId);
  const { text: message } = data || {};

  if (loading) {
    return (
      <Div
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="200px"
        data-testid="content-loader"
      >
        <Spinner size={4} />
      </Div>
    );
  }

  if (error || message === undefined) {
    return <Error onRefetch={refetch} />;
  }

  return (
    <JobContentSection>
      <Heading.h5 textAlign="left">
        {t('job.item.answer.yourAnswer')}
      </Heading.h5>
      <MultilineText>{message}</MultilineText>
    </JobContentSection>
  );
};
