import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const CollapsedRow = styled(Div)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 45px;
  padding: 0;
  &:hover {
    cursor: pointer;
    border-radius: 2px;
    background-color: ${({ theme }): string => theme.colors.black.black1};
  }
`;
