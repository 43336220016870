import * as React from 'react';

import { JobNavigationProvider } from '@contexts/JobNavigationContext';

import JobView from './Job';

export interface JobProps {
  id: string;
  onClose: () => void;
}

const Job: React.FC<React.PropsWithChildren<JobProps>> = ({
  id,
  onClose,
}: JobProps) => {
  return (
    <JobNavigationProvider>
      <JobView id={id} onClose={onClose} />
    </JobNavigationProvider>
  );
};

export default Job;
