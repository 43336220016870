import {
  CompanyStateAlertType,
  StateType,
  UnpaidOrderType,
} from '@internals/business-shared/src/utils/companyState/getCompanyState';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { BoostAlertType } from '@root/src/components/elements/CompanyStateAlert/useMobileBoostAlert';
import { t } from '@utils/i18n';
import { TOptions } from 'i18next';

export interface CompanyStateAlertText {
  title: string;
  subtitle: string;
}

export type CompanyStateAlertVariant = 'warning' | 'danger' | 'info';

export enum CompanyStateAction {
  OpenInvoice = 'open-invoice',
  GoToCustomerService = 'go-to-cs',
  GoToPayment = 'go-to-payment',
  RequestContact = 'request-contact',
  OpenBoostModal = 'open-boost-modal',
}

interface CompanyStateAlert {
  variant: CompanyStateAlertVariant;
  text: (transVars: TOptions) => CompanyStateAlertText;
  icon?: IconPickerName;
  action?: CompanyStateAction;
  dismissible?: boolean;
}

export const getCompanyStateBannerData = (
  state: CompanyStateAlertType | BoostAlertType
): CompanyStateAlert => {
  const data: Partial<
    Record<CompanyStateAlertType | BoostAlertType, CompanyStateAlert>
  > = {
    [BoostAlertType.MobileAlert]: {
      variant: 'info',
      text: () => ({
        title: t('job.list.alert.boostMobileAlert.title'),
        subtitle: t('job.list.alert.boostMobileAlert.message'),
      }),
      dismissible: true,
      action: CompanyStateAction.OpenBoostModal,
    },
    [StateType.AccessUnverified]: {
      variant: 'info',
      text: ({ productName }) => ({
        title: t('company.state.accessUnverified.title', { productName }),
        subtitle: t('company.state.accessUnverified.message'),
      }),
      icon: IconPickerName.Wave,
      dismissible: true,
      action: CompanyStateAction.RequestContact,
    },
    [StateType.AccessVerifiedLacksProducts]: {
      variant: 'info',
      text: ({ productName }) => ({
        title: t('company.state.accessVerifiedLacksProducts.title'),
        subtitle: t('company.state.accessVerifiedLacksProducts.message', {
          productName,
        }),
      }),
      action: CompanyStateAction.RequestContact,
      dismissible: true,
    },
    [StateType.LacksSubscriptions]: {
      variant: 'info',
      text: ({ productName }) => ({
        title: t('company.state.accessVerifiedLacksProducts.title'),
        subtitle: t('company.state.accessVerifiedLacksProducts.message', {
          productName,
        }),
      }),
      action: CompanyStateAction.RequestContact,
      dismissible: true,
    },
    [StateType.ProductsExpired]: {
      variant: 'info',
      text: ({ productName }) => ({
        title: t('company.state.productsExpired.title'),
        subtitle: t('company.state.productsExpired.message', { productName }),
      }),
      action: CompanyStateAction.RequestContact,
      dismissible: true,
    },
    // this will show general unpaid alert fallback if unpaid invoices fetching failed
    [StateType.OrderUnpaid]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderUnpaid.title'),
        subtitle: t('company.state.orderUnpaid.message'),
      }),
      action: CompanyStateAction.GoToCustomerService,
    },
    [UnpaidOrderType.OrderUnpaidInvoiceNotStarted]: {
      variant: 'warning',
      text: ({ productName }) => ({
        title: t('company.state.common.invoiceWaiting.title'),
        subtitle: t('company.state.common.unpaidInvoice.message', {
          productName,
        }),
      }),
      action: CompanyStateAction.OpenInvoice,
      dismissible: true,
    },
    [UnpaidOrderType.OrderUnpaidCardNotStarted]: {
      variant: 'warning',
      text: ({ productName }) => ({
        title: t('company.state.orderUnpaidCardNotStarted.title'),
        subtitle: t('company.state.orderUnpaidCardNotStarted.message', {
          productName,
        }),
      }),
      action: CompanyStateAction.GoToPayment,
      dismissible: true,
    },
    [UnpaidOrderType.OrderInvoiceDue]: {
      variant: 'warning',
      text: () => ({
        title: t('company.state.common.unpaidInvoice.title'),
        subtitle: t('company.state.orderInvoiceDue.message'),
      }),
      action: CompanyStateAction.OpenInvoice,
      dismissible: true,
    },
    [UnpaidOrderType.OrderOverdueInvoice]: {
      variant: 'danger',
      text: ({ phoneNumber }) => ({
        title: t('company.state.common.unpaidInvoice.title'),
        subtitle: t('company.state.orderOverdueInvoice.message', {
          phoneNumber,
        }),
      }),
      action: CompanyStateAction.OpenInvoice,
    },
    [UnpaidOrderType.OrderCardPaymentFailed]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailed.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedRejected]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedRejected.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedInvalidAmount]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t(
          'company.state.orderCardPaymentFailedInvalidAmount.message'
        ),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedFunds]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedFunds.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedCardDetails]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedCardDetails.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedCardType]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedCardType.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedCardExpired]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedCardExpired.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedCardExpiredOrWrongDate]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t(
          'company.state.orderCardPaymentFailedCardExpiredOrWrongDate.message'
        ),
      }),
      action: CompanyStateAction.GoToPayment,
    },
    [UnpaidOrderType.OrderCardPaymentFailedCardDeclined]: {
      variant: 'danger',
      text: () => ({
        title: t('company.state.orderCardPaymentFailed.title'),
        subtitle: t('company.state.orderCardPaymentFailedCardDeclined.message'),
      }),
      action: CompanyStateAction.GoToPayment,
    },
  };

  return data[state] ?? null;
};
