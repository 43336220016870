import { useEffect, useMemo, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { BusinessUser } from '../../types/User';
import { userToTraits, UserTraits } from '../../utils/analyticsTraits';
import { APOLLO_ERROR_HANDLING_HIDE_TOAST } from '../../utils/constants/apollo';
import {
  IDENTITY_QUERY,
  IdentityQueryPayload,
  IdentityQueryVariables,
  isIdentityQuerySuccessResponse,
} from '../../utils/query/Identity/IdentityQuery';

interface UseIdentifyUser {
  data: UserTraits;
  loading: boolean;
}
export const useIdentifyData = (user: BusinessUser): UseIdentifyUser => {
  const [traits, setTraits] = useState<UserTraits | null>(null);
  const [identityQuery, { called, loading }] = useLazyQuery<
    IdentityQueryPayload,
    IdentityQueryVariables
  >(IDENTITY_QUERY, {
    onCompleted: (data) => {
      if (!isIdentityQuerySuccessResponse(data?.business)) {
        return;
      }
      setTraits(userToTraits(user, data));
    },
  });

  useEffect(() => {
    if (called) {
      return;
    }
    if (!user?.operatingAs?.id) {
      return;
    }
    identityQuery({
      fetchPolicy: 'cache-first',
      variables: {
        id: user.operatingAs.id,
      },
      context: {
        [APOLLO_ERROR_HANDLING_HIDE_TOAST]: true,
      },
    });
  }, [user, called, identityQuery]);

  return useMemo(() => ({ data: traits, loading }), [traits, loading]);
};
