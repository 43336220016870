import { Button, Div, Heading, Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledProductSectionTitle = styled(Heading.h4)`
  margin-bottom: ${({ theme }) => theme.space[5]}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StyledProductCard = styled(Div)<{ withIcon?: boolean }>`
  display: flex;
  border: 1px solid ${({ theme }) => theme.colors.beige.darkest};
  background-color: ${({ theme }) => theme.colors.beige.light};
  border-radius: ${({ theme }) => theme.radii[3]};
  overflow: hidden;
  ${({ withIcon }) =>
    withIcon &&
    `
      justify-content: space-between;
      min-height: 150px;
  `}
`;

export const StyledProductContent = styled(Div)<{
  withIcon?: boolean;
  width?: string;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
  padding: ${({ theme }) => theme.space[5]}px;
  ${({ withIcon }) =>
    withIcon &&
    `
      padding-right: 0;
      align-items: flex-start;
  `}
`;

export const StyledProductRewardIconContainer = styled(Div)`
  position: absolute;
  transform: rotate(8deg);
`;

export const StyledProductInfoLabel = styled(Text.p)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  margin-bottom: ${({ theme }) => theme.space[3]}px;
`;

export const StyledProductInfoText = styled(Text.p)<{ isWarning?: boolean }>`
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  font-size: 16px;
  color: ${({ theme, isWarning }) =>
    isWarning ? theme.colors.red.base : theme.colors.black.black9};
`;

export const StyledProductInfoRow = styled(Div)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledProductOptionButton = styled(Button)<{
  isSelected: boolean;
}>`
  width: 100%;
  padding: ${({ theme }) => theme.space[5]}px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  border-radius: ${({ theme }) => theme.radii[3]};
  &,
  &:hover,
  &:focus {
    border: ${({ isSelected, theme }) =>
      `2px solid ${
        isSelected ? theme.colors.primary.base : theme.colors.black.black4
      }`};
    background-color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.blue.lightest : theme.colors.black.black0};
    text-decoration: none;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const StyledProductEmphasizedValue = styled(Text.p)`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: ${({ theme }) => theme.fontWeights.semibold};
`;

export const StyledProductTitle = styled(Heading.h5)`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
`;

export const StyledProductAddonSubtitle = styled(Text.p)`
  color: ${({ theme }) => theme.colors.black.black6};
  margin-bottom: 0;
`;

export const StyledProductVerificationContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => `${theme.space[6]}px ${theme.space[5]}px`};
`;

export const StyledProductVerificationTitle = styled(Heading.h4)`
  margin-bottom: ${({ theme }) => theme.space[3]}px;
  text-align: center;
`;

export const StyledProductVerificationText = styled(Text.p)`
  margin-bottom: 0;
  text-align: center;
`;

export const StyledAddonDescription = styled(Text.p)`
  margin-bottom: ${({ theme }) => theme.space[6]}px;
  color: ${({ theme }) => theme.colors.gray.darker};
  white-space: pre-line;
`;

export const StyledAddonInfoText = styled(Text.p)`
  margin-bottom: ${({ theme }) => theme.space[4]}px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes[3]};
`;
