import { Column } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const JobListNavigationWrapper = styled(Column)`
  padding-left: 0;
  @media print {
    display: none;
  }
`;

export default JobListNavigationWrapper;
