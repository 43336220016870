import { UserCompany } from '@internals/business-shared/src/types/User';
import { ENV_KEY_NAME, getEnvValue } from '@utils/getEnvValue';

const getCompanyProfilePreviewUrl = (
  id: UserCompany['id'],
  isPreview = true
): string => {
  if (isPreview) {
    return `${getEnvValue(ENV_KEY_NAME.COMPANY_URL)}${id}?preview=true`;
  }
  return `${getEnvValue(ENV_KEY_NAME.COMPANY_URL)}${id}`;
};

export default getCompanyProfilePreviewUrl;
