import { Textarea } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const StyledTextarea: any = styled(Textarea)`
  display: block;
  width: 100%;
  height: ${(props) => props.height || 'auto'};
  overflow: auto;

  border: none;
  padding: ${(props) => `${props.theme.space[6]}px`}
    ${(props) => `${props.theme.space[5]}px`};

  &:hover,
  &:invalid,
  &:focus {
    box-shadow: none;
    border: none;
  }
`;

export default StyledTextarea;
