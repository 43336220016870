import gql from 'graphql-tag';

import {
  JOB_ANALYTICS as JOB_ANALYTICSPayload,
  JOB_ANALYTICSVariables,
} from '../../generated/generated';

export type JobAnalyticsPayload = JOB_ANALYTICSPayload;
export type JobAnalyticsVariables = JOB_ANALYTICSVariables;

export const JOB_ANALYTICS = gql`
  query JOB_ANALYTICS($jobId: ID!) {
    jobBusinessRelationState(jobId: $jobId) {
      jobId
      isWon
    }
    chatByJobIdOptional(jobId: $jobId) {
      id
      isDialog
    }
    simpleAgreementContractByJobId(jobId: $jobId) {
      id
    }
  }
`;
