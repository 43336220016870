import { FC } from 'react';

import { useIsTablet } from '@contexts/DeviceSizeContext';
import { Column, Heading, Text } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { Trans, useTranslation } from 'react-i18next';

export const Header: FC = () => {
  const { name: productName } = getProduct();
  const { t } = useTranslation();
  const isTablet = useIsTablet();

  return (
    <Column
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mb={isTablet ? 7 : 6}
      px={5}
    >
      <Heading.h1 color="black.black0" mb={3}>
        {t('job.modal.subscription.selectPackage.title', {
          productName,
        })}
      </Heading.h1>
      <Text.p color="black.black0" textAlign="center" maxWidth={600}>
        <Trans i18nKey="job.modal.subscription.selectPackage.description" />
      </Text.p>
    </Column>
  );
};
