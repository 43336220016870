import gql from 'graphql-tag';

import BASIC_USER_DATA_FRAGMENT from '../../fragments/BasicUserDataFragment';
import JOB_BASIC_DATA_FRAGMENT from '../../fragments/JobBasicDataFragment';
import PAGE_INFO_FRAGMENT from '../../fragments/PageInfoFragment';
import {
  JOBS_LIST_QUERYVariables,
  JOBS_LIST_QUERY as JOBS_LIST_QUERY_Result,
  JOBS_LIST_QUERY_jobList_JobListPayload,
  JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node,
} from '../../generated/generated';

export type JobsListQueryPayload = JOBS_LIST_QUERY_Result;
export type JobsListQueryVariables = JOBS_LIST_QUERYVariables;
export type JobsListQueryData = JOBS_LIST_QUERY_jobList_JobListPayload;
export type JobsList = JOBS_LIST_QUERY_jobList_JobListPayload['jobConnection'];
export type JobsListItem =
  JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node;

export const isJobsListQuerySuccessResponse = (
  jobList?: JOBS_LIST_QUERY_Result['jobList']
): jobList is JOBS_LIST_QUERY_jobList_JobListPayload =>
  !!jobList && jobList.__typename === 'JobListPayload';

export const JOBS_LIST_QUERY = gql`
  ${PAGE_INFO_FRAGMENT}
  ${JOB_BASIC_DATA_FRAGMENT}
  ${BASIC_USER_DATA_FRAGMENT}

  query JOBS_LIST_QUERY(
    $query: String
    $page: Int
    $count: Int
    $size: JobSize
    $sizes: [JobSize!]
    $listId: ID
    $districtId: [ID!]
    $countyId: [ID!]
    $municipalityCode: [ID!]
    $folderId: [ID!]
    $worktypeIds: [ID!]
    $worktypeSubsets: [ID!]
    $worktypeSubsetGroups: [ID!]
    $industryIds: [ID!]
    $skipAnsweredOnlyFields: Boolean!
  ) {
    jobList(
      input: {
        query: $query
        page: $page
        count: $count
        size: $size
        sizes: $sizes
        listId: $listId
        districtId: $districtId
        municipalityCode: $municipalityCode
        countyId: $countyId
        folderId: $folderId
        worktypeIds: $worktypeIds
        worktypeSubsets: $worktypeSubsets
        worktypeSubsetGroups: $worktypeSubsetGroups
        industryIds: $industryIds
      }
    ) {
      ... on JobListPayload {
        listId
        jobConnection {
          pageInfo {
            ...PageInfoFragment
            aggregations {
              type
              values {
                count
                id
              }
            }
          }
          edges {
            node {
              ...JobBasicDataFragment
              traits {
                id
                fromVerifiedUser
              }
              latestMessage @skip(if: $skipAnsweredOnlyFields) {
                id
                text
                writer {
                  ...BasicUserDataFragment
                }
                regTs
                type
              }
              evaluation @skip(if: $skipAnsweredOnlyFields) {
                id
                score
              }
            }
          }
        }
      }

      ... on PageOutOfRangeError {
        message
      }
    }
  }
`;
