import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  EmailAddress: any;
  HexColorCode: any;
  ISO8601Duration: any;
  OrgIdentifier: any;
  PhoneNumber: any;
  PostalCode: any;
  Record: any;
  TimeFormatHHMM: any;
  Upload: any;
  Void: any;
};

export type AiCalculatorResult = {
  __typename: 'AICalculatorResult';
  explanation: Scalars['String'];
  gptData: Array<Scalars['String']>;
  price: PriceRange;
  summary: Scalars['String'];
};

export type AbortOneOnOneJobRegistrationNoMatchInput = {
  jobId: Scalars['ID'];
};

export type AccessDeniedError = Error & {
  __typename: 'AccessDeniedError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type AddBusinessEmployeeInput = {
  businessId: Scalars['ID'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  phoneNr?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type AddBusinessEmployeeInputError = Error & {
  __typename: 'AddBusinessEmployeeInputError';
  businessId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  email?: Maybe<BasicInputError>;
  firstName?: Maybe<BasicInputError>;
  image?: Maybe<BasicInputError>;
  lastName?: Maybe<BasicInputError>;
  message: Scalars['String'];
  phoneNr?: Maybe<BasicInputError>;
  position?: Maybe<BasicInputError>;
};

export type AddBusinessEmployeeNoAccessError = Error & {
  __typename: 'AddBusinessEmployeeNoAccessError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type AddBusinessEmployeePayload = {
  __typename: 'AddBusinessEmployeePayload';
  employee: BusinessEmployee;
};

export type AddBusinessEmployeeResult = AddBusinessEmployeeInputError | AddBusinessEmployeeNoAccessError | AddBusinessEmployeePayload;

export type AddExpoPushNotificationTokenAlreadyExistsError = Error & {
  __typename: 'AddExpoPushNotificationTokenAlreadyExistsError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type AddExpoPushNotificationTokenInput = {
  appName: PushNotificationAppName;
  deviceType?: InputMaybe<PushNotificationDeviceType>;
  token: Scalars['String'];
};

export type AddExpoPushNotificationTokenPayload = {
  __typename: 'AddExpoPushNotificationTokenPayload';
  success: Scalars['Boolean'];
};

export type AddExpoPushNotificationTokenResult = AddExpoPushNotificationTokenAlreadyExistsError | AddExpoPushNotificationTokenPayload;

export type AddJobToJobFolderInput = {
  folderId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type AddLocationToJob = {
  address: AddressInput;
  id: Scalars['ID'];
};

export type AddPushNotificationTokenAlreadyExistsError = Error & {
  __typename: 'AddPushNotificationTokenAlreadyExistsError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type AddPushNotificationTokenInput = {
  appName: PushNotificationAppName;
  deviceType?: InputMaybe<PushNotificationDeviceType>;
  token: Scalars['String'];
  tokenType: PushNotificationTokenType;
};

export type AddPushNotificationTokenPayload = {
  __typename: 'AddPushNotificationTokenPayload';
  success: Scalars['Boolean'];
};

export type AddPushNotificationTokenResult = AddPushNotificationTokenAlreadyExistsError | AddPushNotificationTokenPayload;

export type Address = Node & {
  __typename: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  type: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  id?: InputMaybe<Scalars['ID']>;
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInputError = Error & {
  __typename: 'AddressInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  street?: Maybe<BasicInputError>;
  zip?: Maybe<BasicInputError>;
};

export type Aggregations = {
  __typename: 'Aggregations';
  type: Scalars['String'];
  values: Array<AggregationsValue>;
};

export type AggregationsValue = {
  __typename: 'AggregationsValue';
  count: Scalars['Int'];
  id: Scalars['String'];
};

export type AiCalculatorInput = {
  description: Scalars['String'];
};

export type Album = Node & {
  __typename: 'Album';
  id: Scalars['ID'];
  images: Array<AlbumImage>;
  name: Scalars['String'];
};

export type AlbumImage = Node & {
  __typename: 'AlbumImage';
  album: Album;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image: Image;
  sort: Scalars['Int'];
};

export type AnswerAccess = {
  __typename: 'AnswerAccess';
  clip?: Maybe<Scalars['Int']>;
  code: AnswerAccessCode;
  isOpen: Scalars['Boolean'];
  missingCertificates: Array<Certificate>;
};

export enum AnswerAccessCode {
  Allowed = 'ALLOWED',
  AnswerDeniedCompanyNotInRestrictionList = 'ANSWER_DENIED_COMPANY_NOT_IN_RESTRICTION_LIST',
  DeniedAlreadyAnswered = 'DENIED_ALREADY_ANSWERED',
  DeniedAnsweringOwnJob = 'DENIED_ANSWERING_OWN_JOB',
  DeniedBigJobNotAccessible = 'DENIED_BIG_JOB_NOT_ACCESSIBLE',
  DeniedBlockedByOwner = 'DENIED_BLOCKED_BY_OWNER',
  DeniedBusinessChosen = 'DENIED_BUSINESS_CHOSEN',
  DeniedCompanyLacksFreeAnswers = 'DENIED_COMPANY_LACKS_FREE_ANSWERS',
  DeniedCompanyLacksProduct = 'DENIED_COMPANY_LACKS_PRODUCT',
  DeniedCompanyLacksSubscription = 'DENIED_COMPANY_LACKS_SUBSCRIPTION',
  DeniedCompanyNotAccessible = 'DENIED_COMPANY_NOT_ACCESSIBLE',
  DeniedDemoBusiness = 'DENIED_DEMO_BUSINESS',
  DeniedDoffin = 'DENIED_DOFFIN',
  DeniedFreeAnswerSuspended = 'DENIED_FREE_ANSWER_SUSPENDED',
  DeniedInsufficientFunds = 'DENIED_INSUFFICIENT_FUNDS',
  DeniedJobAwaitingApproval = 'DENIED_JOB_AWAITING_APPROVAL',
  DeniedJobClosed = 'DENIED_JOB_CLOSED',
  DeniedJobExpired = 'DENIED_JOB_EXPIRED',
  DeniedJobNotFound = 'DENIED_JOB_NOT_FOUND',
  DeniedJobOther = 'DENIED_JOB_OTHER',
  DeniedJobPaused = 'DENIED_JOB_PAUSED',
  DeniedJobUnapproved = 'DENIED_JOB_UNAPPROVED',
  DeniedJobUnderRegistration = 'DENIED_JOB_UNDER_REGISTRATION',
  DeniedLackingApproval = 'DENIED_LACKING_APPROVAL',
  DeniedLackingCertification = 'DENIED_LACKING_CERTIFICATION',
  DeniedLackingSubtypes = 'DENIED_LACKING_SUBTYPES',
  DeniedNotTestJob = 'DENIED_NOT_TEST_JOB',
  DeniedNoAccess = 'DENIED_NO_ACCESS',
  DeniedOtherTag = 'DENIED_OTHER_TAG',
  DeniedPlusJob = 'DENIED_PLUS_JOB',
  DeniedSelfRegistered = 'DENIED_SELF_REGISTERED',
  DeniedTechnicalIssue = 'DENIED_TECHNICAL_ISSUE',
  DeniedUnpaidSubscription = 'DENIED_UNPAID_SUBSCRIPTION',
  DeniedUserBlocked = 'DENIED_USER_BLOCKED',
  DeniedUserDifferentJobType = 'DENIED_USER_DIFFERENT_JOB_TYPE',
  DeniedUserDifferentPerson = 'DENIED_USER_DIFFERENT_PERSON',
  DeniedUserNotVerified = 'DENIED_USER_NOT_VERIFIED',
  DeniedUserTimelimit = 'DENIED_USER_TIMELIMIT',
  DeniedWrongArea = 'DENIED_WRONG_AREA'
}

export type AnswerJobAccessError = Error & {
  __typename: 'AnswerJobAccessError';
  accessCode?: Maybe<AnswerAccessCode>;
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type AnswerJobInput = {
  files?: InputMaybe<Array<Scalars['Upload']>>;
  jobId: Scalars['ID'];
  text: Scalars['String'];
};

export type AnswerJobInputError = Error & {
  __typename: 'AnswerJobInputError';
  code?: Maybe<Scalars['Int']>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
  text?: Maybe<BasicInputError>;
};

export type AnswerJobPayload = {
  __typename: 'AnswerJobPayload';
  chat: Chat;
  job: Job;
};

export type AnswerJobResult = AnswerJobAccessError | AnswerJobInputError | AnswerJobPayload;

export type AnswerTemplate = Node & {
  __typename: 'AnswerTemplate';
  id: Scalars['ID'];
  text: Scalars['String'];
  title: Scalars['String'];
};

export type Attachment = File | Image | Video;

export type AuthorizeBoligmappaIntegrationInput = {
  code: Scalars['String'];
  redirectUrl: Scalars['String'];
};

export type BadgeNumbers = {
  __typename: 'BadgeNumbers';
  latestActivityAt?: Maybe<Scalars['Int']>;
  messages?: Maybe<Scalars['Int']>;
  threads?: Maybe<Scalars['Int']>;
};

export type BasicInputError = Error & {
  __typename: 'BasicInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type BoligmappaAddressDetails = {
  __typename: 'BoligmappaAddressDetails';
  buildings: Array<BoligmappaBuilding>;
  id: Scalars['ID'];
};

export type BoligmappaAddressDetailsInput = {
  buildingId: Scalars['ID'];
  buildingPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  propertyCursor?: InputMaybe<Scalars['String']>;
};

export type BoligmappaAssociateAddressInput = {
  addressId: Scalars['ID'];
  associations: Array<BoligmappaAssociationAddress>;
  jobId: Scalars['ID'];
};

export type BoligmappaAssociation = {
  __typename: 'BoligmappaAssociation';
  documents: Array<BoligmappaDocument>;
  id: Scalars['ID'];
  propertyType: BoligmappaAssociationBuildingType;
  unitNumber?: Maybe<Scalars['String']>;
};

export type BoligmappaAssociationAddress = {
  id: Scalars['ID'];
  propertyType: Scalars['String'];
  unitNumber?: InputMaybe<Scalars['String']>;
};

export enum BoligmappaAssociationBuildingType {
  Building = 'BUILDING',
  Property = 'PROPERTY'
}

export type BoligmappaAssociationResponse = {
  __typename: 'BoligmappaAssociationResponse';
  address: BoligmappaBuildingAddress;
  associations: Array<BoligmappaAssociation>;
  id: Scalars['ID'];
};

export type BoligmappaBuilding = {
  __typename: 'BoligmappaBuilding';
  addresses: Array<BoligmappaBuildingAddress>;
  buildingStatus: Scalars['String'];
  buildingType?: Maybe<Scalars['String']>;
  floorCount: Scalars['Int'];
  id: Scalars['ID'];
  properties: Array<BoligmappaProperty>;
  propertyCount: Scalars['Int'];
  totalBuildingArea: Scalars['Float'];
  usedFrom?: Maybe<Scalars['Int']>;
  utilizedBuildingArea: Scalars['Float'];
};

export type BoligmappaBuildingAddress = {
  __typename: 'BoligmappaBuildingAddress';
  houseNumber: Scalars['Int'];
  houseSubNumber?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  postalCode: Scalars['String'];
  postalPlace: Scalars['String'];
  streetName: Scalars['String'];
};

export type BoligmappaCreateAccountInput = {
  acceptTermsAndConditions: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  numberOfEmployees: Scalars['String'];
  organizationName: Scalars['String'];
  organizationNumber: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  professionId: Scalars['String'];
  userName: Scalars['String'];
};

export type BoligmappaDocument = {
  __typename: 'BoligmappaDocument';
  fileName: Scalars['String'];
  id: Scalars['ID'];
};

export type BoligmappaDocumentAndProfessionTypesResponse = {
  __typename: 'BoligmappaDocumentAndProfessionTypesResponse';
  documentTypes: Array<BoligmappaDocumentType>;
  professionTypes: Array<BoligmappaProfessionType>;
};

export type BoligmappaDocumentType = {
  __typename: 'BoligmappaDocumentType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BoligmappaDownloadFileInput = {
  boligmappaNumber: Scalars['ID'];
  fileId: Scalars['ID'];
};

export type BoligmappaDownloadFileResponse = {
  __typename: 'BoligmappaDownloadFileResponse';
  documentType: BoligmappaDocumentType;
  downloadLink: Scalars['String'];
  fileName: Scalars['String'];
  id: Scalars['ID'];
};

export type BoligmappaFileElementInput = {
  documentType: BoligmappaIdObject;
  fileName: Scalars['String'];
  professionType: BoligmappaIdObject;
  title: Scalars['String'];
  uniqueId: Scalars['String'];
};

export type BoligmappaIdObject = {
  id: Scalars['ID'];
};

export type BoligmappaInitiateFileUploadResponse = {
  __typename: 'BoligmappaInitiateFileUploadResponse';
  id: Scalars['ID'];
  uploadTargets: Array<BoligmappaUploadTarget>;
};

export type BoligmappaIntegrationState = {
  __typename: 'BoligmappaIntegrationState';
  id: Scalars['ID'];
  status: BoligmappaIntegrationStateEnum;
};

export enum BoligmappaIntegrationStateEnum {
  AuthorizationRevoked = 'AUTHORIZATION_REVOKED',
  Authorized = 'AUTHORIZED',
  NotAuthorized = 'NOT_AUTHORIZED'
}

export type BoligmappaLoginInput = {
  redirectUrl: Scalars['String'];
};

export type BoligmappaProfessionType = {
  __typename: 'BoligmappaProfessionType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BoligmappaProperty = {
  __typename: 'BoligmappaProperty';
  address: BoligmappaBuildingAddress;
  id: Scalars['ID'];
  propertyFacts: BoligmappaPropertyFacts;
  /** Property owners can be empty array */
  propertyOwners: Array<BoligmappaPropertyOwner>;
  propertyType: Scalars['String'];
  unitNumber: Scalars['String'];
};

export type BoligmappaPropertyFacts = {
  __typename: 'BoligmappaPropertyFacts';
  id: Scalars['ID'];
  numberOfBathrooms: Scalars['Int'];
  numberOfRooms: Scalars['Int'];
  numberOfWCs: Scalars['Int'];
  usableArea: Scalars['Int'];
};

export type BoligmappaPropertyOwner = {
  __typename: 'BoligmappaPropertyOwner';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
};

export type BoligmappaRemoveAssociationInput = {
  boligmappaId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type BoligmappaUploadTarget = {
  __typename: 'BoligmappaUploadTarget';
  filename: Scalars['String'];
  id: Scalars['ID'];
  uniqueId: Scalars['String'];
  uploadLink: Scalars['String'];
};

export type Business = Node & {
  __typename: 'Business';
  addresses: BusinessAddresses;
  /** @deprecated Use businessAlbums query */
  albums: Array<Album>;
  certificateConnection?: Maybe<CertificateConnection>;
  /** @deprecated Use profileDescription */
  description?: Maybe<Scalars['String']>;
  /** @deprecated Use query businessJobAreas */
  districts: Array<District>;
  /** @deprecated Use query businessJobAreas */
  districtsAvailable: Array<District>;
  email?: Maybe<Scalars['String']>;
  /** @deprecated Use businessEmployees instead */
  employees: Array<BusinessEmployee>;
  evaluationConnection: EvaluationConnection;
  /** @deprecated Use businessSocialMedia instead */
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials: Scalars['String'];
  /** @deprecated Use businessSocialMedia instead */
  instagram?: Maybe<Scalars['String']>;
  /** @deprecated Use invoicesUnpaid query */
  invoicesUnpaid: Array<Invoice>;
  isActive?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Image>;
  /** @deprecated Use query businessJobAreas */
  municipalities: Array<Municipality>;
  /** @deprecated Use query businessJobAreas */
  municipalitiesAvailable: Array<Municipality>;
  name: Scalars['String'];
  /** @deprecated Use businessNotificationsAvailable instead */
  notificationsAvailable?: Maybe<NotificationGroup>;
  openingHours?: Maybe<WeeklyOpeningHours>;
  orgNr?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  /** @deprecated Use query businessProducts */
  products?: Maybe<CompanyProducts>;
  profileDescription?: Maybe<Scalars['String']>;
  profileHeaderImage?: Maybe<Scalars['String']>;
  profileHeaderImageV2?: Maybe<Image>;
  profileTitle?: Maybe<Scalars['String']>;
  profileVideo?: Maybe<Video>;
  regdate?: Maybe<Scalars['Float']>;
  score: BusinessEvaluationScore;
  /** @deprecated Use query businessSettings */
  settings: CompanySettings;
  socialMedia?: Maybe<BusinessSocialMedia>;
  /** @deprecated Use businessStates for better performance and parallelization */
  state?: Maybe<BusinessStates>;
  /** @deprecated Use query businessWorkTypes */
  subsets?: Maybe<Array<WorkTypeSubset>>;
  /** @deprecated Use query businessWorkTypes */
  subsetsAvailable?: Maybe<Array<WorkTypeSubset>>;
  /** @deprecated Use businessSocialMedia instead */
  tiktok?: Maybe<Scalars['String']>;
  /** @deprecated Use businessSocialMedia instead */
  website?: Maybe<Scalars['String']>;
  /** @deprecated Use query businessWorkTypes */
  workTypes: Array<WorkType>;
  /** @deprecated Use query businessWorkTypes */
  workTypesAvailable: Array<WorkType>;
  /** @deprecated Use businessSocialMedia instead */
  youtube?: Maybe<Scalars['String']>;
};


export type BusinessEvaluationConnectionArgs = {
  paging?: InputMaybe<PageSelect>;
};

export type BusinessAddressInputError = Error & {
  __typename: 'BusinessAddressInputError';
  billing?: Maybe<AddressInputError>;
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  postal?: Maybe<AddressInputError>;
  visit?: Maybe<AddressInputError>;
};

export type BusinessAddresses = {
  __typename: 'BusinessAddresses';
  billing?: Maybe<Array<Address>>;
  id: Scalars['ID'];
  post?: Maybe<Array<Address>>;
  visit?: Maybe<Array<Address>>;
};

export type BusinessAlbumsInput = {
  businessId: Scalars['ID'];
};

export type BusinessAskToBeEvaluatedInput = {
  jobId: Scalars['ID'];
};

export type BusinessAskToBeSelectedInput = {
  jobId: Scalars['ID'];
};

export type BusinessConnection = Connection & {
  __typename: 'BusinessConnection';
  edges: Array<BusinessEdge>;
  pageInfo: PageInfo;
};

export type BusinessEdge = Edge & {
  __typename: 'BusinessEdge';
  cursor: Scalars['String'];
  node: Business;
};

export type BusinessEmployee = Node & {
  __typename: 'BusinessEmployee';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  image?: Maybe<Image>;
  imageUrl?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNr: Scalars['String'];
  position: Scalars['String'];
};

export type BusinessEvaluationScore = {
  __typename: 'BusinessEvaluationScore';
  average?: Maybe<Scalars['Float']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type BusinessEvaluationsInput = {
  businessId: Scalars['ID'];
  paging?: InputMaybe<PageSelect>;
};

export type BusinessIndustriesAndWorkTypesActiveInput = {
  businessId: Scalars['Int'];
};

export type BusinessIndustriesAndWorkTypesActivePayload = {
  __typename: 'BusinessIndustriesAndWorkTypesActivePayload';
  industries: Array<Industry>;
  workTypes: Array<WorkType>;
};

export type BusinessInputError = Error & {
  __typename: 'BusinessInputError';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type BusinessJobAreas = {
  __typename: 'BusinessJobAreas';
  districts: Array<District>;
  districtsAvailable: Array<District>;
  id: Scalars['ID'];
  municipalities: Array<Municipality>;
  municipalitiesAvailable: Array<Municipality>;
};

export type BusinessJobStatistics = {
  __typename: 'BusinessJobStatistics';
  id: Scalars['ID'];
  won: BusinessWonStatistics;
};

export enum BusinessMetaTypes {
  Email = 'EMAIL',
  Facebook = 'FACEBOOK',
  Instagram = 'INSTAGRAM',
  Mittanbud = 'MITTANBUD',
  Other = 'OTHER',
  Tiktok = 'TIKTOK',
  Twitter = 'TWITTER',
  Website = 'WEBSITE',
  Youtube = 'YOUTUBE'
}

export type BusinessNotFoundError = Error & {
  __typename: 'BusinessNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type BusinessOrUser = Business | User;

export type BusinessResult = Business | BusinessInputError | BusinessNotFoundError;

export type BusinessSearchAggregations = {
  __typename: 'BusinessSearchAggregations';
  counties: Array<BusinessSearchCountyAggregation>;
  municipalities: Array<BusinessSearchMunicipalityAggregation>;
};

export type BusinessSearchCountyAggregation = {
  __typename: 'BusinessSearchCountyAggregation';
  count?: Maybe<Scalars['Int']>;
  countyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type BusinessSearchInput = {
  cities?: InputMaybe<Array<Scalars['String']>>;
  county?: InputMaybe<Scalars['String']>;
  hasProfilePage?: InputMaybe<Scalars['Boolean']>;
  industries?: InputMaybe<Array<Scalars['ID']>>;
  minScore?: InputMaybe<Scalars['Int']>;
  municipality?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
  registeredOnly?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['Int']>;
  worktypes?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessSearchMunicipalityAggregation = {
  __typename: 'BusinessSearchMunicipalityAggregation';
  count?: Maybe<Scalars['Int']>;
  county?: Maybe<County>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type BusinessSearchPayload = {
  __typename: 'BusinessSearchPayload';
  aggregations?: Maybe<BusinessSearchAggregations>;
  businessConnection?: Maybe<BusinessConnection>;
  businessPaid: Array<Business>;
};

export type BusinessSearchV2Input = {
  businessName?: InputMaybe<Scalars['String']>;
  counties?: InputMaybe<Array<Scalars['ID']>>;
  municipalities?: InputMaybe<Array<Scalars['ID']>>;
  pageSelect?: InputMaybe<PageSelect>;
  reviews?: InputMaybe<BusinessSearchV2ReviewInput>;
  worktypes?: InputMaybe<Array<Scalars['ID']>>;
};

export type BusinessSearchV2Response = {
  __typename: 'BusinessSearchV2Response';
  businesses: ElasticBusinessConnection;
  promoted: Array<ElasticBusiness>;
};

export type BusinessSearchV2ReviewInput = {
  /** Minimum number of reviews the business should have */
  minCount?: InputMaybe<Scalars['Int']>;
  /** Minimum average score for the business */
  minScore?: InputMaybe<Scalars['Float']>;
};

export type BusinessSocialMedia = {
  __typename: 'BusinessSocialMedia';
  facebook?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type BusinessStates = {
  __typename: 'BusinessStates';
  availableProductSlugs: Array<ProductTemplateSlug>;
  hasProductExpired: Scalars['Boolean'];
  hasProducts: Scalars['Boolean'];
  /** @deprecated Use hasProducts */
  hasSubscription?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isBlacklisted: Scalars['Boolean'];
  isDeactivated: Scalars['Boolean'];
  isMittanbudXlEligible: Scalars['Boolean'];
  isNew: Scalars['Boolean'];
  isOrderUnpaid: Scalars['Boolean'];
  /** @deprecated Use hasProductExpired */
  isSubscriptionExpired?: Maybe<Scalars['Boolean']>;
  isVerified: Scalars['Boolean'];
  isVerifiedWithSecureId: Scalars['Boolean'];
  jobAccess: CompanySettingsJobAccess;
  products?: Maybe<Array<ProductTemplateSlug>>;
};

export type BusinessWonStatistics = {
  __typename: 'BusinessWonStatistics';
  lastMonth: Scalars['Int'];
  lastYear: Scalars['Int'];
  total: Scalars['Int'];
};

export type BusinessWorkTypes = {
  __typename: 'BusinessWorkTypes';
  id: Scalars['ID'];
  subsets?: Maybe<Array<WorkTypeSubset>>;
  subsetsAvailable?: Maybe<Array<WorkTypeSubset>>;
  workTypes: Array<WorkType>;
  workTypesAvailable: Array<WorkType>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CancelConsumerJobInput = {
  id: Scalars['ID'];
  reason: CancelConsumerJobReason;
};

export enum CancelConsumerJobReason {
  JobNotNeeded = 'JobNotNeeded',
  JobPostponed = 'JobPostponed',
  Other = 'Other',
  OutsideCompany = 'OutsideCompany'
}

export enum CancelRegistrationAttemptAction {
  AddJob = 'ADD_JOB',
  Login = 'LOGIN'
}

export type CancelRegistrationAttemptInput = {
  action?: InputMaybe<CancelRegistrationAttemptAction>;
  phoneNumber: Scalars['String'];
  reason: Scalars['String'];
  ticketId: Scalars['ID'];
};

export type Car = Node & {
  __typename: 'Car';
  id: Scalars['ID'];
  info?: Maybe<CarInfo>;
  /** regNr shouldn't be requested because we cannot hide it as it's a key. It should also be @inaccessible */
  regNr: Scalars['ID'];
  /** This is internal use only but we cannot use @inaccessible directive because we have federation v1 */
  shouldHideSensitiveFields?: Maybe<Scalars['Boolean']>;
};

export type CarInfo = {
  __typename: 'CarInfo';
  ECTypeApprovalNumber?: Maybe<Scalars['String']>;
  VIMNumber?: Maybe<Scalars['String']>;
  apiBrandId?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  chassisType?: Maybe<Scalars['String']>;
  co2Emissions?: Maybe<Scalars['Float']>;
  color?: Maybe<Scalars['String']>;
  deregistrationDate?: Maybe<Scalars['String']>;
  driveShaftCount?: Maybe<Scalars['Int']>;
  engineHectowatt?: Maybe<Scalars['Float']>;
  engineSize?: Maybe<Scalars['Float']>;
  factoryInstalledParticleFilter?: Maybe<Scalars['Boolean']>;
  fuelType?: Maybe<FuelType>;
  hybrid?: Maybe<HybridCarType>;
  hybridCatagory?: Maybe<HybridCarCatagory>;
  importedAsUsed?: Maybe<Scalars['Boolean']>;
  lastRoadWorthinessTestDate?: Maybe<Scalars['String']>;
  length?: Maybe<Scalars['Float']>;
  licencePlate?: Maybe<Scalars['String']>;
  maxRoofLoad?: Maybe<Scalars['Float']>;
  maxSeats?: Maybe<Scalars['Int']>;
  maxTotalWeight?: Maybe<Scalars['Float']>;
  maxTotalWightWithTrailer?: Maybe<Scalars['Float']>;
  model?: Maybe<Scalars['String']>;
  nextRoadWorthinessTestDate?: Maybe<Scalars['String']>;
  norwegianRegistrationDate?: Maybe<Scalars['String']>;
  registrationYear?: Maybe<Scalars['String']>;
  seatsFront?: Maybe<Scalars['Int']>;
  shaftCount?: Maybe<Scalars['Int']>;
  taxCode?: Maybe<Scalars['String']>;
  towbarMaxLoad?: Maybe<Scalars['Float']>;
  trailerMaxWeightWithBreak?: Maybe<Scalars['Float']>;
  trailerMaxWeightWithoutBreak?: Maybe<Scalars['Float']>;
  transmissionType?: Maybe<Scalars['String']>;
  vehicleGroup?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

export type Card = {
  __typename: 'Card';
  id: Scalars['ID'];
  maskedCardNumber: Scalars['String'];
};

export type Certificate = Node & {
  __typename: 'Certificate';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** @deprecated Use logos */
  logo?: Maybe<Image>;
  logos?: Maybe<CertificateLogos>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<CertificateTypes>;
  url?: Maybe<Scalars['String']>;
};

export type CertificateConnection = Connection & {
  __typename: 'CertificateConnection';
  edges: Array<CertificateEdge>;
  pageInfo: PageInfo;
};

export type CertificateEdge = Edge & {
  __typename: 'CertificateEdge';
  cursor: Scalars['String'];
  node: Certificate;
};

export type CertificateListInput = {
  businessId?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type CertificateLogos = {
  __typename: 'CertificateLogos';
  big?: Maybe<Image>;
  icon?: Maybe<Image>;
  small?: Maybe<Image>;
};

export enum CertificateTypes {
  BenefitProgram = 'BENEFIT_PROGRAM',
  Certification = 'CERTIFICATION',
  Chain = 'CHAIN'
}

export type Chat = Node & {
  __typename: 'Chat';
  business?: Maybe<Business>;
  firstMessage?: Maybe<Message>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  /**  if both parts have posted  */
  isDialog?: Maybe<Scalars['Boolean']>;
  /**  the job is done or cancelled  */
  isEnded?: Maybe<Scalars['Boolean']>;
  isUnread?: Maybe<Scalars['Boolean']>;
  /**  if the user requesting the data has won the job  */
  isWon?: Maybe<Scalars['Boolean']>;
  job?: Maybe<Job>;
  /**  latest message in chat. use this when presenting in a list.  */
  latestMessage?: Maybe<Message>;
  latestUpdateTs?: Maybe<Scalars['Int']>;
  messageConnection: MessageConnection;
  /**  total amount of messages in the chat  */
  messageCount?: Maybe<Scalars['Int']>;
  /** For single chat use q.chatParticipants */
  participants: Array<UserOrBusiness>;
};


export type ChatMessageConnectionArgs = {
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderByTime?: InputMaybe<SortDirection>;
};

export type ChatAccessDeniedError = Error & {
  __typename: 'ChatAccessDeniedError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type ChatConnection = Connection & {
  __typename: 'ChatConnection';
  edges: Array<ChatEdge>;
  pageInfo: PageInfo;
};

export type ChatConsumer = Node & {
  __typename: 'ChatConsumer';
  business?: Maybe<Business>;
  id: Scalars['ID'];
  isUnread?: Maybe<Scalars['Boolean']>;
  latestMessage?: Maybe<Message>;
  meta: ChatMeta;
};

export type ChatConsumerListConnection = Connection & {
  __typename: 'ChatConsumerListConnection';
  edges: Array<ChatConsumerListEdge>;
  pageInfo: PageInfo;
};

export type ChatConsumerListEdge = Edge & {
  __typename: 'ChatConsumerListEdge';
  cursor: Scalars['String'];
  node: ChatConsumer;
};

export type ChatConsumerListInput = {
  /**  amount of items on each page  */
  count?: InputMaybe<Scalars['Int']>;
  /**  Start position. Result number (not cursor), starts at zero.   */
  from?: InputMaybe<Scalars['Int']>;
  /**  isDeleted  */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /**  job ID  */
  jobId: Scalars['ID'];
};

export type ChatConsumerListPayload = {
  __typename: 'ChatConsumerListPayload';
  chatConnection: ChatConsumerListConnection;
};

export type ChatEdge = Edge & {
  __typename: 'ChatEdge';
  cursor: Scalars['String'];
  node: Chat;
};

export type ChatFilter = {
  id?: InputMaybe<Scalars['ID']>;
};

export type ChatInputError = Error & {
  __typename: 'ChatInputError';
  code?: Maybe<Scalars['Int']>;
  id?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type ChatListFilter = {
  businessId?: InputMaybe<Scalars['ID']>;
  /**  Max number of items to return  */
  count?: InputMaybe<Scalars['Int']>;
  /**  Start position. Result number (not cursor), starts at zero.  */
  from?: InputMaybe<Scalars['Int']>;
  /**  Is deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /**  For fetching all answers to a job  */
  jobId?: InputMaybe<Scalars['ID']>;
  /**  Free text filter  */
  query?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ChatListType>;
};

export type ChatListFilterError = Error & {
  __typename: 'ChatListFilterError';
  businessId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  count?: Maybe<BasicInputError>;
  from?: Maybe<BasicInputError>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
  query?: Maybe<BasicInputError>;
  type?: Maybe<BasicInputError>;
};

export type ChatListPayload = {
  __typename: 'ChatListPayload';
  chatConnection: ChatConnection;
  listType?: Maybe<ChatListType>;
};

export type ChatListResult = ChatListFilterError | ChatListPayload;

export enum ChatListType {
  Active = 'ACTIVE',
  All = 'ALL',
  Contract = 'CONTRACT',
  Inactive = 'INACTIVE',
  Unread = 'UNREAD',
  Won = 'WON'
}

export type ChatMessagesInput = {
  chatId: Scalars['ID'];
  first?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderByTime?: InputMaybe<SortDirection>;
};

export type ChatMeta = {
  __typename: 'ChatMeta';
  chatId: Scalars['ID'];
  firstMessageTs: Scalars['Int'];
  latestMessageTs: Scalars['Int'];
};

export type ChatNotFoundError = Error & {
  __typename: 'ChatNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type ChatResult = Chat | ChatAccessDeniedError | ChatInputError | ChatNotFoundError;

export type ChooseBusinessWorkTypeSubsetsInput = {
  businessId: Scalars['ID'];
  subsetIds: Array<Scalars['ID']>;
};

export type ChooseBusinessWorkTypeSubsetsResponse = {
  __typename: 'ChooseBusinessWorkTypeSubsetsResponse';
  subsets: Array<WorkTypeSubset>;
};

export type ChooseBusinessWorkTypesInput = {
  businessId: Scalars['ID'];
  workTypeIds: Array<Scalars['ID']>;
};

export type ChooseBusinessWorkTypesResponse = {
  __typename: 'ChooseBusinessWorkTypesResponse';
  workTypes: Array<WorkType>;
};

export type CompanyAlreadyExistsError = Error & {
  __typename: 'CompanyAlreadyExistsError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type CompanyProduct = {
  isOwned: Scalars['Boolean'];
};

export type CompanyProducts = {
  __typename: 'CompanyProducts';
  advertising: SimpleProduct;
  basicPackage: SimpleProduct;
  boost: SimpleProduct;
  companySearch: SimpleProduct;
  homepage: SimpleProduct;
  jobPackage: SubscriptionProduct;
  proPackage: SimpleProduct;
  xlBasicPackage: SimpleProduct;
};

export type CompanyResignationInput = {
  /**  tender Id */
  jobId: Scalars['ID'];
};

export type CompanySelectionInput = {
  /**  company Id */
  companyId: Scalars['ID'];
  /**  tender Id */
  jobId: Scalars['ID'];
};

export type CompanySettings = {
  __typename: 'CompanySettings';
  BoostCredits?: Maybe<Scalars['Int']>;
  BoostCreditsRemaining?: Maybe<Scalars['Int']>;
  CanAnswerFreeJobs?: Maybe<Scalars['Boolean']>;
  EmailFreeJobsDay?: Maybe<Scalars['Boolean']>;
  EmailFreeJobsNight?: Maybe<Scalars['Boolean']>;
  EmailRecipients?: Maybe<Scalars['String']>;
  JobFolders?: Maybe<Scalars['Boolean']>;
  NumAnswers?: Maybe<Scalars['Int']>;
  NumAnswersRemaining?: Maybe<Scalars['Int']>;
  NumPhoneNumberShown?: Maybe<Scalars['Int']>;
  NumProfilePageVisits?: Maybe<Scalars['Int']>;
  OnlyLargeJobs?: Maybe<Scalars['Boolean']>;
  ProfilePage?: Maybe<Scalars['Boolean']>;
  ProfilePageCanonicalUrl?: Maybe<Scalars['String']>;
  ProfilePageShowAreas?: Maybe<Scalars['Boolean']>;
  ProfilePageShowMap?: Maybe<Scalars['Boolean']>;
  ProfilePageShowWorktypes?: Maybe<Scalars['Boolean']>;
  ProfileVideoUrl?: Maybe<Scalars['String']>;
  PushEachNewJob?: Maybe<Scalars['Boolean']>;
  ReceiveDoffin?: Maybe<Scalars['Boolean']>;
  SmsEmailFromProfilePage?: Maybe<Scalars['Boolean']>;
  SmsHugeJobs?: Maybe<Scalars['Boolean']>;
  SmsLargeJobs?: Maybe<Scalars['Boolean']>;
  SmsNewAnswer?: Maybe<Scalars['Boolean']>;
  SmsNormalJobs?: Maybe<Scalars['Boolean']>;
  TopRated?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export enum CompanySettingsJobAccess {
  Blocked = 'BLOCKED',
  FreeOnly = 'FREE_ONLY',
  FullAccess = 'FULL_ACCESS',
  OrderDecides = 'ORDER_DECIDES'
}

export type CompanyWantsContactAboutProductInput = {
  /**  Will become required  */
  buttonText?: InputMaybe<Scalars['String']>;
  jobId?: InputMaybe<Scalars['ID']>;
  productTemplateSlug: ProductTemplateSlug;
  /**  Will become required  */
  url?: InputMaybe<Scalars['String']>;
};

export type CompletePasswordResetInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  token: Scalars['String'];
};

export type ConfirmBoligmappaFileUploadInput = {
  fileIds: Array<Scalars['ID']>;
};

export type ConnectUserToJobInput = {
  id: Scalars['ID'];
  owner: ConnectUserToJobOwnerInput;
};

export type ConnectUserToJobOwnerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type ConnectUserToJobResult = {
  __typename: 'ConnectUserToJobResult';
  job: Job;
  smsVerificationToken?: Maybe<Scalars['String']>;
};

export type Connection = {
  edges: Array<Edge>;
  pageInfo: PageInfo;
};

export type ConsentActivity = {
  __typename: 'ConsentActivity';
  caption?: Maybe<Scalars['String']>;
  caution?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionExtended?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  link?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ConsentCategory = {
  __typename: 'ConsentCategory';
  activities: Array<ConsentActivity>;
  caution: Scalars['String'];
  checked?: Maybe<Scalars['Boolean']>;
  default: Scalars['Boolean'];
  description: Scalars['String'];
  descriptionExtended: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  optional: Scalars['Boolean'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ConsentCategoryInput = {
  checked: Scalars['Boolean'];
  id: Scalars['ID'];
};

export enum ConsumerJobSurveyContractWrittenAnswerChoice {
  ContractNotWritten = 'CONTRACT_NOT_WRITTEN',
  ContractWritten = 'CONTRACT_WRITTEN'
}

export type ConsumerJobSurveyContractWrittenAnswerInput = {
  choice: ConsumerJobSurveyContractWrittenAnswerChoice;
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveyDidChooseSuggestedCompanyAnswerChoice {
  DidChoose = 'DID_CHOOSE',
  DidNotChoose = 'DID_NOT_CHOOSE'
}

export type ConsumerJobSurveyDidChooseSuggestedCompanyAnswerInput = {
  choice: ConsumerJobSurveyDidChooseSuggestedCompanyAnswerChoice;
  companies?: InputMaybe<Scalars['String']>;
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveyEvaluatedAnswerChoice {
  Evaluated = 'EVALUATED',
  NotEvaluated = 'NOT_EVALUATED'
}

export type ConsumerJobSurveyEvaluatedAnswerInput = {
  choice: ConsumerJobSurveyEvaluatedAnswerChoice;
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveyFeedbackAnswerChoice {
  FeedbackGiven = 'FEEDBACK_GIVEN',
  FeedbackNotGiven = 'FEEDBACK_NOT_GIVEN'
}

export type ConsumerJobSurveyFeedbackAnswerInput = {
  choice: ConsumerJobSurveyFeedbackAnswerChoice;
  jobId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type ConsumerJobSurveyNoLongerRelevantOutsideCompanyAnswerInput = {
  choice: Scalars['ID'];
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveyNoLongerRelevantPostponedTimeAnswerChoice {
  Other = 'OTHER',
  PostponedIndefinite = 'POSTPONED_INDEFINITE',
  PostponedSixMonths = 'POSTPONED_SIX_MONTHS',
  PostponedYear = 'POSTPONED_YEAR'
}

export type ConsumerJobSurveyNoLongerRelevantPostponedTimeAnswerInput = {
  choice: ConsumerJobSurveyNoLongerRelevantPostponedTimeAnswerChoice;
  jobId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export enum ConsumerJobSurveyNoLongerRelevantReasonAnswerChoice {
  JobNotNeeded = 'JOB_NOT_NEEDED',
  JobPostponed = 'JOB_POSTPONED',
  Other = 'OTHER',
  OutsideCompany = 'OUTSIDE_COMPANY'
}

export type ConsumerJobSurveyNoLongerRelevantReasonAnswerInput = {
  choice: ConsumerJobSurveyNoLongerRelevantReasonAnswerChoice;
  jobId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export enum ConsumerJobSurveySelectCompanyAnswerChoice {
  NotSelected = 'NOT_SELECTED',
  Selected = 'SELECTED'
}

export type ConsumerJobSurveySelectCompanyAnswerInput = {
  choice: ConsumerJobSurveySelectCompanyAnswerChoice;
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveySelectCompanyReasonAnswerChoice {
  EasyCommunication = 'EASY_COMMUNICATION',
  LowPrice = 'LOW_PRICE',
  Other = 'OTHER',
  RelevantExperience = 'RELEVANT_EXPERIENCE',
  ShortNoticeStart = 'SHORT_NOTICE_START'
}

export type ConsumerJobSurveySelectCompanyReasonAnswerInput = {
  choice: ConsumerJobSurveySelectCompanyReasonAnswerChoice;
  jobId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export enum ConsumerJobSurveyStatusAnswerChoice {
  Completed = 'COMPLETED',
  FoundCompany = 'FOUND_COMPANY',
  NoLongerRelevant = 'NO_LONGER_RELEVANT',
  StillLooking = 'STILL_LOOKING'
}

export type ConsumerJobSurveyStatusAnswerInput = {
  choice: ConsumerJobSurveyStatusAnswerChoice;
  jobId: Scalars['ID'];
};

export enum ConsumerJobSurveyStillLookingReasonAnswerChoice {
  CommunicationDifficulty = 'COMMUNICATION_DIFFICULTY',
  HighPrices = 'HIGH_PRICES',
  LackingRelevantExperience = 'LACKING_RELEVANT_EXPERIENCE',
  NoTimeAvailable = 'NO_TIME_AVAILABLE',
  Other = 'OTHER',
  TooFewBusinesses = 'TOO_FEW_BUSINESSES'
}

export type ConsumerJobSurveyStillLookingReasonAnswerInput = {
  choice: ConsumerJobSurveyStillLookingReasonAnswerChoice;
  jobId: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type ConsumerUpdateProfileAddressInput = {
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type ConsumerUpdateProfileInput = {
  address: ConsumerUpdateProfileAddressInput;
  email: Scalars['EmailAddress'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type Contract = {
  chat?: Maybe<Chat>;
  id: Scalars['ID'];
  job: Job;
  regTime: Scalars['Int'];
  type: ContractType;
};

export type ContractInput = {
  id: Scalars['ID'];
};

export enum ContractList {
  All = 'ALL'
}

export type ContractListInput = {
  count?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  list: ContractList;
  page?: InputMaybe<Scalars['Int']>;
};

export type ContractListPageInfo = {
  __typename: 'ContractListPageInfo';
  totalCount: Scalars['Int'];
};

export type ContractListPayload = {
  __typename: 'ContractListPayload';
  list: Array<Contract>;
  pageInfo?: Maybe<ContractListPageInfo>;
};

export enum ContractPriceType {
  /**  Fixed price  */
  Fixed = 'FIXED',
  /**  Price per hour  */
  HourComputation = 'HOUR_COMPUTATION',
  /**  Price does is not part of the contract  */
  NoComputation = 'NO_COMPUTATION',
  /**  Agreed on price, but neither FIXED nor HOUR_COMPUTATION  */
  Other = 'OTHER'
}

export enum ContractType {
  /**  Reserved for later use  */
  InHouseContract = 'IN_HOUSE_CONTRACT',
  /**  Reserved for later use  */
  Other = 'OTHER',
  SimpleAgreement = 'SIMPLE_AGREEMENT',
  SwiftcourtContract = 'SWIFTCOURT_CONTRACT'
}

export type Country = Node & {
  __typename: 'Country';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type County = Node & {
  __typename: 'County';
  code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CountyInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateAnswerTemplateInput = {
  text: Scalars['String'];
  title: Scalars['String'];
};

export type CreateAnswerTemplatePayload = {
  __typename: 'CreateAnswerTemplatePayload';
  success?: Maybe<Scalars['Boolean']>;
  /**  Set if success  */
  template?: Maybe<AnswerTemplate>;
};

export type CreateBusinessAlbumInput = {
  name: Scalars['String'];
};

export type CreateBusinessAlbumPayload = {
  __typename: 'CreateBusinessAlbumPayload';
  album: Album;
};

export type CreateJobFolderInput = {
  businessId: Scalars['ID'];
  color?: InputMaybe<Scalars['HexColorCode']>;
  description?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type CreateJobFolderInputError = Error & {
  __typename: 'CreateJobFolderInputError';
  businessId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  color?: Maybe<BasicInputError>;
  description?: Maybe<BasicInputError>;
  message: Scalars['String'];
  title?: Maybe<BasicInputError>;
};

export type CreateJobFolderPayload = {
  __typename: 'CreateJobFolderPayload';
  folder: JobFolder;
};

export type CreateJobFolderResult = CreateJobFolderInputError | CreateJobFolderPayload;

export type CreateJobInput = {
  description: Scalars['String'];
  title: Scalars['String'];
  type: JobType;
};

export type CreateJobWatchlistInput = {
  countyId: Array<Scalars['ID']>;
  districtId: Array<Scalars['ID']>;
  industryIds: Array<Scalars['ID']>;
  municipalityCode: Array<Scalars['ID']>;
  notificationSettings?: InputMaybe<JobWatchlistNotificationSettingsInput>;
  query: Scalars['String'];
  sizes: Array<JobSize>;
  title: Scalars['String'];
  worktypeIds: Array<Scalars['ID']>;
  worktypeSubsetGroups: Array<Scalars['ID']>;
  worktypeSubsets: Array<Scalars['ID']>;
};

export type CreateJobWorkTypeInput = {
  /**  job ID  */
  jobId: Scalars['ID'];
  /**  workType ID  */
  wId: Scalars['ID'];
};

export type CreateSimpleAgreementContractInput = {
  businessEmail?: InputMaybe<Scalars['String']>;
  businessId?: InputMaybe<Scalars['ID']>;
  businessPhone?: InputMaybe<Scalars['String']>;
  consumerEmail?: InputMaybe<Scalars['String']>;
  consumerPhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Int']>;
  jobId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Int']>;
  priceType: SimpleAgreementPriceType;
  sign?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Int']>;
};

export type DailyOpeningHours = {
  __typename: 'DailyOpeningHours';
  from: Scalars['TimeFormatHHMM'];
  to: Scalars['TimeFormatHHMM'];
};

export type DeleteAnswerTemplateInput = {
  id: Scalars['ID'];
};

export type DeleteAnswerTemplatePayload = {
  __typename: 'DeleteAnswerTemplatePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteBoligmappaFileInput = {
  boligmappaNumber: Scalars['ID'];
  fileId: Scalars['ID'];
  tenderId: Scalars['ID'];
};

export type DeleteBusinessAlbumImageInput = {
  albumId: Scalars['ID'];
  albumImageId: Scalars['ID'];
};

export type DeleteBusinessAlbumImagePayload = {
  __typename: 'DeleteBusinessAlbumImagePayload';
  success: Scalars['Boolean'];
};

export type DeleteBusinessAlbumInput = {
  albumId: Scalars['ID'];
};

export type DeleteBusinessAlbumPayload = {
  __typename: 'DeleteBusinessAlbumPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteBusinessEmployeeInput = {
  employeeId: Scalars['ID'];
};

export type DeleteBusinessEmployeePayload = {
  __typename: 'DeleteBusinessEmployeePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteChatInput = {
  id: Scalars['ID'];
};

export type DeleteChatPayload = {
  __typename: 'DeleteChatPayload';
  chat?: Maybe<Chat>;
  success?: Maybe<Scalars['Boolean']>;
};

export type DeleteJobAttachmentInput = {
  attachmentId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type DeleteJobAttachmentInputError = {
  __typename: 'DeleteJobAttachmentInputError';
  attachmentId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type DeleteJobAttachmentNoAccessError = {
  __typename: 'DeleteJobAttachmentNoAccessError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type DeleteJobAttachmentNotFoundError = {
  __typename: 'DeleteJobAttachmentNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type DeleteJobAttachmentPayload = {
  __typename: 'DeleteJobAttachmentPayload';
  success: Scalars['Boolean'];
};

export type DeleteJobAttachmentResult = DeleteJobAttachmentInputError | DeleteJobAttachmentNoAccessError | DeleteJobAttachmentNotFoundError | DeleteJobAttachmentPayload;

export type DeleteJobFolderInput = {
  folderId: Scalars['ID'];
};

export type DeleteJobInput = {
  jobId: Scalars['ID'];
};

export type DeleteJobPayload = {
  __typename: 'DeleteJobPayload';
  success: Scalars['Boolean'];
};

export type Dimension = {
  __typename: 'Dimension';
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type District = Node & {
  __typename: 'District';
  id: Scalars['ID'];
  municipality?: Maybe<Municipality>;
  name: Scalars['String'];
};

export type Edge = {
  cursor: Scalars['String'];
  node: Node;
};

export type EditJobFolderInput = {
  color?: InputMaybe<Scalars['HexColorCode']>;
  description?: InputMaybe<Scalars['String']>;
  folderId: Scalars['ID'];
  score?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditJobFolderInputError = Error & {
  __typename: 'EditJobFolderInputError';
  code?: Maybe<Scalars['Int']>;
  color?: Maybe<BasicInputError>;
  description?: Maybe<BasicInputError>;
  message: Scalars['String'];
  score?: Maybe<BasicInputError>;
  title?: Maybe<BasicInputError>;
};

export type EditJobFolderPayload = {
  __typename: 'EditJobFolderPayload';
  folder: JobFolder;
};

export type EditJobFolderResult = EditJobFolderInputError | EditJobFolderPayload;

export type EditSimpleAgreementContractConsumerInput = {
  amount: Scalars['Int'];
  amountType: SimpleAgreementPriceType;
  companyId: Scalars['ID'];
  consumerEmail?: InputMaybe<Scalars['String']>;
  consumerPhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Int']>;
  hasAgreed: Scalars['Boolean'];
  jobId: Scalars['ID'];
  startDate?: InputMaybe<Scalars['Int']>;
};

export type EditSimpleAgreementContractInput = {
  businessEmail?: InputMaybe<Scalars['String']>;
  businessId?: InputMaybe<Scalars['ID']>;
  businessPhone?: InputMaybe<Scalars['String']>;
  consumerEmail?: InputMaybe<Scalars['String']>;
  consumerPhone?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Int']>;
  jobId: Scalars['ID'];
  price?: InputMaybe<Scalars['Int']>;
  priceType?: InputMaybe<SimpleAgreementPriceType>;
  sign?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Int']>;
};

export type EditSimpleAgreementContractPayload = {
  __typename: 'EditSimpleAgreementContractPayload';
  contract?: Maybe<SimpleAgreementContract>;
};

export type ElasticBusiness = Node & {
  __typename: 'ElasticBusiness';
  addresses: Array<ElasticBusinessAddress>;
  counties: Array<ElasticCounty>;
  id: Scalars['ID'];
  industries: Array<ElasticIndustry>;
  initials: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  isPromoted: Scalars['Boolean'];
  isVerifiedWithSecureId: Scalars['Boolean'];
  isXL: Scalars['Boolean'];
  logo: Scalars['String'];
  name: Scalars['String'];
  numberOfJobsWon: Scalars['Int'];
  orgNr?: Maybe<Scalars['String']>;
  profileDescription?: Maybe<Scalars['String']>;
  profileTitle?: Maybe<Scalars['String']>;
  score?: Maybe<ElasticBusinessScore>;
  socialMedia: BusinessSocialMedia;
  workTypes: Array<ElasticWorkType>;
};

export type ElasticBusinessAddress = {
  __typename: 'ElasticBusinessAddress';
  place: Scalars['String'];
  postCode: Scalars['String'];
  street: Scalars['String'];
  type: Scalars['Int'];
};

export type ElasticBusinessConnection = Connection & {
  __typename: 'ElasticBusinessConnection';
  edges: Array<ElasticBusinessEdge>;
  pageInfo: PageInfo;
};

export type ElasticBusinessEdge = Edge & {
  __typename: 'ElasticBusinessEdge';
  cursor: Scalars['String'];
  node: ElasticBusiness;
};

export type ElasticBusinessScore = {
  __typename: 'ElasticBusinessScore';
  average: Scalars['Float'];
  numberOfReviews: Scalars['Int'];
  sampleEvaluation?: Maybe<ElasticSampleEvaluation>;
  topRated: Scalars['Boolean'];
};

export type ElasticCounty = {
  __typename: 'ElasticCounty';
  municipalities: Array<Scalars['String']>;
  name: Scalars['String'];
};

export type ElasticIndustry = {
  __typename: 'ElasticIndustry';
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type ElasticSampleEvaluation = {
  __typename: 'ElasticSampleEvaluation';
  author: Scalars['String'];
  date: Scalars['DateTime'];
  score: Scalars['Int'];
  text: Scalars['String'];
};

export type ElasticWorkType = {
  __typename: 'ElasticWorkType';
  id: Scalars['Int'];
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type Error = {
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type EvaluateBusinessInput = {
  business: Scalars['ID'];
  description: Scalars['String'];
  /** @deprecated No longer needed, will get ignored */
  firstName?: InputMaybe<Scalars['String']>;
  focus: EvaluationFocus;
  jobId: Scalars['ID'];
  score: Scalars['Int'];
};

export type Evaluation = Node & {
  __typename: 'Evaluation';
  approvedTime?: Maybe<Scalars['Int']>;
  /**  The business evaluated  */
  business: Business;
  createdTime: Scalars['Int'];
  deletedTime?: Maybe<Scalars['Int']>;
  /**  Contents of the evaluation  */
  description?: Maybe<Scalars['String']>;
  focus?: Maybe<EvaluationFocus>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  /**  Job the evaluation is based upon  */
  job: Job;
  lastUpdatedTime?: Maybe<Scalars['Int']>;
  publishedTime?: Maybe<Scalars['Int']>;
  reply?: Maybe<EvaluationReply>;
  score: Scalars['Int'];
  verifiedTime?: Maybe<Scalars['Int']>;
  /**  Name of the person who have evaluated  */
  writerName: Scalars['String'];
};

export type EvaluationConnection = Connection & {
  __typename: 'EvaluationConnection';
  edges: Array<EvaluationEdge>;
  pageInfo: PageInfo;
};

export type EvaluationEdge = Edge & {
  __typename: 'EvaluationEdge';
  cursor: Scalars['String'];
  node: Evaluation;
};

export enum EvaluationFocus {
  Communication = 'COMMUNICATION',
  Price = 'PRICE',
  Professionalism = 'PROFESSIONALISM',
  Quality = 'QUALITY',
  Reliability = 'RELIABILITY',
  Tidiness = 'TIDINESS'
}

export type EvaluationInput = {
  evaluationId: Scalars['ID'];
};

export type EvaluationInputError = Error & {
  __typename: 'EvaluationInputError';
  code?: Maybe<Scalars['Int']>;
  evaluationId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type EvaluationListInput = {
  businessIds?: InputMaybe<Array<Scalars['ID']>>;
  cityNames?: InputMaybe<Array<Scalars['String']>>;
  countyNames?: InputMaybe<Array<Scalars['String']>>;
  customerType?: InputMaybe<JobCustomerType>;
  industryIds?: InputMaybe<Array<Scalars['ID']>>;
  jobStates?: InputMaybe<Array<Scalars['Int']>>;
  minimumScore?: InputMaybe<Scalars['Int']>;
  /**  Currently only accepts name of municipality, ie. 'Oslo', 'Langevåg'. According to backend, geo IDs can not be trusted in this use case until they are in a separate MS.  */
  municipalities?: InputMaybe<Array<Scalars['String']>>;
  paging?: InputMaybe<PageSelect>;
  workTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type EvaluationListInputError = Error & {
  __typename: 'EvaluationListInputError';
  businessIds?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  industryIds?: Maybe<BasicInputError>;
  jobStates?: Maybe<BasicInputError>;
  message: Scalars['String'];
  minimumScore?: Maybe<BasicInputError>;
  paging?: Maybe<PageSelectInputError>;
  workTypeIds?: Maybe<BasicInputError>;
};

export type EvaluationListPayload = {
  __typename: 'EvaluationListPayload';
  evaluationConnection?: Maybe<EvaluationConnection>;
};

export type EvaluationListResult = EvaluationListInputError | EvaluationListPayload;

export type EvaluationNotFoundError = Error & {
  __typename: 'EvaluationNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type EvaluationPayload = {
  __typename: 'EvaluationPayload';
  evaluation?: Maybe<Evaluation>;
};

export type EvaluationReply = Node & {
  __typename: 'EvaluationReply';
  business: Business;
  createdTime: Scalars['Int'];
  description: Scalars['String'];
  evaluation: Evaluation;
  id: Scalars['ID'];
  publishedTime?: Maybe<Scalars['Int']>;
};

export type EvaluationResult = EvaluationInputError | EvaluationNotFoundError | EvaluationPayload;

export type FailedToSendWelcomeEmailError = Error & {
  __typename: 'FailedToSendWelcomeEmailError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type FavouriteJobInput = {
  jobId: Scalars['ID'];
};

export type FavouriteJobPayload = {
  __typename: 'FavouriteJobPayload';
  success: Scalars['Boolean'];
};

export type File = Node & {
  __typename: 'File';
  description?: Maybe<Scalars['String']>;
  fileType: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type ForgotUsernameInput = {
  phone: Scalars['String'];
};

export type ForgotUsernameInputError = Error & {
  __typename: 'ForgotUsernameInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  phone?: Maybe<BasicInputError>;
};

export type ForgotUsernamePayload = {
  __typename: 'ForgotUsernamePayload';
  success: Scalars['Boolean'];
};

export type ForgotUsernameResult = ForgotUsernameInputError | ForgotUsernamePayload;

export enum FuelType {
  Diesel = 'DIESEL',
  Electricity = 'ELECTRICITY',
  Gas = 'GAS',
  Other = 'OTHER'
}

export type GenericJobMeta = {
  __typename: 'GenericJobMeta';
  /**  Should equal to one of JobMetaSlug as kebab case. but we need to 'expect the unexpected' as new ones will be added from time to time.  */
  slug?: Maybe<Scalars['String']>;
  /**  Use this in case slugs are not translated in front end  */
  title?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type GenericJobMetaInput = {
  /**
   * Mostly JobMetaSlug values in kebab case. Still, defined as string as we most likely will be adding fields
   * rather rapidly. We do not want app clients with old bundles breaking.
   *
   * Non-JobMetaSlug values are typically in kebab-case.
   */
  slug: Scalars['String'];
  value: Scalars['String'];
};

export type GenericJobMetaInputError = Error & {
  __typename: 'GenericJobMetaInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  slug?: Maybe<BasicInputError>;
  value?: Maybe<BasicInputError>;
};

export type GetJobAttachmentsInput = {
  id: Scalars['ID'];
};

export type GetJobFilesInput = {
  tenderId: Scalars['ID'];
};

export type GetJobImagesInput = {
  tenderId: Scalars['ID'];
};

export type GetJobIsReadInput = {
  jobId: Scalars['ID'];
  ownerId?: InputMaybe<Scalars['ID']>;
};

export type GetJobLabelsInput = {
  clipCost?: InputMaybe<Scalars['Int']>;
  isAnswered?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  isFree?: InputMaybe<Scalars['Boolean']>;
  isWon?: InputMaybe<Scalars['Boolean']>;
  jobId: Scalars['ID'];
  size?: InputMaybe<JobSize>;
  state?: InputMaybe<JobState>;
};

export enum HybridCarCatagory {
  Chargable = 'CHARGABLE',
  None = 'NONE',
  NotChargable = 'NOT_CHARGABLE',
  Other = 'OTHER'
}

export enum HybridCarType {
  No = 'NO',
  Other = 'OTHER',
  Yes = 'YES'
}

export type Image = Node & {
  __typename: 'Image';
  bigPreviewUrl: Scalars['String'];
  bigUrl: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  dimensions: Dimension;
  fileType: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  mediumPreviewUrl: Scalars['String'];
  mediumUrl: Scalars['String'];
  name: Scalars['String'];
  size: Scalars['Int'];
  smallPreviewUrl: Scalars['String'];
  smallUrl: Scalars['String'];
  url: Scalars['String'];
};

export type ImageUploadInput = {
  file: Scalars['Upload'];
};

export type ImageUploadInputError = Error & {
  __typename: 'ImageUploadInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type ImageUploadPayload = {
  __typename: 'ImageUploadPayload';
  logo: Image;
};

export type ImageUploadResult = ImageUploadInputError | ImageUploadPayload | LoginRequiredError;

export type IncrementBusinessProfilePageViewsPayload = {
  __typename: 'IncrementBusinessProfilePageViewsPayload';
  success: Scalars['Boolean'];
};

export type Industry = Node & {
  __typename: 'Industry';
  active: Scalars['Int'];
  formSlug: Scalars['String'];
  id: Scalars['ID'];
  slug: Scalars['String'];
  title: Scalars['String'];
  workTypes?: Maybe<Array<Maybe<WorkType>>>;
};

export type InitializeOverdueInvoiceWithdrawalInput = {
  cardId: Scalars['ID'];
  invoiceId: Scalars['ID'];
};

export type InitiateBoligmappaFileUploadInput = {
  boligmappaNumber: Scalars['ID'];
  files: Array<BoligmappaFileElementInput>;
  tenderId: Scalars['ID'];
};

export type InitiateSelfServiceSubscriptionPaymentConsentInput = {
  acceptBindingContract: Scalars['Boolean'];
  formId: Scalars['String'];
  iam: Scalars['Boolean'];
  readTos: Scalars['Boolean'];
};

export type InitiateSelfServiceSubscriptionPaymentInput = {
  consent: InitiateSelfServiceSubscriptionPaymentConsentInput;
  productVariationSlug: SelfServiceSubscriptionName;
  returnUrl: Scalars['String'];
  verificationKey: Scalars['String'];
};

export type Invoice = Node & {
  __typename: 'Invoice';
  dueDate: Scalars['Int'];
  id: Scalars['ID'];
  latestAttempt?: Maybe<InvoiceLatestAttempt>;
  payType: PaymentType;
  paymentLink?: Maybe<Scalars['String']>;
};

export enum InvoiceLatestAttempt {
  AcquirerBadGateway = 'ACQUIRER_BAD_GATEWAY',
  AcquirerError = 'ACQUIRER_ERROR',
  AcquirerGatewayTimeout = 'ACQUIRER_GATEWAY_TIMEOUT',
  AgreementDeleted = 'AGREEMENT_DELETED',
  AgreementNotFound = 'AGREEMENT_NOT_FOUND',
  AgreementNotVerified = 'AGREEMENT_NOT_VERIFIED',
  BadGateway = 'BAD_GATEWAY',
  BadRequest = 'BAD_REQUEST',
  Cancelled = 'CANCELLED',
  CardBlacklisted = 'CARD_BLACKLISTED',
  CardBlacklistedByAcquirer = 'CARD_BLACKLISTED_BY_ACQUIRER',
  CardDeclined = 'CARD_DECLINED',
  CardExpired = 'CARD_EXPIRED',
  CardExpiredOrWrongExpireDate = 'CARD_EXPIRED_OR_WRONG_EXPIRE_DATE',
  CardIssuerNotAvailable = 'CARD_ISSUER_NOT_AVAILABLE',
  CardLostOrStolen = 'CARD_LOST_OR_STOLEN',
  CardNotSupported = 'CARD_NOT_SUPPORTED',
  CardStolen = 'CARD_STOLEN',
  CommunicationFailure = 'COMMUNICATION_FAILURE',
  ConfigurationError = 'CONFIGURATION_ERROR',
  ConfirmationFailed = 'CONFIRMATION_FAILED',
  ConnectionLost = 'CONNECTION_LOST',
  Creditcard_3DsecureDeclined = 'CREDITCARD_3DSECURE_DECLINED',
  Creditcard_3DsecureRequired = 'CREDITCARD_3DSECURE_REQUIRED',
  CreditcardAccountHolderTypeRejected = 'CREDITCARD_ACCOUNT_HOLDER_TYPE_REJECTED',
  CreditcardAuthenticationStatusRejected = 'CREDITCARD_AUTHENTICATION_STATUS_REJECTED',
  CreditcardBrandDisabled = 'CREDITCARD_BRAND_DISABLED',
  CreditcardFraudDetected = 'CREDITCARD_FRAUD_DETECTED',
  CreditcardTypeRejected = 'CREDITCARD_TYPE_REJECTED',
  Credited = 'CREDITED',
  CurrencyNotSupported = 'CURRENCY_NOT_SUPPORTED',
  DebtCollection = 'DEBT_COLLECTION',
  DeclinedDuring_3Dsecure = 'DECLINED_DURING_3DSECURE',
  DeclinedUsing_3Dsecure = 'DECLINED_USING_3DSECURE',
  Failed = 'FAILED',
  Forbidden = 'FORBIDDEN',
  FraudDetected = 'FRAUD_DETECTED',
  Generated = 'GENERATED',
  GenericError = 'GENERIC_ERROR',
  HostOffline = 'HOST_OFFLINE',
  IncorrectCardDetails = 'INCORRECT_CARD_DETAILS',
  InputError = 'INPUT_ERROR',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidAmount = 'INVALID_AMOUNT',
  InvoiceDownloaded = 'INVOICE_DOWNLOADED',
  NotFound = 'NOT_FOUND',
  NoChange = 'NO_CHANGE',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PaymentTokenError = 'PAYMENT_TOKEN_ERROR',
  PossibleDuplicateTransaction = 'POSSIBLE_DUPLICATE_TRANSACTION',
  PossibleFraud = 'POSSIBLE_FRAUD',
  Processing = 'PROCESSING',
  RejectedByCardIssuer = 'REJECTED_BY_CARD_ISSUER',
  ReminderSent = 'REMINDER_SENT',
  RequiresAction = 'REQUIRES_ACTION',
  RequiresCapture = 'REQUIRES_CAPTURE',
  RequiresPaymentMethod = 'REQUIRES_PAYMENT_METHOD',
  SecondReminderSent = 'SECOND_REMINDER_SENT',
  ThreeDSecureBadGateway = 'THREE_D_SECURE_BAD_GATEWAY',
  ThreeDSecureNotWorking = 'THREE_D_SECURE_NOT_WORKING',
  ThreeDSecureUserCancelled = 'THREE_D_SECURE_USER_CANCELLED',
  Unknown = 'UNKNOWN',
  UnknownError = 'UNKNOWN_ERROR'
}

export type InvoiceReceipt = Node & {
  __typename: 'InvoiceReceipt';
  dueDate: Scalars['Int'];
  id: Scalars['ID'];
  invoiceDate: Scalars['Int'];
  paidDate: Scalars['Int'];
  pdfDownloadUrl: Scalars['String'];
  pdfUrl: Scalars['String'];
};

export type InvoiceReceiptConnection = Connection & {
  __typename: 'InvoiceReceiptConnection';
  edges: Array<InvoiceReceiptEdge>;
  pageInfo: PageInfo;
};

export type InvoiceReceiptEdge = Edge & {
  __typename: 'InvoiceReceiptEdge';
  cursor: Scalars['String'];
  node: InvoiceReceipt;
};

export type Job = Node & {
  __typename: 'Job';
  /**  Exact address of job. Available to business after job has been answered. Use postal address until then.  */
  address?: Maybe<Address>;
  /**  Address hinting the jobs location. Useful when the address is not accessible.   */
  addressHint?: Maybe<PostalAddress>;
  /**  number of answers related to a job  */
  answerCount?: Maybe<Scalars['Int']>;
  /**  maximum number of answers to display  */
  answerCountLimit?: Maybe<Scalars['Int']>;
  /**  approval state of a job  */
  approvedAt?: Maybe<Scalars['Int']>;
  /**  Company chosen to a job  */
  chosenOne?: Maybe<Business>;
  clipCost?: Maybe<Scalars['Int']>;
  contactInfo?: Maybe<JobContactInfo>;
  /**  The person who approved / rejected a job  */
  createdAt?: Maybe<Scalars['Int']>;
  /**  Description of the job  */
  description?: Maybe<Scalars['String']>;
  evaluation?: Maybe<Evaluation>;
  files: Array<File>;
  firstMessage?: Maybe<Message>;
  folders?: Maybe<Array<JobFolder>>;
  /** @deprecated use JobBusinessRelationState query */
  hasRequestedEvaluation?: Maybe<Scalars['Boolean']>;
  /** @deprecated use JobBusinessRelationState query */
  hasRequestedSelection?: Maybe<Scalars['Boolean']>;
  /**  job ID  */
  id: Scalars['ID'];
  images: Array<Image>;
  /** @deprecated use JobBusinessRelationState query */
  isAnswered?: Maybe<Scalars['Boolean']>;
  /** @deprecated use JobBusinessRelationState query */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** @deprecated use JobBusinessRelationState query */
  isFavourite?: Maybe<Scalars['Boolean']>;
  /**
   *  If a job has been answered as seen through the eyes of the beholder.
   * @deprecated use JobBusinessRelationState query
   */
  isFree?: Maybe<Scalars['Boolean']>;
  /**
   *  If job was won by another company than the one making the request
   * @deprecated use JobBusinessRelationState query
   */
  isLost?: Maybe<Scalars['Boolean']>;
  isOneOnOne: Scalars['Boolean'];
  /** @deprecated use JobBusinessRelationState query */
  isRead?: Maybe<Scalars['Boolean']>;
  /** @deprecated use JobBusinessRelationState query */
  isWon?: Maybe<Scalars['Boolean']>;
  isXL: Scalars['Boolean'];
  jobBusinessRelationState: JobBusinessRelationState;
  /** @deprecated Use job.traits and jobBusinessRelationState */
  labels: JobLabels;
  latestMessage?: Maybe<Message>;
  meta: JobMeta;
  /**  Doffin jobs do not have a registered user in our system. Thus the field is optional.  */
  owner?: Maybe<User>;
  simpleAgreement?: Maybe<SimpleAgreementContract>;
  /**  the size of a job - connects to   */
  size?: Maybe<JobSize>;
  /**  the state a job is in - connects to the user journey  */
  state: JobState;
  /**  Main title of a job  */
  title: Scalars['String'];
  /** Use this only on list views. On single job view use query jobTraits  */
  traits: JobTraits;
  /**  the type of job, that is MA, MA_XL, 1:1  */
  type: Array<JobType>;
  workTypes: Array<WorkType>;
};

export enum JobAccessDeniedCode {
  AwaitingApproval = 'AWAITING_APPROVAL',
  Other = 'OTHER',
  Protected = 'PROTECTED',
  Unapproved = 'UNAPPROVED',
  WithdrawnByUser = 'WITHDRAWN_BY_USER'
}

export type JobAccessDeniedError = Error & {
  __typename: 'JobAccessDeniedError';
  /** @deprecated Use the new error handling instead. https://github.com/schibsted-smb/smb-apollo-graphql-errors/wiki/server#classes */
  code?: Maybe<Scalars['Int']>;
  /** @deprecated Use the new error handling instead. https://github.com/schibsted-smb/smb-apollo-graphql-errors/wiki/server#classes */
  message: Scalars['String'];
  /** @deprecated Use the new error handling instead. https://github.com/schibsted-smb/smb-apollo-graphql-errors/wiki/server#classes */
  reasonCode?: Maybe<JobAccessDeniedCode>;
};

export type JobAccessInputError = Error & {
  __typename: 'JobAccessInputError';
  code?: Maybe<Scalars['Int']>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type JobAccessPayload = {
  __typename: 'JobAccessPayload';
  access?: Maybe<AnswerAccess>;
};

export type JobAccessResult = JobAccessInputError | JobAccessPayload | LoginRequiredError;

export type JobBusinessRelationState = {
  __typename: 'JobBusinessRelationState';
  businessId: Scalars['ID'];
  clipsSpent?: Maybe<Scalars['Int']>;
  hasRequestedEvaluation: Scalars['Boolean'];
  hasRequestedSelection: Scalars['Boolean'];
  isAnswered: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isFavourite: Scalars['Boolean'];
  isFree: Scalars['Boolean'];
  isLost: Scalars['Boolean'];
  isOneOnOne: Scalars['Boolean'];
  isRead: Scalars['Boolean'];
  isRejected: Scalars['Boolean'];
  isWon: Scalars['Boolean'];
  jobId: Scalars['ID'];
};

export type JobConnection = Connection & {
  __typename: 'JobConnection';
  edges: Array<JobEdge>;
  pageInfo: PageInfo;
};

export type JobConnectionInput = {
  count?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};

export type JobConsumer = Node & {
  __typename: 'JobConsumer';
  /**  Exact address of job. Available to business after job has been answered. Use postal address until then.  */
  address?: Maybe<Address>;
  /**  number of answers related to a job  */
  answerCount?: Maybe<Scalars['Int']>;
  /**  approval state of a job  */
  approvedAt?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  /**  Description of the job  */
  description?: Maybe<Scalars['String']>;
  /**  job ID  */
  id: Scalars['ID'];
  /** list of images attached to a job */
  images: Array<Image>;
  /** is job one on one */
  isOneOnOne?: Maybe<Scalars['Boolean']>;
  /**  is job XL  */
  isXL: Scalars['Boolean'];
  /**  the size of a job - connects to   */
  size?: Maybe<JobSize>;
  /**  the state a job is in - connects to the user journey  */
  state: JobState;
  /**  Main title of a job  */
  title: Scalars['String'];
  /**  number of unread threads in chat */
  unreadThreadsCount: Scalars['Int'];
};

export type JobConsumerListConnection = Connection & {
  __typename: 'JobConsumerListConnection';
  edges: Array<JobConsumerListEdge>;
  pageInfo: PageInfo;
};

export type JobConsumerListEdge = Edge & {
  __typename: 'JobConsumerListEdge';
  cursor: Scalars['String'];
  node: JobConsumer;
};

export type JobConsumerListInput = {
  /**  amount of items on each page  */
  count?: InputMaybe<Scalars['Int']>;
  /**  filter to apply to the list  */
  filter?: InputMaybe<JobListFilterInput>;
  /**  page number to fetch. starts at 1.  */
  page?: InputMaybe<Scalars['Int']>;
};

export type JobConsumerListPayload = {
  __typename: 'JobConsumerListPayload';
  jobConnection: JobConsumerListConnection;
};

export type JobContactInfo = {
  __typename: 'JobContactInfo';
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export enum JobContactPreference {
  SiteAndPhone = 'SITE_AND_PHONE',
  SiteOnly = 'SITE_ONLY'
}

export type JobContactPreferenceInputError = Error & {
  __typename: 'JobContactPreferenceInputError';
  code?: Maybe<Scalars['Int']>;
  contactPreference?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export enum JobCustomerType {
  Company = 'COMPANY',
  Consumer = 'CONSUMER',
  Cooperative = 'COOPERATIVE',
  Union = 'UNION'
}

export type JobCustomerTypeInputError = Error & {
  __typename: 'JobCustomerTypeInputError';
  code?: Maybe<Scalars['Int']>;
  customerType?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type JobDataInput = {
  carRegistrationNumber?: InputMaybe<Scalars['String']>;
  contactPreference?: InputMaybe<JobContactPreference>;
  customerType?: InputMaybe<JobCustomerType>;
  estimatedSquareMeters?: InputMaybe<Scalars['Int']>;
  requestedStart?: InputMaybe<JobStartInput>;
  toAddress?: InputMaybe<AddressInput>;
};

export type JobEdge = Edge & {
  __typename: 'JobEdge';
  cursor: Scalars['String'];
  node: Job;
};

export type JobFolder = {
  __typename: 'JobFolder';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type JobFolderPayload = {
  __typename: 'JobFolderPayload';
  folders: Array<Maybe<JobFolder>>;
};

export type JobIdTranslatedNotFoundError = Error & {
  __typename: 'JobIdTranslatedNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type JobIdTranslatedPayload = {
  __typename: 'JobIdTranslatedPayload';
  transformedId: Scalars['ID'];
};

export type JobIdTranslatedResult = JobIdTranslatedNotFoundError | JobIdTranslatedPayload;

export enum JobLabel {
  /**  covers both big and huge jobs  */
  Big = 'BIG',
  /**  clip cost should be indicated - which must be fetched from database   */
  ClipCount = 'CLIP_COUNT',
  Deleted = 'DELETED',
  /**  job is fetched from doffin service   */
  Doffin = 'DOFFIN',
  /**  job is marked as ended  */
  Ended = 'ENDED',
  /**  business has marked the job as favourite  */
  Favourite = 'FAVOURITE',
  /**  job does not cost any clips  */
  Free = 'FREE',
  /**  the job is on pause  */
  Paused = 'PAUSED',
  /**  business making the request has won the job  */
  Won = 'WON'
}

export type JobLabels = {
  __typename: 'JobLabels';
  big: Scalars['Boolean'];
  /** @deprecated Never used it seems, why is count a boolean? */
  clipCount: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  doffin: Scalars['Boolean'];
  ended: Scalars['Boolean'];
  favourite: Scalars['Boolean'];
  free: Scalars['Boolean'];
  /** @deprecated Never used it seems */
  paused: Scalars['Boolean'];
  won: Scalars['Boolean'];
};

/**  These enums are defined for front end use  */
export enum JobList {
  Answered = 'ANSWERED',
  Big = 'BIG',
  Deleted = 'DELETED',
  Direct = 'DIRECT',
  Favourite = 'FAVOURITE',
  Folders = 'FOLDERS',
  Free = 'FREE',
  Open = 'OPEN',
  Won = 'WON'
}

/**  filters are used when requesting a job list. ID used when requesting a job list  */
export type JobListFilter = Node & {
  __typename: 'JobListFilter';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  listType?: Maybe<JobList>;
};

export type JobListFilterInput = {
  state?: InputMaybe<JobListFilterState>;
};

export enum JobListFilterState {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type JobListInput = {
  /**  amount of items on each page  */
  count?: InputMaybe<Scalars['Int']>;
  /**  In case all municipalities in a county are to be included  */
  countyId?: InputMaybe<Array<Scalars['ID']>>;
  /**  Oslo's municipality entry in the job list filter is replaced by its districts. these can be filtered here.  */
  districtId?: InputMaybe<Array<Scalars['ID']>>;
  /**  Limit results to a specified folder  */
  folderId?: InputMaybe<Array<Scalars['ID']>>;
  industryIds?: InputMaybe<Array<Scalars['ID']>>;
  /**  List id from one of job list types  */
  listId?: InputMaybe<Scalars['ID']>;
  /**  area filter - limits to municipality by array of municipality code IDs  */
  municipalityCode?: InputMaybe<Array<Scalars['ID']>>;
  /**  page number to fetch. starts at 1.  */
  page?: InputMaybe<Scalars['Int']>;
  /**  text search  */
  query?: InputMaybe<Scalars['String']>;
  /**
   * limit to jobs of a certain size
   * mutually exclusive with sizes
   */
  size?: InputMaybe<JobSize>;
  /**
   * limit to jobs of certain sizes
   * mutually exclusive with size
   */
  sizes?: InputMaybe<Array<JobSize>>;
  /**  worktypes  */
  worktypeIds?: InputMaybe<Array<Scalars['ID']>>;
  worktypeSubsetGroups?: InputMaybe<Array<Scalars['ID']>>;
  worktypeSubsets?: InputMaybe<Array<Scalars['ID']>>;
};

export type JobListPayload = {
  __typename: 'JobListPayload';
  jobConnection: JobConnection;
  listId?: Maybe<Scalars['ID']>;
};

export type JobListPublic = {
  __typename: 'JobListPublic';
  geo?: Maybe<JobPublicGeo>;
  job: JobPublic;
};

export type JobListPublicInput = {
  cityNames?: InputMaybe<Array<Scalars['String']>>;
  countyNames?: InputMaybe<Array<Scalars['String']>>;
  customerType?: InputMaybe<JobCustomerType>;
  from?: InputMaybe<Scalars['Int']>;
  includeFiles?: InputMaybe<Scalars['Boolean']>;
  industryIds?: InputMaybe<Array<Scalars['ID']>>;
  municipalityCodes?: InputMaybe<Array<Scalars['ID']>>;
  size?: InputMaybe<Scalars['Int']>;
  worktypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type JobListPublicPayload = {
  __typename: 'JobListPublicPayload';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  jobList: Array<JobListPublic>;
  nextFrom?: Maybe<Scalars['Int']>;
  previousFrom?: Maybe<Scalars['Int']>;
  totalCount: Scalars['Int'];
};

export type JobListResult = JobListPayload | PageOutOfRangeError;

export type JobMeta = {
  __typename: 'JobMeta';
  actualStartDate?: Maybe<Scalars['Int']>;
  car?: Maybe<Car>;
  contactPreference?: Maybe<JobContactPreference>;
  customerType?: Maybe<JobCustomerType>;
  generics: Array<GenericJobMeta>;
  id: Scalars['ID'];
  /** @deprecated Job.contactInfo.phone should be used instead */
  phone?: Maybe<Scalars['String']>;
  requestedStart?: Maybe<JobStart>;
  toAddress?: Maybe<Address>;
};

/**
 * These are additional preferences. Front end should make sure required fields are provided.
 *
 * Required preferences
 */
export type JobMetaInput = {
  /**  Check with devs before using this. It may very well be moved to its own mutation.  */
  actualStartDate?: InputMaybe<Scalars['Int']>;
  /**  car registration plate  */
  carRegistrationNumber?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<JobContactPreference>;
  customerType?: InputMaybe<JobCustomerType>;
  estimatedSize?: InputMaybe<JobSize>;
  estimatedSquareMeters?: InputMaybe<Scalars['String']>;
  /**
   * you know the slug, and it only has a simple value. use this if you are feeling lucky. be prepared for errors though.
   * some assumed generics: cleaning-frequency, customer-type, accommodation-size, number-of-guests, move-out-cleaning, container-size, storage-size, food-and-drink-options
   *
   * NOTE! Some metas are provided through mutations other than ones providing JobMetaInput. ContatPreference is such.
   */
  generic?: InputMaybe<Array<GenericJobMetaInput>>;
  requestedStart?: InputMaybe<JobStartInput>;
  restrictedCompanies?: InputMaybe<Array<Scalars['ID']>>;
  toAddress?: InputMaybe<AddressInput>;
};

/**  Known slugs providable to genenrics  */
export enum JobMetaSlug {
  AccommodationSize = 'ACCOMMODATION_SIZE',
  CleaningFrequency = 'CLEANING_FREQUENCY',
  ContainerSize = 'CONTAINER_SIZE',
  CustomerType = 'CUSTOMER_TYPE',
  FoodAndDrinkOptions = 'FOOD_AND_DRINK_OPTIONS',
  MoveOutCleaning = 'MOVE_OUT_CLEANING',
  NumberOfGuests = 'NUMBER_OF_GUESTS',
  StorageSize = 'STORAGE_SIZE',
  VehicleRegistrationPlate = 'VEHICLE_REGISTRATION_PLATE'
}

export type JobNotFoundError = Error & {
  __typename: 'JobNotFoundError';
  /** @deprecated Use the new error handling instead. https://github.com/schibsted-smb/smb-apollo-graphql-errors/wiki/server#classes */
  code?: Maybe<Scalars['Int']>;
  /** @deprecated Use the new error handling instead. https://github.com/schibsted-smb/smb-apollo-graphql-errors/wiki/server#classes */
  message: Scalars['String'];
};

export type JobOwnerInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type JobPublic = {
  __typename: 'JobPublic';
  attachments: Array<Attachment>;
  description: Scalars['String'];
  id: Scalars['ID'];
  metaregtime: Scalars['Int'];
  title: Scalars['String'];
};

export type JobPublicGeo = {
  __typename: 'JobPublicGeo';
  city: Scalars['String'];
  postCode: Scalars['String'];
};

export type JobResult = Job | JobAccessDeniedError | JobNotFoundError;

export enum JobSize {
  Big = 'BIG',
  Huge = 'HUGE',
  Normal = 'NORMAL',
  Small = 'SMALL',
  Undefined = 'UNDEFINED'
}

export enum JobSource {
  Adservice = 'ADSERVICE',
  Andelsportal = 'ANDELSPORTAL',
  Byggetilbud = 'BYGGETILBUD',
  Finn = 'FINN',
  Googleads = 'GOOGLEADS',
  Tori = 'TORI'
}

export type JobStart = {
  __typename: 'JobStart';
  relatedTime?: Maybe<Scalars['Int']>;
  type: StartAlternative;
};

export type JobStartInput = {
  /**  time stamp which at the given date  */
  date?: InputMaybe<Scalars['Int']>;
  type: StartAlternative;
};

export enum JobState {
  Approved = 'APPROVED',
  ApprovedAnswers = 'APPROVED_ANSWERS',
  ClosedAgreementCompleted = 'CLOSED_AGREEMENT_COMPLETED',
  ClosedAgreementSignedCompany = 'CLOSED_AGREEMENT_SIGNED_COMPANY',
  ClosedAgreementSignedConsumer = 'CLOSED_AGREEMENT_SIGNED_CONSUMER',
  ClosedAgreementUnsigned = 'CLOSED_AGREEMENT_UNSIGNED',
  ClosedAgreementUnsignedChanged = 'CLOSED_AGREEMENT_UNSIGNED_CHANGED',
  ClosedAwaitingApproval = 'CLOSED_AWAITING_APPROVAL',
  ClosedBusinessChosen = 'CLOSED_BUSINESS_CHOSEN',
  ClosedByAdmin = 'CLOSED_BY_ADMIN',
  ClosedByAdminBlackLabour = 'CLOSED_BY_ADMIN_BLACK_LABOUR',
  ClosedByAdminBusinessAdvertising = 'CLOSED_BY_ADMIN_BUSINESS_ADVERTISING',
  ClosedByAdminCollaboration = 'CLOSED_BY_ADMIN_COLLABORATION',
  ClosedByAdminNotSerious = 'CLOSED_BY_ADMIN_NOT_SERIOUS',
  ClosedByAdminNoCategory = 'CLOSED_BY_ADMIN_NO_CATEGORY',
  ClosedByAdminUnknown = 'CLOSED_BY_ADMIN_UNKNOWN',
  ClosedByHandledByPplus = 'CLOSED_BY_HANDLED_BY_PPLUS',
  ClosedCanceled = 'CLOSED_CANCELED',
  ClosedDeficient = 'CLOSED_DEFICIENT',
  ClosedDirectJobNoMatchAbortRegistration = 'CLOSED_DIRECT_JOB_NO_MATCH_ABORT_REGISTRATION',
  ClosedExpired = 'CLOSED_EXPIRED',
  ClosedFinished = 'CLOSED_FINISHED',
  ClosedFinishedBusinessChosen = 'CLOSED_FINISHED_BUSINESS_CHOSEN',
  ClosedFinishedEvaluated = 'CLOSED_FINISHED_EVALUATED',
  ClosedFinishedEvaluatedPriced = 'CLOSED_FINISHED_EVALUATED_PRICED',
  ClosedFinishedOutsideCompany = 'CLOSED_FINISHED_OUTSIDE_COMPANY',
  ClosedFinishedSkippedEvaluation = 'CLOSED_FINISHED_SKIPPED_EVALUATION',
  ClosedLackingAnswers = 'CLOSED_LACKING_ANSWERS',
  ClosedLackingBusiness = 'CLOSED_LACKING_BUSINESS',
  ClosedPaused = 'CLOSED_PAUSED',
  ClosedUnapproved = 'CLOSED_UNAPPROVED',
  ClosedWithdrawnByUser = 'CLOSED_WITHDRAWN_BY_USER',
  UndefinedCondition = 'UNDEFINED_CONDITION',
  UnderRegistrationPage1 = 'UNDER_REGISTRATION_PAGE1',
  UnderRegistrationPage2 = 'UNDER_REGISTRATION_PAGE2',
  UnderRegistrationPage3 = 'UNDER_REGISTRATION_PAGE3',
  UnderRegistrationPage4 = 'UNDER_REGISTRATION_PAGE4',
  UnderRegistrationPage5 = 'UNDER_REGISTRATION_PAGE5',
  UnderRegistrationPage6 = 'UNDER_REGISTRATION_PAGE6',
  UnderRegistrationPage7 = 'UNDER_REGISTRATION_PAGE7',
  UnderRegistrationPage8 = 'UNDER_REGISTRATION_PAGE8',
  UnderRegistrationPage9 = 'UNDER_REGISTRATION_PAGE9'
}

export type JobSuggestionsInput = {
  id: Scalars['ID'];
};

export type JobSuggestionsPayload = {
  __typename: 'JobSuggestionsPayload';
  askForSquareMeters?: Maybe<Scalars['Boolean']>;
};

/** Job traits are used as alternative to JobLabels to describe job properties that are same for all users. Relationship between job and business is encapsulated in JobBusinessRelationState. */
export type JobTraits = {
  __typename: 'JobTraits';
  big: Scalars['Boolean'];
  ended: Scalars['Boolean'];
  estimatedSquareMeters?: Maybe<Scalars['String']>;
  fromVerifiedUser: Scalars['Boolean'];
  id: Scalars['ID'];
  maXL?: Maybe<MaXl>;
};

export enum JobType {
  Mittanbud = 'MITTANBUD',
  MittanbudXl = 'MITTANBUD_XL',
  OneOnOne = 'ONE_ON_ONE'
}

export type JobWatchlist = {
  __typename: 'JobWatchlist';
  default: Scalars['Boolean'];
  filters: JobWatchlistFilters;
  id: Scalars['ID'];
  masterId: Scalars['ID'];
  notificationSettings: JobWatchlistNotificationSettings;
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type JobWatchlistFilters = {
  __typename: 'JobWatchlistFilters';
  countyId: Array<Scalars['ID']>;
  districtId: Array<Scalars['ID']>;
  industryIds: Array<Scalars['ID']>;
  municipalityCode: Array<Scalars['ID']>;
  query: Scalars['String'];
  sizes: Array<JobSize>;
  worktypeIds: Array<Scalars['ID']>;
  worktypeSubsetGroups: Array<Scalars['ID']>;
  worktypeSubsets: Array<Scalars['ID']>;
};

export enum JobWatchlistNotificationFrequency {
  Daily = 'DAILY',
  Immediate = 'IMMEDIATE',
  ImmediateAndDaily = 'IMMEDIATE_AND_DAILY',
  Never = 'NEVER'
}

export type JobWatchlistNotificationSettings = {
  __typename: 'JobWatchlistNotificationSettings';
  email: JobWatchlistNotificationFrequency;
  id: Scalars['ID'];
  push: JobWatchlistNotificationFrequency;
};

export type JobWatchlistNotificationSettingsInput = {
  email: JobWatchlistNotificationFrequency;
  push: JobWatchlistNotificationFrequency;
};

export enum LatestPaymentAttemptResultCode {
  Blacklisted = 'BLACKLISTED',
  Cancelled = 'CANCELLED',
  CardDeclined = 'CARD_DECLINED',
  CardExpired = 'CARD_EXPIRED',
  CardIssuerError = 'CARD_ISSUER_ERROR',
  CardLostOrStolen = 'CARD_LOST_OR_STOLEN',
  CardNotSupported = 'CARD_NOT_SUPPORTED',
  Credited = 'CREDITED',
  CurrencyNotSupported = 'CURRENCY_NOT_SUPPORTED',
  FraudDetected = 'FRAUD_DETECTED',
  GenericError = 'GENERIC_ERROR',
  IncorrectCardDetails = 'INCORRECT_CARD_DETAILS',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID'
}

export type LoggedInStatus = {
  __typename: 'LoggedInStatus';
  as?: Maybe<UserRole>;
  loggedIn: Scalars['Boolean'];
};

export type LoginAlreadyLoggedInError = Error & {
  __typename: 'LoginAlreadyLoggedInError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginCompanyError = Error & {
  __typename: 'LoginCompanyError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginCompanyNotActiveError = Error & {
  __typename: 'LoginCompanyNotActiveError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginCredentialsError = Error & {
  __typename: 'LoginCredentialsError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginInput = {
  email: Scalars['String'];
  /**  Specify if login is intended for a specific role. Users of business app intend to log in as employees.  */
  intendedRole?: InputMaybe<UserRole>;
  password: Scalars['String'];
};

export type LoginInputError = Error & {
  __typename: 'LoginInputError';
  code?: Maybe<Scalars['Int']>;
  email?: Maybe<BasicInputError>;
  message: Scalars['String'];
  password?: Maybe<BasicInputError>;
};

export type LoginPayload = {
  __typename: 'LoginPayload';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type LoginRequiredError = Error & {
  __typename: 'LoginRequiredError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginResult = LoginAlreadyLoggedInError | LoginCompanyNotActiveError | LoginCredentialsError | LoginInputError | LoginPayload | LoginRoleUnavailableToUserError | LoginRoleUnsupportedInContextError | LoginUserNotApartOfCompanyError | UserDisabledError;

export type LoginRoleUnavailableToUserError = Error & {
  __typename: 'LoginRoleUnavailableToUserError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginRoleUnsupportedInContextError = Error & {
  __typename: 'LoginRoleUnsupportedInContextError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginUserNotApartOfCompanyError = Error & {
  __typename: 'LoginUserNotApartOfCompanyError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type LoginWithEmailInputError = Error & {
  __typename: 'LoginWithEmailInputError';
  code?: Maybe<Scalars['Int']>;
  domain?: Maybe<BasicInputError>;
  email?: Maybe<BasicInputError>;
  message: Scalars['String'];
  path?: Maybe<BasicInputError>;
};

export type LoginWithEmailPayload = {
  __typename: 'LoginWithEmailPayload';
  success: Scalars['Boolean'];
};

export type LoginWithEmailResult = LoginWithEmailInputError | LoginWithEmailPayload;

export type LoginWithOneTimePasswordInput = {
  email: Scalars['String'];
  intendedRole?: InputMaybe<UserRole>;
  /** One-Time Password */
  otp: Scalars['String'];
};

export type LoginWithTokenInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  /**  Specify if login is intended for a specific role. Users of business app intend to log in as employees.  */
  intendedRole?: InputMaybe<UserRole>;
  /**  Specify that an admin with this 'scoutToken' is logging in as the user with 'token'.  */
  scoutToken?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type LoginWithTokenInputError = Error & {
  __typename: 'LoginWithTokenInputError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  token?: Maybe<BasicInputError>;
};

export type LoginWithTokenResult = LoginAlreadyLoggedInError | LoginCompanyError | LoginCompanyNotActiveError | LoginCredentialsError | LoginPayload | LoginRoleUnavailableToUserError | LoginRoleUnsupportedInContextError | LoginUserNotApartOfCompanyError | LoginWithTokenInputError | UserDisabledError;

export type LoginWithVerificationCodeInput = {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type LoginWithVerificationCodeInputError = Error & {
  __typename: 'LoginWithVerificationCodeInputError';
  code?: Maybe<Scalars['Int']>;
  email?: Maybe<BasicInputError>;
  message: Scalars['String'];
  verificationCode?: Maybe<BasicInputError>;
};

export type LoginWithVerificationCodeResult = LoginAlreadyLoggedInError | LoginCompanyError | LoginCompanyNotActiveError | LoginCredentialsError | LoginPayload | LoginRoleUnavailableToUserError | LoginRoleUnsupportedInContextError | LoginUserNotApartOfCompanyError | LoginWithVerificationCodeInputError | UserDisabledError;

export type MaXl = {
  __typename: 'MaXL';
  eligible: Scalars['Boolean'];
  reviewState?: Maybe<Scalars['Int']>;
};

export type MakeJobPublicInput = {
  id: Scalars['ID'];
};

export type MarkConsumerJobAsCompleteInput = {
  id: Scalars['ID'];
};

export type Message = Node & {
  __typename: 'Message';
  business?: Maybe<Business>;
  chat: Chat;
  files?: Maybe<Array<File>>;
  id: Scalars['ID'];
  images?: Maybe<Array<Image>>;
  readEvents?: Maybe<Array<MessageReadEvent>>;
  regTs: Scalars['Int'];
  status: MessageStatus;
  text?: Maybe<Scalars['String']>;
  type: MessageType;
  writer: User;
};

export type MessageConnection = Connection & {
  __typename: 'MessageConnection';
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
};

export type MessageEdge = Edge & {
  __typename: 'MessageEdge';
  cursor: Scalars['String'];
  node: Message;
};

export type MessageReadEvent = Node & {
  __typename: 'MessageReadEvent';
  /**  Only present if read as business  */
  business?: Maybe<Business>;
  id: Scalars['ID'];
  user: User;
};

export enum MessageStatus {
  Failed = 'FAILED',
  Read = 'READ',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export enum MessageType {
  Attachment = 'ATTACHMENT',
  System = 'SYSTEM',
  Text = 'TEXT'
}

export type MessagesFilter = {
  /**  Max number of items to fetch  */
  first?: InputMaybe<Scalars['Int']>;
  /**
   * Position to start at, 0 and upwards. Set to 25 if first 25 are already fetched and you want the next batch.
   *
   * Not the same as 'after' in Relay spec. That one is cursor based.
   *
   * NOTE! Ignored when used in combination with last, for now at least.
   */
  from?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  orderByTime?: InputMaybe<SortDirection>;
};

export type Municipality = Node & {
  __typename: 'Municipality';
  code: Scalars['Int'];
  county: County;
  districts?: Maybe<Array<Maybe<District>>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Mutation = {
  __typename: 'Mutation';
  GDPRRequestDeleteUser?: Maybe<Scalars['Void']>;
  GDPRRequestDownloadData?: Maybe<Scalars['Void']>;
  abortOneOnOneJobRegistrationNoMatch: Scalars['Boolean'];
  addBusinessEmployee: AddBusinessEmployeeResult;
  /** @deprecated Use addPushNotificationToken instead */
  addExpoPushNotificationToken: AddExpoPushNotificationTokenResult;
  addJobToJobFolder: Scalars['Boolean'];
  addLocationToJob: Address;
  addPushNotificationToken: AddPushNotificationTokenResult;
  answerJob: AnswerJobResult;
  authorizeBoligmappaIntegration: BoligmappaIntegrationState;
  boligmappaAssociateAddress: BoligmappaAssociationResponse;
  boligmappaCreateAccount: Scalars['Boolean'];
  boligmappaDeleteFile: Array<BoligmappaAssociation>;
  boligmappaRemoveAssociation?: Maybe<BoligmappaAssociationResponse>;
  boligmappaRequestedDocumentation: Scalars['Boolean'];
  businessAskToBeEvaluated: Scalars['Boolean'];
  businessAskToBeSelected: Scalars['Boolean'];
  cancelConsumerJob: JobConsumer;
  cancelRegistrationAttempt: Scalars['Boolean'];
  chooseBusinessWorkTypeSubsets: ChooseBusinessWorkTypeSubsetsResponse;
  chooseBusinessWorkTypes: ChooseBusinessWorkTypesResponse;
  companyWantsContactAboutProduct?: Maybe<Scalars['Void']>;
  completePasswordReset?: Maybe<Scalars['Void']>;
  confirmBoligmappaFileUpload: Array<BoligmappaAssociation>;
  connectUserToJob: ConnectUserToJobResult;
  consumerJobSurveyContractWritten: Scalars['Boolean'];
  consumerJobSurveyDidChooseSuggestedCompany: Scalars['Boolean'];
  consumerJobSurveyEvaluated: Scalars['Boolean'];
  consumerJobSurveyFeedback: Scalars['Boolean'];
  consumerJobSurveyNoLongerRelevantOutsideCompany: Scalars['Boolean'];
  consumerJobSurveyNoLongerRelevantPostponedTime: Scalars['Boolean'];
  consumerJobSurveyNoLongerRelevantReason: Scalars['Boolean'];
  consumerJobSurveySelectCompany: Scalars['Boolean'];
  consumerJobSurveySelectCompanyReason: Scalars['Boolean'];
  consumerJobSurveyStatus: Scalars['Boolean'];
  consumerJobSurveyStillLookingReason: Scalars['Boolean'];
  consumerUpdateProfile: User;
  createAnswerTemplate: CreateAnswerTemplatePayload;
  createBusinessAlbum: CreateBusinessAlbumPayload;
  createJob: Job;
  createJobFolder: CreateJobFolderResult;
  createJobWatchlist: JobWatchlist;
  createSimpleAgreementContract: SimpleAgreementContract;
  createTicket: Ticket;
  deleteAnswerTemplate: DeleteAnswerTemplatePayload;
  deleteBusinessAlbum: DeleteBusinessAlbumPayload;
  deleteBusinessAlbumImage: DeleteBusinessAlbumImagePayload;
  deleteBusinessEmployee: DeleteBusinessEmployeePayload;
  deleteBusinessEmployeeImage?: Maybe<Scalars['Boolean']>;
  deleteBusinessLogo: Scalars['Boolean'];
  deleteChat: DeleteChatPayload;
  deleteJob: DeleteJobPayload;
  deleteJobAttachment: DeleteJobAttachmentResult;
  deleteJobFolder: Scalars['Boolean'];
  deleteJobWatchlist: Scalars['Boolean'];
  editJobFolder: EditJobFolderResult;
  editSimpleAgreementContract: EditSimpleAgreementContractPayload;
  editSimpleAgreementContractConsumer?: Maybe<SimpleAgreementContract>;
  evaluateBusiness: Evaluation;
  favouriteJob: FavouriteJobPayload;
  /** @deprecated Use forgotUsernameV2 */
  forgotUsername: ForgotUsernameResult;
  forgotUsernameV2: Scalars['Boolean'];
  incrementBusinessProfilePageViews: IncrementBusinessProfilePageViewsPayload;
  initializeOverdueInvoiceWithdrawal: Scalars['String'];
  initiateBoligmappaFileUpload: Array<BoligmappaInitiateFileUploadResponse>;
  initiateBoostCreditsPayment: SelfServiceOrderResult;
  initiateSelfServiceSubscriptionPayment: Scalars['String'];
  /** @deprecated Use loginWithPassword instead */
  login: LoginResult;
  /** @deprecated Use requestLoginEmail instead */
  loginWithEmail: LoginWithEmailResult;
  /** @deprecated Use loginWithOneTimePasswordTmp instead */
  loginWithOneTimePassword: LoginPayload;
  /** Temporary mutation for updating the 'loginWithOneTimePassword' input type */
  loginWithOneTimePasswordTmp: LoginPayload;
  loginWithPassword: LoginPayload;
  /** @deprecated Use loginWithTokenV2 instead */
  loginWithToken: LoginWithTokenResult;
  loginWithTokenV2: LoginPayload;
  /** @deprecated Use loginWithOneTimePassword instead */
  loginWithVerificationCode: LoginWithVerificationCodeResult;
  logout: Scalars['Boolean'];
  makeJobPublic: JobConsumer;
  markConsumerJobAsComplete: JobConsumer;
  markPushNotificationAsRead: Scalars['Boolean'];
  /** @deprecated Use operateAsV2 instead */
  operateAs: OperateAsResult;
  /** @deprecated Use operateAsConsumerV2 */
  operateAsConsumer: OperateAsConsumerResult;
  operateAsConsumerV2: OperateAsConsumerPayload;
  operateAsV2: OperateAsPayload;
  pauseConsumerJob: JobConsumer;
  /**  returns all current attachments  */
  postJobAttachment: PostJobAttachmentResult;
  postJobData: PostJobDataResult;
  publishOneOnOneJobAsNormalJob: Scalars['Boolean'];
  refreshBusinessSettings: RefreshBusinessSettingsPayload;
  registerTrialCompany: RegisterTrialCompanyResult;
  rejectOneToOneJob: RejectOneToOneJobPayload;
  rejectSimpleAgreementContract: RejectSimpleAgreementContractPayload;
  removeJobFromJobFolder: Scalars['Boolean'];
  replyToEvaluation: ReplyToEvaluationResult;
  requestLoginEmail: LoginWithEmailPayload;
  requestMaXLReview?: Maybe<Scalars['Void']>;
  requestPasswordChange?: Maybe<Scalars['Void']>;
  requestPasswordReset?: Maybe<Scalars['Void']>;
  requestSMSVerification?: Maybe<Scalars['Void']>;
  requestSecureIdAuthenticationUrl: SecureIdAuthenticationUrlPayload;
  resignFromCompanyForJob: JobConsumer;
  restoreChat: RestoreChatPayload;
  restoreJob: RestoreJobPayload;
  /**  Should be moved to Melvis along with setAttachmentDescription  */
  rotateImage: Image;
  saveConsumerPriceJob: SavePriceJobPayload;
  selectCompanyForJob: JobConsumer;
  sendEmailToCompany: Scalars['Boolean'];
  /**  user sends a message to other part in a chat  */
  sendMessage: SendMessageResult;
  sendSmsToCompany: Scalars['Boolean'];
  setAttachmentDescription: Array<Attachment>;
  setChatAsRead: SetChatAsReadPayload;
  setChatAsUnread: SetChatAsUnreadPayload;
  setConsentCategories: Array<ConsentCategory>;
  setJobAsRead: SetJobAsReadPayload;
  setJobData?: Maybe<Scalars['Void']>;
  setReceiveNewsletters: Scalars['Boolean'];
  signSimpleAgreementContract: SignSimpleAgreementContractPayload;
  skipConsumerEvaluationJob: JobConsumer;
  submitJob: SubmitJobResult;
  testBusinessSelfServicePurchaseProductCanceled?: Maybe<Scalars['Void']>;
  testBusinessSelfServicePurchaseProductChosen?: Maybe<Scalars['Void']>;
  toggleNotificationDestination: ToggleNotificationDestinationPayload;
  triggerEvent?: Maybe<Scalars['Void']>;
  /** @deprecated Use triggerEventSelfServicePayment with boost product */
  triggerEventBoostPayment?: Maybe<Scalars['Void']>;
  triggerEventSelfServicePayment?: Maybe<Scalars['Void']>;
  unfavouriteJob: UnfavouriteJobPayload;
  unlinkBoligmappaIntegration: BoligmappaIntegrationState;
  unpauseConsumerJob: JobConsumer;
  updateAnswerTemplate: UpdateAnswerTemplatePayload;
  updateBusiness: UpdateBusinessResult;
  updateBusinessAlbum: UpdateBusinessAlbumPayload;
  updateBusinessAlbumImage: UpdateBusinessAlbumImagePayload;
  updateBusinessDistricts: UpdateBusinessDistrictsPayload;
  updateBusinessEmployee: UpdateBusinessEmployeeResult;
  updateBusinessMunicipalities: UpdateBusinessMunicipalitiesPayload;
  updateBusinessProfileInfo: UpdateBusinessProfileInfoResult;
  updateJob: Job;
  updateJobWatchlist: JobWatchlist;
  uploadBusinessAlbumImages: UploadBusinessAlbumImagesPayload;
  uploadBusinessLogo: ImageUploadResult;
  /**
   * Use 'requestSMSVerification' mutation to get the code.
   * Returns 'true' if verification succeeded, false of code is invalid.
   */
  verifyOneOnOneJob: Scalars['Boolean'];
};


export type MutationGdprRequestDeleteUserArgs = {
  from: TicketEntryType;
};


export type MutationGdprRequestDownloadDataArgs = {
  from: TicketEntryType;
};


export type MutationAbortOneOnOneJobRegistrationNoMatchArgs = {
  input: AbortOneOnOneJobRegistrationNoMatchInput;
};


export type MutationAddBusinessEmployeeArgs = {
  input: AddBusinessEmployeeInput;
};


export type MutationAddExpoPushNotificationTokenArgs = {
  input: AddExpoPushNotificationTokenInput;
};


export type MutationAddJobToJobFolderArgs = {
  input: AddJobToJobFolderInput;
};


export type MutationAddLocationToJobArgs = {
  input: AddLocationToJob;
};


export type MutationAddPushNotificationTokenArgs = {
  input: AddPushNotificationTokenInput;
};


export type MutationAnswerJobArgs = {
  input: AnswerJobInput;
};


export type MutationAuthorizeBoligmappaIntegrationArgs = {
  input: AuthorizeBoligmappaIntegrationInput;
};


export type MutationBoligmappaAssociateAddressArgs = {
  input: BoligmappaAssociateAddressInput;
};


export type MutationBoligmappaCreateAccountArgs = {
  input: BoligmappaCreateAccountInput;
};


export type MutationBoligmappaDeleteFileArgs = {
  input: DeleteBoligmappaFileInput;
};


export type MutationBoligmappaRemoveAssociationArgs = {
  input: BoligmappaRemoveAssociationInput;
};


export type MutationBoligmappaRequestedDocumentationArgs = {
  input: RequestedDocInput;
};


export type MutationBusinessAskToBeEvaluatedArgs = {
  input: BusinessAskToBeEvaluatedInput;
};


export type MutationBusinessAskToBeSelectedArgs = {
  input: BusinessAskToBeSelectedInput;
};


export type MutationCancelConsumerJobArgs = {
  input: CancelConsumerJobInput;
};


export type MutationCancelRegistrationAttemptArgs = {
  input: CancelRegistrationAttemptInput;
};


export type MutationChooseBusinessWorkTypeSubsetsArgs = {
  input: ChooseBusinessWorkTypeSubsetsInput;
};


export type MutationChooseBusinessWorkTypesArgs = {
  input: ChooseBusinessWorkTypesInput;
};


export type MutationCompanyWantsContactAboutProductArgs = {
  input: CompanyWantsContactAboutProductInput;
};


export type MutationCompletePasswordResetArgs = {
  input: CompletePasswordResetInput;
};


export type MutationConfirmBoligmappaFileUploadArgs = {
  input: ConfirmBoligmappaFileUploadInput;
};


export type MutationConnectUserToJobArgs = {
  input: ConnectUserToJobInput;
};


export type MutationConsumerJobSurveyContractWrittenArgs = {
  input: ConsumerJobSurveyContractWrittenAnswerInput;
};


export type MutationConsumerJobSurveyDidChooseSuggestedCompanyArgs = {
  input: ConsumerJobSurveyDidChooseSuggestedCompanyAnswerInput;
};


export type MutationConsumerJobSurveyEvaluatedArgs = {
  input: ConsumerJobSurveyEvaluatedAnswerInput;
};


export type MutationConsumerJobSurveyFeedbackArgs = {
  input: ConsumerJobSurveyFeedbackAnswerInput;
};


export type MutationConsumerJobSurveyNoLongerRelevantOutsideCompanyArgs = {
  input: ConsumerJobSurveyNoLongerRelevantOutsideCompanyAnswerInput;
};


export type MutationConsumerJobSurveyNoLongerRelevantPostponedTimeArgs = {
  input: ConsumerJobSurveyNoLongerRelevantPostponedTimeAnswerInput;
};


export type MutationConsumerJobSurveyNoLongerRelevantReasonArgs = {
  input: ConsumerJobSurveyNoLongerRelevantReasonAnswerInput;
};


export type MutationConsumerJobSurveySelectCompanyArgs = {
  input: ConsumerJobSurveySelectCompanyAnswerInput;
};


export type MutationConsumerJobSurveySelectCompanyReasonArgs = {
  input: ConsumerJobSurveySelectCompanyReasonAnswerInput;
};


export type MutationConsumerJobSurveyStatusArgs = {
  input: ConsumerJobSurveyStatusAnswerInput;
};


export type MutationConsumerJobSurveyStillLookingReasonArgs = {
  input: ConsumerJobSurveyStillLookingReasonAnswerInput;
};


export type MutationConsumerUpdateProfileArgs = {
  input: ConsumerUpdateProfileInput;
};


export type MutationCreateAnswerTemplateArgs = {
  input: CreateAnswerTemplateInput;
};


export type MutationCreateBusinessAlbumArgs = {
  input: CreateBusinessAlbumInput;
};


export type MutationCreateJobArgs = {
  input: CreateJobInput;
};


export type MutationCreateJobFolderArgs = {
  input: CreateJobFolderInput;
};


export type MutationCreateJobWatchlistArgs = {
  input: CreateJobWatchlistInput;
};


export type MutationCreateSimpleAgreementContractArgs = {
  input: CreateSimpleAgreementContractInput;
};


export type MutationCreateTicketArgs = {
  content: Scalars['String'];
  entryType: TicketEntryType;
  metadata?: InputMaybe<Scalars['String']>;
  relations?: InputMaybe<Array<RelationsInput>>;
  title: Scalars['String'];
  type: TicketType;
};


export type MutationDeleteAnswerTemplateArgs = {
  input: DeleteAnswerTemplateInput;
};


export type MutationDeleteBusinessAlbumArgs = {
  input: DeleteBusinessAlbumInput;
};


export type MutationDeleteBusinessAlbumImageArgs = {
  input: DeleteBusinessAlbumImageInput;
};


export type MutationDeleteBusinessEmployeeArgs = {
  input: DeleteBusinessEmployeeInput;
};


export type MutationDeleteBusinessEmployeeImageArgs = {
  employeeId: Scalars['ID'];
};


export type MutationDeleteChatArgs = {
  input: DeleteChatInput;
};


export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};


export type MutationDeleteJobAttachmentArgs = {
  input: DeleteJobAttachmentInput;
};


export type MutationDeleteJobFolderArgs = {
  input: DeleteJobFolderInput;
};


export type MutationDeleteJobWatchlistArgs = {
  id: Scalars['ID'];
};


export type MutationEditJobFolderArgs = {
  input: EditJobFolderInput;
};


export type MutationEditSimpleAgreementContractArgs = {
  input: EditSimpleAgreementContractInput;
};


export type MutationEditSimpleAgreementContractConsumerArgs = {
  input: EditSimpleAgreementContractConsumerInput;
};


export type MutationEvaluateBusinessArgs = {
  input: EvaluateBusinessInput;
};


export type MutationFavouriteJobArgs = {
  input: FavouriteJobInput;
};


export type MutationForgotUsernameArgs = {
  input: ForgotUsernameInput;
};


export type MutationForgotUsernameV2Args = {
  input: ForgotUsernameInput;
};


export type MutationIncrementBusinessProfilePageViewsArgs = {
  businessId: Scalars['ID'];
};


export type MutationInitializeOverdueInvoiceWithdrawalArgs = {
  input: InitializeOverdueInvoiceWithdrawalInput;
};


export type MutationInitiateBoligmappaFileUploadArgs = {
  input: InitiateBoligmappaFileUploadInput;
};


export type MutationInitiateBoostCreditsPaymentArgs = {
  input: SelfServiceOrderInput;
};


export type MutationInitiateSelfServiceSubscriptionPaymentArgs = {
  input: InitiateSelfServiceSubscriptionPaymentInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginWithEmailArgs = {
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
};


export type MutationLoginWithOneTimePasswordArgs = {
  input: LoginWithVerificationCodeInput;
};


export type MutationLoginWithOneTimePasswordTmpArgs = {
  input: LoginWithOneTimePasswordInput;
};


export type MutationLoginWithPasswordArgs = {
  input: LoginInput;
};


export type MutationLoginWithTokenArgs = {
  input: LoginWithTokenInput;
};


export type MutationLoginWithTokenV2Args = {
  input: LoginWithTokenInput;
};


export type MutationLoginWithVerificationCodeArgs = {
  input: LoginWithVerificationCodeInput;
};


export type MutationMakeJobPublicArgs = {
  input: MakeJobPublicInput;
};


export type MutationMarkConsumerJobAsCompleteArgs = {
  input: MarkConsumerJobAsCompleteInput;
};


export type MutationMarkPushNotificationAsReadArgs = {
  receiverAppID: Scalars['ID'];
};


export type MutationOperateAsArgs = {
  input: OperateAsInput;
};


export type MutationOperateAsV2Args = {
  input: OperateAsInput;
};


export type MutationPauseConsumerJobArgs = {
  input: PauseJobInput;
};


export type MutationPostJobAttachmentArgs = {
  input: PostJobAttachmentInput;
};


export type MutationPostJobDataArgs = {
  input: PostJobDataInput;
};


export type MutationPublishOneOnOneJobAsNormalJobArgs = {
  input: PublishOneOnOneJobAsNormalJobInput;
};


export type MutationRegisterTrialCompanyArgs = {
  input: RegisterTrialCompanyInput;
};


export type MutationRejectOneToOneJobArgs = {
  input: RejectOneToOneJobInput;
};


export type MutationRejectSimpleAgreementContractArgs = {
  input: RejectSimpleAgreementContractInput;
};


export type MutationRemoveJobFromJobFolderArgs = {
  input: RemoveJobFromJobFolderInput;
};


export type MutationReplyToEvaluationArgs = {
  input: ReplyToEvaluationInput;
};


export type MutationRequestLoginEmailArgs = {
  input: RequestLoginEmailInput;
};


export type MutationRequestMaXlReviewArgs = {
  input: RequestMaXlReviewInput;
};


export type MutationRequestPasswordChangeArgs = {
  input: RequestPasswordResetInput;
};


export type MutationRequestPasswordResetArgs = {
  input: RequestPasswordResetInput;
};


export type MutationRequestSmsVerificationArgs = {
  input: RequestSmsVerificationInput;
};


export type MutationRequestSecureIdAuthenticationUrlArgs = {
  input: SecureIdAuthenticationUrlInput;
};


export type MutationResignFromCompanyForJobArgs = {
  input: CompanyResignationInput;
};


export type MutationRestoreChatArgs = {
  input: RestoreChatInput;
};


export type MutationRestoreJobArgs = {
  input: RestoreJobInput;
};


export type MutationRotateImageArgs = {
  input: RotateImageInput;
};


export type MutationSaveConsumerPriceJobArgs = {
  input: SavePriceJobInput;
};


export type MutationSelectCompanyForJobArgs = {
  input: CompanySelectionInput;
};


export type MutationSendEmailToCompanyArgs = {
  input: SendEmailToCompanyInput;
};


export type MutationSendMessageArgs = {
  input: SendMessageInput;
};


export type MutationSendSmsToCompanyArgs = {
  input: SendSmsToCompanyInput;
};


export type MutationSetAttachmentDescriptionArgs = {
  input: SetAttachmentDescriptionInput;
};


export type MutationSetChatAsReadArgs = {
  input: SetChatAsReadInput;
};


export type MutationSetChatAsUnreadArgs = {
  chatId: Scalars['ID'];
};


export type MutationSetConsentCategoriesArgs = {
  consents: Array<ConsentCategoryInput>;
};


export type MutationSetJobAsReadArgs = {
  input: SetJobAsReadInput;
};


export type MutationSetJobDataArgs = {
  input: SetJobDataInput;
};


export type MutationSetReceiveNewslettersArgs = {
  value: Scalars['Boolean'];
};


export type MutationSignSimpleAgreementContractArgs = {
  input: SignSimpleAgreementContractInput;
};


export type MutationSkipConsumerEvaluationJobArgs = {
  input: SkipEvaluationInput;
};


export type MutationSubmitJobArgs = {
  input: SubmitJobInput;
};


export type MutationTestBusinessSelfServicePurchaseProductCanceledArgs = {
  input?: InputMaybe<TestBusinessSelfServicePurchaseProductCanceledInput>;
};


export type MutationTestBusinessSelfServicePurchaseProductChosenArgs = {
  input: TestBusinessSelfServicePurchaseProductChosenInput;
};


export type MutationToggleNotificationDestinationArgs = {
  input: ToggleNotificationDestinationInput;
};


export type MutationTriggerEventArgs = {
  input: TriggerInput;
};


export type MutationTriggerEventBoostPaymentArgs = {
  input: TriggerEventBoostPaymentInput;
};


export type MutationTriggerEventSelfServicePaymentArgs = {
  input: TriggerEventSelfServicePaymentInput;
};


export type MutationUnfavouriteJobArgs = {
  input: UnfavouriteJobInput;
};


export type MutationUnpauseConsumerJobArgs = {
  input: UnpauseJobInput;
};


export type MutationUpdateAnswerTemplateArgs = {
  input: UpdateAnswerTemplateInput;
};


export type MutationUpdateBusinessArgs = {
  data: UpdateBusinessInput;
};


export type MutationUpdateBusinessAlbumArgs = {
  input: UpdateBusinessAlbumInput;
};


export type MutationUpdateBusinessAlbumImageArgs = {
  input: UpdateBusinessAlbumImageInput;
};


export type MutationUpdateBusinessDistrictsArgs = {
  input: UpdateBusinessDistrictsInput;
};


export type MutationUpdateBusinessEmployeeArgs = {
  input: UpdateBusinessEmployeeInput;
};


export type MutationUpdateBusinessMunicipalitiesArgs = {
  input: UpdateBusinessMunicipalitiesInput;
};


export type MutationUpdateBusinessProfileInfoArgs = {
  input: UpdateBusinessProfileInfoInput;
};


export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};


export type MutationUpdateJobWatchlistArgs = {
  input: UpdateJobWatchlistInput;
};


export type MutationUploadBusinessAlbumImagesArgs = {
  input: UploadBusinessAlbumImagesInput;
};


export type MutationUploadBusinessLogoArgs = {
  input: ImageUploadInput;
};


export type MutationVerifyOneOnOneJobArgs = {
  input: VerifyOneOnOneJobInput;
};

/**
 * An object with an ID.
 * Follows the [Relay Global Object Identification Specification](https://relay.dev/graphql/objectidentification.htm)
 */
export type Node = {
  id: Scalars['ID'];
};

export type NotificationDestination = Node & {
  __typename: 'NotificationDestination';
  defaultOn: Scalars['Boolean'];
  /**
   * Provided as minutes.
   *
   * If the notification is delayed according to occurence. For instance; nowdays emails
   * are sent 15 minutes after push when a new message – and it's cancelled if the message
   * is read before.
   */
  delay?: Maybe<Scalars['ISO8601Duration']>;
  id: Scalars['ID'];
  isToggleable: Scalars['Boolean'];
  type: NotificationDestinationType;
};

export enum NotificationDestinationType {
  Email = 'EMAIL',
  Push = 'PUSH',
  Sms = 'SMS',
  Web = 'WEB'
}

export type NotificationGroup = Node & {
  __typename: 'NotificationGroup';
  children: Array<NotificationGroup>;
  id: Scalars['ID'];
  /**  Should arrive translated from the server  */
  name: Scalars['String'];
  slug: Scalars['String'];
  types: Array<NotificationType>;
};

export type NotificationType = Node & {
  __typename: 'NotificationType';
  destinations: Array<NotificationDestination>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type OneOnOneJobRegistrationValidateWorktypesAndAreaInput = {
  businessId: Scalars['ID'];
  postCode: Scalars['String'];
  worktypes: Array<Scalars['ID']>;
};

/** Days without TimeInterval, will be considered as closed */
export type OpeningHoursInput = {
  friday?: InputMaybe<TimeInterval>;
  monday?: InputMaybe<TimeInterval>;
  saturday?: InputMaybe<TimeInterval>;
  sunday?: InputMaybe<TimeInterval>;
  thursday?: InputMaybe<TimeInterval>;
  tuesday?: InputMaybe<TimeInterval>;
  wednesday?: InputMaybe<TimeInterval>;
};

export type OpeningHoursInputError = Error & {
  __typename: 'OpeningHoursInputError';
  code?: Maybe<Scalars['Int']>;
  friday?: Maybe<TimeIntervalInputError>;
  message: Scalars['String'];
  monday?: Maybe<TimeIntervalInputError>;
  saturday?: Maybe<TimeIntervalInputError>;
  sunday?: Maybe<TimeIntervalInputError>;
  thursday?: Maybe<TimeIntervalInputError>;
  tuesday?: Maybe<TimeIntervalInputError>;
  wednesday?: Maybe<TimeIntervalInputError>;
};

export type OperateAsConsumerPayload = {
  __typename: 'OperateAsConsumerPayload';
  user?: Maybe<User>;
};

export type OperateAsConsumerResult = LoginRequiredError | OperateAsConsumerPayload;

export type OperateAsInput = {
  businessId: Scalars['ID'];
};

export type OperateAsInputError = {
  __typename: 'OperateAsInputError';
  businessId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type OperateAsPayload = {
  __typename: 'OperateAsPayload';
  user: User;
};

export type OperateAsResult = LoginRequiredError | OperateAsInputError | OperateAsPayload;

export type PageInfo = {
  __typename: 'PageInfo';
  /** aggregations */
  aggregations?: Maybe<Array<Maybe<Aggregations>>>;
  /**  cursor to the last element in list  */
  endCursor?: Maybe<Scalars['String']>;
  /**  whether there are items before the current cursor  */
  hasNextPage: Scalars['Boolean'];
  /**  whether there are items after the current cursor  */
  hasPreviousPage: Scalars['Boolean'];
  /**  maximum number of nodes to a single page  */
  pageSizeLimit?: Maybe<Scalars['Int']>;
  /**  cursor to the first element in list   */
  startCursor?: Maybe<Scalars['String']>;
  /**  total number of entries available  */
  totalCount?: Maybe<Scalars['Int']>;
  /**  total number of pages. might be removed due to inclusion of totalCount  */
  totalPages?: Maybe<Scalars['Int']>;
};

export type PageOutOfRangeError = Error & {
  __typename: 'PageOutOfRangeError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type PageSelect = {
  from?: InputMaybe<Scalars['Int']>;
  /**  fieldName:ASC or fieldName:DESC */
  order?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PageSelectInputError = Error & {
  __typename: 'PageSelectInputError';
  code?: Maybe<Scalars['Int']>;
  from?: Maybe<BasicInputError>;
  message: Scalars['String'];
  order?: Maybe<BasicInputError>;
  page?: Maybe<BasicInputError>;
  size?: Maybe<BasicInputError>;
};

export type PauseJobInput = {
  id: Scalars['ID'];
};

export enum PaymentType {
  Card = 'CARD',
  Free = 'FREE',
  Invoice = 'INVOICE'
}

export type PostJobAttachmentInput = {
  files: Array<Scalars['Upload']>;
  jobId: Scalars['ID'];
};

export type PostJobAttachmentInputError = {
  __typename: 'PostJobAttachmentInputError';
  code?: Maybe<Scalars['Int']>;
  files?: Maybe<BasicInputError>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type PostJobAttachmentJobNotFoundError = {
  __typename: 'PostJobAttachmentJobNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type PostJobAttachmentPayload = {
  __typename: 'PostJobAttachmentPayload';
  attachments: Array<Attachment>;
};

export type PostJobAttachmentResult = PostJobAttachmentInputError | PostJobAttachmentJobNotFoundError | PostJobAttachmentPayload | PostJobAttachmentSizeTooBigError;

export type PostJobAttachmentSizeTooBigError = {
  __typename: 'PostJobAttachmentSizeTooBigError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type PostJobDataInput = {
  address?: InputMaybe<AddressInput>;
  description?: InputMaybe<Scalars['String']>;
  jobId?: InputMaybe<Scalars['ID']>;
  meta?: InputMaybe<JobMetaInput>;
  owner?: InputMaybe<JobOwnerInput>;
  source?: InputMaybe<JobSource>;
  title?: InputMaybe<Scalars['String']>;
  workTypeIds?: InputMaybe<Array<Scalars['ID']>>;
  workTypeSubsetIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PostJobDataInputError = Error & {
  __typename: 'PostJobDataInputError';
  address?: Maybe<AddressInputError>;
  code?: Maybe<Scalars['Int']>;
  description?: Maybe<BasicInputError>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
  meta?: Maybe<PostJobMetaInputError>;
  owner?: Maybe<PostJobOwnerInputError>;
  title?: Maybe<BasicInputError>;
  workTypeIds?: Maybe<BasicInputError>;
  workTypeSubsetIds?: Maybe<BasicInputError>;
};

export type PostJobDataPayload = {
  __typename: 'PostJobDataPayload';
  job: Job;
  smsVerificationToken?: Maybe<Scalars['String']>;
};

export type PostJobDataResult = JobNotFoundError | PostJobDataInputError | PostJobDataPayload;

export type PostJobMetaInputError = Error & {
  __typename: 'PostJobMetaInputError';
  actualStartDate?: Maybe<BasicInputError>;
  carRegistrationNumber?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  contact?: Maybe<JobContactPreferenceInputError>;
  customerType?: Maybe<JobCustomerTypeInputError>;
  /** @deprecated Not in use */
  generic?: Maybe<GenericJobMetaInputError>;
  message: Scalars['String'];
  requestedStart?: Maybe<RequestedStartDateInputError>;
  toAddress?: Maybe<AddressInputError>;
};

export type PostJobOwnerInputError = Error & {
  __typename: 'PostJobOwnerInputError';
  code?: Maybe<Scalars['Int']>;
  email?: Maybe<BasicInputError>;
  firstName?: Maybe<BasicInputError>;
  lastName?: Maybe<BasicInputError>;
  message: Scalars['String'];
  phone?: Maybe<BasicInputError>;
};

export type PostalAddress = Node & {
  __typename: 'PostalAddress';
  city: Scalars['String'];
  /**  Same name available through District type. We haven't made these as an entitiy in the API as of now.  */
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lat?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['String']>;
  municipality: Municipality;
  postalCode?: Maybe<Scalars['PostalCode']>;
  region?: Maybe<Scalars['String']>;
};

export type PostalAddressInput = {
  postalCode: Scalars['String'];
};

export type PostalAddressNotFoundError = Error & {
  __typename: 'PostalAddressNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type PostalAddressResult = PostalAddress | PostalAddressNotFoundError;

export type Prediction = {
  __typename: 'Prediction';
  confidence: Scalars['Float'];
  worktype: WorkType;
};

export type PriceRange = {
  __typename: 'PriceRange';
  high: Scalars['Float'];
  low: Scalars['Float'];
};

export type PrimaryBusinessContactPayload = {
  __typename: 'PrimaryBusinessContactPayload';
  id: Scalars['ID'];
  isCurrentUserPrimaryContact: Scalars['Boolean'];
  primaryContactEmail: Scalars['String'];
};

export enum ProductTemplateSlug {
  Advertisement = 'ADVERTISEMENT',
  Boost = 'BOOST',
  Evaluation = 'EVALUATION',
  Homepage = 'HOMEPAGE',
  MittanbudXl = 'MITTANBUD_XL',
  Pro = 'PRO',
  ProfilePage = 'PROFILE_PAGE',
  Search = 'SEARCH',
  Subscription = 'SUBSCRIPTION'
}

export enum ProductUnitType {
  Day = 'DAY',
  Month = 'MONTH',
  Singleunit = 'SINGLEUNIT',
  Split = 'SPLIT',
  Week = 'WEEK'
}

export enum ProfilePageType {
  Plus = 'PLUS',
  Standard = 'STANDARD'
}

export type PromotedBusinessSearchCategoryInput = {
  /** Industry slug to filter the search by */
  industry?: InputMaybe<Scalars['String']>;
  /** Industry id to filter the search by */
  industryId?: InputMaybe<Scalars['Int']>;
  /** Worktype slug to filter the search by */
  worktype?: InputMaybe<Scalars['String']>;
  /** Worktype id to filter the search by */
  worktypeId?: InputMaybe<Scalars['Int']>;
};

export type PromotedBusinessSearchInput = {
  /**
   * The maximum number of the 'size' results that should be promoted results
   * Must be less than or equal to 'size'
   */
  boostedSize: Scalars['Int'];
  /**
   * Filter by category (worktype and/or industry)
   * At least one field needs to be set
   */
  category?: InputMaybe<PromotedBusinessSearchCategoryInput>;
  /** At least one field needs to be set */
  location?: InputMaybe<PromotedBusinessSearchLocationInput>;
  /**
   * Filter based on the review of the businesses
   * At least one field needs to be set
   */
  reviews?: InputMaybe<PromotedBusinessSearchReviewInput>;
  /** The maximum number of results to return */
  size: Scalars['Int'];
};

export type PromotedBusinessSearchLocationInput = {
  /** Name of the city to search in */
  city?: InputMaybe<Scalars['String']>;
  /** Name of the county to search in */
  county?: InputMaybe<Scalars['String']>;
  /** Name of the municipality to search in */
  municipality?: InputMaybe<Scalars['String']>;
};

export type PromotedBusinessSearchResponse = {
  __typename: 'PromotedBusinessSearchResponse';
  businesses: Array<Business>;
  promoted: Array<Scalars['ID']>;
};

export type PromotedBusinessSearchReviewInput = {
  /** Minimum number of reviews the business should have */
  minCount?: InputMaybe<Scalars['Int']>;
  /** Minimum average score for the business */
  minScore?: InputMaybe<Scalars['Int']>;
};

export type PromotedBusinessSearchV2CategoryInput = {
  /** Industry slug to filter the search by */
  industry?: InputMaybe<Scalars['String']>;
  /** Industry id to filter the search by */
  industryId?: InputMaybe<Scalars['Int']>;
  /** Worktype slug to filter the search by */
  worktype?: InputMaybe<Scalars['String']>;
  /** Worktype id to filter the search by */
  worktypeId?: InputMaybe<Scalars['Int']>;
};

export type PromotedBusinessSearchV2Input = {
  /**
   * The maximum number of the 'size' results that should be promoted results
   * Must be less than or equal to 'size'
   */
  boostedSize: Scalars['Int'];
  /**
   * Filter by category (worktype and/or industry)
   * At least one field needs to be set
   */
  category?: InputMaybe<PromotedBusinessSearchV2CategoryInput>;
  /** At least one field needs to be set */
  location?: InputMaybe<PromotedBusinessSearchV2LocationInput>;
  /**
   * Filter based on the review of the businesses
   * At least one field needs to be set
   */
  reviews?: InputMaybe<PromotedBusinessSearchV2ReviewInput>;
  /** The maximum number of results to return */
  size: Scalars['Int'];
};

export type PromotedBusinessSearchV2LocationInput = {
  /** Name of the city to search in */
  city?: InputMaybe<Scalars['String']>;
  /** Name of the county to search in */
  county?: InputMaybe<Scalars['String']>;
  /** Name of the municipality to search in */
  municipality?: InputMaybe<Scalars['String']>;
};

export type PromotedBusinessSearchV2ReviewInput = {
  /** Minimum number of reviews the business should have */
  minCount?: InputMaybe<Scalars['Int']>;
  /** Minimum average score for the business */
  minScore?: InputMaybe<Scalars['Int']>;
};

export type PublishOneOnOneJobAsNormalJobInput = {
  jobId: Scalars['ID'];
};

export enum PushNotificationAppName {
  ByggetilbudMatchBusiness = 'ByggetilbudMatchBusiness',
  ByggetilbudMatchConsumer = 'ByggetilbudMatchConsumer',
  MittanbudBusiness = 'MittanbudBusiness',
  MittanbudConsumer = 'MittanbudConsumer',
  RemppatoriBusiness = 'RemppatoriBusiness',
  RemppatoriConsumer = 'RemppatoriConsumer',
  ServicefinderBusiness = 'ServicefinderBusiness',
  ServicefinderConsumer = 'ServicefinderConsumer'
}

export enum PushNotificationDeviceType {
  Android = 'ANDROID',
  Ios = 'IOS'
}

export enum PushNotificationTokenType {
  Expo = 'EXPO',
  Fcm = 'FCM'
}

export type Query = {
  __typename: 'Query';
  aiCalculator: AiCalculatorResult;
  answerTemplates: Array<Maybe<AnswerTemplate>>;
  badgeNumbers: BadgeNumbers;
  boligmappaAddressDetails: BoligmappaAddressDetails;
  boligmappaDocumentAndProfessionTypes: BoligmappaDocumentAndProfessionTypesResponse;
  boligmappaDownloadFile: BoligmappaDownloadFileResponse;
  boligmappaIntegrationState: BoligmappaIntegrationState;
  boligmappaJobAssociations?: Maybe<BoligmappaAssociationResponse>;
  boligmappaLoginUrl: Scalars['String'];
  boostCreditsProductDetails: SelfServiceProduct;
  boostCreditsProductsPair: Array<SelfServiceProduct>;
  business: BusinessResult;
  businessAlbums: Array<Album>;
  businessEmployees: Array<BusinessEmployee>;
  businessEvaluationScore: BusinessEvaluationScore;
  /**  List of all evaluations. Defined for displaying evaluations on public front mostly in mind.  */
  businessEvaluations: EvaluationConnection;
  businessIndustries: Array<Industry>;
  businessIndustriesAndWorkTypesActive: BusinessIndustriesAndWorkTypesActivePayload;
  businessJobAreas: BusinessJobAreas;
  businessJobStatistics: BusinessJobStatistics;
  businessLatestWonJobs: Array<WonJobs>;
  businessNotificationsAvailable?: Maybe<NotificationGroup>;
  businessProducts?: Maybe<CompanyProducts>;
  businessSearch: BusinessSearchPayload;
  businessSearchV2: BusinessSearchV2Response;
  businessSettings: CompanySettings;
  businessSocialMedia?: Maybe<BusinessSocialMedia>;
  businessStates: BusinessStates;
  businessWorkTypes: BusinessWorkTypes;
  car?: Maybe<Car>;
  cardList: Array<Card>;
  chat: ChatResult;
  chatByJobId: Chat;
  chatByJobIdOptional?: Maybe<Chat>;
  chatList: ChatListResult;
  chatMessages: MessageConnection;
  consumerChatList: ChatConsumerListPayload;
  consumerJobList: JobConsumerListPayload;
  /** @deprecated Use simpleAgreementContract */
  contract?: Maybe<Contract>;
  /** @deprecated Use simpleAgreementContractList */
  contractList: ContractListPayload;
  counties: Array<County>;
  evaluation: EvaluationResult;
  evaluationList: EvaluationListResult;
  getConsentCategories: Array<ConsentCategory>;
  getUserSettings: UserSettings;
  getWorkTypesByJobId: Array<WorkType>;
  industries?: Maybe<Array<Industry>>;
  invoiceReceiptList?: Maybe<InvoiceReceiptConnection>;
  invoicesUnpaid: Array<Invoice>;
  job: JobResult;
  jobAccess: JobAccessResult;
  /**  only use this if there is no reason to fetch through job type  */
  jobAttachments: Array<Attachment>;
  jobBusinessRelationState: JobBusinessRelationState;
  jobChatMeta?: Maybe<ChatMeta>;
  jobEvaluation?: Maybe<Evaluation>;
  jobFiles: Array<File>;
  jobFolders: JobFolderPayload;
  jobIdTranslated: JobIdTranslatedResult;
  jobImages: Array<Image>;
  /** @deprecated use jobBusinessRelationState query */
  jobIsAnswered?: Maybe<Scalars['Boolean']>;
  /** @deprecated use jobBusinessRelationState query */
  jobIsDeleted?: Maybe<Scalars['Boolean']>;
  /** @deprecated use jobBusinessRelationState query */
  jobIsFavourite?: Maybe<Scalars['Boolean']>;
  jobIsProjectPlusEligible?: Maybe<Scalars['Boolean']>;
  /** @deprecated use jobBusinessRelationState query */
  jobIsRead?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use job.traits and jobBusinessRelationState */
  jobLabels: JobLabels;
  /**  Provided as JobListPayload in order to support available facets and such  */
  jobList: JobListResult;
  jobListFilters: Array<JobListFilter>;
  jobListPublic: JobListPublicPayload;
  jobMeta: JobMeta;
  jobRegistrationSuggestionsArea: JobSuggestionsPayload;
  jobTraits: JobTraits;
  jobWatchlistList: Array<JobWatchlist>;
  lastUsedCompaniesSelectedForJobs?: Maybe<Array<Business>>;
  loggedInStatus: LoggedInStatus;
  matchedJobData?: Maybe<Job>;
  me?: Maybe<User>;
  /**  Current notification destinations enabled by user - seen in context of current login state. Less hassle if a user switches between several companies.  */
  notificationDestinationsEnabled: Array<NotificationDestination>;
  oneOnOneJobRegistrationValidateWorktypesAndArea: Scalars['Boolean'];
  postalAddress: PostalAddressResult;
  postalAddressV2: PostalAddress;
  primaryBusinessContact: PrimaryBusinessContactPayload;
  promotedBusinessSearch: PromotedBusinessSearchResponse;
  promotedBusinessSearchV2: Array<ElasticBusiness>;
  registerTrialCompanyVerifyEmail: RegisterTrialCompanyVerifyEmailResult;
  searchBoligmappaAddress: Array<BoligmappaBuildingAddress>;
  selfServiceSubscriptions: Array<SelfServiceSubscriptionOption>;
  simpleAgreementContract?: Maybe<SimpleAgreementContract>;
  simpleAgreementContractByJobId?: Maybe<SimpleAgreementContract>;
  simpleAgreementContractList: SimpleAgreementContractListPayload;
  stianSearch4k: StianSearch4kResult;
  verifyPasswordResetToken: Scalars['Boolean'];
  workTypes?: Maybe<Array<WorkType>>;
};


export type QueryAiCalculatorArgs = {
  input: AiCalculatorInput;
};


export type QueryBoligmappaAddressDetailsArgs = {
  input: BoligmappaAddressDetailsInput;
};


export type QueryBoligmappaDownloadFileArgs = {
  input: BoligmappaDownloadFileInput;
};


export type QueryBoligmappaJobAssociationsArgs = {
  jobId: Scalars['ID'];
};


export type QueryBoligmappaLoginUrlArgs = {
  input: BoligmappaLoginInput;
};


export type QueryBusinessArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessAlbumsArgs = {
  input: BusinessAlbumsInput;
};


export type QueryBusinessEmployeesArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessEvaluationScoreArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessEvaluationsArgs = {
  input: BusinessEvaluationsInput;
};


export type QueryBusinessIndustriesArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessIndustriesAndWorkTypesActiveArgs = {
  input: BusinessIndustriesAndWorkTypesActiveInput;
};


export type QueryBusinessJobAreasArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessJobStatisticsArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessLatestWonJobsArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessProductsArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessSearchArgs = {
  input: BusinessSearchInput;
};


export type QueryBusinessSearchV2Args = {
  input: BusinessSearchV2Input;
};


export type QueryBusinessSettingsArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessSocialMediaArgs = {
  businessId: Scalars['ID'];
};


export type QueryBusinessStatesArgs = {
  id: Scalars['ID'];
};


export type QueryBusinessWorkTypesArgs = {
  businessId: Scalars['ID'];
};


export type QueryCarArgs = {
  regNr: Scalars['ID'];
};


export type QueryChatArgs = {
  filter: ChatFilter;
};


export type QueryChatByJobIdArgs = {
  jobId: Scalars['ID'];
};


export type QueryChatByJobIdOptionalArgs = {
  jobId: Scalars['ID'];
};


export type QueryChatListArgs = {
  filter?: InputMaybe<ChatListFilter>;
};


export type QueryChatMessagesArgs = {
  input: ChatMessagesInput;
};


export type QueryConsumerChatListArgs = {
  input: ChatConsumerListInput;
};


export type QueryConsumerJobListArgs = {
  input: JobConsumerListInput;
};


export type QueryContractArgs = {
  input: ContractInput;
};


export type QueryContractListArgs = {
  input: ContractListInput;
};


export type QueryEvaluationArgs = {
  input: EvaluationInput;
};


export type QueryEvaluationListArgs = {
  input: EvaluationListInput;
};


export type QueryGetWorkTypesByJobIdArgs = {
  jobId: Scalars['ID'];
};


export type QueryIndustriesArgs = {
  formSlugs?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryInvoiceReceiptListArgs = {
  input?: InputMaybe<ReceiptListInput>;
};


export type QueryInvoicesUnpaidArgs = {
  businessId: Scalars['ID'];
};


export type QueryJobArgs = {
  id: Scalars['ID'];
};


export type QueryJobAccessArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobAttachmentsArgs = {
  input: GetJobAttachmentsInput;
};


export type QueryJobBusinessRelationStateArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobChatMetaArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobEvaluationArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobFilesArgs = {
  input: GetJobFilesInput;
};


export type QueryJobIdTranslatedArgs = {
  id: Scalars['ID'];
};


export type QueryJobImagesArgs = {
  input: GetJobImagesInput;
};


export type QueryJobIsAnsweredArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobIsDeletedArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobIsFavouriteArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobIsProjectPlusEligibleArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobIsReadArgs = {
  input: GetJobIsReadInput;
};


export type QueryJobLabelsArgs = {
  input: GetJobLabelsInput;
};


export type QueryJobListArgs = {
  input: JobListInput;
};


export type QueryJobListPublicArgs = {
  input: JobListPublicInput;
};


export type QueryJobMetaArgs = {
  jobId: Scalars['ID'];
};


export type QueryJobRegistrationSuggestionsAreaArgs = {
  input: JobSuggestionsInput;
};


export type QueryJobTraitsArgs = {
  jobId: Scalars['ID'];
};


export type QueryMatchedJobDataArgs = {
  id: Scalars['ID'];
};


export type QueryOneOnOneJobRegistrationValidateWorktypesAndAreaArgs = {
  input: OneOnOneJobRegistrationValidateWorktypesAndAreaInput;
};


export type QueryPostalAddressArgs = {
  input: PostalAddressInput;
};


export type QueryPostalAddressV2Args = {
  input: PostalAddressInput;
};


export type QueryPromotedBusinessSearchArgs = {
  input: PromotedBusinessSearchInput;
};


export type QueryPromotedBusinessSearchV2Args = {
  input: PromotedBusinessSearchV2Input;
};


export type QueryRegisterTrialCompanyVerifyEmailArgs = {
  input: RegisterTrialCompanyVerifyEmailInput;
};


export type QuerySearchBoligmappaAddressArgs = {
  input: SearchBoligmappaAddressInput;
};


export type QuerySimpleAgreementContractArgs = {
  input: SimpleAgreementContractInput;
};


export type QuerySimpleAgreementContractByJobIdArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  jobId: Scalars['ID'];
};


export type QuerySimpleAgreementContractListArgs = {
  input: SimpleAgreementContractListInput;
};


export type QueryStianSearch4kArgs = {
  input: StianSearch4kInput;
};


export type QueryVerifyPasswordResetTokenArgs = {
  token: Scalars['String'];
};


export type QueryWorkTypesArgs = {
  formSlugs?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type ReceiptListInput = {
  /**  Max number of items to return  */
  count?: InputMaybe<Scalars['Int']>;
  /**  Start position. Result number (not cursor), starts at zero.  */
  from?: InputMaybe<Scalars['Int']>;
};

export type RefreshBusinessSettingsPayload = {
  __typename: 'RefreshBusinessSettingsPayload';
  success: Scalars['Boolean'];
};

/**  After first time of running query, include ticketId  */
export type RegisterTrialCompanyInput = {
  companyAddress?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  countyIds?: InputMaybe<Array<Scalars['ID']>>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  employees?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  industryIds?: InputMaybe<Array<Scalars['ID']>>;
  lastName?: InputMaybe<Scalars['String']>;
  /** Object containing countryCode and identifier. Example {countryCode: 'no', identifier: '123456789'} */
  orgIdentifier?: InputMaybe<Scalars['OrgIdentifier']>;
  orgNr?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['PostalCode']>;
  ticketId?: InputMaybe<Scalars['ID']>;
  workTypeIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type RegisterTrialCompanyInputError = Error & {
  __typename: 'RegisterTrialCompanyInputError';
  code?: Maybe<Scalars['Int']>;
  companyAddress?: Maybe<BasicInputError>;
  companyName?: Maybe<BasicInputError>;
  countyIds?: Maybe<BasicInputError>;
  email?: Maybe<BasicInputError>;
  employees?: Maybe<BasicInputError>;
  firstName?: Maybe<BasicInputError>;
  industryIds?: Maybe<BasicInputError>;
  lastName?: Maybe<BasicInputError>;
  message: Scalars['String'];
  orgNr?: Maybe<BasicInputError>;
  phoneNumber?: Maybe<BasicInputError>;
  postCode?: Maybe<BasicInputError>;
  status?: Maybe<BasicInputError>;
  ticketId?: Maybe<BasicInputError>;
  workTypeIds?: Maybe<BasicInputError>;
};

export type RegisterTrialCompanyPayload = {
  __typename: 'RegisterTrialCompanyPayload';
  company?: Maybe<Business>;
  /** @deprecated Users are supposed to use welcome email to login */
  loginToken?: Maybe<Scalars['String']>;
  ticket: Ticket;
  user?: Maybe<User>;
};

export type RegisterTrialCompanyResult = CompanyAlreadyExistsError | FailedToSendWelcomeEmailError | RegisterTrialCompanyInputError | RegisterTrialCompanyPayload | TicketNotFoundError;

export type RegisterTrialCompanyVerifyEmailInput = {
  email: Scalars['String'];
};

export type RegisterTrialCompanyVerifyEmailResult = {
  __typename: 'RegisterTrialCompanyVerifyEmailResult';
  companyRegistered: Scalars['Boolean'];
  emailValid: Scalars['Boolean'];
};

export type RejectOneToOneJobInput = {
  jobId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
};

export type RejectOneToOneJobPayload = {
  __typename: 'RejectOneToOneJobPayload';
  success: Scalars['Boolean'];
};

export type RejectSimpleAgreementContractInput = {
  agreementId: Scalars['ID'];
};

export type RejectSimpleAgreementContractPayload = {
  __typename: 'RejectSimpleAgreementContractPayload';
  contract?: Maybe<SimpleAgreementContract>;
};

export type RelationsInput = {
  relConst: TicketRelConst;
  relId: Scalars['ID'];
  relType: TicketRelType;
};

export type RemoveJobFromJobFolderInput = {
  folderId: Scalars['ID'];
  jobId: Scalars['ID'];
};

export type ReplyToEvaluationInput = {
  description: Scalars['String'];
  evaluationId: Scalars['ID'];
};

export type ReplyToEvaluationInputError = Error & {
  __typename: 'ReplyToEvaluationInputError';
  code?: Maybe<Scalars['Int']>;
  description?: Maybe<BasicInputError>;
  evaluationId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type ReplyToEvaluationPayload = {
  __typename: 'ReplyToEvaluationPayload';
  evaluationReply: EvaluationReply;
};

export type ReplyToEvaluationResult = AccessDeniedError | EvaluationNotFoundError | ReplyToEvaluationInputError | ReplyToEvaluationPayload;

export type RequestLoginEmailInput = {
  domain?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
};

export type RequestMaXlReviewInput = {
  jobId: Scalars['ID'];
};

export type RequestPasswordResetInput = {
  email: Scalars['String'];
  redirectDomain?: InputMaybe<Scalars['String']>;
};

export type RequestSmsVerificationInput = {
  authorizationToken: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type RequestedDocInput = {
  /**  tender Id */
  id: Scalars['Int'];
};

export type RequestedStartDateInputError = Error & {
  __typename: 'RequestedStartDateInputError';
  actualStartDate?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export enum ResetPasswordAction {
  Change = 'CHANGE',
  Forgot = 'FORGOT'
}

export type RestoreChatInput = {
  id: Scalars['ID'];
};

export type RestoreChatPayload = {
  __typename: 'RestoreChatPayload';
  chat?: Maybe<Chat>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RestoreJobInput = {
  jobId: Scalars['ID'];
};

export type RestoreJobPayload = {
  __typename: 'RestoreJobPayload';
  success: Scalars['Boolean'];
};

export type RotateBusinessAlbumImageInput = {
  degrees?: InputMaybe<RotateImageDegrees>;
  direction?: InputMaybe<RotateImageDirection>;
};

export enum RotateImageDegrees {
  HundredEighty = 'HUNDRED_EIGHTY',
  Ninety = 'NINETY',
  TwoHundredSeventy = 'TWO_HUNDRED_SEVENTY'
}

export enum RotateImageDirection {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type RotateImageInput = {
  degrees?: InputMaybe<RotateImageDegrees>;
  direction?: InputMaybe<RotateImageDirection>;
  imageId: Scalars['ID'];
};

export type SavePriceJobInput = {
  id: Scalars['ID'];
  materialsIncluded: Scalars['Boolean'];
  price: Scalars['Float'];
};

export type SavePriceJobPayload = {
  __typename: 'SavePriceJobPayload';
  success: Scalars['Boolean'];
};

export type SearchBoligmappaAddressInput = {
  address: Scalars['String'];
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type SecureIdAuthenticationUrlCallbackUrlsInput = {
  abort: Scalars['String'];
  error: Scalars['String'];
  success: Scalars['String'];
};

export type SecureIdAuthenticationUrlInput = {
  callbackUrls: SecureIdAuthenticationUrlCallbackUrlsInput;
};

export type SecureIdAuthenticationUrlPayload = {
  __typename: 'SecureIdAuthenticationUrlPayload';
  authenticationUrl: Scalars['String'];
  id: Scalars['ID'];
  uuid: Scalars['ID'];
};

export enum SelfPurchaseTriggerSlug {
  SelfPurchaseCancel = 'SELF_PURCHASE_CANCEL',
  SelfPurchaseOpen = 'SELF_PURCHASE_OPEN',
  SelfPurchasePurchase = 'SELF_PURCHASE_PURCHASE',
  SelfPurchasePurchaseConfirm = 'SELF_PURCHASE_PURCHASE_CONFIRM',
  SelfPurchasePurchaseFail = 'SELF_PURCHASE_PURCHASE_FAIL',
  SelfPurchasePurchaseSuccess = 'SELF_PURCHASE_PURCHASE_SUCCESS'
}

export type SelfServiceOrderInput = {
  cardId?: InputMaybe<Scalars['String']>;
  /** @deprecated On boost credits gql is responsible for picking product */
  productName?: InputMaybe<SelfServiceProductName>;
  returnUrl: Scalars['String'];
};

export type SelfServiceOrderResult = {
  __typename: 'SelfServiceOrderResult';
  id: Scalars['ID'];
  resultCode?: Maybe<LatestPaymentAttemptResultCode>;
  returnUrl?: Maybe<Scalars['String']>;
};

export type SelfServiceProduct = {
  __typename: 'SelfServiceProduct';
  clips: Scalars['Int'];
  /** Only defined if discount was applied */
  discount?: Maybe<SelfServiceProductDiscount>;
  id: Scalars['ID'];
  price: Scalars['Int'];
  slug: SelfServiceProductName;
};

export type SelfServiceProductDiscount = {
  __typename: 'SelfServiceProductDiscount';
  discountPercent: Scalars['Int'];
  priceAfterDiscount: Scalars['Int'];
};

export enum SelfServiceProductName {
  BoostCredits_5 = 'BOOST_CREDITS_5',
  BoostCredits_10 = 'BOOST_CREDITS_10',
  BoostCredits_15 = 'BOOST_CREDITS_15',
  SubscriptionLarge = 'SUBSCRIPTION_LARGE',
  SubscriptionMedium = 'SUBSCRIPTION_MEDIUM',
  SubscriptionSmall = 'SUBSCRIPTION_SMALL'
}

export enum SelfServiceSubscriptionName {
  SubscriptionBig = 'SUBSCRIPTION_BIG',
  SubscriptionMedium = 'SUBSCRIPTION_MEDIUM',
  SubscriptionSmall = 'SUBSCRIPTION_SMALL'
}

export type SelfServiceSubscriptionOption = {
  __typename: 'SelfServiceSubscriptionOption';
  clips: Scalars['Int'];
  id: Scalars['ID'];
  price: Scalars['Int'];
  slug: SelfServiceSubscriptionName;
};

export type SendEmailToCompanyInput = {
  companyId: Scalars['ID'];
  consumerName: Scalars['String'];
  fromEmail: Scalars['EmailAddress'];
  message: Scalars['String'];
};

export type SendMessageAccessDeniedError = Error & {
  __typename: 'SendMessageAccessDeniedError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type SendMessageInput = {
  chatId: Scalars['ID'];
  files?: InputMaybe<Array<Scalars['Upload']>>;
  text?: InputMaybe<Scalars['String']>;
};

export type SendMessageInputError = Error & {
  __typename: 'SendMessageInputError';
  chatId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  files?: Maybe<BasicInputError>;
  message: Scalars['String'];
  text?: Maybe<BasicInputError>;
};

export type SendMessagePayload = {
  __typename: 'SendMessagePayload';
  /**  Due to lack of support for attachments with text, the payload is a array of messages  */
  messages?: Maybe<Array<Message>>;
};

export type SendMessageResult = AccessDeniedError | SendMessageInputError | SendMessagePayload;

export type SendSmsToCompanyInput = {
  companyId: Scalars['ID'];
  consumerName: Scalars['String'];
  consumerPhone: Scalars['String'];
};

export type SetAttachmentDescriptionInput = {
  attachmentId: Scalars['ID'];
  description: Scalars['String'];
  jobId: Scalars['ID'];
};

export type SetChatAsReadInput = {
  id: Scalars['ID'];
};

export type SetChatAsReadPayload = {
  __typename: 'SetChatAsReadPayload';
  success: Scalars['Boolean'];
};

export type SetChatAsUnreadPayload = {
  __typename: 'SetChatAsUnreadPayload';
  id: Scalars['ID'];
  isUnread: Scalars['Boolean'];
};

export type SetJobAsReadInput = {
  /**  Cost of job, used for analytics  */
  cost?: InputMaybe<Scalars['Int']>;
  jobId: Scalars['ID'];
};

export type SetJobAsReadPayload = {
  __typename: 'SetJobAsReadPayload';
  success: Scalars['Boolean'];
};

export type SetJobDataInput = {
  data: JobDataInput;
  id: Scalars['ID'];
};

export type SignSimpleAgreementContractInput = {
  agreementId: Scalars['ID'];
};

export type SignSimpleAgreementContractPayload = {
  __typename: 'SignSimpleAgreementContractPayload';
  contract?: Maybe<SimpleAgreementContract>;
};

export type SimpleAgreementContract = Contract & {
  __typename: 'SimpleAgreementContract';
  businessEmail?: Maybe<Scalars['String']>;
  businessPhone?: Maybe<Scalars['String']>;
  chat?: Maybe<Chat>;
  company: Business;
  consumerEmail?: Maybe<Scalars['String']>;
  consumerPhone?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  job: Job;
  newAgreement?: Maybe<SimpleAgreementContract>;
  oldAgreement?: Maybe<SimpleAgreementContract>;
  price?: Maybe<Scalars['Float']>;
  /**  Simple agreement only contains one price. This can also be hourly work rates, which doesn't equal to what we regard as contract amount.  */
  priceType: ContractPriceType;
  regTime: Scalars['Int'];
  signedCompanyTime?: Maybe<Scalars['Int']>;
  signedUserTime?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Int']>;
  statusEnum: SimpleAgreementContractStatus;
  statusFlags: SimpleAgreementContractStatusFlags;
  type: ContractType;
  user: User;
};

export type SimpleAgreementContractInput = {
  id: Scalars['ID'];
};

export type SimpleAgreementContractListInput = {
  count?: InputMaybe<Scalars['Int']>;
  from?: InputMaybe<Scalars['Int']>;
  list: ContractList;
  page?: InputMaybe<Scalars['Int']>;
};

export type SimpleAgreementContractListPayload = {
  __typename: 'SimpleAgreementContractListPayload';
  list: Array<SimpleAgreementContract>;
  pageInfo?: Maybe<ContractListPageInfo>;
};

export enum SimpleAgreementContractStatus {
  /**  All these are shared from the start, but this is here for future proofing  */
  Created = 'CREATED',
  /**  Status is not covered by this enum. For future proofing new status flags.  */
  SeeFlags = 'SEE_FLAGS',
  Shared = 'SHARED',
  SignedBoth = 'SIGNED_BOTH',
  SignedBusiness = 'SIGNED_BUSINESS',
  SignedConsumer = 'SIGNED_CONSUMER',
  /**  Rejected, invalidated or similar. Check status flags.  */
  Stopped = 'STOPPED'
}

export type SimpleAgreementContractStatusFlags = {
  __typename: 'SimpleAgreementContractStatusFlags';
  acceptedBusiness: Scalars['Boolean'];
  acceptedConsumer: Scalars['Boolean'];
  initialized: Scalars['Boolean'];
  invalidated: Scalars['Boolean'];
  rejectedBusiness: Scalars['Boolean'];
  rejectedConsumer: Scalars['Boolean'];
};

export enum SimpleAgreementPriceType {
  Fixed = 'FIXED',
  HourComputation = 'HOUR_COMPUTATION',
  NoComputation = 'NO_COMPUTATION',
  Other = 'OTHER'
}

export type SimpleProduct = CompanyProduct & {
  __typename: 'SimpleProduct';
  isOwned: Scalars['Boolean'];
};

export type SkipEvaluationInput = {
  id: Scalars['ID'];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum StartAlternative {
  AfterDate = 'AFTER_DATE',
  BeforeDate = 'BEFORE_DATE',
  Now = 'NOW',
  OnDate = 'ON_DATE',
  Whenever = 'WHENEVER'
}

export type StianSearch4kInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type StianSearch4kResult = {
  __typename: 'StianSearch4kResult';
  predictions: Array<Prediction>;
};

export type SubmitJobInput = {
  jobId: Scalars['ID'];
};

export type SubmitJobInputError = Error & {
  __typename: 'SubmitJobInputError';
  code?: Maybe<Scalars['Int']>;
  jobId?: Maybe<BasicInputError>;
  message: Scalars['String'];
};

export type SubmitJobPayload = {
  __typename: 'SubmitJobPayload';
  job: Job;
};

export type SubmitJobResult = SubmitJobInputError | SubmitJobPayload;

export type SubscriptionProduct = CompanyProduct & {
  __typename: 'SubscriptionProduct';
  clips?: Maybe<Scalars['Int']>;
  isOwned: Scalars['Boolean'];
};

/**
 * As to decide when a contract is awaiting signature; have a look at initiator and relate it to signstatus.
 * The initiator signs the contract last.
 *
 * Optimal flow...
 * 1. User sets up contract
 * 2. Shares with company
 * 3. Company follows share link making the contract connected
 * 4. Company signs the contract
 */
export type SwiftcourtContract = Contract & {
  __typename: 'SwiftcourtContract';
  amount?: Maybe<Scalars['Float']>;
  chat?: Maybe<Chat>;
  company: Business;
  connected?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  job: Job;
  /**  The person who proposes the terms of an agreement makes an offer  */
  offeror?: Maybe<BusinessOrUser>;
  priceType?: Maybe<ContractPriceType>;
  regTime: Scalars['Int'];
  shared?: Maybe<Scalars['Boolean']>;
  status?: Maybe<SwiftcourtContractSignStatus>;
  swiftUrl?: Maybe<Scalars['String']>;
  type: ContractType;
  user: User;
};

export enum SwiftcourtContractSignStatus {
  Created = 'CREATED',
  Shared = 'SHARED',
  /**  The offeror is the last to sign the contract, ending the sign stauts with both  */
  SignedBoth = 'SIGNED_BOTH',
  /**  The person to whom the offer is made is known as the offeree  */
  SignedOfferee = 'SIGNED_OFFEREE'
}

export type TestBusinessSelfServicePurchaseProductCanceledInput = {
  jobId?: InputMaybe<Scalars['ID']>;
};

export type TestBusinessSelfServicePurchaseProductChosenInput = {
  jobId?: InputMaybe<Scalars['ID']>;
  meta: Scalars['Record'];
};

export type Ticket = Node & {
  __typename: 'Ticket';
  entries: TicketEntryConnection;
  guid: Scalars['String'];
  id: Scalars['ID'];
  metadata: Scalars['String'];
  notes: TicketNoteConnection;
  relations: TicketRelConnection;
  status: TicketStatus;
  title: Scalars['String'];
  type: TicketType;
};

export type TicketEntry = Node & {
  __typename: 'TicketEntry';
  content: Scalars['String'];
  entryType: TicketEntryType;
  id: Scalars['ID'];
  ticketId: Scalars['ID'];
};

export type TicketEntryConnection = Connection & {
  __typename: 'TicketEntryConnection';
  edges: Array<TicketEntryEdge>;
  pageInfo: PageInfo;
};

export type TicketEntryEdge = Edge & {
  __typename: 'TicketEntryEdge';
  cursor: Scalars['String'];
  node: TicketEntry;
};

export enum TicketEntryType {
  App = 'APP',
  Chat = 'CHAT',
  Email = 'EMAIL',
  HelpPage = 'HELP_PAGE',
  Phone = 'PHONE',
  Sms = 'SMS',
  Web = 'WEB'
}

export type TicketNotFoundError = Error & {
  __typename: 'TicketNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type TicketNote = Node & {
  __typename: 'TicketNote';
  content: Scalars['String'];
  entryId: Scalars['ID'];
  id: Scalars['ID'];
  ticketId: Scalars['ID'];
};

export type TicketNoteConnection = Connection & {
  __typename: 'TicketNoteConnection';
  edges: Array<TicketNoteEdge>;
  pageInfo: PageInfo;
};

export type TicketNoteEdge = Edge & {
  __typename: 'TicketNoteEdge';
  cursor: Scalars['String'];
  node: TicketNote;
};

export type TicketRel = Node & {
  __typename: 'TicketRel';
  id: Scalars['ID'];
  relConst: TicketRelConst;
  relId: Scalars['ID'];
  relType: TicketRelType;
  ticketId: Scalars['ID'];
};

export type TicketRelConnection = Connection & {
  __typename: 'TicketRelConnection';
  edges: Array<TicketRelEdge>;
  pageInfo: PageInfo;
};

export enum TicketRelConst {
  Email = 'EMAIL',
  Evaluation = 'EVALUATION',
  Invoice = 'INVOICE',
  Job = 'JOB',
  MasterId = 'MASTER_ID',
  Mittanbud = 'MITTANBUD',
  Order = 'ORDER',
  OrderLine = 'ORDER_LINE',
  Payments = 'PAYMENTS',
  PaymentDetails = 'PAYMENT_DETAILS',
  Product = 'PRODUCT',
  Sms = 'SMS',
  System = 'SYSTEM',
  SystemUserMasterId = 'SYSTEM_USER_MASTER_ID',
  TemplateProduct = 'TEMPLATE_PRODUCT'
}

export type TicketRelEdge = Edge & {
  __typename: 'TicketRelEdge';
  cursor: Scalars['String'];
  node: TicketRel;
};

export enum TicketRelType {
  About = 'ABOUT',
  Receiver = 'RECEIVER',
  Sender = 'SENDER'
}

export enum TicketStatus {
  Failed = 'FAILED',
  OnHold = 'ON_HOLD',
  Open = 'OPEN',
  Reopened = 'REOPENED',
  Resolved = 'RESOLVED'
}

export enum TicketType {
  BugReport = 'BUG_REPORT',
  CompanyLead = 'COMPANY_LEAD',
  Complaint = 'COMPLAINT',
  Feedback = 'FEEDBACK',
  Gdpr = 'GDPR',
  JobReport = 'JOB_REPORT',
  Support = 'SUPPORT',
  WantsContact = 'WANTS_CONTACT',
  WantsProduct = 'WANTS_PRODUCT'
}

export type TimeInterval = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type TimeIntervalInputError = Error & {
  __typename: 'TimeIntervalInputError';
  code?: Maybe<Scalars['Int']>;
  from?: Maybe<BasicInputError>;
  message: Scalars['String'];
  to?: Maybe<BasicInputError>;
};

export type ToggleDistrictsInput = {
  districtId: Scalars['ID'];
  enable: Scalars['Boolean'];
};

export type ToggleMunicipalitiesInput = {
  enable: Scalars['Boolean'];
  municipalityId: Scalars['ID'];
};

export type ToggleNotificationDestinationInput = {
  destinationType: NotificationDestinationType;
  /**  If you'd like to set to a certain state, do so with this  */
  enabled?: InputMaybe<Scalars['Boolean']>;
  slug: Scalars['String'];
};

export type ToggleNotificationDestinationPayload = {
  __typename: 'ToggleNotificationDestinationPayload';
  destinations: Array<NotificationDestination>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TriggerEventBoostPaymentInput = {
  eventSlug: SelfPurchaseTriggerSlug;
  meta: Scalars['Record'];
};

export type TriggerEventSelfServicePaymentInput = {
  eventSlug: SelfPurchaseTriggerSlug;
  meta: Scalars['Record'];
  product: ProductTemplateSlug;
};

export type TriggerInput = {
  auth: Scalars['String'];
  data?: InputMaybe<Scalars['Record']>;
  event: Scalars['String'];
};

export type UnfavouriteJobInput = {
  jobId: Scalars['ID'];
};

export type UnfavouriteJobPayload = {
  __typename: 'UnfavouriteJobPayload';
  success: Scalars['Boolean'];
};

export type UnpauseJobInput = {
  id: Scalars['ID'];
};

export type UpdateAnswerTemplateInput = {
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateAnswerTemplatePayload = {
  __typename: 'UpdateAnswerTemplatePayload';
  success?: Maybe<Scalars['Boolean']>;
  template: AnswerTemplate;
};

export type UpdateBusinessAdressesInput = {
  billing?: InputMaybe<AddressInput>;
  postal?: InputMaybe<AddressInput>;
  visit?: InputMaybe<AddressInput>;
};

export type UpdateBusinessAlbumImageInput = {
  albumId: Scalars['ID'];
  albumImageId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  rotate?: InputMaybe<RotateBusinessAlbumImageInput>;
  sort?: InputMaybe<Scalars['Int']>;
};

export type UpdateBusinessAlbumImagePayload = {
  __typename: 'UpdateBusinessAlbumImagePayload';
  albumImage: AlbumImage;
};

export type UpdateBusinessAlbumInput = {
  albumId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessAlbumPayload = {
  __typename: 'UpdateBusinessAlbumPayload';
  album: Album;
};

export type UpdateBusinessDistrictsInput = {
  toggleDistricts: Array<ToggleDistrictsInput>;
};

export type UpdateBusinessDistrictsPayload = {
  __typename: 'UpdateBusinessDistrictsPayload';
  districts?: Maybe<Array<District>>;
  districtsAvailable?: Maybe<Array<District>>;
};

export type UpdateBusinessEmployeeInput = {
  email?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNr?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessEmployeeInputError = Error & {
  __typename: 'UpdateBusinessEmployeeInputError';
  code?: Maybe<Scalars['Int']>;
  email?: Maybe<BasicInputError>;
  employeeId?: Maybe<BasicInputError>;
  firstName?: Maybe<BasicInputError>;
  image?: Maybe<BasicInputError>;
  lastName?: Maybe<BasicInputError>;
  message: Scalars['String'];
  phoneNr?: Maybe<BasicInputError>;
  position?: Maybe<BasicInputError>;
};

export type UpdateBusinessEmployeeNoAccessError = Error & {
  __typename: 'UpdateBusinessEmployeeNoAccessError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type UpdateBusinessEmployeePayload = {
  __typename: 'UpdateBusinessEmployeePayload';
  employee: BusinessEmployee;
};

export type UpdateBusinessEmployeeResult = UpdateBusinessEmployeeInputError | UpdateBusinessEmployeeNoAccessError | UpdateBusinessEmployeePayload;

export type UpdateBusinessInput = {
  address?: InputMaybe<UpdateBusinessAdressesInput>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  /** @deprecated Use updateBusinessProfile instead */
  facebook?: InputMaybe<Scalars['String']>;
  /** @deprecated Use phone instead */
  mobile?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  /** @deprecated Use updateBusinessProfile instead */
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessInputError = Error & {
  __typename: 'UpdateBusinessInputError';
  address?: Maybe<BusinessAddressInputError>;
  code?: Maybe<Scalars['Int']>;
  description?: Maybe<BasicInputError>;
  email?: Maybe<BasicInputError>;
  facebook?: Maybe<BasicInputError>;
  message: Scalars['String'];
  mobile?: Maybe<BasicInputError>;
  name?: Maybe<BasicInputError>;
  phone?: Maybe<BasicInputError>;
  website?: Maybe<BasicInputError>;
};

export type UpdateBusinessMunicipalitiesInput = {
  toggleMunicipalities: Array<ToggleMunicipalitiesInput>;
};

export type UpdateBusinessMunicipalitiesPayload = {
  __typename: 'UpdateBusinessMunicipalitiesPayload';
  municipalities?: Maybe<Array<Municipality>>;
  municipalitiesAvailable?: Maybe<Array<Municipality>>;
};

export type UpdateBusinessProfileInfoInput = {
  address?: InputMaybe<AddressInput>;
  businessId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  headerImage?: InputMaybe<Scalars['Upload']>;
  instagram?: InputMaybe<Scalars['String']>;
  openingHours?: InputMaybe<OpeningHoursInput>;
  phone?: InputMaybe<Scalars['String']>;
  profileVideo?: InputMaybe<Scalars['Upload']>;
  tiktok?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessProfileInfoInputError = Error & {
  __typename: 'UpdateBusinessProfileInfoInputError';
  address?: Maybe<AddressInputError>;
  businessId?: Maybe<BasicInputError>;
  code?: Maybe<Scalars['Int']>;
  description?: Maybe<BasicInputError>;
  facebook?: Maybe<BasicInputError>;
  headerImage?: Maybe<BasicInputError>;
  instagram?: Maybe<BasicInputError>;
  message: Scalars['String'];
  openingHours?: Maybe<OpeningHoursInputError>;
  phone?: Maybe<BasicInputError>;
  tiktok?: Maybe<BasicInputError>;
  title?: Maybe<BasicInputError>;
  website?: Maybe<BasicInputError>;
  youtube?: Maybe<BasicInputError>;
};

export type UpdateBusinessProfileInfoResult = Business | UpdateBusinessProfileInfoInputError;

export type UpdateBusinessResult = Business | UpdateBusinessInputError;

export type UpdateJobInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type UpdateJobWatchlistInput = {
  countyId: Array<Scalars['ID']>;
  districtId: Array<Scalars['ID']>;
  id: Scalars['ID'];
  industryIds: Array<Scalars['ID']>;
  municipalityCode: Array<Scalars['ID']>;
  notificationSettings?: InputMaybe<JobWatchlistNotificationSettingsInput>;
  query: Scalars['String'];
  sizes: Array<JobSize>;
  title: Scalars['String'];
  worktypeIds: Array<Scalars['ID']>;
  worktypeSubsetGroups: Array<Scalars['ID']>;
  worktypeSubsets: Array<Scalars['ID']>;
};

export type UploadBusinessAlbumImage = {
  description?: InputMaybe<Scalars['String']>;
  image: Scalars['Upload'];
  rotate?: InputMaybe<RotateBusinessAlbumImageInput>;
};

export type UploadBusinessAlbumImagesInput = {
  albumId: Scalars['ID'];
  images: Array<UploadBusinessAlbumImage>;
};

export type UploadBusinessAlbumImagesPayload = {
  __typename: 'UploadBusinessAlbumImagesPayload';
  albumImages: Array<AlbumImage>;
};

export type User = Node & {
  __typename: 'User';
  addresses?: Maybe<Array<Address>>;
  companies?: Maybe<Array<Business>>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initials?: Maybe<Scalars['String']>;
  isConsumerUser?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isVerifiedWithSecureId: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  operatingAs?: Maybe<Business>;
  phone?: Maybe<Scalars['String']>;
};

export type UserDisabledError = Error & {
  __typename: 'UserDisabledError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type UserNotFoundError = Error & {
  __typename: 'UserNotFoundError';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
};

export type UserOrBusiness = Business | User;

export enum UserRole {
  Admin = 'ADMIN',
  Consumer = 'CONSUMER',
  Employee = 'EMPLOYEE'
}

export type UserSettings = {
  __typename: 'UserSettings';
  ReceiveNewsletters?: Maybe<Scalars['Boolean']>;
};

export enum UserSettingsValueType {
  Boolean = 'Boolean'
}

export type VerifyOneOnOneJobInput = {
  code: Scalars['String'];
  jobId: Scalars['ID'];
  phoneNumber: Scalars['String'];
  userId: Scalars['ID'];
};

export type Video = Node & {
  __typename: 'Video';
  description?: Maybe<Scalars['String']>;
  dimensions: Dimension;
  fileType: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export type WeeklyOpeningHours = {
  __typename: 'WeeklyOpeningHours';
  friday?: Maybe<DailyOpeningHours>;
  monday?: Maybe<DailyOpeningHours>;
  saturday?: Maybe<DailyOpeningHours>;
  sunday?: Maybe<DailyOpeningHours>;
  thursday?: Maybe<DailyOpeningHours>;
  tuesday?: Maybe<DailyOpeningHours>;
  wednesday?: Maybe<DailyOpeningHours>;
};

export type WonJobs = {
  __typename: 'WonJobs';
  city: Scalars['String'];
  description: Scalars['String'];
  jobId: Scalars['ID'];
  postCode: Scalars['String'];
  title: Scalars['String'];
  wonAt: Scalars['DateTime'];
};

export type WorkType = Node & {
  __typename: 'WorkType';
  /**  if the worktype is currently in use (inactive=0, active=1)  */
  active?: Maybe<Scalars['Boolean']>;
  /**  the title that will be displayed for the user  */
  consumerTitle?: Maybe<Scalars['String']>;
  /**  the description of the worktype  */
  description?: Maybe<Scalars['String']>;
  /**  worktype used in public forms  */
  formSlug?: Maybe<Scalars['String']>;
  /**  worktype ID  */
  id: Scalars['ID'];
  /**  industries the work type belongs to  */
  industries?: Maybe<Array<Maybe<Industry>>>;
  /**  priority value of the worktype  */
  prio: Scalars['Int'];
  /**  worktype identifyer in string format  */
  slug: Scalars['String'];
  /**  title of worktype */
  title: Scalars['String'];
};

/**
 * Used for connecting additional data related to job work types. Mostly affecting filters,
 * but also as to whether a business can answer a job.
 */
export type WorkTypeSubset = Node & {
  __typename: 'WorkTypeSubset';
  id: Scalars['ID'];
  /**  Group that the work type is part of. E.g. CarBrands, Fuel  */
  partOf?: Maybe<WorkTypeSubsetGroup>;
  /** Title of workTypeSubset  */
  title: Scalars['String'];
  /**  If subset should be displayed to the user  */
  visible: Scalars['Boolean'];
};

/**
 * Group of subsets related to a worktype (within an industry)
 * Car->Breaks [CarBrands, Fuel]
 */
export type WorkTypeSubsetGroup = {
  __typename: 'WorkTypeSubsetGroup';
  consumerTitle: Scalars['String'];
  id: Scalars['ID'];
  /**  E.g. title: 'Car Brands' -> worktypes: [LoadsOfCarWorktypes]  */
  slug: Scalars['String'];
};

export type AuthUserFragment = { __typename: 'User', id: string, isConsumerUser?: boolean | null, operatingAs?: { __typename: 'Business', id: string } | null, companies?: Array<{ __typename: 'Business', id: string }> | null };

export type AuthLoginWithPasswordMutationVariables = Exact<{
  input: LoginInput;
}>;


export type AuthLoginWithPasswordMutation = { __typename: 'Mutation', loginWithPassword: { __typename: 'LoginPayload', token?: string | null, user?: { __typename: 'User', id: string, isConsumerUser?: boolean | null, operatingAs?: { __typename: 'Business', id: string } | null, companies?: Array<{ __typename: 'Business', id: string }> | null } | null } };

export type AuthRequestPasswordlessLoginMutationVariables = Exact<{
  input: RequestLoginEmailInput;
}>;


export type AuthRequestPasswordlessLoginMutation = { __typename: 'Mutation', requestLoginEmail: { __typename: 'LoginWithEmailPayload', success: boolean } };

export type AuthLoginWithOtpMutationVariables = Exact<{
  input: LoginWithOneTimePasswordInput;
}>;


export type AuthLoginWithOtpMutation = { __typename: 'Mutation', loginWithOneTimePasswordTmp: { __typename: 'LoginPayload', token?: string | null, user?: { __typename: 'User', id: string, isConsumerUser?: boolean | null, operatingAs?: { __typename: 'Business', id: string } | null, companies?: Array<{ __typename: 'Business', id: string }> | null } | null } };

export type AuthLoginWithTokenMutationVariables = Exact<{
  input: LoginWithTokenInput;
}>;


export type AuthLoginWithTokenMutation = { __typename: 'Mutation', loginWithTokenV2: { __typename: 'LoginPayload', token?: string | null, user?: { __typename: 'User', id: string, isConsumerUser?: boolean | null, operatingAs?: { __typename: 'Business', id: string } | null, companies?: Array<{ __typename: 'Business', id: string }> | null } | null } };

export type AuthRequestUsernameMutationVariables = Exact<{
  input: ForgotUsernameInput;
}>;


export type AuthRequestUsernameMutation = { __typename: 'Mutation', forgotUsernameV2: boolean };

export type AuthRequestPasswordResetMutationVariables = Exact<{
  input: RequestPasswordResetInput;
}>;


export type AuthRequestPasswordResetMutation = { __typename: 'Mutation', requestPasswordReset?: any | null };

export type AuthConfirmPasswordResetMutationVariables = Exact<{
  input: CompletePasswordResetInput;
}>;


export type AuthConfirmPasswordResetMutation = { __typename: 'Mutation', completePasswordReset?: any | null };

export type AuthLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type AuthLogoutMutation = { __typename: 'Mutation', logout: boolean };

export type AuthIsLoggedInQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthIsLoggedInQuery = { __typename: 'Query', loggedInStatus: { __typename: 'LoggedInStatus', loggedIn: boolean } };

export type AuthMeQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthMeQuery = { __typename: 'Query', me?: { __typename: 'User', id: string, isConsumerUser?: boolean | null, operatingAs?: { __typename: 'Business', id: string } | null, companies?: Array<{ __typename: 'Business', id: string }> | null } | null };

export type AuthLoggedInStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthLoggedInStatusQuery = { __typename: 'Query', loggedInStatus: { __typename: 'LoggedInStatus', loggedIn: boolean, as?: UserRole | null } };

export const AuthUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isConsumerUser"}},{"kind":"Field","name":{"kind":"Name","value":"operatingAs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AuthUserFragment, unknown>;
export const AuthLoginWithPasswordDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthLoginWithPassword"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginWithPassword"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthUser"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isConsumerUser"}},{"kind":"Field","name":{"kind":"Name","value":"operatingAs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AuthLoginWithPasswordMutation, AuthLoginWithPasswordMutationVariables>;
export const AuthRequestPasswordlessLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthRequestPasswordlessLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestLoginEmailInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestLoginEmail"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<AuthRequestPasswordlessLoginMutation, AuthRequestPasswordlessLoginMutationVariables>;
export const AuthLoginWithOtpDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthLoginWithOtp"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginWithOneTimePasswordInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginWithOneTimePasswordTmp"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthUser"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isConsumerUser"}},{"kind":"Field","name":{"kind":"Name","value":"operatingAs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AuthLoginWithOtpMutation, AuthLoginWithOtpMutationVariables>;
export const AuthLoginWithTokenDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthLoginWithToken"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LoginWithTokenInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loginWithTokenV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"token"}},{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthUser"}}]}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isConsumerUser"}},{"kind":"Field","name":{"kind":"Name","value":"operatingAs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AuthLoginWithTokenMutation, AuthLoginWithTokenMutationVariables>;
export const AuthRequestUsernameDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthRequestUsername"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ForgotUsernameInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forgotUsernameV2"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AuthRequestUsernameMutation, AuthRequestUsernameMutationVariables>;
export const AuthRequestPasswordResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthRequestPasswordReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"RequestPasswordResetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"requestPasswordReset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AuthRequestPasswordResetMutation, AuthRequestPasswordResetMutationVariables>;
export const AuthConfirmPasswordResetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthConfirmPasswordReset"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CompletePasswordResetInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"completePasswordReset"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}]}]}}]} as unknown as DocumentNode<AuthConfirmPasswordResetMutation, AuthConfirmPasswordResetMutationVariables>;
export const AuthLogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AuthLogout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"}}]}}]} as unknown as DocumentNode<AuthLogoutMutation, AuthLogoutMutationVariables>;
export const AuthIsLoggedInDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AuthIsLoggedIn"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loggedInStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loggedIn"}}]}}]}}]} as unknown as DocumentNode<AuthIsLoggedInQuery, AuthIsLoggedInQueryVariables>;
export const AuthMeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AuthMe"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"me"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"AuthUser"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"AuthUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"isConsumerUser"}},{"kind":"Field","name":{"kind":"Name","value":"operatingAs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}},{"kind":"Field","name":{"kind":"Name","value":"companies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AuthMeQuery, AuthMeQueryVariables>;
export const AuthLoggedInStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"AuthLoggedInStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loggedInStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"loggedIn"}},{"kind":"Field","name":{"kind":"Name","value":"as"}}]}}]}}]} as unknown as DocumentNode<AuthLoggedInStatusQuery, AuthLoggedInStatusQueryVariables>;