import { ApolloError } from '@apollo/client';
import {
  AuthConfirmPasswordResetDocument,
  type CompletePasswordResetInput,
} from '@internals/business-graphql/src/graphql/generated/types';
import { resolveErrors } from '@schibsted-smb/apollo-graphql-errors/lib/client';
import {
  ErrorTypes,
  GenericErrorCodes,
} from '@schibsted-smb/apollo-graphql-errors/lib/types';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import type { ErrorVariant } from '../login/types';

export type HandleConfirmNewPasswordErrorHandlers = {
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onGenericError: (error?: ErrorVariant) => void;
};

export const handleConfirmNewPassword = async (
  input: CompletePasswordResetInput,
  onSuccess: () => void | Promise<void>,
  errorHandlers: HandleConfirmNewPasswordErrorHandlers
) => {
  try {
    const { email, newPassword, token } = input;
    const { data } = await apolloClient.mutate({
      mutation: AuthConfirmPasswordResetDocument,
      variables: { input: { email, newPassword, token } },
    });

    if (data?.completePasswordReset === null) {
      await onSuccess();
    } else {
      throw new Error('Invalid confirm password reset response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
