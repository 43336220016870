import { AuthLogoutDocument } from '@internals/business-graphql/src/graphql/generated/types';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import type { AuthHandlers } from '../login/types';
import type { SetState } from '../types';

export const handleLogout = async (set: SetState, handlers: AuthHandlers) => {
  set({ status: 'unauthenticated', user: null });

  try {
    await apolloClient.mutate({
      mutation: AuthLogoutDocument,
    });
  } catch (errors) {
    bugsnagClient.notify(errors);
  }

  if (handlers?.onLogout) await handlers.onLogout();
};
