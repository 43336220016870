import { SEND_MESSAGE_sendMessage_SendMessagePayload_messages } from '../../generated/generated';
import getOptimisticId from '../id';

interface MessageParams {
  text?: string;
  id?: string;
  files?: SEND_MESSAGE_sendMessage_SendMessagePayload_messages['files'];
  images?: SEND_MESSAGE_sendMessage_SendMessagePayload_messages['images'];
}

export const getOptimisticMessage = (
  messageData: MessageParams
): SEND_MESSAGE_sendMessage_SendMessagePayload_messages => {
  const {
    text = '',
    id = getOptimisticId(),
    images = null,
    files = null,
  } = messageData;
  const messageTimestamp = Math.floor(Date.now() / 1000);

  return {
    __typename: 'Message',
    id,
    text,
    regTs: messageTimestamp,
    images,
    files,
  };
};

export default getOptimisticMessage;
