import { FC } from 'react';

import { ProductAddonImage } from '@components/layout/Products/ProductAddonImage';
import { ProductAlreadySubscribedBadge } from '@components/layout/Products/ProductAlreadySubscribedBadge';
import { ProductHeader } from '@components/layout/Products/ProductHeader';
import { ProductInfoListAccordion } from '@components/layout/Products/ProductInfoListAccordion';
import { StyledAddonDescription } from '@components/layout/Products/styled';
import XL from '@internals/business-shared/src/assets/images/xl.png';
import { mittanbudXLMock } from '@internals/business-shared/src/components/Products/productMocks';
import { ProductPackageStatus } from '@internals/business-shared/src/components/Products/productTypes';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

export const MittanbudXL: FC = () => {
  const { t } = useTranslation();
  const { status, name } = mittanbudXLMock;
  const isActive = status === ProductPackageStatus.Active;

  return (
    <Div>
      <ProductHeader title={name} />
      {isActive && <ProductAlreadySubscribedBadge />}
      <ProductAddonImage src={XL} />
      <StyledAddonDescription>
        {t('products.xl.descriptionFull')}
      </StyledAddonDescription>
      <Div mb={8}>
        <ProductInfoListAccordion
          title={t('products.xl.requirements.title')}
          infoItems={[
            {
              icon: IconPickerName.Person,
              text: t('products.xl.requirements.experience'),
            },
            {
              icon: IconPickerName.Medal,
              text: t('products.xl.requirements.certifications'),
            },
            {
              icon: IconPickerName.Star,
              text: t('products.xl.requirements.evaluations'),
            },
            {
              icon: IconPickerName.ShieldAlt,
              text: t('products.xl.requirements.insurance'),
            },
          ]}
        />
      </Div>
      {!isActive && <Button fullWidth>{t('general.action.contactMe')}</Button>}
    </Div>
  );
};
