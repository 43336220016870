import { FC } from 'react';

import { SavedFilterModal } from '@components/layout/Jobs/SavedFilters/SavedFilterModal';
import useAnalytics from '@hooks/useAnalytics';
import { useSavedFiltersContext } from '@internals/business-shared/src/components/SavedFilters/context';
import {
  EditedSaveEnabledFilter,
  SavedFilterModalType,
} from '@internals/business-shared/src/components/SavedFilters/types';
import { useUpdateJobsSavedFilterMutation } from '@internals/business-shared/src/hooks/mutation/useUpdateJobsSavedFilterMutation';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { EditedJobsSavedFilter } from '@internals/business-shared/src/utils/mutation/UpdateJobsSavedFilter/UpdateJobsSavedFilterMutation';
import { Button, Div } from '@schibsted-smb/fireball';
import { Trans, useTranslation } from 'react-i18next';

interface ChooseActionSavedFilterModalProps {
  onFilterEdited: (newFilter: EditedJobsSavedFilter) => void;
}

export const ChooseActionSavedFilterModal: FC<
  ChooseActionSavedFilterModalProps
> = ({ onFilterEdited }) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { setNewFilter, openModal, closeModal, filterState } =
    useSavedFiltersContext();
  const { editedFilter } = filterState as EditedSaveEnabledFilter;
  const [editSavedFilterMutation, { loading }] =
    useUpdateJobsSavedFilterMutation();

  const onSave = () => {
    track(ANEventSpace(ANEvent.Edited, ANObject.FilterPrompt, ANPage.JobList));
    editSavedFilterMutation(
      {
        ...editedFilter.filters,
        id: editedFilter.id,
        title: editedFilter.label,
        notificationSettings: {
          push: editedFilter.notificationSettings.push,
          email: editedFilter.notificationSettings.email,
        },
      },
      {
        onCompleted: (response) => {
          closeModal();
          onFilterEdited(response.updateJobWatchlist);
        },
      }
    );
  };

  const onCreateNew = () => {
    setNewFilter(editedFilter.filters);
    openModal(SavedFilterModalType.Create);
  };

  return (
    <SavedFilterModal
      isOpen
      onClose={closeModal}
      isClosable={false}
      shouldCloseOnOverlayClick={false}
      maxWidth="400px"
      contentProps={{ p: 7 }}
    >
      <Div>
        <Div mb={7}>
          <Trans
            i18nKey="job.list.savedFilters.action.choose.text"
            values={{ title: editedFilter.label }}
            components={{ bold: <b /> }}
          />
        </Div>
        <Button
          variant="secondary"
          disabled={loading}
          fullWidth
          mb={5}
          onClick={onCreateNew}
        >
          {t('job.list.savedFilters.action.create.button')}
        </Button>
        <Button
          variant={loading ? 'inProgress' : 'primary'}
          fullWidth
          mb={5}
          onClick={onSave}
        >
          {t('general.action.saveChanges')}
        </Button>
        <Button
          variant="linkPrimary"
          disabled={loading}
          onClick={closeModal}
          fullWidth
        >
          {t('general.label.cancel')}
        </Button>
      </Div>
    </SavedFilterModal>
  );
};
