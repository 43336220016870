import { Div, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const HeaderSettingsText = styled(Div)<DivProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 15vw;
  margin-top: 5px;

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[4]}) {
    max-width: 100px;
    margin-top: 0;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[5]}) {
    max-width: 120px;
    margin-top: 0;
  }

  @media only screen and (min-width: ${(props): string =>
      props.theme.breakpoints[6]}) {
    max-width: 230px;
    margin-top: 0;
  }
`;

export default HeaderSettingsText;
