import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const StyledMobileContentContainer = styled(Div)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[6]}px;
  width: 100%;
  flex-grow: 2;
`;
