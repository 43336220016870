import { useCallback, useState } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { useAuthorizeBoligmappaIntegrationMutation } from '@internals/business-shared/src/hooks/mutation/useAuthorizeBoligmappaIntegration';
import { useUnlinkBoligmappaIntegrationMutation } from '@internals/business-shared/src/hooks/mutation/useUnlinkBoligmappaIntegration';
import { useBoligmappaIntegrationStateQuery } from '@internals/business-shared/src/hooks/query/useBoligmappaIntegrationStateQuery';
import { UseBoligmappaLazyLoginUrlQuery } from '@internals/business-shared/src/hooks/query/useBoligmappaLazyLoginUrlQuery';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { BoligmappaIntegrationStateEnum } from '@internals/business-shared/src/utils/generated/generated';
import { locationRedirect } from '@internals/business-shared/src/utils/url';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';

export type BoligMappaAuthHook = {
  isLoading: boolean;
  isAuthorised: boolean;
  authRedirect: (redirectUri: string) => Promise<void>;
  authoriseBoligMappa: ({
    code,
    redirectUrl,
  }: {
    code: string;
    redirectUrl: string;
  }) => Promise<void>;
  unlinkBoligMappa: () => Promise<void>;
  refetchBoligMappa: () => Promise<void>;
};

export const useBoligMappaAuth = (): BoligMappaAuthHook => {
  const [status, setStatus] = useState<BoligmappaIntegrationStateEnum>(
    BoligmappaIntegrationStateEnum.NOT_AUTHORIZED
  );

  const isBoligMappaEnabled = useFlagEnabled(FeatureFlags.BizBoligMappa);
  const { t } = useTranslation();

  const [boligmappaLazyLoginUrlQuery, boligMappaLoginUrlQueryResult] =
    UseBoligmappaLazyLoginUrlQuery();

  const [
    authorizeBoligmappaIntegration,
    { loading: authorizeMutationLoading },
  ] = useAuthorizeBoligmappaIntegrationMutation();

  const [
    unlinkBoligmappaIntegration,
    { loading: unlinkBoligmappaIntegrationLoading },
  ] = useUnlinkBoligmappaIntegrationMutation();

  const { loading: authorizeQueryLoading, refetch } =
    useBoligmappaIntegrationStateQuery({
      skip: !isBoligMappaEnabled,
      onCompleted: (data) => {
        if (data?.boligmappaIntegrationState.status) {
          setStatus(data.boligmappaIntegrationState.status);
        } else {
          setStatus(BoligmappaIntegrationStateEnum.NOT_AUTHORIZED);
        }
      },
      onError: () => {
        setStatus(BoligmappaIntegrationStateEnum.NOT_AUTHORIZED);
      },
    });

  const refetchBoligMappa = useCallback(async () => {
    const result = await refetch();
    if (result?.data?.boligmappaIntegrationState.status) {
      setStatus(result.data.boligmappaIntegrationState.status);
    }
  }, [refetch]);

  const unlinkBoligMappa = useCallback(async () => {
    await unlinkBoligmappaIntegration({
      onCompleted: (data) => {
        if (data?.unlinkBoligmappaIntegration.status) {
          setStatus(data.unlinkBoligmappaIntegration.status);
        }
      },
    });
  }, [unlinkBoligmappaIntegration]);

  const authoriseBoligMappa = useCallback(
    async ({ code, redirectUrl }: { code: string; redirectUrl: string }) => {
      await authorizeBoligmappaIntegration(
        {
          code,
          redirectUrl,
        },
        {
          onCompleted: (data) => {
            if (data?.authorizeBoligmappaIntegration.status) {
              setStatus(data.authorizeBoligmappaIntegration.status);
              return;
            }

            setStatus(BoligmappaIntegrationStateEnum.NOT_AUTHORIZED);
          },
          onError: () => {
            setStatus(BoligmappaIntegrationStateEnum.NOT_AUTHORIZED);
          },
        }
      );
    },
    [authorizeBoligmappaIntegration]
  );

  const authRedirect = useCallback(
    async (redirectUrl: string) => {
      try {
        const boligMappaLoginUrlResult = await boligmappaLazyLoginUrlQuery(
          redirectUrl
        );
        const url = boligMappaLoginUrlResult?.data?.boligmappaLoginUrl ?? '';
        locationRedirect(url);
      } catch (e) {
        bugsnagClient.notify(`Invalid boligmappa login url. ${e.message}`);
        ToastMessage(
          t('settings.integrations.boligmappa.error.invalidAuthUrl'),
          'danger'
        );
      }
    },
    [boligmappaLazyLoginUrlQuery, t]
  );

  return {
    isLoading:
      authorizeMutationLoading ||
      authorizeQueryLoading ||
      unlinkBoligmappaIntegrationLoading ||
      boligMappaLoginUrlQueryResult.loading,
    isAuthorised: status === BoligmappaIntegrationStateEnum.AUTHORIZED,
    authRedirect,
    authoriseBoligMappa,
    unlinkBoligMappa,
    refetchBoligMappa,
  };
};
