import { ApolloError } from '@apollo/client';
import {
  AuthLoginWithTokenDocument,
  type LoginWithTokenInput,
  UserRole,
} from '@internals/business-graphql/src/graphql/generated/types';
import {
  AuthenticationErrorCodes,
  ErrorTypes,
  GenericErrorCodes,
  resolveErrors,
} from '@schibsted-smb/apollo-graphql-errors/lib/client';

import { apolloClient } from '../../../apollo/initApollo';
import { bugsnagClient } from '../../../utils/initBugsnag';
import { defaultErrorHandler } from '../../error-handling/defaultErrorHandler';
import { handleLoginSuccess } from '../login/handleLoginSuccess';
import type { AuthHandlers, ErrorVariant } from '../login/types';
import type { SetState } from '../types';

export type HandleLoginWithTokenErrorHandlers = {
  onGenericError: (error?: ErrorVariant) => void;
  onRoleUnavailableError: (error: ErrorVariant) => void;
  onInvalidOrMissingInputError: (error: ErrorVariant) => void;
  onAuthNoAccessError: (error: ErrorVariant) => void;
  onUserIsInactive: (error: ErrorVariant) => void;
};

export const handleLoginWithToken = async (
  set: SetState,
  input: LoginWithTokenInput,
  authHandlers: AuthHandlers,
  errorHandlers: HandleLoginWithTokenErrorHandlers,
  defaultIntendedRole?: UserRole
) => {
  const intendedRole = input.intendedRole || defaultIntendedRole;

  try {
    // TODO: Verify that scout token works in production, if not add a feature flag for removing it here
    const { data } = await apolloClient.mutate({
      mutation: AuthLoginWithTokenDocument,
      variables: {
        input: { ...input, intendedRole },
      },
    });

    if (data?.loginWithTokenV2.user) {
      if (authHandlers.onTokenReceived && data.loginWithTokenV2.token) {
        await authHandlers.onTokenReceived(data.loginWithTokenV2.token);
      }

      set({
        status: 'authenticated',
        user: data.loginWithTokenV2.user,
        isAdmin: !!input.scoutToken,
      });

      await handleLoginSuccess(
        data.loginWithTokenV2.user,
        authHandlers,
        intendedRole
      );
    } else {
      throw new Error('Invalid login with token response');
    }
  } catch (errors) {
    if (errors instanceof ApolloError) {
      resolveErrors(
        {
          [ErrorTypes.INPUT]: [
            [
              GenericErrorCodes.INVALID_OR_MISSING,
              GenericErrorCodes.INVALID,
              GenericErrorCodes.MISSING,
              (error) => errorHandlers.onInvalidOrMissingInputError(error),
            ],
          ],
          [ErrorTypes.AUTHENTICATION]: [
            [
              AuthenticationErrorCodes.LOGIN_ROLE_UNAVAILABLE,
              (error) => errorHandlers.onRoleUnavailableError(error),
            ],
            [
              AuthenticationErrorCodes.AUTH_NO_ACCESS,
              (error) => errorHandlers.onAuthNoAccessError(error),
            ],
            [
              AuthenticationErrorCodes.USER_IS_INACTIVE,
              (error) => errorHandlers.onUserIsInactive(error),
            ],
          ],
        },
        () =>
          defaultErrorHandler(
            (error) => errorHandlers.onGenericError(error),
            true
          )
      )(errors.graphQLErrors);
    } else {
      bugsnagClient.notify(errors);
      errorHandlers.onGenericError();
    }
  }
};
