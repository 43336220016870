import { Text } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterName = styled(Text.span)<{ limitSpace?: boolean }>`
  border-radius: ${({ theme }) => theme.radii[1]};
  padding: ${({ theme }) => `0 ${theme.space[4]}px 0 0`};
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  max-width: ${({ limitSpace }) => (limitSpace ? '40%' : 'unset')};
  flex-shrink: ${({ limitSpace }) => (limitSpace ? 0 : 'unset')};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    max-width: ${({ limitSpace }) => (limitSpace ? '60%' : 'unset')};
  }
`;

export default FilterName;
