export const Paths = {
  Homepage: '/',
  JobsList: '/opgaver',
  JobsListItem: '/opgaver/:listId',
  JobsItem: '/opgave/:id',
  JobsItemPrint: '/opgave/:id/print',
  JobsItemTitle: '/opgave/:id/:titel',
  Messages: '/beskeder',
  MessagesItem: '/beskeder/:chatId',
  MessagesItemName: '/beskeder/:chatId/:navn',
  Icons: '/ikoner',
  Customer: '/kunder',
  CustomerContract: '/kunder/kontrakt',
  CustomerInvoice: '/kunder/faktura',
  CustomerEvaluation: '/kunder/anmeldelser',
  Profile: '/profil',
  ProfileEvaluations: '/profil/anmeldelser',
  ProfileEmployees: '/profil/ansatte',
  ProfileCompanyInfo: '/profil/virksomhedsinfo',
  ProfileGraphic: '/profil/grafisk',
  ProfilePictures: '/profil/billeder',
  ProfileReferences: '/profil/referencer',
  ProfileQualifications: '/profil/kvalifikationer',
  SettingsNotifications: '/indstillinger/meddelelser',
  SettingsJobs: '/indstillinger/opgavesoeg',
  SettingsProfile: '/indstillinger/virksomhedsinfo',
  SettingsProducts: '/indstillinger/produkter',
  // todo: add translations
  SettingsProductAddons: '/indstillinger/produkter/addons',
  SettingsProductAddon: '/indstillinger/produkter/addons/:slug',
  SettingsProductAddonPurchase:
    '/indstillinger/produkter/addons/:slug/purchase',
  SettingsProductRewards: '/indstillinger/produkter/rewards',
  SettingsPayment: '/indstillinger/kvitteringer',
  SettingsCustomerService: '/indstillinger/support',
  SettingsReceipts: '/indstillinger/kvitteringer2',
  SettingsPrivacyAndTerms: '/indstillinger/privatliv',
  SettingsIntegrations: '/indstillinger/integrationer',
  Settings: '/indstillinger',
  Logout: '/log-ud',
  AccountSwitch: '/byt-bruger',
} as const;

export const Params = {
  Page: 'side',
  Contract: 'kontrakt',
  Search: 'soeg',
  Size: 'stoerrelse',
  Municipality: 'kommune',
  District: 'omraade',
  Folder: 'mappe',
  WorkType: 'branche',
  WorkSubset: 'subset',
  Industry: 'industry',
  WorkSubsetGroup: 'subset-group',
  County: 'county',
  Job: 'opgave',
  Evaluation: 'anmeldelse',
  Report: 'rapport',
  BoostCreditsModal: 'boost-klip-modal',
  BuySubscriptionModal: 'koeb-abonnement-modal',
  SelectedPackage: 'valgt-pakke',
} as const;
