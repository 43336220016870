import { InMemoryCache, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import gql from 'graphql-tag';

import EVALUATION_FRAGMENT from '../../utils/fragments/EvaluationFragment';

const query = gql`
  ${EVALUATION_FRAGMENT}
  query JOB_EVALUATION($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        evaluation {
          ...EvaluationFragment
        }
      }
    }
  }
`;

export const writeEvaluation = (
  cache: InMemoryCache,
  readField: ReadFieldFunction,
  jobId?: string,
  value?: Reference
) => {
  const evaluation = cache.readFragment({
    fragment: EVALUATION_FRAGMENT,
    fragmentName: 'EvaluationFragment',
    id: `Evaluation:${readField('id', value)}`,
  });
  cache.writeQuery({
    data: { job: { __typename: 'Job', id: jobId, evaluation } },
    variables: { id: jobId },
    query,
  });
};
