import {
  gql,
  QueryHookOptions,
  QueryResult,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import {
  isChatJobQuerySuccessResponse,
  CHAT_JOB_QUERY,
  ChatJobQueryPayload,
  ChatJobQueryVariables,
  ChatJob,
  SimpleAgreementContract,
} from '@internals/business-shared/src/utils/query/ChatJob/ChatJobQuery';

interface ChatJobQueryResult extends QueryResult {
  data: {
    job: ChatJob;
    simpleAgreement: SimpleAgreementContract | null;
  } | null;
}

const useChatJobQuery = (
  chatId: ChatJobQueryVariables['id'],
  queryOptions?: QueryHookOptions<ChatJobQueryPayload, ChatJobQueryVariables>
): ChatJobQueryResult => {
  // check for chat with job in cache to find job id required for job query
  const chatJobIdFragment: { job: { id: string } } | null =
    useApolloClient().readFragment({
      id: `Chat:${chatId}`,
      fragment: gql`
        fragment ChatJobIdFragment on Chat {
          job {
            id
          }
        }
      `,
      fragmentName: 'ChatJobIdFragment',
    });

  const jobId = chatJobIdFragment?.job?.id;

  const { data: jobData, ...rest } = useQuery<
    ChatJobQueryPayload,
    ChatJobQueryVariables
  >(CHAT_JOB_QUERY, {
    variables: { id: jobId },
    skip: !jobId,
    fetchPolicy: 'cache-first',
    ...queryOptions,
  });

  const job =
    jobData && isChatJobQuerySuccessResponse(jobData.job) ? jobData.job : null;

  const { simpleAgreementContractByJobId } = jobData || {};

  return {
    data: jobData
      ? { job, simpleAgreement: simpleAgreementContractByJobId }
      : null,
    ...rest,
  };
};

export default useChatJobQuery;
