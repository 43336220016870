import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  padding: ${(props) => props.theme.space[6]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 77px;
  color: ${(props): string => props.theme.colors.black.black9};
  &:hover {
    color: ${(props): string => props.theme.colors.black.black9};
    text-decoration: none;
    background-color: ${(props): string => props.theme.colors.black.black2};
  }
`;

export default StyledLink;
