import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text, Tooltip } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

import { AlertState } from './constants';

interface CounterBadgeProps {
  amount: string;
  label: string;
  tooltipText: string;
  variant: AlertState;
}

export const CounterBadge: FC<CounterBadgeProps> = ({
  amount,
  label,
  tooltipText,
  variant,
}) => {
  const isTabletOrMobile = useIsTabletOrMobile();
  const theme = useTheme();

  const colorMap: { [key in AlertState]?: string } = {
    [AlertState.Warning]: theme.colors.yellow.base,
    [AlertState.Danger]: theme.colors.red.base,
  };

  return (
    <Div
      display="flex"
      flexDirection="column"
      flex="1"
      mt={isTabletOrMobile ? 5 : 0}
      mb={6}
      p={variant !== AlertState.None ? 4 : 0}
      border={`1px solid ${colorMap[variant] ?? 'transparent'}`}
    >
      <Div display="flex">
        <Text.p mb={2} fontSize={1} fontWeight={theme.fontWeights.medium}>
          {label}
        </Text.p>
        {tooltipText && !isTabletOrMobile && (
          <Tooltip
            ml={3}
            position="under"
            content={<Text.p mb={0}>{tooltipText}</Text.p>}
          >
            <FireIcon
              iconName={IconPickerName.FilledInfo}
              fill={colorMap[variant] ?? theme.colors.black.black6}
              width={14}
              height={14}
            />
          </Tooltip>
        )}
      </Div>
      <Text.p fontSize={5} fontWeight={theme.fontWeights.medium} mb={0}>
        {amount}
      </Text.p>
    </Div>
  );
};
