import { FC } from 'react';

import PrintJobView from '@components/layout/PrintJob';
import { useParams } from 'react-router-dom';

type MatchParams = {
  id: string;
};

const PrintJob: FC = () => {
  const params = useParams<MatchParams>();

  return <>{params.id && <PrintJobView id={params.id} />}</>;
};

export default PrintJob;
