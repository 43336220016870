import Paths from '@router/paths';
import { useLocation } from 'react-router-dom';

export const useIsJobListOpen = (): boolean => {
  const location = useLocation();
  return (
    location.pathname === Paths.Homepage ||
    location.pathname.includes(Paths.JobsList)
  );
};
