import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import ActionButtonsContainer from './ActionButtonsContainer';
import ApprovalTime from './ApprovalTime';

type ColorIndicatorVariant = 'green';

export interface JobContainerProps {
  showActionButtons: boolean;
  indicatorVariant?: ColorIndicatorVariant;
}

const JobContainer = styled(Div)<JobContainerProps>`
  position: relative;
  transition: background-color ${({ theme }) => theme.animationSpeed.fast}
    ease-in-out;

  ${({ indicatorVariant, theme }) =>
    indicatorVariant === 'green' &&
    `border-left: 5px solid ${theme.colors.green.dark};`}

  ${({ showActionButtons, theme }) =>
    showActionButtons
      ? `
      ${ActionButtonsContainer} {
        opacity: 1;
      }
      ${ApprovalTime} {
        opacity: 0;
        visibility: hidden;
      }
    `
      : `
      &:hover {
        background-color: ${theme.colors.black.black2};
        ${ActionButtonsContainer} {
          opacity: 1;
        }
        ${ApprovalTime} {
          opacity: 0;
          visibility: hidden;
        }
      }
    `}
`;

export default JobContainer;
