import { FC, useCallback } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import {
  SystemMessageContainer,
  SystemMessageText,
  SystemMessageTitle,
} from '@components/elements/ChatMessage/styled/SystemMessage';
import { useBoligMappaModalContext } from '@contexts/BoligMappaModalProvider';
import { useUserContext } from '@contexts/UserContext';
import useSetSearchParams from '@hooks/useSetSearchParams';
import {
  getSystemMessagePayload,
  isBoligmappaSystemMessage,
  isContractSystemMessage,
  isNudgedBoligmappaSystemMessage,
  SystemMessageOutput,
} from '@internals/business-shared/src/components/SystemMessage';
import { boligMappaSystemMessage } from '@internals/business-shared/src/components/SystemMessage/boligMappaSystemMessage';
import { contractSystemMessage } from '@internals/business-shared/src/components/SystemMessage/contractSystemMessage';
import { nudgedBoligMappaSystemMessage } from '@internals/business-shared/src/components/SystemMessage/nudgedBoligMappaSystemMessage';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import { MessageStatus } from '@internals/business-shared/src/utils/generated/generated';
import { getInitials } from '@internals/business-shared/src/utils/getInitials';
import { ChatMessageNode } from '@internals/business-shared/src/utils/query/ChatConversation/ChatConversation';
import { Params } from '@router/paths';
import { Avatar, Button, Div, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { bugsnagClient } from '@utils/initBugsnag';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import { useTheme } from 'styled-components';

import { MessageBody } from './ChatMessageBody';
import { SentDate } from './styled';

export interface MessageProps {
  message: ChatMessageNode;
}
export interface ChatMessageProps extends MessageProps {
  isLast?: boolean;
  showDate: boolean;
  showAvatar: boolean;
  name?: string;
  jobId: string;
}
export interface SystemMessageProps extends MessageProps {
  showDate: boolean;
  name?: string;
  contractId?: string;
}

export const SystemMessage: FC<SystemMessageProps> = ({
  message,
  showDate,
  name,
  contractId,
}) => {
  const { t } = useTranslation();
  const { operatingAs: company } = useUserContext();
  const { openModal: openBoligmappaModal } = useBoligMappaModalContext();
  const { type } = getSystemMessagePayload(message.text);
  const isBoligMappaMessage = isBoligmappaSystemMessage(type);
  const isNudgedBoligMappaMessage = isNudgedBoligmappaSystemMessage(type);
  const isContractMessage = isContractSystemMessage(type);

  // TODO - with the new kind of system messages we should refactor this
  let messageContent: SystemMessageOutput;

  if (isBoligMappaMessage) {
    messageContent = boligMappaSystemMessage(type, company?.name);
  } else if (isNudgedBoligMappaMessage) {
    // Ensure you have a function to handle this case
    messageContent = nudgedBoligMappaSystemMessage(type, name); // Request to upload documents to Boligmappa
  } else {
    messageContent = contractSystemMessage(type, company?.name, name);
  }

  const [setParam] = useSetSearchParams();

  const boligMappaEnabled = useFlagEnabled(FeatureFlags.BizBoligMappa);

  const onOpenContract = useCallback(() => {
    setParam(Params.Contract, contractId); // I'm not sure if this is the best solution. But I would like to avoid using callback drilling for that. In case of issues we can always introduce context for that.
  }, [contractId, setParam]);

  if (
    !boligMappaEnabled &&
    (isNudgedBoligMappaMessage || isBoligMappaMessage)
  ) {
    return null;
  }

  if (messageContent === null) {
    bugsnagClient.notify(
      new Error(`Unsupported system message. Message payload: ${message.text}`)
    );
    return null;
  }

  return (
    <>
      {showDate && (
        <SentDate
          my={4}
          textAlign="center"
          fontSize={0}
          color="black.black7"
          fontWeight={600}
        >
          {dateFromTimestamp(message.regTs)}
        </SentDate>
      )}
      <SystemMessageContainer>
        <SystemMessageTitle>{messageContent.title}</SystemMessageTitle>
        <Linkify>
          <SystemMessageText>{messageContent.text}</SystemMessageText>
        </Linkify>
        {isNudgedBoligMappaMessage && (
          <Button
            variant="primary"
            size="small"
            type="button"
            onClick={openBoligmappaModal}
          >
            {t('chat.item.message.cta.uploadBoligmappaDocuments')}
          </Button>
        )}
        {isContractMessage && contractId && (
          <Button
            variant="primary"
            size="small"
            type="button"
            onClick={onOpenContract}
          >
            {t('chat.action.openContract')}
          </Button>
        )}
      </SystemMessageContainer>
    </>
  );
};

export const SentMessage: FC<ChatMessageProps> = ({
  message,
  isLast,
  showDate,
  showAvatar,
  jobId,
}: ChatMessageProps) => {
  const themeContext = useTheme();
  const { firstName, lastName, operatingAs: company } = useUserContext();
  const { t } = useTranslation();

  return (
    <>
      {showDate && (
        <SentDate
          my={4}
          textAlign="center"
          fontSize={0}
          color="black.black7"
          fontWeight={600}
        >
          {dateFromTimestamp(message.regTs)}
        </SentDate>
      )}
      <Div
        display="flex"
        justifyContent="flex-end"
        mb={2}
        mr={showAvatar ? 0 : [4, null, null, null, null, null, 9]}
      >
        <Div
          maxWidth={450}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          ml={4}
        >
          <Div display="flex" flexDirection="row">
            <Div
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
              justifyContent="center"
            >
              <MessageBody
                background={themeContext.colors.primary.base}
                color={themeContext.colors.black.black0}
                message={message}
                jobId={jobId}
                businessMessage
              />
              {isLast && (
                <Text.span mt={2} mb={0} color="black.black7" fontSize={0}>
                  {message.status === MessageStatus.READ
                    ? t('chat.item.message.read')
                    : t('chat.item.message.sent')}
                </Text.span>
              )}
            </Div>
            {showAvatar && (
              <Div
                ml={[0, null, null, null, null, null, 4]}
                mr={[4, null, null, null, null, null, null, null, 0]}
                display="flex"
                alignItems="flex-end"
                mb={isLast ? '18px' : '0'}
              >
                <Avatar
                  display={['none', null, null, null, null, null, 'flex']}
                  initials={getInitials(`${firstName} ${lastName}`)}
                  image={company?.logo?.smallPreviewUrl}
                  size="s"
                  backgroundSize={
                    company?.logo?.smallPreviewUrl ? 'contain' : null
                  }
                />
              </Div>
            )}
          </Div>
        </Div>
      </Div>
    </>
  );
};

export const ReceivedMessage: FC<ChatMessageProps> = ({
  message,
  showDate,
  showAvatar,
  name,
  jobId,
}: ChatMessageProps) => {
  const themeContext = useTheme();
  const isPhoneMaskingEnabled = useFlagEnabled(FeatureFlags.BizPhoneMasking);
  return (
    <>
      {showDate && (
        <SentDate
          my={4}
          textAlign="center"
          fontSize={0}
          color="black.black7"
          fontWeight={600}
        >
          {dateFromTimestamp(message.regTs)}
        </SentDate>
      )}
      <Div
        display="flex"
        mb={2}
        ml={showAvatar ? 0 : [4, null, null, null, null, null, 9]}
        mr={4}
      >
        <Div maxWidth={400} display="flex" flexDirection="row">
          {showAvatar && (
            <Div
              mr={[0, null, null, null, null, null, 4]}
              ml={[4, null, null, null, null, null, null, null, 0]}
              display="flex"
              alignItems="flex-end"
            >
              <Avatar
                display={['none', null, null, null, null, null, 'flex']}
                initials={getInitials(name || '')}
                size="s"
              />
            </Div>
          )}
          <MessageBody
            background={themeContext.colors.black.black2}
            color={themeContext.colors.black.black10}
            message={message}
            phoneMasking={isPhoneMaskingEnabled}
            jobId={jobId}
          />
        </Div>
      </Div>
    </>
  );
};
