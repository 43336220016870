import { JobContainer } from '@components/elements/JobListItem/styled';
import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const JobItemsContainer = styled(Div)`
  ${JobContainer}:last-child {
    border-bottom-left-radius: ${(props) => props.theme.radii[3]};
    border-bottom-right-radius: ${(props) => props.theme.radii[3]};
  }
`;

export default JobItemsContainer;
