import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';

import { ColorLabel, ColorLabelCircleContainer } from './styled';

interface ColorRadioItemProps {
  id: string;
  color: string;
  checked: boolean;
  onChange: () => void;
}

const ColorRadioItem: React.FC<
  React.PropsWithChildren<ColorRadioItemProps>
> = ({ id, color, checked, onChange }) => (
  <>
    <input
      type="radio"
      hidden
      name="color"
      id={`color-${color}-${id.toString()}`}
      value={color}
      checked={checked}
      onChange={onChange}
    />
    <ColorLabel htmlFor={`color-${color}-${id.toString()}`}>
      <ColorLabelCircleContainer color={color} isActive={checked}>
        <FireIcon
          iconName={IconPickerName.Check}
          variant="flat"
          width="16px"
          height="16px"
        />
      </ColorLabelCircleContainer>
    </ColorLabel>
  </>
);

export default ColorRadioItem;
