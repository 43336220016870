import { t } from '@internals/business-translations/src/i18n';

import {
  getSystemMessagePayload,
  isBoligmappaSystemMessage,
  isNudgedBoligmappaSystemMessage,
  SystemMessageOutput,
  SystemMessageType,
} from '../components/SystemMessage';
import { boligMappaSystemMessage } from '../components/SystemMessage/boligMappaSystemMessage';
import { contractSystemMessage } from '../components/SystemMessage/contractSystemMessage';
import { nudgedBoligMappaSystemMessage } from '../components/SystemMessage/nudgedBoligMappaSystemMessage';
import { BusinessUser } from '../types/User';
import { MessageType } from './generated/generated';
import { BasicUser, getUserName } from './user';

export type LatestMessageType = {
  id: string | number;
  text: string | null;
  writer: BasicUser;
  type: MessageType;
} | null;

type LatestMessageTextType = (
  latestMessage: LatestMessageType,
  businessUser: BusinessUser,
  customerName: string,
  includeWriter?: boolean,
  SystemMessageText?: boolean
) => string;

export const latestMessageText: LatestMessageTextType = (
  latestMessage,
  businessUser,
  customerName,
  includeWriter = true,
  SystemMessageText = true
) => {
  let messageText = '';
  let messageContent: SystemMessageOutput;
  let systemMessageType: SystemMessageType;

  // In case of no latest message
  if (!latestMessage) return '';

  switch (latestMessage.type) {
    case MessageType.ATTACHMENT:
      if (latestMessage.writer.id === businessUser.id) {
        messageText = t('chat.item.attachment.sent');
      } else {
        messageText = t('chat.item.attachment.received');
      }
      break;
    case MessageType.SYSTEM:
      systemMessageType = getSystemMessagePayload(latestMessage.text).type;
      switch (true) {
        case isBoligmappaSystemMessage(systemMessageType):
          messageContent = boligMappaSystemMessage(
            systemMessageType,
            businessUser.firstName
          );
          break;
        case isNudgedBoligmappaSystemMessage(systemMessageType):
          messageContent = nudgedBoligMappaSystemMessage(
            systemMessageType,
            customerName
          );
          break;
        default:
          messageContent = contractSystemMessage(
            systemMessageType,
            businessUser.firstName,
            customerName
          );
      }

      messageText = SystemMessageText
        ? messageContent?.text
        : messageContent?.title;

      break;
    default:
      messageText = latestMessage.text;
      break;
  }
  const messageWriter = getUserName(latestMessage.writer);

  if (!messageWriter || !includeWriter) return messageText;
  if (!messageText) return messageWriter;
  return `${messageWriter}: ${messageText}`;
};
