import { FC } from 'react';

import { FeatureFlag } from '@components/elements/FeatureFlag';
import SettingsContainer from '@components/elements/SettingsContainer';
import SettingsSection from '@components/elements/SettingsSection';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { useTranslation } from 'react-i18next';

import { BoligmappaSection } from './Boligmappa/BoligmappaSection';
import IntegrationSubsection from './IntegrationSubsection';

export const Integrations: FC = () => {
  const { t } = useTranslation();

  return (
    <SettingsContainer>
      <SettingsSection
        icon={IconPickerName.Globe}
        title={t('settings.integrations.title')}
        testId="integrations"
      >
        <FeatureFlag name={FeatureFlags.BizBoligMappa}>
          <IntegrationSubsection
            icon={IconPickerName.BoligMappa}
            title={t('settings.integrations.boligmappa.title')}
            description={t('settings.integrations.boligmappa.info')}
            testId="integrations-boligmappa"
          >
            <BoligmappaSection />
          </IntegrationSubsection>
        </FeatureFlag>
      </SettingsSection>
    </SettingsContainer>
  );
};
