import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

type TextStyle = {
  color: string;
  icon?: {
    iconName: IconPickerName;
    color: string;
  };
};

interface CustomerServiceInfoWithIconProps {
  title: {
    text: string;
    style: TextStyle;
  };
  details?: {
    text: string;
    style?: TextStyle;
  };
  textBolded?: boolean;
  ml?: number;
}

const CustomerServiceInfoWithIcon: React.FC<
  React.PropsWithChildren<CustomerServiceInfoWithIconProps>
> = ({ title, details, textBolded = false, ml = 0 }) => {
  const themeContext = useTheme();

  if (details) {
    return (
      <Div ml={ml}>
        {title && title.style.icon ? (
          <Div display="flex" alignItems="center" mb={4}>
            <Div width={13} height={13} mr={13} lineHeight="0">
              <FireIcon
                iconName={title.style.icon.iconName}
                variant="flat"
                fill={title.style.icon.color}
              />
            </Div>
            <Text.p
              fontSize={2}
              mb={0}
              color={title.style.color}
              fontWeight={
                textBolded
                  ? themeContext.fontWeights.bold
                  : themeContext.fontWeights.regular
              }
            >
              {title.text}
            </Text.p>
          </Div>
        ) : (
          <Text.p
            mb={4}
            fontSize={2}
            color={title.style.color}
            fontWeight={
              textBolded
                ? themeContext.fontWeights.bold
                : themeContext.fontWeights.regular
            }
          >
            {title.text}
          </Text.p>
        )}
        {details.style && details.style.icon ? (
          <Div display="flex" alignItems="center">
            <Div width={13} height={13} mr={2} lineHeight="0">
              <FireIcon
                iconName={details.style.icon.iconName}
                variant="flat"
                fill={details.style.icon.color}
              />
            </Div>
            <Text.p
              mb={0}
              fontSize={1}
              color={details.style.color}
              fontWeight={
                textBolded
                  ? themeContext.fontWeights.bold
                  : themeContext.fontWeights.regular
              }
            >
              {details.text}
            </Text.p>
          </Div>
        ) : (
          <Text.p
            mb={0}
            ml={0}
            fontSize={1}
            color={details.style?.color}
            fontWeight={
              textBolded
                ? themeContext.fontWeights.bold
                : themeContext.fontWeights.regular
            }
          >
            {details.text}
          </Text.p>
        )}
      </Div>
    );
  }

  if (title.style.icon) {
    return (
      <Div display="flex">
        <Div width={13} height={13} mr={13}>
          <FireIcon
            iconName={title.style.icon.iconName}
            variant="flat"
            fill={title.style.icon.color}
          />
        </Div>
        <Text.p
          mb={4}
          fontSize={2}
          color={title.style.icon.color}
          fontWeight={
            textBolded
              ? themeContext.fontWeights.bold
              : themeContext.fontWeights.regular
          }
        >
          {title.text}
        </Text.p>
      </Div>
    );
  }

  return (
    <Text.p
      mb={0}
      fontSize={2}
      color={title.style.color}
      fontWeight={
        textBolded
          ? themeContext.fontWeights.bold
          : themeContext.fontWeights.regular
      }
    >
      {title.text}
    </Text.p>
  );
};

export default CustomerServiceInfoWithIcon;
