import { FC } from 'react';

import ProfilePicturesProvider from '@contexts/ProfilePicturesContext';

import ProfilePage from './Profile';

const Profile: FC = () => (
  <ProfilePicturesProvider>
    <ProfilePage />
  </ProfilePicturesProvider>
);

export default Profile;
