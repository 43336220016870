import gql from 'graphql-tag';

import COMPANY_STATE_FRAGMENT from '../../fragments/CompanyStateFragment';
import {
  COMPANY_STATE_QUERY as COMPANY_STATE_QUERY_Result,
  COMPANY_STATE_QUERY_businessStates,
  COMPANY_STATE_QUERYVariables,
} from '../../generated/generated';

export type CompanyStateQueryPayload = COMPANY_STATE_QUERY_Result;
export type CompanyStateQueryVariables = COMPANY_STATE_QUERYVariables;
export type CompanyState = COMPANY_STATE_QUERY_businessStates;

export const COMPANY_STATE_QUERY = gql`
  ${COMPANY_STATE_FRAGMENT}

  query COMPANY_STATE_QUERY($id: ID!) {
    businessStates(id: $id) {
      ...CompanyStateFragment
    }
  }
`;
