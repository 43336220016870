import { formattedChatHours } from '@internals/business-shared/src/utils/formatChatHours';
import { timeShift } from '@internals/business-shared/src/utils/timeShift';
import { getProduct } from '@utils/product';

const { customerServiceAvailability } = getProduct();
const WEEKDAY_UTC_HOUR_START = customerServiceAvailability.start;
const WEEKDAY_UTC_HOUR_END = customerServiceAvailability.end;
const { hourShift } = timeShift();

export const isChatActiveWeekDay = (hour: number): boolean =>
  hour >= hourShift(WEEKDAY_UTC_HOUR_START) &&
  hour < hourShift(WEEKDAY_UTC_HOUR_END);

export const isChatActive = (date: Date): boolean => {
  const SUNDAY = 0;
  const SATURDAY = 6;
  const day = date.getDay();
  const hour = date.getHours();
  const isWeekend = day === SUNDAY || day === SATURDAY;
  return isWeekend ? false : isChatActiveWeekDay(hour);
};

export const localChatHours = (): {
  startTime: string;
  endTime: string;
} => {
  return formattedChatHours(WEEKDAY_UTC_HOUR_START, WEEKDAY_UTC_HOUR_END);
};
