import { ChangeEvent, FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledSearchInput } from './styled/StyledSearchInput';

interface SearchProps {
  value: string;
  onChange: (query: string) => void;
}

export const Search: FC<SearchProps> = ({ value, onChange }) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value);

  return (
    <Div
      display="flex"
      position="relative"
      flex={1}
      px={isMobile ? 7 : 6}
      py={isMobile ? 4 : 0}
      {...(isMobile && { backgroundColor: themeContext.colors.black.black1 })}
    >
      <Div
        mr={4}
        display="flex"
        alignItems="center"
        position="absolute"
        top={0}
        bottom={0}
        left={isMobile ? 4 : 0}
        zIndex={Layer.base + Layer.above}
      >
        <FireIcon
          iconName={IconPickerName.Search}
          fill={
            isMobile
              ? themeContext.colors.black.black7
              : themeContext.colors.black.black9
          }
          variant="flat"
          width={14}
          height={14}
        />
      </Div>
      <StyledSearchInput
        onChange={handleOnChange}
        value={value}
        containersProps={{ flex: 1 }}
        placeholder={t('chat.item.placeholder.search')}
        borderColor="transparent"
        backgroundColor="inherit"
        unlabeled
        autofocus={!isMobile}
      />
    </Div>
  );
};
