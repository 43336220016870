import { useCallback, useMemo } from 'react';

import useAnalytics from '@hooks/useAnalytics';
import { useCompanyState } from '@internals/business-shared/src/contexts/CompanyStateContext';
import { useTriggerEventSelfServicePayment } from '@internals/business-shared/src/hooks/mutation/useTriggerEventSelfServicePayment';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  ProductTemplateSlug,
  SelfPurchaseTriggerSlug,
} from '@internals/business-shared/src/utils/generated/generated';
import { SelfServiceSubscriptions } from '@internals/business-shared/src/utils/query/SelfServiceSubscriptions/SelfServiceSubscriptions';
import { getProduct } from '@root/src/utils/product';

import {
  AnalyticsEvent,
  BusinessStatus,
  ModalState,
  TriggerSource,
} from '../constants';

interface SubscriptionAnalyticsOptions {
  selectedPackage?: SelfServiceSubscriptions;
  jobId?: string;
}

type SubscriptionAnalyticsType = (
  type: AnalyticsEvent | ModalState,
  options?: SubscriptionAnalyticsOptions
) => void;

export const useSubscriptionAnalytics = (): SubscriptionAnalyticsType => {
  const [triggerEventPayment] = useTriggerEventSelfServicePayment(
    ProductTemplateSlug.SUBSCRIPTION
  );
  const { currency } = getProduct();
  const {
    isVerified,
    isNew,
    hasProducts: basicPackage,
  } = useCompanyState() || {};
  const { track, page } = useAnalytics();

  const businessStatus = useMemo(() => {
    if (isNew) {
      return isVerified ? BusinessStatus.VERIFIED : BusinessStatus.UNVERIFIED;
    }
    return isVerified ? BusinessStatus.REACTIVATION : BusinessStatus.UNVERIFIED;
  }, [isVerified, isNew]);

  const getPackageDetails = useCallback(
    (selectedPackage: SelfServiceSubscriptions) => ({
      basicPackage,
      currency,
      ...(selectedPackage && {
        clips: selectedPackage.clips ?? 0,
        price: selectedPackage.price ?? 0,
        slug: selectedPackage.slug ?? '',
      }),
    }),
    [basicPackage, currency]
  );

  return useCallback(
    (type, { selectedPackage, jobId } = {}) => {
      const eventHandlers = {
        [AnalyticsEvent.ALERT_JOB_LIST_CLICKED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.BuySubscription,
              ANPage.JobList
            ),
            {
              businessStatus,
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_OPEN,
            meta: { triggerSource: TriggerSource.JOB_LIST },
          });
        },
        [AnalyticsEvent.ALERT_JOB_CARD_CLICKED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.BuySubscription,
              ANPage.JobCard
            ),
            {
              businessStatus,
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_OPEN,
            meta: { triggerSource: TriggerSource.JOB_CARD, jobId },
          });
        },
        [AnalyticsEvent.PACKAGE_SELECTED]: () => {
          track(
            ANEventSpace(
              ANEvent.Selected,
              ANObject.SelectPackage,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE,
            meta: getPackageDetails(selectedPackage),
          });
        },
        [AnalyticsEvent.PURCHASE_CONFIRMED]: () => {
          track(
            ANEventSpace(
              ANEvent.Selected,
              ANObject.ConfirmPurchase,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_CONFIRM,
            meta: getPackageDetails(selectedPackage),
          });
        },
        [AnalyticsEvent.PURCHASE_CANCELED]: () => {
          track(
            ANEventSpace(
              ANEvent.Cancelled,
              ANObject.CancelPayment,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_CANCEL,
            meta: getPackageDetails(selectedPackage),
          });
        },
        [AnalyticsEvent.PURCHASE_SUCCESS]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.CompletePayment,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_SUCCESS,
            meta: getPackageDetails(selectedPackage),
          });
        },
        [AnalyticsEvent.PURCHASE_FAILED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.PaymentFailed,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_FAIL,
            meta: getPackageDetails(selectedPackage),
          });
        },
        [AnalyticsEvent.RETRY_ATTEMPTED]: () =>
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.RetryAttempt,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          ),
        [AnalyticsEvent.CUSTOMER_SERVICE_OPENED]: () =>
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.OpenCustomerService,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          ),
        [AnalyticsEvent.ONBOARDING_BOOKED]: () =>
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.BookOnboarding,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          ),
        [AnalyticsEvent.MODAL_CLOSED]: () =>
          track(
            ANEventSpace(
              ANEvent.Closed,
              ANObject.None,
              ANPage.SubscriptionModal
            ),
            {
              package: getPackageDetails(selectedPackage),
            }
          ),
        [ModalState.SELECT_PACKAGE]: () =>
          page(ANSpace(ANObject.SelectPackage, ANPage.SubscriptionModal), {
            businessStatus,
          }),
        [ModalState.CONFIRM_PURCHASE]: () =>
          page(ANSpace(ANObject.ConfirmPurchase, ANPage.SubscriptionModal), {
            package: getPackageDetails(selectedPackage),
          }),
        [ModalState.REDIRECTION]: () =>
          page(ANSpace(ANObject.Redirection, ANPage.SubscriptionModal), {
            package: getPackageDetails(selectedPackage),
          }),
        [ModalState.SUMMARY]: () =>
          page(ANSpace(ANObject.Summary, ANPage.SubscriptionModal), {
            package: getPackageDetails(selectedPackage),
          }),
        [ModalState.NOT_COMPLETED]: () =>
          page(ANSpace(ANObject.NotCompleted, ANPage.SubscriptionModal), {
            package: getPackageDetails(selectedPackage),
          }),
      };

      return eventHandlers[type]?.();
    },
    [businessStatus, getPackageDetails, page, track, triggerEventPayment]
  );
};
