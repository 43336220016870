import { Dispatch, useCallback } from 'react';

import { t } from '@internals/business-translations/src/i18n';

import {
  BoligMappaContentAction,
  BoligMappaContentState,
} from '../reducers/BoligMappaModalReducer';
import { ChatJob } from '../utils/query/ChatJob/ChatJobQuery';
import { useBoligmappaRemoveAssociationMutation } from './mutation/useBoligmappaRemoveAssociation';

export const useDisconnectBoligmappa = (
  job: ChatJob,
  boligMappaState: BoligMappaContentState,
  boligMappaDispatch: Dispatch<BoligMappaContentAction>
) => {
  const [disconnectAddress, { loading }] =
    useBoligmappaRemoveAssociationMutation();

  const removeAssociation = useCallback(async () => {
    if (!boligMappaState.jobAssociations?.associations?.[0]) {
      return;
    }

    await disconnectAddress(
      {
        jobId: job.id,
        boligmappaId: boligMappaState.jobAssociations.associations[0].id,
      },
      {
        onCompleted: () => {
          boligMappaDispatch({
            type: 'SEARCH-ADDRESS',
          });
        },
        onError: () => {
          boligMappaDispatch({
            type: 'ERROR',
            payload: {
              message: t('boligmappa.error.disconnectAddress'),
            },
          });
        },
      }
    );
  }, [
    boligMappaDispatch,
    boligMappaState.jobAssociations?.associations,
    disconnectAddress,
    job.id,
  ]);

  return {
    removeAssociation,
    loading,
  };
};
