import { EditContractFormShape } from '../../types/FormShape/EditContract';
import { SimpleAgreementPriceType } from '../generated/generated';
import { EditSimpleAgreementContractMutationVariables } from '../mutation/EditSimpleAgreementContract/EditSimpleAgreementContract';
import { SimpleContract } from '../query/SimpleContract/SimpleContractQuery';
import rawDateFromTimestamp from '../rawDateFromTimestamp';
import { rawDateToTimestamp } from '../rawDateToTimestamp';

export const EditSimpleAgreementContractAdapter = (
  formikValues: EditContractFormShape,
  jobId: SimpleContract['job']['id']
): EditSimpleAgreementContractMutationVariables['input'] => ({
  jobId,
  description: formikValues.description,
  price: formikValues.price
    ? parseInt(formikValues.price.toString(), 10)
    : undefined,
  priceType: formikValues.priceType,

  ...(formikValues.price && {
    price: parseInt(formikValues.price.toString(), 10),
  }),
  ...(formikValues.start && {
    startDate: rawDateToTimestamp(formikValues.start),
  }),
  ...(formikValues.end && { endDate: rawDateToTimestamp(formikValues.end) }),
  sign: true,
  businessEmail: formikValues.businessEmail,
  businessPhone: formikValues.businessPhone,
});

export const EditSimpleAgreementContractFormikAdapter = (
  contractItem: SimpleContract
): EditContractFormShape =>
  ({
    id: contractItem.id,
    price: contractItem.price,
    priceType: contractItem.priceType as unknown as SimpleAgreementPriceType,
    description: contractItem.description,
    start: contractItem.startDate
      ? rawDateFromTimestamp(contractItem.startDate)
      : undefined,
    end: contractItem.endDate
      ? rawDateFromTimestamp(contractItem.endDate)
      : undefined,
    businessEmail: contractItem?.businessEmail ?? '',
    businessPhone: contractItem?.businessPhone ?? '',
  } as EditContractFormShape);
