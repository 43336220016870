import { useEffect, useState } from 'react';

import { i18n, t } from '@internals/business-translations/src/i18n';
import dayjs, { OpUnitType, QUnitType } from 'dayjs';

import IconPickerNames from '../../utils/constants/iconPickerNames';
import dateFromTimestamp from '../../utils/dateFromTimestamp';
import { getProductAddonsMock } from './productMocks';
import {
  ActivePackageStatus,
  BasicProduct,
  JobSubscriptionProduct,
  ProductDate,
  ProductPackageDuration,
  ProductPackagePrice,
  ProductTimeUnit,
  ProductVerification,
  ProductVerificationFailReason,
  ProductVerificationStatus,
} from './productTypes';

export const formatPrice = (price: number, locale: string) => {
  return new Intl.NumberFormat(locale).format(price);
};

export const printPrice = (price: string | number): string => `${price},-`;

const getTimePeriodText = (period: ProductTimeUnit, count: number) => {
  switch (period) {
    case ProductTimeUnit.Month:
      return i18n.t('general.dateTime.month', { count });
    case ProductTimeUnit.Year:
      return i18n.t('general.dateTime.year', { count });
    default:
      throw new Error(`Unhandled time period: ${period}`);
  }
};

export const getPackageMonthlyPrice = (
  price: ProductPackagePrice,
  withCurrency?: boolean
): string => {
  const pricePerMonth =
    price.period === ProductTimeUnit.Year ? price.cost / 12 : price.cost;
  const formattedPrice = formatPrice(pricePerMonth, i18n.language);
  if (withCurrency) {
    return `${formattedPrice} ${price.currency}`;
  }
  return printPrice(formattedPrice);
};

export const getPackageDuration = (duration: ProductPackageDuration): string =>
  `${duration.value} ${getTimePeriodText(duration.unit, duration.value)}`;

export const getProductPackageDate = (date: ProductDate): string =>
  dateFromTimestamp(i18n.language, date, 'DD. MMM YYYY');

export const countDuration = (
  startDate: ProductDate,
  endDate: ProductDate,
  unit: QUnitType | OpUnitType = 'month'
): number => {
  const start = dayjs.unix(startDate);
  const end = dayjs.unix(endDate);
  return end.diff(start, unit);
};

export const getProductPackageText = ({
  productSlug,
  productName,
  packageDetails,
}: {
  productSlug: BasicProduct['slug'];
  productName: string;
  packageDetails?: Pick<
    JobSubscriptionProduct,
    'numberOfCredits' | 'numberOfFreeCredits'
  >;
}): { name: string; description: string } => {
  switch (productSlug) {
    case 'spotlight':
      return {
        name: 'Spotlight',
        description: t('products.spotlight.description'),
      };
    case 'boost':
      return {
        name: 'Boost',
        description: t('products.boost.description'),
      };
    case 'mittanbud-xl':
      return {
        name: 'Mittanbud XL',
        description: t('products.xl.description'),
      };
    case 'job-package':
      if (!packageDetails) {
        throw new Error('Missing package details for job-package');
      }
      return {
        name: t('product.jobPackage.name'),
        description: t('product.jobPackage.description', {
          productName,
          numberOfCredits: packageDetails.numberOfCredits,
          numberOfFreeCredits: packageDetails.numberOfFreeCredits,
        }),
      };
    case 'freemium':
      return {
        name: t('product.freemium.name'),
        description: t('product.freemium.description', {
          productName,
        }),
      };
    default:
      throw new Error(`Unhandled product slug: ${productSlug}`);
  }
};

export const getProductPackageStatusText = (
  status: Extract<
    ActivePackageStatus,
    | ActivePackageStatus.CompanyNotVerified
    | ActivePackageStatus.CompanyVerified
    | ActivePackageStatus.FreemiumExpired
  >,
  productName: string
): { title: string; description: string } => {
  switch (status) {
    case ActivePackageStatus.CompanyNotVerified:
      return {
        title: t('product.package.companyNotVerified.title', {
          productName,
        }),
        description: t('product.package.companyNotVerified.description', {
          productName,
        }),
      };
    case ActivePackageStatus.CompanyVerified:
      return {
        title: t('product.package.companyVerified.title'),
        description: t('product.package.companyVerified.description', {
          productName,
        }),
      };
    case ActivePackageStatus.FreemiumExpired:
      return {
        title: t('product.package.freemiumExpired.title'),
        description: t('product.package.freemiumExpired.description'),
      };
    default: {
      throw new Error(`Missing handling for status: ${status}`);
    }
  }
};

export const getProductAddonContent = ({
  addonSlug,
}: {
  addonSlug: BasicProduct['slug'];
}): {
  title: string;
  addonName: string;
  description: string;
  fullDescription: string;
  icon: IconPickerNames;
} => {
  switch (addonSlug) {
    case 'spotlight':
      return {
        addonName: 'Spotlight',
        title: t('products.spotlight.cardTitle'),
        description: t('products.spotlight.descriptionLong'),
        fullDescription: t('products.spotlight.descriptionFull'),
        icon: IconPickerNames.Search,
      };
    case 'boost':
      return {
        addonName: 'Boost',
        title: t('products.boost.cardTitle'),
        description: t('products.boost.descriptionLong'),
        fullDescription: t('products.boost.descriptionFull'),
        icon: IconPickerNames.Rocket,
      };
    case 'mittanbud-xl':
      return {
        addonName: 'Mittanbud XL',
        title: t('products.xl.cardTitle'),
        description: t('products.xl.descriptionLong'),
        fullDescription: t('products.xl.descriptionFull'),
        icon: IconPickerNames.MittanbudXL,
      };
    default:
      throw new Error(`Unhandled addon slug: ${addonSlug}`);
  }
};

export const getVerificationFailReasonText = (
  reason: ProductVerificationFailReason
): string => {
  switch (reason) {
    case ProductVerificationFailReason.EvaluationCount:
      return t('products.spotlight.requirements.evaluationCount.fail');
    case ProductVerificationFailReason.EvaluationScore:
      return t('products.spotlight.requirements.evaluationScore.fail');
    case ProductVerificationFailReason.SubscriptionPeriod:
      return t('products.spotlight.requirements.subscriptionPeriod.fail');
    default:
      throw new Error(`Unhandled verification fail reason: ${reason}`);
  }
};

export const isHandledAddonPage = (slug: string): boolean => {
  const availableAddons = getProductAddonsMock().map((addon) => addon.slug);
  return availableAddons.includes(slug);
};

// todo: this is a mock function to simulate verification process, should be replaced after gql ready
export const useProductVerification = (): {
  verificationState: ProductVerification;
  startVerification: VoidFunction;
  resetVerification: VoidFunction;
} => {
  const [verificationState, setVerificationState] =
    useState<ProductVerification>({
      status: ProductVerificationStatus.Unsettled,
    });
  const startVerification = () =>
    setVerificationState({ status: ProductVerificationStatus.InProgress });
  const resetVerification = () =>
    setVerificationState({ status: ProductVerificationStatus.Unsettled });

  useEffect(() => {
    if (verificationState.status === ProductVerificationStatus.InProgress) {
      setTimeout(() => {
        setVerificationState({
          status: ProductVerificationStatus.Declined,
          reason: ProductVerificationFailReason.SubscriptionPeriod,
        });
        // setVerificationState({
        //   status: ProductVerificationStatus.Approved,
        // });
      }, 5000);
    }
  }, [verificationState.status]);

  return {
    verificationState,
    startVerification,
    resetVerification,
  };
};
