import { InMemoryCache, Reference } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import gql from 'graphql-tag';

import FILE_FRAGMENT from '../../utils/fragments/FileFragment';

const query = gql`
  ${FILE_FRAGMENT}
  query JOB_FILES($id: ID!) {
    job(id: $id) {
      ... on Job {
        __typename
        id
        files {
          ...FileFragment
        }
      }
    }
  }
`;

export const writeFiles = (
  cache: InMemoryCache,
  readField: ReadFieldFunction,
  jobId?: string,
  value?: Reference[]
) => {
  const files = value?.map((ref: Reference) => {
    return cache.readFragment({
      fragment: FILE_FRAGMENT,
      fragmentName: 'FileFragment',
      id: `File:${readField('id', ref)}`,
    });
  });
  cache.writeQuery({
    data: { job: { __typename: 'Job', id: jobId, files } },
    variables: { id: jobId },
    query,
  });
};
