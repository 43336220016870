import React from 'react';

import JobsView from '@components/layout/Settings/Jobs';
import withNavigationLayout from '@components/layout/WithNavigation';
import { SettingsNavigation } from '@pages/Settings';
import { useTranslation } from 'react-i18next';

export const SettingsJobs: React.FC = () => {
  const { t } = useTranslation();

  return withNavigationLayout({
    Navigation: SettingsNavigation,
    pageName: t('settings.title'),
    pageDetails: {
      PageContent: JobsView,
      subpageName: t('settings.jobSearch.title'),
    },
  })();
};
