export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type DeepWriteable<T> = {
  -readonly [P in keyof T]: DeepWriteable<T[P]>;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PlainObject = Record<PropertyKey, any>;
export type PlainTypedObject<T> = Record<PropertyKey, T>;
export type ValueOf<T> = T[keyof T];

export const removeNullable = <T>(list: (T | null | undefined)[]): T[] =>
  list.filter((el) => el !== undefined && el !== null);

// Taken from: https://stackoverflow.com/questions/55142177/how-to-build-a-type-from-enum-values-in-typescript
export type StringValues<T> = {
  [K in keyof T]: T[K] extends string ? T[K] : never;
}[keyof T];

export type NumberValues<T> = {
  [K in keyof T]: T[K] extends number ? T[K] : never;
}[keyof T];

/**
 * Usage : type EnumValues = EnumAsUnion<typeof anEnum>
 */
export type EnumAsUnion<T> = `${StringValues<T>}` | NumberValues<T>;
