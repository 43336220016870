import { useCallback } from 'react';

import useAnalytics from '@hooks/useAnalytics';
import { useTriggerEventSelfServicePayment } from '@internals/business-shared/src/hooks/mutation/useTriggerEventSelfServicePayment';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import {
  ProductTemplateSlug,
  SelfPurchaseTriggerSlug,
} from '@internals/business-shared/src/utils/generated/generated';
import { BoostCreditsProductDetails } from '@internals/business-shared/src/utils/query/BoostCreditsProductDetails/BoostCreditsProductDetails';
import { BoostCreditsProductsPair } from '@internals/business-shared/src/utils/query/BoostCreditsProductsPair/BoostCreditsProductsPair';
import { useLocation } from 'react-router-dom';

import {
  AnalyticsEvent,
  BOOST_PARAMS,
  HasCard,
  ModalState,
  TriggerSource,
} from '../constants';

interface BoostAnalyticsOptions {
  hasCard?: boolean;
  jobId?: string;
  selectedPackage?: BoostCreditsProductsPair | BoostCreditsProductDetails;
  triggerSource?: TriggerSource;
}

type BoostAnalyticsType = (
  type: AnalyticsEvent | ModalState,
  options?: BoostAnalyticsOptions
) => void;

export const useBoostAnalytics = (): BoostAnalyticsType => {
  const [triggerEventPayment] = useTriggerEventSelfServicePayment(
    ProductTemplateSlug.BOOST
  );
  const { track, page } = useAnalytics();
  const location = useLocation();

  const getProperties = useCallback(
    (
      {
        hasCard,
        jobId,
        selectedPackage,
        triggerSource,
      } = {} as BoostAnalyticsOptions
    ) => {
      const searchParams = new URLSearchParams(location.search);

      return {
        ...(hasCard ? { hasCard: HasCard.YES } : { hasCard: HasCard.NO }),
        ...(jobId && { jobId }),
        ...(selectedPackage && {
          clips: selectedPackage.clips ?? 0,
          price: selectedPackage.price ?? 0,
          slug: selectedPackage.slug ?? '',
        }),
        triggerSource: triggerSource ?? searchParams.get(BOOST_PARAMS.MODAL),
      };
    },
    [location.search]
  );

  return useCallback(
    (type, options) => {
      const eventHandlers = {
        [AnalyticsEvent.ALERT_JOB_LIST_CLICKED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.BoostCredits,
              ANPage.JobList
            ),
            getProperties({ triggerSource: TriggerSource.JOB_LIST, ...options })
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_OPEN,
            meta: getProperties({
              triggerSource: TriggerSource.JOB_LIST,
              ...options,
            }),
          });
        },
        [AnalyticsEvent.ALERT_JOB_CARD_CLICKED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.BoostCredits,
              ANPage.JobCard
            ),
            getProperties({ triggerSource: TriggerSource.JOB_CARD, ...options })
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_OPEN,
            meta: getProperties({
              triggerSource: TriggerSource.JOB_CARD,
              ...options,
            }),
          });
        },
        [AnalyticsEvent.AUTO_OPENED]: () => {
          track(
            ANEventSpace(ANEvent.Opened, ANObject.BoostCredits, ANPage.JobCard),
            getProperties({
              triggerSource: TriggerSource.AUTO_OPEN,
              ...options,
            })
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_OPEN,
            meta: getProperties({
              triggerSource: TriggerSource.AUTO_OPEN,
              ...options,
            }),
          });
        },
        [AnalyticsEvent.PACKAGE_SELECTED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.SelectPackage,
              ANPage.BoostModal
            ),
            getProperties(options)
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE,
            meta: getProperties(options),
          });
        },
        [AnalyticsEvent.PURCHASE_CONFIRMED]: () => {
          track(
            ANEventSpace(
              ANEvent.Selected,
              ANObject.ConfirmPurchase,
              ANPage.BoostModal
            ),
            getProperties()
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_CONFIRM,
            meta: getProperties(),
          });
        },
        [AnalyticsEvent.PURCHASE_CANCELED]: () => {
          track(
            ANEventSpace(
              ANEvent.Cancelled,
              ANObject.CancelPayment,
              ANPage.BoostModal
            ),
            getProperties()
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_CANCEL,
            meta: getProperties(),
          });
        },
        [AnalyticsEvent.PURCHASE_SUCCESS]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.CompletePayment,
              ANPage.BoostModal
            ),
            getProperties()
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_SUCCESS,
            meta: getProperties(),
          });
        },
        [AnalyticsEvent.PURCHASE_FAILED]: () => {
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.PaymentFailed,
              ANPage.BoostModal
            ),
            getProperties()
          );
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          triggerEventPayment({
            eventSlug: SelfPurchaseTriggerSlug.SELF_PURCHASE_PURCHASE_FAIL,
            meta: getProperties(),
          });
        },
        [AnalyticsEvent.RETRY_ATTEMPTED]: () =>
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.RetryAttempt,
              ANPage.BoostModal
            ),
            getProperties()
          ),
        [AnalyticsEvent.CUSTOMER_SERVICE_OPENED]: () =>
          track(
            ANEventSpace(
              ANEvent.Clicked,
              ANObject.OpenCustomerService,
              ANPage.BoostModal
            ),
            getProperties()
          ),
        [AnalyticsEvent.MODAL_CLOSED]: () =>
          track(ANEventSpace(ANEvent.Closed, ANObject.None, ANPage.BoostModal)),
        [ModalState.SELECT_PACKAGE]: () =>
          page(ANSpace(ANObject.BoostCredits, ANPage.BoostModal)),
        [ModalState.CONFIRM_PURCHASE]: () =>
          page(ANSpace(ANObject.ConfirmPurchase, ANPage.BoostModal)),
        [ModalState.SUMMARY]: () =>
          page(ANSpace(ANObject.Summary, ANPage.BoostModal)),
        [ModalState.NOT_COMPLETED]: () =>
          page(ANSpace(ANObject.NotCompleted, ANPage.BoostModal)),
      };

      return eventHandlers[type]?.();
    },
    [getProperties, page, track, triggerEventPayment]
  );
};
