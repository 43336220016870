import { FC, useCallback, useEffect, useMemo } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import PageContainer from '@components/base/PageContainer';
import { BoostCreditsModal } from '@components/elements/BoostCreditsModal';
import { BuySubscriptionModal } from '@components/elements/BuySubscriptionModal';
import JobsFooter from '@components/elements/JobsFooter';
import { MOBILE_HEADER_HEIGHT } from '@components/layout/HeaderMobile/styled/Header';
import { JobsListHeaderMobile } from '@components/layout/Jobs/JobsListHeaderMobile';
import { JobsFilteringTree } from '@components/layout/Jobs/Tree/JobsFilteringTree';
import { useJobListNavigation } from '@components/layout/Jobs/useJobListNavigation';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { JobsFiltersProvider } from '@contexts/JobFiltersContext';
import { JobListProvider } from '@contexts/JobListContext';
import useAnalytics from '@hooks/useAnalytics';
import { useJobListFiltersQuery } from '@internals/business-shared/src/hooks/query/useJobListFiltersQuery';
import {
  ANObject,
  ANPage,
  ANSpace,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JOB_LIST_FILTERS_QUERY_jobListFilters } from '@internals/business-shared/src/utils/generated/generated';
import { JobsListQueryVariables } from '@internals/business-shared/src/utils/query/JobsList/JobsListQuery';
import Paths from '@router/paths';
import { Column, Div, Row } from '@schibsted-smb/fireball';
import {
  FilterCounter,
  JobsNavigation,
  getJobListFilterCounter,
  getJobListMockedFiltersCounter,
} from '@utils/jobList';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';

import JobsList from './JobsList';
import JobsListNavigation from './JobsListNavigation';
import { Statistics } from './Statistics';

export interface JobsProps {
  listId: JobsListQueryVariables['listId'];
}

const MAX_PLACEHOLDER_ITEMS_COUNT = 20;

const Jobs: FC<JobsProps> = ({ listId }: JobsProps) => {
  const { page, track } = useAnalytics();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const jobsFilterTreeEnabled = useFlagEnabled(FeatureFlags.BizJobFilterTree);
  const isPillNavigationEnabled = useFlagEnabled(
    FeatureFlags.BizSavedSearchPillsView
  );

  const { data: filters } = useJobListFiltersQuery();
  const getFilterName = useCallback(
    (filter: JOB_LIST_FILTERS_QUERY_jobListFilters): string => {
      /*
        t('job.list.type_ANSWERED')
        t('job.list.type_BIG')
        t('job.list.type_DELETED')
        t('job.list.type_FAVOURITE')
        t('job.list.type_FOLDERS')
        t('job.list.type_FREE')
        t('job.list.type_OPEN')
        t('job.list.type_WON')
        t('job.list.type_DIRECT')
       */
      return t('job.list.type', {
        context: filter.listType,
        defaultValue: '-',
      });
    },
    [t]
  );

  const navigation: JobsNavigation | undefined = useMemo(() => {
    if (!filters) return undefined;
    return filters.map((filter) => {
      const filterCount = filter.count ?? 0;
      return {
        link: `${Paths.JobsList}/${filter.id}`,
        icon: `NAVIGATION_${filter.listType}` as IconPickerName,
        testId: `NAVIGATION_${filter.listType}`,
        label: getFilterName(filter),
        count: filterCount,
        id: filter.id,
      };
    });
  }, [filters, getFilterName]);

  const filteredNavigation = useJobListNavigation(navigation);

  const filterCounters: FilterCounter[] = useMemo(() => {
    if (!filters) return getJobListMockedFiltersCounter();
    return filters.map((filter) => {
      const filterCount = filter.count ?? 0;
      return {
        id: filter.id,
        count: filterCount,
        name: getFilterName(filter),
      };
    });
  }, [filters, getFilterName]);

  const currentFilterCounter: FilterCounter = useMemo(() => {
    return getJobListFilterCounter(filterCounters, listId);
  }, [filterCounters, listId]);

  const placeholderCount =
    currentFilterCounter.count > MAX_PLACEHOLDER_ITEMS_COUNT
      ? MAX_PLACEHOLDER_ITEMS_COUNT
      : currentFilterCounter.count;

  useEffect(() => {
    if (!filters || filters.length === 0) return;
    page(ANSpace(ANObject.None, ANPage.JobList), {
      list:
        listId && filters
          ? getFilterName(filters.find((filter) => filter.id === listId))
          : getFilterName(filters?.[0]),
    });
  }, [listId, filters, getFilterName, track, page]);

  return (
    <JobListProvider>
      <PageContainer
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        width={1}
        maxWidth={1300}
      >
        {isMobile ? (
          <>
            <JobsListHeaderMobile
              title={currentFilterCounter.name}
              navigationProps={{
                navigation: filteredNavigation,
                firstItemActive: !listId,
              }}
            />
            <Row
              width="100%"
              pt={isPillNavigationEnabled ? 0 : MOBILE_HEADER_HEIGHT}
            >
              <JobsList placeholderCount={placeholderCount} />
            </Row>
          </>
        ) : (
          <Row mt={6} flexWrap="nowrap">
            <JobsFiltersProvider>
              <>
                {jobsFilterTreeEnabled ? (
                  <JobsFilteringTree
                    navigationItems={filteredNavigation}
                    isFirstDefaultActive={!listId}
                  />
                ) : (
                  <JobsListNavigation
                    navigation={filteredNavigation}
                    firstItemActive={!listId}
                  />
                )}
                <JobsList placeholderCount={placeholderCount} />
              </>
            </JobsFiltersProvider>
            <Column
              pt={6}
              flex={1}
              flexDirection="column"
              display={['none', null, null, null, null, null, null, 'block']}
              pr={[4, null, null, null, null, null, null, 0]}
              mb={[null, null, null, null, null, 6]}
            >
              <Div position="sticky" top="90px" zIndex={Layer.sticky}>
                <Statistics />
                <JobsFooter />
              </Div>
            </Column>
          </Row>
        )}
        <BoostCreditsModal />
        <BuySubscriptionModal />
      </PageContainer>
    </JobListProvider>
  );
};

export default Jobs;
