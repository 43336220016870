import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

import { CUSTOM_BLUE_600 } from '../../../constants';

interface ContainerProps {
  isSelected: boolean;
  isSinglePackage: boolean;
}

export const Container = styled(Div)<ContainerProps>`
  background: ${({ theme }) => theme.colors.black.black0};
  border-radius: 8px;
  cursor: ${({ isSinglePackage }) => (isSinglePackage ? 'default' : 'pointer')};
  opacity: ${({ isSelected }) => (isSelected ? 1 : 0.9)};
  outline: ${({ isSelected, isSinglePackage }) =>
    !isSinglePackage && isSelected ? `4px solid ${CUSTOM_BLUE_600}` : 'none'};
  margin: 0 6px;
  position: relative;
  width: 248px;
  transition: all 0.3s ease-in-out;
`;
