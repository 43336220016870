import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const FilterHeader = styled(Div)`
  display: grid;
  grid-template-columns: 1fr auto minmax(max-content, 1fr);
  grid-gap: ${({ theme }) => `${theme.space[3]}px`};
  align-items: center;
  justify-items: flex-start;
  margin-bottom: ${({ theme }) => `${theme.space[7]}px`};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.black.black4}`};
    height: 50px;
    margin-bottom: ${({ theme }) => `${theme.space[3]}px`};
  }
`;

export default FilterHeader;
