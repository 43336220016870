import { FC, useCallback, useEffect } from 'react';

import { useBoligMappaAuthContext } from '@contexts/BoligMappaAuthProvider';
import useAnalytics from '@hooks/useAnalytics';
import useQuerySearchParams from '@hooks/useQuerySearchParams';
import useSetSearchParams from '@hooks/useSetSearchParams';
import useBoolean from '@internals/business-shared/src/hooks/useBoolean';
import {
  ANEvent,
  ANEventSpace,
  ANObject,
  ANPage,
} from '@internals/business-shared/src/utils/analyticsNamespace';
import { Button, Div, Text } from '@schibsted-smb/fireball';
import { getIntegrationsAbsoluteUrl } from '@utils/url';
import { useTranslation } from 'react-i18next';

import { RegisterBoligmappaModal } from './RegisterBoligmappaModal';

export const BoligmappaSection: FC = () => {
  const urlParams = useQuerySearchParams();
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const boligMappaRedirectUrl = getIntegrationsAbsoluteUrl();
  const {
    isAuthorised,
    isLoading,
    authRedirect,
    authoriseBoligMappa,
    unlinkBoligMappa,
  } = useBoligMappaAuthContext();
  const [
    registerBoligMappaModalVisible,
    openRegisterBoligMappaModal,
    hideRegisterBoligMappaModal,
  ] = useBoolean();

  const [, resetParams] = useSetSearchParams();

  const onOpenRegisterBoligMappa = useCallback(() => {
    track(ANEventSpace(ANEvent.Opened, ANObject.SignUp, ANPage.BoligMappa));
    openRegisterBoligMappaModal();
  }, [openRegisterBoligMappaModal, track]);

  const onBoligMappaConnect = useCallback(() => {
    track(ANEventSpace(ANEvent.Connected, ANObject.Account, ANPage.BoligMappa));
    authRedirect(boligMappaRedirectUrl);
  }, [authRedirect, boligMappaRedirectUrl, track]);

  const onBoligMappaDisConnect = useCallback(() => {
    if (!isAuthorised) {
      return;
    }

    track(
      ANEventSpace(ANEvent.Disconnected, ANObject.Account, ANPage.BoligMappa)
    );
    unlinkBoligMappa();
  }, [isAuthorised, unlinkBoligMappa, track]);

  useEffect(() => {
    if (isAuthorised) {
      return;
    }

    if (!urlParams?.code) {
      return;
    }

    if (isLoading) {
      return;
    }
    authoriseBoligMappa({
      code: urlParams.code[0],
      redirectUrl: boligMappaRedirectUrl,
    }).then(() => {
      resetParams();
    });
  }, [
    authoriseBoligMappa,
    boligMappaRedirectUrl,
    isAuthorised,
    isLoading,
    resetParams,
    urlParams?.code,
  ]);
  return (
    <Div>
      {isAuthorised ? (
        <Div>
          {!isLoading && (
            <Text.p mb={6} color="green.base">
              {t('settings.integrations.boligmappa.connected')}
            </Text.p>
          )}
          <Button
            size="tiny"
            type="button"
            variant="secondary"
            onClick={onBoligMappaDisConnect}
            isLoading={isLoading}
          >
            {t('settings.integrations.boligmappa.disconnect')}
          </Button>
        </Div>
      ) : (
        <Div>
          {!isLoading && (
            <Text.p mb={6} color="red.base">
              {t('settings.integrations.boligmappa.notConnected')}
            </Text.p>
          )}
          <Div flexDirection="row" display="flex" justifyContent="start">
            <Button
              size="tiny"
              type="button"
              onClick={onBoligMappaConnect}
              variant="secondary"
              mr={4}
            >
              {t('settings.integrations.boligmappa.connect')}
            </Button>
            <Button
              size="tiny"
              type="button"
              onClick={onOpenRegisterBoligMappa}
              variant="primary"
            >
              {t('settings.integrations.boligmappa.register.register')}
            </Button>
          </Div>
        </Div>
      )}
      {registerBoligMappaModalVisible && (
        <RegisterBoligmappaModal
          show={registerBoligMappaModalVisible}
          onClose={hideRegisterBoligMappaModal}
        />
      )}
    </Div>
  );
};
