import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MutationFunctionOptions, useMutation, useQuery } from '@apollo/client';
import FullScreenLoader from '@components/elements/FullScreenLoader/FullScreenLoader';
import { useUserContext } from '@contexts/UserContext';
import log from '@internals/business-shared/src/utils/devLog';
import {
  PROFILE_PICTURES_QUERY_businessAlbums,
  PROFILE_PICTURES_QUERY_businessAlbums_images,
  RotateBusinessAlbumImageInput,
  RotateImageDegrees,
  RotateImageDirection,
  UploadBusinessAlbumImage,
} from '@internals/business-shared/src/utils/generated/generated';
import {
  CREATE_BUSINESS_ALBUM,
  CreateBusinessAlbumPayload,
  CreateBusinessAlbumVariables,
  isCreateBusinessAlbumSuccessResponse,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbum/CreateBusinessAlbum';
import {
  DELETE_BUSINESS_ALBUM,
  DeleteBusinessAlbumPayload,
  DeleteBusinessAlbumVariables,
  isDeleteBusinessAlbumSuccessResponse,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbum/DeleteBusinessAlbum';
import {
  isUpdateBusinessAlbumSuccessResponse,
  UPDATE_BUSINESS_ALBUM,
  UpdateBusinessAlbumPayload,
  UpdateBusinessAlbumVariables,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbum/UpdateBusinessAlbum';
import {
  DELETE_BUSINESS_ALBUM_IMAGE,
  DeleteBusinessAlbumImagePayload,
  DeleteBusinessAlbumImageVariables,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbumImage/DeleteBusinessAlbumImage';
import {
  isUpdateBusinessAlbumImageSuccessResponse,
  UPDATE_BUSINESS_ALBUM_IMAGE,
  UpdateBusinessAlbumImagePayload,
  UpdateBusinessAlbumImageVariables,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbumImage/UpdateBusinessAlbumImage';
import {
  isUploadBusinessAlbumImagesSuccessResponse,
  UPLOAD_BUSINESS_ALBUM_IMAGES,
  UploadBusinessAlbumImagesPayload,
  UploadBusinessAlbumImagesVariables,
} from '@internals/business-shared/src/utils/mutation/BusinessAlbumImage/UploadBusinessAlbumImages';
import { Omit } from '@internals/business-shared/src/utils/Omit';
import {
  PROFILE_PICTURES_QUERY,
  ProfilePicturesQueryPayload,
  ProfilePicturesQueryVariables,
} from '@internals/business-shared/src/utils/query/ProfilePictures/ProfilePicturesQuery';
import { FilesDetails } from '@schibsted-smb/fireball';
import ToastMessage from '@utils/ToastMessage';

export type DropzoneFileType = FilesDetails & {
  file?: File;
  modified: boolean;
};
export type UploadFileType = {
  albumId: AlbumContextType['id'];
  images: {
    image: DropzoneFileType['file'];
    description: DropzoneFileType['description'];
    rotate?: RotateBusinessAlbumImageInput;
    modified: boolean;
  }[];
}; // same as UploadBusinessAlbumImagesInput but more specific

export type AlbumImageContextType =
  PROFILE_PICTURES_QUERY_businessAlbums_images & {
    selected: boolean;
  };
export type AlbumContextType = Omit<
  PROFILE_PICTURES_QUERY_businessAlbums,
  'images'
> & {
  selected: boolean;
  images: AlbumImageContextType[];
};

export type RotateProperty =
  | {
      rotate: {
        degrees: RotateBusinessAlbumImageInput['degrees'];
        direction: RotateImageDirection;
      };
    }
  | Record<string, never>;

interface ProfilePicturesContextValue {
  isError: boolean;
  albums: AlbumContextType[]; // decorated albums and images
  countAllPhotos: number;
  getContextAlbum: (albumId: AlbumContextType['id']) => AlbumContextType | null;
  deleteAlbumImages: (photos: AlbumContextType['images']) => Promise<boolean>;
  uploadAlbumImages: (
    input: UploadFileType,
    options?: MutationFunctionOptions<
      UploadBusinessAlbumImagesPayload,
      UploadBusinessAlbumImagesVariables
    >
  ) => Promise<UploadBusinessAlbumImagesPayload>;
  uploadAlbumImage: (
    albumId: AlbumContextType['id'],
    image: File,
    description: AlbumImageContextType['description']
  ) => Promise<UploadBusinessAlbumImagesPayload>;

  updateAlbumImages: (
    albumId: AlbumContextType['id'],
    images: {
      albumImageId: AlbumImageContextType['id'];
      description: AlbumImageContextType['description'];
      rotate: number;
      modified: boolean;
    }[]
  ) => Promise<boolean>;

  toggleAlbumImage: (
    photo: AlbumImageContextType,
    selected: AlbumImageContextType['selected']
  ) => void;
  toggleAlbum: (
    albumId: AlbumContextType['id'],
    selected: AlbumContextType['selected']
  ) => void;
  createAlbum: (
    name: AlbumContextType['name']
  ) => Promise<CreateBusinessAlbumPayload>;
  updateAlbum: (
    albumId: AlbumContextType['name'],
    name: AlbumContextType['name']
  ) => Promise<UpdateBusinessAlbumPayload>;
  deleteAlbum: (
    albumId: AlbumContextType['id']
  ) => Promise<DeleteBusinessAlbumPayload>;
  unselectAllAlbums: () => void;
  rotateProperty: (rotate: number) => RotateProperty;
  getAlbumCover: (selectedAlbum: AlbumContextType) => string | null;
}

export const ALBUM_NAME_MAX_LENGTH = 255;
export const ALL_IMAGES_ALBUM_ID = 'ALL';
export const ProfilePicturesContext =
  createContext<ProfilePicturesContextValue>({
    isError: false,
    albums: [],
    countAllPhotos: 0,
    getContextAlbum: () => null,
    deleteAlbumImages: () => new Promise<boolean>(() => null),
    uploadAlbumImages: () =>
      new Promise<UploadBusinessAlbumImagesPayload>(() => null),
    uploadAlbumImage: () =>
      new Promise<UploadBusinessAlbumImagesPayload>(() => null),
    updateAlbumImages: () => new Promise<boolean>(() => null),
    toggleAlbumImage: () => null,
    toggleAlbum: () => null,
    createAlbum: () => new Promise<CreateBusinessAlbumPayload>(() => null),
    updateAlbum: () => new Promise<UpdateBusinessAlbumPayload>(() => null),
    deleteAlbum: () => new Promise<DeleteBusinessAlbumPayload>(() => null),
    unselectAllAlbums: () => null,
    rotateProperty: () => ({}),
    getAlbumCover: () => null,
  });

const ProfilePicturesProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [albums, setAlbums] = useState<AlbumContextType[]>([]);
  const countAllPhotos = useMemo(
    () =>
      albums.reduce(
        (previousValue, currentValue) =>
          previousValue + currentValue.images.length,
        0
      ),
    [albums]
  );
  const user = useUserContext();

  const [deleteBusinessAlbumImage] = useMutation<
    DeleteBusinessAlbumImagePayload,
    DeleteBusinessAlbumImageVariables
  >(DELETE_BUSINESS_ALBUM_IMAGE);
  const [uploadBusinessAlbumImages] = useMutation<
    UploadBusinessAlbumImagesPayload,
    UploadBusinessAlbumImagesVariables
  >(UPLOAD_BUSINESS_ALBUM_IMAGES, {
    refetchQueries: [PROFILE_PICTURES_QUERY],
  });
  const [updateBusinessAlbumImage] = useMutation<
    UpdateBusinessAlbumImagePayload,
    UpdateBusinessAlbumImageVariables
  >(UPDATE_BUSINESS_ALBUM_IMAGE);
  const [createBusinessAlbum] = useMutation<
    CreateBusinessAlbumPayload,
    CreateBusinessAlbumVariables
  >(CREATE_BUSINESS_ALBUM, {
    refetchQueries: [PROFILE_PICTURES_QUERY],
  });
  const [updateBusinessAlbum] = useMutation<
    UpdateBusinessAlbumPayload,
    UpdateBusinessAlbumVariables
  >(UPDATE_BUSINESS_ALBUM, {
    refetchQueries: [PROFILE_PICTURES_QUERY],
  });
  const [deleteBusinessAlbum] = useMutation<
    DeleteBusinessAlbumPayload,
    DeleteBusinessAlbumVariables
  >(DELETE_BUSINESS_ALBUM, {
    refetchQueries: [PROFILE_PICTURES_QUERY],
  });
  const { loading, data, error } = useQuery<
    ProfilePicturesQueryPayload,
    ProfilePicturesQueryVariables
  >(PROFILE_PICTURES_QUERY, {
    variables: { id: user?.operatingAs?.id ?? '' },
    skip: !user,
  });

  const degrees2Constant = (
    rotateDegrees: number
  ): RotateBusinessAlbumImageInput['degrees'] => {
    switch (rotateDegrees) {
      case 0:
        return null;
      case 90:
        return RotateImageDegrees.NINETY;
      case 180:
        return RotateImageDegrees.HUNDRED_EIGHTY;
      case 270:
        return RotateImageDegrees.TWO_HUNDRED_SEVENTY;
      default:
        return null;
    }
  };
  const rotateProperty = (rotate: number): RotateProperty => {
    const degrees = degrees2Constant(rotate);
    if (degrees === null) {
      return {};
    }

    return {
      rotate: {
        degrees,
        direction: RotateImageDirection.RIGHT,
      },
    };
  };

  const getContextAlbum = (
    albumId: AlbumContextType['id']
  ): AlbumContextType => {
    if (albumId === ALL_IMAGES_ALBUM_ID) {
      return {
        id: ALL_IMAGES_ALBUM_ID,
        selected: false,
        __typename: 'Album',
        name: 'All images',
        images: albums.reduce<AlbumContextType['images']>(
          (previousValue, currentValue) =>
            previousValue.concat(currentValue.images),
          []
        ),
      };
    }
    return albums.filter((album) => album.id === albumId)?.[0] ?? null;
  };

  const deleteAlbumImages = (
    photos: AlbumContextType['images']
  ): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      const imagesId = photos.map((image) => image.id);
      const deletes = photos.map(async (image) => {
        return deleteBusinessAlbumImage({
          variables: {
            input: {
              albumId: image.album.id,
              albumImageId: image.id,
            },
          },
        });
      });
      Promise.all(deletes)
        .then((response) => {
          const success = response.every(
            (r) => r.data?.deleteBusinessAlbumImage?.success
          );

          setAlbums((prevAlbums) =>
            prevAlbums.map((prevAlbum) => {
              const filteredImages = prevAlbum.images.filter(
                (image) => !imagesId.includes(image.id)
              );
              return { ...prevAlbum, images: filteredImages };
            })
          );

          // we could have a bit better behaviour here
          resolve(success);
        })
        .catch(reject);
    });

  const uploadAlbumImages = (
    input: UploadFileType,
    options?: MutationFunctionOptions<
      UploadBusinessAlbumImagesPayload,
      UploadBusinessAlbumImagesVariables
    >
  ): Promise<UploadBusinessAlbumImagesPayload> =>
    new Promise<UploadBusinessAlbumImagesPayload>((resolve, reject) => {
      const mutationOptions = {
        variables: {
          input: {
            ...input,
            albumId: input.albumId,
            images: input.images.map(
              (i): UploadBusinessAlbumImage =>
                Omit('modified', i) as UploadBusinessAlbumImage
            ),
          },
        },
        ...(options ?? {}),
      };

      uploadBusinessAlbumImages(mutationOptions)
        .then((result) => {
          const resultData = result?.data;

          if (!isUploadBusinessAlbumImagesSuccessResponse(resultData)) {
            reject(resultData);
            return;
          }

          setAlbums((prevAlbums) =>
            prevAlbums.map((prevAlbum) => {
              if (prevAlbum.id !== input.albumId) {
                return prevAlbum;
              }
              return {
                ...prevAlbum,
                images: [
                  ...prevAlbum.images,
                  ...resultData.uploadBusinessAlbumImages.albumImages.map(
                    (i): AlbumImageContextType =>
                      ({
                        ...i,
                        selected: false,
                        album: { id: input.albumId },
                      } as AlbumImageContextType)
                  ),
                ],
              };
            })
          );

          resolve(resultData);
        })
        .catch(reject);
    });

  const uploadAlbumImage = (
    albumId: AlbumContextType['id'],
    image: File,
    description: AlbumImageContextType['description']
  ): Promise<UploadBusinessAlbumImagesPayload> =>
    new Promise<UploadBusinessAlbumImagesPayload>((resolve, reject) => {
      uploadBusinessAlbumImages({
        variables: { input: { albumId, images: [{ image, description }] } },
      })
        .then((result) => {
          if (!isUploadBusinessAlbumImagesSuccessResponse(result.data)) {
            reject(result.data);
            return;
          }

          setAlbums((prevAlbums) =>
            prevAlbums.map((prevAlbum) => {
              if (prevAlbum.id !== albumId) {
                return prevAlbum;
              }
              return {
                ...prevAlbum,
                images: [
                  ...prevAlbum.images,
                  {
                    ...result.data?.uploadBusinessAlbumImages.albumImages?.[0],
                    selected: false,
                    album: { id: albumId },
                  } as AlbumImageContextType,
                ],
              };
            })
          );

          resolve(result.data);
        })
        .catch(reject);
    });

  const updateAlbumImages = (
    albumId: AlbumContextType['id'],
    images: {
      albumImageId: AlbumImageContextType['id'];
      description: AlbumImageContextType['description'];
      rotate: number;
      modified: boolean;
    }[]
  ): Promise<boolean> =>
    new Promise<boolean>((resolve, reject) => {
      const updatePromises = images
        .filter((image) => image.modified)
        .map(async (image) =>
          updateBusinessAlbumImage({
            variables: {
              input: {
                albumId,
                albumImageId: image.albumImageId,
                description: image.description,
                ...rotateProperty(image.rotate),
              },
            },
          })
        );

      Promise.all(updatePromises)
        .then((response) => {
          const success = response.every((r) =>
            isUpdateBusinessAlbumImageSuccessResponse(r.data)
          );

          if (!success) {
            reject(success);
            return;
          }

          const updateTheseImages = images.map((image) => image.albumImageId);

          setAlbums((prevAlbums) =>
            prevAlbums.map((prevAlbum) => {
              if (prevAlbum.id !== albumId) {
                return prevAlbum;
              }

              return {
                ...prevAlbum,
                images: prevAlbum.images
                  // If something is not in the dropzone, we should remove it
                  .filter((prevImage) =>
                    updateTheseImages.includes(prevImage.id)
                  )
                  .map((prevImage) => {
                    const imageDescription =
                      images.find((i) => i.albumImageId === prevImage.id)
                        ?.description ?? '';
                    return {
                      ...prevImage,
                      modified: false,
                      description: imageDescription,
                    };
                  }),
              };
            })
          );

          resolve(success);
        })
        .catch(reject);
    });

  const toggleAlbumImage = (
    photo: AlbumImageContextType,
    selected: AlbumImageContextType['selected']
  ) => {
    // update state
    setAlbums((prevAlbums) =>
      prevAlbums.map((prevAlbum) => {
        if (prevAlbum.id !== photo.album.id) {
          return prevAlbum;
        }
        return {
          ...prevAlbum,
          images: prevAlbum.images.map((prevImage) => {
            if (prevImage.id !== photo.id) {
              return prevImage;
            }
            return { ...prevImage, selected };
          }),
        };
      })
    );
  };

  const toggleAlbum = (
    albumId: AlbumContextType['id'],
    selected: AlbumContextType['selected']
  ) => {
    setAlbums((prevAlbums) =>
      prevAlbums.map((prevAlbum) => {
        return prevAlbum.id !== albumId
          ? {
              ...prevAlbum,
              selected: false, // Temporary way of doing is to allow to select just one album
            }
          : {
              ...prevAlbum,
              selected,
            };
      })
    );
  };

  const createAlbum = (name: AlbumContextType['name']) =>
    new Promise<CreateBusinessAlbumPayload>((resolve, reject) => {
      createBusinessAlbum({ variables: { input: { name } } })
        .then((response) => {
          if (!isCreateBusinessAlbumSuccessResponse(response.data)) {
            reject(response.data);
            return;
          }

          const newAlbum = response.data.createBusinessAlbum.album;
          setAlbums((prevAlbums) => [
            ...prevAlbums,
            { ...newAlbum, images: [], selected: false },
          ]);
          resolve(response.data);
        })
        .catch(reject);
    });

  const updateAlbum = (
    albumId: AlbumContextType['name'],
    name: AlbumContextType['name']
  ) =>
    new Promise<UpdateBusinessAlbumPayload>((resolve, reject) => {
      if (albumId === ALL_IMAGES_ALBUM_ID) {
        reject(new Error('Cannot rename all images album'));
        return;
      }

      updateBusinessAlbum({ variables: { input: { albumId, name } } })
        .then((response) => {
          if (!isUpdateBusinessAlbumSuccessResponse(response.data)) {
            reject(response.data);
            return;
          }
          const updatedAlbum = response.data.updateBusinessAlbum.album;

          // optimistic ui
          setAlbums((prevAlbums) => {
            return prevAlbums.map((prevAlbum) => {
              if (prevAlbum.id !== updatedAlbum.id) {
                return prevAlbum;
              }
              return {
                ...prevAlbum,
                name: updatedAlbum.name,
              };
            });
          });
          resolve(response.data);
        })
        .catch(reject);
    });

  const deleteAlbum = (albumId: AlbumContextType['id']) =>
    new Promise<DeleteBusinessAlbumPayload>((resolve, reject) => {
      if (albumId === ALL_IMAGES_ALBUM_ID) {
        reject(new Error('Cannot delete all images album'));
        return;
      }

      deleteBusinessAlbum({ variables: { input: { albumId } } })
        .then((response) => {
          if (!isDeleteBusinessAlbumSuccessResponse(response.data)) {
            reject(response.data);
            return;
          }
          // optimistic ui
          setAlbums((prevAlbums) => prevAlbums.filter((a) => a.id !== albumId));
          resolve(response.data);
        })
        .catch(reject);
    });

  const unselectAllAlbums = () => {
    setAlbums((prevAlbums) =>
      prevAlbums.map((prevAlbum) => ({ ...prevAlbum, selected: false }))
    );
  };

  const getAlbumCover = (selectedAlbum: AlbumContextType) => {
    if (!selectedAlbum) {
      return null;
    }
    return selectedAlbum.images?.[0]?.image.smallPreviewUrl ?? null;
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    if (albums.length) {
      // we don't want to refresh data if already have some
      return;
    }

    setAlbums(
      data.businessAlbums.map<AlbumContextType>(
        (album): AlbumContextType => ({
          ...album,
          selected: false,
          images: album.images.map<AlbumImageContextType>((image) => ({
            ...image,
            selected: false,
          })),
        })
      )
    );
  }, [data?.businessAlbums]);

  if (error) {
    log.error('PP context error: ', error);
  }

  if (loading) {
    return <FullScreenLoader />;
  }

  if (!user) {
    return null;
  }

  return (
    <ProfilePicturesContext.Provider
      value={{
        isError: !!error,
        albums,
        countAllPhotos,
        getContextAlbum,
        deleteAlbumImages,
        uploadAlbumImages,
        uploadAlbumImage,
        updateAlbumImages,
        toggleAlbumImage,
        toggleAlbum,
        createAlbum,
        updateAlbum,
        deleteAlbum,
        unselectAllAlbums,
        rotateProperty,
        getAlbumCover,
      }}
    >
      {children}
    </ProfilePicturesContext.Provider>
  );
};

export default ProfilePicturesProvider;
