import { ChatListType } from '@internals/business-shared/src/utils/generated/generated';
import { t } from '@utils/i18n';

export type ChatListTypeFilter = {
  id: ChatListType;
  title: string;
};

export const useChatListFilters = (): ChatListTypeFilter[] => {
  const filters: (ChatListTypeFilter & { enabled: boolean })[] = [
    {
      id: ChatListType.ACTIVE,
      title: t('chat.list.filter.inbox'),
      enabled: true,
    },
    {
      id: ChatListType.UNREAD,
      title: t('chat.list.filter.unread'),
      enabled: true,
    },
    {
      id: ChatListType.WON,
      title: t('job.label.WON'),
      enabled: true,
    },
    {
      id: ChatListType.INACTIVE,
      title: t('chat.list.filter.archive'),
      enabled: true,
    },
  ];

  return filters.filter((f) => f.enabled);
};
