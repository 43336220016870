import { Div, DivProps } from '@schibsted-smb/fireball';
import styled from 'styled-components';

interface MessageLoaderProps extends DivProps {
  highTransparency?: boolean;
}

// it's just an example animation, to be consulted with designers
const MessageLoader = styled(Div)<MessageLoaderProps>`
  @keyframes loading {
    to {
      transform: translateX(100%);
    }
  }
  transform: translateX(-100%);
  background-image: ${({ highTransparency }) =>
    highTransparency
      ? `linear-gradient(90deg, rgba(238, 238, 238, 0) 0, rgba(238, 238, 238, 0.3) 20%, rgba(238, 238, 238, 0.6) 60%, rgba(238, 238, 238, 0))`
      : `linear-gradient(90deg, rgba(238, 238, 238, 0) 0, rgba(238, 238, 238, 0.1) 20%, rgba(238, 238, 238, 0.3) 60%, rgba(238, 238, 238, 0))`};
  animation: loading 2s infinite;
  cursor: not-allowed;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

export default MessageLoader;
