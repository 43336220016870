import {
  CompanyStateAlertType,
  StateType,
  UnpaidOrderType,
} from './getCompanyState';

export const disabledClipsAlertStates: CompanyStateAlertType[] = [
  StateType.AccessUnverified,
  StateType.AccessVerifiedLacksProducts,
  StateType.LacksSubscriptions,
  UnpaidOrderType.OrderUnpaidInvoiceNotStarted,
  UnpaidOrderType.OrderUnpaidCardNotStarted,
];
