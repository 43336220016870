import { ApolloCache, makeReference } from '@apollo/client';

import { JOBS_SAVED_FILTER_FRAGMENT } from '../../utils/fragments/JobsSavedFilterFragment';
import { CREATE_JOBS_SAVED_FILTER_MUTATION } from '../../utils/generated/generated';
import { CreateJobsSavedFilterMutationPayload } from '../../utils/mutation/CreateJobsSavedFilter/CreateJobsSavedFilterMutation';

export const createJobsSavedFilterUpdate = (
  cache: ApolloCache<CREATE_JOBS_SAVED_FILTER_MUTATION>,
  { data }: { data?: CreateJobsSavedFilterMutationPayload | null }
) => {
  if (!data) return;

  const newFilterRef = cache.writeFragment({
    fragment: JOBS_SAVED_FILTER_FRAGMENT,
    fragmentName: 'JobsSavedFilterFragment',
    data: data.createJobWatchlist,
  });

  cache.modify({
    id: cache.identify(makeReference('ROOT_QUERY')),
    fields: {
      jobWatchlistList(existing = []) {
        return [...existing, newFilterRef];
      },
    },
  });
};
