import React from 'react';

export const useIsIntercomAvailable = (): boolean => {
  const [intercomAvailable, setIntercomAvailable] = React.useState(
    !!window.Intercom
  );

  React.useEffect(() => {
    const checkIntercomAvailability = () => {
      if (window.Intercom) {
        setIntercomAvailable(true);
      }
    };
    checkIntercomAvailability();
    const intervalId = setInterval(checkIntercomAvailability, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return intercomAvailable;
};
