import gql from 'graphql-tag';

import {
  REQUEST_SECURE_ID_AUTHENTICATION_URL as REQUEST_SECURE_ID_AUTHENTICATION_URLPayload,
  REQUEST_SECURE_ID_AUTHENTICATION_URLVariables,
} from '../../generated/generated';

export type RequestSecureIdAuthenticationUrlPayload =
  REQUEST_SECURE_ID_AUTHENTICATION_URLPayload;
export type RequestSecureIdAuthenticationUrlVariables =
  REQUEST_SECURE_ID_AUTHENTICATION_URLVariables;

export const REQUEST_SECURE_ID_AUTHENTICATION_URL = gql`
  mutation REQUEST_SECURE_ID_AUTHENTICATION_URL(
    $input: SecureIdAuthenticationUrlInput!
  ) {
    requestSecureIdAuthenticationUrl(input: $input) {
      id
      authenticationUrl
    }
  }
`;
