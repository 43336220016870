import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import { SortDirection } from '../../utils/generated/generated';
import { isOptimisticId } from '../../utils/optimistic/id';
import {
  CHAT_FIRST_MESSAGE_QUERY,
  ChatFirstMessage,
  ChatFirstMessageQueryPayload,
  ChatFirstMessageQueryVariables,
} from '../../utils/query/ChatFirstMessage/ChatFirstMessageQuery';

interface ChatFirstMessageQueryResult extends QueryResult {
  data: ChatFirstMessage | null;
}

export const useChatFirstMessageQuery = (
  chatId: string,
  options?: QueryHookOptions<
    ChatFirstMessageQueryPayload,
    ChatFirstMessageQueryVariables
  >
): ChatFirstMessageQueryResult => {
  const { data, ...rest } = useQuery<
    ChatFirstMessageQueryPayload,
    ChatFirstMessageQueryVariables
  >(CHAT_FIRST_MESSAGE_QUERY, {
    variables: { chatId, first: 1, from: 0, orderByTime: SortDirection.ASC },
    fetchPolicy: 'no-cache',
    skip: !chatId || isOptimisticId(chatId),
    ...(options || {}),
  });

  return useMemo(
    () => ({
      data: data?.chatMessages.edges[0]?.node || null,
      ...rest,
    }),
    [data, rest]
  );
};
