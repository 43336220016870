import styled from 'styled-components';

const SectionIconContainer = styled.div`
  display: block;
  width: 32px;
  height: 32px;
  padding: 0;
  margin: 0 24px 0 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[5]}) {
    margin: 0 24px 0 -56px;
  }
`;

export default SectionIconContainer;
