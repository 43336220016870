// todo: it's just a temporary place to showcase product components, to be removed after proper implementation
import { FC, ReactElement } from 'react';

import { ProductAddonCard } from '@components/layout/Products/ProductAddonCard';
import { ProductAddonCardExtended } from '@components/layout/Products/ProductAddonCardExtended';
import { ProductAlreadySubscribedBadge } from '@components/layout/Products/ProductAlreadySubscribedBadge';
import { ProductHeader } from '@components/layout/Products/ProductHeader';
import { ProductInfoListAccordion } from '@components/layout/Products/ProductInfoListAccordion';
import { ProductOption } from '@components/layout/Products/ProductOption';
import { ProductPackageAccordion } from '@components/layout/Products/ProductPackageAccordion';
import { ProductPackageCard } from '@components/layout/Products/ProductPackageCard';
import { ProductRewardCard } from '@components/layout/Products/ProductRewardCard';
import { ProductSuccessfulPurchase } from '@components/layout/Products/ProductSuccessfulPurchase';
import { ProductSummary } from '@components/layout/Products/ProductSummary';
import { ProductVerification } from '@components/layout/Products/ProductVerification';
import { ProductVerificationFailed } from '@components/layout/Products/ProductVerificationFailed';
import { StyledProductSectionTitle } from '@components/layout/Products/styled';
import {
  ProductPackageStatus,
  ProductTimeUnit,
} from '@internals/business-shared/src/components/Products/productTypes';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div } from '@schibsted-smb/fireball';

const ComponentWrapper: FC<{ name: string; children: ReactElement }> = ({
  name,
  children,
}) => (
  <Div my={7} p={5} border="1px solid black">
    <Div mb={6} fontSize={2}>
      <code>{name}</code>
    </Div>
    {children}
  </Div>
);

export const ProductComponents: FC = () => {
  const renewalDateTimestamp = new Date('12.12.2024').getTime() / 1000;
  return (
    <Div backgroundColor="black.black0" py={7} px={5}>
      <ComponentWrapper name="ProductHeader">
        <ProductHeader
          title="Products"
          subtitle="See all the products we offer at Mittanbud"
        />
      </ComponentWrapper>
      <ComponentWrapper name="StyledProductSectionTitle">
        <StyledProductSectionTitle>Your package</StyledProductSectionTitle>
      </ComponentWrapper>
      <ComponentWrapper name="ProductPackage | with-icon">
        <ProductPackageCard
          title="Try Mittanbud for free"
          subtitle="Verify you business and get 30 days free access to Mittanbud."
          action={{ text: 'Verify company', handler: () => {} }}
          variant="with-icon"
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductPackage | centered">
        <ProductPackageCard
          title="Start free test period"
          subtitle="Get 30 days free access to Mittanbud."
          action={{ text: 'Start now', handler: () => {} }}
          variant="centered"
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductRewardCard">
        <ProductRewardCard
          title="Mittanbud Rewards"
          subtitle="Here you can find all the benefits you have access to as a Mittanbud customer"
          action={{ text: 'Claim your benefits', handler: () => {} }}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductAddonCard">
        <ProductAddonCard
          title="Promoted locally"
          subtitle="Get increased visability in search and more direct jobs"
          onClick={() => {}}
          status={ProductPackageStatus.Active}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductPackageAccordion">
        <ProductPackageAccordion
          price={{ currency: 'NOK', cost: 100, period: ProductTimeUnit.Month }}
          numberOfCredits={10}
          packageName="Job package"
          timeDetails={{
            type: 'renewal',
            date: renewalDateTimestamp,
          }}
          status={ProductPackageStatus.TestPeriod}
          description="The job package gives you access to view all jobs within your area and industry. You have {number_of_clips} clips that allows you to answer jobs. You also get your company's own Profile Page that gives you good visibility on Mittanbud and Google, and can answer up to 30 clip free jobs."
          onMoreClick={() => {}}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductOption | isSelected=true">
        <ProductOption
          value={10}
          valueText="extra credits/mth"
          isSelected
          extraText="+ 1 000 kr per. month"
          onClick={() => {}}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductOption | isSelected=false">
        <ProductOption
          value={20}
          valueText="extra credits/mth"
          isSelected={false}
          extraText="+ 2 000 kr per. month"
          onClick={() => {}}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductSummary">
        <ProductSummary
          data={[
            { name: 'Credits included', value: '50' },
            { name: 'Price', value: '2 100,-' },
            { name: 'Duration', value: '12 months' },
            { name: 'Renewal date', value: '12. des. 2024' },
          ]}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductSuccessfulPurchase">
        <ProductSuccessfulPurchase
          title="Subscription upgraded!"
          subtitle="Great, your new package is now active."
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductAddonCardExtended">
        <ProductAddonCardExtended
          title="Promoted locally"
          subtitle="Get increased visability in search and increase your chances of getting jobs"
          onClick={() => {}}
          icon={IconPickerName.Search}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductAlreadySubscribedBadge">
        <ProductAlreadySubscribedBadge />
      </ComponentWrapper>
      <ComponentWrapper name="ProductInfoListAccordion">
        <ProductInfoListAccordion
          title="Requirement for Promoted locally"
          infoItems={[
            {
              icon: IconPickerName.Person,
              text: 'Active subscription for full period',
            },
            {
              icon: IconPickerName.Star,
              text: '< 15 job reviews',
            },
            {
              icon: IconPickerName.Sparkle,
              text: 'Average evaluation score < 4.5',
            },
          ]}
        />
      </ComponentWrapper>
      <ComponentWrapper name="ProductVerification">
        <ProductVerification />
      </ComponentWrapper>
      <ComponentWrapper name="ProductVerificationFailed">
        <ProductVerificationFailed
          reason="You have under 15 reviews and therefore cannot buy Promoted locally."
          onClose={() => {}}
        />
      </ComponentWrapper>
    </Div>
  );
};
