import { Layer } from '@utils/layer';
import styled from 'styled-components';

export const MOBILE_HEADER_HEIGHT = '60px';

const Header = styled.header`
  position: fixed;
  top: 0;
  z-index: ${Layer.fixed};
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: ${({ theme }) => theme.space[4]}px;
  justify-items: start;
  width: 100%;
  height: ${MOBILE_HEADER_HEIGHT};
  background-color: ${({ theme }) => theme.background.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black.black4};
`;

export default Header;
