import * as React from 'react';

import { Div, Spinner } from '@schibsted-smb/fireball';

interface FullScreenLoaderProps {
  specificTestId?: string;
}

const FullScreenLoader: React.FC<
  React.PropsWithChildren<FullScreenLoaderProps>
> = ({ specificTestId = 'page-loader' }) => (
  <Div
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100dvh"
    data-testid={specificTestId}
  >
    <Spinner />
  </Div>
);

export default FullScreenLoader;
