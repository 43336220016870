import { SimpleAgreementContractStatus } from '../utils/generated/generated';
import { SimpleContract } from '../utils/query/SimpleContract/SimpleContractQuery';
import { throwContractIsRequired } from '../utils/SimpleContract';
import { EnumAsUnion } from '../utils/types/utilsTypes';

// IT will produce this:  "CREATED" | "SEE_FLAGS" | "SHARED" | "STOPPED" | "SIGNED_CONSUMER" | "SIGNED_BOTH" | "SIGNED_BUSINESS" | "LOADING" | "EDIT"
export type SimpleContractModalKind =
  | EnumAsUnion<typeof SimpleAgreementContractStatus>
  | 'ITEM_LOADING'
  | 'EDIT';
export type SimpleContractModalType = Record<
  SimpleContractModalKind,
  { visible: boolean }
>;
export type SimpleContractModalState = {
  contract: SimpleContract | null;
  modal: SimpleContractModalType;
};
export type SimpleContractModalActionType =
  | SimpleContractModalKind
  | 'INIT'
  | 'RESET';
export type SimpleContractModalAction = {
  type: SimpleContractModalActionType;
  payload?: {
    contract: SimpleContract;
  };
};

export const SimpleContractModalDefaultValue: SimpleContractModalState = {
  contract: null,
  modal: {
    ITEM_LOADING: { visible: false },
    EDIT: { visible: false },
    CREATED: { visible: false },
    SEE_FLAGS: { visible: false },
    SHARED: { visible: false },
    STOPPED: { visible: false },
    SIGNED_CONSUMER: { visible: false },
    SIGNED_BOTH: { visible: false },
    SIGNED_BUSINESS: { visible: false },
  },
};

const setModalVisible = (
  kind: SimpleContractModalKind
): SimpleContractModalState['modal'] => ({
  ...SimpleContractModalDefaultValue.modal,
  [kind]: { visible: true },
});

export const SimpleContractModalReducer = (
  state: SimpleContractModalState,
  action: SimpleContractModalAction
): SimpleContractModalState => {
  switch (action.type) {
    case 'INIT':
      if (!action.payload?.contract) {
        throwContractIsRequired();
      }
      return {
        ...state,
        modal: setModalVisible(action.payload.contract.statusEnum),
        contract: action.payload.contract,
      };
    case 'RESET':
      return {
        ...state,
        modal: { ...SimpleContractModalDefaultValue.modal },
        contract: null,
      };
    case 'ITEM_LOADING':
      return {
        ...state,
        modal: setModalVisible('ITEM_LOADING'),
        contract: null,
      };
    case 'EDIT':
      if (!action.payload?.contract) {
        throwContractIsRequired();
      }
      return {
        ...state,
        modal: setModalVisible('EDIT'),
        contract: action.payload.contract,
      };

    case 'SIGNED_BOTH':
      return {
        ...state,
        modal: setModalVisible('SIGNED_BOTH'),
        contract: action.payload.contract,
      };

    case 'SIGNED_BUSINESS':
      return {
        ...state,
        modal: setModalVisible('SIGNED_BUSINESS'),
        contract: action.payload.contract,
      };
    case 'SIGNED_CONSUMER':
      return {
        ...state,
        modal: setModalVisible('SIGNED_CONSUMER'),
        contract: action.payload.contract,
      };
    case 'STOPPED':
      return {
        ...state,
        modal: setModalVisible('STOPPED'),
        contract: action.payload.contract,
      };

    // Unsupported contracts - we don't need to do anything
    case 'CREATED':
    case 'SEE_FLAGS':
    case 'SHARED':
      return { ...state };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
};
