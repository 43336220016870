import gql from 'graphql-tag';

import {
  CREATE_SIMPLE_AGREEMENT_CONTRACT as CREATE_SIMPLE_AGREEMENT_CONTRACT_Result,
  CREATE_SIMPLE_AGREEMENT_CONTRACTVariables,
} from '../../generated/generated';

export type CreateSimpleAgreementContractMutationVariables =
  CREATE_SIMPLE_AGREEMENT_CONTRACTVariables;
export type CreateSimpleAgreementContractMutationPayload =
  CREATE_SIMPLE_AGREEMENT_CONTRACT_Result;

export const isCreateSimpleAgreementContractMutationSuccessResponse = (
  createSimpleAgreementContract?: CREATE_SIMPLE_AGREEMENT_CONTRACT_Result['createSimpleAgreementContract']
): createSimpleAgreementContract is CREATE_SIMPLE_AGREEMENT_CONTRACT_Result['createSimpleAgreementContract'] =>
  !!createSimpleAgreementContract &&
  createSimpleAgreementContract.__typename === 'SimpleAgreementContract';

export const CREATE_SIMPLE_AGREEMENT_CONTRACT = gql`
  mutation CREATE_SIMPLE_AGREEMENT_CONTRACT(
    $input: CreateSimpleAgreementContractInput!
  ) {
    createSimpleAgreementContract(input: $input) {
      id
      endDate
      statusEnum
      statusFlags {
        acceptedBusiness
        acceptedConsumer
        initialized
        invalidated
        rejectedBusiness
        rejectedConsumer
      }
      startDate
      signedUserTime
      signedCompanyTime
      priceType
      price
      description
      businessEmail
      businessPhone
      newAgreement {
        id
      }
      oldAgreement {
        id
      }
      regTime
      type
    }
  }
`;
