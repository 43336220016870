import { Link } from 'react-router-dom';
import styled from 'styled-components';

const JobLink = styled(Link)`
  text-decoration: none;
  width: 100%;
  &:hover {
    text-decoration: none;
  }
`;

export default JobLink;
