import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const Actions = styled(Div)`
  display: flex;
  justify-self: right;

  > *:not(:first-child) {
    margin-left: ${({ theme }) => theme.space[5]}px;
  }
`;

export default Actions;
