import { ApolloCache, makeReference } from '@apollo/client';

import {
  NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled,
  TOGGLE_NOTIFICATION_DESTINATION,
} from '../../utils/generated/generated';
import { ToggleNotificationDestinationPayload } from '../../utils/mutation/ToggleNotificationDestination/ToggleNotificationDestination';
import { BusinessNotificationDestination } from '../../utils/query/BusinessNotificationsAvailable/BusinessNotificationsAvailableQuery';

const toggleNotificationDestinationUpdate = (
  cache: ApolloCache<TOGGLE_NOTIFICATION_DESTINATION>,
  {
    data: mutationPayload,
  }: { data?: ToggleNotificationDestinationPayload | null }
) => {
  if (!mutationPayload) return;
  cache.modify({
    id: cache.identify(makeReference('ROOT_QUERY')),
    fields: {
      notificationDestinationsEnabled(existing) {
        const { toggleNotificationDestination } = mutationPayload;
        if (toggleNotificationDestination.success) {
          return toggleNotificationDestination.destinations;
        }
        return existing;
      },
    },
  });
};

const toggleNotificationDestinationOptimisticResponse = (
  updatedDestination: Pick<BusinessNotificationDestination, 'id' | 'type'>,
  isEnabled: boolean,
  enabledDestinations?: NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled[]
): ToggleNotificationDestinationPayload => {
  let updatedNotifications =
    [] as NOTIFICATION_DESTINATIONS_ENABLED_QUERY_notificationDestinationsEnabled[];
  if (enabledDestinations) {
    if (!isEnabled) {
      updatedNotifications = enabledDestinations.filter(
        (destinationEnabled) => updatedDestination.id !== destinationEnabled.id
      );
    } else {
      updatedNotifications = [
        ...enabledDestinations,
        {
          ...updatedDestination,
          __typename: 'NotificationDestination',
          defaultOn: true,
          isToggleable: true,
          delay: null,
        },
      ];
    }
  }
  return {
    toggleNotificationDestination: {
      __typename: 'ToggleNotificationDestinationPayload',
      success: !!enabledDestinations,
      destinations: updatedNotifications,
    },
  };
};

export {
  toggleNotificationDestinationUpdate,
  toggleNotificationDestinationOptimisticResponse,
};
