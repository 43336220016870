import { FC } from 'react';

import { Div, Heading, Toggle, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

interface SavedFilterNotificationProps {
  type: string;
  text: string;
  title: string;
  value: boolean;
  onChange: VoidFunction;
}

export const SavedFilterNotification: FC<SavedFilterNotificationProps> = ({
  type,
  text,
  title,
  value,
  onChange,
}) => {
  const theme = useTheme();
  return (
    <Div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={5}
      borderBottom={`1px solid ${theme.colors.black.black4}`}
    >
      <Div mr={4}>
        <Heading.h4 fontWeight="medium" textAlign="left">
          {title}
        </Heading.h4>
        <Text.p color={theme.colors.gray.darker}>{text}</Text.p>
      </Div>
      <Toggle
        onChange={onChange}
        name={type}
        checked={value}
        testId={`saved-filter-notification-${type}`}
      />
    </Div>
  );
};
