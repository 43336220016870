import { BasicUserDataFragment } from './generated/generated';
import { ChatDetailsParticipant } from './query/ChatDetails/ChatDetailsQuery';

export interface BasicUser {
  id: string;
  firstName: string | null;
  lastName: string | null;
  isDeleted: boolean | null;
  __typename?: 'User';
}

type Fallback = string | { empty: string; deletedUser: string };

export const getUserName = (
  user?: BasicUser | null,
  fallback: Fallback = ''
): string => {
  if (!user) return '';
  if (user.isDeleted) {
    return typeof fallback === 'string' ? fallback : fallback.deletedUser;
  }
  if (!user.firstName && !user.lastName) {
    return typeof fallback === 'string' ? fallback : fallback.empty;
  }
  return `${user.firstName} ${user.lastName}`;
};

export const getCustomerFromParticipants = (
  participants: ChatDetailsParticipant[]
): BasicUserDataFragment | undefined => {
  if (!participants) return undefined;
  const isCustomer = (
    participant: ChatDetailsParticipant
  ): participant is BasicUserDataFragment => {
    return participant.__typename === 'User';
  };

  const customer = participants.find(isCustomer);
  return customer;
};

export const isMultiAccountUser = (
  user: { isConsumerUser?: boolean; companies?: { id: string }[] } | null
): boolean => {
  if (!user) return false;
  const { companies, isConsumerUser } = user;
  if (companies?.length && !!isConsumerUser) return true;
  return companies?.length > 1;
};
