import { Heading } from '@schibsted-smb/fireball';
import styled from 'styled-components';

export const JobTitle = styled(Heading.h4)`
  font-size: 16px;
  margin: 0;

  text-align: left;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
