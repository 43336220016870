import * as React from 'react';

import { MessageAuthorType } from '@internals/business-shared/src/utils/ChatUtils';
import { Div, Text } from '@schibsted-smb/fireball';
import { dateFromTimestamp } from '@utils/date';
import { useTranslation } from 'react-i18next';

interface MessageBubbleProps {
  type?: MessageAuthorType;
  text: string | null;
  author: string;
  dateTimestamp: number;
}

const getStylesForMessageType = (
  type: MessageAuthorType
): Record<string, Record<string, string>> => {
  switch (type) {
    case MessageAuthorType.Consumer:
      return {
        container: {
          alignItems: 'flex-start',
        },
        textBox: {
          mr: '70px',
          ml: '0',
          borderBottomRightRadius: '10px',
          borderBottomLeftRadius: '0',
        },
      };
    case MessageAuthorType.System:
      return {
        container: {
          alignItems: 'center',
        },
        textBox: {
          mx: 'auto',
          borderRadius: '10px',
        },
      };
    case MessageAuthorType.Business:
    default:
      return {
        container: {
          alignItems: 'flex-end',
        },
        textBox: {
          mr: '0',
          ml: '70px',
          borderBottomRightRadius: '0',
          borderBottomLeftRadius: '10px',
        },
      };
  }
};

const MessageBubble: React.FC<MessageBubbleProps> = ({
  type,
  text,
  author,
  dateTimestamp,
}) => {
  const { t } = useTranslation();

  if (!text) return null;

  const styles = getStylesForMessageType(type);

  return (
    <Div display="block" width="100%">
      <Div display="flex" flexDirection="column" mb={7} {...styles.container}>
        <Div
          px={5}
          py={4}
          mb={4}
          border="1px solid black"
          borderRadius="10px"
          maxWidth="50%"
          {...styles.textBox}
        >
          <Text.p mb={0}>{text}</Text.p>
        </Div>
        <Text.p mb={0}>
          {t('job.item.print.messages.sendFrom')} {author}{' '}
          {dateFromTimestamp(dateTimestamp, 'DD.MM.YY HH:mm')}
        </Text.p>
      </Div>
    </Div>
  );
};

export default MessageBubble;
