import gql from 'graphql-tag';

import COMPANY_PROFILE_FRAGMENT from '../../fragments/CompanyProfileFragment';
import COMPANY_STATE_FRAGMENT from '../../fragments/CompanyStateFragment';
import {
  CURRENT_USER_COMPANY_QUERY as CURRENT_USER_COMPANY_QUERY_Result,
  CURRENT_USER_COMPANY_QUERYVariables,
  CURRENT_USER_COMPANY_QUERY_business_Business,
} from '../../generated/generated';

export type CurrentUserCompanyQueryPayload = CURRENT_USER_COMPANY_QUERY_Result;
export type CurrentUserCompanyQueryVariables =
  CURRENT_USER_COMPANY_QUERYVariables;
export type BusinessSettings =
  CurrentUserCompanyQueryPayload['businessSettings'];

export const isCurrentUserCompanyQuerySuccessResponse = (
  business?: CURRENT_USER_COMPANY_QUERY_Result['business']
): business is CURRENT_USER_COMPANY_QUERY_business_Business =>
  !!(business && business.__typename === 'Business');

export const CURRENT_USER_COMPANY_QUERY = gql`
  ${COMPANY_PROFILE_FRAGMENT}
  ${COMPANY_STATE_FRAGMENT}

  query CURRENT_USER_COMPANY_QUERY($id: ID!) {
    business(id: $id) {
      ... on Business {
        id
        ...CompanyProfileFragment
      }
      ... on BusinessInputError {
        message
        idError: id {
          message
        }
      }
      ... on BusinessNotFoundError {
        message
      }
    }
    businessStates(id: $id) {
      ...CompanyStateFragment
    }
    businessSettings(businessId: $id) {
      id
      NumAnswers
      NumAnswersRemaining
      NumProfilePageVisits
      JobFolders
      ProfilePage
      BoostCredits
      BoostCreditsRemaining
    }
  }
`;
