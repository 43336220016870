import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const ChatConversationContainer = styled(Div)`
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: 1fr auto;
  padding: 0;
  width: 100%;
  height: 100%;
  align-content: flex-end;
  overflow: hidden;
  background-color: ${({ theme }): string => theme.colors.black.black0};

  .simplebar-content {
    height: 100%;
  }
`;

export default ChatConversationContainer;
