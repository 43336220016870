import { ApolloLink } from '@apollo/client';
import { APOLLO_RETRY_ON_GQL_ERROR } from '@internals/business-shared/src/utils/constants/apollo';
import { isRetryableError } from '@internals/business-shared/src/utils/gqlErrors';

export const createRetryOnGglErrorLink = () =>
  new ApolloLink((operation, forward) => {
    return forward(operation).map((data) => {
      // pass gql retryable error to the retry link
      if (data?.errors?.length && isRetryableError(data.errors)) {
        operation.setContext({ [APOLLO_RETRY_ON_GQL_ERROR]: true });
        const errorMsg = `Error on retried operation ${
          operation.operationName
        }: ${data.errors[0].message}. Response: ${JSON.stringify(
          data,
          null,
          2
        )}`;
        throw new Error(errorMsg);
      }
      return data;
    });
  });
