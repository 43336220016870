import { FC } from 'react';

import { Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';

interface DescriptionProps {
  clips: number;
}

export const Description: FC<DescriptionProps> = ({ clips }) => {
  const { t } = useTranslation();

  return (
    <Text.p width={0.9} mb={0}>
      {t('job.modal.boost.packageCard.description', { count: clips })}
    </Text.p>
  );
};
