import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';

export interface JobReportStatusProps {
  icon: IconPickerName;
  iconColor: string;
  text: string;
}

const JobReportStatus: React.FC<
  React.PropsWithChildren<JobReportStatusProps>
> = ({ icon, iconColor, text }: JobReportStatusProps) => (
  <Div py={8}>
    <Div width="75px" margin="0 auto">
      <FireIcon iconName={icon} fill={iconColor} variant="flat" />
    </Div>
    <Text.span fontSize={7} fontWeight={600} mt={4} textAlign="center">
      {text}
    </Text.span>
  </Div>
);

export default JobReportStatus;
