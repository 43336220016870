import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  CHAT_BY_JOB_ID_QUERY,
  ChatByJobId,
  ChatByJobIdQueryPayload,
  ChatByJobIdQueryVariables,
} from '../../utils/query/ChatByJobId/ChatByJobIdQuery';

export interface ChatByJobIdQueryResult extends QueryResult {
  data: ChatByJobId | null;
}

export const isChatByJobIdType = (
  chat?: ChatByJobId | null
): chat is ChatByJobId => !!chat && chat.__typename === 'Chat';

export const useChatByJobIdQuery = (
  jobId: string,
  options?: QueryHookOptions<ChatByJobIdQueryPayload, ChatByJobIdQueryVariables>
): ChatByJobIdQueryResult => {
  const { data, ...rest } = useQuery<
    ChatByJobIdQueryPayload,
    ChatByJobIdQueryVariables
  >(CHAT_BY_JOB_ID_QUERY, {
    variables: {
      jobId,
    },
    fetchPolicy: 'cache-and-network',
    ...options,
  });

  return useMemo(() => {
    return {
      data:
        data && isChatByJobIdType(data?.chatByJobId) ? data.chatByJobId : null,
      ...rest,
    };
  }, [data, rest]);
};
