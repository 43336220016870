import { FC, useMemo, useRef } from 'react';

import { useFlagEnabled } from '@components/base/CheckFlag';
import { CustomTooltip } from '@components/base/CustomTooltip';
import DropdownItemButton from '@components/base/DropdownItemButton';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import JobActionButtons from '@components/elements/JobActionButtons';
import JobLabels from '@components/elements/JobLabels';
import { useIsJobFoldersFeatureEnabled } from '@contexts/UserContext';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import FeatureFlags from '@internals/business-shared/src/utils/constants/FeatureFlags';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node } from '@internals/business-shared/src/utils/generated/generated';
import { JobListOrJobQueryItem } from '@internals/business-shared/src/utils/interfaces/graphql/JobListOrJobQueryItem.interface';
import {
  getJobLabelList,
  JobLabel,
} from '@internals/business-shared/src/utils/jobLabels';
import { JobTraitsQueryData } from '@internals/business-shared/src/utils/query/JobTraits/JobTraits';
import Paths from '@router/paths';
import { Heading, Div, Dropdown, Text } from '@schibsted-smb/fireball';
import { Layer } from '@utils/layer';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { StyledCloseButton, DivActionButton } from './styled';

export interface JobHeaderProps {
  jobTraits: JobTraitsQueryData | null;
  job: JobListOrJobQueryItem;
  deleteAction: () => void;
  favouriteAction: () => void;
  showReportModal: () => void;
  handleClose: () => void;
}

interface JobHeaderDropdownItem {
  text: string;
  iconName: IconPickerName;
  fill: string;
  onClick: VoidFunction;
  testId?: string;
}

const JobHeader: FC<JobHeaderProps> = ({
  jobTraits,
  job,
  deleteAction,
  favouriteAction,
  showReportModal,
  handleClose,
}: JobHeaderProps) => {
  const { t } = useTranslation();
  const headerRef = useRef(null);
  const themeContext = useTheme();
  const [, copyIdToClipboard] = useCopyToClipboard();
  const jobFoldersEnabled = useIsJobFoldersFeatureEnabled();

  const bizVerificationViewEnabled = useFlagEnabled(
    FeatureFlags.BizVerificationView
  );

  const printLink = (
    id: JOBS_LIST_QUERY_jobList_JobListPayload_jobConnection_edges_node['id']
  ): string => Paths.JobsItemPrint.replace(':id', id);
  const {
    hasRequestedEvaluation,
    hasRequestedSelection,
    isFavourite,
    isDeleted,
    isAnswered,
    isRejected,
  } = job.jobBusinessRelationState;

  const jobLabels = useMemo(() => {
    return getJobLabelList({
      isVerifiedWithSecureId:
        bizVerificationViewEnabled && jobTraits?.fromVerifiedUser,
      ...job.labels,
      evaluationRequested: hasRequestedEvaluation,
      selectionRequested: hasRequestedSelection,
      isEvaluated: !!job.evaluation,
      isDirectRegistration: job.isOneOnOne,
      isRejected,
    });
  }, [
    jobTraits?.fromVerifiedUser,
    job.evaluation,
    job.labels,
    job.isOneOnOne,
    hasRequestedEvaluation,
    hasRequestedSelection,
    bizVerificationViewEnabled,
    isRejected,
  ]);

  const handlePrint = (): Window | null =>
    window.open(printLink(job.id), '_blank', 'noopener noreferrer');

  const dropdownItems: JobHeaderDropdownItem[] = [
    {
      text: isFavourite
        ? t('job.action.unfavourite')
        : t('job.action.favourite'),
      iconName: IconPickerName.Favourite,
      fill: themeContext.colors.orange.base,
      testId: isFavourite
        ? 'job-header-button-unfavourite'
        : 'job-header-button-favourite',
      onClick: favouriteAction,
    },
    {
      text: t('job.action.print'),
      iconName: IconPickerName.Print,
      fill: themeContext.colors.primary.base,
      onClick: handlePrint,
    },
    {
      text: t('job.action.report'),
      iconName: IconPickerName.Report,
      fill: themeContext.colors.red.base,
      onClick: showReportModal,
    },
    {
      text: t('job.item.copyId', { id: job.id }),
      iconName: IconPickerName.Copy,
      fill: themeContext.colors.primary.base,
      onClick: () => copyIdToClipboard(job.id),
    },
    ...(!isAnswered
      ? [
          {
            text: isDeleted ? t('job.action.restore') : t('job.action.delete'),
            iconName: isDeleted
              ? IconPickerName.Restore
              : IconPickerName.Delete,
            fill: themeContext.colors.red.base,
            testId: isDeleted
              ? 'job-header-button-restore'
              : 'job-header-button-delete',
            onClick: deleteAction,
          },
        ]
      : []),
  ];

  return (
    <Div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pb={6}
      px={6}
      width="100%"
      borderBottom={`1px solid ${themeContext.colors.black.black3}`}
      ref={headerRef}
      data-testid="job-header"
    >
      <Div display="flex" alignItems="center">
        <Div className="no-print">
          <CustomTooltip id="tooltip-job-header" />
          <StyledCloseButton
            onClick={handleClose}
            data-testid="job-header-button-close"
            data-tooltip-id="tooltip-job-header"
            data-tooltip-place="bottom"
            data-tooltip-content={t('general.label.back')}
          >
            <FireIcon
              iconName={IconPickerName.ArrowLeft}
              fill={themeContext.colors.primary.base}
              variant="flat"
            />
          </StyledCloseButton>
        </Div>
        <Div flex={1} display="flex" flexWrap="wrap" alignItems="center">
          <Heading.h3 mb={0} my={2} mr={2} textAlign="left">
            {job.title}
          </Heading.h3>
          <JobLabels
            labels={jobLabels}
            folders={job.folders}
            evaluation={job.evaluation}
          />
        </Div>
      </Div>
      <Div display="flex" alignItems="center" className="no-print">
        {jobFoldersEnabled ? (
          <JobActionButtons.FolderSelect
            job={job}
            favouriteAction={favouriteAction}
            containerRef={headerRef}
          />
        ) : (
          <JobActionButtons.Favourite
            isFavourite={isFavourite}
            onClick={favouriteAction}
          />
        )}
        {!isAnswered && (
          <JobActionButtons.Delete
            isDeleted={isDeleted}
            onClick={deleteAction}
          />
        )}
        <Dropdown
          parentElement={headerRef}
          openedDropdownMargin={4}
          borderRadius="10px"
          zIndex={Layer.popover}
          width={244}
          items={dropdownItems.map(
            ({ text, iconName, fill, onClick, testId }) => (
              <DropdownItemButton
                onClick={onClick}
                iconSize="24px"
                key={`dropdown-item-${text}`}
              >
                <FireIcon
                  width={24}
                  height={24}
                  iconName={iconName}
                  fill={fill}
                  variant="flat"
                />
                <Text.span ml={4} mb={0} data-testid={testId}>
                  {text}
                </Text.span>
              </DropdownItemButton>
            )
          )}
          testId="job"
          closeOnItemClick
        >
          <DivActionButton
            background={themeContext.colors.black.black0}
            fill={themeContext.colors.black.black9}
            backgroundHover={themeContext.colors.black.black0}
            fillHover={themeContext.colors.black.black9}
            tabIndex={0}
            data-testid="job-header-button-dropdown"
            outlineColor={themeContext.colors.primary.base}
            data-tooltip-id="tooltip-job-header"
            data-tooltip-place="bottom"
            data-tooltip-content={t('chat.item.seeMore')}
          >
            <FireIcon iconName={IconPickerName.More} variant="flat" />
          </DivActionButton>
        </Dropdown>
      </Div>
    </Div>
  );
};

export default JobHeader;
