import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const UploadedDocumentWrapper = styled(Div)`
  cursor: pointer;
  &:hover ${Div} {
    visibility: visible;
  }
`;

export default UploadedDocumentWrapper;
