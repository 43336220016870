import styled from 'styled-components';

const MessageFileLink = styled.a`
  color: ${(props: any): string => props.theme.colors.black.black9};
  &:hover {
    color: ${(props: any): string => props.theme.colors.black.black9};
    text-decoration: none;
  }
`;

export default MessageFileLink;
