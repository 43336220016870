import { FC, Reducer, useCallback, useEffect, useReducer } from 'react';

import { SimpleModalFactory } from '@components/layout/CustomerService/Contract/SimpleModalFactory';
import useSetSearchParams from '@hooks/useSetSearchParams';
import { useSimpleContractQuery } from '@internals/business-shared/src/hooks/query/useSimpleContractQuery';
import {
  SimpleContractModalAction,
  SimpleContractModalDefaultValue,
  SimpleContractModalReducer,
  SimpleContractModalState,
} from '@internals/business-shared/src/reducers/SimpleContractReducer';
import log from '@internals/business-shared/src/utils/devLog';
import { Params } from '@router/paths';
import { bugsnagClient } from '@utils/initBugsnag';
import ToastMessage from '@utils/ToastMessage';
import { useTranslation } from 'react-i18next';

const OpenContract: FC = () => {
  const { t } = useTranslation();
  const [, resetParam, { searchParams }] = useSetSearchParams();
  const contractId = searchParams.get(Params.Contract) ?? null;

  const [modalState, dispatchModal] = useReducer<
    Reducer<SimpleContractModalState, SimpleContractModalAction>
  >(SimpleContractModalReducer, SimpleContractModalDefaultValue);

  const resetContractModals = useCallback(() => {
    log.component('Reset Contract Param');
    dispatchModal({ type: 'RESET' });
    resetParam();
  }, [resetParam]);

  const { loading: singleContractDataLoading } = useSimpleContractQuery(
    contractId,
    {
      skip: contractId === null,
      onError: resetContractModals,
      onCompleted: (res) => {
        if (!res.simpleAgreementContract) {
          ToastMessage(t('contract.list.invalidData'));
          bugsnagClient.notify(
            new Error(
              `Contract request for contract id: ${contractId} returned invalid data`
            )
          );
          resetContractModals();
        }
        dispatchModal({
          type: 'INIT',
          payload: {
            contract: res.simpleAgreementContract,
          },
        });
      },
    }
  );

  useEffect(() => {
    if (singleContractDataLoading) {
      dispatchModal({
        type: 'ITEM_LOADING',
      });
    }
  }, [singleContractDataLoading]);

  if (contractId === null) {
    return null;
  }

  return (
    <SimpleModalFactory
      modalState={modalState}
      dispatchModal={dispatchModal}
      resetContractModals={resetContractModals}
    />
  );
};

export default OpenContract;
