import { FC, RefObject } from 'react';

import SearchInput from '@components/base/SearchInput';
import { ChatListTypeFilter } from '@hooks/useChatFilters';
import { ChatListType } from '@internals/business-shared/src/utils/generated/generated';
import { TabNavigation } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  ChatListFilterContainerV2,
  ChatMessageSearchContainerV2,
  SearchWrapper,
} from './styled';

export interface ChatListFilterProps {
  filters: ChatListTypeFilter[];
  activeFilter: ChatListType;
  setActiveFilter: (id: ChatListType) => void;
  inputValue: string;
  changeInputValue: (value: string) => void;
  parentRef: RefObject<HTMLDivElement>;
}

const UNGROUPED_FILTERS_COUNT = 4;

const ChatListFilterV2: FC<ChatListFilterProps> = ({
  filters,
  activeFilter,
  setActiveFilter,
  inputValue,
  changeInputValue,
  parentRef,
}) => {
  const { t } = useTranslation();
  const themeContext = useTheme();

  const extendedFilters = filters.map((filter, index) => ({
    id: index,
    chatListFilterType: filter.id,
    title: filter.title,
  }));

  return (
    <>
      <ChatMessageSearchContainerV2>
        <SearchWrapper>
          <SearchInput
            inputValue={inputValue}
            changeInputValue={changeInputValue}
            width="100%"
            height={`${themeContext.space[8].toString()}px`}
          />
        </SearchWrapper>
      </ChatMessageSearchContainerV2>
      <ChatListFilterContainerV2>
        <TabNavigation
          tabs={extendedFilters.slice(0, UNGROUPED_FILTERS_COUNT)}
          dropdown={{
            tabs: extendedFilters.slice(UNGROUPED_FILTERS_COUNT),
            basicText: t('chat.list.filter.dropdown'),
            parentElementRef: parentRef,
            renderAtTheEndOfContainer: true,
          }}
          activeTabId={
            extendedFilters.find(
              (filter) => filter.chatListFilterType === activeFilter
            )?.id ?? extendedFilters[0].id
          }
          setActiveTabId={(id: number) =>
            setActiveFilter(
              extendedFilters.find((filter) => filter.id === id)
                ?.chatListFilterType ?? extendedFilters[0].chatListFilterType
            )
          }
          height={`${themeContext.space[9].toString()}px`}
          tabButtonStyles={{ mr: 0, px: 1 }}
          containerStyles={{ justifyContent: 'space-between' }}
          underlineStyle={{ height: `${themeContext.space[1]}px` }}
        />
      </ChatListFilterContainerV2>
    </>
  );
};

export default ChatListFilterV2;
