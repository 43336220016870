import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const MapOverlay = styled(Div)`
  pointer-events: none;
  height: 100%;
  width: 100%;
`;

export default MapOverlay;
