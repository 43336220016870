import debug, { Debugger } from 'debug';

// Disable logs by default
debug.disable();

type LogScopeName =
  | 'analytics'
  | 'apollo'
  | 'auth'
  | 'core'
  | 'component'
  | 'error'
  | 'featureFlags'
  | 'hook'
  | 'networking'
  | 'page'
  | 'pushNotifications'
  | 'screen'
  | 'storage'
  | 'linking';

type LogMethod = (...messages: unknown[]) => void;

const logScopes: Record<LogScopeName, string> = {
  analytics: 'app:analytics',
  apollo: 'app:networking:apollo',
  auth: 'app:auth',
  component: 'app:component',
  core: 'app:core',
  error: 'app:error',
  featureFlags: 'app:featureFlags',
  hook: 'app:hook',
  networking: 'app:networking',
  page: 'app:page',
  pushNotifications: 'app:pushNotifications',
  screen: 'app:screen',
  storage: 'app:storage',
  linking: 'app:linking',
};

// Generate debuggers with custom scopes
const debuggers: Record<LogScopeName, Debugger> = Object.entries(
  logScopes
).reduce(
  (prev, [scopeName, scope]): Record<LogScopeName, Debugger> => ({
    ...prev,
    [scopeName]: debug(scope),
  }),
  {} as Record<LogScopeName, Debugger>
);
// Bind console.info as a default output for debuggers
Object.keys(debuggers).map(
  (key) => ((debuggers?.[key as LogScopeName]).log = console.info.bind(console))
);

// Prepare log.[scopeName] methods
const log: Record<LogScopeName, LogMethod> = {
  ...Object.keys(debuggers).reduce(
    (prev, scopeName) => ({
      ...prev,
      [scopeName]: (...messages: unknown[]): void => {
        debuggers[scopeName as LogScopeName]('%O', ...messages);
        if (scopeName === 'error') {
          // for log.error we want to call console.error as well
          console.error(...messages);
        }
      },
    }),
    {} as Record<LogScopeName, LogMethod>
  ),
};

export const initLog = (configuration: string) => {
  if (configuration !== '') {
    debug.enable(configuration);
    console.log('DEBUG is now ENABLED for configuration:', configuration);
  } else {
    debug.disable();
    console.log('DEBUG is now DISABLED');
  }
};

export default log;
