import { ApolloCache, Reference } from '@apollo/client';

import {
  CURRENT_USER_COMPANY_QUERY_business_Business,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts,
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities,
  UPDATE_BUSINESS_DISTRICTS,
} from '../../utils/generated/generated';
import { UpdateBusinessDistrictsPayload } from '../../utils/mutation/UpdateBusinessDistricts/UpdateBusinessDistricts';

const updateBusinessDistrictsUpdate =
  (
    businessId: CURRENT_USER_COMPANY_QUERY_business_Business['id'],
    updatedMunicipality?: {
      id: JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_municipalities['id'];
      enabled: boolean;
    }
  ) =>
  (
    cache: ApolloCache<UPDATE_BUSINESS_DISTRICTS>,
    { data: mutationPayload }: { data?: UpdateBusinessDistrictsPayload | null }
  ) => {
    if (!mutationPayload) return;
    cache.modify({
      id: cache.identify({ id: businessId, __typename: 'BusinessJobAreas' }),
      fields: {
        // from payload we get district ids,
        // we map them to cached entities and put them in enabled districts field
        districts(existing, { toReference }) {
          return mutationPayload.updateBusinessDistricts.districts
            ?.map((district) =>
              cache.identify({ id: district.id, __typename: 'District' })
            )
            .map((districtCacheId) =>
              districtCacheId ? toReference(districtCacheId) : districtCacheId
            )
            .filter(Boolean);
        },
        municipalities(existing, { readField, toReference }) {
          if (!existing || !updatedMunicipality) return existing;
          // remove municipality if it's already in enabled list
          const { id: municipalityId, enabled } = updatedMunicipality;
          const enabledMunicipalities = existing.filter(
            (m: Reference) => readField('id', m) !== municipalityId
          );
          if (!enabled) {
            return enabledMunicipalities;
          }
          // get municipality cache entity and add it to enabled list
          const municipalityCacheId = cache.identify({
            id: municipalityId,
            __typename: 'Municipality',
          });
          return municipalityCacheId
            ? [...enabledMunicipalities, toReference(municipalityCacheId)]
            : enabledMunicipalities;
        },
      },
    });
  };

type UpdateDistrictType = Pick<
  JOB_SEARCH_SETTINGS_QUERY_businessJobAreas_districts,
  'id' | 'name'
>;

const updateBusinessDistrictsOptimisticResponse = (
  districtsEnabled: UpdateDistrictType[],
  updatedDistrict: UpdateDistrictType,
  enabled: boolean
): UpdateBusinessDistrictsPayload => {
  let updatedDistrictsEnabled = districtsEnabled.filter(
    (d) => d.id !== updatedDistrict.id
  );
  if (enabled) {
    updatedDistrictsEnabled = [...updatedDistrictsEnabled, updatedDistrict];
  }
  return {
    updateBusinessDistricts: {
      __typename: 'UpdateBusinessDistrictsPayload',
      districts: updatedDistrictsEnabled.map(({ id, name }) => ({
        id,
        name,
        __typename: 'District',
      })),
    },
  };
};

export {
  updateBusinessDistrictsUpdate,
  updateBusinessDistrictsOptimisticResponse,
};
