import { FC, RefObject, useCallback, useMemo } from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import DropdownItemButton from '@components/base/DropdownItemButton';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { MoreActionsChatButton } from '@components/elements/ChatListItem/styled';
import { useIsMobile } from '@contexts/DeviceSizeContext';
import { IconVariant } from '@internals/business-shared/src/components/Icon/IconPicker';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Dropdown, Text } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import uniqid from 'uniqid';

interface ChatListItemActionProps {
  disabled?: boolean;
  text: string;
  iconName: IconPickerName;
  iconVariant: IconVariant;
  color: string;
  onClick: VoidFunction;
}

interface ChatListItemActionsProps {
  containerRef: RefObject<HTMLElement>;
  isChatDeleted: boolean;
  isChatEnded: boolean;
  isChatInactive: boolean;
  isChatFavourite: boolean;
  isChatUnread: boolean;
  isDialog: boolean;
  handleChatDelete: VoidFunction;
  handleChatFavourite: VoidFunction;
  handleShowJob: VoidFunction;
  handleChatUnread: VoidFunction;
}

const ChatListItemAction: FC<ChatListItemActionProps> = ({
  text,
  iconName,
  iconVariant,
  color,
  onClick,
  disabled,
}) => {
  return (
    <DropdownItemButton
      key={text}
      pl={5}
      disabled={disabled}
      onClick={onClick}
      iconSize="20px"
      mt={5}
      display="flex"
      alignItems="center"
      flexDirection="row"
      data-testid={`dropdown-item-${iconName}`}
    >
      <FireIcon
        iconName={iconName}
        variant={iconVariant}
        width={20}
        height={20}
        fill={color}
      />
      <Text.p fontSize={2} mb={0} ml={3} color={color} fontWeight={600}>
        {text}
      </Text.p>
    </DropdownItemButton>
  );
};

const uniqueIdConst = uniqid();

const ChatListItemActions: FC<ChatListItemActionsProps> = ({
  containerRef,
  isChatDeleted,
  isChatEnded,
  isChatInactive,
  isChatFavourite,
  isChatUnread,
  isDialog,
  handleChatDelete,
  handleChatFavourite,
  handleShowJob,
  handleChatUnread,
}) => {
  const themeContext = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const canMarkAsUnread = isDialog && !isChatUnread;

  const filterVisible = useCallback(
    (item: ChatListItemActionProps & { hide?: boolean; disabled?: boolean }) =>
      item?.hide !== true,
    []
  );
  const mapDisabled = useCallback(
    (
      item: ChatListItemActionProps & { hide?: boolean; disabled?: boolean }
    ) => {
      if (!item.disabled) {
        return item;
      }
      return {
        ...item,
        iconVariant: 'flat' as IconVariant,
        color: themeContext.colors.black.black4,
      };
    },
    [themeContext]
  );

  const dropdownItems: ChatListItemActionProps[] = useMemo(
    () =>
      [
        {
          disabled: !canMarkAsUnread,
          text: t('chat.list.dropdownItems.markAsUnread'),
          iconName: IconPickerName.Email,
          iconVariant: 'gray' as IconVariant,
          color: themeContext.colors.black.black10,
          onClick: handleChatUnread,
        },
        {
          hide: !isChatDeleted || isChatEnded,
          text: t('chat.list.dropdownItems.restore'),
          iconName: IconPickerName.Restore,
          iconVariant: 'flat' as IconVariant,
          color: themeContext.colors.primary.base,
          onClick: handleChatDelete,
        },
        {
          text: isChatFavourite
            ? t('chat.list.dropdownItems.unmarkFavourite')
            : t('chat.list.dropdownItems.markAsFavourite'),
          iconName: IconPickerName.Favourite,
          iconVariant: 'gray' as IconVariant,
          color: themeContext.colors.black.black10,
          onClick: handleChatFavourite,
        },
        {
          text: t('chat.list.dropdownItems.showJob'),
          iconName: IconPickerName.Invoice,
          iconVariant: 'gray' as IconVariant,
          color: themeContext.colors.black.black10,
          onClick: handleShowJob,
        },
        {
          hide: isChatDeleted || isChatInactive,
          text: t('general.label.delete'),
          iconName: IconPickerName.Delete,
          iconVariant: 'flat' as IconVariant,
          color: themeContext.colors.red.base,
          onClick: handleChatDelete,
        },
      ]
        .filter(filterVisible)
        .map(mapDisabled),
    [
      canMarkAsUnread,
      mapDisabled,
      filterVisible,
      themeContext,
      t,
      isChatDeleted,
      isChatEnded,
      isChatFavourite,
      isChatInactive,
      handleChatDelete,
      handleChatFavourite,
      handleChatUnread,
      handleShowJob,
    ]
  );

  return (
    <Div position="relative" justifySelf="end">
      <CustomTooltip id={uniqueIdConst} />
      <Dropdown
        openedDropdownMargin={isMobile ? 0 : themeContext.space[6]}
        parentElement={containerRef}
        items={dropdownItems.map((item) => (
          <ChatListItemAction key={item.text} {...item} />
        ))}
      >
        <MoreActionsChatButton
          data-tooltip-id={uniqueIdConst}
          data-tooltip-content={t('chat.item.seeMore')}
          m={0}
          p={0}
          data-testid="more-actions-chat-button"
        >
          <FireIcon iconName={IconPickerName.More} variant="flat" />
        </MoreActionsChatButton>
      </Dropdown>
    </Div>
  );
};

export default ChatListItemActions;
