import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  JOB_SEARCH_SETTINGS_QUERY,
  JobSearchSettingsJobAreas,
  JobSearchSettingsQueryPayload,
  JobSearchSettingsQueryVariables,
  JobSearchSettingsWorkTypes,
} from '../../utils/query/JobsSearchSettings/JobSearchSettingsQuery';

interface JobSearchSettingsQueryResult extends QueryResult {
  data:
    | (Omit<JobSearchSettingsWorkTypes, '__typename'> &
        Omit<JobSearchSettingsJobAreas, '__typename'>)
    | null;
}

export const useJobSearchSettingsQuery = (
  businessId: string,
  options?: QueryHookOptions<
    JobSearchSettingsQueryPayload,
    JobSearchSettingsQueryVariables
  >
): JobSearchSettingsQueryResult => {
  const { data, ...rest } = useQuery<
    JobSearchSettingsQueryPayload,
    JobSearchSettingsQueryVariables
  >(JOB_SEARCH_SETTINGS_QUERY, {
    variables: {
      id: businessId,
    },
    fetchPolicy: 'cache-first',
    ...options,
  });

  return useMemo(() => {
    return {
      data: data
        ? {
            ...data.businessWorkTypes,
            ...data.businessJobAreas,
          }
        : null,
      ...rest,
    };
  }, [data, rest]);
};
