export const SYSTEM_MESSAGE_TYPE_CONTRACT_OR_INSURANCE = 1;
export const SYSTEM_MESSAGE_TYPE_CARD = 2;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED = 3;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_SIGNED_BY_BOTH = 4;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_CONSUMER = 5;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_ACCEPTED_BUSINESS = 6;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_CONSUMER = 7;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_REJECTED_BUSINESS = 8;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_INVALIDATED = 9;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_CONSUMER = 10;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_EDITED_BUSINESS = 11;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_CONSUMER = 12;
export const SYSTEM_MESSAGE_TYPE_AGREEMENT_CREATED_BUSINESS = 13;
export const SYSTEM_MESSAGE_TYPE_BOLIGMAPPA = 14;
export const SYSTEM_MESSAGE_TYPE_NUDGED_BOLIGMAPPA = 15;
