import { useMemo } from 'react';

import { QueryHookOptions, QueryResult, useQuery } from '@apollo/client';

import {
  BOOST_CREDITS_PRODUCT_DETAILS,
  BoostCreditsProductDetails,
  BoostCreditsProductDetailsPayload,
} from '../../utils/query/BoostCreditsProductDetails/BoostCreditsProductDetails';

interface BoostCreditsProductDetailsQueryResult extends QueryResult {
  data: BoostCreditsProductDetails[];
}

export const useBoostCreditsProductDetailsQuery = (
  options?: QueryHookOptions<BoostCreditsProductDetailsPayload>
): BoostCreditsProductDetailsQueryResult => {
  const { data, ...rest } = useQuery<BoostCreditsProductDetailsPayload>(
    BOOST_CREDITS_PRODUCT_DETAILS,
    {
      ...options,
    }
  );

  return useMemo(
    () => ({
      data: data?.boostCreditsProductDetails
        ? [data.boostCreditsProductDetails]
        : [],
      ...rest,
    }),
    [data, rest]
  );
};
