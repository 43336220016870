import { FC } from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { Div, Text } from '@schibsted-smb/fireball';
import { useTheme } from 'styled-components';

export interface EvaluationValueProps {
  value: number;
  unfilled?: boolean;
  small?: boolean;
}

const EvaluationValue: FC<EvaluationValueProps> = ({
  value,
  unfilled,
  small,
}) => {
  const theme = useTheme();
  return (
    <Div
      px={small ? 3 : 4}
      py={small ? 1 : 3}
      mr={6}
      display="flex"
      alignItems="center"
      justifyContent="center"
      backgroundColor={
        unfilled ? theme.colors.black.black0 : theme.colors.yellow.lightest
      }
      borderRadius={2}
      border={`1px solid ${theme.colors.yellow.lightest}`}
    >
      <FireIcon
        iconName={value === 5 ? IconPickerName.Stars : IconPickerName.Star}
        variant="flat"
        fill={theme.colors.yellow.base}
        width="24px"
        height="24px"
      />
      <Text.p
        mb={0}
        ml={small ? 3 : 4}
        fontSize={small ? 2 : 4}
        fontWeight={theme.fontWeights.medium}
        color={theme.colors.black.black9}
      >
        {value}
      </Text.p>
    </Div>
  );
};

export default EvaluationValue;
