import { FC } from 'react';

import Navigation from '@components/elements/Navigation';
import JobListNavigationWrapper from '@components/layout/Jobs/styled/JobListNaviagation';
import { Div } from '@schibsted-smb/fireball';
import { JobsNavigation } from '@utils/jobList';

import LanguagePicker from '../../elements/LanguagePicker';
import { Statistics } from './Statistics';

export interface JobsListNavigationProps {
  navigation: JobsNavigation;
  firstItemActive: boolean;
}

const JobsListNavigation: FC<JobsListNavigationProps> = ({
  navigation,
  firstItemActive,
}: JobsListNavigationProps) => {
  return (
    <JobListNavigationWrapper
      flex="1"
      mb={6}
      pt={5}
      pr={[0, null, null, null, null, null, null, null]}
      data-testid="job-list-navigation-wrapper"
    >
      <Div pl={0} m={0} position="sticky" top="90px">
        <Navigation
          navigationItems={navigation}
          isFirstDefaultActive={firstItemActive}
          countOnActive
        />
        <Div
          pl={[null, 4, null, null, null, null, null, 0]}
          pr={2}
          display={['none', null, null, null, null, 'block', null, 'none']}
        >
          <Statistics />
          <LanguagePicker />
        </Div>
      </Div>
    </JobListNavigationWrapper>
  );
};

export default JobsListNavigation;
