import { Button } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const NAVIGATION_BUTTON_SIZE = 48;
const NAVIGATION_BUTTON_SPACE_FROM_MODAL = 30;
const NAVIGATION_BUTTON_OFFSET_WIDE =
  -NAVIGATION_BUTTON_SIZE - NAVIGATION_BUTTON_SPACE_FROM_MODAL;
const NAVIGATION_BUTTON_OFFSET_DESKTOP = -(
  NAVIGATION_BUTTON_SIZE -
  NAVIGATION_BUTTON_SIZE / 2
);

export const StyledJobNavigationButton = styled(Button)<{
  position: 'left' | 'right';
}>`
  width: ${NAVIGATION_BUTTON_SIZE}px;
  height: ${NAVIGATION_BUTTON_SIZE}px;
  border-radius: 50%;
  padding: 0;
  pointer-events: auto;
  @media only screen and (min-width: ${({ theme }): string =>
      theme.breakpoints[5]}) {
    ${({ position }): string =>
      position === 'left'
        ? `margin-left: ${NAVIGATION_BUTTON_OFFSET_DESKTOP}px;`
        : `margin-right: ${NAVIGATION_BUTTON_OFFSET_DESKTOP}px;`}
  }
  @media only screen and (min-width: ${({ theme }): string =>
      theme.breakpoints[6]}) {
    ${({ position }): string =>
      position === 'left'
        ? `margin-left: ${NAVIGATION_BUTTON_OFFSET_WIDE}px;`
        : `margin-right: ${NAVIGATION_BUTTON_OFFSET_WIDE}px;`}
  }
`;
