import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import {
  MessageFileWrapper,
  MessageLoader,
} from '@components/elements/ChatMessage/styled';
import { getIconName } from '@internals/business-shared/src/utils/FileUtils';
import { Div, Text } from '@schibsted-smb/fireball';

export interface ChatFileAttachmentProps {
  name: string;
  isLast: boolean;
  inProgress?: boolean;
  type: string;
  businessMessage?: boolean;
}

const ChatFile: React.FC<React.PropsWithChildren<ChatFileAttachmentProps>> = ({
  name,
  type,
  isLast,
  inProgress,
  businessMessage,
}) => (
  <Div position="relative" overflow="hidden">
    <MessageFileWrapper isLast={isLast} businessMessage={businessMessage}>
      <FireIcon
        iconName={getIconName(type)}
        variant="color"
        width="24px"
        height="24px"
      />
      <Text.span
        truncate
        maxWidth="355px"
        m={0}
        ml={3}
        fontSize={3}
        fontWeight={600}
      >
        {name}
      </Text.span>
      {inProgress && <MessageLoader highTransparency />}
    </MessageFileWrapper>
  </Div>
);

export default ChatFile;
