import { createContext, useContext, useEffect } from 'react';
import * as React from 'react';

import { ApolloError, useLazyQuery } from '@apollo/client';
import { useIsJobFoldersFeatureEnabled } from '@contexts/UserContext';
import { JOB_FOLDERS_QUERY_jobFolders_folders } from '@internals/business-shared/src/utils/generated/generated';
import {
  JOB_FOLDERS_QUERY,
  JobFoldersQueryPayload,
} from '@internals/business-shared/src/utils/query/JobFolders/JobFoldersQuery';

interface JobFoldersContextValue {
  folderList: JOB_FOLDERS_QUERY_jobFolders_folders[];
  folderListLoading: boolean;
  folderListError: ApolloError | undefined;
}

const JobFoldersContext = createContext<JobFoldersContextValue | null>(null);

export const JobFoldersProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [fetchFolders, { data, loading, error }] =
    useLazyQuery<JobFoldersQueryPayload>(JOB_FOLDERS_QUERY, {
      fetchPolicy: 'cache-first',
    });
  const foldersFeatureEnabled = useIsJobFoldersFeatureEnabled();
  useEffect(() => {
    if (foldersFeatureEnabled) {
      fetchFolders();
    }
  }, [foldersFeatureEnabled]);
  const folderList: JOB_FOLDERS_QUERY_jobFolders_folders[] =
    data?.jobFolders?.folders?.filter((folder) => folder !== null) ?? [];
  const contextValue = foldersFeatureEnabled
    ? { folderList, folderListLoading: loading, folderListError: error }
    : null;

  return (
    <JobFoldersContext.Provider value={contextValue}>
      {children}
    </JobFoldersContext.Provider>
  );
};

export const useFoldersContext = (): JobFoldersContextValue | null => {
  return useContext(JobFoldersContext);
};
