import { FC } from 'react';

import { useIsTabletOrMobile } from '@contexts/DeviceSizeContext';
import { convertToThousandSpaces } from '@internals/business-shared/src/utils/convertToThousandSpaces';
import { Div, Text } from '@schibsted-smb/fireball';
import { getProduct } from '@utils/product';
import { useTranslation } from 'react-i18next';

import { ModalState } from '../../constants';
import { useBuySubscriptionModalContext } from '../../ModalContext';

interface StyleProps {
  pb?: number;
  pt?: number;
}

interface CostPerMonthProps {
  price: number;
  mostPopular?: boolean;
}

export const CostPerMonth: FC<CostPerMonthProps> = ({ price, mostPopular }) => {
  const { currency } = getProduct();
  const { modalState } = useBuySubscriptionModalContext();
  const { t } = useTranslation();
  const isTabletOrMobile = useIsTabletOrMobile();

  const getSelectPackageStyle = (): StyleProps => {
    let pb = 60;
    let pt = 0;

    if (isTabletOrMobile) {
      pb = 12;
    } else if (mostPopular) {
      pt = 15;
      pb = 45;
    }

    return { pb, pt };
  };

  const styleProps: Partial<Record<ModalState, StyleProps>> = {
    [ModalState.SELECT_PACKAGE]: getSelectPackageStyle(),
    [ModalState.SUMMARY]: {
      pb: 40,
    },
    [ModalState.CONFIRM_PURCHASE]: {
      pb: 36,
    },
  };

  return (
    <Div {...styleProps[modalState]}>
      <Text.p fontSize={3} fontWeight="medium">
        {t('job.modal.subscription.packageCard.costPerMonth', {
          cost: convertToThousandSpaces(price),
          currency,
        })}
      </Text.p>
      <Text.span fontSize={1} fontWeight="medium" color="gray.darker">
        {t('job.modal.subscription.packageCard.exVat')}
      </Text.span>
    </Div>
  );
};
