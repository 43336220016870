import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { getIconName } from '@internals/business-shared/src/utils/FileUtils';
import {
  FileFragment,
  ImageFragment,
} from '@internals/business-shared/src/utils/generated/generated';
import { Div, Text } from '@schibsted-smb/fireball';
import urlWithProtocol from '@utils/urlWithProtocol';
import { useTheme } from 'styled-components';

import { Gallery, GalleryImageItem } from '../../elements/Gallery';

interface ChatJobInfoFilesProps {
  files: FileFragment[];
  images: ImageFragment[];
}
const ChatJobInfoFiles: React.FC<ChatJobInfoFilesProps> = ({
  files,
  images,
}) => {
  const themeContext = useTheme();

  return (
    <Div mb={9}>
      <Div display="flex" flexDirection="column">
        {files &&
          files.map((file) => (
            <Div
              key={file.id}
              display="flex"
              justifyContent="space-between"
              mb={5}
            >
              <Div display="flex" alignItems="center">
                <FireIcon
                  variant="color"
                  iconName={getIconName(file.fileType)}
                  width="24px"
                  height="24px"
                />
                <Text.span ml={4} mb={0}>
                  {file.name}
                </Text.span>
              </Div>
              <a
                href={urlWithProtocol(file.url)}
                download
                target="_blank"
                rel="noreferrer"
              >
                <FireIcon
                  variant="flat"
                  iconName={IconPickerName.Download}
                  width="24px"
                  height="24px"
                  fill={themeContext.colors.primary.base}
                />
              </a>
            </Div>
          ))}
        {images?.length > 0 && (
          <Div
            mt={2}
            display="flex"
            flexWrap="wrap"
            gridGap={`${themeContext.space[4]}px`}
          >
            <Gallery grid>
              {images.map((image) => (
                <GalleryImageItem
                  key={image.id}
                  item={image}
                  imageProps={{ height: '64px', width: '64px' }}
                />
              ))}
            </Gallery>
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default ChatJobInfoFiles;
