import { Link } from 'react-router-dom';
import styled from 'styled-components';

const GoToJobLink = styled(Link)`
  width: 100%;
  text-decoration: none;
  margin-top: ${(props): number => props.theme.space[6]}px;
  margin-bottom: ${(props): number => props.theme.space[7]}px;

  &:hover {
    text-decoration: none;
  }
`;

export default GoToJobLink;
