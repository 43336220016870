import { Div } from '@schibsted-smb/fireball';
import styled from 'styled-components';

const MessagesContainer = styled(Div)`
  display: block;
  position: relative;

  & > * {
    @media print {
      position: relative;
      break-inside: avoid;
    }
  }
`;

export default MessagesContainer;
