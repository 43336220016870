import {
  INDUSTRIES_WITH_INSPECTION,
  INDUSTRIES_WITHOUT_BOLIGMAPPA,
} from './constants/jobIndustries';
import { Job } from './query/Job/JobQuery';
import {
  WorkType,
  WorkTypeIndustry,
  WorkTypeSubset,
  WorkTypeSubsetGroup,
} from './query/WorkTypes/WorkTypesQuery';
import { removeNullable } from './types/utilsTypes';

const SUBSET_ID_PREFIX = 'subset-';
const WORKTYPE_ID_PREFIX = 'worktype-';

// In UI worktype and subset are represented as the same kind of filter, but they are different types on the api level
// these utils help to manage them based on ID
export const setSubsetId = (id: string) => `${SUBSET_ID_PREFIX}${id}`;
export const getSubsetId = (id: string) => id.replace(SUBSET_ID_PREFIX, '');
export const setWorkTypeId = (id: string) => `${WORKTYPE_ID_PREFIX}${id}`;
export const getWorkTypeId = (id: string) => id.replace(WORKTYPE_ID_PREFIX, '');
export const isWorkTypeId = (id: string) => id.includes(WORKTYPE_ID_PREFIX);
export const isSubsetId = (id: string) => id.includes(SUBSET_ID_PREFIX);

export interface BaseWorkType {
  id: string;
  name: string;
  variant: 'workType' | 'subset';
}

export interface WorkTypeGroup extends Omit<BaseWorkType, 'variant'> {
  workTypes: BaseWorkType[];
  variant: 'industry' | 'subsetGroup';
}

export const groupWorkTypes = (
  activeWorkTypes: Pick<WorkType, 'id' | 'title' | 'industries'>[]
): WorkTypeGroup[] => {
  if (!activeWorkTypes) return [];

  const allIndustries = activeWorkTypes.flatMap((workType) =>
    removeNullable<WorkTypeIndustry>(workType.industries || [])
  );
  const uniqueIndustryIds = new Set(
    allIndustries.map((industry) => industry.id)
  );
  const uniqueIndustries = Array.from(uniqueIndustryIds).map((industryId) => {
    const fullIndustry = allIndustries.find((i) => i.id === industryId);
    if (!fullIndustry) return null;
    return {
      id: fullIndustry.id,
      name: fullIndustry.title,
      variant: 'industry',
      workTypes: activeWorkTypes.reduce<BaseWorkType[]>((list, workType) => {
        const isWorkTypePartOfIndustry = workType?.industries?.some(
          (workTypeIndustry) => workTypeIndustry?.id === industryId
        );
        const isWorkTypeUnique = !list.some(
          (wt) => wt.id === setWorkTypeId(workType.id)
        );
        if (!isWorkTypePartOfIndustry || !isWorkTypeUnique) return list;
        return [
          ...list,
          {
            id: setWorkTypeId(workType.id),
            name: workType.title,
            variant: 'workType',
          },
        ];
      }, []),
    } as const;
  });
  return removeNullable<WorkTypeGroup>(uniqueIndustries);
};

export const groupSubsets = (
  activeSubsets: Pick<WorkTypeSubset, 'id' | 'title' | 'partOf'>[]
): WorkTypeGroup[] => {
  const allSubsetGroups = removeNullable<WorkTypeSubsetGroup>(
    activeSubsets.map((subset) => subset?.partOf) || []
  );
  const uniqueSubsetGroupIds = new Set(
    allSubsetGroups.map((subsetGroup) => subsetGroup.id)
  );
  const uniqueSubsetGroups = Array.from(uniqueSubsetGroupIds).map(
    (subsetGroupId) => {
      const fullSubsetGroup = allSubsetGroups.find(
        (group) => group.id === subsetGroupId
      );
      if (!fullSubsetGroup) return null;
      return {
        id: fullSubsetGroup.id,
        name: fullSubsetGroup.consumerTitle,
        variant: 'subsetGroup',
        workTypes: activeSubsets.reduce<BaseWorkType[]>((list, subset) => {
          const isSubsetPartOfGroup = subset?.partOf?.id === subsetGroupId;
          const isSubsetUnique = !list.some(
            (s) => s.id === setSubsetId(subset?.id)
          );
          if (!isSubsetPartOfGroup || !isSubsetUnique) return list;
          return [
            ...list,
            {
              id: setSubsetId(subset.id),
              name: subset.title,
              variant: 'subset',
            },
          ];
        }, []),
      } as const;
    }
  );
  return removeNullable<WorkTypeGroup>(uniqueSubsetGroups);
};

export const mergeSelectedWorkTypes = (
  selectedWorkTypeIds: string[],
  selectedSubsetIds: string[]
): string[] => {
  return [
    ...selectedWorkTypeIds.map(setWorkTypeId),
    ...selectedSubsetIds.map(setSubsetId),
  ];
};

export const unmergeSelectedWorkTypeIds = (
  selectedWorkTypes: string[]
): { selectedWorkTypes: string[]; selectedSubsets: string[] } => {
  return {
    selectedWorkTypes: selectedWorkTypes
      .filter(isWorkTypeId)
      .map(getWorkTypeId),
    selectedSubsets: selectedWorkTypes.filter(isSubsetId).map(getSubsetId),
  };
};

export const jobWorkTypesToIndustryIds = (
  jobWorkTypes: Job['workTypes']
): string[] => {
  const uniqueIndustryIds: Set<string> = new Set();

  jobWorkTypes.forEach((workType) => {
    workType.industries.forEach((industry) => {
      uniqueIndustryIds.add(industry.id);
    });
  });

  return Array.from(uniqueIndustryIds);
};

export const isJobWithInspection = (
  jobWorkTypes?: Job['workTypes']
): boolean => {
  if (!jobWorkTypes) return false;
  const jobIndustries = jobWorkTypesToIndustryIds(jobWorkTypes);
  return jobIndustries.some((industry) =>
    INDUSTRIES_WITH_INSPECTION.includes(industry)
  );
};

export const isJobWithBoligMappa = (
  jobWorkTypes?: Job['workTypes']
): boolean => {
  if (!jobWorkTypes) return false;
  const jobIndustries = jobWorkTypesToIndustryIds(jobWorkTypes);
  return jobIndustries.some(
    (industry) => !INDUSTRIES_WITHOUT_BOLIGMAPPA.includes(industry)
  );
};

export const getActiveWorkTypeGroups = (
  allWorkTypes: WorkTypeGroup[],
  workTypeFilterValue: string[],
  filterType: 'industry' | 'subsetGroup'
): string[] =>
  workTypeFilterValue.filter((filterValue) =>
    allWorkTypes.some(
      (workTypeGroup) =>
        workTypeGroup.variant === filterType && workTypeGroup.id === filterValue
    )
  );
