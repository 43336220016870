import * as React from 'react';

import { FireIcon } from '@components/base/FireIcon/FireIcon';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import {
  Button,
  ButtonProps,
  Div,
  Heading,
  Text,
} from '@schibsted-smb/fireball';
import { Link } from 'react-router-dom';

interface NoDataProps {
  title: string;
  text: string;
  link?: {
    url: string;
    text: string;
  };
  button?: Omit<ButtonProps, 'children'> & {
    text: string;
    onClick: () => void;
  };
  image?: React.ReactElement;
  autoSize?: boolean;
}

const NoData: React.FC<NoDataProps> = ({
  text,
  title,
  link,
  button,
  image,
  autoSize,
}) => {
  return (
    <Div
      minHeight={autoSize ? 'unset' : '420px'}
      display="flex"
      alignItems="center"
      justifyContent="center"
      data-testid="no-data"
    >
      <Div
        maxWidth={autoSize ? 'unset' : '370px'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {image || (
          <FireIcon
            iconName={IconPickerName.NavigationFolders}
            variant="gray"
            width="64px"
            height="64px"
          />
        )}
        <Heading.h4 my={5}>{title}</Heading.h4>
        <Text.p mb={5} textAlign="center">
          {text}
          {!!link && <Link to={link.url}> {link.text}.</Link>}
        </Text.p>
        {!!button && (
          <Button variant="primary" px="25px" py={4} {...button}>
            {button.text}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default NoData;
