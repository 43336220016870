import { FC } from 'react';

import ContentLoader from 'react-content-loader';

import { LoaderProps } from '.';

const ClipsAndProfileVisitsContentLoader: FC<LoaderProps> = ({ uniqueKey }) => (
  <ContentLoader
    uniqueKey={uniqueKey}
    viewBox="0 0 200 55"
    speed={1}
    foregroundColor="#f3f3f3"
    backgroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="0" ry="0" width="80" height="15" />
    <rect x="100" y="0" rx="0" ry="0" width="80" height="15" />
    <rect x="0" y="25" rx="0" ry="0" width="80" height="30" />
    <rect x="100" y="25" rx="0" ry="0" width="80" height="30" />
  </ContentLoader>
);

export default ClipsAndProfileVisitsContentLoader;
