import gql from 'graphql-tag';

import {
  BOOST_CREDITS_PRODUCTS_PAIR as BOOST_CREDITS_PRODUCTS_PAIRPayload,
  BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair,
} from '../../generated/generated';

export type BoostCreditsProductsPairPayload =
  BOOST_CREDITS_PRODUCTS_PAIRPayload;
export type BoostCreditsProductsPair =
  BOOST_CREDITS_PRODUCTS_PAIR_boostCreditsProductsPair;

export const BOOST_CREDITS_PRODUCTS_PAIR = gql`
  query BOOST_CREDITS_PRODUCTS_PAIR {
    boostCreditsProductsPair {
      id
      price
      slug
      clips
      discount {
        discountPercent
        priceAfterDiscount
      }
    }
  }
`;
