import { ChangeEvent, FC, useRef } from 'react';

import { CustomTooltip } from '@components/base/CustomTooltip';
import { FireIcon } from '@components/base/FireIcon/FireIcon';
import { FILE_UPLOAD_SUPPORTED_EXTENSIONS } from '@internals/business-shared/src/utils/constants/fileUpload';
import IconPickerName from '@internals/business-shared/src/utils/constants/iconPickerNames';
import { WebFileWithPreview } from '@internals/business-shared/src/utils/FileUtils';
import { Button, Div } from '@schibsted-smb/fireball';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { FileUploadInput } from './styled';

export interface NewMessageFooterProps {
  onFileUpload: (files: WebFileWithPreview['file'][]) => void;
  dropZoneInputProps: () => Record<string, unknown>;
  sendDisabled: boolean;
  formDisabled?: boolean;
  onMessageTemplateClick?: VoidFunction;
}

const NewMessageFooter: FC<NewMessageFooterProps> = ({
  onFileUpload,
  dropZoneInputProps,
  sendDisabled,
  onMessageTemplateClick,
  formDisabled,
}) => {
  const fileInput: any = useRef<any>();
  const { t } = useTranslation();

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.files && e.target.files.length > 0) {
      onFileUpload(Array.from(e.target.files));
    }
  };

  const themeContext = useTheme();

  return (
    <Div
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={6}
      py={4}
      width="100%"
    >
      <CustomTooltip id="tooltip-message-footer" />
      <Div display="flex" position="relative">
        <FileUploadInput
          {...dropZoneInputProps()}
          type="file"
          accept={FILE_UPLOAD_SUPPORTED_EXTENSIONS}
          multiple
          onChange={handleFileUpload}
          ref={fileInput}
          data-testid="file-input"
          disabled={formDisabled}
        />

        <Button
          type="button"
          onClick={(): void => fileInput.current.click()}
          variant="linkTertiary"
          mr={6}
          my={0}
          p={0}
          data-tooltip-id="tooltip-message-footer"
          data-tooltip-place="top"
          data-tooltip-content={t('component.dropzone.gridUploadInfo')}
          data-testid="fileInputButton"
          disabled={formDisabled}
        >
          <Div display="flex" alignItems="center">
            <FireIcon
              iconName={IconPickerName.Attachment}
              variant="color"
              width="20px"
              height="20px"
            />
          </Div>
        </Button>

        <Button
          type="button"
          variant="linkTertiary"
          my={0}
          p={0}
          onClick={onMessageTemplateClick}
          data-tooltip-id="tooltip-message-footer"
          data-tooltip-place="top"
          data-tooltip-content={t('chat.item.placeholder.standardMessage')}
          data-testid="changeStandardAnswersStateButton"
          disabled={formDisabled}
        >
          <Div display="flex" alignItems="center">
            <FireIcon
              iconName={IconPickerName.ChatMessageAlt}
              variant="color"
              width="20px"
              height="20px"
            />
          </Div>
        </Button>
      </Div>
      <Button
        type="submit"
        variant={sendDisabled ? 'linkTertiary' : 'primary'}
        m={0}
        data-testid="submit-button"
        borderRadius={4}
        p="8px 7px 8px 9px"
        disabled={sendDisabled || formDisabled}
      >
        <FireIcon
          iconName={IconPickerName.SendMail}
          width="20px"
          height="20px"
          fill={
            sendDisabled
              ? themeContext.colors.black.black6
              : themeContext.colors.black.black0
          }
        />
      </Button>
    </Div>
  );
};

export default NewMessageFooter;
