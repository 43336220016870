import gql from 'graphql-tag';

import SIMPLE_AGREEMENT_CONTRACT_FRAGMENT from '../../fragments/SimpleAgreementContractFragment';
import {
  REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables,
  REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION as REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_Result,
} from '../../generated/generated';

export type RejectSimpleAgreementContractMutationPayload =
  REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_Result;
export type RejectSimpleAgreementContractMutationVariables =
  REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATIONVariables;

export const isRejectSimpleAgreementContractMutationSuccessResponse = (
  rejectSimpleAgreementContract?: REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_Result['rejectSimpleAgreementContract']
): rejectSimpleAgreementContract is REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION_Result['rejectSimpleAgreementContract'] =>
  !!rejectSimpleAgreementContract &&
  rejectSimpleAgreementContract.__typename ===
    'RejectSimpleAgreementContractPayload';

export const REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION = gql`
  ${SIMPLE_AGREEMENT_CONTRACT_FRAGMENT}

  mutation REJECT_SIMPLE_AGREEMENT_CONTRACT_MUTATION(
    $input: RejectSimpleAgreementContractInput!
  ) {
    rejectSimpleAgreementContract(input: $input) {
      contract {
        ...SimpleAgreementContractFragment
      }
    }
  }
`;
